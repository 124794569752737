import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { InspectionManagementRoutingModule } from './inspection-management-routing.module';
import { ActionTrackerModule } from '../action-tracker/action-tracker.module';
import { InspectionManagementInspectorsComponent } from './pages/inspection-management-inspectors/inspection-management-inspectors.component';
import { InspectionCardComponent } from './components/inspection-card/inspection-card.component';
import { InspectionManagementReviewersComponent } from './pages/inspection-management-reviewers/inspection-management-reviewers.component';
import { ReviewerCategoryDetailsPageComponent } from './pages/reviewer-category-details-page/reviewer-category-details-page.component';
import { InspectEquipmentPageComponent } from './pages/inspect-equipment-page/inspect-equipment-page.component';
import { CompanySetupModule } from '../company-setup/company-setup.module';
import { InspectionManagementExcuteComponent } from './pages/inspection-management-excute/inspection-management-excute.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { InspectionReviewComponent } from './pages/inspection-review/inspection-review.component';
import { DownTimeComponent } from './components/down-time/down-time.component';
import { HistoryLogsComponent } from './components/history-logs/history-logs.component';
import { HistoryLogsPageComponent } from './pages/history-logs-page/history-logs-page.component';

@NgModule({
  declarations: [
    InspectionManagementInspectorsComponent,
    InspectionCardComponent,
    InspectionManagementReviewersComponent,
    ReviewerCategoryDetailsPageComponent,
    InspectEquipmentPageComponent,
    InspectionManagementExcuteComponent,
    InspectionReviewComponent,
    HistoryLogsPageComponent,
    HistoryLogsComponent,
    DownTimeComponent,
  ],
  imports: [
    CommonModule,
    CompanySetupModule,
    InspectionManagementRoutingModule,
    SharedModule,
    ActionTrackerModule,
    NgxGaugeModule,
  ],
  providers: [],
})
export class InspectionManagementModule {}
