import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/@hodhod/common/constants';
import { JWTTokenValidation } from 'src/@hodhod/common/enum';
import { NavigationService } from 'src/@hodhod/services/navigation.service';
import { FreeTrialWelcomeMessageComponent } from 'src/app/shared/components/free-trial-welcome-message/free-trial-welcome-message.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { BaseApi } from '../api/base-api';
import { JwtDecoderService } from '../helpers/jwt-decoder.service';
import { LoggedUser } from '../models/session-user/logged-user';
import { MessagingService } from './messaging.service';
import {
  ColorVariable,
  colorVariables,
} from 'src/@hodhod/components/config-panel/color-variables';
import { ConfigService } from 'src/@hodhod/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public redirectionURL = '';

  private translationsList: any = {};
  private destroy$ = new Subject();

  public signInError: string = '';
  public customTheme;

  constructor(
    private baseApi: BaseApi,
    private http: HttpClient,
    private jwtDecoderService: JwtDecoderService,
    private router: Router,
    private locationStrategy: LocationStrategy,
    private loadingService: LoadingService,
    private asyncFeedbackService: AsyncFeedbackService,
    private translate: TranslateService,
    private navigationService: NavigationService,
    private messagingService: MessagingService,
    private dialog: MatDialog,
    private configService: ConfigService
  ) {
    this.getSelectedLanguageText();
  }

  signIn(signInInfo: any, rememberMeClicked: boolean) {
    return this.http
      .post<any>(this.baseApi.encodeURL(Constants.API_SIGN_IN), signInInfo)
      .subscribe({
        next: (user) => {
          const userDetails: LoggedUser = {
            userName: user.userName,
            email: user.email,
            fullName: user.fullName,
            userCode: user.userCode,
            picture: user.picture,
            userId: user.userId,
            lastActive: user.lastActive,
            roles: user.roles,
            roleIds: user.roleIds,
            userDepartments: user.userDepartments,
          };
          this.baseApi.saveUserSession(userDetails);
          this.jwtDecoderService.saveToken(user.accessToken);
          this.baseApi.saveOrganizationSession(user.organization);
          this.baseApi.saveCompanySession(user.companyId);
          this.baseApi.saveCompanyObjectSession(user.company);
          this.baseApi.saveUserMenusSession(user.userMenus);
          this.baseApi.saveUserPermissionSession(user.userPermissions);
          this.navigationService.items = this.baseApi.getUserMenusSession();
          this.navigateToInitialMenu();
          this.preventBackButton();
          this.loadingService.stopLoading();
          if(user.companyId != '0'){
            this.openWelcomeDialog(user.fullName);
          }
          // if (user.companyId === 'O-1670918559523') {
          //   this.openWelcomeDialog(user.fullName);
          // } else {
          //   this.asyncFeedbackService.showFeedback(
          //     new FeedbackModel(
          //       FeedbackType.Success,
          //       this.translationsList['Login']['WelcomeMessage'] +
          //         ' ' +
          //         signInInfo.email
          //     )
          //   );
          // }

          this.signInError = '';
          this.messagingService.editUserDevice();
          if (user.company.themeColor) {
            this.customTheme = {
              default: this.hexToRgb(user.company.themeColor),
              light: this.hexToRgb(user.company.themeColor).replace(
                ')',
                ',.1)'
              ),
              contrast: this.hexToRgb(user.company.secondaryColor),
            };
            this.updateThemeColor();
            if (user.company.companyLogo) {
              this.updateCompanyLogo(user.company.companyLogo);
            }
          }
        },
        error: ({ error }) => {
          this.signInError = '';
          this.signInError = error?.message;
          this.loadingService.stopLoading();
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }

  updateThemeColor(): void {
    this.configService.updateConfig({
      style: {
        colors: {
          primary: { ...this.customTheme },
        },
      },
    });
  }

  updateCompanyLogo(companyLogo): void {
    this.configService.updateConfig({
      sidenav: {
        imageUrl: companyLogo,
      },
    });
  }

  hexToRgb(hex) {
    const rgbValues = hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => '#' + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));

    return `rgb(${rgbValues[0]},${rgbValues[1]},${rgbValues[2]})`;
  }

  openWelcomeDialog(userName: string) {
    this.dialog.open(FreeTrialWelcomeMessageComponent, {
      width: window.innerWidth < 850 ? '90%' : '40%',
      height: 'auto',
      data: {
        userName: userName,
      },
    });
  }

  signInWithOutPassword(signInInfo: any) {
    this.loadingService.startLoading();
    return this.http
      .post<any>(
        this.baseApi.encodeURL(Constants.API_SIGN_IN_WITHOUT_PASSWORD),
        signInInfo
      )
      .subscribe({
        next: (user) => {
          const userDetails: LoggedUser = {
            userName: user.userName,
            email: user.email,
            fullName: user.fullName,
            userCode: user.userCode,
            picture: user.picture,
            userId: user.userId,
            lastActive: user.lastActive,
            roles: user.roles,
            roleIds: user.roleIds,
            userDepartments: user.userDepartments,
          };
          this.baseApi.saveUserSession(userDetails);
          this.jwtDecoderService.saveToken(user.accessToken);
          this.baseApi.saveOrganizationSession(user.organization);
          this.baseApi.saveCompanySession(user.companyId);
          this.baseApi.saveCompanyObjectSession(user.company);
          this.baseApi.saveUserMenusSession(user.userMenus);
          this.baseApi.saveUserPermissionSession(user.userPermissions);
          this.navigationService.items = this.baseApi.getUserMenusSession();
          this.navigateToInitialMenu();
          this.preventBackButton();
          this.loadingService.stopLoading();
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(
              FeedbackType.Success,
              this.translationsList['Login']['WelcomeMessage'] +
                ' ' +
                userDetails.email
            )
          );
          this.signInError = '';

          sessionStorage.setItem('reloading', 'true');
          document.location.reload();
        },
        error: ({ error }) => {
          this.signInError = '';
          this.signInError = error?.message;

          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  signInWithUser(signInInfo: any) {
    this.loadingService.startLoading();
    return this.http
      .post<any>(this.baseApi.encodeURL(Constants.API_SIGN_IN_User), signInInfo)
      .subscribe({
        next: (user) => {
          const userDetails: LoggedUser = {
            userName: user.userName,
            email: user.email,
            fullName: user.fullName,
            userCode: user.userCode,
            picture: user.picture,
            userId: user.userId,
            lastActive: user.lastActive,
            roles: user.roles,
            roleIds: user.roleIds,
            userDepartments: user.userDepartments,
          };
          this.baseApi.saveUserSession(userDetails);
          this.jwtDecoderService.saveToken(user.accessToken);
          this.baseApi.saveOrganizationSession(user.organization);
          this.baseApi.saveCompanySession(user.companyId);
          this.baseApi.saveCompanyObjectSession(user.company);
          this.baseApi.saveUserMenusSession(user.userMenus);
          this.baseApi.saveUserPermissionSession(user.userPermissions);
          this.navigationService.items = this.baseApi.getUserMenusSession();
          this.navigateToInitialMenu();
          this.preventBackButton();
          this.loadingService.stopLoading();
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(
              FeedbackType.Success,
              this.translationsList['Login']['WelcomeMessage'] +
                ' ' +
                userDetails.email
            )
          );
          this.signInError = '';
          sessionStorage.setItem('reloading', 'true');
          document.location.reload();
        },
        error: ({ error }) => {
          this.signInError = '';
          this.signInError = error?.message;
          this.loadingService.stopLoading();

          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }
  signInWithUserToken(signInInfo: any) {
    this.loadingService.startLoading();
    return this.http
      .post<any>(
        this.baseApi.encodeURL(Constants.API_SIGN_IN_User_Token),
        signInInfo
      )
      .subscribe({
        next: (user) => {
          const userDetails: LoggedUser = {
            userName: user.userName,
            email: user.email,
            fullName: user.fullName,
            userCode: user.userCode,
            picture: user.picture,
            userId: user.userId,
            lastActive: user.lastActive,
            roles: user.roles,
            roleIds: user.roleIds,
            userDepartments: user.userDepartments,
          };
          this.baseApi.saveUserSession(userDetails);
          this.jwtDecoderService.saveToken(user.accessToken);
          this.baseApi.saveOrganizationSession(user.organization);
          this.baseApi.saveCompanySession(user.companyId);
          this.baseApi.saveCompanyObjectSession(user.company);
          this.baseApi.saveUserMenusSession(user.userMenus);
          this.baseApi.saveUserPermissionSession(user.userPermissions);
          this.navigationService.items = this.baseApi.getUserMenusSession();
          this.navigateToInitialMenu();
          this.preventBackButton();
          this.loadingService.stopLoading();
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(
              FeedbackType.Success,
              this.translationsList['Login']['WelcomeMessage'] +
                ' ' +
                userDetails.email
            )
          );
          this.signInError = '';
          sessionStorage.setItem('reloading', 'true');
          // document.location.reload();
        },
        error: ({ error }) => {
          this.signInError = '';
          this.signInError = error?.message;
          this.loadingService.stopLoading();

          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }
  refreshTheToken(): Observable<string> {
    return this.http.post<string>(Constants.API_REFRESH_TOKEN, { userId: '' });
  }
  confirmEmail(email: string, token: string): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CONFIRM_EMAIL),
      {
        email,
        token,
      }
    );
  }
  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_FORGOT_PASSWORD),
      {
        email,
      }
    );
  }
  resetPassword(
    email: string,
    token: string,
    password: string
  ): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_RESET_PASSWORD),
      {
        email,
        token,
        password,
      }
    );
  }
  resendVerificationCode(email: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_RESEND_VERIFICATION_CODE) +
        '/' +
        email
    );
  }
  resendVerificationCodeFormCompanyAdmin(companyCode: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_RESEND_VERIFICATION_CODE_COMPANY) +
        '/' +
        companyCode
    );
  }
  resendVerificationCodeFormOrgAdmin(orgCode: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_RESEND_VERIFICATION_CODE_ORGANIZATION
      ) +
        '/' +
        orgCode
    );
  }

  changeUserProfile(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CHANGE_USER_PROFILE),
      data
    );
  }
  changeUserEmail(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CHANGE_USER_EMAIL),
      data
    );
  }

  isLoggedIn() {
    const isValidToken: JWTTokenValidation =
      this.jwtDecoderService.isThereValidToken();
    if (
      isValidToken === JWTTokenValidation.Expired ||
      isValidToken === JWTTokenValidation.NotFound
    ) {
      return false;
    }
    return true;
  }

  private preventBackButton() {
    setTimeout(() => {
      history.pushState(null, null, location.href);
      this.locationStrategy.onPopState(() => {
        history.pushState(null, null, location.href);
      });
    }, 1000);
  }

  private getSelectedLanguageText() {
    this.translate
      .get(['Errors', 'Login'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  public navigateToInitialMenu(): void {
    const userMenus = this.baseApi.getUserMenusSession();
    const first_level = userMenus[0]?.children[0];
    if (userMenus.some((item) => item.label === 'DashboardsManagement')) {
      this.router.navigate(['/dashboard']);
    } else if (first_level?.type === 'dropdown') {
      this.router.navigate([first_level?.children[0]?.route]);
    } else if (first_level?.type === 'link') {
      this.router.navigate([first_level?.route]);
    }
  }
}
