import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LanguageService } from 'src/@hodhod/services/language.service';
import { Language } from '../../models/language';


@Component({
  selector: 'app-language-button',
  templateUrl: './language-button.component.html',
  styleUrls: ['./language-button.component.scss'],
})
export class LanguageButtonComponent implements OnInit {

  constructor(public languageService: LanguageService) { }
  @Input() transparent = false;
  @Input() showIcon = true;
  @Input() color = 'var(--color-on-surface-high-emphasis)';
  @Output() selectLanguageChanged = new EventEmitter();
  @ViewChild('button') button: ElementRef;

  languageList: Language[] = [];
  currentLanguage: Language;
  showOptions = false;

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.button.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showOptions = false;
    }
  }

  ngOnInit(): void {
    this.languageService.currentLanguage$.subscribe((language) => {
      this.currentLanguage = language;
      this.setupLanguageList();
    });
    this.languageService.supportedLanguage$.subscribe((_) => {
      this.setupLanguageList();
    });
    this.setupLanguageList();
  }

  onChangeLanguage(language: Language) {
    this.showOptions = !this.showOptions;    
    this.languageService.changeLanguage(language.isoCode);
    this.selectLanguageChanged.emit();
  }

  private setupLanguageList() {
    this.languageList = this.languageService
      .getSupportedLanguagesList()
      .filter((item) => item.isoCode !== this.languageService.currentLanguage$.value.isoCode);
  }
}
