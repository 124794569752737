import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';
import { SortDirection } from 'src/@hodhod/common/enum';
import { Constants } from 'src/@hodhod/common/constants';
import { GetIdCard, PostIdCard, GetIdCardById, GetReNewIdCardById } from 'src/backend/models/manage-id-card/manage-id-card.model';
import { GetAllUpdateCardViewhistory } from 'src/app/security/models/renew-id-card-model';

@Injectable({
  providedIn: 'root'
})
export class ManageIdCardService {

  constructor(private http: HttpClient, private baseApi: BaseApi) { }

  getAllIdCard(data: any): Observable<DataMapper<GetIdCard>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.orgStatus) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.orgStatus;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<GetIdCard>>(this.baseApi.encodeURL(Constants.API_ID_CARD_GET_ALL + '?' + params));
  }

  postIdCard(data: any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_ID_CARD_CREATE), data);
  }

  updateIdCard(data: any): Observable<any> {
    return this.http.put<any>(this.baseApi.encodeURL(Constants.API_ID_CARD_UPDATE), data);
  }

  getReNewIdCardById(id: number): Observable<GetReNewIdCardById> {
    return this.http.get<GetReNewIdCardById>(this.baseApi.encodeURL(Constants.API_RENEW_ID_CARD_GET_BY_ID + '/' + id));
  }

  getIdCardById(id: number): Observable<GetIdCardById> {
    return this.http.get<GetIdCardById>(this.baseApi.encodeURL(Constants.API_ID_CARD_GET_BY_ID + '/' + id));
  }

  deleteDocument(id: number): Observable<any> {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_ID_CARD_DELETE_DOCUMENT_BY_ID + '/' + id))
  }

  approveIdCard(data: any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_ID_CARD_APPROVE), data)
  }

  rejectIdCard(data: any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_ID_CARD_REJECT), data)
  }

  getIdCardHistory(id: number): Observable<DataMapper<GetAllUpdateCardViewhistory>> {
    return this.http.get<DataMapper<GetAllUpdateCardViewhistory>>(this.baseApi.encodeURL(Constants.API_ID_CARD_HISTORY + '/' + id))
  }
}
