import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import {
  GeneralStatus,
  InspectionStatus,
  SortDirection,
  equipmentStatus,
} from 'src/@hodhod/common/enum';
import { MatSort } from '@angular/material/sort';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { Constants } from 'src/@hodhod/common/constants';
import { MsdsQrcodeComponent } from 'src/app/msds/components/msds-qrcode/msds-qrcode.component';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { MatSelectChange } from '@angular/material/select';
import { SharedConstants } from 'src/app/shared/models/shared-constants';

@UntilDestroy()
@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class EquipmentListComponent implements OnInit, OnChanges {
  @Input() CategoryData: any;
  @Input() page!: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('equipmentCreateUpdateModal')
  equipmentCreateUpdateModal!: TemplateRef<any>;
  public translationsList: any = {};
  hasCreatePermission = ApplicationPermission.EQUIPMENT_CREATE;
  hasUpdatePermission = ApplicationPermission.EQUIPMENT_UPDATE;
  hasActivatePermission = ApplicationPermission.EQUIPMENT_ACTIVATE;
  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  editMode: String = 'view';
  public selectedInspectionStatus: string[] = [];
  status = GeneralStatus;
  InspectionStatus = InspectionStatus;
  equipmentStatus = equipmentStatus;
  searchValue: string = '';
  media: any;
  selectedImage: any;
  equipmentForm: FormGroup;
  timer = null;
  searchCtrl = new UntypedFormControl();
  columns: TableColumn<any>[] = [
    {
      label: 'Image',
      property: 'imageUrl',
      type: 'image',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Code',
      property: 'code',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Name',
      property: 'name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Departments',
      property: 'locationDepartmentName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Location',
      property: 'location',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },

    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  equipmentDetails: any;
  categoryDetails: any;
  public selectedDepartment: string[] = [];
  dataSource = new MatTableDataSource<any>([]);
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';
  departments: any[];
  data: any;
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private machineService: MachineEquipmentService,
    private machineEquipmentService: MachineEquipmentService,
    private fb: FormBuilder,
    private departmentService: DepartmentsService,
    private feedBackService: AsyncFeedbackService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private loadingService: LoadingService
  ) {
    this.translate
      .get([
        'Errors',
        'Success',
        'Role',
        'confirmDeactiveRecord',
        'User',
        'General',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;
    this.currentQueryParams?.pageIndex;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);
    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;
    this.selectedDepartment = this.currentQueryParams?.orgDepartments
      ? this.currentQueryParams?.orgDepartments.split(',')
      : [];
    this.selectedInspectionStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();

    this.getCompanyDepartments();
    this.equipmentForm = this.fb.group({
      equipmentName: ['', [Validators.maxLength(50)]],
      equipmentCode: ['', [Validators.required, Validators.maxLength(20)]],
      department: ['', [Validators.required]],
      location: ['', [Validators.maxLength(100)]],
      checklist: ['', [Validators.required]],
    });

    if (this.page === 'excute') {
      this.columns.splice(
        5,
        0,
        {
          label: 'ExecutedOn',
          property: 'executedOn',
          type: 'date',
          visible: false,
          cssClasses: ['text-secondary', 'font-medium'],
        },
        {
          label: 'InspectedBy',
          property: 'inspectedBy',
          type: 'text',
          visible: true,
          cssClasses: ['text-secondary', 'font-medium'],
        }
      );
    } else if (this.page === 'setup') {
      this.columns.splice(5, 0, {
        label: 'UpdatedOn',
        property: 'updatedOn',
        type: 'date',
        visible: false,
        cssClasses: ['text-secondary', 'font-medium'],
      });
    } else if (this.page === 'review') {
      this.columns.splice(
        5,
        0,
        {
          label: 'Score',
          property: 'score',
          type: 'text',
          visible: true,
          cssClasses: ['text-secondary', 'font-medium'],
        },
        {
          label: 'InspectedBy',
          property: 'inspectedBy',
          type: 'text',
          visible: true,
          cssClasses: ['text-secondary', 'font-medium'],
        }
      );
    }
    // switch (this.page) {
    //   case value:

    //     break;

    //   default:
    //     break;
    // }
  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.categoryDetails = changes['CategoryData']?.currentValue;
  }

  openReviewEquipmentPage(id) {
    this.router.navigate([
      '/' +
        SharedConstants.INSPECTION_MANAGEMENT +
        '/' +
        SharedConstants.INSPECTION_MANAGEMENT_REVIEW +
        '/' +
        id,
    ]);
  }

  openEquipmentModal(id?, editM?) {
    if (this.page === 'setup') {
      this.loadingLabel = 'General.Refreshing';
      this.sectionStateModal = SectionStateStatus.Loading;
      this.dialog.open(this.equipmentCreateUpdateModal, {
        height: 'auto',
        width: '30%',
        disableClose: false,
      });
      if (!editM) {
        this.equipmentForm.disable();
        this.editMode = 'view';
      } else {
        this.equipmentForm.enable();
        this.editMode = 'edit';
      }
      this.equipmentDetails = null;
      this.machineEquipmentService.getEquipmentById(id).subscribe({
        next: (res) => {
          this.equipmentDetails = res;
          this.selectedImage = res?.imageUrl;
          this.equipmentForm.get('equipmentCode').setValue(res?.code);
          this.equipmentForm.get('equipmentName').setValue(res?.name);
          this.equipmentForm
            .get('department')
            .setValue(res?.locationDepartmentId);
          this.equipmentForm.get('location').setValue(res?.location);
          if (this.categoryDetails?.inspectionChecklists.length === 1) {
            this.equipmentForm
              .get('checklist')
              .setValue(this.categoryDetails?.inspectionChecklists[0]?.checkListId);
          }
          this.sectionStateModal = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionStateModal = SectionStateStatus.Ready;
        },
      });
    }
  }

  openCreateModal() {
    this.selectedImage = '';
    this.media = '';
    this.editMode = 'create';
    this.equipmentForm.reset();
    this.equipmentDetails = {};
    this.equipmentForm.get('equipmentCode').enable();
    this.equipmentForm.get('equipmentName').enable();
    this.equipmentForm.get('department').enable();
    this.equipmentForm.get('location').enable();
    if (this.categoryDetails?.inspectionChecklists.length === 1) {
      this.equipmentForm
        .get('checklist')
        .setValue(this.categoryDetails?.inspectionChecklists[0]?.checkListId);
    }
    this.dialog.open(this.equipmentCreateUpdateModal, {
      height: 'auto',
      width: '30%',
      disableClose: false,
    });
  }
  createNewEquipment() {
    const { equipmentName, equipmentCode, department, location, checklist } =
      this.equipmentForm.value;

    const data = {
      Name: equipmentName,
      Code: equipmentCode,
      Location: location,
      EquipmentCategoryId: this.categoryDetails.id,
      LocationDepartmentId: department,
      ChecklistId: checklist,
    };

    const formData = new FormData();
    formData.append('content', JSON.stringify(data));
    formData.append('equipmentImage', this.media);

    this.loadingService.startLoading(true, '');
    this.machineEquipmentService.createEquipment(formData).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['General']['Updated']
          )
        );
        this.loadingService.stopLoading();
        this.getData();
        this.dialog.closeAll();
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  getCompanyDepartments(): void {
    this.departmentService.getCompanyDepartments().subscribe({
      next: (response) => {
        this.departments = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  Dectivate(id) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.machineEquipmentService.DeactivateEquipment(id).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  Activate(id) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.machineEquipmentService.ActivateEquipment(id).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  inspectEquipment(id) {
    this.router.navigate([
      SharedConstants.INSPECTION_MANAGEMENT +
        '/' +
        SharedConstants.INSPECTION_MANAGEMENT_EXECUTE +
        '/' +
        id,
    ]);
  }
  onSelectStatus(event) {
    this.selectedInspectionStatus = event.value;
    // this.currentQueryParams = {
    //   ...this.currentQueryParams,
    //   orgStatus: event.value.toString(),
    // };
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: this.currentQueryParams,
    // });
    this.getData();
  }
  onSelectDepartment(event: MatSelectChange) {
    this.selectedDepartment = event.value;
    // this.currentQueryParams = {
    //   ...this.currentQueryParams,
    //   orgDepartments: event.value.toString(),
    // };
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: this.currentQueryParams,
    //   queryParamsHandling: 'merge',
    //   // preserve the existing query params in the route
    //   skipLocationChange: true,
    //   replaceUrl: true,
    // });
    this.getData();
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  getData() {
    let { id } = this.route.snapshot.params;
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      departmentNames: this.selectedDepartment,
      status: this.selectedInspectionStatus,
    };

    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    if (this.page === 'setup') {
      this.machineService.getEquipmentList(filteredParams, id).subscribe({
        next: (response) => {
          this.dataSource = new MatTableDataSource(response?.data);
          this.pagination.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
    } else {
      this.getInspectionsList(filteredParams, id);
    }
  }
  getInspectionsList(data, id) {
    let Arr: any[] = [];
    if (this.page === 'excute') {
      this.machineService.getCurrentInspections(data, id, false).subscribe({
        next: (response) => {
          this.dataSource = new MatTableDataSource(response.data);
          this.pagination.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
    } else if (this.page === 'review') {
      this.machineService.getReviewerEquipments(data, id, false).subscribe({
        next: (response) => {
          this.dataSource = new MatTableDataSource(response?.data);
          this.pagination.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
    }
  }
  onFileSelected(event: any) {
    const files: FileList | null = event.target.files;
    if (files && files.length > 0) {
      this.media = files[0];
      const file: File = files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (this.equipmentDetails) {
          this.equipmentDetails.imageUrl = e.target.result;
        }
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
  save(id, checklistId) {
    const { equipmentName, equipmentCode, department, location } =
      this.equipmentForm.value;

    const data = {
      Id: id,
      Name: equipmentName,
      Code: equipmentCode,
      Location: location,
      ChecklistId: checklistId,
      LocationDepartmentId: department,
    };
    const formData = new FormData();
    formData.append('content', JSON.stringify(data));
    formData.append('equipmentImage', this.media);
    this.loadingService.startLoading(true, '');
    this.machineEquipmentService.updateEquipment(formData).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['General']['Updated']
          )
        );
        this.loadingService.stopLoading();
        this.getData();
        this.dialog.closeAll();
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: this.currentQueryParams,
    // });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  generateQrCode() {
    const inspectionManagementBaseUrl = '/inspectionManagement/';
    const equipmentId = this.equipmentDetails.id;
    const inspectionManagementUrl =
      inspectionManagementBaseUrl + this.categoryDetails.id + '/' + equipmentId;
    this.matDialog.open(MsdsQrcodeComponent, {
      height: '515px',
      width: '430px',
      panelClass: 'qrcode-dialog',
      id: 'msds-qrcode-dialog',
      data: {
        type: 'equipment',
        Url: inspectionManagementUrl,
        Title: this.equipmentDetails.name,
        Code: this.equipmentDetails.code,
      },
    });
  }
}
