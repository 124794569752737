import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';
import {
  Dashboard,
  DashboardFilterPayload,
} from 'src/app/dashboard-two/models/dashboard';

@Injectable({
  providedIn: 'root',
})
export class ReportingDashboardService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  SaveVisibilityForDashboard(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_SAVE_VISIBILTY_DASHBOARD_BY_USER_ID),
      data
    );
  }

  getCustomizedDashboardByUserId(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_CUSTOMIZE_DASHBOARD_BY_USER_ID)
    );
  }
  getCompanyFeatures(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_COMPANY_FEATURE)
    );
  }

  SaveCustomFilterForDashboard(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_SAVE_CUSTOMIZE_DASHBOARD_BY_USER_ID),
      data
    );
  }

  getReportingDashboardData(data?: any): Observable<Dashboard> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REPORTING_DASHBOARD_DATA),
      data
    );
  }
  getDataByDashboard(
    dashboardName: string,
    data?: DashboardFilterPayload
  ): Observable<Dashboard> {
    return this.http.post<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORTING_DASHBOARD_DATA + '/' + dashboardName
      ),
      data
    );
  }

  filterReportingDashboard(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_FILTER_REPORTING_DASHBOARD),
      data
    );
  }
}
