export const environment = {
  production: true,
  api_version: 'v1.0.0',
  white_listed_Domains: ['https://hodhod-prod-core.azurewebsites.net/'],
  api_baseurl: 'https://hodhod-prod-core.azurewebsites.net/',
  api_prefix: 'api/',
  api_prefix_smartmonitoring: 'apiSmartMonitoring/',
  api_access_key_smartMonitoring: 'pgH7QzFHJx4w46fI~5Uzi4RvtTwlEXp',
  api_workflow_url: 'https://hodhod-workflow-prod-core.azurewebsites.net/api/',
  api_chatbot_url: 'https://hodhod-chatbot-prod.azurewebsites.net/',
  work_flow_company_key: '89f43e9e-b6d8-43b2-a489-060d9bd39ea6',
  firebaseConfig: {
    apiKey: 'AIzaSyBfN2ahD9Zm7Ya16xZrQOFYQ71Omy7MujY',
    authDomain: 'hodhod-prod.firebaseapp.com',
    databaseURL: 'https://hodhod-prod.firebaseio.com',
    projectId: 'hodhod-prod',
    storageBucket: 'hodhod-prod.appspot.com',
    messagingSenderId: '761953905071',
    appId: '1:761953905071:web:8eacf3c4b49f400140275a',
    measurementId: 'G-5FCLGM1EZ3',
    vapidKey:
      'BJfpn11qXDQVACvvzPnuAgfWu8DK-Zsyp0x8obRpm2ED93hF7jVUC3DEcntXrZBLnHYskG63EUnVW60x4-kRVF8',
  },
};
