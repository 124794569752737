import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from '../api/base-api';
import { Feature } from '../models/features/feature';

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  constructor(private http: HttpClient, private baseApi: BaseApi) { }


  getAllFeatures(): Observable<Feature[]> {
    return this.http.get<Feature[]>(this.baseApi.encodeURL(Constants.API_FEATURES));
  }

  getFeaturesByOrganization(orgCode: string): Observable<Feature[]> {
    return this.http.get<Feature[]>(this.baseApi.encodeURL(Constants.API_FEATURES_BY_ORG) + '/' + orgCode);
  }
  getFeaturesByCompany(compCode: string): Observable<Feature[]> {
    return this.http.get<Feature[]>(this.baseApi.encodeURL(Constants.API_FEATURES_BY_COMP) + '/' + compCode);
  }

}
