import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-type',
  templateUrl: './card-type.component.html',
  styleUrls: ['./card-type.component.scss']
})
export class CardTypeComponent implements OnInit {
  @Input() title: string = '';
  @Input() imgSrc: string = '';
  
  @Output() onClick: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onButtonClick(): void {
    this.onClick.emit("Clicked")
  }

}
