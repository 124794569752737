import {
  Component,
  ElementRef,
  Inject,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import {
  Dashboard,
  DashboardFilterPayload,
  DashboardSetting,
} from '../../models/dashboard';
import {
  DashboardColors,
  DashboardTypes,
  PenaltyFilterDashboards,
  SmartSafetyFilterDashboards,
} from 'src/@hodhod/common/enum';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';

import * as htmlToImage from 'html-to-image';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { ReportingDashboardService } from 'src/backend/services/dashboards/reporting-dashboard.service';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { Router } from '@angular/router';
import { DashboardCalenderComponent } from 'src/app/dashboard/components/dashboard-calender/dashboard-calender.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import jsPDF from 'jspdf';
import { LanguageService } from 'src/@hodhod/services/language.service';

@Component({
  selector: 'app-dashboard-fullscreen-two',
  templateUrl: './dashboard-fullscreen.component.html',
  styleUrls: ['./dashboard-fullscreen.component.scss'],
})
export class DashboardFullscreenComponent implements OnInit, OnChanges {
  public dashboard: Dashboard;
  public rtl: string;

  public chartLevel: string = 'one';

  public dashboardColors: any;
  public dashboardTypes: any;

  public chartDataLevel1: any;
  public chartDataLevel2: any = [];
  public chartDataLevel3: any;
  public chartDataLevel4: any;

  public showLevelTwo: boolean = false;
  public showLevelThree: boolean = false;
  public showLevelFour: boolean = false;

  public totalLevelTwo: number = 0;
  public totalLevelThree: number = 0;
  public totalLevelFour: number = 0;

  public titleLevelTwo: string = '';
  public titleLevelThree: string = '';
  public titleLevelFour: string = '';

  public filterDashboards: any = null;

  public translationsList: any = {};
  public isPrint: boolean = false;

  public selectedColor: any;

  public dashboardFilter: DashboardFilterPayload = {
    fromDate: new Date().toISOString(),
    toDate: new Date().toISOString(),
  };
  public penaltyFilterDashboards: any = null;

  public filterName: string = '';

  public auditPassValue: string = '';

  public dashboardSetting: DashboardSetting;

  private destroy$ = new Subject();

  constructor(
    private loadingService: LoadingService,
    private translate: TranslateService,
    private reportingDashboardService: ReportingDashboardService,
    private feedBackService: AsyncFeedbackService,
    private bottomSheet: MatBottomSheet,
    private router: Router,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private elementRef: ElementRef,
    private langService: LanguageService,
    public bottomSheetRef: MatBottomSheetRef<DashboardFullscreenComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { dashboard: Dashboard; dashboardSetting: DashboardSetting }
  ) {
    this.translate
      .get(['Dashboards'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations.Dashboards;
      });
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.dashboard = this.data.dashboard;
    this.dashboardSetting = this.data.dashboardSetting;
    if (this.dashboard.dashboardName === 'AuditScore') {
      this.auditPassValue = Number(
        this.dashboard.drilldownChartLevels.level1.find(
          (item) => item.name == 'PASS'
        )?.value
      ).toFixed(2);
    }
    this.dashboardColors = DashboardColors;
    this.dashboardTypes = DashboardTypes;
    this.filterDashboards = SmartSafetyFilterDashboards;
    this.penaltyFilterDashboards = PenaltyFilterDashboards;

    this.dashboardFilter = {
      fromDate: new Date(this.data.dashboardSetting.fromDate).toISOString(),
      toDate: new Date(this.data.dashboardSetting.toDate).toISOString(),
    };
    this.filterName = this.dashboardSetting.filterName;

    this.optimizedDashboardLevel();
    this.langService.currentLanguage$.subscribe((language) => {
      this.rtl = language.isoCode;
    });
  }

  optimizedDashboardLevel() {
    let level1 = [];
    let object: any;
    let color: Array<string> = [];

    // build chart level1
    color = this.dashboardColors[this.dashboard.dashboardName].split(',');

    if (this.dashboard.chartType === 'PieChart') {
      object = {
        colorByPoint: true,
        innerSize: '40%',
        data: [],
      };
      for (
        let i = 0;
        i < this.dashboard.drilldownChartLevels.level1?.length;
        i++
      ) {
        object.data.push({
          name: this.dashboard.drilldownChartLevels.level1[i]?.name,
          y: this.dashboard.drilldownChartLevels.level1[i]?.value
            ? parseFloat(this.dashboard.drilldownChartLevels.level1[i]?.value)
            : 0,
          color: color[i],
          drilldown: this.dashboard.drilldownChartLevels.level1[i]?.id
            ? this.dashboard.drilldownChartLevels.level1[i]?.id
            : '-',
        });
      }
      level1.push(object);
      object = null;
      this.chartDataLevel1 = [...level1];
    } else {
      for (
        let i = 0;
        i < this.dashboard.drilldownChartLevels.level1?.length;
        i++
      ) {
        object = {
          name: this.dashboard.drilldownChartLevels.level1[i]?.name,
          type: this.dashboardTypes[this.dashboard.chartType],
          data: [
            {
              y: Number(this.dashboard.drilldownChartLevels.level1[i]?.value),
              drilldown: this.dashboard.drilldownChartLevels.level1[i]?.id,
            },
          ],
          status: this.dashboard.drilldownChartLevels.level1[i]?.status,
          color: color[i],
        };
        level1.push(object);
        object = null;
      }
      // this.optimizedDashboard.level1 = [...level1];
      this.chartDataLevel1 = [...level1];
    }
  }

  changeChartLevel(clickedPoint: any) {
    let color = [];
    let object = null;
    let level2 = [];
    let level3 = [];
    let level4 = [];

    if (clickedPoint.level === 'one') {
      this.showLevelTwo = true;
      this.showLevelThree = false;
      this.showLevelFour = false;
      color = this.dashboardColors[this.dashboard.dashboardName].split(',');

      const data = this.dashboard.drilldownChartLevels.level2.filter(
        (item) => item.parent === clickedPoint.point
      );
      for (let i = 0; i < data?.length; i++) {
        object = {
          name: data[i]?.name,
          type: 'column',
          data: [
            {
              y: Number(data[i]?.value),
              drilldown: data[i]?.id,
            },
          ],
          status: data[i]?.status,
          // color: clickedPoint.color,
        };
        level2.push(object);
        object = null;
      }
      this.selectedColor = clickedPoint.color;

      this.chartDataLevel2 = [...level2];

      this.totalLevelTwo = 0;
      if (this.dashboard.dashboardName === 'AuditScore') {
        this.totalLevelTwo = clickedPoint.value;
      } else {
        this.chartDataLevel2
          .map((item) => item.data)
          .forEach((el) => {
            el.map((i) => {
              this.totalLevelTwo += i.y;
            });
          });
      }

      this.titleLevelTwo = this.dashboard.drilldownChartLevels.level1.find(
        (item) => item.id === clickedPoint.point
      )?.name;

      const levelCard = document.getElementById('level-two');
      setTimeout(() => {
        levelCard?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    } else if (clickedPoint.level === 'two') {
      this.showLevelThree = true;
      this.showLevelFour = false;

      color = this.dashboardColors[this.dashboard.dashboardName].split(',');
      const data = this.dashboard.drilldownChartLevels.level3.filter(
        (item) => item.parent === clickedPoint.point
      );
      for (let i = 0; i < data?.length; i++) {
        object = {
          name: data[i]?.name,
          type: 'column',
          data: [
            {
              y: Number(data[i]?.value),
              drilldown: data[i]?.id,
            },
          ],
          status: data[i]?.status,
          // color: clickedPoint.color,
        };
        level3.push(object);
        object = null;
      }
      this.selectedColor = clickedPoint.color;
      this.chartDataLevel3 = [...level3];

      this.totalLevelThree = 0;

      if (
        this.dashboard.dashboardName === 'ReportedHazard' ||
        this.dashboard.dashboardName === 'ReportedNearMiss'
      ) {
        this.totalLevelThree = clickedPoint.value;
      } else {
        this.chartDataLevel3
          .map((item) => item.data)
          .forEach((el) => {
            el.map((i) => {
              this.totalLevelThree += i.y;
            });
          });
      }

      this.titleLevelThree = this.dashboard.drilldownChartLevels.level2.find(
        (item) => item.id === clickedPoint.point
      )?.name;

      const levelCard = document.getElementById('level-three');
      setTimeout(() => {
        levelCard?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);
    } else if (clickedPoint.level === 'three') {
      if (this.dashboard.dashboardName === 'IncidentLoss') {
        this.bottomSheet.dismiss();
        this.router.navigate(
          [
            SharedConstants.REPORTING_MANAGEMENT +
              '/' +
              SharedConstants.REPORT_INCIDENT +
              '/' +
              SharedConstants.REPORT_INCIDENT_VIEW +
              '/' +
              clickedPoint.point,
          ],
          {
            queryParams: { editMode: false },
          }
        );
      } else if (this.dashboard?.drilldownChartLevels['level4']?.length > 0) {
        this.showLevelFour = true;
        color = this.dashboardColors[this.dashboard.dashboardName].split(',');
        const data = this.dashboard.drilldownChartLevels.level4.filter(
          (item) => item.parent === clickedPoint.point
        );
        for (let i = 0; i < data?.length; i++) {
          object = {
            name: data[i]?.name,
            type: 'column',
            data: [
              {
                y: Number(data[i]?.value),
                drilldown: data[i]?.id,
              },
            ],
            status: data[i]?.status,
            // color: clickedPoint.color,
          };
          level4.push(object);
          object = null;
        }
        this.selectedColor = clickedPoint.color;
        this.chartDataLevel4 = [...level4];

        this.totalLevelFour = 0;
        this.chartDataLevel4
          .map((item) => item.data)
          .forEach((el) => {
            el.map((i) => {
              this.totalLevelFour += i.y;
            });
          });

        this.titleLevelFour = this.dashboard.drilldownChartLevels.level3.find(
          (item) => item.id === clickedPoint.point
        )?.name;

        const levelCard = document.getElementById('level-four');
        setTimeout(() => {
          levelCard?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    }
  }

  setFilterForDashboard(filterName: string) {
    this.showLevelTwo = false;
    this.showLevelThree = false;
    this.showLevelFour = false;

    if (filterName !== 'customRange') {
      this.loadingService.startLoading();
      this.filterName = filterName;
      const date = new Date();

      if (filterName === 'thisDay') {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        let lastHourInDay = new Date();
        lastHourInDay.setHours(23);
        lastHourInDay.setMinutes(59);
        lastHourInDay.setSeconds(59);
        lastHourInDay.setMilliseconds(999);

        this.dashboardFilter.fromDate = new Date(today).toISOString();
        this.dashboardFilter.toDate = new Date(lastHourInDay).toISOString();
      } else if (filterName === 'yesterday') {
        const today = new Date();
        const day = today.getDate() - 1;
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setDate(day);

        this.dashboardFilter.fromDate = new Date(today).toISOString();
        today.setHours(23);
        today.setMinutes(59);
        today.setSeconds(59);
        today.setMilliseconds(999);
        this.dashboardFilter.toDate = new Date(today).toISOString();
      } else if (filterName === 'currentMonth') {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDay.setHours(0, 0, 0, 0);
        today.setHours(23, 59, 59, 0);

        this.dashboardFilter.fromDate = firstDay.toISOString();
        this.dashboardFilter.toDate = today.toISOString();
      } else if (filterName === 'lastMonth') {
        const today = new Date();
        const firstDayPrevMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        firstDayPrevMonth.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = firstDayPrevMonth.toISOString();
        const lastDayPrevMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );

        lastDayPrevMonth.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = lastDayPrevMonth.toISOString();
      } else if (filterName === 'lastThirtyDay') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const Past30Day = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
        Past30Day.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = Past30Day.toISOString();
      } else if (filterName === 'yearToDate') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);

        this.dashboardFilter.toDate = new Date(today).toISOString();

        const startYearDate = new Date(date.getFullYear(), 0, 1);
        startYearDate.setHours(0, 0, 0, 0);

        this.dashboardFilter.fromDate = startYearDate.toISOString();
      } else if (filterName === 'lastYear') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const startDateBefore12Months = new Date(today);
        startDateBefore12Months.setFullYear(
          startDateBefore12Months.getFullYear() - 1
        );
        startDateBefore12Months.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = startDateBefore12Months.toISOString();
      } else if (filterName === 'lastNineMonths') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const startDateBefore9Months = new Date(today);
        startDateBefore9Months.setMonth(startDateBefore9Months.getMonth() - 9);
        startDateBefore9Months.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = startDateBefore9Months.toISOString();
      } else if (filterName === 'lastSixMonths') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const startDateBefore6Months = new Date(today);
        startDateBefore6Months.setMonth(startDateBefore6Months.getMonth() - 6);
        startDateBefore6Months.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = startDateBefore6Months.toISOString();
      } else if (filterName === 'lastThreeMonths') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const startDateBefore3Months = new Date(today);
        startDateBefore3Months.setMonth(startDateBefore3Months.getMonth() - 3);
        startDateBefore3Months.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = startDateBefore3Months.toISOString();
      }
      this.saveFilterData();
    }
  }

  onSelectCustomRange() {
    
    const dialogRef = this.dialog.open(DashboardCalenderComponent, {
      width: window.innerWidth < 850 ? '90%' : '60%',
      height: 'auto',
      data: {
        fromDate: this.dashboardSetting.fromDate,
        toDate: this.dashboardSetting.toDate,
        dashboardName:this.dashboard.dashboardName
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event !== 'cancel') {
        this.dashboardFilter.fromDate = new Date(
          new Date(result?.data?.fromDate).setHours(0, 0, 0, 0)
        ).toISOString();
        this.dashboardFilter.toDate = new Date(
          new Date(result?.data?.toDate).setHours(23, 59, 59, 0)
        ).toISOString();
        this.dashboardSetting.fromDate = result?.data?.fromDate;
        this.dashboardSetting.toDate = result?.data?.toDate;
        this.dashboardSetting.filterName = 'customRange';
        this.filterName = 'customRange';
        this.saveFilterData();
      }
    });
    
  }

  saveFilterData() {
    const filterData = {
      dashboardName: this.dashboard.dashboardName,
      filterName: this.filterName,
      fromDate: this.dashboardFilter.fromDate,
      toDate: this.dashboardFilter.toDate,
    };
    this.reportingDashboardService
      .SaveCustomFilterForDashboard(filterData)
      .subscribe({
        next: (res) => {
          this.getDashboardData();
        },
        error: (error) => {
          this.feedBackService?.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  getDashboardData() {
    this.reportingDashboardService
      .getDataByDashboard(this.dashboard.dashboardName, this.dashboardFilter)
      .subscribe({
        next: (res) => {
          this.dashboard = res;
          this.data.dashboard = res;
          this.optimizedDashboardLevel();
          this.loadingService.stopLoading();
        },
        error: (error) => {
          this.feedBackService?.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  async takePrint() {
    const doc = new jsPDF({
      orientation: 'portrait',
    }).setProperties({
      title: this.translationsList[this.dashboard?.dashboardName],
    });
    doc.addFont('assets/fonts/Amiri-Regular.ttf', 'Amiri', 'normal');

    let y = 5;

    // Logo and Company Data
    const companyLogo =
      JSON.parse(localStorage.getItem('company')).companyImage || '';
    if (companyLogo) {
      doc.addImage(companyLogo, 'PNG', 10, y, 20, 20); // Adjust positioning and size as needed
      doc.text(
        JSON.parse(localStorage.getItem('company')).companyName,
        35,
        y + 10
      );
      y += 45;
    } else {
      doc.text(JSON.parse(localStorage.getItem('company')).companyName, 10, y, {
        align: 'center',
      });
      y += 20;
    }

    // Title Line
    doc.setLineWidth(1);
    doc.line(10, 30, 200, 30);
    doc.addImage(
      '../../../../assets/img/resources/saned-white-logo.png',
      'PNG',
      140,
      24,
      40,
      10
    );
    // Report Title
    doc.setFont('Amiri', 'normal');
    doc.setFontSize(18);
    doc.text(this.translationsList[this.dashboard?.dashboardName], 105, 40, {
      align: 'center',
    });
    doc.setFontSize(14);
    if (this.rtl === 'ar') {
      doc.text(
        'منذ ' +
          this.formatCustomDate(this.dashboardFilter.fromDate) +
          ' حتى ' +
          this.formatCustomDate(this.dashboardFilter.toDate),
        200,
        y,
        { align: 'right' }
      );
    } else {
      doc.text(
        'From ' +
          this.formatCustomDate(this.dashboardFilter.fromDate) +
          ' To ' +
          this.formatCustomDate(this.dashboardFilter.toDate),
        10,
        y,
        { align: 'left' }
      );
    }
    y += 10;
    //Doc levels
    let node = document.getElementById('level-one') as HTMLElement;
    await htmlToImage.toPng(node).then((url) => {
      doc.addImage(url, 'PNG', 10, y, 180, 90);
    });
    if (this.showLevelTwo) {
      y += 95;
      let node = document.getElementById('level-two') as HTMLElement;
      await htmlToImage.toPng(node).then((url) => {
        doc.addImage(url, 'PNG', 10, y, 180, 90);
      });
    }
    if (this.showLevelThree) {
      doc.addPage();
      y = 20;
      let node = document.getElementById('level-three') as HTMLElement;
      await htmlToImage.toPng(node).then((url) => {
        doc.addImage(url, 'PNG', 10, y, 180, 90);
      });
    }
    if (this.showLevelFour) {
      y += 95;
      let node = document.getElementById('level-four') as HTMLElement;
      await htmlToImage.toPng(node).then((url) => {
        doc.addImage(url, 'PNG', 10, y, 180, 90);
      });
    }
    doc.save(`${this.translationsList[this.dashboard?.dashboardName]}.pdf`);
  }

  closeFullScreen() {
    this.bottomSheet.dismiss({
      dashboardFilter: this.dashboardFilter,
      filterName: this.filterName,
      isChanged:
        this.filterName !== this.dashboardSetting.filterName ||
        this.filterName === 'customRange'
          ? true
          : false,
    });
  }

  handleBackdropClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (!this.elementRef.nativeElement.contains(target)) {
      this.closeFullScreen();
    }
  }

  getLevelThreeTitle() {
    let title = '';
    if (this.dashboard.dashboardName == 'ReportedHazard') {
      title = 'Per Type of hazard';
    } else if (this.dashboard.dashboardName == 'ReportedNearMiss') {
      title = 'Per Type of near miss';
    } else if (this.dashboard.dashboardName == 'ReportedIncident') {
      title = 'Per Incident classification wise';
    } else if (
      this.dashboard.dashboardName == 'TotalReported' ||
      this.dashboard.dashboardName == 'WorkPermit' ||
      this.dashboard.dashboardName == 'ActionPlan' ||
      this.dashboard.dashboardName == 'CheckList'
    ) {
      title = 'Per Reporters';
    } else if (this.dashboard.dashboardName == 'AuditAdherence') {
      title = 'Per Department';
    } else if (this.dashboard.dashboardName == 'AuditScore') {
      title = 'Per Checklist';
    } else if (this.dashboard.dashboardName == 'IncidentLoss') {
      title = 'Per Report';
    } else if (this.dashboard.dashboardName == 'ReportedPenalty') {
      title = 'Per Team';
    }

    return title;
  }

  getLevelFourTitle() {
    let title = '';
    if (this.dashboard.dashboardName == 'ReportedHazard') {
      title = 'Per Root cause';
    } else if (this.dashboard.dashboardName == 'ReportedNearMiss') {
      title = 'Per Root Cause';
    } else if (this.dashboard.dashboardName == 'ReportedIncident') {
      title = 'Per Root Cause';
    } else if (this.dashboard.dashboardName == 'AuditAdherence') {
      title = 'Per Reporters';
    } else if (this.dashboard.dashboardName == 'AuditScore') {
      title = 'Per Question';
    }

    return title;
  }

  formatDate(date) {
    return moment(new Date(date)).format('dddd, MMMM Do YYYY');
  }

  formatCustomDate(date) {
    return this.datePipe
      .transform(date, 'yyyy-MM-ddT00:00:00.000')
      .slice(0, 10);
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  }
}
