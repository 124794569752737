<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="'User.LoadingUserInfo' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
 <div class="flex justify-between flex-wrap-reverse">
   <form [formGroup]="userForm" class="flex-1 pr-5">
       <div class="flex column">
         <mat-label class="field-label">{{'User.Form.Code' | translate}}</mat-label>
         <mat-form-field class="input-field" appearance="outline">
           <input [placeholder]="'Placeholder.AddText' | translate" matInput readonly
           type="text" formControlName="userCode" />
         </mat-form-field>
       </div>
       <div class="flex column">
         <mat-label class="field-label">{{'User.Form.FirstName' | translate}}</mat-label>
         <mat-form-field class="input-field" appearance="outline">
           <input [placeholder]="'Placeholder.AddText' | translate" matInput readonly
           type="text" formControlName="firstName" />
         </mat-form-field>
       </div>
       <div class="flex column">
         <mat-label class="field-label">{{'User.Form.LastName' | translate}}</mat-label>
         <mat-form-field class="input-field" appearance="outline">
           <input [placeholder]="'Placeholder.AddText' | translate" matInput readonly
           type="text" formControlName="lastName" />
         </mat-form-field>
       </div>
       <div class="flex column">
         <mat-label class="field-label">{{'User.Form.Phone' | translate}}</mat-label>
         <mat-form-field class="input-field" appearance="outline">
           <input [placeholder]="'Placeholder.AddText' | translate" matInput readonly
           type="text" formControlName="phoneNumber" />
         </mat-form-field>
       </div>
       <div class="flex column">
         <mat-label class="field-label">{{'General.Inputs.Email' | translate}}</mat-label>
         <mat-form-field class="input-field" appearance="outline">
           <input matInput type="email" [placeholder]="'Placeholder.EnterEmail' | translate" formControlName="email" autocomplete="email" readonly/>
         </mat-form-field>
       </div>
       <div class="flex column">
         <mat-label class="field-label">{{'User.Form.ReportTo' | translate}}</mat-label>
         <mat-form-field class="input-field" appearance="outline">
           <input [placeholder]="'Placeholder.AddText' | translate" matInput readonly
           type="text" formControlName="reportTo" />
         </mat-form-field>
       </div>
       <div class="flex column">
        <mat-label class="field-label">{{'User.Form.IdNumber' | translate}}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input [placeholder]="'Placeholder.AddText' | translate" matInput readonly
          type="text" formControlName="idNumber" />
        </mat-form-field>
      </div>
   </form>
   <div class="flex-1">
    <div @fadeInUp class="flex items-center" *ngIf="data">
      <p class="text-left font-bold text-primary mr-4 rtl:ml-3">{{'General.Status' | translate}}</p>
      <app-boolean-view
        width="105px"
        [value]="data?.status === 'ACTIVE'"
        [text]="data?.status === 'ACTIVE' ? ('General.Active' | translate) : ('General.Inactive' | translate)"
      ></app-boolean-view>
    </div>
    <div @fadeInUp class="avatar-container">
      <ngx-avatars bgColor="#2c3e5000" fgColor="#222222" class="avatar box" size="200"
        [ngStyle]="{'border-color': (data?.isVerified ? VERIFIED_COLOR : UNVERIFIED_COLOR)}"
        [src]="data?.userImage" [name]="data?.fullName | uppercase"
        [textSizeRatio]="3" [initialsSize]="2" [round]="true"></ngx-avatars>
      <div class="label"
        [ngStyle]="{'background-color': (data?.isVerified ? VERIFIED_COLOR : UNVERIFIED_COLOR)}">
        <span *ngIf="data?.isVerified">{{('General.Verified' | translate) | uppercase}}</span>
        <span *ngIf="!data?.isVerified">{{('General.Unverified' | translate) | uppercase}}</span>
      </div>
    </div>
  <div class="mt-3">
    <button @fadeInUp mat-flat-button class="verify-button" 
     (click)="openChangeProfileModal()">
       <span>{{'BreadCrumb.ChangeProfile'|translate}}</span>
    </button>
    <button @fadeInUp mat-flat-button *ngIf="showEmailChange" class="verify-button ml-3" 
     (click)="openChangeEmailModal()">
       <span>{{'General.Inputs.Email'|translate}}      </span>
    </button>
  </div>
    <p class="text-lg font-bold my-3">{{'User.Form.Role' | translate}}</p>
    <div class="flex my-5 flex-wrap">
      <span class="text-md inline-block py-2 px-3 mx-1 mb-1 leading-none text-center whitespace-nowrap align-baseline font-bold bg-primary text-white rounded-full" *ngFor="let role of data?.userRoles | stringToArray">{{role}}</span>     
    </div>

    <p class="text-lg font-bold my-3">{{'User.Form.Department' | translate}}</p>
    <div class="flex my-5 flex-wrap">
      <span class="text-md inline-block py-2 px-3 mx-1 mb-1 leading-none text-center whitespace-nowrap align-baseline font-bold bg-green-600 text-white rounded-full" *ngFor="let role of data?.departmentName | stringToArray">{{role}}</span>     
    </div>

   </div>
 </div>
</app-state-section>

<!-- Change Image Model -->
<ng-template #profileChangeModal>
    <div class="flex items-center" mat-dialog-title>   
        <h2 class="headline m-0 flex-auto">{{'BreadCrumb.ChangeProfile'|translate}}</h2>
        <button class="text-secondary" (click)="closeDialog()" mat-icon-button type="button">
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
    </div>
    <div>
        <form [formGroup]="profileImageForm">
              <div class="">
                <mat-label class="field-label">{{'BreadCrumb.UploadProfile'|translate}}</mat-label>
                <file-upload formControlName="profileImage" [multiple]="false" [animation]="true"
                  [accept]="'file_extension|image/*'">
                </file-upload>
              </div>
          </form>
    </div>
    <div class="flex justify-end items-center mt-4">
        <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel'"
        (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
        [btnType]="'Add'"
        [btnText]="'Submit'"
        [isDisabled]="!profileImageForm.valid"
        (onClick)="changeProfile()"
        ></app-basic-button>
    </div>
</ng-template>

<!-- Change Email Model -->
<ng-template #emailChangeModal>
  <div class="flex items-center" mat-dialog-title>   
      <h2 class="headline m-0 flex-auto"> {{'General.Inputs.Email'|translate}}      </h2>
      <button class="text-secondary" (click)="closeDialog()" mat-icon-button type="button">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
  </div>
  <div>
      <form [formGroup]="emailChangeForm">
            <div class="">
              <mat-label class="field-label">Email</mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input matInput type="email" [placeholder]="'Placeholder.EnterEmail' | translate" formControlName="email" autocomplete="email"/>
                <mat-error *ngIf="emailChangeForm.invalid && emailChangeForm.get('email').hasError('required')">
                  <div class="flex justify-flex-start align-flex-center">
                    <img class="error-img" src="assets/img/resources/error-icon.svg" />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
                <mat-error *ngIf="emailChangeForm.invalid && emailChangeForm.get('email').hasError('invalidEmail')">
                  <div class="flex justify-flex-start align-flex-center">
                    <img class="error-img" src="assets/img/resources/error-icon.svg" />
                    <span>{{ "Errors.InvalidEmail" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
        </form>
  </div>
  <div class="flex justify-end items-center mt-4">
      <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
      ></app-basic-button>
      <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="!emailChangeForm.valid"
      (onClick)="changeEmail()"
      ></app-basic-button>
  </div>
</ng-template>



