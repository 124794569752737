import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { merge, takeUntil, tap } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { enumToArray } from 'src/@hodhod/common/custom_methods';
import {
  CameraStatus,
  SortDirection,
  VideoAnalyticsStatus,
} from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { EnumToArrayPipe } from 'src/app/shared/pipes/enum-to-array.pipe';
import { BaseApi } from 'src/backend/api/base-api';
import { GetAllCamers } from 'src/backend/models/video-analytics/video-analytics.model';
import { PermissionService } from 'src/backend/services/permission.service';
import { VideoAnalyticsService } from 'src/backend/services/video-analytics/video-analytics.service';

@UntilDestroy()
@Component({
  selector: 'app-video-analytics',
  templateUrl: './video-analytics.component.html',
  styleUrls: ['./video-analytics.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class VideoAnalyticsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('viewImageDialog') private viewImage!: TemplateRef<any>;

  cameras: GetAllCamers[];
  columns: TableColumn<GetAllCamers>[] = [
    { label: 'Image', property: 'picture', type: 'image', visible: true },
    {
      label: 'CameraId',
      property: 'cameraId',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'ChannelNo',
      property: 'channelNo',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'CameraName',
      property: 'cameraName',
      type: 'text',
      visible: true,
    },
    {
      label: 'Department',
      property: 'departmentName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'CameraPosition',
      property: 'cameraPosition',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    {
      label: 'IsCameraRunning',
      property: 'isCameraRunning',
      type: 'boolean',
      visible: true,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<GetAllCamers>([]);
  selection = new SelectionModel<GetAllCamers>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  loadingLabel: string = '';
  sessionCompany: any;
  timer = null;
  viewImagePath: any;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  CameraStatuses = CameraStatus;
  VideoAnalyticsStatuses = VideoAnalyticsStatus;
  SharedConstants = SharedConstants;

  public selectedCamerasStatus: string[] = [];
  public selectedVideoAnaluticsStatus: string[] = [];
  private translationsList: any = {};

  hasActivatePermission = ApplicationPermission.VIDEO_ANALYTICS_ACTIVATE;
hasObservationsAccessPermissions=ApplicationPermission.VIOLATIONS_ACCESS
  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private feedBackService: AsyncFeedbackService,
    private baseApi: BaseApi,
    private videoAnalyticsService: VideoAnalyticsService,
    private router: Router,
    private permissionService: PermissionService,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveCamera',
        'confirmActiveCamera',
        'StaticPages',
        'General',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.getSessionOrganization();
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedVideoAnaluticsStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex:0
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData()
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getSessionOrganization(): void {
    this.sessionCompany = this.baseApi.getCompanyFromStorage();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      statusUnconfigured: this.selectedVideoAnaluticsStatus.find(
        (x) => x === VideoAnalyticsStatus.UnConfigured.toString()
      ),
      statusConfigured: this.selectedVideoAnaluticsStatus.find(
        (x) => x === VideoAnalyticsStatus.Configured.toString()
      ),
      companyCode: this.sessionCompany.companyCode,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.videoAnalyticsService.getAllCameras(filteredParams).subscribe({
      next: (response) => {
        this.cameras = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex:0
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  onSelectCameraStatus(event: MatSelectChange) {
    this.selectedCamerasStatus = event.value;
    this.getData();
  }
  onSelectVideoAnalyticsStatus(event: MatSelectChange) {
    this.selectedVideoAnaluticsStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  viewImgDialog(path: any) {
    this.viewImagePath = path;
    this.dialog.open(this.viewImage, { height: '97%', width: '80%' });
  }

  deactivateCamera(cameraId: number): void {
    const hasPermisison: boolean = this.permissionService.isUserHasPermission(
      this.hasActivatePermission
    );
    if (hasPermisison) {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['confirmDeactiveCamera']['Title'],
            this.translationsList['confirmDeactiveCamera']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            const data = {
              cameraId: cameraId,
            };
            this.videoAnalyticsService.deActivateCamera(data).subscribe({
              next: () => {
                this.getData();
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Success,
                    this.translationsList['General']['DeActivated']
                  )
                );
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.error.message)
                );
              },
            });
          }
        });
    } else {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
    }
  }
  activateCamera(cameraId: number): void {
    const hasPermisison: boolean = this.permissionService.isUserHasPermission(
      this.hasActivatePermission
    );
    if (hasPermisison) {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['confirmActiveCamera']['Title'],
            this.translationsList['confirmActiveCamera']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            const data = {
              cameraId: cameraId,
            };
            this.videoAnalyticsService.activateCamera(data).subscribe({
              next: () => {
                this.getData();
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Success,
                    this.translationsList['General']['Activated']
                  )
                );
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.error.message)
                );
              },
            });
          }
        });
    } else {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
    }
  }

  viewCamera(id: number): void {
    this.router.navigate(
      [
        '/' +
          SharedConstants.SMART_MONITORING +
          '/' +
          SharedConstants.VIDEO_ANALYTICS +
          '/' +
          SharedConstants.VIDEO_ANALYTICS_VIEW +
          '/' +
          id,
      ],
      {
        queryParams: { ...this.currentQueryParams, editMode: false },
      }
    );
  }

  voilations(id: number): void {
    this.router.navigate([
      '/' +
        SharedConstants.SMART_MONITORING +
        '/' +
        SharedConstants.VOILATIONS +
        '/' +
        id,
    ]);
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
}
