<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.ViewAudit' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="loadingLabel | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <div class="flex justify-end mr-1 mb-4">
        <app-basic-button
          *ngIf="
            (data?.status === 'REVIEW' || data?.status === 'MISSED') &&
            data?.status !== 'REJECTED' &&
            data?.status !== 'CLOSED' &&
            loggedUser.userId === data.createdBy
          "
          [btnType]="'Approve text-sm'"
          [btnText]="'Close'"
          (onClick)="openCloseRejectModal('Close Task')"
        >
        </app-basic-button>
        <!-- <app-basic-button [btnType]="'Print text-sm'"
        *ngIf="data?.status === 'REVIEW' && data?.status !== 'REJECTED'"
        [btnText]="'Actions'" (onClick)="onClickActions()">
        </app-basic-button>
        <app-basic-button [btnType]="'Edit text-sm'"
        *ngIf="data?.status === 'NEED_ACTION' && data?.status !== 'REJECTED'"
        [btnText]="'ActionsAssigned'" (onClick)="actionsAssigned()">
        </app-basic-button> -->
        <app-basic-button
          [btnType]="'Delete text-sm'"
          [btnText]="'Reject'"
          *ngIf="
            data?.status === 'REVIEW' &&
            data?.status !== 'MISSED' &&
            data?.status !== 'CLOSED' &&
            data?.status !== 'REJECTED' &&
            loggedUser.userId === data.createdBy
          "
          (onClick)="openCloseRejectModal('Reject Task')"
        >
        </app-basic-button>
        <app-basic-button
          [btnType]="'Print text-sm'"
          [btnText]="'Reschedule'"
          *ngIf="
            data?.status === 'MISSED' && loggedUser.userId === data.createdBy
          "
          (click)="rescheduleTask()"
        >
        </app-basic-button>
      </div>
      <app-dismiss-alert
        *ngIf="data?.status === 'MISSED'"
        [message]="'General.MissedTask' | translate"
      ></app-dismiss-alert>
      <div class="flex gap-2">
        <div class="left-wrapper">
          <app-expandable-panel
            [expanded]="true"
            #expandablePanel
            title="{{ data?.title }}"
          >
            <div body class="flex column" *ngIf="data">
              <div class="py-3 flex items-center">
                <div
                  @scaleIn
                  class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                >
                  <mat-icon
                    class="icon-sm"
                    svgIcon="mat:keyboard_double_arrow_right"
                  ></mat-icon>
                </div>

                <div @fadeInRight>
                  <h4 class="m-0 font-bold">{{ data?.title }}</h4>
                </div>
              </div>
              <div class="grid grid-cols-2 items-start gap-3">
                <div
                  class="item flex column flex-2"
                  *ngIf="data?.isExecuted === true"
                >
                  <div *ngFor="let s of checklist">
                    <div *ngFor="let q of s.questions">
                      <h6 class="font-bold text-gray-400">
                        Question: {{ q.questionText }}
                      </h6>
                      <p class="mt-2">
                        Answer:
                        {{
                          q?.isNotApplicable ? "Not Applicable" : q?.userAnswer
                        }}
                      </p>
                      <p class="mt-2" *ngIf="q?.comment">
                        Comment:
                        {{ q?.comment }}
                      </p>
                      <div class="w-fit">
                        <ngx-avatars
                          *ngIf="q?.media"
                          class="cursor-pointer"
                          size="60"
                          [src]="getAttachmentImage(q.media)"
                          [name]="getAttachementTypeName(q.media)"
                          [textSizeRatio]="2"
                          [round]="false"
                          [initialsSize]="2"
                          [matTooltip]="q?.mediaName"
                          [cornerRadius]="10"
                          [borderColor]="'#131C4E'"
                          (clickOnAvatar)="openBigView(q.media)"
                        >
                        </ngx-avatars>
                      </div>

                      <hr class="my-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-expandable-panel>
        </div>
        <div class="card_wrapper">
          <div class="card">
            <div class="inline-block">
              <h4 class="title">
                {{ "TaskTracker.View.AuditDetails" | translate }}
              </h4>
            </div>
            <div class="flex justify-between flex-wrap-reverse">
              <div class="flex-1">
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <h4 class="m-0 font-bold">{{ data?.title }}</h4>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.description }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.Description" | translate }}
                    </p>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.title }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.Title" | translate }}
                    </p>
                  </div>
                </div>
                <div
                  class="py-3 flex items-center"
                  *ngIf="data?.status === 'REVIEW'"
                >
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.minimumScore }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.MinScore" | translate }}
                    </p>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{
                        data?.departmentParentName
                          ? data?.departmentParentName
                          : data?.departmentName
                      }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.Department" | translate }}
                    </p>
                  </div>
                </div>
                <div
                  class="py-3 flex items-center"
                  *ngIf="data?.departmentParentName"
                >
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.departmentName }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.SubDepartment" | translate }}
                    </p>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.assignTo }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.AssignTo" | translate }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div style="margin-top: -50px" class="text-center">
                  <ngx-gauge
                    [foregroundColor]="getGuageColors(gaugeValue)"
                    [type]="'full'"
                    [value]="gaugeValue"
                    [label]=""
                    [append]="'%'"
                    [thick]="20"
                  ></ngx-gauge>
                  <h4 class="font-bold text-center">
                    {{ "TaskTracker.View.ChecklistResult" | translate }}
                  </h4>
                </div>
                <div
                  class="py-3 flex items-center"
                  *ngIf="data?.status === 'REVIEW'"
                >
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p
                      class="m-0 body-1"
                      [style.color]="
                        data?.scoreStatus == 'FAIL' ? '#D7354F' : '#68BA66'
                      "
                    >
                      {{ data?.scoreStatus }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.ScoreStatus" | translate }}
                    </p>
                  </div>
                </div>

                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ data?.dueDate | date : "mediumDate" }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.dueDate" | translate }}
                    </p>
                  </div>
                </div>
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ data?.executedDeviceType }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.executedDeviceType" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="flex justify-between flex-wrap-reverse" *ngIf="data">
              <div class="flex-1">
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.assignTo }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.AssignTo" | translate }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ data?.dueDate | date : "mediumDate" }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.dueDate" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="flex justify-between flex-wrap-reverse" *ngIf="data">
              <div class="flex-1">
                <div
                  class="py-3 flex items-center"
                  *ngIf="
                    data?.status === 'REJECTED' || data?.status === 'CLOSED'
                  "
                >
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.closeOrRejectBy }}</p>
                    <p class="m-0 caption text-hint">
                      {{
                        data?.status === "REJECTED"
                          ? "Rejected By"
                          : "Closed By"
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div
                  class="py-3 flex items-center"
                  *ngIf="
                    data?.status === 'REJECTED' || data?.status === 'CLOSED'
                  "
                >
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.comment }}</p>
                    <p class="m-0 caption text-hint">Comment</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-between flex-wrap-reverse" *ngIf="data">
              <div
                class="flex-1"
                *ngIf="data?.status === 'REJECTED' || data?.status === 'CLOSED'"
              >
                <div class="py-3 flex items-center" *ngIf="data?.attachment">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 caption text-hint">
                      {{ "ReportHazardList.Table.RCAFile" | translate }}
                    </p>
                    <p class="m-0 body-1">
                      <a
                        mat-button
                        color="primary"
                        href="{{ data?.attachment }}"
                        download
                        >Download File</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="flex justify-between flex-wrap-reverse" *ngIf="data">
              <div class="flex-1">
                <div class="py-3 flex items-center">
                  <div>
                    <p class="mb-2">
                      {{ "ExecutedTask.ProofOfLocation" | translate }}
                    </p>
                    <div class="w-fit">
                      <ngx-avatars
                        *ngIf="data?.proofOfLocation"
                        class="logo box cursor-pointer"
                        size="150"
                        [src]="data?.proofOfLocation"
                        [name]="data?.title"
                        [round]="false"
                        [initialsSize]="2"
                        [cornerRadius]="10"
                        [borderColor]="'#131C4E'"
                        [matTooltip]="data?.proofOfLocationName"
                        (clickOnAvatar)="openImageInBigView()"
                      ></ngx-avatars>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <app-action
          [sourceId]="taskId"
          [sourceType]="ReportingType.Task"
          [isCreate]="data?.status !== 'REJECTED'"
        >
        </app-action>
      </div>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>

<ng-template #closeOrRejectModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{ modalName }}</h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="rejectOrCloseForm">
      <div class="field-full flex column">
        <mat-label class="field-label">Comment</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <textarea
            placeholder="Comment"
            matInput
            type="text"
            formControlName="comment"
          >
          </textarea>
        </mat-form-field>
      </div>
      <div class="flex column">
        <mat-label class="field-label">{{
          "Company.BasicForm.Image" | translate
        }}</mat-label>
        <file-upload
          formControlName="file"
          [multiple]="false"
          [animation]="true"
          (dragover)="$event.preventDefault()"
        >
        </file-upload>
      </div>
    </form>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      (onClick)="modalName === 'Reject Task' ? onClickReject() : closeTask()"
    ></app-basic-button>
  </div>
</ng-template>
<!-- Image Viewer Model -->

<ng-template #imageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="selectedImg" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">{{ data?.title }}</h2>
    <button
      class="text-secondary"
      (click)="downloadDocument(selectedImg)"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:download"></mat-icon>
    </button>

    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex">
    <div class="">
      <img [src]="selectedImg" [alt]="data?.title" />
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
  </div>
</ng-template>
