import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { AddNewMachinePageComponent } from './pages/add-new-machine-page/add-new-machine-page.component';
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { CreateCompanyPageComponent } from './pages/create-company-page/create-company-page.component';
import { DepartmentsPageComponent } from './pages/departments-page/departments-page.component';
import { UpdateCompanyPageComponent } from './pages/update-company-page/update-company-page.component';
import { MachineEquipmentPageComponent } from './pages/machine-equipment-page/machine-equipment-page.component';
import { ViewCompanyPageComponent } from './pages/view-company-page/view-company-page.component';
import { ViewDepartmentPageComponent } from './pages/view-department-page/view-department-page.component';
import { EditViewMachinePageComponent } from './pages/edit-view-machine-page/edit-view-machine-page.component';
import { ValueTypePageComponent } from './pages/value-type-page/value-type-page.component';
import { UnitOfMeasurePageComponent } from './pages/unit-of-measure-page/unit-of-measure-page.component';
import { PpeComponent } from './pages/ppe/ppe.component';
import { ShiftsPageComponent } from './pages/shifts-page/shifts-page.component';
import { ViewCategoryDetailsComponent } from './components/view-category-details/view-category-details.component';
import { CategoryPageComponent } from './pages/category-page/category-page.component';
import { MultiPermissionGuard } from '../shared/guards/MultiPermission.guard';
import { HistoryLogsPageComponent } from '../inspection-management/pages/history-logs-page/history-logs-page.component';
import { AreasListComponent } from './components/areas-list/areas-list.component';
import { AreasPageComponent } from './pages/areas-page/areas-page.component';
import { TeamsPageComponent } from './pages/teams-page/teams-page.component';
const routes: Routes = [
  {
    path: SharedConstants.COMPANIES,
    component: CompanyPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.COMPANIES_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Companies',
      },
    },
  },
  {
    path: SharedConstants.CATEGORIES,
    component: CategoryPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.COMPANIES_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Categories',
      },
    },
  },
  {
    path: SharedConstants.COMPANY_VIEW + '/:id',
    component: ViewCompanyPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.COMPANIES_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.CATEGORIES + '/:id',
    component: CategoryPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.EQUIPMENT_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.COMPANY_CREATE,
    component: CreateCompanyPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.COMPANIES_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'CreateNew',
      },
    },
  },
  {
    path: SharedConstants.MACHINES,
    component: MachineEquipmentPageComponent,
    canActivate: [MultiPermissionGuard],
    data: {
      permissions: [
        ApplicationPermission.MACHINE_ACCESS,
        ApplicationPermission.EQUIPMENT_ACCESS,
      ],
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Machines',
      },
    },
  },
  {
    path:
      SharedConstants.MACHINES + '/' + SharedConstants.ADD_NEW_MACHINE + '/:id',
    component: AddNewMachinePageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.MACHINE_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'AddParameters',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.MACHINES +
      '/' +
      SharedConstants.EDIT_VIEW_MACHINE +
      '/:id',
    component: EditViewMachinePageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.MACHINE_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.COMPANY_UPDATE + '/:id',
    component: UpdateCompanyPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.COMPANIES_UPDATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Edit',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.DEPARTMENT,
    component: DepartmentsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DEPARTMENTS_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Departments',
      },
    },
  },
  {
    path: SharedConstants.DEPARTMENT_VIEW + '/:id',
    component: ViewDepartmentPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DEPARTMENTS_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.VALUE_TYPES,
    component: ValueTypePageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.VALUE_TYPES_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'ValueTypes',
      },
    },
  },
  {
    path: SharedConstants.UNIT_OF_MEASUREMENTS,
    component: UnitOfMeasurePageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.UNIT_OF_MEASURES_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'UnitOfMeasurement',
      },
    },
  },
  {
    path: SharedConstants.PPE_SETTING,
    component: PpeComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.PPE_VIEW,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Ppe',
      },
    },
  },
  {
    path: SharedConstants.AREAS_SETTING,
    component: AreasPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.PPE_VIEW, //handeling when permissions done
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Areas',
      },
    },
  },
  {
    path: SharedConstants.TEAMS_SETTING,
    component: TeamsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.PPE_VIEW, //handeling when permissions done
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Teams',
      },
    },
  },
  {
    path: SharedConstants.SHIFTS_SETTING,
    component: ShiftsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.PPE_VIEW,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Shifts',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanySetupRoutingModule {}
