import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { PenaltyManagementService } from 'src/backend/services/penalty-management.service';

@Component({
  selector: 'app-penalty-management-view',
  templateUrl: './penalty-management-view.component.html',
  styleUrls: ['./penalty-management-view.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms,stagger40ms],
})
export class PenaltyManagementViewComponent implements OnInit {
  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  @ViewChild('closeModal') closeModal!: TemplateRef<any>;

  public data: any;
  penaltyId: any = null;
  files: any[] = [];
  selectedImg: any = '';
  approverTeam: string = '';

  dataSource = new MatTableDataSource<any>([]);
  columns: TableColumn<any>[] = [
    {
      label: 'RequestedBy',
      property: 'requestedBy',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    { label: 'Created', property: 'created', type: 'text', visible: true },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Comments', property: 'comment', type: 'text', visible: true },
  ];

  closeForm: FormGroup;

  public loggedUser: LoggedUser;

  constructor(
    private penaltyManagementService: PenaltyManagementService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private feedBackService: AsyncFeedbackService,
    private loadingService: LoadingService,
    private baseApi: BaseApi
  ) {}

  ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.closeForm = this.fb.group({
      remarks: [''],
      media: [[]],
    });
    this.penaltyId = this.route.snapshot.params['id'];
    this.getPenaltyManagementById();
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  getPenaltyManagementById() {
    this.penaltyManagementService
      .getPenaltyManagementById(this.penaltyId)
      .subscribe((res) => {
        this.data = res;
        this.files = this.data?.penaltyAttachments;
        this.approverTeam = this.data.penaltyApprovers.map(
          (item) => item.approverName
        );
        this.dataSource = new MatTableDataSource(
          this.data?.penaltyHistories.map((ele) => {
            return {
              ...ele,
              created: moment(ele.created)
                .local(true)
                .format('MMM DD YYYY hh mm A'),
            };
          })
        );
      });
  }

  openClosePenalty() {
    this.closeForm.get('remarks')?.setValue('');
    this.closeForm.get('media')?.setValue([]);
    this.dialog.open(this.closeModal, { width: '50%' });
  }

  closePenalty() {
    this.loadingService.startLoading();
    const formData = new FormData();
    const data = {
      Id: this.penaltyId,
      Comment: this.closeForm.get('remarks').value,
    };
    formData.append('content', JSON.stringify(data));
    this.closeForm.get('media').value.forEach((element) => {
      formData.append('files', element);
    });

    this.penaltyManagementService.closePenaltyManagement(formData).subscribe({
      next: (res) => {
        this.closeDialog();
        this.getPenaltyManagementById();
        this.loadingService.stopLoading();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
      },
      error: (err) => {
        this.loadingService.stopLoading();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, err?.message)
        );
      },
    });
  }

  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }
  openBigView(id: number): void {
    const img = this.data?.penaltyAttachments.find((x) => x.id === id);
    if (img) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
      const extension = img.fileName.split('.').pop();
      if (imageExtensions.includes(extension)) {
        this.selectedImg = img;
        this.dialog.open(this.imageBigView, {
          maxHeight: '95vh',
          width: 'auto',
        });
      } else {
        const downloadTag = document.createElement('a');
        downloadTag.href = img?.imageUrl;
        downloadTag.addEventListener('click', () => {
          downloadTag.download;
        });
        downloadTag.click();
      }
    }
  }
  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  downloadDocument(url: any): void {
    const downloadTag = document.createElement('a');
    downloadTag.href = url;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  checkCanClose() {
    return this.data?.penaltyApprovers.some(
      (item) => item.approverId === this.loggedUser.userId
    );
  }
}
