import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { UserService } from 'src/backend/services/user.service';

@Component({
  selector: 'app-add-department',
  templateUrl: './add-department.component.html',
  styleUrls: ['./add-department.component.scss'],
})
export class AddDepartmentComponent implements OnInit, OnDestroy {
  depForm: FormGroup;
  formMode: 'create' | 'update' = 'create';
  private destroy$ = new Subject();
  public translationsList: any = {};
  showDepartmentparent: boolean = false;
  users: any[] = [];

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  departmentsData!: any[];
  loadingLabel: string = '';
  checkedSubDept: boolean = false;

  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { defaults: any; departmentId: number },
    private dialogRef: MatDialogRef<AddDepartmentComponent>,
    private fb: FormBuilder,
    private feedBackService: AsyncFeedbackService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private departmentService: DepartmentsService,
    private userService: UserService
  ) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    if (this.data?.defaults) {
      this.formMode = 'update';
    }
    this.depForm = this.fb.group({
      DepartmentName: [this.data?.defaults?.name || '', Validators.required],
      DepartmentRepresentor: [this.data?.defaults?.representorId || ''],
      departmentParentId: [this.data?.departmentId || 0],
    });
    this.getDepartmentParentChild();
    this.getUsers();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getUsers(): void {
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.users = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  onClickChild(event: any): void {
    if (event.checked === true) {
      this.showDepartmentparent = true;
    } else {
      this.showDepartmentparent = false;
    }
  }

  getDepartmentParentChild(): any {
    this.departmentService.getDepartmentsParentChild().subscribe({
      next: (res) => {
        this.departmentsData = res;
      },
    });
  }

  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createDepartment();
          }
        });
    } else if (this.formMode === 'update') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['UpdateConfirm']['Title'],
            this.translationsList['User']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.updateDepartment();
          }
        });
    }
  }

  createDepartment() {
    const { DepartmentName, DepartmentRepresentor, departmentParentId } =
      this.depForm.value;
    const data = {
      name: DepartmentName,
      representor: DepartmentRepresentor,
      parentId: departmentParentId,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.departmentService.postDepartment(data).subscribe({
      next: () => {
        this.dialogRef.close();
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['SuccessMessage']
          )
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  updateDepartment() {
    const {
      DepartmentName,
      DepartmentRepresentor,
      DepartmentParent,
      departmentParentId,
    } = this.depForm.value;
    const data = {
      id: this.data.defaults.id,
      name: DepartmentName,
      representor: DepartmentRepresentor,
      parentId: departmentParentId,
    };
    this.sectionState = SectionStateStatus.Loading;
    this.departmentService.UpdateDepartment(data).subscribe({
      next: () => {
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['SuccessMessage']
          )
        );
        this.dialogRef.close();
      },
      error: ({ error }) => {
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  isCreateMode() {
    return this.formMode === 'create';
  }

  isUpdateMode() {
    return this.formMode === 'update';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
