import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Constants } from 'src/@hodhod/common/constants';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { RolesStatus, SortDirection } from 'src/@hodhod/common/enum';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { AddUpdateRootCauseComponent } from '../add-update-rootCause/add-update-rootCause.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { GetRootCause } from 'src/backend/models/root-cause/root-cause.models';
import { RootCauseService } from 'src/backend/services/root-cause/root-cause.service';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { PermissionService } from 'src/backend/services/permission.service';

@UntilDestroy()
@Component({
  selector: 'app-root-cause',
  templateUrl: './root-cause.component.html',
  styleUrls: ['./root-cause.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class RootCauseComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  rootCauses: GetRootCause[];
  columns: TableColumn<GetRootCause>[] = [
    {
      label: 'Name',
      property: 'rootCauseName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'ReportingType',
      property: 'reportingTypes',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'CreatedAt', property: 'createdOn', type: 'date', visible: true },
    {
      label: 'UpdatedAt',
      property: 'updatedOn',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Company',
      property: 'companyId',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<GetRootCause>([]);
  selection = new SelectionModel<GetRootCause>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  loadingLabel: string = '';
  timer = null;
  hasCreatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_CREATE;
  hasUpdatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_UPDATE;
  hasActivatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_ACTIVATE;
  hasViewPermission = ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_VIEW;
  RolesStatuses = RolesStatus;
  SharedConstants = SharedConstants;

  public selectedRootCausesStatus: string[] = [];
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  private translationsList: any = {};

  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private rootCauseService: RootCauseService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private permissionService: PermissionService,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'StaticPages',
        'General',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedRootCausesStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex:0
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData()

    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      status: this.currentQueryParams?.orgStatus,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.rootCauseService.getRootCause(filteredParams).subscribe({
      next: (response) => {
        this.rootCauses = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onSelectRootCausesStatus(event: MatSelectChange) {
    this.selectedRootCausesStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex:0
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  deactivateRoot(event: any, roleId: string): void {
    const hasPermission: boolean = this.permissionService.isUserHasPermission(
      this.hasActivatePermission
    );
    if (hasPermission) {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['confirmDeactiveRecord']['Title'],
            this.translationsList['confirmDeactiveRecord']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.rootCauseService.deActivate(parseInt(roleId, 10)).subscribe({
              next: () => {
                this.getData();
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Success,
                    this.translationsList['General']['DeActivated']
                  )
                );
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Failure,
                    this.translationsList['Errors']['ErrorHappened']
                  )
                );
              },
            });
          }
        });
    } else {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
    }
  }
  activateRoot(event: any, roleId: string): void {
    const hasPermission: boolean = this.permissionService.isUserHasPermission(
      this.hasActivatePermission
    );
    if (hasPermission) {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['confirmDeactiveRecord']['Title'],
            this.translationsList['confirmDeactiveRecord']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.rootCauseService.activate(parseInt(roleId, 10)).subscribe({
              next: () => {
                this.getData();
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Success,
                    this.translationsList['General']['Activated']
                  )
                );
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Failure,
                    this.translationsList['Errors']['ErrorHappened']
                  )
                );
              },
            });
          }
        });
    } else {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
    }
  }
  viewRoot(event: any, root: GetRootCause): void {
    const data = {
      root: root,
      action: 'view',
    };
    this.dialog
      .open(AddUpdateRootCauseComponent, {
        height: 'auto',
        width: '40%',
        data: data,
        maxHeight: '70vh',
      })
      .afterClosed()
      .subscribe((updatedRole) => {
        this.getData();
      });
  }

  createRoot() {
    const data = {
      root: '',
      action: 'create',
    };
    this.dialog
      .open(AddUpdateRootCauseComponent, {
        height: 'auto',
        width: '40%',
        data: data,
      })
      .afterClosed()
      .subscribe((role: GetRootCause) => {
        this.getData();
      });
  }

  updateRoot(event: any, root: GetRootCause) {
    const data = {
      root: root,
      action: 'update',
    };
    this.dialog
      .open(AddUpdateRootCauseComponent, {
        height: 'auto',
        width: '40%',
        data: data,
      })
      .afterClosed()
      .subscribe((updatedRole) => {
        this.getData();
      });
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
}
