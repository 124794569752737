<form [formGroup]="reportIncidentForm">
  <div class="flex items-center justify-end my-3">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="goBack()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="!reportIncidentForm.valid"
      (onClick)="createReportIncident()"
    ></app-basic-button>
  </div>
  <div>
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'AddReportIncident.ReportIncident' | translate"
    >
      <div body class="flex column">
        <div class="flex justify-between flex-wrap-reverse">
          <div class="flex-1 pr-5">
            <div class="flex column">
              <mat-label class="field-label"
                >{{ "AddReportIncident.Title" | translate }}
                <span
                  [ngClass]="
                    reportIncidentForm.invalid &&
                    reportIncidentForm.get('title').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  [placeholder]="'Placeholder.AddText' | translate"
                  matInput
                  type="text"
                  formControlName="title"
                />
                <mat-error>
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex column">
              <mat-label class="field-label"
                >{{ "AddReportIncident.TypeOfIncident" | translate }}
                <span
                  [ngClass]="
                    reportIncidentForm.invalid &&
                    reportIncidentForm
                      .get('typeOfIndcident')
                      .hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf1="ngxMatSelect"
                  [displayMember]="'incidentName'"
                  [valueMember]="'id'"
                  [source]="typeOfIncidents"
                  [useInfiniteScroll]="true"
                  [hasPermission]="
                    hasCreateTypeOfIncidentsPermission | permissionChecker
                  "
                  [title]="'type of incidents'"
                  [hasSearchBox]="false"
                  [manageRoute]="'/reporting-management/incident-category'"
                  [placeholder]="'Placeholder.Select' | translate"
                  formControlName="typeOfIndcident"
                  [disabled]="false"
                  multiple
                >
                  <mat-option
                    *ngFor="let toi of msf1.filteredSource"
                    [value]="toi?.id"
                  >
                    {{ toi?.incidentName }}</mat-option
                  >
                </mat-select>
                <mat-error>
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-1">
            <mat-checkbox
              formControlName="isAnyOneInjured"
              color="primary"
              class="mt-5"
              (change)="onChangeAnyOne()"
            >
              {{ "AddReportIncident.AnyoneInjured" | translate }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </app-expandable-panel>
  </div>
  <div class="mt-5">
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'AddReportIncident.IncidentInitialReport' | translate"
    >
      <div body class="flex column">
        <div class="flex justify-between items-center flex-wrap gap-5">
          <div class="flex-1">
            <div class="flex column">
              <mat-label class="field-label">
                {{ "AddReportIncident.Department" | translate }}
                <span
                  [ngClass]="
                    reportIncidentForm.invalid &&
                    reportIncidentForm.get('departmentId').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf2="ngxMatSelect"
                  [displayMember]="'departmentName'"
                  [valueMember]="'departmentCode'"
                  [source]="departments"
                  [useInfiniteScroll]="true"
                  [multiple]="false"
                  [hasPermission]="hasDepartmentPermission | permissionChecker"
                  [title]="'departments'"
                  [hasSearchBox]="false"
                  [manageRoute]="'/company-setup/departments'"
                  formControlName="departmentId"
                  [placeholder]="'Placeholder.SelectDepartment' | translate"
                  (selectionChange)="onChangeDepartment($event)"
                >
                  <mat-option
                    *ngIf="msf2.filteredSource.length === 0"
                    style="height: 0"
                  ></mat-option>
                  <mat-optgroup
                    *ngFor="let group of msf2.filteredSource"
                    [label]="group.parentName"
                  >
                    <mat-option
                      *ngFor="let detail of group.detail"
                      [value]="detail.departmentCode"
                    >
                      {{ detail.departmentName }}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
                <mat-error
                  *ngIf="
                    reportIncidentForm.invalid &&
                    reportIncidentForm.get('departmentId').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>

            <!-- Sub department -->
            <div class="flex column" *ngIf="subDepartments.length > 0">
              <mat-label class="field-label">{{
                "AddReportHazard.SubDepartment" | translate
              }}</mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  [title]="'departments'"
                  [placeholder]="'Placeholder.Select' | translate"
                  formControlName="subDepartment"
                >
                  <mat-option
                    *ngFor="let dep of subDepartments"
                    [value]="dep.code"
                  >
                    {{ dep.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="flex column">
              <mat-label class="field-label"
                >{{ "AddReportIncident.Equipment" | translate }}
                <span
                  [ngClass]="
                    reportIncidentForm.invalid &&
                    reportIncidentForm.get('equipement').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf3="ngxMatSelect"
                  [displayMember]="'machineName'"
                  [valueMember]="'id'"
                  [source]="machines"
                  [useInfiniteScroll]="true"
                  [multiple]="false"
                  [hasPermission]="
                    hasCreateMachinesPermission | permissionChecker
                  "
                  [title]="'machines'"
                  [hasSearchBox]="false"
                  [manageRoute]="'/company-setup/machine-equipment'"
                  [placeholder]="'Placeholder.Select' | translate"
                  formControlName="equipement"
                >
                  <mat-option [value]="0">None</mat-option>
                  <mat-option
                    *ngFor="let m of msf3.filteredSource"
                    [value]="m?.id"
                    >{{ m?.machineName }}</mat-option
                  >
                </mat-select>
                <mat-error>
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex column">
              <mat-label class="field-label"
                >{{ "AddReportHazard.Shift" | translate }}
                <span
                  [ngClass]="
                    reportIncidentForm.invalid &&
                    reportIncidentForm.get('shift').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf4="ngxMatSelect"
                  [displayMember]="'shiftTitle'"
                  [valueMember]="'shiftTitle'"
                  [source]="shifts"
                  [useInfiniteScroll]="true"
                  [multiple]="false"
                  [hasPermission]="
                    hasCreateShiftsPermission | permissionChecker
                  "
                  [title]="'shifts'"
                  [hasSearchBox]="false"
                  [manageRoute]="'/company-setup/shifts-setting'"
                  [placeholder]="'AddReportIncident.Select' | translate"
                  formControlName="shift"
                >
                  <ng-container
                    *ngFor="let shift of msf4.filteredSource; let i = index"
                  >
                    <mat-option *ngIf="i < 3" [value]="shift.shiftTitle">
                      {{ shift.shiftTitle }}
                    </mat-option>
                  </ng-container>
                </mat-select>
                <mat-error
                  *ngIf="
                    reportIncidentForm.invalid &&
                    reportIncidentForm.get('shift').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex gap-2 justify-between">
              <div class="flex column flex-grow">
                <mat-label class="field-label"
                  >{{ "AddReportIncident.IncidentDate" | translate }}
                  <span
                    [ngClass]="
                      reportIncidentForm.invalid &&
                      reportIncidentForm
                        .get('incidentDate')
                        .hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field appearance="outline">
                  <input
                    [placeholder]="'Placeholder.SelectDate' | translate"
                    matInput
                    [matDatepicker]="incidentDate"
                    (click)="incidentDate.open()"
                    [max]="minDate"
                    formControlName="incidentDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="incidentDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #incidentDate></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="flex column flex-grow">
                <mat-label class="field-label">
                  Time
                  <span
                    [ngClass]="
                      reportIncidentForm.invalid &&
                      reportIncidentForm
                        .get('incidentTime')
                        .hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <input
                    [format]="24"
                    matInput
                    [ngxMatTimepicker]="incidentTime"
                    [placeholder]="'Placeholder.Select' | translate"
                    formControlName="incidentTime"
                    readonly
                  />
                  <mat-icon
                    matSuffix
                    svgIcon="mat:watch_later"
                    (click)="incidentTime.open()"
                  >
                  </mat-icon>
                  <ngx-mat-timepicker #incidentTime></ngx-mat-timepicker>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="flex-row">
              <mat-label class="field-label"
                >{{ "AddReportIncident.WhatHappened" | translate }}
                <span
                  [ngClass]="
                    reportIncidentForm.invalid &&
                    reportIncidentForm.get('whathappend').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <textarea
                  formControlName="whathappend"
                  matInput
                  rows="5"
                  [placeholder]="'Placeholder.AddText' | translate"
                  matInput
                >
                </textarea>
                <mat-error>
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="flex column">
              <mat-label class="field-label"
                >{{ "AddReportIncident.ActionTaken" | translate }}
                <span
                  [ngClass]="
                    reportIncidentForm.invalid &&
                    reportIncidentForm.get('actionTaken').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <textarea
                  matInput
                  rows="5"
                  formControlName="actionTaken"
                  [placeholder]="'Placeholder.AddText' | translate"
                  matInput
                >
                </textarea>
                <mat-error>
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div>
          <file-upload
            formControlName="attachments"
            [multiple]="true"
            [animation]="true"
          ></file-upload>
        </div>
        <div class="flex flex-wrap gap-2 my-4" *ngIf="images.length > 0">
          <div class="relative" *ngFor="let img of images">
            <div
              class="cursor-pointer bg-red-700 text-white rounded-full absolute right-0 top-0 z-10 flex items-center justify-center"
              style="width: 20px; height: 20px"
              (click)="deleteImage(img)"
            >
              <mat-icon svgIcon="mat:close" class="icon-sm"></mat-icon>
            </div>
            <ngx-avatars
              class="cursor-pointer"
              size="60"
              [src]="getAttachmentImage(img.attachment)"
              [name]="getAttachementTypeName(img.attachment)"
              [textSizeRatio]="2"
              [round]="false"
              [initialsSize]="2"
              [cornerRadius]="10"
              [matTooltip]="img['fileName']"
              [borderColor]="'#131C4E'"
              (clickOnAvatar)="openBigView(img.id)"
            >
            </ngx-avatars>
          </div>
        </div>
      </div>
    </app-expandable-panel>
  </div>
  <div class="mt-5">
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'AddReportIncident.IncidentClassificationReport' | translate"
    >
      <div body class="flex column">
        <div class="flex justify-between flex-wrap gap-5">
          <div class="flex-1">
            <div class="flex column">
              <mat-label class="field-label">{{
                "AddReportIncident.IncidentClassification" | translate
              }}</mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf5="ngxMatSelect"
                  [displayMember]="'name'"
                  [valueMember]="'id'"
                  [source]="incidentClassifications"
                  [useInfiniteScroll]="true"
                  [multiple]="false"
                  [hasPermission]="
                    hasCreateIncidentClassificationsPermission
                      | permissionChecker
                  "
                  [title]="'incident classification'"
                  [hasSearchBox]="false"
                  [manageRoute]="
                    '/reporting-management/incident-classification'
                  "
                  [placeholder]="'Placeholder.Select' | translate"
                  formControlName="incidentClassification"
                >
                  <mat-option
                    *ngFor="let ic of msf5.filteredSource"
                    [value]="ic?.id"
                    >{{ ic?.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="flex-1">
            <div class="flex column">
              <mat-label class="field-label">{{
                "AddReportIncident.IncidentRisk" | translate
              }}</mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <mat-select
                  [placeholder]="'Placeholder.Select' | translate"
                  formControlName="incidentRisk"
                >
                  <mat-option
                    *ngFor="let r of RiskMatrix | enumToArray"
                    [value]="r.value"
                    >{{ "Enum.RiskMatrix." + r.value | translate }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </app-expandable-panel>
  </div>

  <!-- witness -->
  <div class="mt-5">
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'AddReportIncident.Witness' | translate"
    >
      <div body class="flex column">
        <div class="field flex column" [style.width.%]="100">
          <mat-label class="field-label">{{
            "AddReportIncident.Witness" | translate
          }}</mat-label>
          <mat-form-field appearance="outline">
            <mat-select
              ngxMatSelect
              #msf6="ngxMatSelect"
              [displayMember]="'fullName'"
              [valueMember]="'userId'"
              [source]="users"
              [useInfiniteScroll]="true"
              formControlName="witness"
              [hasPermission]="hasCreateUserPermission | permissionChecker"
              [title]="'users'"
              [multiple]="true"
              [manageRoute]="'/user-management/users'"
              [placeholder]="'Placeholder.Select' | translate"
            >
              <mat-option
                [value]="item.userId"
                *ngFor="let item of msf6.filteredSource"
              >
                {{ item.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </app-expandable-panel>
  </div>
  <!-- root cause -->
  <div class="mt-5">
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'AddReportIncident.RootCause' | translate"
    >
      <div body class="flex column">
        <div class="field flex column" [style.width.%]="100">
          <mat-label class="field-label">{{
            "AddReportIncident.RootCause" | translate
          }}</mat-label>
          <mat-form-field appearance="outline">
            <mat-select
              ngxMatSelect
              #msf7="ngxMatSelect"
              [displayMember]="'rootCauseName'"
              [valueMember]="'id'"
              [source]="rootCauses"
              [useInfiniteScroll]="true"
              formControlName="rootCause"
              [hasPermission]="hasRootCauseCreatePermission | permissionChecker"
              [title]="'root cause'"
              [multiple]="false"
              [manageRoute]="'/reporting-management/root-cause'"
              [placeholder]="'Placeholder.Select' | translate"
            >
              <mat-option
                [value]="item.id"
                *ngFor="let item of msf7.filteredSource"
              >
                {{ item.rootCauseName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </app-expandable-panel>
  </div>

  <div
    class="mt-5"
    formArrayName="injuredPersons"
    *ngIf="reportIncidentForm.get('isAnyOneInjured')?.value"
  >
    <div
      class="mb-5"
      *ngFor="let gro of repeatInjuredPersonFormGroup.controls; let i = index"
      [formGroupName]="i"
    >
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="
          ('AddReportIncident.InjuredPerson' | translate) + '(' + (i + 1) + ')'
        "
      >
        <div body class="flex column">
          <div class="flex justify-between flex-wrap gap-3 relative">
            <div class="flex-1">
              <div class="flex column">
                <mat-label class="field-label"
                  >{{ "AddReportIncident.Employed" | translate }}
                </mat-label>
                <mat-radio-group
                  formControlName="isEmployed"
                  class="py-5"
                  color="primary"
                  (change)="employeeTypeChange(i)"
                >
                  <mat-radio-button class="pr-5" value="true"
                    >{{ "Enum.YesOrNo.Yes" | translate }}
                  </mat-radio-button>
                  <mat-radio-button value="false">{{
                    "Enum.YesOrNo.No" | translate
                  }}</mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="flex items-center gap-1 w-full">
                <div
                  class="field flex column flex-grow"
                  *ngIf="
                    reportIncidentForm.get('injuredPersons').value[i]
                      .isEmployed === 'true'
                  "
                >
                  <mat-label class="field-label">{{
                    "AddReportIncident.IdNo" | translate
                  }}</mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      [placeholder]="'Placeholder.AddText' | translate"
                      matInput
                      type="text"
                      formControlName="idNumber"
                    />
                  </mat-form-field>
                </div>
                <div class="field flex column flex-grow">
                  <mat-label class="field-label"
                    >{{ "AddReportIncident.Gender" | translate }}
                    <span
                      [ngClass]="
                        gro.get('gender').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <mat-select
                      [placeholder]="'Placeholder.Select' | translate"
                      formControlName="gender"
                    >
                      <mat-option
                        *ngFor="let g of Gender | enumToArray"
                        [value]="g.value"
                        >{{ g.value }}</mat-option
                      >
                    </mat-select>
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="field flex column flex-grow">
                  <mat-label class="field-label"
                    >{{ "AddReportIncident.Age" | translate }}
                    <span
                      [ngClass]="
                        gro.get('age').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      matInput
                      type="number"
                      [placeholder]="'Placeholder.EnterNumber' | translate"
                      formControlName="age"
                    />
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="flex items-center gap-3">
                <div class="field flex column flex-grow">
                  <mat-label class="field-label"
                    >{{ "AddReportIncident.Name" | translate }}
                    <span
                      [ngClass]="
                        gro.get('name').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      matInput
                      [placeholder]="'Placeholder.AddText' | translate"
                      formControlName="name"
                      *ngIf="
                        reportIncidentForm.get('injuredPersons').value[i]
                          .isEmployed === 'false'
                      "
                    />
                    <mat-select
                      ngxMatSelect
                      #msf7="ngxMatSelect"
                      *ngIf="
                        reportIncidentForm.get('injuredPersons').value[i]
                          .isEmployed === 'true'
                      "
                      [displayMember]="'fullName'"
                      [valueMember]="'userId'"
                      [source]="users"
                      [useInfiniteScroll]="true"
                      formControlName="name"
                      [multiple]="false"
                      [hasPermission]="
                        hasCreateUserPermission | permissionChecker
                      "
                      [title]="'users'"
                      [manageRoute]="'/user-management/users'"
                      (selectionChange)="onChangeUser(i)"
                      [placeholder]="'Placeholder.Select' | translate"
                    >
                      <mat-option
                        [value]="item.userId"
                        *ngFor="let item of msf7.filteredSource"
                      >
                        {{ item.fullName }}
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="field flex column flex-grow">
                  <mat-label class="field-label"
                    >{{ "AddReportIncident.JobTitle" | translate }}
                    <span
                      [ngClass]="
                        gro.get('jobPosition').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      matInput
                      [placeholder]="'Placeholder.AddText' | translate"
                      formControlName="jobPosition"
                      [readonly]="
                        reportIncidentForm.get('injuredPersons').value[i]
                          .isEmployed === 'true'
                      "
                    />
                    <mat-error>
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <!-- Nature Of Injury -->
              <div class="flex column">
                <mat-label class="field-label"
                  >{{ "AddReportIncident.NatureInjury" | translate }}
                  <span
                    [ngClass]="
                      gro.get('natureOfInjury').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <mat-select
                    ngxMatSelect
                    #msf8="ngxMatSelect"
                    [displayMember]="'natureOfInjuiryName'"
                    [valueMember]="'id'"
                    [source]="natureOfInjuries"
                    [useInfiniteScroll]="true"
                    [hasPermission]="
                      hasCreateNatureOfInjuryPermission | permissionChecker
                    "
                    [title]="'nature of injured'"
                    [hasSearchBox]="false"
                    [manageRoute]="'/reporting-management/nature_injury'"
                    [placeholder]="'Placeholder.Select' | translate"
                    formControlName="natureOfInjury"
                    multiple
                  >
                    <mat-option
                      *ngFor="let noi of msf8.filteredSource"
                      [value]="noi?.id"
                      >{{ noi?.natureOfInjuiryName }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <div class="flex justify-flex-start align-flex-center">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>{{ "Errors.Required" | translate }}</span>
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>

              <!-- Equipement -->
              <div class="flex items-center gap-3">
                <div class="flex column flex-grow">
                  <mat-label class="field-label"
                    >{{ "Task.StartDate" | translate }}
                  </mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      [placeholder]="'Placeholder.SelectDate' | translate"
                      matInput
                      [matDatepicker]="startDate"
                      (click)="startDate.open()"
                      [min]="minDate"
                      formControlName="startDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="startDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="flex column flex-grow">
                  <mat-label class="field-label">{{
                    "Task.EndDate" | translate
                  }}</mat-label>
                  <mat-form-field appearance="outline">
                    <input
                      [placeholder]="'Placeholder.SelectDate' | translate"
                      matInput
                      [matDatepicker]="endDate"
                      (click)="endDate.open()"
                      [min]="minDate"
                      formControlName="endDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="endDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="flex column">
                <mat-label class="field-label"
                  >{{ "AddReportIncident.EquipmentUser" | translate }}
                  <span
                    [ngClass]="
                      gro.get('protectiveEquipment').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <mat-select
                    ngxMatSelect
                    #msf9="ngxMatSelect"
                    [displayMember]="'ppeName'"
                    [valueMember]="'id'"
                    [source]="ppes"
                    [useInfiniteScroll]="true"
                    [hasPermission]="
                      hasCreatePpesPermission | permissionChecker
                    "
                    (selectionChange)="getSelectedPpes($event)"
                    [title]="'ppes'"
                    [hasSearchBox]="false"
                    [manageRoute]="'/company-setup/ppe-setting'"
                    [placeholder]="'Placeholder.Select' | translate"
                    formControlName="protectiveEquipment"
                    multiple
                  >
                    <mat-option
                      *ngFor="let eq of msf9.filteredSource"
                      [value]="eq.id"
                      >{{ eq.ppeName }}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <div class="flex justify-flex-start align-flex-center">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>{{ "Errors.Required" | translate }}</span>
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="py-3 flex flex-wrap w-full">
                <div
                  class="flex border border-gray-200 p-1 ms-0 w-2/6 md:w-3/12 rounded-full items-center m-2"
                  *ngFor="let item of selectedPpes"
                >
                  <ngx-avatars
                    class="logo box"
                    size="38"
                    [src]="item?.imageUrl"
                    [name]="item?.ppeName"
                    [textSizeRatio]="2"
                    [round]="true"
                    [initialsSize]="2"
                  ></ngx-avatars>
                  <p class="text-left ps-2">{{ item?.ppeName }}</p>
                </div>
              </div>
            </div>
            <div class="flex-1">
              <app-body-skeleton
                #bodySkeletonComponent
                [frontBodySelected]="
                  reportIncidentForm.get('injuredPersons').value[i]
                    .frontSkeletonParts
                "
                [backBodySelected]="
                  reportIncidentForm.get('injuredPersons').value[i]
                    .backSkeletonParts
                "
                [viewMode]="false"
              ></app-body-skeleton>
            </div>
          </div>

          <div class="flex justify-between items-center">
            <button
              (click)="addInjuredPersonRow()"
              class="my-4 flex-none"
              color="primary"
              mat-mini-fab
              [matTooltip]="'General.Buttons.AddNew' | translate"
              type="button"
            >
              <mat-icon svgIcon="mat:add"></mat-icon>
            </button>
            <button
              (click)="removeInjuredPersonRow(i)"
              class="my-4 flex-none"
              color="warn"
              mat-mini-fab
              [matTooltip]="'General.Buttons.Remove' | translate"
              type="button"
              *ngIf="reportIncidentForm.get('injuredPersons').value?.length > 1"
            >
              <mat-icon svgIcon="mat:delete"></mat-icon>
            </button>
          </div>
        </div>
      </app-expandable-panel>
    </div>
  </div>
</form>
