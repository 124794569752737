import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';
import { PostContacts, UpdateContacts, GetContactsList } from 'src/backend/models/emergency-contacts/emergency-contacts.model';

@Injectable({
  providedIn: 'root'
})
export class EmergencyContactsService {
  netApiUrl: string = "";
  constructor(private http: HttpClient, private baseApi: BaseApi) { }

  postEmergecnyContact(data: PostContacts): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_EMERGENCY_CONTACTS_CREATE), data);
  }

  UpdateEmergecnyContact(data: UpdateContacts): Observable<any> {
    return this.http.put<any>(this.baseApi.encodeURL(Constants.API_EMERGENCY_CONTACTS_UPDATE), data);
  }

  getEmergencyContactsList(): Observable<GetContactsList[]> {
    return this.http.get<GetContactsList[]>(this.baseApi.encodeURL(Constants.API_EMERGENCY_CONTACTS_GET_ALL));
  }

  deActivate(id: number): Observable<any> {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_EMERGENCY_CONTACTS_DEACTIVATE + '/' + id));
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_EMERGENCY_CONTACTS_ACTIVATE + '/' + id));
  }

  deleteEmergencyContact(id: number): Observable<any> {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_EMERGENCY_CONTACTS_DELETE + '/' + id));
  }

}
