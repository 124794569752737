<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "ActionsTracker.Title" | translate }}</span>
    </h2>
    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'Placeholder.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>
  </div>
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "ActionsTracker.Table." + column.label | translate }}&nbsp;
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date' && column.property !== 'updatedOn'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "ActionsTracker.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "mediumDate" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date' && column.property === 'updatedOn'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "ActionsTracker.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <span
              *ngIf="
                row?.actionStatus !== ActionStatus.INPROGRESS &&
                row?.actionStatus !== ActionStatus.OVERDUE &&
                row?.actionStatus !== ActionStatus.DUE
              "
            >
              {{ row[column.property] | date : "mediumDate" }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.type === 'boolean' && column.property === 'actionStatus'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "User.Table." + column.label | translate }}
              <mat-select
                [(ngModel)]="selectedStatus"
                (selectionChange)="onSelectStatus($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of ActionStatus | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.ActionStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <span>
                {{ "Enum.ActionStatus." + row[column.property] | translate }}
              </span>
            </div>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'priority'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{
                "AddReportIncident.ReportActions.Table." + column.label
                  | translate
              }}
              <mat-select
                [(ngModel)]="selectedPriority"
                (selectionChange)="onSelectPriority($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of ActionPriority | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.ActionPriority." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div class="flex priority" [attr.id]="row[column.property]">
              <span
                [matTooltip]="
                  'Enum.ActionPriority.' + row[column.property] | translate
                "
                >{{
                  "Enum.ActionPriority." + row[column.property] | translate
                }}</span
              >
            </div>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'reportType'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "ActionsTracker.Table." + column.label | translate }}
              <mat-select
                [(ngModel)]="selectedReportingType"
                (selectionChange)="onSelectReportingType($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of ReportingType | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.ReportingType." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            mat-cell
            (click)="navigateToSource(row?.sourceId, row?.reportType)"
          >
            <p class="source">
              {{ "Enum.ReportingType." + row[column.property] | translate }}
            </p>
          </td>
        </ng-container>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th *matHeaderCellDef mat-header-cell></th>

        <td *matCellDef="let row" mat-cell>
          <div class="flex" (click)="$event.stopPropagation()">
            <app-action-button
              *ngIf="
                row['actionStatus'] !== ActionStatus.CLOSE &&
                row['assignToId'] === loggedUser?.userId &&
                (hasReadPermissions | permissionChecker)
              "
              [btnBackgroundClass]="'bg-red-100'"
              [btnTextClass]="'text-red-700'"
              [buttonToolTip]="'Close'"
              [buttonIcon]="'close'"
              (onClick)="openCloseModal($event, row?.id)"
            >
            </app-action-button>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewAction(row)"
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [class.hidden]="dataSource.filteredData.length === 0"
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-icon
      class="text-[#757575] me-1"
      *ngIf="!column.visible"
      svgIcon="mat:check_box_outline_blank"
    ></mat-icon>
    <mat-icon
      *ngIf="column.visible"
      class="text-primary me-1"
      svgIcon="mat:check_box"
    ></mat-icon>
    {{ "ActionsTracker.Table." + column.label | translate }}
  </button>
</mat-menu>

<ng-template #closeModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">Close Action</h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="closeForm">
      <div class="flex-row">
        <mat-label class="field-label">Remarks</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <textarea
            matInput
            rows="5"
            formControlName="remarks"
            [placeholder]="'enter remarks'"
            matInput
          >
          </textarea>
        </mat-form-field>
      </div>
      <div class="flex column">
        <file-upload
          formControlName="media"
          [multiple]="false"
          [animation]="true"
        ></file-upload>
      </div>
    </form>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="!closeForm.valid"
      (onClick)="closeAction()"
    ></app-basic-button>
  </div>
</ng-template>
