import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root-cause-page',
  templateUrl: './root-cause-page.component.html',
  styleUrls: ['./root-cause-page.component.scss']
})
export class RootCausePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
