import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseApi } from 'src/backend/api/base-api';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import {
  GetMeetingRecurrence,
  GetTaskRecurrenceById,
  PostNewTask,
  TaskList,
  UpdateSingleTask,
  UpdateTask,
} from 'src/backend/models/task-tracker/task-tracker.model';
import { GetTaskTrackerViewhistory } from 'src/app/task-tracker/models/view-task-tracker-model';

@Injectable({
  providedIn: 'root',
})
export class TaskTrackerService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getTaskByUser(data: any): Observable<DataMapper<TaskList>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.orgStatus) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.orgStatus;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.departments) {
      if (params.length > 0) {
        params += '&';
      }
      params += `departments=` + data?.departments;
    }

    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<TaskList>>(
      this.baseApi.encodeURL(Constants.API_GET_TASK_BY_USER + '?' + params)
    );
  }

  PostNewTask(data: PostNewTask[]): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_POST_NEW_TASK),
      data
    );
  }
  updateSingleTask(data: UpdateSingleTask): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_SINGLE_TASK),
      data
    );
  }

  updateSeriesTask(data: UpdateTask[]): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_SERIES_TASK),
      data
    );
  }
  GetTaskRecurrence(): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_MEETING_RECURRENCE),
      {}
    );
  }

  GetTaskRecurrenceWithFilter(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_MEETING_RECURRENCE_WITH_FILTER),
      data
    );
  }

  GetTaskRecurrenceById(data: GetTaskRecurrenceById): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_TASK_BY_ID),
      data
    );
  }

  getTaskHistory(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_TASK_HISTORY + '/' + id)
    );
  }

  CheckTaskExecution(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_CHECK_EXECUTION + '/' + id)
    );
  }

  executeChecklist(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_EXECUTE_CHECKLIST_DATA),
      data
    );
  }
  submitTaskStatus(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_SUBMIT_TASK + '/' + id)
    );
  }
  actionTaskStatus(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ACTION_TASK + '/' + id)
    );
  }
  rejectTaskStatus(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REJECT_TASK),
      data
    );
  }

  notifyToAssignee(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_NOTIFYTOASSIGNEE_TASK + '/' + id)
    );
  }
  closeTask(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CLOSE_TASK),
      data
    );
  }

  removeTask(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_DELETE_TASK + '/' + id)
    );
  }
  removeTaskSeries(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_DELETE_TASK_SERIES + '/' + id)
    );
  }

  rescheduleTask(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_RESCHEDULE_TASK),
      data
    );
  }
}
