<div class="card overflow-auto my-3">
    <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
      <h2
        class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
      >
        <span>{{ "organization.Organizations" | translate }}</span>
      </h2>
      <div
        class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
      >
        <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
        <input
          [formControl]="searchCtrl"
          class="px-4 py-2 border-0 outline-none w-full bg-transparent"
          [placeholder]="'General.Search' | translate"
          type="search"
        />
      </div>
  
      <span class="flex-1"></span>
  
      <button
        [matMenuTriggerFor]="columnFilterMenu"
        class="ml-4 flex-none"
        mat-icon-button
        [matTooltip]="'General.Buttons.FilterColumns' | translate"
        type="button"
      >
        <mat-icon svgIcon="mat:filter_list"></mat-icon>
      </button>
  
    </div>
    <div></div>
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="loadingLabel | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
        <!-- Image Column -->
        <ng-container matColumnDef="organizationImage">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <ngx-avatars
              class="logo box"
              size="32"
              [src]="row['organizationImage']"
              [name]="row['organizationName']"
              [textSizeRatio]="2"
              [round]="true"
              [initialsSize]="2"
            ></ngx-avatars>
          </td>
        </ng-container>
        <!-- features icons Column -->
        <ng-container matColumnDef="features">
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            {{ "organization.Table.Features" | translate }}
          </th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <div class="flex items-center flex-wrap" style="width: 200px">
              <img
                width="30"
                height="30"
                class="p-1 transition-all duration-500 hover:scale-125"
                *ngFor="let f of row['features']"
                src="assets/img/resources/{{ f.featureIcon }}"
                [alt]="'Feature.' + f.featureName | translate"
                [matTooltip]="'Feature.' + f.featureName | translate"
              />
            </div>
          </td>
        </ng-container>
        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container
            *ngIf="column.type === 'text'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "organization.Table." + column.label | translate }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] }}
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'date'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "organization.Table." + column.label | translate }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] | date : "dd-MM-yyyy" }}
            </td>
          </ng-container>
          <!-- <ng-container
            *ngIf="column.type === 'boolean' && column.property === 'status'"
            [matColumnDef]="column.property"
          >
            <th *matHeaderCellDef class="uppercase" mat-header-cell>
              <div
                style="width: 100%; position: relative"
                class="flex items-center"
              >
                <mat-icon
                  app-click-stop-propagation
                  style="width: 35px; height: 35px; cursor: pointer"
                  (click)="select.open()"
                  svgIcon="mat:keyboard_arrow_down"
                  color="primary"
                ></mat-icon>
                {{ "organization.Table." + column.label | translate }}
                <mat-select
                  [value]="selectedOrganizationStatus"
                  (selectionChange)="onSelectOrgStatus($event)"
                  #select
                  class="select-options"
                  multiple
                >
                  <mat-option
                    *ngFor="let statue of OrganizationStatuses | enumToArray"
                    [value]="statue.value"
                  >
                    {{ "Enum.CompanyUserStatus." + statue.value | translate }}
                  </mat-option>
                </mat-select>
              </div>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <app-boolean-view
                [value]="row[column.property] === 'ACTIVE'"
                [text]="
                  row[column.property] === 'ACTIVE'
                    ? ('General.Active' | translate)
                    : ('General.Inactive' | translate)
                "
              >
              </app-boolean-view>
            </td>
          </ng-container> -->
        </ng-container>
  
        <!-- Actions Column -->
        <ng-container matColumnDef="actions">
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            {{ "organization.Table.Actions" | translate }}
          </th>
          <td *matCellDef="let row" mat-cell>
              <div (click)="$event.stopPropagation()">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon svgIcon="mat:more_vert"></mat-icon>
                  </button>
                  <mat-menu
                    #menu="matMenu"
                    yPosition="below"
                    xPosition="before"
                    class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
                  >
                    <div
                      mat-menu-item
                      class="flex items-center justify-between p-1 mat-div"
                      (click)="viewCompany($event, row?.organizationCode)"
                    >
                      <button>
                        {{ "General.Buttons.View" | translate }}
                      </button>
                      <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                    </div>
                    <div
                      mat-menu-item
                      class="flex items-center justify-between p-1 mat-div"
                      (click)="updateCompany($event, row?.id)"
                    >
                      <button>
                        {{ "General.Buttons.Edit" | translate }}
                      </button>
                      <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                    </div>
                  </mat-menu>
                </div>
      
            <!-- <div class="flex">
              <app-action-button
                [btnBackgroundClass]="
                  row?.status === 'ACTIVE' ? 'bg-green-100' : 'bg-red-100'
                "
                [btnTextClass]="
                  row?.status === 'ACTIVE' ? 'text-green' : 'text-red'
                "
                [buttonToolTip]="
                  (row?.status === 'ACTIVE'
                    ? 'General.Buttons.Deactivate'
                    : 'General.Buttons.Activate'
                  ) | translate
                "
                [buttonIcon]="row?.status === 'ACTIVE' ? 'person' : 'person_off'"
                (onClick)="
                  row?.status === 'ACTIVE'
                    ? deactivateOrganization($event, row?.id)
                    : activateOrganization($event, row?.id)
                "
              >
              </app-action-button>
              <app-action-button
                [btnBackgroundClass]="'bg-yellow-100'"
                [btnTextClass]="'text-yellow-700'"
                [buttonToolTip]="'Edit'"
                [buttonIcon]="'edit'"
                (onClick)="updateOrganization($event, row?.id)"
              >
              </app-action-button>
              <app-action-button
                [btnBackgroundClass]="'bg-indigo-100'"
                [btnTextClass]="'text-indigo-700'"
                [buttonToolTip]="'View'"
                [buttonIcon]="'visibility'"
                (onClick)="viewOrganization($event, row?.organizationCode)"
              ></app-action-button>
            </div> -->
          </td>
        </ng-container>
  
        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: visibleColumns"
          @fadeInUp
          class="hover:bg-hover trans-ease-out cursor-pointer"
          mat-row
          (click)="viewCompany($event, row?.organizationCode)"
  
        ></tr>
      </table>
      <div
        *ngIf="dataSource.filteredData.length === 0"
        @scaleFadeIn
        class="flex-auto flex flex-col items-center justify-center"
      >
        <img src="assets/img/resources/empty-state.svg" alt="not found" />
        <h2 class="headline m-0 text-center text-primary">
          {{ "General.EmptyTableList" | translate }}...
        </h2>
      </div>
  
      <mat-paginator
        [class.hidden]="dataSource.filteredData.length === 0"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize"
        showFirstLastButtons
        class="sticky left-0"
      ></mat-paginator>
    </app-state-section>
  </div>
  <mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
    <button
      (click)="toggleColumnVisibility(column, $event)"
      *ngFor="let column of columns"
      class="checkbox-item mat-menu-item"
    >
      <mat-checkbox
        (click)="$event.stopPropagation()"
        [(ngModel)]="column.visible"
        color="primary"
      >
        {{ "organization.Table." + column.label | translate }}
      </mat-checkbox>
    </button>
  </mat-menu>