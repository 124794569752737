<app-state-section
  body
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="loadingLabel | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <div class="wrapper">
    <div class="flex justify-end">
      <button
        class="ml-4"
        color="primary"
        mat-mini-fab
        [matTooltip]="'Dashboards.EditDashboards' | translate"
        type="button"
        [matMenuTriggerFor]="menu"
        style="border-radius: 10px"
        (menuClosed)="saveVisibility()"
      >
        <mat-icon svgIcon="mat:edit"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div
          class="flex justify-between edit-list-item"
          mat-menu-item
          (click)="$event.stopPropagation()"
          *ngFor="let item of allDashboards"
        >
          <span>{{ "Dashboards." + item.dashboardName | translate }}</span>
          <mat-slide-toggle
            class="edit-list-toggle"
            color="primary"
            [checked]="item.isVisible"
            (change)="changeDashboardVisibility($event, item.dashboardName)"
          ></mat-slide-toggle>
        </div>
      </mat-menu>
    </div>

    <!-- dashboards card -->
    <mat-grid-list
      *ngIf="visibleDashboards.length > 0; else noDataTemblate"
      cols="{{ columns }}"
      rowHeight="450px"
      gutterSize="10px"
    >
      <mat-grid-tile
        class="card-style ltr:mr-10 rtl:ml-10"
        *ngFor="let dashboard of visibleDashboards"
      >
        <app-dashboard-card-two
          class="card"
          [dashboardSetting]="dashboard"
        ></app-dashboard-card-two>
      </mat-grid-tile>
    </mat-grid-list>

    <ng-template #noDataTemblate>
      <div class="flex no-data">
        <div class="flex column">
          <img
            style="width: fit-content; margin: auto"
            src="assets/img/no-dashboards.png"
          />
          <h1 style="color: #00000099; font-size: large">
            This is the where you'll find your dashboards, start using feature
            to see data reflected here.
          </h1>
        </div>
      </div>
    </ng-template>
  </div>
</app-state-section>
