import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApi } from 'src/backend/api/base-api';
import { Constants } from 'src/@hodhod/common/constants';

@Injectable({
  providedIn: 'root',
})
export class ChatbotService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  initializeChatSession(): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_INITIALIZE_CHAT_SESSION),
      {}
    );
  }

  processChatMessage(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_INITIALIZE_CHAT_MESSAGE),
      data
    );
  }

  saveUserFeedBack(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_SAVE_USER_FEEDBACK),
      data
    );
  }

  getLinks(): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_TRUSTED_LINKS),
      {}
    );
  }
  getDocuments(): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_CHATBOT_DOCUMENTS),
      {}
    );
  }

  uploadDocuments(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_UPLOAD_CHATBOT_DOCUMENT),
      data
    );
  }
  addLinks(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ADD_TRUSTED_LINKS),
      data
    );
  }

  downloadDocument(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_DOWNLOAD_CHATBOT_DOCUMENT),
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/octet-stream',
        },
        observe: 'body',
        responseType: 'blob' as 'json',
      }
    );
  }

  deleteDocument(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_DELETE_CHATBOT_DOCUMENT),
      data
    );
  }

  deleteAllDocuments(): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_DELETE_ALL_CHATBOT_DOCUMENTS),
      {}
    );
  }
  deleteAllLinks(): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REMOVE_ALL_TRUSTED_LINKS),
      {}
    );
  }
  deleteLink(data:any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REMOVE_TRUSTED_LINKS),
      data
    );
  }
  Reindex(): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REINDEX),
      {}
    );
  }

}
