<form [formGroup]="searchForm">
  <div class="violation-db-pannel">
    <div class="chart-header">
      <p style="width: 85%;">{{'VideoAnalyticsDashboard.' + data?.customTitle | translate}}</p>
      <div style="width: 15%; display: flex; justify-content: center;">
        <!-- <mat-icon *ngIf="data?.customTitle !== 'Monthly Violations Avg'" style="transform: scale(0.8);
                  margin-top: 14px;" svgIcon="mat:alarm_on"></mat-icon> -->
        <!-- <mat-icon style="transform: scale(0.8);" (click)="expand()">fullscreen</mat-icon> -->
        <mat-form-field trigger="alarmTrigger" *ngIf="data?.customTitle !== 'Monthly Violations Avg'" class="icon-field"
          appearance="outline">
          <mat-select trigger="alarmTrigger" #alarmSelect formControlName="menu"
            (selectionChange)="onChangeCalenderDD()">
            <mat-option *ngFor="let item of calenderMenu" [value]="item.name">
              {{'VideoAnalyticsDashboard.' + item.name | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>
    <div class="chart-body">


      <div *ngIf="data" [chart]="chart" class="test"></div>


    </div>
  </div>
</form>



<ng-template #RangePicker>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">Date Range</h2>
    <button class="text-secondary" (click)="closeDialog()" mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="searchForm" content>
      <div class="flex items-center gap-3">
        <div class="flex column flex-grow">
          <mat-label class="field-label">{{ "Task.StartDate" | translate }}</mat-label>
          <mat-form-field appearance="outline">
            <input [placeholder]="'Placeholder.SelectDate' | translate" matInput [matDatepicker]="startDate"
              (click)="startDate.open()" formControlName="start">
            <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
            <mat-datepicker #startDate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="flex column flex-grow">
          <mat-label class="field-label">{{ "Task.EndDate" | translate }}</mat-label>
          <mat-form-field appearance="outline">
            <input [placeholder]="'Placeholder.SelectDate' | translate" matInput [matDatepicker]="endDate"
              (click)="endDate.open()" formControlName="end">
            <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
            <mat-datepicker #endDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button [btnType]="'Cancel'" [btnText]="'Cancel'" (onClick)="closeDialog()"></app-basic-button>
    <app-basic-button [btnType]="'Add'" [btnText]="'Submit'" (onClick)="onChangeStartDate()"></app-basic-button>
  </div>
</ng-template>