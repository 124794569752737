export interface VoilationInfo{
    id: number;
    cameraId: number;
    cameraName: string;
    cameraPosition: string;
    departmentName: string;
    picture: string;
    timeDate: Date;
    status: string;
    voilationName: string;
    offenderName: string;
    offenderNature: string;
    isGuestOffender: boolean;
    closerId: string;
}

export class ChartDataColumn {
    public isParetoLine = false;
    public hide = false;
    constructor(
      public id: string,
      public label: string,
      public data: number[],
      public borderColor?: string,
      public backgroundColor?: string,
      public pointBackgroundColor?: string,
      public hoverBackgroundColor?: string,
      public isPieCenter: boolean = false,
    ) {}
  }
  