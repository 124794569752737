<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="
    (formMode === 'create'
      ? 'Role.AddingLoadingRole'
      : 'Role.UpdatingLoadingRole'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="rootCauseForm">
    <div class="flex items-center" mat-dialog-title>
      <h2
        *ngIf="rootCauseForm.get('rootCauseName').value"
        class="headline m-0 flex-auto"
      >
        {{ rootCauseForm.get("rootCauseName").value }}
      </h2>
      <h2
        *ngIf="!rootCauseForm.get('rootCauseName').value"
        class="headline m-0 flex-auto"
      >
        {{ "RootCause.AddRootCause" | translate }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex flex-col flex-wrap justify-between">
      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "RootCause.RootCauseName" | translate }}
          <span
            [ngClass]="
              rootCauseForm.invalid &&
              rootCauseForm.get('rootCauseName').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            [readonly]="this.formMode === 'view'"
            formControlName="rootCauseName"
          />
          <mat-error
            *ngIf="
              rootCauseForm.invalid &&
              rootCauseForm.get('rootCauseName').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              rootCauseForm.invalid &&
              rootCauseForm.get('rootCauseName').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "Reason.ReportingType" | translate }}
          <span
            [ngClass]="
              rootCauseForm.invalid &&
              rootCauseForm.get('reportingTypes').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>

        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            formControlName="reportingTypes"
            [disabled]="this.formMode === 'view'"
            [placeholder]="'LossMode.SelectReportingType' | translate"
            multiple
          >
            <mat-option (click)="selectedvalue($event)" [value]="'ALL'"
              >{{ "Enum.ReportingTypes.ALL" | translate }}
            </mat-option>
            <mat-option
              (click)="selectAll($event)"
              *ngFor="let r of reporting"
              [value]="r"
              >{{ "Enum.ReportingTypes." + r | translate }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              rootCauseForm.invalid &&
              rootCauseForm.get('reportingTypes').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <!-- History -->
    <div class="mt-4" *ngIf="this.formMode === 'view'">
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'RootCause.RootCauseHistory' | translate"
      >
        <app-history-log
          body
          *ngIf="this.defaults.root"
          [sourceId]="this.defaults.root.id"
          [sourceType]="HistoryType"
        ></app-history-log>
      </app-expandable-panel>
    </div>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isCreateMode()"
          [btnType]="'Add'"
          [btnText]="'Add'"
          [isDisabled]="!rootCauseForm.valid"
          (onClick)="save()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isUpdateMode()"
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="!rootCauseForm.valid"
          (onClick)="save()"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
