<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.Machines' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluif">
    <div>
      <div class="flex justify-end mr-1 mb-4">
        <app-basic-button
          [btnType]="'Add text-lg'"
          [btnText]="'Submit'"
          [isDisabled]="enableSubmit === false"
          (onClick)="createMachineParameter()"
        ></app-basic-button>
      </div>
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'Machine.NewMachineParameter' | translate"
      >
        <form body [formGroup]="machineForm" class="flex column">
          <div class="flex column mb-5">
            <mat-label class="field-label">{{
              "Machine.ParameterControl" | translate
            }}</mat-label>
            <mat-radio-group
              (change)="statusChange($event)"
              formControlName="parameterControl"
              class="flex flex-row gap-x-10 mt-4"
            >
              <mat-radio-button
                *ngFor="let type of machineType | enumToArray"
                [value]="type.value"
                >{{ "Machine." + type.value | translate }}</mat-radio-button
              >
            </mat-radio-group>
          </div>
          <div class="flex justify-between flex-wrap-reverse">
            <div class="flex-1 pr-5">
              <div class="flex column">
                <mat-label class="field-label"
                  >{{ "Machine.ParameterName" | translate }}
                  <span
                    [ngClass]="
                      machineForm.invalid &&
                      machineForm.get('pName').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <input
                    [placeholder]="'Placeholder.Insert' | translate"
                    formControlName="pName"
                    matInput
                    type="text"
                  />
                  <mat-error>
                    <div class="flex justify-flex-start align-flex-center">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>{{ "Errors.Required" | translate }}</span>
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="flex column" *ngIf="showParameterPanel">
                <mat-label class="field-label"
                  >{{ "Machine.ValueType" | translate }}
                  <span
                    [ngClass]="
                      machineForm.invalid &&
                      machineForm.get('valueType').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <mat-select
                    ngxMatSelect
                    #msf1="ngxMatSelect"
                    [displayMember]="'value'"
                    [valueMember]="'id'"
                    [source]="valueTypes"
                    [useInfiniteScroll]="true"
                    [multiple]="false"
                    [hasPermission]="
                      hasCreateValueTypesPermission | permissionChecker
                    "
                    [hasSearchBox]="false"
                    [title]="'value types'"
                    [placeholder]="'Placeholder.Select' | translate"
                    formControlName="valueType"
                    [manageRoute]="'/company-setup/value-types'"
                    [disabled]="false"
                  >
                    <mat-option
                      *ngFor="let t of msf1.filteredSource"
                      [value]="t.id"
                      >{{ t.value }}</mat-option
                    >
                  </mat-select>
                  <mat-error>
                    <div class="flex justify-flex-start align-flex-center">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>{{ "Errors.Required" | translate }}</span>
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="flex-1">
              <div class="flex column" *ngIf="showParameterPanel">
                <mat-label class="field-label"
                  >{{ "Machine.UnitOfMeasurement" | translate }}
                  <span
                    [ngClass]="
                      machineForm.invalid &&
                      machineForm.get('unitOfMeasurement').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <mat-select
                    ngxMatSelect
                    #msf2="ngxMatSelect"
                    [displayMember]="'unit'"
                    [valueMember]="'id'"
                    [source]="unitOfMeasures"
                    [useInfiniteScroll]="true"
                    [multiple]="false"
                    [hasPermission]="
                      hasCreateUnitsPermission | permissionChecker
                    "
                    [title]="'unit of messurement'"
                    [hasSearchBox]="false"
                    [placeholder]="'Placeholder.Select' | translate"
                    formControlName="unitOfMeasurement"
                    [manageRoute]="'/company-setup/unit-of-measures'"
                    [disabled]="false"
                  >
                    <mat-option
                      *ngFor="let u of msf2.filteredSource"
                      [value]="u.id"
                      >{{ u.unit }}</mat-option
                    >
                  </mat-select>
                  <mat-error>
                    <div class="flex justify-flex-start align-flex-center">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>{{ "Errors.Required" | translate }}</span>
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div
            class="flex justify-between flex-wrap-reverse"
            *ngIf="!showParameterPanel"
          >
            <div class="flex-1 pr-5">
              <div class="flex column">
                <mat-label class="field-label"
                  >{{ "Machine.IdealValue" | translate }}
                  <span
                    [ngClass]="
                      machineForm.invalid &&
                      machineForm.get('idealValue').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <input
                    [placeholder]="'Placeholder.Insert' | translate"
                    formControlName="idealValue"
                    matInput
                    type="text"
                  />
                  <mat-error>
                    <div class="flex justify-flex-start align-flex-center">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>{{ "Errors.Required" | translate }}</span>
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="flex-1">
              <mat-label class="field-label"
                >{{ "Machine.NonIdealValue" | translate }}
                <span
                  [ngClass]="
                    machineForm.invalid &&
                    machineForm.get('nonIdealValue').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  [placeholder]="'Placeholder.Insert' | translate"
                  formControlName="nonIdealValue"
                  matInput
                  type="text"
                />
                <mat-error>
                  <div class="flex justify-flex-start align-flex-center">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="mb-3">
            <p>
              <mat-checkbox color="primary" formControlName="notification">{{
                "Machine.RecivedNotificationOnEmail" | translate
              }}</mat-checkbox>
            </p>
          </div>
          <div
            *ngIf="showParameterPanel"
            class="flex justify-between flex-wrap-reverse"
          >
            <div class="flex-1 pr-5">
              <div class="flex-row flex gap-x-7">
                <div class="flex column w-full">
                  <mat-label class="field-label text-red-500"
                    >{{ "Machine.LowerSpecificationLimit" | translate }}
                    <span
                      [ngClass]="
                        machineForm.invalid &&
                        machineForm
                          .get('lowerSpecificationLimit')
                          .hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      type="number"
                      [placeholder]="'Placeholder.Insert' | translate"
                      formControlName="lowerSpecificationLimit"
                      matInput
                      (change)="checkLimit()"
                    />
                    <mat-error
                      *ngIf="
                        machineForm
                          .get('lowerSpecificationLimit')
                          .hasError('max')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Machine.hints.LSL" | translate }}</span>
                      </div>
                    </mat-error>
                    <mat-hint></mat-hint>
                  </mat-form-field>
                </div>
                <div class="flex column w-full">
                  <mat-label class="field-label text-yellow-400"
                    >{{ "Machine.LowerControlLimit" | translate }}
                    <span
                      [ngClass]="
                        machineForm.invalid &&
                        machineForm
                          .get('lowerControlLimit')
                          .hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      type="number"
                      [placeholder]="'Placeholder.Insert' | translate"
                      formControlName="lowerControlLimit"
                      matInput
                      (change)="checkLimit()"
                    />
                    <mat-error
                      *ngIf="
                        machineForm.get('lowerControlLimit').hasError('max')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Machine.hints.LCL" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="flex column w-full">
                  <mat-label class="field-label text-yellow-400"
                    >{{ "Machine.UpperControlLimit" | translate }}
                    <span
                      [ngClass]="
                        machineForm.invalid &&
                        machineForm
                          .get('upperControlLimit')
                          .hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      type="number"
                      (change)="checkLimit()"
                      [placeholder]="'Placeholder.Insert' | translate"
                      formControlName="upperControlLimit"
                      matInput
                    />
                    <mat-error
                      *ngIf="
                        machineForm.get('upperControlLimit').hasError('min')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Machine.hints.UCL" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="flex column w-full">
                  <mat-label class="field-label text-red-500"
                    >{{ "Machine.UpperSpecificationLimit" | translate }}
                    <span
                      [ngClass]="
                        machineForm.invalid &&
                        machineForm
                          .get('upperSpecificationLimit')
                          .hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      type="number"
                      [placeholder]="'Placeholder.Insert' | translate"
                      (change)="checkLimit()"
                      formControlName="upperSpecificationLimit"
                      matInput
                    />
                    <mat-error
                      *ngIf="
                        machineForm
                          .get('upperSpecificationLimit')
                          .hasError('min')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Machine.hints.USL" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="flex column w-full">
                  <mat-label class="field-label text-lime-500"
                    >{{ "Machine.Target" | translate }}
                    <span
                      [ngClass]="
                        machineForm.invalid &&
                        machineForm.get('target').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      type="number"
                      min="1"
                      oninput="validity.valid||(value='');"
                      [placeholder]="'Placeholder.Insert' | translate"
                      formControlName="target"
                      matInput
                    />
                  </mat-form-field>
                </div>

                <!-- <div class="flex-row flex gap-x-7">


                  <div class="flex column w-full">
                  <mat-label class="field-label">{{'Machine.LowerWarningLimit' | translate}}</mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input type="number" [placeholder]="'Placeholder.Insert' | translate"
                      formControlName="lowerWarningLimit" matInput />
                  </mat-form-field>
                </div>

                </div> -->
                <!-- <div class="flex-row flex gap-x-7">
                <div class="flex column w-full">
                  <mat-label class="field-label">{{'Machine.UpperWarningLimit' | translate}}</mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input type="number" [placeholder]="'Placeholder.Insert' | translate"
                      formControlName="upperWarningLimit" matInput />
                  </mat-form-field>
                </div>

              </div> -->
                <!-- <div class="flex column">
                <mat-label class="field-label">{{'Machine.UpperRangeLimit' | translate}}</mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <input type="number" [placeholder]="'Placeholder.Insert' | translate"
                    formControlName="upperRangeLimit" matInput />
                </mat-form-field>
              </div> -->
              </div>
              <!-- <div class="flex-1">
              <div class="flex-row flex gap-x-7">
                <div class="flex column w-full">
                  <mat-label class="field-label">{{'Machine.LowerRangeLimit' | translate}}</mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input type="number" [placeholder]="'Placeholder.Insert' | translate"
                      formControlName="lowerRangeLimit" matInput />
                  </mat-form-field>
                </div>


              </div>


              </div> -->
            </div>
          </div>
          <div class="flex justify-end mr-1 mb-4">
            <app-basic-button
              [btnType]="'Add text-lg'"
              [btnText]="'Add'"
              [isDisabled]="!machineForm.valid"
              (onClick)="addParameterTempTable()"
            ></app-basic-button>
          </div>
          <div
            class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center"
          >
            <h2
              class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
            >
              <span>{{ "Parameters.Title" | translate }}</span>
            </h2>
            <span class="flex-1"></span>

            <button
              [matMenuTriggerFor]="columnFilterMenu"
              class="ml-4 flex-none"
              mat-icon-button
              [matTooltip]="'General.Buttons.FilterColumns' | translate"
              type="button"
            >
              <mat-icon svgIcon="mat:filter_list"></mat-icon>
            </button>
          </div>
          <div class="overflow-auto">
            <table [dataSource]="dataSource" class="w-full" mat-table matSort>
              <!-- Text Columns -->
              <ng-container
                *ngFor="let column of columns; trackBy: trackByProperty"
              >
                <ng-container
                  *ngIf="column.type === 'text'"
                  [matColumnDef]="column.property"
                >
                  <th
                    *matHeaderCellDef
                    class="uppercase"
                    mat-header-cell
                    mat-sort-header
                  >
                    {{ "Parameters.Table." + column.label | translate }}
                  </th>
                  <td
                    *matCellDef="let row"
                    [ngClass]="column.cssClasses"
                    mat-cell
                  >
                    {{ row[column.property] }}
                    <mat-icon
                      *ngIf="column.property === 'fullName' && row?.isVerified"
                      svgIcon="mat:verified"
                      class="text-blue-700"
                      style="height: 15px; width: 15px"
                    ></mat-icon>
                  </td>
                </ng-container>
                <ng-container
                  *ngIf="column.type === 'date'"
                  [matColumnDef]="column.property"
                >
                  <th
                    *matHeaderCellDef
                    class="uppercase"
                    mat-header-cell
                    mat-sort-header
                  >
                    {{ "Parameters.Table." + column.label | translate }}
                  </th>
                  <td
                    *matCellDef="let row"
                    [ngClass]="column.cssClasses"
                    mat-cell
                  >
                    {{ row[column.property] | date : "dd-MM-yyyy" }}
                  </td>
                </ng-container>
                <ng-container
                  *ngIf="
                    column.type === 'boolean' &&
                    column.property === 'parameterType'
                  "
                  [matColumnDef]="column.property"
                >
                  <th *matHeaderCellDef class="uppercase" mat-header-cell>
                    {{ "Machine.Table." + column.label | translate }}
                  </th>
                  <td
                    *matCellDef="let row"
                    [ngClass]="column.cssClasses"
                    mat-cell
                  >
                    <app-boolean-view
                      [trueColor]="'#44ce5029'"
                      [falseColor]="'#ff595929'"
                      [trueTextColor]="'#ffffff'"
                      [falseTextColor]="'#534A46'"
                      [trueColor]="'#F96F61'"
                      [falseColor]="'#D7DADE'"
                      [width]="'120px'"
                      [height]="'30px'"
                      [value]="row[column.property] === 'DIGITAL'"
                      [text]="
                        row[column.property] === 'DIGITAL'
                          ? ('Machine.DIGITAL' | translate)
                          : ('Machine.ANALOG' | translate)
                      "
                    >
                    </app-boolean-view>
                  </td>
                </ng-container>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th *matHeaderCellDef mat-header-cell mat-sort-header></th>
                <td *matCellDef="let row" mat-cell>
                  <div class="flex">
                    <app-action-button
                      [btnBackgroundClass]="'bg-red-100'"
                      [btnTextClass]="'text-red-700'"
                      [buttonToolTip]="'Remove'"
                      [buttonIcon]="'delete'"
                      (onClick)="removeTempParameter($event, row?.id)"
                    ></app-action-button>
                  </div>
                </td>
              </ng-container>

              <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
              <tr
                *matRowDef="let row; columns: visibleColumns"
                class="hover:bg-hover trans-ease-out cursor-pointer"
                mat-row
              ></tr>
            </table>
            <div
              *ngIf="dataSource.filteredData.length === 0"
              class="flex-auto flex flex-col items-center justify-center"
            >
              <img src="assets/img/resources/empty-state.svg" alt="not found" />
              <h2 class="headline m-0 text-center text-primary">
                {{ "General.EmptyTableList" | translate }}...
              </h2>
            </div>
            <mat-paginator
              [pageSizeOptions]="pageSizeOptions"
              [pageSize]="pageSize"
              showFirstLastButtons
              class="sticky left-0"
            >
            </mat-paginator>
          </div>
        </form>
      </app-expandable-panel>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-checkbox
      (click)="$event.stopPropagation()"
      [(ngModel)]="column.visible"
      color="primary"
    >
      {{ "Parameters.Table." + column.label | translate }}
    </mat-checkbox>
  </button>
</mat-menu>
