import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Dashboards, TargetDashboardType } from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { DashboardTargetService } from 'src/backend/services/dashboard-target/dashboard-target.service';
import { ReportingDashboardService } from 'src/backend/services/dashboards/reporting-dashboard.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-smart-safety-dashboards',
  templateUrl: './smart-safety-dashboards.component.html',
  styleUrls: ['./smart-safety-dashboards.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class SmartSafetyDashboardsComponent implements OnInit, AfterViewInit {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';

  SharedConstant = SharedConstants;
  public dashboardList: any = [];
  public columns: number = 3;
  private screenWidth: number = null;
  public selectedDashboard: any = null;
  public showFullScreen: boolean = false;
  public filterData: any = {
    fromDate: new Date(),
    toDate: new Date(),
  };

  public firstDayPrevMonth = null;
  public lastDayOfMonth = null;

  public reportingDashboards: any = [];
  public noData = false;
  public getReportingFilter = [];
  public visiablityData = [];
  public dashboards = Object.keys(Dashboards);

  currentPosition;
  height;
  minimumThreshold;
  startPosition;

  constructor(
    private reportingDashboardService: ReportingDashboardService,
    private feedBackService: AsyncFeedbackService,
    private dashboardTargetService: DashboardTargetService,
    private datePipe: DatePipe
  ) {}
  ngAfterViewInit(): void {
    this.closeDialog();
  }

  ngOnInit(): void {}

  // Get Customized Dashboard By UserId
  GetCustomizedDashboardByUserId() {
    this.sectionState = SectionStateStatus.Loading;
    this.reportingDashboardService.getCustomizedDashboardByUserId().subscribe({
      next: (filterData) => {
        this.initFilterData(filterData);
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  // init filter data
  initFilterData(filterData) {
    const today = new Date();
    const initFromDate = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
    const initToDate = new Date();
    const initFilterLabel = 'lastThirtyDay';
    this.getReportingFilter = [];
    this.dashboards.forEach((item) => {
      this.getReportingFilter.push({
        dashboardName: item,
        fromDate: initFromDate,
        toDate: initToDate,
        filterName: initFilterLabel,
        isVisible: true,
      });
    });

    filterData.forEach((item) => {
      const filterElement = this.getReportingFilter.find(
        (ele) => ele.dashboardName === item.dashboardName
      );
      filterElement.fromDate = item.fromDate
        ? this.updateDates(item.filterName)?.fromDate || item.fromDate
        : initFromDate;
      filterElement.toDate = item.toDate
        ? this.updateDates(item.filterName)?.fromFrom || item.toDate
        : initToDate;
      filterElement.filterName = item.filterName
        ? item.filterName
        : initFilterLabel;
      filterElement.isVisible = item.isVisible != null ? item.isVisible : true;
    });

    // this.sectionState = SectionStateStatus.Loading;
    this.getReportingDashboard();
  }
  updateDates(value) {
    const menuVal = value;
    const date = new Date();
    if (menuVal !== 'customRange') {
      if (menuVal === 'thisDay') {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        let lastHourInDay = new Date();
        lastHourInDay.setHours(23);
        lastHourInDay.setMinutes(59);
        lastHourInDay.setSeconds(59);
        lastHourInDay.setMilliseconds(999);
        return {
          fromDate: this.datePipe.transform(
            new Date(today),
            'yyyy-MM-ddT00:00:00.000'
          ),
          fromFrom: this.datePipe.transform(
            new Date(lastHourInDay),
            'yyyy-MM-ddT23:59:59.000'
          ),
        };
      } else if (menuVal === 'yesterday') {
        const yesterdayFrom = new Date();
        const day = yesterdayFrom.getDate() - 1;
        yesterdayFrom.setHours(0);
        yesterdayFrom.setMinutes(0);
        yesterdayFrom.setSeconds(0);
        yesterdayFrom.setDate(day);
        const yesterdayTo = new Date();
        yesterdayTo.setHours(23);
        yesterdayTo.setMinutes(59);
        yesterdayTo.setSeconds(59);
        yesterdayTo.setMilliseconds(999);
        yesterdayTo.setDate(day);
        return {
          fromDate: this.datePipe.transform(
            new Date(yesterdayFrom),
            'yyyy-MM-ddT00:00:00.000'
          ),
          fromFrom: this.datePipe.transform(
            new Date(yesterdayTo),
            'yyyy-MM-ddT23:59:59.000'
          ),
        };
      } else if (menuVal === 'currentMonth') {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDay.setHours(0, 0, 0, 0);
        today.setHours(23, 59, 59, 0);
        return {
          fromDate: this.datePipe.transform(
            new Date(firstDay),
            'yyyy-MM-ddT00:00:00.000'
          ),
          fromFrom: this.datePipe.transform(
            new Date(today),
            'yyyy-MM-ddT23:59:59.000'
          ),
        };
      } else if (menuVal === 'lastMonth') {
        const today = new Date();
        const firstDayPrevMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        firstDayPrevMonth.setHours(0, 0, 0, 0);
        const lastDayPrevMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );

        lastDayPrevMonth.setHours(23, 59, 59, 0);
        return {
          fromDate: this.datePipe.transform(
            new Date(firstDayPrevMonth),
            'yyyy-MM-ddT00:00:00.000'
          ),
          fromFrom: this.datePipe.transform(
            new Date(lastDayPrevMonth),
            'yyyy-MM-ddT23:59:59.000'
          ),
        };
      } else if (menuVal === 'lastThirtyDay') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        const Past30Day = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
        Past30Day.setHours(0, 0, 0, 0);
        return {
          fromDate: this.datePipe.transform(
            new Date(Past30Day),
            'yyyy-MM-ddT00:00:00.000'
          ),
          fromFrom: this.datePipe.transform(
            new Date(today),
            'yyyy-MM-ddT23:59:59.000'
          ),
        };
      } else if (menuVal === 'yearToDate') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        const startYearDate = new Date(date.getFullYear(), 0, 1);
        startYearDate.setHours(0, 0, 0, 0);
        return {
          fromDate: this.datePipe.transform(
            new Date(startYearDate),
            'yyyy-MM-ddT00:00:00.000'
          ),
          fromFrom: this.datePipe.transform(
            new Date(today),
            'yyyy-MM-ddT23:59:59.000'
          ),
        };
      }
    }
    return null;
  }
  // get reporting dashboard
  getReportingDashboard() {
    let data: any = {
      fromDate: new Date(),
      toDate: new Date(),
      companyId: localStorage.getItem('company_id'),
    };

    const today = new Date();
    data.toDate = new Date(today);
    const last30Day = new Date(today.setDate(today.getDate() - 30));
    data.fromDate = new Date(last30Day);

    this.filterData = {
      fromDate: data.fromDate,
      toDate: data.toDate,
    };
    const filter = {
      companyId: localStorage.getItem('company_id'),
      dashboardFilterParams: this.getReportingFilter,
    };

    this.reportingDashboardService.getReportingDashboardData(filter).subscribe({
      next: (dashboardsData) => {
        this.initReportingDashboards(dashboardsData);
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  // initial reporting dashboards
  initReportingDashboards(dashboardsData) {
    this.dashboardList = [];
    this.dashboardList.push(
      ...[
        {
          title: 'ReportedHazard',
          totalReported: dashboardsData.totalHazardReport,
          maxTarget: dashboardsData.targetHazardReport,
          filterLabel: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedHazard'
          ).filterName,
          textColor:
            dashboardsData.hazardReportUptoAtLeast == 'Upto' &&
            dashboardsData.totalHazardReport > dashboardsData.targetHazardReport
              ? '#D52A46'
              : dashboardsData.hazardReportUptoAtLeast == 'At least' &&
                dashboardsData.totalHazardReport >
                  dashboardsData.targetHazardReport
              ? '#35D310'
              : '#000',
          startDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedHazard'
          ).fromDate,
          endDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedHazard'
          ).toDate,
          dashboardType: 'Reporting',
          showCard:
            dashboardsData.companyFeatures.some(
              (item) => item.featureName == 'Reporting'
            ) &&
            this.getReportingFilter.find(
              (item) => item.dashboardName === 'ReportedHazard'
            ).isVisible,
          targetData: {
            targetId: dashboardsData.hazardTargetId,
            targetValue: dashboardsData.targetHazardReport,
            originalTarget: dashboardsData.hazardOriginalTarget,
            targetType: TargetDashboardType.ReportedHazard,
            targetUptoAtLeast: dashboardsData.hazardReportUptoAtLeast,
          },
          chartData: {
            chartTitle: 'Reported Hazard',
            chartColor: '#5D6FCB',
            chartType: 'column',
            part1: [],
            part2: [],
            part3: [],
            part4: [],
          },
        },
        {
          title: 'ReportedNearMiss',
          totalReported: dashboardsData.totalNearMissReport,
          maxTarget: dashboardsData.targetNearMissReport,
          filterLabel: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedNearMiss'
          ).filterName,
          textColor:
            dashboardsData.nearMissReportUptoAtLeast == 'Upto' &&
            dashboardsData.totalNearMissReport >
              dashboardsData.targetNearMissReport
              ? '#D52A46'
              : dashboardsData.nearMissReportUptoAtLeast == 'At least' &&
                dashboardsData.totalNearMissReport >
                  dashboardsData.targetNearMissReport
              ? '#35D310'
              : '#000',
          startDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedNearMiss'
          ).fromDate,
          endDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedNearMiss'
          ).toDate,
          dashboardType: 'Reporting',
          showCard:
            dashboardsData.companyFeatures.some(
              (item) => item.featureName == 'Reporting'
            ) &&
            this.getReportingFilter.find(
              (item) => item.dashboardName === 'ReportedNearMiss'
            ).isVisible,
          targetData: {
            targetId: dashboardsData.nearMissTargetId,
            targetValue: dashboardsData.targetNearMissReport,
            originalTarget: dashboardsData.nearMissOriginalTarget,
            targetType: TargetDashboardType.ReportedNearMiss,
            targetUptoAtLeast: dashboardsData.nearMissReportUptoAtLeast,
          },
          chartData: {
            chartTitle: 'Reported Near Miss',
            chartColor: '#26326E',
            chartType: 'column',
            part1: [],
            part2: [],
            part3: [],
            part4: [],
          },
        },
        {
          title: 'ReportedIncident',
          totalReported: dashboardsData.totalIncidentReport,
          maxTarget: dashboardsData.targetIncidentReport,
          filterLabel: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedIncident'
          ).filterName,
          textColor:
            dashboardsData.incidentReportUptoAtLeast == 'Upto' &&
            dashboardsData.totalIncidentReport >
              dashboardsData.targetIncidentReport
              ? '#D52A46'
              : dashboardsData.incidentReportUptoAtLeast == 'At least' &&
                dashboardsData.totalIncidentReport >
                  dashboardsData.targetIncidentReport
              ? '#35D310'
              : '#000',
          startDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedIncident'
          ).fromDate,
          endDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedIncident'
          ).toDate,
          dashboardType: 'Reporting',
          showCard:
            dashboardsData.companyFeatures.some(
              (item) => item.featureName == 'Reporting'
            ) &&
            this.getReportingFilter.find(
              (item) => item.dashboardName === 'ReportedIncident'
            ).isVisible,
          targetData: {
            targetId: dashboardsData.incidentTargetId,
            targetValue: dashboardsData.targetIncidentReport,
            originalTarget: dashboardsData.incidentOriginalTarget,
            targetType: TargetDashboardType.ReportedIncident,
            targetUptoAtLeast: dashboardsData.incidentReportUptoAtLeast,
          },
          chartData: {
            chartTitle: 'Reported Incident',
            chartColor: '#640F24',
            chartType: 'column',
            part1: [],
            part2: [],
            part3: [],
            part4: [],
          },
        },
        {
          title: 'TotalReported',
          totalReported: dashboardsData.totalReports,
          maxTarget: dashboardsData.maxTarget,
          filterLabel: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'TotalReported'
          ).filterName,
          textColor:
            dashboardsData.totalReportUptoAtLeast == 'Upto' &&
            dashboardsData.totalReports > dashboardsData.totalReportTarget
              ? '#D52A46'
              : dashboardsData.totalReportUptoAtLeast == 'At least' &&
                dashboardsData.totalReports > dashboardsData.totalReportTarget
              ? '#35D310'
              : '#000',
          startDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'TotalReported'
          ).fromDate,
          endDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'TotalReported'
          ).toDate,
          dashboardType: 'Reporting',
          showCard:
            dashboardsData.companyFeatures.some(
              (item) => item.featureName == 'Reporting'
            ) &&
            this.getReportingFilter.find(
              (item) => item.dashboardName === 'TotalReported'
            ).isVisible,
          targetData: {
            targetId: dashboardsData.totalReportTargetId,
            targetValue: dashboardsData.totalReportTarget,
            originalTarget: dashboardsData.totalReportOriginalTarget,
            targetType: TargetDashboardType.TotalReported,
            targetUptoAtLeast: dashboardsData.totalReportUptoAtLeast,
          },
          chartData: {
            chartTitle: 'Total Reported',
            chartColor: '#26326E',
            chartType: '-',
            part1: [],
            part2: [],
            part3: [],
          },
        },
        {
          title: 'CheckList',
          completed: dashboardsData.checkListAdherenceChart.level1.find(
            (item) => item.name == 'Completed'
          )?.y,
          filterLabel: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'CheckList'
          ).filterName,
          textColor:
            dashboardsData.checkListIdherenceUptoAtLeast == 'Upto' &&
            dashboardsData.checkListAdherenceChart.level1[0]?.y >
              dashboardsData.checkListIdherenceTarget
              ? '#D52A46'
              : dashboardsData.checkListIdherenceUptoAtLeast == 'At least' &&
                dashboardsData.checkListAdherenceChart.level1[0]?.y >
                  dashboardsData.checkListIdherenceTarget
              ? '#35D310'
              : '#000',
          startDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'CheckList'
          ).fromDate,
          endDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'CheckList'
          ).toDate,
          dashboardType: 'Audits',
          showCard:
            dashboardsData.companyFeatures.some(
              (item) => item.featureName == 'Audits'
            ) &&
            this.getReportingFilter.find(
              (item) => item.dashboardName === 'CheckList'
            ).isVisible,
          targetData: {
            targetId: dashboardsData.checkListIdherenceTargetId,
            targetValue: dashboardsData.checkListIdherenceTarget,
            originalTarget: dashboardsData.checkListIdherenceOriginalTarget,
            targetType: TargetDashboardType.AuditAdherence,
            targetUptoAtLeast: dashboardsData.checkListIdherenceUptoAtLeast,
          },
          chartData: {
            chartTitle: 'Checklist Adherence',
            chartColor: '#640F24',
            chartType: 'pie',
            part1: [],
            part2: [],
            part3: [],
          },
        },
        {
          title: 'InspectionScore',
          avgScore: dashboardsData.inspectionScoreChart.level1[0]?.y,
          filterLabel: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'InspectionScore'
          ).filterName,
          textColor:
            dashboardsData.inspectionScoreUptoAtLeast == 'Upto' &&
            dashboardsData.inspectionScoreChart.level1[0]?.y >
              dashboardsData.inspectionScoreTarget
              ? '#D52A46'
              : dashboardsData.inspectionScoreUptoAtLeast == 'At least' &&
                dashboardsData.inspectionScoreChart.level1[0]?.y >
                  dashboardsData.inspectionScoreTarget
              ? '#35D310'
              : '#000',
          startDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'InspectionScore'
          ).fromDate,
          endDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'InspectionScore'
          ).toDate,
          dashboardType: 'Audits',
          showCard:
            dashboardsData.companyFeatures.some(
              (item) => item.featureName == 'Audits'
            ) &&
            this.getReportingFilter.find(
              (item) => item.dashboardName === 'InspectionScore'
            ).isVisible,
          targetData: {
            targetId: dashboardsData.inspectionScoreTargetId,
            targetValue: dashboardsData.inspectionScoreTarget,
            originalTarget: dashboardsData.inspectionScoreOriginalTarget,
            targetType: TargetDashboardType.AuditScore,
            targetUptoAtLeast: dashboardsData.inspectionScoreUptoAtLeast,
          },
          chartData: {
            chartTitle: 'Inspection Score',
            chartColor: '#26326E',
            chartType: 'pie',
            part1: [],
            part2: [],
            part3: [],
          },
        },
        {
          title: 'WorkPermit',
          total: dashboardsData.workPermitChart.level1
            .map((item) => item.y)
            .reduce((prev, next) => parseInt(prev) + parseInt(next), 0),
          filterLabel: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'WorkPermit'
          ).filterName,
          textColor:
            dashboardsData.workPermitUptoAtLeast == 'Upto' &&
            dashboardsData.workPermitChart.level1
              .map((item) => item.y)
              .reduce((prev, next) => parseInt(prev) + parseInt(next), 0) >
              dashboardsData.workPermitTarget
              ? '#D52A46'
              : dashboardsData.workPermitUptoAtLeast == 'At least' &&
                dashboardsData.workPermitChart.level1
                  .map((item) => item.y)
                  .reduce((prev, next) => parseInt(prev) + parseInt(next), 0) >
                  dashboardsData.workPermitTarget
              ? '#35D310'
              : '#000',
          startDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'WorkPermit'
          ).fromDate,
          endDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'WorkPermit'
          ).toDate,
          dashboardType: 'WorkPermit',
          showCard:
            dashboardsData.companyFeatures.some(
              (item) => item.featureName == 'WorkPermit'
            ) &&
            this.getReportingFilter.find(
              (item) => item.dashboardName === 'WorkPermit'
            ).isVisible,
          targetData: {
            targetId: dashboardsData.workPermitTargetId,
            targetValue: dashboardsData.workPermitTarget,
            targetType: TargetDashboardType.WorkPermit,
            targetUptoAtLeast: dashboardsData.workPermitUptoAtLeast,
          },
          chartData: {
            chartTitle: 'Work Permit',
            chartColor: '#26326E',
            chartType: '-',
            part1: [],
            part2: [],
            part3: [],
          },
        },
        {
          title: 'ActionPlan',
          total: dashboardsData.actionPlanChart.level1
            .map((item) => item.y)
            .reduce((prev, next) => parseInt(prev) + parseInt(next), 0),
          filterLabel: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ActionPlan'
          ).filterName,
          textColor:
            dashboardsData.actionPlanUptoAtLeast == 'Upto' &&
            dashboardsData.actionPlanChart.level1
              .map((item) => item.y)
              .reduce((prev, next) => parseInt(prev) + parseInt(next), 0) >
              dashboardsData.actionPlanTarget
              ? '#D52A46'
              : dashboardsData.actionPlanUptoAtLeast == 'At least' &&
                dashboardsData.actionPlanChart.level1
                  .map((item) => item.y)
                  .reduce((prev, next) => parseInt(prev) + parseInt(next), 0) >
                  dashboardsData.actionPlanTarget
              ? '#35D310'
              : '#000',
          startDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ActionPlan'
          ).fromDate,
          endDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ActionPlan'
          ).toDate,
          showCard:
            dashboardsData.companyFeatures.length > 0 &&
            this.getReportingFilter.find(
              (item) => item.dashboardName === 'ActionPlan'
            ).isVisible,
          targetData: {
            targetId: dashboardsData.actionPlanTargetId,
            targetValue: dashboardsData.actionPlanTarget,
            originalTarget: dashboardsData.actionPlanOriginalTarget,
            targetType: TargetDashboardType.ActionPlan,
            targetUptoAtLeast: dashboardsData.actionPlanUptoAtLeast,
          },
          chartData: {
            chartTitle: 'Action Plan Completion',
            chartColor: '#26326E',
            chartType: '-',
            part1: [],
            part2: [],
            part3: [],
          },
        },
        {
          title: 'ReportedIncidentLoss',
          totalReported: dashboardsData.totalIncidentLossReport,
          maxTarget: dashboardsData.targetIncidentLossReport,
          filterLabel: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedIncidentLoss'
          ).filterName,
          textColor:
            dashboardsData.incidentReportUptoAtLeast == 'Upto' &&
            dashboardsData.totalIncidentLossReport >
              dashboardsData.targetIncidentLossReport
              ? '#D52A46'
              : dashboardsData.incidentReportUptoAtLeast == 'At least' &&
                dashboardsData.totalIncidentLossReport >
                  dashboardsData.targetIncidentLossReport
              ? '#35D310'
              : '#000',
          startDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedIncidentLoss'
          ).fromDate,
          endDate: this.getReportingFilter.find(
            (ele) => ele.dashboardName === 'ReportedIncidentLoss'
          ).toDate,
          dashboardType: 'Reporting',
          showCard:
            dashboardsData.companyFeatures.some(
              (item) => item.featureName == 'Reporting'
            ) &&
            this.getReportingFilter.find(
              (item) => item.dashboardName === 'ReportedIncidentLoss'
            ).isVisible,
          targetData: {
            targetId: dashboardsData.incidentLossTargetId,
            targetValue: dashboardsData.targetIncidentLossReport,
            originalTarget: dashboardsData.incidentLossOriginalTarget,
            targetType: TargetDashboardType.IncidentLoss,
            targetUptoAtLeast: dashboardsData.incidentLossReportUptoAtLeast,
          },
          chartData: {
            chartTitle: 'Incident Loss Reported',
            chartColor: '#640F24',
            chartType: 'column',
            part1: [],
            part2: [],
            part3: [],
          },
        },
      ]
    );
    this.generateReportingDashboardsLevels(dashboardsData);
  }

  // generate Reporting Levels
  generateReportingDashboardsLevels(dashboardsData) {
    let object: any;
    let color: Array<string> = [];

    // reported hazard dashboard part1
    color = ['#5D6FCB', '#8B96D3'];
    for (let i = 0; i < dashboardsData.hazardReportChart.level1?.length; i++) {
      object = {
        name: dashboardsData.hazardReportChart.level1[i]?.name,
        data: [
          {
            y: parseInt(dashboardsData.hazardReportChart.level1[i]?.y),
            drilldown: dashboardsData.hazardReportChart.level1[i]?.drilldown,
          },
        ],
        status: dashboardsData.hazardReportChart.level1[i]?.name,
        color: color[i],
      };

      this.dashboardList[0].chartData.part1.push(object);
      object = null;
    }

    // reported near miss dashboard part1
    color = ['#26326E', '#3F51AF', '#9FADCC'];
    for (let i = 0; i < dashboardsData.neaMissReportChart.level1?.length; i++) {
      object = {
        name: dashboardsData.neaMissReportChart.level1[i]?.name,
        data: [
          {
            y: parseInt(dashboardsData.neaMissReportChart.level1[i]?.y),
            drilldown: dashboardsData.neaMissReportChart.level1[i]?.drilldown,
          },
        ],
        status: dashboardsData.neaMissReportChart.level1[i]?.name,
        color: color[i],
      };

      this.dashboardList[1].chartData.part1.push(object);
      object = null;
    }

    // reported incident dashboard part1
    color = ['#640F24', '#D44343', '#E27979'];
    for (
      let i = 0;
      i < dashboardsData.incidentReportChart.level1?.length;
      i++
    ) {
      object = {
        name: dashboardsData.incidentReportChart.level1[i]?.name,
        data: [
          {
            y: parseInt(dashboardsData.incidentReportChart.level1[i]?.y),
            drilldown: dashboardsData.incidentReportChart.level1[i]?.drilldown,
          },
        ],
        status: dashboardsData.incidentReportChart.level1[i]?.name,
        color: color[i],
      };

      this.dashboardList[2].chartData.part1.push(object);
      object = null;
    }

    // total reported dashboard part1
    color = ['#5D6FCB', '#640F24', '#26326E'];
    for (let i = 0; i < dashboardsData.totalReportedChart.level1?.length; i++) {
      object = {
        name: dashboardsData.totalReportedChart.level1[i]?.drilldown,
        type: 'bar',
        data: [
          {
            y: parseInt(dashboardsData.totalReportedChart.level1[i]?.y),
            drilldown: dashboardsData.totalReportedChart.level1[i]?.drilldown,
          },
        ],
        status: dashboardsData.totalReportedChart.level1[i]?.name,
        color: color[i],
      };

      this.dashboardList[3].chartData.part1.push(object);
      object = null;
    }

    // checklist Adherence Chart dashboard part1
    color = ['#640F24', '#D44343'];
    if (dashboardsData.checkListAdherenceChart.level1.length > 0) {
      object = {
        name: 'Checklist Adherence',
        colorByPoint: true,
        innerSize: '40%',
        data: [
          {
            y: Number(dashboardsData.checkListAdherenceChart.level1[0]?.y),
            name:
              dashboardsData.checkListAdherenceChart.level1[0]?.name ||
              'Inspection',
            color: dashboardsData.checkListAdherenceChart.level1.find(
              (item) => item.name == 'Completed'
            )?.name
              ? color[0]
              : color[1],
            drilldown: dashboardsData.checkListAdherenceChart.level1[0]
              ?.drilldown
              ? dashboardsData.checkListAdherenceChart.level1[0]?.drilldown
              : '-',
          },
          {
            y: Number(dashboardsData.checkListAdherenceChart.level1[1]?.y),
            name:
              dashboardsData.checkListAdherenceChart.level1[1]?.name ||
              'Inspection',
            color: dashboardsData.checkListAdherenceChart.level1.find(
              (item) => item.name == 'Uncompleted'
            )?.name
              ? color[1]
              : color[0],
            drilldown: dashboardsData.checkListAdherenceChart.level1[1]
              ?.drilldown
              ? dashboardsData.checkListAdherenceChart.level1[1]?.drilldown
              : '-',
          },
        ],
        status: dashboardsData.checkListAdherenceChart.level1[0]?.name,
      };

      this.dashboardList[4].chartData.part1.push(object);
      object = null;
    }

    // inspection score Chart dashboard part1
    color = ['#26326E', '#5D6FCB'];
    if (dashboardsData.inspectionScoreChart.level1.length > 0) {
      object = {
        name: 'Inspection Score',
        colorByPoint: true,
        innerSize: '40%',
        status: 'Inspection Score',
      };
      object.data = [
        {
          name: 'Success',
          y: dashboardsData.inspectionScoreChart.level1[0]?.y
            ? parseInt(dashboardsData.inspectionScoreChart.level1[0]?.y)
            : 0,
          color: color[0],
          drilldown: dashboardsData.inspectionScoreChart.level1[0]?.drilldown
            ? dashboardsData.inspectionScoreChart.level1[0]?.drilldown
            : '-',
        },
        {
          name: 'Fail',
          y: dashboardsData.inspectionScoreChart.level1[0]?.y
            ? 100 - parseInt(dashboardsData.inspectionScoreChart.level1[0]?.y)
            : null,
          color: color[1],
          drilldown: '-',
        },
      ];
      this.dashboardList[5].chartData.part1.push(object);
      object = null;
    }

    // work permit dashboard part1
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24', '#00875A'];
    for (let i = 0; i < dashboardsData.workPermitChart.level1?.length; i++) {
      object = {
        name: dashboardsData.workPermitChart.level1[i]?.name,
        type: 'bar',
        data: [
          {
            name: dashboardsData.workPermitChart.level1[i]?.name,
            y: parseInt(dashboardsData.workPermitChart.level1[i]?.y),
            drilldown: dashboardsData.workPermitChart.level1[i]?.drilldown,
          },
        ],
        status: dashboardsData.workPermitChart.level1[i]?.name,
        color: color[0],
      };

      if (dashboardsData.workPermitChart.level1[i]?.name == 'NEW') {
        object.color = color[0];
      } else if (
        dashboardsData.workPermitChart.level1[i]?.name == 'INPROGRESS'
      ) {
        object.color = color[1];
      } else if (dashboardsData.workPermitChart.level1[i]?.name == 'CLOSED') {
        object.color = color[2];
      } else if (dashboardsData.workPermitChart.level1[i]?.name == 'REJECTED') {
        object.color = color[3];
      } else if (dashboardsData.workPermitChart.level1[i]?.name == 'APPROVED') {
        object.color = color[4];
      }

      this.dashboardList[6].chartData.part1.push(object);
      object = null;
    }

    // action plan completion dashboard part1
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24'];
    for (let i = 0; i < dashboardsData.actionPlanChart.level1?.length; i++) {
      object = {
        name: dashboardsData.actionPlanChart.level1[i]?.name,
        type: 'bar',
        data: [
          {
            name: dashboardsData.actionPlanChart.level1[i]?.name,
            y: parseInt(dashboardsData.actionPlanChart.level1[i]?.y),
            drilldown: dashboardsData.actionPlanChart.level1[i]?.drilldown,
          },
        ],
        status: dashboardsData.actionPlanChart.level1[i]?.name,
        color: color[0],
      };

      if (dashboardsData.actionPlanChart.level1[i]?.name == 'NEW') {
        object.color = color[0];
      } else if (
        dashboardsData.actionPlanChart.level1[i]?.name == 'INPROGRESS'
      ) {
        object.color = color[1];
      } else if (dashboardsData.actionPlanChart.level1[i]?.name == 'CLOSE') {
        object.color = color[2];
      } else if (dashboardsData.actionPlanChart.level1[i]?.name == 'MISSED') {
        object.color = color[3];
      }

      this.dashboardList[7].chartData.part1.push(object);
      object = null;
    }

    // reported loss incident dashboard part1
    color = ['#640F24', '#D44343', '#E27979'];
    for (
      let i = 0;
      i < dashboardsData.incidentLossReportChart.level1?.length;
      i++
    ) {
      object = {
        name: dashboardsData.incidentLossReportChart.level1[i]?.name,
        data: [
          {
            y: parseInt(dashboardsData.incidentLossReportChart.level1[i]?.y),
            drilldown:
              dashboardsData.incidentLossReportChart.level1[i]?.drilldown,
          },
        ],
        status: dashboardsData.incidentLossReportChart.level1[i]?.name,
        color: color[i],
      };

      this.dashboardList[8].chartData.part1.push(object);
      object = null;
    }

    // reported hazard dashboard part2
    color = ['#5D6FCB', '#8B96D3'];
    for (let i = 0; i < dashboardsData.hazardReportChart.level2?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.hazardReportChart.level1?.length;
        index++
      ) {
        if (
          dashboardsData.hazardReportChart.level2[i]?.id ==
          dashboardsData.hazardReportChart.level1[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.hazardReportChart.level2[i]?.name,
            id: dashboardsData.hazardReportChart.level2[i]?.id,
            data: [
              {
                y: parseInt(dashboardsData.hazardReportChart.level2[i]?.y),
                drilldown:
                  dashboardsData.hazardReportChart.level2[i]?.drilldown,
              },
            ],
            status: dashboardsData.hazardReportChart.level2[i]?.status,
            color: color[index],
            levelThreeColor: color[index],
          };

          this.dashboardList[0].chartData.part2.push(object);
          object = null;
        }
      }
    }

    // reported near miss dashboard part2
    for (let i = 0; i < dashboardsData.neaMissReportChart.level2?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.neaMissReportChart.level1?.length;
        index++
      ) {
        if (
          dashboardsData.neaMissReportChart.level2[i]?.id ==
          dashboardsData.neaMissReportChart.level1[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.neaMissReportChart.level2[i]?.name,
            id: dashboardsData.neaMissReportChart.level2[i]?.id,
            data: [
              {
                y: parseInt(dashboardsData.neaMissReportChart.level2[i]?.y),
                drilldown:
                  dashboardsData.neaMissReportChart.level2[i]?.drilldown,
              },
            ],
            status: dashboardsData.neaMissReportChart.level2[i]?.status,
            color: '#26326E',
          };

          this.dashboardList[1].chartData.part2.push(object);
          object = null;
        }
      }
    }

    // reported incident dashboard part2
    for (
      let i = 0;
      i < dashboardsData.incidentReportChart.level2?.length;
      i++
    ) {
      for (
        let index = 0;
        index < dashboardsData.incidentReportChart.level1?.length;
        index++
      ) {
        if (
          dashboardsData.incidentReportChart.level2[i]?.id ==
          dashboardsData.incidentReportChart.level1[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.incidentReportChart.level2[i]?.name,
            id: dashboardsData.incidentReportChart.level2[i]?.id,
            data: [
              {
                y: parseInt(dashboardsData.incidentReportChart.level2[i]?.y),
                drilldown:
                  dashboardsData.incidentReportChart.level2[i]?.drilldown,
              },
            ],
            status: dashboardsData.incidentReportChart.level2[i]?.status,
            color: '#640F24',
          };

          this.dashboardList[2].chartData.part2.push(object);
          object = null;
        }
      }
    }

    // total reported dashboard part2
    for (let i = 0; i < dashboardsData.totalReportedChart.level2?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.totalReportedChart.level1?.length;
        index++
      ) {
        if (
          dashboardsData.totalReportedChart.level2[i]?.id ==
          dashboardsData.totalReportedChart.level1[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.totalReportedChart.level2[i]?.name,
            id: dashboardsData.totalReportedChart.level2[i]?.id,
            type: 'column',
            data: [
              {
                y: parseInt(dashboardsData.totalReportedChart.level2[i]?.y),
                drilldown:
                  dashboardsData.totalReportedChart.level2[i]?.drilldown,
              },
            ],
            status: dashboardsData.totalReportedChart.level2[i]?.status,
            color: '',
          };

          if (dashboardsData.totalReportedChart.level2[i]?.id == 'Hazard') {
            object.color = '#26326E';
          } else if (
            dashboardsData.totalReportedChart.level2[i]?.id == 'Near_Miss'
          ) {
            object.color = '#26326E';
          } else if (
            dashboardsData.totalReportedChart.level2[i]?.id == 'Incident'
          ) {
            object.color = '#640F24';
          }

          this.dashboardList[3].chartData.part2.push(object);
          object = null;
        }
      }
    }

    // checklist Adherence Chart dashboard part2
    color = ['#640F24'];
    for (
      let i = 0;
      i < dashboardsData.checkListAdherenceChart.level2?.length;
      i++
    ) {
      for (
        let index = 0;
        index < dashboardsData.checkListAdherenceChart.level1?.length;
        index++
      ) {
        if (
          dashboardsData.checkListAdherenceChart.level2[i]?.id ==
          dashboardsData.checkListAdherenceChart.level1[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.checkListAdherenceChart.level2[i]?.name,
            id: dashboardsData.checkListAdherenceChart.level2[i]?.id,
            type: 'column',
            color: color[0],
            data: [
              {
                y: Number(dashboardsData.checkListAdherenceChart.level2[i]?.y),
                name: dashboardsData.checkListAdherenceChart.level2[i]?.name,
                drilldown:
                  dashboardsData.checkListAdherenceChart.level2[i]?.drilldown,
              },
            ],
            status: dashboardsData.checkListAdherenceChart.level2[i]?.status,
          };

          this.dashboardList[4].chartData.part2.push(object);
          object = null;
        }
      }
    }

    // inspection score Chart dashboard part2
    color = ['#26326E'];
    for (
      let i = 0;
      i < dashboardsData.inspectionScoreChart.level2?.length;
      i++
    ) {
      object = {
        name: dashboardsData.inspectionScoreChart.level2[i]?.name,
        type: 'column',
        id: dashboardsData.inspectionScoreChart.level2[i]?.id,
        color: color[0],
        data: [
          {
            y: parseInt(dashboardsData.inspectionScoreChart.level2[i]?.y),
            name: dashboardsData.inspectionScoreChart.level2[i]?.name,
          },
        ],
        status: dashboardsData.inspectionScoreChart.level2[i]?.status,
      };

      this.dashboardList[5].chartData.part2.push(object);
      object = null;
    }

    // work permit dashboard part2
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24'];
    for (let i = 0; i < dashboardsData.workPermitChart.level2?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.workPermitChart.level1?.length;
        index++
      ) {
        if (
          dashboardsData.workPermitChart.level2[i]?.id ==
          dashboardsData.workPermitChart.level1[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.workPermitChart.level2[i]?.name,
            type: 'column',
            id: dashboardsData.workPermitChart.level2[i]?.id,
            color: color[0],
            data: [
              {
                name: dashboardsData.workPermitChart.level2[i]?.name,
                y: parseInt(dashboardsData.workPermitChart.level2[i]?.y),
                drilldown: dashboardsData.workPermitChart.level2[i]?.drilldown,
              },
            ],
            status: dashboardsData.workPermitChart.level2[i]?.status,
          };

          if (dashboardsData.workPermitChart.level2[i]?.id == 'NEW') {
            object.color = '#26326E';
          } else if (
            dashboardsData.workPermitChart.level2[i]?.id == 'INPROGRESS'
          ) {
            object.color = '#5D6FCB';
          } else if (dashboardsData.workPermitChart.level2[i]?.id == 'CLOSED') {
            object.color = '#96A3E6';
          } else if (
            dashboardsData.workPermitChart.level2[i]?.id == 'REJECTED'
          ) {
            object.color = '#640F24';
          } else if (
            dashboardsData.workPermitChart.level2[i]?.id == 'APPROVED'
          ) {
            object.color = '#640F24';
          }

          this.dashboardList[6].chartData.part2.push(object);
          object = null;
        }
      }
    }

    // action plan completion dashboard part2
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24'];
    for (let i = 0; i < dashboardsData.actionPlanChart.level2?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.actionPlanChart.level1?.length;
        index++
      ) {
        if (
          dashboardsData.actionPlanChart.level2[i]?.id ==
          dashboardsData.actionPlanChart.level1[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.actionPlanChart.level2[i]?.name,
            type: 'column',
            id: dashboardsData.actionPlanChart.level2[i]?.id,
            color: color[0],
            data: [
              {
                name: dashboardsData.actionPlanChart.level2[i]?.name,
                y: parseInt(dashboardsData.actionPlanChart.level2[i]?.y),
                drilldown: dashboardsData.actionPlanChart.level2[i]?.drilldown,
              },
            ],
            status: dashboardsData.actionPlanChart.level2[i]?.status,
          };

          if (dashboardsData.actionPlanChart.level2[i]?.id == 'NEW') {
            object.color = '#26326E';
          } else if (
            dashboardsData.actionPlanChart.level2[i]?.id == 'INPROGRESS'
          ) {
            object.color = '#5D6FCB';
          } else if (dashboardsData.actionPlanChart.level2[i]?.id == 'CLOSED') {
            object.color = '#96A3E6';
          } else if (dashboardsData.actionPlanChart.level2[i]?.id == 'MISSED') {
            object.color = '#640F24';
          }

          this.dashboardList[7].chartData.part2.push(object);
          object = null;
        }
      }
    }

    // reported loss incident dashboard part2
    for (
      let i = 0;
      i < dashboardsData.incidentLossReportChart.level2?.length;
      i++
    ) {
      for (
        let index = 0;
        index < dashboardsData.incidentLossReportChart.level1?.length;
        index++
      ) {
        if (
          dashboardsData.incidentLossReportChart.level2[i]?.id ==
          dashboardsData.incidentLossReportChart.level1[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.incidentLossReportChart.level2[i]?.name,
            id: dashboardsData.incidentLossReportChart.level2[i]?.id,
            data: [
              {
                y: parseInt(
                  dashboardsData.incidentLossReportChart.level2[i]?.y
                ),
                drilldown:
                  dashboardsData.incidentLossReportChart.level2[i]?.drilldown,
              },
            ],
            status: dashboardsData.incidentLossReportChart.level2[i]?.status,
            color: '#640F24',
          };

          this.dashboardList[8].chartData.part2.push(object);
          object = null;
        }
      }
    }

    // reported hazard dashboard part3
    for (let i = 0; i < dashboardsData.hazardReportChart.level3?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.hazardReportChart.level2?.length;
        index++
      ) {
        if (
          dashboardsData.hazardReportChart.level3[i]?.id ==
          dashboardsData.hazardReportChart.level2[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.hazardReportChart.level3[i]?.name,
            id: dashboardsData.hazardReportChart.level3[i]?.id,
            data: [
              {
                y: parseInt(dashboardsData.hazardReportChart.level3[i]?.y),
                drilldown:
                  dashboardsData.hazardReportChart.level3[i]?.drilldown,
              },
            ],
            status: dashboardsData.hazardReportChart.level3[i]?.status,
            color: color[i],
          };

          this.dashboardList[0].chartData.part3.push(object);
          object = null;
        }
      }
    }

    // reported near miss dashboard part3
    for (let i = 0; i < dashboardsData.neaMissReportChart.level3?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.neaMissReportChart.level2?.length;
        index++
      ) {
        if (
          dashboardsData.neaMissReportChart.level3[i]?.id ==
          dashboardsData.neaMissReportChart.level2[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.neaMissReportChart.level3[i]?.name,
            id: dashboardsData.neaMissReportChart.level3[i]?.id,
            data: [
              {
                y: parseInt(dashboardsData.neaMissReportChart.level3[i]?.y),
                drilldown:
                  dashboardsData.neaMissReportChart.level3[i]?.drilldown,
              },
            ],
            status: dashboardsData.neaMissReportChart.level3[i]?.status,
            color: '#26326E',
          };

          this.dashboardList[1].chartData.part3.push(object);
          object = null;
        }
      }
    }

    // reported incident dashboard part3
    for (
      let i = 0;
      i < dashboardsData.incidentReportChart.level3?.length;
      i++
    ) {
      for (
        let index = 0;
        index < dashboardsData.incidentReportChart.level2?.length;
        index++
      ) {
        if (
          dashboardsData.incidentReportChart.level3[i]?.id ==
          dashboardsData.incidentReportChart.level2[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.incidentReportChart.level3[i]?.name,
            id: dashboardsData.incidentReportChart.level3[i]?.id,
            data: [
              {
                y: parseInt(dashboardsData.incidentReportChart.level3[i]?.y),
                drilldown:
                  dashboardsData.incidentReportChart.level3[i]?.drilldown,
              },
            ],
            status: dashboardsData.incidentReportChart.level3[i]?.status,
            color: '#640F24',
          };

          this.dashboardList[2].chartData.part3.push(object);
          object = null;
        }
      }
    }

    // total reported dashboard part3
    for (let i = 0; i < dashboardsData.totalReportedChart.level3?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.totalReportedChart.level2?.length;
        index++
      ) {
        if (
          dashboardsData.totalReportedChart.level3[i]?.id ==
          dashboardsData.totalReportedChart.level2[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.totalReportedChart.level3[i]?.name,
            id: dashboardsData.totalReportedChart.level3[i]?.id,
            data: [
              {
                y: parseInt(dashboardsData.totalReportedChart.level3[i]?.y),
                drilldown:
                  dashboardsData.totalReportedChart.level3[i]?.drilldown,
              },
            ],
            status: dashboardsData.totalReportedChart.level3[i]?.status,
            type: 'column',
            color: '#640F24',
          };
          this.dashboardList[3].chartData.part3.push(object);
          object = null;
        }
      }
    }

    // checklist Adherence Chart dashboard part3
    color = ['#640F24'];
    for (
      let i = 0;
      i < dashboardsData.checkListAdherenceChart.level3?.length;
      i++
    ) {
      for (
        let index = 0;
        index < dashboardsData.checkListAdherenceChart.level2?.length;
        index++
      ) {
        if (
          dashboardsData.checkListAdherenceChart.level3[i]?.id ==
          dashboardsData.checkListAdherenceChart.level2[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.checkListAdherenceChart.level3[i]?.name,
            id: dashboardsData.checkListAdherenceChart.level3[i]?.id,
            type: 'column',
            color: color[0],
            data: [
              {
                y: Number(dashboardsData.checkListAdherenceChart.level3[i]?.y),
                name: dashboardsData.checkListAdherenceChart.level3[i]?.name,
              },
            ],
            status: dashboardsData.checkListAdherenceChart.level3[i]?.status,
          };

          this.dashboardList[4].chartData.part3.push(object);
          object = null;
        }
      }
    }

    // inspection score Chart dashboard part3
    color = ['#26326E'];
    for (
      let i = 0;
      i < dashboardsData.inspectionScoreChart.level3?.length;
      i++
    ) {
      for (
        let index = 0;
        index < dashboardsData.inspectionScoreChart.level2?.length;
        index++
      ) {
        if (
          dashboardsData.inspectionScoreChart.level3[i]?.id ==
          dashboardsData.inspectionScoreChart.level2[index]?.name
        ) {
          object = {
            name: dashboardsData.inspectionScoreChart.level3[i]?.name,
            id: dashboardsData.inspectionScoreChart.level3[i]?.id,
            type: 'column',
            color: color[0],
            data: [
              {
                y: parseInt(dashboardsData.inspectionScoreChart.level3[i]?.y),
                name: dashboardsData.inspectionScoreChart.level3[i]?.name,
              },
            ],
            status: dashboardsData.inspectionScoreChart.level3[i]?.status,
          };

          this.dashboardList[5].chartData.part3.push(object);
          object = null;
        }
      }
    }

    // work permit dashboard part3
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24'];
    for (let i = 0; i < dashboardsData.workPermitChart.level3?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.workPermitChart.level2?.length;
        index++
      ) {
        if (
          dashboardsData.workPermitChart.level3[i]?.id ==
          dashboardsData.workPermitChart.level2[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.workPermitChart.level3[i]?.name,
            type: 'column',
            id: dashboardsData.workPermitChart.level3[i]?.id,
            color: color[0],
            data: [
              {
                name: dashboardsData.workPermitChart.level3[i]?.name,
                y: parseInt(dashboardsData.workPermitChart.level3[i]?.y),
              },
            ],
            status: dashboardsData.workPermitChart.level3[i]?.status,
          };

          if (dashboardsData.workPermitChart.level3[i]?.status == 'NEW') {
            object.color = color[0];
          } else if (
            dashboardsData.workPermitChart.level3[i]?.status == 'INPROGRESS'
          ) {
            object.color = color[1];
          } else if (
            dashboardsData.workPermitChart.level3[i]?.status == 'CLOSED'
          ) {
            object.color = color[2];
          } else if (
            dashboardsData.workPermitChart.level3[i]?.status == 'REJECTED'
          ) {
            object.color = color[3];
          } else if (
            dashboardsData.workPermitChart.level3[i]?.status == 'APPROVED'
          ) {
            object.color = color[3];
          }

          this.dashboardList[6].chartData.part3.push(object);
          object = null;
        }
      }
    }

    // action plan completion dashboard part3
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24'];
    for (let i = 0; i < dashboardsData.actionPlanChart.level3?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.actionPlanChart.level2?.length;
        index++
      ) {
        if (
          dashboardsData.actionPlanChart.level3[i]?.id ==
          dashboardsData.actionPlanChart.level2[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.actionPlanChart.level3[i]?.name,
            type: 'column',
            id: dashboardsData.actionPlanChart.level3[i]?.id,
            color: color[0],
            data: [
              {
                name: dashboardsData.actionPlanChart.level3[i]?.name,
                y: parseInt(dashboardsData.actionPlanChart.level3[i]?.y),
              },
            ],
            status: dashboardsData.actionPlanChart.level3[i]?.status,
          };

          if (
            String(dashboardsData.actionPlanChart.level3[i]?.id).includes('NEW')
          ) {
            object.color = color[0];
          } else if (
            String(dashboardsData.actionPlanChart.level3[i]?.id).includes(
              'INPROGRESS'
            )
          ) {
            object.color = color[1];
          } else if (
            String(dashboardsData.actionPlanChart.level3[i]?.id).includes(
              'CLOSED'
            )
          ) {
            object.color = color[2];
          } else if (
            String(dashboardsData.actionPlanChart.level3[i]?.id).includes(
              'MISSED'
            )
          ) {
            object.color = color[3];
          }

          this.dashboardList[7].chartData.part3?.push(object);
          object = null;
        }
      }
    }

    // reported loss incident dashboard part3
    for (
      let i = 0;
      i < dashboardsData.incidentLossReportChart.level3?.length;
      i++
    ) {
      for (
        let index = 0;
        index < dashboardsData.incidentLossReportChart.level2?.length;
        index++
      ) {
        if (
          dashboardsData.incidentLossReportChart.level3[i]?.id ==
          dashboardsData.incidentLossReportChart.level2[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.incidentLossReportChart.level3[i]?.name,
            id: dashboardsData.incidentLossReportChart.level3[i]?.id,
            data: [
              {
                y: parseInt(
                  dashboardsData.incidentLossReportChart.level3[i]?.y
                ),
                drilldown:
                  dashboardsData.incidentLossReportChart.level3[i]?.drilldown,
              },
            ],
            status: dashboardsData.incidentLossReportChart.level3[i]?.status,
            color: '#640F24',
          };

          this.dashboardList[8].chartData.part3.push(object);
          object = null;
        }
      }
    }

    // reported hazard dashboard part4
    for (let i = 0; i < dashboardsData.hazardReportChart.level4?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.hazardReportChart.level3?.length;
        index++
      ) {
        if (
          dashboardsData.hazardReportChart.level4[i]?.id ==
          dashboardsData.hazardReportChart.level3[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.hazardReportChart.level4[i]?.name,
            id: dashboardsData.hazardReportChart.level4[i]?.id,
            data: [
              {
                y: parseInt(dashboardsData.hazardReportChart.level4[i]?.y),
                drilldown:
                  dashboardsData.hazardReportChart.level4[i]?.drilldown,
              },
            ],
            status: dashboardsData.hazardReportChart.level4[i]?.status,
            color: color[i],
          };

          this.dashboardList[0].chartData.part4.push(object);
          object = null;
        }
      }
    }

    // reported near miss dashboard part4
    for (let i = 0; i < dashboardsData.neaMissReportChart.level4?.length; i++) {
      for (
        let index = 0;
        index < dashboardsData.neaMissReportChart.level3?.length;
        index++
      ) {
        if (
          dashboardsData.neaMissReportChart.level4[i]?.id ==
          dashboardsData.neaMissReportChart.level3[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.neaMissReportChart.level4[i]?.name,
            id: dashboardsData.neaMissReportChart.level4[i]?.id,
            data: [
              {
                y: parseInt(dashboardsData.neaMissReportChart.level4[i]?.y),
                drilldown:
                  dashboardsData.neaMissReportChart.level4[i]?.drilldown,
              },
            ],
            status: dashboardsData.neaMissReportChart.level4[i]?.status,
            color: '#26326E',
          };

          this.dashboardList[1].chartData.part4.push(object);
          object = null;
        }
      }
    }

    // reported incident dashboard part4
    for (
      let i = 0;
      i < dashboardsData.incidentReportChart.level4?.length;
      i++
    ) {
      for (
        let index = 0;
        index < dashboardsData.incidentReportChart.level3?.length;
        index++
      ) {
        if (
          dashboardsData.incidentReportChart.level4[i]?.id ==
          dashboardsData.incidentReportChart.level3[index]?.drilldown
        ) {
          object = {
            name: dashboardsData.incidentReportChart.level4[i]?.name,
            id: dashboardsData.incidentReportChart.level4[i]?.id,
            data: [
              {
                y: parseInt(dashboardsData.incidentReportChart.level4[i]?.y),
                drilldown:
                  dashboardsData.incidentReportChart.level4[i]?.drilldown,
              },
            ],
            status: dashboardsData.incidentReportChart.level4[i]?.status,
            color: '#640F24',
          };

          this.dashboardList[2].chartData.part4.push(object);
          object = null;
        }
      }
    }

    this.noData =
      !this.dashboardList
        .map((item) => item.chartData.part1)
        .some((item) => item.length > 0) ||
      this.dashboardList.filter((ele) => ele.showCard == true).length == 0;

    let dashboardListByFeatures = [];
    dashboardsData.companyFeatures.forEach((item) => {
      dashboardListByFeatures.push(
        ...this.dashboardList.filter(
          (ele) =>
            item.featureName == ele.dashboardType && ele.title != 'ActionPlan'
        )
      );
    });
    if (dashboardsData.companyFeatures.length > 0) {
      dashboardListByFeatures.push(
        this.dashboardList.find((ele) => ele.title == 'ActionPlan')
      );
    }

    this.getReportingFilter = this.getReportingFilter.filter((item) =>
      dashboardListByFeatures.some((ele) => ele.title === item.dashboardName)
    );

    this.dashboardList = [
      ...this.dashboardList.filter((item) => item.showCard == true),
    ];

    this.sectionState = SectionStateStatus.Ready;
  }

  // update target
  updateTarget(result) {
    if (result?.isUpdate) {
      if (result?.id) {
        this.sectionState = SectionStateStatus.Loading;
        this.GetCustomizedDashboardByUserId();
        this.dashboardTargetService.putDashboardTarget(result).subscribe({
          next: async (response) => {
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Success, response?.message)
            );

            this.getReportingDashboard();
          },
          error: (error) => {
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
            this.sectionState = SectionStateStatus.Ready;
          },
        });
      } else {
        this.sectionState = SectionStateStatus.Loading;
        this.dashboardTargetService.postDashboardTarget(result).subscribe({
          next: (response) => {
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Success, response?.message)
            );
            this.getReportingDashboard();
          },
          error: (error) => {
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
            this.sectionState = SectionStateStatus.Ready;
          },
        });
      }
    }
  }

  changeDashboardVisiblity(event, dashboardName) {
    this.visiablityData = [
      ...this.visiablityData.filter((o) => o.dashboardName !== dashboardName),
      {
        ...{
          dashboardName: dashboardName,
          isVisible: event.checked,
        },
      },
    ];
  }

  saveVisibility() {
    if (this.visiablityData.length > 0) {
      this.sectionState = SectionStateStatus.Loading;
      this.reportingDashboardService
        .SaveVisibilityForDashboard(this.visiablityData)
        .subscribe({
          next: (res) => {
            this.visiablityData = [];
            this.GetCustomizedDashboardByUserId();
          },
          error: (error) => {
            this.feedBackService?.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
            this.sectionState = SectionStateStatus.Ready;
          },
        });
    }
  }

  // dialog actions
  openDialog(data) {
    (<HTMLStyleElement>document.querySelector('.bottomSheet')).style.bottom =
      '0px';
    (<HTMLStyleElement>document.querySelector('.bg')).style.display = 'block';
    this.showFullScreen = true;

    this.selectedDashboard = data.dashboard;
    this.filterData = data.filterData;
  }
  closeDialog() {
    this.currentPosition = 0;
    this.startPosition = 0;
    (<HTMLStyleElement>document.querySelector('.bottomSheet')).style.bottom =
      '-100%';
    (<HTMLStyleElement>document.querySelector('.bottomSheet')).style.transform =
      'translate3d(0px,0px,0px)';
    (<HTMLStyleElement>document.querySelector('.bg')).style.display = 'none';
    this.showFullScreen = false;
    this.GetCustomizedDashboardByUserId();
  }
  touchmove(event: TouchEvent) {
    if (this.startPosition == 0) {
      this.startPosition = event.touches[0].clientY;
    }
    this.height = document.querySelector('.bottomSheet').clientHeight;

    var y = event.touches[0].clientY;
    this.currentPosition = y - this.startPosition;
    if (this.currentPosition > 0 && this.startPosition > 0) {
      (<HTMLStyleElement>(
        document.querySelector('.bottomSheet')
      )).style.transform = 'translate3d(0px,' + this.currentPosition + 'px,0px';
    }
  }
  touchend() {
    this.minimumThreshold = this.height - 130;
    if (this.currentPosition < this.minimumThreshold) {
      (<HTMLStyleElement>(
        document.querySelector('.bottomSheet')
      )).style.transform = 'translate3d(0px,0px,0px)';
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1800) {
      this.columns = 4;
    } else if (this.screenWidth > 1024 && this.screenWidth <= 1800) {
      this.columns = 3;
    } else if (this.screenWidth > 760 && this.screenWidth <= 1024) {
      this.columns = 2;
    } else if (this.screenWidth <= 760) {
      this.columns = 1;
    }
  }
}
