import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'console';
import { Subject, takeUntil } from 'rxjs';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { UserService } from 'src/backend/services/user.service';
import { VideoAnalyticsService } from 'src/backend/services/video-analytics/video-analytics.service';
import { Camera } from '../../models/video-analytics';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-edit-video-analytics',
  templateUrl: './edit-video-analytics.component.html',
  styleUrls: ['./edit-video-analytics.component.scss'],
})
export class EditVideoAnalyticsComponent implements OnInit {
  @Input() cameraInfo!: Camera;
  cameraForm!: FormGroup;
  departments: any[] = [];
  users: any[] = [];
  sessionCompany: any;
  private destroy$ = new Subject();
  public data: Camera;
  public translationsList: any = {};
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  hasCreateUserPermissions = ApplicationPermission.USER_CREATE;
  hasDepartmentPermission = ApplicationPermission.DEPARTMENTS_CREATE;

  constructor(
    private fb: FormBuilder,
    private departmentService: DepartmentsService,
    private feedBackService: AsyncFeedbackService,
    private userService: UserService,
    private baseApi: BaseApi,
    private router: Router,
    private videoAnalyticsService: VideoAnalyticsService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService
  ) {
    this.translate
      .get(['Errors', 'Success', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.getSessionOrganization();
    this.cameraForm = this.fb.group({
      cameraId: ['', Validators.required],
      department: ['', Validators.required],
      cameraName: ['', Validators.required],
      additionalApprv: ['', Validators.required],
      cameraPosition: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cameraInfo']) {
      this.data = this.cameraInfo;
      this.getDepartments();
    }
  }

  getSessionOrganization(): void {
    this.sessionCompany = this.baseApi.getUserSession();
  }

  getDepartments(): void {
    this.departmentService.getDepartmentsParentChild().subscribe({
      next: (response) => {
        this.departments = response;
        if (
          this.departments.length == 1 &&
          this.departments[0].parentName == '---Parent Department---' &&
          this.departments[0].detail == null
        ) {
          this.departments = [];
        }
        this.getUsers();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getUsers(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.users = response;
        this.formBuild();
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  configureCamera() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.editCamera();
        }
      });
  }

  editCamera(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    const {
      cameraId,
      department,
      cameraName,
      additionalApprv,
      cameraPosition,
    } = this.cameraForm.value;
    const depart = this.departments
      .flatMap((x) => x.detail)
      .find((c) => c.departmentCode === department);
    const userName: any[] = this.users
      .filter((x) => additionalApprv.includes(x.userId))
      .map((y) => y.fullName);
    const obj = {
      id: this.data?.id,
      cameraName: cameraName,
      additionalApproveId: additionalApprv.toString(),
      additionalApprovers: userName.toString(),
      departmentId: department,
      departmentName: depart.departmentName,
      position: cameraPosition,
      CreatedBy: this.sessionCompany.userId,
    };
    this.videoAnalyticsService.updateCamera(obj).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['UpdateMessage']
          )
        );
        this.sectionState = SectionStateStatus.Ready;
        this.viewCamera(this.data?.id);
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  formBuild(): void {
    this.cameraForm.get('cameraId').setValue(this.data?.cameraId);
    this.cameraForm.get('department').setValue(this.data?.departmentId);
    this.cameraForm.get('cameraName').setValue(this.data?.cameraName);
    this.cameraForm
      .get('additionalApprv')
      .setValue(this.data?.additionalApprovers.split(','));
    this.cameraForm.get('cameraPosition').setValue(this.data?.cameraPosition);
  }

  viewCamera(id: number): void {
    this.router.navigate(
      [
        '/' +
          SharedConstants.SMART_MONITORING +
          '/' +
          SharedConstants.VIDEO_ANALYTICS +
          '/' +
          SharedConstants.VIDEO_ANALYTICS_VIEW +
          '/' +
          id,
      ],
      {
        queryParams: { editMode: false },
      }
    );
  }
}
