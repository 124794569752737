<div *ngIf="levelsForm.value['levels'].length > 0">
  <div class="flex mt-2 mb-2">
    <!-- <h1>{{ "Dashboards.SafetyTriangle" | translate }}</h1> -->
    <div
      class="w-50 text-center"
      class="status"
      *ngIf="safetyTriangleData?.mode == 'MANUAL'"
    >
      <span>Active Mode</span>
    </div>
  </div>

  <div class="flex justify-between">
    <div class="w-4/12">
      <form [formGroup]="levelsForm">
        <div formArrayName="levels">
          <ol>
            <li
              *ngFor="let levelCtrl of levelsFormArray.controls; let i = index"
            >
              <div [formGroupName]="i">
                <div class="field-full flex column m-1">
                  <mat-label *ngIf="i === 0">1st level*</mat-label>
                  <mat-label class="field-label" *ngIf="i === 1"
                    >2nd level*</mat-label
                  >
                  <mat-label class="field-label" *ngIf="i === 2"
                    >3rd level*</mat-label
                  >
                  <mat-label class="field-label" *ngIf="i > 2">{{
                    i + 1 + "th level"
                  }}</mat-label>
                  <div class="flex items-center">
                    <div class="flex">
                      <mat-form-field
                        class="input-field w-8/12 m-1"
                        appearance="outline"
                      >
                        <input
                          formControlName="name"
                          [placeholder]="'Placeholder.LevelTitle' | translate"
                          matInput
                          type="text"
                          maxlength="200"
                          (change)="getFormValues()"
                        />
                        <mat-error
                          *ngIf="
                            levelsForm.invalid &&
                            levelCtrl.get('name').hasError('required')
                          "
                        >
                          <div
                            class="flex justify-flex-start align-flex-center"
                          >
                            <img
                              class="error-img"
                              src="assets/img/resources/error-icon.svg"
                            />
                            <span>{{ "Errors.Required" | translate }}</span>
                          </div>
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field
                        class="input-field w-4/12 m-1"
                        appearance="outline"
                      >
                        <input
                          [max]="10000000"
                          oninput="validity.valid||(value='');"
                          formControlName="manualValue"
                          [placeholder]="'Placeholder.LevelValue' | translate"
                          matInput
                          type="number"
                          (keyup)="getFormValues()"
                        />
                        <mat-error
                          *ngIf="
                            levelsForm.invalid &&
                            levelCtrl.get('manualValue').hasError('required')
                          "
                        >
                          <div
                            class="flex justify-flex-start align-flex-center"
                          >
                            <img
                              class="error-img"
                              src="assets/img/resources/error-icon.svg"
                            />
                            <span>{{ "Errors.Required" | translate }}</span>
                          </div>
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <button
                      *ngIf="i !== 0 && i !== 1 && i !== 2"
                      color="primary"
                      (click)="removeLevel(i)"
                      mat-icon-button
                      [matTooltip]="'General.Buttons.Remove' | translate"
                      type="button"
                    >
                      <mat-icon svgIcon="mat:cancel"></mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </form>
      <div class="flex justify-end">
        <button
          *ngIf="numOfLevels <= 6"
          (click)="onAddLevel()"
          class="ml-4 flex-none"
          color="primary"
          mat-mini-fab
          [matTooltip]="'Placeholder.AddLevel' | translate"
          type="button"
        >
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div>
    </div>
    <div>
      <app-safety-triangle [chartData]="data"></app-safety-triangle>
    </div>
  </div>
  <div class="flex mb-2">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="navigateBack()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="safetyTriangleData?.mode == 'MANUAL' ? 'Save' : 'Enable'"
      [isDisabled]="true"
      (onClick)="updateSafetyTriangle(safetyTriangleData?.id)"
      [isDisabled]="levelsForm.invalid"
    ></app-basic-button>
  </div>
</div>
