<div class="w-full h-full bg-gradient-to-r from-gray-200 to-gray-300">
  <div class="container items-center justify-center">
    <div class="flex items-center justify-center text-center pt-20">
      <img src="assets/img/resources/Sanedlogo.png" width="200" />
    </div>
    <form class="flex flex-row mt-8" [formGroup]="form">
      <div class="flex column ml-auto mr-auto w-1/5">
        <mat-form-field appearance="outline">
          <mat-label class="field-label">Your Email</mat-label>
          <input
            matInput
            type="email"
            [placeholder]="'Placeholder.EnterYourEmail' | translate"
            formControlName="email"
            autocomplete="email"
          />
          <mat-error
            *ngIf="form.invalid && form.get('email').hasError('required')"
            >{{ "Errors.Required" | translate }}
          </mat-error>
          <mat-error
            *ngIf="form.invalid && form.get('email').hasError('invalidEmail')"
            >{{ "Errors.InvalidEmail" | translate }}</mat-error
          >
        </mat-form-field>
      </div>
    </form>
    <div class="flex justify-center">
      <button
        color="primary"
        mat-flat-button
        class="mt-4"
        type="button"
        (click)="confirmEmailClick()"
        [disabled]="!form.valid"
        *ngIf="!isSignInEnabled"
      >
        Confirm Your Email
      </button>
      <button
        color="primary"
        mat-flat-button
        class="mt-4"
        type="button"
        *ngIf="isSignInEnabled"
        (click)="goToLogin()"
      >
        Sign In
      </button>
    </div>
    <app-dismiss-alert
      *ngIf="successOrErrorMessage"
      [message]="successOrErrorMessage"
      [isError]="isError"
    ></app-dismiss-alert>
  </div>
</div>
