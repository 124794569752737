export class PostIdCard {
    id?: number;
    name: string;
    jobTitle: string;
    phoneNumber: string;
    departmentCode: string;
    siteAccessComment: string;
    carModel: string;
    carColor: string;
    plateNo: string;
    carComment: string;
    siteAccessInformation: SiteAccessInfo[];
}

export class SiteAccessInfo {
    companyCode: string;
    departmentCode: string;
}

export interface GetIdCard {
    id: number;
    name: string;
    code: string;
    jobTitle: string;
    phoneNumber: string;
    departmentCode: string;
    status: string;
    createdOn: Date;
    updatedOn: Date;
    department: string;
    email: string;
    role: string;
    idCardCode: string;
}

export interface GetIdCardById {
    id: number;
    name: string;
    jobTitle: string;
    phoneNumber: string;
    departmentId: string;
    profileImage: string;
    profilePath: string;
    siteAccessComment: string;
    carModel: string;
    carColor: string;
    plateNumber: string;
    carComment: string;
    siteAcessInfoList: SiteAccessInfo[];
    documentList: IdCardDocuments[];
}

export interface SiteAccessInfo {
    siteAccessId: number;
    companyCode: string;
    departmentCode: string;
}

export interface IdCardDocuments {
    documentId: number;
    type: string;
    documentPath: string;
    filePath: string;
}

export interface GetReNewIdCardById {
    id?: number;
    name: string;
    code: string;
    jobTitle: string;
    phoneNumber: string;
    departmentCode: string;
    department: string;
    status: string;
    createdOn: Date;
    updateOn: Date;
    email: string;
    role: string;
    idCardCode: string;
    profilePath: string;
}