import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { LoadingStatus } from '../models/shared.enum';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  // private fields
  private loadingStatus = new Subject<LoadingStatus>();

  // public fields
  public loadingStatus$ = this.loadingStatus.asObservable();

  public loadingLabel = Constants.LOADING_DATA;

  constructor() { }

  // actions
  public startLoading(isTransparent = true, label = Constants.LOADING_DATA) {
    this.loadingLabel = label;
    if (isTransparent === true) {
      this.loadingStatus.next(LoadingStatus.VisibleTransparent);
    } else {
      this.loadingStatus.next(LoadingStatus.Visible);
    }
  }

  public stopLoading() {
    this.loadingStatus.next(LoadingStatus.Hidden);
  }

}
