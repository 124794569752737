import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filterPermissions } from 'src/@hodhod/common/custom_methods';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { RoleService } from 'src/backend/services/role.service';
import { ParentRole } from '../../models/parent-role';
import { RoleDetail } from '../../models/role-detail';
import { UserRole } from '../../models/user-role';

@Component({
  selector: 'app-role-view-page',
  templateUrl: './role-view-page.component.html',
  styleUrls: ['./role-view-page.component.scss'],
})
export class RoleViewPageComponent implements OnInit {
  private destroy$ = new Subject();
  roleId: string = '';
  roleName: string = '';
  isExternal: boolean;

  public rolePermissions: ParentRole[] = [];
  public roleUsers: UserRole[] = [];
  public roleDetails: RoleDetail;
  public permissionBlocks: [];

  private translationsList: any = {};

  constructor(
    private route: ActivatedRoute,
    private roleService: RoleService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private loadingService: LoadingService
  ) {
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const roleId: string = params.get('id');
        this.roleId = roleId;
        this.getRoleDetails();
      }
    });
  }

  getRoleDetails(): void {
    this.loadingService.startLoading(true, '');
    this.roleService.getRoleDetails(this.roleId).subscribe({
      next: (response) => {
        this.roleName = response?.roleName;
        this.isExternal = response?.isExternal;
        this.permissionBlocks = response['permissonBlocks'];
        this.roleUsers = response.users;
        this.roleDetails = response;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  saveChangesPermissions(event: any): void {
    event.map((block) =>
      block.permissions.map((row) => {
        delete row.options;
        delete row.selectedOption;
      })
    );

    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const updatedRole = {
            roleName: this.roleName,
            isExternal: this.isExternal,
            permissionBlocks: event,
          };
          this.roleService.savePermissions(updatedRole, this.roleId).subscribe({
            next: (res) => {
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getRoleDetails();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  onChangeName(event) {
    this.roleName = event;
  }

  onChangeExternal(event) {
    this.isExternal = event;
  }
}
