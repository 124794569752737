<div *ngFor="let item of sortedSafetyKpisArray">
  <div class="relative mb-5" *ngIf="item.isActive">
    <div
      class="border-black border-2 rounded-3xl first-div ms-7 py-2 px-5 bg-white flex column justify-between items-center"
    >
      <div class="flex crd items-center w-full">
        <h2 class="font-semibold leading-tight break-words" [ngClass]="item.mode === 'AUTO'?'w-9/12':null">
          {{ "SafetyKPIs." + item?.type | translate }}
        </h2>
        <div class="flex absolute end-5" *ngIf="item.mode === 'AUTO'">
          <button mat-icon-button (click)="select.open()">
            <img
              id="filterDashboard"
              class="fill-primary"
              style="width: 20px; min-width: 20px; height: 20px"
              src="../../../../assets/img/resources/calendar-icon-not-active.svg"
              alt="calender"
            />
          </button>
          <mat-select
            #select
            class="select-options"
            (selectionChange)="onFilterChange($event.value, item?.type)"
          >
            <mat-option
              *ngFor="let filter of filterDashboards | enumToArray"
              [value]="filter.value"
              (click)="
                filter.key === 'CustomRange'
                  ? onSelectCustomRange(item?.type)
                  : ''
              "
            >
              {{ "Enum.DashboardFilters." + filter.value | translate }}
            </mat-option>
          </mat-select>

          <button
            class="fill-primary"
            mat-button
            (click)="openBottomSheet(item?.type, item?.date)"
          >
            <img
              id="fullScreenDashboard"
              src="../../../../assets/img/resources/fullscreen.svg"
              alt="full-screen"
            />
          </button>
        </div>
      </div>
      <div
        class="border border-black rounded-xl p-1 text-center w-5/12 mx-auto"
      >
        <p>{{ item?.value }}</p>
      </div>
      <p class="text-2xs text-gray-500" *ngIf="item.mode === 'AUTO'">
        {{
          "From " +
            (item["date"]?.fromDate | date) +
            " To " +
            (item["date"]?.toDate | date)
        }}
      </p>
    </div>
    <div
      class="w-4/12 rounded-3xl sec-div"
      [style]="'background-color:' + item?.color + ';'"
    >
      <span
        class="vertical"
        [ngClass]="
          ('SafetyKPIs.titles.' + item?.type | translate).length === 5
            ? '-ml-1.5'
            : null
        "
        >{{ "SafetyKPIs.titles." + item?.type | translate }}</span
      >
    </div>
  </div>
</div>
<ng-template #expansion>
  <div class="overflow-auto p-5">
    <mat-card-title>
      {{ "SafetyKPIs." + kpiName | translate }}
    </mat-card-title>

    <app-state-section
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="loadingLabel | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <p class="text-sm text-gray-500" >
        Untill {{ filterationDate['toDate'] | date }}
      </p>
      <table
        @stagger
        [dataSource]="dataSource"
        class="w-full"
        mat-table
        matSort
        matSortDisabled
      >
        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container 
            *ngIf="
              column.type === 'text' && column.label !== 'IncidentReportTitle'
            "
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "SafetyKPIs.Table." + column.label | translate }}

            </th>
            
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <div class="w-10/12 truncate" #div>
                <span
                  [matTooltip]="
                    div?.offsetWidth < div?.scrollWidth
                      ? row[column.property]
                      : null
                  "
                  >{{ row[column.property] }}</span
                >
              </div>
            </td>
          </ng-container>
          <ng-container
            *ngIf="
              column.type === 'text' && column.label === 'IncidentReportTitle'
            "
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "SafemanHours.Table." + column.label | translate }}
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              mat-cell
              (click)="navigateToIncident(row?.id)"
            >
              <div class="w-10/12 truncate" #div>
                <span
                  class="text-primary underline"
                  [matTooltip]="
                    div?.offsetWidth < div?.scrollWidth
                      ? row[column.property]
                      : null
                  "
                  >{{ row[column.property] }}</span
                >
              </div>
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'date'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "SafetyKPIs.Table." + column.label | translate }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] | date : "dd-MM-yyyy" }}
            </td>
          </ng-container>
          <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            {{ "User.Table." + column.label | translate }}

          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center status"
              [attr.id]="row[column.property]"
            >
              <span>{{
                "Enum.ReportIncidentStatus." + row[column.property] | translate
              }}</span>
            </div>
          </td>
        </ng-container>

        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: visibleColumns"
          @fadeInUp
          class="hover:bg-hover trans-ease-out cursor-pointer"
          mat-row
        ></tr>
      </table>
      <div
        *ngIf="dataSource.filteredData.length === 0"
        @scaleFadeIn
        class="flex-auto flex flex-col items-center justify-center"
      >
        <img src="assets/img/resources/empty-state.svg" alt="not found" />
        <h2 class="headline m-0 text-center text-primary">
          {{ "General.EmptyTableList" | translate }}...
        </h2>
      </div>
      <button
      (click)="getPaginatedKpis(null,null,true)"
      [disabled]="noMoreData"
        class="bg-primary text-white rounded-md flex align-middle justify-center px-2 py-1 ms-auto disabled:bg-zinc-600 mt-4"
      >
        See More <mat-icon svgIcon="mat:arrow_right_alt"></mat-icon>
      </button>
    </app-state-section>
  </div>
</ng-template>
