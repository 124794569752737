import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LanguageService } from 'src/@hodhod/services/language.service';
import { BreadCrumb } from '../models/breadcrumb';
import { SharedConstants } from '../models/shared-constants';


@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbList$: BehaviorSubject<BreadCrumb[]> = new BehaviorSubject([]);
  visibility$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private breadcrumbList = [];

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private languageService: LanguageService) {
    this.breadcrumbList = JSON.parse(localStorage.getItem(SharedConstants.BREADCRUMB))
      ? JSON.parse(localStorage.getItem(SharedConstants.BREADCRUMB))
      : [];
    this.buildBreadCrumb();
    this.languageService.currentLanguage$.subscribe(_ => {
      // TODO: Handle breadcrumb when language changed
    });
  }

  buildBreadCrumb() {
    let routerPath: string;
    this.router.events.subscribe((router: any) => {
      if (router instanceof NavigationEnd) {
        routerPath = router.urlAfterRedirects;
        const breadcrumbObj =
          this.activatedRoute.children[0]?.children[0]?.children[0]?.routeConfig?.data &&
            this.activatedRoute.children[0]?.children[0]?.children[0]?.routeConfig?.data['breadcrumb']
            ? this.activatedRoute.children[0]?.children[0]?.children[0]?.routeConfig?.data['breadcrumb']
            : null;
        if (breadcrumbObj) {
          const firstLevel = breadcrumbObj['isFirstLevel'] ?? false;
          const secondLevel = breadcrumbObj['isSecondLevel'] ?? false;
          const labelKey = breadcrumbObj['labelKey'] ?? '';
          const isActive = breadcrumbObj['isActive'] ?? false;
          const isVisible = breadcrumbObj['isVisible'] ?? true;
          if (firstLevel) {
            this.breadcrumbList = [];
          } else if (secondLevel) {
            this.breadcrumbList.splice(1);
          } else {
            const currentPathIndex = this.breadcrumbList.findIndex((item) => item.labelKey === labelKey);
            if (currentPathIndex !== -1) {
              this.breadcrumbList.splice(currentPathIndex);
            }
          }

          const breadcrumb = new BreadCrumb(labelKey, routerPath, false, isActive);
          this.breadcrumbList.push(breadcrumb);
          this.breadcrumbList$.next(this.breadcrumbList);
          localStorage.setItem(SharedConstants.BREADCRUMB, JSON.stringify(this.breadcrumbList));
          this.visibility$.next(isVisible);
        } else {
          this.breadcrumbList = [];
          this.breadcrumbList$.next(this.breadcrumbList);
          localStorage.setItem(SharedConstants.BREADCRUMB, JSON.stringify(this.breadcrumbList));
        }
      }

    });
  }

  public insertItemBeforLast(text: string) {
    const list = this.breadcrumbList$.value;
    if (list.find(item => item.labelKey === text)) { return; }
    list.splice(list.length - 1, 0, new BreadCrumb(text, '', true, false));
    this.breadcrumbList$.next(list);
  }

  public changeBreadcrumbItem(text: string, index) {
    const list = this.breadcrumbList$.value;
    list[index] = new BreadCrumb(text, '', true, false);
    this.breadcrumbList$.next(list);
  }

  public changeTheLastItem(text: string, isActive = false) {
    const list = this.breadcrumbList$.value;
    list[list.length - 1].isValue = true;
    list[list.length - 1].labelKey = text;
    list[list.length - 1].isActive = isActive;
    this.breadcrumbList$.next(list);
  }
}
