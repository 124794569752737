import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DigitalIotDashboard } from '../../models/dashboard';

@Component({
  selector: 'app-digital-iot-dashboard',
  templateUrl: './digital-iot-dashboard.component.html',
  styleUrls: ['./digital-iot-dashboard.component.scss'],
})
export class DigitalIotDashboardComponent implements OnInit, OnChanges {
  @Input() data: DigitalIotDashboard;
  displayedColumns: string[] = ['criticalParameter', 'liveStatus', 'alerts'];

  public visibleDigitalParameters;
  constructor() {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue) {
      this.visibleDigitalParameters = this.data.digitalReadings.filter(
        (item) => item.isVisible
      );
    }
  }
}
