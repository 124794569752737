import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { enumToArray } from 'src/@hodhod/common/custom_methods';
import { HistoryTypes, ReportingTypes } from 'src/@hodhod/common/enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';

import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { Confirmation, ConfirmationType } from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { GetAllReportingType } from 'src/backend/models/reporting-type/reporting-type.model';
import { ReasonsService } from 'src/backend/services/reasons/reasons.service';
import { ReportingTypeService } from 'src/backend/services/reporting-type/reporting-type.service';

@Component({
  selector: 'app-add-update-reason',
  templateUrl: './add-update-reason.component.html',
  styleUrls: ['./add-update-reason.component.scss']
})
export class AddUpdateReasonComponent implements OnInit {
  reasonForm: FormGroup;
  toppingList: any[] = [];
  formMode: any;
  private destroy$ = new Subject();
  public translationsList: any = {};
  reporting: any[] = enumToArray(ReportingTypes).filter(x => x != 'ALL');
  HistoryType = HistoryTypes.REASONS;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AddUpdateReasonComponent>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private reasonService: ReasonsService,
    private feedBackService: AsyncFeedbackService,
    private reportingtypeService: ReportingTypeService
  ) {
    this.translate.get(['Errors', 'Success', 'Role', 'User']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
      this.translationsList = translations;
    });
  }

  ngOnInit() {

    let ids: number[];
    if (this.defaults.action === 'update') {
      this.formMode = 'update';
    } else if (this.defaults.action === 'view') {
      this.formMode = 'view';
    } else if (this.defaults.action === 'create') {
      this.formMode = 'create'
      this.defaults.reason = {} as any;
    }
    this.reasonForm = this.fb.group({
      causeName: [this.defaults.reason.causeName || '',
      [Validators.required, noWhitespaceValidator]],
      reportingtypeId: ['', [Validators.required]]
    });
    if (this.defaults.reason.reportingType) {
      this.toppingList = this.defaults.reason.reportingType.split(',');
      const index = this.toppingList.indexOf('ALL');
      if (index > -1) {
        this.toppingList.splice(index, 1);
      }
      if (this.toppingList.length == this.reporting.length) {
        this.toppingList.push('ALL');
      }
      this.reasonForm.get('reportingtypeId').setValue(this.toppingList);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createReason();
          }
        });
    } else if (this.formMode === 'update') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['Role']['UpdateConfirm']['Title'],
            this.translationsList['Role']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.updateReason();
          }
        });
    }
  }

  selectedvalue(e: any) {
    debugger
    const typeId = this.reasonForm.get('reportingtypeId').value;
    const types = this.reporting.slice();
    const all = typeId.find(x => x == 'ALL');
    if (all == 'ALL') {
      types.push('ALL');
      this.reasonForm.get('reportingtypeId').setValue(types);
    } else {
      this.reasonForm.get('reportingtypeId').setValue('');
    }
  }

  selectAll(e: any) {
    debugger
    const typeId = this.reasonForm.get('reportingtypeId').value;
    const index = typeId.indexOf('ALL');
    if (index > -1) {
      typeId.splice(index, 1);
    }
    if (typeId.length == this.reporting.length) {
      typeId.push('ALL');
      this.reasonForm.get('reportingtypeId').setValue(typeId);
    } else {
      this.reasonForm.get('reportingtypeId').setValue(typeId);
    }
  }


  createReason() {
    const { causeName, reportingtypeId } = this.reasonForm.value;
    let typeId = [];
    const all = reportingtypeId.find(x => x == 'ALL');
    if (all == 'ALL') {
      typeId = this.reporting.filter(x => x != 'ALL');
    } else {
      typeId = reportingtypeId;
    }
    const data = {
      causeName,
      reportingTypes: typeId.toString(),
      companyId: '2'
    };
    this.loadingLabel = "General.Refreshing";
    this.sectionState = SectionStateStatus.Loading;
    this.reasonService.postReason(data).subscribe({
      next: () => {
        this.dialogRef.close(data);
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, this.translationsList['Success']['SuccessMessage'])
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
      }
    });
  }

  updateReason() {
    const { causeName, reportingtypeId } = this.reasonForm.value;
    let typeId = [];
    const all = reportingtypeId.find(x => x == 'ALL');
    if (all == 'ALL') {
      typeId = this.reporting.filter(a => a != 'ALL');
    } else {
      typeId = reportingtypeId;
    }
    const data = {
      id: this.defaults.reason.id,
      causeName,
      reportingTypes: typeId.toString(),
      companyId: '2'
    };
    this.loadingLabel = "General.Refreshing";
    this.sectionState = SectionStateStatus.Loading;
    this.reasonService.updateReason(data).subscribe({
      next: () => {
        this.dialogRef.close(data);
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, this.translationsList['Success']['UpdateMessage'])
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
      }
    });
  }

  isCreateMode() {
    return this.formMode === 'create';
  }

  isUpdateMode() {
    return this.formMode === 'update';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }



}
