<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="
    (formMode === 'create'
      ? 'Role.AddingLoadingRole'
      : 'Role.UpdatingLoadingRole'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="incidentClassificationForm">
    <div class="flex items-center" mat-dialog-title>
      <h2
        *ngIf="incidentClassificationForm.get('name').value"
        class="headline m-0 flex-auto"
      >
        {{ incidentClassificationForm.get("name").value }}
      </h2>
      <h2
        *ngIf="!incidentClassificationForm.get('name').value"
        class="headline m-0 flex-auto"
      >
        {{ "Role.IncidentClassificationInfo" | translate }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex flex-col flex-wrap justify-between">
      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "Role.IncidentClassificationName" | translate }}
          <span
            [ngClass]="
              incidentClassificationForm.invalid &&
              incidentClassificationForm.get('name').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            [readonly]="this.formMode === 'view'"
            matInput
            type="text"
            formControlName="name"
          />
          <mat-error
            *ngIf="
              incidentClassificationForm.invalid &&
              incidentClassificationForm.get('name').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              incidentClassificationForm.invalid &&
              incidentClassificationForm.get('name').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <!-- History -->
    <div class="mt-4" *ngIf="this.formMode === 'view'">
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="
          'IncidentClassification.IncidentClassificationHistory' | translate
        "
      >
        <app-history-log
          body
          *ngIf="this.defaults.incident"
          [sourceId]="this.defaults.incident.id"
          [sourceType]="HistoryType"
        ></app-history-log>
      </app-expandable-panel>
    </div>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isCreateMode()"
          [btnType]="'Add'"
          [btnText]="'Add'"
          [isDisabled]="!incidentClassificationForm.valid"
          (onClick)="save()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isUpdateMode()"
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="!incidentClassificationForm.valid"
          (onClick)="save()"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
