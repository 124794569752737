<app-card [title]="'ManageAccessControl.UserDetails.Title' | translate">
  <div body class="flex items-center flex-wrap-reverse">
    <div>
      <ngx-avatars
        class="avatar box"
        size="150"
        [src]="imageUrl"
        [name]="userDetails?.fullName"
        [textSizeRatio]="3"
        [initialsSize]="2"
        [round]="true"
      ></ngx-avatars>
    </div>
    <div class="mx-5">
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ userDetails?.fullName }}</p>
          <p class="m-0 caption text-hint">
            {{ "ManageAccessControl.UserDetails.Name" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">
            {{ userDetails?.email }}
          </p>
          <p class="m-0 caption text-hint">
            {{ "ManageAccessControl.UserDetails.Email" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
</app-card>
