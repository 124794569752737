import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checklist-section-page',
  templateUrl: './checklist-section-page.component.html',
  styleUrls: ['./checklist-section-page.component.scss']
})
export class ChecklistSectionPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
