import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import {
  Analog,
  TargetCalculation,
  TargetDashboardType,
  UptoAtLeast,
} from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { DashboardTargetService } from 'src/backend/services/dashboard-target/dashboard-target.service';

@Component({
  selector: 'app-add-dashboard-target',
  templateUrl: './add-dashboard-target.component.html',
  styleUrls: ['./add-dashboard-target.component.scss'],
})
export class AddDashboardTargetComponent implements OnInit {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  targetForm: FormGroup;
  machines: any[] = [];
  machineId: number;
  parameters: any[] = [];
  showPerformance: boolean = false;
  showAnalog: boolean = false;
  showSameTarget: boolean = false;
  showDifferentTarget: boolean = false;
  private destroy$ = new Subject();
  public translationsList: any = {};

  // Enum
  TargetDashboardType = TargetDashboardType;
  uptoAtLeast = UptoAtLeast;
  ContentAnalog = Analog;
  TargetCalculation = TargetCalculation;
  showDigital: boolean = false;

  constructor(
    private fb: FormBuilder,
    private checklistService: ChecklistSectionService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private dashboardTargetService: DashboardTargetService,
    private router: Router
  ) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.targetForm = this.fb.group({
      type: ['', [Validators.required]],
      target: ['', [Validators.required]],
      uptoAtleast: ['', [Validators.required]],
    });
  }

  cancelForm(): void {
    this.targetForm.reset();
    this.router.navigate([
      '/' +
        SharedConstants.DASHBOARD_PATH +
        '/' +
        SharedConstants.DASHBOARD_TARGET,
    ]);
  }

  createDashboardTarget(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const data = {
            id: 0,
            trendingType: this.targetForm.value.type,
            target: this.targetForm.value.target,
            uptoAtLeast: this.targetForm.value.uptoAtleast,
          };
          this.loadingService.startLoading(true, '');
          this.dashboardTargetService.postDashboardTarget(data).subscribe({
            next: (response) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
              this.loadingService.stopLoading();
              this.router.navigate([
                '/' +
                  SharedConstants.DASHBOARD_PATH +
                  '/' +
                  SharedConstants.DASHBOARD_TARGET,
              ]);
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }
}
