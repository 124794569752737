import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import {
  ChecklistType,
  CompanyUserStatus,
  InspectionFrequencyTypes,
  SortDirection,
} from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import { PermissionService } from 'src/backend/services/permission.service';
import { MachineAndEquipment } from '../../models/machine-and-equipment';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { AddUpdateEquipmentCategoryComponent } from '../add-update-equipment-category/add-update-equipment-category.component';
import { LoadingService } from 'src/app/shared/helpers/loading.service';

@UntilDestroy()
@Component({
  selector: 'app-equipments-categories',
  templateUrl: './equipments-categories.component.html',
  styleUrls: ['./equipments-categories.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class EquipmentsCategoriesComponent
  extends BaseComponent
  implements OnChanges, OnInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('activateModal') activateModal;

  equipmentsCategories: any[];

  columns: TableColumn<any>[] = [
    {
      label: 'Image',
      property: 'imageUrl',
      type: 'image',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Name',
      property: 'name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Code',
      property: 'code',
      type: 'text',
      visible: true,
    },
    {
      label: 'Quantity',
      property: 'equipmentQuantity',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Inspectors',
      property: 'inspectors',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'CategoryInCharge',
      property: 'reviewers',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'InspectionChecklist',
      property: 'checklists',
      type: 'boolean',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<MachineAndEquipment>([]);
  selection = new SelectionModel<MachineAndEquipment>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  loadingLabel: string = '';
  timer = null;

  formMode: 'create' | 'update' = 'create';
  equipmentCategoryId: number = 0;
  checklists: any[] = [];

  CompanyUserStatus = CompanyUserStatus;
  InspectionFrequencyTypes = InspectionFrequencyTypes;

  public selectedStatus: string[] = [];
  public selectedChecklist: number[] = [];
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  public activateCategoryForm: FormGroup;
  tomorrowDate: any = null;
  selectedCategoryId: number;

  private translationsList: any = {};

  hasViewPermissions = ApplicationPermission.MACHINE_ACCESS;
  hasWritePermissions = ApplicationPermission.MACHINE_CREATE;
  hasEditPermissions = ApplicationPermission.MACHINE_UPDATE;
  hasActivatePermissions = ApplicationPermission.MACHINE_ACTIVATE;

  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private router: Router,
    private machineService: MachineEquipmentService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private permissionService: PermissionService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private checklistService: ChecklistSectionService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {}

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  override ngOnInit(): void {
    this.activateCategoryForm = this.fb.group({
      inspectionStartDate: ['', Validators.required],
    });
    const currentDate = new Date();
    currentDate.setDate(new Date().getDate() + 1);
    this.tomorrowDate = currentDate.toISOString();

    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);
    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.selectedChecklist = this.currentQueryParams?.selectedChecklist
      ? this.currentQueryParams?.selectedChecklist.split(',').map(Number)
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));

    this.getData();
    this.getChecklistList();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getChecklistList() {
    this.checklistService
      .getAllCheckListForDropdown(ChecklistType.Inspection)
      .subscribe((res) => {
        this.checklists = res;
      });
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }

    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      status: this.currentQueryParams?.orgStatus,
      checklists: this.currentQueryParams?.selectedChecklist,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.machineService.getEquipmentsCategories(filteredParams).subscribe({
      next: (response) => {
        this.equipmentsCategories = response?.data;
        const tableData = this.equipmentsCategories.map((item) => {
          return {
            ...item,
            checklists: item.checklists
              .map((el) => el.checkListName)
              .toString(),
            reviewers: item.reviewers.map((el) => el.reviewerName).toString(),
            inspectors: item.inspectors
              .map((el) => el.inspectorName)
              .toString(),
          };
        });
        this.dataSource = new MatTableDataSource(tableData);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onSelectMachineStatus(event: MatSelectChange) {
    this.selectedStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  onFilterChecklist(event: MatSelectChange) {
    this.selectedChecklist = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      selectedChecklist: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  openCreateMachineModal(): void {
    this.formMode = 'create';
    const dialog = this.dialog.open(AddUpdateEquipmentCategoryComponent, {
      height: 'auto',
      width: '60%',
      disableClose: false,
      data: {
        formMode: this.formMode,
      },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res.created) {
        this.getData();
      }
    });
  }

  openEditMachineModal(event: any, equipmentCategoryId: number) {
    this.formMode = 'update';
    this.equipmentCategoryId = equipmentCategoryId;
    const dialog = this.dialog.open(AddUpdateEquipmentCategoryComponent, {
      height: 'auto',
      width: '60%',
      disableClose: false,
      data: {
        formMode: this.formMode,
        equipmentCategoryId: this.equipmentCategoryId,
      },
    });

    dialog.afterClosed().subscribe((res) => {
      if (res.updated) {
        this.getData();
      }
    });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  deactivateEquipments(event: any, equipmentCategoryId: number): void {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.machineService
            .deactivateEquipmentCategory(equipmentCategoryId)
            .subscribe({
              next: (res) => {
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.getData();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
        }
      });
  }

  openActivateModal(equipmentCategoryId: number) {
    this.selectedCategoryId = equipmentCategoryId;
    this.dialog.open(this.activateModal, {
      height: 'auto',
      width: '60%',
    });
  }

  activateEquipments(): void {
    this.loadingService.startLoading(true);
    const data = {
      inspectionStartDate: this.activateCategoryForm
        .get('inspectionStartDate')
        .value.toISOString(),
    };
    this.machineService
      .activateEquipmentCategory(this.selectedCategoryId, data)
      .subscribe({
        next: (res) => {
          this.loadingService.stopLoading();
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
          this.closeDialog();
          this.getData();
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  viewEquipmentCategory(event: any, equipmentCategoryId: number): void {
    this.router.navigate(
      [
        '/' +
          SharedConstants.COMPANY_SETUP +
          '/' +
          SharedConstants.CATEGORIES +
          '/' +
          equipmentCategoryId,
      ],
      {
        queryParams: {},
      }
    );
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { ...this.currentQueryParams },
    });
    this.getData();
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }
}
