<app-card [title]="'ManageAccessControl.MobileDevices.Title' | translate">
  <div body>
    <div *ngIf="mobileDevices?.length > 0; else noDataTemplate">
      <div
        class="mb-2 border rounded-[10px] p-2 min-h-[400px]"
        *ngFor="
          let device of mobileDevices;
          let i = index;
          trackBy: trackByIndex
        "
      >
        <div class="flex flex-col">
          <div class="flex gap-2 min-h-[380px]">
            <div class="w-1/5 p-4 space-y-2">
              <p class="font-bold">
                {{ device?.manufacturer }} {{ device?.model }}
              </p>
              <p>Device ID: {{ device?.id }}</p>
              <p>{{ device?.osVersion }}</p>
              <p>{{ device?.applicationVersion }}</p>
              <p>{{ device?.secureElementType }}</p>
              <p>
                NFC Capability:
                {{ device?.nfcCapability === "Y" ? "Yes" : "No" }}
              </p>
              <p>
                HCE Capability:
                {{ device?.hceCapability === "Y" ? "Yes" : "No" }}
              </p>
            </div>

            <div class="w-4/5">
              <div class="w-full h-full">
                <mat-tab-group
                  preserveContent
                  class="w-full h-full"
                  (selectedTabChange)="filterMobileIds($event, i)"
                >
                  <mat-tab
                    label="Current Mobile IDs"
                    [bodyClass]="'w-full h-full'"
                  >
                    <div
                      class="w-full h-full border flex rounded-[10px]"
                      *ngIf="device.credentials.length > 0; else NoDevices"
                    >
                      <button
                        class="hover:bg-[#F3F3F3]"
                        (click)="slideLeft(i, 'cards-container')"
                      >
                        <mat-icon
                          class="w-[40px] h-[40px]"
                          svgIcon="mat:keyboard_arrow_left"
                        ></mat-icon>
                      </button>
                      <div
                        #card
                        [id]="'cards-container' + i"
                        class="overflow-hidden flex gap-2 w-full"
                      >
                        <app-mobile-card-id
                          *ngFor="
                            let card of device.credentials;
                            trackBy: trackByIndex
                          "
                          [cardDetails]="card"
                          [userDetails]="userDetails"
                          (onInvokeCredential)="invokeCredential($event)"
                        ></app-mobile-card-id>
                      </div>
                      <button
                        class="hover:bg-[#F3F3F3]"
                        (click)="slideRight(i, 'cards-container')"
                      >
                        <mat-icon
                          class="w-[40px] h-[40px]"
                          svgIcon="mat:keyboard_arrow_right"
                        ></mat-icon>
                      </button>
                    </div>
                    <!-- no data -->
                    <ng-template #NoDevices>
                      <div
                        class="flex justify-center items-center border rounded-[10px]"
                      >
                        <div class="flex column items-center">
                          <img
                            style="width: 250px; margin: auto"
                            src="assets/img/no-dashboards.png"
                          />
                          <h1 style="color: #00000099; font-size: large">
                            {{
                              "ManageAccessControl.MobileDevices.NoDevices"
                                | translate
                            }}
                          </h1>
                        </div>
                      </div>
                    </ng-template>
                  </mat-tab>

                  <mat-tab
                    label="Revoked Mobile IDs"
                    [bodyClass]="'w-full h-full'"
                  >
                    <div
                      class="w-full h-full border flex rounded-[10px]"
                      *ngIf="device.credentials.length > 0; else NoDevices"
                    >
                      <button
                        class="hover:bg-[#F3F3F3]"
                        (click)="slideLeft(i, 'cards-container-revoked')"
                      >
                        <mat-icon
                          class="w-[40px] h-[40px]"
                          svgIcon="mat:keyboard_arrow_left"
                        ></mat-icon>
                      </button>
                      <div
                        #card
                        [id]="'cards-container-revoked' + i"
                        class="overflow-hidden flex gap-2 w-full"
                      >
                        <app-mobile-card-id
                          *ngFor="
                            let card of device.credentials;
                            trackBy: trackByIndex
                          "
                          [cardDetails]="card"
                          [userDetails]="userDetails"
                        ></app-mobile-card-id>
                      </div>
                      <button
                        class="hover:bg-[#F3F3F3]"
                        (click)="slideRight(i, 'cards-container-revoked')"
                      >
                        <mat-icon
                          class="w-[40px] h-[40px]"
                          svgIcon="mat:keyboard_arrow_right"
                        ></mat-icon>
                      </button>
                    </div>

                    <!-- no data -->
                    <ng-template #NoDevices>
                      <div
                        class="flex justify-center items-center border rounded-[10px]"
                      >
                        <div class="flex column items-center">
                          <img
                            style="width: 250px; margin: auto"
                            src="assets/img/no-dashboards.png"
                          />
                          <h1 style="color: #00000099; font-size: large">
                            {{
                              "ManageAccessControl.MobileDevices.NoDevices"
                                | translate
                            }}
                          </h1>
                        </div>
                      </div>
                    </ng-template>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>

          <div class="flex justify-end mt-2">
            <app-basic-button
              [btnType]="'Approve'"
              [btnText]="'IssueMobileId'"
              [isDisabled]="checkSelectedCards(i)"
              (onClick)="openIssueMobileId(i)"
            >
            </app-basic-button>
            <app-basic-button
              [btnType]="'Delete'"
              [btnText]="'DeleteDevice'"
              (onClick)="deleteDevice(device?.id)"
            >
            </app-basic-button>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noDataTemplate>
      <div class="flex justify-center items-center">
        <div class="flex column items-center">
          <img
            style="width: 250px; margin: auto"
            src="assets/img/no-dashboards.png"
          />
          <h1 style="color: #00000099; font-size: large">
            {{ "ManageAccessControl.MobileDevices.NoMobileIds" | translate }}
          </h1>
        </div>
      </div>
    </ng-template>
  </div>
</app-card>

<ng-template #issueMobileIdModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "ManageAccessControl.IssueMobileId" | translate }}
    </h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

  <mat-dialog-content class="flex flex-col flex-wrap justify-between">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div
        *ngFor="let card of filteredCards"
        (click)="toggleSelection(card)"
        [class.selected]="card.selected"
        class="border p-4 cursor-pointer transition hover:shadow-lg bg-[#F3F3F3] rounded-[10px]"
      >
        <div class="mb-2 flex">
          <img
            class="w-[30%] object-contain"
            src="assets/img/resources/saned-logo.png"
            alt="saned"
          />
        </div>

        <div class="flex items-center justify-between mt-4">
          <div class="flex items-center">
            <strong>{{ card?.friendlyName }}</strong>
          </div>
        </div>

        <div class="flex justify-between">
          <div class="flex items-end flex-grow">
            <img
              class="w-[20%]"
              src="assets/img/resources/hid-logo.svg"
              alt="saned"
            />
          </div>
          <div>
            <qrcode
              [qrdata]="'https://isaned.com/'"
              [width]="50"
              [margin]="0"
              [colorDark]="'#131C4E'"
              [errorCorrectionLevel]="'M'"
            ></qrcode>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="flex items-center">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel'"
        (onClick)="closeDialog()"
      ></app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'Add'"
        [isDisabled]="selectedCardsIds.length === 0"
        (onClick)="confirmIssueMobileId()"
      ></app-basic-button>
    </div>
  </mat-dialog-actions>
</ng-template>
