<div class="flex">
  <h1 class="m-auto">{{ data.targetTitle }}</h1>
</div>

<form [formGroup]="targetForm">
  <div class="flex flex-row justify-between">
    <div class="field-full flex ml-6 column">
      <mat-label class="field-label"
        >{{ "DashboardTarget.Table.UptoAtLeast" | translate }}
        <span
          [ngClass]="
            targetForm.invalid &&
            targetForm.get('uptoAtleast').hasError('required')
              ? 'is-invalid'
              : 'is-valid'
          "
          >*</span
        >
      </mat-label>
      <mat-form-field class="input-field" appearance="outline">
        <mat-select
          [placeholder]="'Placeholder.Select' | translate"
          formControlName="uptoAtleast"
        >
          <mat-option
            *ngFor="let type of uptoAtLeast | enumToArray"
            [value]="type.value"
          >
            {{ type.value }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="
            targetForm.invalid &&
            targetForm.get('uptoAtleast').hasError('required')
          "
        >
          <div class="flex justify-flex-start align-flex-center">
            <img class="error-img" src="assets/img/resources/error-icon.svg" />
            <span>{{ "Errors.Required" | translate }}</span>
          </div>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="field-full flex ml-6 column">
      <ng-template #target>
        <mat-label class="field-label"
          >{{ "DashboardTarget.YearlyTarget" | translate }}
          <span
            [ngClass]="
              targetForm.invalid &&
              targetForm.get('target').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
      </ng-template>
      <mat-label
        class="field-label"
        *ngIf="
          data.targetTitle == 'Audit Score' ||
            data.targetTitle == 'Audit Adherence';
          else target
        "
        >Target
        <span
          [ngClass]="
            targetForm.invalid && targetForm.get('target').hasError('required')
              ? 'is-invalid'
              : 'is-valid'
          "
          >*</span
        >
      </mat-label>
      <mat-form-field class="input-field" appearance="outline">
        <input
          type="number"
          min="0"
          [max]="
            data.targetTitle == 'Audit Score' ||
            data.targetTitle == 'Audit Adherence'
              ? 100
              : null
          "
          oninput="validity.valid||(value='');"
          [placeholder]="'Placeholder.Insert' | translate"
          formControlName="target"
          matInput
          #myinput
        />
        <mat-error
          *ngIf="
            targetForm.invalid && targetForm.get('target').hasError('required')
          "
        >
          <div class="flex justify-flex-start align-flex-center p-0">
            <img class="error-img" src="assets/img/resources/error-icon.svg" />
            <span>{{ "Errors.Required" | translate }}</span>
          </div>
        </mat-error>
      </mat-form-field>
      <p
        class="text-red-600 text-xs"
        *ngIf="
          this.targetForm.controls['target'].errors &&
          (data.targetTitle == 'Audit Score' ||
            data.targetTitle == 'Audit Adherence')
        "
      >
        {{ "Errors.valueLimit" | translate }}
      </p>
    </div>
  </div>
  <div class="flex items-center justify-end my-3 w-full">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="cancelForm()"
    >
    </app-basic-button>
    <app-basic-button
      [btnText]="'Save'"
      [btnType]="'Add'"
      [isDisabled]="!targetForm.valid"
      (onClick)="updateDashboardTarget()"
      [mat-dialog-close]="{ updatedData }"
    ></app-basic-button>
  </div>
</form>
