<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.WorkPermitMaster' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div class="card p-7 rounded-xl">
      <h2 class="title">{{'CardTypes.CardsTitle' | translate}}</h2>
      <div class="cardSection pb-5">
        <app-card-type [title]="'General.Buttons.Hazards'|translate" [imgSrc]="'assets/img/resources/hazard.svg'"
          (onClick)="onHazardsClick($event)">
        </app-card-type>
        <app-card-type [title]="'General.Buttons.WorkConditions'|translate" [imgSrc]="'assets/img/resources/work-conditions.svg'"
          (onClick)="onWorkConditionsClick($event)">
        </app-card-type>
      </div>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>