import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-reviewer-category-details-page',
  templateUrl: './reviewer-category-details-page.component.html',
  styleUrls: ['./reviewer-category-details-page.component.scss']
})
export class ReviewerCategoryDetailsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
