<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "Machine.MachinesListTitle" | translate }}</span>
    </h2>

    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'Placeholder.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>

    <button
      *ngIf="hasWritePermissions | permissionChecker"
      (click)="openCreateMachineModal()"
      class="ml-4 flex-none"
      color="primary"
      mat-mini-fab
      [matTooltip]="'General.Buttons.AddNew' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>
  <app-state-section
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "Machine.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div class="w-10/12 truncate" #div>
              <span
                [matTooltip]="
                  div?.offsetWidth < div?.scrollWidth
                    ? row[column.property]
                    : null
                "
                >{{ row[column.property] }}</span
              >
            </div>
            <mat-icon
              *ngIf="column.property === 'fullName' && row?.isVerified"
              svgIcon="mat:verified"
              class="text-blue-700"
              style="height: 15px; width: 15px"
            ></mat-icon>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "Machine.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "dd-MM-yyyy" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'machineType'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "Machine.Table." + column.label | translate }}
              <mat-select
                [value]="selectedMachineType"
                (selectionChange)="onSelectMachineType($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of MachineTypes | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Machine." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <app-boolean-view
              [trueColor]="'#44ce5029'"
              [falseColor]="'#ff595929'"
              [trueTextColor]="'#ffffff'"
              [falseTextColor]="'#534A46'"
              [trueColor]="'#F96F61'"
              [falseColor]="'#D7DADE'"
              [width]="'120px'"
              [height]="'30px'"
              [value]="row[column.property] === 'SWITCHED'"
              [text]="
                row[column.property] === 'SWITCHED'
                  ? ('Machine.SWITCHED' | translate)
                  : ('Machine.PARAMETERIZED' | translate)
              "
            >
            </app-boolean-view>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "User.Table." + column.label | translate }}
              <mat-select
                [value]="selectedStatus"
                (selectionChange)="onSelectMachineStatus($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of CompanyUserStatus | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.CompanyUserStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <app-boolean-view
              [value]="row[column.property] === 'ACTIVE'"
              [text]="
                row[column.property] === 'ACTIVE'
                  ? ('General.Active' | translate)
                  : ('General.Inactive' | translate)
              "
            >
            </app-boolean-view>
          </td>
        </ng-container>
      </ng-container>
      <ng-container matColumnDef="actions" stickyEnd>
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let row" mat-cell>
          <div (click)="$event.stopPropagation()">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                *ngIf="
                  (hasActivatePermissions | permissionChecker) &&
                  row?.status === 'ACTIVE'
                "
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="deactivateMachine($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.Deactivate" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                *ngIf="
                  (hasActivatePermissions | permissionChecker) &&
                  row?.status !== 'ACTIVE'
                "
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="activateMachine($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.Activate" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                *ngIf="hasEditPermissions | permissionChecker"
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="openEditMachineModal($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.Edit" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>

              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="viewMachineParameters($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.View" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                *ngIf="hasWritePermissions | permissionChecker"
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="addMachineParamters($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.AddParameters" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                *ngIf="hasEditPermissions | permissionChecker"
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="updateMachineParameters($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.EditParameters" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewMachineParameters($event, row?.id)"
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-icon
      class="text-[#757575] me-1"
      *ngIf="!column.visible"
      svgIcon="mat:check_box_outline_blank"
    ></mat-icon>
    <mat-icon
      *ngIf="column.visible"
      class="text-primary me-1"
      svgIcon="mat:check_box"
    ></mat-icon>
    {{ "Machine.Table." + column.label | translate }}
  </button>
</mat-menu>

<ng-template #machineModel>
  <app-state-section
    [flexibleHeight]="true"
    [state]="sectionStateModal"
    [loadingLabel]="
      (formMode === 'create' ? 'General.Saving' : 'General.Updating')
        | translate
    "
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <form [formGroup]="machineForm">
      <div class="flex items-center" mat-dialog-title>
        <h2
          *ngIf="machineForm.get('machine').value"
          class="headline m-0 flex-auto"
        >
          {{ machineForm.get("machine").value }}
        </h2>
        <h2
          *ngIf="!machineForm.get('machine').value"
          class="headline m-0 flex-auto"
        >
          {{ "General.AddNew" | translate }}
        </h2>
        <button
          class="text-secondary"
          (click)="closeDialog()"
          mat-icon-button
          type="button"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>

      <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

      <mat-dialog-content class="flex flex-col flex-wrap justify-between">
        <div class="flex column">
          <mat-label class="field-label"
            >{{ "Machine.Table.Code" | translate }}
            <span
              [ngClass]="
                machineForm.invalid &&
                machineForm.get('code').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.Insert' | translate"
              formControlName="code"
              matInput
              type="text"
            />
            <mat-error
              *ngIf="
                machineForm.invalid &&
                machineForm.get('code').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <div class="flex column">
          <mat-label class="field-label"
            >{{ "Machine.Table.Department" | translate }}
            <span
              [ngClass]="
                machineForm.invalid &&
                machineForm.get('department').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              ngxMatSelect
              #msf1="ngxMatSelect"
              [displayMember]="'departmentName'"
              [valueMember]="'departmentId'"
              [source]="departments"
              [useInfiniteScroll]="true"
              [multiple]="false"
              [hasPermission]="
                hasCreateDepartmentPermission | permissionChecker
              "
              [title]="'departments'"
              [hasSearchBox]="false"
              [placeholder]="'Placeholder.Select' | translate"
              formControlName="department"
              [manageRoute]="'/company-setup/departments'"
              [disabled]="false"
              (selectionChange)="onChangeDepartment($event)"
            >
              <mat-option
                *ngIf="msf1.filteredSource.length === 0"
                style="height: 0"
              >
              </mat-option>
              <mat-optgroup
                *ngFor="let group of msf1.filteredSource"
                [label]="group.parentName"
              >
                <mat-option
                  *ngFor="let detail of group.detail"
                  [value]="detail.departmentId"
                >
                  {{ detail.departmentName }}
                </mat-option>
              </mat-optgroup>
            </mat-select>
            <mat-error
              *ngIf="
                machineForm.invalid &&
                machineForm.get('department').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>

        <!-- Sub department -->
        <div class="flex column" *ngIf="subDepartments.length > 0">
          <mat-label class="field-label">{{
            "AddReportHazard.SubDepartment" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              [title]="'departments'"
              [placeholder]="'Placeholder.Select' | translate"
              formControlName="subDepartment"
            >
              <mat-option *ngFor="let dep of subDepartments" [value]="dep.id">
                {{ dep.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="flex column">
          <mat-label class="field-label"
            >{{ "Machine.MachineName" | translate }}
            <span
              [ngClass]="
                machineForm.invalid &&
                machineForm.get('machine').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.Insert' | translate"
              formControlName="machine"
              matInput
              type="text"
            />
            <mat-error
              *ngIf="
                machineForm.invalid &&
                machineForm.get('machine').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <div class="flex items-center">
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="closeDialog()"
          ></app-basic-button>
          <app-basic-button
            [btnType]="formMode === 'create' ? 'Add' : 'Add'"
            [btnText]="formMode === 'create' ? 'Add' : 'Save'"
            [isDisabled]="!machineForm.valid"
            (onClick)="
              formMode === 'create' ? addNewMachine() : updateMachine()
            "
          ></app-basic-button>
        </div>
      </mat-dialog-actions>
    </form>
  </app-state-section>
</ng-template>
