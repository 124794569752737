<div>
  <button
  *ngIf="companyId == 'O-1670918559523'"
    (click)="openChatbotModal()"
    class="chatbot-toggle"
    color="primary"
    mat-fab
    type="button"
  >
    <mat-icon class="w-fit" svgIcon="sidebar:chatbot-icon"></mat-icon>
  </button>

  <button
    (click)="openConfig.emit()"
    class="config-panel-toggle"
    color="primary"
    mat-fab
    type="button"
  >
    <mat-icon svgIcon="mat:settings"></mat-icon>
  </button>
</div>

<ng-template #chatbotModal>
  <app-chatbot-modal></app-chatbot-modal>
</ng-template>
