import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionTrackerRoutingModule } from './action-tracker-routing.module';
import { ActionTrackerPageComponent } from './pages/action-tracker-page/action-tracker-page.component';
import { ActionTrackerComponent } from './components/action-tracker/action-tracker.component';
import { ActionComponent } from './components/action/action.component';
import { SharedModule } from '../shared/shared.module';
import { ActionTrackerService } from 'src/backend/services/action-tracker/action-tracker.service';
import { HistoryLogComponent } from './components/history-log/history-log.component';
import { ViewActionComponent } from './components/view-action/view-action.component';


@NgModule({
  declarations: [
    ActionTrackerPageComponent,
    ActionTrackerComponent,
    ActionComponent,
    HistoryLogComponent,
    ViewActionComponent
  ],
  imports: [
    CommonModule,
    ActionTrackerRoutingModule,
    SharedModule
  ],
  exports: [ActionComponent,HistoryLogComponent],
  providers: [ActionTrackerService]
})
export class ActionTrackerModule { }
