import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  PostIncidentCategory,
  GetIncidentCategory,
  UpdateIncidentCategory,
} from 'src/backend/models/incident-category/incident-category.model';
import { BaseApi } from 'src/backend/api/base-api';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { DataMapper } from 'src/app/shared/models/data-mapper';

@Injectable({
  providedIn: 'root',
})
export class IncidentCategoryService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  postTypeOfIncident(data: PostIncidentCategory): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_INCIDENT_CATEGORY_CREATE),
      data
    );
  }

  UpdateTypeOfIncident(data: UpdateIncidentCategory): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_INCIDENT_CATEGORY_UPDATE),
      data
    );
  }
  getAllTypeOfIncident(data: any): Observable<DataMapper<GetIncidentCategory>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<GetIncidentCategory>>(
      this.baseApi.encodeURL(
        Constants.API_INCIDENT_CATEGORY_GET_ALL + '?' + params
      )
    );
  }
  deActivate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_INCIDENT_CATEGORY_DEACTIVATE + '/' + id
      )
    );
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_INCIDENT_CATEGORY_ACTIVATE + '/' + id
      )
    );
  }
  getReportCategoriesByCompany(reportType): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(
        Constants.API_INCIDENT_CATEGORY_LIST + '?theType=' + reportType
      )
    );
  }
}
