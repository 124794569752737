import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { filterPermissions } from 'src/@hodhod/common/custom_methods';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { UserService } from 'src/backend/services/user.service';
import { ParentUser } from '../../models/parent-user';
import { UserDetails } from '../../models/user-details';

@Component({
  selector: 'app-user-view-page',
  templateUrl: './user-view-page.component.html',
  styleUrls: ['./user-view-page.component.scss'],
})
export class UserViewPageComponent implements OnInit {
  private destroy$ = new Subject();
  userId: string = '';

  public userPermissions: any[] = [];
  public userDetails: UserDetails;

  private translationsList: any = {};

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private loadingService: LoadingService
  ) {
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const userId: string = params.get('id');
        this.userId = userId;
        this.getUserDetails();
      }
    });
  }

  getUserDetails(): void {
    this.loadingService.startLoading(true, '');
    this.userService.getUserDetails(this.userId).subscribe({
      next: (response) => {

        this.userPermissions = response['permissonBlocks'];
        this.userDetails = response;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  saveChangesPermissions(event: any): void {
    event.map((block) =>
    block.permissions.map((row) => {
      delete row.options;
      delete row.selectedOption;
    })
  );

    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const filterdPermissions = {
            permissionBlocks: event,
          };
          this.userService.savePermissions(filterdPermissions, this.userId).subscribe({
            next: (res) => {
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getUserDetails();
            },
            error: ({error}) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            }
          })
        }
      });
  }
}
