import {
  Component,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { IotDashboardService } from 'src/backend/services/dashboards/iot-dashboard.service';
import { IotDashboardSetting } from '../../models/dashboard';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AddIotDashboardComponent } from '../add-iot-dashboard/add-iot-dashboard.component';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-dashboard-iot',
  templateUrl: './dashboard-iot.component.html',
  styleUrls: ['./dashboard-iot.component.scss'],
})
export class DashboardIotComponent
  extends BaseComponent
  implements OnInit, OnChanges
{
  @Input() loadData: boolean;
  @ViewChild('addIotDashboard') addIotDashboard!: TemplateRef<any>;

  public allDashboards: IotDashboardSetting[] = [];
  hasReadPermission = ApplicationPermission.IOT_DASHBOARD_ACCESS;
  haswritePermission = ApplicationPermission.IOT_DASHBOARD_WRITE;
  hasUpdatePermission = ApplicationPermission.IOT_DASHBOARD_UPDATE;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public loadingLabel: string = '';

  public columns: number = 3;
  private screenWidth: number = null;
  translationsList: any = {};

  constructor(
    private iotDashboardService: IotDashboardService,
    private feedBackService: AsyncFeedbackService,
    private dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  override ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadData'].currentValue) {
      this.getCustomizedIotDashboards();
    }
  }

  getCustomizedIotDashboards() {
    this.iotDashboardService.getAllCustomizedIotDashboards().subscribe({
      next: (res) => {
        this.allDashboards = res;
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  removeDashboard(id) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.iotDashboardService.removeDashboard(id).subscribe({
            next: (response) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
              this.getCustomizedIotDashboards();
            },
            error: (error) => {
              this.feedBackService?.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  openCreateModal(): void {
    const data = {
      role: '',
      action: 'create',
    };
    this.dialog
      .open(AddIotDashboardComponent, {
        height: 'auto',
        width: '35%',
        data: data,
      })
      .afterClosed()
      .subscribe((role: any) => {
        this.getCustomizedIotDashboards();
      });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1800) {
      this.columns = 4;
    } else if (this.screenWidth > 1024 && this.screenWidth <= 1800) {
      this.columns = 3;
    } else if (this.screenWidth > 760 && this.screenWidth <= 1024) {
      this.columns = 2;
    } else if (this.screenWidth <= 760) {
      this.columns = 1;
    }
  }
}
