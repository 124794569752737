import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardTargetComponent } from './dashboard-target/dashboard-target.component';
import { AddDashboardTargetComponent } from './add-dashboard-target/add-dashboard-target.component';
import { EditDashboardTargetComponent } from './edit-dashboard-target/edit-dashboard-target.component';

import { DashboardDetailComponent } from './pages/dashboard-detail/dashboard-detail.component';
import { AdvanceAnalyticsComponent } from './pages/advance-analytics/advance-analytics.component';
import { FullscreenDashboardComponent } from './components/fullscreen-dashboard/fullscreen-dashboard.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { DashboardChartComponent } from './components/dashboard-chart/dashboard-chart.component';
import { DashboardCalenderComponent } from './components/dashboard-calender/dashboard-calender.component';
import { DigitalDashboardComponent } from './components/digital-dashboard/digital-dashboard.component';
import { EditDashboardTargetPopupComponent } from './components/edit-dashboard-target-popup/edit-dashboard-target-popup.component';
import { SmartSafetyDashboardsComponent } from './components/smart-safety-dashboards/smart-safety-dashboards.component';
import { IotDashboardsComponent } from './components/iot-dashboards/iot-dashboards.component';
import { AddIotDashboardComponent } from './components/iot-dashboards/add-iot-dashboard/add-iot-dashboard.component';
import { DashboardSettingsComponent } from './pages/dashboard-settings/dashboard-settings.component';
import { SafetyKpisComponent } from './safety-kpis/safety-kpis.component';
import { DefineSafetyTriangleLevelsComponent } from './components/define-safety-triangle-levels/define-safety-triangle-levels.component';
import { ManualLevelsFormComponent } from './components/define-safety-triangle-levels/manual-levels-form/manual-levels-form.component';
import { AutoLevelsFormComponent } from './components/define-safety-triangle-levels/auto-levels-form/auto-levels-form.component';
import { SafetyTriangleComponent } from './components/define-safety-triangle-levels/safety-triangle/safety-triangle.component';
import { SafetyKpiSetupComponent } from './pages/safety-kpi-setup/safety-kpi-setup.component';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardDetailComponent,
    AdvanceAnalyticsComponent,
    DashboardTargetComponent,
    AddDashboardTargetComponent,
    EditDashboardTargetComponent,
    DashboardCardComponent,
    DashboardChartComponent,
    DashboardCalenderComponent,
    FullscreenDashboardComponent,
    DigitalDashboardComponent,
    EditDashboardTargetPopupComponent,
    SmartSafetyDashboardsComponent,
    IotDashboardsComponent,
    AddIotDashboardComponent,
    DashboardSettingsComponent,
    SafetyKpisComponent,
    DefineSafetyTriangleLevelsComponent,
    ManualLevelsFormComponent,
    AutoLevelsFormComponent,
    SafetyTriangleComponent,
    SafetyKpiSetupComponent,
  ],
  imports: [CommonModule, DashboardRoutingModule, SharedModule],
  exports: [
    DashboardComponent,
    DashboardDetailComponent,
    AdvanceAnalyticsComponent,
    DashboardTargetComponent,
    AddDashboardTargetComponent,
    EditDashboardTargetComponent,
    DashboardCardComponent,
    DashboardChartComponent,
    DashboardCalenderComponent,
    FullscreenDashboardComponent,
    DigitalDashboardComponent,
    EditDashboardTargetPopupComponent,
    SmartSafetyDashboardsComponent,
    IotDashboardsComponent,
    AddIotDashboardComponent,
  ],
})
export class DashboardModule {}
