import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../../utils/track-by';
import { PopoverRef } from '../../../../components/popover/popover-ref';
import { BaseApi } from 'src/backend/api/base-api';
import { Router } from '@angular/router';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { ConfigService } from 'src/@hodhod/config/config.service';
import { colorVariables } from 'src/@hodhod/components/config-panel/color-variables';
import { MatDialog } from '@angular/material/dialog';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: string;
  colorClass: string;
}

@Component({
  selector: 'hodhod-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserDropdownComponent implements OnInit {
  currentUser!: any;
  items: MenuItem[] = [
    {
      id: '1',
      icon: 'mat:account_circle',
      label: 'My Profile',
      description: 'Personal Information',
      colorClass: 'text-teal',
      route:
        '/' +
        SharedConstants.USER_MANAGEMENT +
        '/' +
        SharedConstants.VIEW_PROFILE +
        '/' +
        this.currentUser?.userId,
    },
    {
      id: '2',
      icon: 'mat:lock',
      label: 'Change Password',
      description: 'Tasks & Active Projects',
      colorClass: 'text-amber',
      route: '/' + SharedConstants.AUTH + '/' + SharedConstants.FORGOT_PASSWORD,
    },
    // {
    //   id: '3',
    //   icon: 'mat:notifications_off',
    //   label: 'Disable Notifications',
    //   description: 'Pricing & Current Plan',
    //   colorClass: 'text-purple',
    //   route: '/pages/pricing'
    // }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: 'mat:check_circle',
      colorClass: 'text-green',
    },
    {
      id: 'away',
      label: 'Away',
      icon: 'mat:access_time',
      colorClass: 'text-orange',
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: 'mat:do_not_disturb',
      colorClass: 'text-red',
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: 'mat:offline_bolt',
      colorClass: 'text-gray',
    },
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  trackById = trackById;

  constructor(
    private cd: ChangeDetectorRef,
    private configService: ConfigService,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private baseApi: BaseApi,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.currentUser = this.baseApi.getUserSession();
    this.items[0].route =
      '/' +
      SharedConstants.USER_MANAGEMENT +
      '/' +
      SharedConstants.VIEW_PROFILE +
      '/' +
      this.currentUser?.userId;
  }

  userImage() {
    return this.baseApi.getUserSession();
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  close() {
    this.popoverRef.close();
  }

  logout(): void {
    this.close();
    this.baseApi.removeTokenFromStorage();
    this.baseApi.removeCompanyIdFromStorage();
    this.baseApi.removeUserSession();
    this.baseApi.removeUserMenusSession();
    this.baseApi.removeUserPermissionsSession();
    this.baseApi.removeOrganizationFromStorage();
    this.baseApi.removeCompanyFromStorage();
    this.updateThemeColor();
    this.dialog.closeAll();
    this.router.navigate([
      '/' + SharedConstants.AUTH + '/' + SharedConstants.SIGN_IN,
    ]);
  }

  updateThemeColor(): void {
    this.configService.updateConfig({
      style: {
        colors: {
          primary: colorVariables['blue'],
        },
      },
    });
  }
}
