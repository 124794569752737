import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { SharedConstants } from 'src/app/shared/models/shared-constants';

@Component({
  selector: 'app-reporting-content-page',
  templateUrl: './reporting-content-page.component.html',
  styleUrls: ['./reporting-content-page.component.scss'],
})
export class ReportingContentPageComponent implements OnInit {
  hasTypeIncidentPermission =
    ApplicationPermission.CONTENT_REPORTING_INCIDENTCATEGORY_ACCESS;
  hasNatureInjurietPermission =
    ApplicationPermission.CONTENT_REPORTING_NATUREINJURY_ACCESS;
  hasReasonsPermission = ApplicationPermission.CONTENT_REPORTING_REASONS_ACCESS;
  hasRootCausesPermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_ACCESS;
  hasIncidentClassificationsPermission =
    ApplicationPermission.CONTENT_REPORTING_INCIDENTCLASSIFICATION_ACCESS;
  hasLossModesPermission =
    ApplicationPermission.CONTENT_REPORTING_LOSSMODE_ACCESS;

  constructor(private route: Router) {}

  ngOnInit(): void {}
  onTypesIncidentClick(event: string): void {
    this.route.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.INCIDENT_CATEGORY,
    ]);
  }

  onNatureInjurieClick(event: string): void {
    this.route.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.NATURE_INJURY,
    ]);
  }

  onReasonsClick(event: string): void {
    this.route.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REASONS,
    ]);
  }
  onRootCausesClick(event: string): void {
    this.route.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.ROOT_CAUSE,
    ]);
  }

  onIncidentClassificationsClick(event: string): void {
    this.route.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.INCIDENT_CLASSIFICATION,
    ]);
  }
  onLossModesClick(event: string): void {
    this.route.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.LOSS_MODES,
    ]);
  }
}
