<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.PenaltyManagement' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-penalty-management-list></app-penalty-management-list>
  </hodhod-page-layout-content>
</hodhod-page-layout>
