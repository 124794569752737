export class GetAllVoilations{
    id: number;
    cameraId: number;
    cameraName: string;
    cameraPosition: string;
    departmentName: string;
    picture: string;
    timeDate: Date;
    status: string;
    voilationName: string;
    offenderName: string;
    offenderNature: string;
    isGuestOffender: boolean;
    closerId: string;
}

export interface GetFilterdDashboardData{
    data: ViolationDashboardListDto[];
}
export interface DataListDtoVM{
    dataListDto: VoilationDashboardDto;
    violationChartDto: any;
    offenceChart: any;
    departmentChartDto: any;
    violationCardList: any;
    voilationByTeamMembers: any;
    actionListChart: any;
    avgViolations: avg[];
}

export interface VoilationDashboardDto{
    newReports: number;
    acknowledgedReports: number;
    dataListDto: DataMapper<ViolationDashboardListDto>;
}

export interface ViolationDashboardListDto{
    violationId?: number;
    cameraName: string;
    departmentName: string;
    violation: string;
    offender: string;
    status: string;
    createdOn: Date;
}

export interface avg{
    name: string;
    y: number;
    drilldown: string;
}

export interface DataMapper<T> {
    data: Array<T>;
    totalCount: number;
}
