import {  SortDirection } from 'src/@hodhod/common/enum';
import { BaseApi } from '../api/base-api';
import { DataMapper } from '../models/voilations/voilation.model';
import { Areas } from 'src/app/user-management/models/areas';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';

@Injectable({
  providedIn: 'root'
})
export class AreasService {

  constructor(private http: HttpClient, private baseApi: BaseApi) {}
  getAllAreas(data: any): Observable<DataMapper<Areas>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<Areas>>(
      this.baseApi.encodeURL(
        Constants.API_ALL_AREA_LIST_BY_COMPANY + '?' + params
      )
    );
  }

  createArea(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants. API_AREA_CREATE),
      data
    );
  }

  updateArea(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_AREA_UPDATE),
      data
    );
  }

  activateArea(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_AREA_ACTIVATE + '/' + id),id
    );
  }

  deActivateArea(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_AREA_DEACTIVATE + '/' + id),id
    );
  }

}
