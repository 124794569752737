import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { SafemanHoursService } from 'src/backend/services/safeman-hours.service';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { SmartSafetyFilterDashboards } from 'src/@hodhod/common/enum';
import { DashboardCalenderComponent } from 'src/app/dashboard/components/dashboard-calender/dashboard-calender.component';
import { MatDialog } from '@angular/material/dialog';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { error } from 'highcharts';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { ActivatedRoute, Router } from '@angular/router';
@UntilDestroy()
@Component({
  selector: 'app-safeman-hours',
  templateUrl: './safeman-hours.component.html',
  styleUrls: ['./safeman-hours.component.scss'],
})
export class SafemanHoursComponent extends BaseComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private safemanHoursService: SafemanHoursService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private feedBackService: AsyncFeedbackService,
    private router: Router

  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  safeManHoursForm: FormGroup;
  safeManHours;
  ID:number;
  isAuto: boolean = true;
  public filterDashboards: any = null;
  private translationsList: any = {};
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  override ngOnInit(): void {
    this.ID= this.route.snapshot.params['id']

    this.filterDashboards = SmartSafetyFilterDashboards;
    this.safeManHoursForm = this.fb.group({
      employees: [null],
      workingHours: [null],
      workingDays: [null],
      workingWeeks: [null],
      target: [null],
      manualValue: [null],
    });
    this.getSafemanHours();
  }
  submitForm() {
    let data = {
      id: this.safeManHours.id,
      noOfEmployees: this.isAuto
        ? this.safeManHoursForm.get('employees').value
        : null,
      noOfWorkingHours: this.isAuto
        ? this.safeManHoursForm.get('workingHours').value
        : null,
      noOfWorkingDaysPerWeek: this.isAuto
        ? this.safeManHoursForm.get('workingDays').value
        : null,
      noOfWorkingWeeksPerYear: this.isAuto
        ? this.safeManHoursForm.get('workingWeeks').value
        : null,
      target: this.safeManHoursForm.get('target').value,
      mode: this.isAuto ? 'AUTO' : 'MANUAL',
      manualValue: this.safeManHoursForm.get('manualValue')?.value || null,
    };
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        this.sectionState = SectionStateStatus.Loading;
        if (value === true) {
          this.safemanHoursService.updateSafemanHours(data).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService?.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.navigateBack()
            },
            error: (err) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService?.showFeedback(
                new FeedbackModel(FeedbackType.Failure, err?.message)
              );
            },
          });
        }
      });
  }
  navigateBack() {
    this.router.navigate([
      '/' +
        SharedConstants.DASHBOARD_PATH +
        '/' +
        SharedConstants.DASHBOARD_TARGET,
    ]);
  }
  AutoOrManual(ev?) {
    this.isAuto = ev.checked;
    this.validateForm();
  }
  validateForm() {
    this.safeManHoursForm = this.fb.group({
      employees: [
        this.safeManHoursForm.get('employees').value,
        this.isAuto ? [Validators.required,Validators.min(1)] : null,
      ],
      workingHours: [
        this.safeManHoursForm.get('workingHours').value,
        this.isAuto ? [Validators.required,Validators.min(1),Validators.max(24)] : null,
      ],
      workingDays: [
        this.safeManHoursForm.get('workingDays').value,
        this.isAuto ? [Validators.required,Validators.min(1),Validators.max(7)] : null,
      ],
      workingWeeks: [
        this.safeManHoursForm.get('workingWeeks').value,
        this.isAuto ? [Validators.required,Validators.min(1),Validators.max(52)] : null,
      ],
      target: [this.safeManHoursForm.get('target').value, [Validators.required,Validators.min(0)]],
      manualValue: [
        this.safeManHoursForm.get('manualValue').value,
        this.isAuto ? null : [Validators.required,Validators.min(0)],
      ],
    });
  }

  getSafemanHours() {
    this.safemanHoursService.getSafemanHours(this.ID).subscribe({
      next: (res) => {
        this.safeManHours = res;
        this.isAuto =
          res?.mode === 'AUTO'
            ? true
            : res?.mode === 'MANUAL'
            ? false
            : this.isAuto;
        this.safeManHoursForm.get('employees').setValue(res?.noOfEmployees);
        this.safeManHoursForm
          .get('workingHours')
          .setValue(res?.noOfWorkingHours);
        this.safeManHoursForm
          .get('workingDays')
          .setValue(res?.noOfWorkingDaysPerWeek);
        this.safeManHoursForm
          .get('workingWeeks')
          .setValue(res?.noOfWorkingWeeksPerYear);
        this.safeManHoursForm.get('target').setValue(res?.target);
        this.safeManHoursForm.get('manualValue').setValue(res?.manualValue);
        this.validateForm();
      },
      error: () => {},
    });
  }
}
