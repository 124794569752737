<app-state-section
  [flexibleHeight]="true"
  [state]="sectionStateModal"
  [loadingLabel]="
    (data.formMode === 'create'
      ? 'User.AddingLoadingUser'
      : 'User.UpdatingLoadingUser'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="safetyAnnouncementForm">
    <div class="flex items-center justify-end" mat-dialog-title>
      <button
        class="text-primary bg-transparent"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>
    <!-- Form -->
    <mat-dialog-content>
      <div class="flex flex-wrap justify-around">
        <div class="field flex column">
          <div class="form-field flex">
            <mat-label class="label field-label">
              {{ "SafetyAnnouncement.Form.AnnouncementTitle" | translate }}
              <span
                [ngClass]="
                  safetyAnnouncementForm.invalid &&
                  safetyAnnouncementForm.get('title').hasError('required')
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              >
            </mat-label>

            <mat-form-field class="input-field" appearance="outline">
              <input
                [placeholder]="'Placeholder.AddText' | translate"
                matInput
                type="text"
                formControlName="title"
              />
              <mat-error>
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="field flex column">
          <div class="form-field flex">
            <mat-label class="label"
              >{{'SafetyAnnouncement.Form.Receiver'|translate}}
              <span
                [ngClass]="
                  safetyAnnouncementForm
                    .get('receivers')
                    .hasError('required') || !selectedReceivers.length
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              ></mat-label
            >
            <mat-form-field class="w-full" appearance="outline">
              <mat-chip-list #chipGrid aria-label="receiver selection">
                <mat-chip
                  *ngFor="let receiver of selectedReceivers"
                  (removed)="removeReciever(receiver.receiverID)"
                  class="selected-span"
                >
                  {{ receiver.receiverName }}
                  <button
                    matChipRemove
                    [attr.aria-label]="'remove ' + receiver.receiverID"
                  >
                    <mat-icon color="primary" svgIcon="mat:cancel"></mat-icon>
                  </button>
                </mat-chip>
              </mat-chip-list>
              <input
                [placeholder]="'SafetyAnnouncement.SelectReceivers'|translate"
                #receiverInput
                [formControl]="receiverCtrl"
                formControlName="receivers"
                [matChipInputFor]="chipGrid"
                [matAutocomplete]="auto"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                required
                matInput
              />

              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="selecteReciever($event)"
                [displayWith]="displayFn"
              >
                <mat-option value="all"> All </mat-option>
                <mat-optgroup
                  *ngFor="let reciversData of filteredReceivers | async"
                  [label]="reciversData.typeName"
                >
                  <mat-option
                    *ngFor="let reciver of reciversData.recieviers"
                    [value]="reciver"
                  >
                    {{ reciver.receiverName }}
                  </mat-option>
                </mat-optgroup>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </div>

        <div class="field flex column">
          <div class="form-field flex">
            <mat-label class="label">{{'SafetyAnnouncement.Table.Tags'|translate}}</mat-label>
            <mat-form-field class="w-full" appearance="outline">
              <mat-chip-list
                class="mat-chip-list-tags"
                #chipGridTags
                aria-label="Enter Tags"
              >
                <mat-chip
                  *ngFor="let tag of tags; let i = index"
                  (removed)="removeTag(tag)"
                  [ngClass]="i % 2 == 0 ? 'tag-span-yellow' : 'tag-span-blue'"
                >
                  {{ tag }}
                  <button matChipRemove [attr.aria-label]="'remove ' + tag">
                    <mat-icon
                      [style.color]="i % 2 == 0 ? '#F28838' : '#288DAA'"
                      svgIcon="mat:cancel"
                    ></mat-icon>
                  </button>
                </mat-chip>
              </mat-chip-list>
              <input
                [placeholder]="'SafetyAnnouncement.NewTag'|translate"
                [matChipInputFor]="chipGridTags"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="addTag($event)"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="field flex column">
          <div class="form-field flex">
            <mat-label class="label"
              >{{ "SafetyAnnouncement.Form.Type" | translate }}
              <span
                [ngClass]="
                  safetyAnnouncementForm.invalid &&
                  safetyAnnouncementForm.get('type').hasError('required')
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              >
            </mat-label>
            <mat-form-field class="w-full" appearance="outline">
              <mat-select
                [placeholder]="'SafetyAnnouncement.Form.Type' | translate"
                formControlName="type"
              >
                <mat-option
                  *ngFor="let type of safetyAnnouncementType | enumToArray"
                  [value]="type.key"
                >
                  {{ "Enum.SafetyAnnouncementType." + type.value | translate }}
                </mat-option>
              </mat-select>
              <mat-error>
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.Required" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <hr />
      <div class="text-center">
        <h1 class="py-2" [style.fontSize]="'1.2rem'">
          {{ safetyAnnouncementForm.get("title").value || "SafetyAnnouncement.NewAnnouncement"|translate }}
        </h1>
        <div class="flex-row">
          <mat-form-field class="input-field" appearance="outline">
            <textarea
              matInput
              rows="5"
              [placeholder]="'Add Announcement Description'"
              formControlName="description"
            >
            </textarea>
            <mat-error>
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="text-start flex items-center">
          <span style="margin-inline-end: 10px">Attachments</span>
          <input
            #fileInput
            hidden="true"
            type="file"
            (change)="onFileSelected($event)"
            onclick="this.value=null"
            formArrayName="attachments"
            multiple
          />
          <button
            mat-flat-button
            style="background: lightgray; color: #000; text-align: center"
            (click)="fileInput.click()"
          >
            Browse
          </button>
        </div>
        <div
          class="flex items-center"
          *ngFor="let file of files; let i = index"
          style="margin: 5px"
        >
          <div class="flex items-center">
            <mat-icon
              color="primary"
              svgIcon="mat:insert_drive_file"
            ></mat-icon>
            <span>{{
              file?.id ? getFileName(file.attachmentUrl) : file.name
            }}</span>
            <mat-icon
              class="cursor-pointer"
              svgIcon="mat:clear"
              color="primary"
              (click)="
                file?.id ? deleteDirectlyFile(file.id) : removeFile(file.name)
              "
            ></mat-icon>
          </div>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          [btnType]="
            data.formMode === 'create' || data.formMode === 'dublicate'
              ? 'Add'
              : 'Add'
          "
          [btnText]="
            data.formMode === 'create' || data.formMode === 'dublicate'
              ? 'Add'
              : 'Save'
          "
          [isDisabled]="!safetyAnnouncementForm.valid"
          (onClick)="
            data.formMode === 'create' || data.formMode === 'dublicate'
              ? createAnnouncemet()
              : updateAnnouncement()
          "
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
