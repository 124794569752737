import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';

@Component({
  selector: 'app-add-contact-card',
  templateUrl: './add-contact-card.component.html',
  styleUrls: ['./add-contact-card.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class AddContactCardComponent implements OnInit {
  

  
  @Input() title: string = '';
  @Input() imgSrc: string = '';
  
  @Output() onClick: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }
  onButtonClick(): void {
    this.onClick.emit("Clicked")
  }

}
