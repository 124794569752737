import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { PermissionService } from 'src/backend/services/permission.service';
import { WorkPermitService } from 'src/backend/services/work-permits/work-permit.service';

@Component({
  selector: 'app-edit-view-work-permit-page',
  templateUrl: './edit-view-work-permit-page.component.html',
  styleUrls: ['./edit-view-work-permit-page.component.scss'],
})
export class EditViewWorkPermitPageComponent implements OnInit, OnDestroy {
  public mode: 'view' | 'edit' = 'view';
  public workPermitId: number = 0;
  private destroy$ = new Subject();
  public workPermit: any;
  hasPermissionEdit = ApplicationPermission.WORK_PERMIT_LIST_EDIT;
  hasPermissionView = ApplicationPermission.WORK_PERMIT_LIST_ACCESS;
  formMode: any;
  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private workPermitService: WorkPermitService,
    private permissionService: PermissionService,
    private feedBackService: AsyncFeedbackService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;
        this.workPermitId = parseInt(id, 10);
        this.getWorkPermit();
      }
    });
    this.route.queryParamMap.subscribe((queryParam) => {
      const formMode = queryParam.get('editMode') as any;
      this.mode = formMode === 'true' ? 'edit' : 'view';
    });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  getWorkPermit(): void {
    this.loadingService.startLoading(true, '');

    this.workPermitService.getWorkPermitById(this.workPermitId).subscribe({
      next: (response) => {
        this.workPermit = response;

        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  reloadData(event: boolean): void {
    this.getWorkPermit();
  }
}
