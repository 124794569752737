import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject, takeUntil, tap } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import {
  OffenderNature,
  SortDirection,
  VoilationStatus,
} from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { GetAllVoilations } from 'src/backend/models/voilations/voilation.model';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { UserService } from 'src/backend/services/user.service';
import { VoilationsService } from 'src/backend/services/voilations/voilations.service';

@UntilDestroy()
@Component({
  selector: 'app-voilations',
  templateUrl: './voilations.component.html',
  styleUrls: ['./voilations.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class VoilationsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('viewImageDialog') private viewImage!: TemplateRef<any>;

  voilations: GetAllVoilations[];
  columns: TableColumn<GetAllVoilations>[] = [
    { label: 'Image', property: 'picture', type: 'image', visible: true },
    {
      label: 'CameraName',
      property: 'cameraName',
      type: 'text',
      visible: true,
    },
    {
      label: 'Department',
      property: 'departmentName',
      type: 'boolean',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Voilation',
      property: 'voilationName',
      type: 'boolean',
      visible: true,
    },
    { label: 'Time', property: 'timeDate', type: 'date', visible: true },
    {
      label: 'CameraPosition',
      property: 'cameraPosition',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Offender',
      property: 'offenderName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<GetAllVoilations>([]);
  selection = new SelectionModel<GetAllVoilations>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  loadingLabel: string = '';
  sessionCompany: any;
  sessionUser: any;
  timer = null;
  viewImagePath: any;
  cameraId: number = 0;
  voilationForm: FormGroup;
  voilation: GetAllVoilations;
  users: any[] = [];

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  voilationStatuses = VoilationStatus;
  offenderNatures = OffenderNature;
  voilationTypes: string[] = [];
  departments: any[] = [];
  SharedConstants = SharedConstants;

  public selectedVoilationStatus: string[] = [];
  public selectedVoilationType: string[] = [];
  public selectedDepartment: string[] = [];
  private translationsList: any = {};

  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  currentQueryParams = null;
  filteredParams = null;

  hasDeletePermission = ApplicationPermission.VIOLATIONS_DELTE;
  hasCreateUserPermission = ApplicationPermission.USER_CREATE;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private feedBackService: AsyncFeedbackService,
    private baseApi: BaseApi,
    private voilationService: VoilationsService,
    private router: Router,
    private departmentService: DepartmentsService,
    private userService: UserService,
    private loadingService: LoadingService
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'User',
        'deleteVoilation',
        'StaticPages',
        'General',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  override ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;
        this.cameraId = parseInt(id, 10);
      }
    });
    this.voilationForm = this.fb.group({
      offenderNature: [''],
      offenderName: [''],
      isGuestOffender: [false],
      guestOffenderName: [''],
    });
    this.getSessionOrganization();
    this.dataSource.sort = this.sort;

    this.currentQueryParams = this.route.snapshot.queryParams;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedVoilationStatus = this.currentQueryParams?.orgViolationStatus
      ? this.currentQueryParams?.orgViolationStatus.split(',')
      : [];

    this.selectedDepartment = this.currentQueryParams?.orgDepartments
      ? this.currentQueryParams?.orgDepartments.split(',')
      : [];

    this.selectedVoilationType = this.currentQueryParams?.orgVoilationTypes
      ? this.currentQueryParams?.orgVoilationTypes.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex:0
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData()
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }

  getSessionOrganization(): void {
    this.sessionCompany = this.baseApi.getCompanyFromStorage();
    this.sessionUser = this.baseApi.getUserSession();
    this.getCompanyDepartments();
    this.getUsers();
  }

  getUsers(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.users = response;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getCompanyDepartments(): void {
    this.departmentService.getCompanyDepartments().subscribe({
      next: (response) => {
        this.departments = response;
        this.getVoilationNames();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getVoilationNames(): void {
    this.voilationService.getVoilationTypes().subscribe({
      next: (response) => {
        this.voilationTypes = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }

    this.filteredParams = {
      cameraId: this.cameraId,
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      status: this.currentQueryParams?.orgViolationStatus
        ? this.currentQueryParams?.orgViolationStatus.split(',')
        : this.selectedVoilationStatus,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      voilationNames: this.selectedVoilationType.toString(),
      departmentNames: this.selectedDepartment.toString(),
      companyCode: this.sessionCompany.companyCode,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.voilationService.getAllVoilations(this.filteredParams).subscribe({
      next: (response) => {
        this.voilations = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex:0
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  onSelectVoilationStatus(event: MatSelectChange) {
    this.selectedVoilationStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgViolationStatus: this.selectedVoilationStatus.toString(),
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  onSelectVoilation(event: MatSelectChange) {
    this.selectedVoilationType = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgVoilationTypes: this.selectedVoilationType.toString(),
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  onSelectDepartment(event: MatSelectChange) {
    this.selectedDepartment = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgDepartments: this.selectedDepartment.toString(),
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  viewImgDialog(id: number) {
    this.voilation = this.voilations.find((x) => x.id === id);
    this.viewImagePath = this.voilation.picture;
    this.setFormValues(this.voilation);
    this.dialog.open(this.viewImage, { height: '97%', width: '80%' });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  deleteVoilation(id: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['deleteVoilation']['Title'],
          this.translationsList['deleteVoilation']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const data = {
            voilationId: id,
          };
          this.voilationService.deleteVoilation(data).subscribe({
            next: () => {
              this.getData();
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Success,
                  this.translationsList['General']['DeleteVoilation']
                )
              );
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.error.message)
              );
            },
          });
        }
      });
  }

  setFormValues(info: GetAllVoilations): void {
    if (info) {
      const { offenderName, offenderNature, isGuestOffender } = info;
      this.voilationForm.get('offenderNature')?.setValue(offenderNature);
      this.voilationForm.get('isGuestOffender')?.setValue(isGuestOffender);
      if (isGuestOffender) {
        this.voilationForm.get('guestOffenderName')?.setValue(offenderName);
        this.voilationForm.get('offenderName')?.setValue('');
      } else {
        this.voilationForm.get('offenderName')?.setValue(offenderName);
        this.voilationForm.get('guestOffenderName')?.setValue('');
      }
    }
  }

  submitVoilationForm(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const { offenderNature, offenderName, guestOffenderName } =
            this.voilationForm.value;
          if (offenderNature || offenderName || guestOffenderName) {
            const obj = {
              userId: this.sessionUser?.userId,
              voilationId: this.voilation?.id,
              ...this.voilationForm.value,
            };
            this.loadingService.startLoading(true, '');
            this.voilationService.updateVoilation(obj).subscribe({
              next: () => {
                this.getData();
                this.closeDialog();
                this.loadingService.stopLoading();
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Success,
                    this.translationsList['Success']['UpdateMessage']
                  )
                );
              },
              error: (error) => {
                this.loadingService.stopLoading();
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.error.message)
                );
              },
            });
          }
        }
      });
  }

  viewVoilation(id: number): void {
    this.router.navigate(
      [
        '/' +
          SharedConstants.SMART_MONITORING +
          '/' +
          SharedConstants.VOILATIONS +
          '/' +
          SharedConstants.VOILATIONS_VIEW +
          '/' +
          id,
      ],
      {
        queryParams: this.currentQueryParams,
      }
    );
  }
  resetForm(): void {
    this.voilationForm.get('offenderNature')?.setValue('');
    this.voilationForm.get('isGuestOffender')?.setValue('');
    this.voilationForm.get('guestOffenderName')?.setValue('');
    this.voilationForm.get('offenderName')?.setValue('');
  }
}
