<app-state-section
  body
  [flexibleHeight]="true"
  [state]="sectionState"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <div class="flex column gap-2">
    <div class="wrapper_1" style="overflow-wrap: anywhere">
      <app-card [title]="data?.name + ',' + data?.code">
        <div body class="flex justify-between" #pdf>
          <div class="flex-1 w-6/12" style="overflow-wrap: anywhere">
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ data?.createdOn | date : "medium" }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.createdOn" | translate }}
                </p>
              </div>
            </div>
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ data?.inspectionStartDate | date : "medium" }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.StartDate" | translate }}
                </p>
              </div>
            </div>
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">{{ data?.inspectionFrequency }}</p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.InspectionFrequency" | translate }}
                </p>
              </div>
            </div>
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">{{ data?.machinesLocation }}</p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.Location" | translate }}
                </p>
              </div>
            </div>
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ data?.categoryDescription }}
                </p>
                <p class="m-0 caption text-hint" (click)="showMore()">
                  {{ "CategoryDetails.Description" | translate }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex-1 w-6/12 flex-wrap" style="overflow-wrap: anywhere">
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ getListOfProps(data?.inspectors, "inspectorName", true) }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.Inspectors" | translate }}
                </p>
              </div>
            </div>
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ getListOfProps(data?.reviewers, "reviewerName") }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.reviewers" | translate }}
                </p>
              </div>
            </div>
            <div class="py-3 flex items-center">
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{
                    getListOfProps(data?.inspectionChecklists, "checkListName")
                  }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.CheckList" | translate }}
                </p>
              </div>
            </div>

            <div
              class="py-3 flex items-center"
              *ngIf="data?.inspectionFrequency == 'Monthly'"
            >
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ data?.inspectionResetWeek }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.WeekNumber" | translate }}
                </p>
              </div>
            </div>

            <div
              class="py-3 flex items-center"
              *ngIf="
                data?.inspectionFrequency == 'Monthly' ||
                data?.inspectionFrequency == 'Weekly'
              "
            >
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ data?.inspectionResetDay }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.TaskDay" | translate }}
                </p>
              </div>
            </div>

            <div
              class="py-3 flex items-center"
              *ngIf="data?.inspectionFrequency == 'Shift'"
            >
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ getListOfProps(data?.shifts, "shiftTitle") }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.Shifts" | translate }}
                </p>
              </div>
            </div>

            <div
              class="py-3 flex items-center"
              *ngIf="data?.inspectionFrequency == 'Daily'"
            >
              <div
                @scaleIn
                class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
              >
                <mat-icon
                  class="icon-sm"
                  svgIcon="mat:keyboard_double_arrow_right"
                ></mat-icon>
              </div>

              <div @fadeInRight>
                <p class="m-0 body-1">
                  {{ convertToLocalTime(data?.inspectionResetHour) }}
                </p>
                <p class="m-0 caption text-hint">
                  {{ "CategoryDetails.StartTime" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </app-card>
    </div>
    <app-equipment-list
      [CategoryData]="data"
      [page]="page"
    ></app-equipment-list>
    <app-out-of-order-equipments
      [page]="page"
      *ngIf="page !== 'setup'"
    ></app-out-of-order-equipments>
  </div>
</app-state-section>
