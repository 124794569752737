import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root'
})
export class IotAnalogDashboardService {

constructor(
  private http: HttpClient,
  private baseApi: BaseApi
) { }

  getIotAnalogDashboardData(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_ANALOG_DASHBOARD_DATA), data
    );
  }
  getAnalogMachines(){
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_ANALOG_MACHINES)
    );
  }
  getAnalogParameters(machineID:number){
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_ANALOG_PARAMETERS + '/' + machineID)
    );
  }
  createAnalaogDashboard(data){
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CREATE_ANALOG_DASHBOARD), data
    );

  }
}
