import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarEvent } from 'angular-calendar';

@Component({
  selector: 'app-calendar-edit',
  templateUrl: './calendar-edit.component.html',
  styleUrls: ['./calendar-edit.component.scss']
})
export class CalendarEditComponent implements OnInit {
  form = this.fb.group({
    title: null,
    start: null,
    end: null
  });

  constructor(
    private dialogRef: MatDialogRef<CalendarEditComponent>,
    @Inject(MAT_DIALOG_DATA) public event: CalendarEvent<any>,
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.form.patchValue(this.event);
  }
  save() {
    this.dialogRef.close({
      ...this.event,
      ...this.form.value
    });
  }
}
