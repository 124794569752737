import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-free-trial-welcome-message',
  templateUrl: './free-trial-welcome-message.component.html',
  styleUrls: ['./free-trial-welcome-message.component.scss'],
})
export class FreeTrialWelcomeMessageComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { userName: any },
    private dialogRef: MatDialogRef<FreeTrialWelcomeMessageComponent>
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
