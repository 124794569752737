import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { InspectionManagementService } from 'src/backend/services/inspection-management/inspection-management.service';

@Component({
  selector: 'app-inspection-management-inspectors',
  templateUrl: './inspection-management-inspectors.component.html',
  styleUrls: ['./inspection-management-inspectors.component.scss'],
})
export class InspectionManagementInspectorsComponent implements OnInit {
  public inspections = [];
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  constructor(
    private inspectionManagementService: InspectionManagementService,
    private feedBackService: AsyncFeedbackService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getInspectorsInspections();
  }

  getInspectorsInspections() {
    this.sectionState = SectionStateStatus.Loading;
    this.inspectionManagementService.getInspectorsInspections().subscribe({
      next: (res) => {
        this.inspections = res;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  viewInspection(inspectionId) {
    this.router.navigate([
      SharedConstants.INSPECTION_MANAGEMENT +
        '/' +
        SharedConstants.INSPECT_EQUIPMENT +
        '/' +
        inspectionId,
    ]);
  }
}
