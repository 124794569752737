import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { PPE } from 'src/app/company-setup/models/ppe';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';
@Injectable({
  providedIn: 'root',
})
export class PpeService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getAllPpes(data: any): Observable<DataMapper<PPE>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<PPE>>(
      this.baseApi.encodeURL(
        Constants.API_ALL_PPES_LIST_BY_COMPANY + '?' + params
      )
    );
  }

  getActivePpes(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ACTIVE_PPES_LIST_BY_COMPANY)
    );
  }

  createPpe(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_PPE_CREATE),
      data
    );
  }

  updatePpe(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_PPE_UPDATE),
      data
    );
  }

  activatePpe(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_PPE_ACTIVATE + '/' + id)
    );
  }

  deActivatePpe(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_PPE_DEACTIVATE + '/' + id)
    );
  }
}
