import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root'
})
export class IotDigitalDashboardService {

constructor(
  private http: HttpClient,
  private baseApi: BaseApi
) { }

  getIotDigitalDashboardData(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_DIGITAL_DASHBOARD_DATA), data
    );
  }
  getDigitalMachines(){
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_DIGITAL_MACHINES)
    );
  }
  createDigitalDashboard(data:any){
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CREATE_DIGITAL_DASHBOARD), data
    );
  }
}
