import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ActionTrackerModule } from '../action-tracker/action-tracker.module';
import { SharedModule } from '../shared/shared.module';
import { MSDSRoutingModule } from './msds-routing';
import { MsdsComponent } from './pages/msds/msds.component';
import { MsdsListComponent } from './components/msds-list/msds-list.component';
import { AddUpdateMsdsComponent } from './components/add-update-msds/add-update-msds.component';
import { MsdsCreateStepComponent } from './components/msds-create-step/msds-create-step.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MsdsQrcodeComponent } from './components/msds-qrcode/msds-qrcode.component';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [
    MsdsComponent,
    MsdsListComponent,
    AddUpdateMsdsComponent,
    MsdsCreateStepComponent,
    MsdsQrcodeComponent,
  ],
  imports: [
    CommonModule,
    MSDSRoutingModule,
    SharedModule,
    ActionTrackerModule,
    QRCodeModule,
    MatListModule,
  ],
})
export class MSDSModule {}
