import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { SharedConstants } from 'src/app/shared/models/shared-constants';

@Component({
  selector: 'app-id-cards-page',
  templateUrl: './id-cards-page.component.html',
  styleUrls: ['./id-cards-page.component.scss'],
})
export class IdCardsPageComponent implements OnInit {
  hasRenewCard =
    ApplicationPermission.SECURITY_MANAGE_RENEW_ACCESS
  hasnewCard =
    ApplicationPermission.SECURITY_MANAGE_LOSTIDS_ACCESS
  constructor(private route: Router) {}

  ngOnInit() {}

  onNewIdClick(event: string): void {
    this.route.navigate([
      '/' + SharedConstants.SECURITY + '/' + SharedConstants.NEW_ID_CARD,
    ]);
  }

  onUpdateIdClick(event: string): void {
    this.route.navigate([
      '/' + SharedConstants.SECURITY + '/' + SharedConstants.UPDATE_CARD,
    ]);
  }

  onLostIdClick(event: string): void {
    this.route.navigate([
      '/' + SharedConstants.SECURITY + '/' + SharedConstants.LOST_ID_CARD,
    ]);
  }
}
