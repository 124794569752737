import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedConstants } from 'src/app/shared/models/shared-constants';

@Component({
  selector: 'app-work-permit-master-page',
  templateUrl: './work-permit-master-page.component.html',
  styleUrls: ['./work-permit-master-page.component.scss']
})
export class WorkPermitMasterPageComponent implements OnInit {

 

  SharedConstant = SharedConstants;
  constructor(private route: Router) { }

  ngOnInit(): void {
  }
  onWorkConditionsClick(event: string): void {
    this.route.navigate(['/' + SharedConstants.WORK_PERMIT + '/' + SharedConstants.WORK_PERMIT_WORK_CONDITIONS]);
  }
  onHazardsClick(event: string): void {
    this.route.navigate(['/' + SharedConstants.WORK_PERMIT + '/' + SharedConstants.WORK_PERMIT_HAZARDS]);
   
  }

  


}
