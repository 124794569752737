<div class="card overflow-auto">
  <div class="h-full flex flex-col">
    <div class="calendar-bg relative text-contrast-white flex-none">
      <div
        class="bg-primary absolute top-0 left-0 right-0 bottom-0 z-0 w-full h-full"
      ></div>
      <div class="container py-4 px-6 h-32 z-10 relative flex flex-col">
        <div
          class="flex-auto flex flex-col sm:flex-row justify-between items-center"
        >
          <h1
            class="headline my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
          >
            <span>{{ "InspectionPlan.Title" | translate }}</span>
          </h1>
          <app-basic-button
            *ngIf="hasCreatePermissions | permissionChecker"
            [btnType]="'Dark'"
            [btnText]="'PlanAudit'"
            [isDisabled]="false"
            (onClick)="createTask()"
          >
          </app-basic-button>
        </div>
        <div
          class="flex-auto flex flex-col sm:flex-row justify-between items-center"
        >
          <div class="title">
            {{ viewDate | calendarDate : view + "ViewTitle" : "en" }}
          </div>

          <div
            class="actions flex flex-col sm:flex-row justify-end items-center"
          >
            <div class="chevrons">
              <button
                [(viewDate)]="viewDate"
                [view]="view"
                mat-icon-button
                mwlCalendarPreviousView
              >
                <mat-icon svgIcon="mat:chevron_left"></mat-icon>
              </button>
              <button
                [(viewDate)]="viewDate"
                [view]="view"
                mat-icon-button
                mwlCalendarNextView
              >
                <mat-icon svgIcon="mat:chevron_right"></mat-icon>
              </button>
            </div>
            <div class="flex">
              <button
                class="bg-black mx-1"
                (click)="view = CalendarView.Month"
                mat-button
              >
                {{ "General.Buttons.Month" | translate }}
              </button>
              <button
                class="bg-black mx-1"
                (click)="view = CalendarView.Week"
                mat-button
              >
                {{ "General.Buttons.Week" | translate }}
              </button>
              <button
                class="bg-black mx-1"
                (click)="view = CalendarView.Day"
                mat-button
              >
                {{ "General.Buttons.Day" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hodhod-scrollbar
      [ngSwitch]="view"
      class="px-0 shadow flex-auto relative container"
    >
      <mwl-calendar-month-view
        (dayClicked)="dayClicked($event.day)"
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        *ngSwitchCase="'month'"
        [activeDayIsOpen]="activeDayIsOpen"
        [events]="events"
        [refresh]="refresh"
        [viewDate]="viewDate"
      >
      </mwl-calendar-month-view>
      <mwl-calendar-week-view
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        *ngSwitchCase="'week'"
        [events]="events"
        [refresh]="refresh"
        [viewDate]="viewDate"
      >
      </mwl-calendar-week-view>
      <mwl-calendar-day-view
        (eventClicked)="handleEvent('Clicked', $event.event)"
        (eventTimesChanged)="eventTimesChanged($event)"
        *ngSwitchCase="'day'"
        [events]="events"
        [refresh]="refresh"
        [viewDate]="viewDate"
      >
      </mwl-calendar-day-view>
    </hodhod-scrollbar>
  </div>
</div>

<ng-template #editDialog>
  <form [formGroup]="editDialogForm">
    <div mat-dialog-content>
      <p style="font-size: 25px">Do you want to Edit this task?</p>

      <div style="padding: 15px; margin-top: 21px">
        <mat-checkbox matInput formControlName="check" [color]="'warn'"
          >Edit Complete Series
        </mat-checkbox>
      </div>
    </div>
    <div mat-dialog-actions style="float: right">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel' | translate"
        mat-dialog-close
      >
      </app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'General.Edit'| translate"
        (click)="onClickEditDialog()"
      >
      </app-basic-button>
    </div>
  </form>
</ng-template>

<ng-template #deleteDialog>
  <form [formGroup]="deleteDialogForm">
    <div mat-dialog-content>
      <p style="font-size: 25px">Do you want to Delete this task?</p>

      <div style="padding: 15px; margin-top: 21px">
        <mat-checkbox matInput formControlName="check" [color]="'warn'"
          >Delete Complete Series
        </mat-checkbox>
      </div>
    </div>
    <div mat-dialog-actions style="float: right">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel' | translate"
        mat-dialog-close
      >
      </app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'Delete' | translate"
        (click)="onClickDeleteDialog()"
      >
      </app-basic-button>
    </div>
  </form>
</ng-template>
