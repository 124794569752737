<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="'ManageIdCard.BasicInformation' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
 <div class="flex justify-between flex-wrap-reverse">
   <form [formGroup]="userForm" class="flex-1 pr-5">  
    <div class="flex column">
      <mat-label class="field-label">{{'ManageIdCard.CarModel' | translate}}</mat-label>
      <mat-form-field class="input-field" appearance="outline">
        <input [placeholder]="'ManageIdCard.Insert' | translate" matInput 
        type="text" formControlName="phoneNumber" />
      </mat-form-field>
    </div>
    <div class="flex column">
      <mat-label class="field-label">{{'ManageIdCard.PlateNumber' | translate}}</mat-label>
      <mat-form-field class="input-field" appearance="outline">
        <input [placeholder]="'ManageIdCard.Insert' | translate" matInput 
        type="text" formControlName="phoneNumber" />
      </mat-form-field>
    </div>
    
   </form>

   <div class="flex-1">
    <div class="flex column">
      <mat-label class="field-label">{{'ManageIdCard.CarColor' | translate}}</mat-label>
      <mat-form-field class="input-field" appearance="outline">
        <input [placeholder]="'ManageIdCard.Insert' | translate" matInput 
        type="text" formControlName="phoneNumber" />
      </mat-form-field>
    </div>

    </div>
  

  </div>
 <div class="flex-1">
  <div class="flex-row">
    <mat-label class="field-label">{{'ManageIdCard.Comment' | translate}}</mat-label>
    <mat-form-field class="input-field" appearance="outline">
      <textarea  matInput rows="5" [placeholder]="'Placeholder.Comment' | translate" >
      </textarea>
    </mat-form-field>
  </div>
 </div>
 



</app-state-section>



