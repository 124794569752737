import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { SortDirection } from 'src/@hodhod/common/enum';
import { BaseApi } from 'src/backend/api/base-api';
import { MachineAndEquipment } from 'src/app/company-setup/models/machine-and-equipment';

@Injectable({
  providedIn: 'root',
})
export class MachineEquipmentService {
  netApiUrl: string = '';
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getMachineEquipment(data: any): Observable<DataMapper<MachineAndEquipment>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.machineType) {
      if (params.length > 0) {
        params += '&';
      }
      params += `machineType=` + data?.machineType;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<MachineAndEquipment>>(
      this.baseApi.encodeURL(
        Constants.API_MACHINE_EQUIPMENT_FILTERED + '?' + params
      )
    );
  }

  postMachineEquipment(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_MACHINE_EQUIPMENT_POST),
      data
    );
  }
  editMachineEquipment(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_MACHINE_EQUIPMENT_EDIT),
      data
    );
  }
  createMachineParameters(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_MACHINE_PARAMETERS_CREATE),
      data
    );
  }
  editMachineParameters(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_MACHINE_PARAMETERS_EDIT),
      data
    );
  }

  deActivate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_MACHINE_EQUIPMENT_DEACTIVATE + '/' + id
      )
    );
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_MACHINE_EQUIPMENT_ACTIVATE + '/' + id
      )
    );
  }

  getMachineEquipmentById(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_MACHINE_EQUIPMENT_BY_ID + '/' + id)
    );
  }
  checkMachineHasParameter(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_MACHINE_EQUIPMENT_CHECK_MACHINE_HAS_PARAMETER + '/' + id
      )
    );
  }
  getEquipmentList(data: any, id: number) {

    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.machineType) {
      if (params.length > 0) {
        params += '&';
      }
      params += `machineType=` + data?.machineType;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.departmentNames) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'departments=' + data?.departmentNames;
    }
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_MACHINE_EQUIPMENT_LIST_BY_CATEGORY +
          '/' +
          id +
          '?' +
          params
      )
    );
  }
  getCurrentInspections(data: any, id: number, isOutOfOrder) {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.machineType) {
      if (params.length > 0) {
        params += '&';
      }
      params += `machineType=` + data?.machineType;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.departmentNames) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'departments=' + data?.departmentNames;
    }
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_CURRENT_INSPECTIONS_BY_CATEGORY +
          '/' +
          id +
          '/' +
          isOutOfOrder +
          '?' +
          params
      )
    );
  }
  getReviewerEquipments(data: any, id: number, isOutOfOrder) {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.machineType) {
      if (params.length > 0) {
        params += '&';
      }
      params += `machineType=` + data?.machineType;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    // if (data?.IsOutOfOrder) {
    //   if (params.length > 0) {
    //     params += '&';
    //   }
    //   params += 'IsOutOfOrder=' + data?.IsOutOfOrder;
    // }
    if (data?.departmentNames) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'departments=' + data?.departmentNames;
    }
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_REVIEWER_LIST_OF_EQUIPMENTS +
          '/' +
          id +
          '/' +
          isOutOfOrder +
          '?' +
          params
      )
    );
  }
  // getParametersByMachineId(id: number): Observable<any> {
  //   return this.http.get<any>(
  //     this.baseApi.encodeURL(
  //       Constants.API_MACHINE_PARAMETERS_BY_MACHINE + '/' + id
  //     )
  //   );
  // }
  createEquipment(data: any) {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CREATE_EQUIPMENT),
      data
    );
  }
  updateEquipment(data: any) {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_EQUIPMENT_EDIT),
      data
    );
  }
  getEquipmentById(id: any) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_EQUIPMENT_BY_ID + '/' + id)
    );
  }
  getCategoryDetails(id: any) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_CATEGORY_BY_ID + '/' + id)
    );
  }
  ReactivateEquipment(id) {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_EQUIPMENT_REACTIVATE + '/' + id),
      {}
    );
  }
  ActivateEquipment(id) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_EQUIPMENT_ACTIVATE + '/' + id)
    );
  }
  DeactivateEquipment(id) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_EQUIPMENT_DEACTIVATE + '/' + id)
    );
  }
  getMachinesByCompany(): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(Constants.API_MACHINE_EQUIPMENT_ALL)
    );
  }

  // Equipment Category
  createEquipmentCategory(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CREATE_EQUIPMENT_CATEGORY),
      data
    );
  }
  getEquipmentsCategories(data: any): Observable<any> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.checklists) {
      if (params.length > 0) {
        params += '&';
      }
      params += `checklists=` + data?.checklists;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<any[]>(
      this.baseApi.encodeURL(
        Constants.API_GET_EQUIPMENTS_CATEGORIES + '?' + params
      )
    );
  }
  getEquipmentCategoryDetails(id: any): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_EQUIPMENTS_CATEGORIES_BY_ID + '/' + id
      )
    );
  }
  updateEquipmentCategoryDetails(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_EQUIPMENTS_CATEGORY),
      data
    );
  }
  deleteEquipmentCategoryAttachment(id: any): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_DELETE_ATTACHMENT + '/' + id)
    );
  }
  activateEquipmentCategory(id: number, data): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_ACTIVATE_EQUIPMENT_CATEGORY + '/' + id
      ),
      {
        params: data,
      }
    );
  }
  deactivateEquipmentCategory(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_DEACTIVATE_EQUIPMENT_CATEGORY + '/' + id
      )
    );
  }
  getReviewers(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_REVIEWERS)
    );
  }
  getInspectors(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_INSPECTORS)
    );
  }
}
