import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GetBodyParts,
  GetNatureOfInjuiry,
  PostNatureOfInjuiry,
  UpdateNatureOfInjuiry,
} from 'src/backend/models/nature-of-injuiry/nature-of-injuiry.model';
import { Constants } from 'src/@hodhod/common/constants';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { SortDirection } from 'src/@hodhod/common/enum';
import { BaseApi } from 'src/backend/api/base-api';
@Injectable({
  providedIn: 'root',
})
export class NatureOfInjuryService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  postNatureOfInjuiry(data: PostNatureOfInjuiry): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_NATURE_OF_INJUIRY_POST),
      data
    );
  }
  UpdateNatureOfInjuiry(data: UpdateNatureOfInjuiry): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_NATURE_OF_INJUIRY_UPDATE),
      data
    );
  }
  getNatureInjuiry(data: any): Observable<DataMapper<GetNatureOfInjuiry>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<GetNatureOfInjuiry>>(
      this.baseApi.encodeURL(
        Constants.API_NATURE_OF_INJUIRY_GET_ALL + '?' + params
      )
    );
  }
  deActivate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_NATURE_OF_INJUIRY_DEACTIVATE + '/' + id
      )
    );
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_NATURE_OF_INJUIRY_ACTIVATE + '/' + id
      )
    );
  }
  getBodyParts(): Observable<GetBodyParts[]> {
    return this.http.get<GetBodyParts[]>(
      this.baseApi.encodeURL(Constants.API_NATURE_OF_INJUIRY_GET_BODY_PARTS)
    );
  }
  getNatureOfInjureisByCompany(): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(Constants.API_NATURE_OF_INJUIRY_LIST)
    );
  }
}
