import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { UptoAtLeast } from 'src/@hodhod/common/enum';

@Component({
  selector: 'app-edit-dashboard-target-popup',
  templateUrl: './edit-dashboard-target-popup.component.html',
  styleUrls: ['./edit-dashboard-target-popup.component.scss'],
})
export class EditDashboardTargetPopupComponent implements OnInit, OnChanges {
  targetForm: FormGroup;

  uptoAtLeast = UptoAtLeast;
  public translationsList: any = {};
  private destroy$ = new Subject();
  public updatedData: any = null;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: { targetData: any; targetTitle: string }
  ) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  ngOnChanges(changes: SimpleChanges): void {}

  ngOnInit(): void {
    this.targetForm = this.fb.group({
      target: [this.data.targetData.originalTarget, [Validators.required]],
      uptoAtleast: [
        this.data.targetData.targetUptoAtLeast,
        [Validators.required],
      ],
    });
    if (
      this.data.targetTitle == 'Audit Score' ||
      this.data.targetTitle == 'Audit Adherence'
    ) {
      this.targetForm = this.fb.group({
        target: [
          this.data.targetData.originalTarget,
          [Validators.required, Validators.min(0), Validators.max(100)],
        ],
        uptoAtleast: [
          this.data.targetData.targetUptoAtLeast,
          [Validators.required],
        ],
      });
    }
  }
  updateDashboardTarget() {
    this.updatedData = {
      id: this.data.targetData.targetId,
      trendingType: this.data.targetData.trendingType,
      target: this.targetForm.value.target,
      uptoAtLeast: this.targetForm.value.uptoAtleast,
      isUpdate: true,
    };
  }
  cancelForm() {
    this.dialog.closeAll();
    this.updatedData = {
      isUpdate: false,
    };
  }
}
