import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { CreateOrganizationPageComponent } from './pages/create-organization-page/create-organization-page.component';
import { OrganizationPageComponent } from './pages/organization-page/organization-page.component';
import { OrganizationViewPageComponent } from './pages/organization-view-page/organization-view-page.component';
import { UpdateOrganizationPageComponent } from './pages/update-organization-page/update-organization-page.component';
import { ChatbotUploadDocumentsComponent } from './pages/chatbot-upload-documents/chatbot-upload-documents.component';
import { TrustedSourcesPageComponent } from './pages/trusted-sources-page/trusted-sources-page.component';
import { SecurityFeaturesPageComponent } from './pages/security-features-page/security-features-page.component';
import { OrgDetailsPageComponent } from './pages/org-details-page/org-details-page.component';

const routes: Routes = [
  {
    path: SharedConstants.ORGANIZATIONS,
    component: OrganizationPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ORGANIZATIONS_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Organizations',
      },
    },
  },
  {
    path: SharedConstants.ORGANIZATION_VIEW + '/:id',
    component: OrganizationViewPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ORGANIZATIONS_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.ORGANIZATION_DETAIL + '/:id',
    component: OrgDetailsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ORGANIZATIONS_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.ORGANIZATION_DETAIL + '/:id',
    component: OrgDetailsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ORGANIZATIONS_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.ORGANIZATION_CREATE,
    component: CreateOrganizationPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ORGANIZATIONS_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'CreateNew',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.TRUSTED_SOURCES,
    component: TrustedSourcesPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ORGANIZATIONS_VIEW,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'ChatbotTrustedSources',
        isActive: false,
      },
    },
  },
  {
    path: SharedConstants.ORGANIZATION_UPDATE + '/:id',
    component: UpdateOrganizationPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ORGANIZATIONS_UPDATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Edit',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.CHATBOT_UPLOAD_DOCUMENTS,
    component: ChatbotUploadDocumentsComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ORGANIZATIONS_CREATE,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'ChatbotUploadDocuments',
        isActive: false,
      },
    },
  },
  {
    path: SharedConstants.SECURITY_FEATURES,
    component: SecurityFeaturesPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ORGANIZATIONS_UPDATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Organizations',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.SECURITY_FEATURES,
    component: SecurityFeaturesPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ORGANIZATIONS_UPDATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Organizations',
        isActive: true,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OrganizationSetupRoutingModule {}
