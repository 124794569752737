import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CompanySetupRoutingModule } from './company-setup-routing.module';
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { ViewCompanyPageComponent } from './pages/view-company-page/view-company-page.component';
import { CompaniesComponent } from './components/companies/companies.component';
import { CompanyDetailComponent } from './components/company-detail/company-detail.component';
import { SharedModule } from '../shared/shared.module';
import { CompanyPermissionsComponent } from './components/company-permissions/company-permissions.component';
import { CreateCompanyPageComponent } from './pages/create-company-page/create-company-page.component';
import { UpdateCompanyPageComponent } from './pages/update-company-page/update-company-page.component';
import { CreateCompanyComponent } from './components/create-company/create-company.component';
import { UpdateCompanyComponent } from './components/update-company/update-company.component';
import { CompanyService } from 'src/backend/services/company.service';
import { MachineEquipmentPageComponent } from './pages/machine-equipment-page/machine-equipment-page.component';
import { MachineEquipmentComponent } from './components/machine-equipment/machine-equipment.component';
import { AddNewMachinePageComponent } from './pages/add-new-machine-page/add-new-machine-page.component';
import { DepartmentsComponent } from './components/departments/departments.component';
import { DepartmentsPageComponent } from './pages/departments-page/departments-page.component';
import { AddDepartmentComponent } from './components/add-department/add-department.component';
import { ViewDepartmentPageComponent } from './pages/view-department-page/view-department-page.component';
import { ViewDepartmentComponent } from './components/view-department/view-department.component';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { UnitOfMeasureService } from 'src/backend/services/master/unit-of-measure.service';
import { ValueTypeService } from 'src/backend/services/master/value-type.service';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import { EditViewMachinePageComponent } from './pages/edit-view-machine-page/edit-view-machine-page.component';
import { UnitOfMeasurePageComponent } from './pages/unit-of-measure-page/unit-of-measure-page.component';
import { ValueTypePageComponent } from './pages/value-type-page/value-type-page.component';
import { UnitOfMeasuresComponent } from './components/unit-of-measures/unit-of-measures.component';
import { ValueTypesComponent } from './components/value-types/value-types.component';
import { PpeComponent } from './pages/ppe/ppe.component';
import { PpeListComponent } from './components/ppe-list/ppe-list.component';
import { ShiftsPageComponent } from './pages/shifts-page/shifts-page.component';
import { ShiftsListComponent } from './components/shifts-list/shifts-list.component';
import { EquipmentsCategoriesComponent } from './components/equipments-categories/equipments-categories.component';
import { AddUpdateEquipmentCategoryComponent } from './components/add-update-equipment-category/add-update-equipment-category.component';
import { ViewCategoryDetailsComponent } from './components/view-category-details/view-category-details.component';
import { CategoryPageComponent } from './pages/category-page/category-page.component';
import { EquipmentListComponent } from './components/equipment-list/equipment-list.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { OutOfOrderEquipmentsComponent } from './components/out-of-order-equipments/out-of-order-equipments.component';
import { AreasListComponent } from './components/areas-list/areas-list.component';
import { AreasPageComponent } from './pages/areas-page/areas-page.component';
import { TeamsPageComponent } from './pages/teams-page/teams-page.component';
import { TeamsListComponent } from './components/teams-list/teams-list.component';


@NgModule({
  declarations: [
    CompanyPageComponent,
    ViewCompanyPageComponent,
    CompaniesComponent,
    CompanyDetailComponent,
    CompanyPermissionsComponent,
    CreateCompanyPageComponent,
    UpdateCompanyPageComponent,
    CreateCompanyComponent,
    UpdateCompanyComponent,
    MachineEquipmentPageComponent,
    MachineEquipmentComponent,
    AddNewMachinePageComponent,
    DepartmentsComponent,
    DepartmentsPageComponent,
    AddDepartmentComponent,
    ViewDepartmentPageComponent,
    ViewDepartmentComponent,
    EditViewMachinePageComponent,
    UnitOfMeasurePageComponent,
    ValueTypePageComponent,
    UnitOfMeasuresComponent,
    ValueTypesComponent,
    PpeComponent,
    PpeListComponent,
    ShiftsPageComponent,
    ShiftsListComponent,
    EquipmentsCategoriesComponent,
    AddUpdateEquipmentCategoryComponent,
    ViewCategoryDetailsComponent,
    CategoryPageComponent,
    EquipmentListComponent,
    OutOfOrderEquipmentsComponent,
    AreasListComponent,
    AreasPageComponent,
    TeamsPageComponent,
    TeamsListComponent,
  ],
  imports: [
    CommonModule,
    CompanySetupRoutingModule,
    SharedModule,
    NgxGaugeModule,
    NgxMaterialTimepickerModule,
    ImageCropperModule,
  ],
  providers: [
    CompanyService,
    DepartmentsService,
    UnitOfMeasureService,
    ValueTypeService,
    MachineEquipmentService,
  ],
  exports: [
    ViewCategoryDetailsComponent,
  ]
})
export class CompanySetupModule {}
