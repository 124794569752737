<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.OrganizationSettings' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="'organization.LoadingInfo' | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <div>
        <app-organization-detail [orgInfo]="orgInfo" [orgLicenseInfo]="orgLicenseInfo"></app-organization-detail>
      </div>
      <div class="mt-3">
        <app-organization-permissions
          [features]="selectedFeatures"
        ></app-organization-permissions>
      </div>
      <div class="mt-3" *ngIf="orgInfo?.id">
        <app-organization-companies
          [organizationId]="orgInfo.id"
          [organizationCode]="orgInfo.organizationCode"
        ></app-organization-companies>
      </div>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>
