import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-dashboard-settings',
  templateUrl: './dashboard-settings.component.html',
  styleUrls: ['./dashboard-settings.component.scss']
})
export class DashboardSettingsComponent implements OnInit {
  public loadSmartMonitoring: boolean = false;
  public loadSafetyKPIs: boolean = false;
  hasTargetAccessPermission=ApplicationPermission.DASHBOARD_TARGET_VIEW
  hassafetyKpisAccessPermission=ApplicationPermission.SAFETY_KPIS_MANAGEMENT_ACCESS

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
  }
  tabChanged(event) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
    });
    const selectedTabIndex = event.index;
    if (selectedTabIndex == 0) {
      this.loadSafetyKPIs = !this.loadSmartMonitoring;
    } else if (selectedTabIndex == 1) {
      this.loadSmartMonitoring = true;
    }
  }

}
