<div
  class="card h-full overflow-hidden rounded-xl relative cursor-pointer custom-shadow pt-3 pb-5"
  (click)="onButtonClick()"
>
  <div class="ms-3 pb-5">
    <h2>{{'EmergencyContact.AddContact'|translate}}</h2>
  </div>
  <div class="absolute bottom-3 right-3">
    <svg
      width="35"
      height="35"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="15.846"
        cy="15.9888"
        rx="15.846"
        ry="15.236"
        fill="#73C75E"
      />
      <rect
        x="13.2205"
        y="6.63086"
        width="5.2511"
        height="19.9854"
        fill="white"
      />
      <rect
        x="5.8689"
        y="19.2533"
        width="5.25933"
        height="19.9542"
        transform="rotate(-90 5.8689 19.2533)"
        fill="white"
      />
    </svg>
  </div>
</div>
