<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'BreadCrumb.WorkPermit' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-edit-work-permit
      *ngIf="mode === 'edit'"
      [workPermit]="workPermit"
      [workPermitId]="workPermitId"
    >
    </app-edit-work-permit>
    <app-view-work-permit
      *ngIf="mode === 'view'"
      [workPermit]="workPermit"
      (onReloadData)="reloadData($event)"
    ></app-view-work-permit>
  </hodhod-page-layout-content>
</hodhod-page-layout>
