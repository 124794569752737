import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { OrganizationStatus, SortDirection } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { Confirmation, ConfirmationType } from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { OrganizationService } from 'src/backend/services/organization.service';
import { Organization } from '../../models/organization';

@UntilDestroy()
@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class OrganizationsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  organizations: Organization[];
  columns: TableColumn<Organization>[] = [
    {
      label: 'Image',
      property: 'organizationImage',
      type: 'image',
      visible: false,
    },
    {
      label: 'Code',
      property: 'organizationCode',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Name',
      property: 'organizationName',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'Features',
      property: 'features',
      type: 'icons',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'Eligible Companies',
      property: 'noOfEligibleCompanies',
      type: 'text',
      visible: true,
    },
    {
      label: 'Eligible Users',
      property: 'noOfEligibleUsers',
      type: 'text',
      visible: true,
    },
    {
      label: 'Created At',
      property: 'createdOn',
      type: 'date',
      visible: false,
    },
    {
      label: 'Updated At',
      property: 'updatedOn',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-small'],
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  public pageSize = Constants.PAGE_SIZE;
  public pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  public dataSource = new MatTableDataSource<Organization>([]);
  selection = new SelectionModel<Organization>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  OrganizationStatuses = OrganizationStatus;
  SharedConstants = SharedConstants;

  public selectedOrganizationStatus: string[] = [];

  private translationsList: any = {};
  constructor(
    private translate: TranslateService,
    private router: Router,
    private orgService: OrganizationService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'organization'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.getData())
      )
      .subscribe();

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: sortDirection,
      sortField,
      orgStatus: this.selectedOrganizationStatus.toString(),
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      searchValue: this.searchValue
    }
    this.loadingLabel = "General.Refreshing";
    this.sectionState = SectionStateStatus.Loading;
    this.orgService.getOrganizations(filteredParams).subscribe({
      next: (response) => {
        this.organizations = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.paginator.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready
      }
    })
  }
  onSelectOrgStatus(event: MatSelectChange) {
    this.selectedOrganizationStatus = event.value;
    this.getData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.paginator.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  deactivateOrganization(event: any, orgId: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['organization']['StatusConfirm']['Title'],
          this.translationsList['organization']['StatusConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = "organization.DeactivatingOrganization";
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.orgService.deactivateOrganization(orgId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready
            }
          })
        }
      });
  }
  activateOrganization(event: any, orgId: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['organization']['StatusConfirm']['Title'],
          this.translationsList['organization']['StatusConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = "organization.ActivatingOrganization";
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.orgService.activateOrganization(orgId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready
            }
          })
        }
      });
  }
  viewOrganization(event: any, orgCode: string): void {
    this.router.navigate([
      '/' +
      SharedConstants.ORGANIZATION_SETUP +
      '/' +
      SharedConstants.ORGANIZATION_VIEW +
      '/' +
      orgCode,
    ]);
  }

  createOrganization() {
    this.router.navigate(['/' + SharedConstants.ORGANIZATION_SETUP + '/' + SharedConstants.ORGANIZATION_CREATE]);
  }

  updateOrganization(event: any, orgId: number) {
    this.router.navigate(['/' + SharedConstants.ORGANIZATION_SETUP + '/' + SharedConstants.ORGANIZATION_UPDATE + '/' + orgId]);
  }
}
