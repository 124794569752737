import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import {
  NearMissReport,
  NearMissReportHistory,
} from 'src/app/reporting/models/near-miss-report';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class NearMissReportService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  createNearMissReport(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_NEAR_MISSES_CREATE),
      data
    );
  }

  getNearMissReports(data: any): Observable<DataMapper<NearMissReport>> {
    const params = new URLSearchParams();
    if (data?.pageIndex) {
      params.set('offset', data?.pageIndex.toString());
    }
    if (data?.pageSize) {
      params.set('limit', data?.pageSize.toString());
    }
    if (data?.status) {
      params.set('status', data?.status);
    }
    if (data?.saverity) {
      params.set('saverity', data?.saverity);
    }
    if (data?.sortDirection !== SortDirection.None) {
      params.set('sortDirection', data?.sortDirection);
    }
    if (data?.sortField) {
      params.set('sortField', data?.sortField);
    }
    if (data?.searchValue) {
      params.set('phrase', data?.searchValue);
    }
    if (data?.departments) {
      params.set('departments', data?.departments);
    }
    const encodedParams = params.toString();

    return this.http.get<DataMapper<NearMissReport>>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_NEAR_MISSES + '?' + encodedParams
      )
    );
  }
  getNearMissReportById(id: number): Observable<NearMissReport> {
    return this.http.get<NearMissReport>(
      this.baseApi.encodeURL(Constants.API_REPORT_NEAR_MISSE_BY_ID + '/' + id)
    );
  }
  getCustomUsersHierarchyFromNearMiss(id: number): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_CUSTOM_USERS_HIERARCHY_FROM_NEARMISS + '/' + id
      )
    );
  }
  deleteNearMissReportImage(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_NEAR_MISSE_REMOVE_IMG + '/' + id
      )
    );
  }

  editNearMissReport(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_NEAR_MISSE_EDIT),
      data
    );
  }

  getNearMissReportHistory(
    id: number
  ): Observable<DataMapper<NearMissReportHistory>> {
    return this.http.get<DataMapper<NearMissReportHistory>>(
      this.baseApi.encodeURL(Constants.API_REPORT_NEAR_MISSE_HISTORY + '/' + id)
    );
  }

  closeNearMissReport(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_NEAR_MISSE_CLOSE),
      data
    );
  }
  requireInvestigationNearMiss(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_INVESTIGATION_NEARMISS + '/' + id
      ),
      {}
    );
  }
  teamSelectedNearMiss(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_TEAMSELECTED_NEARMISS + '/' + id
      ),
      {}
    );
  }
  teamDeSelectNearMiss(id: number, comment: string): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_TEAM_DE_SELECT_NEARMISS + '/' + id
      ),
      { comment }
    );
  }
  investigationCompletedNearMiss(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_INVESTIGATION_COMPLETED_NEARMISS + '/' + id
      ),
      {}
    );
  }
  nearMissStatusChangesToInprogress(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_NEARMISS_STATUS_CHANGES_TO_INPROGRESS + '/' + id
      ),
      {}
    );
  }
  nearMissTeamApproved(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_NEARMISS_TEAM_APPROVED + '/' + id
      ),
      {}
    );
  }

  removeNearMissReport(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_NEARMISS_DELETE + '/' + id)
    );
  }
}
