<div class="card overflow-auto my-5">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "out of order" | translate }}</span>
    </h2>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>
  </div>

  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'image'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          ></th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <ngx-avatars
              class="logo box"
              size="32"
              [src]="row['imageUrl']"
              [name]="row['imageName'] || row['code']"
              [textSizeRatio]="2"
              [round]="true"
              [initialsSize]="2"
            ></ngx-avatars>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "EquipmentList.Table." + column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date' && column.property !== 'executedOn'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "EquipmentList.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "medium" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date' && column.property === 'executedOn'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "EquipmentList.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "medium" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            {{ "User.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              *ngIf="page !== 'setup'"
              class="flex justify-center align-center status"
              [attr.id]="row[column.property]"
            >
              <span>{{
                "Enum.CompanyUserStatus." + row[column.property] | translate
              }}</span>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions" stickyEnd *ngIf="page === 'review'">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let row" mat-cell>
          <div (click)="$event.stopPropagation()">
            <button mat-icon-button [matMenuTriggerFor]="menu1">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu1="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                (click)="Reactivate(row?.equipmentId)"
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
              >
                {{ "General.Buttons.Reactivate" | translate }}
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>
<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-icon
      class="text-[#757575] me-1"
      *ngIf="!column.visible"
      svgIcon="mat:check_box_outline_blank"
    ></mat-icon>
    <mat-icon
      *ngIf="column.visible"
      class="text-primary me-1"
      svgIcon="mat:check_box"
    ></mat-icon>
    {{ "EquipmentList.Table." + column.label | translate }}
  </button>
</mat-menu>
