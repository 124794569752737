<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'BreadCrumb.View' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div>
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'Role.RoleInfo' | translate"
      >
        <div body class="flex column">
          <div class="">
            <form [formGroup]="roleForm">
              <div>
                <mat-label class="field-label"
                  >{{ "Role.RoleName" | translate }}
                  <span
                    [ngClass]="
                      roleForm.invalid &&
                      roleForm.get('roleName').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <input
                    [placeholder]="'Placeholder.AddText' | translate"
                    matInput
                    type="text"
                    formControlName="roleName"
                  />
                  <mat-error
                    *ngIf="
                      roleForm.invalid &&
                      roleForm.get('roleName').hasError('required') &&
                      roleForm.controls['roleName'].touched
                    "
                  >
                    <div class="flex justify-flex-start align-flex-center">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>{{ "Errors.Required" | translate }}</span>
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- Is External -->
              <div class="field flex column align-middle justify-center">
                <mat-checkbox matInput formControlName="isExternal">
                  {{ "Role.IsExternal" | translate }}
                </mat-checkbox>
              </div>
            </form>
          </div>
        </div>
      </app-expandable-panel>
    </div>
    <div class="mt-5">
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'Role.ManageRolePermissions' | translate"
      >
        <div body class="flex column">
          <app-role-permission
            [permissions]="rolePermissions"
            (savePermissionsEvent)="saveChangesPermissions($event)"
            [roleName]="roleForm.controls['roleName'].value"
            [createFlag]="'Create'"
          >
          </app-role-permission>
        </div>
      </app-expandable-panel>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
