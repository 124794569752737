import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { takeUntil, tap } from 'rxjs/operators';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { MatSelectChange } from '@angular/material/select';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import {
  CompanyUserStatus,
  SortDirection,
  VerificationValues,
} from 'src/@hodhod/common/enum';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { AddDepartmentComponent } from '../add-department/add-department.component';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { GetAllDepartments } from 'src/backend/models/departments/departments.model';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { PermissionService } from 'src/backend/services/permission.service';

@UntilDestroy()
@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class DepartmentsComponent extends BaseComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  departments: GetAllDepartments[];
  columns: TableColumn<GetAllDepartments>[] = [
    {
      label: 'Name',
      property: 'name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'DepartmentCode',
      property: 'code',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Users',
      property: 'users',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Representor',
      property: 'representor',
      type: 'text',
      visible: true,
    },
    {
      label: 'UpdatedAt',
      property: 'updatedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<GetAllDepartments>([]);
  selection = new SelectionModel<GetAllDepartments>(true, []);
  searchCtrl = new UntypedFormControl();
  public selectedDepartmentStatus: string[] = [];
  searchValue: string = '';
  loadingLabel: string = '';
  timer = null;

  CompanyUserStatus = CompanyUserStatus;
  SharedConstants = SharedConstants;
  VerificationValues = VerificationValues;

  public selectedUserStatus: CompanyUserStatus = null;
  public selectedUserVerification: CompanyUserStatus = null;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  private translationsList: any = {};

  hasAccessPermissions = ApplicationPermission.DEPARTMENTS_ACCESS;
  hasWritePermissions = ApplicationPermission.DEPARTMENTS_CREATE;
  hasEditPermissions = ApplicationPermission.DEPARTMENTS_UPDATE;
  hasActivatePermissions = ApplicationPermission.DEPARTMENTS_ACTIVATE;

  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private departmentService: DepartmentsService,
    private confirmationService: ConfirmationService,
    private feedBackService: AsyncFeedbackService,
    private permissionService: PermissionService,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'ConfirmDeleteRecord',
        'StaticPages',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedDepartmentStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      status: this.currentQueryParams?.orgStatus,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.departmentService.getDepartmentList(filteredParams).subscribe({
      next: (response) => {
        this.departments = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onSelectStatus(event: MatSelectChange) {
    this.selectedDepartmentStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  createDepartment() {
    this.dialog
      .open(AddDepartmentComponent, {
        height: 'auto',
        width: '40%',
      })
      .afterClosed()
      .subscribe((department: GetAllDepartments) => {
        this.getData();
      });
  }

  updateDepartment(event: any, department: GetAllDepartments) {
    this.dialog
      .open(AddDepartmentComponent, {
        height: 'auto',
        width: '40%',
        data: {
          defaults: department,
        },
      })
      .afterClosed()
      .subscribe((updatedUser) => {
        this.getData();
      });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  deactivateDepartment(event: any, id: number): any {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.departmentService.deActivate(id).subscribe({
            next: (res) => {
              this.getData();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Failure,
                  this.translationsList['Errors']['ErrorHappened']
                )
              );
            },
          });
        }
      });
  }
  activateDepartment(event: any, id: number): any {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.departmentService.activate(id).subscribe({
            next: (res) => {
              this.getData();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Failure,
                  this.translationsList['Errors']['ErrorHappened']
                )
              );
            },
          });
        }
      });
  }
  viewDepartment(event: any, id: number): void {
    this.router.navigate(
      [
        SharedConstants.COMPANY_SETUP +
          '/' +
          SharedConstants.DEPARTMENT_VIEW +
          '/' +
          id,
      ],
      { queryParams: { ...this.currentQueryParams } }
    );
  }
  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
}
