 
// custome text typing animation method

import { SkeletonParts } from "./enum";

export function typing_animation() {
    let text_element: any = document.querySelector(".animated_text");
    let text_array: any = text_element?.innerHTML.split("");
    let text_array_slice: any = text_element?.innerHTML.split(" ");
    let text_len: any = text_array?.length;

    const word_len = text_array_slice?.map((word: any) => {
        return word.length;
    })
    let timings: any = {
        easing: `steps(${Number(word_len[0] + 1)}, end)`,
        delay: 2000,
        duration: 2000,
        fill: 'forwards'
    }

    let cursor_timings = {
        duration: 700,
        iterations: Infinity,
        easing: 'cubic-bezier(0,.26,.44,.93)'
    }

    document.querySelector(".text_cursor")?.animate([
        {
            opacity: 0
        },
        {
            opacity: 0, offset: 0.7
        },
        {
            opacity: 1
        }
    ], cursor_timings);

    if (text_array_slice?.length == 1) {
        timings.easing = `steps(${Number(word_len[0] === 0 ? 1 : word_len[0])}, end)`;

        let reveal_animation: any = document.querySelector(".text_hide")?.animate([
            { left: '0%' },
            { left: `${(100 / text_len) * (word_len[0])}%` }
        ], timings);

        document.querySelector(".text_cursor")?.animate([
            { left: '0%' },
            { left: `${(100 / text_len) * (word_len[0])}%` }
        ], timings);

        reveal_animation.onfinish = () => {
            setTimeout(() => {
                document.querySelector('.text_hide')?.animate([
                    {left: '0%'}
                ], {
                    duration: 2000,
                    easing: 'cubic-bezier(.73,0,.38,.88)'
                });
                document.querySelector('.text_cursor')?.animate([
                    {left: '0%'}
                ], {
                    duration: 2000,
                    easing: 'cubic-bezier(.73,0,.38,.88)'
                });
                typing_animation();
            }, 1000);
        }
    } else {
        document.querySelector(".text_hide")?.animate([
            { left: '0%' },
            { left: `${(100 / text_len) * (word_len[0] + 1)}%` }
        ], timings);

        document.querySelector(".text_cursor")?.animate([
            { left: '0%' },
            { left: `${(100 / text_len) * (word_len[0] + 1)}%` }
        ], timings);
    }


    for (let i = 1; i < text_array_slice.length; i++) {
        const single_word_len = word_len[i];

        if (i == 1) {
            var left_instance: any = (100 / text_len) * (word_len[i - 1] + 1);
        }

        let timings_2: any = {
            easing: `steps(${Number(single_word_len + 1)}, end)`,
            delay: (2 * (i + 1) + (2 * i)) * (1000),
            // delay: ((i*2)-1)*1000,
            duration: 2000,
            fill: 'forwards'
        }

        if (i == (text_array_slice.length - 1)) {
            timings_2.easing = `steps(${Number(single_word_len)}, end)`;
            let reveal_animation: any = document.querySelector(".text_hide")?.animate([
                { left: `${left_instance}%` },
                { left: `${left_instance + ((100 / text_len) * (word_len[i]))}%` }
            ], timings_2);

            document.querySelector(".text_cursor")?.animate([
                { left: `${left_instance}%` },
                { left: `${left_instance + ((100 / text_len) * (word_len[i]))}%` }
            ], timings_2);

            reveal_animation.onfinish = () => {
                setTimeout(() => {
                    document.querySelector('.text_hide')?.animate([
                        {left: '0%'}
                    ], {
                        duration: 2000,
                        easing: 'cubic-bezier(.73,0,.38,.88)'
                    });
                    document.querySelector('.text_cursor')?.animate([
                        {left: '0%'}
                    ], {
                        duration: 2000,
                        easing: 'cubic-bezier(.73,0,.38,.88)'
                    });
                    typing_animation();
                }, 1000);
            }
        } else {
            document.querySelector(".text_hide")?.animate([
                { left: `${left_instance}%` },
                { left: `${left_instance + ((100 / text_len) * (word_len[i] + 1))}%` }
            ], timings_2);

            document.querySelector(".text_cursor")?.animate([
                { left: `${left_instance}%` },
                { left: `${left_instance + ((100 / text_len) * (word_len[i] + 1))}%` }
            ], timings_2);
        }

        left_instance = left_instance + ((100 / text_len) * (word_len[i] + 1));
    }
}


// generate new guid (google unique identifire) string
export const generateGuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {  
        var r = Math.random()*16|0, v = c === 'x' ? r : (r&0x3|0x8);  
        return v.toString(16);  
     });
}

// generate user code 

export const generateUserCode = (first: string, second: string) => {
    return first[0]?.toUpperCase() + second[0]?.toUpperCase() + '-' + Date.now();
}

export const setRowSelection = (found: any): boolean => {
    if (found.isWrite || found.isRead || found.isUpdate || found.isDelete
      || found.isApprove || found.isReject || found.isActivate 
      || found.isDeactivate) {
      return true;
  }
  return false;
}

export function filterPermissions(arr: any[]) {
    return arr.map(obj => {
      return {
        ...obj,
        childMenus: obj.childMenus.filter(c => c.rowSelected === true)
      };
    });
  }

export function enumToArray(value: object) {
    const result = [];
    const keys = Object.keys(value);
    const values = Object.values(value);
    for (let i = 0; i < keys.length; i++) {
      result.push(values[i]);
    }
    return result;
  }


  