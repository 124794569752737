<app-card [title]="'organization.OrganizationPermissions' | translate">    
    <div body class="grid gap-2 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1" *ngIf="features.length > 0">
        <div class="rounded overflow-hidden shadow-lg border-b-4 border-primary" 
        *ngFor="let feature of features;let i = index;">
            <div class="group w-full h-52 overflow-hidden relative">
                <img class="transition-all absolute top-0 left-0 h-full w-full hover:scale-110" [src]="'assets/img/resources/' + feature.image" [alt]="feature.featureName">
                <div class="transition-transform duration-500
                flex flex-col justify-center items-center font-bold
                text-primary feature_card_bg 
                absolute bg-clip-content p-6 border-4 border-primary/50 border-dashed
                top-0 left-0 h-full w-full z-50 
                transform translate-y-60 group-hover:translate-y-0
                ">
                <img [src]="'assets/img/resources/' + feature.icon" [alt]="feature.featureName"
                class="w-16 mb-2"
                >
                {{'Feature.' + feature.featureName | translate}}</div>
            </div>
            <div class="px-4 py-4">
            <div class="font-bold text-lg mb-2
            bg-clip-text text-transparent bg-gradient-to-r from-primary to-black
            text-center
            ">{{'Feature.' + feature.featureName | translate}}</div>
            </div>
        </div>
    </div>
</app-card>