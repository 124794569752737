import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { HazardReportService } from 'src/backend/services/reportings/hazard-report.service';

@Component({
  selector: 'app-hazard-report-edit',
  templateUrl: './hazard-report-edit.component.html',
  styleUrls: ['./hazard-report-edit.component.scss'],
})
export class HazardReportEditComponent implements OnInit {
  public hazardReport: any;
  public hazardId: any;

  constructor(
    private hazardReportService: HazardReportService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.hazardId = params['id'];
      this.getHazardReport();
    });
  }

  getHazardReport(): void {
    this.loadingService.startLoading(true);
    this.hazardReportService.getHazardReportById(this.hazardId).subscribe({
      next: (response) => {
        this.hazardReport = response;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  updateHazardReport(hazardForm: any) {
    const formData = new FormData();
    const {
      title,
      rootCause,
      shift,
      department,
      cause,
      description,
      typeOfReportIds,
      attachments,
      subDepartment,
    } = hazardForm;
    const data = {
      id: this.hazardId,
      title: title,
      shift: shift,
      description: description,
      departmentId: subDepartment ? subDepartment : department,
      rootCauseId: rootCause,
      causeId: cause,
      typeOfReportIds: typeOfReportIds.toString(),
    };
    formData.append('content', JSON.stringify(data));
    attachments.forEach((element) => {
      formData.append('files', element);
    });
    this.loadingService.startLoading(true);
    this.hazardReportService.editHazardReport(formData).subscribe({
      next: (response) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, response?.message)
        );
        this.loadingService.stopLoading();
        this.router.navigate([
          '/' +
            SharedConstants.REPORTING_MANAGEMENT +
            '/' +
            SharedConstants.REPORT_HAZARD,
        ]);
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
}
