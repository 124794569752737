<div class="flex items-center justify-end my-1">
  <app-basic-button
    *ngIf="roleId && (hasUpdatePermission | permissionChecker)"
    [btnType]="'Add'"
    [btnText]="!editMode ? 'Edit' : 'View'"
    (onClick)="changeMode()"
  >
  </app-basic-button>
</div>

<div class="flex justify-between flex-wrap-reverse">
  <form [formGroup]="roleForm" class="flex-1 pr-5">
    <div class="flex column">
      <mat-label class="field-label">{{
        "Role.RoleName" | translate
      }}</mat-label>
      <mat-form-field class="input-field" appearance="outline">
        <input
          [placeholder]="'Placeholder.AddText' | translate"
          matInput
          [readonly]="!editMode && roleId"
          type="text"
          formControlName="roleName"
          (change)="onChangeRoleName($event)"
        />
      </mat-form-field>
    </div>
    <!-- Is External -->
    <div class="field flex column align-middle justify-center">
      <mat-checkbox
        matInput
        formControlName="isExternal"
        (change)="onChangeIsExternal($event)"
      >
        {{ "Role.IsExternal" | translate }}
      </mat-checkbox>
    </div>
  </form>
</div>
