<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.Machines' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <mat-tab-group
      class="dashboard-tabs"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab
        *ngIf="hasReadMachinePermission | permissionChecker"
        class="tab-btn"
        [label]="'Machine.MachinesListTitle' | translate"
      >
        <app-machine-equipment class="mt-2"></app-machine-equipment>
      </mat-tab>
      <mat-tab
        *ngIf="hasReadEquipmentPermission | permissionChecker"
        class="tab-btn"
        [label]="'Machine.EquipmentsListTitle' | translate"
      >
        <app-equipments-categories class="mt-2"></app-equipments-categories>
      </mat-tab>
    </mat-tab-group>
  </hodhod-page-layout-content>
</hodhod-page-layout>
