import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from '../api/base-api';
import { Observable } from 'rxjs';
import { DataMapper } from '../models/voilations/voilation.model';
import { Penalty } from 'src/app/penalty-management/models/penalty-management';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';

@Injectable({
  providedIn: 'root',
})
export class PenaltyManagementService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getApproverList(): Observable<any> {
    return this.http.get<DataMapper<Penalty>>(
      this.baseApi.encodeURL(Constants.API_GET_APPROVER_LIST)
    );
  }
  getAreasDDL(): Observable<any> {
    return this.http.get<DataMapper<any>>(
      this.baseApi.encodeURL(Constants.API_GET_AREAS_LIST)
    );
  }
  getTeamsDDl(): Observable<any> {
    return this.http.get<DataMapper<any>>(
      this.baseApi.encodeURL(Constants.API_GET_TEAMS_LIST)
    );
  }

  getPenaltyManagementList(data: any): Observable<DataMapper<Penalty>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'status=' + data?.status;
    }
    if (data?.closedBy) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'closedBy=' + data?.closedBy;
    }
    return this.http.get<DataMapper<Penalty>>(
      this.baseApi.encodeURL(
        Constants.API_GET_PENALTY_MANAGEMENT_LIST + '?' + params
      )
    );
  }
  createPenaltyManagement(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CREATE_PENALTY_MANAGEMENT),
      data
    );
  }
  editPenaltyManagement(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_EDIT_PENALTY_MANAGEMENT),
      data
    );
  }
  deleteattachment(id) {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_PENALTY_MANAGEMENT_DELETE_ATTACHMENT + '/' + id
      )
    );
  }

  getPenaltyManagementById(id: any): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_PENALTY_MANAGEMENT_BY_ID + '/' + id
      )
    );
  }

  closePenaltyManagement(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CLOSE_PENALTY_MANAGEMENT),
      data
    );
  }
}
