import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChatbotModalComponent } from '../../chatbot-modal/chatbot-modal.component';
import { ChatbotService } from 'src/backend/services/chatbot/chatbot.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';

@Component({
  selector: 'hodhod-config-panel-toggle',
  templateUrl: './config-panel-toggle.component.html',
  styleUrls: ['./config-panel-toggle.component.scss'],
})
export class ConfigPanelToggleComponent implements OnInit {
  @ViewChild('chatbotModal') chatbotModal: TemplateRef<any>;
  @Output() openConfig = new EventEmitter();

  isDialogOpen: boolean = false;
  companyId:any
  constructor(
    private dialog: MatDialog,
    private chatbotService: ChatbotService,
    private feedbackService: AsyncFeedbackService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.companyId = localStorage.getItem('company_id');
  }

  openChatbotModal() {
    if (!this.isDialogOpen) {
      this.loadingService.startLoading(true);
      this.chatbotService.initializeChatSession().subscribe({
        next: (res) => {
          this.dialog
            .open(this.chatbotModal, {
              disableClose: true,
              panelClass: 'rounded-mat-dialog',
              width: '25%',
              height: 'auto',
              hasBackdrop: false,
              position: { right: '100px', bottom: '100px' },
              data: {
                sessionId: res.session_id,
              },
            })
            .afterClosed()
            .subscribe((res) => {
              this.isDialogOpen = false;
            });
          this.isDialogOpen = true;
          this.loadingService.stopLoading();
        },
        error: (error) => {
          this.feedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
    }
  }
}
