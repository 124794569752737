import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Organization } from '../../models/organization';
import { MatDialog } from '@angular/material/dialog';
import { ChatbotService } from 'src/backend/services/chatbot/chatbot.service';
import { OrganizationStatus } from 'src/@hodhod/common/enum';
import { OrganizationService } from 'src/backend/services/organization.service';
import { log } from 'console';

@UntilDestroy()
@Component({
  selector: 'app-trusted-sources-list',
  templateUrl: './trusted-sources-list.component.html',
  styleUrls: ['./trusted-sources-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class TrustedSourcesListComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('AddURLPopup') AddURLPopup: TemplateRef<any>;
  @ViewChild('viewURLPopup') viewURLPopup: TemplateRef<any>;

  columns: TableColumn<Organization>[] = [
    {
      label: 'Title',
      property: 'title',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'URL',
      property: 'url',
      type: 'text',
      visible: true,
    },
    {
      label: 'CreatedAt',
      property: 'created_at',
      type: 'date',
      visible: true,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];
  URLToView: string = '';
  public pageSize = Constants.PAGE_SIZE;
  public pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  public dataSource = new MatTableDataSource<Organization>([]);
  selection = new SelectionModel<Organization>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';
  urlsFormArray: FormArray;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  sourcesForm: FormGroup;

  OrganizationStatuses = OrganizationStatus;

  public selectedOrganizationStatus: string[] = [];

  private translationsList: any = {};
  constructor(
    private translate: TranslateService,
    private router: Router,
    private fb: FormBuilder,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private dialog: MatDialog,
    private chatbotService: ChatbotService,
    private orgService: OrganizationService
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'Role',
        'User',
        'StaticPages',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.sourcesForm = this.fb.group({
      urls: this.fb.array([]),
    });
    this.urlsFormArray = this.sourcesForm.get('urls') as FormArray;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.getData())
      )
      .subscribe();

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
    this.onAddNewURL();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  createUrlForm(): FormGroup {
    return this.fb.group({
      title: new FormControl('', Validators.required),
      url: new FormControl('', Validators.required),
    });
  }

  getData() {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.chatbotService.getLinks().subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource(response['Trusted Links']);
        this.paginator.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.paginator.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  Reindex() {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.chatbotService.Reindex().subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.detail)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.detail)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  removeLevel(i: number) {
    if (i >= 0) {
      this.urlsFormArray.removeAt(i);
    }
  }
  createNewUrl() {
    this.dialog.open(this.AddURLPopup, {
      height: 'auto',
      width: 'auto',
      maxHeight: '90vh',
      disableClose: false,
    });
  }
  navigateBack() {
    this.dialog.closeAll();
  }
  viewURL(urlObj) {
    this.URLToView = urlObj;
    this.dialog.open(this.viewURLPopup, {
      height: 'auto',
      width: 'auto',
      maxHeight: '90vh',
      disableClose: false,
    });
  }
  deleteURL(url) {
    let data = {
      url: url,
    };
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['Role']['UpdateConfirm']['Title'],
          this.translationsList['Role']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.chatbotService.deleteLink(data).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.detail)
              );
              this.getData();
              this.sectionState = SectionStateStatus.Ready;
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, 'Faild to Remove')
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  deleteAll() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['Role']['UpdateConfirm']['Title'],
          this.translationsList['Role']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.chatbotService.deleteAllLinks().subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.detail)
              );
              this.getData();
              this.sectionState = SectionStateStatus.Ready;
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.detail)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  addURL() {
    let data = {
      links: this.urlsFormArray.value,
    };
    this.dialog.closeAll();
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.chatbotService.addLinks(data).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.detail)
        );
        this.sectionState = SectionStateStatus.Ready;
        this.getData();
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
        this.dialog.closeAll();
      },
    });
  }
  onAddNewURL() {
    this.urlsFormArray.push(this.createUrlForm());
  }
  get repeatUrlsFormGroup(): any {
    return this.sourcesForm.get('urls') as FormArray;
  }
}
