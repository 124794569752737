import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SafetyKpisService } from 'src/backend/services/dashboards/safety-kpis.service';

@Component({
  selector: 'app-manual-levels-form',
  templateUrl: './manual-levels-form.component.html',
  styleUrls: ['./manual-levels-form.component.scss'],
})
export class ManualLevelsFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() safetyTriangleData!: any;
  levelsFormArray: FormArray;
  levelsForm: FormGroup;
  initialized = false;
  numOfLevels = 0;
  defaultLevels: any[] = null;
  arr: any[] = [];
  data;
  public translationsList: any = {};
  private destroy$ = new Subject();

  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private safetyKpisService: SafetyKpisService,
    private router: Router
  ) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });

    this.levelsForm = this.fb.group({
      levels: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.levelsFormArray = this.levelsForm.get('levels') as FormArray;
    this.getFormValues();
    this.initialized = true;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      if (
        changes['safetyTriangleData'] &&
        changes['safetyTriangleData'].currentValue
      ) {
        this.defaultLevels = this.safetyTriangleData['levels'];
        const levelsFormArray = this.levelsForm.get('levels') as FormArray;
        while (levelsFormArray.length !== 0) {
          levelsFormArray.removeAt(0);
        }
        this.defaultLevels
          .map((levelData) => ({
            name: levelData?.name,
            manualValue: levelData?.manualValue,
            number: levelData?.number,
          }))
          .sort((a, b) => a.number - b.number)
          .forEach((levelData) => {
            const levelFormGroup = this.createLevelForm(levelData);
            levelsFormArray.push(levelFormGroup);
          });
        this.getFormValues();
      }

      if (this.defaultLevels === null) {
        for (let i = 0; i < 3; i++) {
          this.onAddLevel();
          this.numOfLevels = this.levelsFormArray.length;
        }
      }
    }
  }

  onAddLevel(): void {
    if (this.numOfLevels < 7) {
      this.levelsFormArray.push(this.createLevelForm());
      this.numOfLevels = this.levelsFormArray.length;
      this.getFormValues();
    }
  }

  getFormValues() {
    this.arr = [];
    let rows = this.levelsFormArray.value;
    rows.forEach((row, i) => {
      this.arr.push({
        color: i + 1 == rows.length ? '#FF0000' : null,
        name: row['name'] ? row['name'] : '',
        y: 1,
        dataLabels: row['manualValue']
          ? { format: `${row['manualValue']}` }
          : { format: `` },
      });
    });
    this.data = this.arr;
  }

  createLevelForm(levelData?): FormGroup {
    return this.fb.group({
      name: [
        levelData ? levelData.name : '',
        [Validators.required, Validators.maxLength(200)],
      ],
      manualValue: [
        levelData ? levelData.manualValue : null,
        [Validators.required, Validators.max(300)],
      ],
      number: [levelData ? levelData.number : null],
    });
  }

  removeLevel(i: number) {
    if (i >= 0) {
      this.levelsFormArray.removeAt(i);
      this.numOfLevels = this.levelsFormArray.value.length;
      this.getFormValues();
    }
  }
  updateSafetyTriangle(id) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const levels = this.levelsForm.value['levels'].map((item, index) => {
            return {
              manualValue: item.manualValue,
              name: item.name,
              number: index + 1,
            };
          });
          let data = {
            Id: id,
            Mode: 'MANUAL',
            Levels: levels,
          };
          this.loadingService.startLoading(true, '');
          this.safetyKpisService.updateSafetyTriabgle(data).subscribe({
            next: (res) => {
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.navigateBack()
            },
            error: (error) => {
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              
            },
          });
        }
      });
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  navigateBack() {
    this.router.navigate([
      '/' +
        SharedConstants.DASHBOARD_PATH +
        '/' +
        SharedConstants.DASHBOARD_TARGET,
    ]);
  }
}
