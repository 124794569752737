import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Permission } from 'src/app/shared/models/permission';
import { BaseApi } from '../api/base-api';
import { UserPermission } from '../models/session-user/user-permission';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private baseApi: BaseApi, private router: Router) {}

  isUserHasPermission(checkingPermission: Permission): boolean {
    const userPermissions: UserPermission[] = this.baseApi.getUserPermissionsSession();
    const key = checkingPermission.PermissionKey;
    const type = checkingPermission.PermissionType;
    let foundPermission = userPermissions.find(x => x.label === key);
    if (foundPermission) {
      if (Object.getOwnPropertyNames(foundPermission).find(x => x === type)) {
        return foundPermission[type] ? true : false;
      }
      return false;
    }    
    return false;
  }
}
