import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { VideoAnalyticsService } from 'src/backend/services/video-analytics/video-analytics.service';
import { Camera } from '../../models/video-analytics';

@Component({
  selector: 'app-video-analytics-view-page',
  templateUrl: './video-analytics-view-page.component.html',
  styleUrls: ['./video-analytics-view-page.component.scss']
})
export class VideoAnalyticsViewPageComponent implements OnInit {
  public mode: 'view' | 'edit' = 'view';
  public cameraId: number = 0;
  private destroy$ = new Subject();
  cameraData: Camera;
  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private videoAnalyticsService: VideoAnalyticsService,
    private feedBackService: AsyncFeedbackService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;   
        this.cameraId = parseInt(id, 10);
      }
    });
    this.route.queryParamMap.subscribe((queryParam) => {
      const formMode = queryParam.get('editMode') as any;
      this.mode = formMode === 'true' ? 'edit' : 'view';
      this.getCameraData();
    });
  }

  getCameraData(): void{
    this.loadingService.startLoading(true,  '');
    this.videoAnalyticsService.getCmeraById(this.cameraId).subscribe({
      next: (response) => {
        this.cameraData = response;
        this.loadingService.stopLoading();
      },
      error: ({error}) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      }
    })
  }

}
