<app-card [title]="'RenewIdCard.View.idinformation' | translate">
  <app-state-section body [flexibleHeight]="true" [state]="sectionState"
    [loadingLabel]="'RenewIdCard.LoadingInfo' | translate" [errorLabel]="'Errors.ErrorHappened' | translate">
    <div class="flex justify-between flex-wrap-reverse">
      <div class="flex-1">
        <div class="py-3 flex items-center">
          <div @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{renewdata?.idCardCode}}</p>
            <p class="m-0 caption text-hint">{{'RenewIdCard.View.RequestId' | translate}}</p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{renewdata?.name}}</p>
            <p class="m-0 caption text-hint">{{'RenewIdCard.View.EmployeeName' | translate}}</p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{renewdata?.department}}</p>
            <p class="m-0 caption text-hint">{{'RenewIdCard.View.Department' | translate}}</p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{renewdata?.createdOn | date: 'dd-MM-yyyy hh:mm:ss'}}</p>
            <p class="m-0 caption text-hint">{{'RenewIdCard.View.CreatedDate' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="flex-1">

        <div class="py-3 flex items-center">
          <div @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
            <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{renewdata?.jobTitle}}</p>
            <p class="m-0 caption text-hint">{{'RenewIdCard.View.JobTitle' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <div class="flex">
          <ngx-avatars bgColor="#2c3e5000" fgColor="#222222" class="logo box cursor-pointer" size="180"
            [src]="renewdata?.profilePath ? renewdata?.profilePath : ''" [name]="'Profile'" [borderColor]="'#4af700'"
            [matTooltip]="'RenewIdCard.View.ViewImage' | translate" [textSizeRatio]="2" [initialsSize]="2"
            (clickOnAvatar)="fullImageView()" [round]="true">
          </ngx-avatars>
        </div>
      </div>
    </div>
  </app-state-section>
</app-card>

<div class="mt-5">
  <app-expandable-panel [expanded]="true" #expandablePanel [title]="'IdCardDetails.History' | translate">
    <table body @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{'RenewIdCard.View.Table.'+
            column.label |
            translate }}</th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] }}
            <span *ngIf="column.property === 'createdBy' && row?.isDefault"
              class="bg-primary text-white rounded-full p-1 font-normal px-3">Default</span>
          </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'boolean' && column.property === 'action'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{'RenewIdCard.View.Table.'+
            column.label |
            translate }}</th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <span [attr.id]="row[column.property]">{{'Enum.IdCardActions.' + row[column.property] | translate}}</span>
          </td>
        </ng-container>
        <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
          <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>{{'RenewIdCard.View.Table.'+
            column.label |
            translate }}</th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] | date:
            'dd-MM-yyyy - hh:mm a'}}
          </td>
        </ng-container>
      </ng-container>
  
      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: visibleColumns;" @fadeInUp class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row></tr>
    </table>
    <div *ngIf="dataSource?.filteredData?.length === 0" @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center">
      <img src="assets/img/resources/empty-state.svg" alt="not found">
      <h2 class="headline m-0 text-center text-primary">{{'General.EmptyTableList' | translate}}...</h2>
    </div>
  
    <mat-paginator [class.hidden]="dataSource.filteredData.length === 0" [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize" showFirstLastButtons class="sticky left-0"></mat-paginator>
  </app-expandable-panel>
</div>



<ng-template #renewImageBigView>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">{{'RenewIdCard.View.ViewImage' | translate}}
    </h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

  <mat-dialog-content class="flex flex-col flex-wrap justify-between">
    <img src="{{renewdata?.profilePath}}" class="object-fill w-full h-auto" />
  </mat-dialog-content>
</ng-template>