<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">Add New IoT Dashboard</h2>
  <button
    class="text-secondary"
    (click)="closeDialog()"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>
<div class="p-3">
  <div>
    <mat-radio-group
      (change)="statusChange($event)"
      class="flex m-5 justify-between button-wrap"
    >
      <mat-radio-button
        id="no-button"
        disableRipple="true"
        value="ANALOG"
        checked="checked"
        class="py-2 text-lg text-gray-400 flex-shrink border m-5 border-gray-400 rounded-lg w-3/12 button-label"
      >
        {{ "Machine.ANALOG" | translate }}</mat-radio-button
      >
      <mat-radio-button
        id="yes-button"
        disableRipple="true"
        value="DIGITAL"
        checked="checked"
        class="py-2 text-lg text-gray-400 flex-shrink border m-5 border-gray-400 rounded-lg w-3/12 button-label"
      >
        {{ "Machine.DIGITAL" | translate }}</mat-radio-button
      >
    </mat-radio-group>

    <form *ngIf="isAnalog === 'ANALOG'" [formGroup]="analogForm">
      <div>
        <mat-label class="field-label">{{
          "Machine.AddNewMachine" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf2="ngxMatSelect"
            [displayMember]="'userDefinedCode'"
            [valueMember]="'machineID'"
            [source]="analogMachines"
            [useInfiniteScroll]="true"
            [multiple]="false"
            [title]="'machines'"
            [hasSearchBox]="false"
            [manageRoute]="'/company-setup/machine-equipment'"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="analogMachine"
            (selectionChange)="getAnalogParameters($event.value)"
          >
            <mat-option
              *ngFor="let m of msf2.filteredSource"
              [value]="m?.machineID"
              >{{ m?.userDefinedCode }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
      <!-- parameters -->
      <div *ngIf="analogForm.controls['analogMachine'].value">
        <div *ngIf="analogParameters.length > 0">
          <mat-label class="field-label">{{
            "General.Buttons.AddParameters" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              ngxMatSelect
              #msf1="ngxMatSelect"
              [displayMember]="'machineName'"
              [valueMember]="'id'"
              [source]="analogParameters"
              [useInfiniteScroll]="true"
              [multiple]="false"
              [title]="'machines'"
              [hasSearchBox]="false"
              [manageRoute]="'/company-setup/machine-equipment'"
              [placeholder]="'Placeholder.Select' | translate"
              formControlName="parameters"
            >
              <mat-option
                *ngFor="let m of msf1.filteredSource"
                [value]="m?.parameterID"
                >{{ m?.parameterName }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
        <p *ngIf="analogParameters.length === 0" class="text-red-700">{{'General.NoParametersFound'|translate}}</p>
      </div>
    </form>
    <form *ngIf="isAnalog === 'DIGITAL'" [formGroup]="digitalForm">
      <div>
        <mat-label class="field-label">{{
          "Machine.AddNewMachine" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf3="ngxMatSelect"
            [displayMember]="'userDefinedCode'"
            [valueMember]="'machineID'"
            [source]="digitalMachines"
            [useInfiniteScroll]="true"
            [multiple]="false"
            [title]="'machines'"
            [hasSearchBox]="false"
            [manageRoute]="'/company-setup/machine-equipment'"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="digitalMachine"
          >
            <mat-option
              *ngFor="let m of msf3.filteredSource"
              [value]="m?.machineID"
              >{{ m?.userDefinedCode }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
<div class="flex justify-end items-center mt-4">
  <app-basic-button
    [btnType]="'Cancel'"
    [btnText]="'Cancel'"
    (onClick)="closeDialog()"
  ></app-basic-button>
  <app-basic-button
    *ngIf="isAnalog === 'ANALOG'"
    [isDisabled]="!analogForm.valid"
    [btnType]="'Add'"
    [btnText]="'Add'"
    (onClick)="createAnalaogDashboard()"
  ></app-basic-button>
  <app-basic-button
    *ngIf="isAnalog === 'DIGITAL'"
    [isDisabled]="!digitalForm.valid"
    [btnType]="'Add'"
    [btnText]="'Add'"
    (onClick)="createDigitalDashboard()"
  ></app-basic-button>
</div>
