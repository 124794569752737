import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from 'src/backend/services/permission.service';
import { Permission } from '../models/permission';
import { SharedConstants } from '../models/shared-constants';

@Injectable({
  providedIn: 'root',
})
export class MultiPermissionGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionService: PermissionService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const permissions = next.data['permissions'] ?? [];
    let isUserHasPermission = false;
    for (let i = 0; i < permissions.length; i++) {
      let result = this.permissionService.isUserHasPermission(permissions[i]);
      if (result) {
        isUserHasPermission = true;
        break;
      }
    }
    if (isUserHasPermission === false) {
      this.router.navigate([
        '/' + SharedConstants.PAGE_ERROR + '/' + SharedConstants.ACCESS_DENIED,
      ]);
    }

    return isUserHasPermission;
  }
}
