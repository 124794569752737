import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { NearMissReportService } from 'src/backend/services/reportings/near-miss-report.service';
import { NearMissReport } from '../../models/near-miss-report';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-near-miss-report-view-page',
  templateUrl: './near-miss-report-view-page.component.html',
  styleUrls: ['./near-miss-report-view-page.component.scss'],
})
export class NearMissReportViewPageComponent implements OnInit {
  public nearMissId: number = 0;
  private destroy$ = new Subject();
  public nearMissReport: NearMissReport;

  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private nearMissReportService: NearMissReportService,
    private feedBackService: AsyncFeedbackService
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;
        this.nearMissId = parseInt(id, 10);
        this.getNearMissReport();
      }
    });
  }

  getNearMissReport(): void {
    this.loadingService.startLoading(true, '');
    this.nearMissReportService
      .getNearMissReportById(this.nearMissId)
      .subscribe({
        next: (response) => {
          this.nearMissReport = response;
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  onInvestigate(status: boolean): void {
    this.getNearMissReport();
  }

  onChangeView(status: boolean): void {
    this.getNearMissReport();
  }
}
