import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from '../api/base-api';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private http: HttpClient, private baseApi: BaseApi) { }

  uploadSingleFile(data: any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_SINGLE_MEDIA_UPLOAD), data);
  }

}
