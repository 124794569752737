import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/@hodhod/common/constants';
import { ReportingType, SearchTypes } from 'src/@hodhod/common/enum';
import { SearchService } from 'src/@hodhod/services/search.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { GlobalSearchService } from 'src/backend/services/global-search.service';

@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})
export class SearchViewComponent implements OnInit {

  searchResults: any[] = [];
  pageSize: number = Constants.PAGE_SIZE;
  public pageNumber: number = 1;
  public total: number = 0;
  constructor(private globalSearchService: GlobalSearchService, private router: Router, 
    private searchService: SearchService) { }

  ngOnInit() {
    this.globalSearchService.searchResults$.subscribe(res => this.searchResults = res);
    this.globalSearchService.pageIndex$.subscribe(num => this.pageNumber = num);
    this.globalSearchService.pageSize$.subscribe(num => this.pageSize = num);
    this.globalSearchService.totalCount$.subscribe(num => this.total = num);
  }

  getData(pageNumber: number): void {
    this.globalSearchService.pageIndex$.next(pageNumber);
    this.globalSearchService.getSearchResults();
  }

  viewPage(event: any): void {
    switch (event?.searchType) {
      case SearchTypes.WORKPERMIT:
        this.router.navigate([SharedConstants.WORK_PERMIT + '/' + SharedConstants.WORK_PERMIT_LIST + '/' + SharedConstants.VIEW_EDIT_WORK_PERMIT + '/' + event?.id], {
          queryParams: {editMode: false}
        });
        break;
      case SearchTypes.TASK:
        this.router.navigate(['/' + SharedConstants.TASK_TRACKER + '/' + SharedConstants.TASK_TRACKER_List + '/' + SharedConstants.View_TASK_TRACKER + '/' + event?.id]);
        break;
      case SearchTypes.HAZARD_REPORT:
        this.router.navigate([SharedConstants.REPORTING_MANAGEMENT + '/' + SharedConstants.REPORT_HAZARD + '/' + SharedConstants.REPORT_HAZARD_VIEW + '/' + event?.id], {
          queryParams: {editMode: false}
        });
        break;
      case SearchTypes.NEARMISS_REPORT:
        this.router.navigate([SharedConstants.REPORTING_MANAGEMENT + '/' + SharedConstants.REPORT_NEAR_MISS + '/' + SharedConstants.REPORT_NEAR_MISS_VIEW + '/' + event?.id], {
          queryParams: { editMode: false }
        });
        break;
      case SearchTypes.INCIDENT_REPORT:
        this.router.navigate([SharedConstants.REPORTING_MANAGEMENT + '/' + SharedConstants.REPORT_INCIDENT + '/' + SharedConstants.REPORT_INCIDENT_VIEW + '/' + event?.id], {
          queryParams: { editMode: false }
        });
        break;
      case SearchTypes.ACTION_TRACKER:
        const splitType: string[] = event?.reportType.split('-')
        if (splitType[1] === ReportingType.Incident) {
          this.router.navigate([SharedConstants.REPORTING_MANAGEMENT + '/' + SharedConstants.REPORT_INCIDENT + '/' + SharedConstants.REPORT_INCIDENT_VIEW + '/' + event?.id], {
            queryParams: { editMode: false }
          });
        }
        if (splitType[1] === ReportingType.NearMiss) {
          this.router.navigate([SharedConstants.REPORTING_MANAGEMENT + '/' + SharedConstants.REPORT_NEAR_MISS + '/' + SharedConstants.REPORT_NEAR_MISS_VIEW + '/' + event?.id], {
            queryParams: { editMode: false }
          });
        }
        if (splitType.length === 1) {
          if (splitType[0] === ReportingType.Incident) {
            this.router.navigate([SharedConstants.REPORTING_MANAGEMENT + '/' + SharedConstants.REPORT_INCIDENT + '/' + SharedConstants.REPORT_INCIDENT_VIEW + '/' + event?.id], {
              queryParams: { editMode: false }
            });
          }
          if (splitType[0] === ReportingType.NearMiss) {
            this.router.navigate([SharedConstants.REPORTING_MANAGEMENT + '/' + SharedConstants.REPORT_NEAR_MISS + '/' + SharedConstants.REPORT_NEAR_MISS_VIEW + '/' + event?.id], {
              queryParams: { editMode: false }
            });
          }
          if (splitType[0] === ReportingType.Hazard) {
            this.router.navigate([SharedConstants.REPORTING_MANAGEMENT + '/' + SharedConstants.REPORT_HAZARD + '/' + SharedConstants.REPORT_HAZARD_VIEW + '/' + event?.id], {
              queryParams: {editMode: false}
            });
          }
          if (splitType[0] === ReportingType.Task) {
            this.router.navigate(['/' + SharedConstants.TASK_TRACKER + '/' + SharedConstants.TASK_TRACKER_List + '/' + SharedConstants.View_TASK_TRACKER + '/' + event?.id]);
          }
        }
        break;
    }
    this.searchService.submitSubject.next('');
    this.globalSearchService.searchString$.next('');
  }

}
