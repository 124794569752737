export class PostChecklist {
    Title: string;
    Type: string;
}

export interface GetAllChecklists {
    id: number;
    checkListName: string;
    noOfSections: number;
    noOfQuestions: number;
    createdBy: Date;
    createdOn: Date;
    updatedOn: Date;
    status: boolean;
    checkListType: string;
}