import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/@hodhod/common/constants';
import { JWTTokenValidation } from 'src/@hodhod/common/enum';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { JwtDecoderService } from '../helpers/jwt-decoder.service';
import { NotificationItem } from '../models/notifications/notification-item';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';
import { UserDeviceService } from './user-device.service';

@Injectable()
export class MessagingService implements OnDestroy {
  public currentMessage$ = new BehaviorSubject(null);
  public currentMessageFromFirebase$ = new BehaviorSubject(null);
  public notificationToken$ = new BehaviorSubject(SharedConstants.INIT_VALUE);

  private destroy$ = new Subject();
  private hubConnectionBuilder!: HubConnection;
  public signalRKey: string = '';

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private notificationService: NotificationService, 
    private userDeviceService: UserDeviceService, 
    private jwtDecoderService: JwtDecoderService) { }

  ngOnDestroy() {
    this.destroy$.complete();
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.pipe(takeUntil(this.destroy$)).subscribe({
      next: (token) => {
        this.notificationToken$.next(token);
        const timezoneOffset = (-1 * new Date().getTimezoneOffset());
        let userDevice = this.userDeviceService.getCurrentUserDevice();
        if (!userDevice) {
          userDevice = this.userDeviceService.generateNewDevice();
        }
        const data = {
          NotificationToken: token,
          OsVersion: userDevice.osVersion,
          DeviceModel: userDevice.deviceModel,
          AppVersion: userDevice.appVersion,
          OsType: userDevice.osType,
          DeviceId: userDevice.deviceId,
          TimeZone: timezoneOffset,
          signalRKey: this.signalRKey
        }
        this.userDeviceService.registerUserDevice(data).subscribe({
          next: (response) => {
            this.userDeviceService.saveUserDevice(response);
            if (this.checkUserLoggin()) {
              this.editUserDevice();
            }
          },
          error: ({error}) => {
          }
        })
      },
      error: (err) => {
        console.error('Unable to get permission to notify.', err);
        this.notificationToken$.next(SharedConstants.NOT_SUPPORTED);
      }
   });
  }
  editUserDevice(): void {
    const timezoneOffset = (-1 * new Date().getTimezoneOffset());
    let userDevice = this.userDeviceService.getCurrentUserDevice();
    if (!userDevice) {
      userDevice = this.userDeviceService.generateNewDevice();
    }
    const data = {
      NotificationToken: userDevice.notificationToken,
      OsVersion: userDevice.osVersion,
      DeviceModel: userDevice.deviceModel,
      AppVersion: userDevice.appVersion,
      OsType: userDevice.osType,
      DeviceId: userDevice.deviceId,
      TimeZone: timezoneOffset,
      signalRKey: this.signalRKey
    };
    this.notificationToken$.subscribe({
      next: (token) => {
        if (!data.NotificationToken) {
          data.NotificationToken = token;
        }
        this.userDeviceService.editUserDevice(data).subscribe({
          next: (response) => {
            this.userDeviceService.saveUserDevice(response);
          },
          error: ({error}) => {
          }
        })
      }
    });    
  }

  receiveMessage() {
    this.angularFireMessaging.messages.pipe(takeUntil(this.destroy$)).subscribe({  
        next: (payload: any) => {          
          const firebaseMessage = {
              ...payload?.data,
              id: parseInt(payload?.data?.id, 10),
              isRead: payload?.data?.isRead === 'TRUE' ? true : false
            };
          this.currentMessageFromFirebase$.next(firebaseMessage);
        }
    });
  }


  InitializeSignalR(): void {
    this.hubConnectionBuilder = new HubConnectionBuilder().withUrl(Constants.API_BASE_URL + 'notifications').configureLogging(LogLevel.Information).build();
    this.hubConnectionBuilder.start().then(() => {
      this.signalRKey = this.hubConnectionBuilder.connectionId;
      this.requestPermission();
    }).catch(err => console.log('Error while connect with signalR server'));
    
  }

  recieveNotificationMessageFromSignalR(): void {
    this.hubConnectionBuilder.on('SendNotificationMessageToUsers', (result: any) => {
      const notification = new NotificationItem();
      notification.id = result['id'];
      notification.title = result['title'];
      notification.body = result['description'];
      notification.type = result['notificationType'];
      notification.elementId = result['elementId'];
      this.notificationService.showNotification(notification);
      this.currentMessage$.next(result);
   });
  }

  checkUserLoggin() {
    const isValidToken: JWTTokenValidation = this.jwtDecoderService.isThereValidToken();
    if (isValidToken === JWTTokenValidation.Expired || isValidToken === JWTTokenValidation.NotFound) {
      return false;
    }
    return true;
  }
}
