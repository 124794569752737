import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-role-page',
  templateUrl: './role-page.component.html',
  styleUrls: ['./role-page.component.scss']
})
export class RolePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
