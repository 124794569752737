import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-history-logs-page',
  templateUrl: './history-logs-page.component.html',
  styleUrls: ['./history-logs-page.component.scss']
})
export class HistoryLogsPageComponent implements OnInit {

  constructor(private router: Router, private route: ActivatedRoute) { }
  public loadDowntime: boolean = false;
  public loadLogs: boolean = false;

  ngOnInit(): void {
  }
  tabChanged(event) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
    });
    const selectedTabIndex = event.index;
    if (selectedTabIndex == 0) {
      this.loadDowntime = !this.loadLogs;
    } else if (selectedTabIndex == 1) {
      this.loadLogs = true;
    }
  }
}

