import { Component, OnInit } from '@angular/core';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { Router } from '@angular/router';


@Component({
  selector: 'app-manage-checklist-page',
  templateUrl: './manage-checklist-page.component.html',
  styleUrls: ['./manage-checklist-page.component.scss']
})
export class ManageChecklistPageComponent implements OnInit {

  constructor(private route: Router) { }

  ngOnInit(): void {
  }
  onChecklistSectionsClick(event: string): void {
    this.route.navigate(['/' + SharedConstants.SAFETY + '/' + SharedConstants.CHECKLIST_SECTION]);
  }

  onChecklistsClick(event: string): void {
    this.route.navigate(['/' + SharedConstants.REPORTING_MANAGEMENT + '/' + SharedConstants.NATURE_INJURY]);
  }

  onMachineChecklistsClick(event: string): void {
    this.route.navigate(['/' + SharedConstants.REPORTING_MANAGEMENT + '/' + SharedConstants.REASONS]);
  }

}
