import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loss-modes-page',
  templateUrl: './loss-modes-page.component.html',
  styleUrls: ['./loss-modes-page.component.scss']
})
export class LossModesPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
