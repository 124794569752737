<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.VideoAnalytics' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div body>
      <div *ngIf="mode === 'view'">
        <app-view-video-analytics [cameraInfo]="cameraData">
        </app-view-video-analytics>
      </div>
      <div *ngIf="mode === 'edit'">
        <app-edit-video-analytics [cameraInfo]="cameraData">

        </app-edit-video-analytics>
      </div>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>