<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.ManageIdCard' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div class="card p-7 rounded-xl">
      <h2 class="title">{{ "NavigationBar.ManageIdCard" | translate }}</h2>
      <div class="cardSection pb-5">
        <app-card-type
        *ngIf="hasnewCard | permissionChecker"
          [title]="'ManageIdCard.NewIDCard'|translate"
          [imgSrc]="'assets/img/resources/new-id.svg'"
          (onClick)="onNewIdClick($event)"
        ></app-card-type>
        <app-card-type
          *ngIf="hasRenewCard | permissionChecker"
          [title]="'ManageIdCard.RenewIDCard'|translate"
          [imgSrc]="'assets/img/resources/update-id.svg'"
          (onClick)="onUpdateIdClick($event)"
        ></app-card-type>
        <!-- <app-card-type [title]="'Lost Id Card'" [imgSrc]="'assets/img/resources/lost-id.svg'"
          (onClick)="onLostIdClick($event)"></app-card-type> -->
      </div>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
