<div
  class="flex-auto flex flex-col items-center justify-center"
  *ngIf="visibleDigitalParameters.length <= 0; else showData"
>
  <img
    style="width: 240px"
    src="assets/img/empty-dashboard.png"
    alt="not found"
  />
  <h6
    class="mt-2 text-center"
    style="color: #2b2c30; padding-top: 20px; opacity: 0.7; font-size: 0.9rem"
  >
    <br />
    {{ "Dashboard.EmptyDashboards.AddParameters" | translate }}
  </h6>
</div>

<ng-template #showData>
  <table
    mat-table
    [dataSource]="visibleDigitalParameters"
    class="mat-elevation-z0"
  >
    <!-- Name Column -->
    <ng-container matColumnDef="criticalParameter">
      <th mat-header-cell *matHeaderCellDef>Critical Parameter</th>
      <td #div class="truncate max-w-0 p-3" mat-cell *matCellDef="let element">
        <span
          [matTooltip]="
            div?.offsetWidth < div?.scrollWidth ? element.parameterName : null
          "
        >
          {{ element.parameterName }}
        </span>
      </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="liveStatus">
      <th mat-header-cell *matHeaderCellDef>Live Status</th>
      <td mat-cell *matCellDef="let element">
        <div
          [ngClass]="
            element.parameterValue >= 1
              ? 'idealValue-text'
              : element.parameterValue === null
              ? ''
              : 'nonIdealValue-text'
          "
        >
          {{ element.status === null ? "N/A" : element.status }}
        </div>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="alerts">
      <th mat-header-cell *matHeaderCellDef>Alerts</th>
      <td
        mat-cell
        *matCellDef="let element"
        [style.color]="
          element.parameterValue >= 1 && element.parameterValue !== null
            ? '#8BAC1A'
            : element.parameterValue < 1 && element.parameterValue !== null
            ? '#D73550'
            : element.parameterValue === null
            ? '#000'
            : '#000'
        "
      >
        {{ element.trend }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-template>
