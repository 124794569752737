import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voilation-page',
  templateUrl: './voilation-page.component.html',
  styleUrls: ['./voilation-page.component.scss']
})
export class VoilationPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
