import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportingRoutingModule } from './reporting-routing.module';
import { ReportingPageComponent } from './pages/reporting-page/reporting-page.component';
import { ReportingContentPageComponent } from './pages/reporting-content-page/reporting-content-page.component';
import { SharedModule } from '../shared/shared.module';
import { IncidentClassificationPageComponent } from './pages/incident-classification-page/incident-classification-page.component';
import { AddUpdateIncidentComponent } from './components/add-update-incident/add-update-incident.component';
import { AddUpdateIncidentClassificationComponent } from './components/add-update-incidentClassification/add-update-incidentClassification.component';
import { AddUpdateLossModeComponent } from './components/add-update-lossMode/add-update-lossMode.component';
import { AddUpdateNatureInjuryComponent } from './components/add-update-natureInjury/add-update-natureInjury.component';
import { AddUpdateReasonComponent } from './components/add-update-reason/add-update-reason.component';
import { AddUpdateRootCauseComponent } from './components/add-update-rootCause/add-update-rootCause.component';
import { IncidentCategoryComponent } from './components/incident-category/incident-category.component';
import { LossModesComponent } from './components/loss-modes/loss-modes.component';
import { NatureInjuryComponent } from './components/nature-injury/nature-injury.component';
import { ReasonsComponent } from './components/reasons/reasons.component';
import { IncidentReportListComponent } from './components/incident-report-list/incident-report-list.component';
import { NearMissReportListComponent } from './components/near-miss-report-list/near-miss-report-list.component';
import { RootCauseComponent } from './components/root-cause/root-cause.component';
import { IncidentCategoryPageComponent } from './pages/incident-category-page/incident-category-page.component';
import { LossModesPageComponent } from './pages/loss-modes-page/loss-modes-page.component';
import { NatureInjuryPageComponent } from './pages/Nature-Injury-page/Nature-Injury-page.component';
import { ReasonsPageComponent } from './pages/reasons-page/reasons-page.component';
import { IncidentReportComponent } from './pages/incident-report/incident-report.component';
import { NearMissReportComponent } from './pages/near-miss-report/near-miss-report.component';
import { RootCausePageComponent } from './pages/root-cause-page/root-cause-page.component';
import { Incident_classificationComponent } from './components/incident_classification/incident_classification.component';
import { HazardReportAddComponent } from './pages/hazard-report-add/hazard-report-add.component';
import { NearMissReportAddComponent } from './pages/near-miss-report-add/near-miss-report-add.component';
import { NearMissReportFormComponent } from './components/near-miss-report-form/near-miss-report-form.component';
import { IncidentReportAddComponent } from './pages/incident-report-add/incident-report-add.component';
import { HazardReportViewPageComponent } from './pages/hazard-report-view-page/hazard-report-view-page.component';
import { NearMissReportViewComponent } from './components/near-miss-report-view/near-miss-report-view.component';
import { NearMissReportViewPageComponent } from './pages/near-miss-report-view-page/near-miss-report-view-page.component';
import { HazardReportService } from 'src/backend/services/reportings/hazard-report.service';
import { LossModeService } from 'src/backend/services/loss-mode/loss-mode.service';
import { RootCauseService } from 'src/backend/services/root-cause/root-cause.service';
import { IncidentCategoryService } from 'src/backend/services/incident-category/incident-category.service';
import { ActionTrackerModule } from '../action-tracker/action-tracker.module';
import { BodySkeletonComponent } from './components/body-skeleton/body-skeleton.component';
import { IncidentReportViewComponent } from './components/incident-report-view/incident-report-view.component';
import { IncidentReportViewPageComponent } from './pages/incident-report-view-page/incident-report-view-page.component';
import { NearMissReportService } from 'src/backend/services/reportings/near-miss-report.service';
import { IncidentReportService } from 'src/backend/services/reportings/incident-report.service';
import { NgxGaugeModule } from 'ngx-gauge';
import { HazardReportFormComponent } from './components/hazard-report-form/hazard-report-form.component';
import { HazardReportEditComponent } from './pages/hazard-report-edit/hazard-report-edit.component';
import { HazardReportComponent } from './pages/hazard-report/hazard-report.component';
import { HazardReportListComponent } from './components/hazard-report-list/hazard-report-list.component';
import { HazardReportViewComponent } from './components/hazard-report-view/hazard-report-view.component';
import { NearMissReportEditComponent } from './pages/near-miss-report-edit/near-miss-report-edit.component';
import { IncidentReportFormComponent } from './components/incident-report-form/incident-report-form.component';
import { IncidentReportEditComponent } from './pages/incident-report-edit/incident-report-edit.component';

@NgModule({
  declarations: [
    ReportingPageComponent,
    ReportingContentPageComponent,
    IncidentClassificationPageComponent,
    Incident_classificationComponent,
    ReportingPageComponent,
    NearMissReportComponent,
    NearMissReportListComponent,
    IncidentReportComponent,
    IncidentReportListComponent,
    IncidentCategoryPageComponent,
    IncidentCategoryComponent,
    NatureInjuryPageComponent,
    NatureInjuryComponent,
    ReasonsPageComponent,
    ReasonsComponent,
    RootCausePageComponent,
    RootCauseComponent,
    LossModesComponent,
    LossModesPageComponent,
    AddUpdateIncidentComponent,
    AddUpdateIncidentClassificationComponent,
    AddUpdateNatureInjuryComponent,
    AddUpdateReasonComponent,
    AddUpdateRootCauseComponent,
    AddUpdateLossModeComponent,
    NearMissReportAddComponent,
    NearMissReportFormComponent,
    IncidentReportAddComponent,
    NearMissReportViewComponent,
    NearMissReportViewPageComponent,
    BodySkeletonComponent,
    IncidentReportViewComponent,
    IncidentReportViewPageComponent,
    HazardReportFormComponent,
    HazardReportListComponent,
    HazardReportViewComponent,
    HazardReportComponent,
    HazardReportViewPageComponent,
    HazardReportEditComponent,
    HazardReportAddComponent,
    NearMissReportEditComponent,
    IncidentReportFormComponent,
    IncidentReportEditComponent,
  ],
  imports: [
    CommonModule,
    ReportingRoutingModule,
    SharedModule,
    NgxGaugeModule,
    ActionTrackerModule,
  ],
  providers: [
    HazardReportService,
    LossModeService,
    RootCauseService,
    IncidentCategoryService,
    NearMissReportService,
    IncidentReportService,
  ],
})
export class ReportingModule {}
