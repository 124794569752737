import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { GetAllChecklists, PostChecklist } from 'src/app/reporting/models/checklist-section.model';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';


@Injectable({
  providedIn: 'root'
})
export class ChecklistSectionService {

  constructor(private http: HttpClient, private baseApi: BaseApi) { }

  PostCheckList(data: any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_CHECKLIST_CREATE), data);
  }

  PostCheckListSection(data: any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_CHECKLIST_SECTION_CREATE), data);
  }

  getAllCheckList(data: any): Observable<DataMapper<GetAllChecklists>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<GetAllChecklists>>(this.baseApi.encodeURL(Constants.API_CHECKLIST_SECTION_GET_ALL + '?' + params));
  }
  getMachines() {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_MACHINE));
  }
  getParameters(id: number) {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_PARAMETER + '/' + id));
  }

  getCheckListById(id: number) {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_CHECKLIST_SECTION_BY_ID + '/' + id));
  }

  deActivate(id: number): Observable<any> {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_CHECKLIST_SECTION_DEACTIVATE + '/' + id));
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_CHECKLIST_SECTION_ACTIVATE + '/' + id));
  }

  editCheckListSection(data: any): Observable<any> {
    return this.http.put<any>(this.baseApi.encodeURL(Constants.API_CHECKLIST_SECTION_EDIT), data);
  }
  updateChecklist(data: any){
    return this.http.put<any>(this.baseApi.encodeURL(Constants.API_CHECKLIST_UPDATE), data);
  }

  getAllCheckListForDropdown(type: string) {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_ALl_CHECKLIST_DROPDOWN + '/' + type));
  }

  deleteCheckList(id: number): Observable<any> {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_ALl_CHECKLIST_DELETE + '/' + id));
  }
  submitBulkCheckList(data: any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_ALl_CHECKLIST_BULK_UPLOAD), data);
  }

}
