<div *ngIf="data.length > 0; else noData">
  <table mat-table [dataSource]="data" class="mat-elevation-z0">
    <!-- Name Column -->
    <ng-container matColumnDef="criticalParameter">
      <th mat-header-cell *matHeaderCellDef>Critical Parameter</th>
      <td mat-cell *matCellDef="let element">{{ element.iotParameterName }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="liveStatus">
      <th mat-header-cell *matHeaderCellDef>Live Status</th>
      <td mat-cell *matCellDef="let element">
        <div
          [ngClass]="
            element.iotParameterValue >= 1
              ? 'idealValue-text'
              : 'nonIdealValue-text'
          "
        >
          {{ element.status }}
        </div>
      </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="alerts">
      <th mat-header-cell *matHeaderCellDef>Alerts</th>
      <td
        mat-cell
        *matCellDef="let element"
        [style.color]="
          element.iotParameterValue >= 1
            ? '#8BAC1A'
            : element.iotParameterValue < 1
            ? '#D73550'
            : '#000'
        "
      >
        {{ element.trend }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>

<ng-template #noData>
  <div class="flex-auto flex flex-col items-center justify-center">
    <img
      style="width: 240px"
      src="assets/img/empty-dashboard.png"
      alt="not found"
    />
    <h6
      class="mt-2 text-center"
      style="color: #2b2c30; padding-top: 20px; opacity: 0.7; font-size: 0.9rem"
    >
      {{ "Dashboard.EmptyDashboards.IoT" | translate }}
    </h6>
  </div>
</ng-template>
