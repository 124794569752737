<div class="h-full">
  <div class="flex justify-end m-0" mat-dialog-title>
    <button
      class="bg-primary w-7 h-7 mb-1 rounded-[8px] text-white mx-2"
      (click)="toggleFullScreen()"
      mat-icon-button
      type="button"
    >
      <mat-icon
        [svgIcon]="!isFullScreen ? 'mat:fullscreen' : 'mat:fullscreen_exit'"
      ></mat-icon>
    </button>
    <button
      class="bg-primary w-7 h-7 mb-1 rounded-[8px] text-white"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <div
    mat-dialog-content
    class="p-0 pr-[20px] pl-[20px] h-full overflow-hidden"
  >
    <div
      class="flex flex-col justify-between"
      [ngClass]="
        isFullScreen
          ? 'min-h-[24rem] max-h-[24rem]'
          : 'min-h-[24rem] max-h-[24rem]'
      "
    >
      <div
        class="flex flex-col h-full w-full bg-white rounded-lg p-2 overflow-auto"
        #scrollMe
      >
        <div class="flex w-full mt-2 space-x-3 max-w-xs">
          <div
            class="flex-shrink-0 h-10 w-10 rounded-full bg-primary chat-icon"
          >
            <img src="assets/img/resources/chatbot-icon.svg" />
          </div>
          <div class="flex">
            <div class="bg-primary text-white p-3 rounded-r-lg rounded-bl-lg">
              <p class="text-sm">
                {{ "Chatbot.Hello" | translate }} {{ loggedUser.fullName }},
                {{ "Chatbot.WelcomeMessage" | translate }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="flex flex-col flex-grow"
          *ngFor="let message of messages; index as i"
        >
          <!-- user message -->
          <div class="flex w-full mt-2 space-x-3 max-w-xs ml-auto justify-end">
            <div class="flex">
              <div
                class="bg-[#4D4D4D] text-white p-3 rounded-l-lg rounded-br-lg"
              >
                <p class="text-sm">
                  {{ message.question }}
                </p>
              </div>
            </div>
            <div class="flex-shrink-0 h-10 w-10 rounded-full">
              <ngx-avatars
                size="40"
                [src]="loggedUser.picture"
                [name]="loggedUser.fullName"
                [textSizeRatio]="2"
                [round]="true"
                [initialsSize]="2"
                [style]="customStyle"
              >
              </ngx-avatars>
            </div>
          </div>

          <!-- chat message -->
          <div class="flex w-full mt-2 space-x-3">
            <div
              class="flex-shrink-0 h-10 w-10 rounded-full bg-primary chat-icon"
            >
              <img src="assets/img/resources/chatbot-icon.svg" />
            </div>
            <div class="flex items-center">
              <div class="bg-primary text-white p-3 rounded-r-lg rounded-bl-lg">
                <pre
                  class="text-sm whitespace-pre-line"
                  style="font-family: 'Inter var'"
                  dir="auto"
                  *ngIf="message?.message; else loader"
                >
                  {{ message.message }}
              </pre
                >
                <ng-template #loader>
                  <div class="loader"></div>
                </ng-template>
              </div>
              <img
                *ngIf="message.message"
                class="mx-1 cursor-pointer h-fit"
                [matTooltip]="'Copy'"
                src="assets/img/resources/copy-icon.svg"
                (click)="copyMessage(message.message)"
              />

              <img
                *ngIf="
                  message.message &&
                  (message?.isItLiked || message.isItLiked == null)
                "
                [ngClass]="
                  message.isItLiked == null ? 'cursor-pointer gray-icon' : ''
                "
                class="mx-1 h-fit"
                [matTooltip]="'like'"
                src="assets/img/resources/like-message.svg"
                (click)="
                  message.isItLiked == null ? likeMessage(message, i) : ''
                "
              />

              <img
                *ngIf="
                  message.message &&
                  (!message?.isItLiked || message.isItLiked == null)
                "
                [ngClass]="
                  message.isItLiked == null ? 'cursor-pointer gray-icon' : ''
                "
                class="mx-1 h-fit"
                [matTooltip]="'dislike'"
                src="assets/img/resources/dislike-message.svg"
                (click)="
                  message.isItLiked == null ? dislikeMessage(message, i) : ''
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- message input -->
    <div class="flex items-center pt-2">
      <input
        class="h-10 pl-2 pr-2 w-full rounded-[20px] border border-[#000] text-sm"
        type="text"
        dir="auto"
        placeholder="Type your message…"
        (keyup.enter)="sendMessage(messageInput.value)"
        #messageInput
      />
      <mat-icon
        class="mx-2 w-[25px] h-[25px] cursor-pointer"
        svgIcon="mat:send"
        color="primary"
        (click)="sendMessage(messageInput.value)"
      ></mat-icon>
    </div>
  </div>
</div>

<ng-template #rateMessage>
  <div class="flex justify-end m-0" mat-dialog-title>
    <button
      class="bg-primary w-8 h-8 rounded-[10px] text-white"
      (click)="closeFeedBackModal()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div mat-dialog-content class="p-0 pr-2 pl-2">
    <div class="flex flex-col justify-center items-center">
      <p>
        {{ "Chatbot.HowExperience" | translate }}
      </p>
      <div class="w-full p-2 text-center">
        <textarea
          class="rounded-[10px] border border-[#000] mt-2 p-2 w-[90%]"
          rows="5"
          [placeholder]="'Chatbot.ShareFeedback' | translate"
          [(ngModel)]="userFeedBackMessage"
        ></textarea>
      </div>
      <div class="flex justify-end w-full">
        <button
          mat-button
          class="bg-[#7DDF81] text-[#fff]"
          (click)="closeFeedBackModal()"
        >
          {{ "General.Buttons.Submit" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>
