<div class="w-full p-gutter text-center">
    <svg width="100%" viewBox="0 0 460 460" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="w-full max-w-lg mx-auto mb-6">
        <g id="web_ui" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="7.2-No-permission" transform="translate(-490.000000, -92.000000)" fill-rule="nonzero">
                <g id="Group-2" transform="translate(490.000000, 92.000000)">
                    <circle id="Oval" fill="#D52A46" transform="translate(230.041957, 230.041957) rotate(-45.000000) translate(-230.041957, -230.041957) " cx="230.041957" cy="230.041957" r="162.041957"></circle>
                    <path d="M230.494763,343.843067 L266.23072,388.177844 C266.23072,388.177844 368.597626,369.46121 390.391871,256.751944 L312.408101,156.154995 L230.494763,343.843067 Z" id="Path" fill="#C21F1C"></path>
                    <g id="Group" transform="translate(137.664489, 138.977167)">
                        <g>
                            <path d="M146.813957,8.15633094 L93.6029784,8.15633094 L93.5963741,8.15633094 L40.3986043,8.15633094 C40.3986043,8.15633094 32.275295,22.4414676 21.1338129,23.7359137 C-18.3864173,160.517914 93.5435396,194.305597 93.6095827,194.32541 L93.6161871,194.32541 C93.6888345,194.305597 205.618791,160.517914 166.098561,23.7359137 C154.924058,22.4348633 146.813957,8.15633094 146.813957,8.15633094 Z" id="Path" fill="#097F7C"></path>
                            <path d="M141.187079,16.774964 L92.7972518,16.774964 L92.7906475,16.774964 L44.4074245,16.774964 C44.4074245,16.774964 37.0171942,29.6797986 26.8861727,30.8487626 C-9.05451799,154.435338 92.7444173,184.960489 92.7972518,184.980302 L92.8038561,184.980302 C92.8698993,184.960489 194.668835,154.435338 158.728144,30.8487626 C148.564101,29.6797986 141.187079,16.774964 141.187079,16.774964 Z" id="Path" fill="#F7B541"></path>
                            <path d="M175.707842,20.446964 L174.743612,17.1778273 L170.932921,16.7419424 C163.701194,15.9230072 156.608158,6.43920863 154.706115,3.17007194 L153.101266,0.449093525 L32.4800288,0.449093525 L30.8949928,3.17667626 C28.9929496,6.43920863 21.9593525,15.9230072 14.6615827,16.7419424 L10.8442878,17.1778273 L9.88005755,20.446964 C-33.6489928,167.577928 89.9177698,204.113007 91.1857986,204.443223 L92.8302734,204.865899 L94.4813525,204.430014 C95.7295683,204.08659 218.913281,166.461799 175.707842,20.446964 Z M92.7972518,194.32541 C77.904518,189.259899 -15.6522302,151.945511 20.0639137,25.8625036 C29.4222302,23.075482 36.1916547,14.8663165 39.2758705,10.4546331 L146.318633,10.4546331 C149.402849,14.8663165 156.165669,23.0820863 165.53059,25.8625036 C201.246734,151.952115 107.676777,189.259899 92.7972518,194.32541 Z" id="Shape" fill="#FFFFFF"></path>
                        </g>
                        <path d="M119.92118,74.628777 L116.20295,74.628777 C116.20295,58.1179856 106.303079,48.6077698 92.6585612,48.6077698 C79.0140432,48.6077698 69.120777,58.1179856 69.120777,74.628777 L65.4025468,74.628777 C59.9275683,74.628777 58.1113813,76.6629065 58.1113813,82.1378849 L58.1113813,116.83036 C58.1113813,122.305338 59.9275683,127.463309 65.4025468,127.463309 L119.92118,127.463309 C125.396158,127.463309 128.784173,122.305338 128.784173,116.83036 L128.784173,82.1378849 C128.784173,76.6629065 125.396158,74.628777 119.92118,74.628777 Z M98.8600144,116.896403 L86.4703165,116.896403 L88.8478705,99.5864892 C86.4108777,98.2193957 84.7664029,95.2936835 84.7664029,92.3019281 C84.7664029,87.8968489 88.3327338,84.1654101 92.7444173,84.1654101 C97.1494964,84.1654101 100.722432,87.6590935 100.722432,92.0641727 C100.722432,95.1153669 99.0119137,98.371295 96.4956691,99.7119712 L98.8600144,116.896403 Z M76.5572374,74.628777 C76.5572374,59.4388489 84.6475252,56.0442302 92.6651655,56.0442302 C100.682806,56.0442302 108.773094,59.4388489 108.773094,74.628777 L76.5572374,74.628777 Z" id="Shape" fill="#FFFFFF"></path>
                    </g>
                    <path d="M321.547165,435.79695 C321.547165,440.657727 280.461712,444.593899 229.773583,444.593899 C179.092058,444.593899 138,440.657727 138,435.79695 C138,430.942777 179.085453,427 229.773583,427 C280.455108,427 321.547165,430.936173 321.547165,435.79695 Z" id="Path" fill="#D7E3F1" opacity="0.7"></path>
                </g>
            </g>
        </g>
    </svg>
  
  
    <h1 class="display-3 font-medium m-0 text-primary">{{SharedConstants.ACCESS_DENIED_ERROR}}</h1>
    <h2 class="headline m-0 font-bold display-2">{{'StaticPages.AccessDenied.Title' | translate}}</h2>
    <h2 class="headline m-0 font-light">{{'StaticPages.AccessDenied.Message' | translate}}</h2>
  
    <button color="primary" type="button" mat-flat-button
     class="mt-4" (click)="goBack()">
            Go Home
    </button>
  </div>