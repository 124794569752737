import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-msds-create-step',
  templateUrl: './msds-create-step.component.html',
  styleUrls: ['./msds-create-step.component.scss'],
})
export class MsdsCreateStepComponent implements OnInit {
  @Input() title: string = '';
  @Input() expanded: any = false;
  @Input() checked: boolean = false;
  @Input() optional: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  openNextPanel() {
    this.expanded = false;
  }
}
