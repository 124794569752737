import { EventEmitter, Injectable, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
// import { ar_EG, en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { Confirmation, ConfirmationType } from 'src/app/shared/models/confirmation';
import { Language } from 'src/app/shared/models/language';
import { Constants } from '../common/constants';
import { LanguageEnum } from '../common/enum';
import { ConfigService } from '../config/config.service';
import { LanguageServiceDelegateInterface } from '../interfaces/language-service-delegate.interface';
// import { ConfirmationService } from '../../ui/shared/helper/confirmation.service';
// import { Confirmation, ConfirmationType } from '../../ui/shared/model/confirmation';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  @Output() languageChanged = new EventEmitter<Language>();
  private translationsList: any = {};

  // public fields
  currentLanguage$: BehaviorSubject<Language> = new BehaviorSubject(new Language());
  supportedLanguage$: BehaviorSubject<Language[]> = new BehaviorSubject([]);

  public set delegate(v: LanguageServiceDelegateInterface) {
    this._delegate = v;
  }

  // private fields
  private supportedLanguagesList = [
    { name: 'English', isoCode: LanguageEnum.English } as Language,
    { name: 'العربية', isoCode: LanguageEnum.Arabic } as Language,
  ];

  private _delegate: LanguageServiceDelegateInterface;

  getSupportedLanguagesList() {
    return this.supportedLanguagesList;
  }

  constructor(
    private translate: TranslateService,
    private cookieService: CookieService,
    private configService: ConfigService,
    // private i18n: NzI18nService,
    private confirmationService: ConfirmationService
  ) {
    this.setupAppLanguageList();
    this.initialState();
    this.supportedLanguage$.subscribe((languages) => {
      if (!languages.length) { return; }
      this.initialState();
    });
  }

  changeLanguage(languageIsoCode: string) {
    if (this.currentLanguage$.value.isoCode === languageIsoCode) {
      return;
    }
    const language = this.supportedLanguagesList.find((lang) => languageIsoCode === lang.isoCode);
    if (language) {
      if (this._delegate) {
        this._delegate.willConfirmChangeLanguage().pipe(first()).subscribe((confirmTheChange) => {
          if (confirmTheChange) {
            this.confirmationService.confirm(
              new Confirmation(
                ConfirmationType.DestructiveAction,
                this.translationsList['ChangeLanguageConfirm']['Title'],
                this.translationsList['ChangeLanguageConfirm']['Message']
              )
            ).then(value => {
              if (value) {
                this.saveCurrentLanguage(language);
                this.languageChanged.emit(language);
              }
            });
          } else {
            this.saveCurrentLanguage(language);
            this.languageChanged.emit(language);
          }
        });
      } else {
        this.saveCurrentLanguage(language);
        this.languageChanged.emit(language);
      }
    }
  }

  checkLanguageDirection(languageIsoCode: string): string {
    if (languageIsoCode === 'ar') {
      return 'rtl';
    } else {
      return 'ltr';
    }
  }

  setOrganizationSupportedLanguage(supportedLanguages: Language[]) {
    this.supportedLanguagesList = [];
    this.supportedLanguagesList = supportedLanguages;
    this.supportedLanguage$.next(this.supportedLanguagesList);
  }

  private getLanguageConfirmationText() {
    this.translate.get(['ChangeLanguageConfirm']).subscribe((translations: any) => {
      this.translationsList['ChangeLanguageConfirm'] = translations['ChangeLanguageConfirm'];
    });
  }

  private saveCurrentLanguage(language: Language) {
    this.currentLanguage$.next(language);
    this.cookieService.set(
      Constants.APP_LANGUAGE_COOKIE_KEY,
      this.currentLanguage$.value.isoCode,
      new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      '/'
    );
    this.translate.use(this.currentLanguage$.value.isoCode);
    this.setNZLocalization(this.currentLanguage$.value.isoCode);
    this.setWebSiteDirection();

  }

  private setupAppLanguageList() {
    const languagesArray: string[] = [];
    this.supportedLanguagesList.forEach((lang) => {
      languagesArray.push(lang.isoCode);
    });
    this.translate.addLangs(languagesArray);
  }

  private setWebSiteDirection() {
    const currentDirection: any = this.checkLanguageDirection(this.currentLanguage$.value.isoCode);
    document.body.dir = currentDirection;
    document.body.parentElement.dir = currentDirection;
    document.body.firstElementChild.setAttribute('dir', currentDirection);
    this.configService.updateConfig({
      direction: currentDirection
    });
  }

  private initialState() {
    const appLangIsoCode = this.cookieService.get(Constants.APP_LANGUAGE_COOKIE_KEY);
    let currentLanguage = new Language();
    if (appLangIsoCode) {
      currentLanguage = this.supportedLanguagesList.find((lang) => lang.isoCode === appLangIsoCode);
    } else {
      currentLanguage = this.supportedLanguagesList[0];
      this.saveCurrentLanguage(currentLanguage);
    }
    this.currentLanguage$.next(currentLanguage);
    this.translate.use(this.currentLanguage$.value.isoCode);
    this.setNZLocalization(this.currentLanguage$.value.isoCode);
    this.translate.setDefaultLang(this.currentLanguage$.value.isoCode);
    this.setWebSiteDirection();
    this.getLanguageConfirmationText();
  }

  private setNZLocalization(language: LanguageEnum) {
    switch (language) {
      case LanguageEnum.English:
        // this.i18n.setLocale(en_US);
        break;
      case LanguageEnum.Arabic:
        // this.i18n.setLocale(ar_EG);
        break;

      default:
        break;
    }
  }
}
