<!-- Card -->
<script src="https://code.highcharts.com/highcharts.js"></script>
<script src="https://code.highcharts.com/modules/funnel.js"></script>
<script src="https://code.highcharts.com/modules/exporting.js"></script>
<script src="https://code.highcharts.com/modules/export-data.js"></script>
<script src="https://code.highcharts.com/modules/accessibility.js"></script>
<div class="w-full ">
  <!-- Date Filter -->
  <div class="p-1 pt-0.5 ms-auto w-4/12">
    <div
      class="flex items-center filter-date"
      app-click-stop-propagation
      (click)="select.open()"
    >
      <span>Date: </span>
      <span class="text-primary">
        {{ "Enum.DashboardFilters." + filterName | translate }}
      </span>
      <mat-icon
        style="width: 20px; height: 20px; cursor: pointer"
        svgIcon="mat:keyboard_arrow_down"
        color="primary"
      ></mat-icon>
    </div>
    <mat-select
      #select
      class="select-options"
      [value]="filterName"
      (selectionChange)="onFilterChange($event.value)"
    >
      <mat-option
        *ngFor="let item of filterDashboards | enumToArray"
        [value]="item.value"
        (click)="item.key === 'CustomRange' ? onSelectCustomRange() : ''"
      >
        {{ "Enum.DashboardFilters." + item.value | translate }}
      </mat-option>
    </mat-select>
  </div>
  
  <div  style="height: 400px;" >
    <div id="container" [chart]="chart" class="chart-container" ></div>
  </div>
</div>
