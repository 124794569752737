
export enum FeedbackType {
  Success,
  Warning,
  Failure,
}

export class FeedbackModel {

  constructor(
    public type: FeedbackType = FeedbackType.Success,
    public message: string = '',
    public duration: number = 5000) { }
}
