import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { IotAnalogDashboardService } from 'src/backend/services/dashboards/iot-analog-dashboard.service';
import { IotDigitalDashboardService } from 'src/backend/services/dashboards/iot-digital-dashboard.service';

@Component({
  selector: 'app-add-iot-dashboard',
  templateUrl: './add-iot-dashboard.component.html',
  styleUrls: ['./add-iot-dashboard.component.scss']
})
export class AddIotDashboardComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private iotAnalogDashboardService: IotAnalogDashboardService,
    private iotDigitalDashboardService: IotDigitalDashboardService,
    private asyncFeedbackService: AsyncFeedbackService,

  ) { }

  isAnalog: String = 'DIGITAL';
  analogForm: FormGroup;
  digitalForm: FormGroup;
  public analogMachines: any = [];
  public analogParameters: any = [];
  public digitalMachines: any = [];
  private translationsList: any = {};


  ngOnInit(): void {
    this.getDigitalMachines();
    this.getAnalogMachines();
    this.analogForm = this.fb.group({
      analogMachine: ['', Validators.required],
      parameters: ['', Validators.required],
    });
    this.digitalForm = this.fb.group({
      digitalMachine: ['', Validators.required],
    });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }
  statusChange(event) {
    if (event.value === 'ANALOG') {
      this.isAnalog = 'ANALOG';
    } else {
      this.isAnalog = 'DIGITAL';
    }
  }
  getAnalogMachines() {
    this.loadingService.startLoading(true, '');
    this.iotAnalogDashboardService.getAnalogMachines().subscribe({
      next: (response) => {
        this.analogMachines = response;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  getAnalogParameters(machineID: number) {
    this.iotAnalogDashboardService.getAnalogParameters(machineID).subscribe({
      next: (response) => {
        this.analogParameters = response;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  getDigitalMachines() {
    this.loadingService.startLoading(true, '');
    this.iotDigitalDashboardService.getDigitalMachines().subscribe({
      next: (response) => {
        this.digitalMachines = response;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  createDigitalDashboard() {
    const data = {
      machineID: this.digitalForm.get('digitalMachine').value,
    };
    this.loadingService.startLoading(true, '');
    this.iotDigitalDashboardService.createDigitalDashboard(data).subscribe({
      next: (response) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, 'Created Successfully')
        );
        this.loadingService.stopLoading();

      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  createAnalaogDashboard() {
    const data = {
      machineID: this.analogForm.get('analogMachine').value,
      machineParameterID:this.analogForm.get('parameters').value
    };
    this.loadingService.startLoading(true, '');
    this.iotAnalogDashboardService.createAnalaogDashboard(data).subscribe({
      next: (response) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, 'Created Successfully')
        );
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
}
