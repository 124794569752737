import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() numOfSkeleton: number = 1;
  @Input() appearance: any = '';
  @Input() config: any;

  constructor() { }

  ngOnInit() {
  }

  getConfiguration(): any {
    if (this.config.hasOwnProperty('background-color')) {
      return {...this.config};
    }else {
      return {...this.config, 'background-color': '#D3D3D3'};
    }
  }

}
