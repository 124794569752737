<mat-expansion-panel
  #expansionPanel
  class="mat-collabse"
  [expanded]="expanded.isOpen"
  (opened)="expanded.isOpen = true"
  (closed)="expanded.isOpen = false"
>
  <mat-expansion-panel-header class="mat-header">
    <mat-panel-title>
      <div class="flex align-center">
        <mat-checkbox
          class="step-checkbox"
          disabled
          [checked]="checked"
        ></mat-checkbox>
        <span class="step-title">{{ title }}</span>
        <span class="step-optional" *ngIf="optional"
          >&nbsp; - {{ "MSDS.Optional" | translate }}</span
        >
      </div>
    </mat-panel-title>
    <button mat-button class="expand-btn text-primary">
      <mat-icon
        [svgIcon]="
          !expanded.isOpen ? 'mat:arrow_drop_down' : 'mat:arrow_drop_up'
        "
      ></mat-icon>
    </button>
  </mat-expansion-panel-header>
  <div class="content" *ngIf="expanded.isOpen">
    <ng-content select="[body]"></ng-content>
    <div class="actions-mobile">
      <ng-content select="[actions-mobile]"></ng-content>
    </div>
  </div>
</mat-expansion-panel>
