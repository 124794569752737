<div class="flex justify-end mr-1 mb-4">
  <app-basic-button
    [btnType]="'Cancel'"
    [btnText]="'Back'"
    (onClick)="goBack()"
  >
  </app-basic-button>
  <app-basic-button
    *ngIf="
      (hasEditPermissions | permissionChecker) &&
      data?.status !== ReportNearMissStatus.CLOSED
    "
    [btnType]="'Add'"
    [btnText]="'Edit'"
    (onClick)="editReportNearMiss()"
  >
  </app-basic-button>
  <app-basic-button
    [btnType]="'Delete'"
    *ngIf="
      data?.status !== 'CLOSED' &&
      (!data?.isInvestigated || data?.isInvestigationCompleted) &&
      (hasSpecialPermissions | permissionChecker)
    "
    [btnText]="'Close'"
    [isDisabled]="false"
    (onClick)="openCloseModal()"
  ></app-basic-button>
  <app-basic-button
    *ngIf="
      !data?.isInvestigationCompleted &&
      data?.isInvestigated === true &&
      data?.isTeamSelected &&
      data?.isTeamApproved &&
      (hasSpecialPermissions | permissionChecker)
    "
    [btnType]="'Approve text-sm'"
    [btnText]="'CompleteInvestigation'"
    [isDisabled]="false"
    (onClick)="completeInvestigation()"
  ></app-basic-button>
  <app-basic-button
    *ngIf="
      data?.investigation?.teamLeadId === loggedUser?.userId &&
      data?.isTeamSelected &&
      !data?.isInvestigationCompleted &&
      !data?.isTeamApproved &&
      (hasSpecialPermissions | permissionChecker)
    "
    [btnType]="'Approve text-sm'"
    [btnText]="'Acknowledge'"
    [isDisabled]="false"
    (onClick)="acknowledgeModalOpen()"
  ></app-basic-button>
  <app-basic-button
    [btnType]="'Print text-sm'"
    [btnText]="'Print'"
    [isDisabled]="false"
    (onClick)="printNearMissReport()"
  ></app-basic-button>
</div>
<div class="flex gap-2">
  <!-- wrapper_1 -->
  <div class="wrapper_1">
    <!-- Detail -->
    <app-card [title]="data?.title">
      <div body class="flex justify-between flex-wrap-reverse" *ngIf="data">
        <div class="flex-1">
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.description }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.Description" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">
                {{
                  data?.departmentParentName
                    ? data?.departmentParentName
                    : data?.department
                }}
              </p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.Department" | translate }}
              </p>
            </div>
          </div>
          <div
            class="py-3 flex items-center"
            *ngIf="data?.departmentParentName"
          >
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.department }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.SubDepartment" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">
                {{ convertUtcToLocal(data?.updatedOn) }}
              </p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.UpdateAt" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.shift }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.Shift" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.reportedBy }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.ReportedBy" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center" *ngIf="data?.rcaFile">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">
                <a
                  mat-button
                  color="primary"
                  href="{{ data?.rcaFile }}"
                  download
                  >Download File</a
                >
              </p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.RCAFile" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div @fadeInUp class="flex items-center">
            <p class="text-left font-bold text-primary mr-4 rtl:ml-3">
              {{ "General.Status" | translate }}
            </p>
            <div
              class="w-28 text-center"
              [attr.id]="data?.status.toUpperCase()"
            >
              <span>{{
                "Enum.ReportNearMissStatus." + data?.status.toUpperCase()
                  | translate
              }}</span>
            </div>
          </div>
          <div class="py-3 flex items-center" *ngIf="data?.lossMode">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.lossMode }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.LossMode" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center" *ngIf="data?.['rootCause']">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.['rootCause'] }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.rootCause" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.cause }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.6MClassification" | translate }}
              </p>
            </div>
          </div>

          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ reportTypes }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportNearMissList.TypeOfNearMiss" | translate }}
              </p>
            </div>
          </div>

          <div class="py-3 flex items-center" *ngIf="data?.rcaDescription">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.rcaDescription }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.RCADescription" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div body class="flex flex-wrap gap-2 mt-3" *ngIf="data">
        <ngx-avatars
          *ngFor="let img of data?.images"
          [src]="getAttachmentImage(img.imageUrl)"
          [name]="getAttachementTypeName(img.imageUrl)"
          [textSizeRatio]="2"
          [round]="false"
          [initialsSize]="2"
          [cornerRadius]="10"
          [matTooltip]="img['fileName']"
          [borderColor]="'#131C4E'"
          (clickOnAvatar)="openBigView(img.id)"
        >
        </ngx-avatars>
      </div>
    </app-card>
    <!-- Actions -->
    <div class="mt-4">
      <app-action
        *ngIf="data"
        [sourceId]="data?.id"
        [sourceType]="ReportingType.NearMiss + ',' + 'INVESTIGATION-NEAR_MISS'"
        (onCreateAction)="onCreateAction($event)"
      ></app-action>
    </div>
    <!-- Witnesses -->
    <div class="mt-5">
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'ViewReportNearMiss.Witness' | translate"
      >
        <table
          body
          @stagger
          [dataSource]="data?.witnesses"
          class="w-full"
          mat-table
          matSort
        >
          <ng-container
            *ngFor="let column of witnessescolumns; trackBy: trackByProperty"
          >
            <ng-container
              *ngIf="column.type === 'text'"
              [matColumnDef]="column.property"
            >
              <th
                *matHeaderCellDef
                class="uppercase"
                mat-header-cell
                mat-sort-header
              >
                {{ "ViewReportNearMiss.Witnesses." + column.label | translate }}
              </th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                {{ row[column.property] }}
              </td>
            </ng-container>
          </ng-container>

          <tr *matHeaderRowDef="visibleWitnessesColumns" mat-header-row></tr>
          <tr
            *matRowDef="let row; columns: visibleWitnessesColumns"
            @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer"
            mat-row
          ></tr>
        </table>
        <div
          *ngIf="data?.witnesses?.length === 0"
          @scaleFadeIn
          class="flex-auto flex flex-col items-center justify-center"
        >
          <img src="assets/img/resources/empty-state.svg" alt="not found" />
          <h2 class="headline m-0 text-center text-primary">
            {{ "General.EmptyTableList" | translate }}...
          </h2>
        </div>

        <mat-paginator
          [class.hidden]="data?.witnesses.length === 0"
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageSize"
          showFirstLastButtons
          class="sticky left-0"
        ></mat-paginator>
      </app-expandable-panel>
    </div>
    <!-- History -->
    <div class="mt-5">
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'AddReportNearMiss.NearMissHistory' | translate"
      >
        <table
          body
          @stagger
          [dataSource]="dataSource"
          class="w-full"
          mat-table
          matSort
        >
          <ng-container
            *ngFor="let column of columns; trackBy: trackByProperty"
          >
            <ng-container
              *ngIf="column.type === 'text'"
              [matColumnDef]="column.property"
            >
              <th
                *matHeaderCellDef
                class="uppercase"
                mat-header-cell
                mat-sort-header
              >
                {{ "RenewIdCard.View.Table." + column.label | translate }}
              </th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                {{ row[column.property] }}
                <span
                  *ngIf="column.property === 'createdBy' && row?.isDefault"
                  class="bg-primary text-white rounded-full p-1 font-normal px-3"
                  >Default</span
                >
              </td>
            </ng-container>
            <ng-container
              *ngIf="column.type === 'boolean' && column.property === 'status'"
              [matColumnDef]="column.property"
            >
              <th
                *matHeaderCellDef
                class="uppercase"
                mat-header-cell
                mat-sort-header
              >
                {{ "RenewIdCard.View.Table." + column.label | translate }}
              </th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                <span [attr.id]="row[column.property].toUpperCase()">{{
                  "Enum.ReportNearMissStatus." +
                    row[column.property].toUpperCase() | translate
                }}</span>
              </td>
            </ng-container>
            <ng-container
              *ngIf="column.type === 'date'"
              [matColumnDef]="column.property"
            >
              <th
                *matHeaderCellDef
                class="uppercase"
                mat-header-cell
                mat-sort-header
              >
                {{ "RenewIdCard.View.Table." + column.label | translate }}
              </th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                {{ row[column.property] | date : "dd-MM-yyyy - hh:mm a" }}
              </td>
            </ng-container>
          </ng-container>

          <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
          <tr
            *matRowDef="let row; columns: visibleColumns"
            @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer"
            mat-row
          ></tr>
        </table>
        <div
          *ngIf="dataSource?.filteredData?.length === 0"
          @scaleFadeIn
          class="flex-auto flex flex-col items-center justify-center"
        >
          <img src="assets/img/resources/empty-state.svg" alt="not found" />
          <h2 class="headline m-0 text-center text-primary">
            {{ "General.EmptyTableList" | translate }}...
          </h2>
        </div>

        <mat-paginator
          [class.hidden]="dataSource.filteredData.length === 0"
          [pageSizeOptions]="pageSizeOptions"
          [pageSize]="pageSize"
          showFirstLastButtons
          class="sticky left-0"
        ></mat-paginator>
      </app-expandable-panel>
    </div>
  </div>
  <!-- Warpper_2 -->
  <div class="wrapper_2">
    <form [formGroup]="investigationForm" class="form-container">
      <div class="card" *ngIf="data?.isInvestigated === true">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
          "
        >
          <div class="flex">
            <mat-icon
              class="text-green mr-1"
              svgIcon="mat:check_box"
            ></mat-icon>
            <h4 class="title">
              {{ "AddReportIncident.RequiredInvestigation" | translate }}
            </h4>
          </div>
          <button
            *ngIf="
              (data?.status === 'NEW' || data?.status === 'INPROGRESS') &&
              !data?.isTeamSelected
            "
            (click)="
              this.data?.isInvestigated
                ? updateInvestigationData()
                : submitInvestigationData()
            "
            color="primary"
            mat-icon-button
            [matTooltip]="
              this.data?.isInvestigated
                ? ('General.Buttons.Update' | translate)
                : ('General.Buttons.Save' | translate)
            "
            type="button"
            [disabled]="!userDetail"
          >
            <mat-icon class="w-8 h-8" svgIcon="mat:save"></mat-icon>
          </button>
        </div>
      </div>
      <div class="card" *ngIf="investigationForm.get('investCheckbox')?.value">
        <h4 class="text-lg font-bold">
          {{ "AddReportIncident.TeamLeaderInvestigation" | translate }}
        </h4>
        <hr class="mt-3" />
        <div
          class="flex items-center justify-center"
          *ngIf="isInvestorsLoading"
        >
          <mat-spinner color="warn" diameter="40"></mat-spinner>
        </div>
        <div
          class="py-3 flex items-center"
          *ngIf="userDetail && !isInvestorsLoading"
        >
          <ngx-avatars
            class="logo box cursor-pointer mr-5"
            size="50"
            [src]="userDetail?.userImage ? userDetail?.userImage : ''"
            [name]="userDetail?.fullName"
            [borderColor]="'#131C4E'"
            [textSizeRatio]="2"
            [initialsSize]="2"
            [round]="true"
          >
          </ngx-avatars>
          <div @fadeInRight>
            <p class="font-bold text-lg text-center text-primary mb-1 rtl:ml-3">
              {{ userDetail?.fullName }}
            </p>
            <p class="font-semibold text-center">
              ({{ userDetail?.roles[0] }})
            </p>
          </div>
        </div>
        <mat-card-footer
          *ngIf="
            (data?.status === 'NEW' || data?.status === 'INPROGRESS') &&
            !data?.isTeamSelected
          "
          class="flex justify-between items-center"
        >
          <button
            (click)="openTeamLeadModal()"
            color="primary"
            mat-icon-button
            [matTooltip]="'General.Buttons.AddNew' | translate"
            type="button"
          >
            <mat-icon svgIcon="mat:add_circle"></mat-icon>
          </button>
          <app-basic-button
            *ngIf="userDetail && !data?.isTeamSelected && data.investigation"
            [btnType]="'Approve text-sm CustomWidth'"
            [btnText]="'TeamSelected'"
            [isDisabled]="membersArray.length <= 0"
            matTooltip="Make sure team is selected before report closing"
            (onClick)="updateInvestigationData(true)"
          ></app-basic-button>
        </mat-card-footer>
        <p
          *ngIf="userDetail && !data?.isTeamSelected && data.investigation"
          class="m-0 caption text-hint"
        >
          Make sure team is selected before report closing
        </p>
      </div>
      <div class="card" *ngIf="investigationForm.get('investCheckbox')?.value">
        <h4 class="text-lg font-bold">
          {{ "AddReportIncident.InitialTeamMembers" | translate }}
        </h4>
        <hr class="mt-3" />
        <div
          class="flex items-center justify-center"
          *ngIf="isInvestorsLoading"
        >
          <mat-spinner color="warn" diameter="40"></mat-spinner>
        </div>
        <div *ngIf="membersArray.length > 0 && !isInvestorsLoading">
          <div *ngFor="let m of membersArray">
            <div class="py-3 flex items-center">
              <ngx-avatars
                class="logo box cursor-pointer mr-5"
                size="50"
                [src]="m?.userImage ? m?.userImage : ''"
                [name]="m?.fullName"
                [borderColor]="'#131C4E'"
                [textSizeRatio]="2"
                [initialsSize]="2"
                [round]="true"
              >
              </ngx-avatars>
              <div @fadeInRight>
                <p
                  class="font-bold text-lg text-center text-primary mb-1 rtl:ml-3"
                >
                  {{ m?.fullName }}
                </p>
                <p class="font-semibold text-center">({{ m?.role }})</p>
              </div>
            </div>
            <div @fadeInRight *ngIf="m?.task">
              <p class="m-0 caption text-hint">
                {{ "AddReportIncident.Task" | translate }}
              </p>
              <p class="m-0 body-1">{{ m?.task }}</p>
            </div>
            <div @fadeInRight *ngIf="m?.task">
              <p class="m-0 caption text-hint">
                {{ "AddReportIncident.TargetDate" | translate }}
              </p>
              <p class="m-0 body-1">
                {{ m?.targetDate | date : "dd-MM-yyyy" }}
              </p>
            </div>
            <div
              class="flex justify-end"
              *ngIf="data?.status === 'NEW' || data?.status === 'INPROGRESS'"
            >
              <button
                (click)="removeTeamMember(m?.userId)"
                *ngIf="!data?.isTeamSelected"
                color="primary"
                mat-icon-button
                [matTooltip]="'General.Buttons.Remove' | translate"
                type="button"
              >
                <mat-icon svgIcon="mat:delete"></mat-icon>
              </button>
            </div>
            <hr />
          </div>
        </div>
        <mat-card-footer
          *ngIf="
            (data?.status === 'NEW' || data?.status === 'INPROGRESS') &&
            !data?.isTeamSelected
          "
        >
          <button
            (click)="openTeamMemberModal()"
            color="primary"
            mat-icon-button
            [matTooltip]="'General.Buttons.AddNew' | translate"
            type="button"
          >
            <mat-icon svgIcon="mat:add_circle"></mat-icon>
          </button>
        </mat-card-footer>
      </div>
    </form>

    <!-- Closer Of Report -->
    <div class="card" *ngIf="data?.status === 'CLOSED' && closerDetail">
      <h4 class="title">{{ "Voilations.View.CloserOfReport" | translate }}</h4>
      <hr class="mt-3" />
      <div class="py-3 flex items-center">
        <ngx-avatars
          class="logo box cursor-pointer mr-5"
          size="100"
          [src]="closerDetail?.userImage ? closerDetail?.userImage : ''"
          [name]="closerDetail?.fullName"
          [borderColor]="'#131C4E'"
          [textSizeRatio]="2"
          [initialsSize]="2"
          [round]="true"
        >
        </ngx-avatars>
        <div @fadeInRight>
          <p class="font-bold text-lg text-center text-primary mb-5 rtl:ml-3">
            {{ closerDetail?.fullName }}
          </p>
          <app-boolean-view
            *ngIf="closerDetail?.departmentName"
            [value]="!closerDetail?.departmentName"
            [falseColor]="'#131C4E'"
            [width]="'165px'"
            [height]="'30px'"
            [matTooltip]="closerDetail?.departmentName"
            [text]="trimedDepartments"
          >
          </app-boolean-view>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Team Lead Modal -->
<ng-template #teamLeadModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "AddReportIncident.TeamLeader" | translate }}
    </h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <form [formGroup]="teamLeadForm">
    <div class="fields">
      <div class="field-full flex column">
        <mat-label class="field-label">User</mat-label>
        <mat-form-field appearance="outline">
          <mat-select
            ngxMatSelect
            #msf1="ngxMatSelect"
            [displayMember]="'fullName'"
            [valueMember]="'userId'"
            [source]="users"
            [useInfiniteScroll]="true"
            formControlName="teamLeadName"
            [multiple]="false"
            [hasPermission]="hasCreateUserPermissions | permissionChecker"
            [title]="'users'"
            [manageRoute]="'/user-management/users'"
            [placeholder]="'Placeholder.Select' | translate"
          >
            <mat-option
              [value]="item.userId"
              *ngFor="let item of msf1.filteredSource"
            >
              {{ item.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      (onClick)="getTeamLeadDetail()"
    ></app-basic-button>
  </div>
</ng-template>

<!-- Team Memeber Modal -->
<ng-template #teamMemberModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "AddReportIncident.InitialTeamMembers" | translate }}
    </h2>

    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="teamMemberForm">
      <div class="flex items-center gap-3">
        <div class="flex column flex-grow">
          <mat-label class="field-label">User</mat-label>
          <mat-form-field appearance="outline">
            <mat-select
              ngxMatSelect
              #msf1="ngxMatSelect"
              [displayMember]="'fullName'"
              [valueMember]="'userId'"
              [source]="users"
              [useInfiniteScroll]="true"
              formControlName="teamMember"
              [multiple]="false"
              [hasPermission]="hasCreateUserPermissions | permissionChecker"
              [title]="'users'"
              [manageRoute]="'/user-management/users'"
              [placeholder]="'Placeholder.Select' | translate"
            >
              <mat-option
                [value]="item.userId"
                *ngFor="let item of msf1.filteredSource"
              >
                {{ item.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="flex column flex-grow">
          <mat-label class="field-label">{{
            "AddReportIncident.TargetDate" | translate
          }}</mat-label>
          <mat-form-field appearance="outline">
            <input
              [placeholder]="'Placeholder.SelectDate' | translate"
              matInput
              [matDatepicker]="targetDate"
              (click)="targetDate.open()"
              [min]="currentDate"
              formControlName="targetDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="targetDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #targetDate></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div class="flex-row">
        <mat-label class="field-label">{{
          "AddReportIncident.Task" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <textarea
            matInput
            rows="5"
            [placeholder]="'Task'"
            formControlName="task"
          >
          </textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="!teamMemberForm.valid"
      (onClick)="getTeamMemberDetail()"
    ></app-basic-button>
  </div>
</ng-template>

<ng-template #imageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="selectedImg?.imageUrl" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">{{ data?.title }}</h2>
    <button
      class="text-secondary"
      (click)="downloadDocument(selectedImg?.imageUrl)"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:download"></mat-icon>
    </button>

    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex">
    <div class="">
      <img [src]="selectedImg?.imageUrl" [alt]="data?.title" />
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
  </div>
</ng-template>

<!-- Acknowledge Team and Members Modal -->
<ng-template #acknowledgeModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">Acknowledge Team and Members</h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <h4 class="title">{{ "AddReportIncident.TeamLeader" | translate }}</h4>
    <div>
      <div class="flex items-center gap-3 flex-wrap">
        <div class="card p-2" *ngIf="userDetail">
          <div class="py-3 flex items-center">
            <ngx-avatars
              bgColor="#2c3e5000"
              fgColor="#222222"
              class="logo box cursor-pointer mr-5"
              size="50"
              [src]="userDetail?.userImage ? userDetail?.userImage : ''"
              [name]="'Profile'"
              [borderColor]="'#131C4E'"
              [textSizeRatio]="2"
              [initialsSize]="2"
              [round]="true"
            >
            </ngx-avatars>
            <div @fadeInRight>
              <p
                class="font-bold text-sm text-center text-primary mb-1 rtl:ml-3"
              >
                {{ userDetail?.fullName }}
              </p>
              <p class="font-semibold text-xs text-center">
                ({{ userDetail?.roles[0] }})
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h4 class="title">{{ "AddReportIncident.TeamMember" | translate }}</h4>
    <div *ngIf="membersArray.length > 0">
      <div class="flex items-center gap-3 flex-wrap">
        <div class="card p-2" *ngFor="let m of membersArray">
          <div class="py-3 flex items-center">
            <ngx-avatars
              bgColor="#2c3e5000"
              fgColor="#222222"
              class="logo box cursor-pointer mr-5"
              size="50"
              [src]="m?.userImage ? m?.userImage : ''"
              [name]="'Profile'"
              [borderColor]="'#131C4E'"
              [textSizeRatio]="2"
              [initialsSize]="2"
              [round]="true"
            >
            </ngx-avatars>
            <div @fadeInRight>
              <p
                class="font-bold text-sm text-center text-primary mb-1 rtl:ml-3"
              >
                {{ m?.fullName }}
              </p>
              <p class="font-semibold text-xs text-center">({{ m?.role }})</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Approve'"
      [btnText]="'Acknowledge'"
      (onClick)="acknowledge()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Delete'"
      [btnText]="'Reject'"
      (onClick)="rejectTeam()"
    ></app-basic-button>
  </div>
</ng-template>

<!-- reject team modal -->
<ng-template #rejectTeamModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">Reject Team</h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="commentForm">
      <div class="flex-row">
        <mat-label class="field-label">RCA Description</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <textarea
            matInput
            rows="5"
            formControlName="comment"
            [placeholder]="'comment'"
            matInput
          >
          </textarea>
        </mat-form-field>
      </div>
    </form>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="!commentForm.valid"
      (onClick)="teamDeSelected()"
    ></app-basic-button>
  </div>
</ng-template>

<!-- close report model -->

<ng-template #closeModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "AddReportNearMiss.CloseNearMissReport" | translate }}
    </h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <form [formGroup]="closeForm">
      <mat-checkbox
        color="primary"
        class="text-primary font-bold"
        formControlName="investCheckbox"
        [disabled]="data?.status === 'CLOSED' || data?.isInvestigated === true"
      >
        {{ "AddReportIncident.RequiredInvestigation" | translate }}?
      </mat-checkbox>
      <div
        *ngIf="
          !this.closeForm.controls['investCheckbox'].value ||
          (this.closeForm.controls['investCheckbox'].value &&
            data?.status !== 'NEW')
        "
      >
        <div class="flex-row">
          <mat-label class="field-label">{{
            "ReportHazardList.Table.Description" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <textarea
              matInput
              rows="5"
              formControlName="comment"
              [placeholder]="'AddReportIncident.Comment' | translate"
              matInput
            >
            </textarea>
          </mat-form-field>
        </div>
        <div body class="flex column">
          <div class="field flex column" [style.width.%]="100">
            <mat-label class="field-label">{{
              "AddReportIncident.RootCause" | translate
            }}</mat-label>
            <mat-form-field appearance="outline">
              <mat-select
                ngxMatSelect
                #msf7="ngxMatSelect"
                [displayMember]="'rootCauseName'"
                [valueMember]="'id'"
                [source]="rootCauses"
                [useInfiniteScroll]="true"
                formControlName="rootCause"
                [hasPermission]="
                  hasRootCauseUpdatePermission | permissionChecker
                "
                [title]="'root cause'"
                [multiple]="false"
                [manageRoute]="'/reporting-management/root-cause'"
                [placeholder]="'AddReportIncident.RootCause' | translate"
              >
                <mat-option
                  [value]="item.id"
                  *ngFor="let item of msf7.filteredSource"
                >
                  {{ item.rootCauseName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex column">
          <file-upload
            formControlName="rcaFile"
            [multiple]="false"
            [animation]="true"
          ></file-upload>
        </div>
      </div>
    </form>
    <div
      *ngIf="
        this.closeForm.controls['investCheckbox'].value &&
        data?.status === 'NEW'
      "
    >
      <form [formGroup]="closeTeamForm">
        <div class="field-full flex column">
          <mat-label class="field-label"
            >*{{ "AddReportIncident.TeamLeader" | translate }}</mat-label
          >
          <mat-form-field appearance="outline">
            <mat-select
              ngxMatSelect
              #msf1="ngxMatSelect"
              [displayMember]="'fullName'"
              [valueMember]="'userId'"
              [source]="users"
              [useInfiniteScroll]="true"
              formControlName="closeTeamLeadName"
              [multiple]="false"
              [hasPermission]="hasCreateUserPermissions | permissionChecker"
              [title]="'users'"
              [manageRoute]="'/user-management/users'"
              [placeholder]="'Placeholder.Select' | translate"
            >
              <mat-option
                [value]="item.userId"
                *ngFor="let item of msf1.filteredSource"
              >
                {{ item.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div formArrayName="closeTeamMemberForm">
          <form
            *ngFor="let group of closeTeamMemberArray.controls; let i = index"
            [formGroupName]="i"
            class="border border-b-pink-100 p-3 rounded-md my-3 mb-4 relative"
          >
            <button
              (click)="removeMember(i)"
              color="primary"
              class="absolute right-0"
              style="top: -20px"
              mat-icon-button
              [matTooltip]="'General.Buttons.Delete' | translate"
              type="button"
            >
              <mat-icon svgIcon="mat:cancel"></mat-icon>
            </button>
            <div class="flex items-center gap-3">
              <div class="flex column flex-grow">
                <mat-label class="field-label">{{
                  "AddReportIncident.TeamMember" | translate
                }}</mat-label>
                <mat-form-field appearance="outline">
                  <mat-select
                    ngxMatSelect
                    #msf1="ngxMatSelect"
                    [displayMember]="'fullName'"
                    [valueMember]="'userId'"
                    [source]="users"
                    [useInfiniteScroll]="true"
                    formControlName="teamMember"
                    [multiple]="false"
                    [hasPermission]="
                      hasCreateUserPermissions | permissionChecker
                    "
                    [title]="'users'"
                    [manageRoute]="'/user-management/users'"
                    [placeholder]="'Placeholder.Select' | translate"
                  >
                    <mat-option
                      [value]="item.userId"
                      *ngFor="let item of msf1.filteredSource"
                    >
                      {{ item.fullName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="flex column flex-grow">
                <mat-label class="field-label">{{
                  "AddReportIncident.TargetDate" | translate
                }}</mat-label>
                <mat-form-field appearance="outline">
                  <input
                    [placeholder]="'Placeholder.SelectDate' | translate"
                    matInput
                    [matDatepicker]="endDate"
                    (click)="endDate.open()"
                    [min]="currentDate"
                    formControlName="targetDate"
                  />
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="endDate"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #endDate></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="flex-row">
              <mat-label class="field-label">{{
                "AddReportIncident.Task" | translate
              }}</mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <textarea
                  matInput
                  rows="5"
                  [placeholder]="'AddReportIncident.Task' | translate"
                  formControlName="task"
                >
                </textarea>
              </mat-form-field>
            </div>
          </form>
        </div>
      </form>
      <button
        (click)="addTeamMemberRow()"
        class="my-4 flex-none"
        color="primary"
        mat-mini-fab
        [matTooltip]="'General.Buttons.AddNew' | translate"
        type="button"
      >
        <mat-icon svgIcon="mat:add"></mat-icon>
      </button>
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      #btn
      *ngIf="
        !this.closeForm.controls['investCheckbox'].value ||
        (this.closeForm.controls['investCheckbox'].value &&
          data?.status !== 'NEW')
      "
      [btnType]="'Add'"
      [btnText]="'Close'"
      [isDisabled]="!closeForm.valid ? true : false"
      (onClick)="checkCloseOrNot()"
    ></app-basic-button>
    <app-basic-button
      #btn
      [btnType]="'Add'"
      *ngIf="
        this.closeForm.controls['investCheckbox'].value && data?.status == 'NEW'
      "
      [btnText]="'StartInvestigation'"
      [isDisabled]="!closeTeamForm.valid ? true : false"
      (onClick)="checkCloseOrNot()"
    >
    </app-basic-button>
  </div>
</ng-template>
