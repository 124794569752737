import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Constants } from 'src/@hodhod/common/constants';
import { MatSelectChange } from '@angular/material/select';
import {
  EquipmentStatus,
  SortDirection,
  equipmentStatus,
} from 'src/@hodhod/common/enum';
import { InspectionManagementService } from 'src/backend/services/inspection-management/inspection-management.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { takeUntil, merge, tap } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { SharedConstants } from 'src/app/shared/models/shared-constants';

@UntilDestroy()
@Component({
  selector: 'app-history-logs',
  templateUrl: './history-logs.component.html',
  styleUrls: ['./history-logs.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class HistoryLogsComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  columns: TableColumn<any>[] = [
    {
      label: 'Date',
      property: 'inspectionDate',
      type: 'date',
      visible: true,
    },
    {
      label: 'Shift',
      property: 'shift',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'InspectedBy',
      property: 'inspectedBy',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    // {
    //   label: 'Adherence',
    //   property: 'adherence',
    //   type: 'text',
    //   visible: true,
    //   cssClasses: ['font-medium'],
    // },
    {
      label: 'Adherence',
      property: 'adherence',
      type: 'boolean',
      visible: true,
    },
    {
      label: 'InspectionScore',
      property: 'inspectionScore',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
  ];
  dataSource = new MatTableDataSource<any>([]);
  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  searchCtrl = new UntypedFormControl();
  public selectedStatus: string[] = [];
  equipmentStatus = equipmentStatus;

  searchValue: string = '';
  loadingLabel: string = '';
  timer = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private inspectionManagementService: InspectionManagementService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.selectedStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);
    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.getData())
      )
      .subscribe();
    this.getData();
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  onSelectStatus(event: MatSelectChange) {
    this.selectedStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  getData() {
    let { id } = this.route.snapshot.params;
    let sortDirection = SortDirection.None;

    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: sortDirection,
      sortField,
      status: this.selectedStatus,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      searchValue: this.searchValue,
    };
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.inspectionManagementService
      .getInspectionLogs(filteredParams, id)
      .subscribe({
        next: (response) => {
          this.dataSource = new MatTableDataSource(response?.data);
          this.pagination.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }
  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  navigateToDetails(id) {
    this.router.navigate([
      SharedConstants.INSPECTION_MANAGEMENT +
        '/' +
        SharedConstants.INSPECTION_MANAGEMENT_REVIEW +
        '/' +
        id,
    ]);
  }
}
