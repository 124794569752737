import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserManagementRoutingModule } from './user-management-routing.module';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { SharedModule } from '../shared/shared.module';
import { ViewUserComponent } from './components/view-user/view-user.component';
import { UserPermissionComponent } from './components/user-permission/user-permission.component';
import { UserViewPageComponent } from './pages/user-view-page/user-view-page.component';
import { PermissionsDataTableComponent } from './components/permissions-data-table/permissions-data-table.component';
import { ScrollbarModule } from 'src/@hodhod/components/scrollbar/scrollbar.module';
import { RolePageComponent } from './pages/role-page/role-page.component';
import { RoleViewPageComponent } from './pages/role-view-page/role-view-page.component';
import { RolesListComponent } from './components/roles-list/roles-list.component';
import { ViewRoleComponent } from './components/view-role/view-role.component';
import { RolePermissionComponent } from './components/role-permission/role-permission.component';
import { UsersInRoleComponent } from './components/users-in-role/users-in-role.component';
import { RoleService } from 'src/backend/services/role.service';
import { UserService } from 'src/backend/services/user.service';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserProfileViewPageComponent } from './pages/user-profile-view-page/user-profile-view-page.component';
import { SwitchAccountComponent } from './components/switch-account/switch-account.component';
import { BulkUploadUsersComponent } from './components/bulk-upload-users/bulk-upload-users.component';
import { BulkUploadUserPageComponent } from './pages/bulk-upload-user-page/bulk-upload-user-page.component';
import { BulkUploadUsersListComponent } from './components/bulk-upload-users-list/bulk-upload-users-list.component';
import { NewRoleComponent } from './components/new-role/new-role.component';


@NgModule({
  declarations: [
    UserPageComponent,
    UsersListComponent,
    ViewUserComponent,
    UserPermissionComponent,
    UserViewPageComponent,
    PermissionsDataTableComponent,
    RolePageComponent,
    RoleViewPageComponent,
    RolesListComponent,
    ViewRoleComponent,
    RolePermissionComponent,
    UsersInRoleComponent,
    UserProfileComponent,
    UserProfileViewPageComponent,
    SwitchAccountComponent,
    BulkUploadUsersComponent,
    BulkUploadUserPageComponent,
    BulkUploadUsersListComponent,
    NewRoleComponent
  ],
  imports: [
    CommonModule,
    UserManagementRoutingModule,
    SharedModule,
    ScrollbarModule
  ],
  providers: [
    RoleService,
    UserService
  ]
})
export class UserManagementModule { }
