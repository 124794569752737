import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HistoryTypes } from 'src/@hodhod/common/enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { Confirmation, ConfirmationType } from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { GetBodyParts } from 'src/backend/models/nature-of-injuiry/nature-of-injuiry.model';
import { NatureOfInjuryService } from 'src/backend/services/nature-of-injuiry/nature-of-injury.service';

@Component({
  selector: 'app-add-update-natureInjury',
  templateUrl: './add-update-natureInjury.component.html',
  styleUrls: ['./add-update-natureInjury.component.scss']
})
export class AddUpdateNatureInjuryComponent implements OnInit {

  injuiryForm: FormGroup;
  formMode: any;
  bodyParts: GetBodyParts[];
  private destroy$ = new Subject();
  public translationsList: any = {};

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';
  HistoryType = HistoryTypes.NATURE_OF_INJURIES;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AddUpdateNatureInjuryComponent>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private natureOfInjuiryService: NatureOfInjuryService,
    private feedBackService: AsyncFeedbackService
  ) {
    this.translate.get(['Errors', 'Success', 'Role', 'User']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
      this.translationsList = translations;
    });
  }

  ngOnInit() {
    this.getBodyParts();
    if (this.defaults.action === 'update') {
      this.formMode = 'update';
    } else if (this.defaults.action === 'view') {
      this.formMode = 'view';
    } else if (this.defaults.action === 'create') {
      this.formMode = 'create'
      this.defaults.role = {} as any;
    }
    this.injuiryForm = this.fb.group({
      injuiryName: [this.defaults.role.natureOfInjuiryName || '',
      [Validators.required, noWhitespaceValidator]],
      bodypart: [this.defaults.role.bodyPartId, [Validators.required]]
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getBodyParts() {
    this.natureOfInjuiryService.getBodyParts().subscribe({
      next: (res) => {
        this.bodyParts = res;
      }
    });
  }

  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createUser();
          }
        });
    } else if (this.formMode === 'update') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['Role']['UpdateConfirm']['Title'],
            this.translationsList['Role']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.updateUser();
          }
        });
    }
  }

  createUser() {
    const { injuiryName, bodypart } = this.injuiryForm.value;
    const obj = {
      natureOfInjuiryName: injuiryName,
      bodyPartId: bodypart,
      companyID: '2'
    }
    this.loadingLabel = "General.Refreshing";
    this.sectionState = SectionStateStatus.Loading;
    this.natureOfInjuiryService.postNatureOfInjuiry(obj).subscribe({
      next: () => {
        const bodyPartName = this.bodyParts.find(x => x.bodyPartId == bodypart).bodyPart;
        this.dialogRef.close();
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, this.translationsList['Success']['SuccessMessage'])
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      }
    });

  }

  updateUser() {
    const { injuiryName, bodypart } = this.injuiryForm.value;
    const data = {
      id: this.defaults.role.id,
      natureOfInjuiryName: injuiryName,
      bodyPartId: bodypart,
      companyID: '2'
    };
    this.loadingLabel = "General.Refreshing";
    this.sectionState = SectionStateStatus.Loading;
    this.natureOfInjuiryService.UpdateNatureOfInjuiry(data).subscribe({
      next: () => {
        const bodyPartName = this.bodyParts.find(x => x.bodyPartId == bodypart).bodyPart;
        this.dialogRef.close();
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, this.translationsList['Success']['UpdateMessage'])
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      }
    });
  }

  isCreateMode() {
    return this.formMode === 'create';
  }

  isUpdateMode() {
    return this.formMode === 'update';
  }

  isViewMode() {
    return this.formMode === 'view';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


}
