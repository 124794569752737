import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TaskStatus } from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { GetTaskRecurrenceById } from 'src/backend/models/task-tracker/task-tracker.model';
import { TaskTrackerService } from 'src/backend/services/task-tracker/task-tracker.service';
import { AddInspectionPlanComponent } from '../add-inspection-plan/add-inspection-plan.component';
import { UpdateSingleTaskComponent } from '../update-single-task/update-single-task.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-audit-item',
  templateUrl: './audit-item.component.html',
  styleUrls: ['./audit-item.component.scss'],
})
export class AuditItemComponent implements OnInit {
  @Input() task: any;
  @Output() onChangeTask: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('editDialog') editDialog!: TemplateRef<any>;
  @ViewChild('deleteDialog') deleteDialog!: TemplateRef<any>;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public expanded: any = false;

  public taskDetails: any;

  public TasksStatus = TaskStatus;
  public loggedUser: LoggedUser;

  editDialogForm: FormGroup;
  deleteDialogForm: FormGroup;

  public translationsList: any = {};
  private destroy$ = new Subject();

  hasViewPermissions = ApplicationPermission.INSPECTION_PLAN_ACCESS;
  hasCreatePermissions = ApplicationPermission.INSPECTION_PLAN_CREATE;
  hasApprovePermissions = ApplicationPermission.INSPECTION_PLAN_APPROVE;
  hasEditPermissions = ApplicationPermission.INSPECTION_PLAN_UPDATE;
  hasDeletePermissions = ApplicationPermission.INSPECTION_PLAN_DELETE;

  constructor(
    private taskTrackerService: TaskTrackerService,
    private feedBackService: AsyncFeedbackService,
    private router: Router,
    private dialog: MatDialog,
    private baseApi: BaseApi,
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.editDialogForm = this.fb.group({
      check: [false],
    });
    this.deleteDialogForm = this.fb.group({
      check: [false],
    });
    this.translate
      .get([
        'Errors',
        'Success',
        'Role',
        'TaskTracker',
        'RenewIdCard',
        'StaticPages',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  getTaskById(event) {
    if (event && !this.taskDetails) {
      this.sectionState = SectionStateStatus.Loading;
      const data: GetTaskRecurrenceById = {
        Id: this.task.id,
      };
      this.taskTrackerService.GetTaskRecurrenceById(data).subscribe({
        next: (res) => {
          this.sectionState = SectionStateStatus.Ready;
          this.taskDetails = res;
        },
        error: (error) => {
          this.sectionState = SectionStateStatus.Ready;
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
    }
  }

  editAudit() {
    this.dialog.open(this.editDialog, {
      height: 'auto',
      width: '30%',
    });
  }

  deleteAudit() {
    this.dialog.open(this.deleteDialog, {
      height: 'auto',
      width: '30%',
    });
  }

  executeAudit() {
    this.router.navigate([
      '/' +
        SharedConstants.TASK_TRACKER +
        '/' +
        SharedConstants.TASK_TRACKER_List +
        '/' +
        SharedConstants.EXECUTE_TASK_TRACKER +
        '/' +
        this.task.id,
    ]);
  }
  viewAudit() {
    this.router.navigate([
      '/' +
        SharedConstants.TASK_TRACKER +
        '/' +
        SharedConstants.TASK_TRACKER_List +
        '/' +
        SharedConstants.View_TASK_TRACKER +
        '/' +
        this.task.id,
    ]);
  }

  onClickEditDialog(): any {
    this.dialog.closeAll();
    if (this.editDialogForm.value.check == true) {
      const data = {
        role: '',
        action: 'update',
        Id: this.task.id,
      };

      let dialogRef = this.dialog
        .open(AddInspectionPlanComponent, {
          height: 'auto',
          width: '90%',
          data: data,
        })
        .afterClosed()
        .subscribe((role: any) => {
          this.onChangeTask.emit(true);
        });
    } else {
      const data = {
        role: '',
        action: 'update',
        Id: this.task.id,
      };

      let dialogRef = this.dialog
        .open(UpdateSingleTaskComponent, {
          height: 'auto',
          width: '90%',
          data: data,
        })
        .afterClosed()
        .subscribe((role: any) => {
          this.onChangeTask.emit(true);
          // this.GetTaskRecurrence();
        });
    }
  }

  onClickDeleteDialog(): any {
    this.dialog.closeAll();
    if (this.deleteDialogForm.value.check == true) {
      this.deleteSeries();
    } else {
      this.deleteEvent();
    }
  }

  isWithinRange(): boolean {
    const today = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    const formattedStartDate = this.datePipe.transform(
      this.task?.startTaskDate,
      'MM/dd/yyyy'
    );
    const formattedEndDate = this.datePipe.transform(
      this.task?.taskDate,
      'MM/dd/yyyy'
    );

    return (
      new Date(today).getTime() >= new Date(formattedStartDate).getTime() &&
      new Date(today).getTime() <= new Date(formattedEndDate).getTime()
    );
  }

  deleteSeries() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['TaskTracker']['RemoveTaskConfirm']['Title'],
          this.translationsList['TaskTracker']['RemoveTaskConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.taskTrackerService
            .removeTaskSeries(parseInt(this.task.id, 10))
            .subscribe({
              next: (res) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                // this.GetTaskRecurrence();
                this.onChangeTask.emit(true);
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
              },
            });
        }
      });
    //this.events = this.events.filter(event => event !== eventToDelete);
  }

  deleteEvent() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['TaskTracker']['RemoveTaskConfirm']['Title'],
          this.translationsList['TaskTracker']['RemoveTaskConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.taskTrackerService
            .removeTask(parseInt(this.task.id, 10))
            .subscribe({
              next: (res) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.onChangeTask.emit(true);
                // this.GetTaskRecurrence();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
              },
            });
        }
      });
    //this.events = this.events.filter(event => event !== eventToDelete);
  }
}
