import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-condition-page',
  templateUrl: './work-condition-page.component.html',
  styleUrls: ['./work-condition-page.component.scss']
})
export class WorkConditionPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
