import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class InspectionManagementService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getInspectorsInspections() {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_INSPECTORS_INSPECTIONS)
    );
  }

  getReviewersInspections() {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_REVIEWER_INSPECTIONS)
    );
  }

  getInspectionChecklist(id) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_INSPECTION_CHECKLIST + '/' + id)
    );
  }

  executeInspection(data) {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_POST_EXECUTE_INSPECTION),
      data
    );
  }

  approveUnsafety(id) {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_APPROVE_UNSAFETY + '/' + id),
      {}
    );
  }
  getDownTimeLogs(data, id) {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      data?.status.forEach((statusItem, index) => {
        if (params.length > 0) {
          params += '&';
        }
        params += `status${statusItem}=` + statusItem;
      });
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.sourceType) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sourceType=' + data?.sourceType;
    }

    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_DOWNTIME_LOGS + '/' + id + '?' + params
      )
    );
  }
  getInspectionLogs(data, id) {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      data?.status.forEach((statusItem, index) => {
        if (params.length > 0) {
          params += '&';
        }
        params += `status${statusItem}=` + statusItem;
      });
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.sourceType) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sourceType=' + data?.sourceType;
    }

    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_INSPECTION_LOGS + '/' + id + '?' + params
      )
    );
  }
}
