<!-- <div class="box-grid">
  <img src="assets/img/loading_hodhod.gif" width="150" alt="loading">
  <h5 class="mt-0 text-sm font-bold">{{loadingLabel}}</h5>
</div> -->
<div class="box-grid">
  <div class="box box1"></div>
  <div class="box box2"></div>
  <div class="box box3"></div>
  <div class="box box4"></div>
  <div class="box box5"></div>
  <div class="box box6"></div>
  <div class="box box7"></div>
  <div class="box box8"></div>
  <div class="box box9"></div>
</div>