import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicLayoutRoutingModule } from './public-layout-routing.module';
import { PublicLayoutComponent } from './public-layout.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { CarouselModule } from 'ng-carousel-cdk';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';


@NgModule({
  declarations: [
    PublicLayoutComponent,
    SignInComponent,
    ForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    PublicLayoutRoutingModule,
    CarouselModule,
    SharedModule
  ]
})
export class PublicLayoutModule { }
