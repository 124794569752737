import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { takeUntil, merge, tap } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { PenaltyStatusFilter, SortDirection } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { PenaltyManagementService } from 'src/backend/services/penalty-management.service';

@UntilDestroy()
@Component({
  selector: 'app-penalty-management-list',
  templateUrl: './penalty-management-list.component.html',
  styleUrls: ['./penalty-management-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class PenaltyManagementListComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  loadingLabel: string = '';
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;

  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;

  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };

  dataSource: MatTableDataSource<any> | null;
  columns: TableColumn<any>[] = [
    {
      label: 'Code',
      property: 'penaltyCode',
      type: 'text',
      visible: true,
    },
    {
      label: 'WorkOrderNo',
      property: 'workOrderNo',
      type: 'text',
      visible: true,
    },
    {
      label: 'Title',
      property: 'title',
      type: 'text',
      visible: true,
    },
    {
      label: 'ReportedBy',
      property: 'createdBy',
      type: 'text',
      visible: true,
    },
    {
      label: 'DateOfReceiving',
      property: 'dateReceived',
      type: 'date',
      visible: true,
    },
    {
      label: 'ReportedOn',
      property: 'createdOn',
      type: 'date',
      visible: true,
    },
    {
      label: 'Area',
      property: 'area',
      type: 'text',
      visible: true,
    },
    {
      label: 'Team',
      property: 'responsibleTeam',
      type: 'text',
      visible: true,
    },
    {
      label: 'Cost',
      property: 'cost',
      type: 'text',
      visible: true,
    },
    {
      label: 'Status',
      property: 'status',
      type: 'boolean',
      visible: true,
    },
    {
      label: 'LastUpdate',
      property: 'updatedOn',
      type: 'date',
      visible: true,
    },
    {
      label: 'ClosedBy',
      property: 'approverName',
      type: 'text',
      visible: true,
    },
    {
      label: 'Actions',
      property: 'actions',
      type: 'button',
      visible: true,
      cssClasses: ['action-cell'],
    },
  ];

  currentQueryParams = null;
  filteredParams = null;

  public selectedStatus: string[] = [];
  public selectedUsers: string[] = [];
  public approverList = [];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;

  public penaltyStatusFilter = PenaltyStatusFilter;
  hasCreatePermission=ApplicationPermission.PENALTY_MANAGEMENT_CREATE
  hasUpdatePermission=ApplicationPermission.PENALTY_MANAGEMENT_UPDATE
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private penaltyManagementService: PenaltyManagementService,
    private feedBackService: AsyncFeedbackService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource = new MatTableDataSource();
    this.currentQueryParams = this.route.snapshot.queryParams;
    this.dataSource.sort = this.sort;
    this.selectedUsers = this.currentQueryParams?.selectedUsers?.split(',');
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);
    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));

    this.getPenalty();
    this.getApproverList();
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getPenalty();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();

    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  getApproverList() {
    this.penaltyManagementService.getApproverList().subscribe({
      next: (res) => {
        this.approverList = res;
      },
    });
  }

  getPenalty() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortProperty = null;
    if (this.sort.active) {
      sortProperty = this.sort.active;
    }

    this.filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortProperty:
        this.currentQueryParams?.sortDirection === SortDirection.None
          ? null
          : this.currentQueryParams?.sortDirection,
      status: this.currentQueryParams?.orgStatus,
      closedBy: this.currentQueryParams?.selectedUsers,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
    };

    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.penaltyManagementService
      .getPenaltyManagementList(this.filteredParams)
      .subscribe({
        next: (response) => {
          this.dataSource = new MatTableDataSource(response.data);
          this.pagination.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  addPenalty() {
    this.router.navigate(
      [
        '/' +
          SharedConstants.PENALTY_MANAGEMENT +
          '/' +
          SharedConstants.CREATE_PENALTY_MANAGEMENT,
      ],
      { queryParams: { editMode: false } }
    );
  }

  viewPenalty(id) {
    this.router.navigate([
      SharedConstants.PENALTY_MANAGEMENT +
        '/' +
        SharedConstants.PENALTY_MANAGEMENT_VIEW +
        '/' +
        id,
    ]);
  }

  editPenalty(id: any) {
    this.router.navigate(
      [
        '/' +
          SharedConstants.PENALTY_MANAGEMENT +
          '/' +
          SharedConstants.CREATE_PENALTY_MANAGEMENT +
          '/' +
          id,
      ],
      { queryParams: { editMode: true } }
    );
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getPenalty();
  }

  onSelectStatus(event) {
    this.selectedStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getPenalty();
  }

  onSelectUser(event) {
    this.selectedUsers = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      selectedUsers: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getPenalty();
  }
}
