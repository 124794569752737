import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DashboardFilterPayload } from '../../models/dashboard';
import { SmartSafetyFilterDashboards } from 'src/@hodhod/common/enum';
import { DashboardCalenderComponent } from 'src/app/dashboard/components/dashboard-calender/dashboard-calender.component';
import { MatDialog } from '@angular/material/dialog';
import { SafemanHoursService } from 'src/backend/services/safeman-hours.service';
import { SafetyKpisService } from 'src/backend/services/dashboards/safety-kpis.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { MatTableDataSource } from '@angular/material/table';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { Router } from '@angular/router';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { log } from 'util';
@Component({
  selector: 'safeman-hours-card',
  templateUrl: './safeman-hours-card.component.html',
  styleUrls: ['./safeman-hours-card.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms, stagger40ms],
})
export class SafemanHoursCardComponent implements OnInit, OnChanges {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';
  dataSource = new MatTableDataSource<any>([]);
  @Input('colorCode') colorCode: any;
  @Input('activKpis') activKpis: any;
  Offset: number = 0;
  @Input('data') data: any;
  @ViewChild('expansion') expansion!: TemplateRef<any>;
  columns: TableColumn<any>[] = [
    {
      label: 'IncidentReportTitle',
      property: 'title',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'IncidentCategory',
      property: 'classification',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Department',
      property: 'department',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },

    {
      label: 'DateTime',
      property: 'incidentDate',
      type: 'date',
      visible: true,
    },
    {
      label: 'ReportedBy',
      property: 'createdBy',
      type: 'text',
      visible: true,
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
  ];
  PaginatedIncidents: any = [];
  activeCards;
  isLTIRActive: boolean = false;
  isTRIRActive: boolean = false;
  isTRIFRActive: boolean = false;
  noMoreData: boolean = false;
  kpiName: string;
  filterationDate: any;
  public filterDashboards: any = null;
  public dashboardFilter: DashboardFilterPayload = {
    fromDate: new Date().toISOString(),
    toDate: new Date().toISOString(),
  };
  LTIR: any;
  TRIR: any;
  TRIFR: any;
  safetyKpisArray: any[] = [];
  public filterName: string = 'Past30Days';
  constructor(
    private dialog: MatDialog,
    private safetyKpisService: SafetyKpisService,
    private _bottomSheet: MatBottomSheet,
    private router: Router,
    private feedBackService: AsyncFeedbackService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.filterDashboards = SmartSafetyFilterDashboards;
    const today = new Date();
    today.setHours(23, 59, 59, 0);
    this.dashboardFilter.toDate = new Date(today).toISOString();
    const Past30Day = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
    Past30Day.setHours(0, 0, 0, 0);
    this.dashboardFilter.fromDate = Past30Day.toISOString();
    this.getActiveSafetykpis();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.activeCards = changes['activKpis'].currentValue;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  onFilterChange(filterName: string, apiName) {
    const date = new Date();
    this.filterName = filterName;
    if (filterName !== 'customRange') {
      if (filterName === 'thisDay') {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        let lastHourInDay = new Date();
        lastHourInDay.setHours(23);
        lastHourInDay.setMinutes(59);
        lastHourInDay.setSeconds(59);
        lastHourInDay.setMilliseconds(999);

        this.dashboardFilter.fromDate = new Date(today).toISOString();
        this.dashboardFilter.toDate = new Date(lastHourInDay).toISOString();
      } else if (filterName === 'yesterday') {
        const today = new Date();
        const day = today.getDate() - 1;
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setDate(day);

        this.dashboardFilter.fromDate = new Date(today).toISOString();
        today.setHours(23);
        today.setMinutes(59);
        today.setSeconds(59);
        today.setMilliseconds(999);
        this.dashboardFilter.toDate = new Date(today).toISOString();
      } else if (filterName === 'currentMonth') {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDay.setHours(0, 0, 0, 0);
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.fromDate = firstDay.toISOString();
        this.dashboardFilter.toDate = today.toISOString();
      } else if (filterName === 'lastMonth') {
        const today = new Date();
        const firstDayPrevMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        firstDayPrevMonth.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = firstDayPrevMonth.toISOString();
        const lastDayPrevMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        lastDayPrevMonth.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = lastDayPrevMonth.toISOString();
      } else if (filterName === 'lastThirtyDay') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const Past30Day = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
        Past30Day.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = Past30Day.toISOString();
      } else if (filterName === 'yearToDate') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);

        this.dashboardFilter.toDate = new Date(today).toISOString();

        const startYearDate = new Date(date.getFullYear(), 0, 1);
        startYearDate.setHours(0, 0, 0, 0);

        this.dashboardFilter.fromDate = startYearDate.toISOString();
      }
      this.checkWhichApi(apiName, this.dashboardFilter);
    }
  }
  checkWhichApi(apiName, filterDate) {
    if (apiName === 'totalRecordableIncidentRate') {
      this.getTRIR(filterDate);
    } else if (apiName === 'lostTimeInjuryFrequencyRate') {
      this.getLTIR(filterDate);
    } else if (apiName === 'totalRecordableInjuryFrequencyRate') {
      this.getTRIFR(filterDate);
    }
  }
  getLTIR(date) {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.safetyKpisService.getLTIR(date).subscribe({
      next: (res) => {
        this.LTIR = res;
        this.LTIR.color = '#D52A46';
        this.LTIR.number = 1;
        this.LTIR.isActive = this.isLTIRActive;
        this.LTIR.date = { ...date };
        let foundIndex = this.safetyKpisArray.findIndex(
          (obj) => obj.type === this.LTIR.type
        );
        if (foundIndex === -1) {
          this.safetyKpisArray.push(this.LTIR);
        } else {
          this.safetyKpisArray[foundIndex] = this.LTIR;
        }
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (err) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, err?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  getTRIR(date) {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.safetyKpisService.getTRIR(date).subscribe({
      next: (res) => {
        this.TRIR = res;
        this.TRIR.color = '#73C75E';
        this.TRIR.number = 3;
        this.TRIR.isActive = this.isTRIRActive;
        this.TRIR.date = { ...date };
        let foundIndex = this.safetyKpisArray.findIndex(
          (obj) => obj.type === this.TRIR.type
        );
        if (foundIndex === -1) {
          this.safetyKpisArray.push(this.TRIR);
        } else {
          this.safetyKpisArray[foundIndex] = this.TRIR;
        }
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (err) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, err?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  getTRIFR(date) {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.safetyKpisService.getTRIFR(date).subscribe({
      next: (res) => {
        this.TRIFR = res;
        this.TRIFR.color = '#FFDD29';
        this.TRIFR.number = 2;
        this.TRIFR.isActive = this.isTRIFRActive;
        this.TRIFR.date = { ...date };
        let foundIndex = this.safetyKpisArray.findIndex(
          (obj) => obj.type === this.TRIFR.type
        );
        if (foundIndex === -1) {
          this.safetyKpisArray.push(this.TRIFR);
        } else {
          this.safetyKpisArray[foundIndex] = this.TRIFR;
        }
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (err) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, err?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  getActiveSafetykpis() {
    this.isLTIRActive = this.activeCards?.some(
      (obj) => obj.type === 'lostTimeInjuryFrequencyRate'
    );
    this.isTRIRActive = this.activeCards?.some(
      (obj) => obj.type === 'totalRecordableIncidentRate'
    );
    this.isTRIFRActive = this.activeCards?.some(
      (obj) => obj.type === 'totalRecordableInjuryFrequencyRate'
    );
    this.isLTIRActive?this.getLTIR(this.dashboardFilter):null;
    this.isTRIFRActive?this.getTRIFR(this.dashboardFilter):null;
    this.isTRIRActive?this.getTRIR(this.dashboardFilter):null;
  }

  get sortedSafetyKpisArray() {
    return this.safetyKpisArray.slice().sort((a, b) => a.number - b.number);
  }
  onSelectCustomRange(apiName) {
    const dialogRef = this.dialog.open(DashboardCalenderComponent, {
      width: window.innerWidth < 850 ? '90%' : '60%',
      height: 'auto',
      data: {
        fromDate: this.dashboardFilter.fromDate,
        toDate: this.dashboardFilter.toDate,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event !== 'cancel') {
        this.dashboardFilter.fromDate = new Date(
          new Date(result?.data?.fromDate).setHours(0, 0, 0, 0)
        ).toISOString();
        this.dashboardFilter.toDate = new Date(
          new Date(result?.data?.toDate).setHours(23, 59, 59, 0)
        ).toISOString();
        this.filterName = 'customRange';
      }
      this.dashboardFilter.fromDate = result?.data?.fromDate;
      this.dashboardFilter.toDate = result?.data?.toDate;
      this.filterName = 'customRange';
      this.checkWhichApi(apiName, this.dashboardFilter);
    });
  }
  openBottomSheet(kpiName, filterationDate) {
    this.getPaginatedKpis(kpiName, filterationDate);
    this.kpiName = kpiName;
    this.filterationDate = filterationDate;
    this.dialog.open(this.expansion, {
      maxHeight: '95vh',
      width: '70%',
      disableClose: false,
    });
  }
  getPaginatedKpis(kpiName?, filterationDate?, seeMore?) {
    if (seeMore) {
      this.Offset++;
    } else {
      this.Offset = 0;
      this.PaginatedIncidents = [];
    }
    let data = {
      offset: this.Offset,
      dashboardType: kpiName ? kpiName : this.kpiName,
    };
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.safetyKpisService
      .getPaginatedKpis(
        data,
        filterationDate ? filterationDate : this.filterationDate
      )
      .subscribe({
        next: (res) => {
          this.PaginatedIncidents.push(...res['data']);
          if (res['totalCount'] === this.PaginatedIncidents?.length) {
            this.noMoreData = true;
          }
          this.dataSource = new MatTableDataSource(this.PaginatedIncidents);
          this.sectionState = SectionStateStatus.Ready;
        },

        error: (err) => {
          this.dataSource = new MatTableDataSource(this.PaginatedIncidents);
          this.sectionState = SectionStateStatus.Ready;
          this.feedBackService?.showFeedback(
            new FeedbackModel(FeedbackType.Failure, err['error'].details)
          );
        },
      });
  }
  navigateToIncident(id) {
    this.dialog.closeAll();
    this.router.navigate(
      [
        SharedConstants.REPORTING_MANAGEMENT +
          '/' +
          SharedConstants.REPORT_INCIDENT +
          '/' +
          SharedConstants.REPORT_INCIDENT_VIEW +
          '/' +
          id,
      ],
      {
        queryParams: { editMode: false },
      }
    );
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
}
