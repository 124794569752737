<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "CheckList.Title" | translate }}</span>
    </h2>
    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'Placeholder.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>

    <button
      *ngIf="
        (hasAuditCreatePermission | permissionChecker) ||
        (hasWorkConditionsCreatePermission | permissionChecker) ||
        (hasInspectionCreatePermission | permissionChecker)
      "
      class="ml-4 flex-none"
      (click)="createChecklist()"
      color="primary"
      mat-mini-fab
      [matTooltip]="'General.Buttons.AddNew' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>

    <button
      *ngIf="
        (hasAuditCreatePermission | permissionChecker) ||
        (hasWorkConditionsCreatePermission | permissionChecker) ||
        (hasInspectionCreatePermission | permissionChecker)
      "
      class="ml-4 flex-none bg-indigo-700"
      (click)="openBulkUploadModal()"
      mat-mini-fab
      [matTooltip]="'CheckList.BulkUpload' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:queue"></mat-icon>
    </button>
  </div>
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "CheckList.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div class="w-10/12 truncate" #div>
              <span
                [matTooltip]="
                  div?.offsetWidth < div?.scrollWidth
                    ? row[column.property]
                    : null
                "
                >{{ row[column.property] }}</span
              >
            </div>
            <span
              *ngIf="column.property === 'roleName' && row?.isDefault"
              class="bg-primary text-white rounded-full p-1 font-normal px-3"
              >Default</span
            >
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "CheckList.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "dd-MM-yyyy" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "CheckList.Table." + column.label | translate }}
              <mat-select
                [value]="selectedChecklistStatus"
                (selectionChange)="onSelectCheckListStatus($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of RolesStatuses | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.CompanyUserStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <app-boolean-view
              [value]="row[column.property] === 'ACTIVE'"
              [text]="
                row[column.property] === 'ACTIVE'
                  ? ('General.Active' | translate)
                  : ('General.Inactive' | translate)
              "
            >
            </app-boolean-view>
          </td>
        </ng-container>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th *matHeaderCellDef mat-header-cell></th>

        <td *matCellDef="let row" mat-cell>
          <div
            (click)="$event.stopPropagation()"
            *ngIf="
              (row.checkListType === 'Audit'
                ? hasAuditAccessPermission
                : hasWorkConditionsAccessPermission
              ) | permissionChecker
            "
          >
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                *ngIf="
                  (row.checkListType === 'Audit'
                    ? hasAuditActivePermission
                    : row.checkListType === 'WorkCondition'
                    ? hasWorkConditionsActivePermission
                    : hasInspectionChecklistActivePermission
                  ) | permissionChecker
                "
                (click)="
                  row?.status === 'ACTIVE'
                    ? deactivateChecklist(row?.id, row?.checkListType)
                    : activateChecklist(row?.id, row?.checkListType)
                "
              >
                <button>
                  {{
                    (row?.status === "ACTIVE"
                      ? "General.Buttons.Deactivate"
                      : "General.Buttons.Activate"
                    ) | translate
                  }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="viewChecklist($event, row?.id, row?.checkListType)"
              >
                <button>
                  {{ "General.Buttons.View" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                *ngIf="
                  (row.checkListType === 'Audit'
                    ? hasAuditEditPermission
                    : row.checkListType === 'WorkCondition'
                    ? hasWorkConditionsEditPermission
                    : hasInspectionChecklistEditPermission
                  ) | permissionChecker
                "
                (click)="updateChecklist($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.Edit" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewChecklist($event, row?.id, row?.checkListType)"
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-icon
      class="text-[#757575] me-1"
      *ngIf="!column.visible"
      svgIcon="mat:check_box_outline_blank"
    ></mat-icon>
    <mat-icon
      *ngIf="column.visible"
      class="text-primary me-1"
      svgIcon="mat:check_box"
    ></mat-icon>
    {{ "CheckList.Table." + column.label | translate }}
  </button>
</mat-menu>

<ng-template #bulkUploadModel>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "CheckList.BulkUpload" | translate }}
    </h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div>
    <div class="flex justify-between flex-wrap-reverse">
      <form [formGroup]="bulkUploadChecklistForm" class="flex-1 pr-5">
        <div class="flex columns">
          <button
            mat-flat-button
            color="primary"
            type="button"
            class="w-22 bg-black"
          >
            <a
              class="sample"
              href="../../../../assets//sheets/CheckListFormat.xlsx"
              download
              >{{ "BulkUpload.DownlodTemplate" | translate }}</a
            >
          </button>
        </div>
        <div class="flex column mt-2">
          <mat-label class="field-label">{{
            "CheckList.UploadChecklist" | translate
          }}</mat-label>
          <file-upload
            (change)="checkFileExtension($event)"
            formControlName="file"
            [multiple]="false"
            [animation]="true"
            (drop)="checkFileExtension($event)"
          ></file-upload>
        </div>
        <div class="flex items-center justify-end mt-3">
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="cancel()"
          ></app-basic-button>
          <app-basic-button
            [btnType]="'Add'"
            [isDisabled]="!bulkUploadChecklistForm.valid"
            [btnText]="'Add'"
            (onClick)="saveExcel()"
          ></app-basic-button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
