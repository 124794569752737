import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { HazardReportService } from 'src/backend/services/reportings/hazard-report.service';
import { ReportHazard } from '../../models/report-hazard';
import { PermissionService } from 'src/backend/services/permission.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-hazard-report-view-page',
  templateUrl: './hazard-report-view-page.component.html',
  styleUrls: ['./hazard-report-view-page.component.scss'],
})
export class HazardReportViewPageComponent implements OnInit {
  public reportHazardId: number = 0;
  private destroy$ = new Subject();
  public hazardReport: ReportHazard;
  public formMode: any;

  hasEditPermission = ApplicationPermission.REPORTING_HAZARDS_UPDATE;
  hasViewPermission = ApplicationPermission.REPORTING_HAZARDS_ACCESS;

  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private hazardReportService: HazardReportService,
    private feedBackService: AsyncFeedbackService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;
        this.reportHazardId = parseInt(id, 10);
        this.getHazardReport(this.reportHazardId);
      }
    });
    this.route.queryParamMap.subscribe((queryParam) => {
      this.formMode = queryParam.get('editMode') as any;
    });
  }

  getHazardReport(event: number): void {
    this.loadingService.startLoading(true);
    this.hazardReportService.getHazardReportById(event).subscribe({
      next: (response) => {
        this.hazardReport = response;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
}
