import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SearchTypes } from 'src/@hodhod/common/enum';

@Component({
  selector: 'app-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss']
})
export class SearchCardComponent implements OnInit {
  @Input() searchData: any;
  @Output() onCardClick: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  setSearchTypeIcon(searchType: string): string {
    let icon = '';
    switch (searchType) {
      case SearchTypes.WORKPERMIT:
        icon = 'work-permit-icon.svg';
        break;    
      case SearchTypes.TASK:
        icon = 'task-tracker-icon.svg';
        break; 
      case SearchTypes.HAZARD_REPORT:
        icon = 'report-hazard.svg';
        break; 
      case SearchTypes.NEARMISS_REPORT:
        icon = 'report-near-miss.svg';
        break; 
      case SearchTypes.INCIDENT_REPORT:
        icon = 'report-incident.svg';
        break; 
      case SearchTypes.ACTION_TRACKER:
        icon = 'actions-side-nav.svg';
        break;
    }
    return icon;
  }

  viewPage(): void {
    this.onCardClick.emit(this.searchData);
  }

}
