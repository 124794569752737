<div class="wrapper_section" [class.page]="isPageSection">
  <ng-container [ngSwitch]="state">
    <ng-container *ngSwitchCase="SectionState.Loading">
      <div class="state background fade-in" [class.ready-s]="transparentBackground">
        <app-spinner
        [loadingLabel]="loadingLabel"
        ></app-spinner>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="SectionState.Disconnected">
      <div class="state background problematic fade-in" [class.ready-s]="transparentBackground">
        <div class="error-icon">
          <img src="assets/img/resources/error-state.svg" alt="error-icon.svg" />
        </div>
        <div class="error-message">
          <span>Connection was broken while loading</span>
        </div>
        <div class="try-again" (click)="onReload()">Try Again</div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="SectionState.Error">
      <div class="state background problematic fade-in" [class.ready-s]="transparentBackground">
        <div class="error-icon">
          <svg-icon src="assets/img/resources/error.svg"></svg-icon>
        </div>
        <div *ngIf="errorLabel" class="error-message">
          <span>{{errorLabel}}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="SectionState.Empty">
      <div class="state background empty fade-in">
        <div class="empty-icon">
          <img [src]="emptyImagePath" />
        </div>
        <div *ngIf="emptyLabel" class="empty-message">
          <span>{{emptyLabel}}</span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="state === SectionState.Ready || state === SectionState.LoadingTransparent">
      <div *ngIf="state === SectionState.LoadingTransparent" class="background transparent" [class.flexible-height]="flexibleHeight">
        <app-spinner
        [loadingLabel]="loadingLabel"
        ></app-spinner>
      </div>
      <div class="fade-in " [class.ready-s]="transparentBackground">
        <ng-content></ng-content>
      </div>
    </ng-container>

  </ng-container>
</div>