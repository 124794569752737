<app-card [title]="'License data'">
  <div body class="flex justify-between flex-wrap-reverse">
    <div class="flex-1">
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ licenseData?.available }}</p>
          <p class="m-0 caption text-hint">available</p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ licenseData?.availableQty }} </p>
          <p class="m-0 caption text-hint">Total</p>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ licenseData?.consumedQty }}</p>
          <p class="m-0 caption text-hint">ِActive</p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ licenseData?.totalUsers }}</p>
          <p class="m-0 caption text-hint">Total added Users</p>
        </div>
      </div>
    </div>
  </div>
  <!-- <div body class="py-3 flex items-center" *ngIf="orgInfo">
      <div
        @scaleIn
        class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
      >
        <mat-icon
          class="icon-sm"
          svgIcon="mat:keyboard_double_arrow_right"
        ></mat-icon>
      </div>
      <div @fadeInRight>
        <p class="m-0 body-1">Description</p>
        <p class="m-0 caption text-hint">{{ orgInfo?.description }}</p>
      </div>
    </div> -->
</app-card>
