<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "ManageUsers.Title" | translate }}</span>
    </h2>

    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'General.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>

    <button
      (click)="openCreateUserModal()"
      *ngIf="hasWritePermissions | permissionChecker"
      class="ml-4 flex-none"
      color="primary"
      mat-mini-fab
      [matTooltip]="'General.Buttons.AddNew' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>

    <button
      (click)="openBulkUpload()"
      *ngIf="hasWritePermissions | permissionChecker"
      class="ml-4 flex-none bg-indigo-700"
      mat-mini-fab
      [matTooltip]="'General.Buttons.BulkUpload' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:group_add"></mat-icon>
    </button>
  </div>
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
    style="overflow: hidden"
  >
    <div style="overflow: auto">
      <table
        @stagger
        [dataSource]="dataSource"
        class="w-full"
        mat-table
        matSort
      >
        <!-- Image Column -->
        <ng-container matColumnDef="userImage">
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <ngx-avatars
              class="logo box"
              size="32"
              [src]="row['userImage']"
              [name]="row['fullName']"
              [textSizeRatio]="2"
              [round]="true"
              [initialsSize]="2"
            ></ngx-avatars>
          </td>
        </ng-container>

        <!-- Text Columns -->
        <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
          <ng-container
            *ngIf="
              column.type === 'text' &&
              column.property !== 'fullName' &&
              column.property !== 'departmentName'
            "
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "ManageUsers.Table." + column.label | translate }}
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              #div
              class="truncate max-w-0 p-3"
              mat-cell
            >
              <span
                [matTooltip]="
                  div?.offsetWidth < div?.scrollWidth
                    ? row[column.property]
                    : null
                "
              >
                {{ row[column.property] }}
              </span>
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'text' && column.property === 'fullName'"
            [matColumnDef]="column.property"
          >
            <th *matHeaderCellDef class="uppercase" mat-header-cell>
              <div
                style="width: 100%; position: relative"
                class="flex items-center"
              >
                <mat-icon
                  app-click-stop-propagation
                  style="width: 35px; height: 35px; cursor: pointer"
                  (click)="select.open()"
                  svgIcon="mat:keyboard_arrow_down"
                  color="primary"
                ></mat-icon>
                {{ "User.Table." + column.label | translate }}
                <mat-select
                  [value]="selectedUserVerification"
                  (selectionChange)="onSelectUserVerification($event)"
                  #select
                  class="select-options"
                  multiple
                >
                  <mat-option
                    *ngFor="
                      let verification of VerificationValues | enumToArray
                    "
                    [value]="verification.value"
                  >
                    {{
                      "Enum.VerificationValues." + verification.value
                        | translate
                    }}
                  </mat-option>
                </mat-select>
              </div>
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              #div
              class="truncate max-w-0 p-3"
              mat-cell
            >
              <span
                class="px-2"
                [matTooltip]="
                  div?.offsetWidth < div?.scrollWidth
                    ? row[column.property]
                    : null
                "
                >{{ row[column.property] }}</span
              >
              <mat-icon
                *ngIf="row?.isVerified"
                svgIcon="mat:verified"
                class="text-blue-700"
                style="height: 15px; width: 15px"
                [matTooltip]="'General.Verified' | translate"
              ></mat-icon>
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'date'"
            [matColumnDef]="column.property"
          >
            <th
              *matHeaderCellDef
              class="uppercase"
              mat-header-cell
              mat-sort-header
            >
              {{ "ManageUsers.Table." + column.label | translate }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              {{ row[column.property] | date : "dd-MM-yyyy" }}
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'boolean' && column.property === 'status'"
            [matColumnDef]="column.property"
          >
            <th *matHeaderCellDef class="uppercase" mat-header-cell>
              <div
                style="width: 100%; position: relative"
                class="flex items-center"
              >
                <mat-icon
                  app-click-stop-propagation
                  style="width: 35px; height: 35px; cursor: pointer"
                  (click)="select.open()"
                  svgIcon="mat:keyboard_arrow_down"
                  color="primary"
                ></mat-icon>
                {{ "User.Table." + column.label | translate }}
                <mat-select
                  [value]="selectedUserStatus"
                  (selectionChange)="onSelectUserStatus($event)"
                  #select
                  class="select-options"
                  multiple
                >
                  <mat-option
                    *ngFor="let statue of CompanyUserStatus | enumToArray"
                    [value]="statue.value"
                  >
                    {{ "Enum.CompanyUserStatus." + statue.value | translate }}
                  </mat-option>
                </mat-select>
              </div>
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <app-boolean-view
                [trueColor]="'#44ce5029'"
                [falseColor]="'#ff595929'"
                [trueTextColor]="'#3BA847'"
                [falseTextColor]="'#FF5959'"
                [value]="row[column.property] === 'ACTIVE'"
                [text]="
                  row[column.property] === 'ACTIVE'
                    ? ('General.Active' | translate)
                    : ('General.Inactive' | translate)
                "
              >
              </app-boolean-view>
            </td>
          </ng-container>
          <ng-container
            *ngIf="
              column.type === 'text' && column.property === 'departmentName'
            "
            [matColumnDef]="column.property"
          >
            <th *matHeaderCellDef class="uppercase" mat-header-cell>
              <div class="flex items-center">
                <mat-icon
                  app-click-stop-propagation
                  style="width: 35px; height: 35px; cursor: pointer"
                  (click)="select.open()"
                  svgIcon="mat:keyboard_arrow_down"
                  color="primary"
                ></mat-icon>
                {{ "ManageUsers.Table." + column.label | translate }}
                <mat-select
                  [value]="selectedDepartments"
                  (selectionChange)="onSelectDepartments($event)"
                  #select
                  class="select-options"
                  multiple
                >
                  <mat-option
                    *ngFor="let dep of allDepartments"
                    [value]="dep?.id"
                  >
                    {{ dep?.name }}
                  </mat-option>
                </mat-select>
              </div>
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              #div
              class="truncate max-w-0 p-3"
              mat-cell
            >
              <span
                [matTooltip]="
                  div?.offsetWidth < div?.scrollWidth
                    ? row[column.property]
                    : null
                "
              >
                {{ row[column.property] }}
              </span>
            </td>
          </ng-container>
        </ng-container>

        <!-- ACTIONS Column -->
        <ng-container matColumnDef="actions" stickyEnd>
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" mat-cell>
            <div (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon svgIcon="mat:more_vert"></mat-icon>
              </button>
              <mat-menu
                #menu="matMenu"
                yPosition="below"
                xPosition="before"
                class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
              >
                <div
                  *ngIf="
                    (hasActivatePermissions | permissionChecker) &&
                    row?.status === 'ACTIVE'
                  "
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  (click)="deactivateUser($event, row?.userId)"
                >
                  <button>
                    {{ "General.Buttons.Deactivate" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  *ngIf="
                    (hasActivatePermissions | permissionChecker) &&
                    row?.status !== 'ACTIVE'
                  "
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  (click)="activateUser($event, row?.userId)"
                >
                  <button>
                    {{ "General.Buttons.Activate" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  *ngIf="hasAccessPermissions | permissionChecker"
                  (click)="viewUser($event, row?.userId)"
                >
                  <button>
                    {{ "General.Buttons.View" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  *ngIf="hasEditPermissions | permissionChecker"
                  (click)="openUpdateUserModal(row?.userId)"
                >
                  <button>
                    {{ "General.Buttons.Edit" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  *ngIf="hasReadPermission | permissionChecker"
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  (click)="LoginAs($event, row?.email)"
                >
                  <button>
                    {{ "General.Buttons.LoginAs" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: visibleColumns"
          @fadeInUp
          class="hover:bg-hover trans-ease-out cursor-pointer"
          mat-row
          (click)="viewUser($event, row?.userId)"
        ></tr>
      </table>
      <div
        *ngIf="dataSource.filteredData.length === 0"
        @scaleFadeIn
        class="flex-auto flex flex-col items-center justify-center"
      >
        <img src="assets/img/resources/empty-state.svg" alt="not found" />
        <h2 class="headline m-0 text-center text-primary">
          {{ "General.EmptyTableList" | translate }}...
        </h2>
      </div>

      <mat-paginator
        [pageSizeOptions]="pageSizeOptions"
        [length]="pagination.length"
        [pageSize]="pagination.pageSize"
        [pageIndex]="pagination.pageIndex"
        (page)="handlePageEvent($event)"
        showFirstLastButtons
        class="sticky left-0"
      ></mat-paginator>
    </div>
  </app-state-section>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-icon
      class="text-[#757575] me-1"
      *ngIf="!column.visible"
      svgIcon="mat:check_box_outline_blank"
    ></mat-icon>
    <mat-icon
      *ngIf="column.visible"
      class="text-primary me-1"
      svgIcon="mat:check_box"
    ></mat-icon>
    {{ "ManageUsers.Table." + column.label | translate }}
  </button>
</mat-menu>

<ng-template #userQuickViewTemplate>
  <h1>Tool Tip Pop Over</h1>
</ng-template>

<ng-template #userCreateUpdateModal>
  <app-state-section
    [flexibleHeight]="true"
    [state]="sectionStateModal"
    [loadingLabel]="
      (formMode === 'create'
        ? 'User.AddingLoadingUser'
        : 'User.UpdatingLoadingUser'
      ) | translate
    "
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <form [formGroup]="userForm">
      <div class="flex items-center" mat-dialog-title>
        <h2
          *ngIf="
            userForm.get('firstName').value || userForm.get('lastName').value
          "
          class="headline m-0 flex-auto"
        >
          {{
            userForm.get("firstName").value +
              " " +
              userForm.get("lastName").value
          }}
        </h2>
        <h2
          *ngIf="
            !userForm.get('firstName').value && !userForm.get('lastName').value
          "
          class="headline m-0 flex-auto"
        >
          {{ "User.AddNewUser" | translate }}
        </h2>
        <button
          class="text-secondary"
          mat-dialog-close
          mat-icon-button
          type="button"
        >
          <mat-icon svgIcon="mat:close"></mat-icon>
        </button>
      </div>

      <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

      <mat-dialog-content class="flex flex-wrap justify-between">
        <div class="field flex column">
          <mat-label class="field-label"
            >{{ "User.Form.FirstName" | translate }}
            <span
              [ngClass]="
                userForm.invalid &&
                userForm.get('firstName').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="firstName"
              (keydown.space)="$event.preventDefault()"
            />
            <mat-error
              *ngIf="
                userForm.invalid &&
                userForm.get('firstName').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
            <mat-error
              *ngIf="
                userForm.invalid &&
                userForm.get('firstName').hasError('whitespace')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field flex column">
          <mat-label class="field-label">{{
            "User.Form.LastName" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="lastName"
            />
            <mat-error
              *ngIf="
                userForm.invalid &&
                userForm.get('lastName').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
            <mat-error
              *ngIf="
                userForm.invalid &&
                userForm.get('lastName').hasError('whitespace')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="field flex column">
          <mat-label class="field-label">{{
            "User.Form.Phone" | translate
          }}</mat-label>
          <mat-form-field class="input-field relative" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="phoneNumber"
            />
            <p
              *ngIf="
                userForm.controls['phoneNumber']?.errors &&
                userForm.controls['phoneNumber']?.touched
              "
              class="absolute top-full text-red-500"
            >
              {{ "Errors.InvalidPhone" | translate }}
            </p>
          </mat-form-field>
        </div>
        <div class="field flex column">
          <mat-label class="field-label"
            >{{ "General.Inputs.Email" | translate }}
            <span
              [ngClass]="
                userForm.invalid && userForm.get('email').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              matInput
              type="email"
              [placeholder]="'Placeholder.EnterEmail' | translate"
              formControlName="email"
              autocomplete="email"
              [readonly]="formMode !== 'create'"
            />
            <mat-error
              *ngIf="
                userForm.invalid && userForm.get('email').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
            <mat-error
              *ngIf="
                userForm.invalid &&
                userForm.get('email').hasError('invalidEmail')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InvalidEmail" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Roles -->
        <div class="field flex column">
          <mat-label class="field-label"
            >{{ "User.Form.Role" | translate }}
            <span
              [ngClass]="
                userForm.invalid && userForm.get('roles').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              ngxMatSelect
              #msf1="ngxMatSelect"
              [displayMember]="'roleName'"
              [valueMember]="'roleId'"
              [source]="filteredRoles"
              [useInfiniteScroll]="true"
              [hasPermission]="hasCreateRolesPermission | permissionChecker"
              [title]="'roles'"
              [placeholder]="'Placeholder.Select' | translate"
              formControlName="roles"
              [manageRoute]="'/user-management/roles'"
              multiple
            >
              <mat-option
                *ngFor="let role of msf1.filteredSource"
                [value]="role.roleId"
                >{{ role.roleName }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                userForm.invalid && userForm.get('roles').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Is External -->
        <div class="field flex column align-middle justify-center">
          <mat-checkbox
            matInput
            formControlName="isExternal"
            (change)="onExternalUserSelect($event)"
          >
            {{ "User.Form.IsExternal" | translate }}
          </mat-checkbox>
          <mat-checkbox matInput formControlName="isGuest">
            {{ "User.Form.IsGuest" | translate }}
          </mat-checkbox>
        </div>
        <!-- Department -->
        <div
          class="field flex column"
          *ngIf="!userForm.get('isExternal').value"
        >
          <mat-label class="field-label"
            >{{ "User.Form.Department" | translate }}
            <span
              [ngClass]="
                userForm.invalid &&
                userForm.get('departments').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              ngxMatSelect
              #msf2="ngxMatSelect"
              [displayMember]="'departmentName'"
              [valueMember]="'departmentId'"
              [source]="departments"
              [useInfiniteScroll]="true"
              [hasPermission]="
                hasCreateDepartmentPermission | permissionChecker
              "
              [title]="'departments'"
              [placeholder]="'Placeholder.SelectDepartment' | translate"
              [manageRoute]="'/company-setup/departments'"
              multiple
              formControlName="departments"
            >
              <!-- <mat-option
                *ngIf="departments.length !== 0"
                (click)="selectedvalue($event)"
                [value]="'ALL'"
              >
                All
              </mat-option> -->
              <mat-option
                (click)="selectAll($event)"
                *ngFor="let dep of msf2.filteredSource"
                [value]="dep.departmentId"
              >
                {{ dep.departmentName }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                userForm.invalid &&
                userForm.get('departments').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <!-- Report To Users -->
        <div
          class="field flex column"
          *ngIf="!userForm.get('isExternal').value"
        >
          <mat-label class="field-label">{{
            "User.Form.ReportTo" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              ngxMatSelect
              #msf3="ngxMatSelect"
              [displayMember]="'fullName'"
              [valueMember]="'userId'"
              [source]="reportUsers"
              [useInfiniteScroll]="true"
              formControlName="reportTo"
              [hasPermission]="hasCreateUserPermission | permissionChecker"
              [title]="'users'"
              [manageRoute]="'/user-management/users'"
              [multiple]="false"
              [placeholder]="'Placeholder.Select' | translate"
            >
              <mat-option
                [value]="item.userId"
                *ngFor="let item of msf3.filteredSource"
              >
                {{ item.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!-- Id Number -->
        <div
          class="field flex column"
          *ngIf="!userForm.get('isExternal').value"
        >
          <mat-label class="field-label">{{
            "ManageUsers.Table.IdNumber" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="idNumber"
            />
          </mat-form-field>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <div class="flex items-center">
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="closeDialog()"
          ></app-basic-button>
          <app-basic-button
            [btnType]="formMode === 'create' ? 'Add' : 'Add'"
            [btnText]="formMode === 'create' ? 'Add' : 'Save'"
            [isDisabled]="!userForm.valid"
            (onClick)="formMode === 'create' ? createUser() : updateUser()"
          ></app-basic-button>
        </div>
      </mat-dialog-actions>
    </form>
  </app-state-section>
</ng-template>
