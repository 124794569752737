import { Injectable } from '@angular/core';
import { BaseApi } from '../api/base-api';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class VersionChecker {
  constructor(private baseApi: BaseApi, private http: HttpClient) {}
  private currentHash = '';

  public initVersionCheck(url: any, frequency = 1000 * 600 * 3 * 1) {
    setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }

  checkVersion(url) {
    this.http.get(url + '?nocache=' + new Date().getTime()).subscribe(
      (response: any) => {
        this.currentHash = localStorage.getItem('currentVersion');
        const hash = response.hash;

        if (!this.currentHash) {
          localStorage.setItem('currentVersion', hash);
          location.reload();
        }
        const hashChanged = this.hasHashChanged(this.currentHash, String(hash));

        // If new version, do something
        if (hashChanged) {
          localStorage.setItem('currentVersion', hash);
          location.reload();
        }
        // store the new hash so we wouldn't trigger versionChange again
        // only necessary in case you did not force refresh
      },
      (err: any) => {
        console.error(err, 'Could not get version');
      }
    );
  }

  private hasHashChanged(currentHash: string, newHash: string) {
    return currentHash.toString().trim() !== newHash.toString().trim();
  }
}
