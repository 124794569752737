import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { PermissionService } from 'src/backend/services/permission.service';
import { TaskTrackerService } from 'src/backend/services/task-tracker/task-tracker.service';
import { AddInspectionPlanComponent } from '../add-inspection-plan/add-inspection-plan.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { BaseApi } from 'src/backend/api/base-api';

@Component({
  selector: 'app-audit-plan-calendar',
  templateUrl: './audit-plan-calendar.component.html',
  styleUrls: ['./audit-plan-calendar.component.scss'],
})
export class AuditPlanCalendarComponent implements OnInit {
  public today = new Date();
  public selectedDay = new Date();
  public daysInMonth: any;
  public datesList: any;
  public monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  public selectedDayNumber: number;
  public selectedMonth: string;
  public selectedMonthNumber: number;
  public selectedYear: number;
  public selectedDate: string;
  loggedUser: LoggedUser;

  public tasks: any = [];
  public tasksOfDay: any = [];

  hasApprovePermissions = ApplicationPermission.INSPECTION_PLAN_APPROVE;
  hasCreatePermission = ApplicationPermission.INSPECTION_PLAN_CREATE;
  constructor(
    private taskTrackerService: TaskTrackerService,
    private permissionService: PermissionService,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private dialog: MatDialog,
    private baseApi: BaseApi
  ) {}

  ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.selectedYear = this.today.getFullYear();
    this.selectedDayNumber = this.today.getDate();
    this.selectedDate = this.today.toISOString().split('T')[0];
    this.getListOfDaysByMonth(this.getMonth(this.selectedDay));
    this.getTasks();
  }

  onDayClick(date, dayNumber) {
    this.selectedDate = date;
    this.selectedDayNumber = dayNumber;
  }

  getDayName(date: string) {
    const dayName = new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
    });
    return dayName;
  }

  getMonth(date: Date) {
    return this.monthNames[date.getMonth()];
  }

  getListOfDaysByMonth(month: string) {
    this.datesList = [];
    this.selectedMonth = month;
    this.selectedMonthNumber = this.monthNames.indexOf(this.selectedMonth);
    this.daysInMonth = new Date(
      this.selectedYear,
      this.selectedMonthNumber + 1,
      0
    ).getDate();
    if (this.selectedDayNumber > this.daysInMonth) {
      this.selectedDayNumber = this.daysInMonth;
    }

    this.selectedDate = `${this.selectedYear}-${(this.selectedMonthNumber + 1)
      .toString()
      .padStart(2, '0')}-${this.selectedDayNumber.toString().padStart(2, '0')}`;

    for (let i = 1; i <= this.daysInMonth; i++) {
      let currentDate = new Date(
        this.selectedYear,
        this.selectedMonthNumber,
        i
      );
      const offsetMs = currentDate.getTimezoneOffset() * 60 * 1000;
      const msLocal = currentDate.getTime() - offsetMs;
      const dateLocal = new Date(msLocal);
      const iso = dateLocal.toISOString();
      const isoLocal = iso.slice(0, 10);
      this.datesList.push(isoLocal);
    }

    return this.datesList;
  }

  getTasks() {
    const startDate = new Date(this.selectedYear, 0, 1);
    const endDate = new Date(this.selectedYear + 1, 0, 1);

    const data = {
      startDate: startDate,
      endDate: endDate,
    };
    this.loadingService.startLoading();
    this.taskTrackerService.GetTaskRecurrenceWithFilter(data).subscribe({
      next: (res) => {
        this.tasks = res;
        this.loadingService.stopLoading();
      },
      error: (error) => {
        this.loadingService.stopLoading();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getTasksByDay() {
    this.tasksOfDay = this.tasks.filter(
      (item) =>
        item?.startTaskDate?.split('T')[0] <= this.selectedDate &&
        item?.taskDate?.split('T')[0] >= this.selectedDate
    );
    return this.tasksOfDay;
  }

  getNumberOfTasksByDay(day) {
    this.tasksOfDay = this.tasks.filter(
      (item) =>
        item?.startTaskDate?.split('T')[0] <= day &&
        item?.taskDate?.split('T')[0] >= day
    );
    return this.tasksOfDay;
  }

  getNumberOfTasksByMonth(month) {
    const tasks = this.tasks.filter((item) => {
      const date = new Date(item?.startTaskDate?.split('T')[0]);
      const m = date.getMonth();
      const y = date.getFullYear();
      return this.monthNames[m] === month && y === this.selectedYear;
    });
    return tasks.length;
  }

  nextYear() {
    this.selectedYear++;
    this.getListOfDaysByMonth(this.selectedMonth);
    this.getTasks();
  }

  previousYear() {
    this.selectedYear--;
    this.getListOfDaysByMonth(this.selectedMonth);
    this.getTasks();
  }

  nextMonth() {
    const currentMonthIndex = this.monthNames.indexOf(this.selectedMonth);
    this.selectedMonth = this.monthNames[currentMonthIndex + 1];
    this.selectedMonthNumber = currentMonthIndex + 1;
    this.getListOfDaysByMonth(this.selectedMonth);
  }

  previousMonth() {
    const currentMonthIndex = this.monthNames.indexOf(this.selectedMonth);
    this.selectedMonth = this.monthNames[currentMonthIndex - 1];
    this.selectedMonthNumber = currentMonthIndex - 1;
    this.getListOfDaysByMonth(this.selectedMonth);
  }

  checkCurrentDay(date) {
    if (this.selectedDate === date) {
      return true;
    } else {
      return false;
    }
  }

  getSelectedDateForAudit() {
    const date = moment(this.selectedDate);
    const formattedDate = date.format('Do [of] MMMM YYYY');
    return formattedDate;
  }

  createTask() {
    const data = {
      role: '',
      action: 'create',
    };
    this.dialog
      .open(AddInspectionPlanComponent, {
        height: 'auto',
        width: '30%',
        data: data,
      })
      .afterClosed()
      .subscribe((role: any) => {
        this.getTasks();
      });
  }

  updateTasks(event) {
    this.getTasks();
  }

  slideRight() {
    document.getElementById('months-container').scrollLeft += 800;
  }
  slideLeft() {
    document.getElementById('months-container').scrollLeft -= 800;
  }
}
