import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingService } from '../../helpers/loading.service';
import { LoadingStatus } from '../../models/shared.enum';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @ViewChild('loadingOverlayPage', { static: true }) loadingOverlayPage!: ElementRef;
  isHidden$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadingLabel: string = '';

  constructor(
    private renderer: Renderer2,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.loadingService.loadingStatus$.subscribe((status: LoadingStatus) => {
      this.loadingLabel = this.loadingService.loadingLabel;
      switch (status) {
        case LoadingStatus.Hidden:
          this.hide();
          break;

        case LoadingStatus.Visible:
          this.showNotTransparent();
          break;

        case LoadingStatus.VisibleTransparent:
          this.showTransparent();
          break;
      }
    });
  }

  showNotTransparent() {
    this.renderer.setStyle(
      this.loadingOverlayPage.nativeElement,
      'background',
      '#FFFFFF'
    );
    this.isHidden$.next(false);
  }

  showTransparent() {
    this.renderer.setStyle(
      this.loadingOverlayPage.nativeElement,
      'background',
      'rgba(151, 151, 151, 0.368)'
    );
    this.isHidden$.next(false);
  }

  hide() {
    this.isHidden$.next(true);
    this.renderer.addClass(this.loadingOverlayPage.nativeElement, 'hidden');
  }

}
