import { PermissionKeys, PermissionTypes } from 'src/@hodhod/common/enum';
import { Permission } from './permission';

export class ApplicationPermission {
  // organizations defined permissions
  public static ORGANIZATIONS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.Organizations,
    PermissionType: PermissionTypes.IsRead,
  };
  public static ORGANIZATIONS_VIEW: Permission = {
    PermissionKey: PermissionKeys.Organizations,
    PermissionType: PermissionTypes.IsRead,
  };
  public static ORGANIZATIONS_CREATE: Permission = {
    PermissionKey: PermissionKeys.Organizations,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static ORGANIZATIONS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Organizations,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static ORGANIZATIONS_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.Organizations,
    PermissionType: PermissionTypes.IsActivate,
  };

  public static ORGANIZATIONS_PERMISSION_FRAGMENT_ACCESS: Permission = {
    PermissionKey: PermissionKeys.OrganizationPermissions,
    PermissionType: PermissionTypes.IsRead,
  };
  public static ORGANIZATIONS_PERMISSION_FRAGMENT_UPDATE: Permission = {
    PermissionKey: PermissionKeys.OrganizationPermissions,
    PermissionType: PermissionTypes.IsWrite,
  };

  // companies defined permissions
  public static COMPANIES_ACCESS: Permission = {
    PermissionKey: PermissionKeys.Companies,
    PermissionType: PermissionTypes.IsRead,
  };

  public static COMPANIES_VIEW: Permission = {
    PermissionKey: PermissionKeys.Companies,
    PermissionType: PermissionTypes.IsRead,
  };
  public static COMPANIES_CREATE: Permission = {
    PermissionKey: PermissionKeys.Companies,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static COMPANIES_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Companies,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static COMPANIES_DELETE: Permission = {
    PermissionKey: PermissionKeys.Companies,
    PermissionType: PermissionTypes.IsDelete,
  };
  public static COMPANIES_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.Companies,
    PermissionType: PermissionTypes.IsActivate,
  };

  public static COMPANIES_PERMISSION_FRAGMENT_ACCESS: Permission = {
    PermissionKey: PermissionKeys.CompanyPermissions,
    PermissionType: PermissionTypes.IsRead,
  };
  public static COMPANIES_PERMISSION_FRAGMENT_UPDATE: Permission = {
    PermissionKey: PermissionKeys.CompanyPermissions,
    PermissionType: PermissionTypes.IsWrite,
  };

  // departments defined permissions
  public static DEPARTMENTS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.Departments,
    PermissionType: PermissionTypes.IsRead,
  };
  public static DEPARTMENTS_CREATE: Permission = {
    PermissionKey: PermissionKeys.Departments,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static DEPARTMENTS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Departments,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static DEPARTMENTS_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.Departments,
    PermissionType: PermissionTypes.IsActivate,
  };

  // machine and equipments defined permissions
  public static MACHINE_ACCESS: Permission = {
    PermissionKey: PermissionKeys.Machines,
    PermissionType: PermissionTypes.IsRead,
  };
  public static MACHINE_CREATE: Permission = {
    PermissionKey: PermissionKeys.Machines,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static MACHINE_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Machines,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static MACHINE_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.Machines,
    PermissionType: PermissionTypes.IsActivate,
  };
  public static EQUIPMENT_ACCESS: Permission = {
    PermissionKey: PermissionKeys.EquipmentCategories,
    PermissionType: PermissionTypes.IsRead,
  };
  public static EQUIPMENT_CREATE: Permission = {
    PermissionKey: PermissionKeys.EquipmentCategories,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static EQUIPMENT_UPDATE: Permission = {
    PermissionKey: PermissionKeys.EquipmentCategories,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static EQUIPMENT_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.EquipmentCategories,
    PermissionType: PermissionTypes.IsActivate,
  };
  public static PERFORM_INSPECTION: Permission = {
    PermissionKey: PermissionKeys.PerformInspections,
    PermissionType: PermissionTypes.IsRead,
  };
  public static REVIEW_INSPECTION: Permission = {
    PermissionKey: PermissionKeys.ReviewInspections,
    PermissionType: PermissionTypes.IsRead,
  };
  public static INSPECTION_MANAGEMENT: Permission = {
    PermissionKey: PermissionKeys.InspectionManagement,
    PermissionType: PermissionTypes.IsRead,
  };

  // role defined permissions
  public static ROLE_ACCESS: Permission = {
    PermissionKey: PermissionKeys.ManageRoles,
    PermissionType: PermissionTypes.IsRead,
  };
  public static ROLE_CREATE: Permission = {
    PermissionKey: PermissionKeys.ManageRoles,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static ROLE_UPDATE: Permission = {
    PermissionKey: PermissionKeys.ManageRoles,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static ROLE_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.ManageRoles,
    PermissionType: PermissionTypes.IsActivate,
  };

  // user defined permissions
  public static USER_ACCESS: Permission = {
    PermissionKey: PermissionKeys.ManageUsers,
    PermissionType: PermissionTypes.IsRead,
  };
  public static USER_CREATE: Permission = {
    PermissionKey: PermissionKeys.ManageUsers,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static USER_UPDATE: Permission = {
    PermissionKey: PermissionKeys.ManageUsers,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static USER_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.ManageUsers,
    PermissionType: PermissionTypes.IsActivate,
  };

  // security defined permissions
  public static SECURITY_MANAGE_CONTACTS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.EmergencyContacts,
    PermissionType: PermissionTypes.IsRead,
  };
  public static SECURITY_MANAGE_CONTACTS_CREATE: Permission = {
    PermissionKey: PermissionKeys.EmergencyContacts,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static SECURITY_MANAGE_CONTACTS_EDIT: Permission = {
    PermissionKey: PermissionKeys.EmergencyContacts,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static SECURITY_MANAGE_CONTACTS_DELETE: Permission = {
    PermissionKey: PermissionKeys.EmergencyContacts,
    PermissionType: PermissionTypes.IsDelete,
  };
  public static SECURITY_MANAGE_CONTACTS_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.EmergencyContacts,
    PermissionType: PermissionTypes.IsActivate,
  };

  public static SECURITY_MANAGE_IDCARDS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.ManageIdCard,
    PermissionType: PermissionTypes.IsRead,
  };
  public static SECURITY_MANAGE_IDCARDS_CREATE: Permission = {
    PermissionKey: PermissionKeys.ManageIdCard,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static SECURITY_MANAGE_IDCARDS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.ManageIdCard,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static SECURITY_MANAGE_IDCARDS_APPEOVE: Permission = {
    PermissionKey: PermissionKeys.ManageIdCard,
    PermissionType: PermissionTypes.IsApprove,
  };

  public static SECURITY_MANAGE_LOSTIDS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.LostIDCard,
    PermissionType: PermissionTypes.IsRead,
  };
  public static SECURITY_MANAGE_LOSTIDS_CREATE: Permission = {
    PermissionKey: PermissionKeys.LostIDCard,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static SECURITY_MANAGE_LOSTIDS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.LostIDCard,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static SECURITY_MANAGE_UPDATEIDCARDS_VIEW_ACCESS: Permission = {
    PermissionKey: PermissionKeys.RenewIDCard,
    PermissionType: PermissionTypes.IsRead,
  };
  public static SECURITY_MANAGE_RENEW_ACCESS: Permission = {
    PermissionKey: PermissionKeys.RenewIDCard,
    PermissionType: PermissionTypes.IsRead,
  };
  public static SECURITY_MANAGE_RENEW_CREATE: Permission = {
    PermissionKey: PermissionKeys.RenewIDCard,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static SECURITY_MANAGE_RENEW_UPDATE: Permission = {
    PermissionKey: PermissionKeys.RenewIDCard,
    PermissionType: PermissionTypes.IsUpdate,
  };

  public static AUDIT_CHECKLIST_READ: Permission = {
    PermissionKey: PermissionKeys.AuditChecklists,
    PermissionType: PermissionTypes.IsRead,
  };
  public static AUDIT_CHECKLIST_WRITE: Permission = {
    PermissionKey: PermissionKeys.AuditChecklists,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static AUDIT_CHECKLIST_UPDATE: Permission = {
    PermissionKey: PermissionKeys.AuditChecklists,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static AUDIT_CHECKLIST_APPROVE: Permission = {
    PermissionKey: PermissionKeys.AuditChecklists,
    PermissionType: PermissionTypes.IsApprove,
  };
  public static AUDIT_CHECKLIST_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.AuditChecklists,
    PermissionType: PermissionTypes.IsActivate,
  };

  public static INSPECTION_CHECKLIST_READ: Permission = {
    PermissionKey: PermissionKeys.InspectionChecklists,
    PermissionType: PermissionTypes.IsRead,
  };
  public static INSPECTION_CHECKLIST_WRITE: Permission = {
    PermissionKey: PermissionKeys.InspectionChecklists,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static INSPECTION_CHECKLIST_UPDATE: Permission = {
    PermissionKey: PermissionKeys.InspectionChecklists,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static INSPECTION_CHECKLIST_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.InspectionChecklists,
    PermissionType: PermissionTypes.IsActivate,
  };

  //IOT DASHBOARDS
  public static IOT_DASHBOARD_ACCESS: Permission = {
    PermissionKey: PermissionKeys.IOTDashboards,
    PermissionType: PermissionTypes.IsRead,
  };
  public static IOT_DASHBOARD_WRITE: Permission = {
    PermissionKey: PermissionKeys.IOTDashboards,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static IOT_DASHBOARD_UPDATE: Permission = {
    PermissionKey: PermissionKeys.IOTDashboards,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static IOT_DASHBOARD_APPROVE: Permission = {
    PermissionKey: PermissionKeys.IOTDashboards,
    PermissionType: PermissionTypes.IsApprove,
  };
  public static IOT_DASHBOARD_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.IOTDashboards,
    PermissionType: PermissionTypes.IsActivate,
  };

  // REPORTING defined permissions
  public static REPORTING_ACCESS: Permission = {
    PermissionKey: PermissionKeys.Reportings,
    PermissionType: PermissionTypes.IsRead,
  };
  public static REPORTING_HAZARDS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.ReportHazard,
    PermissionType: PermissionTypes.IsRead,
  };
  public static REPORTING_HAZARDS_CREATE: Permission = {
    PermissionKey: PermissionKeys.ReportHazard,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static REPORTING_HAZARDS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.ReportHazard,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static REPORTING_HAZARDS_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.ReportHazard,
    PermissionType: PermissionTypes.IsActivate,
  };

  public static REPORTING_HAZARDS_DELETE: Permission = {
    PermissionKey: PermissionKeys.ReportHazard,
    PermissionType: PermissionTypes.IsDelete,
  };
  public static REPORTING_HAZARDS_SPECIAL: Permission = {
    PermissionKey: PermissionKeys.ReportHazard,
    PermissionType: PermissionTypes.IsApprove,
  };
  public static REPORTING_NEARMISS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.ReportNearMiss,
    PermissionType: PermissionTypes.IsRead,
  };
  public static REPORTING_NEARMISS_CREATE: Permission = {
    PermissionKey: PermissionKeys.ReportNearMiss,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static REPORTING_NEARMISS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.ReportNearMiss,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static REPORTING_NEARMISS_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.ReportNearMiss,
    PermissionType: PermissionTypes.IsActivate,
  };
  public static REPORTING_NEARMISS_APPROVE: Permission = {
    PermissionKey: PermissionKeys.ReportNearMiss,
    PermissionType: PermissionTypes.IsApprove,
  };

  public static REPORTING_NEARMISS_DELETE: Permission = {
    PermissionKey: PermissionKeys.ReportNearMiss,
    PermissionType: PermissionTypes.IsDelete,
  };
  public static REPORTING_INCIDENT_ACCESS: Permission = {
    PermissionKey: PermissionKeys.ReportIncident,
    PermissionType: PermissionTypes.IsRead,
  };
  public static REPORTING_INCIDENT_CREATE: Permission = {
    PermissionKey: PermissionKeys.ReportIncident,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static REPORTING_INCIDENT_UPDATE: Permission = {
    PermissionKey: PermissionKeys.ReportIncident,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static REPORTING_INCIDENT_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.ReportIncident,
    PermissionType: PermissionTypes.IsActivate,
  };
  public static REPORTING_INCIDENT_APPROVE: Permission = {
    PermissionKey: PermissionKeys.ReportIncident,
    PermissionType: PermissionTypes.IsApprove,
  };

  public static REPORTING_INCIDENT_DELETE: Permission = {
    PermissionKey: PermissionKeys.ReportIncident,
    PermissionType: PermissionTypes.IsDelete,
  };
  public static CONTENT_REPORTING_ACCESS: Permission = {
    PermissionKey: PermissionKeys.ContentReporting,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_INCIDENTCATEGORY_ACCESS: Permission = {
    PermissionKey: PermissionKeys.IncidentCategory,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_INCIDENTCATEGORY_CREATE: Permission = {
    PermissionKey: PermissionKeys.IncidentCategory,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static CONTENT_REPORTING_INCIDENTCATEGORY_UPDATE: Permission = {
    PermissionKey: PermissionKeys.IncidentCategory,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static CONTENT_REPORTING_INCIDENTCATEGORY_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.IncidentCategory,
    PermissionType: PermissionTypes.IsActivate,
  };
  public static CONTENT_REPORTING_INCIDENTCATEGORY_VIEW: Permission = {
    PermissionKey: PermissionKeys.IncidentCategory,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_NATUREINJURY_ACCESS: Permission = {
    PermissionKey: PermissionKeys.NatureInjury,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_NATUREINJURY_CREATE: Permission = {
    PermissionKey: PermissionKeys.NatureInjury,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static CONTENT_REPORTING_NATUREINJURY_UPDATE: Permission = {
    PermissionKey: PermissionKeys.NatureInjury,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static CONTENT_REPORTING_NATUREINJURY_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.NatureInjury,
    PermissionType: PermissionTypes.IsActivate,
  };

  public static CONTENT_REPORTING_NATUREINJURY_VIEW: Permission = {
    PermissionKey: PermissionKeys.NatureInjury,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_REASONS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.Reasons,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_REASONS_CREATE: Permission = {
    PermissionKey: PermissionKeys.Reasons,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static CONTENT_REPORTING_REASONS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Reasons,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static CONTENT_REPORTING_REASONS_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.Reasons,
    PermissionType: PermissionTypes.IsActivate,
  };
  public static CONTENT_REPORTING_REASONS_VIEW: Permission = {
    PermissionKey: PermissionKeys.Reasons,
    PermissionType: PermissionTypes.IsRead,
  };

  public static CONTENT_REPORTING_ROOTCAUSE_ACCESS: Permission = {
    PermissionKey: PermissionKeys.RootCause,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_ROOTCAUSE_CREATE: Permission = {
    PermissionKey: PermissionKeys.RootCause,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static CONTENT_REPORTING_ROOTCAUSE_UPDATE: Permission = {
    PermissionKey: PermissionKeys.RootCause,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static CONTENT_REPORTING_ROOTCAUSE_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.RootCause,
    PermissionType: PermissionTypes.IsActivate,
  };

  public static CONTENT_REPORTING_ROOTCAUSE_VIEW: Permission = {
    PermissionKey: PermissionKeys.RootCause,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_INCIDENTCLASSIFICATION_ACCESS: Permission = {
    PermissionKey: PermissionKeys.IncidentClassification,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_INCIDENTCLASSIFICATION_CREATE: Permission = {
    PermissionKey: PermissionKeys.IncidentClassification,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static CONTENT_REPORTING_INCIDENTCLASSIFICATION_UPDATE: Permission = {
    PermissionKey: PermissionKeys.IncidentClassification,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static CONTENT_REPORTING_INCIDENTCLASSIFICATION_ACTIVATE: Permission =
    {
      PermissionKey: PermissionKeys.IncidentClassification,
      PermissionType: PermissionTypes.IsActivate,
    };

  public static CONTENT_REPORTING_INCIDENTCLASSIFICATION_VIEW: Permission = {
    PermissionKey: PermissionKeys.IncidentClassification,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_LOSSMODE_ACCESS: Permission = {
    PermissionKey: PermissionKeys.LossModes,
    PermissionType: PermissionTypes.IsRead,
  };
  public static CONTENT_REPORTING_LOSSMODE_CREATE: Permission = {
    PermissionKey: PermissionKeys.LossModes,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static CONTENT_REPORTING_LOSSMODE_UPDATE: Permission = {
    PermissionKey: PermissionKeys.LossModes,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static CONTENT_REPORTING_LOSSMODE_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.LossModes,
    PermissionType: PermissionTypes.IsActivate,
  };

  public static CONTENT_REPORTING_LOSSMODE_VIEW: Permission = {
    PermissionKey: PermissionKeys.LossModes,
    PermissionType: PermissionTypes.IsRead,
  };

  // safety announcement defined permissions
  public static SAFETY_ANNOUNCEMENT_ACCESS: Permission = {
    PermissionKey: PermissionKeys.SafetyAnnouncement,
    PermissionType: PermissionTypes.IsRead,
  };
  public static SAFETY_ANNOUNCEMENT_CREATE: Permission = {
    PermissionKey: PermissionKeys.SafetyAnnouncement,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static SAFETY_ANNOUNCEMENT_UPDATE: Permission = {
    PermissionKey: PermissionKeys.SafetyAnnouncement,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static SAFETY_ANNOUNCEMENT_VIEW: Permission = {
    PermissionKey: PermissionKeys.SafetyAnnouncement,
    PermissionType: PermissionTypes.IsRead,
  };
  public static SAFETY_ANNOUNCEMENT_DELETE: Permission = {
    PermissionKey: PermissionKeys.SafetyAnnouncement,
    PermissionType: PermissionTypes.IsDelete,
  };
  //SAFETY_KPIS
  public static SAFETY_KPIS_MANAGEMENT_ACCESS: Permission = {
    PermissionKey: PermissionKeys.SafetyKPIsManagement,
    PermissionType: PermissionTypes.IsRead,
  };
  public static SAFETY_KPIS_MANAGEMENT_CREATE: Permission = {
    PermissionKey: PermissionKeys.SafetyKPIsManagement,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static SAFETY_KPIS_MANAGEMENT_UPDATE: Permission = {
    PermissionKey: PermissionKeys.SafetyKPIsManagement,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static SAFETY_KPIS_MANAGEMENT_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.SafetyKPIsManagement,
    PermissionType: PermissionTypes.IsActivate,
  };
  public static SAFETY_KPIS_DASHBOARD_ACCESS: Permission = {
    PermissionKey: PermissionKeys.SafetyKPIsDashboard,
    PermissionType: PermissionTypes.IsRead,
  };
  public static SAFETY_KPIS_DASHBOARD_CREATE: Permission = {
    PermissionKey: PermissionKeys.SafetyKPIsDashboard,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static SAFETY_KPIS_DASHBOARD_UPDATE: Permission = {
    PermissionKey: PermissionKeys.SafetyKPIsDashboard,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static SAFETY_KPIS_DASHBOARD_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.SafetyKPIsDashboard,
    PermissionType: PermissionTypes.IsActivate,
  };

  // work permits defined permissions

  public static WORK_PERMIT_MASTER_ACCESS: Permission = {
    PermissionKey: PermissionKeys.WorkPermitMaster,
    PermissionType: PermissionTypes.IsRead,
  };
  public static WORK_PERMIT_LIST_ACCESS: Permission = {
    PermissionKey: PermissionKeys.WorkPermits,
    PermissionType: PermissionTypes.IsRead,
  };
  public static WORK_PERMIT_LIST_CREATE: Permission = {
    PermissionKey: PermissionKeys.WorkPermits,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static WORK_PERMIT_LIST_EDIT: Permission = {
    PermissionKey: PermissionKeys.WorkPermits,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static WORK_PERMIT_LIST_APPROVE: Permission = {
    PermissionKey: PermissionKeys.WorkPermits,
    PermissionType: PermissionTypes.IsApprove,
  };

  public static WORK_PERMIT_LIST_DELETED: Permission = {
    PermissionKey: PermissionKeys.WorkPermits,
    PermissionType: PermissionTypes.IsDelete,
  };
  public static WORK_PERMIT_HAZARDS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.WorkPermitHazards,
    PermissionType: PermissionTypes.IsRead,
  };
  public static WORK_PERMIT_HAZARDS_CREATE: Permission = {
    PermissionKey: PermissionKeys.WorkPermitHazards,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static WORK_PERMIT_HAZARDS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.WorkPermitHazards,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static WORK_PERMIT_HAZARDS_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.WorkPermitHazards,
    PermissionType: PermissionTypes.IsActivate,
  };

  public static WORK__PERMIT_HAZARDS_VIEW: Permission = {
    PermissionKey: PermissionKeys.WorkPermitHazards,
    PermissionType: PermissionTypes.IsRead,
  };
  public static WORK_CONDITIONS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.WorkConditionChecklists,
    PermissionType: PermissionTypes.IsRead,
  };
  public static WORK_CONDITIONS_CREATE: Permission = {
    PermissionKey: PermissionKeys.WorkConditionChecklists,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static WORK_CONDITIONS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.WorkConditionChecklists,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static WORK_CONDITIONS_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.WorkConditionChecklists,
    PermissionType: PermissionTypes.IsActivate,
  };

  public static WORK_CONDITIONS_VIEW: Permission = {
    PermissionKey: PermissionKeys.WorkConditionChecklists,
    PermissionType: PermissionTypes.IsRead,
  };
  public static WORK_PERMIT_WORK_CONDITIONS: Permission = {
    PermissionKey: PermissionKeys.WorkPermitWorkConditions,
    PermissionType: PermissionTypes.IsRead,
  };
  public static WORK_PERMIT_WORK_CONDITIONS_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.WorkPermitWorkConditions,
    PermissionType: PermissionTypes.IsActivate,
  };
  public static WORK_PERMIT_WORK_CONDITIONS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.WorkPermitWorkConditions,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static WORK_PERMIT_WORK_CONDITIONS_CREATE: Permission = {
    PermissionKey: PermissionKeys.WorkPermitWorkConditions,
    PermissionType: PermissionTypes.IsWrite,
  };

  // value types
  public static VALUE_TYPES_VIEW: Permission = {
    PermissionKey: PermissionKeys.ValueTypes,
    PermissionType: PermissionTypes.IsRead,
  };
  public static VALUE_TYPES_ACCESS: Permission = {
    PermissionKey: PermissionKeys.ValueTypes,
    PermissionType: PermissionTypes.IsRead,
  };
  public static VALUE_TYPES_CREATE: Permission = {
    PermissionKey: PermissionKeys.ValueTypes,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static VALUE_TYPES_UPDATE: Permission = {
    PermissionKey: PermissionKeys.ValueTypes,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static VALUE_TYPES_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.ValueTypes,
    PermissionType: PermissionTypes.IsActivate,
  };

  // unit of measures
  public static UNIT_OF_MEASURES_VIEW: Permission = {
    PermissionKey: PermissionKeys.UnitOfMeasures,
    PermissionType: PermissionTypes.IsRead,
  };
  public static UNIT_OF_MEASURES_ACCESS: Permission = {
    PermissionKey: PermissionKeys.UnitOfMeasures,
    PermissionType: PermissionTypes.IsRead,
  };
  public static UNIT_OF_MEASURES_CREATE: Permission = {
    PermissionKey: PermissionKeys.UnitOfMeasures,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static UNIT_OF_MEASURES_UPDATE: Permission = {
    PermissionKey: PermissionKeys.UnitOfMeasures,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static UNIT_OF_MEASURES_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.UnitOfMeasures,
    PermissionType: PermissionTypes.IsActivate,
  };

  // PPE
  public static PPE_VIEW: Permission = {
    PermissionKey: PermissionKeys.Ppe,
    PermissionType: PermissionTypes.IsRead,
  };
  public static PPE_CREATE: Permission = {
    PermissionKey: PermissionKeys.Ppe,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static PPE_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Ppe,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static PPE_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.Ppe,
    PermissionType: PermissionTypes.IsActivate,
  };
  public static PPE_DELETE: Permission = {
    PermissionKey: PermissionKeys.Ppe,
    PermissionType: PermissionTypes.IsDelete,
  };

  // Shifts
  public static SHIFT_VIEW: Permission = {
    PermissionKey: PermissionKeys.Shift,
    PermissionType: PermissionTypes.IsRead,
  };
  public static SHIFT_CREATE: Permission = {
    PermissionKey: PermissionKeys.Shift,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static SHIFT_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Shift,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static SHIFT_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.Shift,
    PermissionType: PermissionTypes.IsActivate,
  };
  // Areas
  public static AREA_VIEW: Permission = {
    PermissionKey: PermissionKeys.Area,
    PermissionType: PermissionTypes.IsRead,
  };
  public static AREA_CREATE: Permission = {
    PermissionKey: PermissionKeys.Area,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static AREA_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Area,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static AREA_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.Area,
    PermissionType: PermissionTypes.IsActivate,
  };
  // Teams
  public static TEAM_VIEW: Permission = {
    PermissionKey: PermissionKeys.Team,
    PermissionType: PermissionTypes.IsRead,
  };
  public static TEAM_CREATE: Permission = {
    PermissionKey: PermissionKeys.Team,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static TEAM_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Team,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static TEAM_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.Team,
    PermissionType: PermissionTypes.IsActivate,
  };

  // inspection plan
  public static INSPECTION_PLAN_VIEW: Permission = {
    PermissionKey: PermissionKeys.CreateAudit,
    PermissionType: PermissionTypes.IsRead,
  };
  public static INSPECTION_PLAN_ACCESS: Permission = {
    PermissionKey: PermissionKeys.CreateAudit,
    PermissionType: PermissionTypes.IsRead,
  };
  public static INSPECTION_PLAN_CREATE: Permission = {
    PermissionKey: PermissionKeys.CreateAudit,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static INSPECTION_PLAN_UPDATE: Permission = {
    PermissionKey: PermissionKeys.CreateAudit,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static INSPECTION_PLAN_APPROVE: Permission = {
    PermissionKey: PermissionKeys.CreateAudit,
    PermissionType: PermissionTypes.IsApprove,
  };

  public static INSPECTION_PLAN_DELETE: Permission = {
    PermissionKey: PermissionKeys.CreateAudit,
    PermissionType: PermissionTypes.IsDelete,
  };
  // action tracker
  public static ACTION_TRACKER_VIEW: Permission = {
    PermissionKey: PermissionKeys.ActionsTracker,
    PermissionType: PermissionTypes.IsRead,
  };
  public static ACTION_TRACKER_CREATE: Permission = {
    PermissionKey: PermissionKeys.ActionsTracker,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static ACTION_TRACKER_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Actions,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static ACTION_TRACKER_APPROVE: Permission = {
    PermissionKey: PermissionKeys.ActionsTracker,
    PermissionType: PermissionTypes.IsApprove,
  };
  public static ACTION_TRACKER_DELETE: Permission = {
    PermissionKey: PermissionKeys.ActionsTracker,
    PermissionType: PermissionTypes.IsDelete,
  };

  // action
  // public static ACTION_VIEW: Permission = {
  //   PermissionKey: PermissionKeys.Actions,
  //   PermissionType: PermissionTypes.IsRead,
  // };
  // public static ACTION_ACCESS: Permission = {
  //   PermissionKey: PermissionKeys.Actions,
  //   PermissionType: PermissionTypes.IsRead,
  // };
  // public static ACTION_CREATE: Permission = {
  //   PermissionKey: PermissionKeys.Actions,
  //   PermissionType: PermissionTypes.IsWrite,
  // };
  // public static ACTION_UPDATE: Permission = {
  //   PermissionKey: PermissionKeys.Actions,
  //   PermissionType: PermissionTypes.IsUpdate,
  // };
  // public static ACTION_APPROVE: Permission = {
  //   PermissionKey: PermissionKeys.Actions,
  //   PermissionType: PermissionTypes.IsApprove,
  // };

  // video analytics
  public static VIDEO_ANALYTICS_VIEW: Permission = {
    PermissionKey: PermissionKeys.VideoAnalytics,
    PermissionType: PermissionTypes.IsRead,
  };
  public static VIDEO_ANALYTICS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.VideoAnalytics,
    PermissionType: PermissionTypes.IsRead,
  };
  public static VIDEO_ANALYTICS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.VideoAnalytics,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static VIDEO_ANALYTICS_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.VideoAnalytics,
    PermissionType: PermissionTypes.IsActivate,
  };

  // task tracker
  public static TASK_TRACKER_VIEW: Permission = {
    PermissionKey: PermissionKeys.YourAudits,
    PermissionType: PermissionTypes.IsRead,
  };
  public static TASK_TRACKER_ACCESS: Permission = {
    PermissionKey: PermissionKeys.YourAudits,
    PermissionType: PermissionTypes.IsRead,
  };
  public static TASK_TRACKER_CREATE: Permission = {
    PermissionKey: PermissionKeys.YourAudits,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static TASK_TRACKER_UPDATE: Permission = {
    PermissionKey: PermissionKeys.YourAudits,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static TASK_TRACKER_APPROVE: Permission = {
    PermissionKey: PermissionKeys.YourAudits,
    PermissionType: PermissionTypes.IsApprove,
  };

  // violations
  public static VIOLATIONS_VIEW: Permission = {
    PermissionKey: PermissionKeys.Violations,
    PermissionType: PermissionTypes.IsRead,
  };
  public static VIOLATIONS_DELTE: Permission = {
    PermissionKey: PermissionKeys.Violations,
    PermissionType: PermissionTypes.IsDelete,
  };
  public static VIOLATIONS_ACCESS: Permission = {
    PermissionKey: PermissionKeys.Violations,
    PermissionType: PermissionTypes.IsRead,
  };
  public static VIOLATIONS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Violations,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static VIOLATIONS_APPROVE: Permission = {
    PermissionKey: PermissionKeys.Violations,
    PermissionType: PermissionTypes.IsApprove,
  };
  // video analytics dashboard
  public static VIDEO_DASHBOARD_VIEW: Permission = {
    PermissionKey: PermissionKeys.VideoAnalyticsDashboard,
    PermissionType: PermissionTypes.IsRead,
  };

  // reporting dashboard
  public static REPORTING_DASHBOARD_VIEW: Permission = {
    PermissionKey: PermissionKeys.ReportingDashboard,
    PermissionType: PermissionTypes.IsRead,
  };
  //Dashboard
  public static DASHBOARD_VIEW: Permission = {
    PermissionKey: PermissionKeys.Dashboard,
    PermissionType: PermissionTypes.IsRead,
  };
  // dashboard target
  public static DASHBOARD_TARGET_VIEW: Permission = {
    PermissionKey: PermissionKeys.DashboardTaragets,
    PermissionType: PermissionTypes.IsRead,
  };

  public static DASHBOARD_TARGET_CREATE: Permission = {
    PermissionKey: PermissionKeys.DashboardTaragets,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static DASHBOARD_TARGET_UPDATE: Permission = {
    PermissionKey: PermissionKeys.DashboardTaragets,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static DASHBOARD_TARGET_DELETE: Permission = {
    PermissionKey: PermissionKeys.DashboardTaragets,
    PermissionType: PermissionTypes.IsDelete,
  };
  public static DASHBOARD_TARGET_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.DashboardTaragets,
    PermissionType: PermissionTypes.IsActivate,
  };

  // MSDS Permissions
  public static MSDS_VIEW: Permission = {
    PermissionKey: PermissionKeys.Msds,
    PermissionType: PermissionTypes.IsRead,
  };
  public static MSDS_CREATE: Permission = {
    PermissionKey: PermissionKeys.Msds,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static MSDS_UPDATE: Permission = {
    PermissionKey: PermissionKeys.Msds,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static MSDS_DELETE: Permission = {
    PermissionKey: PermissionKeys.Msds,
    PermissionType: PermissionTypes.IsDelete,
  };
  public static MSDS_APPROVE: Permission = {
    PermissionKey: PermissionKeys.Msds,
    PermissionType: PermissionTypes.IsApprove,
  };
  public static MSDS_REVIEWER_ACCESS: Permission = {
    PermissionKey: PermissionKeys.Msds,
    PermissionType: PermissionTypes.IsViewAll,
  };
  public static AUDITCHECK_DELETE: Permission = {
    PermissionKey: PermissionKeys.AuditChecklists,
    PermissionType: PermissionTypes.IsDelete,
  };

  // penalty management defined permissions
  public static PENALTY_MANAGEMENT_VIEW: Permission = {
    PermissionKey: PermissionKeys.PenaltyManagement,
    PermissionType: PermissionTypes.IsRead,
  };
  public static PENALTY_MANAGEMENT_CREATE: Permission = {
    PermissionKey: PermissionKeys.PenaltyManagement,
    PermissionType: PermissionTypes.IsWrite,
  };
  public static PENALTY_MANAGEMENT_UPDATE: Permission = {
    PermissionKey: PermissionKeys.PenaltyManagement,
    PermissionType: PermissionTypes.IsUpdate,
  };
  public static PENALTY_MANAGEMENT_ACTIVATE: Permission = {
    PermissionKey: PermissionKeys.PenaltyManagement,
    PermissionType: PermissionTypes.IsActivate,
  };
  public static PENALTY_MANAGEMENT_DEACTIVATE: Permission = {
    PermissionKey: PermissionKeys.PenaltyManagement,
    PermissionType: PermissionTypes.IsDeactivate,
  };

  // Manage Access Control
  public static ACCESS_CONTROL_VIEW: Permission = {
    PermissionKey: PermissionKeys.MobileAccessControl,
    PermissionType: PermissionTypes.IsRead,
  };
  public static ACCESS_CONTROL_CREATE: Permission = {
    PermissionKey: PermissionKeys.MobileAccessControl,
    PermissionType: PermissionTypes.IsWrite,
  };
}
