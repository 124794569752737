<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">
    {{ "Dashboards.DateRange" | translate }}
  </h2>
  <button
    class="text-secondary"
    (click)="closeDialog()"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>
<div>
  <form [formGroup]="searchForm" content>
    <div class="flex items-center gap-3">
      <div class="flex column flex-grow">
        <mat-label class="field-label">{{
          "Task.StartDate" | translate
        }}</mat-label>
        <mat-form-field appearance="outline">
          <input
            [placeholder]="'Placeholder.SelectDate' | translate"
            matInput
            [matDatepicker]="startDate"
            [(ngModel)]="data.fromDate"
            (click)="startDate.open()"
            (dateChange)="onChangeStartDate()"
            formControlName="start"
            [max]="eDate"
          />
          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker
            panelClass="example-month-picker"
            #startDate
            [startView]="dashboardName === 'ReportedPenalty' ? 'multi-year' : 'month'"
            (monthSelected)="dashboardName === 'ReportedPenalty' ? setMonthAndYear($event, startDate) : null"
          ></mat-datepicker>
        </mat-form-field>
        
        <!-- <mat-form-field appearance="outline">
          <input
            [placeholder]="'Placeholder.SelectDate' | translate"
            matInput
            [matDatepicker]="startDate"
            [(ngModel)]="data.fromDate"
            (click)="startDate.open()"
            (dateChange)="onChangeStartDate()"
            formControlName="start"
            [max]="eDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDate"
          ></mat-datepicker-toggle>
          <mat-datepicker
            panelClass="example-month-picker"
            #startDate
            [startView]="
              dashboardName === 'ReportedPenalty' ? 'multi-year' : 'month'
            "
            (monthSelected)="
              dashboardName === 'ReportedPenalty'
                ? setMonthAndYear($event, startDate)
                : null
            "
          ></mat-datepicker>
        </mat-form-field> -->
      </div>
      <div class="flex column flex-grow">
        <mat-label class="field-label">{{
          "Task.EndDate" | translate
        }}</mat-label>
        <mat-form-field appearance="outline">
          <input
            [placeholder]="'Placeholder.SelectDate' | translate"
            matInput
            [(ngModel)]="data.toDate"
            [matDatepicker]="endDate"
            (click)="endDate.open()"
            (dateChange)="onChangeEndDate()"
            formControlName="end"
            [min]="sDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDate"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #endDate
            [startView]="
            dashboardName === 'ReportedPenalty' ? 'multi-year' : 'month'
          "
          (monthSelected)="
            dashboardName === 'ReportedPenalty'
              ? setMonthAndYearEnd($event, endDate)
              : null
          "

          ></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div class="flex justify-end items-center mt-4">
  <app-basic-button
    [btnType]="'Cancel'"
    [btnText]="'Cancel'"
    (onClick)="closeDialog()"
  ></app-basic-button>
  <app-basic-button
    [btnType]="'Add'"
    [btnText]="'Submit'"
    [isDisabled]="!isValidRange"
    [mat-dialog-close]="{ data }"
    cdkFocusInitial
  ></app-basic-button>
</div>
