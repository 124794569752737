<ng-container class="container">
  <mat-card
    class="dashboard-card border"
    [ngClass]="{
      'border-2': !fullScreenOpen,

      'border-[#D52A46]':
        (!fullScreenOpen &&
          dashboard.targetData?.targetValue &&
          dashboard.totalReported > dashboard.targetData?.targetValue &&
          dashboard.targetData?.targetUptoAtLeast == 'Upto') ||
        (dashboard.total > dashboard.targetData?.targetValue &&
          dashboard.targetData?.targetUptoAtLeast == 'Upto') ||
        (dashboard.avgScore > dashboard.targetData?.targetValue &&
          dashboard.targetData?.targetUptoAtLeast == 'Upto') ||
        (dashboard.completed > dashboard.targetData?.targetValue &&
          dashboard.targetData?.targetUptoAtLeast == 'Upto'),
      'border-[#35D310]':
        (!fullScreenOpen &&
          dashboard.targetData?.targetValue &&
          dashboard.totalReported > dashboard.targetData?.targetValue &&
          dashboard.targetData?.targetUptoAtLeast == 'At least') ||
        (dashboard.total > dashboard.targetData?.targetValue &&
          dashboard.targetData?.targetUptoAtLeast == 'At least') ||
        (dashboard.avgScore > dashboard.targetData?.targetValue &&
          dashboard.targetData?.targetUptoAtLeast == 'At least') ||
        (dashboard.completed > dashboard.targetData?.targetValue &&
          dashboard.targetData?.targetUptoAtLeast == 'At least')
    }"
  >
    <mat-card-header class="flex column">
      <!-- first row -->
      <div class="flex justify-space-between">
        <!-- card title -->
        <div class="header-text">
          <mat-card-title
            matTooltip="{{ 'Dashboards.' + dashboard.title | translate }}"
            *ngIf="
              dashboard.dashboardType != 'analog' &&
              dashboard.dashboardType != 'digital'
            "
          >
            {{ "Dashboards." + dashboard.title | translate }}
            {{
              chartLevel == "two"
                ? "Per Department"
                : chartLevel == "three"
                ? getLevelThreeTitle()
                : chartLevel != "one"
                ? chartLevel === "four"
                  ? getLevelFourTitle()
                  : "Per Type"
                : ""
            }}
          </mat-card-title>

          <mat-card-title
            matTooltip="{{
              dashboard.title +
                ' - ' +
                ('Dashboards.DigitalDashboards' | translate)
            }}"
            *ngIf="dashboard.dashboardType == 'digital'"
          >
            {{
              dashboard.title +
                " - " +
                ("Dashboards.DigitalDashboards" | translate)
            }}
          </mat-card-title>

          <mat-card-title
            *ngIf="dashboard.dashboardType == 'analog'"
            matTooltip="{{ dashboard.title }}"
            >{{ dashboard.title }}</mat-card-title
          >
        </div>

        <!-- card actions -->
        <mat-card-actions class="actions" *ngIf="!hideActions">
          <div>
            <mat-select
              class="filter-options"
              #SelectFilter
              (selectionChange)="onChangeCalenderDD($event.value)"
              (openedChange)="checkSelectIfOpen($event)"
              [value]="dashboard.filterLabel"
            >
              <mat-option
                *ngFor="let item of filterDashboards | enumToArray"
                [value]="item.value"
                (click)="
                  item.key === 'CustomRange' ? onSelectCustomRange() : ''
                "
              >
                {{ "Enum.DashboardFilters." + item.value | translate }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              *ngIf="!openCalender"
              (click)="SelectFilter.open(); openCalender = true"
            >
              <img
                id="filterDashboard"
                src="../../../../assets/img/resources/calendar-icon-not-active.svg"
                alt="calender"
              />
            </button>
            <button
              mat-button
              *ngIf="openCalender"
              (click)="openCalender = false"
              class="active-calender"
            >
              <img
                src="../../../../assets/img/resources/calendar-icon.svg"
                alt="active-calender"
              />
            </button>
          </div>

          <button
            (click)="openTargetDialog()"
            *ngIf="
              (hasUpdateDashboardTargetPermission | permissionChecker) &&
              dashboard.dashboardType != 'digital' &&
              dashboard.dashboardType != 'analog'
            "
            mat-button
          >
            <img
              id="targetDashboard"
              src="../../../../assets/img/resources/settings-icon.svg"
              alt="settings"
            />
          </button>

          <button
            *ngIf="
              dashboard.dashboardType != 'digital' &&
              dashboard.dashboardType != 'analog'
            "
            mat-button
            (click)="openFullScreen()"
          >
            <img
              id="fullScreenDashboard"
              src="../../../../assets/img/resources/fullscreen.svg"
              alt="full-screen"
            />
          </button>
        </mat-card-actions>
      </div>

      <!-- second row -->
      <div class="flex justify-space-between align-center mb-1">
        <!-- filter label -->
        <mat-card-subtitle class="subtitle"
          >{{
            dashboard.filterLabel == "customRange"
              ? "From " +
                formatDate(dashboard.startDate) +
                " To " +
                formatDate(dashboard.endDate)
              : ("Enum.DashboardFilters." + dashboard.filterLabel | translate)
          }}
          {{
            dashboard.dashboardType == "analog" &&
            (dashboard.filterLabel == "thisDay" ||
              dashboard.filterLabel == "yesterday")
              ? "(Per Hour)"
              : ""
          }}</mat-card-subtitle
        >

        <!-- target -->
        <div class="class flex center">
          <div class="total" *ngIf="dashboard.title !== 'ReportedIncidentLoss'">
            <span
              *ngIf="dashboard.dashboardType == 'Reporting'"
              class="title"
              [ngStyle]="{ color: dashboard.textColor }"
              >{{ "Dashboards.TotalReported" | translate }}</span
            >
            <p
              *ngIf="dashboard.totalReported != null"
              class="value"
              [ngStyle]="{ color: dashboard.textColor }"
            >
              {{
                chartLevel == "one"
                  ? dashboard.totalReported
                  : chartLevel == "two"
                  ? dashboard.totalReportedLevelTwo
                  : chartLevel == "three"
                  ? dashboard.totalReportedLevelThree
                  : dashboard.totalReportedLevelFour
              }}
            </p>

            <!-- total permit and plan -->
            <span
              *ngIf="dashboard.total || dashboard.total == 0"
              class="title"
              [ngStyle]="{ color: dashboard.textColor }"
              >{{ "Dashboards.TotalReport" | translate }}</span
            >
            <p
              *ngIf="dashboard.total || dashboard.total == 0"
              class="value"
              [ngStyle]="{ color: dashboard.textColor }"
            >
              {{
                chartLevel == "one"
                  ? dashboard.total
                  : chartLevel == "two"
                  ? dashboard.totalReportedLevelTwo
                  : dashboard.totalReportedLevelThree
              }}
            </p>
            <!-- inspection score -->
            <span
              *ngIf="dashboard.avgScore || dashboard.avgScore == 0"
              class="title"
              [ngStyle]="{ color: dashboard.textColor }"
              >{{ "Dashboards.AvgScore" | translate }}</span
            >
            <p
              *ngIf="dashboard.avgScore || dashboard.avgScore == 0"
              class="value"
              [ngStyle]="{ color: dashboard.textColor }"
            >
              {{ dashboard.avgScore }} %
            </p>

            <span
              *ngIf="dashboard.completed || dashboard.completed == 0"
              class="title"
              [ngStyle]="{ color: dashboard.textColor }"
              >{{ "Dashboards.Completed" | translate }}</span
            >
            <p
              *ngIf="dashboard.completed || dashboard.completed == 0"
              class="value"
              [ngStyle]="{ color: dashboard.textColor }"
            >
              {{ dashboard.completed }} %
            </p>
          </div>

          <div class="max" *ngIf="chartLevel == 'one'">
            <!-- max target -->
            <span
              *ngIf="
                dashboard?.targetData?.targetValue ||
                dashboard?.targetData?.targetValue == 0
              "
              class="title"
              >{{
                dashboard?.targetData?.targetUptoAtLeast == "Upto"
                  ? ("Dashboards.MaxTarget" | translate)
                  : ("Dashboards.MinTarget" | translate)
              }}
            </span>
            <p
              *ngIf="
                dashboard?.targetData?.targetValue ||
                dashboard?.targetData?.targetValue == 0
              "
              class="value"
            >
              {{ dashboard?.targetData?.targetValue }}
              <span
                *ngIf="
                  dashboard.title == 'InspectionScore' ||
                  dashboard.title == 'CheckList'
                "
                >%</span
              >
            </p>

            <!-- target -->
            <span
              *ngIf="
                (dashboard.title != 'WorkPermit' && dashboard.target) ||
                dashboard.target == 0
              "
              class="title"
              >{{ "Dashboards.Target" | translate }}
            </span>
            <p
              *ngIf="
                (dashboard.title != 'WorkPermit' && dashboard.target) ||
                dashboard.target == 0
              "
              class="value"
            >
              {{ dashboard.target }}
            </p>
          </div>
        </div>
      </div>
    </mat-card-header>
    <mat-divider></mat-divider>
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="loadingLabel | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <mat-card-content
        class="flex justify-center items-center"
        [ngClass]="dashboard.dashboardType == 'digital' ? 'digital-card' : ''"
      >
        <div
          class="flex-auto flex flex-col items-center justify-center"
          *ngIf="dashboard?.chartData?.part1?.length == 0; else showData"
        >
          <img
            style="width: 240px"
            src="assets/img/empty-dashboard.png"
            alt="not found"
          />
          <h6
            class="mt-2 text-center"
            style="
              color: #2b2c30;
              padding-top: 20px;
              opacity: 0.7;
              font-size: 0.9rem;
            "
          >
            {{
              dashboard.dashboardType != "digital" &&
              dashboard.dashboardType != "analog"
                ? ("Dashboard.EmptyDashboards." + dashboard.title | translate)
                : ("Dashboard.EmptyDashboards.IoT" | translate)
            }}
            <br />
            {{
              dashboard.dashboardType != "digital" &&
              dashboard.dashboardType != "analog" &&
              dashboard.title != "InspectionScore" &&
              dashboard.title != "ActionPlan" &&
              dashboard.title != "CheckList" &&
              dashboard.title != "WorkPermit"
                ? ("Dashboard.EmptyDashboards.Since" | translate) +
                  " " +
                  formatDate(dashboard.startDate)
                : ""
            }}
          </h6>
        </div>
        <ng-template #showData>
          <app-dashboard-chart
            *ngIf="
              showChart && dashboard?.dashboardType != 'digital';
              else digital
            "
            class="chart"
            [ngClass]="fullScreenOpen ? 'full-screen' : ''"
            [dashboard]="dashboard"
            (onChartClicked)="chartClicked($event)"
            [chartLevel]="chartLevel"
            [clickedPoint]="clickedPoint"
            [fullScreenOpen]="fullScreenOpen"
          ></app-dashboard-chart>
          <ng-template #digital>
            <app-digital-dashboard
              class="flex"
              [data]="dashboard.data"
              [style.height]="'100%'"
            ></app-digital-dashboard>
          </ng-template>
        </ng-template>
      </mat-card-content>
    </app-state-section>
  </mat-card>
</ng-container>
