<div>
  <div class="my-4 flex justify-between items-center">
    <div
      class="shadow-xl p-4 flex justify-between"
      *ngIf="data.isViewAll !== null"
    >
      <mat-slide-toggle
        color="primary"
        (change)="onSelectReviwerAccess($event)"
        [checked]="data.isViewAll"
        class="p-2 m-2"
        >{{ "Permissions.ReviewerAccess" | translate }}
      </mat-slide-toggle>
    </div>
  </div>
  <hodhod-scrollbar class="h-full relative">
    <div
      class="flex flex-col justify-between"
      *ngIf="dataSource.filteredData.length > 0"
    >
      <table
        @stagger
        [dataSource]="dataSource"
        class="w-full flex-auto"
        mat-table
        matSort
      >
        <!-- Model Properties Column -->
        <ng-container *ngFor="let column of columns">
          <ng-container
            *ngIf="column.type === 'text' && column.label !== 'Permissions'"
            [matColumnDef]="column.property"
          >
            <th *matHeaderCellDef mat-header-cell>
              {{ "Permissions." + column.label | translate }}
            </th>
            <td
              *matCellDef="let row"
              class="w-4/12 tooltip-list"
              [matTooltip]="getTooltipContent(row)
              "
              matTooltipClass="tooltip-list"
              [ngClass]="column.cssClasses"
              mat-cell
            >
              {{ "PermissionsRows." + row["rowName"] | translate }}
            </td>
          </ng-container>

          <ng-container
            *ngIf="column.type === 'checkbox'"
            [matColumnDef]="column.property"
          >
            <th *matHeaderCellDef mat-header-cell>
              {{ column.label }}
            </th>
            <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
              <!-- <mat-slide-toggle
              (change)="rowSelection($event, row?.menuId)"
              color="primary"
              [checked]="row[column.property]"
            ></mat-slide-toggle> -->
            </td>
          </ng-container>
          <ng-container
            *ngIf="column.type === 'text' && column.label == 'Permissions'"
            [matColumnDef]="column.property"
          >
            <th *matHeaderCellDef mat-header-cell>
              {{ "Permissions." + column.label | translate }}
            </th>
            <td
              *matCellDef="let row"
              [ngClass]="column.cssClasses"
              mat-cell
              class="w-8/12"
            >
              <div class="flex items-center">
                <div
                  class="field-full flex column border-gray-500 border m-2 w-1/2 p-2 rounded-lg"
                >
                  <mat-select
                    #select
                    ngxMatSelect
                    [hasSearchBox]="false"
                    #msf1="ngxMatSelect"
                    [displayMember]="'label'"
                    [value]="row.selectedOption ? row.selectedOption : '0'"
                    [source]="row?.options"
                    (selectionChange)="changeSelection($event, row)"
                    [useInfiniteScroll]="true"
                    [multiple]="false"
                    [manageRoute]=""
                    [disabled]="
                      RoleFlag
                        ? !(updateRolePermission | permissionChecker)
                        : !(updateUserPermission | permissionChecker)
                    "
                  >
                    <mat-option [value]="'0'">{{
                      "Permissions.None" | translate
                    }}</mat-option>
                    <mat-option
                      [value]="item"
                      *ngFor="let item of msf1.filteredSource"
                    >
                      {{ "Permissions." + item | translate }}
                    </mat-option>
                  </mat-select>
                </div>
                <div>
                  <img
                    src="/assets/img/resources/info-icon.png"
                    class="mx-2"
                    [matTooltip]="
                      (select.value
                        ? 'Permissions.toolTip.' + select.value
                        : null
                      ) | translate
                    "
                    [matTooltipPosition]="'right'"
                  />
                </div>
              </div>
            </td>
          </ng-container>
        </ng-container>

        <tr *matHeaderRowDef="visibleColumns; sticky: true" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: visibleColumns"
          @fadeInUp
          class="hover:bg-hover cursor-pointer"
          mat-row
        ></tr>
      </table>
      <div
        *ngIf="dataSource.filteredData.length === 0"
        @scaleFadeIn
        class="flex-auto flex flex-col items-center justify-center"
      >
        <svg
          class="m-5 h-64"
          width="752pt"
          height="752pt"
          version="1.1"
          viewBox="0 0 752 752"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="rgba(var(--color-primary), 1)">
            <path
              d="m213.21 427.8c-28.555 0-51.797 23.242-51.797 51.797v7.3984h14.801v-7.3984c0-20.402 16.598-37 37-37 20.402 0 37 16.598 37 37v7.3984h14.801v-7.3984c-0.003907-28.555-23.25-51.797-51.805-51.797z"
            />
            <path
              d="m472.2 302c0-12.238 9.9609-22.199 22.199-22.199 12.238 0 22.199 9.9609 22.199 22.199v19.09c34.766-9.7539 59.199-41.363 59.199-78.289 0-44.879-36.516-81.398-81.398-81.398-44.883 0.003906-81.398 36.52-81.398 81.402 0 36.926 24.434 68.535 59.199 78.289zm-16.969-57.027 16.969 16.965 68.766-68.766 10.465 10.465-79.23 79.227-27.43-27.43z"
            />
            <path
              d="m584.98 417.38-9.1914-2.3047v64.527h-14.801v-68.219l-14.801-3.6992 0.003906 71.918h-14.801v-75.617l-14.801-3.6992 0.003906 79.316h-14.801v-177.59c0-4.0859-3.3164-7.3984-7.3984-7.3984-4.0859 0-7.3984 3.3164-7.3984 7.3984v143.42l-0.003906 11.973v22.199h-14.801v-34.172c0.003907-13.793-11.23-25.027-25.023-25.027-2.5234 0-4.5742 2.0508-4.5742 4.5742 0 0.71094 0.16406 1.4141 0.48047 2.043l8.8438 17.699c3.582 7.1484 5.4766 15.164 5.4766 23.16v33.145c0 8.3672 2.8789 16.574 8.1094 23.109l17.648 22.066h81.68l17.648-22.059c5.2305-6.5352 8.1094-14.75 8.1094-23.109v-76.469c0-3.4023-2.3086-6.3555-5.6094-7.1836z"
            />
            <path
              d="m564.43 560.99h-80.879l-0.28906 0.4375c-5.8594 8.7969-9.4883 18.758-10.656 29.164h102.77c-1.168-10.402-4.7969-20.363-10.656-29.164z"
            />
            <path
              d="m257.61 501.8h-88.797c-4.0859 0-7.3984 3.3164-7.3984 7.3984v73.996c0 4.0859 3.3164 7.3984 7.3984 7.3984h88.797c4.0859 0 7.3984-3.3164 7.3984-7.3984l0.003906-73.996c0-4.082-3.3164-7.3984-7.4023-7.3984zm-36.996 49.746v24.25h-14.801v-24.25c-4.4023-2.5664-7.3984-7.2891-7.3984-12.75 0-8.1758 6.6211-14.801 14.801-14.801 8.1758 0 14.801 6.6211 14.801 14.801-0.003907 5.4609-3 10.184-7.4023 12.75z"
            />
            <path
              d="m235.56 250.21c-1.8633 4.582-3.9141 8.5977-5.9258 12.082 7.4883-2.5156 14.223-6.6758 19.758-12.082z"
            />
            <path
              d="m213.21 165.89c-1.8555 2.6797-3.9805 6.1406-6.0508 10.316h12.113c-2.082-4.1758-4.2031-7.6367-6.0625-10.316z"
            />
            <path
              d="m224.96 191.01h-23.508c-1.2578 4.5156-2.1992 9.4727-2.6797 14.801h28.867c-0.48047-5.3281-1.4219-10.285-2.6797-14.801z"
            />
            <path
              d="m219.26 250.21h-12.105c2.0703 4.1719 4.1953 7.6367 6.0586 10.324 1.8516-2.6875 3.9805-6.1523 6.0469-10.324z"
            />
            <path
              d="m224.96 235.41c1.2578-4.5156 2.1992-9.4727 2.6797-14.801l-28.875 0.003906c0.48047 5.3281 1.4219 10.285 2.6797 14.801z"
            />
            <path
              d="m242.53 205.81h21.887c-0.75391-5.2227-2.2773-10.195-4.4766-14.801h-19.699c1.0742 4.6094 1.8945 9.5312 2.2891 14.801z"
            />
            <path
              d="m240.24 235.41h19.699c2.1992-4.6016 3.7227-9.5742 4.4766-14.801h-21.887c-0.39453 5.2695-1.2148 10.191-2.2891 14.801z"
            />
            <path
              d="m249.39 176.21c-5.5273-5.4102-12.262-9.5664-19.758-12.082 2.0195 3.4805 4.0625 7.5 5.9336 12.082z"
            />
            <path
              d="m177.03 250.21c5.5273 5.4102 12.262 9.5664 19.758 12.082-2.0195-3.4844-4.0625-7.5039-5.9375-12.082z"
            />
            <path
              d="m186.18 235.41c-1.0742-4.6094-1.8945-9.5312-2.2852-14.801l-21.887 0.003906c0.75391 5.2227 2.2773 10.195 4.4766 14.801z"
            />
            <path
              d="m186.18 191.01h-19.695c-2.1992 4.6055-3.7227 9.5781-4.4766 14.801h21.887c0.39062-5.2695 1.2148-10.191 2.2852-14.801z"
            />
            <path
              d="m196.78 164.12c-7.4883 2.5156-14.223 6.6758-19.75 12.086h13.824c1.8711-4.582 3.9141-8.6016 5.9258-12.086z"
            />
            <path
              d="m235.41 390.8c0 12.262-9.9375 22.199-22.199 22.199-12.258 0-22.199-9.9375-22.199-22.199s9.9414-22.199 22.199-22.199c12.262 0 22.199 9.9375 22.199 22.199"
            />
            <path
              d="m249.83 354.18c-9.7734-9.7812-22.781-15.176-36.621-15.176-13.836 0-26.848 5.3945-36.621 15.176l10.465 10.465c6.9844-6.9883 16.277-10.844 26.156-10.844 9.8789 0 19.172 3.8555 26.156 10.84z"
            />
            <path
              d="m155.65 333.25 10.465 10.465c25.973-25.973 68.219-25.973 94.191 0l10.465-10.465c-31.746-31.738-83.383-31.738-115.12 0z"
            />
            <path
              d="m361.2 252.78c0-25.898-21.074-46.973-46.973-46.973h-19.625v14.801h19.625c17.742 0 32.172 14.426 32.172 32.172v130.62h-51.797v14.801h51.797v101.02c0 17.742-14.43 32.172-32.172 32.172h-19.625v14.801h19.625c25.898 0 46.973-21.074 46.973-46.973v-101.02h51.797v-14.801h-51.797z"
            />
          </g>
        </svg>
        <h2 class="headline m-0 text-center text-primary">
          {{ "General.EmptyTableList" | translate }}...
        </h2>
      </div>
    </div>
  </hodhod-scrollbar>
</div>
