<div class="flex justify-end mr-1 mb-4" *ngIf="data?.status === 'Unconfigured'">
  <app-basic-button [btnType]="'Add text-lg'" [btnText]="'Save'" [isDisabled]="false" (onClick)="editCamera(data?.id)">
  </app-basic-button>
</div>
<app-card [title]="'VideoAnalytics.View.Title' | translate">
  <div body class="flex justify-between flex-wrap-reverse" *ngIf="data">
    <div class="flex-1">
      <div class="py-3 flex items-center">
        <ngx-avatars bgColor="#2c3e5000" fgColor="#222222" class="logo box cursor-pointer mr-5" size="100"
          [src]="data?.picture ? data?.picture: ''" [name]="'Profile'" [borderColor]="'#d52a46'"
          [matTooltip]="'RenewIdCard.View.ViewImage' | translate" [textSizeRatio]="2" [initialsSize]="2"
          (clickOnAvatar)="openBigView()" [round]="true">
        </ngx-avatars>
        <div @fadeInRight>
          <p class="text-left font-bold text-lg text-primary mr-4 rtl:ml-3">{{data?.cameraName}}</p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
          <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{data?.cameraId}}</p>
          <p class="m-0 caption text-hint">{{'VideoAnalytics.View.CameraID' | translate}}</p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
          <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{data?.channelNo}}</p>
          <p class="m-0 caption text-hint">{{'VideoAnalytics.View.ChannelNo' | translate}}</p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
          <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{data?.departmentName}}</p>
          <p class="m-0 caption text-hint">{{'VideoAnalytics.View.Department' | translate}}</p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
          <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{data?.warehouse}}</p>
          <p class="m-0 caption text-hint">{{'VideoAnalytics.View.FilmProductionFloor' | translate}}</p>
        </div>
      </div>

    </div>
    <div class="flex-1">
      <div @fadeInUp class="flex items-center">
        <p class="text-left font-bold text-primary mr-4 rtl:ml-3">{{'General.Status' | translate}}</p>
        <app-boolean-view width="105px" [value]="data?.status === 'Configured'"
          [text]="data?.status === 'Configured' ? ('Enum.VideoAnalyticsStatus.Configured' | translate) : ('Enum.VideoAnalyticsStatus.Unconfigured' | translate)">
        </app-boolean-view>
      </div>
      <div class="py-3 flex items-center">
        <div @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
          <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{data?.cameraPosition}}</p>
          <p class="m-0 caption text-hint">{{'VideoAnalytics.View.CameraPosition' | translate}}</p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center">
          <mat-icon class="icon-sm" svgIcon="mat:keyboard_double_arrow_right"></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{data?.additionalApprovers}}</p>
          <p class="m-0 caption text-hint">{{'VideoAnalytics.View.AdditionalApprove' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div class="flex">
        <ngx-avatars bgColor="#2c3e5000" fgColor="#222222" class="logo box cursor-pointer" size="350"
          [src]="data?.picture ? data?.picture: ''" [name]="'Profile'" [cornerRadius]="10"
          [matTooltip]="'RenewIdCard.View.ViewImage' | translate" [textSizeRatio]="2" [initialsSize]="2"
          (clickOnAvatar)="openBigView()" [round]="false">
        </ngx-avatars>
      </div>
    </div>
  </div>

</app-card>
<!-- Image Viwer Model -->
<ng-template #imageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="selectedImg" class="avatar mr-5">
    <h2 class="headline m-0 flex-auto">{{data?.cameraName}}</h2>
    <button
    class="text-secondary"
    (click)="downloadDocument(selectedImg)"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:download"></mat-icon>
  </button>
    <button class="text-secondary" (click)="closeDialog()" mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex">
    <div class="image_wrapper">
      <img [src]="selectedImg" [alt]="data?.cameraName">
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button [btnType]="'Cancel'" [btnText]="'Cancel'" (onClick)="closeDialog()"></app-basic-button>
  </div>
</ng-template>