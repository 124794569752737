<app-state-section [flexibleHeight]="true" [state]="sectionState" [loadingLabel]="'User.LoadingUserInfo' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate">
  <div class="flex justify-between flex-wrap-reverse">
    <form [formGroup]="userForm" class="flex-1 pr-5">

      <div class="flex column">
        <mat-label class="field-label">{{'User.Table.Name' | translate}}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input [placeholder]="'Placeholder.AddText' | translate" matInput type="text" formControlName="Name" />
        </mat-form-field>
      </div>
      <div class="flex column">
        <mat-label class="field-label">{{'IdCardDetails.JobTitle' | translate}}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input [placeholder]="'Placeholder.AddText' | translate" matInput type="text" formControlName="JobTitle" />
        </mat-form-field>
      </div>
      <div class="flex column">
        <mat-label class="field-label">{{'User.Form.Phone' | translate}}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input [placeholder]="'Placeholder.AddText' | translate" matInput type="number"
            formControlName="phoneNumber" />
        </mat-form-field>
      </div>


      <div class="flex column">
        <mat-label class="field-label">{{'User.Form.Department' | translate}}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select [placeholder]="'Placeholder.SelectDepartment' | translate" formControlName="department"
            [disabled]="false">
            <mat-option [value]="'department 1'">Department 1</mat-option>
            <mat-option [value]="'department 2'">Department 2</mat-option>
            <mat-option [value]="'department 3'">Department 3</mat-option>
            <mat-option [value]="'department 4'">Department 4</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <div class="flex-1">

    </div>
  </div>
</app-state-section>