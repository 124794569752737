<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'BreadCrumb.SwitchAccount' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-card [title]="'SwitchAccount.Title' | translate">
      <div body class="flex column">
        <div class="field flex flex-wrap justify-between">
          <p class="text-lg font-bold my-3">{{'SwitchAccount.CurrentCompany' | translate}}</p>
          <div class="flex my-5 flex-wrap">
            <span
              class="text-md inline-block py-2 px-3 mx-1 mb-1 leading-none text-center whitespace-nowrap align-baseline font-bold bg-primary text-white rounded-full">Designer
              Art</span>
          </div>
        </div>
        <div class="flex flex-wrap justify-between">
          <div class='flex flex-col p-4 my-2  bg-primary/10 notification_card shadow-md hover:shodow-lg rounded-2xl'
            style="width: 30%;">
            <div class="flex items-center justify-between cursor-pointer">
              <div class="flex flex-col">
                <div class="flex items-center">
                  <div class="font-black leading-none ml-1 capitalize">Obeikan</div>
                </div>
              </div>
              <button type="button" class="flex-no-shrink px-2 ml-4 py-1 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider rounded-full 
              cursor-pointer">
                <mat-icon svgIcon="mat:login" [matTooltip]="'SwitchAccount.Login' | translate"></mat-icon>
              </button>
            </div>
          </div>
          <div class='flex flex-col p-4 my-2  bg-primary/10 notification_card shadow-md hover:shodow-lg rounded-2xl'
            style="width: 30%;">
            <div class="flex items-center justify-between cursor-pointer">
              <div class="flex flex-col">
                <div class="flex items-center">
                  <div class="font-black leading-none ml-1 capitalize">Designer Art</div>
                </div>
              </div>
              <button type="button" class="flex-no-shrink px-2 ml-4 py-1 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider rounded-full 
              cursor-pointer">
                <mat-icon svgIcon="mat:login" [matTooltip]="'SwitchAccount.Login' | translate"></mat-icon>
              </button>
            </div>
          </div>
          <div class='flex flex-col p-4 my-2  bg-primary/10 notification_card shadow-md hover:shodow-lg rounded-2xl'
            style="width: 30%;">
            <div class="flex items-center justify-between cursor-pointer">
              <div class="flex flex-col">
                <div class="flex items-center">
                  <div class="font-black leading-none ml-1 capitalize">NKU technology</div>
                </div>
              </div>
              <button type="button" class="flex-no-shrink px-2 ml-4 py-1 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider rounded-full 
              cursor-pointer">
                <mat-icon svgIcon="mat:login" [matTooltip]="'SwitchAccount.Login' | translate"></mat-icon>
              </button>
            </div>
          </div>
          <div class='flex flex-col p-4 my-2  bg-primary/10 notification_card shadow-md hover:shodow-lg rounded-2xl'
            style="width: 30%;">
            <div class="flex items-center justify-between cursor-pointer">
              <div class="flex flex-col">
                <div class="flex items-center">
                  <div class="font-black leading-none ml-1 capitalize">Emco Steel Mills</div>
                </div>
              </div>
              <button type="button" class="flex-no-shrink px-2 ml-4 py-1 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider rounded-full 
              cursor-pointer">
                <mat-icon svgIcon="mat:login" [matTooltip]="'SwitchAccount.Login' | translate"></mat-icon>
              </button>
            </div>
          </div>
          <div class='flex flex-col p-4 my-2  bg-primary/10 notification_card shadow-md hover:shodow-lg rounded-2xl'
            style="width: 30%;">
            <div class="flex items-center justify-between cursor-pointer">
              <div class="flex flex-col">
                <div class="flex items-center">
                  <div class="font-black leading-none ml-1 capitalize">Hod Hod Company</div>
                </div>
              </div>
              <button type="button" class="flex-no-shrink px-2 ml-4 py-1 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider rounded-full 
              cursor-pointer">
                <mat-icon svgIcon="mat:login" [matTooltip]="'SwitchAccount.Login' | translate"></mat-icon>
              </button>
            </div>
          </div>
          <div class='flex flex-col p-4 my-2  bg-primary/10 notification_card shadow-md hover:shodow-lg rounded-2xl'
            style="width: 30%;">
            <div class="flex items-center justify-between cursor-pointer">
              <div class="flex flex-col">
                <div class="flex items-center">
                  <div class="font-black leading-none ml-1 capitalize">New Steel Mill</div>
                </div>
              </div>
              <button type="button" class="flex-no-shrink px-2 ml-4 py-1 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider rounded-full 
              cursor-pointer">
                <mat-icon svgIcon="mat:login" [matTooltip]="'SwitchAccount.Login' | translate"></mat-icon>
              </button>
            </div>
          </div>

        </div>
      </div>
    </app-card>
  </hodhod-page-layout-content>
</hodhod-page-layout>