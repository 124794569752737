import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {

  @Input() btnBackgroundClass: string = 'bg-primary/10';
  @Input() btnTextClass: string = 'text-primary';
  @Input() buttonIcon: string = 'visibility';
  @Input() buttonToolTip: string = 'View';

  @Output() onClick: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  onClickEvent(): void {
    this.onClick.emit('clicked');
  }
}
