<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.dashboardTarget' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div body class="wrapper_1">
      <mat-tab-group
        class="dashboard-tabs"
        (selectedTabChange)="tabChanged($event)"
      >
        <mat-tab class="tab-btn" [label]="'Manual Entry' | translate">
          <app-manual-levels-form
            [safetyTriangleData]="safetyTriangleData"
          ></app-manual-levels-form>
        </mat-tab>
        <mat-tab
          class="tab-btn"
          *ngIf="foundReportingFeature"
          [label]="'Auto Calculated' | translate"
        >
          <app-auto-levels-form
          ></app-auto-levels-form>
        </mat-tab>
      </mat-tab-group>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
