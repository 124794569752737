import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { Action } from 'src/app/action-tracker/models/action';
import { ActionTracker } from 'src/app/action-tracker/models/action-tracker';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class ActionTrackerService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getActionTrackers(data: any): Observable<DataMapper<ActionTracker>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      data?.status.forEach((statusItem, index) => {
        if (params.length > 0) {
          params += '&';
        }
        params += `status${statusItem}=` + statusItem;
      });
    }
    if (data?.priority) {
      data?.priority.forEach((priorityItem, index) => {
        if (params.length > 0) {
          params += '&';
        }
        params += `priority${priorityItem}=` + priorityItem;
      });
    }
    if (data?.reportType) {
      data?.reportType.forEach((typeItem, index) => {
        if (params.length > 0) {
          params += '&';
        }
        params += `reportType${typeItem}=` + typeItem;
      });
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }

    return this.http.get<DataMapper<ActionTracker>>(
      this.baseApi.encodeURL(Constants.API_ACTIONS_TRACKER + '?' + params)
    );
  }
  getActions(data: any, sourceId: number): Observable<DataMapper<Action>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      data?.status.forEach((statusItem, index) => {
        if (params.length > 0) {
          params += '&';
        }
        params += `status${statusItem}=` + statusItem;
      });
    }
    if (data?.priority) {
      data?.priority.forEach((priorityItem, index) => {
        if (params.length > 0) {
          params += '&';
        }
        params += `priority${priorityItem}=` + priorityItem;
      });
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.sourceType) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sourceType=' + data?.sourceType;
    }

    return this.http.get<DataMapper<Action>>(
      this.baseApi.encodeURL(
        Constants.API_ACTIONS + '/' + sourceId + '?' + params
      )
    );
  }

  createAction(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ACTION_CREATE),
      data
    );
  }
  editAction(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_ACTION_EDIT),
      data
    );
  }
  reassignAction(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ACTION_REASSIGN),
      data
    );
  }
  removeAction(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_ACTION_REMOVE + '/' + id)
    );
  }
  closeAction(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ACTION_CLOSE),
      data
    );
  }

  getActionById(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ACTION_GET_ACTION_BY_ID),
      data
    );
  }
}
