<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="
    (formMode === 'create'
      ? 'Role.AddingLoadingRole'
      : 'Role.UpdatingLoadingRole'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="roleForm">
    <div class="flex items-center" mat-dialog-title>
      <h2 *ngIf="roleForm.get('typeName').value" class="headline m-0 flex-auto">
        {{ roleForm.get("typeName").value }}
      </h2>
      <h2
        *ngIf="!roleForm.get('typeName').value"
        class="headline m-0 flex-auto"
      >
        {{ "Role.TypeofReportsInfo" | translate }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex flex-col flex-wrap justify-between">
      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "Role.TypeofReportsName" | translate }}
          <span
            [ngClass]="
              roleForm.invalid && roleForm.get('typeName').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            [readonly]="this.formMode === 'view'"
            matInput
            type="text"
            formControlName="typeName"
          />
          <mat-error
            *ngIf="
              roleForm.invalid && roleForm.get('typeName').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              roleForm.invalid &&
              roleForm.get('typeName').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Reporing type Id -->
      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "Reason.ReportingType" | translate }}
          <span
            [ngClass]="
              roleForm.invalid &&
              roleForm.get('typeOfReport').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            [placeholder]="'Reason.ReportingType' | translate"
            formControlName="typeOfReport"
            [disabled]="this.formMode === 'view'"
            multiple
          >
            <!-- <mat-option
              *ngIf="reporting.length !== 0"
              (click)="selectedvalue($event)"
              [value]="'ALL'"
            >
              {{ "Enum.ReportingTypes.ALL" | translate }}
            </mat-option> -->
            <mat-option
              (click)="selectAll($event)"
              *ngFor="let r of reporting"
              [value]="r"
              >{{ "Enum.ReportingTypes." + r | translate }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              roleForm.invalid &&
              roleForm.get('typeOfReport').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <!-- History -->
    <div class="mt-4" *ngIf="this.formMode === 'view'">
      <h1>{{ "Reporting.TypeOfReportHistory" | translate }}</h1>
      <app-history-log
        body
        *ngIf="this.defaults.role"
        [sourceId]="this.defaults.role.id"
      ></app-history-log>
    </div>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isCreateMode()"
          [btnType]="'Add'"
          [btnText]="'Add'"
          [isDisabled]="!roleForm.valid"
          (onClick)="save()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isUpdateMode()"
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="!roleForm.valid"
          (onClick)="save()"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
