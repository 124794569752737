import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { merge } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { Constants } from 'src/@hodhod/common/constants';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, UntypedFormControl } from '@angular/forms';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SortDirection, TaskStatus } from 'src/@hodhod/common/enum';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, tap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';

import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { TaskList } from 'src/backend/models/task-tracker/task-tracker.model';
import { TaskTrackerService } from 'src/backend/services/task-tracker/task-tracker.service';
import { AddInspectionPlanComponent } from '../add-inspection-plan/add-inspection-plan.component';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { PermissionService } from 'src/backend/services/permission.service';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';

@UntilDestroy()
@Component({
  selector: 'app-task-tracker-list',
  templateUrl: './task-tracker-list.component.html',
  styleUrls: ['./task-tracker-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class TaskTrackerListComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('roleCreateUpdateModal') roleCreateUpdateModal!: TemplateRef<any>;
  roleForm: FormGroup;
  formMode: 'create' | 'update' = 'create';

  columns: TableColumn<TaskList>[] = [
    { label: 'Id', property: 'id', type: 'text', visible: false },
    {
      label: 'Title',
      property: 'title',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: false,
    },
    {
      label: 'RecurrenceType',
      property: 'recurrenceType',
      type: 'text',
      visible: true,
    },
    {
      label: 'ChecklistName',
      property: 'checkListName',
      type: 'text',
      visible: true,
    },
    {
      label: 'Department',
      property: 'departmentParentName',
      type: 'text',
      visible: true,
    },
    {
      label: 'SubDepartment',
      property: 'departmentName',
      type: 'text',
      visible: true,
    },
    {
      label: 'StartDate',
      property: 'startDate',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'DueDate',
      property: 'dueDate',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'CreatedOn',
      property: 'createdOn',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'AssignedTo',
      property: 'user',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Duration', property: 'duration', type: 'text', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<TaskList>([]);
  selection = new SelectionModel<TaskList>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';
  roleId: string = '';
  currentQueryParams = null;
  filteredParams = null;
  allDepartments: any = [];
  selectedDepartments: any = [];
  tasksList: any = [];

  SharedConstants = SharedConstants;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;

  private translationsList: any = {};
  public selectedStatus: string[] = [];

  public TasksStatus = TaskStatus;
  loggedUser: LoggedUser;
  hasCreatePermissions = ApplicationPermission.TASK_TRACKER_CREATE;
  hasApprovePermissions = ApplicationPermission.TASK_TRACKER_APPROVE;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private TaskTrackerService: TaskTrackerService,
    private feedBackService: AsyncFeedbackService,
    private baseApi: BaseApi,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private departmentService: DepartmentsService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  override ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.dataSource = new MatTableDataSource();
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    merge(this.pagination.pageIndex)
      .pipe(
        takeUntil(this.destroy$),
        tap((res: any) => {
          this.currentQueryParams = {
            ...this.currentQueryParams,
            pageIndex: res.pageIndex,
            pageSize: res.pageSize,
          };
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: this.currentQueryParams,
          });
          this.getData();
        })
      )
      .subscribe();

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => {
        this.currentQueryParams = {
          ...this.currentQueryParams,
          searchValue: value,
        };
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: this.currentQueryParams,
        });
        this.onFilterChange(value);
      });

    this.getData('startDate', 'desc');
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  getData(sortFld?, sortDir?) {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;

    if (this.sort.active && this.sort.direction) {
      sortField = this.sort.active;
    }
    this.selectedStatus = this.currentQueryParams?.statusFilter
      ? this.currentQueryParams?.statusFilter.split(',')
      : [];

    this.filteredParams = {
      sortDirection: sortDir || this.currentQueryParams?.sortDirection,
      sortField: sortFld || sortField,
      orgStatus: this.currentQueryParams?.statusFilter,
      pageIndex:
        this.currentQueryParams?.pageIndex === 0
          ? this.currentQueryParams?.pageIndex
          : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      departments: this.selectedDepartments.toString(),
    };

    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.TaskTrackerService.getTaskByUser(this.filteredParams).subscribe({
      next: (response) => {
        this.tasksList = response?.data.map((item: any) => {
          return {
            ...item,
            departmentParentName: item.departmentParentName
              ? item.departmentParentName
              : item.department,
            departmentName: item.departmentParentName
              ? item.departmentName
              : '',
          };
        });
        this.dataSource = new MatTableDataSource(this.tasksList);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.pagination.pageIndex = this.currentQueryParams?.pageIndex
          ? Number(this.currentQueryParams?.pageIndex)
          : 0;
        this.pagination.pageSize = this.currentQueryParams?.pageSize
          ? Number(this.currentQueryParams?.pageSize)
          : Constants.PAGE_SIZE;

        this.sectionState = SectionStateStatus.Ready;
        this.getAllDepartments();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    // event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;

    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  getAllDepartments() {
    this.departmentService.getDepartmentList().subscribe({
      next: (res) => {
        this.allDepartments = res.data;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  onSelectDepartments(event: MatSelectChange) {
    this.selectedDepartments = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      departments: event.value.toString(),
      pageIndex: 0,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  onSelectStatus(event: MatSelectChange) {
    this.selectedStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      statusFilter: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  openUpdateModal(event: any, Id: any): void {
    const data = {
      role: '',
      action: 'update',
      Id: Id,
    };
    let dialogRef = this.dialog
      .open(AddInspectionPlanComponent, {
        height: 'auto',
        width: '90%',
        data: data,
      })
      .afterClosed()
      .subscribe((role: any) => {
        this.getData();
      });
  }
  openCreateModal(): void {
    const data = {
      role: '',
      action: 'create',
    };
    this.dialog
      .open(AddInspectionPlanComponent, {
        height: 'auto',
        width: '90%',
        data: data,
      })
      .afterClosed()
      .subscribe((role: any) => {
        this.getData();
      });
  }

  resetForm(): void {
    this.roleForm.get('roleName')?.setValue('');
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  viewTaskTracker(id: number): void {
    this.router.navigate(
      [
        '/' +
          SharedConstants.TASK_TRACKER +
          '/' +
          SharedConstants.TASK_TRACKER_List +
          '/' +
          SharedConstants.View_TASK_TRACKER +
          '/' +
          id,
      ],
      {
        queryParams: this.currentQueryParams,
      }
    );
  }

  executeCheckList(id: number): void {
    this.router.navigate([
      '/' +
        SharedConstants.TASK_TRACKER +
        '/' +
        SharedConstants.TASK_TRACKER_List +
        '/' +
        SharedConstants.EXECUTE_TASK_TRACKER +
        '/' +
        id,
    ]);
  }

  getNumber(stringNumber) {
    return Number(stringNumber);
  }
}
