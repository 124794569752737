<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="'ManageIdCard.BasicInformation' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
 <div class="flex justify-between flex-wrap-reverse">
   <form [formGroup]="userForm" class="flex-1 pr-5">  
        <div class="flex column">
         <mat-label class="field-label">{{'ManageIdCard.Company' | translate}}</mat-label>
         <mat-form-field class="input-field" appearance="outline">
           <mat-select [placeholder]="'Placeholder.SelectCompany' | translate" formControlName="Company" [disabled]="false">
             <mat-option [value]="'test'">test</mat-option>
             <mat-option [value]="'test 2'">test 2</mat-option>
             <mat-option [value]="'test 3'">test 3</mat-option>
             <mat-option [value]="'test 4'">test 4</mat-option>
           </mat-select>
         </mat-form-field>
       </div>
    
    </form>
   <div class="flex-1">
    <div class="flex column">
      <mat-label class="field-label">{{'User.Form.Department' | translate}}</mat-label>
      <mat-form-field class="input-field" appearance="outline">
        <mat-select [placeholder]="'Placeholder.SelectDepartment' | translate" formControlName="department" [disabled]="false">
          <mat-option [value]="'department 1'">Department 1</mat-option>
          <mat-option [value]="'department 2'">Department 2</mat-option>
          <mat-option [value]="'department 3'">Department 3</mat-option>
          <mat-option [value]="'department 4'">Department 4</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
   </div>
 </div>

 <div class="flex-1">
  <div class="flex-row">
    <mat-label class="field-label">{{'ManageIdCard.Comment' | translate}}</mat-label>
    <mat-form-field class="input-field" appearance="outline">
      <textarea  matInput rows="5" [placeholder]="'Placeholder.Comment' | translate" matInput >
      </textarea>
    </mat-form-field>
  </div>
 </div>

</app-state-section>



