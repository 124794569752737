import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Feature, Features } from 'src/backend/models/features/feature';
import { CompanyService } from 'src/backend/services/company.service';
import { CompanyDetail } from '../../models/company-detail';

@Component({
  selector: 'app-view-company-page',
  templateUrl: './view-company-page.component.html',
  styleUrls: ['./view-company-page.component.scss'],
})
export class ViewCompanyPageComponent implements OnInit {
  public compInfo: CompanyDetail;
  public compLicenseInfo: any;
  // public features: Feature[] = [];
  public compCode: any = null;
  public selectedFeatures: Feature[] = [];

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  private destroy$ = new Subject();
  constructor(
    private route: ActivatedRoute,
    private compService: CompanyService,
    private feedBackService: AsyncFeedbackService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        this.compCode = params.get('id')   
        this.getCompInfo(); 
        // this.getCompCardInfo(); 
      }
    });

  }

  getCompInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.compService.getCompanyDetails(this.compCode).subscribe({
      next: (response) => {
        this.compInfo = response;       
        let security:any = response?.features?.find(
          (element) => element['module'] === '1'
        )
        let safety:any = response['features']?.find(
          (element) => element['module'] === '0'
        );
        if (safety?.features || security?.features) {
          this.selectedFeatures = [
            ...(safety?.features || []),
            ...(security?.features || []),
          ];         
        }
        security?.features?this.getCompCardInfo():null
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }
  getCompCardInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.compService.getCompCardDetails(this.compCode).subscribe({
      next: (response) => {
        this.compLicenseInfo=response['message']
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }
}
