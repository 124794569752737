import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-result',
  templateUrl: './show-result.component.html',
  styleUrls: ['./show-result.component.scss']
})
export class ShowResultComponent implements OnInit {
  @Input() iconColor: string = '';
  @Input() icon: string = '';
  @Input() title: string = '';
  @Input() details: string = '';
  @Input() iconHeight: number = 20;
  @Input() iconWidth: number = 20;
  constructor() { }

  ngOnInit() {
  }

}
