import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { IncidentReportService } from 'src/backend/services/reportings/incident-report.service';

@Component({
  selector: 'app-incident-report-edit',
  templateUrl: './incident-report-edit.component.html',
  styleUrls: ['./incident-report-edit.component.scss'],
})
export class IncidentReportEditComponent implements OnInit {
  public incidentReport: any;
  public incidentId: any;

  constructor(
    private incidentReportService: IncidentReportService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.incidentId = params['id'];
      this.getIncidentReport();
    });
  }

  getIncidentReport(): void {
    this.loadingService.startLoading(true);
    this.incidentReportService
      .getIncidentReportById(this.incidentId)
      .subscribe({
        next: (response) => {
          this.incidentReport = response;
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  updateIncidentReport(reportIncidentForm: any): void {
    const formData = new FormData();
    const {
      title,
      shift,
      attachments,
      typeOfIndcident,
      isAnyOneInjured,
      whathappend,
      incidentDate,
      departmentId,
      equipement,
      incidentTime,
      actionTaken,
      incidentClassification,
      incidentRisk,
      witness,
      rootCause,
      injuredPersons,
      subDepartment,
    } = reportIncidentForm;
    const data = {
      id: this.incidentId,
      title: title,
      typeOfIndcident: typeOfIndcident?.toString(),
      actionTaken: actionTaken,
      departmentCode: subDepartment ? subDepartment : departmentId,
      machineId: equipement,
      incidentClassificationId: incidentClassification,
      incidentDate: incidentDate,
      incidentRisk: incidentRisk,
      incidentTime: incidentTime,
      shift: shift,
      whathappend: whathappend,
      isAnyOneInjured: isAnyOneInjured,
      witness: witness ? witness.toString() : '',
      rootCauseId: rootCause,
      injuredPersons: injuredPersons?.map((ele) => {
        return {
          age: ele.age,
          startDate: ele.startDate,
          endDate: ele.endDate,
          gender: ele.gender,
          idNumber: ele.idNumber,
          isEmployed: ele.isEmployed,
          jobPosition: ele.jobPosition,
          name: ele.name,
          natureOfInjury: ele.natureOfInjury?.toString(),
          protectiveEquipment: ele.protectiveEquipment?.toString(),
          frontSkeletonParts: ele.frontSkeletonParts?.toString(),
          backSkeletonParts: ele.backSkeletonParts?.toString(),
        };
      }),
    };
    formData.append('content', JSON.stringify(data));
    attachments.forEach((element) => {
      formData.append('files', element);
    });
    this.loadingService.startLoading(true, '');
    this.incidentReportService.editIncidentReport(formData).subscribe({
      next: (response) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, response?.message)
        );
        this.loadingService.stopLoading();
        this.goBack();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_INCIDENT,
    ]);
  }
}
