import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { ReportHazard } from 'src/app/reporting/models/report-hazard';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class HazardReportService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  createHazardReport(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_HAZARDS_CREATE),
      data
    );
  }

  getHazardReports(data: any): Observable<DataMapper<ReportHazard>> {
    const params = new URLSearchParams();
    if (data?.pageIndex) {
      params.set('offset', data?.pageIndex.toString());
    }
    if (data?.pageSize) {
      params.set('limit', data?.pageSize.toString());
    }
    if (data?.status) {
      params.set('status', data?.status);
    }
    if (data?.sortDirection !== SortDirection.None) {
      params.set('sortDirection', data?.sortDirection);
    }
    if (data?.sortField) {
      params.set('sortField', data?.sortField);
    }
    if (data?.searchValue) {
      params.set('phrase', data?.searchValue);
    }
    if (data?.departments) {
      params.set('departments', data?.departments);
    }
    const encodedParams = params.toString();

    return this.http.get<DataMapper<ReportHazard>>(
      this.baseApi.encodeURL(Constants.API_REPORT_HAZARDS + '?' + encodedParams)
    );
  }
  getHazardReportById(id: number): Observable<ReportHazard> {
    return this.http.get<ReportHazard>(
      this.baseApi.encodeURL(Constants.API_REPORT_HAZARD_BY_ID + '/' + id)
    );
  }
  deleteHazardReportImage(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_HAZARD_REMOVE_IMG + '/' + id)
    );
  }

  editHazardReport(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_HAZARD_EDIT),
      data
    );
  }
  closeHazardReport(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_HAZARD_CLOSE + '/' + id)
    );
  }

  removeHazardReport(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_HAZARD_DELETE + '/' + id)
    );
  }

  getCustomUsersHierarchyFromHazard(id: number): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_HAZARD_CUSTOM_USERS_HEIRARCY + '/' + id
      )
    );
  }
}
