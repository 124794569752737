import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { VoilationsService } from 'src/backend/services/voilations/voilations.service';
import { VoilationInfo } from '../../models/voilation';

@Component({
  selector: 'app-view-voilation-page',
  templateUrl: './view-voilation-page.component.html',
  styleUrls: ['./view-voilation-page.component.scss']
})
export class ViewVoilationPageComponent implements OnInit {
  public voilationId: number = 0;
  private destroy$ = new Subject();
  voilationData: VoilationInfo;
  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private voilationService: VoilationsService,
    private feedBackService: AsyncFeedbackService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;   
        this.voilationId = parseInt(id, 10);
        this.getVoilationData();
      }
    });
  }

  getVoilationData(): void{
    this.loadingService.startLoading(true,  '');
    this.voilationService.getVoilationById(this.voilationId).subscribe({
      next: (response) => {
        this.voilationData = response;
        this.loadingService.stopLoading();
      },
      error: ({error}) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      }
    })
  }

}
