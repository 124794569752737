<div [ngClass]="fullScreenOpen ? 'full-screen-chart' : 'parent'">
  <div
    style="
      width: 100% !important;
      display: inline-flex;
      justify-content: center;
    "
  >
    <div
      [chart]="chart"
      [ngClass]="
        fullScreenOpen ? 'full-screen-chart-container' : 'chart-container'
      "
    ></div>
  </div>
</div>
