import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import Drilldown from 'highcharts/modules/drilldown';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
Exporting(Highcharts);
Drilldown(Highcharts);

@Component({
  selector: 'app-voilation-drilldown-column-chart',
  templateUrl: './voilation-drilldown-column-chart.component.html',
  styleUrls: ['./voilation-drilldown-column-chart.component.scss'],
})
export class VoilationDrilldownColumnChartComponent
  implements OnInit, OnChanges
{
  @Input() data: any = {};
  public chart: Chart;
  isCardExpanded = false;
  Exdpandhide = true;
  Collapsehide = false;
  private destroy$ = new Subject();
  private translationsList: any = {};
  @Output() onClickCalendar: EventEmitter<any> = new EventEmitter();
  @ViewChild('RangePicker') private RangePicker!: TemplateRef<any>;
  @ViewChild('dashboardCalendar', { static: false }) dashboardCalendar;
  calenderMenu = [
    {
      id: 2,
      name: 'ThisDay',
    },
    {
      id: 3,
      name: 'Yesterday',
    },
    {
      id: 4,
      name: 'CurrentMonth',
    },
    {
      id: 5,
      name: 'LastMonth',
    },
    {
      id: 6,
      name: 'Past30Days',
    },
    {
      id: 7,
      name: 'CustomRange',
    },
  ];
  searchForm = new FormGroup({
    start: new FormControl(
      new Date(new Date().setDate(new Date().getDate() - 7))
    ),
    end: new FormControl(new Date()),
    menu: new FormControl(),
  });
  constructor(
    private dialog: MatDialog,
    private asyncFeedbackService: AsyncFeedbackService,
    private translate: TranslateService
  ) {
    this.translate
      .get(['Errors', 'Success', 'VideoAnalyticsDashboard'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.chart = new Chart(this.data);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      this.chart = new Chart(this.data);
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onChangeCalenderDD(): void {
    const menuVal = this.searchForm.value.menu;
    if (menuVal === 'ThisDay') {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(1);
      this.searchForm.get('start')?.setValue(new Date(today));
      this.onClickCalendar.emit({
        type: 'THIS_DAY',
        startDate: this.searchForm.value.start,
        endDate: this.searchForm.value.end,
      });
    } else if (menuVal === 'Yesterday') {
      const today = new Date();
      const day = today.getDate() - 1;
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setDate(day);
      this.searchForm.get('start')?.setValue(new Date(today));
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setDate(day + 1);
      this.searchForm.get('end')?.setValue(new Date(today));
      this.onClickCalendar.emit({
        type: 'YESTERDAY',
        startDate: this.searchForm.value.start,
        endDate: this.searchForm.value.end,
      });
    } else if (menuVal === 'CurrentMonth') {
      const today = new Date();
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      this.searchForm.get('start')?.setValue(new Date(firstDay));
      this.onClickCalendar.emit({
        type: 'CURRENT_MONTH',
        startDate: this.searchForm.value.start,
        endDate: this.searchForm.value.end,
      });
    } else if (menuVal === 'LastMonth') {
      debugger;
      const today = new Date();
      const firstDayPrevMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      this.searchForm.get('start')?.setValue(new Date(firstDayPrevMonth));
      const lastDayPrevMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      this.searchForm.get('end')?.setValue(lastDayPrevMonth);
      this.onClickCalendar.emit({
        type: 'LAST_MONTH',
        startDate: this.searchForm.value.start,
        endDate: this.searchForm.value.end,
      });
    } else if (menuVal === 'Past30Days') {
      const today = new Date();
      this.searchForm.get('end')?.setValue(new Date(today));
      const Past30Day = new Date(today.setDate(today.getDate() - 30));
      this.searchForm.get('start')?.setValue(new Date(Past30Day));
      this.onClickCalendar.emit({
        type: 'LAST_30_DAYS',
        startDate: this.searchForm.value.start,
        endDate: this.searchForm.value.end,
      });
    } else if (menuVal === 'CustomRange') {
      this.searchForm.get('start')?.setValue(new Date());
      this.searchForm.get('end')?.setValue(new Date());
      this.dialog.open(this.RangePicker, {
        width: '40%',
        height: 'auto',
      });
    }
  }

  onChangeStartDate(): void {
    const { start, end } = this.searchForm.value;
    if (new Date(start) > new Date(end)) {
      this.asyncFeedbackService.showWarningMessage(
        this.translationsList['VideoAnalyticsDashboard']['StartDateShouldBe']
      );
      this.searchForm.get('start')?.setValue(new Date(end));
      return;
    }
    if (new Date(end) < new Date(start)) {
      this.asyncFeedbackService.showWarningMessage(
        this.translationsList['VideoAnalyticsDashboard']['EndDateShouldBe']
      );
      this.searchForm.get('end')?.setValue(new Date(start));
      return;
    }

    this.onClickCalendar.emit({
      type: 'CUSTOM_RANGE',
      startDate: this.searchForm.value.start,
      endDate: this.searchForm.value.end,
    });
    this.dialog.closeAll();
  }
}
