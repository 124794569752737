import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { BaseApi } from 'src/backend/api/base-api';
import { AuthService } from 'src/backend/services/auth.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  SharedConstants = SharedConstants;
  constructor(private authService: AuthService,   private router: Router, private baseApi: BaseApi,) { }

  ngOnInit(): void {
    
    var reloading = sessionStorage.getItem("reloading");
    if (reloading) {
        sessionStorage.removeItem("reloading");
      this.navigateToInitialMenu();
    }
  }

  goBack(): void {
    this.authService.navigateToInitialMenu();
  }
  public navigateToInitialMenu(): void {
    const userMenus = this.baseApi.getUserMenusSession();
    const first_level = userMenus[0]?.children[0];
    if (first_level?.type === "dropdown") {
      this.router.navigate([first_level?.children[0]?.route]);
    }else if (first_level?.type === 'link') {
      this.router.navigate([first_level?.route]);
    }
  }
}
