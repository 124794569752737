<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="
    (formMode === 'create'
      ? 'WorkConditions.AddingLoadingWorkConditions'
      : 'WorkConditions.UpdatingLoadingWorkConditions'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="workConditionForm">
    <div class="flex items-center" mat-dialog-title>
      <h2
        *ngIf="workConditionForm.get('workConditionName').value"
        class="headline m-0 flex-auto"
      >
        {{ workConditionForm.get("workConditionName").value }}
      </h2>
      <h2
        *ngIf="!workConditionForm.get('workConditionName').value"
        class="headline m-0 flex-auto"
      >
        {{ "WorkConditions.WorkConditionInfo" | translate }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>
    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex flex-col flex-wrap justify-between">
      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "WorkConditions.WorkConditionName" | translate }}
          <span
            [ngClass]="
              workConditionForm.invalid &&
              workConditionForm.get('workConditionName').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            formControlName="workConditionName"
            [readonly]="this.formMode === 'view'"
          />
          <mat-error
            *ngIf="
              workConditionForm.invalid &&
              workConditionForm.get('workConditionName').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "WorkConditions.SelectHazard" | translate }}
          <span
            [ngClass]="
              workConditionForm.invalid &&
              workConditionForm.get('hazard').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf2="ngxMatSelect"
            [displayMember]="'name'"
            [valueMember]="'id'"
            [source]="hazards"
            [useInfiniteScroll]="true"
            [hasPermission]="hasCreateHazardsPermission | permissionChecker"
            [title]="'hazards'"
            [placeholder]="'WorkConditions.SelectHazard' | translate"
            formControlName="hazard"
            [manageRoute]="'/work-permit/work-permit-hazards'"
            [disabled]="this.formMode === 'view'"
            multiple
          >
            <mat-option
              *ngIf="hazards?.length !== 0"
              (click)="selectedhazard($event)"
              [value]="0"
              >All
            </mat-option>
            <mat-option
              (click)="selectAllhazard($event)"
              *ngFor="let h of msf2.filteredSource"
              [value]="h.id"
            >
              {{ h.name | translate }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              workConditionForm.invalid &&
              workConditionForm.get('hazard').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "WorkConditions.SelectChecklist" | translate }}
          <span
            [ngClass]="
              workConditionForm.invalid &&
              workConditionForm.get('checklist').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf1="ngxMatSelect"
            [displayMember]="'name'"
            [valueMember]="'id'"
            [source]="checklists"
            [useInfiniteScroll]="true"
            [hasPermission]="
              hasCreateWorkConditionCheckListPermission | permissionChecker
            "
            [title]="'checklists'"
            [placeholder]="'WorkConditions.SelectChecklist' | translate"
            formControlName="checklist"
            [manageRoute]="'/safety/checklist-section'"
            [disabled]="this.formMode === 'view'"
            multiple
          >
            <!-- <mat-option
              *ngIf="checklists?.length !== 0"
              (click)="selectedChecklist($event)"
              [value]="0"
            >
              {{ "Enum.WorkConditions.ALL" | translate }}
            </mat-option> -->
            <mat-option
              (click)="selectAllChecklist($event)"
              *ngFor="let w of msf1.filteredSource"
              [value]="w.id"
            >
              {{ w.name }}
            </mat-option>
          </mat-select>

          <mat-error
            *ngIf="
              workConditionForm.invalid &&
              workConditionForm.get('checklist').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <!-- History -->
    <div class="mt-4" *ngIf="this.formMode === 'view'">
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'WorkConditions.WorkConditionHistory' | translate"
      >
        <app-history-log
          body
          *ngIf="this.defaults.workCondition"
          [sourceId]="this.defaults.workCondition.id"
          [sourceType]="HistoryType"
        ></app-history-log>
      </app-expandable-panel>
    </div>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isCreateMode()"
          [btnType]="'Add'"
          [btnText]="'Add'"
          [isDisabled]="!workConditionForm.valid"
          (onClick)="save()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isUpdateMode()"
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="!workConditionForm.valid"
          (onClick)="save()"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
