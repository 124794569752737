import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { Organization } from 'src/app/organization-setup/models/organization';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'app-list-of-companies',
  templateUrl: './list-of-companies.component.html',
  styleUrls: ['./list-of-companies.component.scss']
})
export class ListOfCompaniesComponent implements OnInit {
  searchCtrl = new UntypedFormControl();
  public dataSource = new MatTableDataSource<Organization>([]);
  searchValue: string = '';
  columns: TableColumn<Organization>[] = [
    {
      label: 'Logo',
      property: 'Logo',
      type: 'image',
      visible: false,
    },
    {
      label: 'Name',
      property: 'Name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Code',
      property: 'Code',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'LicenseQuota',
      property: 'LicenseQuota',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'Active',
      property: 'Active',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'Available',
      property: 'Available',
      type: 'text',
      visible: true,
      cssClasses: ['font-bold'],
    },
    {
      label: 'SubscriptionDate',
      property: 'SubscriptionDate',
      type: 'text',
      visible: true,
    },
    {
      label: 'Updatedat',
      property: 'Updatedat',
      type: 'date',
      visible: true,
    },

    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';
  public pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  public pageSize = Constants.PAGE_SIZE;


  constructor() { }

  ngOnInit(): void {
    this.searchCtrl.valueChanges
    .pipe(untilDestroyed(this))
    .subscribe((value: any) => this.onFilterChange(value));

  }
  onFilterChange(value: string) {
    // if (!this.dataSource) {
    //   return;
    // }
    // this.searchValue = value;
    // this.paginator.pageIndex = 0;
    // clearTimeout(this.timer);
    // this.timer = setTimeout(() => {
    //   this.getData();
    // }, 700);
  }
  
  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  viewCompany(event,id){

  }
  updateCompany(event,id){

  }

}
