import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import {
  scaleFadeIn400ms,
  scaleFadeInAnimation,
} from 'src/@hodhod/animations/scale-fade-in.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import {
  GeneralStatus,
  InspectionStatus,
  SortDirection,
} from 'src/@hodhod/common/enum';
import { MatSort } from '@angular/material/sort';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { Constants } from 'src/@hodhod/common/constants';
@UntilDestroy()
@Component({
  selector: 'app-out-of-order-equipments',
  templateUrl: './out-of-order-equipments.component.html',
  styleUrls: ['./out-of-order-equipments.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class OutOfOrderEquipmentsComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public translationsList: any = {};
  @Input() page!: string;

  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };

  status = GeneralStatus;
  InspectionStatus = InspectionStatus;
  searchValue: string = '';
  media: any;
  selectedImage: any;
  equipmentForm: FormGroup;
  timer = null;
  searchCtrl = new UntypedFormControl();
  columns: TableColumn<any>[] = [
    {
      label: 'Image',
      property: 'imageUrl',
      type: 'image',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Code',
      property: 'code',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Name',
      property: 'name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'InspectedBy',
      property: 'inspectedBy',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'InActiveFrom',
      property: 'inActiveFrom',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'ApprovedBy',
      property: 'approvedBy',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Departments',
      property: 'locationDepartmentName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Location',
      property: 'location',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },

    { label: 'Status', property: 'status', type: 'boolean', visible: true },
  ];
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  equipmentDetails: any;
  categoryDetails: any;
  dataSource = new MatTableDataSource<any>([]);
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';
  departments: any[];
  public selectedInspectionStatus: string[] = [];

  data: any;
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private machineService: MachineEquipmentService,
    private machineEquipmentService: MachineEquipmentService,
    private fb: FormBuilder,
    private departmentService: DepartmentsService,
    private feedBackService: AsyncFeedbackService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private loadingService: LoadingService
  ) {
    this.translate
      .get([
        'Errors',
        'Success',
        'Role',
        'confirmDeactiveRecord',
        'User',
        'General',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);
    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedInspectionStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();

    this.getData();
    this.getCompanyDepartments();
    this.equipmentForm = this.fb.group({
      equipmentName: ['', [Validators.required]],
      equipmentCode: ['', [Validators.required]],
      department: ['', [Validators.required]],
      location: ['', [Validators.required]],
      checklist: ['', [Validators.required]],
    });

    if (this.page === 'review') {
      this.columns.splice( (this.columns.length), 0, {
        label: 'Actions',
        property: 'actions',
        type: 'button',
        visible: true,
      });
    }
  }
  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }
  Reactivate(id) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.machineEquipmentService.ReactivateEquipment(id).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.categoryDetails = changes['CategoryData']?.currentValue;
  }
  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  getCompanyDepartments(): void {
    this.departmentService.getCompanyDepartments().subscribe({
      next: (response) => {
        this.departments = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  onSelectStatus(event) {
    this.selectedInspectionStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  getData() {
    let { id } = this.route.snapshot.params;
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      status: this.currentQueryParams?.orgStatus,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.getInspectionsList(filteredParams, id);
  }
  getInspectionsList(data, id) {
    let Arr: any[] = [];
    if (this.page === 'excute') {
      this.machineService.getCurrentInspections(data, id, true).subscribe({
        next: (response) => {
          response?.data.forEach((e) => {
            let obj = {};
            for (const i in e['equipment']) {
              obj[i] = e['equipment'][i];
              delete obj['id'];
            }
            for (const i in e) {
              obj[i] = e[i];
            }
            Arr.push(obj);
          });

          this.dataSource = new MatTableDataSource(Arr);
          this.pagination.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
    } else if (this.page === 'review') {
      this.machineService.getReviewerEquipments(data, id, true).subscribe({
        next: (response) => {
          response?.data.forEach((e) => {
            let obj = {};
            for (const i in e['equipment']) {
              obj[i] = e['equipment'][i];
              delete obj['id'];
            }
            for (const i in e) {
              obj[i] = e[i];
            }
            Arr.push(obj);
          });

          this.dataSource = new MatTableDataSource(Arr);
          this.pagination.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
    }
  }
  onFileSelected(event: any) {
    const files: FileList | null = event.target.files;
    if (files && files.length > 0) {
      this.media = files[0];
      const file: File = files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (this.equipmentDetails) {
          this.equipmentDetails.imageUrl = e.target.result;
        }
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
}
