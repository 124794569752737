import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrganizationSetupRoutingModule } from './organization-setup-routing.module';
import { OrganizationPageComponent } from './pages/organization-page/organization-page.component';
import { OrganizationViewPageComponent } from './pages/organization-view-page/organization-view-page.component';
import { OrganizationsComponent } from './components/organizations/organizations.component';
import { OrganizationDetailComponent } from './components/organization-detail/organization-detail.component';
import { SharedModule } from '../shared/shared.module';
import { OrganizationPermissionsComponent } from './components/organization-permissions/organization-permissions.component';
import { CreateOrganizationPageComponent } from './pages/create-organization-page/create-organization-page.component';
import { CreateOrganizationComponent } from './components/create-organization/create-organization.component';
import { OrganizationService } from 'src/backend/services/organization.service';
import { UpdateOrganizationPageComponent } from './pages/update-organization-page/update-organization-page.component';
import { UpdateOrganizationComponent } from './components/update-organization/update-organization.component';
import { OrganizationCompaniesComponent } from './components/organization-companies/organization-companies.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ChatbotUploadDocumentsComponent } from './pages/chatbot-upload-documents/chatbot-upload-documents.component';
import { TrustedSourcesListComponent } from './components/trusted-sources-list/trusted-sources-list.component';
import { AddSourcePopupComponent } from './components/add-source-popup/add-source-popup.component';
import { TrustedSourcesPageComponent } from './pages/trusted-sources-page/trusted-sources-page.component';
import { ViewTrustedSourceComponent } from './pages/view-trusted-source/view-trusted-source.component';
import { SecurityFeaturesComponent } from './components/security-features/security-features.component';
import { LicenseDataCardComponent } from './components/security-features/license-data-card/license-data-card.component';
import { SecurityFeaturesPageComponent } from './pages/security-features-page/security-features-page.component';
import { OrgDetailsPageComponent } from './pages/org-details-page/org-details-page.component';
import { CompanyDetailsComponent } from './pages/company-details/company-details.component';
import { ListOfCompaniesComponent } from './components/security-features/list-of-companies/list-of-companies.component';

@NgModule({
  declarations: [
    OrganizationPageComponent,
    OrganizationViewPageComponent,
    OrganizationsComponent,
    OrganizationDetailComponent,
    OrganizationPermissionsComponent,
    CreateOrganizationPageComponent,
    CreateOrganizationComponent,
    UpdateOrganizationPageComponent,
    UpdateOrganizationComponent,
    OrganizationCompaniesComponent,
    ChatbotUploadDocumentsComponent,
    TrustedSourcesListComponent,
    TrustedSourcesPageComponent,
    SecurityFeaturesComponent,
    LicenseDataCardComponent,
    SecurityFeaturesPageComponent,
    OrgDetailsPageComponent,
    CompanyDetailsComponent,
    ListOfCompaniesComponent,
  ],
  imports: [
    CommonModule,
    OrganizationSetupRoutingModule,
    SharedModule,
    ImageCropperModule,
  ],
  providers: [OrganizationService],
})
export class OrganizationSetupModule {}
