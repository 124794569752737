<div #button class="button flex flex-col justify-end items-center" (click)="showOptions = !showOptions; select.open();">
  <div class="wrapper flex justify-end items-center gaps self-end" [class.transparent]="transparent"  >
    <mat-icon [matTooltip]="'General.Language' | translate" *ngIf="showIcon" class="mat-icon-rtl-mirror icon-prefix box"
    svgIcon="mat:language"
    ></mat-icon>
    <mat-select #select class="select-options">
      <mat-option *ngFor="let lang of languageList" [value]="lang.isoCode" (click)="onChangeLanguage(lang)">{{lang.name}}</mat-option>
    </mat-select>
  
  </div>
</div>