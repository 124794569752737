import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from 'src/backend/services/permission.service';
import { Permission } from '../models/permission';
import { SharedConstants } from '../models/shared-constants';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissionService: PermissionService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const permissions = next.data['permissions'] ?? ({} as Permission);
    let isUserHasPermission = false;
    isUserHasPermission =
      this.permissionService.isUserHasPermission(permissions);
    if (isUserHasPermission === false) {
      this.router.navigate([
        '/' + SharedConstants.PAGE_ERROR + '/' + SharedConstants.ACCESS_DENIED,
      ]);
    }
    if (next.routeConfig.path === 'companies') {
      let rolesArray = JSON.parse(localStorage.getItem('logged_in_user'))[
        'roles'
      ];
      let isOrganizationAdmin = rolesArray.includes('Organization Admin');
      if (!isOrganizationAdmin) {
        this.router.navigate([
          '/' +
            SharedConstants.PAGE_ERROR +
            '/' +
            SharedConstants.ACCESS_DENIED,
        ]);
      }
      return isOrganizationAdmin;
    }
    return isUserHasPermission;
  }
}
