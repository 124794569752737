import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi } from 'src/backend/api/base-api';
@Injectable({
  providedIn: 'root',
})
export class SafemanHoursService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getSafemanHours(id): Observable<any> {
    return this.http.get(
      this.baseApi.encodeURL(Constants.API_GET_SAFEMAN_HOURS) + '/' + id
    );
  }
  getActiveSafetyKPIs(): Observable<any> {
    return this.http.get(
      this.baseApi.encodeURL(Constants.API_ACTIVE_SAFETYKPIs) 
    );
  }
  resetSafemanHours(id): Observable<any> {
    return this.http.get(
      this.baseApi.encodeURL(Constants.API_RESET_SAFEMAN_HOURS) + '/' + id
    );
  }
  getSafemanHoursByFilter(data): Observable<any> {
    return this.http.post(
      this.baseApi.encodeURL(Constants.API_GET_SAFEMAN_HOURS_FILTER),
      data
    );
  }

  updateSafemanHours(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_SAFEMAN_HOURS),
      data
    );
  }
}
