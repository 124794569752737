import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { Constants } from 'src/@hodhod/common/constants';
import { generateGuid } from 'src/@hodhod/common/custom_methods';
import { MachineType } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import { UnitOfMeasureService } from 'src/backend/services/master/unit-of-measure.service';
import { ValueTypeService } from 'src/backend/services/master/value-type.service';
import { UnitOfMeasure } from '../../models/unit-of-measure';
import { ValueType } from '../../models/value-type';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-add-new-machine-page',
  templateUrl: './add-new-machine-page.component.html',
  styleUrls: ['./add-new-machine-page.component.scss'],
})
export class AddNewMachinePageComponent implements OnInit {
  tittle: string;
  showParameterPanel: boolean = false;
  machineForm: FormGroup;
  status: boolean;
  formMode: 'create' | 'update' = 'create';
  private destroy$ = new Subject();
  public translationsList: any = {};
  machineType = MachineType;
  unitOfMeasures: UnitOfMeasure[] = [];
  valueTypes: ValueType[] = [];
  departments: any[] = [];
  machineId: number = 0;
  tempParametersArray: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columns: TableColumn<any>[] = [
    {
      label: 'Name',
      property: 'parameterName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Value Type',
      property: 'valueType',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Unit',
      property: 'unitOfMeasurement',
      type: 'text',
      visible: true,
    },
    {
      label: 'Control',
      property: 'parameterType',
      type: 'boolean',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Notification',
      property: 'receiveNotification',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Lower Control Limit',
      property: 'lowerControlLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    //{ label: 'Lower Warning Limit', property: 'lowerWarningLimit', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    //{ label: 'Upper Warning Limit', property: 'upperWarningLimit', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    {
      label: 'Upper Control Limit',
      property: 'upperControlLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    //{ label: 'Lower Range Limit', property: 'lowerRangeLimit', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    {
      label: 'Lower Specification Limit',
      property: 'lowerSpecificationLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Target',
      property: 'target',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Upper Specification Limit',
      property: 'upperSpecificationLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    //{ label: 'Upper Range Limit', property: 'upperRangeLimit', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    {
      label: 'IdealValue',
      property: 'idealValue',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'NonIdealValue',
      property: 'nonIdealValue',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<any>([]);
  matcher = new MyErrorStateMatcher();
  errorMessages = {
    maxlength: 'max',
    minlength: 'min',
    email: 'email',
    required: 'Required',
  };
  enableSubmit: boolean = false;
  hasCreateValueTypesPermission = ApplicationPermission.VALUE_TYPES_CREATE;
  hasCreateUnitsPermission = ApplicationPermission.UNIT_OF_MEASURES_CREATE;
  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private machineService: MachineEquipmentService,
    private feedBackService: AsyncFeedbackService,
    private unitOfMeasureService: UnitOfMeasureService,
    private valueTypeService: ValueTypeService,
    private departmentService: DepartmentsService,
    private route: ActivatedRoute
  ) {
    this.translate
      .get(['Errors', 'Success', 'User', 'Machine'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
        this.tittle = this.translationsList['Machine']['ParameterInfo'];
      });
  }

  ngOnInit() {
    this.machineForm = this.fb.group({
      pName: ['', Validators.required],
      valueType: [''],
      unitOfMeasurement: [''],
      parameterControl: [MachineType.Digital, Validators.required],
      notification: [false],

      lowerControlLimit: [''],
      // lowerWarningLimit: [''],
      // upperWarningLimit: [''],
      upperControlLimit: [''],
      // lowerRangeLimit: [''],
      lowerSpecificationLimit: [''],
      target: [''],
      upperSpecificationLimit: [''],
      idealValue: ['', Validators.required],
      nonIdealValue: ['', Validators.required],
      // upperRangeLimit: ['']
    });
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;
        this.machineId = parseInt(id, 10);
      }
    });

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => console.log(''))
      )
      .subscribe();
    this.getDepartments();
    this.getUnitOfMeasures();
    this.getValueTypes();
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  checkLimit(): void {
    const {
      lowerControlLimit,
      upperControlLimit,
      lowerSpecificationLimit,
      upperSpecificationLimit,
    } = this.machineForm.value;
    if (lowerControlLimit >= upperControlLimit) {
      this.machineForm
        .get('lowerControlLimit')
        .setValidators([
          Validators.required,
          Validators.max(upperControlLimit - 1),
        ]);
    } else {
      this.machineForm.get('lowerControlLimit').clearValidators();
    }
    if (upperControlLimit <= lowerControlLimit) {
      this.machineForm
        .get('upperControlLimit')
        .setValidators([
          Validators.required,
          Validators.min(lowerControlLimit + 1),
        ]);
    } else {
      this.machineForm.get('upperControlLimit').clearValidators();
    }

    if (lowerSpecificationLimit >= upperSpecificationLimit) {
      this.machineForm
        .get('lowerSpecificationLimit')
        .setValidators([
          Validators.required,
          Validators.max(upperSpecificationLimit - 1),
        ]);
    } else {
      this.machineForm.get('lowerSpecificationLimit').clearValidators();
    }
    if (upperSpecificationLimit <= lowerSpecificationLimit) {
      this.machineForm
        .get('upperSpecificationLimit')
        .setValidators([
          Validators.required,
          Validators.min(lowerSpecificationLimit + 1),
        ]);
    } else {
      this.machineForm.get('upperSpecificationLimit').clearValidators();
    }
    this.machineForm.get('upperControlLimit').updateValueAndValidity();
    this.machineForm.get('lowerControlLimit').updateValueAndValidity();
    this.machineForm.get('upperSpecificationLimit').updateValueAndValidity();
    this.machineForm.get('lowerSpecificationLimit').updateValueAndValidity();
  }

  errors(ctrl: FormControl): string[] {
    return ctrl.errors ? Object.keys(ctrl.errors) : [];
  }
  getUnitOfMeasures(): void {
    this.unitOfMeasureService.getUnitOfMeasuresByCompany().subscribe({
      next: (response) => {
        this.unitOfMeasures = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  getValueTypes(): void {
    this.valueTypeService.getValueTypesByCompany().subscribe({
      next: (response) => {
        this.valueTypes = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  getDepartments(): void {
    this.departmentService.getDepartmentsParentChild().subscribe({
      next: (response) => {
        this.departments = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  statusChange(status: any) {
    if (status.value == MachineType.Analog) {
      this.showParameterPanel = true;
      this.enableSubmit = false;
      this.addValidations();
    } else {
      this.showParameterPanel = false;
      this.enableSubmit = false;
      this.clearValidations();
    }
  }

  addParameterTempTable(): void {
    const data: any = {
      id: generateGuid(),
      parameterName: this.machineForm.value.pName,
      valueTypeId: this.machineForm.value.valueType
        ? this.machineForm.value.valueType
        : 0,
      valueType: this.valueTypes.find(
        (x) => x.id === this.machineForm.value.valueType
      )?.value,
      unitOfMeasurementId: this.machineForm.value.unitOfMeasurement
        ? this.machineForm.value.unitOfMeasurement
        : 0,
      unitOfMeasurement: this.unitOfMeasures.find(
        (x) => x.id === this.machineForm.value.unitOfMeasurement
      )?.unit,
      parameterType: this.machineForm.value.parameterControl,
      receiveNotification: this.machineForm.value.notification,
      lowerControlLimit: this.machineForm.value.lowerControlLimit
        ? this.machineForm.value.lowerControlLimit
        : 0,
      upperControlLimit: this.machineForm.value.upperControlLimit
        ? this.machineForm.value.upperControlLimit
        : 0,
      lowerSpecificationLimit: this.machineForm.value.lowerSpecificationLimit
        ? this.machineForm.value.lowerSpecificationLimit
        : 0,
      target: this.machineForm.value.target ? this.machineForm.value.target : 0,
      upperSpecificationLimit: this.machineForm.value.upperSpecificationLimit
        ? this.machineForm.value.upperSpecificationLimit
        : 0,
      idealValue: this.machineForm.value.idealValue
        ? this.machineForm.value.idealValue
        : '',
      nonIdealValue: this.machineForm.value.nonIdealValue
        ? this.machineForm.value.nonIdealValue
        : '',
    };
    this.tempParametersArray.push(data);
    this.dataSource = new MatTableDataSource(this.tempParametersArray);
    this.enableSubmit = true;
    this.resetForm();
  }
  removeTempParameter(event: any, id: string) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Machine']['RemoveMachineParameter']['Title'],
          this.translationsList['Machine']['RemoveMachineParameter']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.tempParametersArray = this.tempParametersArray.filter(
            (x) => x.id !== id
          );
          this.dataSource = new MatTableDataSource(this.tempParametersArray);
        }
      });
  }

  createMachineParameter() {
    if (this.tempParametersArray.length === 0) {
      this.feedBackService.showFeedback(
        new FeedbackModel(FeedbackType.Failure, 'Please enter some parameters')
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const data = {
            machineId: this.machineId,
            parameters: this.tempParametersArray.map((ele) => {
              return {
                parameterName: ele?.parameterName,
                valueTypeId: ele?.valueTypeId,
                unitOfMeasurementId: ele?.unitOfMeasurementId,
                parameterType: ele?.parameterType,
                receiveNotification: ele?.receiveNotification,
                lowerControlLimit: ele?.lowerControlLimit,
                upperControlLimit: ele?.upperControlLimit,

                lowerSpecificationLimit: ele?.lowerSpecificationLimit,
                target: ele?.target,
                upperSpecificationLimit: ele?.upperSpecificationLimit,
                idealValue: ele?.idealValue,
                nonIdealValue: ele?.nonIdealValue,
              };
            }),
          };
          this.loadingService.startLoading(true, '');
          this.machineService.createMachineParameters(data).subscribe({
            next: (response) => {
              this.resetForm();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
              this.loadingService.stopLoading();
              this.tempParametersArray = [];
              this.dataSource = new MatTableDataSource(
                this.tempParametersArray
              );
              this.enableSubmit = false;
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
              this.enableSubmit = false;
            },
          });
        }
      });
  }

  resetForm(): void {
    this.machineForm.reset();
    this.machineForm.get('parameterControl').setValue(MachineType.Digital);
    this.machineForm.get('notification').setValue(false);
    this.showParameterPanel = false;
    this.clearValidations();
  }

  addValidations(): void {
    this.machineForm.get('target')?.setValidators(Validators.required);
    this.machineForm.get('target')?.updateValueAndValidity();
    this.machineForm.get('valueType')?.setValidators(Validators.required);
    this.machineForm.get('valueType')?.updateValueAndValidity();
    this.machineForm
      .get('unitOfMeasurement')
      ?.setValidators(Validators.required);
    this.machineForm.get('unitOfMeasurement')?.updateValueAndValidity();
    this.machineForm.get('idealValue')?.clearValidators();
    this.machineForm.get('idealValue')?.updateValueAndValidity();
    this.machineForm.get('nonIdealValue')?.clearValidators();
    this.machineForm.get('nonIdealValue')?.updateValueAndValidity();

    this.machineForm.get('valueType')?.setValue('');
    this.machineForm.get('unitOfMeasurement')?.setValue('');
  }
  clearValidations(): void {
    this.machineForm.get('lowerControlLimit')?.clearValidators();
    this.machineForm.get('upperControlLimit')?.clearValidators();
    this.machineForm.get('lowerSpecificationLimit')?.clearValidators();
    this.machineForm.get('target')?.clearValidators();
    this.machineForm.get('upperSpecificationLimit')?.clearValidators();
    // updating validators values
    this.machineForm.get('lowerControlLimit')?.updateValueAndValidity();
    this.machineForm.get('upperControlLimit')?.updateValueAndValidity();
    this.machineForm.get('lowerSpecificationLimit')?.updateValueAndValidity();
    this.machineForm.get('target')?.updateValueAndValidity();
    this.machineForm.get('upperSpecificationLimit')?.updateValueAndValidity();
    this.machineForm.get('valueType')?.clearValidators();
    this.machineForm.get('valueType')?.updateValueAndValidity();
    this.machineForm.get('unitOfMeasurement')?.clearValidators();
    this.machineForm.get('unitOfMeasurement')?.updateValueAndValidity();

    this.machineForm.get('idealValue')?.setValidators(Validators.required);
    this.machineForm.get('idealValue')?.updateValueAndValidity();
    this.machineForm.get('nonIdealValue')?.setValidators(Validators.required);
    this.machineForm.get('nonIdealValue')?.updateValueAndValidity();

    this.machineForm.get('idealValue')?.setValue('');
    this.machineForm.get('nonIdealValue')?.setValue('');
  }
}
