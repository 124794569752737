import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, merge, tap } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { PermissionService } from 'src/backend/services/permission.service';
import { SafetyAnnouncementService } from 'src/backend/services/safety-announcement/safety-announcement.service';
import { AddUpdateSafetyAnnouncementComponent } from '../add-update-safety-announcement/add-update-safety-announcement.component';
import { ViewSafetyAnnouncementComponent } from '../view-safety-announcement/view-safety-announcement.component';
@UntilDestroy()
@Component({
  selector: 'app-safety-announcement-list',
  templateUrl: './safety-announcement-list.component.html',
  styleUrls: ['./safety-announcement-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class SafetyAnnouncementListComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  loadingLabel: string = '';
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;

  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;

  hasViewPermission = ApplicationPermission.SAFETY_ANNOUNCEMENT_VIEW;
  hasCreatePermission = ApplicationPermission.SAFETY_ANNOUNCEMENT_CREATE;
  hasUpdatePermission = ApplicationPermission.SAFETY_ANNOUNCEMENT_UPDATE;
  hasDeletePermission = ApplicationPermission.SAFETY_ANNOUNCEMENT_DELETE;

  viewPermission = this.permissionService.isUserHasPermission(
    this.hasViewPermission
  );
  createPermission = this.permissionService.isUserHasPermission(
    this.hasCreatePermission
  );
  updatePermission = this.permissionService.isUserHasPermission(
    this.hasUpdatePermission
  );

  dataSource: MatTableDataSource<any> | null;
  columns: TableColumn<any>[] = [
    {
      label: 'Read',
      property: 'isRead',
      type: 'image',
      visible:
        this.viewPermission && !this.createPermission && !this.updatePermission
          ? true
          : false,
    },
    { label: 'Title', property: 'title', type: 'text', visible: true },
    { label: 'CreatedAt', property: 'createdAt', type: 'date', visible: true },
    { label: 'Tags', property: 'tags', type: 'text', visible: true },
    {
      label: 'CreatedBy',
      property: 'fullName',
      type: 'text',
      visible:
        this.viewPermission && !this.createPermission && !this.updatePermission
          ? true
          : false,
    },
    {
      label: 'EngagementCounter',
      property: 'engagementCounter',
      type: 'text',
      visible:
        this.viewPermission && this.createPermission && this.updatePermission
          ? true
          : false,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;

  private translationsList: any = {};

  public loggedUser: LoggedUser;

  constructor(
    private dialog: MatDialog,
    private feedBackService: AsyncFeedbackService,
    private safetyAnnouncementService: SafetyAnnouncementService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private baseApi: BaseApi,
    private permissionService: PermissionService
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'ConfirmDeleteRecord',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.dataSource = new MatTableDataSource();
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.getAnnouncements())
      )
      .subscribe();
    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => {
        this.onFilterChange(value);
      });

    this.getAnnouncements();
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }


  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.paginator.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getAnnouncements();
    }, 700);
  }

  getAnnouncements() {

    let sortDirection = SortDirection.None;
    const filteredParams = {
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      searchValue: this.searchValue,
      sortField:this.sort.active?this.sort.active:null,
      sortDirection:this.sort.direction
    };
    this.sectionState = SectionStateStatus.Loading;

    this.safetyAnnouncementService
      .GetSafetyAnnouncements(filteredParams)
      .subscribe({
        next: (response) => {
          this.paginator.length = response.totalCount;
          this.sectionState = SectionStateStatus.Ready;
          this.dataSource = new MatTableDataSource(
            response.safetyAnnouncementDtos
          );
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  splitTags(tags: string) {
    return tags.split(',');
  }

  viewAnnouncement(announcementId: number) {
    const announcementDialog = this.dialog.open(
      ViewSafetyAnnouncementComponent,
      {
        height: 'auto',
        width: '90%',
        data: { formMode: 'view', announcementId: announcementId },
      }
    );
    announcementDialog.afterClosed().subscribe((res) => {
      if (res.event === 'markedAsRead') {
        this.getAnnouncements();
      }
    });
  }

  addNewAnnouncement() {
    const announcementDialog = this.dialog.open(
      AddUpdateSafetyAnnouncementComponent,
      { height: 'auto', width: '90%', data: { formMode: 'create' } }
    );

    announcementDialog.afterClosed().subscribe((res) => {
      if (res.event === 'createdNew') {
        this.getAnnouncements();
      }
    });
  }

  editAnnouncement(announcementId: number) {
    const announcementDialog = this.dialog.open(
      AddUpdateSafetyAnnouncementComponent,
      {
        height: 'auto',
        width: '90%',
        data: { formMode: 'edit', announcementId: announcementId },
      }
    );

    announcementDialog.afterClosed().subscribe((res) => {
      if (res.event === 'updated') {
        this.getAnnouncements();
      }
    });
  }

  dublicateAnnouncement(announcementId: number) {
    const announcementDialog = this.dialog.open(
      AddUpdateSafetyAnnouncementComponent,
      {
        height: 'auto',
        width: '90%',
        data: { formMode: 'dublicate', announcementId: announcementId },
      }
    );

    announcementDialog.afterClosed().subscribe((res) => {
      if (res.event === 'createdNew') {
        this.getAnnouncements();
      }
    });
  }

  deleteAnnouncement(announcementId: number) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['ConfirmDeleteRecord']['Title'],
          this.translationsList['ConfirmDeleteRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.safetyAnnouncementService
            .DeleteAnnouncement(announcementId)
            .subscribe({
              next: (res) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
                this.getAnnouncements();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
