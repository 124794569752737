<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "EquipmentList.Title" | translate }}</span>
    </h2>
    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'Placeholder.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>
    <button
      *ngIf="page === 'setup' && hasCreatePermission | permissionChecker"
      (click)="openCreateModal()"
      class="ml-4 flex-none"
      color="primary"
      mat-mini-fab
      [matTooltip]="'AddReportIncident.ReportActions.AddActions' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>

  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'image'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          ></th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <ngx-avatars
              class="logo box"
              size="32"
              [src]="row['imageUrl']"
              [name]="row['imageName'] || row['code']"
              [textSizeRatio]="2"
              [round]="true"
              [initialsSize]="2"
            ></ngx-avatars>
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.type === 'text' &&
            column.property !== 'locationDepartmentName'
          "
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "EquipmentList.Table." + column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.type === 'text' &&
            column.property === 'locationDepartmentName'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "EquipmentList.Table." + column.label | translate }}
              <mat-select
                [value]="selectedDepartment"
                (selectionChange)="onSelectDepartment($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let dep of departments"
                  [value]="dep.departmentId"
                >
                  {{ dep.departmentName }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date' && column.property !== 'executedOn'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "EquipmentList.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "medium" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.type === 'date' &&
            page === 'excute' &&
            column.property === 'executedOn'
          "
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "EquipmentList.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "medium" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              *ngIf="page === 'setup'"
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="selectS.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "User.Table." + column.label | translate }}
              <mat-select
                [value]="selectedInspectionStatus"
                (selectionChange)="onSelectStatus($event)"
                #selectS
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of status | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.CompanyUserStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
            <div
              *ngIf="page === 'excute'"
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="selectE.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "User.Table." + column.label | translate }}
              <mat-select
                [value]="selectedInspectionStatus"
                (selectionChange)="onSelectStatus($event)"
                #selectE
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of InspectionStatus | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.CompanyUserStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
            <div
              *ngIf="page === 'review'"
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="selectR.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "User.Table." + column.label | translate }}
              <mat-select
                [value]="selectedInspectionStatus"
                (selectionChange)="onSelectStatus($event)"
                #selectR
                class="select-options absolute"
                multiple
              >
                <mat-option
                  *ngFor="let statue of equipmentStatus | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.CompanyUserStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <app-boolean-view
              *ngIf="page == 'setup'"
              [value]="row[column.property] === 'ACTIVE'"
              [text]="
                row[column.property] === 'ACTIVE'
                  ? ('General.Active' | translate)
                  : ('General.Inactive' | translate)
              "
            >
            </app-boolean-view>
            <div
              *ngIf="page !== 'setup'"
              class="flex justify-center align-center status"
              [attr.id]="row[column.property]"
            >
              <span>{{
                "Enum.CompanyUserStatus." + row[column.property] | translate
              }}</span>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let row" mat-cell>
          <div (click)="$event.stopPropagation()" *ngIf="page === 'setup'">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                (click)="Dectivate(row?.id)"
                *ngIf="
                  row?.status === 'ACTIVE' &&
                  (hasUpdatePermission | permissionChecker)
                "
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
              >
                {{ "General.Buttons.Deactivate" | translate }}
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                (click)="Activate(row?.id)"
                *ngIf="
                  row?.status !== 'ACTIVE' &&
                  (hasUpdatePermission | permissionChecker)
                "
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
              >
                {{ "General.Buttons.Activate" | translate }}
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                (click)="openEquipmentModal(row?.id, false)"
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
              >
                {{ "General.Buttons.View" | translate }}
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                *ngIf="hasUpdatePermission | permissionChecker"
                (click)="openEquipmentModal(row?.id, true)"
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
              >
                {{ "General.Buttons.Edit" | translate }}
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
          <div
            (click)="$event.stopPropagation()"
            *ngIf="page === 'excute' && row?.status === 'Uninspected'"
          >
            <button mat-icon-button [matMenuTriggerFor]="menu1">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu1="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                (click)="inspectEquipment(row?.id)"
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
              >
                {{ "General.Buttons.Inspect" | translate }}
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
          <div (click)="$event.stopPropagation()" *ngIf="page === 'review'">
            <button mat-icon-button [matMenuTriggerFor]="menu2">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu2="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                (click)="openReviewEquipmentPage(row?.id)"
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
              >
                {{ "General.Buttons.View" | translate }}
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        (click)="openEquipmentModal(row?.id, false)"
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-icon
      class="text-[#757575] me-1"
      *ngIf="!column.visible"
      svgIcon="mat:check_box_outline_blank"
    ></mat-icon>
    <mat-icon
      *ngIf="column.visible"
      class="text-primary me-1"
      svgIcon="mat:check_box"
    ></mat-icon>
    {{ "EquipmentList.Table." + column.label | translate }}
  </button>
</mat-menu>

<ng-template #equipmentCreateUpdateModal>
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionStateModal"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <div>
      <form [formGroup]="equipmentForm" style="overflow-wrap: break-word">
        <div class="flex items-center justify-between" mat-dialog-title>
          <div class="flex items-center">
            <label for="fileInput">
              <img
                [src]="
                  equipmentDetails?.imageUrl ||
                  selectedImage ||
                  'assets/img/resources/add-new-img.svg'
                "
                style="width: 50px; height: 50px"
                alt="Upload Image"
                class="rounded-full"
              />
              <input
                [disabled]="editMode === 'view'"
                type="file"
                id="fileInput"
                accept="'file_extension|image/*'"
                (change)="onFileSelected($event)"
                style="display: none"
              />
            </label>
            <div class="ps-5">
              <h2 class="text-4xl font-normal m-0 flex-auto text-blue-950">
                {{ equipmentForm.get("equipmentName").value }}
              </h2>
              <p class="text-xs font-medium">
                {{ this.equipmentForm.get("equipmentCode").value }}
              </p>
            </div>
          </div>
          <div (click)="generateQrCode()" *ngIf="editMode === 'view'">
            <img
              class="cursor-pointer"
              src="assets/img/resources/qrcode-icon.png"
              width="30px"
              height="30px"
            />
          </div>
        </div>

        <mat-divider class="text-border mb-2"></mat-divider>
        <mat-dialog-content class="">
          <div class="">
            <p class="p-1">{{ "Category Name: " + categoryDetails?.name }}</p>
            <p class="p-1">{{ "Category Code: " + categoryDetails?.code }}</p>
            <p class="p-1">
              {{
                "Inspection Frequency: " + categoryDetails?.inspectionFrequency
              }}
            </p>
          </div>
          <div class="field-full flex column p-0">
            <mat-form-field class="input-field p-0" appearance="outline">
              <input
                [placeholder]="'Placeholder.EquipmentName' | translate"
                maxlength="50"
                matInput
                type="text"
                formControlName="equipmentName"
              />
            </mat-form-field>
          </div>
          <div class="field-full flex column p-0">
            <mat-form-field class="input-field p-0" appearance="outline">
              <input
                [placeholder]="'*' + ('Placeholder.EquipmentCode' | translate)"
                matInput
                type="text"
                maxlength="20"
                formControlName="equipmentCode"
              />
            </mat-form-field>
          </div>
          <div class="field-full flex column p-0">
            <mat-form-field class="input-field" appearance="outline">
              <mat-select
                formControlName="department"
                [placeholder]="'*' + ('Placeholder.Department' | translate)"
              >
                <mat-option
                  *ngFor="let dep of departments"
                  [value]="dep.departmentId"
                >
                  {{ dep.departmentName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="field-full flex column p-0">
            <mat-form-field class="input-field" appearance="outline">
              <mat-select
                formControlName="checklist"
                [placeholder]="'*' + ('Placeholder.Checklist' | translate)"
              >
                <mat-option
                  *ngFor="
                    let checklist of categoryDetails?.inspectionChecklists
                  "
                  [value]="checklist.checkListId"
                >
                  {{ checklist.checkListName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="field-full flex column p-0">
            <mat-form-field class="input-field p-0" appearance="outline">
              <input
                [placeholder]="'Placeholder.Location' | translate"
                matInput
                maxlength="100"
                type="text"
                formControlName="location"
              />
            </mat-form-field>
          </div>
          <mat-dialog-actions align="end" *ngIf="editMode !== 'view'">
            <div class="flex items-center">
              <app-basic-button
                [btnType]="'Add'"
                [btnText]="'Save'"
                [isDisabled]="!equipmentForm.valid"
                (onClick)="
                  editMode === 'edit'
                    ? save(equipmentDetails?.id, equipmentDetails?.checklistId)
                    : createNewEquipment()
                "
              ></app-basic-button>
            </div>
          </mat-dialog-actions>
        </mat-dialog-content>
      </form>
    </div>
  </app-state-section>
</ng-template>
