<app-card [title]="'ManageAccessControl.LicenseData' | translate">
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionStateCompanyLicense"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <div body class="flex justify-between flex-wrap-reverse">
      <div class="flex-1">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ companyLicenseData?.name }}</p>
            <p class="m-0 caption text-hint">
              {{ "ManageAccessControl.CompanyName" | translate }}
            </p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{ companyLicenseData?.accessControlLicenseQuota }}
            </p>
            <p class="m-0 caption text-hint">
              {{ "ManageAccessControl.Total" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>
          <div @fadeInRight>
            <p class="m-0 body-1">{{ companyLicenseData?.active }}</p>
            <p class="m-0 caption text-hint">
              {{ "ManageAccessControl.Active" | translate }}
            </p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{ companyLicenseData?.available }}
            </p>
            <p class="m-0 caption text-hint">
              {{ "ManageAccessControl.Available" | translate }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </app-state-section>
</app-card>

<div class="card overflow-auto mt-2">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "ManageAccessControl.ManageMobileUsers" | translate }}</span>
    </h2>

    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'General.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      *ngIf="
        (hasCreatePermission | permissionChecker) &&
        usersWithoutAccessControl.length > 0
      "
      class="ml-2 flex-none"
      color="primary"
      mat-mini-fab
      [matTooltip]="'General.Buttons.AddNew' | translate"
      type="button"
      (click)="addUser()"
    >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "ManageAccessControl.Table." + column.label | translate }}&nbsp;
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "ManageAccessControl.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "mediumDate" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.type === 'boolean' &&
            column.property === 'lastInvitationStatus'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              {{ "ManageAccessControl.Table." + column.label | translate }}
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <span>
                {{
                  "ManageAccessControl.Status." + row[column.property]
                    | translate
                }}
              </span>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <!-- ACTIONS Column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th *matHeaderCellDef mat-header-cell></th>

        <td *matCellDef="let row" mat-cell>
          <div (click)="$event.stopPropagation()">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="viewUserAccess(row?.id)"
              >
                <button>
                  {{ "General.Buttons.View" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="deleteUser(row?.id)"
              >
                <button>
                  {{ "General.Buttons.Delete" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewUserAccess(row?.id)"
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    >
    </mat-paginator>
  </app-state-section>
</div>

<ng-template #addUserModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "ManageAccessControl.InviteUser" | translate }}
    </h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

  <mat-dialog-content class="flex flex-col flex-wrap justify-between">
    <!-- title -->
    <div class="field-full flex column">
      <mat-label class="field-label"
        >{{ "ManageAccessControl.InviteUser" | translate }}
        <span
          [ngClass]="
            addUserControl.invalid && addUserControl.hasError('required')
              ? 'is-invalid'
              : 'is-valid'
          "
          >*</span
        >
      </mat-label>
      <div
        class="bg-foreground rounded-full px-4 flex-auto flex items-center border border-gray-300"
      >
        <input
          [formControl]="addUserControl"
          class="px-4 py-2 border-0 outline-none w-full bg-transparent"
          [placeholder]="'ManageAccessControl.SelectUser' | translate"
          type="search"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            *ngFor="let option of filteredUsersWithoutAccessControl | async"
            [value]="option"
          >
            {{ option?.fullName }} ( {{ option?.email }} )
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <div class="flex items-center">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel'"
        (onClick)="closeDialog()"
      ></app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [btnText]="'Add'"
        [isDisabled]="!addUserControl.valid"
        (onClick)="onAddUser()"
      ></app-basic-button>
    </div>
  </mat-dialog-actions>
</ng-template>
