import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class ExportExcelService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  exportToExcel(data: any): Observable<any> {
    let params = '';
    if (data?.allWorkPermits) {
      params += 'allWorkPermits=' + data?.allWorkPermits;
      delete data.allWorkPermits;
    }
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_EXPORT_TO_EXCEL + '?' + params),
      data,
      {
        responseType: 'blob' as 'json',
      }
    );
  }

  exportToExcelFiltered(data: any): Observable<any> {
    let params = '';
    if (data?.allWorkPermits) {
      params += 'allWorkPermits=' + data?.allWorkPermits;
      delete data.allWorkPermits;
    }
    return this.http.post<any>(
      this.baseApi.encodeURL(
        Constants.API_EXPORT_TO_EXCEL_FILTERED + '?' + params
      ),
      data,
      {
        responseType: 'blob' as 'json',
      }
    );
  }
}
