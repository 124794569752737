import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { setRowSelection } from 'src/@hodhod/common/custom_methods';
import { MenuProps } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { Menu } from 'src/app/shared/models/permissions/menu';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ParentRole } from '../../models/parent-role';
import { RolePermission } from '../../models/role-permission';
import { Route } from '@angular/router';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-role-permission',
  templateUrl: './role-permission.component.html',
  styleUrls: ['./role-permission.component.scss'],
  animations: [fadeInUp400ms],
})
export class RolePermissionComponent implements OnInit, OnChanges {
  @Input() permissions: any = [];
  @Input() roleName: any = '';
  @Input() createFlag: any = '';
  @Output() savePermissionsEvent: EventEmitter<any> = new EventEmitter();
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public menuPermissions: any[];
  isRoleName: boolean = true;
  hasSelectedPermission = false;
  disapleBtn: boolean;
  selectedPermissions: any[] = [];
  hasCreateRolePermission=ApplicationPermission.ROLE_CREATE
  hasUpdateRolePermission=ApplicationPermission.ROLE_UPDATE
  tableColumns: TableColumn<Menu>[] = [
    {
      label: '',
      property: 'rowSelected',
      type: 'checkbox',
      cssClasses: ['w-6'],
    },
    {
      label: 'TypeName',
      property: 'label',
      type: 'text',
      cssClasses: ['font-medium'],
    },
    {
      label: 'Permissions',
      property: 'permissions',
      type: 'text',
      cssClasses: ['font-medium'],
    },
  ];
  constructor() {} 
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['permissions']) {
      this.getPermissions();
      this.checkIfPermissionSelected();
    }
    if (changes['roleName']) {
      this.checkIfPermissionSelected();
    }
  }

  getPermissions(): void {
    if (this.permissions) {
      this.menuPermissions = [...this.permissions];
    }
    this.menuPermissions?.sort((a,b)=> a.order - b.order)
  }


  checkIfPermissionSelected(event?) {
    this.hasSelectedPermission = false;
    this.menuPermissions?.forEach((menu) => {
      menu.permissions.forEach((row) => {
        row.menusPermissions.forEach((permission) => {
          const permissionsValues = Object.values(permission.permissions);
          const permissionSelected = permissionsValues.some(
            (value) => value === true
          );
          if (permissionSelected) {
            this.hasSelectedPermission = true;
          } else if (event && this.hasSelectedPermission) {
            this.hasSelectedPermission = true;
          }
        });
      });
    });
  }

  savePermissions(): void {
    this.savePermissionsEvent.emit(this.selectedPermissions);
  }

  getPermissionsList(event) {
  const index = this.selectedPermissions.findIndex(obj => obj.name === event.name);
  if (index !== -1) {
    this.selectedPermissions[index] = event;
  } else {
    this.selectedPermissions.push(event);
  }
  
  this.checkIfPermissionSelected();
  }
}
