import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';


@Component({
  selector: 'app-add-id-card',
  templateUrl: './add-id-card.component.html',
  styleUrls: ['./add-id-card.component.scss']
})
export class AddIdCardComponent implements OnInit {



  userForm: FormGroup;
  formMode: 'create' | 'update' = 'create';
  private destroy$ = new Subject();
  public translationsList: any = {};
  public userInfo: any;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  constructor(
    private fb: FormBuilder,
    private asyncFeedbackService: AsyncFeedbackService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.translate.get(['Errors', 'Success', 'User']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
      this.translationsList = translations;
    });
  }

  ngOnInit() {
    this.userForm = this.fb.group({
      userCode: [''],
      firstName: [''],
      lastName: [''],
      phoneNumber: ['', Validators.pattern("^[0-9]*$")],
      email: [''],
      department: [''],
    });

    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const userId: string = params.get('id');
        // this.getUserInfo(userId);     
      }
    });
  }



}
