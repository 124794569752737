import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafetyRoutingModule } from './safety-routing.module';
import { SharedModule } from '../shared/shared.module';
import { ManageChecklistPageComponent } from './pages/manage-checklist-page/manage-checklist-page.component';
import { ChecklistSectionComponent } from './components/checklist-section/checklist-section.component';
import { ChecklistSectionPageComponent } from './pages/checklist-section-page/checklist-section-page.component';
import { AddChecklistSectionComponent } from './components/add-checklist-section/add-checklist-section.component';
import { AddChecklistSectionPageComponent } from './pages/add-checklist-section-page/add-checklist-section-page.component';
import { AddUpdateChecklistPopupComponent } from './components/add-update-checklist-popup/add-update-checklist-popup.component';
import { ViewChecklistSectionPageComponent } from './pages/view-checklist-section-page/view-checklist-section-page.component';
import { EditChecklistSectionComponent } from './components/edit-checklist-section/edit-checklist-section.component';
import { ActionTrackerModule } from '../action-tracker/action-tracker.module';
@NgModule({
  declarations: [

    ManageChecklistPageComponent,
    ChecklistSectionComponent,
    ChecklistSectionPageComponent,
    AddChecklistSectionComponent,
    AddChecklistSectionPageComponent,
    AddUpdateChecklistPopupComponent,
    ViewChecklistSectionPageComponent,
    EditChecklistSectionComponent

  ],
  imports: [
    CommonModule,
    SafetyRoutingModule,
    SharedModule,
    ActionTrackerModule,
    

  ]
})
export class SafetyModule { }
