<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'BreadCrumb.View' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div>
      <app-expandable-panel [expanded]="true" #expandablePanel [title]="'User.UserInfo' | translate">
        <div body class="flex column">
          <app-view-user [userDetails]="userDetails"></app-view-user>
        </div>
      </app-expandable-panel>
    </div>
    <div class="mt-5">
      <app-expandable-panel [expanded]="true" #expandablePanel [title]="'User.ManageUserPermissions' | translate">
        <div body class="flex column">
          <app-user-permission [userPermissions]="userPermissions"
            (savePermissionsEvent)="saveChangesPermissions($event)"></app-user-permission>
        </div>
      </app-expandable-panel>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>