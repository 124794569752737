import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit, Renderer2 } from '@angular/core';
import {
  MatIconRegistry,
  SafeResourceUrlWithIconOptions,
} from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Settings } from 'luxon';
import {
  ColorVariable,
  colorVariables,
} from 'src/@hodhod/components/config-panel/color-variables';
import { ColorSchemeName } from 'src/@hodhod/config/colorSchemeName';
import { HodhodConfigName } from 'src/@hodhod/config/config-name.model';
import { ConfigService } from 'src/@hodhod/config/config.service';
import { NavigationService } from 'src/@hodhod/services/navigation.service';
import { BaseApi } from 'src/backend/api/base-api';
import { AuthService } from 'src/backend/services/auth.service';
import { LoadingService } from './shared/helpers/loading.service';
import { MessagingService } from 'src/backend/services/messaging.service';
import { BreadcrumbService } from './shared/helpers/breadcrumb.service';
import { VersionChecker } from 'src/backend/services/version-checker.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'HODHOD';

  constructor(
    private configService: ConfigService,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private authService: AuthService,
    private versionChecker: VersionChecker,
    private baseApi: BaseApi,
    private breadCrumbService: BreadcrumbService,
    private messagingService: MessagingService
  ) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );

          case 'sidebar':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/resources/${name}.svg`
            );
        }
        return null;
      }
    );

    this.route.queryParamMap.subscribe((queryParamMap) => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(
          queryParamMap.get('layout') as HodhodConfigName
        );
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName,
          },
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable =
          colorVariables[queryParamMap.get('primaryColor')!];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color,
              },
            },
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl'))
            ? 'rtl'
            : 'ltr',
        });
      }
    });

    /**
     * Add your own routes here
     */

    this.navigationService.items = this.baseApi.getUserMenusSession();
    this.breadCrumbService.buildBreadCrumb();
    // this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.messagingService.InitializeSignalR();
    this.messagingService.recieveNotificationMessageFromSignalR();
  }

  ngOnInit(): void {
    this.versionChecker.initVersionCheck(
      'https://live.hodhod.io/assets/scripts/version.json'
    );
    setTimeout(() => {
      this.loadingService.stopLoading();
    }, 500);
    if (this.authService.isLoggedIn()) {
      // this.authService.navigateToInitialMenu();
    }
  }
}
