<div
  [class.container]="isVerticalLayout$ | async"
  class="toolbar w-full px-gutter flex items-center"
>
  <button
    (click)="openSidenav()"
    [class.hidden]="!mobileQuery"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a
    *ngIf="isVerticalLayout$ | async"
    [routerLink]="['/']"
    class="ltr:mr-4 rtl:ml-4 block flex items-center"
  >
    <img
      alt="Logo"
      class="w-8 select-none"
      src="assets/img/resources/hodhod-logo.svg"
    />
    <h1
      [class.hidden]="!mobileQuery"
      class="title ltr:pl-4 rtl:pr-4 m-0 select-none"
    >
      iSaned
    </h1>
  </a>

  <mat-menu
    #ManageContentMenu="matMenu"
    [overlapTrigger]="false"
    xPosition="after"
    yPosition="below"
  >
    <a
      [routerLink]="menu.route"
      mat-menu-item
      class="hover:text-primary"
      *ngFor="let menu of formateManageContentItems()"
    >
      <mat-icon
        class="content_icon_filter"
        [svgIcon]="menu.icon"
        color="primary"
      ></mat-icon>
      <span>{{ "NavigationBar." + menu.label | translate }}</span>
    </a>

    <!-- <button [matMenuTriggerFor]="documentMenu" mat-menu-item>
      <mat-icon svgIcon="mat:ballot"></mat-icon>
      <span>Add Document</span>
    </button> -->
  </mat-menu>
  <mat-menu
    #ManageAccountMenu="matMenu"
    [overlapTrigger]="false"
    xPosition="after"
    yPosition="below"
  >
    <div *ngFor="let menu of formateManageAccountItems()">
      <a
        [routerLink]="menu.route"
        mat-menu-item
        class="hover:text-primary"
        *ngIf="
          menu.label === 'Companies'
            ? isOrganizationAdmin
              ? true
              : false
            : true
        "
      >
        <mat-icon [svgIcon]="menu.icon"></mat-icon>
        <span>{{ "NavigationBar." + menu.label | translate }}</span>
      </a>
    </div>
  </mat-menu>

  <mat-menu #documentMenu="matMenu">
    <button mat-menu-item>
      <mat-icon svgIcon="mat:description"></mat-icon>
      <span>Add Quote</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:assignment"></mat-icon>
      <span>Add Invoice</span>
    </button>

    <button mat-menu-item>
      <mat-icon svgIcon="mat:receipt"></mat-icon>
      <span>Add Receipt</span>
    </button>
  </mat-menu>

  <div
    *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
    [class.hidden]="mobileQuery"
    class="px-gutter flex-none flex items-center"
  >
    <hodhod-navigation-item
      *ngFor="let item of navigationItems"
      [item]="item"
    ></hodhod-navigation-item>
  </div>
  <div
    class="flex items-center"
    style="margin-inline-end: 75px"
    *ngIf="sidenavCollapsed$ | async"
  >
    <img
      [src]="imageUrl$ | async"
      style="width: 145px; height: 46px"
      alt="Logo"
      class="select-none object-contain"
    />
    <!-- <img
      [src]="'assets/img/resources/hodhod-name.svg'"
      alt="Logo"
      style="width: 85px"
      class="select-none"
      [style]="(isDark$ | async) ? 'filter: invert(100%)' : ''"
    /> -->
  </div>
  <div class="px-1" [class.visible]="mobileQuery" [class.hidden]="!mobileQuery">
    <button (click)="openSearch()" mat-icon-button type="button">
      <mat-icon color="primary" svgIcon="mat:search"></mat-icon>
    </button>
  </div>
  <div
    class="bg-transparent px-1 max-w-[300px] flex-auto flex items-center"
    [class.hidden]="mobileQuery"
  >
    <mat-icon class="text-secondary" svgIcon="mat:search"></mat-icon>
    <input
      (keyup)="search()"
      class="px-4 py-2 border-0 outline-none w-full bg-transparent"
      [placeholder]="'General.SearchForAnything' | translate"
      [formControl]="searchCtrl"
      type="text"
    />
    <button
      class="text-base font-bold"
      *ngIf="searchCtrl.value"
      (click)="clearSearch()"
      type="button"
    >
      &times;
    </button>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <!-- <div class="px-1">
      <hodhod-toolbar-notifications></hodhod-toolbar-notifications>
    </div> -->
    <div class="px-1" *ngIf="formateManageAccountItems()?.length > 0">
      <button
        *ngIf="isHorizontalLayout$ | async"
        [matMenuTriggerFor]="ManageAccountMenu"
        mat-button
        class="content_btn flex content-between w-full text-primary"
        [disableRipple]="true"
        type="button"
      >
        <mat-icon svgIcon="sidebar:setting-icon"></mat-icon>
        <mat-icon class="ms-5" svgIcon="mat:expand_more"></mat-icon>
      </button>
    </div>
    <div class="px-1" *ngIf="formateManageContentItems()?.length > 0">
      <button
        *ngIf="isHorizontalLayout$ | async"
        [matMenuTriggerFor]="ManageContentMenu"
        mat-button
        class="content_btn flex content-between w-full text-primary"
        [disableRipple]="true"
        type="button"
      >
        <mat-icon svgIcon="sidebar:manage-content-icon"></mat-icon>
        <mat-icon class="ms-5" svgIcon="mat:expand_more"></mat-icon>
      </button>
    </div>

    <div class="px-1">
      <button
        (click)="openQuickpanel()"
        mat-icon-button
        type="button"
        class="relative"
      >
        <mat-icon color="primary" svgIcon="mat:notifications"></mat-icon>
        <div
          *ngIf="notifyCount > 0"
          class="inline-flex absolute -top-2 -right-2 justify-center items-center w-7 h-7 text-xs font-bold text-white bg-primary rounded-full border-2 border-white dark:border-gray-900"
        >
          {{ notifyCount > 99 ? "99+" : notifyCount }}
        </div>
      </button>
    </div>

    <!-- <div class="px-1">
      <button [matMenuTriggerFor]="languageMenu" mat-icon-button type="button">
        <mat-icon svgIcon="flag:united-states"></mat-icon>
      </button>
    </div> -->

    <div *ngIf="userVisible$ | async" class="px-1">
      <hodhod-toolbar-user></hodhod-toolbar-user>
    </div>

    <mat-menu
      #languageMenu="matMenu"
      overlapTrigger="false"
      xPosition="before"
      yPosition="below"
    >
      <button mat-menu-item>
        <mat-icon svgIcon="flag:united-states"></mat-icon>
        <span>English</span>
      </button>

      <button mat-menu-item>
        <mat-icon svgIcon="flag:germany"></mat-icon>
        <span>German</span>
      </button>
    </mat-menu>
  </div>
</div>

<hodhod-navigation
  *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
  [class.hidden]="mobileQuery"
></hodhod-navigation>
