import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-dismiss-alert',
  templateUrl: './dismiss-alert.component.html',
  styleUrls: ['./dismiss-alert.component.scss']
})
export class DismissAlertComponent implements OnInit {

  @Input() message: string = '';
  @Input() isError: boolean = true;
  constructor() { }

  ngOnInit() {
  }
}
