<hodhod-page-layout>
    <hodhod-secondary-toolbar [current]="'NavigationBar.ActionsTracker' | translate">
        <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
    </hodhod-secondary-toolbar>
    <hodhod-page-layout-content class="container-fluid">
        <!-- <app-action
        [sourceId]="6"
        [sourceType]="ReportingType.Voilation"
        ></app-action> -->
        <app-action-tracker></app-action-tracker>
    </hodhod-page-layout-content>
</hodhod-page-layout>