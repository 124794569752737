import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { UserRole } from '../../models/user-role';


@UntilDestroy()
@Component({
  selector: 'app-users-in-role',
  templateUrl: './users-in-role.component.html',
  styleUrls: ['./users-in-role.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    scaleFadeIn400ms
  ],
})
export class UsersInRoleComponent implements OnInit, OnChanges {

  @Input() users: UserRole[] = [];
  usersInRole: UserRole[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  columns: TableColumn<UserRole>[] = [
    { label: 'Full Name', property: 'fullName', type: 'text', visible: true, cssClasses: ['font-medium', 'w-auto'] },
    { label: 'Reports To', property: 'reportsTo', type: 'text', visible: true, cssClasses: ['font-medium', 'w-auto'] },
    { label: 'Email', property: 'email', type: 'text', visible: true, cssClasses: ['font-medium', 'w-auto'] },
    { label: 'Actions', property: 'actions', type: 'button', visible: false, cssClasses: ['w-3']},
    { label: 'Verification', property: 'emailVerification', type: 'text', visible: false },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<UserRole>([]);
  selection = new SelectionModel<UserRole>(true, []);
  searchCtrl = new UntypedFormControl();
  SharedConstants = SharedConstants;

  private translationsList: any = {};
  private destroy$ = new Subject();
  constructor(
    private translate: TranslateService,
    private router: Router,
  ) {
    this.translate.get(['Errors', 'Success', 'confirmDeactiveRecord']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
      this.translationsList = translations;
    });
   }
   get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.getData())
      )
      .subscribe();

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe((value: any) => this.onFilterChange(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['users']) {        
        this.getData();
      }
  }

  viewUser(userId:string){
    this.router.navigate([
      '/' +
      SharedConstants.USER_MANAGEMENT +
      '/' +
      SharedConstants.VIEW_USER +
      '/' +
      userId,
    ]);    
  }

  getData() {
    this.usersInRole = [...this.users];
    this.dataSource = new MatTableDataSource(this.usersInRole);
    this.paginator.length = this.usersInRole.length;
    this.dataSource.sort = this.sort;
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.dataSource.filter = value;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  removeUser(event: any, id: string): void {
  }

}