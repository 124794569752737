import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { isThisSecond } from 'date-fns';
import { takeUntil } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { EmailValidator, noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { Confirmation, ConfirmationType } from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { AuthService } from 'src/backend/services/auth.service';
import { User } from '../../models/user';
import { UserDetails } from '../../models/user-details';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class UserProfileComponent extends BaseComponent implements OnInit {
  @Input() profileData!: UserDetails;
  @ViewChild('profileChangeModal') changeProfileModal!: TemplateRef<any>;
  @ViewChild('emailChangeModal') changeEmailModal!: TemplateRef<any>;
  public data: UserDetails;
  userForm: FormGroup;
  emailChangeForm: FormGroup;
  profileImageForm: FormGroup;
  sessionUser: LoggedUser;
  userRoles: any;
  showEmailChange: boolean = false;
  public VERIFIED_COLOR = '#67DD2D';
  public UNVERIFIED_COLOR = '#F76F45';
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  private translationsList: any = {};
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private baseApi: BaseApi
  ) { 
    super();
    this.translate.get(['Errors', 'Success','User', 'deleteVoilation', 'StaticPages', 'General']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
      this.translationsList = translations;
    });
  }

  override ngOnInit(): void {
    this.getSessionUser();
    this.profileImageForm = this.fb.group({
      profileImage: [[]]
    });
    this.emailChangeForm = this.fb.group({
      email: ['', [Validators.required, noWhitespaceValidator, EmailValidator]],
    });
  }

  getSessionUser(): void{
    this.sessionUser = this.baseApi.getUserSession();
    this.userRoles = this.sessionUser.roles;
    this.userRoles = this.userRoles.filter(x => SharedConstants.ADMIN_ROLES.includes(x));
    if(this.userRoles.length > 0){
      this.showEmailChange = true
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.userForm = this.fb.group({
      userCode: [''],
      firstName: [''],
      lastName: [''],
      phoneNumber: [''],
      email: [''],
      reportTo: [''],
      idNumber: ['']
    });
    
    if (changes['profileData']) {
      this.data = this.profileData;
      if(this.data){
        this.getUserInfo();
      }
    }
  }

  getUserInfo(): void {
    this.userForm.get('userCode')?.setValue(this.data?.userCode);
    this.userForm.get('firstName')?.setValue(this.data?.firstName);
    this.userForm.get('lastName')?.setValue(this.data?.lastName);
    this.userForm.get('phoneNumber')?.setValue(this.data?.phoneNumber);
    this.userForm.get('email')?.setValue(this.data?.email); 
    this.userForm.get('reportTo')?.setValue(this.data?.reportTo);  
    this.userForm.get('idNumber')?.setValue(this.data?.idNumber);  
    this.sectionState = SectionStateStatus.Ready;      
  }

  openChangeProfileModal(): void{
    this.dialog.open(this.changeProfileModal, {height: 'auto', width: '50%'});
  }
  openChangeEmailModal(): void{
    this.emailChangeForm.get('email').setValue('');
    this.dialog.open(this.changeEmailModal, {height: 'auto', width: '30%'});
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  changeProfile(){
    this.confirmationService
    .confirm(
      new Confirmation(
        ConfirmationType.NonDestructiveAction,
        this.translationsList['User']['AddConfirm']['Title'],
        this.translationsList['User']['AddConfirm']['Message']
      )
    )
    .then((value) => {
      if (value === true) {
        this.loadingService.startLoading(true, '');
        const {profileImage} = this.profileImageForm.value;
        const form = new FormData();
        form.append('profile', profileImage[0]);
        form.append('userId', this.data?.userId);
        this.authService.changeUserProfile(form).subscribe({
          next: (response) => {
            this.data.userImage = response?.imagePath;
            if(this.sessionUser){
              this.sessionUser.picture = response?.imagePath;
              this.baseApi.saveUserSession(this.sessionUser);
            }
            this.profileImageForm.get('profileImage').setValue([]);
            this.loadingService.stopLoading();
            this.closeDialog();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Success, response?.message)
            );
          },
          error: ({error}) => {
            this.loadingService.stopLoading();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
          }
        })
      }
    });  
  }

  changeEmail(){
    this.confirmationService
    .confirm(
      new Confirmation(
        ConfirmationType.NonDestructiveAction,
        this.translationsList['User']['AddConfirm']['Title'],
        this.translationsList['User']['AddConfirm']['Message']
      )
    )
    .then((value) => {
      if (value === true) {
        this.loadingService.startLoading(true, '');
        const data = {
          userId: this.data?.userId,
          email: this.emailChangeForm.value.email
        }
        this.authService.changeUserEmail(data).subscribe({
          next: (response) => {
            this.data.email = response?.email;
            this.userForm.get('email')?.setValue(this.data?.email);
            this.emailChangeForm.get('email').setValue('');
            this.loadingService.stopLoading();
            this.closeDialog();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Success, response?.message)
            );
          },
          error: ({error}) => {
            this.loadingService.stopLoading();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
          }
        })
      }
    });  
  }
}
