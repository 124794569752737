import { constants } from 'buffer';
import { environment } from 'src/environments/environment';

export class Constants {
  static AUTH_TOKEN = 'auth_token';
  static Refresh_TOKEN = 'refresh_token';
  static SESSION_ORGANIZATION = 'organization';
  static CURRENT_COMPANY_ID = 'company_id';
  static CURRENT_COMPANY = 'company';
  static LOGGED_IN_USER = 'logged_in_user';
  static USER_MENUS = 'user_menus';
  static USER_PERMISSIONS = 'user_permissions';
  static APP_LANGUAGE_COOKIE_KEY = 'appLang';
  static PAGE_SIZE = 10;
  static PAGE_OPTIONS = [5, 10, 20, 50];
  static NOTIFICATION_LIMIT = 10;
  static APP_CREATE_ACTION = 'Create';
  static APP_CLOSE_ACTION = 'Close';
  static APP_YESORNO_ACTION = 'YesOrNo';
  static APP_SELECT_ACTION = 'Select';
  static APP_INVESTIGATE_ACTION = 'Investigate';
  static APP_APPROVE_REJECT_ACTION = 'Approveorreject';
  static APP_PERFORM_ACTION = 'Perform';
  static APP_EXECUTE_ACTION = 'Execute';
  static APP_NEEDACTION_ACTION = 'Need Action';
  static APP_ACTION = 'Action';
  static APP_EXTEND_ACTION = 'Extend';
  static APP_EXTENDED_ACTION = 'Extended';
  static WORKFLOW_HAZARD_SCREEN = 'Hazard Report';
  static WORKFLOW_NEARMISS_SCREEN = 'Near Miss Report';
  static WORKFLOW_INCIDENT_SCREEN = 'Incident Report';
  static WORKFLOW_AUDIT_SCREEN = 'Audit Work Flow Screen';
  static WORKFLOW_WORKPERMIT_SCREEN = 'Work Permit Screen';

  static USER_DEVICE_KEY = 'UDK';
  // loading labels
  static LOADING_DATA = 'Refreshing';
  static ACTION_TRACKER_LOADING = 'Action Tracker Loading';

  // api routes configurations
  static API_VERSION = environment.api_version;
  static API_BASE_URL = environment.api_baseurl;
  static API_PREFIX = environment.api_prefix;
  static API_PREFIX_SMARTMONITORING = environment.api_prefix_smartmonitoring;
  static API_ACCESS_KEY_SMARTMONITORING =
    environment.api_access_key_smartMonitoring;
  static API_WORKFLOW_BASEURL = environment.api_workflow_url;
  static WORK_FLOW_COMPANY_KEY = environment.work_flow_company_key;
  static API_BASE_URL_CHATBOT = environment.api_chatbot_url;

  static API_BASE_URL_GENERAL = Constants.API_BASE_URL + Constants.API_PREFIX;
  static API_BASE_URL_SMARTMONITORING =
    Constants.API_BASE_URL + Constants.API_PREFIX_SMARTMONITORING;

  static API_SIGN_IN = Constants.API_BASE_URL_GENERAL + 'authentication/signIn';
  static API_SIGN_IN_WITHOUT_PASSWORD =
    Constants.API_BASE_URL_GENERAL + 'authentication/loginbycompany';
  static API_SIGN_IN_User =
    Constants.API_BASE_URL_GENERAL + 'authentication/loginbyuser';
  static API_SIGN_IN_User_Token =
    Constants.API_BASE_URL_GENERAL + 'authentication/loginbyusertoken';
  static API_REFRESH_TOKEN =
    Constants.API_BASE_URL_GENERAL + 'authentication/refreshToken';
  static API_CONFIRM_EMAIL =
    Constants.API_BASE_URL_GENERAL + 'authentication/verifyAccount';
  static API_FORGOT_PASSWORD =
    Constants.API_BASE_URL_GENERAL + 'authentication/forgotPassword';
  static API_RESET_PASSWORD =
    Constants.API_BASE_URL_GENERAL + 'authentication/resetPassword';
  static API_RESEND_VERIFICATION_CODE =
    Constants.API_BASE_URL_GENERAL + 'authentication/resetVerificationToken';
  static API_RESEND_VERIFICATION_CODE_COMPANY =
    Constants.API_BASE_URL_GENERAL +
    'authentication/resetVerificationTokenForCompany';
  static API_RESEND_VERIFICATION_CODE_ORGANIZATION =
    Constants.API_BASE_URL_GENERAL +
    'authentication/resetVerificationTokenForOranization';
  static API_CHANGE_USER_PROFILE =
    Constants.API_BASE_URL_GENERAL + 'authentication/changeUserProfile';
  static API_CHANGE_USER_EMAIL =
    Constants.API_BASE_URL_GENERAL + 'authentication/changeUerEmail';

  // features routes
  static API_FEATURES =
    Constants.API_BASE_URL_GENERAL + 'feature/getAllFeatures';
  static API_FEATURES_BY_ORG =
    Constants.API_BASE_URL_GENERAL + 'feature/getFeaturesByOrg';
  static API_FEATURES_BY_COMP =
    Constants.API_BASE_URL_GENERAL + 'feature/getFeaturesByComp';

  // organization routes

  static API_ORGANIZATION_CREATE =
    Constants.API_BASE_URL_GENERAL + 'organization/createOrganization';
  static API_ORGANIZATION_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'organization/getAllOrganizations';
  static API_ORGANIZATION_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'organization/getOrganizationById';
  static API_ORGANIZATION_DETAILS =
    Constants.API_BASE_URL_GENERAL + 'organization/getOrganizationDetails';
  static API_ORGANIZATION_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'organization/activate';
  static API_ORGANIZATION_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'organization/deactivate';
  static API_ORGANIZATION_EDIT =
    Constants.API_BASE_URL_GENERAL + 'organization/updateOrganization';

  //AccessControlUserManagement
  static API_ORGANIZATION_LICENSE_INFORMATION =
    Constants.API_BASE_URL_GENERAL + 'AccessControlQuota/LicenseInformation';
  static API_ORGANIZATION_LICENSE_CARD =
    Constants.API_BASE_URL_GENERAL + 'AccessControlQuota/ShowOrganizationCard';
  static API_COMPANY_LICENSE_CARD =
    Constants.API_BASE_URL_GENERAL + 'AccessControlQuota/ShowCompanyCard';

  // company routes

  static API_COMPANY_CREATE =
    Constants.API_BASE_URL_GENERAL + 'company/createCompany';
  static API_COMPANY_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'company/getAllCompaniesByOrg';
  static API_COMPANY_GET_ALL_FOR_DROPDOWN =
    Constants.API_BASE_URL_GENERAL + 'company/getAllCompaniesByOrgId';
  static API_COMPANY_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'company/getCompanyById';
  static API_COMPANY_DETAILS =
    Constants.API_BASE_URL_GENERAL + 'company/getCompanyDetails';
  static API_COMPANY_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'company/activate';
  static API_COMPANY_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'company/deactivate';
  static API_COMPANY_EDIT =
    Constants.API_BASE_URL_GENERAL + 'company/updateCompany';
  static API_COMPANY_DELETE =
    Constants.API_BASE_URL_GENERAL + 'company/deleteCompany';

  //department routes

  static API_DEPARTMENT_CREATE =
    Constants.API_BASE_URL_GENERAL + 'Departments/PostDepartment';
  static API_DEPARTMENT_EDIT =
    Constants.API_BASE_URL_GENERAL + 'Departments/UpdateDepartment';
  static API_DEPARTMENT_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'Departments/getAllDepartments';
  static API_SUBDEPARTMENT_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'Departments/GetSubDepartmentList';
  static API_DEPARTMENT_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Departments/activate';
  static API_DEPARTMENT_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Departments/deactivate';
  static API_DEPARTMENT_DELETE =
    Constants.API_BASE_URL_GENERAL + 'Departments/DeleteDepartment';
  static API_DEPARTMENT_COMPANY_ALL =
    Constants.API_BASE_URL_GENERAL + 'Departments/getCompanyDepartments';
  static API_DEPARTMENT_GET_DEPARTMENTS_PARENT_CHILD =
    Constants.API_BASE_URL_GENERAL + 'Departments/getDepartmentParentChild';
  static API_DEPARTMENT_GET_DEPARTMENTS_PARENT_CHILD_BY_COMPANY_CODE =
    Constants.API_BASE_URL_GENERAL +
    'Departments/getDepartmentParentChildByCompanyCode';
  static API_DEPARTMENT_GET_DEPARTMENTS_PARENT_CHILD_BY_USER_ID =
    Constants.API_BASE_URL_GENERAL +
    'Departments/getDepartmentParentChildByUserId';
  static API_DEPARTMENT_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'Departments/getDepartmentById';
  static API_SUBDEPARTMENTS_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'Departments/SubDepartmentList';

  // incident-category routes
  static API_INCIDENT_CATEGORY_CREATE =
    Constants.API_BASE_URL_GENERAL + 'TypeOfIncident/PostTypeOfIncidents';
  static API_INCIDENT_CATEGORY_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'TypeOfIncident/GetTypeOfIncidentsList';
  static API_INCIDENT_CATEGORY_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'TypeOfIncident/UpdateTypeOfIncidents';
  static API_INCIDENT_CATEGORY_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'TypeOfIncident/Activate';
  static API_INCIDENT_CATEGORY_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'TypeOfIncident/DeActivate';
  static API_INCIDENT_CATEGORY_LIST =
    Constants.API_BASE_URL_GENERAL + 'TypeOfIncident/getAllTypeOfIncidents';

  // incident-classification routes
  static API_INCIDENT_CLASSIFICATION_CREATE =
    Constants.API_BASE_URL_GENERAL +
    'IncidentClassification/PostIncidentClassifications';
  static API_INCIDENT_CLASSIFICATION_GET_ALL =
    Constants.API_BASE_URL_GENERAL +
    'IncidentClassification/GetAllIncidentClassification';
  static API_INCIDENT_CLASSIFICATION_EDIT =
    Constants.API_BASE_URL_GENERAL +
    'IncidentClassification/UpdateIncidentClassification';
  static API_INCIDENT_CLASSIFICATION_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'IncidentClassification/Activate';
  static API_INCIDENT_CLASSIFICATION_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'IncidentClassification/DeActivate';
  static API_INCIDENT_CLASSIFICATION_LIST =
    Constants.API_BASE_URL_GENERAL +
    'IncidentClassification/getIncidentClassificationsByCompany';
  //penalty-management
  static API_CREATE_PENALTY_MANAGEMENT =
    Constants.API_BASE_URL_GENERAL + 'PenaltyManagement/createPenalty';
  static API_EDIT_PENALTY_MANAGEMENT =
    Constants.API_BASE_URL_GENERAL + 'PenaltyManagement/editPenaltyById';
  static API_PENALTY_MANAGEMENT_DETAILS =
    Constants.API_BASE_URL_GENERAL + 'PenaltyManagement/getPenaltyById';
  static API_PENALTY_MANAGEMENT_APPROVERS =
    Constants.API_BASE_URL_GENERAL + 'PenaltyManagement/getApproversByCompany';

  static API_PENALTY_MANAGEMENT_DELETE_ATTACHMENT =
    Constants.API_BASE_URL_GENERAL + 'PenaltyManagement/deleteAttachement';

  // loss-mode routes
  static API_LOSS_MODE_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'LossMode/GetAllLossModes';
  static API_LOSS_MODE_POST =
    Constants.API_BASE_URL_GENERAL + 'LossMode/PostLossMode';
  static API_LOSS_MODE_EDIT =
    Constants.API_BASE_URL_GENERAL + 'LossMode/UpdateLossMode';
  static API_LOSS_MODE_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'LossMode/DeActivate';
  static API_LOSS_MODE_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'LossMode/Activate';
  static API_LOSS_MODES_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'LossMode/getLossModeByCompany';

  //machine-equipment routes
  static API_MACHINE_EQUIPMENT_FILTERED =
    Constants.API_BASE_URL_GENERAL +
    'machineAndEquipment/getFilteredMachineAndEquipments';
  static API_MACHINE_EQUIPMENT_ALL =
    Constants.API_BASE_URL_GENERAL +
    'machineAndEquipment/getAllMachineAndEquipmentByCompany';
  static API_MACHINE_EQUIPMENT_POST =
    Constants.API_BASE_URL_GENERAL +
    'machineAndEquipment/createMachineAndEquipment';
  static API_MACHINE_EQUIPMENT_EDIT =
    Constants.API_BASE_URL_GENERAL +
    'machineAndEquipment/updateMachineAndEquipment';
  static API_EQUIPMENT_EDIT =
    Constants.API_BASE_URL_GENERAL + 'Equipment/editEquipment';
  static API_CREATE_EQUIPMENT =
    Constants.API_BASE_URL_GENERAL + 'Equipment/createEquipment';
  static API_MACHINE_EQUIPMENT_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'machineAndEquipment/deactivate';
  static API_MACHINE_EQUIPMENT_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'machineAndEquipment/activate';
  static API_MACHINE_PARAMETERS_CREATE =
    Constants.API_BASE_URL_GENERAL +
    'machineAndEquipment/createMachineParameters';
  static API_MACHINE_PARAMETERS_EDIT =
    Constants.API_BASE_URL_GENERAL +
    'machineAndEquipment/updateMachineParameters';
  static API_MACHINE_EQUIPMENT_BY_ID =
    Constants.API_BASE_URL_GENERAL +
    'machineAndEquipment/getMachineEquipmentById';
  static API_EQUIPMENT_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'Equipment/getEquipmentById';
  static API_CATEGORY_BY_ID =
    Constants.API_BASE_URL_GENERAL +
    'EquipmentCategory/getEquipmentCategoryById';
  static API_EQUIPMENT_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Equipment/Activate';
  static API_EQUIPMENT_REACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'InspectionManagement/Reviewer/Reactivate';
  static API_EQUIPMENT_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Equipment/DeActivate';
  static API_MACHINE_EQUIPMENT_LIST_BY_CATEGORY =
    Constants.API_BASE_URL_GENERAL +
    'Equipment/getFilteredEquipmentListByCategoryId';
  static API_CURRENT_INSPECTIONS_BY_CATEGORY =
    Constants.API_BASE_URL_GENERAL +
    'InspectionManagement/Inspector/getCurrentInspectionsByCategoryId';
  static API_REVIEWER_LIST_OF_EQUIPMENTS =
    Constants.API_BASE_URL_GENERAL +
    'InspectionManagement/Reviewer/GetReviewerListOfEquipment';
  static API_MACHINE_EQUIPMENT_CHECK_MACHINE_HAS_PARAMETER =
    Constants.API_BASE_URL_GENERAL +
    'machineAndEquipment/checkMachineHasParameter';
  static API_CREATE_EQUIPMENT_CATEGORY =
    Constants.API_BASE_URL_GENERAL +
    'EquipmentCategory/createEquipmentCategory';
  static API_GET_EQUIPMENTS_CATEGORIES =
    Constants.API_BASE_URL_GENERAL +
    'EquipmentCategory/getEquipmentCategoryList';
  static API_GET_EQUIPMENTS_CATEGORIES_BY_ID =
    Constants.API_BASE_URL_GENERAL +
    'EquipmentCategory/getEquipmentCategoryById';
  static API_UPDATE_EQUIPMENTS_CATEGORY =
    Constants.API_BASE_URL_GENERAL + 'EquipmentCategory/editEquipmentCategory';
  static API_ACTIVATE_EQUIPMENT_CATEGORY =
    Constants.API_BASE_URL_GENERAL + 'EquipmentCategory/Activate';
  static API_DEACTIVATE_EQUIPMENT_CATEGORY =
    Constants.API_BASE_URL_GENERAL + 'EquipmentCategory/DeActivate';
  static API_DELETE_ATTACHMENT =
    Constants.API_BASE_URL_GENERAL + 'EquipmentCategory/deleteAttachement';
  static API_GET_REVIEWERS =
    Constants.API_BASE_URL_GENERAL +
    'EquipmentCategory/getEquipmentCategoryReviewers';
  static API_GET_INSPECTORS =
    Constants.API_BASE_URL_GENERAL +
    'EquipmentCategory/getEquipmentCategoryInspectors';
  // static API_MACHINE_PARAMETERS_BY_MACHINE =
  //   Constants.API_BASE_URL_GENERAL +
  //   'machineAndEquipment/getParametersByMachine';

  // nature-of-injuiry routes
  static API_NATURE_OF_INJUIRY_POST =
    Constants.API_BASE_URL_GENERAL + 'NatureofInjuiry/PostNatureOfInjuiry';
  static API_NATURE_OF_INJUIRY_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'NatureofInjuiry/getAllNatureOfInjury';
  static API_NATURE_OF_INJUIRY_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'NatureofInjuiry/UpdateNatureOfInjuiry';
  static API_NATURE_OF_INJUIRY_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'NatureofInjuiry/DeActivate';
  static API_NATURE_OF_INJUIRY_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'NatureofInjuiry/Activate';
  static API_NATURE_OF_INJUIRY_GET_BODY_PARTS =
    Constants.API_BASE_URL_GENERAL + 'NatureofInjuiry/GetBodyParts';
  static API_NATURE_OF_INJUIRY_LIST =
    Constants.API_BASE_URL_GENERAL +
    'NatureofInjuiry/getNatureOfInjuriesByCompany';

  // root-cause routes
  static API_ROOT_CAUSE_POST =
    Constants.API_BASE_URL_GENERAL + 'RootCause/PostRootCause';
  static API_ROOT_CAUSE_EDIT =
    Constants.API_BASE_URL_GENERAL + 'RootCause/UpdateRootCause';
  static API_ROOT_CAUSE_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'RootCause/GetAllRootCauses';
  static API_ROOT_CAUSE_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'RootCause/DeActivate';
  static API_ROOT_CAUSE_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'RootCause/Activate';
  static API_ROOT_CAUSES_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'RootCause/getRootCauseByCompany';
  // roles routes

  static API_ROLE_CREATE = Constants.API_BASE_URL_GENERAL + 'role/createRole';
  static API_ROLE_GET_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'role/getRolesByCompany';
  static API_ROLE_GET_ALL = Constants.API_BASE_URL_GENERAL + 'role/getRoles';
  static API_ROLE_BY_ID = Constants.API_BASE_URL_GENERAL + 'role/getRoleById';
  static API_ROLE_DETAILS =
    Constants.API_BASE_URL_GENERAL + 'Role/getRoleDetails';
  static API_ROLE_ACTIVATE = Constants.API_BASE_URL_GENERAL + 'role/activate';
  static API_ROLE_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'role/deactivate';
  static API_ROLE_EDIT = Constants.API_BASE_URL_GENERAL + 'role/updateRole';
  static API_ROLE_SAVE_PERMISSIONS =
    Constants.API_BASE_URL_GENERAL + 'role/updateRoleWithPermissions';
  static API_NEW_ROLE_PERMISSIONS =
    Constants.API_BASE_URL_GENERAL + 'role/createRoleWithPermissions';

  // reasons routes

  static API_REASON_CREATE =
    Constants.API_BASE_URL_GENERAL + 'Reason/PostReason';
  static API_REASON_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'Reason/UpdateReason';
  static API_REASON_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'Reason/GetReasonsList';
  static API_REASON_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Reason/DeActivate';
  static API_REASON_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Reason/Activate';
  static API_REASON_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'Reason/getReasonsByCompany';

  // hazards routes

  static API_HAZARD_CREATE =
    Constants.API_BASE_URL_GENERAL + 'Hazards/PostHazard';
  static API_HAZARD_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'Hazards/UpdateHazard';
  static API_HAZARD_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'Hazards/GetHazardsList';
  static API_HAZARD_HAZARDLIST =
    Constants.API_BASE_URL_GENERAL + 'Hazards/GetAllHazards';
  static API_HAZARD_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Hazards/DeActivate';
  static API_HAZARD_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Hazards/Activate';

  // reporting routes

  static API_REPORTING_REPORTINGLIST =
    Constants.API_BASE_URL_GENERAL + 'ReportingType/GetAllReportingType';

  // work condition routes

  static API_WORK_CONDITION_CREATE =
    Constants.API_BASE_URL_GENERAL + 'WorkConditions/PostWorkConditionsList';
  static API_WORK_CONDITION_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'WorkConditions/GetWorkConditionsList';
  static API_WORK_CONDITION_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'WorkConditions/PutWorkConditionsList';
  static API_WORK_CONDITION_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'WorkConditions/DeActivate';
  static API_WORK_CONDITION_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'WorkConditions/Activate';
  static API_WORK_CONDITIONS_CHECKLIST =
    Constants.API_BASE_URL_GENERAL +
    'WorkConditions/getWorkConditionsWithExecutionCheckList';

  // emergency contacts routes

  static API_EMERGENCY_CONTACTS_CREATE =
    Constants.API_BASE_URL_GENERAL + 'EmergencyContacts/PostEmergencyContact';
  static API_EMERGENCY_CONTACTS_GET_ALL =
    Constants.API_BASE_URL_GENERAL +
    'EmergencyContacts/GetAllEmergencyContacts';
  static API_EMERGENCY_CONTACTS_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'EmergencyContacts/UpdateEmergencyContact';
  static API_EMERGENCY_CONTACTS_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'EmergencyContacts/DeActivate';
  static API_EMERGENCY_CONTACTS_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'EmergencyContacts/Activate';
  static API_EMERGENCY_CONTACTS_DELETE =
    Constants.API_BASE_URL_GENERAL + 'EmergencyContacts/deleteEmergencyContact';

  // users routes

  static API_USER_GET_LIST =
    Constants.API_BASE_URL_GENERAL + 'authentication/getUsersByCompany';
  static API_USER_REPORT_USERS =
    Constants.API_BASE_URL_GENERAL + 'authentication/getAllCompanyUsers';
  static API_USER_CREATE =
    Constants.API_BASE_URL_GENERAL + 'authentication/register';
  static API_USER_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'authentication/updateUser';
  static API_USER_DETAILS =
    Constants.API_BASE_URL_GENERAL + 'authentication/getUserDetails';
  static API_USER_SAVE_PERMISSIONS =
    Constants.API_BASE_URL_GENERAL + 'authentication/updateUserPermissions';
  static API_USER_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'authentication/activate';
  static API_USER_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'authentication/deactivate';
  static API_USER_DELETE =
    Constants.API_BASE_URL_GENERAL + 'authentication/deleteUser';
  static API_GET_USERS_HIERARCHY =
    Constants.API_BASE_URL_GENERAL + 'authentication/getUsersHierarchy';

  // value types routes
  static API_VALUETYPES_LIST_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'valueType/getAllValueTypesByCompany';
  static API_VALUETYPES_FILTERED_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'valueType/getFilteredValueTypesByCompany';
  static API_VALUETYPES_CREATE =
    Constants.API_BASE_URL_GENERAL + 'valueType/createValueType';
  static API_VALUETYPES_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'valueType/updateValueType';
  static API_VALUETYPES_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'valueType/activate';
  static API_VALUETYPES_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'valueType/deactivate';
  static API_VALUETYPES_By_Id =
    Constants.API_BASE_URL_GENERAL + 'valueType/getValueTypeId';

  // unit of measures routes
  static API_UNITOFMEASURES_LIST_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL +
    'unitOfMeasure/getAllUnitOfMeasuresByCompany';
  static API_UNITOFMEASURES_FILTERED_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL +
    'unitOfMeasure/getFilteredUnitOfMeasuresByCompany';
  static API_UNITOFMEASURES_CREATE =
    Constants.API_BASE_URL_GENERAL + 'unitOfMeasure/createUnitOfMeasure';
  static API_UNITOFMEASURES_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'unitOfMeasure/updateUnitOfMeasure';
  static API_UNITOFMEASURES_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'unitOfMeasure/activate';
  static API_UNITOFMEASURES_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'unitOfMeasure/deactivate';
  static API_UNITOFMEASURES_By_Id =
    Constants.API_BASE_URL_GENERAL + 'unitOfMeasure/getUnitOfMeasureId';

  // PPE routes
  static API_ALL_PPES_LIST_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'PPE/getAllPPEsByCompany';
  static API_ACTIVE_PPES_LIST_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'PPE/getActivePPEsByCompany';
  static API_PPE_CREATE = Constants.API_BASE_URL_GENERAL + 'PPE/CreatePPE';
  static API_PPE_UPDATE = Constants.API_BASE_URL_GENERAL + 'PPE/EditPPE';
  static API_PPE_ACTIVATE = Constants.API_BASE_URL_GENERAL + 'PPE/Activate';
  static API_PPE_DEACTIVATE = Constants.API_BASE_URL_GENERAL + 'PPE/DeActivate';

  // Shifts routes
  static API_ALL_SHIFT_LIST_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'Shift/getAllShiftsByCompany';
  static API_ACTIVE_SHIFT_LIST_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'Shift/getActiveShiftsByCompany';
  static API_SHIFT_CREATE =
    Constants.API_BASE_URL_GENERAL + 'Shift/CreateShift';
  static API_SHIFT_UPDATE = Constants.API_BASE_URL_GENERAL + 'Shift/EditShift';
  static API_SHIFT_ACTIVATE = Constants.API_BASE_URL_GENERAL + 'Shift/Activate';
  static API_SHIFT_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Shift/DeActivate';
  //Areas
  static API_ALL_AREA_LIST_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'Area/GetAreaList';
  static API_AREA_CREATE = Constants.API_BASE_URL_GENERAL + 'Area/CreateArea';
  static API_AREA_UPDATE = Constants.API_BASE_URL_GENERAL + 'Area/EditArea';
  static API_AREA_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'area/ActivateArea';
  static API_AREA_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Area/DeactivateArea';
  //Areas
  static API_ALL_TEAM_LIST_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'Team/GetTeamsList';
  static API_TEAM_CREATE = Constants.API_BASE_URL_GENERAL + 'Team/CreateTeam';
  static API_TEAM_UPDATE = Constants.API_BASE_URL_GENERAL + 'Team/EditTeam';
  static API_TEAM_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Team/ActivateTeam';
  static API_TEAM_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'Team/DeactivateTeam';

  // checkList section routes
  static API_CHECKLIST_CREATE =
    Constants.API_BASE_URL_GENERAL + 'CheckListSection/PostCheckList';
  static API_CHECKLIST_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'CheckListSection/UpdateCheckList';
  static API_CHECKLIST_SECTION_CREATE =
    Constants.API_BASE_URL_GENERAL + 'CheckListSection/PostCheckListSection';
  static API_CHECKLIST_SECTION_EDIT =
    Constants.API_BASE_URL_GENERAL + 'CheckListSection/UpdateCheckListSection';
  static API_CHECKLIST_SECTION_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'CheckListSection/GetAllCheckList';
  static API_CHECKLIST_SECTION_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'CheckListSection/GetCheckListById';
  static API_CHECKLIST_SECTION_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'CheckListSection/Activate';
  static API_CHECKLIST_SECTION_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'CheckListSection/DeActivate';
  static API_ALl_CHECKLIST_DROPDOWN =
    Constants.API_BASE_URL_GENERAL +
    'CheckListSection/GetAllCheckListForDropdown';
  static API_ALl_CHECKLIST_DELETE =
    Constants.API_BASE_URL_GENERAL + 'CheckListSection/DeleteCheckList';
  static API_ALl_CHECKLIST_BULK_UPLOAD =
    Constants.API_BASE_URL_GENERAL + 'CheckListSection/checkListBulkUpload';
  // manage id card routes
  static API_ID_CARD_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'IdCard/GetAllIdCards';
  static API_ID_CARD_CREATE =
    Constants.API_BASE_URL_GENERAL + 'IdCard/PostIdCard';
  static API_ID_CARD_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'IdCard/UpdateIdCard';
  static API_ID_CARD_GET_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'IdCard/GetIdCardById';
  static API_ID_CARD_DELETE_DOCUMENT_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'IdCard/DeleteDocumentById';
  static API_RENEW_CARD_CREATE =
    Constants.API_BASE_URL_GENERAL + 'IdCard/ReNewIdCard';
  static API_RENEW_ID_CARD_GET_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'IdCard/GetReNewIdCardById';
  static API_ID_CARD_APPROVE =
    Constants.API_BASE_URL_GENERAL + 'IdCard/ApproveIdCard';
  static API_ID_CARD_REJECT =
    Constants.API_BASE_URL_GENERAL + 'IdCard/RejectIdCard';
  static API_ID_CARD_HISTORY =
    Constants.API_BASE_URL_GENERAL + 'IdCard/GetIdCardHistory';
  // manage id card routes
  static API_ACTIONS_TRACKER =
    Constants.API_BASE_URL_SMARTMONITORING +
    'actionTracker/getActionsTrackerByCompany';
  static API_ACTIONS =
    Constants.API_BASE_URL_SMARTMONITORING +
    'actionTracker/getActionsByCompany';
  static API_ACTION_CREATE =
    Constants.API_BASE_URL_SMARTMONITORING + 'actionTracker/createAction';
  static API_ACTION_EDIT =
    Constants.API_BASE_URL_SMARTMONITORING + 'ActionTracker/editActionbyId';
  static API_ACTION_REMOVE =
    Constants.API_BASE_URL_SMARTMONITORING + 'actionTracker/removeAction';
  static API_ACTION_REASSIGN =
    Constants.API_BASE_URL_SMARTMONITORING +
    'actionTracker/RemindActionTracker';
  static API_ACTION_GET_ACTION_BY_ID =
    Constants.API_BASE_URL_SMARTMONITORING + 'actionTracker/getActionsById';
  static API_ACTION_CLOSE =
    Constants.API_BASE_URL_SMARTMONITORING + 'actionTracker/closeAction';

  // hazard report routes
  static API_REPORT_HAZARDS =
    Constants.API_BASE_URL_GENERAL +
    'hazardReport/getFilteredHazardReportsByCompany';
  static API_REPORT_HAZARDS_CREATE =
    Constants.API_BASE_URL_GENERAL + 'hazardReport/createHazardReport';
  static API_REPORT_HAZARD_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'hazardReport/getHazardReportById';
  static API_REPORT_HAZARD_EDIT =
    Constants.API_BASE_URL_GENERAL + 'hazardReport/editHazardReport';
  static API_REPORT_HAZARD_REMOVE_IMG =
    Constants.API_BASE_URL_GENERAL + 'hazardReport/deleteHazardReportImage';
  static API_REPORT_HAZARD_CLOSE =
    Constants.API_BASE_URL_GENERAL + 'hazardReport/closeReportHazard';
  static API_REPORT_HAZARD_CUSTOM_USERS_HEIRARCY =
    Constants.API_BASE_URL_GENERAL + 'hazardReport/getUsersHierarchyFromHazard';
  static API_REPORT_HAZARD_DELETE =
    Constants.API_BASE_URL_GENERAL + 'hazardReport/deleteHazardReport';

  // Machine And Parameter
  static API_MACHINE =
    Constants.API_BASE_URL_GENERAL +
    'MachineAndEquipment/FetchMachineAndEquipmentForCheckList';
  static API_PARAMETER =
    Constants.API_BASE_URL_GENERAL +
    'MachineAndEquipment/FetchMachineParametersForCheckList';

  // near miss report routes
  static API_REPORT_NEAR_MISSES =
    Constants.API_BASE_URL_GENERAL +
    'nearMissReport/getFilteredNearMissReportsByCompany';
  static API_REPORT_NEAR_MISSES_CREATE =
    Constants.API_BASE_URL_GENERAL + 'nearMissReport/createNearMissReport';
  static API_REPORT_NEAR_MISSE_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'nearMissReport/getNearMissReportById';
  static API_REPORT_NEAR_MISSE_EDIT =
    Constants.API_BASE_URL_GENERAL + 'nearMissReport/editNearMissReport';
  static API_REPORT_NEAR_MISSE_REMOVE_IMG =
    Constants.API_BASE_URL_GENERAL + 'nearMissReport/deleteNearMissReportImage';
  static API_REPORT_NEAR_MISSE_HISTORY =
    Constants.API_BASE_URL_GENERAL + 'nearMissReport/getNearMissHistory';
  static API_REPORT_NEAR_MISSE_CLOSE =
    Constants.API_BASE_URL_GENERAL + 'nearMissReport/closeNearMissReport';
  static API_REPORT_CUSTOM_USERS_HIERARCHY_FROM_NEARMISS =
    Constants.API_BASE_URL_GENERAL +
    'nearMissReport/getUsersHierarchyFromNearMiss';
  static API_REPORT_INVESTIGATION_NEARMISS =
    Constants.API_BASE_URL_GENERAL + 'nearMissReport/investigateNearMissReport';
  static API_REPORT_TEAMSELECTED_NEARMISS =
    Constants.API_BASE_URL_GENERAL + 'nearMissReport/teamSelectedNearMiss';
  static API_REPORT_INVESTIGATION_COMPLETED_NEARMISS =
    Constants.API_BASE_URL_GENERAL +
    'nearMissReport/investigationCompletedNearMiss';
  static API_REPORT_TEAM_DE_SELECT_NEARMISS =
    Constants.API_BASE_URL_GENERAL + 'nearMissReport/teamDeSelectNearMiss';
  static API_REPORT_NEARMISS_STATUS_CHANGES_TO_INPROGRESS =
    Constants.API_BASE_URL_GENERAL +
    'nearMissReport/nearMissStatusUpdatedToInprogress';
  static API_REPORT_NEARMISS_TEAM_APPROVED =
    Constants.API_BASE_URL_GENERAL +
    'nearMissReport/nearMissReportTeamApproved';
  static API_REPORT_NEARMISS_DELETE =
    Constants.API_BASE_URL_GENERAL + 'nearMissReport/deleteNearMissReport';

  // near miss report routes
  static API_WORK_PERMITS =
    Constants.API_BASE_URL_GENERAL + 'workPermit/getWorkPermitsByCompany';
  static API_WORK_PERMIT_CREATE =
    Constants.API_BASE_URL_GENERAL + 'workPermit/createWorkPermit';
  static API_WORK_PERMIT_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'workPermit/getWorkPermitById';
  static API_WORK_PERMIT_EDIT =
    Constants.API_BASE_URL_GENERAL + 'workPermit/editWorkPermit';
  static API_WORK_PERMIT_REMOVE_IMG =
    Constants.API_BASE_URL_GENERAL + 'workPermit/deleteWorkPermitImage';
  static API_WORK_PERMIT_APPROVE_REJECT =
    Constants.API_BASE_URL_GENERAL + 'workPermit/approveOrRejectWorkPermit';
  static API_WORK_PERMIT_SAVE_EXTEND_DATE =
    Constants.API_BASE_URL_GENERAL + 'workPermit/addExtendDate';
  static API_WORK_PERMIT_APPROVE_EXTEND_DATE =
    Constants.API_BASE_URL_GENERAL + 'workPermit/approveExtendDate';
  static API_WORK_PERMIT_REJECT_EXTEND_DATE =
    Constants.API_BASE_URL_GENERAL + 'workPermit/rejectExtendDate';
  static API_WORK_PERMIT_CLOSE =
    Constants.API_BASE_URL_GENERAL + 'workPermit/closeWorkPermit';
  static API_WORK_PERMIT_CLOSE_REQUEST =
    Constants.API_BASE_URL_GENERAL + 'workPermit/closeWorkPermitRequest';
  static API_WORK_PERMIT_EXTENDTIME_REQUIRED =
    Constants.API_BASE_URL_GENERAL + 'workPermit/extendTimeRequired';
  static API_WORK_PERMIT_DELETE =
    Constants.API_BASE_URL_GENERAL + 'workPermit/deleteWorkPermit';
  static API_APPROVER_USER_LIST =
    Constants.API_BASE_URL_GENERAL + 'WorkPermit/getApproversByCompany';

  // Task-Tracker routes

  static API_POST_NEW_TASK =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/PostNewTask';
  static API_UPDATE_SINGLE_TASK =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/UpdateSingleTask';
  static API_UPDATE_SERIES_TASK =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/UpdateSeriesTask';
  static API_GET_MEETING_RECURRENCE =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/GetTaskRecurrence';
  static API_GET_MEETING_RECURRENCE_WITH_FILTER =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/GetTaskRecurrenceWithFilter';
  static API_GET_TASK_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/GetTaskRecurrenceById';
  static API_GET_TASK_BY_USER =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/GetTasksByUser';
  static API_GET_TASK_HISTORY =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/getTaskWithCheckListById';
  static API_EXECUTE_CHECKLIST_DATA =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/saveTaskExecution';
  static API_SUBMIT_TASK =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/submitTaskStatus';
  static API_ACTION_TASK =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/actionTaskStatus';
  static API_REJECT_TASK =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/rejectTaskStatus';
  static API_NOTIFYTOASSIGNEE_TASK =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/notifyToAssignee';
  static API_CLOSE_TASK =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/closeTaskStatus';
  static API_DELETE_TASK =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/deleteTask';
  static API_DELETE_TASK_SERIES =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/DeleteTaskSeries';
  static API_CHECK_EXECUTION =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/CheckTaskExecution';
  static API_RESCHEDULE_TASK =
    Constants.API_BASE_URL_GENERAL + 'TaskTracker/ReassignTask';

  // incident report routes
  static API_REPORT_INCIDENTS =
    Constants.API_BASE_URL_GENERAL +
    'incidentReport/getFilteredIncidentReportsByCompany';
  static API_REPORT_INCIDENT_CREATE =
    Constants.API_BASE_URL_GENERAL + 'incidentReport/createIncidentReport';
  static API_REPORT_INCIDENT_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'incidentReport/getIncidentReportById';
  static API_REPORT_INCIDENT_EDIT =
    Constants.API_BASE_URL_GENERAL + 'incidentReport/editIncidentReport';
  static API_REPORT_INCIDENT_REMOVE_IMG =
    Constants.API_BASE_URL_GENERAL + 'incidentReport/deleteIncidentReportImage';
  static API_REPORT_CUSTOM_USERS_HIERARCHY_FROM_INCIDENT =
    Constants.API_BASE_URL_GENERAL +
    'incidentReport/getUsersHierarchyFromIncident';
  static API_REPORT_INCIDENT_CLOSE =
    Constants.API_BASE_URL_GENERAL + 'incidentReport/closeIncidentReport';
  static API_REPORT_INVESTIGATION_INCIDENT =
    Constants.API_BASE_URL_GENERAL + 'incidentReport/investigateIncidentReport';
  static API_REPORT_TEAMSELECTED_INCIDENT =
    Constants.API_BASE_URL_GENERAL + 'incidentReport/teamSelectedIncident';
  static API_REPORT_INVESTIGATION_COMPLETED_INCIDENT =
    Constants.API_BASE_URL_GENERAL +
    'incidentReport/investigationCompletedIncident';
  static API_REPORT_TEAM_DE_SELECT_INCIDENT =
    Constants.API_BASE_URL_GENERAL + 'incidentReport/teamDeSelectIncident';
  static API_REPORT_INCIDENT_STATUS_CHANGES_TO_INPROGRESS =
    Constants.API_BASE_URL_GENERAL +
    'incidentReport/incidentStatusUpdatedToInprogress';
  static API_REPORT_INCIDENT_TEAM_APPROVED =
    Constants.API_BASE_URL_GENERAL +
    'incidentReport/incidentReportTeamApproved';
  static API_REPORT_INCIDENT_HISTORY =
    Constants.API_BASE_URL_GENERAL + 'incidentReport/getIncidentReportHistory';
  static API_REPORT_INCIDENT_DELETE =
    Constants.API_BASE_URL_GENERAL + 'incidentReport/deleteIncidentReport';
  static API_SAVE_REPORT_INCIDENT_LOSSES =
    Constants.API_BASE_URL_GENERAL + 'CostOfIncident/CreateCostOfIncident';
  static API_UPDATE_REPORT_INCIDENT_LOSSES =
    Constants.API_BASE_URL_GENERAL + 'CostOfIncident/EditCostOfIncident';

  // Video Analytics
  static API_VIDEO_ANALYTICS_GET_ALL =
    Constants.API_BASE_URL_SMARTMONITORING + 'Cameras/getListOfCameras';
  static API_VIDEO_ANALYTICS_ACTIVATE_CAMERA =
    Constants.API_BASE_URL_SMARTMONITORING + 'Cameras/activateCamera';
  static API_VIDEO_ANALYTICS_DEACTIVATE_CAMERA =
    Constants.API_BASE_URL_SMARTMONITORING + 'Cameras/deactivateCamera';
  static API_VIDEO_ANALYTICS_BY_ID =
    Constants.API_BASE_URL_SMARTMONITORING + 'Cameras/getCameraById';
  static API_VIDEO_ANALYTICS_UPDATE_CAMERA =
    Constants.API_BASE_URL_SMARTMONITORING + 'Cameras/configureCamera';

  // voilations
  static API_VOILATIONS_GET_ALL =
    Constants.API_BASE_URL_SMARTMONITORING + 'Voilation/getListOfVoilations';
  static API_VOILATIONS_GET_VOILATION_NAMES =
    Constants.API_BASE_URL_SMARTMONITORING + 'Voilation/getViolationNames';
  static API_VOILATIONS_DELETE_VOILATION =
    Constants.API_BASE_URL_SMARTMONITORING + 'Voilation/deleteVoilation';
  static API_VOILATIONS_UPDATE_VOILATION =
    Constants.API_BASE_URL_SMARTMONITORING + 'Voilation/updateVoilation';
  static API_VOILATIONS_GET_VOILATION_BY_ID =
    Constants.API_BASE_URL_SMARTMONITORING + 'Voilation/getVoilationById';
  static API_VIOLATION_DASHBOARD_DATA =
    Constants.API_BASE_URL_SMARTMONITORING +
    'Voilation/getViolationDashboardData';
  static API_VIOLATION_DASHBOARD_FILTERED_DATA =
    Constants.API_BASE_URL_SMARTMONITORING + 'Voilation/FilterDashBoardData';
  static API_VIOLATION_DASHBOARD_CHART_FILTERED_DATA =
    Constants.API_BASE_URL_SMARTMONITORING +
    'Voilation/GetDashBoardChartsByChartName';

  // workflow api routers start
  static API_WORKFLOW_GET_WORKFLOW =
    Constants.API_WORKFLOW_BASEURL + 'WorkFlow/getWorkFlowByScreenName';
  static API_WORKFLOW_CREATE_INSTANCE =
    Constants.API_WORKFLOW_BASEURL + 'Instance/createInstance';
  static API_WORKFLOW_SET_INSTANCE =
    Constants.API_WORKFLOW_BASEURL + 'Instance/setInstance';
  static API_WORKFLOW_UPDATE_INSTANCE =
    Constants.API_WORKFLOW_BASEURL + 'Instance/updateInstance';
  static API_WORKFLOW_UPDATE_BULK_INSTANCE =
    Constants.API_WORKFLOW_BASEURL + 'Instance/updateBulkInstance';
  static API_WORKFLOW_DISCARD_INSTANCE =
    Constants.API_WORKFLOW_BASEURL + 'Instance/discardInstance';
  static API_WORKFLOW_DELETE_INSTANCE =
    Constants.API_WORKFLOW_BASEURL + 'Instance/deleteInstance';
  static API_WORKFLOW_SET_CUSTOM_USERS =
    Constants.API_WORKFLOW_BASEURL +
    'Reminder/setCustomUsersForReminderOrEscalation';
  static API_WORKFLOW_TRANISTION_RULES =
    Constants.API_WORKFLOW_BASEURL + 'Rule/getRules';
  static API_WORKFLOW_CUSTOM_REMINDER_MESSAGE =
    Constants.API_WORKFLOW_BASEURL + 'Reminder/setReminderMessage';
  static API_WORKFLOW_CREATE_BULK_INSTANCE =
    Constants.API_WORKFLOW_BASEURL + 'Instance/createBulkInstances';
  static API_WORKFLOW_SET_BULK_INSTANCE =
    Constants.API_WORKFLOW_BASEURL + 'Instance/setBulkInstance';
  static API_WORKFLOW_DELETE_BULK_INSTANCE =
    Constants.API_WORKFLOW_BASEURL + 'Instance/deleteBulkInstance';
  static API_WORKFLOW_GET_NEXT_TRANSITION_ASSIGNEES =
    Constants.API_WORKFLOW_BASEURL + 'Instance/getNextTransitionAssignee';
  static API_WORKFLOW_GET_INSTANCE_KEY_BY_TABLE_ID =
    Constants.API_WORKFLOW_BASEURL + 'Instance/getInstanceKeyById';
  static API_WORKFLOW_GET_USER_PERMISSION =
    Constants.API_WORKFLOW_BASEURL + 'Instance/getUserPermission';
  // workflow api routers end

  // user device routes starts
  static API_USER_DEVICE_CREATE =
    Constants.API_BASE_URL_GENERAL + 'userDevice/registerUserDevice';
  static API_USER_DEVICE_EDIT =
    Constants.API_BASE_URL_GENERAL + 'userDevice/editUserDevice';
  // user device routes ends

  // investigation route
  static API_INVESTIGATION_CREATE =
    Constants.API_BASE_URL_GENERAL + 'investigation/saveInvestigation';
  static API_INVESTIGATION_UPDATE =
    Constants.API_BASE_URL_GENERAL + 'investigation/updateInvestigation';

  // notifications routes starts
  static API_NOTIFICATIONS =
    Constants.API_BASE_URL_GENERAL + 'notification/getNotificationsByUser';
  static API_NOTIFICATION_MARK_AS_READ =
    Constants.API_BASE_URL_GENERAL + 'notification/markAsReadNotification';
  static API_NOTIFICATIONS_MARK_ALL_AS_READ =
    Constants.API_BASE_URL_GENERAL + 'notification/markAllAsReadNotifications';
  static API_DELETE_ALL_NOTIFICATIONS =
    Constants.API_BASE_URL_GENERAL + 'notification/DeleteAllNotifications';
  // notifications routes ends

  // dashboard target routes
  static API_DASHBOARD_TARGET_GET_ALL =
    Constants.API_BASE_URL_GENERAL +
    'DashboardTarget/getFilterDashboardTargets';
  static API_DASHBOARD_TARGET_CREATE =
    Constants.API_BASE_URL_GENERAL + 'DashboardTarget/saveDashboardTarget';
  static API_DASHBOARD_TARGET_EDIT =
    Constants.API_BASE_URL_GENERAL + 'DashboardTarget/updateDashboardTarget';
  static API_DASHBOARD_TARGET_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'DashboardTarget/getDashboardTargetById';
  static API_DASHBOARD_TARGET_DEACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'DashboardTarget/deActivate';
  static API_DASHBOARD_TARGET_ACTIVATE =
    Constants.API_BASE_URL_GENERAL + 'DashboardTarget/activate';

  // Reporting dashboard api routers start
  static API_SAVE_VISIBILTY_DASHBOARD_BY_USER_ID =
    Constants.API_BASE_URL_GENERAL +
    'DashboardCustomization/SaveVisibilityForDashboard';
  static API_GET_CUSTOMIZE_DASHBOARD_BY_USER_ID =
    Constants.API_BASE_URL_GENERAL +
    'DashboardCustomization/GetCustomizedDashboardByUserId';
  static API_SAVE_CUSTOMIZE_DASHBOARD_BY_USER_ID =
    Constants.API_BASE_URL_GENERAL +
    'DashboardCustomization/SaveCustomFilterForDashboard';
  static API_REPORTING_DASHBOARD_DATA =
    Constants.API_BASE_URL_GENERAL + 'ReportingDashboard';
  static API_FILTER_REPORTING_DASHBOARD =
    Constants.API_BASE_URL_GENERAL +
    'ReportingDashboard/getReportingDashboardDataByChartName';
  static API_GET_COMPANY_FEATURE =
    Constants.API_BASE_URL_GENERAL + 'ReportingDashboard/GetCompanyFeatures';
  // Reporting dashboard api routers end

  // IOT dashboard data api routers start
  static API_GET_CUSTOMIZED_IOT_DASHBOARDS =
    Constants.API_BASE_URL_GENERAL +
    'IotDashboardCustomization/getAllCustomizedDashboardsByUserId';
  static API_GET_ANALOG_DASHBOARD_DATA =
    Constants.API_BASE_URL_GENERAL + 'IotDashboard/GetAnalogDashboard';
  static API_UPDATE_FILTER_FOR_IOT =
    Constants.API_BASE_URL_GENERAL +
    'IotDashboardCustomization/updateFilterForDashboard';
  static API_GET_ANALOG_MACHINES =
    Constants.API_BASE_URL_GENERAL +
    'IotAnalogDashboardCustomization/getAvailableMachines';
  static API_GET_ANALOG_PARAMETERS =
    Constants.API_BASE_URL_GENERAL +
    'IotAnalogDashboardCustomization/getAvailableParams';
  static API_CREATE_ANALOG_DASHBOARD =
    Constants.API_BASE_URL_GENERAL +
    'IotAnalogDashboardCustomization/createNewDashboard';
  static API_DELETE_ANALOG_DASHBOARD =
    Constants.API_BASE_URL_GENERAL +
    'IOTDashboardCustomization/deleteDashboardById';
  static API_GET_DIGITAL_DASHBOARD_DATA =
    Constants.API_BASE_URL_GENERAL + 'IotDashboard/GetDigitalDashboard';
  static API_UPDATE_DIGITAL_PARAMETERS =
    Constants.API_BASE_URL_GENERAL +
    'IotDigitalDashboardCustomization/updateParmetersForDashboard';
  static API_GET_DIGITAL_MACHINES =
    Constants.API_BASE_URL_GENERAL +
    'IotDigitalDashboardCustomization/getAvailableMachines';
  static API_CREATE_DIGITAL_DASHBOARD =
    Constants.API_BASE_URL_GENERAL +
    'IotDigitalDashboardCustomization/createNewDashboard';
  // IOT dashboard data api routers end
  //SafetyKpis
  static API_GET_SAFETY_TRIANGLE =
    Constants.API_BASE_URL_GENERAL + 'SafetyTriangle/getSafetyTriangleById';
  static API_GET_SAFETY_KPIS_BY_COMPANY =
    Constants.API_BASE_URL_GENERAL + 'SafetyKPIsList/getSafetyKPIsByCompany';
  static API_UPDATE_SAFETY_TRIANGLE =
    Constants.API_BASE_URL_GENERAL + 'SafetyTriangle/editSafetyTriangle';
  static API_ACTIVATE_SAFETY_TRIANGLE =
    Constants.API_BASE_URL_GENERAL + 'SafetyTriangle/activate';
  static API_DEACTIVATE_SAFETY_TRIANGLE =
    Constants.API_BASE_URL_GENERAL + 'SafetyTriangle/deactivate';
  static API_GET_SAFETY_KPI_SETUP_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'SafetyKpiSetup/GetSafetyKpiSetupById';
  static API_UPDATE_SAFETY_KPI_SETUP =
    Constants.API_BASE_URL_GENERAL + 'SafetyKpiSetup/UpdateSafetyKpiSetup';
  static API_ACTIVATE_SAFETY_KPI_SETUP =
    Constants.API_BASE_URL_GENERAL + 'SafetyKpiSetup/Activate';
  static API_DEACTIVATE_SAFETY_KPI_SETUP =
    Constants.API_BASE_URL_GENERAL + 'SafetyKpiSetup/Deactivate';

  static API_GET_SAFEMAN_HOURS =
    Constants.API_BASE_URL_GENERAL + 'SafeManHours/getSafeManHoursById';
  static API_GET_SAFEMAN_HOURS_FILTER =
    Constants.API_BASE_URL_GENERAL + 'SafetyKPIsDashboard/safeManHours';
  static API_UPDATE_SAFEMAN_HOURS =
    Constants.API_BASE_URL_GENERAL + 'SafeManHours/editSafeManHours';
  static API_RESET_SAFEMAN_HOURS =
    Constants.API_BASE_URL_GENERAL + 'SafeManHours/Reset';
  static API_ACTIVATE_SAFEMAN_HOURS =
    Constants.API_BASE_URL_GENERAL + 'SafeManHours/activate';
  static API_DEACTIVATE_SAFEMAN_HOURS =
    Constants.API_BASE_URL_GENERAL + 'SafeManHours/deactivate';
  // Safety Kpis Dashboards Apis
  static API_GET_SAFETY_TRIANGLE_DASHBOARD =
    Constants.API_BASE_URL_GENERAL + 'SafetyKPIsDashboard/safetyTriangle';
  static API_ACTIVE_SAFETYKPIs =
    Constants.API_BASE_URL_GENERAL + 'SafetyKPIsDashboard/getActiveSafetyKPIs';
  static API_GET_LTIR =
    Constants.API_BASE_URL_GENERAL +
    'SafetyKPIsDashboard/lostTimeInjuryFrequencyRate';
  static API_GET_TRIR =
    Constants.API_BASE_URL_GENERAL +
    'SafetyKPIsDashboard/totalRecordableIncidentRate';
  static API_GET_TRIFR =
    Constants.API_BASE_URL_GENERAL +
    'SafetyKPIsDashboard/totalRecordableInjuryFrequencyRate';
  static API_GET_PAGINATED_KPIS =
    Constants.API_BASE_URL_GENERAL +
    'SafetyKPIsDashboard/getPaginatedKpiDashboardIncidents';
  static API_LTI_INCIDENT =
    Constants.API_BASE_URL_GENERAL + 'SafetyKpiSetup/IsItLTIIncident';
  static API_RECORDABLE_INCIDENT =
    Constants.API_BASE_URL_GENERAL + 'SafetyKpiSetup/IsItRecordable';

  // media upload api routes
  static API_SINGLE_MEDIA_UPLOAD =
    Constants.API_BASE_URL_GENERAL + 'media/uploadSingleFile';

  // safety announcement api
  static API_GET_RECEIVER_LIST =
    Constants.API_BASE_URL_GENERAL + 'SafetyAnnouncement/GetReceiversList';
  static API_GET_SAFETY_ANNOUNCEMENTS =
    Constants.API_BASE_URL_GENERAL +
    'SafetyAnnouncement/GetSafetyAnnouncements';
  static API_GET_SAFETY_ANNOUNCEMENTS_BY_ID =
    Constants.API_BASE_URL_GENERAL +
    'SafetyAnnouncement/GetSafetyAnnouncementById';
  static API_CREATE_SAFETY_ANNOUNCEMENT =
    Constants.API_BASE_URL_GENERAL +
    'SafetyAnnouncement/createSafetyAnnouncement';
  static API_EDIT_SAFETY_ANNOUNCEMENT =
    Constants.API_BASE_URL_GENERAL +
    'SafetyAnnouncement/EditSafetyAnnouncement';
  static API_DELETE_SAFETY_ANNOUNCEMENT =
    Constants.API_BASE_URL_GENERAL + 'SafetyAnnouncement/deleteAnnouncement';
  static API_DELETE_SAFETY_ANNOUNCEMENT_ATTACHMENTS =
    Constants.API_BASE_URL_GENERAL +
    'SafetyAnnouncement/deleteAnnounceAttachments';

  // Msds api
  static API_GET_MSDS_LIST =
    Constants.API_BASE_URL_GENERAL + 'MSDS/GetListOfMSDS';
  static API_GET_MSDS_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'MSDS/GetMSDSDetailsById';
  static API_CREATE_MSDS = Constants.API_BASE_URL_GENERAL + 'MSDS/CreateMSDS';
  static API_EDIT_MSDS = Constants.API_BASE_URL_GENERAL + 'MSDS/EditMSDS';
  static API_PUBLISH_MSDS = Constants.API_BASE_URL_GENERAL + 'MSDS/PublishMSDS';
  static API_REJECT_MSDS = Constants.API_BASE_URL_GENERAL + 'MSDS/RejectMSDS';
  static API_GET_APPROVERS_LIST =
    Constants.API_BASE_URL_GENERAL + 'MSDS/ApproversList';
  static API_PENDING_APPROVERS =
    Constants.API_BASE_URL_GENERAL + 'MSDS/PendingApprovers';
  static API_APPROVE_MSDS = Constants.API_BASE_URL_GENERAL + 'MSDS/ApproveMSDS';
  static API_DELETE_MSDS = Constants.API_BASE_URL_GENERAL + 'MSDS/DeleteMSDS';
  static API_DELETE_MSDS_ATTACHMENTS =
    Constants.API_BASE_URL_GENERAL + 'MSDS/DeleteMSDSAttachment';

  // Penalty Management api
  static API_GET_APPROVER_LIST =
    Constants.API_BASE_URL_GENERAL + 'PenaltyManagement/getApproversByCompany';
  static API_GET_TEAMS_LIST =
    Constants.API_BASE_URL_GENERAL + 'Team/GetTeamsDDL';
  static API_GET_AREAS_LIST =
    Constants.API_BASE_URL_GENERAL + 'Area/GetAreaDDL';
  static API_GET_PENALTY_MANAGEMENT_LIST =
    Constants.API_BASE_URL_GENERAL +
    'PenaltyManagement/getFilteredPenaltiesByCompany';
  static API_GET_PENALTY_MANAGEMENT_BY_ID =
    Constants.API_BASE_URL_GENERAL + 'PenaltyManagement/getPenaltyById';
  static API_CLOSE_PENALTY_MANAGEMENT =
    Constants.API_BASE_URL_GENERAL + 'PenaltyManagement/closePenalty';

  // Inspection Management api
  static API_GET_INSPECTORS_INSPECTIONS =
    Constants.API_BASE_URL_GENERAL +
    'EquipmentCategory/getInspectorEquipmentCategoriesGrid';
  static API_GET_REVIEWER_INSPECTIONS =
    Constants.API_BASE_URL_GENERAL +
    'InspectionManagement/Reviewer/getReviewerEquipmentCategoriesGrid';
  static API_GET_DOWNTIME_LOGS =
    Constants.API_BASE_URL_GENERAL +
    'InspectionManagement/Inspector/EquipmentDownTimeLog';
  static API_GET_INSPECTION_LOGS =
    Constants.API_BASE_URL_GENERAL +
    'InspectionManagement/Inspector/EquipmentInspectionsLog';
  static API_GET_INSPECTION_CHECKLIST =
    Constants.API_BASE_URL_GENERAL +
    'InspectionManagement/Inspector/getInspectionWithCheckListById';
  static API_POST_EXECUTE_INSPECTION =
    Constants.API_BASE_URL_GENERAL +
    'InspectionManagement/Inspector/executeChecklist';
  static API_APPROVE_UNSAFETY =
    Constants.API_BASE_URL_GENERAL +
    'InspectionManagement/Reviewer/ApproveUnsafety';

  // Bulk user upload
  static API_INSERT_USER_IN_BULK =
    Constants.API_BASE_URL_GENERAL + 'Authentication/insertUserInBulk';
  static API_BULK_USERS_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'Authentication/getbulkUsersByCompany';
  static API_DELETE_BULK_USER =
    Constants.API_BASE_URL_GENERAL + 'Authentication/deleteBulkUser';
  static API_REGISTER_BULK_USER =
    Constants.API_BASE_URL_GENERAL + 'Authentication/registerBulkUser';

  // global search routes
  static API_GET_GLOBAL_SEARCH_RESULTS =
    Constants.API_BASE_URL_GENERAL + 'GlobalSearch/getGlobalSearchResult';
  // History Log
  static API_HISTORY_GET_ALL =
    Constants.API_BASE_URL_GENERAL + 'HistoryLog/getHistoryLog';

  // Export to Excel
  static API_EXPORT_TO_EXCEL =
    Constants.API_BASE_URL_GENERAL + 'FileExport/GetExcelFile';
  static API_EXPORT_TO_EXCEL_FILTERED =
    Constants.API_BASE_URL_GENERAL + 'FileExport/GetExcelFileFiltered';

  // Chatbot APIs
  static API_INITIALIZE_CHAT_SESSION =
    Constants.API_BASE_URL_CHATBOT + 'initialize_chat_session';

  static API_INITIALIZE_CHAT_MESSAGE =
    Constants.API_BASE_URL_CHATBOT + 'process_chat_message';

  static API_GET_CHATBOT_DOCUMENTS =
    Constants.API_BASE_URL_CHATBOT + 'view_documents';

  static API_UPLOAD_CHATBOT_DOCUMENT =
    Constants.API_BASE_URL_CHATBOT + 'upload_documents';

  static API_DOWNLOAD_CHATBOT_DOCUMENT =
    Constants.API_BASE_URL_CHATBOT + 'download_document';

  static API_DELETE_CHATBOT_DOCUMENT =
    Constants.API_BASE_URL_CHATBOT + 'delete_document';

  static API_DELETE_ALL_CHATBOT_DOCUMENTS =
    Constants.API_BASE_URL_CHATBOT + 'delete_all_documents';

  static API_SAVE_USER_FEEDBACK =
    Constants.API_BASE_URL_GENERAL + 'Chatbot/SaveUserFeedBack';
  static API_GET_TRUSTED_LINKS =
    Constants.API_BASE_URL_CHATBOT + 'view_trusted_links';
  static API_REMOVE_TRUSTED_LINKS =
    Constants.API_BASE_URL_CHATBOT + 'remove_trusted_link';
  static API_ADD_TRUSTED_LINKS =
    Constants.API_BASE_URL_CHATBOT + 'add_trusted_links';
  static API_REMOVE_ALL_TRUSTED_LINKS =
    Constants.API_BASE_URL_CHATBOT + 'delete_all_trusted_links';
  static API_REINDEX = Constants.API_BASE_URL_CHATBOT + 'reindex';

  static API_GET_USERS_WITH_ACCESS_CONTROL =
    Constants.API_BASE_URL_GENERAL +
    'AccessControlUserManagement/getUsersWithAccessControl';

  static API_GET_USERS_WITHOUT_ACCESS_CONTROL =
    Constants.API_BASE_URL_GENERAL +
    'AccessControlUserManagement/getUsersWithNoAccessControl';

  static API_GET_COMPANY_LICENSE_DATA =
    Constants.API_BASE_URL_GENERAL + 'AccessControlQuota/ShowCompanyCard';

  static API_MANAGE_ACCESS_CREATE_USER =
    Constants.API_BASE_URL_GENERAL + 'AccessControlUserManagement/createUser';

  static API_MANAGE_ACCESS_DELETE_USER =
    Constants.API_BASE_URL_GENERAL + 'AccessControlUserManagement/deleteUser';

  static API_MANAGE_ACCESS_GET_USER_DETAILS =
    Constants.API_BASE_URL_GENERAL +
    'AccessControlUserManagement/getUserDetails';

  static API_MANAGE_ACCESS_DELETE_USER_DEVICE =
    Constants.API_BASE_URL_GENERAL +
    'AccessControlDeviceManagement/deleteCredentialContainer';

  static API_MANAGE_ACCESS_GET_CARDS =
    Constants.API_BASE_URL_GENERAL +
    'AccessControlDeviceManagement/getPartNumbersList';

  static API_MANAGE_ACCESS_ISSUE_MOBILE_ID =
    Constants.API_BASE_URL_GENERAL +
    'AccessControlDeviceManagement/issueCredential';

  static API_MANAGE_ACCESS_DELETE_CREDENTIAL =
    Constants.API_BASE_URL_GENERAL +
    'AccessControlDeviceManagement/revokeCredential';
}
