import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import html2canvas from 'html2canvas';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import {
  ActionStatus,
  ReportIncidentStatus,
  ReportingType,
  ReportingTypes,
  RiskMatrix,
} from 'src/@hodhod/common/enum';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BodySkeletonComponent } from '../body-skeleton/body-skeleton.component';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { UserService } from 'src/backend/services/user.service';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { InvestigationService } from 'src/backend/services/investigation/investigation.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { Constants } from 'src/@hodhod/common/constants';
import {
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { BaseApi } from 'src/backend/api/base-api';
import { IncidentReportService } from 'src/backend/services/reportings/incident-report.service';
import { IncidentReportHistory } from '../../models/incident-report';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActionTrackerService } from 'src/backend/services/action-tracker/action-tracker.service';
import { Action } from 'src/app/action-tracker/models/action';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { ActionComponent } from 'src/app/action-tracker/components/action/action.component';
import { witnessescolumns } from '../../models/near-miss-report';
import { IncidentClassificationService } from 'src/backend/services/incident-classification/incident-classification.service';
import { RootCauseService } from 'src/backend/services/root-cause/root-cause.service';
import { SafemanHoursService } from 'src/backend/services/safeman-hours.service';
import { SafetyKpisService } from 'src/backend/services/dashboards/safety-kpis.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import svg2pdf from 'svg2pdf.js';

import { LanguageService } from 'src/@hodhod/services/language.service';
import { franc } from 'franc';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-incident-report-view',
  templateUrl: './incident-report-view.component.html',
  styleUrls: ['./incident-report-view.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms,
    scaleFadeIn400ms,
  ],
})
export class IncidentReportViewComponent implements OnInit, OnChanges {
  @Input() incidentReport: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(ActionComponent) actionsComponent!: ActionComponent;
  @Output() onInvestigate: EventEmitter<boolean> = new EventEmitter();
  @Output() onAddLossCosts: EventEmitter<boolean> = new EventEmitter();
  public data: any;
  public selectedImg: any;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  investigationForm!: FormGroup;
  teamLeadForm!: FormGroup;
  teamMemberForm!: FormGroup;
  commentForm!: FormGroup;
  users: any[] = [];
  userDetail: any;
  currentDate: Date = new Date();
  membersArray: any[] = [];
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<IncidentReportHistory>([]);
  incidentHistory: IncidentReportHistory[];
  injerdPersonPPes: any;
  altPopup: boolean = true;
  actions: Action[];
  RiskMatrix = RiskMatrix;

  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  @ViewChild('teamLeadModal') teamLeadModal!: TemplateRef<any>;
  @ViewChild('teamMemberModal') teamMemberModal!: TemplateRef<any>;
  @ViewChild('acknowledgeModal') acknowledgeModal!: TemplateRef<any>;
  @ViewChild('rejectTeamModal') rejectTeamModal!: TemplateRef<any>;
  @ViewChild('closeModal') closeModal!: TemplateRef<any>;
  ReportingType = ReportingType;
  optionsKey: string;
  public rtl: string;
  public translationsList: any = {};
  private destroy$ = new Subject();
  companyKey: string = '';
  company: any;
  instanceKey: string = '';
  rootCauses: any[] = [];
  closeTeamForm: FormGroup;
  teamMemberFormArray: FormArray;
  reportType = ReportingTypes.Incident;
  loggedUser: LoggedUser;
  rulesApplied: Array<{
    condition: string;
    leftOperand: string;
    rightOperand: string;
    operator: string;
    order: number;
  }> = [];
  rulesAppliedAcknowledge: Array<{
    condition: string;
    leftOperand: string;
    rightOperand: string;
    operator: string;
    order: number;
  }> = [];
  isInvestorsLoading: boolean = false;
  isResetSafeManHours: boolean = true;
  closerDetail: any;

  ReportIncidentStatus = ReportIncidentStatus;
  hasEditPermissions = ApplicationPermission.REPORTING_INCIDENT_UPDATE;
  hasSpecialPermissions = ApplicationPermission.REPORTING_INCIDENT_APPROVE;
  hasCreateUserPermissions = ApplicationPermission.USER_CREATE;
  hasCreateIncidentClassificationsPermission =
    ApplicationPermission.CONTENT_REPORTING_INCIDENTCLASSIFICATION_CREATE;
  hasRootCauseUpdatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_UPDATE;

  closeForm: FormGroup;
  witnesses: any;
  trimedDepartments = '';

  lossCostsForm: FormGroup;
  hasIncidentLoss: boolean = false;
  totalDirectCost: any = 0;
  totalIndirectCost: any = 0;
  totalCost: any = 0;
  directCostId: any = null;
  indirectCostId: any = null;
  firstDialogClosed: boolean = false;
  secDialogClosed: boolean = false;
  public incidentClassifications: any[] = [];

  workFlow: WorkFlow;
  constructor(
    private dialog: MatDialog,
    private feedBackService: AsyncFeedbackService,
    private fb: FormBuilder,
    private userService: UserService,
    private investigationService: InvestigationService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private workFlowService: WorkFlowService,
    private safemanHoursService: SafemanHoursService,
    private baseApi: BaseApi,
    private incidentReportService: IncidentReportService,
    private trackerService: ActionTrackerService,
    private router: Router,
    private route: ActivatedRoute,
    private rootService: RootCauseService,
    private incidentClassService: IncidentClassificationService,
    private safetyKpisService: SafetyKpisService,
    private langService: LanguageService
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.translate
      .get(['Errors', 'Success', 'Role', 'User', 'CheckAlerts'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  get visibleWitnessesColumns() {
    return this.witnessesColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  columns: TableColumn<IncidentReportHistory>[] = [
    { label: 'CreatedBy', property: 'createdBy', type: 'text', visible: true },
    { label: 'CreatedAt', property: 'created', type: 'text', visible: true },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Comments', property: 'comment', type: 'text', visible: true },
  ];

  witnessesColumns: TableColumn<witnessescolumns>[] = [
    { label: 'Name', property: 'fullName', type: 'text', visible: true },
    {
      label: 'Department',
      property: 'departmentName',
      type: 'text',
      visible: true,
    },
    { label: 'Role', property: 'roleName', type: 'text', visible: true },
  ];

  ngOnInit() {
    this.closeTeamForm = this.fb.group({
      closeTeamLeadName: ['', Validators.required],
      closeTeamMemberForm: this.fb.array([]),
    });
    this.teamMemberFormArray = this.closeTeamForm.get(
      'closeTeamMemberForm'
    ) as FormArray;
    this.company = this.baseApi.getCompanyFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.investigationForm = this.fb.group({
      investCheckbox: [false],
    });
    this.commentForm = this.fb.group({
      comment: ['', Validators.required],
    });
    this.teamLeadForm = this.fb.group({
      teamLeadName: ['', Validators.required],
    });
    this.teamMemberForm = this.fb.group({
      teamMember: ['', Validators.required],
      targetDate: ['', Validators.required],
      task: ['', Validators.required],
    });
    this.closeForm = this.fb.group({
      investCheckbox: [false],
      comment: [''],
      incidentClassification: ['', Validators.required],
      incidentRisk: ['', Validators.required],
      rootCause: ['', Validators.required],
      rcaFile: [[]],
    });

    this.lossCostsForm = this.fb.group({
      propertyDamageCosts: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
      productionLoss: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
      materialAndInventoryLoss: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
      laborCosts: [0, [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)]],
      emergencyResponse: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
      businessInterruption: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
      lossOfReputationAndCustomerConfidence: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
      regulatoryAndCompliance: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
      legalAndLiability: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
      environmentalCleanupAndRemediation: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
      healthcare: [0, [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)]],
      insurancePremiums: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
      trainingAndRetraining: [
        0,
        [Validators.min(0), Validators.pattern(/^[0-9]\d*$/)],
      ],
    });

    this.lossCostsForm.valueChanges.subscribe((res) => {
      this.totalDirectCost =
        res.propertyDamageCosts +
        res.productionLoss +
        res.laborCosts +
        res.materialAndInventoryLoss +
        res.emergencyResponse;

      this.totalIndirectCost =
        res.businessInterruption +
        res.lossOfReputationAndCustomerConfidence +
        res.regulatoryAndCompliance +
        res.legalAndLiability +
        res.environmentalCleanupAndRemediation +
        res.healthcare +
        res.insurancePremiums +
        res.trainingAndRetraining;

      this.totalCost = this.totalDirectCost + this.totalIndirectCost;
    });

    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.getUsers();
    this.langService.currentLanguage$.subscribe((language) => {
      this.rtl = language.isoCode;
    });
  }
  getRootCause(): void {
    this.rootService.getRootCauseByCompany(this.reportType).subscribe({
      next: (response) => {
        this.rootCauses = response;
        if (!this.rootCauses.some((item) => item.id == this.data.rootCauseId)) {
          this.closeForm.get('rootCause').setValue('');
        } else {
          this.closeForm.get('rootCause').setValue(this.data?.rootCauseId);
        }
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  get closeTeamMemberArray(): FormArray {
    return this.closeTeamForm.get('closeTeamMemberForm') as FormArray;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['incidentReport'].currentValue) {
      this.companyKey = this.baseApi.getCompanyIdFromStorage();
      this.data = this.incidentReport;
      if (this.data?.isResetSafeManHours) {
        this.firstDialogClosed = true;
        this.data?.isItLossTimeInjury === true
          ? (this.secDialogClosed = true)
          : this.secDialogClosed;
      }
      this.isResetSafeManHours = this.incidentReport?.isResetSafeManHours;
      this.instanceKey = this.incidentReport?.instanceKey;
      this.directCostId =
        this.data?.directCosts.length > 0
          ? this.data.directCosts[0]?.directID
          : null;

      this.indirectCostId =
        this.data?.indirectCosts.length > 0
          ? this.data.indirectCosts[0]?.indirectID
          : null;

      if (this.directCostId || this.indirectCostId) {
        this.hasIncidentLoss = true;
        this.initLossCostsFormForUpdate();
      }

      if (this.data?.status === 'CLOSED') {
        this.lossCostsForm.disable();
      }

      if (this.data) {
        this.getWorkFlow();
        this.witnesses = this.data?.theWitness.map((obj) => {
          return {
            ...obj,
            departmentName: obj.departmentName.split(',')[0],
            roleName: obj.roleName.split(',')[0],
          };
        });
        if (this.data?.isInvestigated) {
          this.investigationForm?.get('investCheckbox').setValue(true);
          this.closeForm.get('investCheckbox').setValue(true);
        }
        this.getUsers();
        this.getActionById();
        this.getHistoryData();
        this.getCloserDetail();
      }
    }
  }
  createteamMemberFormGroup(): FormGroup {
    return this.fb.group({
      teamMember: ['', Validators.required],
      targetDate: ['', Validators.required],
      task: ['', Validators.required],
    });
  }
  getIncidentClassifications(): void {
    this.loadingService.startLoading(true, '');
    this.incidentClassService.getIncidentClassificationByCompany().subscribe({
      next: (response) => {
        this.incidentClassifications = response;
        if (
          !this.incidentClassifications.some(
            (item) => item.id == this.data?.incidentClassificationId
          )
        ) {
          this.closeForm.get('incidentClassification').setValue('');
        } else {
          this.closeForm
            .get('incidentClassification')
            .setValue(this.data?.incidentClassificationId);
        }

        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  resetSafemanHours(res, id?) {
    if (res === 'yes') {
      this.loadingService.startLoading(true);
      this.safemanHoursService.resetSafemanHours(id).subscribe({
        next: (res) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
          this.loadingService.stopLoading();
        },
        error: (err) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, err['error'].message)
          );
          this.loadingService.stopLoading();
        },
      });
    }
    this.isResetSafeManHours = !this.isResetSafeManHours;
    this.firstDialogClosed = true;
    this.data?.isItLossTimeInjury === true
      ? (this.secDialogClosed = true)
      : this.secDialogClosed;
  }
  IsItLTIIncident(res, id?) {
    if (res === 'yes') {
      this.loadingService.startLoading(true);
      this.safetyKpisService.LTIIncident(id).subscribe({
        next: (res) => {
          this.loadingService.stopLoading();
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
        },
        error: () => {
          this.loadingService.stopLoading();
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.error)
          );
        },
      });
    }
    this.firstDialogClosed = false;
    this.secDialogClosed = true;
  }

  IsItRecordableIncident(res, id?) {
    if (res === 'yes') {
      this.loadingService.startLoading(true);
      this.safetyKpisService.RecordableIncident(id).subscribe({
        next: (res) => {
          this.loadingService.stopLoading();
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
        },
        error: (res) => {
          this.loadingService.stopLoading();
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, res?.message)
          );
        },
      });
    }
    this.secDialogClosed = false;
  }
  initLossCostsFormForUpdate() {
    this.lossCostsForm
      .get('propertyDamageCosts')
      .setValue(this.data?.directCosts[0].propertyDamageCosts);
    this.lossCostsForm
      .get('productionLoss')
      .setValue(this.data?.directCosts[0].productionLoss);
    this.lossCostsForm
      .get('materialAndInventoryLoss')
      .setValue(this.data?.directCosts[0].materialAndInventoryLoss);
    this.lossCostsForm
      .get('laborCosts')
      .setValue(this.data?.directCosts[0].laborCosts);
    this.lossCostsForm
      .get('emergencyResponse')
      .setValue(this.data?.directCosts[0].emergencyResponse);

    this.lossCostsForm
      .get('businessInterruption')
      .setValue(this.data?.indirectCosts[0].businessInterruption);
    this.lossCostsForm
      .get('lossOfReputationAndCustomerConfidence')
      .setValue(
        this.data?.indirectCosts[0].lossOfReputationAndCustomerConfidence
      );
    this.lossCostsForm
      .get('regulatoryAndCompliance')
      .setValue(this.data?.indirectCosts[0].regulatoryAndCompliance);
    this.lossCostsForm
      .get('legalAndLiability')
      .setValue(this.data?.indirectCosts[0].legalAndLiability);
    this.lossCostsForm
      .get('environmentalCleanupAndRemediation')
      .setValue(this.data?.indirectCosts[0].environmentalCleanupAndRemediation);
    this.lossCostsForm
      .get('healthcare')
      .setValue(this.data?.indirectCosts[0].healthcare);
    this.lossCostsForm
      .get('insurancePremiums')
      .setValue(this.data?.indirectCosts[0].insurancePremiums);
    this.lossCostsForm
      .get('trainingAndRetraining')
      .setValue(this.data?.indirectCosts[0].trainingAndRetraining);
  }

  saveLossIncidents() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true);
          const data = {
            totalDirectCost: this.totalDirectCost,
            totalIndirectCost: this.totalIndirectCost,
            incidentID: this.data.id,
            ...this.lossCostsForm.value,
          };
          for (const key in data) {
            if (data.hasOwnProperty(key) && data[key] === null) {
              data[key] = 0;
            }
          }
          if (this.directCostId || this.indirectCostId) {
            const updateData = {
              direct_ID: this.directCostId ? this.directCostId : 0,
              indirect_ID: this.indirectCostId ? this.indirectCostId : 0,
              coiDto: { ...data },
            };
            this.incidentReportService
              .updateIncidentReportLosses(updateData)
              .subscribe({
                next: (res) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Success, res?.message)
                  );
                  this.onAddLossCosts.emit(true);
                },
                error: (err) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Failure, err?.message)
                  );
                  this.loadingService.stopLoading();
                },
              });
          } else {
            this.incidentReportService
              .saveIncidentReportLosses(data)
              .subscribe({
                next: (res) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Success, res?.message)
                  );
                  this.onAddLossCosts.emit(true);
                },
                error: (err) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Failure, err?.message)
                  );
                  this.loadingService.stopLoading();
                },
              });
          }
        }
      });
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_INCIDENT_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
        this.getWorkflowInstanceKeyById(this.workFlow.workFlowKey);
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getWorkflowInstanceKeyById(workflowKey: string) {
    this.workFlowService
      .getInstanceKeyByTableId(workflowKey, this.data?.id)
      .subscribe({
        next: (res) => {
          this.instanceKey = res?.instanceKey
            ? res?.instanceKey
            : this.data?.instanceKey;
          if (this.instanceKey) {
            this.getTransitionRules();
            this.getTransitionRulesForAcknowledge();
          }
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }

  getCloserDetail(): void {
    if (this.data?.closerId) {
      this.userService.getUserDetails(this.data?.closerId).subscribe({
        next: (response) => {
          this.closerDetail = response;
          this.trimedDepartments =
            this.closerDetail?.departmentName?.substring(0, 10) + ' ...';
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
    }
  }
  getUsers(): void {
    this.isInvestorsLoading = true;
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.users = response;
        if (this.data?.isInvestigated) {
          this.investigationForm.get('investCheckbox').setValue(true);
          this.userDetail = this.users.find(
            (x) => x.userId == this.data.investigation?.teamLeadId
          );
          this.teamLeadForm
            .get('teamLeadName')
            ?.setValue(this.data?.investigation?.teamLeadId);
          if (this.data?.teamMembers.length > 0) {
            this.membersArray = [];
            for (
              let index = 0;
              index < this.data?.teamMembers.length;
              index++
            ) {
              const user = this.users.find(
                (x) => x.userId == this.data?.teamMembers[index]?.userId
              );
              this.membersArray.push({
                userId: user?.userId,
                fullName: user?.fullName,
                role: user?.roles[0],
                userImage: user?.userImage,
                task: this.data?.teamMembers[index].task,
                targetDate: this.data?.teamMembers[index].targetDate,
                email: user?.email,
              });
            }
          }
        }
        this.isInvestorsLoading = false;
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.isInvestorsLoading = false;
      },
    });
  }

  getUsersHierarchy(userIds: string[]): void {
    this.userService
      .getUserHierarchy({ userIds: userIds?.toString() })
      .subscribe({
        next: (response) => {
          this.setUsersHierarchy(response);
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }
  setUsersHierarchy(usersLevels: any[]): void {
    const data = {
      instanceKey: this.instanceKey,
      isReminder: false,
      isEscalation: true,
      reminderUsers: [],
      escalationUsers: usersLevels?.map((ele) => {
        return {
          level: ele?.level,
          email: ele?.email,
          userId: ele?.userId,
        };
      }),
    };
    this.workFlowService.setCustomUserForReminderOrEscalation(data).subscribe({
      next: (response) => {
        this.onInvestigate.emit(true);
        // this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
      },
    });
  }

  getUserName(userId) {
    return this.users.find((item) => item.userId == userId)?.fullName;
  }

  getNextTransitionAssigneesFromWorkFlow(): void {
    this.workFlowService
      .getNextTransitionAssignees(this.instanceKey)
      .subscribe({
        next: (response) => {
          this.getUsersHierarchy(response);
        },
        error: ({ error }) => {
          this.loadingService.stopLoading();
        },
      });
  }
  getHistoryData() {
    this.incidentReportService
      .getIncidentReportHistory(this.data.id)
      .subscribe({
        next: (response) => {
          this.incidentHistory = response?.data?.map((ele) => {
            return {
              ...ele,
              created: moment(ele.created)
                .local(true)
                .format('MMM DD YYYY hh mm A') as any,
            };
          });
          this.dataSource = new MatTableDataSource(this.incidentHistory);
          this.paginator.length = response?.totalCount;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  openTeamLeadModal(): void {
    this.dialog.open(this.teamLeadModal, { height: 'auto', width: '30%' });
  }

  openTeamMemberModal(): void {
    this.teamMemberForm.reset();
    this.teamMemberForm.get('teamMember')?.setValue('');
    this.teamMemberForm.get('task')?.setValue('');
    this.dialog.open(this.teamMemberModal, { height: 'auto', width: '50%' });
  }

  chnageCheckBox(): void {
    this.chooseInvestigation();
  }

  getTeamLeadDetail(closeForm?) {
    const userId = closeForm
      ? this.closeTeamForm.value.closeTeamLeadName
      : this.teamLeadForm.value.teamLeadName;
    this.userDetail = this.users.find((x) => x.userId == userId);
    this.closeDialog();
  }

  getTeamMemberDetail(closeform?) {
    if (closeform) {
      this.closeTeamForm.value.closeTeamMemberForm.forEach((item) => {
        const { teamMember, targetDate, task } = item;
        const userData = this.users.find((x) => x.userId == teamMember);
        if (this.membersArray.length > 0) {
          const data = this.membersArray.find((x) => x.userId == teamMember);
          if (data) {
            this.feedBackService.showFeedback(
              new FeedbackModel(
                FeedbackType.Warning,
                'Already selected this user'
              )
            );
            return;
          }
        }
        if (teamMember && targetDate && task) {
          this.membersArray.push({
            userId: teamMember,
            fullName: userData?.fullName,
            role: userData?.roles[0],
            userImage: userData?.userImage,
            task: task,
            targetDate: targetDate
              ? moment(new Date(targetDate)).format('YYYY-MM-DDThh:mm:ssZ')
              : '',
          });
        }
        this.closeDialog();
      });
    } else {
      const { teamMember, targetDate, task } = this.teamMemberForm.value;
      const userData = this.users.find((x) => x.userId == teamMember);
      if (this.membersArray.length > 0) {
        const data = this.membersArray.find((x) => x.userId == teamMember);
        if (data) {
          this.feedBackService.showFeedback(
            new FeedbackModel(
              FeedbackType.Warning,
              'Already selected this user'
            )
          );
          return;
        }
      }
      if (teamMember && targetDate && task) {
        this.membersArray.push({
          userId: teamMember,
          fullName: userData?.fullName,
          role: userData?.roles[0],
          userImage: userData?.userImage,
          task: task,
          targetDate: targetDate
            ? moment(new Date(targetDate)).format('YYYY-MM-DDThh:mm:ssZ')
            : '',
        });
      }
      this.closeDialog();
    }
  }

  removeTeamMember(userId: string) {
    this.membersArray = this.membersArray.filter((x) => x.userId != userId);
  }

  submitInvestigationData(close?) {
    if (close) {
      this.getTeamLeadDetail(true);
      this.getTeamMemberDetail(true);
    } else {
      this.getTeamLeadDetail();
      this.getTeamMemberDetail();
    }
    this.loadingService.startLoading(true, '');
    const data = {
      teamLeadId: close
        ? this.closeTeamForm.value.closeTeamLeadName
        : this.teamLeadForm.value.teamLeadName,
      reportId: this.data.id,
      type: ReportingType.Incident,
      teamMembers: this.membersArray,
    };
    this.investigationService.saveInvestigation(data).subscribe({
      next: (res) => {
        this.data.isInvestigated = true;
        this.onInvestigate.emit(true);
        this.actionsComponent.getData();
        if (close) {
          this.getNextTransitionAssigneesFromWorkFlow();
        }
        // this.loadingService.stopLoading();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
      },
      error: (error) => {
        this.loadingService.stopLoading();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.error.message)
        );
      },
    });
    this.closeDialog();
  }
  areArraysEqual(arr1: any[], arr2: any[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every(
      (element, index) => element?.userId === arr2[index]?.userId
    );
  }
  updateInvestigationData(teamselected?) {
    if (
      this.areArraysEqual(this.membersArray, this.data?.teamMembers) === false
    ) {
      this.loadingService.startLoading(true, '');
      const data = {
        investigationId: this.data?.investigation
          ? this.data?.investigation?.id
          : 0,
        teamLeadId: this.teamLeadForm.value.teamLeadName,
        reportId: this.data.id,
        type: ReportingType.Incident,
        teamMembers: this.membersArray,
      };
      this.investigationService.updateInvestigation(data).subscribe({
        next: (res) => {
          this.data.isInvestigated = true;
          this.loadingService.stopLoading();
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
          this.onInvestigate.emit(true);
          this.actionsComponent.getData();
          if (teamselected) {
            this.teamSelected();
          }
        },
        error: (error) => {
          this.loadingService.stopLoading();
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.error.message)
          );
        },
      });
      this.closeDialog();
    } else {
      if (teamselected) {
        this.teamSelected();
      }
    }
  }
  removeMember(i: any) {
    this.teamMemberFormArray.removeAt(i);
  }
  getActionById() {
    const data = {
      sourceId: this.data?.id,
      SourceType: ReportingType.Incident + ',' + 'INVESTIGATION-INCIDENT',
    };
    this.trackerService.getActionById(data).subscribe({
      next: (res) => {
        this.actions = res?.data;
      },
    });
  }
  observActionById() {
    const data = {
      sourceId: this.data?.id,
      SourceType: ReportingType.Incident,
    };
    return this.trackerService.getActionById(data);
  }
  openBigView(id: number): void {
    const img = this.data?.images.find((x) => x.id === id);
    if (img) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
      const extension = img.attachment.split('.').pop();
      if (imageExtensions.includes(extension)) {
        this.selectedImg = img;
        this.dialog.open(this.imageBigView, {
          maxHeight: '95vh',
          width: 'auto',
        });
      } else {
        const downloadTag = document.createElement('a');
        downloadTag.href = img.attachment;
        downloadTag.addEventListener('click', () => {
          downloadTag.download = img.fileName;
        });
        downloadTag.click();
      }
    }
  }
  downloadDocument(url: any): void {
    const downloadTag = document.createElement('a');
    downloadTag.href = url;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }

  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }

  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  printIncidentReport() {
    if (this.data) {
      this.observActionById()
        .pipe(
          tap((res) => {
            this.actions = res?.data;
          })
        )
        .subscribe(() => {
          this.takePrint(this.data);
        });
    }
  }
  async takePrint(data: any): Promise<void> {
    const doc = new jsPDF({
      orientation: 'portrait',
    });
    doc.addFont('assets/fonts/Amiri-Regular.ttf', 'Amiri', 'normal');
    doc.setFont('Amiri', 'italic', 'bold');
    const date = moment(data?.updatedOn)
      .local(true)
      .format('MMM DD YYYY hh mm A');
    let y = 5;
    // Logo and Company Data
    const companyLogo =
      JSON.parse(localStorage.getItem('company')).companyImage || '';
    if (companyLogo) {
      doc.addImage(companyLogo, 'PNG', 10, y, 20, 20); // Adjust positioning and size as needed
      doc.text(
        JSON.parse(localStorage.getItem('company')).companyName,
        35,
        y + 10
      );
      y += 45;
    } else {
      doc.text(JSON.parse(localStorage.getItem('company')).companyName, 10, y, {
        align: 'center',
      });
      y += 20;
    }
    // Title Line
    doc.setLineWidth(1);
    doc.line(10, 30, 200, 30);
    doc.addImage(
      '../../../../assets/img/resources/saned-white-logo.png',
      'PNG',
      140,
      24,
      40,
      10
    );
    y += 10;
    // Report Title
    doc.setFont('Amiri', 'normal');
    doc.setFontSize(18);
    doc.text(this.rtl === 'ar' ? 'تقرير الحادث' : 'Report Incident', 105, 40, {
      align: 'center',
    });
    doc.setFont('Amiri', 'normal');
    //Details
    function addTextWithOverflow(
      doc,
      text,
      x,
      y,
      maxWidth,
      rtl,
      pageHeight,
      bottomMargin,
      desc?
    ) {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line) => {
        if (y + 10 > pageHeight - bottomMargin) {
          doc.addPage();
          y = 10;
        }
        if (desc) {
          if (checkIfArabic(line)) {
            doc.text(line, 190, y, { align: 'right' });
          } else {
            doc.text(line, x, y);
          }
        } else {
          doc.text(line, x, y);
        }
        y += 5;
      });
      return y;
    }
    function checkIfArabic(text: string) {
      if (text.includes('ا' || 'أ' || 'ل')) {
        return true;
      } else {
        return false;
      }
    }

    const details = [
      {
        label: 'Title',
        arLabel: 'العنوان',
        value: data?.title,
      },
      {
        label: 'Department',
        arLabel: 'القسم',
        value: data?.departmentParentName || data?.department,
      },
      {
        label: 'Sub Department',
        arLabel: 'القسم الفرعي',
        value: data?.departmentParentName ? data?.department : '--',
      },
      {
        label: 'Type of Hazard',
        arLabel: 'النوع ',
        value: data?.typesOfIncident,
      },
      {
        label: 'Shift',
        arLabel: 'الوردية',
        value: data?.shift,
      },
      {
        label: 'Classification',
        arLabel: 'التصنيف  ',
        value: data?.classification,
      },
    ];
    const secDetails = [
      {
        label: 'Status',
        arLabel: 'الحالة',
        value: data?.status,
      },
      {
        label: 'Incident Date',
        arLabel: 'وقت الحادث',
        value: date,
      },
      {
        label: 'Root Cause',
        arLabel: 'السبب الجذري',
        value: data?.rootCause,
      },

      {
        label: 'Reported By',
        arLabel: 'تم عمل تقرير بواسطة ',
        value: data?.reporterName,
      },
      {
        label: 'Incident Risk',
        arLabel: 'خطورة الحادث',
        value: data?.incidentRisk,
      },
    ];
    doc.setFontSize(12);
    let Y = y;
    let pageHeight = doc.internal.pageSize.height;
    let bottomMargin = 10;
    const lineHeight = 5;
    const maxLength = Math.max(details.length, secDetails.length);

    for (let i = 0; i < maxLength; i++) {
      if (i < details.length) {
        doc.setTextColor('#131C4E');
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          8,
          y - 3,
          5,
          5
        );
        if (this.rtl === 'ar') {
          doc.text(details[i].arLabel, 15, y);
        } else {
          doc.text(details[i].label, 15, y);
        }
        y += 5;
        doc.setTextColor('black');
        y = addTextWithOverflow(
          doc,
          details[i].value || '',
          15,
          y,
          90,
          this.rtl,
          pageHeight,
          bottomMargin
        );
        y += 5;
      }

      if (i < secDetails.length) {
        doc.setTextColor('#131C4E');
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          103,
          Y - 3,
          5,
          5
        );
        if (this.rtl === 'ar') {
          doc.text(secDetails[i].arLabel, 110, Y);
        } else {
          doc.text(secDetails[i].label, 110, Y);
        }
        Y += 5;
        doc.setTextColor('black');
        Y = addTextWithOverflow(
          doc,
          secDetails[i].value || '',
          110,
          Y,
          90,
          this.rtl,
          pageHeight,
          bottomMargin
        );
        Y += 5;
      }
    }
    // Description
    doc.setTextColor('#131C4E');
    doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
    if (this.rtl === 'ar') {
      doc.text('الوصف', 15, y);
    } else {
      doc.text('Description', 15, y);
    }
    doc.setTextColor('black');
    doc.setFontSize(12);
    y += 5;
    y = addTextWithOverflow(
      doc,
      data?.whatHappend || '',
      15,
      y,
      180,
      this.rtl,
      pageHeight,
      bottomMargin,
      true
    );
    y += 10;
    doc.setTextColor('#131C4E');
    doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
    if (this.rtl === 'ar') {
      doc.text('الإجراء الذي تم اتخاذه لجعل الموقف آمنًا (إصلاح سريع)', 15, y);
    } else {
      doc.text('Action taken to make situation safe (Quick fix)', 15, y);
    }
    doc.setTextColor('black');
    doc.setFontSize(12);
    y += 5;
    y = addTextWithOverflow(
      doc,
      data?.actionTaken || '',
      15,
      y,
      180,
      this.rtl,
      pageHeight,
      bottomMargin,
      true
    );
    y += 5;

    // Images Section
    if (data?.images.length > 0) {
      doc.setFontSize(18);
      doc.text(this.rtl === 'ar' ? 'الملفات' : 'Documents', 105, y, {
        align: 'center',
      });
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      let x = 10;
      let count = 0;
      const pageWidth = doc.internal.pageSize.width - 20; // Adjust for margins
      const imageWidth = 25;
      const imageHeight = 25;
      const spacing = 10;

      for (let i = 0; i < data?.images.length; i++) {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
        const extension = data?.images[i].attachment.split('.').pop();
        let imgData = null;
        if (imageExtensions.includes(extension)) {
          imgData = data?.images[i].attachment;
        } else {
          imgData = await this.getBase64ImageFromURL(
            this.getAttachmentImage(data?.images[i].attachment)
          );
        }
        if (imgData) {
          if (x + imageWidth > pageWidth) {
            x = 10;
            y += imageHeight + spacing;
          }
          doc.addImage(imgData, 'JPEG', x, y, imageWidth, imageHeight);

          // Add clickable annotation
          doc.link(x, y, imageWidth, imageHeight, {
            url: data?.images[i].attachment,
          });

          x += imageWidth + spacing;
          count++;
        }
        if (y + 10 > pageHeight - bottomMargin) {
          doc.addPage();
          y = 10;
        }
      }
      y += imageHeight + spacing;
    }
    y += 10;
    //Injured Persons
    if (data?.injuredPersons.length > 0) {
      for (let i = 0; i < data?.injuredPersons.length; i++) {
        const details = [
          {
            label: 'Name',
            arLabel: 'الاسم',
            value: this.getUserName(data?.injuredPersons[i]?.employeeName),
          },
          {
            label: 'Age',
            arLabel: 'العمر',
            value: data?.injuredPersons[i]?.age.toString(),
          },
          {
            label: 'Gender',
            arLabel: 'الجنس',
            value: data?.injuredPersons[i]?.gender,
          },
          {
            label: 'ID NO',
            arLabel: 'رقم بطاقة الهوية',
            value: data?.injuredPersons[i]?.idNumber,
          },
          {
            label:
              'Has the person been employed in the activity being done at the time on the accident?',
            arLabel:
              'هل تم توظيف الشخص في النشاط الذي تم القيام به في وقت وقوع الحادث؟',
            value: data?.injuredPersons[i]?.isEmployed,
          },
        ];
        const secDetails = [
          {
            label: 'Jop Title',
            arLabel: 'الوظيفة',
            value: data?.injuredPersons[i]?.jobPosition,
          },
          {
            label: 'Start Date',
            arLabel: 'تاريخ البدأ',
            value: date,
          },
          {
            label: 'End Date',
            arLabel: 'تاريخ الإنتهاء',
            value: date,
          },
          {
            label: 'Nature Of Injury',
            arLabel: 'طبيعة الإصابة',
            value: data?.injuredPersons[i]?.natureOfInjuries,
          },
        ];
        const maxLength = Math.max(details.length, secDetails.length);
        doc.setFontSize(18);
        doc.text(
          this.rtl === 'ar'
            ? `(${i + 1}) شخص مصاب `
            : `injured Person (${i + 1})`,
          105,
          y,
          {
            align: 'center',
          }
        );
        y += 5;
        doc.setLineWidth(0.5);
        doc.line(10, y, 200, y);
        y += 10;
        doc.setFontSize(12);
        for (let i = 0; i < maxLength; i++) {
          Y = y;
          if (i < details.length) {
            doc.setTextColor('#131C4E');
            doc.addImage(
              'assets/img/resources/pdfArrow.png',
              'PNG',
              8,
              y - 3,
              5,
              5
            );
            if (this.rtl === 'ar') {
              doc.text(details[i].arLabel, 15, y);
            } else {
              doc.text(details[i].label, 15, y);
            }
            y += 5;
            doc.setTextColor('black');
            y = addTextWithOverflow(
              doc,
              details[i].value || '',
              15,
              y,
              90,
              this.rtl,
              pageHeight,
              bottomMargin
            );
            y += 5;
          }

          if (i < secDetails.length) {
            if (y == 20) {
              Y = 10;
            }
            doc.setTextColor('#131C4E');
            doc.addImage(
              'assets/img/resources/pdfArrow.png',
              'PNG',
              103,
              Y - 3,
              5,
              5
            );
            if (this.rtl === 'ar') {
              doc.text(secDetails[i].arLabel, 110, Y);
            } else {
              doc.text(secDetails[i].label, 110, Y);
            }

            Y += 5;
            doc.setTextColor('black');
            Y = addTextWithOverflow(
              doc,
              secDetails[i].value || '',
              110,
              Y,
              90,
              this.rtl,
              pageHeight,
              bottomMargin
            );
            Y += 5;
          }
        }
        //PPE
        y += 15;
        if (y > pageHeight - bottomMargin) {
          doc.addPage();
          y = 10; // Reset y to the top margin after adding a new page
        }

        if (data?.injuredPersons[i]?.ppeData?.length > 0) {
          doc.setTextColor('#131C4E');
          doc.addImage(
            'assets/img/resources/pdfArrow.png',
            'PNG',
            8,
            y - 3,
            5,
            5
          );
          doc.setFontSize(16);
          if (this.rtl === 'ar') {
            doc.text('معدات الحماية', 15, y);
          } else {
            doc.text('PPE List', 15, y);
          }
          y += 5;

          Y = y;
          let x = 15;
          const pageHeight = doc.internal.pageSize.height;
          const bottomMargin = 20;
          const lineHeight = 5;

          for (let j = 0; j < data?.injuredPersons[i]?.ppeData?.length; j++) {
            if (data?.injuredPersons[i]?.ppeData[j]) {
              doc.setTextColor('black');
              doc.setFontSize(12);
              doc.text(j + 1 + '-', x - 2, y + 5);
              if (data?.injuredPersons[i]?.ppeData[j]?.imageUrl) {
                doc.addImage(
                  data?.injuredPersons[i]?.ppeData[j]?.imageUrl,
                  'PNG',
                  x + 3,
                  y,
                  10,
                  10
                );
              }

              doc.text(
                data?.injuredPersons[i]?.ppeData[j]?.ppeName || '',
                x + 15,
                y + 5,
                {
                  maxWidth: 200,
                }
              );
              y += 12;

              if (y + lineHeight > pageHeight - bottomMargin) {
                doc.addPage();
                y = 10;
              }
            }
          }
        }
        const bodySkeleton = document.getElementById(
          `bodySkeleton${i}`
        ) as HTMLElement;
        const fixedWidth = 800;
        const fixedHeight = 600;
        bodySkeleton.style.width = `${fixedWidth}px`;
        bodySkeleton.style.height = `${fixedHeight}px`;
        const canvas = await html2canvas(bodySkeleton);
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 210;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        doc.addImage(imgData, 'PNG', 0, y, imgWidth, imgHeight);
        y += imgHeight;
        if (y + 10 > pageHeight - bottomMargin) {
          doc.addPage();
          y = 10;
        }
      }
    }
    //investgation
    if (data?.isInvestigated) {
      if (y + 10 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      doc.setFontSize(18);
      doc.text(this.rtl === 'ar' ? `التحقيق` : `Investigation`, 105, y, {
        align: 'center',
      });
      y += 5;
      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 10;

      doc.setFontSize(16);
      doc.setTextColor('#131C4E');
      doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
      doc.text(this.rtl === 'ar' ? `قائد الفريق` : `Team Lead`, 15, y);
      Y = y;
      doc.setFontSize(12);
      y += 5;
      y = addTextWithOverflow(
        doc,
        this.userDetail?.fullName || '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y = addTextWithOverflow(
        doc,
        this.userDetail?.roles[0] || '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;
      if (this.membersArray.length > 0) {
        doc.setFontSize(16);
        doc.setTextColor('#131C4E');
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          8,
          y - 3,
          5,
          5
        );
        doc.text(this.rtl === 'ar' ? `اعضاء الفريق` : `Team Mambers`, 15, y);
        doc.setFontSize(12);
        for (let i = 0; i < this.membersArray.length; i++) {
          y += 5;
          this.membersArray[i]?.fullName
            ? (y = addTextWithOverflow(
                doc,
                i + 1 + '-' + this.membersArray[i]?.fullName || '',
                15,
                y,
                90,
                this.rtl,
                pageHeight,
                bottomMargin
              ))
            : null;
          this.membersArray[i]?.role
            ? (y = addTextWithOverflow(
                doc,
                this.membersArray[i]?.role || '',
                15,
                y,
                90,
                this.rtl,
                pageHeight,
                bottomMargin
              ))
            : null;
          Y += 7;
        }
      }
      y += 20;
    }
    //cost
    if (data?.directCosts?.length > 0 || data?.indirectCost?.length > 0) {
      if (y + 10 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
        Y = y; //40
      }
      doc.setFontSize(18);
      doc.text(
        this.rtl === 'ar'
          ? `الخسارة المتكبدة من الحادث`
          : `Loss incurred from the incident`,
        105,
        y,
        {
          align: 'center',
        }
      );
      y += 5;
      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 10;
      doc.setFontSize(16);
      doc.setTextColor('#131C4E');

      doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
      doc.text(this.rtl === 'ar' ? 'التكاليف المباشرة' : `Direct Costs`, 15, y);

      y += 10;
      doc.setFontSize(12);
      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.directCosts[0].propertyDamageCosts.toString()} :تكاليف الأضرار بالممتلكات`
          : `Property Damage Costs: ${data?.directCosts[0].propertyDamageCosts.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;
      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.directCosts[0].materialAndInventoryLoss.toString()} :خسارة المواد والمخزون`
          : `Material And Inventory Loss: ${data?.directCosts[0].materialAndInventoryLoss.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;
      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.directCosts[0].productionLoss.toString()} :خسارة الإنتاج`
          : `Production Loss: ${data?.directCosts[0].productionLoss.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;
      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.directCosts[0].laborCosts.toString()} :تكاليف العمالة `
          : `laborCosts: ${data?.directCosts[0].laborCosts.toString()}` || '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;
      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.directCosts[0].emergencyResponse.toString()} :الاستجابة لحالات الطوارئ `
          : `Emergency Response: ${data?.directCosts[0].emergencyResponse.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 10;
      doc.setFontSize(14);
      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.directCosts[0].totalDirectCost.toString()} :إجمالي التكلفة المباشرة `
          : ` Total Direct Cost: ${data?.directCosts[0].totalDirectCost.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 10;
      doc.setFontSize(16);
      doc.setTextColor('#131C4E');
      doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);

      doc.text(
        this.rtl === 'ar' ? `التكاليف غير المباشرة` : `Indirect Costs`,
        15,
        y
      );
      doc.setFontSize(12);
      y += 10;
      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.indirectCosts[0].businessInterruption.toString()} :انقطاع العمل`
          : `Business Interruption: ${data?.indirectCosts[0].businessInterruption.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;

      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.indirectCosts[0].lossOfReputationAndCustomerConfidence.toString()} :فقدان السمعة وثقة العملاء`
          : `Loss of Reputation And Customer Confidence: ${data?.indirectCosts[0].lossOfReputationAndCustomerConfidence.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;
      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.indirectCosts[0].regulatoryAndCompliance.toString()} :التنظيم والامتثال`
          : `Regulatory And Compliance: ${data?.indirectCosts[0].regulatoryAndCompliance.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );

      y += 5;

      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.indirectCosts[0].legalAndLiability.toString()} :المسؤولية القانونية `
          : `Legal And Liability: ${data?.indirectCosts[0].legalAndLiability.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;

      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.indirectCosts[0].environmentalCleanupAndRemediation.toString()} :تنظيف البيئة ومعالجتها`
          : `Environmental Cleanup And Remediation: ${data?.indirectCosts[0].environmentalCleanupAndRemediation.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;

      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.indirectCosts[0].healthcare.toString()} :الرعاية الصحية`
          : `Healthcare: ${data?.indirectCosts[0].healthcare.toString()}` || '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;

      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.indirectCosts[0].insurancePremiums.toString()} :أقساط التأمين`
          : `Insurance Premiums: ${data?.indirectCosts[0].insurancePremiums.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 5;
      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.indirectCosts[0].trainingAndRetraining.toString()} :التدريب وإعادة التدريب`
          : `Training And Retraining: ${data?.indirectCosts[0].trainingAndRetraining.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 10;
      doc.setFontSize(14);

      y = addTextWithOverflow(
        doc,
        this.rtl === 'ar'
          ? `${data?.indirectCosts[0].totalIndirectCost.toString()} :إجمالي التكلفة غير المباشرة`
          : ` Total Indirect Cost: ${data?.indirectCosts[0].totalIndirectCost.toString()}` ||
              '',
        15,
        y,
        90,
        this.rtl,
        pageHeight,
        bottomMargin
      );
      y += 50;
      if (y + 10 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
    }

    // Translation function for Arabic headers
    const translateKey = (key) => {
      const translations = {
        Description: 'الوصف',
        title: 'العنوان',
        Responsibility: 'المسؤل',
        priority: 'الأفضلية',
        targetDate: 'تاريخ التسليم',
        closingDate: 'تاريخ الانتهاء',
        actionStatus: 'الحاله',
        status: 'الحاله',
        assignToName: 'المسؤل',
        fullName: 'الاسم',
        department: 'القسم',
        role: 'الدور',
        created: 'تاريخ الإنشاء',
        comments: 'التعليقات',
      };
      return translations[key] || key;
    };
    const createHeaders = (keys, rtl) => {
      const translatedKeys = rtl === 'ar' ? keys.map(translateKey) : keys;
      return translatedKeys.map((key) => ({
        id: key,
        name: key,
        prompt: key.replace(/([A-Z])/g, ' $1').trim(),
        width: 40,
        align: 'center',
        padding: 5,
      }));
    };
    // Function to generate table data
    const generateTableData = (data, fields) => {
      return data.map((item) =>
        fields.reduce((acc, field) => {
          acc[field] =
            field === 'targetDate' || field === 'closingDate'
              ? moment.utc(item[field]).local(true).format('MMM-DD-yyyy')
              : item[field];
          return acc;
        }, {})
      );
    };

    // Function to add a table to the document and return the new y position
    const addTable = (doc, startY, data, headers, rtl) => {
      autoTable(doc, {
        startY: startY,
        head: [headers.map((header) => header.prompt)],
        body: data.map((row) => Object.values(row)),
        styles: { fontSize: 12, font: 'Amiri', overflow: 'linebreak' },
      });
      return doc.lastAutoTable.finalY + 20; // Add space after the table
    };
    //Incident History Section
    if (this.incidentHistory.length > 0) {
      if (y + 30 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      doc.setFontSize(18);
      doc.text(
        this.rtl === 'ar' ? 'تاريخ الحوادث ' : 'Incident History',
        105,
        y,
        { align: 'center' }
      );
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      doc.setFontSize(12);
      doc.setTextColor('black');

      const incidentHistoryFields = [
        'created',
        'status',
        'comment',
        'createdBy',
      ];
      const incidentHistoryHeaders = createHeaders(
        incidentHistoryFields,
        this.rtl
      );
      const incidentHistoryTableData = generateTableData(
        this.incidentHistory,
        incidentHistoryFields
      );
      y = addTable(
        doc,
        y,
        incidentHistoryTableData,
        incidentHistoryHeaders,
        this.rtl
      );
    }

    // Witnesses Section
    if (data.theWitness?.length > 0) {
      if (y + 30 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      doc.setFontSize(18);
      doc.text(this.rtl === 'ar' ? 'الشهود' : 'Witnesses', 105, y, {
        align: 'center',
      });
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      doc.setFontSize(12);
      doc.setTextColor('black');
      data.theWitness.forEach((obj) => {
        obj.role = obj?.roleName.split(',')[0];
        obj.department = obj?.departmentName.split(',')[0];
      });
      const witnessesFields = ['fullName', 'department', 'role'];
      const witnessesHeaders = createHeaders(witnessesFields, this.rtl);
      const witnessesTableData = generateTableData(
        data.theWitness,
        witnessesFields
      );
      y = addTable(doc, y, witnessesTableData, witnessesHeaders, this.rtl);
    }
    // Actions Section
    if (this.actions.length > 0) {
      if (y + 30 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
      doc.setFontSize(18);
      doc.text(this.rtl === 'ar' ? 'الأجرائات' : 'Actions', 105, y, {
        align: 'center',
      });
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      doc.setFontSize(12);
      doc.setTextColor('black');

      const actionsFields = [
        'title',
        'assignToName',
        'priority',
        'targetDate',
        'closingDate',
        'actionStatus',
      ];
      const actionsHeaders = createHeaders(actionsFields, this.rtl);
      const actionsTableData = generateTableData(this.actions, actionsFields);
      y = addTable(doc, y, actionsTableData, actionsHeaders, this.rtl);
    }
    doc.save(`report-incident-${data?.id}.pdf`);
  }

  addTeamMemberRow(): void {
    this.teamMemberFormArray.push(this.createteamMemberFormGroup());
    this.closeTeamForm.get('closeTeamMemberForm')['controls'] = [
      ...this.teamMemberFormArray.controls,
    ];

    this.teamMemberFormArray.controls = [...this.teamMemberFormArray.controls];
  }

  acknowledgeModalOpen(): void {
    this.dialog.open(this.acknowledgeModal, {
      height: 'auto',
      width: '50%',
      disableClose: true,
    });
  }

  setCustomReminderEscalationMessage(message: any): void {
    const data = {
      instanceKey: this.instanceKey,
      isReminder: true,
      isEscalation: false,
      message: message,
    };
    this.workFlowService.setReminderEscalationMessage(data).subscribe({
      next: (res) => {
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
      },
    });
  }
  setCustomUsersHierarchy(users: any[]): void {
    const data = {
      instanceKey: this.instanceKey,
      isReminder: true,
      isEscalation: false,
      reminderUsers: users?.map((ele) => {
        return {
          level: ele?.level,
          email: ele?.email,
          userId: ele?.userId,
        };
      }),
      escalationUsers: [],
    };
    this.workFlowService.setCustomUserForReminderOrEscalation(data).subscribe({
      next: (res) => {
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
      },
    });
  }

  teamSelected(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_SELECT_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    const users = this.membersArray?.map((ele, index) => {
      return {
        level: index + 1,
        email: ele?.email,
      };
    });
    users.push({
      level: users.length + 1,
      email: this.userDetail?.email,
    });
    // this.loadingService.startLoading(true, '');
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.setCustomUsersHierarchy(users);
        this.teamHasbeenSelected();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, 'Team has been selected')
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  teamHasbeenSelected(): void {
    this.incidentReportService.teamSelectedIncident(this.data?.id).subscribe({
      next: (response) => {
        this.onInvestigate.emit(true);
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
      },
    });
  }

  completeInvestigation(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const data: SetInstanceParam = {
            instanceKey: this.instanceKey,
            userId: this.loggedUser.userId,
            userName: this.loggedUser.fullName,
            actionName: Constants.APP_INVESTIGATE_ACTION,
            rules: [],
            roleId: this.loggedUser.roleIds,
            payload: this.data,
          };
          this.loadingService.startLoading(true, '');
          this.workFlowService.setWorkFlowInstance(data).subscribe({
            next: (response) => {
              this.investigationCompleted();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  teamDeSelected(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['Reject']['Title'],
          this.translationsList['CheckAlerts']['Reject']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.rulesAppliedAcknowledge = this.rulesAppliedAcknowledge?.map(
            (ele) => {
              return {
                ...ele,
                rightOperand: 'No',
              };
            }
          );
          const data: SetInstanceParam = {
            instanceKey: this.instanceKey,
            userId: this.loggedUser.userId,
            userName: this.loggedUser.fullName,
            actionName: Constants.APP_APPROVE_REJECT_ACTION,
            rules: this.rulesAppliedAcknowledge,
            roleId: this.loggedUser.roleIds,
            payload: this.data,
          };
          this.loadingService.startLoading(true, '');
          this.workFlowService.setWorkFlowInstance(data).subscribe({
            next: (response) => {
              this.incidentReportService
                .teamDeSelectIncident(
                  this.data?.id,
                  this.commentForm.value.comment
                )
                .subscribe({
                  next: (response) => {
                    this.onInvestigate.emit(true);
                    this.loadingService.stopLoading();
                    this.dialog.closeAll();
                    this.feedBackService.showFeedback(
                      new FeedbackModel(
                        FeedbackType.Success,
                        'Team has been rejected'
                      )
                    );
                  },
                  error: ({ error }) => {
                    this.loadingService.stopLoading();
                  },
                });
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }
  investigationCompleted(): void {
    this.incidentReportService
      .investigationCompletedIncident(this.data?.id)
      .subscribe({
        next: (response) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, response?.message)
          );
          this.onInvestigate.emit(true);
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.loadingService.stopLoading();
        },
      });
  }

  acknowledge(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['Acknowledge']['Title'],
          this.translationsList['CheckAlerts']['Acknowledge']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.rulesAppliedAcknowledge = this.rulesAppliedAcknowledge?.map(
            (ele) => {
              return {
                ...ele,
                rightOperand: 'Yes',
              };
            }
          );
          const data: SetInstanceParam = {
            instanceKey: this.instanceKey,
            userId: this.loggedUser.userId,
            userName: this.loggedUser.fullName,
            actionName: Constants.APP_APPROVE_REJECT_ACTION,
            rules: this.rulesAppliedAcknowledge,
            roleId: this.loggedUser.roleIds,
            payload: this.data,
          };
          this.loadingService.startLoading(true, '');
          this.workFlowService.setWorkFlowInstance(data).subscribe({
            next: (response) => {
              this.dialog.closeAll();
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Success,
                  'Team has been Acknowledged'
                )
              );
              this.teamApproved();
              this.setCustomReminderEscalationMessage(
                'Team has been Acknowledged'
              );
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  openRejectTeamModal(): void {
    this.commentForm.get('comment')?.setValue('');
    this.dialog.open(this.rejectTeamModal, { height: 'auto', width: '50%' });
  }
  rejectTeam(): void {
    this.dialog.closeAll();
    this.openRejectTeamModal();
  }

  teamApproved(): void {
    this.incidentReportService.incidentTeamApproved(this.data?.id).subscribe({
      next: (response) => {
        this.getNextTransitionAssigneesFromWorkFlow();
      },
      error: ({ error }) => {
        this.loadingService.stopLoading();
      },
    });
  }

  chooseInvestigation(): void {
    this.investigationForm.get('investCheckbox')?.setValue(false);
    this.closeForm.get('investCheckbox').setValue(false);
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['ChooseInvestigation']['Title'],
          this.translationsList['CheckAlerts']['ChooseInvestigation']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.investigationForm.get('investCheckbox')?.setValue(true);
          this.closeForm.get('investCheckbox').setValue(true);
          this.rulesApplied = this.rulesApplied?.map((ele) => {
            return {
              ...ele,
              rightOperand: 'Yes',
            };
          });
          this.setWorkFlowInstance(true, false);
        } else {
          this.investigationForm.get('investCheckbox')?.setValue(false);
          this.closeForm.get('investCheckbox').setValue(false);
          if (!this.investigationForm.get('investCheckbox').value) {
            this.userDetail = null;
            this.membersArray = [];
            this.teamLeadForm.reset();
            this.teamMemberForm.reset();
          }
          this.rulesApplied = this.rulesApplied?.map((ele) => {
            return {
              ...ele,
              rightOperand: 'No',
            };
          });
          this.setWorkFlowInstance(false, false);
        }
      });
  }

  requireInvestigation(): void {
    this.incidentReportService
      .requireInvestigationIncident(this.data?.id)
      .subscribe({
        next: (response) => {
          this.submitInvestigationData(true);
        },
        error: ({ error }) => {
          this.loadingService.stopLoading();
          this.investigationForm.get('investCheckbox')?.setValue(false);
        },
      });
  }
  incidentStatusChangesToInprogress(): void {
    this.incidentReportService
      .incidentStatusChangesToInprogress(this.data?.id)
      .subscribe({
        next: (response) => {
          this.onInvestigate.emit(true);
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.loadingService.stopLoading();
        },
      });
  }

  setWorkFlowInstance(isRequired: boolean, isDirectClose: boolean): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser?.userId,
      userName: this.loggedUser?.fullName,
      actionName: Constants.APP_YESORNO_ACTION,
      rules: this.rulesApplied,
      roleId: this.loggedUser?.roleIds,
      payload: this.data,
    };
    this.loadingService.startLoading(true, '');
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        if (isRequired) {
          this.requireInvestigation();
          this.getTransitionRulesForAcknowledge();
        } else {
          if (isDirectClose) {
            this.setCloseWorkFlowInstance();
          } else {
            this.incidentStatusChangesToInprogress();
          }
        }
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.investigationForm.get('investCheckbox')?.setValue(false);
        this.closeForm.get('investCheckbox')?.setValue(false);
        this.loadingService.stopLoading();
      },
    });
  }
  getTransitionRules(): void {
    const data = {
      instanceKey: this.instanceKey,
      actionName: Constants.APP_YESORNO_ACTION,
    };
    this.workFlowService.getTransitionRules(data).subscribe({
      next: (response) => {
        this.rulesApplied = response;
      },
      error: ({ error }) => {
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  getTransitionRulesForAcknowledge(): void {
    const data = {
      instanceKey: this.instanceKey,
      actionName: Constants.APP_APPROVE_REJECT_ACTION,
    };
    this.workFlowService.getTransitionRules(data).subscribe({
      next: (response) => {
        this.rulesAppliedAcknowledge = response;
      },
      error: ({ error }) => {
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onCreateAction(event: boolean): void {
    this.getActionById();
  }

  goBack(): void {
    this.route.queryParams.subscribe((params) => {
      this.router.navigate(
        [
          '/' +
            SharedConstants.REPORTING_MANAGEMENT +
            '/' +
            SharedConstants.REPORT_INCIDENT,
        ],
        {
          queryParams: params,
        }
      );
    });
  }

  editReportIncident(): void {
    this.router.navigate([
      SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_INCIDENT +
        '/' +
        SharedConstants.REPORT_INCIDENT_EDIT +
        '/' +
        this.data.id,
    ]);
    // this.onInvestigate.emit(true);
    this.closeDialog();
  }

  openCloseModal(): void {
    this.getIncidentClassifications();
    this.getRootCause();
    this.closeForm
      .get('incidentClassification')
      .setValue(this.data?.incidentClassificationId);
    this.closeForm.get('incidentRisk').setValue(this.data?.incidentRisk);

    this.altPopup = false;
    this.closeForm.get('comment')?.setValue('');
    this.closeForm.get('rcaFile')?.setValue([]);
    if (this.data?.id && this.data?.instanceKey) {
      this.dialog.open(this.closeModal, { maxHeight: '90vh', width: '50%' });
    }
  }

  closeIncidentReport(): void {
    const { comment, rcaFile } = this.closeForm.value;
    const data = {
      id: this.data?.id,
      comment: comment,
    };
    const formData = new FormData();
    formData.append('file', rcaFile[0]);
    formData.append('data', JSON.stringify(data));
    this.incidentReportService.closeIncidentReport(formData).subscribe({
      next: (res) => {
        this.loadingService.stopLoading();
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
        this.closeDialog();
        this.goBack();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  checkCloseOrNot(): void {
    if (
      this.closeForm.get('investCheckbox')?.value &&
      this.data?.status === 'NEW'
    ) {
      this.investigationForm.get('investCheckbox')?.setValue(true);
      this.closeForm.get('investCheckbox')?.setValue(true);
      this.rulesApplied = this.rulesApplied?.map((ele) => {
        return {
          ...ele,
          rightOperand: 'Yes',
        };
      });
      this.setWorkFlowInstance(true, false);
      this.closeDialog();
    } else {
      const obj = {
        id: this.data?.id,
        incidentClassificationId: this.closeForm.get('incidentClassification')
          .value,
        rootCauseId: this.closeForm.get('rootCause').value,
        incidentRisk: this.closeForm.get('incidentRisk').value,
      };
      const NewFormData = new FormData();
      NewFormData.append('content', JSON.stringify(obj));
      this.incidentReportService.editIncidentReport(NewFormData).subscribe({
        next: () => {
          this.investigationForm.get('investCheckbox')?.setValue(false);
          this.closeForm.get('investCheckbox')?.setValue(false);
          if (!this.investigationForm.get('investCheckbox').value) {
            this.userDetail = null;
            this.membersArray = [];
            this.teamLeadForm.reset();
            this.teamMemberForm.reset();
          }
          this.rulesApplied = this.rulesApplied?.map((ele) => {
            return {
              ...ele,
              rightOperand: 'No',
            };
          });
          this.data.incidentRisk = obj.incidentRisk;
          this.data.rootCauseId = obj.rootCauseId;
          this.data.rootCause = this.rootCauses.find(
            (root) => root.id === obj.rootCauseId
          )?.rootCauseName;
          this.data.classification = obj.incidentClassificationId;
          if (this.data?.isInvestigationCompleted) {
            this.setCloseWorkFlowInstance();
          } else {
            this.setWorkFlowInstance(false, true);
          }
        },
        error: () => {},
      });
    }
  }

  setCloseWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_CLOSE_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    this.loadingService.startLoading(true, '');
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.closeIncidentReport();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  onKeyDown(event: KeyboardEvent) {
    if (
      event.key === '-' ||
      event.key === '.' ||
      (!event.key.match(/^[0-9]+$/) &&
        event.key !== 'Backspace' &&
        event.key !== 'Delete' &&
        event.key !== 'ArrowLeft' &&
        event.key !== 'ArrowRight' &&
        event.key !== 'Home' &&
        event.key !== 'End')
    ) {
      event.preventDefault();
    }
  }

  onWheel(event: MouseEvent) {
    event.preventDefault();
  }
}
