import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boolean-view',
  templateUrl: './boolean-view.component.html',
  styleUrls: ['./boolean-view.component.scss']
})
export class BooleanViewComponent implements OnInit {
  @Input() value = false;
  @Input() text = '';
  @Input() width = '';
  @Input() height = '';
  @Input() trueColor = '#137B25';
  @Input() falseColor = '#FF3C00';
  @Input() trueTextColor = 'var(--color-on-primary-high-emphasis)';
  @Input() falseTextColor = 'var(--color-on-primary-high-emphasis)';
  constructor() { }

  ngOnInit(): void { }
}
