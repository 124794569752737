import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { PopoverRef } from '../popover/popover-ref';

@Component({
  selector: 'hodhod-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  constructor(private readonly popoverRef: PopoverRef, private router: Router) { }

  ngOnInit(): void {
  }

  close(): void {
    /** Wait for animation to complete and then close */
    setTimeout(() => this.popoverRef.close(), 250);
  }

  onClickSwitchAcc(): void {
    this.router.navigate(['/' + SharedConstants.USER_MANAGEMENT + '/' + SharedConstants.SWITCH_ACCOUNT])
  }
}
