<hodhod-page-layout>
  <hodhod-page-layout-content class="container-fluid">
    <div class="card overflow-auto">
      <div
        class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center"
      >
        <h2
          class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
        >
          <span>{{ "DashboardTarget.Title" | translate }}</span>
        </h2>
        <div
          class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
        >
          <mat-icon
            class="icon-sm text-secondary"
            svgIcon="mat:search"
          ></mat-icon>
          <input
            [formControl]="searchCtrl"
            class="px-4 py-2 border-0 outline-none w-full bg-transparent"
            [placeholder]="'Placeholder.Search' | translate"
            type="search"
          />
        </div>

        <span class="flex-1"></span>

        <button
          [matMenuTriggerFor]="columnFilterMenu"
          class="ml-4 flex-none"
          mat-icon-button
          [matTooltip]="'General.Buttons.FilterColumns' | translate"
          type="button"
        >
          <mat-icon svgIcon="mat:filter_list"></mat-icon>
        </button>
      </div>

      <app-state-section
        body
        [flexibleHeight]="true"
        [state]="sectionState"
        [loadingLabel]="loadingLabel | translate"
        [errorLabel]="'Errors.ErrorHappened' | translate"
      >
        <table
          @stagger
          [dataSource]="dataSource"
          class="w-full"
          mat-table
          matSort
        >
          <!-- Text Columns -->
          <ng-container
            *ngFor="let column of columns; trackBy: trackByProperty"
          >
            <ng-container
              *ngIf="column.type === 'text'"
              [matColumnDef]="column.property"
            >
              <th
                *matHeaderCellDef
                class="uppercase"
                mat-header-cell
                mat-sort-header
              >
                {{ "DashboardTarget.Table." + column.label | translate }}
              </th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                <div class="w-10/12 truncate" #div>
                  <span
                    [matTooltip]="
                      div?.offsetWidth < div?.scrollWidth
                        ? row[column.property]
                        : null
                    "
                    >{{
                      column.property == "type"
                        ? ("Dashboards." + row[column.property] | translate)
                        : row[column.property]
                    }}</span
                  >
                </div>
                <!-- <span
                    *ngIf="column.property === 'roleName' && row?.isDefault"
                    class="bg-primary text-white rounded-full p-1 font-normal px-3"
                    >Default</span
                  > -->
              </td>
            </ng-container>
            <ng-container
              *ngIf="column.type === 'date'"
              [matColumnDef]="column.property"
            >
              <th
                *matHeaderCellDef
                class="uppercase"
                mat-header-cell
                mat-sort-header
              >
                {{ "DashboardTarget.Table." + column.label | translate }}
              </th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                {{ row[column.property] | date : "dd-MM-yyyy" }}
              </td>
            </ng-container>
            <ng-container
              *ngIf="
                column.type === 'boolean' && column.property === 'isActive'
              "
              [matColumnDef]="column.property"
            >
              <th *matHeaderCellDef class="uppercase" mat-header-cell>
                {{ "User.Table." + column.label | translate }}
                <!-- <div
                    style="width: 100%; position: relative"
                    class="flex items-center"
                  >
                    <svg-icon
                      app-click-stop-propagation
                      style="margin-inline-end: 5px"
                      (click)="select.open()"
                      src="assets/img/resources/table-dropdown-arrow.svg"
                    ></svg-icon>
                    {{ "User.Table." + column.label | translate }}
                    <mat-select
                      [value]="selectedDbTargetStatus"
                      (selectionChange)="onSelectDbStatus($event)"
                      #select
                      class="select-options"
                      multiple
                    >
                      <mat-option
                        *ngFor="let statue of DbTargetStatus | enumToArray"
                        [value]="statue.value"
                      >
                        {{ "Enum.CompanyUserStatus." + statue.value | translate }}
                      </mat-option>
                    </mat-select>
                  </div> -->
              </th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                <app-boolean-view
                  [value]="row[column.property] === true"
                  [text]="
                    row[column.property] === true
                      ? ('General.Active' | translate)
                      : ('General.Inactive' | translate)
                  "
                >
                </app-boolean-view>
              </td>
            </ng-container>
            <ng-container
              *ngIf="column.type === 'button' && column.property === 'actions'"
              [matColumnDef]="column.property"
              stickyEnd
            >
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let row" mat-cell>
                <div (click)="$event.stopPropagation()">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon svgIcon="mat:more_vert"></mat-icon>
                  </button>
                  <mat-menu
                    #menu="matMenu"
                    yPosition="below"
                    xPosition="before"
                    class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
                  >
                    <div
                      mat-menu-item
                      *ngIf="
                        row?.isActive === true &&
                        (hasActivateSafetyKPIsPermission | permissionChecker)
                      "
                      class="flex items-center justify-between p-1 mat-div"
                      (click)="deactivate(row?.type, row?.id)"
                    >
                      <button>
                        {{ "General.Buttons.Deactivate" | translate }}
                      </button>
                      <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                    </div>
                    <div
                      mat-menu-item
                      *ngIf="
                        row?.isActive === false &&
                        (hasActivateSafetyKPIsPermission | permissionChecker)
                      "
                      class="flex items-center justify-between p-1 mat-div"
                      (click)="activate(row?.type, row?.id)"
                    >
                      <button>
                        {{ "General.Buttons.Activate" | translate }}
                      </button>
                      <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                    </div>
                    <div
                      mat-menu-item
                      class="flex items-center justify-between p-1 mat-div"
                      (click)="view(row?.type, row?.id)"
                    >
                      <button>
                        {{ "General.Buttons.View" | translate }}
                      </button>
                      <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                    </div>
                  </mat-menu>
                </div>
              </td>
            </ng-container>
          </ng-container>

          <!-- Actions Column -->

          <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
          <tr
            *matRowDef="let row; columns: visibleColumns"
            @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer"
            mat-row
            (click)="view(row?.type, row?.id)"
          ></tr>
        </table>
        <div
          *ngIf="dataSource.filteredData.length === 0"
          @scaleFadeIn
          class="flex-auto flex flex-col items-center justify-center"
        >
          <img src="assets/img/resources/empty-state.svg" alt="not found" />
          <h2 class="headline m-0 text-center text-primary">
            {{ "General.EmptyTableList" | translate }}...
          </h2>
        </div>

        <mat-paginator
          [pageSizeOptions]="pageSizeOptions"
          [length]="pagination.length"
          [pageSize]="pagination.pageSize"
          [pageIndex]="pagination.pageIndex"
          (page)="handlePageEvent($event)"
          showFirstLastButtons
          class="sticky left-0"
        ></mat-paginator>
      </app-state-section>
    </div>

    <mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
      <button
        mat-menu-item
        (click)="toggleColumnVisibility(column, $event)"
        *ngFor="let column of columns"
        class="checkbox-item mat-menu-item"
      >
        <mat-icon
          class="text-[#757575] me-1"
          *ngIf="!column.visible"
          svgIcon="mat:check_box_outline_blank"
        ></mat-icon>
        <mat-icon
          *ngIf="column.visible"
          class="text-primary me-1"
          svgIcon="mat:check_box"
        ></mat-icon>
        {{ "DashboardTarget.Table." + column.label | translate }}
      </button>
    </mat-menu>
  </hodhod-page-layout-content>
</hodhod-page-layout>
