import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
// material modules imported
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MatNativeDateModule,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

// custom shared components
import { LoadingComponent } from './components/loading/loading.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { EnumToArrayPipe } from './pipes/enum-to-array.pipe';
import { SharedComponent } from './components/shared/shared.component';
import { LanguageButtonComponent } from './components/language-button/language-button.component';
import { LanguageService } from 'src/@hodhod/services/language.service';
import ar from '@angular/common/locales/ar';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PaginatorLocalization } from './helpers/paginator-localization';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AvatarModule } from 'ngx-avatars';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { ShowResultComponent } from './components/show-result/show-result.component';
import { PageLayoutModule } from 'src/@hodhod/components/page-layout/page-layout.module';
import { BreadcrumbsModule } from 'src/@hodhod/components/breadcrumbs/breadcrumbs.module';
import { SecondaryToolbarModule } from 'src/@hodhod/components/secondary-toolbar/secondary-toolbar.module';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { BooleanViewComponent } from './components/boolean-view/boolean-view.component';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { BaseComponent } from './components/base-component/base.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { StateSectionComponent } from './components/state-section/state-section.component';
import { BasicButtonComponent } from './components/basic-button/basic-button.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { ExpandablePanelComponent } from './components/expandable-panel/expandable-panel.component';
import { CardTypeComponent } from './components/card-type/card-type.component';
import { ContactsCardComponent } from './components/contacts-card/contacts-card.component';
import { ReverseRenderingListPipe } from './pipes/reverse-rendering-list.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CardComponent } from './components/card/card.component';
import { PermissionCheckerPipe } from './pipes/permission-checker.pipe';
import { DismissAlertComponent } from './components/dismiss-alert/dismiss-alert.component';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { AddContactCardComponent } from './components/add-contact-card/add-contact-card.component';
import { MatCardModule } from '@angular/material/card';
import { StringToArrayPipe } from './pipes/string-to-array.pipe';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { ProgressWidgetComponent } from './components/progress-widget/progress-widget.component';
import { ChartModule } from 'angular-highcharts';
import { CarouselModule } from 'ng-carousel-cdk';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgxMatSelectModule } from './components/ngx-mat-select/ngx-mat-select.module';
import {
  NgxMatSelectConfigs,
  NGX_MAT_SELECT_CONFIGS,
} from './components/ngx-mat-select/ngx-mat-select-configs';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchCardComponent } from './components/search-card/search-card.component';
import { FreeTrialWelcomeMessageComponent } from './components/free-trial-welcome-message/free-trial-welcome-message.component';

const MATERIAL_MODULES = [
  MatFormFieldModule,

  MatInputModule,
  MatRippleModule,
  MatIconModule,
  MatSelectModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatTabsModule,
  MatButtonModule,
  MatTableModule,
  MatSortModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatExpansionModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatRadioModule,
  MatBadgeModule,
  MatChipsModule,
  MatMenuModule,
  DragDropModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatBottomSheetModule,
  MatDialogModule,
  MatDividerModule,
  MatSlideToggleModule,
  MatCardModule,
  MatGridListModule,
  NgxMatSelectModule.forRoot({
    maxWidthForMobileView: 600,
    inFirstLoadCallSearcher: true,
    inFirstLoadSearcherValue: '',
    emptyLabel: 'no entry found',
    noMoreResultLabel: 'no more found',
    useInfiniteScroll: false,
    searchBoxPlaceholder: 'Search',
    maximumResultForShow: 4000,
    useMobileView: false,
    valueMember: 'key',
    displayMember: 'value',
    mobileViewType: 'FullScreen',
  }),
  NgxPaginationModule,
];

const SHARED_FORMS_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
];

const avatarColors = ['#f1c40f', '#2c3e50', '#95a5a6', '#f39c12', '#1abc9c'];

const SHARED_LAYOUT_MODULES = [
  PageLayoutModule,
  BreadcrumbsModule,
  SecondaryToolbarModule,
];

export const ngxMatSelectConfigs: NgxMatSelectConfigs = {
  maxWidthForMobileView: 600,
  inFirstLoadCallSearcher: true,
  inFirstLoadSearcherValue: '',
  emptyLabel: 'no entry found',
  noMoreResultLabel: 'no more found',
  useInfiniteScroll: false,
  searchBoxPlaceholder: 'please search',
  maximumResultForShow: 40000,
  useMobileView: false,
  valueMember: 'key',
  displayMember: 'value',
  mobileViewType: 'FullScreen',
};

@NgModule({
  declarations: [
    LoadingComponent,
    SpinnerComponent,
    EnumToArrayPipe,
    SharedComponent,
    LanguageButtonComponent,
    SnackBarComponent,
    ShowResultComponent,
    SkeletonLoaderComponent,
    BooleanViewComponent,
    ActionButtonComponent,
    BaseComponent,
    SpinnerComponent,
    StateSectionComponent,
    BasicButtonComponent,
    ConfirmationComponent,
    ExpandablePanelComponent,
    CardTypeComponent,
    ContactsCardComponent,
    AddContactCardComponent,
    ReverseRenderingListPipe,
    CardComponent,
    PermissionCheckerPipe,
    DismissAlertComponent,
    StringToArrayPipe,
    ProgressWidgetComponent,
    SearchCardComponent,
    FreeTrialWelcomeMessageComponent,
  ],
  imports: [
    SHARED_FORMS_MODULES,
    MATERIAL_MODULES,
    SHARED_LAYOUT_MODULES,
    CommonModule,
    AngularSvgIconModule.forRoot(),
    AvatarModule.forRoot({
      colors: avatarColors,
    }),
    NgxSkeletonLoaderModule,
    FileUploadModule,
    NgxMatTimepickerModule,
    ChartModule,
    CarouselModule,
  ],
  exports: [
    SHARED_FORMS_MODULES,
    MATERIAL_MODULES,
    SHARED_LAYOUT_MODULES,
    AngularSvgIconModule,
    AvatarModule,
    NgxSkeletonLoaderModule,
    LoadingComponent,
    SpinnerComponent,
    EnumToArrayPipe,
    SharedComponent,
    LanguageButtonComponent,
    SnackBarComponent,
    ShowResultComponent,
    SkeletonLoaderComponent,
    BooleanViewComponent,
    ActionButtonComponent,
    BaseComponent,
    StateSectionComponent,
    BasicButtonComponent,
    ConfirmationComponent,
    ExpandablePanelComponent,
    CardTypeComponent,
    ContactsCardComponent,
    AddContactCardComponent,
    ReverseRenderingListPipe,
    CardComponent,
    PermissionCheckerPipe,
    DismissAlertComponent,
    FileUploadModule,
    StringToArrayPipe,
    NgxMatTimepickerModule,
    ProgressWidgetComponent,
    ChartModule,
    CarouselModule,
    SearchCardComponent,
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new PaginatorLocalization(translateService).getPaginatorIntl(),
    },
    { provide: NGX_MAT_SELECT_CONFIGS, useValue: ngxMatSelectConfigs },
  ],
})
export class SharedModule {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    private languageService: LanguageService
  ) {
    this.languageService.languageChanged.subscribe((language) => {
      dateAdapter.setLocale(language.isoCode); // DD/MM/YYYY
    });
    dateAdapter.setLocale(this.languageService.currentLanguage$.value.isoCode); // DD/MM/YYYY
    registerLocaleData(ar);
  }
}
