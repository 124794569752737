<app-card [title]="'organization.UpdateOrganization' | translate">
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="'organization.' + loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <form [formGroup]="updateOrgForm">
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div>
          <div class="flex column">
            <mat-label class="field-label">{{
              "organization.BasicForm.Code" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                [placeholder]="'Placeholder.AddText' | translate"
                matInput
                type="text"
                formControlName="orgCode"
                readonly
              />
              <mat-error
                *ngIf="
                  updateOrgForm.invalid &&
                  updateOrgForm.get('orgCode').hasError('required')
                "
                >{{ "Errors.Required" | translate }}</mat-error
              >
              <mat-error
                *ngIf="updateOrgForm?.get('orgCode').hasError('whitespace')"
              >
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.InValidData" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex column">
            <mat-label class="field-label">{{
              "organization.BasicForm.Name" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                [placeholder]="'Placeholder.AddText' | translate"
                matInput
                type="text"
                formControlName="orgName"
              />
              <mat-error
                *ngIf="
                  updateOrgForm.invalid &&
                  updateOrgForm.get('orgName').hasError('required')
                "
                >{{ "Errors.Required" | translate }}</mat-error
              >
              <mat-error
                *ngIf="updateOrgForm?.get('orgName').hasError('whitespace')"
              >
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.InValidData" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex column">
            <mat-label class="field-label">{{
              "organization.BasicForm.NoOfCompany" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                [placeholder]="'Placeholder.AddText' | translate"
                matInput
                type="number"
                formControlName="noOfCompanies"
                min="1"
              />
              <mat-error
                *ngIf="
                  updateOrgForm.invalid &&
                  updateOrgForm.get('noOfCompanies').hasError('required')
                "
                >{{ "Errors.Required" | translate }}</mat-error
              >
            </mat-form-field>
          </div>
          <div class="flex column">
            <mat-label class="field-label">{{
              "organization.BasicForm.NoOfUser" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                [placeholder]="'Placeholder.AddText' | translate"
                matInput
                type="number"
                formControlName="noOfUsers"
                min="1"
              />
              <mat-error
                *ngIf="
                  updateOrgForm.invalid &&
                  updateOrgForm.get('noOfUsers').hasError('required')
                "
                >{{ "Errors.Required" | translate }}</mat-error
              >
            </mat-form-field>
          </div>
          <div class="flex column">
            <mat-label class="field-label">{{
              "organization.BasicForm.Description" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <textarea
                [placeholder]="'Placeholder.AddText' | translate"
                rows="2"
                matInput
                type="text"
                formControlName="description"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <div>
          <div class="flex justify-between items-center">
            <div class="flex column w-full">
              <mat-label class="field-label">{{
                "organization.BasicForm.Image" | translate
              }}</mat-label>
              <file-upload
                formControlName="orgImage"
                [multiple]="false"
                [animation]="true"
                [accept]="'file_extension|image/*'"
              ></file-upload>
            </div>
            <div
              @fadeInUp
              class="avatar-container cursor-pointer p-3"
              *ngIf="orgInfo"
            >
              <ngx-avatars
                bgColor="#2c3e5000"
                fgColor="#222222"
                class="avatar box"
                size="180"
                [ngStyle]="{
                  'border-color':
                    orgInfo?.status === 'ACTIVE' ? ACTIVE_COLOR : INACTIVE_COLOR
                }"
                [src]="orgInfo?.organizationImage"
                [name]="orgInfo?.organizationName | uppercase"
                [textSizeRatio]="3"
                [initialsSize]="2"
                [round]="true"
              ></ngx-avatars>
              <div
                class="label"
                [ngStyle]="{
                  'background-color':
                    orgInfo?.status === 'ACTIVE' ? ACTIVE_COLOR : INACTIVE_COLOR
                }"
              >
                <span *ngIf="orgInfo?.status === 'ACTIVE'">{{
                  "General.Active" | translate | uppercase
                }}</span>
                <span *ngIf="orgInfo?.status === 'INACTIVE'">{{
                  "General.Inactive" | translate | uppercase
                }}</span>
              </div>
            </div>
          </div>
          <div class="my-2">
            <mat-label class="field-label">{{
              "organization.BasicForm.Theme" | translate
            }}</mat-label>

            <div class="flex items-center">
              <div class="border border-gray-200 p-2 m-1 w-4/12 rounded-lg">
                <input type="color" formControlName="primary" />
                <label for="head" class="p-2">Primary</label>
              </div>
              <img
                src="/assets/img/resources/info-icon.png"
                class="mx-2"
                [matTooltip]="
                  'Hint: Please, avoid bright colors for better contrast.'
                "
                [matTooltipPosition]="'right'"
              />
            </div>
            <div class="flex items-center">
              <div class="border border-gray-200 p-2 m-1 w-4/12 rounded-lg">
                <input type="color" id="body" formControlName="secondary" />
                <label for="body" class="p-2">Secondary</label>
              </div>
              <img
                src="/assets/img/resources/info-icon.png"
                class="mx-2"
                [matTooltip]="
                  'Hint: For better visibility, better to be black or white.'
                "
                [matTooltipPosition]="'right'"
              />
            </div>
          </div>
        </div>
      </div>
      <h3 class="text-base font-bold mb-2">
        {{ "organization.OrganizationFeatures" | translate }}
      </h3>
      <div class="flex column">
        <div *ngIf="sefetyFeatures.length > 0">
          <mat-label class="field-label"
            >{{ "Company.BasicForm.SafetyFeatures" | translate }}
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              [placeholder]="'Company.BasicForm.SafetyFeatures' | translate"
              formControlName="safetyFeatures"
              multiple
              (selectionChange)="setSelectedFeature($event)"
            >
              <mat-option [value]="'ALL'"> All </mat-option>
              <mat-option
                *ngFor="let feature of sefetyFeatures"
                [value]="feature.featureCode"
              >
                {{ "Feature." + feature.featureName | translate }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                updateOrgForm.invalid &&
                updateOrgForm.get('safetyFeatures').hasError('required')
              "
              >{{ "Errors.Required" | translate }}</mat-error
            >
          </mat-form-field>
        </div>
        <div *ngIf="secFeatures.length > 0">
          <mat-label class="field-label"
            >{{ "Company.BasicForm.SecurityFeatures" | translate }}
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              [placeholder]="'Company.BasicForm.SecurityFeatures' | translate"
              formControlName="securityFeatures"
              multiple
              (selectionChange)="setSelectedFeature($event)"
            >
              <mat-option [value]="'ALL'"> All </mat-option>
              <mat-option
                *ngFor="let feature of secFeatures"
                [value]="feature.featureCode"
              >
                {{ "Feature." + feature.featureName | translate }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                updateOrgForm.invalid &&
                updateOrgForm.get('securityFeatures').hasError('required')
              "
              >{{ "Errors.Required" | translate }}</mat-error
            >
          </mat-form-field>
        </div>
        <div *ngIf="setLicenseQuota" class="flex column">
          <mat-label class="field-label"
            >{{ "Company.BasicForm.LicenseQuota" | translate }}
          </mat-label>
          <mat-form-field
            class="input-field"
            appearance="outline"
            class="w-5/12"
          >
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="number"
              formControlName="OrgQuota"
            />
            <mat-error
              *ngIf="
                updateOrgForm.invalid &&
                updateOrgForm.get('OrgQuota').hasError('required')
              "
              >{{ "Errors.Required" | translate }}</mat-error
            >
            <mat-error
              *ngIf="updateOrgForm?.get('OrgQuota').hasError('whitespace')"
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div
        class="grid gap-2 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1"
        *ngIf="selectedFeatures.length > 0"
      >
        <div
          class="rounded overflow-hidden shadow-lg border-b-4 border-primary"
          *ngFor="let feature of selectedFeatures; let i = index"
        >
          <div class="group w-full h-52 overflow-hidden relative">
            <img
              class="transition-all absolute top-0 left-0 h-full w-full hover:scale-110"
              [src]="'assets/img/resources/' + feature.image"
              [alt]="feature.featureName"
            />
            <div
              class="transition-transform duration-500 flex flex-col justify-center items-center font-bold text-primary feature_card_bg absolute bg-clip-content p-6 border-4 border-primary/50 border-dashed top-0 left-0 h-full w-full z-50 transform translate-y-60 group-hover:translate-y-0"
            >
              <img
                [src]="'assets/img/resources/' + feature.icon"
                [alt]="feature.featureName"
                class="w-16 mb-2"
              />
              {{ "Feature." + feature.featureName | translate }}
            </div>
          </div>
          <div class="px-4 py-4">
            <div
              class="font-bold text-lg mb-2 bg-clip-text text-transparent bg-gradient-to-r from-primary to-black text-center"
            >
              {{ "Feature." + feature.featureName | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end my-3">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="cancel()"
        ></app-basic-button>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="
            !updateOrgForm.valid || selectedFeatureCodes.length <= 0
          "
          [matTooltip]="
            selectedFeatureCodes.length <= 0
              ? 'Set at least one feature.'
              : null
          "
          (onClick)="updateOrganization()"
        ></app-basic-button>
      </div>
    </form>
  </app-state-section>
</app-card>
