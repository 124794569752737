import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import {
  IncidentReport,
  IncidentReportHistory,
} from 'src/app/reporting/models/incident-report';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class IncidentReportService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  createIncidentReport(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_INCIDENT_CREATE),
      data
    );
  }

  getIncidentReports(data: any): Observable<DataMapper<IncidentReport>> {
    const params = new URLSearchParams();
    if (data?.pageIndex) {
      params.set('offset', data?.pageIndex.toString());
    }
    if (data?.pageSize) {
      params.set('limit', data?.pageSize.toString());
    }
    if (data?.status) {
      params.set('status', data?.status);
    }
    if (data?.risks) {
      params.set('risks', data?.risks);
    }
    if (data?.sortDirection !== SortDirection.None) {
      params.set('sortDirection', data?.sortDirection);
    }
    if (data?.sortField) {
      params.set('sortField', data?.sortField);
    }
    if (data?.searchValue) {
      params.set('phrase', data?.searchValue);
    }
    if (data?.departments) {
      params.set('departments', data?.departments.toString());
    }
    const encodedParams = params.toString();

    return this.http.get<DataMapper<IncidentReport>>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_INCIDENTS + '?' + encodedParams
      )
    );
  }
  getIncidentReportById(id: number): Observable<IncidentReport> {
    return this.http.get<IncidentReport>(
      this.baseApi.encodeURL(Constants.API_REPORT_INCIDENT_BY_ID + '/' + id)
    );
  }
  deleteIncidentReportImage(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_INCIDENT_REMOVE_IMG + '/' + id
      )
    );
  }

  editIncidentReport(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_INCIDENT_EDIT),
      data
    );
  }

  getCustomUsersHierarchyFromIncident(id: number): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_CUSTOM_USERS_HIERARCHY_FROM_INCIDENT + '/' + id
      )
    );
  }

  closeIncidentReport(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_INCIDENT_CLOSE),
      data
    );
  }
  requireInvestigationIncident(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_INVESTIGATION_INCIDENT + '/' + id
      ),
      {}
    );
  }
  teamSelectedIncident(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_TEAMSELECTED_INCIDENT + '/' + id
      ),
      {}
    );
  }
  teamDeSelectIncident(id: number, comment: string): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_TEAM_DE_SELECT_INCIDENT + '/' + id
      ),
      { comment }
    );
  }
  investigationCompletedIncident(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_INVESTIGATION_COMPLETED_INCIDENT + '/' + id
      ),
      {}
    );
  }
  incidentStatusChangesToInprogress(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_INCIDENT_STATUS_CHANGES_TO_INPROGRESS + '/' + id
      ),
      {}
    );
  }

  incidentTeamApproved(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_REPORT_INCIDENT_TEAM_APPROVED + '/' + id
      ),
      {}
    );
  }

  getIncidentReportHistory(
    id: number
  ): Observable<DataMapper<IncidentReportHistory>> {
    return this.http.get<DataMapper<IncidentReportHistory>>(
      this.baseApi.encodeURL(Constants.API_REPORT_INCIDENT_HISTORY + '/' + id)
    );
  }

  removeIncidentReport(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_REPORT_INCIDENT_DELETE + '/' + id)
    );
  }

  saveIncidentReportLosses(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_SAVE_REPORT_INCIDENT_LOSSES),
      data
    );
  }

  updateIncidentReportLosses(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_REPORT_INCIDENT_LOSSES),
      data
    );
  }
}
