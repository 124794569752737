import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { VideoAnalyticsDashboardPageComponent } from './pages/video-analytics-dashboard-page/video-analytics-dashboard-page.component';
import { VideoAnalyticsPageComponent } from './pages/video-analytics-page/video-analytics-page.component';
import { VideoAnalyticsViewPageComponent } from './pages/video-analytics-view-page/video-analytics-view-page.component';
import { ViewVoilationPageComponent } from './pages/view-voilation-page/view-voilation-page.component';
import { VoilationPageComponent } from './pages/voilation-page/voilation-page.component';

const routes: Routes = [
  {
    path: SharedConstants.VIDEO_ANALYTICS,
    component: VideoAnalyticsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.VIDEO_ANALYTICS_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'VideoAnalytics',
      },
    }
    
  },
  {
    path: SharedConstants.VIDEO_ANALYTICS + '/' + SharedConstants.VIDEO_ANALYTICS_VIEW + '/:id',
    component: VideoAnalyticsViewPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.VIDEO_ANALYTICS_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true
      }
    }
  },
  {
    path: SharedConstants.VOILATIONS + '/:id',
    component: VoilationPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.VIOLATIONS_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Voilations',
      },
    }
    
  },
  {
    path: SharedConstants.VOILATIONS + '/' + SharedConstants.VOILATIONS_VIEW + '/:id',
    component: ViewVoilationPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.VIOLATIONS_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
      },
    }
    
  },
  {
    path: SharedConstants.VIDEO_ANALYTICS_DASHBOARD,
    component: VideoAnalyticsDashboardPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.VIDEO_DASHBOARD_VIEW,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Dashboard',
      },
    }
    
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmartMonitoringRoutingModule { }
