<div class="flex justify-end mr-1 mb-4" *ngIf="data?.status === 'New'">
  <app-basic-button
    *ngIf="hasSpecialPermission | permissionChecker"
    [btnType]="'Add text-lg'"
    [btnText]="'Submit'"
    (onClick)="submitVoilationForm()"
    [isDisabled]="!voilationForm.valid"
  ></app-basic-button>
</div>
<div class="flex gap-2">
  <div class="image_wrapper1">
    <app-expandable-panel
      [expanded]="true"
      #expandablePanel
      [title]="'Offence Report'"
    >
      <div body class="flex justify-between flex-wrap-reverse" *ngIf="data">
        <div class="flex-1">
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 caption font-bold text-sm text-primary">
                {{ "Voilations.View.CameraName" | translate }}
              </p>
              <p class="m-0 body-1">{{ data?.cameraName }}</p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 caption font-bold text-sm text-primary">
                {{ "Voilations.View.Department" | translate }}
              </p>
              <p class="m-0 body-1">{{ data?.departmentName }}</p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 caption font-bold text-sm text-primary">
                {{ "Voilations.View.CameraPosition" | translate }}
              </p>
              <p class="m-0 body-1">{{ data?.cameraPosition }}</p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 caption text-hint">
                {{ "Voilations.View.Time" | translate }}
              </p>
              <p class="m-0 body-1">
                {{ data?.timeDate | date : "dd-MM-yyyy hh:mm a" }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 caption text-hint">
                {{ "Voilations.View.Voilation" | translate }}
              </p>
              <p class="m-0 body-1">{{ data?.voilationName }}</p>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="py-3 flex items-center">
            <ngx-avatars
              bgColor="#2c3e5000"
              fgColor="#222222"
              class="logo box cursor-pointer"
              size="270"
              [src]="data?.picture ? data?.picture : ''"
              [name]="'Profile'"
              [cornerRadius]="10"
              [matTooltip]="'RenewIdCard.View.ViewImage' | translate"
              [textSizeRatio]="2"
              [initialsSize]="2"
              (clickOnAvatar)="openBigView()"
              [round]="false"
            >
            </ngx-avatars>
          </div>
          <div @fadeInUp class="flex items-center">
            <p class="text-left font-bold text-primary mr-4 rtl:ml-3">
              {{ "General.Status" | translate }}
            </p>
            <app-boolean-view
              [value]="data?.status === 'New'"
              [text]="
                data?.status === 'New'
                  ? ('Enum.VoilationsStatus.New' | translate)
                  : ('Enum.VoilationsStatus.Close' | translate)
              "
            >
            </app-boolean-view>
          </div>
        </div>
      </div>
    </app-expandable-panel>
    <div class="mt-4">
      <app-action
        *ngIf="data"
        [sourceId]="data?.id"
        [sourceType]="ReportingType.Voilation"
      ></app-action>
    </div>
  </div>
  <div class="card_wrapper">
    <form [formGroup]="voilationForm" class="form-container">
      <div class="card">
        <div class="flex justify-between">
          <h4 class="title">
            {{ "Voilations.Form.OffenderInformation" | translate }}
          </h4>
          <a
            *ngIf="data?.status === 'New'"
            class="text-primary font-bold cursor-pointer"
            (click)="resetForm()"
            >Reset</a
          >
        </div>
        <mat-radio-group
          class="radio-group"
          (change)="addValidators()"
          formControlName="offenderNature"
          [disabled]="
            voilationForm.get('offenderName')?.value ||
            voilationForm.get('isGuestOffender')?.value ||
            data?.status === 'Close'
              ? true
              : false
          "
        >
          <mat-radio-button
            class="radio-button"
            *ngFor="let nature of offenderNatures | enumToArray"
            [value]="nature.value"
          >
            {{ nature.value }}
          </mat-radio-button>
        </mat-radio-group>
        <hr class="mb-3" />
        <!-- users -->
        <div class="fields">
          <div class="field-full flex column">
            <mat-label class="field-label">{{
              "Voilations.Form.OffenderName" | translate
            }}</mat-label>
            <mat-form-field appearance="outline">
              <mat-select
                ngxMatSelect
                #msf2="ngxMatSelect"
                [displayMember]="'fullName'"
                [valueMember]="'userId'"
                [source]="users"
                [useInfiniteScroll]="true"
                [multiple]="false"
                [hasPermission]="hasCreateUserPermission | permissionChecker"
                [title]="'users'"
                [manageRoute]="'/user-management/users'"
                [placeholder]="'Placeholder.Select' | translate"
                (selectionChange)="addValidators()"
                formControlName="offenderName"
                [disabled]="
                  voilationForm.get('offenderNature')?.value ||
                  data?.status === 'Close' ||
                  voilationForm.get('isGuestOffender')?.value
                    ? true
                    : false
                "
              >
                <mat-option
                  *ngFor="let user of msf2.filteredSource"
                  [value]="user.fullName"
                  >{{ user.fullName }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="card">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <h4 class="title">
            {{ "Voilations.Form.GuestVisitor" | translate }}
          </h4>
          <mat-checkbox
            color="primary"
            (change)="addValidators()"
            formControlName="isGuestOffender"
            style="margin-top: -20px"
            [disabled]="
              voilationForm.get('offenderNature')?.value ||
              voilationForm.get('offenderName')?.value ||
              data?.status === 'Close'
                ? true
                : false
            "
          >
          </mat-checkbox>
        </div>
        <!-- guest/visitor offender name -->
        <div class="fields" *ngIf="voilationForm.get('isGuestOffender')?.value">
          <div class="field-full flex column">
            <mat-form-field appearance="outline">
              <input
                matInput
                type="text"
                [placeholder]="'Voilations.Form.GuestVisitor' | translate"
                formControlName="guestOffenderName"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>

    <!-- Closer Of Report -->
    <div class="card" *ngIf="data?.status === 'Close' && closerDetail">
      <h4 class="title">{{ "Voilations.View.CloserOfReport" | translate }}</h4>
      <hr class="mt-3" />
      <div class="py-3 flex items-center">
        <ngx-avatars
          bgColor="#2c3e5000"
          fgColor="#222222"
          class="logo box cursor-pointer mr-5"
          size="100"
          [src]="closerDetail?.userImage ? closerDetail?.userImage : ''"
          [name]="'Profile'"
          [borderColor]="'#d52a46'"
          [textSizeRatio]="2"
          [initialsSize]="2"
          [round]="true"
        >
        </ngx-avatars>
        <div @fadeInRight>
          <p class="font-bold text-lg text-center text-primary mb-5 rtl:ml-3">
            {{ closerDetail?.fullName }}
          </p>
          <app-boolean-view
            *ngIf="closerDetail?.departmentName"
            [value]="!closerDetail?.departmentName"
            [falseColor]="'#D52A46'"
            [width]="'165px'"
            [height]="'30px'"
            [matTooltip]="closerDetail?.departmentName"
            [text]="trimedDepartments"
          >
          </app-boolean-view>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Image Viewer Model -->
<ng-template #imageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="selectedImg" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">{{ data?.cameraName }}</h2>
    <button
      class="text-secondary"
      (click)="downloadDocument(selectedImg)"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:download"></mat-icon>
    </button>

    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <div class="flex">
    <div class="image_wrapper overflow-hidden">
      <img
        class="object-contain transition duration-500 cursor-zoom-in"
        (click)="moveImage($event)"
        [src]="selectedImg"
        [alt]="data?.cameraName"
      />
    </div>
  </div>
</ng-template>
