import { Injectable } from '@angular/core';
import { Constants } from 'src/@hodhod/common/constants';
import { LanguageEnum } from 'src/@hodhod/common/enum';
import { LoggedUser } from '../models/session-user/logged-user';

@Injectable({
  providedIn: 'root',
})
export class BaseApi {
  constructor() {}

  public getHeaders(): any {
    const companyCode = this.getCompanyIdFromStorage();
    const companyId = this.getCompanyFromStorage()?.companyId;
    const token = this.getTokenFromStorage();
    return {
      Authorization: `Bearer ${token}`,
      'Accept-Language': LanguageEnum.Arabic,
      'company-id': companyCode ? companyCode : '',
      companyId: companyId ? companyId.toString() : '',
      user_device:
        window.navigator.platform === 'iphone'
          ? 'mac'
          : window.navigator.platform,
      HodHodApiKey: Constants.API_ACCESS_KEY_SMARTMONITORING,
    };
  }

  public encodeURL(url: string): string {
    return url.replace(' ', '%20');
  }

  public getTokenFromStorage(): string {
    return localStorage.getItem(Constants.AUTH_TOKEN);
  }

  public removeTokenFromStorage() {
    localStorage.removeItem(Constants.AUTH_TOKEN);
  }

  public getCompanyIdFromStorage(): string {
    return localStorage.getItem(Constants.CURRENT_COMPANY_ID);
  }
  public removeCompanyIdFromStorage() {
    localStorage.removeItem(Constants.CURRENT_COMPANY_ID);
  }

  public saveCompanySession(companyId: string): void {
    localStorage.setItem(Constants.CURRENT_COMPANY_ID, companyId);
  }
  public getCompanyFromStorage(): any {
    const comp = localStorage.getItem(Constants.CURRENT_COMPANY);
    if (comp) {
      return JSON.parse(comp);
    }
    return null;
  }
  public removeCompanyFromStorage() {
    localStorage.removeItem(Constants.CURRENT_COMPANY);
  }

  public saveCompanyObjectSession(company: any): void {
    localStorage.setItem(Constants.CURRENT_COMPANY, JSON.stringify(company));
  }

  public getOrganizationFromStorage(): any {
    const org = localStorage.getItem(Constants.SESSION_ORGANIZATION);
    if (org) {
      return JSON.parse(org);
    }
    return null;
  }
  public removeOrganizationFromStorage() {
    localStorage.removeItem(Constants.SESSION_ORGANIZATION);
  }

  public saveOrganizationSession(org: any): void {
    localStorage.setItem(Constants.SESSION_ORGANIZATION, JSON.stringify(org));
  }

  public saveUserSession(user: LoggedUser): void {
    localStorage.setItem(Constants.LOGGED_IN_USER, JSON.stringify(user));
  }
  public removeUserSession() {
    localStorage.removeItem(Constants.LOGGED_IN_USER);
  }

  public getUserSession(): LoggedUser {
    const user = localStorage.getItem(Constants.LOGGED_IN_USER);
    if (user) {
      return JSON.parse(user);
    }
    return null;
  }

  public saveUserMenusSession(userMenus: any): void {
    localStorage.setItem(Constants.USER_MENUS, JSON.stringify(userMenus));
  }
  public removeUserMenusSession() {
    localStorage.removeItem(Constants.USER_MENUS);
  }

  public getUserMenusSession(): any[] {
    const menus = localStorage.getItem(Constants.USER_MENUS);
    if (menus) {
      return JSON.parse(menus);
    }
    return [];
  }
  public saveUserPermissionSession(userPermissions: any): void {
    localStorage.setItem(
      Constants.USER_PERMISSIONS,
      JSON.stringify(userPermissions)
    );
  }

  public getUserPermissionsSession(): any[] {
    const permissions = localStorage.getItem(Constants.USER_PERMISSIONS);
    if (permissions) {
      return JSON.parse(permissions);
    }
    return [];
  }
  public removeUserPermissionsSession() {
    localStorage.removeItem(Constants.USER_PERMISSIONS);
  }
}
