import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { PermissionService } from 'src/backend/services/permission.service';
import { IncidentReportService } from 'src/backend/services/reportings/incident-report.service';

@Component({
  selector: 'app-incident-report-view-page',
  templateUrl: './incident-report-view-page.component.html',
  styleUrls: ['./incident-report-view-page.component.scss'],
})
export class IncidentReportViewPageComponent implements OnInit {
  public mode: 'view' | 'edit' = 'view';
  public reportId: number = 0;
  private destroy$ = new Subject();
  public incidentReport: any;
  public formMode: any;

  hasEditPermission = ApplicationPermission.REPORTING_INCIDENT_UPDATE;
  hasViewPermission = ApplicationPermission.REPORTING_INCIDENT_ACCESS;

  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private incidentReportService: IncidentReportService,
    private feedBackService: AsyncFeedbackService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;
        this.reportId = parseInt(id, 10);
        this.getIncidentReport();
      }
    });
  }

  getIncidentReport(): void {
    this.loadingService.startLoading(true, '');
    this.incidentReportService.getIncidentReportById(this.reportId).subscribe({
      next: (response) => {
        this.incidentReport = response;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  onInvestigate(status: boolean): void {
    this.getIncidentReport();
  }
  onChangeView(status: boolean): void {
    this.getIncidentReport();
  }
  onAddLossCosts(status: boolean): void {
    this.getIncidentReport();
  }
}
