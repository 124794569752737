<div #originRef
     (click)="showPopover(originRef)"
     [class.bg-hover]="dropdownOpen"
     class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
     matRipple>
  <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">{{currentUser?.fullName}}</div>
  <ngx-avatars
  size="40"
  [src]="userImage()?.picture ? userImage()?.picture : ''"
  [name]="currentUser?.fullName"
  [textSizeRatio]="2"
  [round]="true"
  [initialsSize]="2"
  ></ngx-avatars>
</div>
