import { Component, HostListener, OnInit } from '@angular/core';
import { Dashboards } from 'src/@hodhod/common/enum';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ReportingDashboardService } from 'src/backend/services/dashboards/reporting-dashboard.service';
import { DashboardSetting } from '../../models/dashboard';

@Component({
  selector: 'app-dashboard-smart-safety',
  templateUrl: './dashboard-smart-safety.component.html',
  styleUrls: ['./dashboard-smart-safety.component.scss'],
})
export class DashboardSmartSafetyComponent
  extends BaseComponent
  implements OnInit
{
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public loadingLabel: string = '';

  public columns: number = 3;
  private screenWidth: number = null;

  public allDashboards: DashboardSetting[] = [];
  public visibleDashboards: DashboardSetting[] = [];
  public visibilityData = [];
  public dashboards = Dashboards;

  constructor(
    private reportingDashboardService: ReportingDashboardService,
    private feedBackService: AsyncFeedbackService
  ) {
    super();
  }

  override ngOnInit(): void {
    this.GetCustomizedDashboardByUserId();
  }

  // Get Customized Dashboard By UserId
  GetCustomizedDashboardByUserId() {
    this.sectionState = SectionStateStatus.Loading;
    this.reportingDashboardService.getCustomizedDashboardByUserId().subscribe({
      next: (res) => {
        this.allDashboards = res;
        if (this.allDashboards.length < Object.keys(this.dashboards).length) {
          Object.keys(this.dashboards).forEach((item) => {
            if (!this.allDashboards.some((el) => el.dashboardName == item)) {
              const obj = {
                dashboardName: item,
                isVisible: true,
              };
              this.visibilityData.push(obj);
            }
          });
          this.saveVisibility();
        }
        this.allDashboards.sort((a, b) => {
          const indexA = Object.keys(this.dashboards).indexOf(a.dashboardName);
          const indexB = Object.keys(this.dashboards).indexOf(b.dashboardName);
          return indexA - indexB;
        });

        this.visibleDashboards = res.filter((item) => item.isVisible);
        this.visibleDashboards.sort((a, b) => {
          const indexA = Object.keys(this.dashboards).indexOf(a.dashboardName);
          const indexB = Object.keys(this.dashboards).indexOf(b.dashboardName);
          return indexA - indexB;
        });
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  saveVisibility() {
    if (this.visibilityData.length > 0) {
      this.sectionState = SectionStateStatus.Loading;
      this.reportingDashboardService
        .SaveVisibilityForDashboard(this.visibilityData)
        .subscribe({
          next: (res) => {
            this.visibilityData = [];
            this.GetCustomizedDashboardByUserId();
          },
          error: (error) => {
            this.feedBackService?.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
            this.sectionState = SectionStateStatus.Ready;
          },
        });
    }
  }

  changeDashboardVisibility(event, dashboardName) {
    this.visibilityData = [
      ...this.visibilityData.filter((o) => o.dashboardName !== dashboardName),
      {
        ...{
          dashboardName: dashboardName,
          isVisible: event.checked,
        },
      },
    ];
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth > 1800) {
      this.columns = 4;
    } else if (this.screenWidth > 1024 && this.screenWidth <= 1800) {
      this.columns = 3;
    } else if (this.screenWidth > 760 && this.screenWidth <= 1024) {
      this.columns = 2;
    } else if (this.screenWidth <= 760) {
      this.columns = 1;
    }
  }
}
