import { NotificationType } from 'src/@hodhod/common/enum';
import { Model } from 'src/app/reporting/models/model';

export class NotificationItem extends Model {
  public timeout = 5000;
  public get iconSrc(): string {
    switch (this.type) {
      case NotificationType.IncidentReport: {
        return 'assets/img/resources/fire-incident-icon.png';
      }
      case NotificationType.Task: {
        return 'assets/img/resources/task-icon.svg';
      }
      case NotificationType.IncidentReport: {
        return 'assets/img/resources/report-incident-icon.svg';
      }
      case NotificationType.SafetyAnnouncement: {
        return 'assets/img/resources/safety_announce-module-icon.svg';
      }
      default: {
        return 'assets/img/resources/unsafe-condition-icon.svg';
      }
    }
  }

  constructor(
    public id: string = '',
    public title: string = '',
    public body: string = '',
    public type: NotificationType = null,
    public isRead: boolean = false,
    public elementId: string = '',
    public createdAt: string = ''
  ) {
    super();
  }

  toJson() {
    return JSON.stringify(this);
  }
}
