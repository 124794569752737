import { mergeDeep } from '../utils/merge-deep';
import { HodhodConfigName } from './config-name.model';
import { HodhodConfig } from './hodhod-config.interface';
import { ColorSchemeName } from './colorSchemeName';
import { colorVariables } from '../components/config-panel/color-variables';

const companyLogo = JSON.parse(localStorage.getItem('company'))?.companyLogo;

const defaultConfig: HodhodConfig = {
  id: HodhodConfigName.poseidon,
  name: 'Poseidon',
  style: {
    colorScheme: ColorSchemeName.light,
    colors: {
      primary: colorVariables['customTheme'].default
        ? colorVariables['customTheme']
        : colorVariables['blue'],
    },
    borderRadius: {
      value: 0.25,
      unit: 'rem',
    },
    button: {
      borderRadius: undefined,
    },
  },
  direction: 'ltr',
  imgSrc: 'assets/img/layouts/poseidon.png',
  layout: 'horizontal',
  boxed: false,
  sidenav: {
    title: 'HODHOD',
    imageUrl: companyLogo
      ? companyLogo
      : 'assets/img/resources/Sanedlogo.png',
    showCollapsePin: true,
    user: {
      visible: true,
    },
    search: {
      visible: true,
    },
    state: 'expanded',
  },
  toolbar: {
    fixed: true,
    user: {
      visible: true,
    },
  },
  navbar: {
    position: 'in-toolbar',
  },
  footer: {
    visible: true,
    fixed: false,
  },
};

export const configs: HodhodConfig[] = [defaultConfig];
