import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { BaseApi } from '../api/base-api';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  public searchString$ = new BehaviorSubject(null);
  public searchResults$ = new BehaviorSubject([]);
  public phrase: string = '';
  public pageIndex$ = new BehaviorSubject(1);
  public pageSize$ = new BehaviorSubject(Constants.PAGE_SIZE);
  public totalCount$ = new BehaviorSubject(0);
  constructor(private http: HttpClient, private baseApi: BaseApi,
    private loadingService: LoadingService) { }

  onSearch(literalString: string): void {
    this.searchString$.next(literalString);
    this.pageIndex$.next(1);
    this.searchString$.subscribe(res => this.phrase = res);
    this.getSearchResults();
  }

  getSearchResults() {
    let pageIndex: number = 1;
    let pageSize: number = Constants.PAGE_SIZE;
    this.pageIndex$.subscribe(num => pageIndex = (num - 1));
    this.pageSize$.subscribe(num => pageSize = num);
    let params = '';
    if (pageIndex) {
      params += 'offset=' + pageIndex.toString();
    }
    if (pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + pageSize.toString();
    }
    if (this.phrase) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + this.phrase
    }
    this.loadingService.startLoading(true, '');
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_GET_GLOBAL_SEARCH_RESULTS + '?' + params)).subscribe({
      next: (response) => {
        this.searchResults$.next(response?.data);
        this.totalCount$.next(response?.totalCount);
        this.loadingService.stopLoading();
      },
      error: ({error}) => { 
        this.loadingService.stopLoading();
      }
    });
  }

}
