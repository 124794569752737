import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { merge, takeUntil, tap } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import {
  noWhitespaceValidator,
  EmailValidator,
} from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { BulkUserUploadService } from 'src/backend/services/bulk-user-upload.service';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { RoleService } from 'src/backend/services/role.service';
import { UserService } from 'src/backend/services/user.service';
@UntilDestroy()
@Component({
  selector: 'app-bulk-upload-users-list',
  templateUrl: './bulk-upload-users-list.component.html',
  styleUrls: ['./bulk-upload-users-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class BulkUploadUsersListComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('confirmBulkModal') confirmBulkModal!: TemplateRef<any>;
  dataSource = new MatTableDataSource<any>([]);
  confirmtionForm: FormGroup;
  columns: TableColumn<any>[] = [
    {
      label: 'FirstName',
      property: 'firstName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    { label: 'LastName', property: 'lastName', type: 'text', visible: true },
    {
      label: 'PhoneNumber',
      property: 'phoneNumber',
      type: 'text',
      visible: true,
    },
    { label: 'Email', property: 'email', type: 'text', visible: true },
    { label: 'IdNumber', property: 'idNumber', type: 'text', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  searchValue: string = '';
  loadingLabel: string = '';
  searchCtrl = new UntypedFormControl();
  timer = null;
  private translationsList: any = {};
  roles: any[] = [];
  departments: any[] = [];
  reportUsers: any[] = [];
  userId: any;
  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  hasCreateRolesPermission = ApplicationPermission.ROLE_CREATE;
  hasCreateDepartmentsPermission = ApplicationPermission.DEPARTMENTS_CREATE;
  constructor(
    private feedBackService: AsyncFeedbackService,
    private bulkService: BulkUserUploadService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private fb: FormBuilder,
    private roleService: RoleService,
    private departmentService: DepartmentsService,
    private userService: UserService,
    private dialog: MatDialog,
    private baseApi: BaseApi
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.confirmtionForm = this.fb.group({
      firstName: ['', [Validators.required, noWhitespaceValidator]],
      lastName: ['', noWhitespaceValidator],
      phoneNumber: ['', [Validators.required, noWhitespaceValidator]],
      reportTo: [''],
      email: ['', [Validators.required, noWhitespaceValidator, EmailValidator]],
      departments: [[], Validators.required],
      roles: [[], Validators.required],
      idNumber: [''],
    });
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.getRoles();
    this.getCompanyDepartments();
    this.getReportUsers();
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.getData())
      )
      .subscribe();

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: sortDirection,
      sortField,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      searchValue: this.searchValue,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.bulkService.getbulkUsersByCompany(filteredParams).subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource(response?.data);
        this.paginator.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  openConfirmuserModal(row: any): void {
    this.resetForm();
    const data = row;
    this.userId = row.id;
    this.confirmtionForm.get('email')?.setValue(data.email);
    this.confirmtionForm.get('firstName')?.setValue(data.firstName);
    this.confirmtionForm.get('phoneNumber')?.setValue(data.phoneNumber);
    this.confirmtionForm.get('lastName')?.setValue(data.lastName);
    this.confirmtionForm.get('idNumber')?.setValue(data.idNumber);
    this.dialog.open(this.confirmBulkModal, {
      height: 'auto',
      width: '70%',
      disableClose: true,
    });
  }

  saveConfirmedUser(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.Loading;
          const {
            departments,
            roles,
            reportTo,
            phoneNumber,
            lastName,
            firstName,
            email,
            idNumber,
          } = this.confirmtionForm.value;
          const org = this.baseApi.getOrganizationFromStorage();
          let deptId = [];
          const all = departments.find((x) => x == 'ALL');
          if (all == 'ALL') {
            deptId = departments.filter((x) => x != 'ALL');
          } else {
            deptId = departments;
          }
          const data = {
            bulkUserId: this.userId,
            firstName,
            lastName,
            email,
            phoneNumber,
            roleIds: roles,
            userImage: '',
            organizationCode: org?.orgCode,
            departmentIds: deptId,
            reportTo,
            idNumber,
          };
          this.bulkService.registerBulkUser(data).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  resetForm(): void {
    this.confirmtionForm.get('email')?.setValue('');
    this.confirmtionForm.get('departments')?.setValue('');
    this.confirmtionForm.get('roles')?.setValue([]);
    this.confirmtionForm.get('reportTo')?.setValue('');
    this.confirmtionForm.get('phoneNumber')?.setValue('');
    this.confirmtionForm.get('lastName')?.setValue('');
    this.confirmtionForm.get('firstName')?.setValue('');
    this.confirmtionForm.get('idNumber')?.setValue('');
  }

  removeUser(rowId: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.bulkService.deleteBulkUser(rowId).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  getRoles(): void {
    this.roleService.getAllRoles().subscribe({
      next: (response) => {
        this.roles = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getCompanyDepartments(): void {
    this.departmentService.getCompanyDepartments().subscribe({
      next: (response) => {
        this.departments = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getReportUsers(): void {
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.reportUsers = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  selectedvalue(e: any) {
    const deptid = this.confirmtionForm.get('departments').value;
    const types = this.departments.map((x) => x.departmentId);
    const all = deptid.find((x) => x == 'ALL');
    if (all == 'ALL') {
      types.push('ALL');
      this.confirmtionForm.get('departments').setValue(types);
    } else {
      this.confirmtionForm.get('departments').setValue('');
    }
  }

  selectAll(e: any) {
    const deptId = this.confirmtionForm.get('departments').value;
    const index = deptId.indexOf('ALL');
    if (index > -1) {
      deptId.splice(index, 1);
    }
    if (deptId.length == this.departments.length) {
      deptId.push('ALL');
      this.confirmtionForm.get('departments').setValue(deptId);
    } else {
      this.confirmtionForm.get('departments').setValue(deptId);
    }
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.paginator.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
