import { Component, OnInit, ViewChild } from '@angular/core';
import { Constants } from 'src/@hodhod/common/constants';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import {
  CompanyUserStatus,
  SortDirection,
  VerificationValues,
} from 'src/@hodhod/common/enum';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateIdCard } from 'src/app/shared/models/update-id-card/update-id-card.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { RenewIdCardComponent } from '../renew-id-card/renew-id-card.component';
import { GetIdCard } from 'src/backend/models/manage-id-card/manage-id-card.model';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ManageIdCardService } from 'src/backend/services/manage-id-card/manage-id-card.service';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { PermissionService } from 'src/backend/services/permission.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@UntilDestroy()
@Component({
  selector: 'app-update-card',
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class UpdateCardComponent extends BaseComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  searchValue: string = '';
  loadingLabel: string = '';
  timer = null;

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  selection = new SelectionModel<UpdateIdCard>(true, []);
  searchCtrl = new UntypedFormControl();
  dataSource = new MatTableDataSource<GetIdCard>([]);
  public selectedIdCardStatus: string[] = [];
  public selectedUserVerification: CompanyUserStatus = null;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  idCards: GetIdCard[];
  CompanyUserStatus = CompanyUserStatus;
  SharedConstants = SharedConstants;
  VerificationValues = VerificationValues;
  sCreatePermission = ApplicationPermission.SECURITY_MANAGE_LOSTIDS_UPDATE;
  hasViewPermission =
    ApplicationPermission.SECURITY_MANAGE_UPDATEIDCARDS_VIEW_ACCESS;

  hasCreateReNewCard = ApplicationPermission.SECURITY_MANAGE_RENEW_CREATE;
  hasUpdateReNewCard = ApplicationPermission.SECURITY_MANAGE_RENEW_UPDATE;
  private translationsList: any = {};

  // destroy$: any;

  columns: TableColumn<GetIdCard>[] = [
    {
      label: 'IdCardCode',
      property: 'idCardCode',
      type: 'text',
      visible: true,
    },
    {
      label: 'EmployeeCode',
      property: 'code',
      type: 'text',
      visible: true,
    },
    {
      label: 'EmployeeName',
      property: 'name',
      type: 'text',
      visible: true,
    },
    {
      label: 'JobTitle',
      property: 'jobTitle',
      type: 'text',
      visible: true,
    },
    { label: 'Email', property: 'email', type: 'text', visible: false },
    {
      label: 'Department',
      property: 'department',
      type: 'text',
      visible: true,
    },
    {
      label: 'Role',
      property: 'role',
      type: 'text',
      visible: true,
    },
    { label: 'UpdateAt', property: 'updatedOn', type: 'date', visible: true },
    { label: 'Status', property: 'status', type: 'text', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private idCardService: ManageIdCardService,
    private feedBackService: AsyncFeedbackService,
    private permissionService: PermissionService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.getData())
      )
      .subscribe();

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: sortDirection,
      sortField,
      orgStatus: this.selectedIdCardStatus.toString(),
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      searchValue: this.searchValue,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.idCardService.getAllIdCard(filteredParams).subscribe({
      next: (response) => {
        this.idCards = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.paginator.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onSelectIdCardStatus(event: MatSelectChange) {
    this.selectedIdCardStatus = event.value;
    this.getData();
  }

  viewIdCard(id: number): void {
    this.router.navigate([
      '/' +
        SharedConstants.SECURITY +
        '/' +
        SharedConstants.ID_CARDS +
        '/' +
        SharedConstants.UPDATE_CARD +
        '/' +
        SharedConstants.UPDATE_CARD_VIEW +
        '/' +
        id,
    ]);
  }

  onLabelChange(change: MatSelectChange, row: GetIdCard) {
    const index = this.idCards.findIndex((c) => c === row);
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.paginator.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  ReNewIdCard() {
    this.dialog
      .open(RenewIdCardComponent, {
        height: 'auto',
        minWidth: '50%',
        maxWidth: '100%',
      })
      .afterClosed()
      .subscribe((user: GetIdCard) => {
        this.getData();
      });
  }
}
