import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { GetHistoryLog } from 'src/backend/models/history-log/history-log.model';
import { HistoryLogService } from 'src/backend/services/history-log/history-log.service';

@Component({
  selector: 'app-history-log',
  templateUrl: './history-log.component.html',
  styleUrls: ['./history-log.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class HistoryLogComponent
  extends BaseComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() sourceId: number = 0;
  @Input() sourceType: string = '';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<GetHistoryLog>([]);
  loadingLabel: string = '';
  pageSize = Constants.PAGE_SIZE;
  constructor(
    private historyService: HistoryLogService,
    private feedBackService: AsyncFeedbackService
  ) {
    super();
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  columns: TableColumn<GetHistoryLog>[] = [
    { label: 'CreatedBy', property: 'createdBy', type: 'text', visible: true },
    { label: 'CreatedAt', property: 'createdOn', type: 'date', visible: true },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Comments', property: 'comment', type: 'text', visible: true },
  ];
  override ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sourceId']) {
      // this.getData();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  getData() {
    const filteredParams = {
      sourceType: this.sourceType,
      sourceId: this.sourceId,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.historyService.getHistoryLog(filteredParams).subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource(
          response?.data?.map((ele) => {
            return {
              ...ele,
              createdOn: moment(ele.createdOn)
                .local(true)
                .format('MMM DD YYYY hh mm A') as any,
            };
          })
        );
        this.paginator.length = response?.totalCount;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
}
