import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WorkPermitRoutingModule } from './work-permit-routing.module';
import { SharedModule } from '../shared/shared.module';
import { WorkPermitMasterPageComponent } from './pages/work-permit-master-page/work-permit-master-page.component';
import { WorkPermitPageComponent } from './pages/work-permit-page/work-permit-page.component';
import { HazardsPageComponent } from './pages/hazards-page/hazards-page.component';
import { WorkConditionPageComponent } from './pages/work-condition-page/work-condition-page.component';
import { WorkPermitListComponent } from './components/work-permit-list/work-permit-list.component';
import { HazardsComponent } from './components/hazards/hazards.component';
import { WorkConditionsComponent } from './components/work-conditions/work-conditions.component';
import { AddWorkConditionsComponent } from './components/add-work-conditions/add-work-conditions.component';
import { AddHazardsComponent } from './components/add-hazards/add-hazards.component';
import { AddWorkPermitPageComponent } from './pages/add-work-permit-page/add-work-permit-page.component';
import { EditViewWorkPermitPageComponent } from './pages/edit-view-work-permit-page/edit-view-work-permit-page.component';
import { EditWorkPermitComponent } from './components/edit-work-permit/edit-work-permit.component';
import { ViewWorkPermitComponent } from './components/view-work-permit/view-work-permit.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { ActionTrackerModule } from '../action-tracker/action-tracker.module';

@NgModule({
  declarations: [
    WorkPermitMasterPageComponent,
    WorkPermitPageComponent,
    HazardsPageComponent,
    WorkConditionPageComponent,
    WorkPermitListComponent,
    HazardsComponent,
    WorkConditionsComponent,
    AddWorkConditionsComponent,
    AddHazardsComponent,
    AddWorkPermitPageComponent,
    EditViewWorkPermitPageComponent,
    EditWorkPermitComponent,
    ViewWorkPermitComponent
  ],
  imports: [
    CommonModule,
    WorkPermitRoutingModule,
    SharedModule,
    NgxGaugeModule,
    ActionTrackerModule
  ]
})
export class WorkPermitModule { }
