import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { PostRenewIdCard } from 'src/app/security/models/renew-id-card-model';
import { BaseApi } from 'src/backend/api/base-api';


@Injectable({
  providedIn: 'root'
})
export class RenewIdCardService {

  constructor(private http: HttpClient, private baseApi: BaseApi) { }

  postIdCard(data: any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_RENEW_CARD_CREATE), data);
  }
}
