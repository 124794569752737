import { LanguageEnum } from 'src/@hodhod/common/enum';
import { Language } from './language';

export class SharedConstants {
  public static BREADCRUMB = 'breadcrumb';
  public static DEVICE_OS_TYPE_MAC = 'Mac';
  public static INIT_VALUE = 'Init';
  public static NOT_SUPPORTED = 'NOT_SUPPORTED';

  // ERRORS RESPONSE TYPES
  public static NOT_FOUND_ERROR = 404;
  public static SERVER_ERROR = 500;
  public static VALIDATAION_ERROR = 403;
  public static ACCESS_DENIED_ERROR = 403;
  // SUCCESS RESPONSE TYPES
  public static CREATED_SUCCESS = 201;
  public static REQUEST_SUCCESS = 200;

  // STATIC NAVIGATION PATH
  public static PAGE_ERROR = 'error';
  public static PAGE_NOT_FOUND = 'error-404';
  public static ACCESS_DENIED = 'access-denied';

  // STATIC NAVIGATION PATH
  public static ACCOUNT = 'account';
  public static CONFIRM_EMAIL = 'confirm-email';
  public static RESET_PASSWORD = 'reset-password';

  // PUBLIC NAVIGATION PATH
  public static AUTH = 'auth';
  public static SIGN_IN = 'sign-in';
  public static SIGN_OUT = 'sign-out';
  public static FORGOT_PASSWORD = 'forgot-password';

  // PRIVATE Navigation path
  public static DASHBOARD_PATH = 'dashboard';
  public static DASHBOARD_TWO_PATH = 'dashboard-two';
  public static DASHBOARD_DETAILS_PATH = 'details';
  public static ADVANCE_ANALYTICS = 'advance-analytics';
  public static DASHBOARD_TARGET = 'dashboard-target';
  public static ADD_DASHBOARD_TARGET = 'add-dashboard-target';
  public static VIEW_DASHBOARD_TARGET = 'view-dashboard-target';
  public static DEFINE_SAFETY_TRIANGLE = 'define-safety-triangle';
  public static SAFEMAN_HOURS = 'safeman-hours';
  public static SAFETY_KPI_SETUP = 'safety-kpi-setup';
  public static DASHBOARD_FULLSCREEN = 'dashboard-fullscreen';

  public static USER_MANAGEMENT = 'user-management';
  public static USERS = 'users';
  public static VIEW_USER = 'view-user';
  public static VIEW_PROFILE = 'view-profile';
  public static ROLES = 'roles';
  public static VIEW_ROLE = 'view-role';
  public static NEW_ROLE = 'new-role';
  public static SWITCH_ACCOUNT = 'switch-account';
  public static BULK_UPLOAD = 'bulk-upload-users';

  public static ORGANIZATION_SETUP = 'organization-setup';
  public static ORGANIZATIONS = 'organizations';
  public static ORGANIZATION_VIEW = 'organization-view';
  public static ORGANIZATION_DETAIL = 'organization-detail';
  public static ORGANIZATION_CREATE = 'organization-create';
  public static ORGANIZATION_UPDATE = 'organization-update';
  public static TRUSTED_SOURCES = 'trusted-sources';
  public static SECURITY_FEATURES = 'security-features';
  public static COMPANY_SETUP = 'company-setup';
  public static COMPANIES = 'companies';
  public static CATEGORIES = 'categories';
  public static INSPECT_EQUIPMENT = 'inspect-equipment';
  public static REVIEW_INSPECTIONS = 'review-inspections';
  public static COMPANY_VIEW = 'company-view';
  public static COMPANY_CREATE = 'company-create';
  public static COMPANY_UPDATE = 'company-update';
  public static MACHINES = 'machine-equipment';
  public static HISTORY_LOGS = 'history-logs';
  public static DEPARTMENT = 'departments';
  public static DEPARTMENT_VIEW = 'department-view';
  public static ADD_NEW_MACHINE = 'add-machine-parameters';
  public static EDIT_VIEW_MACHINE = 'edit-view-machine-parameters';
  public static VALUE_TYPES = 'value-types';
  public static UNIT_OF_MEASUREMENTS = 'unit-of-measures';
  public static PPE_SETTING = 'ppe-setting';
  public static AREAS_SETTING = 'areas-setting';
  public static TEAMS_SETTING = 'teams-setting';
  public static SHIFTS_SETTING = 'shifts-setting';
  public static CHATBOT_UPLOAD_DOCUMENTS = 'chatbot-upload-documents';

  public static SECURITY = 'security';
  public static ID_CARDS = 'id-cards';
  public static ADD_ID_CARD = 'add-id-card';
  public static EMERGENCY_CONTACTS = 'emergency-contacts';
  public static NEW_ID_CARD = 'new-id-card';
  public static UPDATE_CARD = 'update-card';
  public static UPDATE_CARD_VIEW = 'update-card-view';
  public static LOST_ID_CARD = 'lost-id-card';

  public static SAFETY = 'safety';
  public static MANAGE_CHECKLIST = 'manage-checklist';
  public static CHECKLIST_SECTION = 'checklist-section';
  public static ADD_CHECKLIST_SECTION = 'add-checklist-section';
  public static VIEW_CHECKLIST_SECTION = 'view-checklist-section';

  public static REPORTING_MANAGEMENT = 'reporting-management';
  public static REPORTING = 'reporting';
  public static CONTENT_REPORTING = 'content-reporting';
  public static REPORT_HAZARD = 'report-hazard';
  public static REPORT_HAZARD_VIEW = 'report-hazard-view';
  public static REPORT_HAZARD_EDIT = 'report-hazard-edit';
  public static REPORT_INCIDENT = 'report-incident';
  public static REPORT_INCIDENT_VIEW = 'report-incident-view';
  public static REPORT_INCIDENT_EDIT = 'report-incident-edit';
  public static REPORT_NEAR_MISS = 'report-nearMiss';
  public static REPORT_NEAR_MISS_VIEW = 'report-nearMiss-view';
  public static REPORT_NEAR_MISS_EDIT = 'report-nearMiss-edit';
  public static NATURE_INJURY = 'nature_injury';
  public static INCIDENT_CATEGORY = 'incident-category';
  public static REASONS = 'reasons';
  public static ROOT_CAUSE = 'root-cause';
  public static INCIDENT_CLASSIFICATION = 'incident-classification';
  public static LOSS_MODES = 'loss-modes';
  public static ADD_REPORT_HAZARD = 'add-report-hazard';
  public static ADD_REPORT_NEAR_MISS = 'add-report-near-miss';
  public static ADD_REPORT_INCIDENT = 'add-report-incident';

  public static WORK_PERMIT = 'work-permit';
  public static WORK_PERMIT_LIST = 'work-permit-list';
  public static WORK_PERMIT_MASTER = 'work-permit-master';
  public static WORK_PERMIT_HAZARDS = 'work-permit-hazards';
  public static WORK_PERMIT_WORK_CONDITIONS = 'work-permit-work-conditions';
  public static ADD_WORK_PERMIT = 'add-work-permit';
  public static VIEW_EDIT_WORK_PERMIT = 'view-edit-work-permit';

  public static MANAGE_ID_CARD = 'manage-id-card';

  public static TASK_TRACKER = 'task-tracker';

  public static TASK_TRACKER_List = 'task-tracker-list';
  public static View_TASK_TRACKER = 'view-task-tracker';
  public static EXECUTE_TASK_TRACKER = 'execute-checkList';
  public static INSPECTION_PLAN = 'inspection-plan';

  public static ACTION_TRACKER = 'action-tracker';
  public static ACTION_TRACKER_LIST = 'action-tracker-list';
  public static SMART_MONITORING = 'smart-monitoring';
  public static VIDEO_ANALYTICS = 'video-analytics';
  public static VIDEO_ANALYTICS_VIEW = 'video-analytics-view';
  public static VOILATIONS = 'violations';
  public static VOILATIONS_VIEW = 'violations-view';
  public static VIDEO_ANALYTICS_DASHBOARD = 'video-analytics-dashboard';

  // safety announcements
  public static SAFETY_ANNOUNCEMENTS = 'safety-announcements';
  public static SAFETY_ANNOUNCEMENTS_LIST = 'safety-announcements-list';

  // msds routing
  public static MSDS = 'msds';
  public static MSDS_LIST = 'msds-list';

  // penalty management routing
  public static PENALTY_MANAGEMENT = 'penalty-management';
  public static PENALTY_MANAGEMENT_LIST = 'penalty-management-list';
  public static CREATE_PENALTY_MANAGEMENT = 'create-penalty-management';
  public static PENALTY_MANAGEMENT_VIEW = 'penalty-management-view';

  // inspection management routing
  public static INSPECTION_MANAGEMENT = 'inspection-management';
  public static INSPECTION_MANAGEMENT_LIST_INSPECTORS =
    'inspection-management-list-inspectors';
  public static INSPECTION_MANAGEMENT_LIST_REVIEWERS =
    'inspection-management-list-reviewers';
  public static INSPECTION_MANAGEMENT_EXECUTE = 'inspection-management-execute';
  public static INSPECTION_MANAGEMENT_REVIEW = 'inspection-management-review';

  // Manage access control setup routing
  public static MANAGE_ACCESS_CONTROL = 'manage-access-control';
  public static MANAGE_USERS_ACCESS_CONTROL = 'manage-users-access-control';
  public static MANAGE_USERS_ACCESS_CONTROL_USER_DETAILS =
    'manage-users-access-control-user-details';

  // Validations

  public static PHOTOGRAPHY_TYPE_ICON_MAX_HEIGHT = 200;
  public static PHOTOGRAPHY_TYPE_ICON_MAX_WIDTH = 200;

  public static IMAGE_PREVIEW_SIZE_WIDTH = 1280;
  public static IMAGE_PREVIEW_SIZE_HEIGHT = 720;
  public static COMPANY_TITLE_MAX_LENGTH = 100;
  public static COMPANY_DESCRIPTION_MAX_LENGTH = 450;

  public static UPLOAD_IMAGE_MAX_SIZE_HINT = '5MB';
  public static UPLOAD_IMAGE_MAX_SIZE_KB = 5242880;
  public static UPLOAD_IMAGE_FILE_TYPE = [
    'image/png',
    'image/jpeg',
    'image/jpg',
  ];
  public static UPLOAD_ANY_FILE_TYPE = ['all-files'];
  public static UPLOAD_BULK_USERS_FILE_TYPE = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.csv',
    'text/csv',
  ];

  public static LANGUAGES_NAMES: Language[] = [
    new Language('', 'العربية', LanguageEnum.Arabic),
    new Language('', 'English', LanguageEnum.English),
  ];

  public static SHIFTS: any = {
    SHIFT_A: 'Shift A (12 AM to 8 AM)',
    SHIFT_B: 'Shift B (8 AM to 4 PM)',
    SHIFT_C: 'Shift C (4 PM to 12 PM)',
    SHIFT_D: 'Shift D',
    SHIFT_E: 'Shift E',
  };
  public static SHIFTS_REVERSE: any = {
    'Shift A (12 AM to 8 AM)': 'SHIFT_A',
    'Shift B (8 AM to 4 PM)': 'SHIFT_B',
    'Shift C (4 PM to 12 PM)': 'SHIFT_C',
    'Shift D': 'SHIFT_D',
    'Shift E': 'SHIFT_E',
  };

  public static REPORT_LOGO_URL =
    'https://hodhodblobstorev1.blob.core.windows.net/dev/Media/3bd9bf4f-8e17-4cd0-b66c-a20cef615c87_20221116T100019068.png';
  public static REPORT_DOC_IMAGE =
    'https://hodhodblobstorev1.blob.core.windows.net/dev/Media/45bdf1fa-b438-4991-b45c-6bd021bddb1e_20230102T071712552.png';
  public static WORK_PERMIT_FILE_SIZE = 5.0;
  public static EQUIPMENT_CATEGORY_FILE_SIZE = 10.0;

  public static ADMIN_ROLES: string[] = ['Organization Admin', 'Company Admin'];
}
