import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CarouselConfig } from 'ng-carousel-cdk';
import { map, Observable } from 'rxjs';
import { ConfigService } from 'src/@hodhod/config/config.service';
import { HodhodConfig } from 'src/@hodhod/config/hodhod-config.interface';

interface CarouselItem {
  index: number;
}

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent implements OnInit {
  config: CarouselConfig<CarouselItem> = {
    items: [{ index: 1 }, { index: 2 }, { index: 3 }],
    autoplayEnabled: true,
    autoplayDelay: 3000,
    dragEnabled: false,
    shouldLoop: true,
    shouldRecalculateOnResize: true,
    allowKeyboardNavigation: true,
  };
  itemIndex = 1;

  config$: Observable<HodhodConfig> = this.configService.config$;

  isRTL$: Observable<boolean> = this.config$.pipe(
    map((config) => config.direction === 'rtl')
  );
  constructor(
    private configService: ConfigService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.translateService.get(['Login']).subscribe((translates) => {});
  }

  ngOnInit(): void {}

  setItemIndex(newIndex: number): void {
    this.itemIndex = newIndex;
    const carouselStyle = document.querySelector('ul');
    let isRTL = false;
    this.isRTL$.subscribe((res) => {
      isRTL = res;
    });
    if (carouselStyle && isRTL) {
      setTimeout(() => {
        carouselStyle.style.transform = 'none';
      }, 200);
    }
  }
}
