import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';
import { SortDirection } from 'src/@hodhod/common/enum';

@Injectable({
  providedIn: 'root',
})
export class SafetyKpisService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}
  getSefetyTriangleById(id) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_SAFETY_TRIANGLE) + '/' + id
    );
  }
  getLTIR(data) {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_LTIR),
      data
    );
  }
  getTRIR(data) {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_TRIR),
      data
    );
  }
  getTRIFR(data) {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_TRIFR),
      data
    );
  }
  getSefetyKpis(data) {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      data?.status.forEach((statusItem, index) => {
        if (params.length > 0) {
          params += '&';
        }
        params += `status${statusItem}=` + statusItem;
      });
    }
    if (data?.priority) {
      data?.priority.forEach((priorityItem, index) => {
        if (params.length > 0) {
          params += '&';
        }
        params += `priority${priorityItem}=` + priorityItem;
      });
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.sourceType) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sourceType=' + data?.sourceType;
    }

    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_SAFETY_KPIS_BY_COMPANY + '?' + params
      )
    );
  }
  updateSafetyTriabgle(data) {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_SAFETY_TRIANGLE),
      data
    );
  }
  deActivateSafemanHours(id) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_DEACTIVATE_SAFEMAN_HOURS) + '/' + id
    );
  }
  activateSafemanHours(id) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ACTIVATE_SAFEMAN_HOURS) + '/' + id
    );
  }
  deActivateSafetyTriangle(id) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_DEACTIVATE_SAFETY_TRIANGLE) +
        '/' +
        id
    );
  }
  activateSafetyTriangle(id) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ACTIVATE_SAFETY_TRIANGLE) + '/' + id
    );
  }
  LTIIncident(id): Observable<any> {
    return this.http.get(
      this.baseApi.encodeURL(Constants.API_LTI_INCIDENT) + '/' + id
    );
  }
  RecordableIncident(id): Observable<any> {
    return this.http.get(
      this.baseApi.encodeURL(Constants.API_RECORDABLE_INCIDENT) + '/' + id
    );
  }
  getPaginatedKpis(data, filteration): Observable<any> {
    let params = '';
    if (data?.offset) {
      params += 'offset=' + data?.offset.toString();
    }
    if (data?.dashboardType) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'dashboardType=' + data?.dashboardType;
    }
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_PAGINATED_KPIS + '?' + params),
      filteration
    );
  }

  updateSafetyKpiSetup(data) {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_SAFETY_KPI_SETUP),
      data
    );
  }
  getSafetyKpiSetupById(data) {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_SAFETY_KPI_SETUP_BY_ID),
      {
        params: data,
      }
    );
  }
  activateSafetyKpiSetup(data) {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_ACTIVATE_SAFETY_KPI_SETUP),
      data
    );
  }
  deactivateSafetyKpiSetup(data) {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_DEACTIVATE_SAFETY_KPI_SETUP),
      data
    );
  }
}
