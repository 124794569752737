import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';
import {
  PostReason,
  GetAllReasons,
  UpdateReason,
} from 'src/backend/models/reasons/reasons.model';

@Injectable({
  providedIn: 'root',
})
export class ReasonsService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  postReason(data: PostReason): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_REASON_CREATE),
      data
    );
  }

  getAllReason(data: any): Observable<DataMapper<GetAllReasons>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<GetAllReasons>>(
      this.baseApi.encodeURL(Constants.API_REASON_GET_ALL + '?' + params)
    );
  }

  updateReason(data: UpdateReason): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_REASON_UPDATE),
      data
    );
  }

  deActivate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_REASON_DEACTIVATE + '/' + id)
    );
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_REASON_ACTIVATE + '/' + id)
    );
  }

  getReasonsByCompany(reportType: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_REASON_BY_COMPANY + '/' + reportType)
    );
  }
}
