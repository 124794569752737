import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { Shift } from 'src/app/company-setup/models/shift';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class ShiftsService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getAllShifts(data: any): Observable<DataMapper<Shift>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<Shift>>(
      this.baseApi.encodeURL(
        Constants.API_ALL_SHIFT_LIST_BY_COMPANY + '?' + params
      )
    );
  }

  getActiveShifts(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ACTIVE_SHIFT_LIST_BY_COMPANY)
    );
  }

  createShift(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_SHIFT_CREATE),
      data
    );
  }

  updateShift(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_SHIFT_UPDATE),
      data
    );
  }

  activateShift(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_SHIFT_ACTIVATE + '/' + id)
    );
  }

  deActivateShift(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_SHIFT_DEACTIVATE + '/' + id)
    );
  }
  
}
