import { Component, OnInit } from '@angular/core';
import { AppNavigationService } from 'src/app/shared/helpers/app-navigation.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { AuthService } from 'src/backend/services/auth.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  public SharedConstants = SharedConstants;
  constructor(private authService: AuthService) { }

  ngOnInit() {
  }

  goBack(): void {
    this.authService.navigateToInitialMenu();
  }

}
