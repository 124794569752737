import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { AddChecklistSectionPageComponent } from './pages/add-checklist-section-page/add-checklist-section-page.component';
import { ChecklistSectionPageComponent } from './pages/checklist-section-page/checklist-section-page.component';
import { ManageChecklistPageComponent } from './pages/manage-checklist-page/manage-checklist-page.component';
import { ViewChecklistSectionPageComponent } from './pages/view-checklist-section-page/view-checklist-section-page.component';
import { ChecklistSectionComponent } from './components/checklist-section/checklist-section.component';
import { MultiPermissionGuard } from '../shared/guards/MultiPermission.guard';

const routes: Routes = [
  {
    path: SharedConstants.MANAGE_CHECKLIST,
    component: ChecklistSectionPageComponent,
    canActivate: [MultiPermissionGuard],
    data: {
      permissions: [
        ApplicationPermission.WORK_CONDITIONS_ACCESS,
        ApplicationPermission.AUDIT_CHECKLIST_READ,
        ApplicationPermission.INSPECTION_MANAGEMENT,
      ],
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'ManageChecklist',
      },
    },
  },
  // {
  //   path: SharedConstants.CHECKLIST_SECTION,
  //   component: ChecklistSectionPageComponent,
  //   canActivate: [PermissionGuard],
  //   data: {
  //     permissions: ApplicationPermission.SAFETY_CHECKLIST_SECTION_ACCESS,
  //     breadcrumb: {
  //       isFirstLevel: false,
  //       labelKey: 'ChecklistSection',
  //       isActive: true,
  //     },
  //   },
  // },
  // {
  //   path:
  //     SharedConstants.CHECKLIST_SECTION +
  //     '/' +
  //     SharedConstants.ADD_CHECKLIST_SECTION +
  //     '/:mode' +
  //     '/:id',
  //   component: AddChecklistSectionPageComponent,
  //   canActivate: [PermissionGuard],
  //   data: {
  //     permissions: ApplicationPermission.SAFETY_CHECKLIST_SECTION_UPDATE,
  //     breadcrumb: {
  //       isFirstLevel: false,
  //       labelKey: 'AddChecklistSection',
  //       isActive: true,
  //     },
  //   },
  // },
  {
    path:
      SharedConstants.CHECKLIST_SECTION +
      '/' +
      SharedConstants.ADD_CHECKLIST_SECTION +
      '/:mode',
    component: AddChecklistSectionPageComponent,
    canActivate: [MultiPermissionGuard],
    data: {
      permissions: [
        ApplicationPermission.WORK_CONDITIONS_CREATE,
        ApplicationPermission.AUDIT_CHECKLIST_WRITE,
        ApplicationPermission.INSPECTION_MANAGEMENT,
      ],
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'AddChecklist',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.CHECKLIST_SECTION +
      '/' +
      SharedConstants.VIEW_CHECKLIST_SECTION +
      '/:id',
    component: ViewChecklistSectionPageComponent,
    canActivate: [MultiPermissionGuard],
    data: {
      permissions: [
        ApplicationPermission.WORK_CONDITIONS_ACCESS,
        ApplicationPermission.AUDIT_CHECKLIST_READ,
        ApplicationPermission.INSPECTION_MANAGEMENT,
      ],
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SafetyRoutingModule {}
