<div class="w-full flex justify-end">
  <button mat-icon-button class="close-btn" (click)="closeFullScreen()">
    <mat-icon [svgIcon]="'mat:close'"></mat-icon>
  </button>
</div>

<div class="container">
  <button class="mx-1 print-btn" (click)="takePrint()">
    <img src="assets/img/print_icon.png" alt="print" />
  </button>
  <div
    class="flex items-center filter-date"
    app-click-stop-propagation
    (click)="select.open()"
  >
    <span>Date: </span>
    <span class="text-primary">
      {{ "Enum.DashboardFilters." + filterName | translate }}
    </span>
    <mat-icon
      style="width: 20px; height: 20px; cursor: pointer"
      svgIcon="mat:keyboard_arrow_down"
      color="primary"
    ></mat-icon>
  </div>
  <mat-select
    #select
    class="select-options"
    [value]="filterName"
    (selectionChange)="setFilterForDashboard($event.value)"
  >
    <mat-option
    *ngFor="
    let item of (dashboard?.dashboardName === 'ReportedPenalty'
      ? penaltyFilterDashboards
      : filterDashboards
    ) | enumToArray
  "      [value]="item.value"
      (click)="item.key === 'CustomRange' ? onSelectCustomRange() : ''"
    >
      {{ "Enum.DashboardFilters." + item.value | translate }}
    </mat-option>
  </mat-select>
</div>
<div class="container w-full">
  <div id="charts" class="flex column justify-center w-full">
    <!-- level one -->
    <mat-card class="w-full" id="level-one">
      <mat-card-header class="flex column">
        <!-- first row -->
        <div class="flex justify-space-between">
          <!-- card title -->
          <div class="header-text">
            <mat-card-title>
              {{ "Dashboards." + dashboard.dashboardName | translate }}
            </mat-card-title>
          </div>
        </div>

        <!-- second row -->
        <div class="flex justify-space-between align-center mb-1">
          <!-- filter label -->
          <mat-card-subtitle class="subtitle">
            {{
              filterName === "customRange"
                ? "From " +
                  formatCustomDate(dashboardSetting.fromDate) +
                  " To " +
                  formatCustomDate(dashboardSetting.toDate)
                : ("Enum.DashboardFilters." + filterName | translate)
            }}
          </mat-card-subtitle>

          <div class="flex">
            <!-- target -->
            <div
              class="flex center"
              *ngIf="dashboard?.targetStatus === 'ACTIVE'"
            >
              <div class="max">
                <span class="title">
                  {{
                    dashboard?.targetType == "Upto"
                      ? ("Dashboards.MaxTarget" | translate)
                      : ("Dashboards.MinTarget" | translate)
                  }}
                </span>
                <p>
                  {{ dashboard?.target }}
                  <span *ngIf="dashboard?.totalType == 'Percentage'">%</span>
                </p>
              </div>
            </div>
            <!-- total -->
            <div class="class flex center" *ngIf="dashboard?.total">
              <div class="total">
                <span class="title">{{
                  dashboard?.dashboardName == "AuditScore"
                    ? ("Dashboards.TotalTypes.Pass" | translate)
                    : ("Dashboards.TotalTypes." + dashboard?.totalLabel
                      | translate)
                }}</span>
                <p>
                  {{
                    dashboard?.dashboardName == "AuditScore"
                      ? auditPassValue
                      : dashboard?.total
                  }}
                  <span *ngIf="dashboard?.totalType == 'Percentage'">%</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="w-full">
        <div
          class="flex-auto flex flex-col items-center justify-center"
          *ngIf="!(dashboard?.drilldownChartLevels.level1.length > 0)"
        >
          <img
            style="width: 240px"
            src="assets/img/empty-dashboard.png"
            alt="not found"
          />
          <h6
            class="mt-2 text-center"
            style="
              color: #2b2c30;
              padding-top: 20px;
              opacity: 0.7;
              font-size: 0.9rem;
            "
          >
            <br />
            {{
              "Dashboard.EmptyDashboards." + dashboard?.dashboardName
                | translate
            }}
          </h6>
        </div>
        <app-dashboard-chart-two
          *ngIf="dashboard?.drilldownChartLevels.level1.length > 0"
          [dashboard]="dashboard"
          [chartOptimized]="chartDataLevel1"
          [chartLevel]="chartLevel"
          [fullScreenOpen]="true"
          (onChartClicked)="changeChartLevel($event)"
        ></app-dashboard-chart-two>
      </mat-card-content>
    </mat-card>

    <!-- level two -->
    <mat-card
      id="level-two"
      [ngClass]="
        showLevelTwo && chartDataLevel2.length > 0
          ? 'w-full m-0 mt-2'
          : 'hide-card'
      "
    >
      <mat-card-header class="flex column">
        <!-- first row -->
        <div class="flex justify-space-between">
          <!-- card title -->
          <div class="header-text">
            <mat-card-title>
              {{ "Dashboards." + dashboard.dashboardName | translate }}
              {{
                dashboard.dashboardName == "AuditAdherence"
                  ? "Per Checklist"
                  :dashboard.dashboardName == "ReportedPenalty"?'Per Area': "Per Department"
              }}
            </mat-card-title>
          </div>
        </div>

        <!-- second row -->
        <div class="flex justify-space-between align-center mb-1">
          <!-- filter label -->
          <mat-card-subtitle class="subtitle">
            {{
              filterName === "customRange"
                ? "From " +
                  formatCustomDate(dashboardSetting.fromDate) +
                  " To " +
                  formatCustomDate(dashboardSetting.toDate)
                : ("Enum.DashboardFilters." + filterName | translate)
            }}
          </mat-card-subtitle>

          <!-- total -->
          <div
            class="class flex center"
            *ngIf="
              dashboard?.total || dashboard?.dashboardName === 'IncidentLoss'
            "
          >
            <div class="total">
              <span class="title">
                {{
                  dashboard.dashboardName == "AuditAdherence" ||
                  dashboard.dashboardName == "AuditScore"
                    ? titleLevelTwo
                    : dashboard.dashboardName === "IncidentLoss"
                    ? ("Dashboards.Total" | translate)
                    : ("Dashboards.TotalTypes." + dashboard?.totalLabel
                      | translate)
                }}
              </span>
              <p>
                {{
                  dashboard.dashboardName == "AuditAdherence" ||
                  dashboard.dashboardName == "AuditScore"
                    ? totalLevelTwo.toFixed(2)
                    : totalLevelTwo
                }}
                <span *ngIf="dashboard?.totalType == 'Percentage'">%</span>
              </p>
            </div>
          </div>
        </div>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="w-full">
        <app-dashboard-chart-two
          [dashboard]="dashboard"
          [chartOptimized]="chartDataLevel2"
          [chartLevel]="'two'"
          [chartTitle]="titleLevelTwo"
          [fullScreenOpen]="true"
          [color]="selectedColor"
          (onChartClicked)="changeChartLevel($event)"
        ></app-dashboard-chart-two>
      </mat-card-content>
    </mat-card>

    <!-- level three -->
    <mat-card
      id="level-three"
      [ngClass]="
        showLevelThree && chartDataLevel3.length > 0
          ? 'w-full mt-2'
          : 'hide-card'
      "
    >
      <mat-card-header class="flex column">
        <!-- first row -->
        <div class="flex justify-space-between">
          <!-- card title -->
          <div class="header-text">
            <mat-card-title>
              {{ "Dashboards." + dashboard.dashboardName | translate }}
              {{ getLevelThreeTitle() }}
            </mat-card-title>
          </div>
        </div>

        <!-- second row -->
        <div class="flex justify-space-between align-center mb-1">
          <!-- filter label -->
          <mat-card-subtitle class="subtitle">
            {{
              filterName === "customRange"
                ? "From " +
                  formatCustomDate(dashboardSetting.fromDate) +
                  " To " +
                  formatCustomDate(dashboardSetting.toDate)
                : ("Enum.DashboardFilters." + filterName | translate)
            }}
          </mat-card-subtitle>

          <!-- target -->
          <div
            class="class flex center"
            *ngIf="
              dashboard?.total || dashboard.dashboardName === 'IncidentLoss'
            "
          >
            <div class="total">
              <span class="title">
                {{
                  dashboard.dashboardName == "AuditAdherence"
                    ? titleLevelTwo
                    : dashboard.dashboardName === "IncidentLoss"
                    ? ("Dashboards.Total" | translate)
                    : ("Dashboards.TotalTypes." + dashboard?.totalLabel
                      | translate)
                }}
              </span>
              <p>
                {{
                  dashboard.dashboardName == "AuditAdherence" ||
                  dashboard.dashboardName == "AuditScore"
                    ? totalLevelThree.toFixed(2)
                    : totalLevelThree
                }}
                <span *ngIf="dashboard?.totalType == 'Percentage'">%</span>
              </p>
            </div>
          </div>
        </div>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="w-full">
        <app-dashboard-chart-two
          [dashboard]="dashboard"
          [chartOptimized]="chartDataLevel3"
          [chartLevel]="'three'"
          [fullScreenOpen]="true"
          [chartTitle]="titleLevelThree"
          [color]="selectedColor"
          (onChartClicked)="changeChartLevel($event)"
        ></app-dashboard-chart-two>
      </mat-card-content>
    </mat-card>

    <!-- level four -->
    <mat-card 
      id="level-four"
      [ngClass]="
        showLevelFour && chartDataLevel4.length > 0
          ? 'w-full mt-2'
          : 'hide-card'
      "
    >
      <mat-card-header class="flex column">
        <!-- first row -->
        <div class="flex justify-space-between">
          <!-- card title -->
          <div class="header-text">
            <mat-card-title>
              {{ "Dashboards." + dashboard.dashboardName | translate }}
              {{ getLevelFourTitle() }}
            </mat-card-title>
          </div>
        </div>

        <!-- second row -->
        <div class="flex justify-space-between align-center mb-1">
          <!-- filter label -->
          <mat-card-subtitle class="subtitle">
            {{
              filterName === "customRange"
                ? "From " +
                  formatCustomDate(dashboardSetting.fromDate) +
                  " To " +
                  formatCustomDate(dashboardSetting.toDate)
                : ("Enum.DashboardFilters." + filterName | translate)
            }}
          </mat-card-subtitle>

          <!-- tot -->
          <div class="class flex center" *ngIf="dashboard?.total">
            <div class="total">
              <span class="title">
                {{
                  dashboard.dashboardName == "AuditAdherence"
                    ? titleLevelTwo
                    : dashboard.dashboardName === "IncidentLoss"
                    ? ("Dashboards.Total" | translate)
                    : ("Dashboards.TotalTypes." + dashboard?.totalLabel
                      | translate)
                }}
              </span>
              <p>
                {{
                  dashboard.dashboardName == "AuditAdherence" ||
                  dashboard.dashboardName == "AuditScore"
                    ? totalLevelFour.toFixed(2)
                    : totalLevelFour
                }}
                <span *ngIf="dashboard?.totalType == 'Percentage'">%</span>
              </p>
            </div>
          </div>
        </div>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content class="w-full">
        <app-dashboard-chart-two
          [dashboard]="dashboard"
          [chartOptimized]="chartDataLevel4"
          [chartLevel]="'four'"
          [fullScreenOpen]="true"
          [chartTitle]="titleLevelFour"
          [color]="selectedColor"
          (onChartClicked)="changeChartLevel($event)"
        ></app-dashboard-chart-two>
      </mat-card-content>
    </mat-card>
  </div>
</div>
