import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { PenaltyManagementComponent } from './pages/penalty-management/penalty-management.component';
import { NewPenaltyMangmentComponent } from './components/new-penalty-mangment/new-penalty-mangment.component';
import { PenaltyManagementViewComponent } from './pages/penalty-management-view/penalty-management-view.component';

const routes: Routes = [
  {
    path: SharedConstants.PENALTY_MANAGEMENT_LIST,
    component: PenaltyManagementComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.PENALTY_MANAGEMENT_VIEW,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'PenaltyManagement',
      },
    },
  },
  {
    path: SharedConstants.CREATE_PENALTY_MANAGEMENT,
    component: NewPenaltyMangmentComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.PENALTY_MANAGEMENT_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'CreateNew',
      },
    },
  },
  {
    path: SharedConstants.CREATE_PENALTY_MANAGEMENT + '/:id',
    component: NewPenaltyMangmentComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.PENALTY_MANAGEMENT_UPDATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Edit',
      },
    },
  },
  {
    path: SharedConstants.PENALTY_MANAGEMENT_VIEW + '/:id',
    component: PenaltyManagementViewComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.PENALTY_MANAGEMENT_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PenaltyManagementRoutingModule {}
