import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { log } from 'console';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { generateUserCode } from 'src/@hodhod/common/custom_methods';
import {
  EmailValidator,
  noWhitespaceValidator,
} from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { GetContactsList } from 'src/backend/models/emergency-contacts/emergency-contacts.model';
import { EmergencyContactsService } from 'src/backend/services/emergency-contacts/emergency-contacts.service';
import { PermissionService } from 'src/backend/services/permission.service';
import { UserService } from 'src/backend/services/user.service';

@Component({
  selector: 'app-add-new-contact',
  templateUrl: './add-new-contact.component.html',
  styleUrls: ['./add-new-contact.component.scss'],
})
export class AddNewContactComponent implements OnInit {
  @ViewChild(MatSelect) matSelect: MatSelect;
  @ViewChild('customOption') customOption: MatOption;
  contactForm: FormGroup;
  formMode: 'create' | 'update' = 'create';
  contactNum: any;

  private destroy$ = new Subject();
  public translationsList: any = {};
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  hasEditPermissions = ApplicationPermission.USER_UPDATE;
  isUserHasPermission: boolean = false;
  companyUsers: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AddNewContactComponent>,
    private fb: FormBuilder,
    private asyncFeedbackService: AsyncFeedbackService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private contactService: EmergencyContactsService,
    private userService: UserService,
    private permissionService: PermissionService,
    private router: Router
  ) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  foundUser: any;
  ngOnInit() {
    if (this.defaults) {
      this.formMode = 'update';
    } else {
      this.defaults = {} as GetContactsList;
    }
    this.contactForm = this.fb.group({
      UserId: [
        this.defaults.name || '',
        [Validators.required, noWhitespaceValidator],
      ],
      ContactName: '',
    });
    this.getCompanyUsers();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createContacts();
          }
        });
    }
  }
  getCompanyUsers() {
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.companyUsers = response;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  createContacts() {
    const { ContactName, UserId } = this.contactForm.value;

    const data = {
      ContactName,
      UserId,
    };
    this.sectionState = SectionStateStatus.Loading;

    this.contactService.postEmergecnyContact(data).subscribe({
      next: (res) => {
        this.sectionState = SectionStateStatus.Ready;
        this.dialogRef.close({ event: 'created' });
        if (
          res.message ===
          'This contact has already been added as emergency contact'
        ) {
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Warning, res.message)
          );
        } else {
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(
              FeedbackType.Success,
              this.translationsList['Success']['SuccessMessage']
            )
          );
        }
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Failure,
            this.translationsList['Errors']['ErrorHappened']
          )
        );
      },
    });
  }
  onSelectionChange(event: any) {
    const userID = event.value;
    this.userService.getUserDetails(userID).subscribe((res) => {
      this.contactNum = res.phoneNumber;
      this.foundUser = res;
      this.contactForm.get('ContactName').setValue(res.fullName);
    });
    this.isUserHasPermission = this.permissionService.isUserHasPermission(
      this.hasEditPermissions
    );
  }
  editUserPhone() {
    this.router.navigate(
      [SharedConstants.USER_MANAGEMENT + '/' + SharedConstants.USERS],
      { queryParams: { user: this.foundUser?.email } }
    );
    this.closeDialog();
  }

  isCreateMode() {
    return this.formMode === 'create';
  }

  isUpdateMode() {
    return this.formMode === 'update';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
