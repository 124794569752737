import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ActionTrackerModule } from "../action-tracker/action-tracker.module";
import { SharedModule } from "../shared/shared.module";
import { SafetyAnnouncementComponent } from './pages/safety-announcement/safety-announcement.component';
import { SafetyAnnouncementRoutingModule } from "./safety-announcement-routing";
import { SafetyAnnouncementListComponent } from './components/safety-announcement-list/safety-announcement-list.component';
import { AddUpdateSafetyAnnouncementComponent } from './components/add-update-safety-announcement/add-update-safety-announcement.component';
import { ViewSafetyAnnouncementComponent } from './components/view-safety-announcement/view-safety-announcement.component';

@NgModule({
    declarations: [
    SafetyAnnouncementComponent,
    SafetyAnnouncementListComponent,
    AddUpdateSafetyAnnouncementComponent,
    ViewSafetyAnnouncementComponent
  ],
    imports: [
        CommonModule,
        SafetyAnnouncementRoutingModule,
        SharedModule,
        ActionTrackerModule,
      ]
})

export class SafetyAnnouncementModule { }