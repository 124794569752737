import { Injectable } from '@angular/core';
import { Constants } from 'src/@hodhod/common/constants';
import pkg from '../../../package.json';
import * as uuid from 'uuid';
import { BaseApi } from '../api/base-api';
import { UserDevice } from '../models/user/user-device';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserDeviceService {
  constructor(private baseApi: BaseApi, private http: HttpClient) {}

  saveUserDevice(userDevice: UserDevice) {
    if (userDevice) {
      localStorage.setItem(Constants.USER_DEVICE_KEY, JSON.stringify(userDevice));
    }
  }

  getCurrentUserDevice(): UserDevice {
    const json = localStorage.getItem(Constants.USER_DEVICE_KEY);
    return (json) ? JSON.parse(json) :  null;
  }

  generateNewDevice(): UserDevice {
    const userDevice: UserDevice = {
      deviceId: uuid.v4(),
      appVersion: pkg.version,
      osVersion: navigator.platform,
      deviceModel: navigator.userAgent,
      notificationToken: '',
      osType: 'WEB',
      signalRKey: ''
    }   
    return userDevice;
  }
  

  registerUserDevice(data: any): Observable<UserDevice> {
    return this.http.post<UserDevice>(this.baseApi.encodeURL(Constants.API_USER_DEVICE_CREATE), data)
  }
  editUserDevice(data: any): Observable<UserDevice> {
    return this.http.put<UserDevice>(this.baseApi.encodeURL(Constants.API_USER_DEVICE_EDIT), data)
  }
}
