import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from 'src/@hodhod/common/constants';
import { UserPermission } from 'src/backend/models/session-user/user-permission';
import { Permission } from '../models/permission';

@Pipe({
  name: 'permissionChecker'
})
export class PermissionCheckerPipe implements PipeTransform {
  transform(checkingPermission: Permission): any {
    const permissions = localStorage.getItem(Constants.USER_PERMISSIONS);
    let userPermissions = [];
    if (permissions) {
      userPermissions =  JSON.parse(permissions);
    }
    const key = checkingPermission.PermissionKey;
    const type = checkingPermission.PermissionType;
    let foundPermission = userPermissions.find(x => x.label === key);
    
    if (foundPermission) {
      if (Object.getOwnPropertyNames(foundPermission).find(x => x === type)) {
        return foundPermission[type] ? true : false;
      }
      return false;
    }    
    return false;
  }
}
