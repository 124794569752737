<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.Safety' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div class="card p-7 rounded-xl">
      <h2 class="title">{{'CardTypes.CardsTitle' | translate}}</h2>
      <div class="cardSection pb-5">
        <app-card-type [title]="'General.Buttons.Checklist'|translate" [imgSrc]="'assets/img/resources/section-creation.svg'"
          (onClick)="onChecklistSectionsClick($event)">
        </app-card-type>
        <!-- <app-card-type [title]="'Checklists'" [imgSrc]="'assets/img/resources/checklist-forms.svg'"
          (onClick)="onChecklistsClick($event)">
        </app-card-type>
        <app-card-type [title]="'
        Machine Checklists'" [imgSrc]="'assets/img/resources/checklist-machine-form.svg'"
          (onClick)="onMachineChecklistsClick($event)">
        </app-card-type> -->
      </div>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>