import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { setRowSelection } from 'src/@hodhod/common/custom_methods';
import { MenuProps } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { Menu } from 'src/app/shared/models/permissions/menu';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ParentUser } from '../../models/parent-user';
import { UserPermission } from '../../models/user-permission';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-user-permission',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.scss'],
  animations: [fadeInUp400ms],
})
export class UserPermissionComponent implements OnInit, OnChanges {
  @Input() userPermissions: any[] = [];
  @Output() savePermissionsEvent: EventEmitter<any> = new EventEmitter();
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public menuPermissions: any[];
  selectedPermissions: any[] = [];
  tableColumns: TableColumn<Menu>[] = [
    {
      label: '',
      property: 'rowSelected',
      type: 'checkbox',
      cssClasses: ['w-6'],
    },
    {
      label: 'TypeName',
      property: 'label',
      type: 'text',
      cssClasses: ['font-medium'],
    },
    {
      label: 'Permissions',
      property: 'permissions',
      type: 'text',
      cssClasses: ['font-medium'],
    },
  ];
  hasUpdateUserPermission = ApplicationPermission.USER_UPDATE;
  constructor() {}

  ngOnInit() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userPermissions'].currentValue) {
      this.getPermissions();
    }
  }

  getPermissions(): void {
    this.menuPermissions = [...this.userPermissions];
  }

  savePermissions(): void {
    this.savePermissionsEvent.emit(this.selectedPermissions);
  }
  getPermissionsList(event) {
    const index = this.selectedPermissions.findIndex(
      (obj) => obj.name === event.name
    );
    if (index !== -1) {
      this.selectedPermissions[index] = event;
    } else {
      this.selectedPermissions.push(event);
    }
  }
}
