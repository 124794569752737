import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import {
  NotificationType,
  ReportingType,
  ReportingTypes,
} from 'src/@hodhod/common/enum';
import { ViewSafetyAnnouncementComponent } from 'src/app/safety-announcement/components/view-safety-announcement/view-safety-announcement.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { BaseApi } from '../api/base-api';
import { NotificationItem } from '../models/notifications/notification-item';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public notification$ = new Subject<NotificationItem>();
  public markNotification$ = new Subject<NotificationItem>();
  public translationsList = {};
  public notifications = new Subject<Array<any>>();
  public notificationCount$ = new Subject<number>();
  public eof$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private translate: TranslateService,
    private baseApi: BaseApi,
    private http: HttpClient,
    private dialog: MatDialog,
    private loadingService: LoadingService,
    private route: ActivatedRoute
  ) {
    this.translate.get(['Success']).subscribe((translations: any) => {
      this.translationsList = translations;
    });
  }

  showNotification(notification: NotificationItem) {
    this.notification$.next(notification);
  }

  navigateToNotificationTarget(notification: NotificationItem) {
    const splitType: string[] = notification.type.split('-');
    switch (splitType[0]) {
      case NotificationType.ExecutedTask: {
        this.markNotification$.next(notification);
        this.router.navigate([
          '/' +
            SharedConstants.TASK_TRACKER +
            '/' +
            SharedConstants.TASK_TRACKER_List +
            '/' +
            SharedConstants.View_TASK_TRACKER +
            '/' +
            notification.elementId,
        ]);
        break;
      }
      case NotificationType.IncidentReport: {
        this.markNotification$.next(notification);
        this.router.navigate(
          [
            SharedConstants.REPORTING_MANAGEMENT +
              '/' +
              SharedConstants.REPORT_INCIDENT +
              '/' +
              SharedConstants.REPORT_INCIDENT_VIEW +
              '/' +
              notification.elementId,
          ],
          {
            queryParams: { editMode: false },
          }
        );
        break;
      }
      case NotificationType.WorkPermit: {
        this.markNotification$.next(notification);
        this.router.navigate(
          [
            SharedConstants.WORK_PERMIT +
              '/' +
              SharedConstants.WORK_PERMIT_LIST +
              '/' +
              SharedConstants.VIEW_EDIT_WORK_PERMIT +
              '/' +
              notification?.elementId,
          ],
          {
            queryParams: { editMode: false },
          }
        );
        break;
      }
      case NotificationType.HazardReport: {
        this.markNotification$.next(notification);
        this.router.navigate(
          [
            SharedConstants.REPORTING_MANAGEMENT +
              '/' +
              SharedConstants.REPORT_HAZARD +
              '/' +
              SharedConstants.REPORT_HAZARD_VIEW +
              '/' +
              notification.elementId,
          ],
          {
            queryParams: { editMode: false },
          }
        );
        break;
      }
      case NotificationType.NearMiss: {
        this.markNotification$.next(notification);
        this.router.navigate(
          [
            SharedConstants.REPORTING_MANAGEMENT +
              '/' +
              SharedConstants.REPORT_NEAR_MISS +
              '/' +
              SharedConstants.REPORT_NEAR_MISS_VIEW +
              '/' +
              notification.elementId,
          ],
          {
            queryParams: { editMode: false },
          }
        );
        break;
      }
      case NotificationType.Task: {
        this.markNotification$.next(notification);
        this.router.navigate([
          '/' +
            SharedConstants.TASK_TRACKER +
            '/' +
            SharedConstants.TASK_TRACKER_List +
            '/' +
            SharedConstants.EXECUTE_TASK_TRACKER +
            '/' +
            notification.elementId,
        ]);
        break;
      }
      case NotificationType.InspectionReminder: {
        this.markNotification$.next(notification);
        if (this.router.url.includes('inspect-equipment')) {
          window.location.replace(
            '/' +
              SharedConstants.INSPECTION_MANAGEMENT +
              '/' +
              SharedConstants.INSPECT_EQUIPMENT +
              '/' +
              notification.elementId
          );
        } else {
          this.router.navigate([
            '/' +
              SharedConstants.INSPECTION_MANAGEMENT +
              '/' +
              SharedConstants.INSPECT_EQUIPMENT +
              '/' +
              notification.elementId,
          ]);
        }
        break;
      }
      case NotificationType.InspectionReview: {
        this.markNotification$.next(notification);
        if (this.router.url.includes('review-inspections')) {
          window.location.replace(
            '/' +
              SharedConstants.INSPECTION_MANAGEMENT +
              '/' +
              SharedConstants.REVIEW_INSPECTIONS +
              '/' +
              notification.elementId
          );
        } else {
          this.router.navigate([
            '/' +
              SharedConstants.INSPECTION_MANAGEMENT +
              '/' +
              SharedConstants.REVIEW_INSPECTIONS +
              '/' +
              notification.elementId,
          ]);
        }

        break;
      }
      case NotificationType.Action: {
        if (splitType[1] === ReportingType.Incident) {
          this.router.navigate(
            [
              SharedConstants.REPORTING_MANAGEMENT +
                '/' +
                SharedConstants.REPORT_INCIDENT +
                '/' +
                SharedConstants.REPORT_INCIDENT_VIEW +
                '/' +
                notification.elementId,
            ],
            {
              queryParams: { editMode: false },
            }
          );
        }
        if (splitType[1] === ReportingType.NearMiss) {
          this.router.navigate(
            [
              SharedConstants.REPORTING_MANAGEMENT +
                '/' +
                SharedConstants.REPORT_NEAR_MISS +
                '/' +
                SharedConstants.REPORT_NEAR_MISS_VIEW +
                '/' +
                notification.elementId,
            ],
            {
              queryParams: { editMode: false },
            }
          );
        }
        if (splitType[1] === ReportingType.Hazard) {
          this.router.navigate(
            [
              SharedConstants.REPORTING_MANAGEMENT +
                '/' +
                SharedConstants.REPORT_HAZARD +
                '/' +
                SharedConstants.REPORT_HAZARD_VIEW +
                '/' +
                notification.elementId,
            ],
            {
              queryParams: { editMode: false },
            }
          );
        }
        if (splitType[1] === ReportingType.Task) {
          this.router.navigate([
            '/' +
              SharedConstants.TASK_TRACKER +
              '/' +
              SharedConstants.TASK_TRACKER_List +
              '/' +
              SharedConstants.View_TASK_TRACKER +
              '/' +
              notification.elementId,
          ]);
        }
        if (splitType[1] === ReportingType.Voilation) {
          this.router.navigate([
            '/' +
              SharedConstants.SMART_MONITORING +
              '/' +
              SharedConstants.VOILATIONS +
              '/' +
              SharedConstants.VOILATIONS_VIEW +
              '/' +
              notification.elementId,
          ]);
        }
        if (splitType[1] === ReportingType.PenaltyManagement) {
          this.markNotification$.next(notification);
          if (this.router.url.includes('penalty-management-view')) {
            window.location.replace(
              '/' +
                SharedConstants.PENALTY_MANAGEMENT +
                '/' +
                SharedConstants.PENALTY_MANAGEMENT_VIEW +
                '/' +
                notification.elementId
            );
          } else {
            this.router.navigate(
              [
                '/' +
                  SharedConstants.PENALTY_MANAGEMENT +
                  '/' +
                  SharedConstants.PENALTY_MANAGEMENT_VIEW +
                  '/' +
                  notification.elementId,
              ],
              {
                queryParams: { editMode: false },
              }
            );
          }
          break;
        }
        if (splitType.length === 1) {
          this.router.navigate([
            '/' +
              SharedConstants.ACTION_TRACKER +
              '/' +
              SharedConstants.ACTION_TRACKER_LIST,
          ]);
        }
        break;
      }
      case NotificationType.SafetyAnnouncement: {
        this.dialog.open(ViewSafetyAnnouncementComponent, {
          height: 'auto',
          width: '90%',
          data: {
            formMode: 'view',
            announcementId: notification.elementId,
          },
        });
        break;
      }
      case NotificationType.SafetyAnnouncementEdit: {
        this.dialog.open(ViewSafetyAnnouncementComponent, {
          height: 'auto',
          width: '90%',
          data: {
            formMode: 'view',
            announcementId: notification.elementId,
          },
        });
        break;
      }
      case NotificationType.PenaltyReport: {
        this.markNotification$.next(notification);
        if (this.router.url.includes('penalty-management-view')) {
          window.location.replace(
            '/' +
              SharedConstants.PENALTY_MANAGEMENT +
              '/' +
              SharedConstants.PENALTY_MANAGEMENT_VIEW +
              '/' +
              notification.elementId
          );
        } else {
          this.router.navigate(
            [
              '/' +
                SharedConstants.PENALTY_MANAGEMENT +
                '/' +
                SharedConstants.PENALTY_MANAGEMENT_VIEW +
                '/' +
                notification.elementId,
            ],
            {
              queryParams: { editMode: false },
            }
          );
        }
        break;
      }
      case NotificationType.MSDS: {
        this.markNotification$.next(notification);
        this.router.navigate([
          '/' + SharedConstants.MSDS + '/' + SharedConstants.MSDS_LIST,
        ]);
        break;
      }
    }
  }

  getUserNotifications(data: any) {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.notificationType) {
      if (params.length > 0) {
        params += '&';
      }
      params += `notificationType=` + data?.notificationType;
    }
    this.loadingService.startLoading(true, '');
    return this.http
      .get<any>(
        this.baseApi.encodeURL(Constants.API_NOTIFICATIONS + '?' + params)
      )
      .subscribe({
        next: (response) => {
          this.notifications.next(response?.data);
          this.notificationCount$.next(response?.totalCount);
          this.eof$.next(response?.data?.length < 10 ? true : false);
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.loadingService.stopLoading();
        },
      });
  }
  markAsReadNotification(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_NOTIFICATION_MARK_AS_READ),
      data
    );
  }
  markAllAsReadNotification(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_NOTIFICATIONS_MARK_ALL_AS_READ),
      data
    );
  }

  deleteAllAsReadNotification(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_DELETE_ALL_NOTIFICATIONS),
      data
    );
  }
}
