<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.dashboardTarget' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div>
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="
          (viewMode === true
            ? 'AddDashboardTarget.View.Title'
            : 'AddDashboardTarget.Edit.Title'
          ) | translate
        "
      >
        <div body class="flex column">
          <app-state-section
            [flexibleHeight]="true"
            [state]="sectionState"
            [loadingLabel]="'' | translate"
            [errorLabel]="'Errors.ErrorHappened' | translate"
          >
            <form [formGroup]="targetForm">
              <div class="flex flex-row flex-wrap justify-between">
                <div class="field-medium flex column">
                  <mat-label class="field-label"
                    >{{ "AddDashboardTarget.Type" | translate }}
                    <span
                      [ngClass]="
                        targetForm.invalid &&
                        targetForm.get('type').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <mat-select
                      [placeholder]="'Placeholder.Select' | translate"
                      formControlName="type"
                      [disabled]="true"
                    >
                      <mat-option
                        *ngFor="let type of TargetDashboardType | enumToArray"
                        [value]="type.key"
                      >
                        {{ type.value }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        targetForm.invalid &&
                        targetForm.get('type').hasError('required')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="field-medium flex ml-2 column">
                  <mat-label class="field-label"
                    >{{ "DashboardTarget.Table.UptoAtLeast" | translate }}
                    <span
                      [ngClass]="
                        targetForm.invalid &&
                        targetForm.get('uptoAtleast').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <mat-select
                      [disabled]="
                        viewMode ||
                        !(
                          hasUpdateDashboardTargetPermission | permissionChecker
                        )
                      "
                      [placeholder]="'Placeholder.Select' | translate"
                      formControlName="uptoAtleast"
                    >
                      <mat-option
                        *ngFor="let type of uptoAtLeast | enumToArray"
                        [value]="type.value"
                      >
                        {{ type.value }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        targetForm.invalid &&
                        targetForm.get('uptoAtleast').hasError('required')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="field-medium flex ml-2 column">
                  <mat-label class="field-label"
                    >{{ "DashboardTarget.YearlyTarget" | translate }}
                    <span
                      [ngClass]="
                        targetForm.invalid &&
                        targetForm.get('target').hasError('required')
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      >*</span
                    >
                  </mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <input
                      [readonly]="
                        viewMode ||
                        !(
                          hasUpdateDashboardTargetPermission | permissionChecker
                        )
                      "
                      type="number"
                      min="0"
                      oninput="validity.valid||(value='');"
                      [placeholder]="'Placeholder.Insert' | translate"
                      formControlName="target"
                      matInput
                    />
                    <mat-error
                      *ngIf="
                        targetForm.invalid &&
                        targetForm.get('target').hasError('required')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex items-center justify-end my-3 w-full">
                <app-basic-button
                  *ngIf="
                    (hasUpdateDashboardTargetPermission | permissionChecker) &&
                    !viewMode
                  "
                  [btnType]="'Cancel'"
                  [btnText]="'Cancel'"
                  (onClick)="cancelForm()"
                >
                </app-basic-button>
                <app-basic-button
                  *ngIf="
                    (hasUpdateDashboardTargetPermission | permissionChecker) &&
                    !viewMode
                  "
                  [btnType]="'Add'"
                  [btnText]="'Save'"
                  [isDisabled]="!targetForm.valid"
                  (onClick)="updateDashboardTarget()"
                ></app-basic-button>
              </div>
            </form>
          </app-state-section>
        </div>
      </app-expandable-panel>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
