import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { SkeletonParts } from 'src/@hodhod/common/enum';

@Component({
  selector: 'app-body-skeleton',
  templateUrl: './body-skeleton.component.html',
  styleUrls: ['./body-skeleton.component.scss']
})
export class BodySkeletonComponent implements OnInit {
  @Input() viewMode = false;
  @Input() frontBodySelected: SkeletonParts[] = [];
  @Input() backBodySelected: SkeletonParts[] = [];
  public frontBodySkeleton = [];
  public backBodySkeleton = [];
  public bodyFrontExpression = {};
  public bodyBackExpression = {};

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.frontBodySkeleton = this.frontBodySelected;
    this.backBodySkeleton = this.backBodySelected;
    this.frontBodySelected?.forEach(part => {
      this.highlightSelectedFrontParts(part);
    });

    this.backBodySelected?.forEach(part => {
      this.highlightSelectedBackParts(part);
    });
  }
  ngAfterViewInit() {
    this.elementRef.nativeElement.querySelector('.front').addEventListener('click', this.onFrontClick.bind(this));
    this.elementRef.nativeElement.querySelector('.back').addEventListener('click', this.onBackClick.bind(this));
  }

  onFrontClick(event) {
    if (!this.viewMode) {
      if (!event.target.id.includes('Fill-1')) {
        event.target.classList.toggle('highlighted');
        const index = this.frontBodySkeleton.indexOf(event.target.attributes[0].nodeValue);
        if (index !== -1) {
          this.frontBodySkeleton.splice(index, 1);
        } else {
          this.frontBodySkeleton.push(event.target.attributes[0].nodeValue);
        }
      }
    }
  }

  onBackClick(event) {
    if (!this.viewMode) {
      if (!event.target.id.includes('Fill-1') && !event.target.id.includes('Fill-81')) {
        event.target.classList.toggle('highlighted');
        const index = this.backBodySkeleton.indexOf(event.target.attributes[0].nodeValue);
        if (index !== -1) {
          this.backBodySkeleton.splice(index, 1);
        } else {
          this.backBodySkeleton.push(event.target.attributes[0].nodeValue);
        }
      }
    }
  }

  // private methods
  private highlightSelectedFrontParts(part) {
    if (part === SkeletonParts.HeadFront.toUpperCase()) { this.bodyFrontExpression['HeadFront'] = true; }
    if (part === SkeletonParts.Eyes.toUpperCase()) { this.bodyFrontExpression['Eyes'] = true; }
    if (part === SkeletonParts.Neck.toUpperCase()) { this.bodyFrontExpression['Neck'] = true; }
    if (part === SkeletonParts.Back.toUpperCase()) { this.bodyFrontExpression['Back'] = true; }
    if (part === SkeletonParts.LowerBack.toUpperCase()) { this.bodyFrontExpression['LowerBack'] = true; }
    if (part === SkeletonParts.UpperRightArm.toUpperCase()) { this.bodyFrontExpression['UpperRightArm'] = true; }
    if (part === SkeletonParts.LowerRightArm.toUpperCase()) { this.bodyFrontExpression['LowerRightArm'] = true; }
    if (part === SkeletonParts.RightHand.toUpperCase()) { this.bodyFrontExpression['RightHand'] = true; }
    if (part === SkeletonParts.UpperLeftArm.toUpperCase()) { this.bodyFrontExpression['UpperLeftArm'] = true; }
    if (part === SkeletonParts.LowerLeftArm.toUpperCase()) { this.bodyFrontExpression['LowerLeftArm'] = true; }
    if (part === SkeletonParts.LeftHand.toUpperCase()) { this.bodyFrontExpression['LeftHand'] = true; }
    if (part === SkeletonParts.Thorax.toUpperCase()) { this.bodyFrontExpression['Thorax'] = true; }
    if (part === SkeletonParts.UpperRightLeg.toUpperCase()) { this.bodyFrontExpression['UpperRightLeg'] = true; }
    if (part === SkeletonParts.LowerRightLeg.toUpperCase()) { this.bodyFrontExpression['LowerRightLeg'] = true; }
    if (part === SkeletonParts.UpperLeftLeg.toUpperCase()) { this.bodyFrontExpression['UpperLeftLeg'] = true; }
    if (part === SkeletonParts.LowerLeftLeg.toUpperCase()) { this.bodyFrontExpression['LowerLeftLeg'] = true; }
    if (part === SkeletonParts.LeftFootFront.toUpperCase()) { this.bodyFrontExpression['LeftFootFront'] = true; }
    if (part === SkeletonParts.RightFootFront.toUpperCase()) { this.bodyFrontExpression['RightFootFront'] = true; }
  }

  private highlightSelectedBackParts(part) {
    if (part === SkeletonParts.HeadBack.toUpperCase()) { this.bodyBackExpression['HeadBack'] = true; }
    if (part === SkeletonParts.Eyes.toUpperCase()) { this.bodyBackExpression['Eyes'] = true; }
    if (part === SkeletonParts.Neck.toUpperCase()) { this.bodyBackExpression['Neck'] = true; }
    if (part === SkeletonParts.Back.toUpperCase()) { this.bodyBackExpression['Back'] = true; }
    if (part === SkeletonParts.LowerBack.toUpperCase()) { this.bodyBackExpression['LowerBack'] = true; }
    if (part === SkeletonParts.UpperRightArm.toUpperCase()) { this.bodyBackExpression['UpperRightArm'] = true; }
    if (part === SkeletonParts.LowerRightArm.toUpperCase()) { this.bodyBackExpression['LowerRightArm'] = true; }
    if (part === SkeletonParts.RightHand.toUpperCase()) { this.bodyBackExpression['RightHand'] = true; }
    if (part === SkeletonParts.UpperLeftArm.toUpperCase()) { this.bodyBackExpression['UpperLeftArm'] = true; }
    if (part === SkeletonParts.LowerLeftArm.toUpperCase()) { this.bodyBackExpression['LowerLeftArm'] = true; }
    if (part === SkeletonParts.LeftHand.toUpperCase()) { this.bodyBackExpression['LeftHand'] = true; }
    if (part === SkeletonParts.Thorax.toUpperCase()) { this.bodyBackExpression['Thorax'] = true; }
    if (part === SkeletonParts.UpperRightLeg.toUpperCase()) { this.bodyBackExpression['UpperRightLeg'] = true; }
    if (part === SkeletonParts.LowerRightLeg.toUpperCase()) { this.bodyBackExpression['LowerRightLeg'] = true; }
    if (part === SkeletonParts.UpperLeftLeg.toUpperCase()) { this.bodyBackExpression['UpperLeftLeg'] = true; }
    if (part === SkeletonParts.LowerLeftLeg.toUpperCase()) { this.bodyBackExpression['LowerLeftLeg'] = true; }
    if (part === SkeletonParts.LeftFootBack.toUpperCase()) { this.bodyBackExpression['LeftFootBack'] = true; }
    if (part === SkeletonParts.RightFootBack.toUpperCase()) { this.bodyBackExpression['RightFootBack'] = true; }
  }

}
