import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { SafetyAnnouncementComponent } from './pages/safety-announcement/safety-announcement.component';

const routes: Routes = [
  {
    path: SharedConstants.SAFETY_ANNOUNCEMENTS_LIST,
    component: SafetyAnnouncementComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.SAFETY_ANNOUNCEMENT_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'SafetyAnnouncement',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SafetyAnnouncementRoutingModule {}
