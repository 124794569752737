import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-machine-equipment-page',
  templateUrl: './machine-equipment-page.component.html',
  styleUrls: ['./machine-equipment-page.component.scss'],
})
export class MachineEquipmentPageComponent implements OnInit {
  public loadEquipments: boolean = false;
  public loadMachines: boolean = false;
  constructor(private router: Router, private route: ActivatedRoute) {}
  hasReadEquipmentPermission = ApplicationPermission.EQUIPMENT_ACCESS;

  hasReadMachinePermission = ApplicationPermission.MACHINE_ACCESS;
  ngOnInit() {
    let { currentPageIndex } = this.route.snapshot.params;
    if (currentPageIndex) {
      this.loadEquipments = true;
    }
  }

  tabChanged(event) {
    const selectedTabIndex = event.index;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { currentPageIndex: selectedTabIndex },
    });

    if (selectedTabIndex == 0) {
      this.loadMachines = !this.loadEquipments;
    } else if (selectedTabIndex == 1) {
      this.loadEquipments = true;
    }
  }
}
