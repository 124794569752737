export enum LoadingStatus {
    Hidden = 'Hidden',
    Visible = 'Visible',
    VisibleTransparent = 'VisibleTransparent',
  }
  
export enum SectionStateStatus {
    Loading = 'Loading',
    LoadingTransparent = 'LoadingTransparent',
    Ready = 'Ready',
    Disconnected = 'Disconnected',
    Error = 'Error',
    Empty = 'Empty',
    EmptyFilterResult = 'EmptyFilterResult'
}