import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivateLayoutComponent } from './private-layout/private-layout.component';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { SharedConstants } from './shared/models/shared-constants';
import { AccessDeniedComponent } from './static/access-denied/access-denied.component';
import { ConfirmEmailComponent } from './static/confirmEmail/confirmEmail.component';
import { NotFoundPageComponent } from './static/not-found-page/not-found-page.component';
import { ResetPasswordComponent } from './static/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: SharedConstants.AUTH,
        pathMatch: 'full',
      },
      {
        path: SharedConstants.AUTH,
        loadChildren: () =>
          import('./public-layout/public-layout.module').then(
            (p) => p.PublicLayoutModule
          ),
      },
    ],
  },
  {
    path: '',
    component: PrivateLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: SharedConstants.DASHBOARD_PATH,
        pathMatch: 'full',
      },
      // switch until finish iot dashboards
      {
        path: SharedConstants.DASHBOARD_TWO_PATH,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((d) => d.DashboardModule),
      },
      {
        path: SharedConstants.DASHBOARD_TWO_PATH,
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((d) => d.DashboardModule),
      },
      {
        path: SharedConstants.DASHBOARD_PATH,
        loadChildren: () =>
          import('./dashboard-two/dashboard-routing.module').then(
            (d) => d.DashboardTwoRoutingModule
          ),
      },
      {
        path: SharedConstants.USER_MANAGEMENT,
        loadChildren: () =>
          import('./user-management/user-management.module').then(
            (d) => d.UserManagementModule
          ),
      },
      {
        path: SharedConstants.ORGANIZATION_SETUP,
        loadChildren: () =>
          import('./organization-setup/organization-setup.module').then(
            (d) => d.OrganizationSetupModule
          ),
      },
      {
        path: SharedConstants.COMPANY_SETUP,
        loadChildren: () =>
          import('./company-setup/company-setup.module').then(
            (d) => d.CompanySetupModule
          ),
      },
      {
        path: SharedConstants.SECURITY,
        loadChildren: () =>
          import('./security/security.module').then((d) => d.SecurityModule),
      },
      {
        path: SharedConstants.SAFETY,
        loadChildren: () =>
          import('./safety/safety.module').then((d) => d.SafetyModule),
      },
      {
        path: SharedConstants.WORK_PERMIT,
        loadChildren: () =>
          import('./work-permit/work-permit.module').then(
            (d) => d.WorkPermitModule
          ),
      },
      {
        path: SharedConstants.REPORTING_MANAGEMENT,
        loadChildren: () =>
          import('./reporting/reporting.module').then((d) => d.ReportingModule),
      },
      {
        path: SharedConstants.TASK_TRACKER,
        loadChildren: () =>
          import('./task-tracker/task-tracker.module').then(
            (d) => d.TaskTrackerModule
          ),
      },
      {
        path: SharedConstants.ACTION_TRACKER,
        loadChildren: () =>
          import('./action-tracker/action-tracker.module').then(
            (d) => d.ActionTrackerModule
          ),
      },
      {
        path: SharedConstants.DASHBOARD_PATH,
        loadChildren: () =>
          import('./smart-monitoring/smart-monitoring.module').then(
            (d) => d.SmartMonitoringModule
          ),
      },
      {
        path: SharedConstants.SMART_MONITORING,
        loadChildren: () =>
          import('./smart-monitoring/smart-monitoring.module').then(
            (d) => d.SmartMonitoringModule
          ),
      },

      {
        path: SharedConstants.SAFETY_ANNOUNCEMENTS,
        loadChildren: () =>
          import('./safety-announcement/safety-announcement.module').then(
            (d) => d.SafetyAnnouncementModule
          ),
      },
      {
        path: SharedConstants.MSDS,
        loadChildren: () =>
          import('./msds/msds.module').then((d) => d.MSDSModule),
      },
      {
        path: SharedConstants.PENALTY_MANAGEMENT,
        loadChildren: () =>
          import('./penalty-management/penalty-management.module').then(
            (d) => d.PenaltyManagementModule
          ),
      },
      {
        path: SharedConstants.INSPECTION_MANAGEMENT,
        loadChildren: () =>
          import('./inspection-management/inspection-management.module').then(
            (d) => d.InspectionManagementModule
          ),
      },
      {
        path: SharedConstants.MANAGE_ACCESS_CONTROL,
        loadChildren: () =>
          import('./access-control-setup/access-control-setup.module').then(
            (d) => d.AccessControlSetupModule
          ),
      },
    ],
  },
  {
    path: SharedConstants.PAGE_ERROR + '/' + SharedConstants.PAGE_NOT_FOUND,
    component: NotFoundPageComponent,
  },
  {
    path: SharedConstants.PAGE_ERROR + '/' + SharedConstants.ACCESS_DENIED,
    component: AccessDeniedComponent,
  },
  {
    path: SharedConstants.ACCOUNT + '/' + SharedConstants.CONFIRM_EMAIL,
    component: ConfirmEmailComponent,
  },
  {
    path: SharedConstants.ACCOUNT + '/' + SharedConstants.RESET_PASSWORD,
    component: ResetPasswordComponent,
  },
  {
    path: '**',
    redirectTo:
      SharedConstants.PAGE_ERROR + '/' + SharedConstants.PAGE_NOT_FOUND,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'corrected',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
