<div class="flex column items-center justify-center">
  <img src="assets/img/welcome.png" alt="welcome" />
  <div class="slide flex column center">
    <h1 dir="auto" class="description text-base text-s">
      {{ "Login.Title" | translate }} , <span class="font-bold">{{ data?.userName }}</span>!<br>
      {{ "Login.WelcomeAnnouncement" | translate }}
    </h1>
  </div>
</div>
<div class="flex items-center justify-center my-3">
  <app-basic-button dir="auto"
    [btnType]="'Add'"
    [btnText]="'letsStart'"
    (onClick)="close()"
  ></app-basic-button>
</div>
