<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'BreadCrumb.View' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div>
      <app-expandable-panel [expanded]="true" #expandablePanel [title]="'BulkUpload.Title' | translate">
        <div body class="flex column">
          <app-bulk-upload-users (onInsertBulkUser)="passDataToComponent($event)"></app-bulk-upload-users>
        </div>
      </app-expandable-panel>
    </div>
    <div class="mt-5">
      <app-expandable-panel [expanded]="true" #expandablePanel [title]="'BulkUpload.Table.Title' | translate">
        <div body class="flex column">
          <app-bulk-upload-users-list></app-bulk-upload-users-list>
        </div>
      </app-expandable-panel>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>