import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  Input,
  Output,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import {
  ActionPriority,
  ActionStatus,
  SortDirection,
} from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { ActionTrackerService } from 'src/backend/services/action-tracker/action-tracker.service';
import { MediaService } from 'src/backend/services/media.service';
import { PermissionService } from 'src/backend/services/permission.service';
import { UserService } from 'src/backend/services/user.service';
import { Action } from '../../models/action';
import * as moment from 'moment';
import { ViewActionComponent } from '../view-action/view-action.component';

@UntilDestroy()
@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class ActionComponent
  extends BaseComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() sourceId: number = 0;
  @Input() sourceType: string = '';
  @Input() isCreate: boolean = true;
  @Output() onCreateAction: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('createActionModal') createActionModal!: TemplateRef<any>;
  @ViewChild('reassignActionModal') reassignActionModal!: TemplateRef<any>;
  @ViewChild('closeModal') closeModal!: TemplateRef<any>;
  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  minDate: Date;
  actions: Action[];
  columns: TableColumn<Action>[] = [
    { label: 'Image', property: 'closedMedia', type: 'image', visible: false },
    {
      label: 'Description',
      property: 'title',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Responsibility',
      property: 'assignToName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-small'],
    },
    {
      label: 'Priority',
      property: 'priority',
      type: 'boolean',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'TargetDate',
      property: 'targetDate',
      type: 'date',
      visible: true,
    },
    {
      label: 'CompleteDate',
      property: 'closedDate',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Status',
      property: 'actionStatus',
      type: 'boolean',
      visible: true,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<Action>([]);
  selection = new SelectionModel<Action>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';

  actionForm: FormGroup;
  reassignForm: FormGroup;
  formMode: 'create' | 'view' | 'edit' = 'create';
  actionId: number = 0;

  responsibles: any[] = [];

  allowCreate: boolean = true;

  closeForm: FormGroup;
  singleAction: Action;

  selectedImg: any = null;

  public sectionState: SectionStateStatus = SectionStateStatus.Loading;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;
  public selectedStatus: ActionStatus[] = [];
  public selectedPriority: ActionPriority[] = [];

  SharedConstants = SharedConstants;
  ActionStatus = ActionStatus;
  ActionPriority = ActionPriority;
  private translationsList: any = {};
  public loggedUser: LoggedUser;
  hasApprovePermissions = ApplicationPermission.ACTION_TRACKER_APPROVE;
  hasReadPermissions = ApplicationPermission.ACTION_TRACKER_VIEW;

  hasCreateUsersPermissions = ApplicationPermission.USER_CREATE;
  hasCreateActionPermission = ApplicationPermission.ACTION_TRACKER_CREATE;
  hasAccessActionPermission = ApplicationPermission.ACTION_TRACKER_VIEW;
  hasUpdateActionPermission = ApplicationPermission.ACTION_TRACKER_UPDATE;
  hasApproveActionPermission = ApplicationPermission.ACTION_TRACKER_APPROVE;
  hasDeleteActionPermission = ApplicationPermission.ACTION_TRACKER_DELETE;

  hasPermission = false;
  constructor(
    private translate: TranslateService,
    private trackerService: ActionTrackerService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder,
    private baseApi: BaseApi,
    private dialog: MatDialog,
    private userService: UserService,
    private loadingService: LoadingService,
    private mediaService: MediaService,
    private permissionService: PermissionService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
    this.minDate = new Date();
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  override ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    const approvePermission = this.permissionService.isUserHasPermission(
      this.hasApprovePermissions
    );
    const rejectPermission = this.permissionService.isUserHasPermission(
      this.hasApprovePermissions
    );
    if (approvePermission && rejectPermission) {
      this.hasPermission = true;
    }
    this.actionForm = this.fb.group({
      action: new FormControl('', [Validators.required, noWhitespaceValidator]),
      assignToId: new FormControl('', Validators.required),
      priority: new FormControl('', Validators.required),
      targetDate: new FormControl('', Validators.required),
      remarks: new FormControl(''),
    });
    this.reassignForm = this.fb.group({
      targetDate: new FormControl('', Validators.required),
      AssigneeId: new FormControl('', Validators.required),
    });
    this.closeForm = this.fb.group({
      remarks: [''],
      media: [[]],
    });
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.getData())
      )
      .subscribe();

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getResponsibles();
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['sourceId'].currentValue || changes['isCreate']) {
      this.allowCreate = this.isCreate;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  openBigView(id: number): void {
    const found: Action = this.actions.find((x) => x.id === id);
    if (found) {
      this.singleAction = found;
      if (found.actionStatus === ActionStatus.CLOSE && found.closedMedia) {
        this.dialog.open(this.imageBigView, {
          maxHeight: '95vh',
          width: 'auto',
        });
      }
    }
  }
  getResponsibles(): void {
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.responsibles = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getData() {
    let sortDirection = SortDirection.None;

    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: sortDirection,
      sortField,
      status: this.selectedStatus,
      priority: this.selectedPriority,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      searchValue: this.searchValue,
      sourceType: this.sourceType,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.trackerService.getActions(filteredParams, this.sourceId).subscribe({
      next: (response) => {
        this.actions = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.paginator.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.paginator.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onSelectStatus(event: MatSelectChange) {
    this.selectedStatus = event.value;
    this.getData();
  }

  onSelectPriority(event: MatSelectChange) {
    this.selectedPriority = event.value;
    this.getData();
  }

  openCreateActionModal(): void {
    this.formMode = 'create';
    this.actionForm.enable();
    this.resetForm();
    this.dialog.open(this.createActionModal, {
      height: 'auto',
      width: '50%',
      disableClose: true,
    });
  }

  createAction(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.LoadingTransparent;
          const resId = this.actionForm.get('assignToId')?.value;
          const currentUser = this.baseApi.getUserSession();
          const data = {
            title: this.actionForm.get('action')?.value,
            assignToId: resId ? resId : 0,
            assignToName: this.responsibles.find((x) => x.userId === resId)
              ?.fullName,
            priority: this.actionForm.get('priority')?.value,
            targetDate: moment(
              new Date(this.actionForm.get('targetDate')?.value)
            ).format('YYYY-MM-DDThh:mm:ssZ'),
            reportType: this.sourceType.split(',')[0],
            sourceId: this.sourceId,
            createdBy: currentUser.userId,
          };
          this.trackerService.createAction(data).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
              this.onCreateAction.emit(true);
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  editAction(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.LoadingTransparent;
          const resId = this.actionForm.get('assignToId')?.value;
          const currentUser = this.baseApi.getUserSession();
          const data = {
            id: this.actionId,
            title: this.actionForm.get('action')?.value,
            assignToId: resId ? resId : 0,
            assignToName: this.responsibles.find((x) => x.userId === resId)
              ?.fullName,
            priority: this.actionForm.get('priority')?.value,
            targetDate: moment(
              new Date(this.actionForm.get('targetDate')?.value)
            ).format('YYYY-MM-DDThh:mm:ssZ'),
            updatedBy: this.loggedUser.userId,
          };
          this.trackerService.editAction(data).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
              this.onCreateAction.emit(true);
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  removeAction(event: any, id: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.trackerService.removeAction(id).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  reAssignActionModal(event: any, id: number): void {
    this.actionId = id;
    this.reassignForm.reset();
    this.dialog.open(this.reassignActionModal, {
      height: 'auto',
      width: '30%',
      disableClose: true,
    });
    const found = this.actions.find((x) => x.id === id);
    this.reassignForm.get('targetDate').setValue(new Date(found?.targetDate));
    this.reassignForm.get('AssigneeId').setValue(found.assignToId);
  }

  reAssignAction(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.LoadingTransparent;
          const currentUser = this.baseApi.getUserSession();
          const data = {
            id: this.actionId,
            targetDate: moment(
              new Date(this.reassignForm.get('targetDate')?.value)
            ).format('YYYY-MM-DDThh:mm:ssZ'),
            assigneeId: this.reassignForm.get('AssigneeId')?.value,
            assigneeName: this.responsibles.find(
              (x) => x.userId === this.reassignForm.get('AssigneeId')?.value
            )?.fullName,
            updatedBy: currentUser.userId,
          };
          this.trackerService.reassignAction(data).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  viewAction(event: any, id: number): void {
    this.resetForm();
    const selectedAction = this.actions.find((x) => x.id === id);
    this.dialog.open(ViewActionComponent, {
      height: 'auto',
      width: '40%',
      data: {
        actionData: selectedAction,
      },
    });
  }

  openEditAction(id: number): void {
    this.resetForm();
    const found = this.actions.find((x) => x.id === id);
    this.actionId = id;
    this.formMode = 'edit';
    this.actionForm.enable();
    if (found) {
      this.actionForm.get('action').setValue(found?.title);
      this.actionForm.get('assignToId').setValue(found?.assignToId);
      this.actionForm.get('priority').setValue(found?.priority);
      this.actionForm.get('targetDate').setValue(new Date(found?.targetDate));
      this.actionForm.get('remarks').setValue(found?.remarks);
      this.dialog.open(this.createActionModal, {
        height: 'auto',
        width: '50%',
        disableClose: true,
      });
    }
  }

  resetForm(): void {
    this.actionForm.reset();
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  openCloseModal(event: any, actionId: number): void {
    this.actionId = actionId;
    this.closeForm.get('remarks')?.setValue('');
    this.closeForm.get('media')?.setValue([]);
    if (actionId) {
      this.dialog.open(this.closeModal, { height: '90vh', width: '50%' });
    }
  }

  closeAction(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          if (this.closeForm.value.media[0]) {
            const formData = new FormData();
            formData.append('file', this.closeForm.value.media[0]);
            this.mediaService.uploadSingleFile(formData).subscribe({
              next: (response) => {
                const data = {
                  actionId: this.actionId,
                  actionMedia: response?.mediaPath,
                  actionMediaName: this.closeForm.value.media[0].name,
                  remarks: this.closeForm.value.remarks,
                  updatedBy: this.loggedUser.userId,
                };
                this.trackerService.closeAction(data).subscribe({
                  next: (res) => {
                    this.feedBackService.showFeedback(
                      new FeedbackModel(FeedbackType.Success, res?.message)
                    );
                    this.closeDialog();
                    this.getData();
                    this.loadingService.stopLoading();
                  },
                  error: ({ error }) => {
                    this.feedBackService.showFeedback(
                      new FeedbackModel(FeedbackType.Failure, error?.message)
                    );
                    this.loadingService.stopLoading();
                  },
                });
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.loadingService.stopLoading();
              },
            });
          } else {
            const data = {
              actionId: this.actionId,
              actionMedia: '',
              remarks: this.closeForm.value.remarks,
              updatedBy: this.loggedUser.userId,
            };
            this.trackerService.closeAction(data).subscribe({
              next: (res) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.closeDialog();
                this.getData();
                this.loadingService.stopLoading();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.loadingService.stopLoading();
              },
            });
          }
        }
      });
  }
  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }
  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  openBigViewImage(imageUrl: string): void {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = imageUrl.split('.').pop();
    if (imageExtensions.includes(extension)) {
      this.selectedImg = imageUrl;
      this.dialog.open(this.imageBigView, {
        maxHeight: '95vh',
        width: 'auto',
      });
    } else {
      const downloadTag = document.createElement('a');
      downloadTag.href = imageUrl;
      downloadTag.addEventListener('click', () => {
        downloadTag.download;
      });
      downloadTag.click();
    }
  }
  downloadDocument(url: any): void {
    const downloadTag = document.createElement('a');
    downloadTag.href = url;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }
}
