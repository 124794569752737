<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.Reporting' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div class="card p-7 rounded-xl">
      <h2 class="title">{{ "CardTypes.CardsTitle" | translate }}</h2>
      <div class="cardSection pb-5">
        <app-card-type
          *ngIf="hasTypeIncidentPermission | permissionChecker"
          [title]="'General.Buttons.IncidentCategory'|translate"
          [imgSrc]="'assets/img/resources/type-of-incident.svg'"
          (onClick)="onTypesIncidentClick($event)"
        >
        </app-card-type>
        <app-card-type
          *ngIf="hasNatureInjurietPermission | permissionChecker"
          [title]="'General.Buttons.NatureInjury'|translate"
          [imgSrc]="'assets/img/resources/nature-of-injury.svg'"
          (onClick)="onNatureInjurieClick($event)"
        >
        </app-card-type>
        <app-card-type
          *ngIf="hasReasonsPermission | permissionChecker"
          [title]="'General.Buttons.Reasons'|translate"
          [imgSrc]="'assets/img/resources/reason.svg'"
          (onClick)="onReasonsClick($event)"
        >
        </app-card-type>
        <app-card-type
          *ngIf="hasRootCausesPermission | permissionChecker"
          [title]="'General.Buttons.RootCause'|translate"
          [imgSrc]="'assets/img/resources/root-cause.svg'"
          (onClick)="onRootCausesClick($event)"
        >
        </app-card-type>
        <app-card-type
          *ngIf="hasIncidentClassificationsPermission | permissionChecker"
          [title]="'General.Buttons.IncidentClassification'|translate"
          [imgSrc]="'assets/img/resources/incident-calssification.svg'"
          (onClick)="onIncidentClassificationsClick($event)"
        >
        </app-card-type>
        <!-- <app-card-type
          *ngIf="hasLossModesPermission | permissionChecker"
          [title]="'Loss Modes'"
          [imgSrc]="'assets/img/resources/loss-mode.svg'"
          (onClick)="onLossModesClick($event)"
        >
        </app-card-type> -->
      </div>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
