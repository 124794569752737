<app-state-section [flexibleHeight]="true" [state]="sectionState"
  [loadingLabel]="(formMode === 'create' ? 'Reason.AddingLoadingReason' : 'Reason.UpdatingLoadingReason')| translate"
  [errorLabel]="'Errors.ErrorHappened' | translate">
  <form [formGroup]="reasonForm">
    <div class="flex items-center" mat-dialog-title>
      <h2 *ngIf="reasonForm.get('causeName').value" class="headline m-0 flex-auto">{{
        reasonForm.get('causeName').value}}</h2>
      <h2 *ngIf="!reasonForm.get('causeName').value" class="headline m-0 flex-auto">{{'Reason.AddReason' | translate}}
      </h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex flex-col flex-wrap justify-between">
      <div class="field-full flex column">
        <mat-label class="field-label">{{'Reason.ReasonName' | translate}}
          <span
            [ngClass]="reasonForm.invalid && reasonForm.get('causeName').hasError('required') ? 'is-invalid' : 'is-valid'">*</span>
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input [placeholder]="'Placeholder.AddText' | translate" matInput type="text"
            [readonly]="this.formMode === 'view'" formControlName="causeName" />
          <mat-error *ngIf="reasonForm.invalid && reasonForm.get('causeName').hasError('required')">
            <div class="flex justify-flex-start align-flex-center">
              <img class="error-img" src="assets/img/resources/error-icon.svg" />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error *ngIf="reasonForm.invalid && reasonForm.get('causeName').hasError('whitespace')">
            <div class="flex justify-flex-start align-flex-center">
              <img class="error-img" src="assets/img/resources/error-icon.svg" />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Reporing type Id -->
      <div class="field-full flex column">
        <mat-label class="field-label">{{'Reason.ReportingType' | translate}}
          <span
            [ngClass]="reasonForm.invalid && reasonForm.get('reportingtypeId').hasError('required') ? 'is-invalid' : 'is-valid'">*</span>
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select [placeholder]="'Reason.ReportingType' | translate" formControlName="reportingtypeId"
            [disabled]="this.formMode === 'view'" multiple>

            <mat-option *ngIf="reporting.length !== 0" (click)="selectedvalue($event)" [value]="'ALL'">
              {{'Enum.ReportingTypes.ALL' | translate}}
            </mat-option>
            <mat-option (click)="selectAll($event)" *ngFor="let r of reporting" [value]="r">{{'Enum.ReportingTypes.'
              + r| translate}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="reasonForm.invalid && reasonForm.get('reportingtypeId').hasError('required')">
            <div class="flex justify-flex-start align-flex-center">
              <img class="error-img" src="assets/img/resources/error-icon.svg" />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

    </mat-dialog-content>
    <!-- History -->
    <div class="mt-4" *ngIf="this.formMode === 'view'">
      <app-expandable-panel [expanded]="true"  #expandablePanel [title]="'Reason.ReasonHistory'|translate">
        <app-history-log body *ngIf="this.defaults.reason"
          [sourceId]="this.defaults.reason.id"
          [sourceType]="HistoryType"
        ></app-history-log>
      </app-expandable-panel>
    </div>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button [btnType]="'Cancel'" [btnText]="'Cancel'" (onClick)="closeDialog()"></app-basic-button>
        <app-basic-button *ngIf="isCreateMode()" [btnType]="'Add'" [btnText]="'Add'" [isDisabled]="!reasonForm.valid"
          (onClick)="save()"></app-basic-button>
        <app-basic-button *ngIf="isUpdateMode()" [btnType]="'Add'" [btnText]="'Save'" [isDisabled]="!reasonForm.valid"
          (onClick)="save()"></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>