<div class="overflow-hidden">
  <div class="flex justify-end">
    <div class="wrapper">
      <div class="flex justify-end">
        <button
          (click)="openCreateModal()"
          class="ml-1 flex-none"
          color="primary"
          mat-mini-fab
          [matTooltip]="'General.Buttons.AddNew' | translate"
          type="button"
          style="border-radius: 10px"
        >
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <div class="wrapper">
      <mat-grid-list
        *ngIf="
          digitalDashboards.length > 0 || analogDashboards.length > 0;
          else noDataTemblate
        "
        cols="{{ columns }}"
        rowHeight="450px"
        gutterSize="10px"
      >
        <mat-grid-tile
          class="card-style ltr:mr-10 rtl:ml-10"
          *ngFor="let dashboard of dashboardList"
        >
          <app-dashboard-card
            *ngIf="dashboard.showCard"
            class="card"
            [dashboard]="dashboard"
          ></app-dashboard-card>
        </mat-grid-tile>
      </mat-grid-list>

      <ng-template #noDataTemblate>
        <div class="flex no-data">
          <div class="flex column">
            <img
              style="width: fit-content; margin: auto"
              src="assets/img/no-dashboards.png"
            />
            <h1 style="color: #00000099; font-size: large">
              This is the where you'll find your dashboards, start using feature
              to see data reflected here.
            </h1>
          </div>
        </div>
      </ng-template>
    </div>
  </app-state-section>
</div>
<ng-template #addIotDashboard>
  <app-add-iot-dashboard></app-add-iot-dashboard>
</ng-template>
