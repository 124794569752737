import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { generateGuid } from 'src/@hodhod/common/custom_methods';
import {
  ChecklistSectionType,
  OperatorType,
  WorkPermitApproverTypes,
} from 'src/@hodhod/common/enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import {
  CreateInstanceParam,
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { MediaService } from 'src/backend/services/media.service';
import { UserService } from 'src/backend/services/user.service';
import { WorkConditionsService } from 'src/backend/services/work-conditions/work-conditions.service';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { WorkPermitService } from 'src/backend/services/work-permits/work-permit.service';
import * as moment from 'moment';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { PpeService } from 'src/backend/services/ppe-services/ppe.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-add-work-permit-page',
  templateUrl: './add-work-permit-page.component.html',
  styleUrls: ['./add-work-permit-page.component.scss'],
})
export class AddWorkPermitPageComponent implements OnInit {
  @ViewChild('mediaModal') mediaModal!: TemplateRef<any>;
  mediaForm: FormGroup;
  minDate = new Date();
  minEndDate = new Date();
  minEndTime = moment(new Date()).format('hh:mm a');
  workPermitForm: FormGroup;
  workderInfoFormArray: FormArray;
  jobSafetyAnalyissFormArray: FormArray;
  private destroy$ = new Subject();
  public translationsList: any = {};
  public workConditions: any[] = [];
  public selectedWorkConditions: any[] = [];
  public selectedPpes: any[] = [];
  public departments: any[] = [];
  public subDepartments: any[] = [];
  public ppes: any[];
  public hazards: string[] = [];
  public wCCheckListMode: boolean = false;
  public wCHazardsMode: boolean = false;
  public checklists: any[] = [];
  sectionListFormArray: FormArray;
  questionTypes = ChecklistSectionType;
  OperatorType = OperatorType;
  riskStatus = [
    {
      text: 'HIGH',
      color: '#D52A46',
    },
    {
      text: 'MEDIUM',
      color: '#E49114',
    },
    {
      text: 'LOW',
      color: '#FFEA30',
    },
  ];
  gaugeType = 'semi';
  gaugeValue = 0;

  gaugeAppendText = '%';
  machines: any[] = [];
  machineId: number;
  ranges = [
    { id: 0, value: '0' },
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
  ];
  reportUsers: any;
  workFlow: WorkFlow;
  companyKey: string = '';
  instanceKey: string = '';
  loggedUser: LoggedUser;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  mintime: string;

  sectionId: number = 0;
  questionId: number = 0;

  jobSafetyColumns: TableColumn<any>[] = [
    { label: 'Number', property: 'number', type: 'text', visible: true },
    { label: 'JobSteps', property: 'jobStep', type: 'text', visible: true },
    { label: 'Hazards', property: 'theHazards', type: 'text', visible: true },
    {
      label: 'RiskLevel',
      property: 'riskLevel',
      type: 'select',
      visible: true,
    },
    {
      label: 'MitigatingSteps',
      property: 'mitigatingSteps',
      type: 'text',
      visible: true,
    },
    {
      label: 'ResidualRisk',
      property: 'residualRisk',
      type: 'select',
      visible: true,
    },
    {
      label: 'ToolsAndEquipments',
      property: 'toolsAndEquipments',
      type: 'text',
      visible: true,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  hasCreateDepartmentPermission = ApplicationPermission.DEPARTMENTS_CREATE;
  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  hasCreatePpePermission = ApplicationPermission.PPE_CREATE;
  hasCreateWorkConditionPermission =
    ApplicationPermission.WORK_CONDITIONS_CREATE;

  constructor(
    private fb: FormBuilder,
    private asyncFeedbackService: AsyncFeedbackService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private workConditionService: WorkConditionsService,
    private loadingService: LoadingService,
    private departmentService: DepartmentsService,
    private ppeService: PpeService,
    private checklistService: ChecklistSectionService,
    private router: Router,
    private workPermitService: WorkPermitService,
    private userService: UserService,
    private workFlowService: WorkFlowService,
    private baseApi: BaseApi,
    private mediaService: MediaService,
    private dialog: MatDialog
  ) {
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.getCurrenttime();
    this.workPermitForm = this.fb.group({
      title: ['', Validators.required],
      plannedActivityDescription: ['', Validators.required],
      equipmentDescription: ['', Validators.required],
      pPE: [[]],
      departmentId: ['', Validators.required],
      subDepartment: [''],
      workConditions: [[], Validators.required],
      startDate: [new Date(), Validators.required],
      endDate: [new Date(), Validators.required],
      startTime: [this.mintime, Validators.required],
      endTime: [this.mintime, Validators.required],
      attachments: [[]],
      approver: [[], Validators.required],
      additionalApprovers: [[]],
      workerInfos: this.fb.array([this.createWorkerInfoFormGroup()]),
      jobSafetyAnalyiss: this.fb.array([]),
      sections: this.fb.array([this.createSectionForm()]),
    });
    this.mediaForm = this.fb.group({
      file: [[], Validators.required],
    });
    this.workderInfoFormArray = this.workPermitForm.get(
      'workerInfos'
    ) as FormArray;
    this.jobSafetyAnalyissFormArray = this.workPermitForm.get(
      'jobSafetyAnalyiss'
    ) as FormArray;
    this.getWorkConditions();
    this.getCompanyDepartments();
    this.getPpes();
    this.sectionListFormArray = this.workPermitForm.get(
      'sections'
    ) as FormArray;
    this.getMachines();
    this.getWorkFlow();
    this.getReportedUsers();
  }

  newJobSafetyStep(): FormGroup {
    return this.fb.group({
      jobStep: ['', [Validators.required, noWhitespaceValidator]],
      toolsAndEquipments: ['', [Validators.required, noWhitespaceValidator]],
      theHazards: ['', [Validators.required, noWhitespaceValidator]],
      riskLevel: ['', [Validators.required, noWhitespaceValidator]],
      mitigatingSteps: ['', [Validators.required, noWhitespaceValidator]],
      residualRisk: ['', [Validators.required, noWhitespaceValidator]],
    });
  }

  addJobSafety() {
    this.jobSafetyAnalyissFormArray.push(this.newJobSafetyStep());
    this.workPermitForm.get('jobSafetyAnalyiss')['controls'] = [
      ...this.jobSafetyAnalyissFormArray.controls,
    ];
    this.jobSafetyAnalyissFormArray.controls = [
      ...this.jobSafetyAnalyissFormArray.controls,
    ];
  }
  get jobSafetyAnalyiss(): FormArray {
    return this.workPermitForm.get('jobSafetyAnalyiss') as FormArray;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  get visibleJobSafetyColumns() {
    return this.jobSafetyColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  removeJobSafety(index) {
    this.jobSafetyAnalyissFormArray.removeAt(index);
    this.workPermitForm.get('jobSafetyAnalyiss')['controls'] = [
      ...this.jobSafetyAnalyissFormArray.controls,
    ];
    this.jobSafetyAnalyissFormArray.controls = [
      ...this.jobSafetyAnalyissFormArray.controls,
    ];
  }
  validateDateRange(): void {
    const { startDate } = this.workPermitForm.value;
    this.minEndDate = new Date(startDate);
    this.workPermitForm.get('endDate')?.setValue(this.minEndDate);
  }
  validateTimeRange(e: any): void {
    const startTime = e;
    this.minEndTime = startTime;
    this.workPermitForm.get('endTime')?.setValue(this.minEndTime);
  }

  get repeatSectionFormGroup(): any {
    return this.workPermitForm.get('sections') as FormArray;
  }
  getCurrenttime(): void {
    const date = new Date();
    var n = date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
    this.mintime = n;
  }
  repeatQuestionsFormGroup(i: number): any {
    return this.workPermitForm
      .get('sections')
      ['controls'][i].get('questions') as FormArray;
  }
  createSectionForm(): FormGroup {
    return this.fb.group({
      sectionId: [0],
      title: [''],
      questionType: [''],
      questions: this.fb.array([this.createQuestionForm()]),
    });
  }
  createQuestionForm(): FormGroup {
    return this.fb.group({
      questionId: [0],
      questionText: [''],
      answer: [''],
      target: [0],
      parameters: [[]],
      userAnswer: [''],
      comment: [''],
      isNotApplicable: [false],
      media: [''],
    });
  }

  getReportedUsers(): void {
    this.workPermitService.getApproverByCompany().subscribe({
      next: (response) => {
        this.reportUsers = response;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  onChangeApprovers(event: any): void {
    const approvers = event.value;
    const additionalApprovers = this.workPermitForm.value.additionalApprovers;
    if (additionalApprovers.length > 0) {
      const unique = additionalApprovers.filter((x) => !approvers.includes(x));
      this.workPermitForm.get('additionalApprovers').setValue(unique);
    }
  }

  onChangeAdditionalApprovers(event: any): void {
    const approvers = this.workPermitForm.value.approver;
    if (approvers.length > 0) {
      const additionApprovers = event.value;
      const duplicate = approvers.filter((x) => additionApprovers.includes(x));
      const unique = additionApprovers.filter((x) => !duplicate.includes(x));
      if (duplicate.length > 0) {
        this.workPermitForm.get('additionalApprovers').setValue(unique);
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Warning,
            'Already selected in approvers'
          )
        );
      }
    }
  }

  getMachines() {
    this.checklistService.getMachines().subscribe((res) => {
      this.machines = res;
    });
  }
  getParameters(id: number, i: number, j: number) {
    this.checklistService.getParameters(id).subscribe((res) => {
      this.workPermitForm
        .get('sections')
        ['controls'][i].get('questions')
        ['controls'][j].get('parameters')
        ?.setValue(res);
    });
  }

  getWorkConditions(): void {
    this.loadingService.startLoading(true, '');
    this.workConditionService
      .getWorkConditionsWithCheckListExecution()
      .subscribe({
        next: (response) => {
          this.workConditions = response;
          this.loadingService.stopLoading();
        },
        error: ({ error }) => {
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  getCompanyDepartments(): void {
    this.departmentService.getDepartmentsParentChild().subscribe({
      next: (response) => {
        this.departments = response;
        if (
          this.departments.length === 1 &&
          this.departments[0].parentName == '---Parent Department---' &&
          this.departments[0].detail == null
        ) {
          this.departments = [];
        }
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getPpes() {
    this.ppeService.getActivePpes().subscribe({
      next: (response) => {
        this.ppes = response;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  get repeatWorkderInfoFormGroup(): FormArray {
    return this.workPermitForm.get('workerInfos') as FormArray;
  }
  createWorkerInfoFormGroup(): FormGroup {
    return this.fb.group({
      workerName: ['', [Validators.required, noWhitespaceValidator]],
      workerRole: ['', [Validators.required, noWhitespaceValidator]],
    });
  }
  addWorkerInfoRow(): void {
    this.workderInfoFormArray.push(this.createWorkerInfoFormGroup());
  }

  removeWorkerInfoRow(index: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['ConfirmDeleteRecord']['Title'],
          this.translationsList['ConfirmDeleteRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.workderInfoFormArray.removeAt(index);
        }
      });
  }

  onChangeConditions(event: any): void {
    const conditions: number[] = this.workPermitForm.value.workConditions;
    const unsortedCheckList: any[] = [];
    const unsortedHazards: any[] = [];
    this.selectedWorkConditions = this.workConditions.filter((x) =>
      conditions.includes(x.id)
    );
    this.workConditions
      .filter((x) => conditions.includes(x.id))
      .forEach((elem) => {
        unsortedCheckList.push(...elem.checkLists);
        unsortedHazards.push(...elem.hazardNames);
      });
    const mergeSections: any[] = [];
    [
      ...new Map(
        unsortedCheckList.map((item) => [item['checkListId'], item])
      ).values(),
    ].forEach((ele) => {
      mergeSections.push(...ele?.section);
    });

    this.checklists = mergeSections;
    if (mergeSections.length > 0) {
      this.wCCheckListMode = true;
    } else {
      this.wCCheckListMode = false;
    }
    if (unsortedHazards.length > 0) {
      this.wCHazardsMode = true;
    } else {
      this.wCHazardsMode = false;
    }
    this.buildForm();
    this.GetScorePercentage();
  }

  getParamterName(id: number, i: number, j: number): string {
    return this.workPermitForm
      .get('sections')
      .value[i].questions[j].parameters.find((x) => x.id === id)?.name;
  }

  buildForm(): void {
    this.loadingService.startLoading(true, '');
    const sections = this.checklists;
    this.addSectionsFormGroup(sections);
    this.workPermitForm
      .get('sections')
      ?.setValue(this.formatCheckListData(this.checklists));
    this.loadingService.stopLoading();
  }
  addSectionsFormGroup(sections: any[]): void {
    this.sectionListFormArray.clear();
    for (let index = 0; index < sections.length; index++) {
      this.sectionListFormArray.push(this.createSectionForm());
      this.addQuestionsFormGroup(sections[index]?.questions?.length, index);
    }
  }

  addQuestionsFormGroup(numOfGroups: number, i: number): void {
    this.repeatQuestionsFormGroup(i).clear();
    for (let index = 0; index < numOfGroups; index++) {
      this.repeatQuestionsFormGroup(i).push(this.createQuestionForm());
    }
  }

  formatCheckListData(data: any[]): any[] {
    const formatedData = data.map((section, i) => {
      return {
        sectionId: section?.sectionId,
        title: section?.sectionTitle,
        questionType: section?.sectionType,
        questions: section?.questions?.map((question, j) => {
          if (section?.sectionType === ChecklistSectionType.MachineParameter) {
            this.checklistService
              .getParameters(parseInt(question?.answer, 10))
              .subscribe((res) => {
                this.workPermitForm
                  .get('sections')
                  ['controls'][i].get('questions')
                  ['controls'][j].get('parameters')
                  ?.setValue(res);
              });
          }
          return {
            questionId: question?.questionId,
            questionText:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.questionTitle, 10)
                : question?.questionTitle,
            answer:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.Range
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[0]
                : question?.answer,
            target:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[1]
                : '',
            parameters: [],
            userAnswer: question?.userAnswer,
            comment: question?.comment,
            isNotApplicable: question?.isNotApplicable,
            media: question?.media,
          };
        }),
      };
    });
    return formatedData;
  }

  createWorkPermit(): void {
    const attachment = this.workPermitForm.value.attachments;
    const fileSize = attachment.reduce((accumulator, object) => {
      return accumulator + object.size;
    }, 0);
    const sizeInMB = fileSize / 1024 / 1024;
    if (sizeInMB <= SharedConstants.WORK_PERMIT_FILE_SIZE) {
      if (this.gaugeValue === 100) {
        this.confirmationService
          .confirm(
            new Confirmation(
              ConfirmationType.NonDestructiveAction,
              this.translationsList['User']['AddConfirm']['Title'],
              this.translationsList['User']['AddConfirm']['Message']
            )
          )
          .then((value) => {
            if (value === true) {
              this.loadingService.startLoading(true, '');
              this.createWorkFlowInstance();
            }
          });
      } else {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Warning, 'need 100% score to submit')
        );
      }
    } else {
      this.asyncFeedbackService.showFeedback(
        new FeedbackModel(FeedbackType.Warning, 'maximum file size allowed 5Mb')
      );
    }
  }

  createPermit(): void {
    const {
      title,
      plannedActivityDescription,
      equipmentDescription,
      pPE,
      departmentId,
      workConditions,
      startDate,
      endDate,
      startTime,
      endTime,
      attachments,
      workerInfos,
      jobSafetyAnalyiss,
      sections,
      approver,
      additionalApprovers,
      subDepartment,
    } = this.workPermitForm.value;
    const approvers = approver?.map((ele) => {
      return {
        approverId: 0,
        approver: ele,
        approverType: WorkPermitApproverTypes.InitialApprover,
      };
    });
    const formData = new FormData();
    const data = {
      title: title,
      plannedActivityDescription: plannedActivityDescription,
      equipmentDescription: equipmentDescription,
      pPE: pPE.toString(),
      departmentCode: subDepartment ? subDepartment : departmentId,
      startDate: moment(new Date(startDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      endDate: moment(new Date(endDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      startTime: startTime,
      endTime: endTime,
      workConditions: workConditions,
      workerInfos: workerInfos,
      jobSafetyAnalyisses: jobSafetyAnalyiss,
      instanceKey: this.instanceKey,
      additionalApprovers: [
        ...approvers,
        ...additionalApprovers?.map((ele) => {
          return {
            approverId: 0,
            approver: ele,
            approverType: WorkPermitApproverTypes.AdditionalApprover,
          };
        }),
      ],
      sections: sections?.map((ele) => {
        if (ele.questionType === 'Value Vs Target') {
          return {
            sectionId: ele.sectionId,
            sectionTitle: ele.title,
            questions: ele.questions?.map((ques) => {
              return {
                questionId: ques.questionId,
                userAnswer: ques.isNotApplicable
                  ? ''
                  : ques.answer + ' ' + ques.userAnswer.toString(),
                comment: ques.comment,
                isNotApplicable: ques.isNotApplicable,
                media: ques.media,
              };
            }),
          };
        } else {
          return {
            sectionId: ele.sectionId,
            sectionTitle: ele.title,
            questions: ele.questions?.map((ques) => {
              return {
                questionId: ques.questionId,
                userAnswer: ques.isNotApplicable
                  ? ''
                  : ques.userAnswer.toString(),
                comment: ques.comment,
                isNotApplicable: ques.isNotApplicable,
                media: ques.media,
              };
            }),
          };
        }
      }),
    };
    attachments.forEach((element) => {
      formData.append('files', element);
    });
    formData.append('content', JSON.stringify(data));
    this.workPermitService.createWorkPermit(formData).subscribe({
      next: (response) => {
        this.updateWorkFlowInstance(parseInt(response?.id, 10));
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, response?.message)
        );
        this.loadingService.stopLoading();
        this.goBack();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.WORK_PERMIT +
        '/' +
        SharedConstants.WORK_PERMIT_LIST,
    ]);
  }

  GetScorePercentage(): any {
    let correctanswers = 0;
    let NoOfQuestions = 0;
    let Percentage = 0;
    let sectionsFormArray = this.workPermitForm.get('sections').value;
    sectionsFormArray.forEach((element) => {
      if (element.questionType === 'Value Vs Target') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (
              el1.answer + ' ' + ' ' + el1.target ==
              el1.answer + ' ' + ' ' + el1.userAnswer
            ) {
              correctanswers++;
            }
            NoOfQuestions++;
          }
        });
      } else if (element.questionType === 'Select from range') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            switch (el1.userAnswer) {
              case '0':
                correctanswers = correctanswers + 0;
                break;
              case '1':
                correctanswers = correctanswers + 0.25;
                break;
              case '2':
                correctanswers = correctanswers + 0.5;
                break;
              case '3':
                correctanswers = correctanswers + 0.75;
                break;
              case '4':
                correctanswers++;
                break;
              default:
                break;
            }
            NoOfQuestions++;
          }
        });
      } else {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (el1.answer == el1.userAnswer) {
              correctanswers++;
            }
            NoOfQuestions++;
          }
        });
      }
    });
    Percentage = (correctanswers / NoOfQuestions) * 100;
    this.gaugeValue = Number(Percentage.toFixed(1));
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_WORKPERMIT_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  getSelectedPpes(event) {
    const ids = event.value;
    this.selectedPpes = [...this.ppes.filter((x) => ids.includes(x.id))];
  }
  createWorkFlowInstance(): void {
    this.instanceKey = generateGuid();
    const data: CreateInstanceParam = {
      workFlowKey: this.workFlow?.workFlowKey,
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.userName,
      tenantId: this.companyKey,
    };
    this.workFlowService.createWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.setWorkFlowInstance();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  setWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_CREATE_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: null,
    };
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.createPermit();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.deleteWorkFlowInstance();
        this.loadingService.stopLoading();
      },
    });
  }

  updateWorkFlowInstance(id: number): void {
    const {
      title,
      plannedActivityDescription,
      equipmentDescription,
      pPE,
      departmentId,
      workConditions,
      startDate,
      endDate,
      startTime,
      endTime,
      workerInfos,
      jobSafetyAnalyiss,
      approver,
    } = this.workPermitForm.value;
    const departmentsDetail = this.departments.map((x) => x.detail).flat(1);
    const workPermit = {
      workPermitId: id,
      title: title,
      plannedActivityDescription: plannedActivityDescription,
      equipmentDescription: equipmentDescription,
      pPE: pPE.toString(),
      departmentName: departmentsDetail.find(
        (x) => x.departmentCode === departmentId
      ).departmentName,
      startDate: moment(new Date(startDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      endDate: moment(new Date(endDate)).format('YYYY-MM-DDThh:mm:ssZ'),
      startTime: startTime,
      endTime: endTime,
      workConditions: this.workConditions
        .filter((x) => workConditions.includes(x.id))
        .map((x) => x.workConditionName)
        .toString(),
      workerInfos: workerInfos,
      jobSafetyAnalyisses: jobSafetyAnalyiss,
      instanceKey: this.instanceKey,
      additionalApprovers: this.reportUsers
        .filter((x) => approver.includes(x.userId))
        .map((x) => x.fullName)
        .toString(),
      createdBy: this.loggedUser.fullName,
      createdOn: new Date(),
      isActive: true,
      isDeleted: false,
    };
    const instance = {
      tenantId: this.companyKey,
      tableId: id,
      instanceKey: this.instanceKey,
      payload: workPermit,
    };
    this.workFlowService.updateWorkFlowInstance(instance).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  deleteWorkFlowInstance(): void {
    this.workFlowService.deleteWorkFlowInstance(this.instanceKey).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  getGuageColors(value: number): string {
    if (value < 40) {
      return '#fa2020';
    }
    if (value >= 40 && value < 70) {
      return '#2041fa';
    }
    if (value >= 70) {
      return '#42f548';
    }
    return '';
  }

  updateMediaUrl(mediaUrl: string): void {
    const sections = this.workPermitForm.get('sections').value;
    const singleSection = sections.find((x) => x.sectionId === this.sectionId);
    if (singleSection) {
      const question = singleSection.questions.find(
        (x) => x.questionId === this.questionId
      );
      if (question) {
        question.media = mediaUrl;
        this.workPermitForm.get('sections')?.setValue(sections);
      }
    }
  }

  uploadAnswerMedia(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const formData = new FormData();
          formData.append('file', this.mediaForm.value.file[0]);
          this.loadingService.startLoading(true, '');
          this.mediaService.uploadSingleFile(formData).subscribe({
            next: (response) => {
              this.updateMediaUrl(response?.mediaPath);
              this.closeDialog();
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
              this.loadingService.stopLoading();
            },
            error: ({ error }) => {
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  openMediaModal(sectionId: number, questionId: number): void {
    this.sectionId = sectionId;
    this.questionId = questionId;
    this.mediaForm.get('file')?.setValue([]);
    this.dialog.open(this.mediaModal, { height: 'auto', width: '40%' });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  notApplicableChange(): void {
    this.GetScorePercentage();
  }
  onChangeDepartment(event) {
    this.workPermitForm.get('subDepartment').setValue('');
    this.loadingService.startLoading(true);
    const departmentId = this.departments[0].detail.find(
      (item) => item.departmentCode == event.value
    )?.departmentId;
    this.departmentService.getSubDepartmentsById(departmentId).subscribe({
      next: (response) => {
        this.subDepartments = response;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
}
