export class PostNewTask {
    title: string;
    description: string;
    checklist: number;
    department: number;
    machine: number;
    user: string;
    start: Date;
    DurationDays:number;
    RecurrenceType:string;
    Interval:number;
    AbsoluteDays:string;
    RecurrenceSubType: string;
    DayOfMonth: number;
    RecurrenceMonth:string;
    RecurrenceWeek:string;
    durationType: string;
    StartTime:Date;
    EndTime:Date;
    createdBy:string;
}
export class UpdateTask {
    title: string;
    description: string;
    checklist: number;
    department: number;
    machine: number;
    user: string;
    start: Date;
    DurationDays:number;
    RecurrenceType:string;
    Interval:number;
    AbsoluteDays:string;
    RecurrenceSubType: string;
    DayOfMonth: number;
    RecurrenceMonth:string;
    RecurrenceWeek:string;
    durationType: string;
    StartTime:Date;
    EndTime:Date;
    Id:number;
    createdBy:string;
    
}


export class UpdateSingleTask {
    title: string;
    description: string;
    checklist: number;
    department: number;
    machine: number;
    user: string;
    TaskDate: any;
    Id:number;
    createdBy:string;
    
}
export class GetMeetingRecurrence {
    
    user: string;
    
}

export class GetTaskRecurrenceById {
    
    Id: number;
    
}

export interface TaskList{
    id:number;
    title:string;
    description:string;
    recurrenceType:string;
    createdOn:Date;
    dueDate:Date;
    user:string;
    status:string;
    userId: string;
}

