import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApi } from 'src/backend/api/base-api';
import { Constants } from 'src/@hodhod/common/constants';

@Injectable({
  providedIn: 'root'
})
export class InvestigationService {

constructor(private http: HttpClient, private baseApi: BaseApi) { }

saveInvestigation(data: any): Observable<any>{
  return this.http.post<any>(this.baseApi.encodeURL(Constants.API_INVESTIGATION_CREATE), data);
}

updateInvestigation(data: any): Observable<any>{
  return this.http.post<any>(this.baseApi.encodeURL(Constants.API_INVESTIGATION_UPDATE), data);
}

}
