<div
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav flex flex-col bg-primary"
>
  <div class="hodhod-sidenav-toolbar__container">
    <div class="sidenav-toolbar flex-none flex justify-between items-center">
      <div class="flex items-center" style="margin-inline-end: 75px">
        <img
          [src]="imageUrl$ | async"
          alt="Logo"
          class="w-full select-none object-contain"
          *ngIf="!collapsed"
        />
        <!-- <img
          [src]="'assets/img/resources/hodhod-name.svg'"
          alt="Logo"
          class="w-6 select-none"
          [style]="(isDark$ | async) ? 'filter: invert(100%)' : ''"
          *ngIf="!collapsed"
        /> -->
      </div>
      <button
        (click)="toggleCollapse()"
        *ngIf="showCollapsePin$ | async"
        [ngStyle]="
          currentLanguage.isoCode === 'ar'
            ? { 'margin-right': '-70px' }
            : { 'margin-left': '-58px' }
        "
        class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
        mat-icon-button
        type="button"
      >
        <img src="assets/img/resources/collapse.svg" alt="collapse" />
      </button>
    </div>
    <div class="hodhod-sidenav-toolbar__divider"></div>
  </div>

  <hodhod-scrollbar class="flex-1 flex flex-col">
    <div class="pt-6 flex-1">
      <hodhod-sidenav-item
        *ngFor="let item of items; trackBy: trackByRoute"
        [item]="item"
        [level]="0"
      >
      </hodhod-sidenav-item>
    </div>
  </hodhod-scrollbar>
  <div
    class="hodhod-sidenav-footer flex items-center justify-center px-6 py-6 select-none flex-none"
  ></div>
  <div
    *ngIf="
      (userVisible$ | async) &&
      organization?.orgId > 0 &&
      company?.companyId === 0
    "
    class="hodhod-sidenav-user__container flex-none"
  >
    <div
      #userProfileMenuOriginRef
      (click)="openProfileMenu(userProfileMenuOriginRef)"
      [class.hodhod-sidenav-user--open]="userMenuOpen$ | async"
      class="hodhod-sidenav-user flex items-center"
      matRipple
      matRippleColor="var(--sidenav-item-ripple-color)"
    >
      <ngx-avatars
        size="30"
        [src]="organization?.orgImage ? organization?.orgImage : ''"
        [name]="organization?.orgName"
        [textSizeRatio]="2"
        [round]="true"
        [initialsSize]="2"
      ></ngx-avatars>
      <div class="hodhod-sidenav-user__content flex-auto">
        <div class="hodhod-sidenav-user__title">
          {{ organization?.orgName | titlecase }}
        </div>
      </div>
      <mat-icon
        class="hodhod-sidenav-user__dropdown-icon flex-none"
        svgIcon="mat:arrow_drop_down"
      ></mat-icon>
    </div>
  </div>
  <div
    *ngIf="
      (userVisible$ | async) &&
      organization?.orgId > 0 &&
      company?.companyId > 0
    "
    class="hodhod-sidenav-user__container flex-none"
  >
    <div
      #userProfileMenuOriginRef
      (click)="openProfileMenu(userProfileMenuOriginRef)"
      [class.hodhod-sidenav-user--open]="userMenuOpen$ | async"
      class="hodhod-sidenav-user flex items-center"
      matRipple
      matRippleColor="var(--sidenav-item-ripple-color)"
    >
      <ngx-avatars
        size="30"
        [src]="company?.companyImage ? company?.companyImage : ''"
        [name]="company?.companyName"
        [textSizeRatio]="2"
        [round]="true"
        [initialsSize]="2"
      ></ngx-avatars>
      <div class="hodhod-sidenav-user__content flex-auto">
        <div class="hodhod-sidenav-user__title">
          {{ company?.companyName | titlecase }}
        </div>
      </div>
      <mat-icon
        class="hodhod-sidenav-user__dropdown-icon flex-none"
        svgIcon="mat:arrow_drop_down"
      ></mat-icon>
    </div>
  </div>
</div>
