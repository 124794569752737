<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "PPE.Title" | translate }}</span>
    </h2>
    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'Placeholder.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>

    <button
      (click)="openCreatePpeModal()"
      *ngIf="hasWritePermissions | permissionChecker"
      class="ml-4 flex-none"
      color="primary"
      mat-mini-fab
      [matTooltip]="'General.Buttons.AddNew' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>

  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'image'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ column.label }}
          </th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <ngx-avatars
              class="logo box"
              size="32"
              [src]="row[column.property]"
              [name]="row.ppeName"
              [textSizeRatio]="2"
              [round]="true"
              [initialsSize]="2"
            ></ngx-avatars>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "PPE.Table." + column.label | translate }}
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "PPE.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "dd-MM-yyyy" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "User.Table." + column.label | translate }}
              <mat-select
                [value]="selectedPpesStatus"
                (selectionChange)="onSelectStatus($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of PpeStatuses | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.CompanyUserStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <app-boolean-view
              [value]="row[column.property] === 'ACTIVE'"
              [text]="
                row[column.property] === 'ACTIVE'
                  ? ('General.Active' | translate)
                  : ('General.Inactive' | translate)
              "
            >
            </app-boolean-view>
          </td>
        </ng-container>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th *matHeaderCellDef mat-header-cell></th>

        <td *matCellDef="let row" mat-cell>
          <div (click)="$event.stopPropagation()">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                *ngIf="
                  (hasActivatePermissions | permissionChecker) &&
                  row?.status === 'ACTIVE'
                "
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="deactivatePpe(row?.id)"
              >
                <button>
                  {{ "General.Buttons.Deactivate" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                *ngIf="
                  (hasActivatePermissions | permissionChecker) &&
                  row?.status !== 'ACTIVE'
                "
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                (click)="activatePpe(row?.id)"
              >
                <button>
                  {{ "General.Buttons.Activate" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                *ngIf="hasViewPermissions | permissionChecker"
                (click)="viewPpe(row?.id)"
              >
                <button>
                  {{ "General.Buttons.View" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewPpe(row?.id)"
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-icon
      class="text-[#757575] me-1"
      *ngIf="!column.visible"
      svgIcon="mat:check_box_outline_blank"
    ></mat-icon>
    <mat-icon
      *ngIf="column.visible"
      class="text-primary me-1"
      svgIcon="mat:check_box"
    ></mat-icon>
    {{ "PPE.Table." + column.label | translate }}
  </button>
</mat-menu>

<ng-template #ppeCreateUpdateModal>
  <app-state-section
    [flexibleHeight]="true"
    [state]="sectionStateModal"
    [loadingLabel]="'General.Refreshing' | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <form [formGroup]="ppeForm">
      <div class="flex items-center justify-between" mat-dialog-title>
        <div class="relative">
          <div
            *ngIf="
              formMode === 'update' &&
              selectedImage &&
              (hasDeletePermission | permissionChecker)
            "
            class="cursor-pointer bg-red-700 text-white rounded-full absolute right-0 top-0 z-10 flex items-center justify-center"
            style="width: 20px; height: 20px"
            (click)="removePic($event)"
          >
            <mat-icon svgIcon="mat:close" class="icon-sm"></mat-icon>
          </div>
          <ngx-avatars
            class="logo box m-0 flex-auto"
            size="64"
            [src]="selectedImage"
            [name]="ppeDetails?.ppeName"
            [textSizeRatio]="2"
            [round]="true"
            [initialsSize]="2"
            *ngIf="formMode !== 'create'"
            (click)="$event.stopPropagation()"
          >
          </ngx-avatars>
        </div>
        <div>
          <button
            [disabled]="formMode === 'update'"
            class="mx-2"
            (click)="enableEdit()"
            *ngIf="
              (hasEditPermissions | permissionChecker) &&
              (formMode == 'view' || formMode == 'update')
            "
          >
            <img
              [class.gray-image]="formMode === 'update'"
              src="assets/img/resources/edit-icon.png"
              width="30px"
              height="30px"
            />
          </button>

          <button
            class="text-secondary"
            (click)="closeDialog()"
            mat-icon-button
            type="button"
          >
            <mat-icon svgIcon="mat:close"></mat-icon>
          </button>
        </div>
      </div>
      <mat-dialog-content class="flex flex-col flex-wrap justify-between">
        <div class="field-full flex column">
          <mat-label class="field-label"
            >{{ "PPE.Form.PpeName" | translate }}
            <span
              [ngClass]="
                ppeForm.invalid && ppeForm.get('ppeName').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="ppeName"
            />
            <mat-error
              *ngIf="
                ppeForm.invalid && ppeForm.get('ppeName').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
          <mat-label class="field-label" *ngIf="!ppeForm.disabled">{{
            "PPE.Form.Image" | translate
          }}</mat-label>
          <file-upload
            formControlName="file"
            [multiple]="false"
            [animation]="true"
            [accept]="'file_extension|image/*'"
            *ngIf="!ppeForm.disabled"
            (change)="onFileSelected($event)"
          ></file-upload>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions align="end">
        <div class="flex items-center">
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="closeDialog()"
          ></app-basic-button>
          <app-basic-button
            [btnType]="formMode === 'create' ? 'Add' : 'Add'"
            [btnText]="formMode === 'create' ? 'Add' : 'Save'"
            [isDisabled]="!ppeForm.valid"
            (onClick)="formMode === 'create' ? createPpe() : updatePpe()"
          ></app-basic-button>
        </div>
      </mat-dialog-actions>
    </form>
  </app-state-section>
</ng-template>
