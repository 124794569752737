import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hazards-page',
  templateUrl: './hazards-page.component.html',
  styleUrls: ['./hazards-page.component.scss']
})
export class HazardsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
