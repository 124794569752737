<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="'Role.LoadingRolePermissions' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
 <div @fadeInUp class="flex justify-between flex-wrap-reverse"  
 >
  <div class="panel_wrapper">
    <div class="flex justify-end items-center mb-3">
      <app-basic-button  *ngIf="createFlag && (hasCreateRolePermission| permissionChecker)"
      [btnType]="'Add'"
      [btnText]="'Save'"
      [isDisabled]="!(hasSelectedPermission && roleName)"
      [matTooltip]="!hasSelectedPermission?'You Need To Add Role Name & at least 1 permission':null"
      (onClick)="savePermissions()"
      ></app-basic-button>
      <app-basic-button *ngIf="!createFlag && (hasUpdateRolePermission| permissionChecker)"
      [btnType]="'Add'"
      [btnText]="'Save'"
      [isDisabled]="!hasSelectedPermission"
      [matTooltip]="!hasSelectedPermission?'You Need To Add at least 1 permission':null"
      (onClick)="savePermissions()"
      ></app-basic-button>
    </div>
    <app-dismiss-alert
    *ngIf="!(hasUpdateRolePermission|permissionChecker)"
    [message]="'General.NoPermissions'|translate"
  ></app-dismiss-alert>
    <div *ngFor="let Block of menuPermissions " class="customPanel">
      <app-expandable-panel [expanded]="true" #expandablePanel  [isSmallStyle]="true" 
      [title]="'PermissionsRows.' + Block.name | translate" 
      *ngIf="Block?.permissions?.length > 0">
        <div body class="flex column"   
        >
          <permissions-data-table 
            [columns]="tableColumns"
            [data]="Block"
            [RoleFlag]="true"
            [sectionId]="Block?.sectionId"
            [sectionSelected]="Block?.sectionSelected"
            (sharePermissions)="getPermissionsList($event)"
            (onSelectReviwer)="checkIfPermissionSelected($event)"
            >
          </permissions-data-table>
        </div>  
      </app-expandable-panel>
    </div>
  </div>
 </div>
</app-state-section>



