import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/@hodhod/common/constants';
import { generateGuid } from 'src/@hodhod/common/custom_methods';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import {
  CreateInstanceParam,
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import * as moment from 'moment';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { IncidentReportService } from 'src/backend/services/reportings/incident-report.service';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { Router } from '@angular/router';
import { BaseApi } from 'src/backend/api/base-api';

@Component({
  selector: 'app-incident-report-add',
  templateUrl: './incident-report-add.component.html',
  styleUrls: ['./incident-report-add.component.scss'],
})
export class IncidentReportAddComponent implements OnInit {
  workFlow: WorkFlow;
  companyKey: string = '';
  instanceKey: string = '';
  incidentForm: any;

  loggedUser: LoggedUser;

  constructor(
    private loadingService: LoadingService,
    private incidentReportService: IncidentReportService,
    private workFlowService: WorkFlowService,
    private baseApi: BaseApi,
    private router: Router,
    private asyncFeedbackService: AsyncFeedbackService
  ) {}

  ngOnInit() {
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.getWorkFlow();
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_INCIDENT_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  createReportIncident(reportIncidentForm: any): void {
    this.incidentForm = reportIncidentForm;
    this.createWorkFlowInstance();
  }

  createIncident(): void {
    const formData = new FormData();
    const {
      title,
      shift,
      attachments,
      typeOfIndcident,
      isAnyOneInjured,
      whathappend,
      incidentDate,
      departmentId,
      equipement,
      incidentTime,
      actionTaken,
      incidentClassification,
      incidentRisk,
      witness,
      rootCause,
      injuredPersons,
      subDepartment,
    } = this.incidentForm;
    const data = {
      title: title,
      typeOfIndcident: typeOfIndcident?.toString(),
      actionTaken: actionTaken,
      departmentCode: subDepartment ? subDepartment : departmentId,
      machineId: equipement,
      incidentClassificationId: incidentClassification,
      incidentDate: moment(new Date(incidentDate)).format(
        'YYYY-MM-DDThh:mm:ssZ'
      ),
      incidentRisk: incidentRisk,
      incidentTime: incidentTime,
      shift: shift,
      whathappend: whathappend,
      isAnyOneInjured: isAnyOneInjured,
      instanceKey: this.instanceKey,
      witness: witness ? witness?.toString() : '',
      rootCauseId: rootCause,
      injuredPersons: injuredPersons?.map((ele) => {
        return {
          age: ele.age,
          startDate: ele.startDate,
          endDate: ele.endDate,
          gender: ele.gender,
          idNumber: ele.idNumber,
          isEmployed: ele.isEmployed,
          jobPosition: ele.jobPosition,
          name: ele.name,
          natureOfInjury: ele.natureOfInjury?.toString(),
          protectiveEquipment: ele.protectiveEquipment?.toString(),
          frontSkeletonParts: ele.frontSkeletonParts?.toString(),
          backSkeletonParts: ele.backSkeletonParts?.toString(),
        };
      }),
    };
    formData.append('content', JSON.stringify(data));
    attachments.forEach((element) => {
      formData.append('files', element);
    });
    this.loadingService.startLoading(true, '');
    this.incidentReportService.createIncidentReport(formData).subscribe({
      next: (response) => {
        this.updateWorkFlowInstance(parseInt(response?.id, 10));
        this.getCustomUsersHierarchy(
          parseInt(response?.id, 10),
          response?.message
        );
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  getCustomUsersHierarchy(id: number, message: string): void {
    this.incidentReportService
      .getCustomUsersHierarchyFromIncident(id)
      .subscribe({
        next: (response) => {
          this.setCustomUsersHierarchy(response, message);
        },
        error: ({ error }) => {
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }
  setCustomUsersHierarchy(users: any[], message: string): void {
    const data = {
      instanceKey: this.instanceKey,
      isReminder: false,
      isEscalation: true,
      reminderUsers: [],
      escalationUsers: users?.map((ele) => {
        return {
          level: ele?.level,
          email: ele?.email,
          userId: ele?.userId,
        };
      }),
    };
    this.workFlowService.setCustomUserForReminderOrEscalation(data).subscribe({
      next: (res) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, message)
        );
        this.loadingService.stopLoading();
        this.goBack();
      },
      error: ({ error }) => {
        this.goBack();
        this.loadingService.stopLoading();
      },
    });
  }

  createWorkFlowInstance(): void {
    this.instanceKey = generateGuid();
    const data: CreateInstanceParam = {
      workFlowKey: this.workFlow?.workFlowKey,
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.userName,
      tenantId: this.companyKey,
    };
    this.loadingService.startLoading(true, '');
    this.workFlowService.createWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.setWorkFlowInstance();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  setWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_CREATE_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: null,
    };
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.createIncident();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
        this.deleteWorkFlowInstance();
      },
    });
  }

  updateWorkFlowInstance(id: number): void {
    const {
      title,
      shift,
      typeOfIndcident,
      isAnyOneInjured,
      whathappend,
      incidentDate,
      departmentId,
      equipement,
      incidentTime,
      actionTaken,
      incidentClassification,
      incidentRisk,
    } = this.incidentForm;
    const incidentData = {
      title: title,
      typeOfIndcident: typeOfIndcident?.toString(),
      actionTaken: actionTaken,
      departmentName: departmentId,
      machine: equipement,
      incidentClassification: incidentClassification,
      incidentDate: moment(new Date(incidentDate)).format(
        'YYYY-MM-DDThh:mm:ssZ'
      ),
      incidentRisk: incidentRisk,
      incidentTime: incidentTime,
      shift: SharedConstants.SHIFTS[shift],
      whathappend: whathappend,
      isAnyOneInjured: isAnyOneInjured,
      createdBy: this.loggedUser.fullName,
      createdOn: new Date(),
      isActive: true,
      isDeleted: false,
    };
    const instance = {
      tenantId: this.companyKey,
      tableId: id,
      instanceKey: this.instanceKey,
      payload: incidentData,
    };
    this.workFlowService.updateWorkFlowInstance(instance).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  deleteWorkFlowInstance(): void {
    this.workFlowService.deleteWorkFlowInstance(this.instanceKey).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_INCIDENT,
    ]);
  }
}
