<div
  class="grid lg:grid-cols-2 md:grid-cols-1 mb-2 card p-3 justify-evenly"
  *ngIf="activeKPIs?.length > 0; else noDataTemblate"
>
  <div class="p-3 w-fit">
    <!-- <div class="p-3" *ngIf="isSafemanHoursActive"> -->
    <safeman-hours-card
      [colorCode]="'#D52A46'"
      [activKpis]="activeKPIs"
    ></safeman-hours-card>
    <div *ngIf="isSafemanHoursActive">
      <div class="relative mb-5">
        <div
          class="border-black border-2 rounded-3xl first-div ms-7 py-2 px-5 bg-white"
        >
          <div
            class="flex justify-between crd"
            *ngIf="safemanHoursData?.mode === 'AUTO'"
          >
            <div>
              <h2
                class="font-semibold"
                [ngClass]="untillDate ? 'leading-none' : null"
              >
                Safeman Hours
              </h2>
              <span class="text-2xs text-gray-500" *ngIf="untillDate"
                >Untill {{ untillDate | date }}</span
              >
            </div>

            <div class="flex relative">
              <div style="min-width: unset !important">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  [matTooltip]="'Change Time Zone'"
                >
                  <img src="../../../../assets/img/resources/timezone.svg" />
                </button>
                <mat-menu #menu="matMenu" style="width: 50px; min-width: 0">
                  <span
                    mat-menu-item
                    class="p-0 h-fit py-1"
                    (click)="onSelectFlag('Africa/Cairo')"
                  >
                    <img
                      src="assets/img/resources/32209_egypt_flag_icon.png"
                      style="width: 25px; height: 20px"
                      alt=""
                    />
                  </span>
                  <span
                    mat-menu-item
                    class="p-0 h-fit"
                    (click)="onSelectFlag('Asia/Riyadh')"
                  >
                    <img
                      src="assets/img/resources/saudi-flag.png"
                      style="width: 25px; height: 20px"
                      alt=""
                    />
                  </span>
                </mat-menu>
              </div>

              <mat-form-field style="width: 0; height: 0; visibility: hidden">
                <input
                  matInput
                  [matDatepicker]="picker"
                  (dateChange)="onDateChange($event)"
                  style="display: none"
                  #datepicker
                />
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
              <button
                mat-icon-button
                (click)="picker.open()"
                #datepickerTrigger
              >
                <img
                  id="filterDashboard"
                  class="fill-primary"
                  src="../../../../assets/img/resources/calendar-icon-not-active.svg"
                  alt="calender"
                />
              </button>
              <button
                class="fill-primary"
                mat-button
                (click)="openBottomSheet()"
              >
                <img
                  id="fullScreenDashboard"
                  src="../../../../assets/img/resources/fullscreen.svg"
                  alt="full-screen"
                />
              </button>
            </div>
          </div>
          <h2 class="font-semibold" *ngIf="safemanHoursData?.mode !== 'AUTO'">
            Safeman Hours
          </h2>

          <div class="w-10/12 mx-auto mt-1 flex justify-between flex-wrap">
            <div class="border border-black rounded-xl p-1 text-center w-5/12">
              <p class="font-semibold">Target</p>
              <p class="truncate">{{ safemanHoursData?.target }}</p>
            </div>
            <div class="border border-black rounded-xl p-1 text-center w-5/12">
              <p class="font-semibold">Achieved</p>
              <p class="truncate">{{ safemanHoursData?.value }}</p>
              <p
                class="truncate text-2xs text-gray-500"
                *ngIf="safemanHoursData?.value === null"
              >
                {{ "No Records" }}
              </p>
            </div>
            <span
              class="text-2xs text-center text-gray-500 p-1"
              style="display: block; margin: auto"
              *ngIf="untillDate"
              >Timezone has an impact on the results</span
            >
          </div>
        </div>
        <div class="w-4/12 rounded-3xl sec-div bg-orange-400">
          <span class="vertical">SMH</span>
        </div>
      </div>
      <div class="relative mb-5" *ngIf="safemanHoursData?.mode === 'AUTO'">
        <div
          class="border-black border-2 rounded-3xl first-div ms-7 py-2 px-5 bg-white flex column justify-between items-center"
        >
          <h2 class="font-semibold self-start">
            Highest recorded Safeman Hours
          </h2>
          <div
            class="border border-black rounded-xl p-1 text-center w-5/12 mx-auto"
          >
            <p>{{ safemanHoursData?.highestRecordedValue["value"] }}</p>
          </div>
          <p class="text-2xs text-gray-500">
            {{
              "From " +
                (safemanHoursData?.highestRecordedValue["startedOn"] | date) +
                " To " +
                (safemanHoursData?.highestRecordedValue["resetOn"] | date)
            }}
          </p>
        </div>
        <div
          class="w-4/12 rounded-3xl sec-div"
          style="background-color: #ff4d00"
        >
          <span class="vertical">SMH</span>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div *ngIf="isTriangleActive">
      <app-safety-triangle-dashboard></app-safety-triangle-dashboard>
    </div>
  </div>

  <ng-template #expansion>
    <div class="overflow-hidden">
      <mat-card-title>
        {{ "SafemanHours.Table.Title" | translate }}
      </mat-card-title>

      <app-state-section
        [flexibleHeight]="true"
        [state]="sectionState"
        [loadingLabel]="loadingLabel | translate"
        [errorLabel]="'Errors.ErrorHappened' | translate"
      >
        <p class="text-sm text-gray-500" *ngIf="untillDate">
          Untill {{ untillDate | date }}
        </p>

        <table
          @stagger
          [dataSource]="dataSource"
          class="w-full"
          mat-table
          matSortDisabled
          matSort
        >
          <!-- Text Columns -->
          <ng-container
            *ngFor="let column of columns; trackBy: trackByProperty"
          >
            <ng-container
              *ngIf="
                column.type === 'text' && column.label === 'IncidentReportTitle'
              "
              [matColumnDef]="column.property"
            >
              <th
                *matHeaderCellDef
                class="uppercase"
                mat-header-cell
                mat-sort-header
              >
                {{ "SafemanHours.Table." + column.label | translate }}
              </th>
              <td
                *matCellDef="let row"
                [ngClass]="column.cssClasses"
                mat-cell
                (click)="navigateToIncident(row?.incidentReportId)"
              >
                <div class="w-10/12 truncate" #div>
                  <span
                    class="text-primary underline"
                    [matTooltip]="
                      div?.offsetWidth < div?.scrollWidth
                        ? row[column.property]
                        : null
                    "
                    >{{ row[column.property] }}</span
                  >
                </div>
              </td>
            </ng-container>
            <ng-container
              *ngIf="
                column.type === 'text' && column.label !== 'IncidentReportTitle'
              "
              [matColumnDef]="column.property"
            >
              <th
                *matHeaderCellDef
                class="uppercase"
                mat-header-cell
                mat-sort-header
              >
                {{ "SafemanHours.Table." + column.label | translate }}
              </th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                <div class="w-10/12 truncate" #div>
                  <span
                    [matTooltip]="
                      div?.offsetWidth < div?.scrollWidth
                        ? row[column.property]
                        : null
                    "
                    >{{ row[column.property] }}</span
                  >
                </div>
              </td>
            </ng-container>
            <ng-container
              *ngIf="column.type === 'date'"
              [matColumnDef]="column.property"
            >
              <th
                *matHeaderCellDef
                class="uppercase"
                mat-header-cell
                mat-sort-header
              >
                {{ "SafemanHours.Table." + column.label | translate }}
              </th>
              <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
                {{ row[column.property] | date : "dd-MM-yyyy" }}
              </td>
            </ng-container>
          </ng-container>

          <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
          <tr
            *matRowDef="let row; columns: visibleColumns"
            @fadeInUp
            class="hover:bg-hover trans-ease-out cursor-pointer"
            mat-row
          ></tr>
        </table>
        <div
          *ngIf="dataSource.filteredData.length === 0"
          @scaleFadeIn
          class="flex-auto flex flex-col items-center justify-center"
        >
          <img src="assets/img/resources/empty-state.svg" alt="not found" />
          <h2 class="headline m-0 text-center text-primary">
            {{ "General.EmptyTableList" | translate }}...
          </h2>
        </div>
      </app-state-section>
    </div>
  </ng-template>
</div>
<ng-template #noDataTemblate>
  <div class="flex no-data">
    <div class="flex column">
      <img
        style="width: fit-content; margin: auto"
        src="assets/img/no-dashboards.png"
      />
      <h1 style="color: #00000099; font-size: large">
        This is the where you'll find your dashboards, start using feature to
        see data reflected here.
      </h1>
    </div>
  </div>
</ng-template>
