import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lost-id-card-page',
  templateUrl: './lost-id-card-page.component.html',
  styleUrls: ['./lost-id-card-page.component.scss']
})
export class LostIdCardPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
