<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.ReportNearMiss' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div body>
      <app-near-miss-report-view
        [nearMissReport]="nearMissReport"
        (onInvestigate)="onInvestigate($event)"
      >
      </app-near-miss-report-view>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
