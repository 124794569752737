import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators, EmailValidator } from 'src/app/shared/custom-validators/form-validators';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { AuthService } from 'src/backend/services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public hidePassword = true;
  public hideConfirmPassword = true;
  userForm: FormGroup;
  public token: any = '';
  successOrErrorMessage: string = '';
  isSignInEnabled: boolean = false;
  isError: boolean = true;
  constructor(private route: ActivatedRoute, private loadingService: LoadingService,
    private authService: AuthService, private router: Router, private fb: FormBuilder) {
  }
 

  ngOnInit() {
   this.userForm = this.fb.group({
    email: ['', [Validators.required, EmailValidator]],
    password: ['', Validators.compose(
      [Validators.required,
        CustomValidators.patternValidator(/\d/, {
        hasNumber: true
      }),
      // check whether the entered password has upper case letter
      CustomValidators.patternValidator(/[A-Z]/, {
        hasCapitalCase: true
      }),
      // check whether the entered password has a lower case letter
      CustomValidators.patternValidator(/[a-z]/, {
        hasSmallCase: true
      }),
      // check whether the entered password has a special character
      CustomValidators.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }
      ),
      Validators.minLength(6)])],
      confirmPassword: ['', Validators.compose([Validators.required])],
    }, {validators: this.matchingPasswords('password', 'confirmPassword')});
    this.route.queryParams
    .subscribe((params: any) => {        
      this.userForm.get('email')?.setValue(params?.email);     
      this.token = encodeURIComponent(params?.token);      
    }
    );

  }

  matchingPasswords(password: string, confirmPassword: string) {
    return (controls: AbstractControl) => {
      if (controls) {
        const password = controls.get('password')!.value;
        const confirmPassword = controls.get('confirmPassword')!.value;
        //console.log ("check what is passed to the validator", password, confirmPassword);
        if (password !== confirmPassword) { 
          //this is an error set for a specific control which you can use in a mat-error
          controls.get('confirmPassword')?.setErrors({NoPassswordMatch: true});  
          //this is the returned error for the form normally used to disable a submit button
          return {NoPassswordMatch: true}  
        }
      }
      return null;
    }
  }

  resetPasswordClick(): void {
    const data = {
      email: this.userForm.value.email,
      token: this.token,
      password: this.userForm.value.password
    };
    this.loadingService.startLoading(true, '');
    this.successOrErrorMessage = '';
    this.isSignInEnabled = false;
    this.isError = true;
    this.authService.resetPassword(data?.email, this.token, data?.password).subscribe({
      next: (response) => {
        this.successOrErrorMessage = response?.message;
        this.loadingService.stopLoading();
        this.isSignInEnabled = true;
        this.isError = false;
      },
      error: ({error}) => {
        this.successOrErrorMessage = error?.message;
        this.loadingService.stopLoading();
        this.isSignInEnabled = false;
        this.isError = true;
      }
    })
  }

  goToLogin(): void {
    this.router.navigate(['/' + SharedConstants.AUTH + '/' + SharedConstants.SIGN_IN]);
  }
}
