import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { SmartSafetyFilterDashboards } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { SafemanHoursService } from 'src/backend/services/safeman-hours.service';

@Component({
  selector: 'app-safety-kpi-dashboards',
  templateUrl: './safety-kpi-dashboards.component.html',
  styleUrls: ['./safety-kpi-dashboards.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms, stagger40ms],
})
export class SafetyKpiDashboardsComponent implements OnInit, OnChanges {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  @Input() loadKPIs: boolean;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('expansion') expansion!: TemplateRef<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('picker') picker: MatDatepicker<any>;
  @ViewChild('specificElement') specificElementRef: ElementRef;

  constructor(
    private dialog: MatDialog,
    private safemanHoursService: SafemanHoursService,
    private _bottomSheet: MatBottomSheet,
    private router: Router,
    private elementRef: ElementRef,
    private loadingService: LoadingService
  ) {}
  public filterDashboards: any = null;
  dataSource = new MatTableDataSource<any>([]);
  loadingLabel: string = '';
  untillDate: any;
  activeKPIs: any;
  today = new Date();
  isTriangleActive: boolean = false;
  isSafemanHoursActive: boolean = false;
  localTimaZone: string;
  columns: TableColumn<any>[] = [
    {
      label: 'IncidentReportTitle',
      property: 'incidentReportTitle',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Value',
      property: 'value',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },

    {
      label: 'StartedOn',
      property: 'startedOn',
      type: 'date',
      visible: true,
    },
    {
      label: 'ResetOn',
      property: 'resetOn',
      type: 'date',
      visible: true,
    },
  ];
  safemanHoursData;
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadKPIs'].currentValue) {
      this.dataSource.sort = this.sort;
      this.filterDashboards = SmartSafetyFilterDashboards;
      this.localTimaZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.getActiveSafetykpis();
    }
  }
  onDateChange(event?) {
    this.untillDate = event?.value ? event.value : this.today;
    this.getSafemanHoursByFilter({
      localFilterDate: this.untillDate.toLocaleString(),
      timeZone: this.localTimaZone
        ? this.localTimaZone
        : this.localTimaZone === 'Africa/Cairo'
        ? 'Africa/Cairo'
        : 'Asia/Riyadh',
    });
  }
  onSelectFlag(value) {
    this.localTimaZone = value;
  }
  navigateToIncident(id) {
    this._bottomSheet.dismiss();
    this.router.navigate(
      [
        SharedConstants.REPORTING_MANAGEMENT +
          '/' +
          SharedConstants.REPORT_INCIDENT +
          '/' +
          SharedConstants.REPORT_INCIDENT_VIEW +
          '/' +
          id,
      ],
      {
        queryParams: { editMode: false },
      }
    );
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  openDatePicker() {
    this.picker.open();
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  getSafemanHoursByFilter(filter) {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.loadingService.startLoading(true);
    this.safemanHoursService.getSafemanHoursByFilter(filter).subscribe({
      next: (res) => {
        this.sectionState = SectionStateStatus.Ready;
        this.safemanHoursData = res;
        this.dataSource = new MatTableDataSource(res?.lastRecorded);
        this.dataSource.sort = this.sort;
        this.safemanHoursData['filter'] = filter;
        this.loadingService.stopLoading();
      },
      error: (error) => {
        this.loadingService.stopLoading();
      },
    });
  }
  getActiveSafetykpis() {
    this.loadingService.startLoading(true);
    this.safemanHoursService.getActiveSafetyKPIs().subscribe({
      next: (res) => {
        this.activeKPIs = res;
        this.isTriangleActive = res?.some(
          (obj) => obj.type === 'safetyTriangle'
        );
        this.isSafemanHoursActive = res?.some(
          (obj) => obj.type === 'safeManHours'
        );
        this.isSafemanHoursActive ? this.onDateChange() : null;
        this.loadingService.stopLoading();
      },
    });
  }

  openBottomSheet() {
    this.dialog.open(this.expansion, {
      maxHeight: 'auto',
      width: '70%',
      disableClose: false,
    });
    this.dataSource.sort = this.sort;
  }
}
