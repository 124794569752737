import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime } from 'luxon';
import * as moment from 'moment';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { PenaltyManagementService } from 'src/backend/services/penalty-management.service';
import { UserService } from 'src/backend/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
@Component({
  selector: 'app-new-penalty-mangment',
  templateUrl: './new-penalty-mangment.component.html',
  styleUrls: ['./new-penalty-mangment.component.scss'],
})
export class NewPenaltyMangmentComponent implements OnInit {
  penaltyMangmentForm: FormGroup;
  listOfApprovers: any = [];
  listOfAreas: any = [];
  listOfTeams: any = [];
  ids = [];

  currentQueryParams = null;
  foundPenalty: any = null;
  today: Date = new Date();
  private destroy$ = new Subject();
  public translationsList: any = {};
  attachments: any;
  editMode: any;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private PenaltyManagementService: PenaltyManagementService,
    private loadingService: LoadingService,
    private asyncFeedbackService: AsyncFeedbackService,
    private dialog: MatDialog,
    private usersService: UserService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private translate: TranslateService
  ) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.editMode = this.route.snapshot.queryParams['editMode'];
    if (this.editMode === 'true') {
      this.getPenaletyDetails();
    }
    this.currentQueryParams = this.route.snapshot.queryParams;
    this.penaltyMangmentForm = this.fb.group({
      workerOrderNO: ['', [Validators.required, Validators.maxLength(30)]],
      title: ['', [Validators.required, Validators.maxLength(50)]],
      approvers: [[], [Validators.required]],
      description: ['', [noWhitespaceValidator, Validators.maxLength(500)]],
      area: [null, []],
      ResponsibleTeam: [null, []],
      cost: [null, [Validators.min(0)]],
      DayOfMonth: ['', [Validators.required]],
      TimeReceived: ['', [Validators.required]],
      attachments: [[]],
    });
    this.getReportToUsers();
    this.getAreasDDL();
    this.getTeamsDDl()
  }
  goBack() {
    this.router.navigate([
      '/' +
        SharedConstants.PENALTY_MANAGEMENT +
        '/' +
        SharedConstants.PENALTY_MANAGEMENT_LIST,
    ]);
  }
  createPenaltyMangment() {
    this.loadingService.startLoading(true, '');
    const data = {
      WorkOrderNo: this.penaltyMangmentForm.get('workerOrderNO').value,
      Title: this.penaltyMangmentForm.get('title').value,
      Description: this.penaltyMangmentForm.get('description').value,
      Cost: +this.penaltyMangmentForm.get('cost').value,
      DateReceived: moment(
        new Date(this.penaltyMangmentForm.get('DayOfMonth').value)
      ).format('YYYY-MM-DDThh:mm:ssZ'),
      TimeReceived: this.penaltyMangmentForm.get('TimeReceived').value,
      Area: this.penaltyMangmentForm.get('area').value,
      ResponsibleTeam: this.penaltyMangmentForm.get('ResponsibleTeam').value,
      PenaltyApprovers: this.ids.map((id) => {
        return { approverId: id };
      }),
    };
    const formData = new FormData();
    formData.append('content', JSON.stringify(data));
    let files = this.penaltyMangmentForm.get('attachments').value;
    files.forEach((element) => {
      formData.append('files', element);
    });
    this.PenaltyManagementService.createPenaltyManagement(formData).subscribe({
      next: (response) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, 'Created Successfully')
        );
        this.goBack();
        this.dialog.closeAll();
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  editPenaltyMangment() {
    let { id } = this.route.snapshot.params;
    this.loadingService.startLoading(true, '');

    const data = {
      Id: id,
      WorkOrderNo: this.penaltyMangmentForm.get('workerOrderNO').value,
      Title: this.penaltyMangmentForm.get('title').value,
      Description: this.penaltyMangmentForm.get('description').value,
      Cost: +this.penaltyMangmentForm.get('cost').value,
      DateReceived: moment(
        new Date(this.penaltyMangmentForm.get('DayOfMonth').value)
      ).format('YYYY-MM-DDThh:mm:ssZ'),
      TimeReceived: this.penaltyMangmentForm.get('TimeReceived').value,
      Area: this.penaltyMangmentForm.get('area').value,
      ResponsibleTeam: this.penaltyMangmentForm.get('ResponsibleTeam').value,
      PenaltyApprovers: this.ids.map((id) => {
        return { approverId: id };
      }),
    };

    const formData = new FormData();
    formData.append('content', JSON.stringify(data));
    let files = this.penaltyMangmentForm.get('attachments').value;
    files.forEach((element) => {
      formData.append('files', element);
    });
    this.PenaltyManagementService.editPenaltyManagement(formData).subscribe({
      next: (response) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, response?.message)
        );
        this.dialog.closeAll();
        this.loadingService.stopLoading();
        this.goBack();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  getReportToUsers() {
    this.PenaltyManagementService.getApproverList().subscribe((res) => {
      this.listOfApprovers = res;
    });
  }
  getAreasDDL(){
    this.PenaltyManagementService.getAreasDDL().subscribe((res) => {
      this.listOfAreas = res['message'];
    });

  }
  getTeamsDDl(){
    this.PenaltyManagementService.getTeamsDDl().subscribe((res) => {
      this.listOfTeams = res['message'];
    });
  }
  deleteAttachment(id) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.PenaltyManagementService.deleteattachment(id).subscribe(
            (response) => {
              this.getPenaletyDetails();
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
            }
          );
        }
      });
  }
  validateFiles() {
    const attachmentsControl = this.penaltyMangmentForm.get('attachments');
    const files = attachmentsControl.value;

    let totalSize = 0;
    for (const file of files) {
      totalSize += file.size;
    }
    const maxSize = 10 * 1024 * 1024; // 10 MB
    if (totalSize > maxSize) {
      this.asyncFeedbackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          'Total file size exceeds the limit (10 MB)'
        )
      );
      this.penaltyMangmentForm
        .get('attachments')
        .setErrors({ sizeExceeded: true });
    }
  }
  selectApprovers(event?) {
    let IDs;
    event ? (IDs = event.value) : (IDs = this.ids);
    const uniqueApproverIds = new Set();
    this.ids = IDs.map((id) => {
      const isUnique = !uniqueApproverIds.has(id);
      if (isUnique) {
        uniqueApproverIds.add(id);
        return id;
      }
      return null;
    }).filter(Boolean);
  }
  getPenaletyDetails() {
    let { id } = this.route.snapshot.params;
    let { editMode } = this.route.snapshot.queryParams;
    this.sectionState = SectionStateStatus.Loading;
    this.PenaltyManagementService.getPenaltyManagementById(id).subscribe(
      (response) => {
        if (editMode) {
          this.foundPenalty = response;
          this.attachments = [...response?.penaltyAttachments];
          this.penaltyMangmentForm
            .get('workerOrderNO')
            .setValue(response?.workOrderNo);
          this.penaltyMangmentForm.get('title').setValue(response?.title);
          this.penaltyMangmentForm
            .get('description')
            .setValue(response?.description);
          this.penaltyMangmentForm.get('area').setValue(response?.area);
          this.penaltyMangmentForm
            .get('ResponsibleTeam')
            .setValue(response?.responsibleTeam);
          this.penaltyMangmentForm.get('cost').setValue(response?.cost);
          this.penaltyMangmentForm
            .get('DayOfMonth')
            .setValue(response?.dateReceived);

          this.ids = response?.penaltyApprovers.map(
            (approver) => approver.approverId
          );
          this.penaltyMangmentForm.get('approvers').setValue(this.ids);
          this.penaltyMangmentForm
            .get('TimeReceived')
            .setValue(response?.timeReceived); //   let files=[];
        }
        this.sectionState = SectionStateStatus.Ready;
      }
    );
  }
  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }
  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }
  onWheel(event: MouseEvent) {
    event.preventDefault();
  }
}
