import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { ChatbotService } from 'src/backend/services/chatbot/chatbot.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { LanguageService } from 'src/@hodhod/services/language.service';

@Component({
  selector: 'app-chatbot-modal',
  templateUrl: './chatbot-modal.component.html',
  styleUrls: ['./chatbot-modal.component.scss'],
})
export class ChatbotModalComponent implements OnInit {
  @ViewChild('messageInput') messageInput: ElementRef;
  @ViewChild('scrollMe') myScrollContainer: ElementRef;
  @ViewChild('rateMessage') rateMessage: TemplateRef<any>;

  sessionId: string = '';
  customStyle = {
    backgroundColor: '#ffffff',
    border: '1px solid #000',
    color: '#000',
  };
  messages = [];

  public loggedUser: LoggedUser;
  public userFeedBackMessage: string = '';
  public feedBackModal: any;
  public isFullScreen: boolean = false;
  public currentLanguage: string;

  constructor(
    private chatbotService: ChatbotService,
    private baseApi: BaseApi,
    private feedbackService: AsyncFeedbackService,
    private dialogRef: MatDialogRef<ChatbotModalComponent>,
    private dialog: MatDialog,
    private clipboard: Clipboard,
    private langService: LanguageService,
    @Inject(MAT_DIALOG_DATA)
    public data: { sessionId: string }
  ) {}

  ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.sessionId = this.data.sessionId;
    this.langService.currentLanguage$.subscribe((language) => {
      this.currentLanguage = language.isoCode;
    });
  }

  sendMessage(messageContent: string) {
    this.messageInput.nativeElement.disabled = true;
    const message = {
      question: messageContent,
      message: '',
      isItLiked: null,
    };
    this.messages.push(message);
    const data = {
      msg: messageContent,
      session_id: this.sessionId,
      company_name: JSON.parse(localStorage.getItem('company'))?.companyName,
      username: JSON.parse(localStorage.getItem('logged_in_user'))?.fullName,
      language: this.currentLanguage,
    };
    this.scrollToBottom();
    this.messageInput.nativeElement.value = '';
    this.chatbotService.processChatMessage(data).subscribe({
      next: (res) => {
        let count = 0;
        const id = setInterval(() => {
          if (message.message.length === res.response.length) {
            clearInterval(id);
          }
          message.message += res.response.charAt(count);
          count += 1;
          this.scrollToBottom();
        }, 50);

        this.messageInput.nativeElement.disabled = false;
      },
      error: (error) => {
        this.feedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.messageInput.nativeElement.disabled = false;
      },
    });
  }

  likeMessage(message, index) {
    this.feedBackModal = this.dialog.open(this.rateMessage, {
      disableClose: true,
      panelClass: 'rounded-mat-dialog',
      width: '285px',
      height: '290px',
    });

    this.feedBackModal.afterClosed().subscribe((res) => {
      const data = {
        question: message.question,
        answer: message.message,
        isItLiked: true,
        sessionId: this.sessionId,
        feedbackComment: this.userFeedBackMessage,
      };
      this.userFeedBackMessage = '';
      this.chatbotService.saveUserFeedBack(data).subscribe({
        next: (res) => {
          this.messages[index].isItLiked = true;
          this.feedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
        },
        error: (error) => {
          this.feedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
    });
  }

  dislikeMessage(message, index) {
    this.feedBackModal = this.dialog.open(this.rateMessage, {
      disableClose: true,
      panelClass: 'rounded-mat-dialog',
      width: '285px',
      height: '290px',
    });
    this.feedBackModal.afterClosed().subscribe((res) => {
      const data = {
        question: message.question,
        answer: message.message,
        isItLiked: false,
        sessionId: this.sessionId,
        feedbackComment: this.userFeedBackMessage,
      };
      this.userFeedBackMessage = '';
      this.chatbotService.saveUserFeedBack(data).subscribe({
        next: (res) => {
          this.messages[index].isItLiked = false;
          this.feedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
        },
        error: (error) => {
          this.feedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
    });
  }

  copyMessage(message: string) {
    this.clipboard.copy(message);
  }

  scrollToBottom = () => {
    try {
      setTimeout(() => {
        this.myScrollContainer.nativeElement.scrollTop =
          this.myScrollContainer.nativeElement.scrollHeight;
      }, 100);
    } catch (err) {}
  };

  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
    if (this.isFullScreen) {
      this.dialogRef.updateSize('75%', '75vh');
    } else {
      this.dialogRef.updateSize('25%', 'auto');
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  closeFeedBackModal() {
    this.feedBackModal.close();
  }
}
