import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import { LoadingService } from 'src/app/shared/helpers/loading.service';

@Component({
  selector: 'app-msds-qrcode',
  templateUrl: './msds-qrcode.component.html',
  styleUrls: ['./msds-qrcode.component.scss'],
})
export class MsdsQrcodeComponent implements OnInit {
  public companyLogo =
    JSON.parse(localStorage.getItem('company')).companyImage || '';

  @ViewChild('captureElement', { static: false }) captureElement!: ElementRef;

  print = false;

  constructor(
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA)
    public data: { Url: string; Title?: string; Code?: string,type:string }
  ) {}

  ngOnInit(): void {}

  captureAndDownload() {
    this.loadingService.startLoading(true);
    this.print = true;
    setTimeout(() => {
      var node = document.getElementById('msds-qrcode-dialog');
      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          const link = document.createElement('a');
          link.download = 'image.png';
          link.href = dataUrl;
          link.click();
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error);
        });
      this.loadingService.stopLoading();
      this.print = false;
    }, 1000);
  }
}
