<div class="w-full h-full bg-gradient-to-r from-gray-200 to-gray-300">
  <div class="container items-center justify-center">
    <div class="flex items-center justify-center text-center pt-20">
      <img src="assets/img/resources/Sanedlogo.png" width="200" />
    </div>
    <form class="flex column mt-8" [formGroup]="userForm">
      <div class="field flex column mx-auto w-1/5">
        <mat-label class="field-label">{{
          "General.Inputs.Email" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            matInput
            type="email"
            [placeholder]="'Placeholder.EnterEmail' | translate"
            formControlName="email"
            autocomplete="email"
            readonly
          />
          <mat-error
            *ngIf="
              userForm.invalid && userForm.get('email').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.invalid && userForm.get('email').hasError('invalidEmail')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InvalidEmail" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="field flex column mx-auto w-1/5">
        <mat-label class="field-label">{{ "New Password" }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            matInput
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
            autocomplete="off"
          />
          <mat-icon
            class="password-visibility"
            matSuffix
            (click)="hidePassword = !hidePassword"
            [svgIcon]="hidePassword ? 'mat:visibility_off' : 'mat:visibility'"
          >
          </mat-icon>
        </mat-form-field>
        <div
          class="bg-white p-2 shadow"
          *ngIf="
            userForm.invalid &&
            userForm.touched &&
            (userForm.get('password').hasError('required') ||
              userForm.get('password').hasError('minlength') ||
              userForm.get('password').hasError('hasNumber') ||
              userForm.get('password').hasError('hasCapitalCase') ||
              userForm.get('password').hasError('hasSmallCase') ||
              userForm.get('password').hasError('hasSpecialCharacters'))
          "
        >
          <mat-error
            *ngIf="
              userForm.invalid && userForm.get('password').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.invalid && userForm.get('password').hasError('minlength')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "* Must be at least 6 characters!" }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.invalid && userForm.get('password').hasError('hasNumber')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "* Must contain at least 1 number!" }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.invalid &&
              userForm.get('password').hasError('hasCapitalCase')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "* Must contain at least 1 in Capital Case!" }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.invalid &&
              userForm.get('password').hasError('hasSmallCase')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{
                "* Must contain at least 1 Letter in Small Case!"
              }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.invalid &&
              userForm.get('password').hasError('hasSpecialCharacters')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "* Must contain at least 1 Special Character!" }}</span>
            </div>
          </mat-error>
        </div>
      </div>
      <div class="field flex column mx-auto w-1/5">
        <mat-label class="field-label">Confirm Password</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            matInput
            [type]="hideConfirmPassword ? 'password' : 'text'"
            formControlName="confirmPassword"
            autocomplete="off"
          />
          <mat-icon
            class="password-visibility"
            matSuffix
            (click)="hideConfirmPassword = !hideConfirmPassword"
            [svgIcon]="
              hideConfirmPassword ? 'mat:visibility_off' : 'mat:visibility'
            "
          ></mat-icon>
          <mat-error
            *ngIf="
              userForm.invalid &&
              userForm.get('confirmPassword').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.invalid &&
              userForm.get('confirmPassword').hasError('NoPassswordMatch')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{
                "* password and confirm password does not match"
              }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <div class="flex justify-center">
      <button
        color="primary"
        mat-flat-button
        class="mt-4"
        type="button"
        (click)="resetPasswordClick()"
        [disabled]="!userForm.valid"
        *ngIf="!isSignInEnabled"
      >
        Reset Your Password
      </button>
      <button
        color="primary"
        mat-flat-button
        class="mt-4"
        type="button"
        *ngIf="isSignInEnabled"
        (click)="goToLogin()"
      >
        Sign In
      </button>
    </div>
    <app-dismiss-alert
      *ngIf="successOrErrorMessage"
      [message]="successOrErrorMessage"
      [isError]="isError"
    ></app-dismiss-alert>
  </div>
</div>
