<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <ngx-avatars class="h-auto w-auto mr-2" size="40" [src]="userImage()?.picture ? userImage()?.picture : ''"
        [name]="currentUser?.fullName" [textSizeRatio]="2" [round]="true" [initialsSize]="2"></ngx-avatars>
      <div>
        <div class="dropdown-heading block">{{currentUser?.fullName}}</div>
        <div class="text-sm text-muted">{{currentUser?.roles[0]}}</div>
      </div>
    </div>
  </div>

  <div class="dropdown-content">
    <a (click)="close()" *ngFor="let item of items; trackBy: trackById" [routerLink]="item.route"
      class="notification flex items-center" matRipple>
      <mat-icon [svgIcon]="item.icon" [ngClass]="item.colorClass" class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <div class="notification-label">{{ item.label }}</div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
  </div>

  <div class="dropdown-footer flex items-center justify-between">
    <button (click)="logout()" color="primary" mat-button type="button">
      <mat-icon svgIcon="mat:logout" class="text-primary font-medium"></mat-icon> Logout
    </button>
  </div>
</div>