import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-msds',
  templateUrl: './msds.component.html',
  styleUrls: ['./msds.component.scss']
})
export class MsdsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
