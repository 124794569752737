import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/backend/services/user.service';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import { ActivatedRoute } from '@angular/router';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
@Component({
  selector: 'app-view-category-details',
  templateUrl: './view-category-details.component.html',
  styleUrls: ['./view-category-details.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms],
})
export class ViewCategoryDetailsComponent implements OnInit {
  @Input() page!: string;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private userService: UserService,
    private departmentService: DepartmentsService,
    private feedBackService: AsyncFeedbackService,
    private machineEquipmentService: MachineEquipmentService,
    private route: ActivatedRoute
  ) {}
  equipmentForm: FormGroup;
  public data: any;
  selectedImage: any;
  departments: any[];
  currentQueryParams: any;
  showMoreFlag: boolean = false;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  @ViewChild('equipmentCreateUpdateModal')
  equipmentCreateUpdateModal!: TemplateRef<any>;

  ngOnInit(): void {
    this.getCompanyDepartments();
    this.getCategoryDetails();
    this.currentQueryParams = this.route.snapshot.queryParams;
  }
  closeDialog() {
    this.dialog.closeAll();
  }
  getCategoryDetails() {
    this.sectionState = SectionStateStatus.Loading;
    const { id } = this.route.snapshot.params;
    this.machineEquipmentService.getCategoryDetails(id).subscribe({
      next: (res) => {
        this.data = res;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );

        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  showMore() {
    this.showMoreFlag = true;
  }
  getListOfProps(array: [], prop, id?) {
    let newArray = [];
    array?.map((element) => {
      newArray.push(element[prop]);
    });
    return newArray.join(',');
  }
  convertToLocalTime(utcTimeString) {
    let utcDate = new Date();
    if (utcTimeString <= 9) {
      utcDate = new Date(`2000-01-01T0${utcTimeString.toString()}:00:00.000Z`);
    } else {
      utcDate = new Date(`2000-01-01T${utcTimeString.toString()}:00:00.000Z`);
    }
    const localTimeString = utcDate.toLocaleTimeString([], { hour12: false });
    if (localTimeString.length == 10) {
      return `0${localTimeString.slice(0, 4)}`;
    } else {
      return localTimeString.slice(0, 5);
    }
  }
  getCompanyDepartments(): void {
    this.departmentService.getCompanyDepartments().subscribe({
      next: (response) => {
        this.departments = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
}
