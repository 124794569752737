import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'app-mobile-card-id',
  templateUrl: './mobile-card-id.component.html',
  styleUrls: ['./mobile-card-id.component.scss'],
})
export class MobileCardIdComponent implements OnInit, OnChanges {
  @Input() userDetails: any;
  @Input() cardDetails: any;

  @Output() onInvokeCredential: EventEmitter<string> = new EventEmitter();

  public imageUrl: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userDetails']?.currentValue) {
      const base64String = changes['userDetails'].currentValue.photo;
      this.imageUrl = `data:image/jpeg;base64,${base64String}`;
    }
  }

  revokeCard() {
    this.onInvokeCredential.emit(this.cardDetails?.id);
  }
}
