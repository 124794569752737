
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { SwitchAccountComponent } from './components/switch-account/switch-account.component';
import { BulkUploadUserPageComponent } from './pages/bulk-upload-user-page/bulk-upload-user-page.component';
import { RolePageComponent } from './pages/role-page/role-page.component';
import { RoleViewPageComponent } from './pages/role-view-page/role-view-page.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { UserProfileViewPageComponent } from './pages/user-profile-view-page/user-profile-view-page.component';
import { UserViewPageComponent } from './pages/user-view-page/user-view-page.component';
import { NewRoleComponent } from './components/new-role/new-role.component';

const routes: Routes = [
  {
    path: SharedConstants.USERS,
    component: UserPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.USER_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'ManageUsers',
      },
    }
  },
  {
    path: SharedConstants.VIEW_USER + '/:id',
    component: UserViewPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.USER_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true
      },
    }
  },
  {
    path: SharedConstants.VIEW_PROFILE + '/:id',
    component: UserProfileViewPageComponent,
    data: {
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'UserProfile',
        isActive: true
      },
    }
  },
  {
    path: SharedConstants.ROLES,
    component: RolePageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ROLE_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'ManageRoles',
      },
    }
  },
  {
    path: SharedConstants.NEW_ROLE,
    component: NewRoleComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ROLE_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'CreateNew',
        isActive: true
      },
    }
  },
  {
    path: SharedConstants.VIEW_ROLE + '/:id',
    component: RoleViewPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.ROLE_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true
      },
    }
  },
  {
    path: SharedConstants.SWITCH_ACCOUNT,
    component: SwitchAccountComponent,
    // canActivate: [PermissionGuard],
    data: {
      // permissions: ApplicationPermission.ROLE_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'SwitchAccount',
        isActive: true
      },
    }
  },
  {
    path: SharedConstants.BULK_UPLOAD,
    component: BulkUploadUserPageComponent,
    // canActivate: [PermissionGuard],
    data: {
      // permissions: ApplicationPermission.ROLE_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'BulkUploadUser',
        isActive: true
      },
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
