<div class="card p-6 relative 
 hover:shadow-lg trans-shadow flex flex-col items-center">
  <div [ngClass]="iconClass" class="rounded-full w-12 h-12 flex items-center justify-center">
    <mat-icon [svgIcon]="icon"></mat-icon>
  </div>

  <h2 class="display-2 font-bold mt-4 mb-2">{{ value }}</h2>
  <h3 class="subheading-2 font-medium text-secondary m-0">{{ label }}</h3>

  <h4 [class.text-green]="change > 0"
      [class.text-red]="change <= 0"
      class="subheading-1 font-medium mt-4 mb-0 flex items-center">
    <mat-icon *ngIf="change > 0" class="icon-lg text-lg" svgIcon="mat:arrow_drop_up"></mat-icon>
    <mat-icon *ngIf="change <= 0" class="icon-lg text-lg" svgIcon="mat:arrow_drop_down"></mat-icon>
    <!-- <span>{{ change }}%</span> -->
  </h4>
</div>
