import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { AddDashboardTargetComponent } from './add-dashboard-target/add-dashboard-target.component';
import { DashboardTargetComponent } from './dashboard-target/dashboard-target.component';
import { EditDashboardTargetComponent } from './edit-dashboard-target/edit-dashboard-target.component';
import { AdvanceAnalyticsComponent } from './pages/advance-analytics/advance-analytics.component';
import { DashboardDetailComponent } from './pages/dashboard-detail/dashboard-detail.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardSettingsComponent } from './pages/dashboard-settings/dashboard-settings.component';
import { DefineSafetyTriangleLevelsComponent } from './components/define-safety-triangle-levels/define-safety-triangle-levels.component';
import { SafemanHoursComponent } from '../dashboard-two/components/safeman-hours-settings/safeman-hours.component';
import { SafetyKpiSetupComponent } from './pages/safety-kpi-setup/safety-kpi-setup.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    // canActivate: [PermissionGuard],
    data: {
      //  permissions: PredefinedPermission.VOILATIONS_INFO_PERMISSION,
      permissions: ApplicationPermission.REPORTING_DASHBOARD_VIEW,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Dashboard',
      },
    },
  },
  {
    path: SharedConstants.DASHBOARD_DETAILS_PATH,
    component: DashboardDetailComponent,
    // canActivate: [PermissionGuard],
    data: {
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.ADVANCE_ANALYTICS,
    component: AdvanceAnalyticsComponent,
  },
  {
    canActivate: [PermissionGuard],
    path: SharedConstants.DASHBOARD_TARGET,
    component: DashboardSettingsComponent,
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_VIEW,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'dashboardTarget',
      },
    },
  },
  {
    path:
      SharedConstants.DASHBOARD_TARGET +
      '/' +
      SharedConstants.ADD_DASHBOARD_TARGET,
    component: AddDashboardTargetComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Add',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.DASHBOARD_TARGET +
      '/' +
      SharedConstants.DEFINE_SAFETY_TRIANGLE,
    component: DefineSafetyTriangleLevelsComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.DASHBOARD_TARGET + '/' + SharedConstants.SAFEMAN_HOURS+
      '/:id',
    component: SafemanHoursComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.DASHBOARD_TARGET +
      '/' +
      SharedConstants.VIEW_DASHBOARD_TARGET +
      '/:id' +
      '/:mode',
    component: EditDashboardTargetComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.DASHBOARD_TARGET +
      '/' +
      SharedConstants.SAFETY_KPI_SETUP +
      '/:id' +
      '/:type',
    component: SafetyKpiSetupComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
