import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { User } from 'src/app/reporting/models/user';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { ShiftsService } from 'src/backend/services/shifts/shifts.service';
import { UserService } from 'src/backend/services/user.service';
import { Shift } from '../../models/shift';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import { MatSelectChange } from '@angular/material/select';
import {
  ChecklistType,
  DaysOfWeek,
  InspectionFrequencyTypes,
} from 'src/@hodhod/common/enum';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@UntilDestroy()
@Component({
  selector: 'app-add-update-equipment-category',
  templateUrl: './add-update-equipment-category.component.html',
  styleUrls: ['./add-update-equipment-category.component.scss'],
})
export class AddUpdateEquipmentCategoryComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  formMode: 'create' | 'update' = 'create';

  equipmentCategoryForm: FormGroup;

  public equipmentCategory: any;
  public reviewers: User[] = [];
  public inspectors: User[] = [];
  public shifts: Shift[] = [];
  checklists: any[] = [];
  images: any[] = [];
  selectedImg: any = null;
  categoryImageFile: any;
  categoryImage: any;

  private translationsList: any = {};
  InspectionFrequencyTypes = InspectionFrequencyTypes;
  days = DaysOfWeek;
  weeksNumber = [1, 2, 3, 4];
  tomorrowDate: any = null;

  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  hasCreateChecklistPermissions = ApplicationPermission.AUDIT_CHECKLIST_WRITE;
  hasCreateShiftsPermission = ApplicationPermission.SHIFT_CREATE;

  constructor(
    private userService: UserService,
    private shiftService: ShiftsService,
    private checklistService: ChecklistSectionService,
    private feedBackService: AsyncFeedbackService,
    private fb: FormBuilder,
    private machineService: MachineEquipmentService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddUpdateEquipmentCategoryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { formMode: any; equipmentCategoryId: string }
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.formMode = this.data.formMode;
    const currentDate = new Date();
    currentDate.setDate(new Date().getDate() + 1);
    this.tomorrowDate = currentDate.toISOString();

    this.equipmentCategoryForm = this.fb.group({
      id: [''],
      name: ['', Validators.required],
      inspectionStartDate: ['', Validators.required],
      machinesLocation: [''],
      code: ['', [Validators.required, noWhitespaceValidator]],
      equipmentQuantity: ['', [Validators.required, Validators.max(1000)]],
      InspectionChecklistsIds: ['', Validators.required],
      reviewersIds: ['', Validators.required], // category in charge
      InspectorsIds: ['', Validators.required], // inspectors
      inspectionFrequency: ['', Validators.required],
      inspectionResetDay: [''],
      inspectionResetWeek: [''],
      shiftIds: [''],
      inspectionResetHour: [''],
      categoryDescription: [''],
      attachments: [[]],
      categoryImage: [],
    });

    this.getReviewers();
    this.getInspectors();
    this.getActiveShifts();
    this.getChecklistList();
    if (this.formMode == 'update') {
      this.getEquipmentCategoryDetails();
      this.equipmentCategoryForm.get('equipmentQuantity').disable();
    }
  }

  onChangeStartDate(event) {
    event.target.min = this.tomorrowDate;
    if (
      new Date(event.value.toDateString()) >=
      new Date(new Date(this.tomorrowDate).setHours(0, 0, 0, 0))
    ) {
      this.equipmentCategoryForm.get('inspectionStartDate').setErrors(null);
      this.equipmentCategoryForm
        .get('inspectionStartDate')
        .updateValueAndValidity();
    } else {
      this.equipmentCategoryForm
        .get('inspectionStartDate')
        .setErrors({ invalidDate: true });
    }
  }

  resetStartDate() {
    if (this.formMode === 'update') {
      this.equipmentCategoryForm.get('inspectionStartDate').setValue('');
      this.equipmentCategoryForm
        .get('inspectionStartDate')
        .updateValueAndValidity();
    }
  }

  setUpdateForm() {
    this.equipmentCategoryForm.get('id').setValue(this.equipmentCategory.id);
    this.equipmentCategoryForm
      .get('name')
      .setValue(this.equipmentCategory.name);
    this.equipmentCategoryForm
      .get('inspectionStartDate')
      .setValue(this.equipmentCategory.inspectionStartDate);
    this.equipmentCategoryForm
      .get('machinesLocation')
      .setValue(this.equipmentCategory.machinesLocation);
    this.equipmentCategoryForm
      .get('code')
      .setValue(this.equipmentCategory.code);
    this.equipmentCategoryForm
      .get('equipmentQuantity')
      .setValue(this.equipmentCategory.equipmentQuantity);
    this.equipmentCategoryForm
      .get('InspectionChecklistsIds')
      .setValue(
        this.equipmentCategory.inspectionChecklists.map((el) => el.checkListId)
      );
    this.equipmentCategoryForm
      .get('reviewersIds')
      .setValue(this.equipmentCategory.reviewers.map((el) => el.reviewerId));
    this.equipmentCategoryForm
      .get('InspectorsIds')
      .setValue(this.equipmentCategory.inspectors.map((el) => el.inspectorId));
    this.equipmentCategoryForm
      .get('inspectionFrequency')
      .setValue(this.equipmentCategory.inspectionFrequency);
    this.equipmentCategoryForm
      .get('inspectionResetDay')
      .setValue(this.equipmentCategory.inspectionResetDay);
    this.equipmentCategoryForm
      .get('inspectionResetWeek')
      .setValue(this.equipmentCategory.inspectionResetWeek);
    this.equipmentCategoryForm
      .get('shiftIds')
      .setValue(this.equipmentCategory.shifts.map((el) => el.id));
    this.equipmentCategoryForm
      .get('inspectionResetHour')
      .setValue(
        this.equipmentCategory.inspectionResetHour
          ? this.convertToLocalTime(this.equipmentCategory.inspectionResetHour)
          : ''
      );
    this.equipmentCategoryForm
      .get('categoryDescription')
      .setValue(this.equipmentCategory.categoryDescription);

    this.categoryImage = this.equipmentCategory?.imageUrl;
  }

  getEquipmentCategoryDetails() {
    this.sectionState = SectionStateStatus.Loading;
    this.machineService
      .getEquipmentCategoryDetails(this.data.equipmentCategoryId)
      .subscribe({
        next: (response) => {
          this.equipmentCategory = response;
          this.images = response?.equipmentCategoryAttachments;
          this.setUpdateForm();
          this.sectionState = SectionStateStatus.Ready;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  getReviewers(): void {
    this.machineService.getReviewers().subscribe({
      next: (response) => {
        this.reviewers = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getInspectors(): void {
    this.machineService.getInspectors().subscribe({
      next: (response) => {
        this.inspectors = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getActiveShifts(): void {
    this.shiftService.getActiveShifts().subscribe({
      next: (response) => {
        this.shifts = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getChecklistList() {
    this.checklistService
      .getAllCheckListForDropdown(ChecklistType.Inspection)
      .subscribe((res) => {
        this.checklists = res;
      });
  }

  addNewCategory(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const attachment = this.equipmentCategoryForm.value.attachments;
          const fileSize = attachment.reduce((accumulator, object) => {
            return accumulator + object.size;
          }, 0);
          const sizeInMB = fileSize / 1024 / 1024;
          if (sizeInMB <= SharedConstants.EQUIPMENT_CATEGORY_FILE_SIZE) {
            const { attachments, categoryImage, ...data } =
              this.equipmentCategoryForm.value;
            const formData = new FormData();
            data.inspectionResetHour = data.inspectionResetHour
              ? this.convertToUTC(data.inspectionResetHour)
              : '';
            const files = this.equipmentCategoryForm.get('attachments').value;
            files.forEach((element) => {
              formData.append('files', element);
            });
            formData.append('categoryImage', this.categoryImageFile);
            formData.append('content', JSON.stringify(data));
            this.sectionState = SectionStateStatus.LoadingTransparent;
            this.machineService.createEquipmentCategory(formData).subscribe({
              next: (res) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.dialogRef.close({ created: true });
                this.sectionState = SectionStateStatus.Ready;
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
          } else {
            this.feedBackService.showFeedback(
              new FeedbackModel(
                FeedbackType.Warning,
                'maximum file size allowed 10Mb'
              )
            );
          }
        }
      });
  }

  updateEquipmentCategory(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const attachment = this.equipmentCategoryForm.value.attachments;
          const fileSize = attachment.reduce((accumulator, object) => {
            return accumulator + object.size;
          }, 0);
          const sizeInMB = fileSize / 1024 / 1024;
          if (sizeInMB <= SharedConstants.EQUIPMENT_CATEGORY_FILE_SIZE) {
            this.sectionState = SectionStateStatus.LoadingTransparent;
            const { attachments, ...data } = this.equipmentCategoryForm.value;
            data.inspectionResetHour = data.inspectionResetHour
              ? this.convertToUTC(data.inspectionResetHour)
              : '';

            const formData = new FormData();
            const files = this.equipmentCategoryForm.get('attachments').value;
            files.forEach((element) => {
              formData.append('files', element);
            });
            if (this.categoryImageFile) {
              formData.append('categoryImage', this.categoryImageFile);
            }
            formData.append('content', JSON.stringify(data));
            this.machineService
              .updateEquipmentCategoryDetails(formData)
              .subscribe({
                next: (response) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Success, response?.message)
                  );
                  this.closeDialog();
                  this.dialogRef.close({ updated: true });
                  this.sectionState = SectionStateStatus.Ready;
                },
                error: ({ error }) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Failure, error?.message)
                  );
                  this.sectionState = SectionStateStatus.Ready;
                },
              });
          } else {
            this.feedBackService.showFeedback(
              new FeedbackModel(
                FeedbackType.Warning,
                'maximum file size allowed 10Mb'
              )
            );
          }
        }
      });
  }

  deleteImage(img?: any): void {
    this.selectedImg = img;
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.machineService
            .deleteEquipmentCategoryAttachment(this.selectedImg.id)
            .subscribe({
              next: (response) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, response?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
                this.images = this.images.filter(
                  (x) => x.id !== this.selectedImg.id
                );
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
        }
      });
  }

  convertToUTC(timeString) {
    const localDate = new Date(`2000-01-01 ${timeString}`);
    const utcTime = localDate.toISOString();
    return Number(utcTime.slice(11, 13));
  }

  convertToLocalTime(utcTimeString) {
    let utcDate = new Date();
    if (utcTimeString <= 9) {
      utcDate = new Date(`2000-01-01T0${utcTimeString.toString()}:00:00.000Z`);
    } else {
      utcDate = new Date(`2000-01-01T${utcTimeString.toString()}:00:00.000Z`);
    }
    const localTimeString = utcDate.toLocaleTimeString([], { hour12: false });
    if (localTimeString.length == 10) {
      return `0${localTimeString.slice(0, 4)}`;
    } else {
      return localTimeString.slice(0, 5);
    }
  }

  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }

  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  openBigView(id: number): void {
    const img = this.images.find((x) => x.id === id);
    const downloadTag = document.createElement('a');
    downloadTag.href = img.attachment;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  onChangeFrequency(event: MatSelectChange) {
    if (event.value === this.InspectionFrequencyTypes.Shift) {
      this.equipmentCategoryForm.controls['shiftIds'].setValidators([
        Validators.required,
      ]);
      this.equipmentCategoryForm.controls[
        'inspectionResetHour'
      ].clearValidators();
      this.equipmentCategoryForm.controls[
        'inspectionResetWeek'
      ].clearValidators();
      this.equipmentCategoryForm.controls[
        'inspectionResetDay'
      ].clearValidators();
      this.equipmentCategoryForm.controls['inspectionResetHour'].setValue('');
      this.equipmentCategoryForm.controls['inspectionResetWeek'].setValue('');
      this.equipmentCategoryForm.controls['inspectionResetDay'].setValue('');
    } else if (event.value === this.InspectionFrequencyTypes.Daily) {
      this.equipmentCategoryForm.controls['inspectionResetHour'].setValidators([
        Validators.required,
      ]);
      this.equipmentCategoryForm.controls['shiftIds'].clearValidators();
      this.equipmentCategoryForm.controls[
        'inspectionResetWeek'
      ].clearValidators();
      this.equipmentCategoryForm.controls[
        'inspectionResetDay'
      ].clearValidators();
      this.equipmentCategoryForm.controls['shiftIds'].setValue('');
      this.equipmentCategoryForm.controls['inspectionResetWeek'].setValue('');
      this.equipmentCategoryForm.controls['inspectionResetDay'].setValue('');
    } else if (event.value === this.InspectionFrequencyTypes.Weekly) {
      this.equipmentCategoryForm.controls['inspectionResetDay'].setValidators([
        Validators.required,
      ]);
      this.equipmentCategoryForm.controls['shiftIds'].clearValidators();

      this.equipmentCategoryForm.controls[
        'inspectionResetHour'
      ].clearValidators();
      this.equipmentCategoryForm.controls[
        'inspectionResetWeek'
      ].clearValidators();
      this.equipmentCategoryForm.controls['shiftIds'].setValue('');
      this.equipmentCategoryForm.controls['inspectionResetHour'].setValue('');
      this.equipmentCategoryForm.controls['inspectionResetWeek'].setValue('');
    } else if (event.value === this.InspectionFrequencyTypes.Monthly) {
      this.equipmentCategoryForm.controls['inspectionResetWeek'].setValidators([
        Validators.required,
      ]);
      this.equipmentCategoryForm.controls['inspectionResetDay'].setValidators([
        Validators.required,
      ]);
      this.equipmentCategoryForm.controls['shiftIds'].clearValidators();
      this.equipmentCategoryForm.controls[
        'inspectionResetHour'
      ].clearValidators();
      this.equipmentCategoryForm.controls['shiftIds'].setValue('');
      this.equipmentCategoryForm.controls['inspectionResetHour'].setValue('');
    } else {
      this.equipmentCategoryForm.controls['shiftIds'].clearValidators();
      this.equipmentCategoryForm.controls[
        'inspectionResetHour'
      ].clearValidators();
      this.equipmentCategoryForm.controls[
        'inspectionResetWeek'
      ].clearValidators();
      this.equipmentCategoryForm.controls[
        'inspectionResetDay'
      ].clearValidators();
      this.equipmentCategoryForm.controls['shiftIds'].setValue('');
      this.equipmentCategoryForm.controls['inspectionResetHour'].setValue('');
      this.equipmentCategoryForm.controls['inspectionResetWeek'].setValue('');
      this.equipmentCategoryForm.controls['inspectionResetDay'].setValue('');
    }
    this.resetStartDate();
    this.equipmentCategoryForm.updateValueAndValidity();
  }

  onFileSelected(event: any) {
    const files: FileList | null = event.target.files;
    if (files && files.length > 0) {
      this.categoryImageFile = files[0];
      const file: File = files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (this.equipmentCategoryForm.get('categoryImage').value) {
          this.categoryImage = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (
      event.key === '-' ||
      event.key === '.' ||
      (!event.key.match(/^[0-9]+$/) &&
        event.key !== 'Backspace' &&
        event.key !== 'Delete' &&
        event.key !== 'ArrowLeft' &&
        event.key !== 'ArrowRight' &&
        event.key !== 'Home' &&
        event.key !== 'End')
    ) {
      event.preventDefault();
    }
  }

  onWheel(event: MouseEvent) {
    event.preventDefault();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
