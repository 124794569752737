import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { UserService } from 'src/backend/services/user.service';
import { User } from '../../models/user';
import { UserDetails } from '../../models/user-details';

@Component({
  selector: 'app-user-profile-view-page',
  templateUrl: './user-profile-view-page.component.html',
  styleUrls: ['./user-profile-view-page.component.scss']
})
export class UserProfileViewPageComponent implements OnInit {
  profileData: UserDetails;
  profileId: string;
  private destroy$ = new Subject();
  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;   
        this.profileId = id;
      }
      this.getProfileData();
    });
  }
  getProfileData(): void{
    this.loadingService.startLoading(true,  '');
    this.userService.getUserDetails(this.profileId).subscribe({
      next: (response) => {
        this.profileData = response;
        this.loadingService.stopLoading();
      },
      error: ({error}) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      }
    })
  }

}
