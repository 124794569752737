import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';

@Component({
  selector: 'app-user-details-card',
  templateUrl: './user-details-card.component.html',
  styleUrls: ['./user-details-card.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    scaleFadeIn400ms,
    scaleFadeIn400ms,
    scaleIn400ms,
    fadeInRight400ms,
  ],
})
export class UserDetailsCardComponent implements OnInit, OnChanges {
  @Input() userDetails: any = null;

  public imageUrl: string;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userDetails'].currentValue) {
      const base64String = changes['userDetails'].currentValue.photo;
      this.imageUrl = `data:image/jpeg;base64,${base64String}`; // Adjust the MIME type if necessary
    }
  }

  ngOnInit(): void {}
}
