import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { TaskTrackerRoutingModule } from './task-tracker-routing.module';
import { InspectionPlanPageComponent } from './pages/inspection-plan-page/inspection-plan-page.component';
import { InspectionPlanComponent } from './components/inspection-plan/inspection-plan.component';
import { SharedModule } from '../shared/shared.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarEditComponent } from './components/calendar-edit/calendar-edit.component';
import { AddInspectionPlanComponent } from './components/add-inspection-plan/add-inspection-plan.component';
import { TaskTrackerListComponent } from './components/task-tracker-list/task-tracker-list.component';
import { TaskTrackerComponent } from './pages/inspection-plan-page/task-tracker/task-tracker.component';
import { NgxGaugeModule } from 'ngx-gauge';
import { ViewTaskTrackerPageComponent } from './pages/view-task-tracker-page/view-task-tracker-page/view-task-tracker-page.component';
import { ActionTrackerModule } from '../action-tracker/action-tracker.module';
import { ExecuteChecklistPageComponent } from './pages/execute-checklist-page/execute-checklist-page/execute-checklist-page.component';
import { UpdateSingleTaskComponent } from './components/update-single-task/update-single-task.component';
import { RescheduleTaskPopupComponent } from './components/reschedule-task-popup/reschedule-task-popup.component';
import { AuditPlanCalendarComponent } from './components/audit-plan-calendar/audit-plan-calendar.component';
import { AuditItemComponent } from './components/audit-item/audit-item.component';
@NgModule({
  declarations: [
    InspectionPlanPageComponent,
    InspectionPlanComponent,
    CalendarEditComponent,
    AddInspectionPlanComponent,
    TaskTrackerListComponent,
    TaskTrackerComponent,
    ViewTaskTrackerPageComponent,
    ExecuteChecklistPageComponent,
    UpdateSingleTaskComponent,
    RescheduleTaskPopupComponent,
    AuditPlanCalendarComponent,
    AuditItemComponent,
  ],
  imports: [
    CommonModule,
    TaskTrackerRoutingModule,
    SharedModule,
    ActionTrackerModule,
    NgxGaugeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  providers: [DatePipe],
})
export class TaskTrackerModule {}
