<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.dashboardTarget' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <div class="card overflow-auto">
        <div
          class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 m-3"
          role="alert"
        >
          <p class="font-bold">
            {{ "SafetyKpiSetup." + type + "Title" | translate }}
          </p>
          <p>
            {{ "SafetyKpiSetup." + type + "Note" | translate }}
          </p>
          <p class="font-medium">
            {{ "SafetyKpiSetup." + type + "Equation" | translate }}
          </p>
        </div>
        <div class="p-4">
          <div class="flex justify-between">
            <h2 class="text-xl font-semibold p-1 pb-2">
              {{ "SafetyKpiSetup." + type | translate }}
            </h2>
            <div>
              <mat-slide-toggle
                (change)="autoOrManual($event)"
                [checked]="isAuto"
              >
                <span>Auto</span>
              </mat-slide-toggle>
            </div>
          </div>

          <form [formGroup]="safetyKpiForm">
            <div class="flex flex-wrap">
              <div class="lg:w-6/12 md:w-6/12 sm:w-full" *ngIf="!isAuto">
                <mat-label class="field-label">
                  {{ "SafetyKpiSetup." + type + "Label" | translate }}
                  <span
                    [ngClass]="
                      safetyKpiForm.invalid &&
                      safetyKpiForm.get('manualValue').hasError('required')
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    >*</span
                  >
                </mat-label>
                <mat-form-field class="input-field" appearance="outline">
                  <input
                    type="number"
                    min="0"
                    [placeholder]="'Placeholder.Insert' | translate"
                    formControlName="manualValue"
                    matInput
                  />
                  <mat-error
                    *ngIf="
                      safetyKpiForm.get('manualValue').hasError('required')
                    "
                  >
                    <div class="flex justify-flex-start align-flex-center p-0">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>{{ "Errors.Required" | translate }}</span>
                    </div>
                  </mat-error>
                  <mat-error
                    *ngIf="safetyKpiForm.get('manualValue').hasError('min')"
                  >
                    <div class="flex justify-flex-start align-flex-center p-0">
                      <img
                        class="error-img"
                        src="assets/img/resources/error-icon.svg"
                      />
                      <span>{{ "Errors.NotAPositiveNumber" | translate }}</span>
                    </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
          <div class="flex justify-end items-center mt-4">
            <app-basic-button
              [btnType]="'Cancel'"
              [btnText]="'Cancel'"
              (onClick)="navigateBack()"
            ></app-basic-button>
            <app-basic-button
              [btnType]="'Add'"
              [btnText]="'Save'"
              [isDisabled]="safetyKpiForm.invalid"
              (onClick)="submitForm()"
            ></app-basic-button>
          </div>
        </div>
      </div>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>
