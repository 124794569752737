<div class="card overflow-auto">
  <!-- <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
      <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
        <span>History</span>
      </h2>
    </div> -->
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table
      body
      @stagger
      [dataSource]="dataSource"
      class="w-full"
      mat-table
      matSort
    >
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "RenewIdCard.View.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] }}
            <span
              *ngIf="column.property === 'createdBy' && row?.isDefault"
              class="bg-primary text-white rounded-full p-1 font-normal px-3"
              >Default</span
            >
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "RenewIdCard.View.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <span [attr.id]="row[column.property].toUpperCase()">{{
              "Enum.ReportNearMissStatus." + row[column.property].toUpperCase()
                | translate
            }}</span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "RenewIdCard.View.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
      ></tr>
    </table>
    <div
      *ngIf="dataSource?.filteredData?.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [class.hidden]="dataSource.filteredData.length === 0"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>
