<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "TaskTracker.Audits" | translate }}</span>
    </h2>
    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'General.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>

    <button
      *ngIf="hasCreatePermissions | permissionChecker"
      (click)="openCreateModal()"
      class="ml-4 flex-none"
      color="primary"
      mat-mini-fab
      [matTooltip]="'General.Buttons.AddNew' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>

  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->

      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="
            column.type === 'text' &&
            column.property !== 'recurrenceType' &&
            column.property !== 'duration' &&
            column.property !== 'departmentParentName'
          "
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "TaskTracker.Table." + column.label | translate }} &nbsp;
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>

        <ng-container
          *ngIf="
            column.type === 'text' && column.property === 'departmentParentName'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div class="flex items-center">
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="selectDep.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "TaskTracker.Table." + column.label | translate }} &nbsp;
              <mat-select
                [value]="selectedDepartments"
                (selectionChange)="onSelectDepartments($event)"
                #selectDep
                class="select-options w-3/12"
                multiple
              >
                <mat-option
                  *ngFor="let dep of allDepartments"
                  [value]="dep?.id"
                >
                  {{ dep?.name }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.type === 'date' && column.property !== 'recurrenceType'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "TaskTracker.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "dd-MM-yyyy" }}
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.type === 'text' && column.property === 'duration'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "TaskTracker.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] }}

            {{
              row[column.property]
                ? getNumber(row[column.property]) > 1
                  ? ("TaskTracker.Days" | translate)
                  : ("TaskTracker.Day" | translate)
                : ""
            }}
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.property === 'recurrenceType'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "TaskTracker.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ "Enum.TaskRepeated." + row[column.property] | translate }}
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "User.Table." + column.label | translate }}
              <mat-select
                (selectionChange)="onSelectStatus($event)"
                #select
                class="select-options"
                multiple
                [(ngModel)]="selectedStatus"
              >
                <mat-option
                  *ngFor="let statue of TasksStatus | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.TaskStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <span>{{
                "Enum.TaskStatus." + row[column.property] | translate
              }}</span>
            </div>
          </td>
        </ng-container>
        <!-- Actions -->
        <ng-container
          stickyEnd
          *ngIf="column.type === 'button'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef mat-header-cell></th>

          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div (click)="$event.stopPropagation()">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon svgIcon="mat:more_vert"></mat-icon>
              </button>
              <mat-menu
                yPosition="below"
                xPosition="before"
                #menu="matMenu"
                class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
              >
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  (click)="viewTaskTracker(row?.id)"
                >
                  <button>
                    {{ "General.Buttons.View" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
                <div
                  mat-menu-item
                  class="flex items-center justify-between p-1 mat-div"
                  *ngIf="
                    row['userId'] === loggedUser?.userId &&
                    (row['status'] === TasksStatus.NEW ||
                      row['status'] === TasksStatus.RESCHEDULE)
                  "
                  (click)="executeCheckList(row?.id)"
                >
                  <button>
                    {{ "General.Buttons.Execute" | translate }}
                  </button>
                  <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
                </div>
              </mat-menu>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewTaskTracker(row?.id)"
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [class.hidden]="dataSource.filteredData.length === 0"
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-icon
      class="text-[#757575] me-1"
      *ngIf="!column.visible"
      svgIcon="mat:check_box_outline_blank"
    ></mat-icon>
    <mat-icon
      *ngIf="column.visible"
      class="text-primary me-1"
      svgIcon="mat:check_box"
    ></mat-icon>
    {{ "TaskTracker.Table." + column.label | translate }}
  </button>
</mat-menu>
