import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailValidator } from 'src/app/shared/custom-validators/form-validators';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { AuthService } from 'src/backend/services/auth.service';

@Component({
  selector: 'app-confirmEmail',
  templateUrl: './confirmEmail.component.html',
  styleUrls: ['./confirmEmail.component.scss']
})
export class ConfirmEmailComponent implements OnInit {

  
  public form = new FormGroup({
    email: new FormControl('', [Validators.required, EmailValidator]),
  });
  public token: any = '';
  successOrErrorMessage: string = '';
  isSignInEnabled: boolean = false;
  isError: boolean = true;
  constructor(private route: ActivatedRoute, private loadingService: LoadingService,
    private authService: AuthService, private router: Router) {
  }
 

  ngOnInit() {
    this.route.queryParams
      .subscribe((params: any) => {        
        this.form.get('email')?.setValue(params?.email)
        this.token = params?.token;      
      }
   );
  }

  confirmEmailClick(): void {
    const data = {
      email: this.form.value.email,
      token: this.token
    };
    this.loadingService.startLoading(true, '');
    this.successOrErrorMessage = '';
    this.isSignInEnabled = false;
    this.isError = true;
    this.authService.confirmEmail(data.email, data.token).subscribe({
      next: (response) => {
        this.successOrErrorMessage = response?.message;
        this.loadingService.stopLoading();
        this.isSignInEnabled = true;
        this.isError = false;
      },
      error: ({error}) => {
        this.successOrErrorMessage = error?.message;
        this.loadingService.stopLoading();
        this.isSignInEnabled = false;
        this.isError = true;
      }
    })
  }

  goToLogin(): void {
    this.router.navigate(['/' + SharedConstants.AUTH + '/' + SharedConstants.SIGN_IN]);
  }
}
