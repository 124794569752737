import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { EmailValidator, noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { Confirmation, ConfirmationType } from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { AuthService } from 'src/backend/services/auth.service';
import { UserDetails } from '../../models/user-details';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class ViewUserComponent implements OnInit, OnChanges {
  @Input() userDetails: UserDetails;
  @ViewChild('emailChangeModal') changeEmailModal!: TemplateRef<any>;

  emailChangeForm: FormGroup;
  public VERIFIED_COLOR = '#67DD2D';
  public UNVERIFIED_COLOR = '#F76F45';
  
  userForm: FormGroup;
  formMode: 'create' | 'update' = 'create';
  public translationsList: any = {};
  public destroy$ = new Subject();
hasUpdateUsersPermission=ApplicationPermission.USER_UPDATE
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private translate: TranslateService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
  ) {
    this.translate.get(['Errors', 'Success','User', 'confirmVerificationCode']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
      this.translationsList = translations;
    });
   }

  ngOnInit() {
    this.userForm = this.fb.group({
      userCode: [''],
      firstName: [''],
      lastName: [''],
      phoneNumber: [''],
      email: [''],
      department: [''],
      reportTo: [''],
      idNumber: ['']
    });
    this.emailChangeForm = this.fb.group({
      email: ['', [Validators.required, noWhitespaceValidator, EmailValidator]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes['userDetails']) {
        if (this.userForm) {
          this.getUserInfo();
        }
      }
  }

  getUserInfo(): void {
    this.userForm.get('userCode')?.setValue(this.userDetails?.userCode);
    this.userForm.get('firstName')?.setValue(this.userDetails?.firstName);
    this.userForm.get('lastName')?.setValue(this.userDetails?.lastName);
    this.userForm.get('phoneNumber')?.setValue(this.userDetails?.phoneNumber);
    this.userForm.get('email')?.setValue(this.userDetails?.email);
    this.userForm.get('department')?.setValue(this.userDetails.departmentName);  
    this.userForm.get('reportTo')?.setValue(this.userDetails.reportTo);  
    this.userForm.get('idNumber')?.setValue(this.userDetails.idNumber);  
    this.sectionState = SectionStateStatus.Ready;      
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  openChangeEmailModal(): void{
    this.emailChangeForm.get('email').setValue('');
    this.dialog.open(this.changeEmailModal, {height: 'auto', width: '30%'});
  }

  changeEmail(){
    this.confirmationService
    .confirm(
      new Confirmation(
        ConfirmationType.NonDestructiveAction,
        this.translationsList['User']['AddConfirm']['Title'],
        this.translationsList['User']['AddConfirm']['Message']
      )
    )
    .then((value) => {
      if (value === true) {
        this.loadingService.startLoading(true, '');
        const data = {
          userId: this.userDetails?.userId,
          email: this.emailChangeForm.value.email
        }
        this.authService.changeUserEmail(data).subscribe({
          next: (response) => {
            this.userDetails.email = response?.email;
            this.userForm.get('email')?.setValue(this.userDetails?.email);
            this.emailChangeForm.get('email').setValue('');
            this.loadingService.stopLoading();
            this.closeDialog();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Success, response?.message)
            );
          },
          error: ({error}) => {
            this.loadingService.stopLoading();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
          }
        })
      }
    });  
  }

  resendVerificationCode(): void {
    this.confirmationService
    .confirm(
      new Confirmation(
        ConfirmationType.NonDestructiveAction,
        this.translationsList['confirmVerificationCode']['Title'],
        this.translationsList['confirmVerificationCode']['Message']
      )
    )
    .then((value) => {
      if (value === true) {
        const email = this.userForm.value.email;
        this.loadingService.startLoading(true, '');
        this.authService.resendVerificationCode(email).subscribe({
          next: (response) => {
            this.loadingService.stopLoading();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Success, response?.message)
            );
          },
          error: ({error}) => {
            this.loadingService.stopLoading();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
          }
        })
      }
    });   
  }
}
