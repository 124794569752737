import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { Shift } from '../../models/shift';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { Constants } from 'src/@hodhod/common/constants';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { GeneralStatus, SortDirection } from 'src/@hodhod/common/enum';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { PermissionService } from 'src/backend/services/permission.service';
import { merge, takeUntil, tap } from 'rxjs';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { ShiftsService } from 'src/backend/services/shifts/shifts.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-shifts-list',
  templateUrl: './shifts-list.component.html',
  styleUrls: ['./shifts-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class ShiftsListComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('shiftCreateUpdateModal')
  shiftCreateUpdateModal!: TemplateRef<any>;
  shiftForm: FormGroup;
  formMode: 'create' | 'update' = 'create';

  shifts: Shift[];
  columns: TableColumn<Shift>[] = [
    {
      label: 'Shift',
      property: 'shiftTitle',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Created At',
      property: 'createdOn',
      type: 'date',
      visible: false,
    },
    {
      label: 'Updated At',
      property: 'updatedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<Shift>([]);
  selection = new SelectionModel<Shift>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';
  shiftId: number = 0;
  isReadonlyMode = false;

  ShiftStatuses = GeneralStatus;
  SharedConstants = SharedConstants;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;
  public selectedShiftStatus: string[] = [];

  private translationsList: any = {};

  hasViewPermissions = ApplicationPermission.SHIFT_VIEW;
  hasWritePermissions = ApplicationPermission.SHIFT_CREATE;
  hasEditPermissions = ApplicationPermission.SHIFT_UPDATE;
  hasActivatePermissions = ApplicationPermission.SHIFT_ACTIVATE;

  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private feedBackService: AsyncFeedbackService,
    private shiftService: ShiftsService,
    private confirmationService: ConfirmationService,
    private permissionService: PermissionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'Role',
        'User',
        'StaticPages',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.shiftForm = this.fb.group({
      shiftTitle: ['', [Validators.required]],
      startTime: ['', [Validators.required]],
      endTime: ['', [Validators.required]],
    });
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedShiftStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  convertToUTC(timeString) {
    const localDate = new Date(`2000-01-01 ${timeString}`);
    const utcTime = localDate.toISOString();
    return utcTime.slice(11, 16);
  }
  convertToLocalTime(utcTimeString = '12:00') {
    const utcDate = new Date(`2000-01-01T${utcTimeString}:00.000Z`);
    const localTimeString = utcDate.toLocaleTimeString([], { hour12: false });
    if (localTimeString.length == 10) {
      return `0${localTimeString.slice(0, 4)}`;
    } else {
      return localTimeString.slice(0, 5);
    }
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      status: this.currentQueryParams?.orgStatus,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.shiftService.getAllShifts(filteredParams).subscribe({
      next: (response) => {
        this.shifts = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  viewShift(shiftId: number) {
    this.isReadonlyMode = true;
    this.resetForm();
    this.shiftId = shiftId;
    this.shiftForm
      .get('shiftTitle')
      ?.setValue(this.shifts.find((x) => x.shiftId === shiftId).shiftTitle);
    this.shiftForm
      .get('startTime')
      ?.setValue(
        this.convertToLocalTime(
          this.shifts.find((x) => x.shiftId === shiftId).startTimeUTC
        )
      );

    this.shiftForm
      .get('endTime')
      ?.setValue(
        this.convertToLocalTime(
          this.shifts.find((x) => x.shiftId === shiftId).endTimeUTC
        )
      );

    this.dialog.open(this.shiftCreateUpdateModal, {
      height: 'auto',
      width: '60%',
      disableClose: true,
    });
  }

  openCreateShiftModal() {
    this.isReadonlyMode = false;
    this.formMode = 'create';
    this.shiftForm.reset();
    this.resetForm();
    this.dialog.open(this.shiftCreateUpdateModal, {
      height: 'auto',
      width: '60%',
      disableClose: true,
    });
  }

  openUpdateShiftModal(shiftId: number) {
    let { startTimeUTC, endTimeUTC } = this.shifts.find(
      (x) => x.shiftId === shiftId
    );
    this.isReadonlyMode = false;
    this.formMode = 'update';
    this.resetForm();
    this.shiftId = shiftId;
    this.shiftForm
      .get('shiftTitle')
      ?.setValue(this.shifts.find((x) => x.shiftId === shiftId).shiftTitle);
    startTimeUTC
      ? this.shiftForm
          .get('startTime')
          ?.setValue(
            this.convertToLocalTime(
              this.shifts.find((x) => x.shiftId === shiftId).startTimeUTC
            )
          )
      : null;
    endTimeUTC
      ? this.shiftForm
          .get('endTime')
          ?.setValue(
            this.convertToLocalTime(
              this.shifts.find((x) => x.shiftId === shiftId).endTimeUTC
            )
          )
      : null;
    this.dialog.open(this.shiftCreateUpdateModal, {
      height: 'auto',
      width: '60%',
      disableClose: true,
    });
  }

  createShift() {
    // const shift = { shiftTitle: this.shiftForm.value.shiftTitle };
    const { shiftTitle, startTime, endTime } = this.shiftForm.value;
    const data = {
      shiftTitle,
      startTimeUTC: this.convertToUTC(startTime),
      endTimeUTC: this.convertToUTC(endTime),
    };
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.Loading;
          const shift = { shiftTitle: this.shiftForm.value.shiftTitle };
          this.shiftService.createShift(data).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  updateShift() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Role']['UpdateConfirm']['Title'],
          this.translationsList['Role']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.Loading;
          const { shiftTitle, startTime, endTime } = this.shiftForm.value;
          const shift = {
            id: this.shiftId,
            shiftTitle: shiftTitle,
            startTimeUTC: this.convertToUTC(startTime),
            endTimeUTC: this.convertToUTC(endTime),
          };
          this.shiftService.updateShift(shift).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  activateShift(shiftId: number) {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.shiftService.activateShift(shiftId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  deactivateShift(shiftId: number) {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.shiftService.deActivateShift(shiftId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onSelectStatus(event) {
    this.selectedShiftStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  resetForm(): void {
    this.shiftForm.get('shiftTitle')?.setValue('');
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
}
