import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BreadcrumbService } from 'src/app/shared/helpers/breadcrumb.service';
import { BreadCrumb } from 'src/app/shared/models/breadcrumb';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { AuthService } from 'src/backend/services/auth.service';
import { trackByValue } from '../../utils/track-by';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'hodhod-breadcrumbs',
  template: `
    <div class="flex items-center">
      <hodhod-breadcrumb>
        <div (click)="goToInitialRoute()">
          <mat-icon svgIcon="mat:home" class="icon-sm"></mat-icon>
        </div>
      </hodhod-breadcrumb>
      <ng-container *ngFor="let breadcrumb of breadcrumbs; last as isLast">
        <div class="w-1 h-1 bg-gray rounded-full ltr:mr-2 rtl:ml-2"></div>
        <hodhod-breadcrumb>
          <a
            *ngIf="!isLast && breadcrumb.path != ''; else lastRoute"
            [routerLink]="[breadcrumb.path.split('?')[0]]"
            [queryParams]="queryParams"
          >
            {{ 'BreadCrumb.' + breadcrumb.labelKey | translate }}
          </a>
          <ng-template #lastRoute>
            <a
              [routerLink]="[]"
              *ngIf="breadcrumb.isValue"
              style="color: rgba(var(--color-primary), 1)"
            >
              {{ breadcrumb.labelKey }}
            </a>
            <a
              [routerLink]="[]"
              *ngIf="!breadcrumb.isValue"
              style="color: rgba(var(--color-primary), 1)"
            >
              {{ 'BreadCrumb.' + breadcrumb.labelKey | translate }}
            </a>
          </ng-template>
        </hodhod-breadcrumb>
      </ng-container>
    </div>
  `,
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  trackByValue = trackByValue;
  SharedConstant = SharedConstants;

  private breadcrumbSubscription: Subscription;
  breadcrumbs: BreadCrumb[] = [];
  showBreadcrumb = true;

  queryParams = {};

  constructor(
    private breadcrumbService: BreadcrumbService,
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    this.breadcrumbSubscription =
      this.breadcrumbService.breadcrumbList$.subscribe(
        (breadcrumbList) => (this.breadcrumbs = breadcrumbList)
      );
    this.showBreadcrumb = this.breadcrumbService.visibility$.value;
    this.breadcrumbService.visibility$.subscribe(
      (value) => (this.showBreadcrumb = value)
    );
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
    if (this.queryParams['editMode'] === 'true') {
      let lastLabelKey=this.breadcrumbs[this.breadcrumbs.length-1]
      lastLabelKey.labelKey='Edit'
    }
  }

  ngOnDestroy(): void {
    if (this.breadcrumbSubscription) {
      this.breadcrumbSubscription.unsubscribe();
    }
  }

  goToInitialRoute(): void {
    this.authService.navigateToInitialMenu();
  }
}
