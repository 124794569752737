import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { SharedConstants } from 'src/app/shared/models/shared-constants';

@Component({
  selector: 'app-reporting-page',
  templateUrl: './reporting-page.component.html',
  styleUrls: ['./reporting-page.component.scss'],
})
export class ReportingPageComponent implements OnInit {
  SharedConstant = SharedConstants;

  public hasHazardViewPermission =
    ApplicationPermission.REPORTING_HAZARDS_ACCESS;
  public hasNearmissViewPermission =
    ApplicationPermission.REPORTING_NEARMISS_ACCESS;
  public hasIncidentViewPermission =
    ApplicationPermission.REPORTING_INCIDENT_ACCESS;

  constructor(private route: Router) {}

  ngOnInit(): void {}
  onHazardClick(event: string): void {
    this.route.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_HAZARD,
    ]);
  }
  onNearMissClick(event: string): void {
    this.route.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_NEAR_MISS,
    ]);
  }

  onIncidentClick(event: string): void {
    this.route.navigate([
      '/' +
        SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_INCIDENT,
    ]);
  }
}
