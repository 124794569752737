<div
  class="container-modal"
  [ngClass]="{
    destructive: confirmData?.type == ConfirmationType.DestructiveAction
  }"
  *ngIf="isVisible"
>
  <div class="popup">
    <mat-icon
      class="close_icon"
      (click)="cancelAlert()"
      svgIcon="mat:close"
    ></mat-icon>
    <div class="indicator bg-primary"></div>
    <div *ngIf="confirmData?.headerTitle" class="title">
      {{ confirmData?.headerTitle }}
    </div>
    <div *ngIf="confirmData?.message" class="message">
      {{ confirmData?.message }}
    </div>
    <div
      *ngIf="confirmData?.checkboxOption"
      class="checkbox-option text-primary"
    >
      <mat-checkbox
        #checkbox
        value="true"
        (change)="checkboxValue = !checkboxValue"
        >{{ confirmData?.checkboxOption }}</mat-checkbox
      >
    </div>
    <div class="actions">
      <div
        class="secondary-action text-primary button"
        (click)="onActionButtonClicked(false, false)"
      >
        {{ confirmData?.cancelActionText }}
      </div>
      <div
        class="primary-action bg-primary button"
        (click)="onActionButtonClicked(true, checkboxValue)"
      >
        {{ confirmData?.mainActionText }}
      </div>
    </div>
  </div>
</div>
