import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedConstants } from '../shared/models/shared-constants';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignInComponent } from './sign-in/sign-in.component';

const routes: Routes = [
  {
    path: '',
    component: SignInComponent
  },
  {
    path: SharedConstants.SIGN_IN,
    component: SignInComponent
  },
  {
    path: SharedConstants.FORGOT_PASSWORD,
    component: ForgotPasswordComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicLayoutRoutingModule { }
