<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="LoadingLabel"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="UpdateSingleTaskForm">
    <div class="flex items-center mb-2" mat-dialog-title>
      <h2
        *ngIf="UpdateSingleTaskForm.get('title').value"
        class="headline m-0 flex-auto"
      >
        {{ UpdateSingleTaskForm.get("title").value }}
      </h2>
      <h2
        *ngIf="!UpdateSingleTaskForm.get('title').value"
        class="headline m-0 flex-auto"
      >
        {{ "InspectionPlan.UpdateTask" | translate }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-5"></mat-divider>

    <mat-dialog-content class="flex flex-row flex-wrap justify-between">
      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "InspectionPlan.TaskName" | translate }}
          <span
            [ngClass]="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('title').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            formControlName="title"
          />
          <mat-error
            *ngIf="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('title').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('title').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Description -->
      <div class="field ml-2 flex column">
        <mat-label class="field-label"
          >{{ "InspectionPlan.Description" | translate }}
          <span
            [ngClass]="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('description').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            formControlName="description"
          />
          <mat-error
            *ngIf="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('description').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('description').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Checklist -->
      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "InspectionPlan.Checklist" | translate }}
          <span
            [ngClass]="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('checklist').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf1="ngxMatSelect"
            [displayMember]="'name'"
            [valueMember]="'id'"
            [source]="checklists"
            [useInfiniteScroll]="true"
            [hasPermission]="
              hasCreateAuditCheckListPermission | permissionChecker
            "
            [title]="'checklists'"
            [hasSearchBox]="false"
            [manageRoute]="'/safety/checklist-section'"
            [placeholder]="'InspectionPlan.Checklist' | translate"
            formControlName="checklist"
          >
            <mat-option *ngFor="let w of msf1.filteredSource" [value]="w.id">{{
              w.name
            }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('checklist').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Machine -->
      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "InspectionPlan.Machine" | translate }}
          <span
            [ngClass]="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('machine').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf3="ngxMatSelect"
            [displayMember]="'machineName'"
            [valueMember]="'id'"
            [source]="machines"
            [useInfiniteScroll]="true"
            [hasPermission]="hasCreateMachinesPermission | permissionChecker"
            [title]="'machines'"
            [hasSearchBox]="false"
            [manageRoute]="'/company-setup/machine-equipment'"
            [placeholder]="'InspectionPlan.Machine' | translate"
            formControlName="machine"
          >
            <mat-option [value]="0">None</mat-option>
            <mat-option *ngFor="let m of msf3.filteredSource" [value]="m?.id">{{
              m?.machineName
            }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('machine').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Department -->
      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "InspectionPlan.Department" | translate }}
          <span
            [ngClass]="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('department').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf2="ngxMatSelect"
            [displayMember]="'departmentName'"
            [valueMember]="'departmentId'"
            [source]="departmentsData"
            [useInfiniteScroll]="true"
            [hasPermission]="hasCreateDepartmentPermission | permissionChecker"
            [title]="'departments'"
            [hasSearchBox]="false"
            [manageRoute]="'/company-setup/departments'"
            formControlName="department"
            [placeholder]="'InspectionPlan.Department' | translate"
            (selectionChange)="onChangeDepartment($event)"
          >
            <mat-option
              *ngIf="msf2.filteredSource.length === 0"
              style="height: 0"
            ></mat-option>
            <mat-optgroup
              *ngFor="let group of msf2.filteredSource"
              [label]="group.parentName"
            >
              <mat-option
                *ngFor="let detail of group.detail"
                [value]="detail.departmentId"
              >
                {{ detail.departmentName }}
              </mat-option>
            </mat-optgroup>
          </mat-select>

          <mat-error
            *ngIf="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('department').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Sub department -->
      <div class="field flex column" *ngIf="subDepartments.length > 0">
        <mat-label class="field-label">{{
          "AddReportHazard.SubDepartment" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            [title]="'departments'"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="subDepartment"
          >
            <mat-option *ngFor="let dep of subDepartments" [value]="dep.id">
              {{ dep.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- User -->
      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "Task.SelectUser" | translate }}
          <span
            [ngClass]="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('user').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field appearance="outline">
          <mat-select
            ngxMatSelect
            #msf4="ngxMatSelect"
            [displayMember]="'fullName'"
            [valueMember]="'userId'"
            [source]="reportUsers"
            [useInfiniteScroll]="true"
            formControlName="user"
            [hasPermission]="hasCreateUserPermission | permissionChecker"
            [title]="'users'"
            [manageRoute]="'/user-management/users'"
            [multiple]="false"
            [placeholder]="'Task.SelectUser' | translate"
          >
            <mat-option
              [value]="item.userId"
              *ngFor="let item of msf4.filteredSource"
            >
              {{ item.fullName }}
            </mat-option>
          </mat-select>

          <mat-error
            *ngIf="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('user').touched &&
              UpdateSingleTaskForm.get('user').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('user').touched &&
              !UpdateSingleTaskForm.get('user').hasError('required') &&
              UpdateSingleTaskForm.get('user').hasError('InvalidSelectedValue')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <span>{{ "Errors.InvalidSelectedValue" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Start Date -->
      <div class="field flex column">
        <mat-label class="field-label"
          >{{ "InspectionPlan.TaskDate" | translate }}
          <span
            [ngClass]="
              UpdateSingleTaskForm.invalid &&
              UpdateSingleTaskForm.get('TaskDate').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field appearance="outline">
          <input
            [placeholder]="'InspectionPlan.TaskDate' | translate"
            [min]="minDate"
            matInput
            formControlName="TaskDate"
            [matDatepicker]="startpicker"
            readonly
          />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matSuffix
            [for]="startpicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startpicker></mat-datepicker>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel' | translate"
          (onClick)="closeDialog()"
        >
        </app-basic-button>

        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'Update' | translate"
          [isDisabled]="!UpdateSingleTaskForm.valid"
          (onClick)="Update()"
        >
        </app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
