<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2 class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none">
      <span *ngIf="selection.isEmpty()">{{ "LostIdCard.title" | translate }}</span>
      <span *ngIf="selection.hasValue()">{{ selection.selected.length }} Users<span
          *ngIf="selection.selected.length > 1">s</span>
        selected</span>
    </h2>

    <div *ngIf="selection.hasValue()" class="mr-4 pr-4 border-r flex-none">
      <button (click)="deleteUsers()" color="primary" mat-icon-button matTooltip="Delete selected" type="button">
        <mat-icon svgIcon="mat:delete"></mat-icon>
      </button>

      <button color="primary" mat-icon-button matTooltip="Another action" type="button">
        <mat-icon svgIcon="mat:folder"></mat-icon>
      </button>
    </div>

    <div class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300">
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input [formControl]="searchCtrl" class="px-4 py-2 border-0 outline-none w-full bg-transparent"
      [placeholder]="'General.Search'|translate" type="search" />
    </div>

    <span class="flex-1"></span>

    <button [matMenuTriggerFor]="columnFilterMenu" class="ml-4 flex-none" mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate" type="button">
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>

    <button (click)="createUser()" class="ml-4 flex-none" color="primary" mat-mini-fab
      [matTooltip]="'General.Buttons.AddNew' | translate" type="button">
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>

  <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="checkbox">
      <th *matHeaderCellDef mat-header-cell>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" color="primary">
        </mat-checkbox>
      </th>
      <td *matCellDef="let row" class="w-4" mat-cell>
        <mat-checkbox (change)="$event ? selection.toggle(row) : null" (click)="$event.stopPropagation()"
          [checked]="selection.isSelected(row)" color="primary">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Image Column -->
    <ng-container matColumnDef="userImage">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
        <ngx-avatars class="logo box" size="32" [src]="row['userImage']" [name]="row['title']" [textSizeRatio]="2"
          [round]="true" [initialsSize]="2"></ngx-avatars>
      </td>
    </ng-container>

    <!-- Text Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
      <ng-container *ngIf="column.type === 'text'" [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
          {{ "LostIdCard.Table." + column.label | translate }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          {{ row[column.property] }}
          <!-- <mat-icon *ngIf="column.property === 'title' && row?.isVerified" svgIcon="mat:verified" class="text-blue-700"
          style="height: 15px; width: 15px;"
          ></mat-icon> -->
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'date'" [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
          {{ "LostIdCard.Table." + column.label | translate }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          {{ row[column.property] | date: "dd-MM-yyyy" }}
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'boolean' && column.property === 'isVerified'"
        [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase" mat-header-cell>
          <div style="width: 100%; position: relative" class="flex items-center">
            <mat-icon app-click-stop-propagation style="width: 35px; height: 35px; cursor: pointer"
              (click)="select.open()" svgIcon="mat:keyboard_arrow_down" color="primary"></mat-icon>
            {{ "User.Table." + column.label | translate }}
            <mat-select [value]="selectedUserVerification" (selectionChange)="onSelectUserVerification($event)" #select
              class="select-options" multiple>
              <mat-option *ngFor="let verification of VerificationValues | enumToArray" [value]="verification.value">
                {{
                "Enum.VerificationValues." + verification.value | translate
                }}
              </mat-option>
            </mat-select>
          </div>
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          <app-boolean-view width="105px" [value]="row[column.property]" [text]="
              row[column.property]
                ? ('User.Table.Verified' | translate)
                : ('User.Table.NotVerified' | translate)
            "></app-boolean-view>
        </td>
      </ng-container>
      <ng-container *ngIf="column.type === 'boolean' && column.property === 'isActive'"
        [matColumnDef]="column.property">
        <th *matHeaderCellDef class="uppercase" mat-header-cell>
          <div style="width: 100%; position: relative" class="flex items-center">
            <mat-icon app-click-stop-propagation style="width: 35px; height: 35px; cursor: pointer"
              (click)="select.open()" svgIcon="mat:keyboard_arrow_down" color="primary"></mat-icon>
            {{ "User.Table." + column.label | translate }}
            <mat-select [value]="selectedUserStatus" (selectionChange)="onSelectUserStatus($event)" #select
              class="select-options" multiple>
              <mat-option *ngFor="let statue of CompanyUserStatus | enumToArray" [value]="statue.value">
                {{ "Enum.CompanyUserStatus." + statue.value | translate }}
              </mat-option>
            </mat-select>
          </div>
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          <app-boolean-view [trueColor]="'rgba(0, 135, 90, 0.1)'" [falseColor]="'#ff595929'" [trueTextColor]="'#046240'"
            [falseTextColor]="'#FF5959'" [value]="row[column.property]" [text]="
              row[column.property]
                ? ('General.Active' | translate)
                : ('General.Inactive' | translate)
            "></app-boolean-view>
        </td>
      </ng-container>
    </ng-container>

    <!-- Contact Column -->
    <ng-container matColumnDef="contact">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ "LostIdCard.Table.Contact" | translate }}</th>
      <td *matCellDef="let row" mat-cell>
        <div class="flex">
          <app-action-button [btnBackgroundClass]="row?.isActive ? 'bg-green-100' : 'bg-red-100'"
            [btnTextClass]="row?.isActive ? 'text-green' : 'text-red'"
            [buttonToolTip]="(row?.isActive ? 'General.Buttons.Deactivate' : 'General.Buttons.Activate')|translate"
            [buttonIcon]="row?.isActive ? 'person' : 'person_off'" (onClick)="
              row?.isActive
                ? deactivateUser($event, row?.id)
                : activateUser($event, row?.id)
            "></app-action-button>
          <app-action-button [btnBackgroundClass]="'bg-yellow-100'" [btnTextClass]="'text-yellow-700'"
            [buttonToolTip]="'Edit'" [buttonIcon]="'edit'" (onClick)="updateUser($event, row)"></app-action-button>
          <app-action-button [btnBackgroundClass]="'bg-indigo-100'" [btnTextClass]="'text-indigo-700'"
            [buttonToolTip]="'View'" [buttonIcon]="'visibility'" (onClick)="viewUser($event, row?.id)">
          </app-action-button>
        </div>
      </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell mat-sort-header class="uppercase">{{ "LostIdCard.Table.Actions" | translate
        }}</th>
      <td *matCellDef="let row" class="w-10 text-secondary" mat-cell>
        <button (click)="$event.stopPropagation()" [matMenuTriggerData]="{ user: row }"
          [matMenuTriggerFor]="actionsMenu" mat-icon-button type="button">
          <mat-icon svgIcon="mat:more_horiz"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: visibleColumns" @fadeInUp class="hover:bg-hover trans-ease-out cursor-pointer"
      mat-row></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize" showFirstLastButtons class="sticky left-0">
  </mat-paginator>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button (click)="toggleColumnVisibility(column, $event)" *ngFor="let column of columns"
    class="checkbox-item mat-menu-item">
    <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="column.visible" color="primary">
      {{ "LostIdCard.Table." + column.label | translate }}
    </mat-checkbox>
  </button>
</mat-menu>

<mat-menu #actionsMenu="matMenu" xPosition="before" yPosition="below">
  <ng-template let-user="user" matMenuContent>
    <!-- <button mat-menu-item>
      <mat-icon svgIcon="mat:edit"></mat-icon>
      <span>Modify</span>
    </button> -->
    <button mat-menu-item>
      <mat-icon svgIcon="mat:visibility"></mat-icon>
      <span>View</span>
    </button>
    <!-- <button mat-menu-item>
      <mat-icon svgIcon="mat:delete"></mat-icon>
      <span>Delete</span>
    </button> -->
  </ng-template>
</mat-menu>

<ng-template #userQuickViewTemplate>
  <h1>Tool Tip Pop Over</h1>
</ng-template>