import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Camera } from '../../models/video-analytics';

@Component({
  selector: 'app-view-video-analytics',
  templateUrl: './view-video-analytics.component.html',
  styleUrls: ['./view-video-analytics.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    scaleFadeIn400ms,
    fadeInRight400ms,
    scaleIn400ms
  ],
})
export class ViewVideoAnalyticsComponent implements OnInit {
  @Input() cameraInfo!: Camera;
  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  public data: Camera;
  selectedImg: any;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cameraInfo']) {
      this.data = this.cameraInfo;
    }
}

openBigView(): void {
  if (this.data?.picture) {      
    this.selectedImg = this.data?.picture;
    this.dialog.open(this.imageBigView, {height: '97%', width: '80%'});
  }
}
downloadDocument(url: any): void {
  const downloadTag = document.createElement('a');
  downloadTag.href = url;
  downloadTag.addEventListener('click', () => {
    downloadTag.download;
  });
  downloadTag.click();
}

closeDialog(): void {
  this.dialog.closeAll();
}

editCamera(id: number): void{
  this.router.navigate(['/' + SharedConstants.SMART_MONITORING + '/' + SharedConstants.VIDEO_ANALYTICS + '/' + SharedConstants.VIDEO_ANALYTICS_VIEW + '/' + id], {
    queryParams: {editMode: true}
  });
}

}
