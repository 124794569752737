import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { merge, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Constants } from 'src/@hodhod/common/constants';
import { generateGuid } from 'src/@hodhod/common/custom_methods';
import { MachineType } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import { UnitOfMeasureService } from 'src/backend/services/master/unit-of-measure.service';
import { ValueTypeService } from 'src/backend/services/master/value-type.service';
import { UnitOfMeasure } from '../../models/unit-of-measure';
import { ValueType } from '../../models/value-type';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { PermissionService } from 'src/backend/services/permission.service';

@Component({
  selector: 'app-edit-view-machine-page',
  templateUrl: './edit-view-machine-page.component.html',
  styleUrls: ['./edit-view-machine-page.component.scss'],
})
export class EditViewMachinePageComponent implements OnInit {
  tittle: string;
  showParameterPanel: boolean = false;
  machineForm: FormGroup;
  status: boolean;
  formMode: string = 'false';
  private destroy$ = new Subject();
  public translationsList: any = {};
  machineType = MachineType;
  unitOfMeasures: UnitOfMeasure[] = [];
  valueTypes: ValueType[] = [];
  departments: any[] = [];
  machineId: number = 0;
  tempParametersArray: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columns: TableColumn<any>[] = [
    {
      label: 'Name',
      property: 'parameterName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Value Type',
      property: 'valueType',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Unit',
      property: 'unitOfMeasurement',
      type: 'text',
      visible: true,
    },
    {
      label: 'Control',
      property: 'parameterType',
      type: 'boolean',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Notification',
      property: 'receiveNotification',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Lower Control Limit',
      property: 'lowerControlLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Lower Warning Limit',
      property: 'lowerWarningLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Upper Warning Limit',
      property: 'upperWarningLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Upper Control Limit',
      property: 'upperControlLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Lower Range Limit',
      property: 'lowerRangeLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Lower Specification Limit',
      property: 'lowerSpecificationLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Target',
      property: 'target',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Upper Specification Limit',
      property: 'upperSpecificationLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Upper Range Limit',
      property: 'upperRangeLimit',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'IdealValue',
      property: 'idealValue',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'NonIdealValue',
      property: 'nonIdealValue',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<any>([]);

  parameterId: string = '';
  mode: boolean = true;
  USLvalue: any;
  UCLvalue: any;
  machineName: string = '';

  hasCreateValueTypesPermission = ApplicationPermission.VALUE_TYPES_CREATE;
  hasCreateUnitsPermission = ApplicationPermission.UNIT_OF_MEASURES_CREATE;
  hasUpdateMachine = ApplicationPermission.MACHINE_UPDATE;

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private permissionService: PermissionService,
    private confirmationService: ConfirmationService,
    private machineService: MachineEquipmentService,
    private feedBackService: AsyncFeedbackService,
    private unitOfMeasureService: UnitOfMeasureService,
    private valueTypeService: ValueTypeService,
    private departmentService: DepartmentsService
  ) {
    this.translate
      .get(['Errors', 'Success', 'User', 'Machine'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
        this.tittle = this.translationsList['Machine']['ParameterInfo'];
      });
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;
        this.machineId = parseInt(id, 10);
      }
    });
    this.route.queryParamMap.subscribe((queryParam) => {
      const mode = queryParam.get('editMode') as any;
      this.route.queryParamMap.subscribe((queryParam) => {
        this.formMode = queryParam.get('editMode') as any;
      });
      const hasPermissionEdit = this.permissionService.isUserHasPermission(
        this.hasUpdateMachine
      );
      if (hasPermissionEdit && this.formMode === 'true') {
        this.formMode = 'edit';
      } else if (this.formMode === 'false') {
        this.formMode = 'view';
      } else {
        this.router.navigate([
          '/' +
            SharedConstants.PAGE_ERROR +
            '/' +
            SharedConstants.ACCESS_DENIED,
        ]);
      }
      this.loadingService.startLoading(true, '');
      this.getDepartments();
    });

    this.machineForm = this.fb.group({
      pName: ['', Validators.required],
      valueType: [''],
      unitOfMeasurement: [''],
      parameterControl: [MachineType.Digital, Validators.required],
      notification: [false],

      lowerControlLimit: [''],
      upperControlLimit: [''],
      // lowerRangeLimit: [''],
      lowerSpecificationLimit: [''],
      target: [''],
      upperSpecificationLimit: [''],
      idealValue: [''],
      nonIdealValue: [''],
      // upperRangeLimit: ['']
    });
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => console.log(''))
      )
      .subscribe();
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  UCLValidator() {
    this.UCLvalue = this.UCLvalue;
    if (this.UCLvalue === null) {
      this.machineForm.get('lowerControlLimit')?.clearValidators();
      this.machineForm.get('upperControlLimit')?.clearValidators();
      // updating validators values
      this.machineForm.get('lowerControlLimit')?.updateValueAndValidity();
      this.machineForm.get('upperControlLimit')?.updateValueAndValidity();
    } else {
      this.machineForm
        .get('lowerControlLimit')
        ?.setValidators(Validators.required);
      this.machineForm
        .get('upperControlLimit')
        ?.setValidators(Validators.required);
      // updating validators values
      this.machineForm.get('lowerControlLimit')?.updateValueAndValidity();
      this.machineForm.get('upperControlLimit')?.updateValueAndValidity();
    }
  }
  USLValidator() {
    this.USLvalue = this.USLvalue;
    if (this.USLvalue === null) {
      this.machineForm.get('lowerSpecificationLimit')?.clearValidators();
      this.machineForm.get('upperSpecificationLimit')?.clearValidators();
      // updating validators values
      this.machineForm.get('lowerSpecificationLimit')?.updateValueAndValidity();
      this.machineForm.get('upperSpecificationLimit')?.updateValueAndValidity();
    } else {
      this.machineForm
        .get('lowerSpecificationLimit')
        ?.setValidators(Validators.required);
      this.machineForm
        .get('upperSpecificationLimit')
        ?.setValidators(Validators.required);
      // updating validators values
      this.machineForm.get('lowerSpecificationLimit')?.updateValueAndValidity();
      this.machineForm.get('upperSpecificationLimit')?.updateValueAndValidity();
    }
  }

  getUnitOfMeasures(): void {
    this.unitOfMeasureService.getUnitOfMeasuresByCompany().subscribe({
      next: (response) => {
        this.unitOfMeasures = response;
        this.getValueTypes();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  getValueTypes(): void {
    this.valueTypeService.getValueTypesByCompany().subscribe({
      next: (response) => {
        this.valueTypes = response;
        this.getMachineById();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  getDepartments(): void {
    this.departmentService.getDepartmentsParentChild().subscribe({
      next: (response) => {
        this.departments = response;
        this.getUnitOfMeasures();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  getMachineById(): void {
    this.machineService.getMachineEquipmentById(this.machineId).subscribe({
      next: (response) => {
        this.machineName = response.machineName;
        this.tempParametersArray = response.parameters;
        this.dataSource = new MatTableDataSource(this.tempParametersArray);
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  statusChange(status: any) {
    if (status.value == MachineType.Analog) {
      this.showParameterPanel = true;
      //this.addValidations();
    } else {
      this.showParameterPanel = false;
      //this.clearValidations();
    }
  }

  addParameterTempTable(): void {
    const data: any = {
      id: generateGuid(),
      parameterName: this.machineForm.value.pName,
      valueTypeId: this.machineForm.value.valueType
        ? this.machineForm.value.valueType
        : 0,
      valueType: this.valueTypes.find(
        (x) => x.id === this.machineForm.value.valueType
      )?.value,
      unitOfMeasurementId: this.machineForm.value.unitOfMeasurement
        ? this.machineForm.value.unitOfMeasurement
        : 0,
      unitOfMeasurement: this.unitOfMeasures.find(
        (x) => x.id === this.machineForm.value.unitOfMeasurement
      )?.unit,
      parameterType: this.machineForm.value.parameterControl,
      receiveNotification: this.machineForm.value.notification,
      lowerControlLimit: this.machineForm.value.lowerControlLimit
        ? this.machineForm.value.lowerControlLimit
        : 0,
      upperControlLimit: this.machineForm.value.upperControlLimit
        ? this.machineForm.value.upperControlLimit
        : 0,
      // lowerRangeLimit: this.machineForm.value.lowerRangeLimit ? this.machineForm.value.lowerRangeLimit : 0,
      lowerSpecificationLimit: this.machineForm.value.lowerSpecificationLimit
        ? this.machineForm.value.lowerSpecificationLimit
        : 0,
      target: this.machineForm.value.target ? this.machineForm.value.target : 0,
      upperSpecificationLimit: this.machineForm.value.upperSpecificationLimit
        ? this.machineForm.value.upperSpecificationLimit
        : 0,
      idealValue: this.machineForm.value.idealValue
        ? this.machineForm.value.idealValue
        : '',
      nonIdealValue: this.machineForm.value.nonIdealValue
        ? this.machineForm.value.nonIdealValue
        : '',
      // upperRangeLimit: this.machineForm.value.upperRangeLimit ? this.machineForm.value.upperRangeLimit : 0
    };
    this.tempParametersArray.push(data);
    this.dataSource = new MatTableDataSource(this.tempParametersArray);
    this.resetForm();
  }
  removeTempParameter(event: any, id: string) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Machine']['RemoveMachineParameter']['Title'],
          this.translationsList['Machine']['RemoveMachineParameter']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.tempParametersArray = this.tempParametersArray.filter(
            (x) => x.id !== id
          );
          this.dataSource = new MatTableDataSource(this.tempParametersArray);
        }
      });
  }

  editParamterTempTable(): void {
    const found = this.tempParametersArray.find(
      (x) => x.id === this.parameterId
    );
    if (found) {
      (found.parameterName = this.machineForm.value.pName),
        (found.valueTypeId = this.machineForm.value.valueType
          ? this.machineForm.value.valueType
          : 0),
        (found.valueType = this.valueTypes.find(
          (x) => x.id === this.machineForm.value.valueType
        )?.value),
        (found.unitOfMeasurementId = this.machineForm.value.unitOfMeasurement
          ? this.machineForm.value.unitOfMeasurement
          : 0),
        (found.unitOfMeasurement = this.unitOfMeasures.find(
          (x) => x.id === this.machineForm.value.unitOfMeasurement
        )?.unit),
        (found.parameterType = this.machineForm.value.parameterControl),
        (found.receiveNotification = this.machineForm.value.notification),
        (found.lowerControlLimit = this.machineForm.value.lowerControlLimit
          ? this.machineForm.value.lowerControlLimit
          : 0),
        (found.lowerWarningLimit = this.machineForm.value.lowerWarningLimit
          ? this.machineForm.value.lowerWarningLimit
          : 0),
        (found.upperWarningLimit = this.machineForm.value.upperWarningLimit
          ? this.machineForm.value.upperWarningLimit
          : 0),
        (found.upperControlLimit = this.machineForm.value.upperControlLimit
          ? this.machineForm.value.upperControlLimit
          : 0),
        (found.lowerRangeLimit = this.machineForm.value.lowerRangeLimit
          ? this.machineForm.value.lowerRangeLimit
          : 0),
        (found.lowerSpecificationLimit = this.machineForm.value
          .lowerSpecificationLimit
          ? this.machineForm.value.lowerSpecificationLimit
          : 0),
        (found.target = this.machineForm.value.target
          ? this.machineForm.value.target
          : 0),
        (found.upperSpecificationLimit = this.machineForm.value
          .upperSpecificationLimit
          ? this.machineForm.value.upperSpecificationLimit
          : 0),
        (found.upperRangeLimit = this.machineForm.value.upperRangeLimit
          ? this.machineForm.value.upperRangeLimit
          : 0),
        (found.idealValue = this.machineForm.value.idealValue
          ? this.machineForm.value.idealValue
          : ''),
        (found.nonIdealValue = this.machineForm.value.nonIdealValue
          ? this.machineForm.value.nonIdealValue
          : '');

      this.dataSource = new MatTableDataSource(this.tempParametersArray);
      this.resetForm();
    }
  }

  editParameter(event: any, id: string): void {
    const found = this.tempParametersArray.find((x) => x.id === id);
    if (found) {
      this.parameterId = found?.id;
      this.mode = false;
      this.machineForm.get('parameterControl').setValue(found?.parameterType);
      this.machineForm.get('pName').setValue(found?.parameterName);
      this.machineForm.get('valueType').setValue(found?.valueTypeId);
      this.machineForm
        .get('unitOfMeasurement')
        .setValue(found?.unitOfMeasurementId);
      this.machineForm.get('notification').setValue(found?.receiveNotification);
      this.machineForm
        .get('lowerControlLimit')
        ?.setValue(found?.lowerControlLimit);
      this.machineForm
        .get('lowerWarningLimit')
        ?.setValue(found?.lowerWarningLimit);
      this.machineForm
        .get('upperWarningLimit')
        ?.setValue(found?.upperWarningLimit);
      this.machineForm
        .get('upperControlLimit')
        ?.setValue(found?.upperControlLimit);
      this.machineForm.get('lowerRangeLimit')?.setValue(found?.lowerRangeLimit);
      this.machineForm
        .get('lowerSpecificationLimit')
        ?.setValue(found?.lowerSpecificationLimit);
      this.machineForm.get('target')?.setValue(found?.target);
      this.machineForm
        .get('upperSpecificationLimit')
        ?.setValue(found?.upperSpecificationLimit);
      this.machineForm.get('upperRangeLimit')?.setValue(found?.upperRangeLimit);
      this.machineForm.get('idealValue')?.setValue(found?.idealValue);
      this.machineForm.get('nonIdealValue')?.setValue(found?.nonIdealValue);
      if (found?.parameterType === MachineType.Digital) {
        this.showParameterPanel = false;
        this.clearValidations();
      } else {
        this.showParameterPanel = true;
        //this.addValidations();
      }
    }
  }

  updateMachineParameter() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const data = {
            machineId: this.machineId,
            parameters: this.tempParametersArray.map((ele) => {
              return {
                parameterName: ele?.parameterName,
                valueTypeId: ele?.valueTypeId,
                unitOfMeasurementId: ele?.unitOfMeasurementId,
                parameterType: ele?.parameterType,
                receiveNotification: ele?.receiveNotification,
                lowerControlLimit: ele?.lowerControlLimit,
                lowerWarningLimit: ele?.lowerWarningLimit,
                upperWarningLimit: ele?.upperWarningLimit,
                upperControlLimit: ele?.upperControlLimit,
                lowerRangeLimit: ele?.lowerRangeLimit,
                lowerSpecificationLimit: ele?.lowerSpecificationLimit,
                target: ele?.target,
                upperSpecificationLimit: ele?.upperSpecificationLimit,
                upperRangeLimit: ele?.upperRangeLimit,
                idealValue: ele?.idealValue,
                nonIdealValue: ele?.nonIdealValue,
              };
            }),
          };
          this.loadingService.startLoading(true, '');
          this.machineService.editMachineParameters(data).subscribe({
            next: (response) => {
              this.resetForm();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
              this.loadingService.stopLoading();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  resetForm(): void {
    this.machineForm.reset();
    this.machineForm.get('parameterControl').setValue(MachineType.Digital);
    this.showParameterPanel = false;
    this.clearValidations();
    this.mode = true;
    this.parameterId = '';
  }

  addValidations(): void {
    this.machineForm
      .get('lowerControlLimit')
      ?.setValidators(Validators.required);
    this.machineForm
      .get('upperControlLimit')
      ?.setValidators(Validators.required);
    //this.machineForm.get('lowerRangeLimit')?.setValidators(Validators.required);
    this.machineForm
      .get('lowerSpecificationLimit')
      ?.setValidators(Validators.required);
    //this.machineForm.get('target')?.setValidators(Validators.required);
    this.machineForm
      .get('upperSpecificationLimit')
      ?.setValidators(Validators.required);
    // this.machineForm.get('upperRangeLimit')?.setValidators(Validators.required);

    // updating validators values
    this.machineForm.get('lowerControlLimit')?.updateValueAndValidity();
    this.machineForm.get('lowerWarningLimit')?.updateValueAndValidity();
    this.machineForm.get('upperWarningLimit')?.updateValueAndValidity();
    this.machineForm.get('upperControlLimit')?.updateValueAndValidity();
    this.machineForm.get('lowerRangeLimit')?.updateValueAndValidity();
    this.machineForm.get('lowerSpecificationLimit')?.updateValueAndValidity();
    this.machineForm.get('target')?.updateValueAndValidity();
    this.machineForm.get('upperSpecificationLimit')?.updateValueAndValidity();
    this.machineForm.get('upperRangeLimit')?.updateValueAndValidity();
  }
  clearValidations(): void {
    this.machineForm.get('lowerControlLimit')?.clearValidators();
    this.machineForm.get('lowerWarningLimit')?.clearValidators();
    this.machineForm.get('upperWarningLimit')?.clearValidators();
    this.machineForm.get('upperControlLimit')?.clearValidators();
    this.machineForm.get('lowerRangeLimit')?.clearValidators();
    this.machineForm.get('lowerSpecificationLimit')?.clearValidators();
    this.machineForm.get('target')?.clearValidators();
    this.machineForm.get('upperSpecificationLimit')?.clearValidators();
    this.machineForm.get('upperRangeLimit')?.clearValidators();
    // updating validators values
    this.machineForm.get('lowerControlLimit')?.updateValueAndValidity();
    this.machineForm.get('lowerWarningLimit')?.updateValueAndValidity();
    this.machineForm.get('upperWarningLimit')?.updateValueAndValidity();
    this.machineForm.get('upperControlLimit')?.updateValueAndValidity();
    this.machineForm.get('lowerRangeLimit')?.updateValueAndValidity();
    this.machineForm.get('lowerSpecificationLimit')?.updateValueAndValidity();
    this.machineForm.get('target')?.updateValueAndValidity();
    this.machineForm.get('upperSpecificationLimit')?.updateValueAndValidity();
    this.machineForm.get('upperRangeLimit')?.updateValueAndValidity();
  }
}
