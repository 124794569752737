import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from 'src/@hodhod/services/language.service';
import { EmailValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { AuthService } from 'src/backend/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  public form = new FormGroup({
    email: new FormControl('', [Validators.required, EmailValidator])
  });
  public recoverEmailSent = false;

  private destroy$ = new Subject();

  private translationsList: any = {};

  SharedConstant = SharedConstants;

  constructor(
    private loadingService: LoadingService,
    private asyncFeedbackService: AsyncFeedbackService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private authService: AuthService
  ) {
    this.getSelectedLanguageText();
  }

  ngOnInit(): void {
    this.languageService.languageChanged.subscribe(language => {
      this.getSelectedLanguageText();
    });
  }

  ngOnDestroy(): void {
      this.destroy$.complete();
  }

  // UI Events
  onRecoverPasswordButtonClicked() {
    this.loadingService.startLoading(true, '');
    this.authService.forgotPassword(this.form.value.email).subscribe({
      next: (response) => {
        this.recoverEmailSent = true;
        this.loadingService.stopLoading();
      },
      error: ({error}) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      }
    })

  }

  // private functions
  private getSelectedLanguageText() {
    this.translate.get(['Errors', 'ForgotPassword']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
      this.translationsList = translations;
    });
  }
}
