import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Constants } from 'src/@hodhod/common/constants';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import {
  ChecklistType,
  RolesStatus,
  SortDirection,
} from 'src/@hodhod/common/enum';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil, tap } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { AddUpdateChecklistPopupComponent } from '../add-update-checklist-popup/add-update-checklist-popup.component';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { GetAllChecklists } from 'src/app/reporting/models/checklist-section.model';
import { BaseApi } from 'src/backend/api/base-api';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { PermissionService } from 'src/backend/services/permission.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import * as XLSX from 'xlsx-js-style';
@UntilDestroy()
@Component({
  selector: 'app-checklist-section',
  templateUrl: './checklist-section.component.html',
  styleUrls: ['./checklist-section.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class ChecklistSectionComponent extends BaseComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('bulkUploadModel') bulkUploadModel!: TemplateRef<any>;

  subject$: ReplaySubject<GetAllChecklists[]> = new ReplaySubject<
    GetAllChecklists[]
  >(1);
  data$: Observable<GetAllChecklists[]> = this.subject$.asObservable();
  roles: GetAllChecklists[];
  columns: TableColumn<GetAllChecklists>[] = [
    {
      label: 'checklistName',
      property: 'checkListName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'NoSection',
      property: 'noOfSections',
      type: 'text',
      visible: true,
    },
    {
      label: 'NoQuestion',
      property: 'noOfQuestions',
      type: 'text',
      visible: true,
    },
    {
      label: 'createdBy',
      property: 'createdBy',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'CheckListType',
      property: 'checkListType',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'createdOn',
      property: 'createdOn',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'updatedOn', property: 'updatedOn', type: 'date', visible: true },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  selection = new SelectionModel<GetAllChecklists>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  loadingLabel: string = '';
  sessionCompany: any;
  timer = null;
  RolesStatuses = RolesStatus;
  SharedConstants = SharedConstants;
  public selectedChecklistStatus: string[] = [];
  public selectedRoleStatus: RolesStatus = null;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  dataSource = new MatTableDataSource<GetAllChecklists>([]);
  private translationsList: any = {};
  override destroy$ = new Subject();
  checklist: GetAllChecklists[];

  hasAuditActivePermission = ApplicationPermission.AUDIT_CHECKLIST_ACTIVATE;
  hasInspectionChecklistActivePermission =
    ApplicationPermission.INSPECTION_CHECKLIST_ACTIVATE;
  hasWorkConditionsActivePermission =
    ApplicationPermission.WORK_CONDITIONS_ACTIVATE;

  hasAuditEditPermission = ApplicationPermission.AUDIT_CHECKLIST_UPDATE;
  hasWorkConditionsEditPermission =
    ApplicationPermission.WORK_CONDITIONS_UPDATE;
  hasInspectionChecklistEditPermission =
    ApplicationPermission.INSPECTION_CHECKLIST_UPDATE;
  hasAuditCreatePermission = ApplicationPermission.AUDIT_CHECKLIST_WRITE;
  hasWorkConditionsCreatePermission =
    ApplicationPermission.WORK_CONDITIONS_CREATE;
  hasAuditAccessPermission = ApplicationPermission.AUDIT_CHECKLIST_READ;
  hasWorkConditionsAccessPermission =
    ApplicationPermission.WORK_CONDITIONS_ACCESS;
  hasInspectionAccessPermission = ApplicationPermission.INSPECTION_MANAGEMENT;
  hasInspectionCreatePermission =
    ApplicationPermission.INSPECTION_CHECKLIST_WRITE;
  bulkUploadChecklistForm: FormGroup;
  checkListData: any;
  sectionQuestionData: any;
  excelFile: any;
  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    private feedBackService: AsyncFeedbackService,
    private checklistService: ChecklistSectionService,
    private baseApi: BaseApi,
    private permissionService: PermissionService,
    private confirmationService: ConfirmationService,
    private loadService: LoadingService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'ConfirmDeleteRecord',
        'confirmDeactiveRecord',
        'StaticPages',
        'General',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  override ngOnInit(): void {
    this.getSessionOrganization();
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedChecklistStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));

    this.getData();
    this.bulkUploadChecklistForm = this.fb.group({
      file: [[], Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getSessionOrganization(): void {
    this.sessionCompany = this.baseApi.getCompanyFromStorage();
  }
  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      status: this.currentQueryParams?.orgStatus,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.checklistService.getAllCheckList(filteredParams).subscribe({
      next: (response) => {
        this.checklist = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onSelectCheckListStatus(event: MatSelectChange) {
    this.selectedChecklistStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  deactivateChecklist(Id: string, type: string): void {
    const hasPermission: boolean = this.permissionService.isUserHasPermission(
      type == ChecklistType.Audit
        ? this.hasAuditActivePermission
        : type == ChecklistType.WorkCondition
        ? this.hasWorkConditionsActivePermission
        : this.hasInspectionChecklistActivePermission
    );
    if (hasPermission) {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['confirmDeactiveRecord']['Title'],
            this.translationsList['confirmDeactiveRecord']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.checklistService.deActivate(parseInt(Id, 10)).subscribe({
              next: () => {
                this.getData();
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Success,
                    this.translationsList['General']['DeActivated']
                  )
                );
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Failure,
                    this.translationsList['Errors']['ErrorHappened']
                  )
                );
              },
            });
          }
        });
    } else {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
    }
  }
  activateChecklist(Id: string, type: string): void {
    const hasPermission: boolean = this.permissionService.isUserHasPermission(
      type == ChecklistType.Audit
        ? this.hasAuditActivePermission
        : type == ChecklistType.WorkCondition
        ? this.hasWorkConditionsActivePermission
        : this.hasInspectionChecklistActivePermission
    );
    if (hasPermission) {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['confirmDeactiveRecord']['Title'],
            this.translationsList['confirmDeactiveRecord']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.checklistService.activate(parseInt(Id, 10)).subscribe({
              next: () => {
                this.getData();
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Success,
                    this.translationsList['General']['Activated']
                  )
                );
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Failure,
                    this.translationsList['Errors']['ErrorHappened']
                  )
                );
              },
            });
          }
        });
    } else {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
    }
  }
  viewRole(event: any, roleId: string): void {
    this.router.navigate([
      '/' +
        SharedConstants.USER_MANAGEMENT +
        '/' +
        SharedConstants.VIEW_ROLE +
        '/' +
        roleId,
    ]);
  }

  createChecklist(event?: any, checklistId?: number): void {
    this.router.navigate([
      '/' +
        SharedConstants.SAFETY +
        '/' +
        SharedConstants.CHECKLIST_SECTION +
        '/' +
        SharedConstants.ADD_CHECKLIST_SECTION +
        '/create',
    ]);
  }

  updateChecklist(event: any, checklistId: number): void {
    this.router.navigate(
      [
        '/' +
          SharedConstants.SAFETY +
          '/' +
          SharedConstants.CHECKLIST_SECTION +
          '/' +
          SharedConstants.VIEW_CHECKLIST_SECTION +
          '/' +
          checklistId,
      ],
      {
        queryParams: { ...this.currentQueryParams, editMode: true },
      }
    );
  }
  viewChecklist(event: any, checklistId: number, checkListType?): void {
    let hasPermission: boolean;
    if (checkListType === 'Audit') {
      hasPermission = this.permissionService.isUserHasPermission(
        this.hasAuditAccessPermission
      );
    } else if (checkListType === 'WorkCondition') {
      hasPermission = this.permissionService.isUserHasPermission(
        this.hasWorkConditionsAccessPermission
      );
    } else if (checkListType === 'Inspection') {
      hasPermission = this.permissionService.isUserHasPermission(
        this.hasInspectionAccessPermission
      );
    }
    if (hasPermission) {
      this.router.navigate(
        [
          '/' +
            SharedConstants.SAFETY +
            '/' +
            SharedConstants.CHECKLIST_SECTION +
            '/' +
            SharedConstants.VIEW_CHECKLIST_SECTION +
            '/' +
            checklistId,
        ],
        {
          queryParams: { ...this.currentQueryParams, editMode: false },
        }
      );
    }
  }

  createChecklistName() {
    this.dialog
      .open(AddUpdateChecklistPopupComponent, {
        height: 'auto',
        width: '40%',
      })
      .afterClosed()
      .subscribe((role: any) => {
        this.getData();
      });
  }

  deleteCheckList(id: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['ConfirmDeleteRecord']['Title'],
          this.translationsList['ConfirmDeleteRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadService.startLoading(true, '');
          this.checklistService.deleteCheckList(id).subscribe({
            next: (res) => {
              this.getData();
              this.loadService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
            },
            error: (error) => {
              this.loadService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.error.message)
              );
            },
          });
        }
      });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  openBulkUploadModal(): void {
    this.bulkUploadChecklistForm.get('file').setValue([]);
    this.dialog.open(this.bulkUploadModel, { height: 'auto', width: '50%' });
  }

  checkFileExtension(e: any) {
    let files = this.bulkUploadChecklistForm.get('file').value;
    const images = files.filter(
      (x) =>
        x.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        x.type === 'application/vnd.ms-excel' ||
        x.type === 'text/csv'
    );
    const otherFiles = files.filter(
      (x) =>
        x.type !==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        x.type !== 'application/vnd.ms-excel' &&
        x.type !== 'text/csv'
    );
    if (otherFiles.length > 0) {
      this.bulkUploadChecklistForm.get('file').setValue(images);
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Warning,
          'Only XLSX , XLS and CSV files supported'
        )
      );
    }
  }

  cancel(): void {
    this.bulkUploadChecklistForm.get('file').setValue([]);
    this.dialog.closeAll();
  }

  saveExcel(): void {
    this.loadService.startLoading(true, '');
    this.excelFile = this.bulkUploadChecklistForm.get('file').value[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.excelFile);
    fileReader.onload = (e) => {
      let arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const checkListSheet = workbook.SheetNames[0];
      const sectionQustionSheet = workbook.SheetNames[1];
      const sectionQuestion = workbook.Sheets[sectionQustionSheet];
      const checkList = workbook.Sheets[checkListSheet];
      this.checkListData = XLSX.utils.sheet_to_json(checkList, { raw: true });
      this.sectionQuestionData = XLSX.utils.sheet_to_json(sectionQuestion, {
        raw: true,
      });
      if (this.checkListData?.length > 0) {
        this.checkListData.forEach((data) => {
          Object.keys(data).forEach((key, i) => {
            var replacedKey = key.replace(/ /g, '');
            if (key !== replacedKey) {
              data[replacedKey] = data[key];
              delete data[key];
            }
          });
        });
        this.sectionQuestionData.forEach((data) => {
          Object.keys(data).forEach((key, i) => {
            var replacedKey = key.replace(/ /g, '');
            if (key !== replacedKey) {
              data[replacedKey] = data[key];
              delete data[key];
            }
          });
        });
        let array = [];
        const unique = new Set(this.checkListData.map((x) => x.CheckListName))
          .size;
        if (unique !== this.checkListData.length) {
          this.loadService.stopLoading();
          this.feedBackService.showFeedback(
            new FeedbackModel(
              FeedbackType.Warning,
              'Duplicate CheckList name not allowed'
            )
          );
          return;
        }
        for (const checkList of this.checkListData) {
          const section = this.sectionQuestionData.filter(
            (x) => x.CheckListName === checkList.CheckListName
          );
          const uniqueSection = [
            ...new Set(section.map((item) => item.SectionName)),
          ];
          let sectionType = '';
          let sectionArray = [];
          for (const s of uniqueSection) {
            const sectionType = section.find(
              (x) => x.SectionName === s
            ).SectionType;
            const obj = {
              sectionName: s ? s : '',
              sectionType: sectionType,
              questions: section
                .filter((x) => x.SectionName === s)
                .map((d) => {
                  return {
                    question: d.Question ? d.Question : '',
                    answer: d.Answer ? d.Answer : '',
                  };
                }),
            };
            sectionArray.push(obj);
          }
          const checkListData = {
            checkListName: checkList.CheckListName,
            checkListType: checkList.CheckListType,
            minimumScore: checkList.MinimumScore,
            sections: sectionArray,
          };
          array.push(checkListData);
        }
        this.checklistService.submitBulkCheckList(array).subscribe({
          next: () => {
            this.feedBackService.showFeedback(
              new FeedbackModel(
                FeedbackType.Success,
                this.translationsList['Success']['SuccessMessage']
              )
            );
            this.cancel();
            this.getData();
            this.loadService.stopLoading();
          },
          error: ({ error }) => {
            this.loadService.stopLoading();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error.message)
            );
          },
        });
      }
    };
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
}
