import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { DashboardTwoRoutingModule } from './dashboard-routing.module';
import { DashboardTwoComponent } from './pages/dashboard-two/dashboard-two.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { DashboardSmartSafetyComponent } from './components/dashboard-smart-safety/dashboard-smart-safety.component';
import { DashboardChartComponent } from './components/dashboard-chart/dashboard-chart.component';
import { DashboardFullscreenComponent } from './components/dashboard-fullscreen/dashboard-fullscreen.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { DashboardIotComponent } from './components/dashboard-iot/dashboard-iot.component';
import { AddIotDashboardComponent } from './components/add-iot-dashboard/add-iot-dashboard.component';
import { DigitalIotDashboardComponent } from './components/digital-iot-dashboard/digital-iot-dashboard.component';
import { SmartMonitoringModule } from '../smart-monitoring/smart-monitoring.module';
import { SmartMonitoringRoutingModule } from '../smart-monitoring/smart-monitoring-routing.module';
import { SafetyKpiDashboardsComponent } from './components/safety-kpi-dashboards/safety-kpi-dashboards.component';
import { SafetyTriangleDashboardComponent } from './components/safety-triangle-dashboard/safety-triangle-dashboard.component';
import { SafemanHoursCardComponent } from './components/safeman-hours-card/safeman-hours-card.component';
import { SafemanHoursComponent } from './components/safeman-hours-settings/safeman-hours.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [
    DashboardTwoComponent,
    DashboardCardComponent,
    DashboardSmartSafetyComponent,
    DashboardChartComponent,
    DashboardFullscreenComponent,
    DashboardIotComponent,
    AddIotDashboardComponent,
    DigitalIotDashboardComponent,
    SafetyKpiDashboardsComponent,
    SafetyTriangleDashboardComponent,
    SafemanHoursCardComponent,
    SafemanHoursComponent,
  ],
  imports: [
    CommonModule,
    DashboardModule,
    DashboardTwoRoutingModule,
    SharedModule,
    SmartMonitoringModule,
    SmartMonitoringRoutingModule,
    MatMenuModule,
  ],
})
export class DashboardTwoModule {}
