import { Component, OnInit } from '@angular/core';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { OrganizationDetail } from '../../models/organization-detail';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { OrganizationService } from 'src/backend/services/organization.service';
import { Feature } from 'src/backend/models/features/feature';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-org-details-page',
  templateUrl: './org-details-page.component.html',
  styleUrls: ['./org-details-page.component.scss']
})

export class OrgDetailsPageComponent implements OnInit {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public orgInfo: OrganizationDetail;
  public selectedFeatures: Feature[] = [];
  public orgCode: string = '';
  private destroy$ = new Subject();

  constructor(
    private orgService: OrganizationService,
    private feedBackService: AsyncFeedbackService,
    private route: ActivatedRoute,


  ) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        
        this.orgCode = params.get('id') as string;
        this.getOrgInfo();
      }

    });
  }
  getOrgInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.orgService.getOrganizationDetails(this.orgCode).subscribe({
      next: (response) => {
        this.orgInfo = response;
      
        let security:any = response?.features?.find(
          (element) => element['module'] === '1'
        )
        let safety:any = response['features']?.find(
          (element) => element['module'] === '0'
        );
        if (safety?.features || security?.features) {
          this.selectedFeatures = [
            ...(safety?.features || []),
            ...(security?.features || []),
          ];         
        }
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }
  getOrgCardInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.orgService.getOrganizationCardDetails(this.orgCode).subscribe({
      next: (response) => {
        
        // console.log(response['features']?.find(
        //   (element) => element['module'] === '0'
        // ));        
        // let security:any = response?.features?.find(
        //   (element) => element['module'] === '1'
        // )
        // let safety:any = response['features']?.find(
        //   (element) => element['module'] === '0'
        // );
        // if (safety?.features || security?.features) {
        //   this.selectedFeatures = [
        //     ...(safety?.features || []),
        //     ...(security?.features || []),
        //   ];         
        // }
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }



}
