import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { Subject, takeUntil } from 'rxjs';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
Drilldown(Highcharts);
@Component({
  selector: 'app-dashboard-chart',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss'],
})
export class DashboardChartComponent implements OnInit, OnChanges {
  // @Input() dashboard: any;
  @Input() chartLevel: any = null;
  @Input() clickedPoint: { point: string; level: string } = null;
  @Output() onChartClicked: EventEmitter<any> = new EventEmitter();
  @Input() fullScreenOpen: boolean = false;

  private _dashboard: any;
  @Input()
  set dashboard(dashboard) {
    this._dashboard = dashboard;
  }

  public chart: Chart;
  public data: any;
  public chartData: any;

  public translationsList: any = {};
  private destroy$ = new Subject();

  constructor(private translate: TranslateService, private router: Router) {}

  ngOnChanges(changes: SimpleChanges) {
    this.chartData = changes['dashboard'].currentValue.chartData;
    this.getChartLevel();
    this.translate
      .get(['Dashboards'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations.Dashboards;
      });
    this.chart = new Chart({
      chart: {
        type: this.chartData?.chartType,
        events: {
          load: function () {
            this.reflow();
          },
          beforePrint: () => {
            this.chart.ref$.subscribe((chart) => {
              chart.update({
                exporting: {
                  enabled: false,
                },
                title: {
                  text: JSON.parse(localStorage.getItem('company')).companyName,
                  style: {
                    justifyContent: 'start',
                    display: 'flex',
                    color: '#d52a46',
                    fontSize: '1rem',
                    fontWeight: '0.5rem',
                  },
                  y: 5,
                  align: 'left',
                },
                subtitle: {
                  text: this.translationsList[this._dashboard?.title],
                  style: {
                    marginBottom: 100,
                  },
                },
                credits: {
                  text:
                    `<span style="color:#d52a46">From: </span>` +
                    Highcharts.dateFormat(
                      '%m/%d/%Y',
                      this._dashboard.startDate
                    ) +
                    `<span style="color:#d52a46"> To: </span>` +
                    Highcharts.dateFormat('%m/%d/%Y', this._dashboard.endDate),
                  position: {
                    align: 'right',
                    x: -10,
                    y: 10,
                    verticalAlign: 'top',
                  },
                  style: {
                    margin: '10px',
                    color: '#000',
                  },
                  enabled: true,
                },
              });
            });
          },
          afterPrint: function () {
            this.update({
              exporting: {
                enabled: true,
              },
              title: {
                text: '',
              },
              subtitle: {
                text: '',
              },
              credits: {
                enabled: false,
              },
            });
          },
        },
      },
      title: {
        text:
          this._dashboard.title == 'InspectionScore' &&
          this.chartLevel == 'one' &&
          this.chartData?.part1[0]?.data[0]?.y >= 0
            ? this.chartData.part1[0].data[0].y + ' %'
            : '',
        verticalAlign: 'middle',
        align: 'center',
        y: -4,
        style: {
          display:
            this._dashboard.title == 'InspectionScore' &&
            this.chartLevel == 'one'
              ? ''
              : 'none',
        },
      },
      legend: {
        enabled: true,
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        squareSymbol: false,
        borderRadius: 0,
        symbolRadius: 3,
        symbolHeight: 10,
        symbolWidth: 12,
        symbolPadding: 12,
        itemStyle: {
          width: 100,
          height: 100,
          position: 'absolute',
        },
      },
      exporting: {
        enabled: false,
        buttons: {
          contextButton: {
            menuItems: [
              'printChart',
              'separator',
              'downloadPNG',
              'downloadJPEG',
              'downloadPDF',
              'downloadSVG',
            ],
          },
        },
        allowHTML: true,
        chartOptions: {
          title: {
            text: JSON.parse(localStorage.getItem('company')).companyName,
            style: {
              justifyContent: 'start',
              display: 'flex',
              color: '#d52a46',
              fontSize: '1rem',
              fontWeight: '0.5rem',
            },
            y: 25,
            align: 'left',
          },
          subtitle: {
            text: this.translationsList[this._dashboard.title],
          },
          credits: {
            text:
              `<span style="color:#d52a46">From: </span>` +
              Highcharts.dateFormat('%m/%d/%Y', this._dashboard.startDate) +
              `<span style="color:#d52a46">To: </span>` +
              Highcharts.dateFormat('%m/%d/%Y', this._dashboard.endDate),
            position: {
              align: 'right',
              x: -10,
              y: 30,
              verticalAlign: 'top',
            },
            style: {
              margin: '10px',
              color: '#000',
            },
            enabled: true,
          },
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
          point: {
            events: {
              click: (event) => {
                if (this._dashboard.title == 'InspectionScore') {
                  this.clickedPoint.point =
                    this.chartLevel != null
                      ? event?.point?.options?.name.toString()
                      : null;
                  this.clickedPoint.level =
                    this.chartLevel != null ? this.chartLevel : null;
                } else if (
                  this._dashboard.title == 'ReportedIncidentLoss' &&
                  this.chartLevel == 'three'
                ) {
                  this.router.navigate(
                    [
                      SharedConstants.REPORTING_MANAGEMENT +
                        '/' +
                        SharedConstants.REPORT_INCIDENT +
                        '/' +
                        SharedConstants.REPORT_INCIDENT_VIEW +
                        '/' +
                        event?.point?.options?.drilldown,
                    ],
                    {
                      queryParams: { editMode: false },
                    }
                  );
                } else {
                  this.clickedPoint.point =
                    this.chartLevel != null
                      ? event?.point?.options?.drilldown.toString()
                      : null;
                  this.clickedPoint.level =
                    this.chartLevel != null ? this.chartLevel : null;
                }
                this.onChartClicked.emit(this.clickedPoint);
              },
            },
          },
        },
        column: {
          borderRadius: 5,
          dataLabels: {
            padding: 10,
            borderRadius: 5,
            backgroundColor: '#ffffff',
            color: '#000000',
            borderWidth: 0,
            borderColor: 'none',
            x: 0,
            y: 15,
            format:
              this._dashboard.title == 'CheckList' ||
              this._dashboard.title == 'InspectionScore'
                ? '{point.y} %'
                : '{point.y}',
          },
        },
        bar: {
          dataLabels: {
            enabled: true,
            padding: 6,
            borderRadius: 5,
            backgroundColor: '#ffffff',
            color: '#000000',
            borderWidth: 0,
            borderColor: 'none',
            y: 0,
            x: -10,
          },
        },
        pie: {
          allowPointSelect: true,
          center: ['50%', '50%'],
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f} %',
            distance: -45,
            backgroundColor: '#ffffff',
            color: '#000000',
            style: {
              display: this._dashboard.title == 'InspectionScore' ? 'none' : '',
            },
          },
          showInLegend: true,
        },
        line: {
          dataLabels: {
            enabled: true,
          },
          marker: {
            symbol: '',
            color: '#26326E',
          },
          showInLegend: true,
          enableMouseTracking: true,
        },
      },
      xAxis: {
        type: this.chartData?.chartType == 'line' ? 'datetime' : 'linear',
        gridLineColor: 'transparent',
        categories:
          this.chartData?.chartType == 'column'
            ? [this.chartData?.chartTitle]
            : this.chartData?.chartType == 'line'
            ? this.chartData.xValues
            : null,
        tickInterval: this.chartData?.chartType == 'line' ? 1 : 5,
        title: {
          text:
            this.chartData?.chartType == 'bar'
              ? this.chartData?.chartTitle
              : null,
          style: {
            fontSize: '14px',
            color: '#6F6F6F',
          },
        },
      },
      yAxis: {
        min: 0,
        gridLineColor: 'transparent',
        title:
          this.chartData?.chartType == 'line'
            ? {
                align: 'middle',
                text: this._dashboard?.unit,
              }
            : {
                text: '',
              },
        tickInterval: 5,
        plotBands: [
          {
            // correct range
            from:
              this.chartData?.chartType == 'line' && this.chartData?.lowerRange
                ? this.chartData?.lowerRange
                : 0,
            to:
              this.chartData?.chartType == 'line' && this.chartData?.upperRange
                ? this.chartData?.upperRange
                : 0,
            color: 'rgba(151, 232, 132, 0.3)',
          },
        ],
      },
      series: this.data,
      drilldown: {
        breadcrumbs: {
          style: {
            display: 'none',
          },
        },
        allowPointDrilldown: false,
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
        },
      },
    });
  }

  ngOnInit() {}

  getChartLevel() {
    if (this.chartLevel == 'one' || !this.chartLevel) {
      this.data = this.chartData?.part1;
    } else if (this.chartLevel == 'two') {
      this.data = this.chartData?.part2;
    } else if (this.chartLevel == 'three') {
      this.data = this.chartData?.part3;
    } else if (this.chartLevel == 'four') {
      this.data = this.chartData?.part4;
    }
  }
}
