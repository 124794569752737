import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/@hodhod/common/constants';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { generateGuid } from 'src/@hodhod/common/custom_methods';
import {
  CreateInstanceParam,
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { Router } from '@angular/router';
import { NearMissReportService } from 'src/backend/services/reportings/near-miss-report.service';

@Component({
  selector: 'app-near-miss-report-add',
  templateUrl: './near-miss-report-add.component.html',
  styleUrls: ['./near-miss-report-add.component.scss'],
})
export class NearMissReportAddComponent implements OnInit {
  workFlow: WorkFlow;
  companyKey: string = '';
  instanceKey: string = '';
  loggedUser: LoggedUser;
  nearMissForm: any;

  constructor(
    private baseApi: BaseApi,
    private workFlowService: WorkFlowService,
    private loadingService: LoadingService,
    private router: Router,
    private nearMissReportService: NearMissReportService,
    private feedBackService: AsyncFeedbackService
  ) {}

  ngOnInit() {
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.getWorkFlow();
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_NEARMISS_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  createNearMissReport(payload): void {
    this.nearMissForm = payload;
    this.createWorkFlowInstance();
  }

  createWorkFlowInstance(): void {
    this.instanceKey = generateGuid();
    const data: CreateInstanceParam = {
      workFlowKey: this.workFlow?.workFlowKey,
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.userName,
      tenantId: this.companyKey,
    };
    this.loadingService.startLoading(true);
    this.workFlowService.createWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.setWorkFlowInstance();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  setWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_CREATE_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: null,
    };
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.createNearMiss();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
        this.deleteWorkFlowInstance();
      },
    });
  }

  createNearMiss(): void {
    const formData = new FormData();
    const {
      title,
      rootCause,
      shift,
      severity,
      department,
      cause,
      description,
      witness,
      attachments,
      typeOfReportIds,
      subDepartment,
    } = this.nearMissForm;
    const data = {
      title: title,
      shift: shift,
      saverity: severity,
      description: description,
      departmentId: subDepartment ? subDepartment : department,
      rootCauseId: rootCause,
      causeId: cause,
      witness: witness ? witness.toString() : '',
      instanceKey: this.instanceKey,
      typeOfReportIds: typeOfReportIds.toString(),
    };
    formData.append('content', JSON.stringify(data));
    attachments.forEach((element) => {
      formData.append('files', element);
    });
    this.loadingService.startLoading(true);
    this.nearMissReportService.createNearMissReport(formData).subscribe({
      next: (response) => {
        this.updateWorkFlowInstance(parseInt(response?.id, 10));
        this.getCustomUsersHierarchy(
          parseInt(response?.id, 10),
          response?.message
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  updateWorkFlowInstance(id: number): void {
    const {
      title,
      rootCause,
      shift,
      severity,
      department,
      cause,
      description,
    } = this.nearMissForm;
    const nearMissData = {
      reportNearMissId: id,
      title: title,
      shift: SharedConstants.SHIFTS[shift],
      saverity: severity,
      description: description,
      departmentName: department,
      rootCause: rootCause,
      cause: cause,
      createdBy: this.loggedUser.fullName,
      createdOn: new Date(),
      isActive: true,
      isDeleted: false,
    };
    const instance = {
      tenantId: this.companyKey,
      tableId: id,
      instanceKey: this.instanceKey,
      payload: nearMissData,
    };
    this.workFlowService.updateWorkFlowInstance(instance).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  deleteWorkFlowInstance(): void {
    this.workFlowService.deleteWorkFlowInstance(this.instanceKey).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getCustomUsersHierarchy(id: number, message: string): void {
    this.nearMissReportService
      .getCustomUsersHierarchyFromNearMiss(id)
      .subscribe({
        next: (response) => {
          this.setCustomUsersHierarchy(response, message);
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }
  setCustomUsersHierarchy(users: any[], message: string): void {
    const data = {
      instanceKey: this.instanceKey,
      isReminder: false,
      isEscalation: true,
      reminderUsers: [],
      escalationUsers: users?.map((ele) => {
        return {
          level: ele?.level,
          email: ele?.email,
          userId: ele?.userId,
        };
      }),
    };
    this.workFlowService.setCustomUserForReminderOrEscalation(data).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, message)
        );
        this.loadingService.stopLoading();

        this.cancelForm();
      },
      error: ({ error }) => {
        this.cancelForm();
        this.loadingService.stopLoading();
      },
    });
  }

  cancelForm(): void {
    this.router.navigate([
      SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_NEAR_MISS,
    ]);
  }
}
