import { LanguageEnum } from "src/@hodhod/common/enum";

export class Language {
    constructor(
        public id: string = '',
        public name = 'Arabic',
        public isoCode = LanguageEnum.Arabic,
        public isDefault: boolean = false
      ) {
      }
}
