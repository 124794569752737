import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { PenaltyManagementService } from 'src/backend/services/penalty-management.service';
import { PenaltyManagementComponent } from './pages/penalty-management/penalty-management.component';
import { PenaltyManagementRoutingModule } from './penalty-managements-routing.module';
import { PenaltyManagementListComponent } from './components/penalty-management-list/penalty-management-list.component';
import { PenaltyManagementViewComponent } from './pages/penalty-management-view/penalty-management-view.component';
import { ActionTrackerModule } from '../action-tracker/action-tracker.module';
import { NewPenaltyMangmentComponent } from './components/new-penalty-mangment/new-penalty-mangment.component';

@NgModule({
  declarations: [
    PenaltyManagementComponent,
    PenaltyManagementListComponent,
    PenaltyManagementViewComponent,
    NewPenaltyMangmentComponent,
  ],
  imports: [
    CommonModule,
    PenaltyManagementRoutingModule,
    SharedModule,
    ActionTrackerModule,
  ],
  providers: [PenaltyManagementService],
})
export class PenaltyManagementModule {}
