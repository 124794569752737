import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { id } from 'date-fns/locale';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';
import { GetHistoryLog } from 'src/backend/models/history-log/history-log.model';

@Injectable({
  providedIn: 'root'
})
export class HistoryLogService {
  netApiUrl: string = "";
  constructor(private http: HttpClient, private baseApi: BaseApi) { }

  getHistoryLog(data: any): Observable<DataMapper<GetHistoryLog>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.hazardStatus) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.hazardStatus;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.sourceId){
      if (params.length > 0){
        params += '&';
      }
      params += 'sourceId=' + data?.sourceId;
    }
    if (data?.sourceType){
      if (params.length > 0){
        params += '&';
      }
      params += 'sourceType=' + data?.sourceType; 
    }
    return this.http.get<DataMapper<GetHistoryLog>>(this.baseApi.encodeURL(Constants.API_HISTORY_GET_ALL + '?' + params));
  }

}
