import { LocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { LanguageService } from 'src/@hodhod/services/language.service';
import { EmailValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { AuthService } from 'src/backend/services/auth.service';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from 'src/@hodhod/config/config.service';
import { colorVariables } from 'src/@hodhod/components/config-panel/color-variables';
import { BaseApi } from 'src/backend/api/base-api';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  key = CryptoJS.enc.Utf8.parse('XBJaOfDEOTVSwOqE7kvIRvXzJYJ7rwms');
  iv = CryptoJS.enc.Utf8.parse('abcdefghijklmnop');
  public hidePassword = true;
  public form = new FormGroup({
    email: new FormControl('', [Validators.required, EmailValidator]),
    password: new FormControl('', [Validators.required]),
    rememberMe: new FormControl(false),
  });

  private translationsList: any = {};
  private destroy$ = new Subject();
  SharedConstant = SharedConstants;

  constructor(
    private loadingService: LoadingService,
    private translate: TranslateService,
    private languageService: LanguageService,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private baseApi: BaseApi
  ) {
    this.getSelectedLanguageText();
  }

  ngOnInit(): void {
    this.extractAndDecryptParameter();
    this.languageService.languageChanged.subscribe((language) => {
      this.getSelectedLanguageText();
    });
    this.logout()
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  onLoginButtonClicked() {
    const { email, password, rememberMe } = this.form.value;
    const signInInfo = { email, password };
    this.loadingService.startLoading(true, 'Signing In...');
    this.authService.signIn(signInInfo, rememberMe);
  }

  // private functions
  private getSelectedLanguageText() {
    this.translate
      .get(['Errors', 'Login'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  updateThemeColor(): void {
    this.configService.updateConfig({
      style: {
        colors: {
          primary: colorVariables['blue'],
        },
      },
    });
  }
  logout(): void {
    // this.close();
    this.baseApi.removeTokenFromStorage();
    this.baseApi.removeCompanyIdFromStorage();
    this.baseApi.removeUserSession();
    this.baseApi.removeUserMenusSession();
    this.baseApi.removeUserPermissionsSession();
    this.baseApi.removeOrganizationFromStorage();
    this.baseApi.removeCompanyFromStorage();
    this.updateThemeColor();
    // this.dialog.closeAll();
    // this.router.navigate([
    //   '/' + SharedConstants.AUTH + '/' + SharedConstants.SIGN_IN,
    // ]);
  }
  // object decrypt for sso
  private extractAndDecryptParameter() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const encryptedData = params['encrypted'];

      if (encryptedData) {
        const decryptedData = this.decryptObject(
          encryptedData,
          this.key,
          this.iv
        );

        if (decryptedData) {
          this.authService.signInWithUserToken(decryptedData);
        }
      }
    });
  }
  decryptObject(encrypted: string, key: any, iv: any): any {
    const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv });
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }
}
