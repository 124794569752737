<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.Department' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div body>
      <app-view-department></app-view-department>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
