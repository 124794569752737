<ng-container class="container">
  <mat-card class="dashboard-card border">
    <mat-card-header class="flex column">
      <!-- first row -->
      <div class="flex justify-space-between">
        <!-- card title -->
        <div class="header-text">
          <mat-card-title>
            {{
              isIot
                ? iotdashboardSetting.dashboardName
                : ("Dashboards." + dashboardSetting?.dashboardName | translate)
            }}
          </mat-card-title>
        </div>

        <!-- card actions -->
        <mat-card-actions class="actions">
          <div *ngIf="!iotdashboardSetting?.isAnalog && isIot">
            <mat-select
              class="filter-options"
              #selectParameters
              [(ngModel)]="visibleDigitalParameters"
              (closed)="updateDigitalParameters()"
              multiple
            >
              <mat-option
                *ngFor="let item of digitalDashboard?.digitalReadings"
                [value]="item.parameterID"
              >
                {{ item.parameterName }}
              </mat-option>
            </mat-select>
            <button
              *ngIf="hasIotReadPermission | permissionChecker"
              mat-button
              (click)="selectParameters.open(); openCalender = true"
            >
              <img
                class="fill-primary"
                src="../../../../assets/img/resources/settings-icon.svg"
                alt="select"
              />
            </button>
          </div>

          <div>
            <mat-select
              class="filter-options"
              #SelectFilter
              (selectionChange)="setFilterForDashboard($event.value, true)"
              [value]="
                isIot
                  ? iotdashboardSetting?.filterName
                  : dashboardSetting?.filterName
              "
            >
              <mat-option
                *ngFor="
                  let item of (dashboard?.dashboardName === 'ReportedPenalty'
                    ? penaltyFilterDashboards
                    : filterDashboards
                  ) | enumToArray
                "
                [value]="item.value"
                (click)="
                  item.key === 'CustomRange' ? onSelectCustomRange() : ''
                "
              >
                {{
                  isIot
                    ? ("Enum.DashboardFiltersIot." + item.value | translate)
                    : ("Enum.DashboardFilters." + item.value | translate)
                }}
              </mat-option>
            </mat-select>
            <button
              mat-button
              (click)="SelectFilter.open(); openCalender = true"
            >
              <img
                id="filterDashboard"
                class="fill-primary"
                src="../../../../assets/img/resources/calendar-icon-not-active.svg"
                alt="calender"
              />
            </button>
          </div>

          <button
            mat-button
            (click)="openTargetModal()"
            *ngIf="
              !isIot &&
              dashboard?.targetStatus === 'ACTIVE' &&
              (hasUpdateDashboardTargetPermission | permissionChecker)
            "
          >
            <img
              id="targetDashboard"
              class="fill-primary"
              src="../../../../assets/img/resources/settings-icon.svg"
              alt="settings"
            />
          </button>

          <button
            class="fill-primary"
            mat-button
            (click)="openBottomSheet()"
            *ngIf="!isIot"
          >
            <img
              id="fullScreenDashboard"
              src="../../../../assets/img/resources/fullscreen.svg"
              alt="full-screen"
            />
          </button>
        </mat-card-actions>
      </div>

      <!-- second row -->
      <div class="flex justify-space-between align-center mb-1">
        <!-- filter label -->
        <mat-card-subtitle class="subtitle">
          {{
            dashboardSetting?.filterName === "customRange" ||
            iotdashboardSetting?.filterName === "CustomRange"
              ? "From " +
                formatDate(
                  isIot
                    ? iotdashboardSetting?.filteredFrom
                    : dashboardSetting?.fromDate
                ) +
                " To " +
                formatDate(
                  isIot
                    ? iotdashboardSetting.filteredTo
                    : dashboardSetting?.toDate
                )
              : isIot
              ? ("Enum.DashboardFiltersIot." + iotdashboardSetting.filterName
                | translate)
              : ("Enum.DashboardFilters." + dashboardSetting?.filterName
                | translate)
          }}
        </mat-card-subtitle>

        <div class="flex">
          <div class="flex center" *ngIf="isIot">
            <div class="max">
              <span
                class="title"
                [style.color]="
                  digitalDashboard?.machineStatus == 'ACTIVE' ||
                  analogDashboard?.machineStatus == 'ACTIVE'
                    ? '#8BAC1A'
                    : '#D73550'
                "
              >
                {{
                  digitalDashboard?.machineStatus ||
                    analogDashboard?.machineStatus
                }}
              </span>
            </div>
          </div>
          <!-- target -->
          <div
            class="flex center"
            *ngIf="!isIot && dashboard?.targetStatus === 'ACTIVE'"
          >
            <div class="max">
              <span class="title">
                {{
                  dashboard?.targetType == "Upto"
                    ? ("Dashboards.MaxTarget" | translate)
                    : ("Dashboards.MinTarget" | translate)
                }}
              </span>
              <p>
                {{ dashboard?.target }}
                <span *ngIf="dashboard?.totalType == 'Percentage'">%</span>
              </p>
            </div>
          </div>

          <!-- total -->
          <div class="flex center" *ngIf="dashboard?.total">
            <div class="total">
              <span class="title">{{
                dashboard?.dashboardName == "AuditScore"
                  ? ("Dashboards.TotalTypes.Pass" | translate)
                  : ("Dashboards.TotalTypes." + dashboard?.totalLabel
                    | translate)
              }}</span>
              <p>
                {{
                  dashboard?.dashboardName == "AuditScore"
                    ? auditPassValue
                    : dashboard?.total
                }}
                <span *ngIf="dashboard?.totalType == 'Percentage'">%</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-card-header>
    <mat-divider></mat-divider>
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="loadingLabel | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <mat-card-content class="flex justify-center items-center">
        <div
          class="flex-auto flex flex-col items-center justify-center"
          *ngIf="
            !(dashboard?.drilldownChartLevels.level1.length > 0) &&
              !(analogDashboard?.analogReadings.length > 0) &&
              !(digitalDashboard?.digitalReadings.length > 0);
            else showChart
          "
        >
          <img
            style="width: 240px"
            src="assets/img/empty-dashboard.png"
            alt="not found"
          />
          <h6
            class="mt-2 text-center"
            style="
              color: #2b2c30;
              padding-top: 20px;
              opacity: 0.7;
              font-size: 0.9rem;
            "
          >
            <br />
            {{
              isIot
                ? ("Dashboard.EmptyDashboards.IoT" | translate)
                : ("Dashboard.EmptyDashboards." + dashboard?.dashboardName
                  | translate)
            }}
          </h6>
        </div>
        <ng-template #showChart>
          <app-digital-iot-dashboard
            class="overflow-auto h-full w-full"
            *ngIf="isIot && !iotdashboardSetting.isAnalog; else chart"
            [data]="digitalDashboard"
          ></app-digital-iot-dashboard>
          <ng-template #chart>
            <app-dashboard-chart-two
              [dashboard]="dashboard"
              [chartOptimized]="chartData"
              [chartLevel]="chartLevel"
              [isIot]="isIot"
            ></app-dashboard-chart-two>
          </ng-template>
        </ng-template>
      </mat-card-content>
    </app-state-section>
  </mat-card>
</ng-container>
