import { Component, Inject, Input, OnInit, SimpleChanges } from '@angular/core';
import {
  FormGroup,
  FormArray,
  Validators,
  FormBuilder,
  AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChecklistSectionType, OperatorType } from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { AddUpdateReasonComponent } from 'src/app/reporting/components/add-update-reason/add-update-reason.component';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
export interface QuestionType {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-add-checklist-section',
  templateUrl: './add-checklist-section.component.html',
  styleUrls: ['./add-checklist-section.component.scss'],
})
export class AddChecklistSectionComponent implements OnInit {
  @Input() checkList: any;
  @Input() editMode: string;
  public OperatorType = OperatorType;
  public valueVsTargetValue: number;
  checkListId: number;
  checkListForm: FormGroup; //
  loadingLabel: string;
  questions: any = ChecklistSectionType;
  machines: any[] = [];
  numOfSections: number;
  numOfQuestions: number;
  parameters: any[] = [];
  public checkListData: any[] = [];
  public translationsList: any = {};
  ranges = [
    { id: 0, value: '0' },
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
  ];
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public valueVsTargetOperator: OperatorType = OperatorType.Equale;
  answer: number = 0;
  public range: number[] = [];
  private destroy$ = new Subject();
  formMode: string;
  machineId: number;
  sectionListFormArray: FormArray;
  questionTypes = ChecklistSectionType;
  hasAuditCreatePermission = ApplicationPermission.AUDIT_CHECKLIST_WRITE;
  hasWorkConditionsCreatePermission =
    ApplicationPermission.WORK_CONDITIONS_CREATE;
  hasInspectionCreatePermission =
    ApplicationPermission.INSPECTION_CHECKLIST_WRITE;

  constructor(
    private checklistService: ChecklistSectionService,
    private feedBackService: AsyncFeedbackService,
    private translate: TranslateService,
    private router: Router,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute
  ) {
    this.translate
      .get(['Errors', 'Success', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.checkListForm = this.fb.group({
      sections: this.fb.array([this.createSectionForm()]),
      checklistName: ['', [Validators.required, noWhitespaceValidator]],
      checklisttype: ['', [Validators.required]],
      minimumScore: ['', []],
    });
    this.checkListForm
      .get('checklisttype')
      .valueChanges.subscribe((checklisttype) => {
        const minimumScoreControl = this.checkListForm.get('minimumScore');
        if (checklisttype === 'Audit') {
          minimumScoreControl.setValidators([
            Validators.required,
            Validators.min(0),
            Validators.max(100),
          ]);
        } else {
          minimumScoreControl.setValidators([]);
        }
        minimumScoreControl.updateValueAndValidity();
      });
    this.sectionListFormArray = this.checkListForm.get('sections') as FormArray;
    this.numOfSections = this.sectionListFormArray.value.length;
    this.getMachines();
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id');
        const mode: string = params.get('mode');
        this.checkListId = parseInt(id, 10);
        this.formMode = mode;
      }
    });
  }
  sectionsValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const sectionsArray = control.get('sections') as FormArray;

    if (sectionsArray && sectionsArray.length > 0) {
      return null; // Valid
    } else {
      return { sectionsRequired: true }; // Invalid
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checkList'].currentValue) {
      this.checkListData = this.checkList?.section;
      if (this.checkListForm && this.checkListData) {
        this.checkListForm
          .get('checklistName')
          ?.setValue(this.checkList?.title);
        this.checkListForm.get('checklisttype')?.setValue(this.checkList?.type);
        this.checkListForm
          .get('minimumScore')
          ?.setValue(this.checkList?.minimumScore);
        this.buildForm();
        this.numOfSections = this.sectionListFormArray.value.length;
      }
    }
  }
  buildForm(): void {
    const sections = this.checkListData;
    if (this.editMode == 'edit' && sections.length === 0) {
      this.checkListForm.setControl(
        'sections',
        this.fb.array([this.createSectionForm()])
      );
    } else {
      this.loadingService.startLoading(true, '');
      this.addSectionsFormGroup(sections);
      this.checkListForm.patchValue({
        sections: this.formatCheckListData(this.checkListData),
      });
      if (this.editMode !== 'edit') {
        this.checkListForm.disable();
      }
      this.loadingService.stopLoading();
    }
  }

  get repeatSectionFormGroup(): any {
    return this.checkListForm.get('sections') as FormArray;
  }
  repeatQuestionsFormGroup(i: number): any {
    return this.checkListForm
      .get('sections')
      ['controls'][i].get('questions') as FormArray;
  }
  createSectionForm(): FormGroup {
    return this.fb.group({
      sectionId: [0],
      title: ['', Validators.required],
      questionType: [''],
      questions: this.fb.array([this.createQuestionForm()]),
    });
  }
  createQuestionForm(): FormGroup {
    return this.fb.group({
      questionId: [0],
      questionText: ['', Validators.required],
      answer: ['', Validators.required],
      target: [''],
      parameters: [[]],
    });
  }

  getMachines() {
    this.checklistService.getMachines().subscribe((res) => {
      this.machines = res;
    });
  }
  onMachineChange(event: any, i: number, j: number) {
    this.machineId = event?.value;
    this.getParameters(this.machineId, i, j);
  }
  getParameters(id: number, i: number, j: number) {
    this.checklistService.getParameters(id).subscribe((res) => {
      this.checkListForm
        .get('sections')
        ['controls'][i].get('questions')
        ['controls'][j].get('parameters')
        ?.setValue(res);
    });
  }

  onAddSection(): void {
    this.sectionListFormArray.push(this.createSectionForm());
    this.numOfSections = this.sectionListFormArray.value.length;
  }
  addQuestion(index: number): void {
    this.repeatQuestionsFormGroup(index).push(this.createQuestionForm());
    const questiontype =
      this.checkListForm.get('sections').value[index].questionType;
    if (questiontype === 'Value Vs Target') {
      for (
        let j = 0;
        j <
        this.checkListForm.get('sections')['controls'][index].get('questions')[
          'controls'
        ]?.length;
        j++
      ) {
        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.setValidators(Validators.required);

        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.updateValueAndValidity();
      }
    } else if (questiontype === 'Select from range') {
      for (
        let j = 0;
        j <
        this.checkListForm.get('sections')['controls'][index].get('questions')[
          'controls'
        ]?.length;
        j++
      ) {
        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('answer')
          ?.clearValidators();

        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('answer')
          ?.updateValueAndValidity();
        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.clearValidators();

        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.updateValueAndValidity();
      }
    } else {
      for (
        let j = 0;
        j <
        this.checkListForm.get('sections')['controls'][index].get('questions')[
          'controls'
        ]?.length;
        j++
      ) {
        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.clearValidators();

        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.updateValueAndValidity();
      }
    }
  }
  onSeletSectionType(questionType: string, index: number): void {
    this.repeatQuestionsFormGroup(index).clear();
    this.addQuestion(index);
    if (questionType === 'Value Vs Target') {
      for (
        let j = 0;
        j <
        this.checkListForm.get('sections')['controls'][index].get('questions')[
          'controls'
        ]?.length;
        j++
      ) {
        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.setValidators(Validators.required);

        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.updateValueAndValidity();
      }
    } else if (questionType === 'Select from range') {
      for (
        let j = 0;
        j <
        this.checkListForm.get('sections')['controls'][index].get('questions')[
          'controls'
        ]?.length;
        j++
      ) {
        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('answer')
          ?.clearValidators();

        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('answer')
          ?.updateValueAndValidity();
        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.clearValidators();

        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.updateValueAndValidity();
      }
    } else {
      for (
        let j = 0;
        j <
        this.checkListForm.get('sections')['controls'][index].get('questions')[
          'controls'
        ]?.length;
        j++
      ) {
        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.clearValidators();

        this.checkListForm
          .get('sections')
          ['controls'][index].get('questions')
          ['controls'][j].get('target')
          ?.updateValueAndValidity();
      }
    }
  }
  onRemoveSection(index: number): void {
    if (this.sectionListFormArray.value.length > 1) {
      this.sectionListFormArray.removeAt(index);
      this.numOfSections -= 1;
    }
  }
  removeQuestion(i: number, j: number): void {
    if (this.repeatQuestionsFormGroup(i).value.length > 1) {
      this.repeatQuestionsFormGroup(i).removeAt(j);
    }
  }
  isCreateMode() {
    return this.formMode === 'create';
  }
  save() {
    if (this.editMode !== 'edit') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createChecklist();
          }
        });
    } else if (this.editMode === 'edit') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['Role']['UpdateConfirm']['Title'],
            this.translationsList['Role']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.updateChecklist();
          }
        });
    }
  }
  formatCheckListData(data: any[]): any[] {
    const formatedData = data.map((section, i) => {
      return {
        sectionId: section?.sectionId,
        title: section?.sectionTitle,
        questionType: section?.sectionType,
        questions: section?.questions?.map((question, j) => {
          if (section?.sectionType === ChecklistSectionType.MachineParameter) {
            this.checklistService
              .getParameters(parseInt(question?.answer, 10))
              .subscribe((res) => {
                this.checkListForm
                  .get('sections')
                  ['controls'][i].get('questions')
                  ['controls'][j].get('parameters')
                  ?.setValue(res);
              });
          }
          return {
            questionId: question?.questionId,
            questionText:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.questionTitle, 10)
                : question?.questionTitle,
            answer:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.Range
                ? question?.answer
                  ? parseInt(question?.answer, 10)
                  : ''
                : section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer
                  ? question?.answer.split(' ')[0]
                  : ''
                : question?.answer,
            target:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer
                  ? question?.answer.split(' ')[1]
                  : ''
                : '',
            parameters: [],
          };
        }),
      };
    });
    return formatedData;
  }
  createChecklist() {
    const { checklistName, checklisttype, minimumScore } =
      this.checkListForm.value;
    const sections = this.checkListForm.value.sections;
    const data = {
      title: checklistName,
      type: checklisttype,
      minimumScore: checklisttype !== 'WorkCondition' ? minimumScore : 100,
      section: sections?.map((section) => {
        return {
          sectionId: 0,
          sectionTitle: section?.title,
          sectionType: section?.questionType,
          questions: section?.questions?.map((question) => {
            return {
              questionId: 0,
              questionTitle: question?.questionText,
              answer:
                section?.questionType === ChecklistSectionType.ValueVsTarget
                  ? question?.answer + ' ' + question?.target
                  : question?.answer,
            };
          }),
        };
      }),
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.checklistService.PostCheckList(data).subscribe({
      next: () => {
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['SuccessMessage']
          )
        );
        this.goBack();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  updateChecklist() {
    const { checklistName, checklisttype, minimumScore } =
      this.checkListForm.value;
    const sections = this.checkListForm.value.sections;
    const data = {
      id: +this.route.snapshot.params['id'],
      title: checklistName,
      type: checklisttype,
      minimumScore: checklisttype !== 'WorkCondition' ? minimumScore : 100,
      section: sections?.map((section) => {
        return {
          sectionId: 0,
          sectionTitle: section?.title,
          sectionType: section?.questionType,
          questions: section?.questions?.map((question) => {
            return {
              questionId: 0,
              questionTitle: question?.questionText,
              answer:
                section?.questionType === ChecklistSectionType.ValueVsTarget
                  ? question?.answer + ' ' + question?.target
                  : question?.answer,
            };
          }),
        };
      }),
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.checklistService.updateChecklist(data).subscribe({
      next: (res) => {
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res.message)
        );
        this.goBack();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  goBack(): void {
    this.router.navigate([
      '/' + SharedConstants.SAFETY + '/' + SharedConstants.MANAGE_CHECKLIST,
    ]);
  }

  addSectionsFormGroup(sections: any[]): void {
    this.sectionListFormArray.clear();
    for (let index = 0; index < sections.length; index++) {
      this.sectionListFormArray.push(this.createSectionForm());
      this.addQuestionsFormGroup(sections[index]?.questions?.length, index);
    }
  }
  addQuestionsFormGroup(numOfGroups: number, i: number): void {
    this.repeatQuestionsFormGroup(i).clear();
    for (let index = 0; index < numOfGroups; index++) {
      this.repeatQuestionsFormGroup(i).push(this.createQuestionForm());
    }
  }
  isUpdateMode() {
    return this.formMode === 'update';
  }
}
