<div class="list-wrapper" *ngIf="dataSource.length > 0">
  <div class="list">
    <div class="list-header">
      <h3>{{'VideoAnalyticsDashboard.ViolationList.ViolationList' | translate}}</h3>
    </div>
    <div class="list-content">
      <div class="list-body" *ngFor="let item of dataSource" (click)="onRowClcik(item)" style="cursor: pointer;">
        <div class="status-wrapper">
          <span
            [ngClass]="{'status-btn-green' : item.status === 'New' , 'status-btn-red' : item.status === 'Close'}">{{item.status}}</span>
        </div>
        <div class="list-tag">
          <p class="text-xs"> {{'VideoAnalyticsDashboard.ViolationList.Department' | translate}}:</p>
        </div>
        <div class="list-value">
          <p class="text-xs">{{item.departmentName}}</p>
        </div>
        <div class="list-tag">
          <p class="text-xs"> {{'VideoAnalyticsDashboard.ViolationList.Violation' | translate}}:</p>
        </div>
        <div class="list-value">
          <p class="text-xs">{{item.violation}}</p>
        </div>
        <div class="list-tag">
          <p class="text-xs"> {{'VideoAnalyticsDashboard.ViolationList.Offender' | translate}}:</p>
        </div>
        <div class="list-value">
          <p class="text-xs">{{item.offender}}</p>
        </div>
        <div class="list-tag">
          <p class="text-xs"> {{'VideoAnalyticsDashboard.ViolationList.Time' | translate}}:</p>
        </div>
        <div class="list-value">
          <p class="text-xs">{{item.createdOn |
            date: 'dd-MM-yyyy h:mm a'}}</p>
        </div>
      </div>
    </div>
  </div>
</div>