<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.ManageIdCard' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="loadingLabel | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <div>
        <div class="flex justify-end mr-1 mb-4">
          <app-basic-button
            *ngIf="this.formMode != 'view'"
            [btnType]="'Add text-lg'"
            [btnText]="this.formMode === 'create' ? 'Submit' : 'Update'"
            [isDisabled]="!idCardForm.valid"
            (onClick)="
              this.formMode === 'create' ? createIdCard() : updateIdCard()
            "
          ></app-basic-button>
          <app-basic-button
            *ngIf="
              formMode === 'view' && (hasApprovePermission | permissionChecker)
            "
            [btnType]="'Add text-lg'"
            [btnText]="'Approve'"
            [isDisabled]="false"
            (onClick)="openApproveModel()"
          ></app-basic-button>
          <app-basic-button
            *ngIf="
              formMode === 'view' && (hasApprovePermission | permissionChecker)
            "
            [btnType]="'Add text-lg'"
            [btnText]="'Reject'"
            [isDisabled]="false"
            (onClick)="openRejectModel()"
          ></app-basic-button>
        </div>
        <app-expandable-panel
          [expanded]="true"
          #expandablePanel
          [title]="'ManageIdCard.BasicInformation' | translate"
        >
          <div body class="flex column">
            <form [formGroup]="idCardForm" class="flex-1 pr-5">
              <div class="flex justify-between flex-wrap-reverse">
                <div class="flex-1 pr-5">
                  <div class="flex column">
                    <mat-label class="field-label">
                      {{ "ManageIdCard.Name" | translate }}
                      <span
                        [ngClass]="
                          idCardForm.invalid &&
                          idCardForm.get('name').hasError('required')
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        >*</span
                      >
                    </mat-label>
                    <mat-form-field class="input-field" appearance="outline">
                      <mat-select
                        ngxMatSelect
                        #msf1="ngxMatSelect"
                        [displayMember]="'fullName'"
                        [valueMember]="'userId'"
                        [source]="responsibles"
                        [useInfiniteScroll]="true"
                        formControlName="name"
                        [multiple]="false"
                        [hasPermission]="
                          hasCreateUserPermissions | permissionChecker
                        "
                        [title]="'users'"
                        [placeholder]="'Placeholder.AddText' | translate"
                        [manageRoute]="'/user-management/users'"
                        formControlName="name"
                        [disabled]="this.formMode === 'view'"
                      >
                        <mat-option
                          *ngFor="let user of msf1.filteredSource"
                          [value]="user.userId"
                          >{{ user.fullName }}
                        </mat-option>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          idCardForm.invalid &&
                          idCardForm.get('name').hasError('required')
                        "
                      >
                        <div class="flex justify-flex-start align-flex-center">
                          <img
                            class="error-img"
                            src="assets/img/resources/error-icon.svg"
                          />
                          <span>{{ "Errors.Required" | translate }}</span>
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex column">
                    <mat-label class="field-label"
                      >{{ "ManageIdCard.JobTitle" | translate }}
                      <span
                        [ngClass]="
                          idCardForm.invalid &&
                          idCardForm.get('jobTitle').hasError('required')
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        >*</span
                      >
                    </mat-label>
                    <mat-form-field class="input-field" appearance="outline">
                      <input
                        [placeholder]="'Placeholder.AddText' | translate"
                        matInput
                        type="text"
                        formControlName="jobTitle"
                        [readonly]="this.formMode === 'view'"
                      />
                      <mat-error
                        *ngIf="
                          idCardForm.invalid &&
                          idCardForm.get('jobTitle').hasError('required')
                        "
                      >
                        <div class="flex justify-flex-start align-flex-center">
                          <img
                            class="error-img"
                            src="assets/img/resources/error-icon.svg"
                          />
                          <span>{{ "Errors.Required" | translate }}</span>
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="flex column">
                    <mat-label class="field-label"
                      >{{ "ManageIdCard.PhoneNumber" | translate }}
                      <span
                        [ngClass]="
                          idCardForm.invalid &&
                          idCardForm.get('phoneNumber').hasError('required')
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        >*</span
                      >
                    </mat-label>
                    <mat-form-field class="input-field" appearance="outline">
                      <input
                        [placeholder]="'Placeholder.AddText' | translate"
                        matInput
                        type="number"
                        formControlName="phoneNumber"
                        [readonly]="this.formMode === 'view'"
                      />
                      <mat-error
                        *ngIf="
                          idCardForm.invalid &&
                          idCardForm.get('phoneNumber').hasError('required')
                        "
                      >
                        <div class="flex justify-flex-start align-flex-center">
                          <img
                            class="error-img"
                            src="assets/img/resources/error-icon.svg"
                          />
                          <span>{{ "Errors.Required" | translate }}</span>
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="flex column">
                    <mat-label class="field-label"
                      >{{ "ManageIdCard.Department" | translate }}
                      <span
                        [ngClass]="
                          idCardForm.invalid &&
                          idCardForm.get('department').hasError('required')
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        >*</span
                      >
                    </mat-label>
                    <mat-form-field class="input-field" appearance="outline">
                      <mat-select
                        ngxMatSelect
                        #msf2="ngxMatSelect"
                        [displayMember]="'departmentName'"
                        [valueMember]="'departmentId'"
                        [source]="departments"
                        [useInfiniteScroll]="true"
                        [multiple]="false"
                        [hasSearchBox]="false"
                        [hasPermission]="
                          hasDepartmentPermission | permissionChecker
                        "
                        [title]="'departments'"
                        [placeholder]="'Placeholder.Select' | translate"
                        formControlName="department"
                        [disabled]="this.formMode === 'view'"
                        [manageRoute]="'/company-setup/departments'"
                      >
                        <mat-option
                          *ngIf="msf2.filteredSource.length === 0"
                          style="height: 0"
                        ></mat-option>
                        <mat-optgroup
                          *ngFor="let group of departments"
                          [label]="group.parentName"
                        >
                          <mat-option
                            *ngFor="let detail of group.detail"
                            [value]="detail.departmentId"
                          >
                            {{ detail.departmentName }}
                          </mat-option>
                        </mat-optgroup>
                      </mat-select>
                      <mat-error
                        *ngIf="
                          idCardForm.invalid &&
                          idCardForm.get('department').hasError('required')
                        "
                      >
                        <div class="flex justify-flex-start align-flex-center">
                          <img
                            class="error-img"
                            src="assets/img/resources/error-icon.svg"
                          />
                          <span>{{ "Errors.Required" | translate }}</span>
                        </div>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex" *ngIf="this.formMode != 'create'">
                    <ngx-avatars
                      class="logo box ml-40 cursor-pointer"
                      size="190"
                      [src]="profilePath"
                      [name]="'Profile'"
                      [textSizeRatio]="2"
                      [round]="true"
                      [initialsSize]="2"
                      [matTooltip]="'View Image'"
                      (clickOnAvatar)="
                        viewImgDialog(profilePath, '', 'profile')
                      "
                    ></ngx-avatars>
                  </div>
                  <div class="flex column" *ngIf="this.formMode != 'view'">
                    <mat-label class="field-label">{{
                      "ManageIdCard.ProfileImage" | translate
                    }}</mat-label>
                    <file-upload
                      formControlName="idCardProfileImage"
                      (dragover)="$event.preventDefault()"
                      (drop)="checkProfileExtension($event)"
                      [multiple]="false"
                      [animation]="true"
                      [accept]="'file_extension|image/*'"
                      (change)="
                        formMode === 'view'
                          ? onReadProfile($event)
                          : checkProfileExtension($event)
                      "
                    >
                    </file-upload>
                    <mat-error
                      *ngIf="
                        idCardForm.invalid &&
                        idCardForm
                          .get('idCardProfileImage')
                          .hasError('required')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex column" *ngIf="this.formMode != 'view'">
                  <mat-label class="field-label">{{
                    "ManageIdCard.BasicDocuments" | translate
                  }}</mat-label>
                  <file-upload
                    formControlName="basicDocument"
                    (change)="checkBasicDocExtension($event)"
                    (dragover)="$event.preventDefault()"
                    (drop)="checkBasicDocExtension($event)"
                    [multiple]="true"
                    [animation]="true"
                    [accept]="'file_extension|image/*'"
                  ></file-upload>
                </div>
                <div
                  class="flex flex-wrap gap-2 my-4"
                  *ngIf="this.formMode != 'create'"
                >
                  <ngx-avatars
                    *ngFor="let b of basicDoc"
                    class="cursor-pointer shadow"
                    size="80"
                    [src]="b.filePath"
                    [cornerRadius]="10"
                    [name]="'Basic Documents'"
                    [textSizeRatio]="2"
                    [round]="false"
                    [initialsSize]="2"
                    [matTooltip]="'View Image'"
                    [borderColor]="'#131C4E'"
                    (clickOnAvatar)="
                      viewImgDialog(b.filePath, b.documentId, 'BASIC')
                    "
                  ></ngx-avatars>
                </div>
              </div>
            </form>
          </div>
        </app-expandable-panel>
      </div>
      <div class="mt-5">
        <app-expandable-panel
          [expanded]="true"
          #expandablePanel
          [title]="'IdCardDetails.SiteAccessInformation' | translate"
        >
          <div body class="flex column">
            <form [formGroup]="idCardForm" class="flex-1 pr-5">
              <div formArrayName="siteAccess">
                <div
                  *ngFor="
                    let item of siteAccessControls.controls;
                    let i = index
                  "
                  [formGroupName]="i"
                >
                  <div class="flex justify-between flex-wrap-reverse">
                    <div class="flex-1 pr-5">
                      <div class="flex column">
                        <mat-label class="field-label">{{
                          "ManageIdCard.Company" | translate
                        }}</mat-label>
                        <mat-form-field
                          class="input-field"
                          appearance="outline"
                        >
                          <mat-select
                            (selectionChange)="
                              getDepartmentsByCompanyCode($event, i)
                            "
                            [placeholder]="
                              'Placeholder.SelectCompany' | translate
                            "
                            formControlName="siteAccessCompany"
                            [disabled]="this.formMode === 'view'"
                          >
                            <mat-option
                              *ngFor="let comp of companies"
                              [value]="comp.companyCode"
                            >
                              {{ comp.companyName }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div *ngIf="formArrayLength === i + 1">
                        <button
                          class="flex align-center cursor-pointer w-48 mb-4"
                          (click)="addFormGroup()"
                          *ngIf="this.formMode != 'view'"
                        >
                          <mat-icon
                            class="text-primary"
                            svgIcon="mat:add_circle_outline"
                          ></mat-icon>
                          <span class="text-primary font-semibold ml-1">{{
                            "ManageIdCard.AddMoreSiteAccess" | translate
                          }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="flex-1">
                      <div class="flex column">
                        <mat-label class="field-label">{{
                          "ManageIdCard.Department" | translate
                        }}</mat-label>
                        <mat-form-field
                          class="input-field"
                          appearance="outline"
                        >
                          <mat-select
                            ngxMatSelect
                            #msf3="ngxMatSelect"
                            [displayMember]="'departmentName'"
                            [valueMember]="'departmentId'"
                            [source]="departmentsByCompany[i]"
                            [useInfiniteScroll]="true"
                            [multiple]="false"
                            [hasPermission]="
                              hasDepartmentPermission | permissionChecker
                            "
                            [hasSearchBox]="false"
                            [title]="'departments'"
                            [placeholder]="'Placeholder.Select' | translate"
                            formControlName="siteAccessDepartment"
                            [disabled]="this.formMode === 'view'"
                            [manageRoute]="'/company-setup/departments'"
                          >
                            <mat-option
                              *ngIf="msf3.filteredSource.length === 0"
                              style="height: 0"
                            ></mat-option>
                            <mat-optgroup
                              *ngFor="let group of msf3.filteredSource"
                              [label]="group.parentName"
                            >
                              <mat-option
                                *ngFor="let detail of group.detail"
                                [value]="detail.departmentId"
                              >
                                {{ detail.departmentName }}
                              </mat-option>
                            </mat-optgroup>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div
                        *ngIf="showRemoveBtn && formArrayLength != i + 1"
                        class="float-right"
                      >
                        <button
                          class="flex align-center cursor-pointer w-48 mb-4"
                          (click)="reemoveSiteAccessForm(i)"
                          *ngIf="this.formMode != 'view'"
                        >
                          <mat-icon
                            class="text-primary"
                            svgIcon="mat:delete"
                          ></mat-icon>
                          <span class="text-primary font-semibold ml-1">{{
                            "ManageIdCard.RemoveSite" | translate
                          }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex-1">
                <div class="flex-row">
                  <mat-label class="field-label">{{
                    "ManageIdCard.Comment" | translate
                  }}</mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <textarea
                      matInput
                      rows="5"
                      formControlName="siteAccessComment"
                      [placeholder]="'Placeholder.Comment' | translate"
                      matInput
                      [readonly]="this.formMode === 'view'"
                    >
                    </textarea>
                  </mat-form-field>
                </div>
              </div>
            </form>
          </div>
        </app-expandable-panel>
      </div>
      <div class="mt-5">
        <app-expandable-panel
          [expanded]="true"
          #expandablePanel
          [title]="'IdCardDetails.CarAccessInformation' | translate"
        >
          <div body class="flex column">
            <form [formGroup]="idCardForm" class="flex-1 pr-5">
              <div class="flex justify-between flex-wrap-reverse">
                <div class="flex-1 pr-5">
                  <div class="flex column">
                    <mat-label class="field-label">{{
                      "ManageIdCard.CarModel" | translate
                    }}</mat-label>
                    <mat-form-field class="input-field" appearance="outline">
                      <input
                        [placeholder]="'ManageIdCard.Insert' | translate"
                        matInput
                        type="text"
                        formControlName="carModel"
                        [readonly]="this.formMode === 'view'"
                      />
                    </mat-form-field>
                  </div>
                  <div class="flex column">
                    <mat-label class="field-label">{{
                      "ManageIdCard.PlateNumber" | translate
                    }}</mat-label>
                    <mat-form-field class="input-field" appearance="outline">
                      <input
                        [placeholder]="'ManageIdCard.Insert' | translate"
                        matInput
                        type="text"
                        formControlName="plateNumber"
                        [readonly]="this.formMode === 'view'"
                      />
                    </mat-form-field>
                  </div>
                </div>
                <div class="flex-1">
                  <div class="flex column">
                    <mat-label class="field-label">{{
                      "ManageIdCard.CarColor" | translate
                    }}</mat-label>
                    <mat-form-field class="input-field" appearance="outline">
                      <input
                        [placeholder]="'ManageIdCard.Insert' | translate"
                        matInput
                        type="text"
                        formControlName="carColor"
                        [readonly]="this.formMode === 'view'"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex-row">
                  <mat-label class="field-label">{{
                    "ManageIdCard.Comment" | translate
                  }}</mat-label>
                  <mat-form-field class="input-field" appearance="outline">
                    <textarea
                      matInput
                      rows="5"
                      formControlName="carComment"
                      [placeholder]="'Placeholder.Comment' | translate"
                      [readonly]="this.formMode === 'view'"
                    >
                    </textarea>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex column" *ngIf="this.formMode != 'view'">
                  <mat-label class="field-label">{{
                    "ManageIdCard.CarDocuments" | translate
                  }}</mat-label>
                  <file-upload
                    formControlName="carDocument"
                    [multiple]="true"
                    [animation]="true"
                    (change)="checkCarDocExtension($event)"
                    (dragover)="$event.preventDefault()"
                    (drop)="checkCarDocExtension($event)"
                    [accept]="'file_extension|image/*'"
                  ></file-upload>
                </div>
                <div
                  class="flex flex-wrap gap-2 my-4"
                  *ngIf="this.formMode != 'create'"
                >
                  <ngx-avatars
                    *ngFor="let b of carDoc"
                    class="cursor-pointer shadow"
                    size="130"
                    [src]="b.filePath"
                    [name]="'Car Documents'"
                    [textSizeRatio]="2"
                    [round]="false"
                    [cornerRadius]="10"
                    [initialsSize]="2"
                    [matTooltip]="'View Image'"
                    (clickOnAvatar)="
                      viewImgDialog(b.filePath, b.documentId, 'CAR')
                    "
                  >
                  </ngx-avatars>
                </div>
              </div>
            </form>
          </div>
        </app-expandable-panel>
      </div>

      <div class="mt-5" *ngIf="formMode === 'view'">
        <app-expandable-panel
          [expanded]="true"
          #expandablePanel
          [title]="'IdCardDetails.History' | translate"
        >
          <table
            body
            @stagger
            [dataSource]="dataSource"
            class="w-full"
            mat-table
            matSort
          >
            <ng-container
              *ngFor="let column of columns; trackBy: trackByProperty"
            >
              <ng-container
                *ngIf="column.type === 'text'"
                [matColumnDef]="column.property"
              >
                <th
                  *matHeaderCellDef
                  class="uppercase"
                  mat-header-cell
                  mat-sort-header
                >
                  {{ "RenewIdCard.View.Table." + column.label | translate }}
                </th>
                <td
                  *matCellDef="let row"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                  {{ row[column.property] }}
                  <span
                    *ngIf="column.property === 'createdBy' && row?.isDefault"
                    class="bg-primary text-white rounded-full p-1 font-normal px-3"
                    >Default</span
                  >
                </td>
              </ng-container>
              <ng-container
                *ngIf="
                  column.type === 'boolean' && column.property === 'action'
                "
                [matColumnDef]="column.property"
              >
                <th
                  *matHeaderCellDef
                  class="uppercase"
                  mat-header-cell
                  mat-sort-header
                >
                  {{ "RenewIdCard.View.Table." + column.label | translate }}
                </th>
                <td
                  *matCellDef="let row"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                  <span [attr.id]="row[column.property]">{{
                    "Enum.IdCardActions." + row[column.property] | translate
                  }}</span>
                </td>
              </ng-container>
              <ng-container
                *ngIf="column.type === 'date'"
                [matColumnDef]="column.property"
              >
                <th
                  *matHeaderCellDef
                  class="uppercase"
                  mat-header-cell
                  mat-sort-header
                >
                  {{ "RenewIdCard.View.Table." + column.label | translate }}
                </th>
                <td
                  *matCellDef="let row"
                  [ngClass]="column.cssClasses"
                  mat-cell
                >
                  {{ row[column.property] | date : "dd-MM-yyyy - hh:mm a" }}
                </td>
              </ng-container>
            </ng-container>

            <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
            <tr
              *matRowDef="let row; columns: visibleColumns"
              @fadeInUp
              class="hover:bg-hover trans-ease-out cursor-pointer"
              mat-row
            ></tr>
          </table>
          <div
            *ngIf="dataSource?.filteredData?.length === 0"
            @scaleFadeIn
            class="flex-auto flex flex-col items-center justify-center"
          >
            <img src="assets/img/resources/empty-state.svg" alt="not found" />
            <h2 class="headline m-0 text-center text-primary">
              {{ "General.EmptyTableList" | translate }}...
            </h2>
          </div>

          <mat-paginator
            [class.hidden]="dataSource.filteredData.length === 0"
            [pageSizeOptions]="pageSizeOptions"
            [pageSize]="pageSize"
            showFirstLastButtons
            class="sticky left-0"
          ></mat-paginator>
        </app-expandable-panel>
      </div>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>

<ng-template #viewImageDialog>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">View Image</h2>
    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

  <mat-dialog-content class="flex flex-col flex-wrap justify-between">
    <img src="{{ viewImagePath }}" class="object-fill w-full h-auto" />
  </mat-dialog-content>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      *ngIf="docType != 'profile' && formMode != 'view'"
      [btnType]="'Delete'"
      [btnText]="'Delete'"
      (onClick)="deleteDocument(docId, docType)"
    ></app-basic-button>
  </div>
</ng-template>

<ng-template #approveRejDialouge>
  <app-state-section
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <div class="flex items-center" mat-dialog-title>
      <h2 class="headline m-0 flex-auto">
        {{ formName === "Approve" ? "Approve IdCard" : "Reject IdCard" }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content>
      <form [formGroup]="approveForm">
        <mat-label class="field-label">{{
          "ManageIdCard.Comment" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <textarea
            matInput
            rows="5"
            formControlName="approveComment"
            [placeholder]="'Placeholder.Comment' | translate"
          >
          </textarea>
          <mat-error
            *ngIf="
              approveForm.invalid &&
              approveForm.get('approveComment').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <div class="flex justify-end items-center mt-4">
      <app-basic-button
        [btnType]="'Cancel'"
        [btnText]="'Cancel'"
        (onClick)="closeDialog()"
      ></app-basic-button>
      <app-basic-button
        [btnType]="'Add'"
        [isDisabled]="!approveForm.valid"
        [btnText]="formName === 'Approve' ? 'Approve' : 'Reject'"
        (onClick)="formName === 'Approve' ? approveIdCard() : rejectIdCard()"
      ></app-basic-button>
    </div>
  </app-state-section>
</ng-template>
