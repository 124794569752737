import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, Observable, startWith, takeUntil } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import {
  AllSafetyAnnouncementReceiver,
  SafetyAnnouncementReceiver,
} from '../../models/safety-announcement-receiver.model';
import { SafetyAnnouncementService } from 'src/backend/services/safety-announcement/safety-announcement.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SafetyAnnouncementType } from 'src/@hodhod/common/enum';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { ConfirmationComponent } from 'src/app/shared/components/confirmation/confirmation.component';

@Component({
  selector: 'app-add-update-safety-announcement',
  templateUrl: './add-update-safety-announcement.component.html',
  styleUrls: ['./add-update-safety-announcement.component.scss'],
})
export class AddUpdateSafetyAnnouncementComponent
  extends BaseComponent
  implements OnInit
{
  @ViewChild('receiverInput') receiverInput: ElementRef<HTMLInputElement>;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  loadingLabel: string;
  safetyAnnouncementForm!: FormGroup;

  selectedUsers = [];
  receiverCtrl = new FormControl(null);
  filteredReceivers: Observable<AllSafetyAnnouncementReceiver[]>;
  selectedReceivers: SafetyAnnouncementReceiver[] = [];
  allReceivers: AllSafetyAnnouncementReceiver[] = [];
  addOnBlur = true;
  tags = [];
  files: any[] = [];
  oldFiles: any[] = [];

  safetyAnnouncementType = SafetyAnnouncementType;
  translationsList: any = {};
  public selectedFile;

  selectedAnnouncement: any;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialogRef<AddUpdateSafetyAnnouncementComponent>,
    private confirmDialog: MatDialogRef<ConfirmationComponent>,
    private feedBackService: AsyncFeedbackService,
    private safetyAnnouncementService: SafetyAnnouncementService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: { formMode: string; announcementId?: number }
  ) {
    super();
    this.filterData();
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    if (this.data?.announcementId) {
      this.getAnnouncementById();
    }
    this.safetyAnnouncementForm = this.fb.group({
      receivers: [this.selectedReceivers, Validators.required],
      tags: [this.tags],
      type: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      attachments: new FormArray([]),
    });

    this.getReceivers();
  }

  getAnnouncementById() {
    this.safetyAnnouncementService
      .GetSafetyAnnouncementById(this.data.announcementId)
      .subscribe((response) => {
        this.selectedAnnouncement = response;
        this.files = response?.theattachments;
        this.oldFiles = [...response?.theattachments];
        this.initFormUpdate(response);
      });
  }

  initFormUpdate(data) {
    this.selectedReceivers = data.receivers;
    this.tags = data.tags ? data.tags.split(',') : [];
    this.safetyAnnouncementForm = this.fb.group({
      receivers: [data.receivers, Validators.required],
      tags: [data.tags.split(',')],
      type: [data.type, Validators.required],
      title: [data.title, Validators.required],
      description: [data.description, Validators.required],
      attachments: new FormArray([]),
    });
  }

  getFileName(value: string) {
    return value.substring(value.lastIndexOf('/') + 1);
  }

  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  deleteDirectlyFile(id: number): void {
    const file = this.files.find((x) => x.id === id);
    if (file) {
      this.selectedFile = file;
      this.deleteFile();
    }
  }

  deleteFile(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.Loading;
          this.safetyAnnouncementService
            .DeleteAnnouncementAttachments(
              this.selectedFile ? this.selectedFile.id : 0
            )
            .subscribe({
              next: (response) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, response?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
                this.files = this.files.filter(
                  (x) => x.id !== this.selectedFile.id
                );
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.confirmDialog.close();
                this.sectionState = SectionStateStatus.Ready;
              },
            });
        }
      });
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      const files = event.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.safetyAnnouncementForm.get('attachments').value.push(file);
      }
    }
    for (let file of event.target.files) {
      this.files.push({
        name: file.name,
        type: file.type,
      });
    }
  }

  filterData() {
    this.filteredReceivers = this.receiverCtrl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.receiverName)),
      map((receiverName) =>
        receiverName ? this._filter(receiverName) : this.allReceivers.slice()
      )
    );
  }

  getReceivers() {
    this.loadingLabel = 'General.Refreshing';
    this.sectionStateModal = SectionStateStatus.Loading;
    this.safetyAnnouncementService.GetReceiversList().subscribe({
      next: (response) => {
        const roles: SafetyAnnouncementReceiver[] = response?.roleList?.map(
          (ele) => {
            return {
              receiverID: ele?.roleId,
              receiverType: ele.receiverType,
              receiverName: ele?.roleName,
            };
          }
        );
        const departements = response?.departmentList?.map((ele) => {
          return {
            receiverID: ele?.departmentId,
            receiverType: ele.receiverType,
            receiverName: ele?.departmentName,
          };
        });
        const users = response?.users?.map((ele) => {
          return {
            receiverID: ele?.userId,
            receiverType: ele.receiverType,
            receiverName: ele?.fullName,
          };
        });
        this.allReceivers = [
          {
            typeName: 'departements',
            recieviers: departements,
          },
          {
            typeName: 'roles',
            recieviers: roles,
          },
          {
            typeName: 'users',
            recieviers: users,
          },
        ];
        this.filterData();
        this.sectionStateModal = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionStateModal = SectionStateStatus.Ready;
      },
    });
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.tags.push(value);
    }
    this.safetyAnnouncementForm.get('tags').patchValue(this.tags);
    event.chipInput!.clear();
  }

  removeTag(tag: string) {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    this.safetyAnnouncementForm.get('tags').patchValue(this.tags);
  }

  selecteReciever(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value === 'all') {
      const users = this.allReceivers.find(
        (item) => item.typeName == 'users'
      ).recieviers;
      this.selectedReceivers.push(...users);
      this.safetyAnnouncementForm
        .get('receivers')
        .patchValue(this.selectedReceivers);
    } else {
      this.selectedReceivers.push(event.option.value);
      this.receiverInput.nativeElement.value = '';
      this.receiverCtrl.patchValue('');
      this.safetyAnnouncementForm
        .get('receivers')
        .patchValue(this.selectedReceivers);
    }
  }

  removeReciever(receiverId: string): void {
    const index = this.selectedReceivers.findIndex(
      (receiver) => receiver.receiverID == receiverId
    );
    if (index >= 0) {
      this.selectedReceivers.splice(index, 1);
      this.safetyAnnouncementForm
        .get('receivers')
        .patchValue(this.selectedReceivers);
      this.filterData();
    }
  }

  private _filter(receiverName: any): AllSafetyAnnouncementReceiver[] {
    const filterValue = receiverName.toLowerCase();

    return this.allReceivers
      .map((group) => ({
        ...group,
        recieviers: group.recieviers.filter((option) =>
          option.receiverName.toLowerCase().includes(filterValue)
        ),
      }))
      .filter((group) => group.recieviers.length > 0);
  }

  createAnnouncemet() {
    const attachment = this.safetyAnnouncementForm.value.attachments;
    const fileSize = attachment.reduce((accumulator, object) => {
      return accumulator + object.size;
    }, 0);
    const sizeInMB = fileSize / 1024 / 1024;
    if (sizeInMB <= SharedConstants.WORK_PERMIT_FILE_SIZE) {
      const formData = new FormData();
      const files = this.safetyAnnouncementForm.get('attachments').value;
      files.forEach((element) => {
        formData.append('files', element);
      });
      const data = {
        title: this.safetyAnnouncementForm.get('title').value,
        description: this.safetyAnnouncementForm.get('description').value,
        type: this.safetyAnnouncementForm.get('type').value,
        tags: this.safetyAnnouncementForm.get('tags').value.toString(),
        receivers: this.safetyAnnouncementForm.get('receivers').value,
        attachments: this.oldFiles.map(
          (item) => item.attachmentUrl && item.attachmentUrl
        ),
      };
      formData.append('content', JSON.stringify(data));
      this.sectionStateModal = SectionStateStatus.Loading;
      this.safetyAnnouncementService
        .CreateSafetyAnnouncement(formData)
        .subscribe({
          next: (response) => {
            this.sectionStateModal = SectionStateStatus.Ready;
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Success, response?.message)
            );
            this.dialog.close({ event: 'createdNew' });
          },
          error: ({ error }) => {
            this.sectionStateModal = SectionStateStatus.Ready;
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
          },
        });
    } else {
      this.feedBackService.showFeedback(
        new FeedbackModel(FeedbackType.Warning, 'maximum file size allowed 5Mb')
      );
    }
  }

  removeFile(fileName: number) {
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i]?.name === fileName) {
        this.files.splice(i, 1);
        this.safetyAnnouncementForm.get('attachments').value.splice(i, 1);
        break;
      }
    }
  }

  updateAnnouncement() {
    const attachment = this.safetyAnnouncementForm.value.attachments;
    const fileSize = attachment.reduce((accumulator, object) => {
      return accumulator + object.size;
    }, 0);
    const sizeInMB = fileSize / 1024 / 1024;
    if (sizeInMB <= SharedConstants.WORK_PERMIT_FILE_SIZE) {
      const formData = new FormData();
      const files = this.safetyAnnouncementForm.get('attachments').value;
      files.forEach((element) => {
        formData.append('files', element);
      });
      const data = {
        id: this.selectedAnnouncement?.id,
        title: this.safetyAnnouncementForm.get('title').value,
        description: this.safetyAnnouncementForm.get('description').value,
        type: this.safetyAnnouncementForm.get('type').value,
        tags: this.safetyAnnouncementForm.get('tags').value.toString(),
        receivers: this.safetyAnnouncementForm.get('receivers').value,
      };
      formData.append('content', JSON.stringify(data));
      this.sectionStateModal = SectionStateStatus.Loading;
      this.safetyAnnouncementService
        .EditSafetyAnnouncement(formData)
        .subscribe({
          next: (response) => {
            this.sectionStateModal = SectionStateStatus.Ready;
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Success, response?.message)
            );
            this.dialog.close({ event: 'updated' });
          },
          error: ({ error }) => {
            this.sectionStateModal = SectionStateStatus.Ready;
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
          },
        });
    } else {
      this.feedBackService.showFeedback(
        new FeedbackModel(FeedbackType.Warning, 'maximum file size allowed 5Mb')
      );
    }
  }

  displayFn(option: { name: string; value: number }): string {
    return option && option.name ? option.name : '';
  }

  closeDialog() {
    this.dialog.close();
  }
}
