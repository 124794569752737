<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="LoadingLabel"
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="inspectionplanForm">
    <div class="flex items-center" mat-dialog-title>
      <h2
        *ngIf="inspectionplanForm.get('title').value"
        class="headline m-0 flex-auto"
      >
        {{ inspectionplanForm.get("title").value }}
      </h2>
      <h2
        *ngIf="!inspectionplanForm.get('title').value"
        class="headline m-0 flex-auto"
      >
        {{ "InspectionPlan.AddAudit" | translate }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="justify-between">
      <div class="field flex column w-full">
        <mat-label class="field-label"
          >{{ "InspectionPlan.TaskName" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('title').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            maxlength="255"
            formControlName="title"
          />
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('title').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('title').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Description -->
      <div class="field flex column w-full">
        <mat-label class="field-label"
          >{{ "InspectionPlan.Description" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('description').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            formControlName="description"
          />
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('description').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('description').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Checklist -->
      <div class="field flex column w-full">
        <mat-label class="field-label"
          >{{ "InspectionPlan.Checklist" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('checklist').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf1="ngxMatSelect"
            [displayMember]="'name'"
            [valueMember]="'id'"
            [source]="checklists"
            [useInfiniteScroll]="true"
            formControlName="checklist"
            [multiple]="false"
            [hasPermission]="
              hasCreateAuditChecklistPermissions | permissionChecker
            "
            [title]="'Checklists'"
            [manageRoute]="'/safety/manage-checklist'"
            [placeholder]="'InspectionPlan.Checklist' | translate"
          >
            <mat-option *ngFor="let w of msf1.filteredSource" [value]="w.id">{{
              w.name
            }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('checklist').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Department -->
      <div class="field flex column w-full">
        <mat-label class="field-label"
          >{{ "InspectionPlan.Department" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('department').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf2="ngxMatSelect"
            [displayMember]="'departmentId'"
            [valueMember]="'departmentName'"
            [source]="departmentsData"
            [useInfiniteScroll]="true"
            [multiple]="false"
            [hasSearchBox]="false"
            [hasPermission]="hasCreateDepartmentPermissions | permissionChecker"
            [title]="'departments'"
            [manageRoute]="'/company-setup/departments'"
            formControlName="department"
            [placeholder]="'InspectionPlan.Department' | translate"
            (selectionChange)="onChangeDepartment($event)"
          >
            <mat-option
              *ngIf="msf2.filteredSource.length === 0"
              style="height: 0"
            >
            </mat-option>
            <mat-optgroup
              *ngFor="let group of msf2.filteredSource"
              [label]="group.parentName"
            >
              <mat-option
                *ngFor="let detail of group.detail"
                [value]="detail.departmentId"
              >
                {{ detail.departmentName }}
              </mat-option>
            </mat-optgroup>
          </mat-select>

          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('department').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Sub department -->
      <div class="field flex column w-full" *ngIf="subDepartments.length > 0">
        <mat-label class="field-label">{{
          "AddReportHazard.SubDepartment" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            [title]="'departments'"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="subDepartment"
          >
            <mat-option *ngFor="let dep of subDepartments" [value]="dep.id">
              {{ dep.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Machine -->
      <div class="field flex column w-full">
        <mat-label class="field-label"
          >{{ "InspectionPlan.Machine" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('machine').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf3="ngxMatSelect"
            [displayMember]="'id'"
            [valueMember]="'machineName'"
            [source]="machines"
            [useInfiniteScroll]="true"
            [multiple]="false"
            [hasSearchBox]="false"
            [hasPermission]="hasCreateDepartmentPermissions | permissionChecker"
            [title]="'machines'"
            [manageRoute]="'/company-setup/machine-equipment'"
            [placeholder]="'InspectionPlan.Machine' | translate"
            formControlName="machine"
          >
            <mat-option [value]="0">None</mat-option>
            <mat-option *ngFor="let m of msf3.filteredSource" [value]="m?.id">{{
              m?.machineName
            }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('machine').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- User -->
      <div class="field flex column w-full">
        <mat-label class="field-label"
          >{{ "Task.SelectUser" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('user').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field appearance="outline">
          <mat-select
            ngxMatSelect
            #msf4="ngxMatSelect"
            [displayMember]="'fullName'"
            [valueMember]="'userId'"
            [source]="reportUsers"
            [useInfiniteScroll]="true"
            formControlName="user"
            [multiple]="false"
            [title]="'users'"
            [manageRoute]="'/user-management/users'"
            [hasPermission]="hasCreateUserPermissions | permissionChecker"
            [placeholder]="'Task.SelectUser' | translate"
          >
            <mat-option
              [value]="item.userId"
              *ngFor="let item of msf4.filteredSource"
            >
              {{ item.fullName }}
            </mat-option>
          </mat-select>

          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('user').touched &&
              inspectionplanForm.get('user').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('user').touched &&
              !inspectionplanForm.get('user').hasError('required') &&
              inspectionplanForm.get('user').hasError('InvalidSelectedValue')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <span>{{ "Errors.InvalidSelectedValue" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Start Date -->
      <div class="field flex column w-full">
        <mat-label class="field-label"
          >{{ "InspectionPlan.StartDate" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('start').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field appearance="outline">
          <input
            [placeholder]="'InspectionPlan.StartDate' | translate"
            [min]="minDate"
            matInput
            (dateChange)="ValidateDateRange()"
            formControlName="start"
            [matDatepicker]="startpicker"
            readonly
          />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matSuffix
            [for]="startpicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startpicker></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Duration Check -->
      <div class="field flex w-full">
        <div>
          <mat-label class="field-label">{{
            "InspectionPlan.DurationType" | translate
          }}</mat-label>

          <mat-radio-group
            (change)="SetValidations()"
            formControlName="durationType"
            color="primary"
            class="flex flex-col gap-3"
          >
            <mat-radio-button value="Time">{{
              "AddReportIncident.Time" | translate
            }}</mat-radio-button>
            <mat-radio-button value="Days">{{
              "InspectionPlan.Days" | translate
            }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          class="flex flex-col"
          style="margin-left: 40px"
          *ngIf="inspectionplanForm.value.durationType == 'Days' ? true : false"
        >
          <mat-label class="field-label"
            >{{ "InspectionPlan.Duration" | translate }}
            <span
              [ngClass]="
                inspectionplanForm.invalid &&
                inspectionplanForm.get('DurationDays').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>

          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'InspectionPlan.AddDays' | translate"
              matInput
              type="number"
              min="1"
              oninput="validity.valid||(value='');"
              formControlName="DurationDays"
            />
            <mat-error
              *ngIf="
                inspectionplanForm.invalid &&
                inspectionplanForm.get('DurationDays').hasError('required')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.Required" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div
          style="margin-left: 40px"
          *ngIf="inspectionplanForm.value.durationType == 'Time' ? true : false"
        >
          <div class="flex flex-row flex-wrap justify-between">
            <div class="flex column" style="width: calc(50% - 15px)">
              <mat-label class="field-label"
                >{{ "InspectionPlan.StartTime" | translate }}
                <span
                  [ngClass]="
                    inspectionplanForm.invalid &&
                    inspectionplanForm.get('StartTime').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  [format]="24"
                  [ngxMatTimepicker]="starttimepicker"
                  matInput
                  [min]="mintime"
                  [placeholder]="'InspectionPlan.StartTime' | translate"
                  readonly
                  formControlName="StartTime"
                />
                <mat-icon
                  matSuffix
                  svgIcon="mat:watch_later"
                  (click)="starttimepicker.open()"
                >
                </mat-icon>
                <ngx-mat-timepicker #starttimepicker></ngx-mat-timepicker>
              </mat-form-field>
            </div>
            <div class="flex column" style="width: calc(50% - 15px)">
              <mat-label class="field-label"
                >{{ "InspectionPlan.EndTime" | translate }}
                <span
                  [ngClass]="
                    inspectionplanForm.invalid &&
                    inspectionplanForm.get('EndTime').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  [format]="24"
                  [ngxMatTimepicker]="endtimepicker"
                  matInput
                  [min]="mintime"
                  [placeholder]="'InspectionPlan.EndTime' | translate"
                  readonly
                  formControlName="EndTime"
                />
                <mat-icon
                  matSuffix
                  svgIcon="mat:watch_later"
                  (click)="endtimepicker.open()"
                >
                </mat-icon>
                <ngx-mat-timepicker #endtimepicker></ngx-mat-timepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Duration in hours -->

      <!-- Recurrence Pattern -->
      <div class="field flex column w-full">
        <mat-label class="field-label"
          >{{ "InspectionPlan.RecurrenceType" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('RecurrenceType').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            [placeholder]="'InspectionPlan.RecurrenceType' | translate"
            (selectionChange)="resetRecurrenceFields()"
            formControlName="RecurrenceType"
          >
            <mat-option
              *ngFor="let statue of TaskRepeated | enumToArray"
              [value]="statue.value"
            >
              {{ "Enum.TaskRepeated." + statue.value | translate }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('RecurrenceType').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Interval -->
      <div
        class="field flex column w-full"
        *ngIf="
          inspectionplanForm.value.RecurrenceType == 'NotRepeated'
            ? false
            : true
        "
      >
        <mat-label class="field-label"
          >{{ "InspectionPlan.Interval" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('Interval').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'InspectionPlan.Interval' | translate"
            matInput
            type="number"
            min="1"
            oninput="validity.valid||(value='');"
            formControlName="Interval"
          />
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('Interval').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Sub Type -->
      <div
        class="field flex column w-full"
        *ngIf="
          inspectionplanForm.value.RecurrenceType == 'Monthly' ||
          inspectionplanForm.value.RecurrenceType == 'Yearly' ||
          inspectionplanForm.value.RecurrenceType == 'Quaterly'
            ? true
            : false
        "
      >
        <mat-label class="field-label"
          >{{ "InspectionPlan.RecurrenceSubType" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('RecurrenceSubType').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            (selectionChange)="SetValidations()"
            [placeholder]="'InspectionPlan.RecurrenceSubType' | translate"
            formControlName="RecurrenceSubType"
          >
            <mat-option value="Relative">{{
              "InspectionPlan.Relative" | translate
            }}</mat-option>
            <mat-option value="Absolute">{{
              "InspectionPlan.Absolute" | translate
            }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('RecurrenceSubType').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Month -->
      <div
        class="field flex column w-full"
        *ngIf="
          inspectionplanForm.value.RecurrenceType == 'Yearly' ? true : false
        "
      >
        <mat-label class="field-label"
          >{{ "InspectionPlan.RecurrenctMonth" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('RecurrenceMonth').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            [placeholder]="'InspectionPlan.RecurrenctMonth' | translate"
            formControlName="RecurrenceMonth"
          >
            <mat-option *ngFor="let month of Months" [value]="month.id">
              {{ "Months." + month.name | translate }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('RecurrenceMonth').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <!-- Relative Day No -->
      <div
        class="field flex column w-full"
        *ngIf="
          (inspectionplanForm.value.RecurrenceType == 'Monthly' ||
            inspectionplanForm.value.RecurrenceType == 'Yearly' ||
            inspectionplanForm.value.RecurrenceType == 'Quaterly') &&
          inspectionplanForm.value.RecurrenceSubType == 'Relative'
            ? true
            : false
        "
      >
        <mat-label class="field-label">
          {{ "InspectionPlan.NthDayOfMonth" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('DayOfMonth').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'InspectionPlan.NthDayOfMonth' | translate"
            matInput
            min="1"
            oninput="validity.valid||(value='');"
            type="number"
            formControlName="DayOfMonth"
          />
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('DayOfMonth').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Week No -->
      <div
        class="field flex column w-full"
        *ngIf="
          (inspectionplanForm.value.RecurrenceType == 'Monthly' ||
            inspectionplanForm.value.RecurrenceType == 'Yearly' ||
            inspectionplanForm.value.RecurrenceType == 'Quaterly') &&
          inspectionplanForm.value.RecurrenceSubType == 'Absolute'
            ? true
            : false
        "
      >
        <mat-label class="field-label"
          >{{ "InspectionPlan.NthWeek" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('RecurrenceWeek').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            [placeholder]="'InspectionPlan.NthWeek' | translate"
            formControlName="RecurrenceWeek"
          >
            <mat-option value="1"> {{ "Weeks.first" | translate }}</mat-option>
            <mat-option value="2">
              {{ "Weeks.second" | translate }}
            </mat-option>
            <mat-option value="3"> {{ "Weeks.third" | translate }} </mat-option>
            <mat-option value="4">
              {{ "Weeks.fourth" | translate }}
            </mat-option>
            <mat-option value="5"> {{ "Weeks.last" | translate }} </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('RecurrenceWeek').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Absolute Days -->
      <div
        class="field flex column w-full"
        *ngIf="
          inspectionplanForm.value.RecurrenceType == 'Weekly' ||
          inspectionplanForm.value.RecurrenceSubType == 'Absolute'
            ? true
            : false
        "
      >
        <mat-label class="field-label">
          {{ "InspectionPlan.AbsoluteDays" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('AbsoluteDays').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            formControlName="AbsoluteDays"
            [placeholder]="'InspectionPlan.AbsoluteDays' | translate"
            multiple
          >
            <mat-option *ngFor="let days of DaysOfWeek" [value]="days.id">
              {{ "DaysOfWeek." + days.name | translate }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('AbsoluteDays').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Repeated End Date -->
      <div
        class="field flex column w-full"
        *ngIf="
          inspectionplanForm.value.RecurrenceType != 'NotRepeated'
            ? true
            : false
        "
      >
        <mat-label class="field-label">
          {{ "InspectionPlan.RepeatedEndDate" | translate }}
          <span
            [ngClass]="
              inspectionplanForm.invalid &&
              inspectionplanForm.get('RepeatedEndDate').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>

        <mat-form-field appearance="outline">
          <input
            [placeholder]="'InspectionPlan.RepeatedEndDate' | translate"
            matInput
            [min]="minRepeatedDate"
            formControlName="RepeatedEndDate"
            [matDatepicker]="RepeatedEndDate"
            readonly
          />
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle
            matSuffix
            [for]="RepeatedEndDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #RepeatedEndDate></mat-datepicker>
        </mat-form-field>
        <!-- <mat-error
                    *ngIf="inspectionplanForm.invalid && inspectionplanForm.get('RepeatedEndDate').hasError('required')">
                    <div class="flex justify-flex-start align-flex-center">
                        <img class="error-img" src="assets/img/resources/error-icon.svg" />
                        <span>{{ "Errors.Required" | translate }}</span>
                    </div>
                </mat-error> -->
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel' | translate"
          (onClick)="closeDialog()"
        >
        </app-basic-button>
        <app-basic-button
          *ngIf="isCreateMode() && this.Type != 'View'"
          [btnType]="'Add'"
          [btnText]="'Add' | translate"
          [isDisabled]="!inspectionplanForm.valid"
          (onClick)="save()"
        >
        </app-basic-button>
        <app-basic-button
          *ngIf="isUpdateMode() && this.Type != 'View'"
          [btnType]="'Add'"
          [btnText]="'Save' | translate"
          [isDisabled]="!inspectionplanForm.valid"
          (onClick)="save()"
        >
        </app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
