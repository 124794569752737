<div class="flex items-center" mat-dialog-title>
  <h2 class="headline m-0 flex-auto">Reschedule Task</h2>
  <button
    class="text-secondary"
    (click)="closeDialog()"
    mat-icon-button
    type="button"
  >
    <mat-icon svgIcon="mat:close"></mat-icon>
  </button>
</div>
<div>
  <form [formGroup]="taskForm">
    <!-- User -->
    <div class="field-full flex column">
      <mat-label class="field-label"
        >{{ "Task.SelectUser" | translate }}
        <span
          [ngClass]="
            taskForm.invalid && taskForm.get('assigneeId').hasError('required')
              ? 'is-invalid'
              : 'is-valid'
          "
          >*</span
        >
      </mat-label>
      <mat-form-field appearance="outline">
        <mat-select
          ngxMatSelect
          #msf1="ngxMatSelect"
          [displayMember]="'fullName'"
          [valueMember]="'userId'"
          [source]="reportUsers"
          [useInfiniteScroll]="true"
          formControlName="assigneeId"
          [hasPermission]="hasCreateUserPermission | permissionChecker"
          [title]="'users'"
          [manageRoute]="'/user-management/users'"
          [multiple]="false"
          [placeholder]="'Task.SelectUser' | translate"
        >
          <mat-option
            [value]="item.userId"
            *ngFor="let item of msf1.filteredSource"
          >
            {{ item.fullName }}
          </mat-option>
        </mat-select>

        <mat-error
          *ngIf="
            taskForm.invalid &&
            taskForm.get('assigneeId').touched &&
            taskForm.get('assigneeId').hasError('required')
          "
        >
          <div class="flex justify-flex-start align-flex-center">
            <span>{{ "Errors.Required" | translate }}</span>
          </div>
        </mat-error>
        <mat-error
          *ngIf="
            taskForm.invalid &&
            taskForm.get('assigneeId').touched &&
            !taskForm.get('assigneeId').hasError('required') &&
            taskForm.get('assigneeId').hasError('InvalidSelectedValue')
          "
        >
          <div class="flex justify-flex-start align-flex-center">
            <span>{{ "Errors.InvalidSelectedValue" | translate }}</span>
          </div>
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Start Date -->
    <div class="field-full flex column">
      <mat-label class="field-label"
        >{{ "InspectionPlan.StartDate" | translate }}
        <span
          [ngClass]="
            taskForm.invalid &&
            taskForm.get('rescheduleDate').hasError('required')
              ? 'is-invalid'
              : 'is-valid'
          "
          >*</span
        >
      </mat-label>
      <mat-form-field appearance="outline">
        <input
          [placeholder]="'InspectionPlan.StartDate' | translate"
          matInput
          formControlName="rescheduleDate"
          [matDatepicker]="startpicker"
          readonly
        />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matSuffix
          [for]="startpicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startpicker></mat-datepicker>
      </mat-form-field>
    </div>
  </form>
</div>
<div class="flex justify-end items-center mt-4">
  <app-basic-button
    [btnType]="'Cancel'"
    [btnText]="'Cancel'"
    (onClick)="closeDialog()"
  ></app-basic-button>
  <app-basic-button
    [btnType]="'Add'"
    [btnText]="'Submit'"
    (onClick)="rescheduleTask()"
    [isDisabled]="!taskForm.valid"
  ></app-basic-button>
</div>
