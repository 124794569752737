import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { ChecklistSectionType } from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { InspectionManagementService } from 'src/backend/services/inspection-management/inspection-management.service';

@Component({
  selector: 'app-inspection-review',
  templateUrl: './inspection-review.component.html',
  styleUrls: ['./inspection-review.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms,
    scaleFadeIn400ms,
  ],
})
export class InspectionReviewComponent implements OnInit {
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  ReportingType = 'INSPECTION_MANAGEMENT';
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  private destroy$ = new Subject();
  loadingLabel: string = '';

  taskId: any;
  data: any;
  checklist: any;
  gaugeValue: number;
  translationsList = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private inspectionManagementService: InspectionManagementService,
    private asyncFeedbackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private location: Location
  ) {
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        this.taskId = parseInt(params.get('id'), 10);
      }
    });
    this.getData();
  }

  getData() {
    this.sectionState = SectionStateStatus.Loading;
    this.inspectionManagementService
      .getInspectionChecklist(this.taskId)
      .subscribe({
        next: (res) => {
          this.data = res;
          this.GetScorePercentage();
          this.checklist = this.formatCheckListData(
            this.data?.checkList.section
          );
          this.sectionState = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  approveUnSafety() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.inspectionManagementService
            .approveUnsafety(this.data?.equipmentId)
            .subscribe({
              next: (res) => {
                this.asyncFeedbackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.goBack();
                this.sectionState = SectionStateStatus.Ready;
              },
              error: (error) => {
                this.asyncFeedbackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
        }
      });
  }

  GetScorePercentage(): any {
    let correctanswers = 0;
    let NoOfQuestions = 0;
    let Percentage = 0;
    let sectionsFormArray = this.formatCheckListData(
      this.data?.checkList.section
    );
    sectionsFormArray.forEach((element) => {
      if (element.questionType === 'Value Vs Target') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (
              el1.answer + ' ' + ' ' + el1.target ==
              el1.answer + ' ' + ' ' + el1.userAnswer
            ) {
              correctanswers++;
            }
            NoOfQuestions++;
          }
        });
      } else if (element.questionType === 'Select from range') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            switch (el1.userAnswer) {
              case '0':
                correctanswers = correctanswers + 0;
                break;
              case '1':
                correctanswers = correctanswers + 0.25;
                break;
              case '2':
                correctanswers = correctanswers + 0.5;
                break;
              case '3':
                correctanswers = correctanswers + 0.75;
                break;
              case '4':
                correctanswers++;
                break;
              default:
                break;
            }
            NoOfQuestions++;
          }
        });
      } else {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (el1.answer == el1.userAnswer) {
              correctanswers++;
            }
            NoOfQuestions++;
          }
        });
      }
    });
    Percentage = (correctanswers / NoOfQuestions) * 100;
    this.gaugeValue = Number(Percentage.toFixed(1));
  }

  formatCheckListData(data: any[]): any[] {
    const formatedData = data.map((section, i) => {
      return {
        sectionId: section?.sectionId,
        title: section?.sectionTitle,
        questionType: section?.sectionType,
        questions: section?.questions?.map((question, j) => {
          return {
            questionId: question?.questionId,
            questionText:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.questionTitle, 10)
                : question?.questionTitle,
            answer:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.Range
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[0]
                : question?.answer,
            target:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[1]
                : '',
            parameters: [],
            userAnswer:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.userAnswer
                  ? question?.userAnswer.split(' ')[1]
                  : ''
                : question?.userAnswer,
            comment: question?.comment,
            isNotApplicable: question?.isNotApplicable,
            media: question?.media,
            mediaName: question?.mediaName,
          };
        }),
      };
    });
    return formatedData;
  }

  getGuageColors(value: number): string {
    if (value < 40) {
      return '#fa2020';
    }
    if (value >= 40 && value < 70) {
      return '#2041fa';
    }
    if (value >= 70) {
      return '#42f548';
    }
    return '';
  }

  goToHistoryLog() {
    this.router.navigate([
      SharedConstants.INSPECTION_MANAGEMENT +
        '/' +
        SharedConstants.HISTORY_LOGS +
        '/' +
        this.data?.equipmentId,
    ]);
  }

  goBack(): void {
    this.location.back();
  }
}
