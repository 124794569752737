<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="
    (formMode === 'create'
      ? 'EmergencyContact.AddingLoadingEmergencyContacts'
      : 'EmergencyContact.UpdatingLoadingEmergencyContacts'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="contactForm">
    <div class="flex items-center justify-end">
     
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-dialog-content class="flex flex-col flex-wrap justify-between">
      <div class="field-full flex column">
        

        <mat-label class="field-label"
        >{{ "EmergencyContact.Name" | translate }}
        <span
          [ngClass]="
            contactForm.invalid &&
            contactForm.get('UserId').hasError('required')
              ? 'is-invalid'
              : 'is-valid'
          "
          >*</span
        >
      </mat-label>
        <mat-form-field class="input-field relative" appearance="outline">
         
          <mat-select
            ngxMatSelect
            #msf3="ngxMatSelect"
            [displayMember]="'fullName'"
            [valueMember]="'userId'"
            [source]="companyUsers"
            [useInfiniteScroll]="true"
            formControlName="UserId"
            [title]="'users'"
            [manageRoute]="'/user-management/users'"
            [placeholder]="'Placeholder.NamePlaceholder' | translate"
            (selectionChange)="onSelectionChange($event)"
          >
            <mat-option
              [value]="item.userId"
              [attr.data-custom-attribute]="item.fullName"
              *ngFor="let item of msf3.filteredSource"
            >
              {{ item.fullName }}
            </mat-option>
          </mat-select>
          <mat-error>
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <p *ngIf="contactNum === '' && !isUserHasPermission" class="absolute top-full text-red-700" >*This Contact have no number, please contact your admin</p>
          <p *ngIf="contactNum === '' && isUserHasPermission" class="absolute top-full " >*This Contact have no number,<span class="underline text-red-700" (click)="editUserPhone()">click here to edit</span></p>

        </mat-form-field>

        <mat-label class="field-label"
          >{{ "EmergencyContact.PhoneNumber" | translate }}
        </mat-label>
        <mat-form-field appearance="outline">
          <input
            [value]="contactNum ? contactNum : null"
            [placeholder]="'EmergencyContact.PhoneNumber'|translate"
            matInput
            type="text"
            readonly
            disabled
          />
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isCreateMode()"
          [btnType]="'Add'"
          [btnText]="'Add'"
          [isDisabled]="!contactNum"
          (onClick)="save()"
        ></app-basic-button>

      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
