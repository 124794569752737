import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { EmailValidator, noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { Confirmation, ConfirmationType } from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AuthService } from 'src/backend/services/auth.service';
import { OrganizationDetail } from '../../models/organization-detail';

@Component({
  selector: 'app-organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
  ]
})
export class OrganizationDetailComponent implements OnInit {
  @Input() orgInfo: OrganizationDetail;
  @Input() orgLicenseInfo: any;
  @ViewChild('emailChangeModal') changeEmailModal!: TemplateRef<any>;
  emailChangeForm: FormGroup;
  public ACTIVE_COLOR = '#67DD2D';
  public INACTIVE_COLOR = '#F76F45';

  public translationsList: any = {};
  public destroy$ = new Subject();

  @ViewChild('orgImageBigView') orgImageBigView!: TemplateRef<any>;
  constructor(private dialog: MatDialog,
    private fb: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,) { 
      this.translate.get(['Errors', 'Success','User', 'confirmVerificationCode']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
        this.translationsList = translations;
      });
    }

  ngOnInit(): void {
    this.emailChangeForm = this.fb.group({
      email: ['', [Validators.required, noWhitespaceValidator, EmailValidator]],
    });
  }

  fullImageView(): void {
    this.dialog.open(this.orgImageBigView, {height: '97%', width: '80%'});
  }

  openChangeEmailModal(): void{
    this.emailChangeForm.get('email').setValue('');
    this.dialog.open(this.changeEmailModal, {height: 'auto', width: '30%'});
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  changeEmail(){
    this.confirmationService
    .confirm(
      new Confirmation(
        ConfirmationType.NonDestructiveAction,
        this.translationsList['User']['AddConfirm']['Title'],
        this.translationsList['User']['AddConfirm']['Message']
      )
    )
    .then((value) => {
      if (value === true) {
        this.loadingService.startLoading(true, '');
        const data = {
          userId: this.orgInfo?.adminId,
          email: this.emailChangeForm.value.email
        }
        this.authService.changeUserEmail(data).subscribe({
          next: (response) => {
            this.emailChangeForm.get('email').setValue('');
            this.loadingService.stopLoading();
            this.closeDialog();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Success, response?.message)
            );
          },
          error: ({error}) => {
            this.loadingService.stopLoading();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
          }
        })
      }
    });  
  }

  resendVerificationCode(): void {
    this.confirmationService
    .confirm(
      new Confirmation(
        ConfirmationType.NonDestructiveAction,
        this.translationsList['confirmVerificationCode']['Title'],
        this.translationsList['confirmVerificationCode']['Message']
      )
    )
    .then((value) => {
      if (value === true) {
        const orgCode = this.orgInfo.organizationCode;
        this.loadingService.startLoading(true, '');
        this.authService.resendVerificationCodeFormOrgAdmin(orgCode).subscribe({
          next: (response) => {
            this.loadingService.stopLoading();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Success, response?.message)
            );
          },
          error: ({error}) => {
            this.loadingService.stopLoading();
            this.feedBackService.showFeedback(
              new FeedbackModel(FeedbackType.Failure, error?.message)
            );
          }
        })
      }
    });   
  }
}
