<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "Voilations.Title" | translate }}</span>
    </h2>
    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'Placeholder.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>
  </div>

  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <!--text Columns -->
        <ng-container
          *ngIf="column.type === 'text' && column.property !== 'offenderName'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "Voilations.Table." + column.label | translate }}&nbsp;
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'text' && column.property === 'offenderName'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            {{ "Voilations.Table." + column.label | translate }}&nbsp;
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] || row["offenderNature"] }}
            </span>
          </td>
        </ng-container>
        <!-- Image Column -->
        <ng-container
          *ngIf="column.type === 'image' && column.property === 'picture'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef mat-header-cell></th>
          <td *matCellDef="let row" class="w-8 min-w-8 pr-0" mat-cell>
            <div (click)="$event.stopPropagation()">
              <ngx-avatars
                class="logo box"
                size="32"
                [src]="row[column.property]"
                [name]="row['cameraName']"
                [textSizeRatio]="2"
                [round]="true"
                [initialsSize]="2"
                (clickOnAvatar)="viewImgDialog(row?.id)"
              >
              </ngx-avatars>
            </div>
          </td>
        </ng-container>
        <!-- Date Column -->
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "Voilations.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "dd-MM-yyyy hh:mm a" }}
          </td>
        </ng-container>
        <!-- Department Column -->
        <ng-container
          *ngIf="
            column.type === 'boolean' && column.property === 'departmentName'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
            <mat-icon
            app-click-stop-propagation
            style="width: 35px; height: 35px; cursor: pointer"
            (click)="select.open()"
            svgIcon="mat:keyboard_arrow_down"
            color="primary"
          ></mat-icon>
              {{ "Voilations.Table." + column.label | translate }}
              <mat-select
                [value]="selectedDepartment"
                (selectionChange)="onSelectDepartment($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let dep of departments"
                  [value]="dep.departmentId"
                >
                  {{ dep.departmentName }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
        <!-- voilation Column -->
        <ng-container
          *ngIf="
            column.type === 'boolean' && column.property === 'voilationName'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
            <mat-icon
            app-click-stop-propagation
            style="width: 35px; height: 35px; cursor: pointer"
            (click)="select.open()"
            svgIcon="mat:keyboard_arrow_down"
            color="primary"
          ></mat-icon>
              {{ "Voilations.Table." + column.label | translate }}
              <mat-select
                [value]="selectedVoilationType"
                (selectionChange)="onSelectVoilation($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option *ngFor="let voi of voilationTypes" [value]="voi">
                  {{ voi }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
        <!-- Status Column -->
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "Voilations.Table." + column.label | translate }}
              <mat-select
                [value]="selectedVoilationStatus"
                (selectionChange)="onSelectVoilationStatus($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of voilationStatuses | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.VoilationsStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <app-boolean-view
              [value]="row[column.property] === 'New'"
              [text]="
                row[column.property] === 'New'
                  ? ('Enum.VoilationsStatus.New' | translate)
                  : ('Enum.VoilationsStatus.Close' | translate)
              "
            >
            </app-boolean-view>
          </td>
        </ng-container>
      </ng-container>

      <!-- Actions Column -->

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewVoilation(row?.id)"
      ></tr>
      <ng-container matColumnDef="actions" stickyEnd class="bg-hover">
        <th *matHeaderCellDef mat-header-cell></th>

        <td *matCellDef="let row" mat-cell>
          <div (click)="$event.stopPropagation()">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                mat-menu-item
                (click)="viewVoilation(row?.id)"
                class="flex items-center justify-between p-1 mat-div"
              >
                <button>
                  {{ "General.Buttons.View" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <hr />
              <div
                *ngIf="hasDeletePermission | permissionChecker"
                mat-menu-item
                (click)="deleteVoilation(row?.id)"
                class="flex items-center justify-between p-1 mat-div"
              >
                <button>
                  {{ "General.Buttons.Delete" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
        </td>
      </ng-container>
    </table>
    <div
      *ngIf="dataSource?.filteredData?.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [class.hidden]="dataSource.filteredData.length === 0"
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-icon
      class="text-[#757575] me-1"
      *ngIf="!column.visible"
      svgIcon="mat:check_box_outline_blank"
    ></mat-icon>
    <mat-icon
      *ngIf="column.visible"
      class="text-primary me-1"
      svgIcon="mat:check_box"
    ></mat-icon>
    {{ "Voilations.Table." + column.label | translate }}
  </button>
</mat-menu>

<ng-template #viewImageDialog>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="viewImagePath" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">
      {{ "Voilations.Form.ViewImage" | translate }}
    </h2>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex gap-5">
    <div class="image_wrapper">
      <img src="{{ viewImagePath }}" alt="{{ voilation?.voilationName }}" />
    </div>
    <div class="card_wrapper">
      <form [formGroup]="voilationForm" class="form-container">
        <div class="card">
          <div class="flex justify-between">
            <h4 class="title">
              {{ "Voilations.Form.OffenderInformation" | translate }}
            </h4>
            <a
              *ngIf="voilation?.status === 'New'"
              class="text-primary font-bold cursor-pointer"
              (click)="resetForm()"
              >Reset</a
            >
          </div>
          <mat-radio-group
            class="radio-group"
            formControlName="offenderNature"
            [disabled]="
              voilationForm.get('offenderName')?.value ||
              voilationForm.get('isGuestOffender')?.value
                ? true
                : false
            "
          >
            <mat-radio-button
              class="radio-button"
              *ngFor="let nature of offenderNatures | enumToArray"
              [value]="nature.value"
            >
              {{ nature.value }}
            </mat-radio-button>
          </mat-radio-group>
          <hr class="mb-3" />
          <!-- users -->
          <div class="fields">
            <div class="field-full flex column">
              <mat-label class="field-label">{{
                "Voilations.Form.OffenderName" | translate
              }}</mat-label>
              <mat-form-field appearance="outline">
                <mat-select
                  ngxMatSelect
                  #msf1="ngxMatSelect"
                  [displayMember]="'fullName'"
                  [valueMember]="'userId'"
                  [source]="users"
                  [useInfiniteScroll]="true"
                  [multiple]="false"
                  [hasPermission]="hasCreateUserPermission | permissionChecker"
                  [title]="'users'"
                  [manageRoute]="'/user-management/users'"
                  [placeholder]="'Placeholder.Select' | translate"
                  formControlName="offenderName"
                  [disabled]="
                    voilationForm.get('offenderNature')?.value ||
                    voilationForm.get('isGuestOffender')?.value
                      ? true
                      : false
                  "
                >
                  <mat-option
                    *ngFor="let user of msf1.filteredSource"
                    [value]="user.fullName"
                    >{{ user.fullName }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="card">
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <h4 class="title">
              {{ "Voilations.Form.GuestVisitor" | translate }}
            </h4>
            <mat-checkbox
              color="primary"
              formControlName="isGuestOffender"
              style="margin-top: -20px"
              [disabled]="
                voilationForm.get('offenderNature')?.value ||
                voilationForm.get('offenderName')?.value
                  ? true
                  : false
              "
            >
            </mat-checkbox>
          </div>
          <!-- guest/visitor offender name -->
          <div
            class="fields"
            *ngIf="voilationForm.get('isGuestOffender')?.value"
          >
            <div class="field-full flex column">
              <mat-form-field appearance="outline">
                <input
                  matInput
                  type="text"
                  [placeholder]="'Voilations.Form.GuestVisitor' | translate"
                  formControlName="guestOffenderName"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [btnText]="'Submit'"
      [isDisabled]="voilation?.status === 'New' ? false : true"
      (onClick)="submitVoilationForm()"
    ></app-basic-button>
  </div>
</ng-template>
