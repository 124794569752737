import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, tap } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { CompanyStatus, SortDirection } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { Company } from 'src/app/company-setup/models/company';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { AuthService } from 'src/backend/services/auth.service';
import { CompanyService } from 'src/backend/services/company.service';
import { merge } from 'rxjs';
@UntilDestroy()
@Component({
  selector: 'app-organization-companies',
  templateUrl: './organization-companies.component.html',
  styleUrls: ['./organization-companies.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class OrganizationCompaniesComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Input() organizationId: number;
  @Input() organizationCode: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  companies: Company[];
  columns: TableColumn<Company>[] = [
    { label: 'Image', property: 'companyImage', type: 'image', visible: true },
    {
      label: 'Code',
      property: 'companyCode',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Title',
      property: 'companyName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    { label: 'Features', property: 'features', type: 'icons', visible: true },
    {
      label: 'Created At',
      property: 'createdOn',
      type: 'date',
      visible: false,
    },
    {
      label: 'Updated At',
      property: 'updatedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    {
      label: 'Description',
      property: 'description',
      type: 'text',
      visible: false,
      cssClasses: ['text-secondary', 'font-small'],
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<Company>([]);
  selection = new SelectionModel<Company>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  CompanyStatuses = CompanyStatus;
  SharedConstants = SharedConstants;
  sessionOrg: any;

  public selectedCompanyStatus: string[] = [];
  private translationsList: any = {};
  constructor(
    private translate: TranslateService,
    private router: Router,
    private companyService: CompanyService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private baseApi: BaseApi,
    private authService: AuthService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'Company'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  override ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.sort.sortChange
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => this.getData())
      )
      .subscribe();

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortProperty = null;
    if (this.sort.active) {
      sortProperty = this.sort.active;
    }
    const filteredParams = {
      sortDirection: sortDirection,
      sortProperty: sortDirection === SortDirection.None ? null : sortProperty,
      orgStatus: this.selectedCompanyStatus.toString(),
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      searchValue: this.searchValue,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.companyService
      .getCompaniesByOrg(filteredParams, this.organizationId)
      .subscribe({
        next: (response) => {
          this.companies = response?.data;
          this.dataSource = new MatTableDataSource(response?.data);
          this.paginator.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  onSelectCompanyStatus(event: MatSelectChange) {
    this.selectedCompanyStatus = event.value;
    this.getData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.paginator.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  deactivateCompany(event: any, compId: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['Company']['StatusConfirm']['Title'],
          this.translationsList['Company']['StatusConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'Company.DeactivatingCompany';
          this.sectionState = SectionStateStatus.Loading;
          this.companyService.deactivateCompany(compId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  activateCompany(event: any, compId: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Company']['StatusConfirm']['Title'],
          this.translationsList['Company']['StatusConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'Company.ActivatingCompany';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.companyService.activateCompany(compId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  deleteCompany(event: any, compId: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Company']['StatusConfirm']['Title'],
          this.translationsList['Company']['StatusConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'Deleting Company';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.companyService.deleteCompany(compId).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  viewCompany(event: any, orgCode: string): void {
    this.router.navigate([
      '/' +
        SharedConstants.COMPANY_SETUP +
        '/' +
        SharedConstants.COMPANY_VIEW +
        '/' +
        orgCode,
    ]);
  }

  createCompany() {
    this.router.navigate(
      [
        '/' +
          SharedConstants.COMPANY_SETUP +
          '/' +
          SharedConstants.COMPANY_CREATE,
      ],
      {
        queryParams: {
          org: this.organizationCode,
          id: this.organizationId,
        },
      }
    );
  }

  updateCompany(event: any, compId: number) {
    this.router.navigate(
      [
        '/' +
          SharedConstants.COMPANY_SETUP +
          '/' +
          SharedConstants.COMPANY_UPDATE +
          '/' +
          compId,
      ],
      {
        queryParams: {
          org: this.organizationCode,
          id: this.organizationId,
        },
      }
    );

    
  }
  LoginAs(event: any, compId: any): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Company']['LoginConfirm']['Title'],
          this.translationsList['Company']['LoginConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const obj = {
            CompanyCode: compId,
          };
          this.loadingLabel = 'Company.LoginAs';
          this.authService.signInWithOutPassword(obj);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
