import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BackgroundScrollService } from '../../helpers/background-scroll.service';

import { ConfirmationService } from '../../helpers/confirmation.service';
import { Confirmation, ConfirmationType } from '../../models/confirmation';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit, OnDestroy {

  public confirmData: Confirmation = new Confirmation();
  public isVisible = false;
  public ConfirmationType = ConfirmationType;
  public checkboxValue = false;

  // private fields
  private dispose$ = new Subject();

  constructor(
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private backgroundScrollService: BackgroundScrollService
  ) { }

  ngOnInit() {
    this.confirmationService.confirmationMessage$.pipe(takeUntil(this.dispose$)).subscribe(confirm => {
      if (confirm != null) {
        this.translate.get(['Confirm.Discard', 'Confirm.Confirm']).pipe(takeUntil(this.dispose$)).subscribe((translations: any) => {
          confirm.mainActionText = confirm.mainActionText ?? translations['Confirm.Confirm'];
          confirm.cancelActionText = confirm.cancelActionText ?? translations['Confirm.Discard'];
          this.confirmData = confirm;
          this.isVisible = true;
          this.backgroundScrollService.setScrollState(false);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.dispose$.next(true);
  }

  onActionButtonClicked(value: boolean, checkboxChecked: boolean) {
    this.confirmationService.onConfirmMessageAction(value, checkboxChecked !== null ? checkboxChecked : false);
    this.isVisible = false;
    this.checkboxValue = false;
    this.confirmData.message = '';
    this.backgroundScrollService.setScrollState(true);
  }

  cancelAlert(): void {
    this.isVisible = false;
  }

}
