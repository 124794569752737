import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'highcharts';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ManageAccessControlService } from 'src/backend/services/manage-access-control/manage-access-control.service';

@Component({
  selector: 'app-access-control-user-details',
  templateUrl: './access-control-user-details.component.html',
  styleUrls: ['./access-control-user-details.component.scss'],
})
export class AccessControlUserDetailsComponent
  extends BaseComponent
  implements OnInit
{
  public userId: string = '';
  public userDetails: any = null;
  public mobileDevices: any = null;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  translationsList: any = {};

  constructor(
    private manageAccessControlService: ManageAccessControlService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private feedBackService: AsyncFeedbackService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.route.params.subscribe({
      next: (params) => {
        this.userId = params['id'];
        this.getUserDetails();
      },
    });
  }

  getUserDetails() {
    this.sectionState = SectionStateStatus.Loading;
    this.manageAccessControlService.getUserDetails(this.userId).subscribe({
      next: (res) => {
        this.userDetails = {
          fullName: res?.fullName,
          email: res?.email,
          photo: res?.photo,
        };
        this.mobileDevices = res?.credentialContainers;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  deleteDevice(id) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value) {
          this.loadingService.startLoading(true);

          this.manageAccessControlService.deleteDevice(id).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getUserDetails();
              this.loadingService.stopLoading();
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  issueMobileId(data) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value) {
          this.loadingService.startLoading(true);

          this.manageAccessControlService.issueMobileId(data).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getUserDetails();
              this.dialog.closeAll();
              this.loadingService.stopLoading();
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  invokeCredential(credentialId) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value) {
          this.loadingService.startLoading(true);

          this.manageAccessControlService
            .revokeCredential(credentialId)
            .subscribe({
              next: (res) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.getUserDetails();
                this.loadingService.stopLoading();
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.loadingService.stopLoading();
              },
            });
        }
      });
  }
}
