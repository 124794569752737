import { Component, OnInit, ViewChild } from '@angular/core';
import { BulkUploadUsersListComponent } from '../../components/bulk-upload-users-list/bulk-upload-users-list.component';

@Component({
  selector: 'app-bulk-upload-user-page',
  templateUrl: './bulk-upload-user-page.component.html',
  styleUrls: ['./bulk-upload-user-page.component.scss']
})
export class BulkUploadUserPageComponent implements OnInit {
  @ViewChild(BulkUploadUsersListComponent) listCompnent!: BulkUploadUsersListComponent;
  constructor() { }

  ngOnInit() {
  }

  passDataToComponent(e: any) {
    if (e) {
      this.listCompnent.getData();
    }
  }

}
