import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { AddIdCardPageComponent } from './pages/add-id-card-page/add-id-card-page.component';
import { EmergencyContactsPageComponent } from './pages/emergency-contacts-page/emergency-contacts-page.component';
import { IdCardsPageComponent } from './pages/id-cards-page/id-cards-page.component';
import { LostIdCardPageComponent } from './pages/lost-id-card-page/lost-id-card-page.component';
import { NewIdCardPageComponent } from './pages/new-id-card-page/new-id-card-page.component';
import { UpdateCardPageComponent } from './pages/update-card-page/update-card-page.component';
import { ViewUpdateCardPageComponent } from './pages/view-update-card-page/view-update-card-page.component';
import { MultiPermissionGuard } from '../shared/guards/MultiPermission.guard';

const routes: Routes = [
  {
    path: SharedConstants.ID_CARDS,
    component: IdCardsPageComponent,
    canActivate: [MultiPermissionGuard],
    data: {
      permissions: [ApplicationPermission.SECURITY_MANAGE_LOSTIDS_ACCESS,ApplicationPermission.SECURITY_MANAGE_RENEW_ACCESS],
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'IDCard',
      },
    },
  },
  {
    path: SharedConstants.EMERGENCY_CONTACTS,
    component: EmergencyContactsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.SECURITY_MANAGE_CONTACTS_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'EmergencyContacts',
      },
    },
  },
  {
    path: SharedConstants.NEW_ID_CARD,
    component: NewIdCardPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.SECURITY_MANAGE_IDCARDS_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'NewIDCard',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.UPDATE_CARD,
    component: UpdateCardPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions:
        ApplicationPermission.SECURITY_MANAGE_UPDATEIDCARDS_VIEW_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'UpdateIDCard',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.LOST_ID_CARD,
    component: LostIdCardPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.SECURITY_MANAGE_LOSTIDS_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'LostIDCard',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.NEW_ID_CARD +
      '/' +
      SharedConstants.ADD_ID_CARD +
      '/:mode' +
      '/:id',
    component: AddIdCardPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.SECURITY_MANAGE_IDCARDS_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'AddIDCard',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.ID_CARDS +
      '/' +
      SharedConstants.UPDATE_CARD +
      '/' +
      SharedConstants.UPDATE_CARD_VIEW +
      '/:id',
    component: ViewUpdateCardPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions:
        ApplicationPermission.SECURITY_MANAGE_UPDATEIDCARDS_VIEW_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SecurityRoutingModule {}
