import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { SortDirection } from 'src/@hodhod/common/enum';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';

@Component({
  selector: 'app-voilation-dashboard-list',
  templateUrl: './voilation-dashboard-list.component.html',
  styleUrls: ['./voilation-dashboard-list.component.scss']
})
export class VoilationDashboardListComponent implements OnInit {
  @Input() data: any[] = [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  public sectionState: SectionStateStatus = SectionStateStatus.Loading;
  public displayedColumns: string[] = ['cameraName', 'departmentName', 'violation', 'offender', 'status', 'createdOn'];
  public dataSource = [];
  public sortDirection = SortDirection;
  constructor(
    public router: Router,
    private loadingService: LoadingService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      this.loadingService.stopLoading();
      this.dataSource = this.data;
    }
  }

  onClickStatus(): void {
    this.router.navigate(['/voilation_action_tracker']);
  }
  onRowClcik(row: any): void {
    this.router.navigate(['/' + SharedConstants.SMART_MONITORING + '/' + SharedConstants.VOILATIONS + '/' + SharedConstants.VOILATIONS_VIEW + '/' + row.violationId]);
  }

}
