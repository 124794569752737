import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmartMonitoringRoutingModule } from './smart-monitoring-routing.module';
import { SharedModule } from '../shared/shared.module';
import { VideoAnalyticsPageComponent } from './pages/video-analytics-page/video-analytics-page.component';
import { VideoAnalyticsViewPageComponent } from './pages/video-analytics-view-page/video-analytics-view-page.component';
import { VideoAnalyticsComponent } from './components/video-analytics/video-analytics.component';
import { ViewVideoAnalyticsComponent } from './components/view-video-analytics/view-video-analytics.component';
import { EditVideoAnalyticsComponent } from './components/edit-video-analytics/edit-video-analytics.component';
import { VideoAnalyticsService } from 'src/backend/services/video-analytics/video-analytics.service';
import { VoilationsComponent } from './components/voilations/voilations.component';
import { VoilationPageComponent } from './pages/voilation-page/voilation-page.component';
import { ViewVoilationPageComponent } from './pages/view-voilation-page/view-voilation-page.component';
import { ViewVoilationComponent } from './components/view-voilation/view-voilation.component';
import { ActionTrackerModule } from '../action-tracker/action-tracker.module';
import { VideoAnalyticsDashboardPageComponent } from './pages/video-analytics-dashboard-page/video-analytics-dashboard-page.component';
import { VoilationDrilldownColumnChartComponent } from './components/voilation-drilldown-column-chart/voilation-drilldown-column-chart.component';
import { VoilationDashboardListComponent } from './components/voilation-dashboard-list/voilation-dashboard-list.component';


@NgModule({
  declarations: [
    VideoAnalyticsPageComponent,
    VideoAnalyticsViewPageComponent,
    VideoAnalyticsComponent,
    ViewVideoAnalyticsComponent,
    EditVideoAnalyticsComponent,
    VoilationsComponent,
    VoilationPageComponent,
    ViewVoilationPageComponent,
    ViewVoilationComponent,
    VideoAnalyticsDashboardPageComponent,
    VoilationDrilldownColumnChartComponent,
    VoilationDashboardListComponent
  ],
  imports: [
    CommonModule,
    SmartMonitoringRoutingModule,
    SharedModule,
    ActionTrackerModule
  ],
  providers: [
    VideoAnalyticsService
  ],
  exports:[
    VideoAnalyticsPageComponent,
    VideoAnalyticsViewPageComponent,
    VideoAnalyticsComponent,
    ViewVideoAnalyticsComponent,
    EditVideoAnalyticsComponent,
    VoilationsComponent,
    VoilationPageComponent,
    ViewVoilationPageComponent,
    ViewVoilationComponent,
    VideoAnalyticsDashboardPageComponent,
    VoilationDrilldownColumnChartComponent,
    VoilationDashboardListComponent

  ]
})
export class SmartMonitoringModule { }
