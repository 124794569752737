import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ChecklistType } from 'src/@hodhod/common/enum';
import { AddUpdateReasonComponent } from 'src/app/reporting/components/add-update-reason/add-update-reason.component';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { takeUntil } from 'rxjs/operators';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
@Component({
  selector: 'app-add-update-checklist-popup',
  templateUrl: './add-update-checklist-popup.component.html',
  styleUrls: ['./add-update-checklist-popup.component.scss'],
})
export class AddUpdateChecklistPopupComponent implements OnInit {
  formMode: 'create' | 'update' = 'create';
  checklistForm: FormGroup;
  public translationsList: any = {};
  ChecklistType = ChecklistType;
  private destroy$ = new Subject();
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string;
  hasAuditCreatePermission = ApplicationPermission.AUDIT_CHECKLIST_WRITE;
  hasWorkConditionsCreatePermission =
    ApplicationPermission.WORK_CONDITIONS_CREATE;
  hasInspectionCreatePermission =
    ApplicationPermission.INSPECTION_CHECKLIST_WRITE;

  constructor(
    private feedBackService: AsyncFeedbackService,
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AddUpdateReasonComponent>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private checklistService: ChecklistSectionService
  ) {
    this.translate
      .get(['Errors', 'Success', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.checklistForm = this.fb.group({
      checklistName: ['', [Validators.required, noWhitespaceValidator]],
      checklisttype: ['', [Validators.required]],
      minimumScore: ['', []],
    });
    this.checklistForm
      .get('checklisttype')
      .valueChanges.subscribe((checklisttype) => {
        const minimumScoreControl = this.checklistForm.get('minimumScore');

        if (checklisttype === 'Audit') {
          minimumScoreControl.setValidators([
            Validators.required,
            Validators.min(0),
            Validators.max(100),
          ]);
        }
        minimumScoreControl.updateValueAndValidity();
      });
  }
  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createChecklist();
          }
        });
    } else if (this.formMode === 'update') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['Role']['UpdateConfirm']['Title'],
            this.translationsList['Role']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.updateChecklist();
          }
        });
    }
  }

  createChecklist() {
    const { checklistName, checklisttype, minimumScore } =
      this.checklistForm.value;
    let data;
    data = {
      Title: checklistName,
      Type: checklisttype,
    };
    if (checklisttype === 'Audit') {
      data.minimumScore = minimumScore;
    } else {
      data.minimumScore = 100;
    }

    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.checklistService.PostCheckList(data).subscribe({
      next: () => {
        this.dialogRef.close(data);
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['SuccessMessage']
          )
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  updateChecklist() {}

  isCreateMode() {
    return this.formMode === 'create';
  }

  isUpdateMode() {
    return this.formMode === 'update';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
