<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="
    (formMode === 'create'
      ? 'RenewIdCard.AddingLoadingIdCard'
      : 'Role.UpdatingLoadingRole'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="renewCardForm">
    <div class="flex items-center" mat-dialog-title>
      <h2
        *ngIf="renewCardForm.get('idcardNo').value"
        class="headline m-0 flex-auto"
      >
        {{ renewCardForm.get("idcardNo").value }}
      </h2>
      <h2
        *ngIf="!renewCardForm.get('idcardNo').value"
        class="headline m-0 flex-auto"
      >
        {{ "ManageIdCard.RenewIdCard" | translate }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex-col flex-wrap justify-between">
      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "ManageIdCard.IdCardNumber" | translate }}
          <span
            [ngClass]="
              renewCardForm.invalid &&
              renewCardForm.get('idcardNo').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            formControlName="idcardNo"
          />
          <mat-error
            *ngIf="
              renewCardForm.invalid &&
              renewCardForm.get('idcardNo').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              renewCardForm.invalid &&
              renewCardForm.get('idcardNo').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex column">
        <mat-label class="field-label">{{
          "Company.BasicForm.Image" | translate
        }}</mat-label>
        <file-upload
          formControlName="attachment"
          [multiple]="true"
          [animation]="true"
          (change)="checkExtension($event)"
          [accept]="'file_extension|image/*'"
          (dragover)="$event.preventDefault()"
          (drop)="checkExtension($event)"
        >
        </file-upload>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isCreateMode()"
          [btnType]="'Add'"
          [btnText]="'Add'"
          [isDisabled]="!renewCardForm.valid"
          (onClick)="save()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isUpdateMode()"
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="!renewCardForm.valid"
          (onClick)="save()"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
