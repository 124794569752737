import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-detail',
  templateUrl: './dashboard-detail.component.html',
  styleUrls: ['./dashboard-detail.component.scss']
})
export class DashboardDetailComponent implements OnInit {

  configSkeleton: any = {
    'border-radius': '5px',
    'height': '25px',
    'border': '1px solid #D3D3D3'
  }
  constructor() { }

  ngOnInit() {
  }

}
