import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';
import { GetAllReportingType } from 'src/backend/models/reporting-type/reporting-type.model';

@Injectable({
  providedIn: 'root'
})
export class ReportingTypeService {
  netApiUrl: string = '';
  constructor(private http: HttpClient, private baseApi: BaseApi) { }

  getAllReportingtype(): Observable<GetAllReportingType[]> {
    return this.http.get<GetAllReportingType[]>(this.baseApi.encodeURL(Constants.API_REPORTING_REPORTINGLIST));
  }
}
