import { Component, Input, OnInit } from '@angular/core';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Feature } from 'src/backend/models/features/feature';

@Component({
  selector: 'app-company-permissions',
  templateUrl: './company-permissions.component.html',
  styleUrls: ['./company-permissions.component.scss']
})
export class CompanyPermissionsComponent implements OnInit {
  @Input() features: Feature[] = [];

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public isWritePermissionSection = ApplicationPermission.COMPANIES_PERMISSION_FRAGMENT_UPDATE

  constructor() { }

  ngOnInit(): void {
  }

}
