import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { User } from 'src/app/user-management/models/user';
import { UserDetails } from 'src/app/user-management/models/user-details';
import { BaseApi } from '../api/base-api';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getUsersByCompany(data: any): Observable<DataMapper<User>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.verifications) {
      if (params.length > 0) {
        params += '&';
      }
      params += `verifications=` + data?.verifications;
    }
    if (data?.departments) {
      if (params.length > 0) {
        params += '&';
      }
      params += `departments=` + data?.departments;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<User>>(
      this.baseApi.encodeURL(Constants.API_USER_GET_LIST + '?' + params)
    );
  }
  getReportToUsers(): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(Constants.API_USER_REPORT_USERS)
    );
  }
  createUser(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_USER_CREATE),
      data
    );
  }
  editUser(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_USER_UPDATE),
      data
    );
  }

  getUserDetails(id: string): Observable<UserDetails> {
    return this.http.get<UserDetails>(
      this.baseApi.encodeURL(Constants.API_USER_DETAILS + '/' + id)
    );
  }

  savePermissions(permissions: any, userId: string): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(
        Constants.API_USER_SAVE_PERMISSIONS + '/' + userId
      ),
      permissions
    );
  }

  activateUser(id: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_USER_ACTIVATE + '/' + id)
    );
  }
  deactivateUser(id: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_USER_DEACTIVATE + '/' + id)
    );
  }

  getUserHierarchy(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_USERS_HIERARCHY),
      data
    );
  }
}
