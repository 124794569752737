<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.ReviewInspections' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <div
        class="flex justify-center align-middle"
        *ngIf="inspections.length == 0 && sectionState; else showData"
      >
        <div class="flex column align-middle text-center">
          <img
            style="width: fit-content; margin: auto"
            src="assets/img/no-dashboards.png"
          />
          <h1 style="color: #00000099; font-size: large">
            {{ "InspectionManagement.NoData" | translate }}
          </h1>
        </div>
      </div>
      <ng-template #showData>
        <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-6">
          <app-inspection-card
            *ngFor="let inspection of inspections"
            [viewMode]="'reviewer'"
            [inspection]="inspection"
            (onInspectionClick)="viewInspection($event)"
          ></app-inspection-card>
        </div>
      </ng-template>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>
