import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { ValueType } from 'src/app/company-setup/models/value-type';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class ValueTypeService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  createValueType(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_VALUETYPES_CREATE),
      data
    );
  }

  editValueType(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_VALUETYPES_UPDATE),
      data
    );
  }

  getFilteredValueTypes(data: any): Observable<DataMapper<ValueType>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<ValueType>>(
      this.baseApi.encodeURL(
        Constants.API_VALUETYPES_FILTERED_BY_COMPANY + '?' + params
      )
    );
  }

  deActivate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_VALUETYPES_DEACTIVATE + '/' + id)
    );
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_VALUETYPES_ACTIVATE + '/' + id)
    );
  }
  getValueTypeById(id: number): Observable<ValueType> {
    return this.http.get<ValueType>(
      this.baseApi.encodeURL(Constants.API_VALUETYPES_By_Id + '/' + id)
    );
  }

  getValueTypesByCompany(): Observable<ValueType[]> {
    return this.http.get<ValueType[]>(
      this.baseApi.encodeURL(Constants.API_VALUETYPES_LIST_BY_COMPANY)
    );
  }
}
