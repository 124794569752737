import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { IotAnalogDashboardService } from 'src/backend/services/dashboards/iot-analog-dashboard.service';
import { IotDigitalDashboardService } from 'src/backend/services/dashboards/iot-digital-dashboard.service';

@Component({
  selector: 'app-iot-dashboards',
  templateUrl: './iot-dashboards.component.html',
  styleUrls: ['./iot-dashboards.component.scss'],
})
export class IotDashboardsComponent implements OnInit, OnChanges {
  @Input() loadData: boolean;
  SharedConstant = SharedConstants;
  @ViewChild('addIotDashboard') addIotDashboard!: TemplateRef<any>;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';
  public columns: number = 3;
  public visiablityData = [];

  private filterLabel: string = 'lastThirtyDay';
  public filterData: any = {
    fromDate: new Date(),
    toDate: new Date(),
  };

  public digitalDashboards: any = [];
  public analogDashboards: any = [];
  public dashboardList: any = [];
  public machines: any[] = [];

  constructor(
    private iotAnalogDashboardService: IotAnalogDashboardService,
    private iotDigitalDashboardService: IotDigitalDashboardService,
    private feedBackService: AsyncFeedbackService,
    private dialog: MatDialog
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadData'].currentValue) {
      this.getAnalogDashboards();
      this.getDigitalDashboardsData();
    }
  }

  ngOnInit(): void {}

  openCreateModal(): void {
    const data = {
      role: '',
      action: 'create',
    };
    this.dialog
      .open(this.addIotDashboard, {
        height: 'auto',
        width: '35%',
        data: data,
      })
      .afterClosed()
      .subscribe((role: any) => {});
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  getDigitalDashboardsData() {
    let data: any = {
      filteredFrom: new Date(),
      filteredTo: new Date(),
      companyCode: localStorage.getItem('company_id'),
    };

    const today = new Date();
    data.filteredTo = new Date(today);
    const last30Day = new Date(today.setDate(today.getDate() - 30));
    data.filteredFrom = new Date(last30Day);

    this.sectionState = SectionStateStatus.Loading;
    this.iotDigitalDashboardService.getIotDigitalDashboardData(data).subscribe({
      next: (digitalData) => {
        this.generateDigitalDashboards(digitalData);
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  // generate digital dashboards
  generateDigitalDashboards(digitalData?: any) {
    for (let i = 0; i < digitalData.part1.length; i++) {
      const digitalDashboard = {
        title: digitalData.part1[i].machineName,
        filterLabel: this.filterLabel,
        startDate: this.filterData.fromDate,
        endDate: this.filterData.toDate,
        dashboardType: 'digital',
        showCard: true,
        data: digitalData.part1[i].machineParameterReadings,
      };
      this.digitalDashboards.push(digitalDashboard);
    }
    this.dashboardList.push(...this.digitalDashboards);
  }
  // get Analog Dashboards
  getAnalogDashboards() {
    const today = new Date();
    const analogData = {
      filteredFrom: new Date(today.setDate(today.getDate() - 30)),
      filteredTo: new Date(),
      filterName: 'PerDay',
      companyCode: localStorage.getItem('company_id'),
    };

    this.sectionState = SectionStateStatus.Loading;
    this.iotAnalogDashboardService
      .getIotAnalogDashboardData(analogData)
      .subscribe({
        next: (result) => {
          this.generateAnalogData(result);
          this.sectionState = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.feedBackService?.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }
  // generate analog dashboard data
  generateAnalogData(analogData) {
    for (let i = 0; i < analogData.part1.length; i++) {
      const uniqueParameters = [
        ...new Set(
          analogData.part1[i].machineReadings.map(
            (item) => item.iotParameterName
          )
        ),
      ];
      for (let j = 0; j < uniqueParameters.length; j++) {
        let baseObject = {
          title: analogData.part1[i]?.machineName + ' - ' + uniqueParameters[j],
          filterLabel: this.filterLabel,
          textColor: '#35D310',
          dashboardType: 'analog',
          machineName: analogData.part1[i]?.machineName,
          parameterName: uniqueParameters[j],
          startDate: this.filterData.fromDate,
          endDate: this.filterData.toDate,
          unit: analogData.part1[i]?.machineReadings.find(
            (item) => item.iotParameterName == uniqueParameters[j]
          ).unit,
          value: analogData.part1[i]?.machineReadings.find(
            (item) => item.iotParameterName == uniqueParameters[j]
          ).value,
          showCard: true,
          chartData: {
            chartTitle:
              analogData.part1[i]?.machineName + '-' + uniqueParameters[j],
            chartColor: '#3A9E21',
            chartType: 'line',
            xValues: [],
            lowerRange: analogData.part1[i]?.machineReadings.find(
              (item) => item.iotParameterName == uniqueParameters[j]
            ).lowerControlLimit,
            upperRange: analogData.part1[i]?.machineReadings.find(
              (item) => item.iotParameterName == uniqueParameters[j]
            ).upperControlLimit,
            part1: [],
          },
        };
        const object = {
          name:
            analogData.part1[i]?.machineReadings.find(
              (item) => item.iotParameterName == uniqueParameters[j]
            )?.value || '',
          marker: {
            symbol: 'circle',
          },
          data: [],
        };
        const values = analogData.part1[i].machineReadings
          .filter((item) => item.iotParameterName === uniqueParameters[j])
          .map(
            (item) =>
              item.iotParameterName === uniqueParameters[j] &&
              item.parameterValue
          );
        const xValues = analogData.part1[i].machineReadings
          .filter((item) => item.iotParameterName === uniqueParameters[j])
          .map(
            (item) =>
              item.iotParameterName === uniqueParameters[j] && item.filterRange
          );
        baseObject.chartData.xValues = xValues;
        for (let k = 0; k < values.length; k++) {
          object.data.push({
            y: Math.round((parseFloat(values[k]) + Number.EPSILON) * 100) / 100,
            color:
              parseFloat(values[k]) <=
                parseFloat(
                  analogData.part1[i]?.machineReadings.find(
                    (item) => item.iotParameterName == uniqueParameters[j]
                  ).upperControlLimit
                ) &&
              parseFloat(values[k]) >=
                parseFloat(
                  analogData.part1[i]?.machineReadings.find(
                    (item) => item.iotParameterName == uniqueParameters[j]
                  ).lowerControlLimit
                )
                ? '#3A9E21'
                : '#DB2A2A',
            lineColor: '#3A9E21',
          });
        }
        baseObject.chartData.part1.push(object);
        this.analogDashboards.push(baseObject);
        baseObject = null;
      }
    }
    this.dashboardList.push(...this.analogDashboards);
  }
}
