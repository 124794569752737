import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { GetContactsList } from 'src/backend/models/emergency-contacts/emergency-contacts.model';
import { EmergencyContactsService } from 'src/backend/services/emergency-contacts/emergency-contacts.service';
import { AddNewContactComponent } from '../../components/add-new-contact/add-new-contact.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
@Component({
  selector: 'app-emergency-contacts-page',
  templateUrl: './emergency-contacts-page.component.html',
  styleUrls: ['./emergency-contacts-page.component.scss'],
  animations: [fadeInUp400ms],
})
export class EmergencyContactsPageComponent implements OnInit {
  private imgsrc;
  public translationsList: any = {};
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';

  SharedConstant = SharedConstants;
  private destroy$ = new Subject();
  users: any;
  subject$: any;
  contacts: GetContactsList[];
  hasCreatePermissions = ApplicationPermission.SECURITY_MANAGE_CONTACTS_CREATE;

  constructor(
    private route: Router,
    private dialog: MatDialog,
    private contactService: EmergencyContactsService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private feedBackService: AsyncFeedbackService
  ) {
    this.translate
      .get(['Errors', 'Success', 'User', 'confirmDeactiveRecord', 'General'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.getContactsList();
  }

  getContactsList() {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.contactService.getEmergencyContactsList().subscribe({
      next: (res: any) => {
        if (!res?.message) {
          this.contacts = res;
        } else {
          this.contacts = [];
        }
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  actionsBtn(event: any) {
    if (event.event === 'Activate') {
      this.activateContacts(event.event, event.id);
    } else if (event.event === 'Deactivate') {
      this.deactivateContacts(event.event, event.id);
    } else if (event.event === 'Delete') {
      this.deleteContacts(event.event, event.id);
    } else {
      this.updateContacts(event);
    }
  }
  btnClick(event: any) {
    this.createContacts(event);
  }
  createContacts(event: any) {
    this.dialog
      .open(AddNewContactComponent, {
        height: 'auto',
        width: '40%',
      })
      .afterClosed()
      .subscribe((res) => {
        if(res.event === 'created'){
          this.getContactsList();
        }
      });
  }

  updateContacts(data: any) {
    this.dialog
      .open(AddNewContactComponent, {
        height: 'auto',
        width: '40%',
        data: data,
      })
      .afterClosed()
      .subscribe((contacts: GetContactsList) => {
        this.getContactsList();
      });
  }

  deactivateContacts(event: any, contactId: string): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.contactService.deActivate(parseInt(contactId, 10)).subscribe({
            next: () => {
              this.getContactsList();
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Success,
                  this.translationsList['General']['DeActivated']
                )
              );
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Failure,
                  this.translationsList['Errors']['ErrorHappened']
                )
              );
            },
          });
        }
      });
  }

  activateContacts(event: any, contactId: string): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.contactService.activate(parseInt(contactId, 10)).subscribe({
            next: () => {
              this.getContactsList();
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Success,
                  this.translationsList['General']['Activated']
                )
              );
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Failure,
                  this.translationsList['Errors']['ErrorHappened']
                )
              );
            },
          });
        }
      });
  }

  deleteContacts(event: any, contactId: string): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.contactService
            .deleteEmergencyContact(parseInt(contactId, 10))
            .subscribe({
              next: (res) => {
                this.getContactsList();
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
              },
              error: (error) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.error.message)
                );
              },
            });
        }
      });
  }
}
