import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';
import { FeedbackModel, FeedbackType } from '../models/feedback';

@Injectable({
  providedIn: 'root',
})
export class AsyncFeedbackService {
  constructor(private snackBar: MatSnackBar) { }

  public showFeedback(feedback: FeedbackModel) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: {
        message: feedback.message,
        icon: feedback.type === FeedbackType.Success ? 'check_box' : feedback.type === FeedbackType.Failure ? 'error' : 'warning',
      },
      duration: feedback.duration,
      panelClass:
        feedback.type === FeedbackType.Failure ? ['snack-bar-error'] : feedback.type === FeedbackType.Warning ? ['snack-bar-warring'] : [],
    });
  }

  public showFailureMessage(message: string) {
    this.showFeedback(new FeedbackModel(FeedbackType.Failure, message));
  }

  public showSuccessMessage(message: string) {
    this.showFeedback(new FeedbackModel(FeedbackType.Success, message));
  }

  public showWarningMessage(message: string) {
    this.showFeedback(new FeedbackModel(FeedbackType.Warning, message));
  }
}
