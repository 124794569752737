<hodhod-page-layout>
    <hodhod-secondary-toolbar
      [current]="'NavigationBar.OrganizationSettings' | translate"
    >
      <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
    </hodhod-secondary-toolbar>
    <hodhod-page-layout-content class="container-fluid">
      <app-state-section
        body
        [flexibleHeight]="true"
        [state]="sectionState"
        [loadingLabel]="'organization.LoadingInfo' | translate"
        [errorLabel]="'Errors.ErrorHappened' | translate"
      >
      <app-license-data-card></app-license-data-card>
      <app-list-of-companies></app-list-of-companies>

      </app-state-section>
    </hodhod-page-layout-content>
  </hodhod-page-layout>
  
