<!-- <full-calendar [options]="calendarOptions"></full-calendar> -->

<div class="grid grid-cols-4 gap-6 mb-2">
  <div class="col-span-3 flex justify-between">
    <div class="text-lg font-normal">
      {{ selectedDayNumber }} | {{ selectedMonth }}
    </div>

    <div class="flex align-center">
      <button
        class="month-btn"
        (click)="previousMonth()"
        [disabled]="selectedMonthNumber === 0"
      >
        <mat-icon
          [ngClass]="selectedMonthNumber === 0 ? 'disabled-btn' : ''"
          svgIcon="mat:keyboard_arrow_left"
        ></mat-icon>
      </button>

      <input class="month-input" [value]="selectedMonth" matInput readonly />

      <button
        class="month-btn"
        (click)="nextMonth()"
        [disabled]="selectedMonthNumber === monthNames.length - 1"
      >
        <mat-icon
          [ngClass]="
            selectedMonthNumber === monthNames.length - 1 ? 'disabled-btn' : ''
          "
          svgIcon="mat:keyboard_arrow_right"
        ></mat-icon>
      </button>
    </div>
  </div>
  <div
    class="col-span-1 flex justify-start"
    *ngIf="hasCreatePermission | permissionChecker"
  >
    <button mat-button class="plan-btn" (click)="createTask()">
      Plan Audit
    </button>
  </div>
</div>

<div class="grid grid-cols-4 gap-6">
  <div class="col-span-3 flex flex-col">
    <!-- days -->
    <div class="grid grid-cols-7 gap-3 mb-3">
      <mat-card
        class="day-card"
        [ngClass]="
          checkCurrentDay(date)
            ? 'current-card'
            : date == today.toISOString().split('T')[0]
            ? 'today-card'
            : ''
        "
        *ngFor="let date of datesList; let i = index"
        (click)="onDayClick(date, i + 1)"
      >
        <div class="flex justify-end">
          <div class="number-tasks text-primary">
            <span>
              {{ getNumberOfTasksByDay(date).length }}
            </span>
          </div>
        </div>
        <div class="flex justify-between">
          <span class="day-number">
            {{ i + 1 }}
          </span>
          <span class="day-name flex flex-col justify-end mb-1">
            {{ getDayName(date) }}
          </span>
        </div>
      </mat-card>
    </div>

    <div>
      <!-- change year -->
      <div class="flex justify-end">
        <div class="flex align-center">
          <button class="month-btn" (click)="previousYear()">
            <mat-icon svgIcon="mat:keyboard_arrow_left"></mat-icon>
          </button>

          <input class="month-input" [value]="selectedYear" matInput readonly />

          <button class="month-btn" (click)="nextYear()">
            <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
          </button>
        </div>
      </div>

      <!-- months -->
      <div class="flex">
        <button (click)="slideLeft()">
          <mat-icon svgIcon="mat:keyboard_arrow_left"></mat-icon>
        </button>
        <!-- months cards -->
        <div id="months-container" class="overflow-hidden flex p-3">
          <mat-card
            class="mx-3 month-card cursor-pointer"
            (click)="getListOfDaysByMonth(month)"
            *ngFor="let month of monthNames; let i = index"
          >
            <div class="flex justify-end">
              <div
                class="number-tasks text-primary"
                [matTooltip]="
                  getNumberOfTasksByMonth(month) === 0
                    ? 'No Audits Planned For This Month'
                    : null
                "
              >
                <span>
                  {{ getNumberOfTasksByMonth(month) }}
                </span>
              </div>
            </div>
            {{ i + 1 }} | {{ month.toUpperCase().slice(0, 3) }}
          </mat-card>
        </div>

        <button (click)="slideRight()">
          <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="col-span-1">
    <div class="show-audits bg-primary">
      <div class="flex items-center mb-5">
        <div class="task-number-container">
          <span class="text-primary">
            {{ getTasksByDay().length }}
          </span>
        </div>
        <div class="flex flex-col">
          <span class="text-2xl">Audits For Today</span>
          <span class="text-xs">{{ getSelectedDateForAudit() }}</span>
        </div>
      </div>

      <div class="overflow-auto" style="height: 88%">
        <div class="mb-2" *ngFor="let task of tasksOfDay">
          <app-audit-item
            (onChangeTask)="updateTasks($event)"
            [task]="task"
          ></app-audit-item>
        </div>
      </div>
    </div>
  </div>
</div>
