import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inspect-equipment-page',
  templateUrl: './inspect-equipment-page.component.html',
  styleUrls: ['./inspect-equipment-page.component.scss']
})
export class InspectEquipmentPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
