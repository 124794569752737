import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import {
  noWhitespaceValidator,
  PhoneNumberValidator,
  EmailValidator,
} from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Feature, SelectedFeature } from 'src/backend/models/features/feature';
import { FeatureService } from 'src/backend/services/feature.service';
import { OrganizationService } from 'src/backend/services/organization.service';

@Component({
  selector: 'app-update-organization',
  templateUrl: './update-organization.component.html',
  styleUrls: ['./update-organization.component.scss'],
  animations: [fadeInUp400ms],
})
export class UpdateOrganizationComponent implements OnInit, OnDestroy {
  public ACTIVE_COLOR = '#67DD2D';
  public INACTIVE_COLOR = '#F76F45';
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public updateOrgForm!: FormGroup;
  public features: Feature[] = [];
  secFeatures: Feature[] = [];
  sefetyFeatures: Feature[] = [];
  setLicenseQuota: boolean = false;

  public selectedFeatures: Feature[] = [];
  public selectedFeatureCodes: string[] = [];

  private destroy$ = new Subject();
  public translationsList: any = {};
  public loadingLabel: string = 'BuildingOrganizationForm';
  public orgId: number = 0;
  public orgInfo: any;
  public orgLicenseInfo: any;
  constructor(
    private formBuilder: FormBuilder,
    private featureService: FeatureService,
    private feedBackService: AsyncFeedbackService,
    private orgService: OrganizationService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.translate
      .get(['Errors', 'Success', 'organization'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.updateOrgForm = this.formBuilder.group({
      orgCode: ['', [Validators.required, noWhitespaceValidator]],
      orgName: ['', [Validators.required, noWhitespaceValidator]],
      orgImage: [[]],
      noOfCompanies: ['', Validators.required],
      noOfUsers: ['', Validators.required],
      description: [''],
      safetyFeatures: [[]],
      securityFeatures: [[]],
      OrgQuota: [0],
      primary: ['#131C4E'],
      secondary: ['#ffffff'],
    });
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const Id = params.get('id') as string;
        this.orgId = parseInt(Id, 10);
        this.getAllFeatures();
      }
    });
    this.getAllFeatures();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getAllFeatures(): void {
    this.sectionState = SectionStateStatus.Loading;
    this.featureService.getAllFeatures().subscribe({
      next: (response) => {
        this.sectionState = SectionStateStatus.Ready;
        let security = response?.find(
          (element) => element['module'] === 'Security'
        );
        let safety = response?.find(
          (element) => element['module'] === 'Safety'
        );
        this.secFeatures = security['features'];
        this.sefetyFeatures = safety['features'];

        this.getOrganizationById();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  setSelectedFeature(event: any): void {
    const selectedSafetyCodes: string[] =
      this.updateOrgForm.value.safetyFeatures;
    const selectedSecCodes: string[] =
      this.updateOrgForm.value.securityFeatures;

    const allSafety = selectedSafetyCodes.includes('ALL');
    const allSecurity = selectedSecCodes.includes('ALL');

    this.selectedFeatures = [];

    if (allSafety) {
      this.selectedFeatures = [...this.sefetyFeatures];
    } else {
      this.selectedFeatures = this.sefetyFeatures.filter((x) =>
        selectedSafetyCodes.includes(x.featureCode)
      );
    }
    if (allSecurity) {
      this.selectedFeatures = [...this.selectedFeatures, ...this.secFeatures];
    } else {
      const selectedSecurityFeatures = this.secFeatures.filter((x) =>
        selectedSecCodes.includes(x.featureCode)
      );
      this.selectedFeatures = [
        ...this.selectedFeatures,
        ...selectedSecurityFeatures,
      ];
    }
    this.selectedFeatureCodes = this.selectedFeatures.map(
      (feature) => feature.featureCode
    );
    if (this.selectedFeatureCodes.includes('AMF-012')) {
      this.setLicenseQuota = true;
      this.updateOrgForm.get('OrgQuota').addValidators([Validators.required]);
    } else {
      this.setLicenseQuota = false;
    }
  }

  getOrganizationById(): void {
    this.sectionState = SectionStateStatus.Loading;
    this.orgService.getOrganizationDetails(this.orgId).subscribe({
      next: (response) => {
        this.orgInfo = response;

        this.builForm(this.orgInfo);
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  getOrgCardInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.orgService.getOrganizationCardDetails(this.orgId).subscribe({
      next: (response) => {
        this.orgLicenseInfo = response['message'];
        this.setLicenseQuota = true;
        this.updateOrgForm
          .get('OrgQuota')
          .setValue(response['message']?.accessControlLicenseQuota);
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }

  updateOrganization(): void {
    const {
      orgName,
      description,
      safetyFeatures,
      orgImage,
      noOfCompanies,
      OrgQuota,
      noOfUsers,
      primary,
      secondary,
    } = this.updateOrgForm.value;
    const formData = new FormData();
    formData.append('file', orgImage[0] ? orgImage[0] : null);
    const organizationData = {
      orgId: this.orgId,
      orgName,
      description,
      OrgQuota,
      features: this.selectedFeatureCodes,
      oldImage: this.orgInfo?.organizationImage,
      noOfCompanies,
      noOfUsers,
      themeColor: primary,
      secondaryColor: secondary,
    };
    formData.append('content', JSON.stringify(organizationData));
    this.loadingLabel = 'UpdatingOrganization';
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['organization']['UpdateConfirm']['Title'],
          this.translationsList['organization']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.orgService.editOrganization(formData).subscribe({
            next: (respose) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, respose?.message)
              );
              this.router.navigate([
                '/' +
                  SharedConstants.ORGANIZATION_SETUP +
                  '/' +
                  SharedConstants.ORGANIZATIONS,
              ]);
            },
            error: ({ error }) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
  }
  builForm(response: any): void {
    this.updateOrgForm.get('orgCode')?.setValue(response?.organizationCode);
    this.updateOrgForm.get('orgName')?.setValue(response?.organizationName);
    this.updateOrgForm.get('orgImage')?.setValue([]);
    this.updateOrgForm
      .get('noOfCompanies')
      ?.setValue(response?.noOfEligibleCompanies);
    this.updateOrgForm.get('noOfUsers')?.setValue(response?.noOfEligibleUsers);
    this.updateOrgForm.get('description')?.setValue(response?.description);
    response?.themeColor
      ? this.updateOrgForm.get('primary')?.setValue(response?.themeColor)
      : null;
    response?.secondaryColor
      ? this.updateOrgForm.get('secondary')?.setValue(response?.secondaryColor)
      : null;
    response?.themeColor
      ? this.updateOrgForm.get('primary')?.setValue(response?.themeColor)
      : null;
    response?.secondaryColor
      ? this.updateOrgForm.get('secondary')?.setValue(response?.secondaryColor)
      : null;
    let safetyCodes = response?.features?.find(
      (element) => element['module'] === '0'
    );

    let secCodes = response?.features?.find(
      (element) => element['module'] === '1'
    );
    if (safetyCodes) {
      const selectedSafetyFeatures = safetyCodes['features'];
      safetyCodes = safetyCodes['features'].map(
        (feature) => feature.featureCode
      );
      this.updateOrgForm.get('safetyFeatures')?.setValue(safetyCodes);
      this.selectedFeatures = [...selectedSafetyFeatures];
    }

    if (secCodes) {
      const selectedSecurityFeatures = secCodes['features'];
      secCodes = secCodes['features'].map((feature) => feature.featureCode);
      this.updateOrgForm.get('securityFeatures')?.setValue(secCodes);
      this.selectedFeatures = [
        ...(this.selectedFeatures || []),
        ...selectedSecurityFeatures,
      ];
      this.getOrgCardInfo();
    }
    this.selectedFeatureCodes = [...(secCodes || []), ...(safetyCodes || [])];
  }

  cancel(): void {
    this.router.navigate([
      '/' +
        SharedConstants.ORGANIZATION_SETUP +
        '/' +
        SharedConstants.ORGANIZATIONS,
    ]);
  }
}
