import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';
import {
  addDays,
  endOfDay,
  isSameDay,
  isSameMonth,
  startOfDay,
} from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { TaskTrackerService } from 'src/backend/services/task-tracker/task-tracker.service';
import { AddInspectionPlanComponent } from '../add-inspection-plan/add-inspection-plan.component';
import moment from 'moment';
import { Router } from '@angular/router';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { PermissionService } from 'src/backend/services/permission.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UpdateSingleTaskComponent } from '../update-single-task/update-single-task.component';

const colors: any = {
  blue: {
    primary: '#5c77ff',
    secondary: '#FFFFFF',
  },
  yellow: {
    primary: '#ffc107',
    secondary: '#FDF1BA',
  },
  red: {
    primary: '#f44336',
    secondary: '#FFFFFF',
  },
};
@Component({
  selector: 'app-inspection-plan',
  templateUrl: './inspection-plan.component.html',
  styleUrls: ['./inspection-plan.component.scss'],
})
export class InspectionPlanComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild('editDialog') editDialog!: TemplateRef<any>;

  @ViewChild('deleteDialog') deleteDialog!: TemplateRef<any>;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  editDialogForm: FormGroup;
  deleteDialogForm: FormGroup;
  taskId: any;
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refresh: Subject<any> = new Subject();
  actions: CalendarEventAction[] = [
    {
      label: 'Edit',
      a11yLabel: 'Edit',
      cssClass: 'edit-btn',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: 'Remove',
      a11yLabel: 'Delete',
      cssClass: 'dlt-btn',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Deleted', event);
      },
    },
  ];
  events: CalendarEvent[] = [];
  activeDayIsOpen = true;
  TaskRecurrence: any;
  private destroy$ = new Subject();
  public translationsList: any = {};
  hasCreatePermissions = ApplicationPermission.INSPECTION_PLAN_CREATE;
  hasApprovePermissions = ApplicationPermission.INSPECTION_PLAN_APPROVE;
  hasEditPermissions = ApplicationPermission.INSPECTION_PLAN_UPDATE;
  hasDeletePermissions = ApplicationPermission.INSPECTION_PLAN_DELETE;
  loggedUser: LoggedUser;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private TaskTrackerService: TaskTrackerService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private feedBackService: AsyncFeedbackService,
    private translate: TranslateService,
    private permissionService: PermissionService,
    private baseApi: BaseApi
  ) {
    this.translate
      .get([
        'Errors',
        'Success',
        'Role',
        'TaskTracker',
        'RenewIdCard',
        'StaticPages',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.editDialogForm = this.fb.group({
      check: [false],
    });
    this.deleteDialogForm = this.fb.group({
      check: [false],
    });
    this.GetTaskRecurrence();
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.activeDayIsOpen = !(
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      );
      this.viewDate = date;
    }
  }
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.taskId = event.id;
    if (action === 'Clicked') {
      this.router.navigate([
        '/' +
          SharedConstants.TASK_TRACKER +
          '/' +
          SharedConstants.TASK_TRACKER_List +
          '/' +
          SharedConstants.EXECUTE_TASK_TRACKER +
          '/' +
          event.id,
      ]);
    }
    const task = this.TaskRecurrence.find((x) => x.id === event.id);
    const approvePermission = this.permissionService.isUserHasPermission(
      this.hasApprovePermissions
    );
    const rejectPermission = this.permissionService.isUserHasPermission(
      this.hasApprovePermissions
    );
    const editPermission = this.permissionService.isUserHasPermission(
      this.hasEditPermissions
    );
    const deletePermission = this.permissionService.isUserHasPermission(
      this.hasDeletePermissions
    );

    const Status = this.TaskRecurrence.find((x) => x.id === event.id).status;
    let hasPermission = false;
    if (approvePermission && rejectPermission) {
      hasPermission = true;
    }
    if (action === 'Edited') {
      if (
        editPermission &&
        Status === 'NEW' &&
        task.createdBy == this.loggedUser.userId
      ) {
        this.taskId = event.id;
        this.dialog.open(this.editDialog, {
          height: 'auto',
          width: '30%',
        });
      } else if (!editPermission) {
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Failure,
            this.translationsList['StaticPages']['AccessDenied']['Title']
          )
        );
      } else {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Warning, 'This task cannot be edited')
        );
      }
    }
    if (action === 'Deleted') {
      if (
        deletePermission &&
        Status === 'NEW' &&
        task.createdBy == this.loggedUser.userId
      ) {
        this.dialog.open(this.deleteDialog, {
          height: 'auto',
          width: '30%',
        });
      } else if (!deletePermission) {
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Failure,
            this.translationsList['StaticPages']['AccessDenied']['Title']
          )
        );
      } else {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Warning, 'This task cannot be deleted')
        );
      }
    }
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        color: colors.red,
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  deleteEvent(taskId: any) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['TaskTracker']['RemoveTaskConfirm']['Title'],
          this.translationsList['TaskTracker']['RemoveTaskConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.TaskTrackerService.removeTask(parseInt(taskId, 10)).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.GetTaskRecurrence();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
    //this.events = this.events.filter(event => event !== eventToDelete);
  }

  deleteSeries(taskId: any) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['TaskTracker']['RemoveTaskConfirm']['Title'],
          this.translationsList['TaskTracker']['RemoveTaskConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.TaskTrackerService.removeTaskSeries(
            parseInt(taskId, 10)
          ).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.GetTaskRecurrence();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
    //this.events = this.events.filter(event => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  createTask() {
    const data = {
      role: '',
      action: 'create',
    };
    this.dialog
      .open(AddInspectionPlanComponent, {
        height: 'auto',
        width: '90%',
        data: data,
      })
      .afterClosed()
      .subscribe((role: any) => {
        this.GetTaskRecurrence();
      });
  }
  GetTaskRecurrence() {
    this.TaskTrackerService.GetTaskRecurrence().subscribe({
      next: (res) => {
        if (res != null) {
          this.TaskRecurrence = res;
          this.TaskRecurrence.forEach((element) => {
            // if (element.recurrenceType == 'Daily') {
            //Days between Start Date and End Date

            if (
              element.recurrenceType != 'Weekly' &&
              element.recurrenceSubType != 'Absolute'
            ) {
              if (element.durationType == 'Days') {
                var DurationEnd = new Date();
                var a = new Date(element.taskDate).getDate();
                //DurationEnd.setDate(a + (Number(element.durationDays)) )

                const obj = {
                  id: element.id,
                  start: startOfDay(new Date(element.startTaskDate)),
                  //end:endOfDay( new Date(moment(DurationEnd).format('YYYY-MM-DD')) ),
                  end: addDays(
                    new Date(element.startTaskDate),
                    Number(element.durationDays) - 1
                  ),
                  title: element.title + '(' + element.description + ')',
                  color: colors.primary,
                  actions: this.actions,
                  allDay: true,
                  resizable: {
                    beforeStart: true,
                    afterEnd: true,
                  },
                  draggable: false,
                };

                this.events = [...this.events, obj];

                // var newDate = loopDaily.setDate(DurationEnd.getDate() + element.interval);
                // loopDaily = new Date(newDate);
              } else {
                const obj = {
                  id: element.id,
                  title: element.title + '(' + element.description + ')',
                  start: new Date(
                    moment(new Date(element.startTaskDate)).format(
                      'YYYY-MM-DD'
                    ) +
                      ' ' +
                      element.startTime
                  ),
                  end: new Date(
                    moment(new Date(element.startTaskDate)).format(
                      'YYYY-MM-DD'
                    ) +
                      ' ' +
                      element.endTime
                  ),
                  color: colors.red,
                  actions: this.actions,
                  draggable: false,
                  resizable: {
                    beforeStart: true,
                    afterEnd: true,
                  },
                };
                this.events = [...this.events, obj];
              }
            } else {
              if (element.durationType == 'Days') {
                var DurationEnd = new Date();
                var a = new Date(element.taskDate).getDate();
                //DurationEnd.setDate(a + (Number(element.durationDays)) )

                const obj = {
                  id: element.id,
                  start: startOfDay(
                    new Date(element?.startTaskDate?.toString())
                  ),
                  //end:endOfDay( new Date(moment(DurationEnd).format('YYYY-MM-DD')) ),
                  end: addDays(
                    new Date(element?.startTaskDate?.toString()),
                    Number(element.durationDays) - 1
                  ),
                  title: element.title + '(' + element.description + ')',
                  color: colors.primary,
                  actions: this.actions,
                  allDay: true,
                  resizable: {
                    beforeStart: true,
                    afterEnd: true,
                  },
                  draggable: false,
                };

                this.events = [...this.events, obj];

                // var newDate = loopDaily.setDate(DurationEnd.getDate() + element.interval);
                // loopDaily = new Date(newDate);
              } else {
                const obj = {
                  id: element.id,
                  title: element.title + '(' + element.description + ')',
                  start: new Date(
                    moment(
                      new Date(element.taskDate.toString().replace('Z', ''))
                    ).format('YYYY-MM-DD') +
                      ' ' +
                      element.startTime
                  ),
                  end: new Date(
                    moment(
                      new Date(element.taskDate.toString().replace('Z', ''))
                    ).format('YYYY-MM-DD') +
                      ' ' +
                      element.endTime
                  ),
                  color: colors.red,
                  actions: this.actions,
                  draggable: false,
                  resizable: {
                    beforeStart: true,
                    afterEnd: true,
                  },
                };
                this.events = [...this.events, obj];
              }
            }
          });
        }
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  GetDayNo(day: string) {
    switch (day) {
      case 'Monday':
        return 1;
      case 'Tuesday':
        return 2;
      case 'Wednesday':
        return 3;

      case 'Thursday':
        return 4;
      case 'Friday':
        return 5;
      case 'Saturday':
        return 6;
      case 'Sunday':
        return 7;
      default:
        return 0;
    }
  }

  weekOfMonth(m) {
    return m.week() - moment(m).startOf('month').week() + 1;
  }

  onClickEditDialog(): any {
    this.dialog.closeAll();
    if (this.editDialogForm.value.check == true) {
      const data = {
        role: '',
        action: 'update',
        Id: this.taskId,
      };

      let dialogRef = this.dialog
        .open(AddInspectionPlanComponent, {
          height: 'auto',
          width: '90%',
          data: data,
        })
        .afterClosed()
        .subscribe((role: any) => {
          this.GetTaskRecurrence();
        });
    } else {
      const data = {
        role: '',
        action: 'update',
        Id: this.taskId,
      };

      let dialogRef = this.dialog
        .open(UpdateSingleTaskComponent, {
          height: 'auto',
          width: '90%',
          data: data,
        })
        .afterClosed()
        .subscribe((role: any) => {
          this.GetTaskRecurrence();
        });
    }
  }

  onClickDeleteDialog(): any {
    this.dialog.closeAll();
    if (this.deleteDialogForm.value.check == true) {
      this.deleteSeries(this.taskId);
    } else {
      this.deleteEvent(this.taskId);
    }
  }
}
