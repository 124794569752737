import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { InspectionManagementInspectorsComponent } from './pages/inspection-management-inspectors/inspection-management-inspectors.component';
import { InspectionManagementReviewersComponent } from './pages/inspection-management-reviewers/inspection-management-reviewers.component';
import { ReviewerCategoryDetailsPageComponent } from './pages/reviewer-category-details-page/reviewer-category-details-page.component';
import { InspectEquipmentPageComponent } from './pages/inspect-equipment-page/inspect-equipment-page.component';
import { InspectionManagementExcuteComponent } from './pages/inspection-management-excute/inspection-management-excute.component';
import { InspectionReviewComponent } from './pages/inspection-review/inspection-review.component';
import { HistoryLogsPageComponent } from './pages/history-logs-page/history-logs-page.component';

const routes: Routes = [
  {
    path: SharedConstants.INSPECT_EQUIPMENT + '/:id',
    component: InspectEquipmentPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.PERFORM_INSPECTION,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.REVIEW_INSPECTIONS + '/:id',
    component: ReviewerCategoryDetailsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REVIEW_INSPECTION,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },

  {
    path: SharedConstants.INSPECTION_MANAGEMENT_LIST_INSPECTORS,
    component: InspectionManagementInspectorsComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.PERFORM_INSPECTION,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'YourInspections',
      },
    },
  },
  {
    path: SharedConstants.INSPECTION_MANAGEMENT_LIST_REVIEWERS,
    component: InspectionManagementReviewersComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REVIEW_INSPECTION,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'ReviewInspections',
      },
    },
  },
  {
    path: SharedConstants.INSPECTION_MANAGEMENT_EXECUTE + '/:id',
    component: InspectionManagementExcuteComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REVIEW_INSPECTION,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'ExecuteInspection',
      },
    },
  },
  {
    path: SharedConstants.INSPECTION_MANAGEMENT_REVIEW + '/:id',
    component: InspectionReviewComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REVIEW_INSPECTION,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'ReviewInspection',
      },
    },
  },
  {
    path: SharedConstants.HISTORY_LOGS + '/:id',
    component: HistoryLogsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REVIEW_INSPECTION,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'HistoryLogs',
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InspectionManagementRoutingModule {}
