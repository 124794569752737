<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.CompanySettings' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section body [flexibleHeight]="true" [state]="sectionState"
      [loadingLabel]="'Company.LoadingInfo' | translate" [errorLabel]="'Errors.ErrorHappened' | translate">
      <div>
        <app-company-detail [compInfo]="compInfo" [compLicenseInfo]="compLicenseInfo"></app-company-detail>
      </div>
      <div class="mt-3">
        <app-company-permissions [features]="selectedFeatures"></app-company-permissions>
      </div>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>