import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { Role } from 'src/app/user-management/models/role';
import { RoleDetail } from 'src/app/user-management/models/role-detail';
import { BaseApi } from '../api/base-api';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getRolesByCompany(data: any): Observable<DataMapper<Role>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<Role>>(
      this.baseApi.encodeURL(Constants.API_ROLE_GET_BY_COMPANY + '?' + params)
    );
  }

  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(
      this.baseApi.encodeURL(Constants.API_ROLE_GET_ALL)
    );
  }
  createRole(data: any[]): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ROLE_CREATE),
      data
    );
  }
  updateRole(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ROLE_EDIT),
      data
    );
  }

  activateRole(id: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ROLE_ACTIVATE + '/' + id)
    );
  }
  deactivateRole(id: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ROLE_DEACTIVATE + '/' + id)
    );
  }
  getRoleDetails(id: any): Observable<RoleDetail> {
    return this.http.get<RoleDetail>(this.baseApi.encodeURL(Constants.API_ROLE_DETAILS + '/' + id));
  }
  savePermissions(permissions: any, roleId: string): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(
        Constants.API_ROLE_SAVE_PERMISSIONS + '/' + roleId
      ),
      permissions
    );
  }
  createPermissions(data:any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_NEW_ROLE_PERMISSIONS),data);
  }

}
