export class LostIdCard {
  requestId: string;
  employeeCode: string;
  employeeName: string;
  employeeId: string;
  email: string;
  department: string;
  role: string;
  UpdateAt: Date;
  isActive: boolean;
  status: string;

  constructor(idCard) {
    this.requestId = idCard.requestId;
    this.employeeCode = idCard.employeeCode;
    this.employeeName = idCard.employeeName;
    this.employeeId = idCard.employeeId;
    this.email = idCard.email;
    this.department = idCard.department;
    this.role = idCard.role;
    this.UpdateAt = idCard.UpdateAt;
    this.isActive = idCard.isActive;
    this.status = idCard.status;
  }
}
