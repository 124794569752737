import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PostRootCause,
  PutRootCause,
  GetRootCause,
} from 'src/backend/models/root-cause/root-cause.models';
import { Constants } from 'src/@hodhod/common/constants';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { SortDirection } from 'src/@hodhod/common/enum';
import { BaseApi } from 'src/backend/api/base-api';
@Injectable({
  providedIn: 'root',
})
export class RootCauseService {
  netApiUrl: string = '';
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  postRootCause(data: PostRootCause): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_ROOT_CAUSE_POST),
      data
    );
  }

  updateRootCause(data: PutRootCause): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_ROOT_CAUSE_EDIT),
      data
    );
  }

  getRootCause(data: any): Observable<DataMapper<GetRootCause>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<GetRootCause>>(
      this.baseApi.encodeURL(Constants.API_ROOT_CAUSE_GET_ALL + '?' + params)
    );
  }

  deActivate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ROOT_CAUSE_DEACTIVATE + '/' + id)
    );
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ROOT_CAUSE_ACTIVATE + '/' + id)
    );
  }

  getRootCauseByCompany(reportType): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_ROOT_CAUSES_BY_COMPANY + '/'+ reportType)
    );
  }
}
