import { Component, OnInit } from '@angular/core';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'hodhod-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class DashboardComponent implements OnInit {
  public loadIot = false;

  public userName = '';
  constructor() {}

  ngOnInit(): void {
    this.userName = JSON.parse(localStorage.getItem('logged_in_user')).fullName;
  }

  tabChanged(event: MatTabChangeEvent) {
    const selectedTabIndex = event.index;
    if (selectedTabIndex == 1 && !this.loadIot) {
      this.loadIot = true;
    }
  }
}
