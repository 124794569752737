import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DashboardCalenderComponent } from '../dashboard-calender/dashboard-calender.component';
import { Router } from '@angular/router';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ReportingDashboardService } from 'src/backend/services/dashboards/reporting-dashboard.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { IotDigitalDashboardService } from 'src/backend/services/dashboards/iot-digital-dashboard.service';
import { IotAnalogDashboardService } from 'src/backend/services/dashboards/iot-analog-dashboard.service';
import moment from 'moment';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { EditDashboardTargetPopupComponent } from '../edit-dashboard-target-popup/edit-dashboard-target-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  IoTFilterDashboards,
  SmartSafetyFilterDashboards,
} from 'src/@hodhod/common/enum';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
})
export class DashboardCardComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() dashboard: any = null;
  @Input() chartLevel: any = 'one';
  @Input() hideActions: boolean = false;
  @Input() clickedPoint: { point: string; level: string } = null;
  @Input() fullScreenOpen = false;
  @Input() fullScreenFilter: any = null;

  @Output() chartClickedEvent = new EventEmitter();
  @Output() fullScreenEvent = new EventEmitter();
  @Output() fullscreenDashboard = new EventEmitter();
  @Output() updateTarget = new EventEmitter();

  public openCalender: boolean = false;
  public filterDashboards = null;
  public updatedChartData: any;
  public filteredDashboard: any = {
    part1: [],
    part2: [],
    part3: [],
    part4: [],
  };
  public showChart = true;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';

  searchForm = new FormGroup({
    start: new FormControl(
      new Date(new Date().setDate(new Date().getDate() - 7))
    ),
    end: new FormControl(new Date()),
    menu: new FormControl(),
  });

  public translationsList: any = {};
  private destroy$ = new Subject();

  hasUpdateDashboardTargetPermission =
    ApplicationPermission.DASHBOARD_TARGET_UPDATE;

  constructor(
    private reportingDashboardService: ReportingDashboardService,
    private iotDigitalDashboardService: IotDigitalDashboardService,
    private iotAnalogDashboardService: IotAnalogDashboardService,
    private dialog: MatDialog,
    private feedBackService: AsyncFeedbackService,
    private translate: TranslateService,
    public router: Router,
    public datePipe: DatePipe
  ) {
    this.translate
      .get(['Dashboards'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  ngAfterViewInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fullScreenFilter']?.currentValue) {
      if (this.fullScreenFilter === 'customRange') {
        this.onSelectCustomRange();
      } else {
        this.onChangeCalenderDD(this.fullScreenFilter);
      }
    }
  }

  ngOnInit() {
    this.updatedChartData = this.dashboard.chartData;
    this.filterDashboards =
      this.dashboard?.dashboardType === 'analog' ||
      this.dashboard?.dashboardType === 'digital'
        ? IoTFilterDashboards
        : SmartSafetyFilterDashboards;
    // this.onChangeCalenderDD(this.dashboard.filterLabel);
  }

  formatDate(date) {
    return this.datePipe
      .transform(date, 'yyyy-MM-ddT00:00:00.000')
      .slice(0, 10);
  }

  openTargetDialog() {
    const dialogRef = this.dialog.open(EditDashboardTargetPopupComponent, {
      width: window.innerWidth < 850 ? '90%' : '60%',
      height: 'auto',
      data: {
        targetData: { ...this.dashboard.targetData },
        targetTitle: this.translationsList.Dashboards[this.dashboard.title],
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.updateTarget.emit(result?.updatedData);
    });
  }

  onSelectCustomRange() {
    this.searchForm.get('start')?.setValue(new Date());
    this.searchForm.get('end')?.setValue(new Date());

    const today = new Date();

    const dialogRef = this.dialog.open(DashboardCalenderComponent, {
      width: window.innerWidth < 850 ? '90%' : '60%',
      height: 'auto',
      data: {
        toDate: new Date(today),
        fromDate: new Date(today),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && !result?.event) {
        result?.data?.fromDate.setHours(0, 0, 0, 0);
        result?.data?.toDate.setHours(23, 59, 59, 0);

        this.searchForm.get('start')?.setValue(result?.data?.fromDate);
        this.searchForm.get('end')?.setValue(result?.data?.toDate);
        this.dashboard.filterLabel = 'customRange';

        this.dashboard.startDate = new Date(
          result.data.fromDate.getTime() -
            result.data.fromDate.getTimezoneOffset() * 60000
        ).toISOString();
        this.dashboard.endDate = new Date(result.data.toDate);
        if (this.dashboard?.dashboardType === 'digital') {
          this.filterDigitalDashboards();
        } else if (this.dashboard?.dashboardType === 'analog') {
          this.filterAnalogDashboards();
        } else {
          this.saveFilterData();
        }
      }
    });
  }

  onChangeCalenderDD(value) {
    this.openCalender = false;
    const menuVal = value;
    const date = new Date();

    if (menuVal === 'thisDay') {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);

      let lastHourInDay = new Date();
      lastHourInDay.setHours(23);
      lastHourInDay.setMinutes(59);
      lastHourInDay.setSeconds(59);
      lastHourInDay.setMilliseconds(999);

      this.searchForm.get('start')?.setValue(today);
      this.searchForm.get('end')?.setValue(lastHourInDay);

      this.dashboard.filterLabel = menuVal;
      this.dashboard.startDate = new Date(today);
      this.dashboard.endDate = new Date(today);

      if (this.dashboard?.dashboardType === 'digital') {
        this.filterDigitalDashboards();
      } else if (this.dashboard?.dashboardType === 'analog') {
        this.filterAnalogDashboards('perHour');
      } else {
        this.saveFilterData();
      }
    } else if (menuVal === 'yesterday') {
      const today = new Date();
      const day = today.getDate() - 1;
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setDate(day);

      this.searchForm.get('start')?.setValue(new Date(today));
      today.setHours(23);
      today.setMinutes(59);
      today.setSeconds(59);
      today.setMilliseconds(999);
      this.searchForm.get('end')?.setValue(new Date(today));

      this.dashboard.filterLabel = menuVal;
      this.dashboard.startDate = new Date(today);
      this.dashboard.endDate = new Date(today);

      if (this.dashboard?.dashboardType === 'digital') {
        this.filterDigitalDashboards();
      } else if (this.dashboard?.dashboardType === 'analog') {
        this.filterAnalogDashboards('perHour');
      } else {
        this.saveFilterData();
      }
    } else if (menuVal === 'currentMonth') {
      const today = new Date();
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      firstDay.setHours(0, 0, 0, 0);
      today.setHours(23, 59, 59, 0);

      this.searchForm.get('start')?.setValue(firstDay);
      this.searchForm.get('end')?.setValue(today);

      this.dashboard.startDate = new Date(new Date(firstDay));
      this.dashboard.endDate = new Date(today);
      this.dashboard.filterLabel = menuVal;

      if (this.dashboard?.dashboardType === 'digital') {
        this.filterDigitalDashboards();
      } else if (this.dashboard?.dashboardType === 'analog') {
        this.filterAnalogDashboards();
      } else {
        this.saveFilterData();
      }
    } else if (menuVal === 'lastMonth') {
      const today = new Date();
      const firstDayPrevMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      firstDayPrevMonth.setHours(0, 0, 0, 0);
      this.searchForm.get('start')?.setValue(new Date(firstDayPrevMonth));
      const lastDayPrevMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );

      lastDayPrevMonth.setHours(23, 59, 59, 0);
      this.searchForm.get('end')?.setValue(lastDayPrevMonth);

      this.dashboard.startDate = new Date(new Date(firstDayPrevMonth));
      this.dashboard.endDate = new Date(lastDayPrevMonth);
      this.dashboard.filterLabel = menuVal;

      if (this.dashboard?.dashboardType === 'digital') {
        this.filterDigitalDashboards();
      } else if (this.dashboard?.dashboardType === 'analog') {
        this.filterAnalogDashboards();
      } else {
        this.saveFilterData();
      }
    } else if (menuVal === 'lastThirtyDay') {
      const today = new Date();
      today.setHours(23, 59, 59, 0);
      this.searchForm.get('end')?.setValue(new Date(today));
      const Past30Day = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
      Past30Day.setHours(0, 0, 0, 0);
      this.searchForm.get('start')?.setValue(Past30Day);

      this.dashboard.startDate = new Date(Past30Day);
      this.dashboard.endDate = new Date(today);
      this.dashboard.filterLabel = menuVal;

      if (this.dashboard?.dashboardType === 'digital') {
        this.filterDigitalDashboards();
      } else if (this.dashboard?.dashboardType === 'analog') {
        this.filterAnalogDashboards();
      } else {
        this.saveFilterData();
      }
    } else if (menuVal === 'yearToDate') {
      const today = new Date();
      today.setHours(23, 59, 59, 0);
      this.searchForm.get('end')?.setValue(new Date(today));
      const startYearDate = new Date(date.getFullYear(), 0, 1);
      startYearDate.setHours(0, 0, 0, 0);
      this.searchForm.get('start')?.setValue(startYearDate);
      this.dashboard.startDate = new Date(startYearDate);
      this.dashboard.endDate = new Date(today);
      this.dashboard.filterLabel = menuVal;

      if (this.dashboard?.dashboardType === 'digital') {
        this.filterDigitalDashboards();
      } else if (this.dashboard?.dashboardType === 'analog') {
        this.filterAnalogDashboards();
      } else {
        this.saveFilterData();
      }
    } else if (menuVal === 'currentHour') {
      const currentHour = new Date();
      currentHour.setMinutes(0);
      currentHour.setSeconds(0);
      currentHour.setMilliseconds(0);
      const endOfCurrentHour = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        59,
        59,
        0
      );
      this.searchForm.get('start')?.setValue(currentHour);
      this.searchForm.get('end')?.setValue(endOfCurrentHour);
      this.dashboard.startDate = new Date(currentHour);
      this.dashboard.endDate = new Date(endOfCurrentHour);
      this.dashboard.filterLabel = menuVal;
      if (this.dashboard.dashboardType === 'digital') {
        this.filterDigitalDashboards();
      } else if (this.dashboard.dashboardType === 'analog') {
        this.filterAnalogDashboards('PerMinute');
      } else {
        this.saveFilterData();
      }
    } else if (menuVal === 'lastHour') {
      const now = new Date();
      const beginningOfPreviousHour = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours() - 1,
        0,
        0,
        0
      );
      const endOfLastHour = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        now.getHours() - 1,
        59,
        59,
        999
      );
      this.searchForm.get('start')?.setValue(beginningOfPreviousHour);
      this.searchForm.get('end')?.setValue(endOfLastHour);
      this.dashboard.startDate = new Date(beginningOfPreviousHour);
      this.dashboard.endDate = new Date(endOfLastHour);
      this.dashboard.filterLabel = menuVal;
      if (this.dashboard.dashboardType === 'digital') {
        this.filterDigitalDashboards();
      } else if (this.dashboard.dashboardType === 'analog') {
        this.filterAnalogDashboards('PerMinute');
      } else {
        this.saveFilterData();
      }
    }
  }

  saveFilterData() {
    const filterData = {
      dashboardName: this.dashboard.title,
      filterName: this.dashboard.filterLabel,
      fromDate: moment(this.searchForm.value.start + ' UTC').toISOString(),
      toDate: moment(this.searchForm.value.end + ' UTC').toISOString(),
    };
    this.reportingDashboardService
      .SaveCustomFilterForDashboard(filterData)
      .subscribe({
        next: (res) => {
          this.filterReportingDashboard();
        },
        error: (error) => {
          this.feedBackService?.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  filterReportingDashboards(filterDashboard, colors?: any) {
    let object: any;
    let color: Array<string> = [];
    this.dashboard.chartData.part1 = [];
    this.dashboard.chartData.part2 = [];
    this.dashboard.chartData.part3 = [];
    this.dashboard.chartData.part4 = [];

    // part1
    color = colors;
    for (let i = 0; i < filterDashboard.level1?.length; i++) {
      object = {
        name: filterDashboard.level1[i]?.drilldown,
        type: this.dashboard.title == 'TotalReported' ? 'bar' : '',
        data: [
          {
            y: parseInt(filterDashboard.level1[i]?.y),
            drilldown: filterDashboard.level1[i]?.drilldown,
          },
        ],
        status: filterDashboard.level1[i]?.name,
        color: color[i],
      };

      this.dashboard.chartData.part1.push(object);
      object = null;
    }

    // part2
    for (let i = 0; i < filterDashboard.level2?.length; i++) {
      for (let index = 0; index < filterDashboard.level1?.length; index++) {
        if (
          filterDashboard.level2[i]?.id ==
          filterDashboard.level1[index]?.drilldown
        ) {
          object = {
            name: filterDashboard.level2[i]?.name,
            id: filterDashboard.level2[i]?.id,
            type: this.dashboard.title == 'TotalReported' ? 'column' : '',
            data: [
              {
                y: parseInt(filterDashboard.level2[i]?.y),
                drilldown: filterDashboard.level2[i]?.drilldown,
              },
            ],
            status: filterDashboard.level2[i]?.status,
            color: color[i],
          };

          this.dashboard.chartData.part2.push(object);
          object = null;
        }
      }
    }

    // part3
    for (let i = 0; i < filterDashboard.level3?.length; i++) {
      for (let index = 0; index < filterDashboard.level2?.length; index++) {
        if (
          filterDashboard.level3[i]?.id ==
          filterDashboard.level2[index]?.drilldown
        ) {
          object = {
            name: filterDashboard.level3[i]?.name,
            id: filterDashboard.level3[i]?.id,
            type: this.dashboard.title == 'TotalReported' ? 'column' : '',
            data: [
              {
                y: parseInt(filterDashboard.level3[i]?.y),
                drilldown: filterDashboard.level3[i]?.drilldown,
              },
            ],
            status: filterDashboard.level3[i]?.status,
            color: color[i],
          };

          this.dashboard.chartData.part3.push(object);
          object = null;
        }
      }
    }
    // part4
    for (let i = 0; i < filterDashboard.level4?.length; i++) {
      for (let index = 0; index < filterDashboard.level3?.length; index++) {
        if (
          filterDashboard.level4[i]?.id ==
          filterDashboard.level3[index]?.drilldown
        ) {
          object = {
            name: filterDashboard.level4[i]?.name,
            id: filterDashboard.level4[i]?.id,
            type: this.dashboard.title == 'TotalReported' ? 'column' : '',
            data: [
              {
                y: parseInt(filterDashboard.level4[i]?.y),
                drilldown: filterDashboard.level4[i]?.drilldown,
              },
            ],
            status: filterDashboard.level4[i]?.status,
            color: color[i],
          };

          this.dashboard.chartData.part4.push(object);
          object = null;
        }
      }
    }
    this.dashboard = { ...this.dashboard };
    if (this.openFullScreen) {
      this.fullscreenDashboard.emit(this.dashboard);
    }
  }

  filterScoreDashboards(filterDashboard, colors?: any) {
    let object: any;
    let color: Array<string> = colors;
    this.dashboard.chartData.part1 = [];
    this.dashboard.chartData.part2 = [];
    this.dashboard.chartData.part3 = [];
    this.dashboard.chartData.part4 = [];

    // Inspection Score Chart dashboard part1
    color = ['#26326E', '#5D6FCB'];
    this.dashboard.avgScore = filterDashboard.level1[0]?.y || 0;
    if (filterDashboard.level1.length > 0) {
      object = {
        name: 'Inspection Score',
        colorByPoint: true,
        innerSize: '40%',
        data: [
          {
            y: parseInt(filterDashboard.level1[0]?.y),
            name: 'Success',
            color: color[0],
            drilldown: filterDashboard.level1[0]?.drilldown,
          },
          {
            y: filterDashboard.level1[0]?.y
              ? 100 - parseInt(filterDashboard.level1[0]?.y)
              : 100,
            name: 'Fail',
            color: color[1],
            drilldown: '-',
          },
        ],
        status: filterDashboard.level1[0]?.name,
      };
      this.dashboard.chartData.part1.push(object);
      object = null;
    }

    // Inspection Score Chart dashboard part2
    color = ['#26326E'];
    for (let i = 0; i < filterDashboard.level2?.length; i++) {
      object = {
        name: filterDashboard.level2[i]?.name,
        type: 'column',
        id: filterDashboard.level2[i]?.id,
        color: color[0],
        data: [
          {
            y: parseInt(filterDashboard.level2[i]?.y),
            name: filterDashboard.level2[i]?.name,
          },
        ],
        status: filterDashboard.level2[i]?.status,
      };

      this.dashboard.chartData.part2.push(object);
      object = null;
    }
    this.dashboard = { ...this.dashboard };
    if (this.openFullScreen) {
      this.fullscreenDashboard.emit(this.dashboard);
    }
  }

  filterCheckListDashboard(filterDashboard) {
    let object: any;
    let color: Array<string> = [];
    this.dashboard.chartData.part1 = [];
    this.dashboard.chartData.part2 = [];
    this.dashboard.chartData.part3 = [];
    this.dashboard.chartData.part4 = [];

    // checklist Adherence Chart dashboard part1
    this.dashboard.completed =
      filterDashboard.level1.find((item) => item.name == 'Completed')?.y || 0;
    color = ['#640F24', '#D44343'];
    object = {
      name: 'Checklist Adherence',
      colorByPoint: true,
      innerSize: '40%',
      data: [
        {
          y: Number(filterDashboard.level1[0]?.y),
          name: filterDashboard.level1[0]?.name || 'Audit',
          color: filterDashboard.level1.find((item) => item.name == 'Completed')
            ?.name
            ? color[0]
            : color[1],
          drilldown: filterDashboard.level1[0]?.drilldown
            ? filterDashboard.level1[0]?.drilldown
            : '-',
        },
        {
          y: Number(filterDashboard.level1[1]?.y),
          name: filterDashboard.level1[1]?.name || 'Audit',
          color: filterDashboard.level1.find(
            (item) => item.name == 'Uncompleted'
          )?.name
            ? color[1]
            : color[0],
          drilldown: filterDashboard.level1[1]?.drilldown
            ? filterDashboard.level1[1]?.drilldown
            : '-',
        },
      ],
      status: filterDashboard.level1[0]?.name,
    };
    this.dashboard.chartData.part1.push(object);
    object = null;

    // checklist Adherence Chart dashboard part2
    color = ['#640F24'];
    for (let i = 0; i < filterDashboard.level2?.length; i++) {
      for (let index = 0; index < filterDashboard.level1?.length; index++) {
        if (
          filterDashboard.level2[i]?.id ==
          filterDashboard.level1[index]?.drilldown
        ) {
          object = {
            name: filterDashboard.level2[i]?.name,
            id: filterDashboard.level2[i]?.id,
            type: 'column',
            color: color[0],
            data: [
              {
                y: Number(filterDashboard.level2[i]?.y),
                name: filterDashboard.level2[i]?.name,
                drilldown: filterDashboard.level2[i]?.drilldown,
              },
            ],
            status: filterDashboard.level2[i]?.status,
          };

          this.dashboard.chartData.part2.push(object);
          object = null;
        }
      }
    }

    // checklist Adherence Chart dashboard part3
    color = ['#640F24'];
    for (let i = 0; i < filterDashboard.level3?.length; i++) {
      for (let index = 0; index < filterDashboard.level2?.length; index++) {
        if (
          filterDashboard.level3[i]?.id ==
          filterDashboard.level2[index]?.drilldown
        ) {
          object = {
            name: filterDashboard.level3[i]?.name,
            id: filterDashboard.level3[i]?.id,
            type: 'column',
            color: color[0],
            data: [
              {
                y: Number(filterDashboard.level3[i]?.y),
                name: filterDashboard.level3[i]?.name,
              },
            ],
            status: filterDashboard.level3[i]?.status,
          };

          this.dashboard.chartData.part3.push(object);
          object = null;
        }
      }
    }

    this.dashboard = { ...this.dashboard };

    if (this.openFullScreen) {
      this.fullscreenDashboard.emit(this.dashboard);
    }
  }

  filterWorkPermitDashboards(filterDashboard) {
    let object: any;
    let color: Array<string> = [];
    this.dashboard.chartData.part1 = [];
    this.dashboard.chartData.part2 = [];
    this.dashboard.chartData.part3 = [];
    this.dashboard.chartData.part4 = [];
    this.dashboard.total = null;

    this.dashboard.total = filterDashboard.level1
      .map((item) => item.y)
      .reduce((prev, next) => parseInt(prev) + parseInt(next), 0);

    // part1
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24', '#00875A'];
    for (let i = 0; i < filterDashboard.level1?.length; i++) {
      object = {
        name: filterDashboard.level1[i]?.name,
        type: 'bar',
        data: [
          {
            name: filterDashboard.level1[i]?.name,
            y: parseInt(filterDashboard.level1[i]?.y),
            drilldown: filterDashboard.level1[i]?.drilldown,
          },
        ],
        status: filterDashboard.level1[i]?.name,
        color: color[0],
      };

      if (filterDashboard.level1[i]?.name == 'NEW') {
        object.color = color[0];
      } else if (filterDashboard.level1[i]?.name == 'INPROGRESS') {
        object.color = color[1];
      } else if (filterDashboard.level1[i]?.name == 'CLOSED') {
        object.color = color[2];
      } else if (filterDashboard.level1[i]?.name == 'REJECTED') {
        object.color = color[3];
      } else if (filterDashboard.level1[i]?.name == 'APPROVED') {
        object.color = color[4];
      }

      this.dashboard.chartData.part1.push(object);
      object = null;
    }

    // part2
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24'];
    for (let i = 0; i < filterDashboard.level2?.length; i++) {
      for (let index = 0; index < filterDashboard.level1?.length; index++) {
        if (
          filterDashboard.level2[i]?.id ==
          filterDashboard.level1[index]?.drilldown
        ) {
          object = {
            name: filterDashboard.level2[i]?.name,
            type: 'column',
            id: filterDashboard.level2[i]?.id,
            color: color[0],
            data: [
              {
                name: filterDashboard.level2[i]?.name,
                y: parseInt(filterDashboard.level2[i]?.y),
                drilldown: filterDashboard.level2[i]?.drilldown,
              },
            ],
            status: filterDashboard.level2[i]?.status,
          };

          if (filterDashboard.level2[i]?.id == 'NEW') {
            object.color = '#26326E';
          } else if (filterDashboard.level2[i]?.id == 'INPROGRESS') {
            object.color = '#5D6FCB';
          } else if (filterDashboard.level2[i]?.id == 'CLOSED') {
            object.color = '#96A3E6';
          } else if (filterDashboard.level2[i]?.id == 'REJECTED') {
            object.color = '#640F24';
          } else if (filterDashboard.level2[i]?.id == 'APPROVED') {
            object.color = '#640F24';
          }

          this.dashboard.chartData.part2.push(object);
          object = null;
        }
      }
    }

    // part3
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24'];
    for (let i = 0; i < filterDashboard.level3?.length; i++) {
      for (let index = 0; index < filterDashboard.level2?.length; index++) {
        if (
          filterDashboard.level3[i]?.id ==
          filterDashboard.level2[index]?.drilldown
        ) {
          object = {
            name: filterDashboard.level3[i]?.name,
            type: 'column',
            id: filterDashboard.level3[i]?.id,
            color: color[0],
            data: [
              {
                name: filterDashboard.level3[i]?.name,
                y: parseInt(filterDashboard.level3[i]?.y),
              },
            ],
            status: filterDashboard.level3[i]?.status,
          };

          if (filterDashboard.level3[i]?.status == 'NEW') {
            object.color = color[0];
          } else if (filterDashboard.level3[i]?.status == 'INPROGRESS') {
            object.color = color[1];
          } else if (filterDashboard.level3[i]?.status == 'CLOSED') {
            object.color = color[2];
          } else if (filterDashboard.level3[i]?.status == 'REJECTED') {
            object.color = color[3];
          } else if (filterDashboard.level3[i]?.status == 'APPROVED') {
            object.color = color[3];
          }

          this.dashboard.chartData.part3.push(object);
          object = null;
        }
      }
    }

    this.dashboard = { ...this.dashboard };
    if (this.openFullScreen) {
      this.fullscreenDashboard.emit(this.dashboard);
    }
  }

  filterActionPlanDashboard(filterDashboard) {
    let object: any;
    let color: Array<string> = [];
    this.dashboard.chartData.part1 = [];
    this.dashboard.chartData.part2 = [];
    this.dashboard.chartData.part3 = [];
    this.dashboard.chartData.part4 = [];
    this.dashboard.total = null;

    this.dashboard.total = filterDashboard.level1
      .map((item) => item.y)
      ?.reduce((prev, next) => parseInt(prev) + parseInt(next), 0);

    // action plan completion dashboard part1
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24'];
    for (let i = 0; i < filterDashboard.level1?.length; i++) {
      object = {
        name: filterDashboard.level1[i]?.name,
        type: 'bar',
        data: [
          {
            name: filterDashboard.level1[i]?.name,
            y: parseInt(filterDashboard.level1[i]?.y),
            drilldown: filterDashboard.level1[i]?.drilldown,
          },
        ],
        status: filterDashboard.level1[i]?.name,
        color: color[0],
      };

      if (filterDashboard.level1[i]?.name == 'NEW') {
        object.color = color[0];
      } else if (filterDashboard.level1[i]?.name == 'INPROGRESS') {
        object.color = color[1];
      } else if (filterDashboard.level1[i]?.name == 'CLOSE') {
        object.color = color[2];
      } else if (filterDashboard.level1[i]?.name == 'MISSED') {
        object.color = color[3];
      }

      this.dashboard.chartData.part1.push(object);
      object = null;
    }

    // action plan completion dashboard part2
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24'];
    for (let i = 0; i < filterDashboard.level2?.length; i++) {
      for (let index = 0; index < filterDashboard.level1?.length; index++) {
        if (
          filterDashboard.level2[i]?.id ==
          filterDashboard.level1[index]?.drilldown
        ) {
          object = {
            name: filterDashboard.level2[i]?.name,
            type: 'column',
            id: filterDashboard.level2[i]?.id,
            color: color[0],
            data: [
              {
                name: filterDashboard.level2[i]?.name,
                y: parseInt(filterDashboard.level2[i]?.y),
                drilldown: filterDashboard.level2[i]?.drilldown,
              },
            ],
            status: filterDashboard.level2[i]?.status,
          };

          if (filterDashboard.level2[i]?.id == 'NEW') {
            object.color = '#26326E';
          } else if (filterDashboard.level2[i]?.id == 'INPROGRESS') {
            object.color = '#5D6FCB';
          } else if (filterDashboard.level2[i]?.id == 'CLOSED') {
            object.color = '#96A3E6';
          } else if (filterDashboard.level2[i]?.id == 'MISSED') {
            object.color = '#640F24';
          }

          this.dashboard.chartData.part2.push(object);
          object = null;
        }
      }
    }

    // action plan completion dashboard part3
    color = ['#26326E', '#5D6FCB', '#96A3E6', '#640F24'];
    for (let i = 0; i < filterDashboard.level3?.length; i++) {
      for (let index = 0; index < filterDashboard.level2?.length; index++) {
        if (
          filterDashboard.level3[i]?.id ==
          filterDashboard.level2[index]?.drilldown
        ) {
          object = {
            name: filterDashboard.level3[i]?.name,
            type: 'column',
            id: filterDashboard.level3[i]?.id,
            color: color[0],
            data: [
              {
                name: filterDashboard.level3[i]?.name,
                y: parseInt(filterDashboard.level3[i]?.y),
              },
            ],
            status: filterDashboard.level3[i]?.status,
          };

          if (String(filterDashboard.level3[i]?.id).includes('NEW')) {
            object.color = color[0];
          } else if (
            String(filterDashboard.level3[i]?.id).includes('INPROGRESS')
          ) {
            object.color = color[1];
          } else if (String(filterDashboard.level3[i]?.id).includes('CLOSED')) {
            object.color = color[2];
          } else if (String(filterDashboard.level3[i]?.id).includes('MISSED')) {
            object.color = color[3];
          }

          this.dashboard.chartData.part3?.push(object);
          object = null;
        }
      }
    }
    this.dashboard = { ...this.dashboard };
    if (this.openFullScreen) {
      this.fullscreenDashboard.emit(this.dashboard);
    }
  }
  filterReportingDashboard() {
    const data = {
      fromDate: moment(this.searchForm.value.start + ' UTC').toISOString(),
      toDate: moment(this.searchForm.value.end + ' UTC').toISOString(),
      companyId: localStorage.getItem('company_id'),
      chartName: this.dashboard.title,
    };

    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.reportingDashboardService.filterReportingDashboard(data).subscribe({
      next: (filteredDashboardData) => {
        if (this.dashboard.title === 'ReportedHazard') {
          this.dashboard.totalReported =
            filteredDashboardData.totalHazardReport;
          this.dashboard.targetData.targetValue =
            filteredDashboardData.targetHazardReport;
          this.dashboard.textColor =
            filteredDashboardData.hazardReportUptoAtLeast == 'Upto' &&
            filteredDashboardData.totalHazardReport >
              filteredDashboardData.targetHazardReport
              ? '#D52A46'
              : filteredDashboardData.hazardReportUptoAtLeast == 'At least' &&
                filteredDashboardData.totalHazardReport >
                  filteredDashboardData.targetHazardReport
              ? '#35D310'
              : '#000';
          const colors = ['#5D6FCB', '#8B96D3'];
          this.filterReportingDashboards(
            filteredDashboardData.hazardReportChart,
            colors
          );
        } else if (this.dashboard.title === 'ReportedNearMiss') {
          this.dashboard.totalReported =
            filteredDashboardData.totalNearMissReport;
          this.dashboard.targetData.targetValue =
            filteredDashboardData.targetNearMissReport;
          this.dashboard.textColor =
            filteredDashboardData.nearMissReportUptoAtLeast == 'Upto' &&
            filteredDashboardData.totalNearMissReport >
              filteredDashboardData.targetNearMissReport
              ? '#D52A46'
              : filteredDashboardData.nearMissReportUptoAtLeast == 'At least' &&
                filteredDashboardData.totalNearMissReport >
                  filteredDashboardData.targetNearMissReport
              ? '#35D310'
              : '#000';
          const colors = ['#26326E', '#3F51AF', '#9FADCC'];
          this.filterReportingDashboards(
            filteredDashboardData.neaMissReportChart,
            colors
          );
        } else if (this.dashboard.title === 'ReportedIncident') {
          this.dashboard.totalReported =
            filteredDashboardData.totalIncidentReport;
          this.dashboard.targetData.targetValue =
            filteredDashboardData.targetIncidentReport;
          this.dashboard.textColor =
            filteredDashboardData.incidentReportUptoAtLeast == 'Upto' &&
            filteredDashboardData.totalIncidentReport >
              filteredDashboardData.targetIncidentReport
              ? '#D52A46'
              : filteredDashboardData.incidentReportUptoAtLeast == 'At least' &&
                filteredDashboardData.totalIncidentReport >
                  filteredDashboardData.targetIncidentReport
              ? '#35D310'
              : '#000';
          const colors = ['#640F24', '#D44343', '#E27979'];
          this.filterReportingDashboards(
            filteredDashboardData.incidentReportChart,
            colors
          );
        } else if (this.dashboard.title === 'ReportedIncidentLoss') {
          this.dashboard.totalReported =
            filteredDashboardData.totalIncidentLossReport;
          this.dashboard.targetData.targetValue =
            filteredDashboardData.targetIncidentLossReport;
          this.dashboard.textColor =
            filteredDashboardData.incidentLossReportUptoAtLeast == 'Upto' &&
            filteredDashboardData.totalIncidentLossReport >
              filteredDashboardData.targetIncidentLossReport
              ? '#D52A46'
              : filteredDashboardData.incidentLossReportUptoAtLeast ==
                  'At least' &&
                filteredDashboardData.totalIncidentLossReport >
                  filteredDashboardData.targetIncidentLossReport
              ? '#35D310'
              : '#000';
          const colors = ['#640F24', '#D44343', '#E27979'];
          this.filterReportingDashboards(
            filteredDashboardData.incidentLossReportChart,
            colors
          );
        } else if (this.dashboard.title === 'TotalReported') {
          this.dashboard.totalReported = filteredDashboardData.totalReports;
          this.dashboard.targetData.targetValue =
            filteredDashboardData.totalReportTarget;
          this.dashboard.textColor =
            filteredDashboardData.totalReportUptoAtLeast == 'Upto' &&
            filteredDashboardData.totalReports >
              filteredDashboardData.totalReportTarget
              ? '#D52A46'
              : filteredDashboardData.totalReportUptoAtLeast == 'At least' &&
                filteredDashboardData.totalReports >
                  filteredDashboardData.totalReportTarget
              ? '#35D310'
              : '#000';
          const colors = ['#5D6FCB', '#640F24', '#26326E'];
          this.filterReportingDashboards(
            filteredDashboardData.totalReportedChart,
            colors
          );
        } else if (this.dashboard.title === 'CheckList') {
          this.dashboard.targetData.targetValue =
            filteredDashboardData.checkListIdherenceTarget;
          this.dashboard.textColor =
            filteredDashboardData.checkListIdherenceUptoAtLeast == 'Upto' &&
            filteredDashboardData.checkListAdherenceChart.level1[0]?.y >
              filteredDashboardData.checkListIdherenceTarget
              ? '#D52A46'
              : filteredDashboardData.checkListIdherenceUptoAtLeast ==
                  'At least' &&
                filteredDashboardData.checkListAdherenceChart.level1[0]?.y >
                  filteredDashboardData.checkListIdherenceTarget
              ? '#35D310'
              : '#000';
          this.filterCheckListDashboard(
            filteredDashboardData.checkListAdherenceChart
          );
        } else if (this.dashboard.title === 'InspectionScore') {
          const colors = ['#26326E', '#5D6FCB'];
          this.dashboard.targetData.targetValue =
            filteredDashboardData.inspectionScoreTarget;
          this.dashboard.textColor =
            filteredDashboardData.inspectionScoreUptoAtLeast == 'Upto' &&
            filteredDashboardData.inspectionScoreChart.level1[0]?.y >
              filteredDashboardData.inspectionScoreTarget
              ? '#D52A46'
              : filteredDashboardData.inspectionScoreUptoAtLeast ==
                  'At least' &&
                filteredDashboardData.inspectionScoreChart.level1[0]?.y >
                  filteredDashboardData.inspectionScoreTarget
              ? '#35D310'
              : '#000';
          this.filterScoreDashboards(
            filteredDashboardData.inspectionScoreChart,
            colors
          );
        } else if (this.dashboard.title === 'WorkPermit') {
          this.dashboard.targetData.targetValue =
            filteredDashboardData.workPermitTarget;
          this.dashboard.textColor =
            filteredDashboardData.workPermitUptoAtLeast == 'Upto' &&
            filteredDashboardData.workPermitChart.level1
              .map((item) => item.y)
              .reduce((prev, next) => parseInt(prev) + parseInt(next), 0) >
              filteredDashboardData.workPermitTarget
              ? '#D52A46'
              : filteredDashboardData.workPermitUptoAtLeast == 'At least' &&
                filteredDashboardData.workPermitChart.level1
                  .map((item) => item.y)
                  .reduce((prev, next) => parseInt(prev) + parseInt(next), 0) >
                  filteredDashboardData.workPermitTarget
              ? '#35D310'
              : '#000';
          this.filterWorkPermitDashboards(
            filteredDashboardData.workPermitChart
          );
        } else if (this.dashboard.title === 'ActionPlan') {
          this.dashboard.targetData.targetValue =
            filteredDashboardData.actionPlanTarget;
          this.dashboard.textColor =
            filteredDashboardData.actionPlanUptoAtLeast == 'Upto' &&
            filteredDashboardData.actionPlanChart.level1
              .map((item) => item.y)
              .reduce((prev, next) => parseInt(prev) + parseInt(next), 0) >
              filteredDashboardData.actionPlanTarget
              ? '#D52A46'
              : filteredDashboardData.actionPlanUptoAtLeast == 'At least' &&
                filteredDashboardData.actionPlanChart.level1
                  .map((item) => item.y)
                  .reduce((prev, next) => parseInt(prev) + parseInt(next), 0) >
                  filteredDashboardData.actionPlanTarget
              ? '#35D310'
              : '#000';
          this.filterActionPlanDashboard(filteredDashboardData.actionPlanChart);
        }
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  filterDigitalDashboards() {
    const data = {
      filteredFrom: moment(this.searchForm.value.start + ' UTC').toISOString(),
      filteredTo: moment(this.searchForm.value.end + ' UTC').toISOString(),
      companyCode: localStorage.getItem('company_id'),
    };

    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.iotDigitalDashboardService.getIotDigitalDashboardData(data).subscribe({
      next: (digitalData) => {
        this.dashboard.data =
          digitalData.part1.find(
            (item) => item.machineName === this.dashboard.title
          )?.machineParameterReadings || [];
        this.dashboard = { ...this.dashboard };
        if (this.openFullScreen) {
          this.fullscreenDashboard.emit(this.dashboard);
        }
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  generateAnalogDashboard(
    analogData,
    machineName: string,
    parameterName: string,
    filterName: string
  ) {
    if (analogData.part1.length > 0) {
      const machine = analogData.part1.find(
        (item) => item.machineName == machineName
      );
      const parameterReadings =
        machine?.machineReadings.filter(
          (item) => item.iotParameterName == parameterName
        ) || [];
      this.dashboard.chartData.part1 = [];

      if (parameterReadings.length > 0) {
        const object = {
          name:
            parameterReadings.find(
              (item) => item.iotParameterName == parameterName
            )?.value || '',
          marker: {
            symbol: 'circle',
          },
          data: [],
        };
        const xValues = parameterReadings.map((item) => {
          if (filterName) {
            const currentDate = new Date();
            const [hours, minutes] = item.filterRange.split(':').map(Number);

            currentDate.setHours(hours);
            currentDate.setMinutes(minutes);

            const timeZoneOffsetMilliseconds =
              currentDate.getTimezoneOffset() * 60 * 1000;

            const localTime = new Date(
              currentDate.getTime() - timeZoneOffsetMilliseconds
            );

            const localTimeFormatted = localTime.toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            });
            return localTimeFormatted;
          } else {
            return item.filterRange;
          }
        });
        this.dashboard.chartData.xValues = xValues;

        for (let i = 0; i < parameterReadings.length; i++) {
          const value =
            Math.round(
              (parseFloat(parameterReadings[i]?.parameterValue) +
                Number.EPSILON) *
                100
            ) / 100;
          object.data.push({
            y: value,
            color:
              value <= parseFloat(parameterReadings[i]?.upperControlLimit) &&
              value >= parseFloat(parameterReadings[i]?.lowerControlLimit)
                ? '#3A9E21'
                : '#DB2A2A',
            lineColor: '#3A9E21',
          });
        }
        this.dashboard.chartData.part1.push(object);
      }
    } else {
      const object = [];
      this.dashboard.chartData.part1 = object;
    }
    this.dashboard = { ...this.dashboard };
    if (this.openFullScreen) {
      this.fullscreenDashboard.emit(this.dashboard);
    }
  }

  filterAnalogDashboards(filterName?: string) {
    const utcDateStart = new Date(this.searchForm.value.start.toUTCString());
    const utcDateEnd = new Date(this.searchForm.value.end.toUTCString());
    const data = {
      filteredFrom: filterName
        ? utcDateStart
        : moment(this.searchForm.value.start + ' UTC').toISOString(),
      filteredTo: filterName
        ? utcDateEnd
        : moment(this.searchForm.value.end + ' UTC').toISOString(),
      companyCode: localStorage.getItem('company_id'),
      filterName: filterName ? filterName : 'PerDay',
    };

    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.iotAnalogDashboardService.getIotAnalogDashboardData(data).subscribe({
      next: (result) => {
        this.generateAnalogDashboard(
          result,
          this.dashboard.machineName,
          this.dashboard.parameterName,
          filterName
        );
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  checkSelectIfOpen(value) {
    this.openCalender = value;
  }

  openFullScreen() {
    const currentFilter = {
      menuVal: this.searchForm.get('menu').value,
      fromDate: this.searchForm.get('start').value,
      toDate: this.searchForm.get('end').value,
      chartName: this.dashboard.title,
    };
    const data = {
      filterData: currentFilter,
      dashboard: this.dashboard,
    };

    this.fullScreenEvent.emit(data);
  }

  chartClicked(event?) {
    this.clickedPoint = event;
    let chartData = this.dashboard.chartData;
    let partArray: Array<any>;
    if (this.chartLevel === 'one') {
      partArray = [];
      if (this.dashboard.title == 'InspectionScore') {
        for (let i = 0; i < this.dashboard.chartData?.part2?.length; i++) {
          partArray.push(this.dashboard.chartData?.part2[i]);
        }
      } else {
        for (let i = 0; i < this.dashboard.chartData?.part2?.length; i++) {
          if (
            this.dashboard.chartData?.part2[i]?.id == this.clickedPoint.point
          ) {
            partArray.push(this.dashboard.chartData?.part2[i]);
          }
        }
      }
      chartData.part2 = partArray;
      this.updatedChartData = chartData;
    } else if (this.chartLevel === 'two') {
      partArray = [];
      for (let i = 0; i < this.dashboard.chartData?.part3?.length; i++) {
        if (this.dashboard.chartData?.part3[i]?.id == this.clickedPoint.point) {
          partArray.push(this.dashboard.chartData?.part3[i]);
        }
      }
      chartData.part3 = partArray;
      this.updatedChartData = chartData;
    } else if (this.chartLevel === 'three') {
      partArray = [];
      for (let i = 0; i < this.dashboard.chartData?.part4?.length; i++) {
        if (this.dashboard.chartData?.part4[i]?.id == this.clickedPoint.point) {
          partArray.push(this.dashboard.chartData?.part4[i]);
        }
      }
      chartData.part4 = partArray;
      this.updatedChartData = chartData;
    }
    this.chartClickedEvent.emit(this.clickedPoint);
  }

  getLevelThreeTitle() {
    let title = '';
    if (this.dashboard.title == 'ReportedHazard') {
      title = 'Per Type of hazard';
    } else if (this.dashboard.title == 'ReportedNearMiss') {
      title = 'Per Type of near miss';
    } else if (this.dashboard.title == 'ReportedIncident') {
      title = 'Per Incident classification wise';
    } else if (
      this.dashboard.title == 'TotalReported' ||
      this.dashboard.title == 'WorkPermit' ||
      this.dashboard.title == 'ActionPlan' ||
      this.dashboard.title == 'CheckList'
    ) {
      title = 'Per Reporters';
    } else if (this.dashboard.title == 'InspectionPlan') {
      title = 'Per Checklist';
    } else if (this.dashboard.title == 'ReportedIncidentLoss') {
      title = 'Per Report';
    } else if (this.dashboard.title == 'InspectionScore') {
      title = 'Per Loss Mode';
    }

    return title;
  }

  getLevelFourTitle() {
    let title = '';
    if (this.dashboard.title == 'ReportedHazard') {
      title = 'Per Root cause';
    } else if (this.dashboard.title == 'ReportedNearMiss') {
      title = 'Per Root Cause';
    } else if (this.dashboard.title == 'ReportedIncident') {
      title = 'Per Root Cause';
    }

    return title;
  }
}
