import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { SafetyAnnouncementType } from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { NotificationService } from 'src/backend/services/notification.service';
import { SafetyAnnouncementService } from 'src/backend/services/safety-announcement/safety-announcement.service';
import {
  AllSafetyAnnouncementReceiver,
  SafetyAnnouncementReceiver,
} from '../../models/safety-announcement-receiver.model';

@Component({
  selector: 'app-view-safety-announcement',
  templateUrl: './view-safety-announcement.component.html',
  styleUrls: ['./view-safety-announcement.component.scss'],
})
export class ViewSafetyAnnouncementComponent implements OnInit {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;

  selectedReceivers: SafetyAnnouncementReceiver[] = [];
  allReceivers: AllSafetyAnnouncementReceiver[] = [];
  tags = [];
  safetyAnnouncementType = SafetyAnnouncementType;
  selectedAnnouncement: any;
  files: any;

  loggedUser: LoggedUser;

  constructor(
    private dialog: MatDialogRef<ViewSafetyAnnouncementComponent>,
    private safetyAnnouncementService: SafetyAnnouncementService,
    private notificationService: NotificationService,
    private baseApi: BaseApi,
    private feedBackService: AsyncFeedbackService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      formMode: string;
      announcementId?: number;
    }
  ) {}

  ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.getAnnouncementById();
  }

  getAnnouncementById() {
    this.sectionStateModal = SectionStateStatus.Loading;
    this.safetyAnnouncementService
      .GetSafetyAnnouncementById(this.data.announcementId)
      .subscribe((response) => {
        this.selectedAnnouncement = response;
        this.files = response?.theattachments;
        this.tags = response.tags ? response.tags.split(',') : [];
        this.sectionStateModal = SectionStateStatus.Ready;
      });
  }
  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase()
    if (extension.includes('xlsx')||extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png'
    }else if(extension.includes('docx')||extension.includes('doc')){
      return '../../../../assets/img/resources/doc.png'
    }else if(extension.includes('pdf')){
      return '../../../../assets/img/resources/pdf.png'
    }else{
      return url
    }
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }
  openBigView(id: number): void {
    const img = this.files.find((x) => x.id === id); 
    const downloadTag = document.createElement('a');
    downloadTag.href = img?.attachmentUrl;
    downloadTag.addEventListener('click', () => {
    downloadTag.download;
    });
    downloadTag.click();
  }


  isImage(url) {
    const imageExtensions = ['jpg', 'jpeg', 'gif', 'png', 'bmp', 'svg'];
    const extension = url.split('.').pop().toLowerCase();
    return imageExtensions.includes(extension);
  }

  getFileName(value: string) {
    return value.substring(value.lastIndexOf('/') + 1);
  }

  closeDialog() {
    this.dialog.close();
  }

  markAsRead() {
    const markAsReadData = {
      userId: this.loggedUser.userId,
      notificationId: this.selectedAnnouncement?.notificationId,
    };
    this.sectionStateModal = SectionStateStatus.Loading;
    this.notificationService.markAsReadNotification(markAsReadData).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            'Marked As Read Successfully!'
          )
        );
        this.sectionStateModal = SectionStateStatus.Ready;
        this.dialog.close({ event: 'markedAsRead' });
      },
      error: ({ error }) => {},
    });
  }
}
