import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { SortDirection } from 'src/@hodhod/common/enum';
import { BaseApi } from 'src/backend/api/base-api';
import { GetAllCamers } from 'src/backend/models/video-analytics/video-analytics.model';

@Injectable({
    providedIn: 'root'
  })
export class VideoAnalyticsService{
   constructor(private http: HttpClient, private baseApi: BaseApi) { }

   getAllCameras(data: any): Observable<DataMapper<GetAllCamers>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.statusUnconfigured) {
      if (params.length > 0) {
        params += '&';
      }
      params += `statusUnconfigured=` + data?.statusUnconfigured;
    }
    if (data?.statusConfigured) {
      if (params.length > 0) {
        params += '&';
      }
      params += `statusConfigured=` + data?.statusConfigured;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.companyCode) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'companyId=' + data?.companyCode;
    }
  return this.http.get<DataMapper<GetAllCamers>>(this.baseApi.encodeURL(Constants.API_VIDEO_ANALYTICS_GET_ALL + '?' + params));
  }

  deActivateCamera(data: any): Observable<any>{
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_VIDEO_ANALYTICS_DEACTIVATE_CAMERA), data);
  }
  activateCamera(data: any): Observable<any>{
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_VIDEO_ANALYTICS_ACTIVATE_CAMERA), data);
  }

  getCmeraById(id: number): Observable<any>{
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_VIDEO_ANALYTICS_BY_ID + '/' + id));
  }

  updateCamera(data: any): Observable<any>{
    return this.http.put<any>(this.baseApi.encodeURL(Constants.API_VIDEO_ANALYTICS_UPDATE_CAMERA), data);
  }
} 