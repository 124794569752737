import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { Confirmation, ConfirmationType } from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { RenewIdCardService } from 'src/backend/services/renew-id-card/renew-id-card.service';


@Component({
  selector: 'app-renew-id-card',
  styleUrls: ['./renew-id-card.component.scss'],
  templateUrl: './renew-id-card.component.html',

})
export class RenewIdCardComponent implements OnInit {


  renewCardForm: FormGroup;
  formMode: 'create' | 'update' = 'create';
  private destroy$ = new Subject();
  public translationsList: any = {};

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';

  constructor(

    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<RenewIdCardComponent>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private renewService: RenewIdCardService,
    private feedBackService: AsyncFeedbackService,
  ) {
    this.translate.get(['Errors', 'Success', 'Role', 'User', 'RenewIdCard']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
      this.translationsList = translations;
    });
  }

  ngOnInit() {
    if (this.defaults) {
      this.formMode = 'update';
    } else {
      this.defaults = {} as any;
    }
    this.renewCardForm = this.fb.group({
      idcardNo: ['', [Validators.required]],
      attachment: [[], Validators.required]
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  checkExtension(e: any){
    let files = this.renewCardForm.get('attachment').value;
    const images = files.filter(x => x.type === 'image/png' || x.type === 'image/jpeg');
    const otherFiles = files.filter(x => x.type != 'image/png' && x.type != 'image/jpeg');
    if(otherFiles.length > 0){
      this.renewCardForm.get('attachment').setValue(images);
      this.feedBackService.showFeedback(
        new FeedbackModel(FeedbackType.Warning, "Only png and jpeg files supported")
      );
    }
  }

  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createUser();
          }
        });
    } else if (this.formMode === 'update') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['Role']['UpdateConfirm']['Title'],
            this.translationsList['Role']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.updateUser();
          }
        });
    }
  }

  createUser() {
    this.loadingLabel = "General.Refreshing";
    this.sectionState = SectionStateStatus.LoadingTransparent;
    const { idcardNo, attachment } = this.renewCardForm.value;

    const formData = new FormData();
    attachment.forEach(element => {
      formData.append('basicDoc', element)
    });
    formData.append('code', idcardNo);
    this.loadingLabel = 'CreatingIdCard';
    this.renewService.postIdCard(formData).subscribe({
      next: (respose) => {
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, respose?.message)
        );
        this.resetForm();
        this.dialogRef.close(formData);
      },
      error: ({ error }) => {
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      }
    })

  }

  resetForm(): void {
    this.renewCardForm.get('idcardNo')?.setValue('');
    this.renewCardForm.get('attachment')?.setValue([]);

  }

  updateUser() {
    const { idcardNo, languageInput } = this.renewCardForm.value;
    const data = {
      id: this.defaults.id,
      idcardNo: idcardNo,
      createdAt: this.defaults.createdAt,
      updatedAt: this.defaults.updatedAt,
      company: this.defaults.company,
      numOfUsers: this.defaults.numOfUsers,
      isActive: this.defaults.isActive,
      isDefault: this.defaults.isDefault
    };
    this.sectionState = SectionStateStatus.Loading;
    setTimeout(() => {
      this.dialogRef.close(data);
      this.sectionState = SectionStateStatus.Ready;
    }, 6000);
  }

  isCreateMode() {
    return this.formMode === 'create';
  }

  isUpdateMode() {
    return this.formMode === 'update';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}
