<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.ReviewInspection' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="loadingLabel | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <div class="flex justify-end mb-2" *ngIf="data?.status === 4">
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'Approve'"
          (onClick)="approveUnSafety()"
        ></app-basic-button>
      </div>
      <app-dismiss-alert
        *ngIf="data?.status === 4"
        [message]="'InspectionManagement.UnsafeMessage' | translate"
      ></app-dismiss-alert>
      <app-dismiss-alert
        *ngIf="data?.status === 3"
        [message]="'InspectionManagement.MissedMessage' | translate"
      ></app-dismiss-alert>
      <div class="flex gap-2">
        <div class="wrapper_1">
          <app-card title="{{ data?.equipmentName }}">
            <div body>
              <div class="flex column">
                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <h4 class="m-0 font-bold">{{ data?.checkList.title }}</h4>
                  </div>
                </div>
                <div class="grid grid-cols-2 items-start gap-3">
                  <div class="item flex column flex-2">
                    <div *ngFor="let s of checklist">
                      <div *ngFor="let q of s.questions">
                        <h6 class="font-bold text-gray-400">
                          Question: {{ q.questionText }}
                        </h6>
                        <p class="mt-2">
                          Answer:
                          {{
                            q?.isNotApplicable
                              ? "Not Applicable"
                              : q?.userAnswer
                          }}
                        </p>
                        <p class="mt-2" *ngIf="q?.comment">
                          Comment:
                          {{ q?.comment }}
                        </p>
                        <div class="w-fit">
                          <!-- <ngx-avatars
                          *ngIf="q?.media"
                          class="cursor-pointer"
                          size="60"
                          [src]="getAttachmentImage(q.media)"
                          [name]="getAttachementTypeName(q.media)"
                          [textSizeRatio]="2"
                          [round]="false"
                          [initialsSize]="2"
                          [matTooltip]="q?.mediaName"
                          [cornerRadius]="10"
                          [borderColor]="'#d52a46'"
                          (clickOnAvatar)="openBigView(q.media)"
                        >
                        </ngx-avatars> -->
                        </div>

                        <hr class="my-2" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </app-card>
          <div class="mt-5">
            <app-action
              [sourceId]="taskId"
              [sourceType]="ReportingType"
              [isCreate]="data?.status !== 'REJECTED'"
            >
            </app-action>
          </div>
        </div>

        <div class="wrapper_2">
          <div class="card">
            <div class="flex justify-between flex-wrap">
              <div class="flex-1">
                <div class="text-center">
                  <ngx-gauge
                    [foregroundColor]="getGuageColors(gaugeValue)"
                    [type]="'full'"
                    [value]="gaugeValue"
                    [label]=""
                    [append]="'%'"
                    [thick]="20"
                  ></ngx-gauge>
                  <h4 class="font-bold text-center">
                    {{ "TaskTracker.View.ChecklistResult" | translate }}
                  </h4>
                </div>

                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <h4 class="m-0 font-bold">
                      {{ data?.equipmentName }}, {{ data?.equipmentCode }}
                    </h4>
                  </div>
                </div>

                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p
                      class="m-0 body-1"
                      [style.color]="
                        data?.scoreStatus == 'FAIL' ? '#D7354F' : '#68BA66'
                      "
                    >
                      {{ data?.scoreStatus }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "TaskTracker.View.ScoreStatus" | translate }}
                    </p>
                  </div>
                </div>

                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ data?.inspectedBy }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "InspectionManagement.InspectedBy" | translate }}
                    </p>
                  </div>
                </div>

                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ data?.executedOn | date : "medium" }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "InspectionManagement.ExecutedOn" | translate }}
                    </p>
                  </div>
                </div>

                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">
                      {{ data?.createdOn | date : "medium" }}
                    </p>
                    <p class="m-0 caption text-hint">
                      {{ "InspectionManagement.StartDate" | translate }}
                    </p>
                  </div>
                </div>

                <div class="py-3 flex items-center">
                  <div
                    @scaleIn
                    class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
                  >
                    <mat-icon
                      class="icon-sm"
                      svgIcon="mat:keyboard_double_arrow_right"
                    ></mat-icon>
                  </div>

                  <div @fadeInRight>
                    <p class="m-0 body-1">{{ data?.checkList.title }}</p>
                    <p class="m-0 caption text-hint">
                      {{ "InspectionManagement.ChecklistName" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <mat-expansion-panel
              #expansionPanel
              class="mat-collabse audit-collabse"
            >
              <mat-expansion-panel-header class="mat-header">
                <mat-panel-title class="justify-between">
                  <h1 class="task-title">
                    {{ "InspectionManagement.HistoryLogs" | translate }}
                  </h1>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description class="flex-col text-[#000] m-0">
                <div *ngFor="let item of data?.inspectionHistory">
                  <p>
                    {{ "InspectionManagement.InspectionDate" | translate }}:
                    {{ item.inspectionDate | date : "medium" }}
                  </p>
                  <p>
                    {{ "InspectionManagement.Status" | translate }}:
                    {{ item.status }}
                  </p>
                  <p>
                    {{ "InspectionManagement.InspectionResult" | translate }}:
                    {{ item.inspectionResult }}
                  </p>
                  <hr />
                </div>
                <div
                  class="show-more"
                  *ngIf="data?.inspectionHistory.length > 0"
                >
                  <span (click)="goToHistoryLog()">
                    {{ "InspectionManagement.ShowMore" | translate }}...
                  </span>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>

            <mat-expansion-panel
              #expansionPanel
              class="mat-collabse audit-collabse"
            >
              <mat-expansion-panel-header class="mat-header">
                <mat-panel-title class="justify-between">
                  <h1 class="task-title">
                    {{ "InspectionManagement.DownTimeHistory" | translate }}
                  </h1>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description class="flex justify-center m-0">
                <div *ngFor="let item of data?.downTimeHistory">
                  <p>
                    {{ "InspectionManagement.Duration" | translate }}:
                    {{ item.duration }}
                  </p>
                  <p>
                    {{ "InspectionManagement.ReactivatedBy" | translate }}:
                    {{ item.ReActivatedBy }}
                  </p>
                  <p>
                    {{ "InspectionManagement.ReactivatedOn" | translate }}:
                    {{ item.ReActivatedOn }}
                  </p>
                  <hr />
                </div>
                <div class="show-more" *ngIf="data?.downTimeHistory.length > 0">
                  <span (click)="goToHistoryLog()">
                    {{ "InspectionManagement.ShowMore" | translate }}...
                  </span>
                </div>
              </mat-panel-description>
            </mat-expansion-panel>
          </div>
        </div>
      </div>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>
