import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToArray'
})
export class StringToArrayPipe implements PipeTransform {

  transform(value: any): any {
    if (value) {
      return value.split(',');
    }
    return [];
  }

}
