import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import {
  ChecklistSectionType,
  ChecklistType,
  HistoryTypes,
  OperatorType,
} from 'src/@hodhod/common/enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';

@Component({
  selector: 'app-edit-checklist-section',
  templateUrl: './edit-checklist-section.component.html',
  styleUrls: ['./edit-checklist-section.component.scss'],
  animations: [scaleFadeIn400ms],
})
export class EditChecklistSectionComponent implements OnInit, OnChanges {
  @Input() checkList: any;
  @Input() editMode: string = 'view';
  public checkListData: any[] = [];
  checkListForm: FormGroup;
  sectionListFormArray: FormArray;
  questionTypes = ChecklistSectionType;
  OperatorType = OperatorType;
  ChecklistType = ChecklistType;
  machines: any[] = [];
  numOfSections: number;
  machineId: number;
  HistoryType = HistoryTypes.CHECKLIST;
  ranges = [
    { id: 0, value: '0' },
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
  ];
  private destroy$ = new Subject();
  public translationsList: any = {};
  constructor(
    private fb: FormBuilder,
    private checklistService: ChecklistSectionService,
    private loadingService: LoadingService,
    private translate: TranslateService,
  ) {
    this.translate
      .get(['Errors', 'Success', 'User', 'General'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.checkListForm = this.fb.group({
      checklistName: ['', [Validators.required, noWhitespaceValidator]],
      checklisttype: ['', [Validators.required]],
      minimumScore: ['', []],
      sections: this.fb.array([this.createSectionForm()]),
    });
    this.checkListForm
    .get('checklisttype')
    .valueChanges.subscribe((checklisttype) => {
      const minimumScoreControl = this.checkListForm.get('minimumScore');
      if (checklisttype === 'Audit') {
        minimumScoreControl.setValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(100),
        ]);
      }
      minimumScoreControl.updateValueAndValidity();
    });
    this.sectionListFormArray = this.checkListForm.get('sections') as FormArray;  
    this.getMachines();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checkList']) {
      this.checkListData = this.checkList?.section;
      if (this.checkListForm && this.checkListData) {
        this.checkListForm
          .get('checklistName')
          ?.setValue(this.checkList?.title);
        this.checkListForm.get('checklisttype')?.setValue(this.checkList?.type);
        this.checkListForm.get('minimumScore')?.setValue(this.checkList?.minimumScore);
        this.buildForm();
        this.numOfSections = this.sectionListFormArray.value.length;
      }
    }
  }

  get repeatSectionFormGroup(): any {
    return this.checkListForm.get('sections') as FormArray;
  }
  repeatQuestionsFormGroup(i: number): any {
    return this.checkListForm
      .get('sections')
      ['controls'][i].get('questions') as FormArray;
  }
  createSectionForm(): FormGroup {
    return this.fb.group({
      sectionId: [0],
      title: ['', Validators.required],
      questionType: [''],
      questions: this.fb.array([this.createQuestionForm()]),
    });
  }
  createQuestionForm(): FormGroup {
    return this.fb.group({
      questionId: [0],
      questionText: ['', Validators.required],
      answer: [''],
      target: [0],
      parameters: [[]],
    });
  }

  getMachines() {
    this.checklistService.getMachines().subscribe((res) => {
      this.machines = res;
    });
  }
  onMachineChange(event: any, i: number, j: number) {
    this.machineId = event?.value;
    this.getParameters(this.machineId, i, j);
  }
  getParameters(id: number, i: number, j: number) {
    this.checklistService.getParameters(id).subscribe((res) => {
      this.checkListForm
        .get('sections')
        ['controls'][i].get('questions')
        ['controls'][j].get('parameters')
        ?.setValue(res);
    });
  }

  onAddSection(): void {
    this.sectionListFormArray.push(this.createSectionForm());
  }

  addQuestion(index: number): void {
    this.repeatQuestionsFormGroup(index).push(this.createQuestionForm());
  }
  onSeletSectionType(questionType: string, index: number): void {
    this.repeatQuestionsFormGroup(index).clear();
    this.addQuestion(index);
  }
  onRemoveSection(index: number): void {
    if (this.sectionListFormArray.value.length > 1) {
      this.sectionListFormArray.removeAt(index);
      this.numOfSections -= 1;
    }
  }
  removeQuestion(i: number, j: number): void {
    if (this.repeatQuestionsFormGroup(i).value.length > 1) {
      this.repeatQuestionsFormGroup(i).removeAt(j);
    }
  }

  editCheckList(): void {
    // this.confirmationService
    //   .confirm(
    //     new Confirmation(
    //       ConfirmationType.NonDestructiveAction,
    //       this.translationsList['User']['UpdateConfirm']['Title'],
    //       this.translationsList['User']['UpdateConfirm']['Message']
    //     )
    //   )
    //   .then((value) => {
    //     if (value === true) {
    //       this.loadingService.startLoading(true, '');
    //       const { checklistName, checklisttype, minimumScore ,sections } =
    //         this.checkListForm.value;

    //       const data = {
    //         checkListId: this.checkList?.checkListId,
    //         checkListName: checklistName,
    //         checkListType: checklisttype,
    //         minimumScore,
    //         section: sections?.map((section) => {
    //           return {
    //             sectionId: section?.sectionId,
    //             sectionTitle: section?.title,
    //             sectionType: section?.questionType,
    //             minimumScore:section?.minimumScore,
    //             questions: section?.questions?.map((question) => {
    //               return {
    //                 questionId: question?.questionId,
    //                 questionTitle: question?.questionText,
    //                 answer:
    //                   section?.questionType ===
    //                   ChecklistSectionType.ValueVsTarget
    //                     ? question?.answer + ' ' + question?.target
    //                     : question?.answer,
    //               };
    //             }),
    //           };
    //         }),
    //       };
    //       this.checklistService.editCheckListSection(data).subscribe({
    //         next: () => {
    //           this.loadingService.stopLoading();
    //           this.feedBackService.showFeedback(
    //             new FeedbackModel(
    //               FeedbackType.Success,
    //               this.translationsList['General']['Updated']
    //             )
    //           );
    //           this.router.navigate([
    //             '/' +
    //               SharedConstants.SAFETY +
    //               '/' +
    //               SharedConstants.CHECKLIST_SECTION,
    //           ]);
    //         },
    //         error: ({ error }) => {
    //           this.feedBackService.showFeedback(
    //             new FeedbackModel(FeedbackType.Failure, error?.message)
    //           );
    //           this.loadingService.stopLoading();
    //         },
    //       });
    //     }
    //   });
  }
  // updateCheckList(): void {
  //   this.confirmationService
  //     .confirm(
  //       new Confirmation(
  //         ConfirmationType.NonDestructiveAction,
  //         this.translationsList['User']['UpdateConfirm']['Title'],
  //         this.translationsList['User']['UpdateConfirm']['Message']
  //       )
  //     )
  //     .then((value) => {
  //       if (value === true) {
  //         this.loadingService.startLoading(true, '');
  //         const { checklistName, checklisttype, minimumScore  } =
  //           this.checkListForm.value;

  //         const data = {
  //           id: this.checkList?.checkListId,
  //           title: checklistName,
  //           type: checklisttype,
  //           minimumScore,
  //         };
  //         this.checklistService.updateChecklist(data).subscribe({
  //           next: () => {
  //             this.loadingService.stopLoading();
  //             this.feedBackService.showFeedback(
  //               new FeedbackModel(
  //                 FeedbackType.Success,
  //                 this.translationsList['General']['Updated']
  //               )
  //             );
  //             this.router.navigate([
  //               '/' +
  //                 SharedConstants.SAFETY +
  //                 '/' +
  //                 SharedConstants.CHECKLIST_SECTION,
  //             ]);
  //           },
  //           error: ({ error }) => {
  //             this.feedBackService.showFeedback(
  //               new FeedbackModel(FeedbackType.Failure, error?.message)
  //             );
  //             this.loadingService.stopLoading();
  //           },
  //         });
  //       }
  //     });
  // }

  buildForm(): void {
    this.loadingService.startLoading(true, '');
    const sections = this.checkListData;
    this.addSectionsFormGroup(sections);
    this.checkListForm
      .get('sections')
      ?.setValue(this.formatCheckListData(this.checkListData));
    if (this.editMode !== 'edit') {
      this.checkListForm.disable();
    }
    this.loadingService.stopLoading();
  }
  addSectionsFormGroup(sections: any[]): void {
    this.sectionListFormArray.clear();
    for (let index = 0; index < sections.length; index++) {
      this.sectionListFormArray.push(this.createSectionForm());
      this.addQuestionsFormGroup(sections[index]?.questions?.length, index);
    }
  }

  addQuestionsFormGroup(numOfGroups: number, i: number): void {
    this.repeatQuestionsFormGroup(i).clear();
    for (let index = 0; index < numOfGroups; index++) {
      this.repeatQuestionsFormGroup(i).push(this.createQuestionForm());
    }
  }

  formatCheckListData(data: any[]): any[] {
    const formatedData = data.map((section, i) => {
      return {
        sectionId: section?.sectionId,
        title: section?.sectionTitle,
        questionType: section?.sectionType,
        questions: section?.questions?.map((question, j) => {
          if (section?.sectionType === ChecklistSectionType.MachineParameter) {
            this.checklistService
              .getParameters(parseInt(question?.answer, 10))
              .subscribe((res) => {
                this.checkListForm
                  .get('sections')
                  ['controls'][i].get('questions')
                  ['controls'][j].get('parameters')
                  ?.setValue(res);
              });
          }
          return {
            questionId: question?.questionId,
            questionText:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.questionTitle, 10)
                : question?.questionTitle,
            answer:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.Range
                ? question?.answer
                  ? parseInt(question?.answer, 10)
                  : ''
                : section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer
                  ? question?.answer.split(' ')[0]
                  : ''
                : question?.answer,
            target:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer
                  ? question?.answer.split(' ')[1]
                  : ''
                : '',
            parameters: [],
          };
        }),
      };
    });
    return formatedData;
  }
}
