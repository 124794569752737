import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { ChecklistSectionType, ChecklistType } from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import {
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { MediaService } from 'src/backend/services/media.service';
import { TaskTrackerService } from 'src/backend/services/task-tracker/task-tracker.service';
import { WorkFlowService } from 'src/backend/services/work-flow.service';

@Component({
  selector: 'app-execute-checklist-page',
  templateUrl: './execute-checklist-page.component.html',
  styleUrls: ['./execute-checklist-page.component.scss'],
})
export class ExecuteChecklistPageComponent implements OnInit {
  @ViewChild('mediaModal') mediaModal!: TemplateRef<any>;
  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  gaugeValue = 0;
  workPermitForm: FormGroup;
  mediaForm: FormGroup;
  private destroy$ = new Subject();
  public translationsList: any = {};
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  questionTypes = ChecklistSectionType;
  sectionListFormArray: FormArray;
  loadingLabel: string = '';
  checklistType = ChecklistType;
  ranges = [
    { id: 0, value: '0' },
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
  ];
  data: any;
  TodayDate = moment.utc(new Date()).format('MM/DD/YYYY');

  taskId: number;
  taskCode: string;
  isExecuted: boolean;

  companyKey: string = '';
  instanceKey: string = '';
  loggedUser: LoggedUser;

  sectionId: number = 0;
  questionId: number = 0;
  TaskDate: string;
  workFlow: WorkFlow;

  public selectedImg: string;

  showProofOfLocation: boolean = false;
  constructor(
    private checklistService: ChecklistSectionService,
    private route: ActivatedRoute,
    private TaskService: TaskTrackerService,
    private router: Router,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private asyncFeedbackService: AsyncFeedbackService,
    private workFlowService: WorkFlowService,
    private baseApi: BaseApi,
    private mediaService: MediaService,
    private dialog: MatDialog,
    private datePipe: DatePipe
  ) {
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        this.taskId = parseInt(params.get('id'), 10);
      }
    });
    this.getHistoryData();
    this.workPermitForm = this.fb.group({
      sections: this.fb.array([this.createSectionForm()]),
    });
    this.mediaForm = this.fb.group({
      file: [[], Validators.required],
    });
    this.sectionListFormArray = this.workPermitForm.get(
      'sections'
    ) as FormArray;
  }

  isWithinRange(): boolean {
    const today = this.datePipe.transform(new Date(), 'MM/dd/yyyy');
    const formattedStartDate = this.datePipe.transform(
      this.data?.startTaskDate,
      'MM/dd/yyyy'
    );
    const formattedEndDate = this.datePipe.transform(
      this.TaskDate,
      'MM/dd/yyyy'
    );

    return (
      new Date(today).getTime() >= new Date(formattedStartDate).getTime() &&
      new Date(today).getTime() <= new Date(formattedEndDate).getTime()
    );
  }

  getHistoryData() {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.TaskService.getTaskHistory(this.taskId).subscribe({
      next: (response) => {
        this.data = response;
        this.TaskDate = moment.utc(response.taskDate).format('MM/DD/YYYY');
        this.getWorkFlow();
        // this.instanceKey = this.data?.instanceKey;
        this.sectionState = SectionStateStatus.Ready;
        this.buildForm(this.data);
        this.GetScorePercentage();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_AUDIT_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
        this.getWorkflowInstanceKeyById(this.workFlow.workFlowKey);
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getWorkflowInstanceKeyById(workflowKey: string) {
    this.workFlowService
      .getInstanceKeyByTableId(workflowKey, this.taskId)
      .subscribe({
        next: (res) => {
          this.instanceKey = res?.instanceKey
            ? res?.instanceKey
            : this.data?.instanceKey;
        },
        error: ({ error }) => {
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }

  buildForm(data: any): void {
    this.loadingService.startLoading(true, '');
    const sections = data.checkList.section;
    const code = this.data.taskCode;
    const executed = this.data.isExecuted;
    this.addSectionsFormGroup(sections);
    this.workPermitForm
      .get('sections')
      ?.setValue(this.formatCheckListData(sections, code, executed));
    this.loadingService.stopLoading();
  }
  addSectionsFormGroup(sections: any[]): void {
    this.sectionListFormArray.clear();
    for (let index = 0; index < sections.length; index++) {
      this.sectionListFormArray.push(this.createSectionForm());
      this.addQuestionsFormGroup(sections[index]?.questions?.length, index);
    }
  }

  addQuestionsFormGroup(numOfGroups: number, i: number): void {
    this.repeatQuestionsFormGroup(i).clear();
    for (let index = 0; index < numOfGroups; index++) {
      this.repeatQuestionsFormGroup(i).push(this.createQuestionForm());
    }
  }
  formatCheckListData(data: any[], code: any, Executed: boolean): any[] {
    this.taskCode = code;
    this.isExecuted = Executed;
    const formatedData = data.map((section, i) => {
      return {
        sectionId: section?.sectionId,
        title: section?.sectionTitle,
        questionType: section?.sectionType,
        questions: section?.questions?.map((question, j) => {
          if (section?.sectionType === ChecklistSectionType.MachineParameter) {
            this.checklistService
              .getParameters(parseInt(question?.answer, 10))
              .subscribe((res) => {
                this.workPermitForm
                  .get('sections')
                  ['controls'][i].get('questions')
                  ['controls'][j].get('parameters')
                  ?.setValue(res);
              });
          }
          return {
            questionId: question?.questionId,
            questionText:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.questionTitle, 10)
                : question?.questionTitle,
            answer:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.Range
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[0]
                : question?.answer,
            target:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[1]
                : '',
            parameters: [],
            userAnswer:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.userAnswer
                  ? question?.userAnswer.split(' ')[1]
                  : ''
                : question?.userAnswer,
            comment: question?.comment,
            isNotApplicable: question?.isNotApplicable,
            media: question?.media,
            correct: question?.correct ? question?.correct : null,
            mediaName: question?.mediaName ? question?.mediaName : '',
          };
        }),
      };
    });
    return formatedData;
  }
  createSectionForm(): FormGroup {
    return this.fb.group({
      sectionId: [0],
      title: [''],
      questionType: [''],
      questions: this.fb.array([this.createQuestionForm()]),
    });
  }
  createQuestionForm(): FormGroup {
    return this.fb.group({
      questionId: [0],
      questionText: [''],
      answer: [''],
      target: [0],
      parameters: [[]],
      userAnswer: [''],
      comment: [''],
      isNotApplicable: [false],
      media: [''],
      correct: [''],
      mediaName: [''],
    });
  }
  get repeatSectionFormGroup(): any {
    return this.workPermitForm.get('sections') as FormArray;
  }
  repeatQuestionsFormGroup(i: number): any {
    return this.workPermitForm
      .get('sections')
      ['controls'][i].get('questions') as FormArray;
  }
  getParamterName(id: number, i: number, j: number): string {
    return this.workPermitForm
      .get('sections')
      .value[i].questions[j].parameters.find((x) => x.id === id)?.name;
  }

  openProofOfLocationModel() {
    const { sections } = this.workPermitForm.value;
    if (this.checkAllAnswered(sections)) {
      this.showProofOfLocation = true;
      this.mediaForm.get('file')?.setValue([]);
      const mediaDialog = this.dialog.open(this.mediaModal, {
        height: 'auto',
        width: '40%',
      });

      mediaDialog.afterClosed().subscribe(() => {
        this.showProofOfLocation = false;
      });
    } else {
      this.asyncFeedbackService.showFeedback(
        new FeedbackModel(FeedbackType.Warning, 'Please, check complete list')
      );
    }
  }

  executeTask(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.setWorkFlowInstance();
        }
      });
  }
  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.TASK_TRACKER +
        '/' +
        SharedConstants.TASK_TRACKER_List,
    ]);
  }
  GetScorePercentage(): any {
    let correctanswers = 0;
    let NoOfQuestions = 0;
    let Percentage = 0;
    let sectionsFormArray = this.workPermitForm.get('sections').value;
    sectionsFormArray.forEach((element) => {
      if (element.questionType === 'Value Vs Target') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (
              el1.answer + ' ' + ' ' + el1.target ==
              el1.answer + ' ' + ' ' + el1.userAnswer
            ) {
              correctanswers++;
              el1.correct = true;
            } else {
              el1.correct = false;
            }
            NoOfQuestions++;
          } else {
            el1.correct = null;
          }
        });
      } else if (element.questionType === 'Select from range') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (Number(el1.userAnswer) >= el1.answer) {
              el1.correct = true;
            } else {
              el1.correct = false;
            }
            switch (el1.userAnswer) {
              case '0':
                correctanswers = correctanswers + 0;
                break;
              case '1':
                correctanswers = correctanswers + 0.25;
                break;
              case '2':
                correctanswers = correctanswers + 0.5;
                break;
              case '3':
                correctanswers = correctanswers + 0.75;
                break;
              case '4':
                correctanswers++;
                break;
              default:
                break;
            }
            NoOfQuestions++;
          } else {
            el1.correct = null;
          }
        });
      } else {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (el1.answer == el1.userAnswer) {
              correctanswers++;
              el1.correct = true;
            } else {
              el1.correct = false;
            }
            NoOfQuestions++;
          } else {
            el1.correct = null;
          }
        });
      }
    });
    Percentage = (correctanswers / NoOfQuestions) * 100;
    this.gaugeValue = Number(Percentage.toFixed(1));
  }

  updateMediaUrl(mediaUrl: string, fileName: string): void {

    const sections = [...this.workPermitForm.get('sections').value];
    const singleSection = sections.find((x) => x.sectionId === this.sectionId);
    if (singleSection) {
      const question = singleSection.questions.find(
        (x) => x.questionId === this.questionId
      );
      if (question) {
        question.media = mediaUrl;
        question.mediaName = fileName;
        this.workPermitForm.get('sections')?.setValue(sections);
      }
    }
  }

  execute(): void {
    const { sections } = this.workPermitForm.value;

    const data = {
      taskId: this.taskId,
      checktype: this.checklistType.Audit,
      code: this.taskCode,
      score: this.gaugeValue,
      sections: sections?.map((ele) => {
        if (ele.questionType === 'Value Vs Target') {
          return {
            sectionId: ele.sectionId,
            questions: ele.questions?.map((ques) => {
              return {
                questionId: ques.questionId,
                userAnswer: ques.isNotApplicable
                  ? ''
                  : ques.answer + ' ' + ques.userAnswer.toString(),
                comment: ques.comment,
                isNotApplicable: ques.isNotApplicable,
                media: ques.media,
                correct: ques.correct,
                mediaName: ques.mediaName,
              };
            }),
          };
        } else {
          return {
            sectionId: ele.sectionId,
            questions: ele.questions?.map((ques) => {
              return {
                questionId: ques.questionId,
                userAnswer: ques.isNotApplicable
                  ? ''
                  : ques.userAnswer.toString(),
                comment: ques.comment,
                isNotApplicable: ques.isNotApplicable,
                media: ques.media,
                correct: ques.correct,
                mediaName: ques.mediaName,
              };
            }),
          };
        }
      }),
    };

    const formData = new FormData();
    formData.append('content', JSON.stringify(data));
    if (this.mediaForm.value.file.length > 0) {
      formData.append('file', this.mediaForm.value.file[0]);
    }

    this.TaskService.executeChecklist(formData).subscribe({
      next: (response) => {
        this.notifyToAssignee(response?.message);
        this.closeDialog();
        this.mediaForm.get('file')?.setValue([]);
        this.showProofOfLocation = false;
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  notifyToAssignee(message: string): void {
    this.TaskService.notifyToAssignee(this.taskId).subscribe({
      next: (response) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, message)
        );
        this.loadingService.stopLoading();
        this.goBack();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  setWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_PERFORM_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    this.loadingService.startLoading(true, '');
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.execute();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  getGuageColors(value: number): string {
    if (value < 40) {
      return '#fa2020';
    }
    if (value >= 40 && value < 70) {
      return '#2041fa';
    }
    if (value >= 70) {
      return '#42f548';
    }
    return '';
  }

  uploadAnswerMedia(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          // this.updateMediaUrl(
          //   'https://hodhodblobstorev1.blob.core.windows.net/dev/Media/1320166-unit-4.jpg_2dd8fdc1-b6ee-4e43-b164-467cf541e54e_20240102T124437244%20%282%29.jpg_8c1838a6-0532-427c-92e5-c2d289152123_20240116T122820749.jpg_2bd80fd1-6395-4180-a59b-24a40bae2113_20240117T100816914.jpg'
          // );
          const formData = new FormData();
          formData.append('file', this.mediaForm.value.file[0]);
          this.loadingService.startLoading(true, '');
          this.mediaService.uploadSingleFile(formData).subscribe({
            next: (response) => {
              this.updateMediaUrl(
                response?.mediaPath,
                this.mediaForm.value.file[0].name
              );
              this.closeDialog();
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
              this.loadingService.stopLoading();
            },
            error: ({ error }) => {
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  openMediaModal(sectionId: number, questionId: number): void {
    this.sectionId = sectionId;
    this.questionId = questionId;
    this.mediaForm.get('file')?.setValue([]);
    this.dialog.open(this.mediaModal, { height: 'auto', width: '40%' });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  notApplicableChange(): void {
    this.GetScorePercentage();
  }
  checkAllAnswered(sections: any): boolean {

    let questions = [];
    for (const sec of sections) {
      questions = [...questions, ...sec.questions];
    }
    const notAnswered = questions.filter(
      (x) => x.isNotApplicable === false && x.userAnswer === ''
    );
    if (notAnswered.length > 0) {
      return false;
    }
    return true;
  }

  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }

  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  openBigView(imageUrl: string): void {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = imageUrl.split('.').pop();
    if (imageExtensions.includes(extension)) {
      this.selectedImg = imageUrl;
      this.dialog.open(this.imageBigView, {
        maxHeight: '95vh',
        width: 'auto',
      });
    } else {
      const downloadTag = document.createElement('a');
      downloadTag.href = imageUrl;
      downloadTag.addEventListener('click', () => {
        downloadTag.download;
      });
      downloadTag.click();
    }
  }
  downloadDocument(url: any): void {
    const downloadTag = document.createElement('a');
    downloadTag.href = url;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }
}
