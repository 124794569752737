import { EnvironmentInjector, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  GetLossMode,
  PostLossMode,
  UpdateLossMode,
} from 'src/backend/models/loss-mode/loss-mode.model';
import { Constants } from 'src/@hodhod/common/constants';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { SortDirection } from 'src/@hodhod/common/enum';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class LossModeService {
  netApiUrl: string = '';
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getLossModes(data: any): Observable<DataMapper<GetLossMode>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<GetLossMode>>(
      this.baseApi.encodeURL(Constants.API_LOSS_MODE_GET_ALL + '?' + params)
    );
  }

  posLossMode(data: PostLossMode): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_LOSS_MODE_POST),
      data
    );
  }

  updateLossMode(data: UpdateLossMode): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_LOSS_MODE_EDIT),
      data
    );
  }

  deActivate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_LOSS_MODE_DEACTIVATE + '/' + id)
    );
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_LOSS_MODE_ACTIVATE + '/' + id)
    );
  }

  getLossModesByCompany(reportType: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_LOSS_MODES_BY_COMPANY + '/' + reportType
      )
    );
  }
}
