import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { HazardReportAddComponent } from './pages/hazard-report-add/hazard-report-add.component';
import { IncidentReportAddComponent } from './pages/incident-report-add/incident-report-add.component';
import { NearMissReportAddComponent } from './pages/near-miss-report-add/near-miss-report-add.component';
import { IncidentCategoryPageComponent } from './pages/incident-category-page/incident-category-page.component';
import { IncidentClassificationPageComponent } from './pages/incident-classification-page/incident-classification-page.component';
import { LossModesPageComponent } from './pages/loss-modes-page/loss-modes-page.component';
import { NatureInjuryPageComponent } from './pages/Nature-Injury-page/Nature-Injury-page.component';
import { ReasonsPageComponent } from './pages/reasons-page/reasons-page.component';
import { HazardReportComponent } from './pages/hazard-report/hazard-report.component';
import { IncidentReportComponent } from './pages/incident-report/incident-report.component';
import { NearMissReportComponent } from './pages/near-miss-report/near-miss-report.component';
import { ReportingContentPageComponent } from './pages/reporting-content-page/reporting-content-page.component';
import { ReportingPageComponent } from './pages/reporting-page/reporting-page.component';
import { RootCausePageComponent } from './pages/root-cause-page/root-cause-page.component';
import { HazardReportViewPageComponent } from './pages/hazard-report-view-page/hazard-report-view-page.component';
import { IncidentReportViewPageComponent } from './pages/incident-report-view-page/incident-report-view-page.component';
import { NearMissReportViewPageComponent } from './pages/near-miss-report-view-page/near-miss-report-view-page.component';
import { MultiPermissionGuard } from '../shared/guards/MultiPermission.guard';
import { HazardReportEditComponent } from './pages/hazard-report-edit/hazard-report-edit.component';
import { NearMissReportEditComponent } from './pages/near-miss-report-edit/near-miss-report-edit.component';
import { IncidentReportEditComponent } from './pages/incident-report-edit/incident-report-edit.component';

const routes: Routes = [
  {
    path: SharedConstants.REPORTING,
    component: ReportingPageComponent,
    canActivate: [MultiPermissionGuard],
    data: {
      permissions: [
        ApplicationPermission.REPORTING_HAZARDS_ACCESS,
        ApplicationPermission.REPORTING_NEARMISS_ACCESS,
        ApplicationPermission.REPORTING_INCIDENT_ACCESS,
      ],
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Reporting',
      },
    },
  },
  {
    path: SharedConstants.CONTENT_REPORTING,
    component: ReportingContentPageComponent,
    canActivate: [MultiPermissionGuard],
    data: {
      permissions: [
        ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_ACCESS,
        ApplicationPermission.CONTENT_REPORTING_NATUREINJURY_ACCESS,
        ApplicationPermission.CONTENT_REPORTING_REASONS_ACCESS,
        ApplicationPermission.CONTENT_REPORTING_INCIDENTCLASSIFICATION_ACCESS,
        ApplicationPermission.CONTENT_REPORTING_INCIDENTCATEGORY_ACCESS,
      ],
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'ContentReporting',
      },
    },
  },
  {
    path: SharedConstants.REPORT_HAZARD,
    component: HazardReportComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_HAZARDS_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'ReportHazard',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.REPORT_NEAR_MISS,
    component: NearMissReportComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_NEARMISS_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'ReportNearMiss',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.REPORT_INCIDENT,
    component: IncidentReportComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_INCIDENT_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'ReportIncident',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.INCIDENT_CATEGORY,
    component: IncidentCategoryPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions:
        ApplicationPermission.CONTENT_REPORTING_INCIDENTCATEGORY_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'IncidentCategory',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.NATURE_INJURY,
    component: NatureInjuryPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.CONTENT_REPORTING_NATUREINJURY_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'NatureInjury',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.REASONS,
    component: ReasonsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.CONTENT_REPORTING_REASONS_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Reasons',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.ROOT_CAUSE,
    component: RootCausePageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'RootCause',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.INCIDENT_CLASSIFICATION,
    component: IncidentClassificationPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions:
        ApplicationPermission.CONTENT_REPORTING_INCIDENTCLASSIFICATION_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'IncidentClassification',
        isActive: true,
      },
    },
  },
  {
    path: SharedConstants.LOSS_MODES,
    component: LossModesPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.CONTENT_REPORTING_LOSSMODE_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'LossModes',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.REPORT_HAZARD + '/' + SharedConstants.ADD_REPORT_HAZARD,
    component: HazardReportAddComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_HAZARDS_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'AddReportHazard',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.REPORT_HAZARD +
      '/' +
      SharedConstants.REPORT_HAZARD_VIEW +
      '/:id',
    component: HazardReportViewPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_HAZARDS_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.REPORT_HAZARD +
      '/' +
      SharedConstants.REPORT_HAZARD_EDIT +
      '/:id',
    component: HazardReportEditComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_HAZARDS_UPDATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Edit',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.REPORT_NEAR_MISS +
      '/' +
      SharedConstants.REPORT_NEAR_MISS_VIEW +
      '/:id',
    component: NearMissReportViewPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_NEARMISS_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.REPORT_NEAR_MISS +
      '/' +
      SharedConstants.ADD_REPORT_NEAR_MISS,
    component: NearMissReportAddComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_NEARMISS_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'AddReportNearMiss',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.REPORT_NEAR_MISS +
      '/' +
      SharedConstants.REPORT_NEAR_MISS_EDIT +
      '/:id',
    component: NearMissReportEditComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_NEARMISS_UPDATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Edit',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.REPORT_INCIDENT +
      '/' +
      SharedConstants.ADD_REPORT_INCIDENT,
    component: IncidentReportAddComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_INCIDENT_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'AddReportIncident',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.REPORT_INCIDENT +
      '/' +
      SharedConstants.REPORT_INCIDENT_VIEW +
      '/:id',
    component: IncidentReportViewPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_INCIDENT_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.REPORT_INCIDENT +
      '/' +
      SharedConstants.REPORT_INCIDENT_EDIT +
      '/:id',
    component: IncidentReportEditComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.REPORTING_INCIDENT_UPDATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Edit',
        isActive: true,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportingRoutingModule {}
