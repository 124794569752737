<div class="flex items-center main">
  <div class="w-7/12 vh-100 content slider">
    <div class="content-h flex column justify-flex-start align-center">
      <ng-carousel
        class="carousel box grow-fixed"
        style="direction: ltr"
        #carouselRef="ngCarousel"
        [config]="config"
        (itemIndexChange)="setItemIndex($event)"
      >
        <ng-template [ngCarouselSlide]="carouselRef" let-item>
          <div class="slide flex column justify-end items-center">
            <div style="height: 90%; margin: auto;">
              <img
                class="rounded-md Carousel-img"
                [src]="
                  'assets/img/resources/login-slide-' + item.index + '.png'
                "
              />
            </div>
          </div>
        </ng-template>
      </ng-carousel>
      <div class="description my-3 mx-auto">
        {{
          "The dashboard is configurable, interactive, and user-friendly. Its responsive design makes it perfect for any project."
        }}
      </div>
      <div class="bannerPoint flex center justify-center">
        <div
          *ngFor="let item of config.items; index as i"
          (click)="carouselRef.setIndex(i)"
          [style.background]="'url(' + config.items[i] + ')'"
        >
          <div [class.active]="i === itemIndex"></div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="w-5/12 bg-white vh-100 flex column justify-center items-center login"
  >
    <div
      class="w-full"
      [class]="
        (isRTL$ | async)
          ? 'left_direction flex center'
          : 'right_direction flex center'
      "
    >
      <div class="routs-container w-10/12 flex">
        <router-outlet style="margin: 0 !important"></router-outlet>
      </div>
    </div>
  </div>
</div>
