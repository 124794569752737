<div
  class="border flex flex-col p-3 justify-between rounded-[10px] mx-1 bg-[#F3F3F3] min-w-[300px] mt-2"
>
  <div class="mb-2 flex justify-between">
    <img
      class="w-[30%] object-contain"
      src="assets/img/resources/saned-logo.png"
      alt="saned"
    />
    <p
      class="text-sm font-bold"
      [ngClass]="cardDetails?.status === 'ISSUED' ? 'text-green' : 'text-red'"
    >
      {{ cardDetails?.status }}
    </p>
  </div>

  <div class="flex items-center justify-between mt-2">
    <div class="flex items-center">
      <div>
        <ngx-avatars
          class="avatar box"
          size="50"
          [src]="imageUrl"
          [name]="userDetails?.fullName"
          [textSizeRatio]="3"
          [initialsSize]="2"
          [round]="true"
          [cornerRadius]="'10px'"
        ></ngx-avatars>
      </div>
      <div class="mx-1">
        <p class="text-base font-bold">{{ userDetails?.fullName }}</p>
        <p class="text-base font-bold">{{ cardDetails?.cardNumber }}</p>
      </div>
    </div>
  </div>

  <div class="flex justify-between">
    <div class="flex items-end flex-grow">
      <img
        class="w-[20%]"
        src="assets/img/resources/hid-logo.svg"
        alt="saned"
      />
    </div>
    <div>
      <qrcode
        [qrdata]="'https://isaned.com/'"
        [width]="50"
        [margin]="0"
        [colorDark]="'#131C4E'"
        [errorCorrectionLevel]="'M'"
      ></qrcode>
    </div>
  </div>
</div>
<p
  *ngIf="cardDetails?.status == 'ISSUED'"
  class="text-center mt-2 font-bold cursor-pointer text-primary"
  (click)="revokeCard()"
>
  {{ "ManageAccessControl.RevokeMobileId" | translate }}
</p>
