import { Component, Input, OnInit } from '@angular/core';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { Feature } from 'src/backend/models/features/feature';

@Component({
  selector: 'app-organization-permissions',
  templateUrl: './organization-permissions.component.html',
  styleUrls: ['./organization-permissions.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
  ]
})
export class OrganizationPermissionsComponent implements OnInit {
  @Input() features: Feature[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
