<div class="cursor-pointer" (click)="clickInspection()">
  <div class="title rounded-[18px] flex justify-between">
    <div
      class="flex items-center"
      [ngClass]="viewMode === 'reviewer' ? 'w-[40%]' : 'w-full p-2'"
    >
      <h1
        class="text-base whitespace-nowrap overflow-hidden text-ellipsis"
        [matTooltip]="inspection.name"
      >
        {{ inspection.name }}
      </h1>
    </div>

    <div class="flex" *ngIf="viewMode === 'reviewer'">
      <span class="bg-[#fff] text-[#7DDF81] title-data"
        >Status: {{ inspection?.status }}</span
      >
      <span class="bg-[#fff] text-[#FF6422] title-data">
        Adherence: {{ inspection?.adherence }} %
      </span>
    </div>
  </div>

  <img
    class="rounded-[18px] h-[190px] w-full object-fill bg-[#D9D9D9]"
    [src]="
      inspection?.imageUrl
        ? inspection?.imageUrl
        : 'assets/img/resources/category-placeholder.svg'
    "
    [alt]="inspection?.name"
  />
</div>
