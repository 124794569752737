<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="
    (formMode === 'create'
      ? 'Role.AddingLoadingRole'
      : 'Role.UpdatingLoadingRole'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="injuiryForm">
    <div class="flex items-center" mat-dialog-title>
      <h2
        *ngIf="injuiryForm.get('injuiryName').value"
        class="headline m-0 flex-auto"
      >
        {{ injuiryForm.get("injuiryName").value }}
      </h2>
      <h2
        *ngIf="!injuiryForm.get('injuiryName').value"
        class="headline m-0 flex-auto"
      >
        {{ "Role.NatureInjuryInfo" | translate }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex flex-col flex-wrap justify-between">
      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "Role.NatureInjuryName" | translate }}
          <span
            [ngClass]="
              injuiryForm.invalid &&
              injuiryForm.get('injuiryName').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            [readonly]="this.formMode === 'view'"
            matInput
            type="text"
            formControlName="injuiryName"
          />
          <mat-error
            *ngIf="
              injuiryForm.invalid &&
              injuiryForm.get('injuiryName').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              injuiryForm.invalid &&
              injuiryForm.get('injuiryName').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "NatureInjury.BodyParts" | translate }}
          <span
            [ngClass]="
              injuiryForm.invalid &&
              injuiryForm.get('bodypart').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            [placeholder]="'NatureInjury.BodyParts' | translate"
            formControlName="bodypart"
            [disabled]="this.formMode === 'view'"
          >
            <mat-option *ngFor="let b of bodyParts" [value]="b.bodyPartId">
              {{ "Enum.BodyParts." + b.bodyPart | translate }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              injuiryForm.invalid &&
              injuiryForm.get('bodypart').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <!-- History -->
    <div class="mt-4" *ngIf="this.formMode === 'view'">
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="
          'ViewReportIncident.InjuredPerson.natureofInjuryHistory' | translate
        "
      >
        <app-history-log
          body
          *ngIf="this.defaults.role"
          [sourceId]="this.defaults.role.id"
          [sourceType]="HistoryType"
        ></app-history-log>
      </app-expandable-panel>
    </div>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isCreateMode()"
          [btnType]="'Add'"
          [btnText]="'Add'"
          [isDisabled]="!injuiryForm.valid"
          (onClick)="save()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isUpdateMode()"
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="!injuiryForm.valid"
          (onClick)="save()"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
