import { Team } from 'src/app/user-management/models/team';
import {  SortDirection } from 'src/@hodhod/common/enum';
import { BaseApi } from '../api/base-api';
import { DataMapper } from '../models/voilations/voilation.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';



@Injectable({
  providedIn: 'root'
})
export class TeamsService {
  constructor(private http: HttpClient, private baseApi: BaseApi) { }
  getAllTeams(data: any): Observable<DataMapper<Team>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<Team>>(
      this.baseApi.encodeURL(
        Constants.API_ALL_TEAM_LIST_BY_COMPANY + '?' + params
      )
    );
  }


  createTeam(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_TEAM_CREATE),
      data
    );
  }

  updateTeam(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_TEAM_UPDATE),
      data
    );
  }

  activateTeam(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_TEAM_ACTIVATE + '/' + id),id
    );
  }

  deActivateTeam(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_TEAM_DEACTIVATE + '/' + id),id
    );
  }
}
