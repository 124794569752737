import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { GetReNewIdCardById } from 'src/backend/models/manage-id-card/manage-id-card.model';
import { ManageIdCardService } from 'src/backend/services/manage-id-card/manage-id-card.service';
import { GetAllUpdateCardViewhistory } from '../../models/renew-id-card-model';

@Component({
  selector: 'app-view-update-card',
  templateUrl: './view-update-card.component.html',
  styleUrls: ['./view-update-card.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    scaleFadeIn400ms,
    fadeInRight400ms,
    scaleIn400ms
  ],
})
export class ViewUpdateCardComponent implements OnInit {
  @ViewChild('renewImageBigView') private renewImageBigView!: TemplateRef<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<GetAllUpdateCardViewhistory>([]);
  private destroy$ = new Subject();
  renewId: number;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  renewdata: any;
  loadingLabel: string = '';
  constructor(private dialog: MatDialog,
    private route: ActivatedRoute,
    private idCardService: ManageIdCardService,
    private feedBackService: AsyncFeedbackService
  ) { }
  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }
  columns: TableColumn<GetAllUpdateCardViewhistory>[] = [
    { label: 'CreatedBy', property: 'createdBy', type: 'text', visible: true, cssClasses: ['font-medium'] },
    { label: 'CreatedAt', property: 'createdOn', type: 'date', visible: true },
    { label: 'Status', property: 'action', type: 'boolean', visible: true },
    { label: 'Attachment', property: 'attachment', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    { label: 'Comments', property: 'comment', type: 'text', visible: true }
  ];
  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id');
        this.renewId = parseInt(id, 10);
      }
    });
    this.dataSource.paginator = this.paginator;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = Constants.PAGE_SIZE;
    this.getHistoryData();
    this.getIdCardDataByid();
  }
  fullImageView(): void {
    this.dialog.open(this.renewImageBigView, {
      height: 'auto',
      width: 'auto'
    })
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  getIdCardDataByid() {
    this.loadingLabel = "General.Refreshing";
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.idCardService.getReNewIdCardById(this.renewId).subscribe({
      next: (response) => {
        this.renewdata = response;
        this.sectionState = SectionStateStatus.Ready
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready
      }
    })
  }

  getHistoryData() {
    this.loadingLabel = "General.Refreshing";
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.idCardService.getIdCardHistory(this.renewId).subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource(response?.data);
        this.paginator.length = response?.totalCount;
        this.sectionState = SectionStateStatus.Ready
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready
      }
    });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }
}
