import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';
import {
  PostDepartment,
  UpdateDepartment,
  GetAllDepartments,
} from 'src/backend/models/departments/departments.model';

@Injectable({
  providedIn: 'root',
})
export class DepartmentsService {
  netApiUrl: string = '';

  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  postDepartment(data: PostDepartment): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_DEPARTMENT_CREATE),
      data
    );
  }

  UpdateDepartment(data: UpdateDepartment): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_DEPARTMENT_EDIT),
      data
    );
  }

  getDepartmentList(data: any = {}): Observable<DataMapper<GetAllDepartments>> {

    if (data) {
      let params = '';
      if (data?.pageIndex) {
        params += 'offset=' + data?.pageIndex.toString();
      }
      if (data?.pageSize) {
        if (params.length > 0) {
          params += '&';
        }
        params += 'limit=' + data?.pageSize.toString();
      }
      if (data?.status) {
        if (params.length > 0) {
          params += '&';
        }
        params += `status=` + data?.status;
      }
      if (data?.sortDirection !== SortDirection.None) {
        if (params.length > 0) {
          params += '&';
        }
        params += 'sortDirection=' + data?.sortDirection;
      }
      if (data?.sortField) {
        if (params.length > 0) {
          params += '&';
        }
        params += 'sortField=' + data?.sortField;
      }
      if (data?.searchValue) {
        if (params.length > 0) {
          params += '&';
        }
        params += 'phrase=' + data?.searchValue;
      }
      return this.http.get<DataMapper<GetAllDepartments>>(
        this.baseApi.encodeURL(Constants.API_DEPARTMENT_GET_ALL + '?' + params)
      );
    } else {
      return this.http.get<DataMapper<GetAllDepartments>>(
        this.baseApi.encodeURL(Constants.API_DEPARTMENT_GET_ALL)
      );
    }
  }

  getSubDepartmentList(
    data: any = {},
    id
  ): Observable<DataMapper<GetAllDepartments>> {

    if (data) {
      let params = '';
      if (data?.pageIndex) {
        params += 'offset=' + data?.pageIndex.toString();
      }
      if (data?.pageSize) {
        if (params.length > 0) {
          params += '&';
        }
        params += 'limit=' + data?.pageSize.toString();
      }
      if (data?.status) {
        if (params.length > 0) {
          params += '&';
        }
        params += `status=` + data?.status;
      }
      if (data?.sortDirection !== SortDirection.None) {
        if (params.length > 0) {
          params += '&';
        }
        params += 'sortDirection=' + data?.sortDirection;
      }
      if (data?.sortField) {
        if (params.length > 0) {
          params += '&';
        }
        params += 'sortField=' + data?.sortField;
      }
      if (data?.searchValue) {
        if (params.length > 0) {
          params += '&';
        }
        params += 'phrase=' + data?.searchValue;
      }
      return this.http.get<DataMapper<GetAllDepartments>>(
        this.baseApi.encodeURL(
          Constants.API_SUBDEPARTMENT_GET_ALL + '/' + id + '?' + params
        )
      );
    } else {
      return this.http.get<DataMapper<GetAllDepartments>>(
        this.baseApi.encodeURL(Constants.API_SUBDEPARTMENT_GET_ALL + '/' + id)
      );
    }
  }

  deActivate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_DEPARTMENT_DEACTIVATE + '/' + id)
    );
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_DEPARTMENT_ACTIVATE + '/' + id)
    );
  }
  getDepartmentById(id: number): Observable<GetAllDepartments> {
    return this.http.get<GetAllDepartments>(
      this.baseApi.encodeURL(Constants.API_DEPARTMENT_BY_ID + '/' + id)
    );
  }
  deleteDepartment(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_DEPARTMENT_DELETE + '/' + id)
    );
  }
  getCompanyDepartments(): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(Constants.API_DEPARTMENT_COMPANY_ALL)
    );
  }
  getDepartmentsParentChild(): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(
        Constants.API_DEPARTMENT_GET_DEPARTMENTS_PARENT_CHILD
      )
    );
  }

  getDepartmentsParentChildByCompanyCode(
    companyCode: string
  ): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(
        Constants.API_DEPARTMENT_GET_DEPARTMENTS_PARENT_CHILD_BY_COMPANY_CODE +
          '/' +
          companyCode
      )
    );
  }
  getDepartmentsParentChildByUserId(): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(
        Constants.API_DEPARTMENT_GET_DEPARTMENTS_PARENT_CHILD_BY_USER_ID
      )
    );
  }

  getSubDepartmentsById(id: Number): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(Constants.API_SUBDEPARTMENTS_BY_ID + '/' + id)
    );
  }
}
