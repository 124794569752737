import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { TranslateService } from '@ngx-translate/core';
import Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { DashboardCalenderComponent } from '../dashboard-calender/dashboard-calender.component';
import { MatDialog } from '@angular/material/dialog';
import {
  IoTFilterDashboards,
  SmartSafetyFilterDashboards,
} from 'src/@hodhod/common/enum';

@Component({
  selector: 'app-fullscreen-dashboard',
  templateUrl: './fullscreen-dashboard.component.html',
  styleUrls: ['./fullscreen-dashboard.component.scss'],
})
export class FullscreenDashboardComponent implements OnInit, OnDestroy {
  @Input() selectedDashboard: any = null;
  @Input() filterData: any = null;

  public showLevelOne: boolean = false;
  public showLevelTwo: boolean = false;
  public showLevelThree: boolean = false;
  public showLevelFour: boolean = false;
  public clickedPoint: { point: string; level: string } = {
    point: 'New',
    level: 'one',
  };
  @ViewChild('pdf') pdf!: ElementRef;

  public filterDashboards =
    this.selectedDashboard?.dashboardType === 'analog' ||
    this.selectedDashboard?.dashboardType === 'digital'
      ? IoTFilterDashboards
      : SmartSafetyFilterDashboards;
  public openSelect: boolean = false;
  private destroy$ = new Subject();
  public dashboard: any = null;

  public searchForm = new FormGroup({
    start: new FormControl(
      new Date(new Date().setDate(new Date().getDate() - 7))
    ),
    end: new FormControl(new Date()),
  });

  public translationsList: any = {};

  public charts: any = [];
  public printClicked = false;

  constructor(
    private loadingService: LoadingService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.translate
      .get(['Dashboards'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations.Dashboards;
      });
  }

  updateDashboard(updateDashboard) {
    this.dashboard = updateDashboard;
  }

  ngOnInit() {
    this.filterData = {
      fromDate: this.selectedDashboard.startDate,
      toDate: this.selectedDashboard.endDate,
      companyId: localStorage.getItem('company_id'),
      chartName: this.selectedDashboard.title,
    };
    this.showLevelOne = true;
    this.dashboard = structuredClone(this.selectedDashboard);
    // this.filterDashboardByDate(this.filterData);
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  changeChartLevel(clickedPoint?) {
    this.clickedPoint = clickedPoint;

    if (this.showLevelTwo && this.clickedPoint.level == 'one') {
      this.showLevelTwo = false;
      this.showLevelThree = false;
      this.showLevelFour = false;
      this.dashboard.chartData.part2 = this.selectedDashboard.chartData?.part2;
      let partArray = [];
      this.loadingService.startLoading();
      setTimeout(() => {
        if (this.dashboard.title == 'InspectionScore') {
          for (let i = 0; i < this.dashboard.chartData?.part2?.length; i++) {
            partArray.push(this.dashboard.chartData?.part2[i]);
          }
        } else {
          if (this.dashboard.title == 'CheckList') {
            for (let i = 0; i < this.dashboard.chartData?.part2?.length; i++) {
              if (
                this.dashboard.chartData?.part2[i]?.id ==
                this.clickedPoint.point
              ) {
                this.dashboard.chartData.part2[i].color =
                  this.dashboard.chartData.part1[0].data.find(
                    (item) => item.name === clickedPoint?.point
                  )?.color;
                partArray.push(this.dashboard.chartData?.part2[i]);
              }
            }
          } else {
            for (let i = 0; i < this.dashboard.chartData?.part2?.length; i++) {
              if (
                this.dashboard.chartData?.part2[i]?.id ==
                this.clickedPoint.point
              ) {
                this.dashboard.chartData.part2[i].color =
                  this.dashboard.chartData.part1.find(
                    (item) => item.name === clickedPoint.point
                  )?.color;
                partArray.push(this.dashboard.chartData?.part2[i]);
              }
            }
          }
        }
        this.dashboard.chartData.part2 = partArray;
        this.showLevelTwo = true;

        this.dashboard.totalReportedLevelTwo = this.dashboard.chartData.part1
          .find((item) => item.name === clickedPoint.point)
          ?.data.find((el) => el.drilldown == clickedPoint.point).y;

        const levelCard = document.getElementById('levelTwo');
        setTimeout(() => {
          levelCard?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          this.loadingService.stopLoading();
        }, 500);
      }, 1000);
    } else if (this.showLevelThree && this.clickedPoint.level == 'one') {
      this.showLevelTwo = false;
      this.showLevelThree = false;
      this.showLevelFour = false;
      this.dashboard.chartData.part2 = this.selectedDashboard.chartData?.part2;
      this.dashboard.chartData.part3 = this.selectedDashboard.chartData?.part3;
      this.dashboard.chartData.part4 = this.selectedDashboard.chartData?.part4;
      let partArray = [];
      this.loadingService.startLoading();
      setTimeout(() => {
        for (let i = 0; i < this.dashboard.chartData?.part2?.length; i++) {
          if (
            this.dashboard.chartData?.part2[i]?.id == this.clickedPoint.point
          ) {
            this.dashboard.chartData.part2[i].color =
              this.dashboard.chartData.part1.find(
                (item) => item.name === clickedPoint.point
              )?.color;
            partArray.push(this.dashboard.chartData?.part2[i]);
          }
        }
        this.dashboard.chartData.part2 = partArray;
        this.showLevelTwo = true;
        this.dashboard.totalReportedLevelTwo = this.dashboard.chartData.part1
          .find((item) => item.name === clickedPoint.point)
          .data.find((el) => el.drilldown == clickedPoint.point).y;

        const levelCard = document.getElementById('levelTwo');
        setTimeout(() => {
          levelCard?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          this.loadingService.stopLoading();
        }, 500);
      }, 1000);
    } else if (this.showLevelThree && this.clickedPoint.level == 'two') {
      this.showLevelThree = false;
      this.showLevelFour = false;
      this.dashboard.chartData.part3 = this.selectedDashboard.chartData?.part3;
      this.dashboard.chartData.part4 = this.selectedDashboard.chartData?.part4;
      let partArray = [];

      this.loadingService.startLoading();
      setTimeout(() => {
        for (let i = 0; i < this.dashboard.chartData?.part3?.length; i++) {
          if (
            this.dashboard.chartData?.part3[i]?.id == this.clickedPoint.point
          ) {
            this.dashboard.chartData.part3[i].color =
              this.dashboard.chartData.part2.find(
                (item) => item.name === clickedPoint?.point?.split('-')[0]
              )?.color;
            partArray.push(this.dashboard.chartData?.part3[i]);
          }
        }
        this.dashboard.chartData.part3 = partArray;
        this.showLevelThree = true;

        this.dashboard.totalReportedLevelThree = this.dashboard.chartData.part2
          .find((item) => item.name === clickedPoint?.point?.split('-')[0])
          ?.data.find((el) => el.drilldown == clickedPoint.point)?.y;
        const levelCard = document.getElementById('levelThree');
        setTimeout(() => {
          levelCard?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          this.loadingService.stopLoading();
        }, 500);
      }, 1000);
    } else if (
      this.showLevelOne &&
      !this.showLevelTwo &&
      !this.showLevelThree
    ) {
      this.showLevelTwo = true;
      this.showLevelThree = false;
      this.showLevelFour = false;
      this.dashboard.totalReportedLevelTwo = this.dashboard.chartData.part1
        .find((item) => item.name === clickedPoint.point)
        ?.data.find((el) => el.drilldown == clickedPoint.point).y;
      const levelCard = document.getElementById('levelTwo');

      if (this.dashboard.title == 'CheckList') {
        for (let i = 0; i < this.dashboard.chartData?.part2?.length; i++) {
          if (
            this.dashboard.chartData?.part2[i]?.id == this.clickedPoint.point
          ) {
            this.dashboard.chartData.part2[i].color =
              this.dashboard.chartData.part1[0].data.find(
                (item) => item.name === clickedPoint?.point
              )?.color;
          }
        }
      } else {
        for (let i = 0; i < this.dashboard.chartData?.part2?.length; i++) {
          if (
            this.dashboard.chartData?.part2[i]?.id == this.clickedPoint.point
          ) {
            this.dashboard.chartData.part2[i].color =
              this.dashboard.chartData.part1.find(
                (item) => item.name === clickedPoint?.point
              )?.color;
          }
        }
      }
      setTimeout(() => {
        levelCard?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    } else if (this.showLevelOne && this.showLevelTwo && !this.showLevelThree) {
      this.showLevelThree = true;
      const levelCard = document.getElementById('levelThree');
      this.loadingService.startLoading();

      for (let i = 0; i < this.dashboard.chartData?.part3?.length; i++) {
        if (this.dashboard.chartData?.part3[i]?.id == this.clickedPoint.point) {
          this.dashboard.chartData.part3[i].color =
            this.dashboard.chartData.part2.find(
              (item) => item.name === clickedPoint?.point?.split('-')[0]
            )?.color;
        }
      }
      this.dashboard.totalReportedLevelThree = this.dashboard.chartData.part2
        .find((item) => item.name === clickedPoint?.point?.split('-')[0])
        ?.data.find((el) => el.drilldown == clickedPoint.point)?.y;

      setTimeout(() =>
        levelCard?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      );
      this.loadingService.stopLoading();
    } else if (
      this.showLevelOne &&
      this.showLevelTwo &&
      this.showLevelThree &&
      !this.showLevelFour
    ) {
      this.showLevelFour = true;

      const levelCard = document.getElementById('levelFour');
      this.loadingService.startLoading();

      for (let i = 0; i < this.dashboard.chartData?.part4?.length; i++) {
        if (this.dashboard.chartData?.part4[i]?.id == this.clickedPoint.point) {
          this.dashboard.chartData.part4[i].color =
            this.dashboard.chartData.part3.find(
              (item) => item.name === clickedPoint?.point?.split('-')[0]
            )?.color;
        }
      }
      this.dashboard.totalReportedLevelFour = this.dashboard.chartData.part3
        .find((item) => item.name === clickedPoint?.point?.split('-')[0])
        ?.data.find((el) => el.drilldown == clickedPoint.point)?.y;

      setTimeout(() =>
        levelCard?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      );
      this.loadingService.stopLoading();
    } else if (this.showLevelFour && this.clickedPoint.level == 'three') {
      this.showLevelFour = false;
      this.dashboard.chartData.part4 = this.selectedDashboard.chartData?.part4;
      let partArray = [];

      this.loadingService.startLoading();
      setTimeout(() => {
        for (let i = 0; i < this.dashboard.chartData?.part4?.length; i++) {
          if (
            this.dashboard.chartData?.part4[i]?.id == this.clickedPoint.point
          ) {
            this.dashboard.chartData.part4[i].color =
              this.dashboard.chartData.part3.find(
                (item) => item.name === clickedPoint?.point?.split('-')[0]
              )?.color;
            partArray.push(this.dashboard.chartData?.part4[i]);
          }
        }
        this.dashboard.chartData.part4 = partArray;
        this.showLevelFour = true;

        this.dashboard.totalReportedLevelFour =
          this.dashboard.chartData.part3.find(
            (item) => item.name === clickedPoint?.point?.split('-')[0]
          )?.data[0]?.y;

        const levelCard = document.getElementById('levelFour');
        setTimeout(() => {
          levelCard?.scrollIntoView({ behavior: 'smooth', block: 'start' });
          this.loadingService.stopLoading();
        }, 500);
      }, 1000);
    }
  }

  checkSelectIfOpen(value) {
    this.openSelect = value;
  }

  onChangeCalenderDD(value) {
    this.filterData = value;
    this.showLevelTwo = false;
    this.showLevelThree = false;
    this.showLevelFour = false;
  }

  async print() {
    this.printClicked = true;
    this.loadingService.startLoading();
    let chartImage = null;

    if (this.showLevelOne) {
      this.createChart(1, 'part1');
    }
    if (this.showLevelTwo) {
      this.createChart(2, 'part2');
    }
    if (this.showLevelThree) {
      this.createChart(3, 'part3');
    }
    if (this.showLevelFour) {
      this.createChart(4, 'part4');
    }

    setTimeout(() => {
      const element = document.getElementById('pdfContent');
      const images = {
        snow: SharedConstants.REPORT_LOGO_URL,
      };
      html2canvas(element, { scrollY: -window.scrollY }).then((canvas) => {
        chartImage = canvas.toDataURL('image/jpeg');
        let companyData = null;
        images['companyLogo'] =
          JSON.parse(localStorage.getItem('company')).companyImage || '';

        if (JSON.parse(localStorage.getItem('company')).companyImage) {
          companyData = {
            columns: [
              {
                canvas: [
                  {
                    type: 'ellipse',
                    x: 50,
                    y: 50,
                    r1: 25,
                    r2: 25,
                    lineWidth: 2,
                    lineColor: '#000',
                  },
                ],
                image: 'companyLogo',
                width: 50,
                height: 50,
              },
              {
                text: JSON.parse(localStorage.getItem('company')).companyName,
                alignment: 'left',
                bold: true,
                fontSize: 15,
                margin: [15, 10, 0, 0],
              },
            ],
          };
        } else {
          companyData = {
            columns: [
              {
                text: JSON.parse(localStorage.getItem('company')).companyName,
                alignment: 'left',
                bold: true,
                fontSize: 15,
                margin: [15, 10, 0, 0],
              },
            ],
          };
        }
        const docDefinition = {
          pageSize: 'A4',
          pageOrientation: 'portrait',
          content: [
            companyData,
            {
              image: 'snow',
              alignment: 'right',
              width: 135,
              height: 40,
              marginTop: -20,
              marginRight: 35,
            },
            {
              text: '_________________                __',
              fontSize: 40,
              color: '#131c4e',
              bold: true,
              marginTop: -55,
            },
            {
              alignment: 'center',
              margin: [10, 10],
              columns: [
                [
                  {
                    text: this.translationsList[this.dashboard?.title],
                    color: 'black',
                    fontSize: 15,
                  },
                ],
              ],
            },
            {
              margin: [0, 10, 0, 15],
              columns: [
                [
                  {
                    text:
                      'From ' +
                      this.formatDate(this.dashboard.startDate) +
                      ' To ' +
                      this.formatDate(this.dashboard.endDate),
                    color: '#131c4e',
                    alignment: 'left',
                    fontSize: 10,
                  },
                ],
              ],
            },

            // charts
            // {
            //   image: chartImage,
            //   fit: [500, 600],
            //   margin: [0, 10],
            // },
          ],
          footer: {
            columns: [
              {
                canvas: [
                  { type: 'line', x1: 0, y1: 0, x2: 315, y2: 0, lineWidth: 35 },
                ],
              },
              {
                text: 'Thanks for your business',
                marginLeft: -220,
                marginTop: -7,
                color: 'white',
                fontSize: 10,
              },
              {
                text: 'If you have any request about this please contact',
                marginLeft: -250,
                fontSize: 8,
                marginTop: -10,
              },
              {
                text: 'HodHod@gmail.com',
                bold: true,
                marginLeft: -530,
                fontSize: 10,
              },
            ],
          },
          images,
          styles: {
            sectiontext: {
              bold: true,
            },
            filterText: {
              color: 'grey',
            },
            continer: {
              display: 'flex',
              backgroundcolor: 'black',
            },
          },
        };
        pdfMake.createPdf(docDefinition).open();
        this.printClicked = false;
        this.loadingService.stopLoading();
      });
    }, 1000);
  }

  createChart(level, part) {
    Highcharts.chart('container' + level, {
      chart: {
        type: this.dashboard.chartData?.chartType,
      },
      title: {
        text:
          this.dashboard.title == 'InspectionScore' && level == 1
            ? this.dashboard?.chartData[part][0]?.data[0]?.y + ' %'
            : this.dashboard.title,
        verticalAlign: 'middle',
        align: 'center',
        y: -4,
        style: {
          display:
            this.dashboard.title == 'InspectionScore' && level == 1
              ? ''
              : 'none',
        },
      },
      legend: {
        enabled: true,
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        squareSymbol: false,
        borderRadius: 0,
        symbolRadius: 3,
        symbolHeight: 10,
        symbolWidth: 12,
        symbolPadding: 12,
        itemStyle: {
          width: 100,
          height: 100,
          position: 'absolute',
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
        },
        column: {
          borderRadius: 5,
          dataLabels: {
            padding: 10,
            borderRadius: 5,
            backgroundColor: '#ffffff',
            color: '#000000',
            borderWidth: 0,
            borderColor: 'none',
            x: 0,
            y: 15,
            format:
              this.dashboard.title == 'CheckList' ||
              this.dashboard.title == 'InspectionScore'
                ? '{point.y} %'
                : '{point.y}',
          },
        },
        bar: {
          dataLabels: {
            enabled: true,
            padding: 6,
            borderRadius: 5,
            backgroundColor: '#ffffff',
            color: '#000000',
            borderWidth: 0,
            borderColor: 'none',
            y: 0,
            x: -10,
          },
        },
        pie: {
          allowPointSelect: true,
          center: ['50%', '50%'],
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f} %',
            distance: -45,
            backgroundColor: '#ffffff',
            color: '#000000',
            style: {
              display: this.dashboard.title == 'InspectionScore' ? 'none' : '',
            },
          },
          showInLegend: true,
        },
        line: {
          dataLabels: {
            enabled: true,
          },
          marker: {
            symbol: '',
            color: '#26326E',
          },
          showInLegend: true,
          enableMouseTracking: true,
        },
      },
      xAxis: {
        type: 'datetime',
        gridLineColor: 'transparent',
        title: {
          text: '',
          style: {
            fontSize: '14px',
            color: '#6F6F6F',
          },
        },
      },
      exporting: {
        enabled: false,
      },
      yAxis: {
        min: 0,
        gridLineColor: 'transparent',
        tickInterval: 5,
        title: {
          text: '',
        },
      },
      series: this.dashboard.chartData[part],
      drilldown: {
        breadcrumbs: {
          style: {
            display: 'none',
          },
        },
        allowPointDrilldown: false,
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
        },
      },
    });
  }
  formatDate(date) {
    return moment(new Date(date)).format('dddd, MMMM Do YYYY');
  }
}
