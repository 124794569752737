import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { TaskTrackerService } from 'src/backend/services/task-tracker/task-tracker.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { Subject, takeUntil } from 'rxjs';
import * as moment from 'moment';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { UserService } from 'src/backend/services/user.service';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import { ChecklistType, TaskRepeated } from 'src/@hodhod/common/enum';
import {
  CreateInstanceParam,
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { Constants } from 'src/@hodhod/common/constants';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { BaseApi } from 'src/backend/api/base-api';
import { generateGuid } from 'src/@hodhod/common/custom_methods';
import { addDays } from 'date-fns';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-add-inspection-plan',
  templateUrl: './add-inspection-plan.component.html',
  styleUrls: ['./add-inspection-plan.component.scss'],
})
export class AddInspectionPlanComponent implements OnInit {
  inspectionplanForm: FormGroup;
  minDate = new Date();
  OldRepeatedDate = null;
  formMode: 'create' | 'update' = 'create';
  private destroy$ = new Subject();
  public translationsList: any = {};
  public DaysOfWeek = [
    { id: 'Sunday', name: 'Sunday' },
    { id: 'Monday', name: 'Monday' },
    { id: 'Tuesday', name: 'Tuesday' },
    { id: 'Wednesday', name: 'Wednesday' },
    { id: 'Thursday', name: 'Thursday' },
    { id: 'Friday', name: 'Friday' },
    { id: 'Saturday', name: 'Saturday' },
  ];
  public TaskRepeated = TaskRepeated;
  public Days;

  public Months = [
    { id: '1', name: 'January' },
    { id: '2', name: 'February' },
    { id: '3', name: 'March' },
    { id: '4', name: 'April' },
    { id: '5', name: 'May' },
    { id: '6', name: 'June' },
    { id: '7', name: 'July' },
    { id: '8', name: 'August' },
    { id: '9', name: 'September' },
    { id: '10', name: 'October' },
    { id: '11', name: 'November' },
    { id: '12', name: 'December' },
  ];
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  TaskRecurrence: any;
  checklists: any;

  departmentsData: any;
  subDepartments: any[] = [];
  reportUsers: any;
  machines: any;
  LoadingLabel: any;
  public Type = '';
  mintime: string;
  minRepeatedDate: any;
  workFlow: WorkFlow;
  companyKey: string = '';
  instanceKeys: string[] = [];
  tasks: any[] = [];
  loggedUser: LoggedUser;
  hasCreateAuditChecklistPermissions =
    ApplicationPermission.AUDIT_CHECKLIST_WRITE;

  hasCreateDepartmentPermissions = ApplicationPermission.DEPARTMENTS_CREATE;
  hasCreateUserPermissions = ApplicationPermission.USER_CREATE;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddInspectionPlanComponent>,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private TaskTrackerService: TaskTrackerService,
    private checklistService: ChecklistSectionService,
    private feedBackService: AsyncFeedbackService,
    private departmentService: DepartmentsService,
    private machineService: MachineEquipmentService,
    private userService: UserService,
    private workFlowService: WorkFlowService,
    private baseApi: BaseApi
  ) {
    this.translate
      .get(['Errors', 'Success', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.inspectionplanForm = this.fb.group({
      title: ['', [Validators.required, noWhitespaceValidator]],
      description: ['', [Validators.required, noWhitespaceValidator]],
      checklist: ['', [Validators.required]],
      department: ['', [Validators.required]],
      subDepartment: [''],
      machine: ['', [Validators.required]],
      user: ['', [Validators.required]],
      start: ['', [Validators.required]],
      DurationDays: [1],
      RecurrenceType: ['', [Validators.required]],
      Interval: [''],
      AbsoluteDays: [''],
      RecurrenceSubType: [''],
      DayOfMonth: [''],
      RecurrenceMonth: [''],
      RecurrenceWeek: [''],
      RepeatedEndDate: [''],
      durationType: ['Days', [Validators.required]],
      StartTime: [''],
      EndTime: [''],
    });
    this.getChecklistList();
    this.getDepartmentParentChild();
    this.getUsers();
    this.getMachines();
    this.SetValidations();
    this.Type = this.defaults.type;
    if (this.defaults.action == 'update') {
      this.formMode = 'update';
      this.EditTask();
    } else if (this.defaults.action == 'create') {
      this.isCreateMode();
      this.defaults.Id = 0;
    } else {
      this.EditTask();
    }
    this.OldRepeatedDate = null;
    this.getWorkFlow();
  }
  getCurrenttime(): void {
    const date = new Date();
    var n = date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
    this.mintime = n;
  }
  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.formateTasks();
          }
        });
    } else if (this.formMode === 'update') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['Role']['UpdateConfirm']['Title'],
            this.translationsList['Role']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.formateTasks();
          }
        });
    }
  }

  formateTasks(): void {
    let user = JSON.parse(localStorage.getItem('logged_in_user'));
    // this.sectionState = SectionStateStatus.LoadingTransparent;
    let TasksList = [];
    this.instanceKeys = [];
    const recurrenceType = this.inspectionplanForm.value.RecurrenceType;
    const stardDate = this.inspectionplanForm.value.start;
    const endDate = this.inspectionplanForm.value.RepeatedEndDate;
    const durationDays = this.inspectionplanForm.value.DurationDays;
    const Interval = this.inspectionplanForm.value.Interval;
    const durationType = this.inspectionplanForm.value.durationType;
    const recurrenceSubType = this.inspectionplanForm.value.RecurrenceSubType;
    const absoluteDays = this.inspectionplanForm.value.AbsoluteDays;
    const dayOfMonth = this.inspectionplanForm.value.DayOfMonth;
    const recurrenceWeek = this.inspectionplanForm.value.RecurrenceWeek;
    const recurrenceMonth = this.inspectionplanForm.value.RecurrenceMonth;
    if (recurrenceType == 'Daily') {
      //Days between Start Date and End Date
      let start = new Date(stardDate);
      let end = new Date(endDate);
      let count = 0;
      let loopDaily = new Date(start);
      while (loopDaily <= end) {
        count++;

        const instanceKey = generateGuid();
        this.instanceKeys.push(instanceKey);
        if (durationType == 'Days') {
          let DurationEnd = new Date(loopDaily);
          DurationEnd.setDate(loopDaily.getDate() + (Number(durationDays) - 1));
          const element = {
            Id: this.defaults.Id,
            title: this.inspectionplanForm.value.title,
            description: this.inspectionplanForm.value.description,
            checklist: this.inspectionplanForm.value.checklist,
            department: this.inspectionplanForm.value.subDepartment
              ? this.inspectionplanForm.value.subDepartment
              : this.inspectionplanForm.value.department,
            machine: this.inspectionplanForm.value.machine,
            user: this.inspectionplanForm.value.user,
            start: moment(new Date(this.inspectionplanForm.value.start)).format(
              'YYYY-MM-DDThh:mm:ssZ'
            ),
            DurationDays: this.inspectionplanForm.value.DurationDays,
            RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
            Interval: this.inspectionplanForm.value.Interval,
            AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
            RecurrenceSubType: this.inspectionplanForm.value.RecurrenceSubType,
            DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
            RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
            RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
            RepeatedEndDate: moment(
              new Date(this.inspectionplanForm.value.RepeatedEndDate)
            ).format('YYYY-MM-DDThh:mm:ssZ'),
            StartTime: null,
            EndTime: null,
            durationType: this.inspectionplanForm.value.durationType,
            createdBy: user.userId,
            instanceKey: instanceKey,
            startTaskDate: moment(new Date(loopDaily.toString())).format(
              'YYYY-MM-DDThh:mm:ssZ'
            ),
            TaskDate: moment(new Date(DurationEnd).toString()).format(
              'YYYY-MM-DDThh:mm:ssZ'
            ),
          };
          TasksList.push(element);
          let newDate = addDays(new Date(DurationEnd), Number(Interval));
          loopDaily = new Date(newDate);
        } else {
          const element = {
            Id: this.defaults.Id,
            title: this.inspectionplanForm.value.title,
            description: this.inspectionplanForm.value.description,
            checklist: this.inspectionplanForm.value.checklist,
            department: this.inspectionplanForm.value.subDepartment
              ? this.inspectionplanForm.value.subDepartment
              : this.inspectionplanForm.value.department,
            machine: this.inspectionplanForm.value.machine,
            user: this.inspectionplanForm.value.user,
            start: moment(new Date(this.inspectionplanForm.value.start)).format(
              'YYYY-MM-DDThh:mm:ssZ'
            ),
            DurationDays: null,
            RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
            Interval: this.inspectionplanForm.value.Interval,
            AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
            RecurrenceSubType: this.inspectionplanForm.value.RecurrenceSubType,
            DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
            RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
            RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
            RepeatedEndDate: moment(
              new Date(this.inspectionplanForm.value.RepeatedEndDate)
            ).format('YYYY-MM-DDThh:mm:ssZ'),
            StartTime: this.inspectionplanForm.value.StartTime,
            EndTime: this.inspectionplanForm.value.EndTime,
            durationType: this.inspectionplanForm.value.durationType,
            createdBy: user.userId,
            instanceKey: instanceKey,
            TaskDate: moment(new Date(loopDaily.toString())).format(
              'YYYY-MM-DDThh:mm:ssZ'
            ),
            startTaskDate: moment(new Date(loopDaily.toString())).format(
              'YYYY-MM-DDThh:mm:ssZ'
            ),
          };
          TasksList.push(element);
          let newDate = addDays(
            new Date(loopDaily.toString()),
            Number(Interval)
          );
          loopDaily = new Date(newDate);
        }
      }
    } else if (recurrenceType == 'Weekly') {
      let interval = 7 * Number(Interval);

      let start1 = moment(new Date(stardDate));
      let end1 = moment(new Date(endDate));
      let arrayOfDays = absoluteDays;
      //Loop Of Days string Start
      arrayOfDays.forEach((el) => {
        // Get "next" monday
        let tmp = start1.clone().day(this.GetDayNo(el));
        let count1 = 0;
        while (tmp.isBefore(end1)) {
          const instanceKey = generateGuid();

          this.instanceKeys.push(instanceKey);
          if (durationType == 'Days') {
            if (
              new Date(tmp.format('YYYY-MM-DD')) >=
              new Date(start1.format('YYYY-MM-DD'))
            ) {
              const element = {
                Id: this.defaults.Id,
                title: this.inspectionplanForm.value.title,
                description: this.inspectionplanForm.value.description,
                checklist: this.inspectionplanForm.value.checklist,
                department: this.inspectionplanForm.value.subDepartment
                  ? this.inspectionplanForm.value.subDepartment
                  : this.inspectionplanForm.value.department,
                machine: this.inspectionplanForm.value.machine,
                user: this.inspectionplanForm.value.user,
                start: moment(
                  new Date(this.inspectionplanForm.value.start)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                DurationDays: durationDays,
                RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                Interval: this.inspectionplanForm.value.Interval,
                AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                RecurrenceSubType:
                  this.inspectionplanForm.value.RecurrenceSubType,
                DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
                RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                RepeatedEndDate: moment(
                  new Date(this.inspectionplanForm.value.RepeatedEndDate)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                StartTime: this.inspectionplanForm.value.StartTime,
                EndTime: this.inspectionplanForm.value.EndTime,
                durationType: this.inspectionplanForm.value.durationType,
                createdBy: user.userId,
                instanceKey: instanceKey,
                TaskDate: moment(
                  new Date(
                    addDays(
                      new Date(tmp.format('YYYY-MM-DD')),
                      Number(durationDays) - 1
                    )
                  )
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                startTaskDate: moment(
                  new Date(tmp.format('YYYY-MM-DD'))
                ).format('YYYY-MM-DDThh:mm:ssZ'),
              };
              TasksList.push(element);
              count1++;
            }
            if (count1 > 0) {
              tmp.add(interval, 'days');
            } else {
              tmp.add(7, 'days');
            }
          } else {
            if (
              new Date(tmp.format('YYYY-MM-DD')) >=
              new Date(start1.format('YYYY-MM-DD'))
            ) {
              const element = {
                Id: this.defaults.Id,
                title: this.inspectionplanForm.value.title,
                description: this.inspectionplanForm.value.description,
                checklist: this.inspectionplanForm.value.checklist,
                department: this.inspectionplanForm.value.subDepartment
                  ? this.inspectionplanForm.value.subDepartment
                  : this.inspectionplanForm.value.department,
                machine: this.inspectionplanForm.value.machine,
                user: this.inspectionplanForm.value.user,
                start: moment(
                  new Date(this.inspectionplanForm.value.start)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                DurationDays: null,
                RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                Interval: this.inspectionplanForm.value.Interval,
                AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                RecurrenceSubType:
                  this.inspectionplanForm.value.RecurrenceSubType,
                DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
                RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                RepeatedEndDate: moment(
                  new Date(this.inspectionplanForm.value.RepeatedEndDate)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                StartTime: this.inspectionplanForm.value.StartTime,
                EndTime: this.inspectionplanForm.value.EndTime,
                durationType: this.inspectionplanForm.value.durationType,
                createdBy: user.userId,
                instanceKey: instanceKey,
                TaskDate: moment(new Date(tmp.format('YYYY-MM-DD'))).format(
                  'YYYY-MM-DDThh:mm:ssZ'
                ),
                startTaskDate: moment(
                  new Date(tmp.format('YYYY-MM-DD'))
                ).format('YYYY-MM-DDThh:mm:ssZ'),
              };
              TasksList.push(element);
              count1++;
            }
            if (count1 > 0) {
              tmp.add(interval, 'days');
            } else {
              tmp.add(7, 'days');
            }
          }
        }
      });
    } else if (recurrenceType == 'Monthly') {
      if (recurrenceSubType == 'Relative') {
        //Days between Start Date and End Date

        let startMonthly = new Date(stardDate);
        let end = new Date(endDate);

        let countRelativeMOnthly = 0;
        let loop = new Date(startMonthly);

        while (loop <= end) {
          let Date1 = moment(loop);
          let startOfMonth = moment(new Date(loop)).startOf('month');
          let dayDate = startOfMonth.add(Number(dayOfMonth) - 1, 'days');
          let DayDateFormated = dayDate.format('YYYY-MM-DD');
          const instanceKey = generateGuid();
          this.instanceKeys.push(instanceKey);
          if (
            new Date(DayDateFormated) >= new Date(startMonthly) &&
            new Date(DayDateFormated) <= new Date(end)
          ) {
            countRelativeMOnthly++;
            if (durationType == 'Days') {
              const element = {
                Id: this.defaults.Id,
                title: this.inspectionplanForm.value.title,
                description: this.inspectionplanForm.value.description,
                checklist: this.inspectionplanForm.value.checklist,
                department: this.inspectionplanForm.value.subDepartment
                  ? this.inspectionplanForm.value.subDepartment
                  : this.inspectionplanForm.value.department,
                machine: this.inspectionplanForm.value.machine,
                user: this.inspectionplanForm.value.user,
                start: moment(
                  new Date(this.inspectionplanForm.value.start)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                DurationDays: durationDays,
                RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                Interval: this.inspectionplanForm.value.Interval,
                AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                RecurrenceSubType:
                  this.inspectionplanForm.value.RecurrenceSubType,
                DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
                RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                RepeatedEndDate: moment(
                  new Date(this.inspectionplanForm.value.RepeatedEndDate)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                StartTime: this.inspectionplanForm.value.StartTime,
                EndTime: this.inspectionplanForm.value.EndTime,
                durationType: this.inspectionplanForm.value.durationType,
                createdBy: user.userId,
                instanceKey: instanceKey,
                TaskDate: moment(
                  addDays(new Date(DayDateFormated), durationDays - 1)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                startTaskDate: moment(new Date(DayDateFormated)).format(
                  'YYYY-MM-DDThh:mm:ssZ'
                ),
              };

              TasksList.push(element);
            } else {
              const element = {
                Id: this.defaults.Id,
                title: this.inspectionplanForm.value.title,
                description: this.inspectionplanForm.value.description,
                checklist: this.inspectionplanForm.value.checklist,
                department: this.inspectionplanForm.value.subDepartment
                  ? this.inspectionplanForm.value.subDepartment
                  : this.inspectionplanForm.value.department,
                machine: this.inspectionplanForm.value.machine,
                user: this.inspectionplanForm.value.user,
                start: moment(
                  new Date(this.inspectionplanForm.value.start)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                DurationDays: null,
                RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                Interval: this.inspectionplanForm.value.Interval,
                AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                RecurrenceSubType:
                  this.inspectionplanForm.value.RecurrenceSubType,
                DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
                RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                RepeatedEndDate: moment(
                  new Date(this.inspectionplanForm.value.RepeatedEndDate)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                StartTime: this.inspectionplanForm.value.StartTime,
                EndTime: this.inspectionplanForm.value.EndTime,
                durationType: this.inspectionplanForm.value.durationType,
                createdBy: user.userId,
                instanceKey: instanceKey,
                TaskDate: moment(new Date(DayDateFormated)).format(
                  'YYYY-MM-DDThh:mm:ssZ'
                ),
                startTaskDate: moment(new Date(DayDateFormated)).format(
                  'YYYY-MM-DDThh:mm:ssZ'
                ),
              };
              TasksList.push(element);
            }
          }
          if (countRelativeMOnthly > 0) {
            let dateString = Date1.add(Interval, 'M')
              .startOf('month')
              .toString();
            loop = new Date(dateString);
          } else {
            let dateString = Date1.add(1, 'M').startOf('month').toString();
            loop = new Date(dateString);
          }
        }
      } else {
        var interval = Number(Interval);
        var countAbsoliteMonthly = 0;
        var start1 = moment(new Date(stardDate));
        var end1 = moment(new Date(endDate));
        var arrayOfDays = absoluteDays;
        //Loop Of Days string Start
        arrayOfDays.forEach((el1) => {
          let tmp = start1.clone().day(this.GetDayNo(el1));

          while (tmp.isBefore(end1)) {
            const instanceKey = generateGuid();
            this.instanceKeys.push(instanceKey);
            // If condition for week no
            let week = this.weekOfMonth(tmp);
            if (week == Number(recurrenceWeek)) {
              countAbsoliteMonthly++;
              if (durationType == 'Days') {
                const element = {
                  Id: this.defaults.Id,
                  title: this.inspectionplanForm.value.title,
                  description: this.inspectionplanForm.value.description,
                  checklist: this.inspectionplanForm.value.checklist,
                  department: this.inspectionplanForm.value.subDepartment
                    ? this.inspectionplanForm.value.subDepartment
                    : this.inspectionplanForm.value.department,
                  machine: this.inspectionplanForm.value.machine,
                  user: this.inspectionplanForm.value.user,
                  start: moment(
                    new Date(this.inspectionplanForm.value.start)
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  DurationDays: durationDays,
                  RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                  Interval: this.inspectionplanForm.value.Interval,
                  AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                  RecurrenceSubType:
                    this.inspectionplanForm.value.RecurrenceSubType,
                  DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                  RecurrenceMonth:
                    this.inspectionplanForm.value.RecurrenceMonth,
                  RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                  RepeatedEndDate: moment(
                    new Date(this.inspectionplanForm.value.RepeatedEndDate)
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  StartTime: this.inspectionplanForm.value.StartTime,
                  EndTime: this.inspectionplanForm.value.EndTime,
                  durationType: this.inspectionplanForm.value.durationType,
                  createdBy: user.userId,
                  instanceKey: instanceKey,
                  TaskDate: moment(
                    addDays(
                      new Date(tmp.format('YYYY-MM-DD')),
                      durationDays - 1
                    )
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  startTaskDate: moment(
                    new Date(tmp.format('YYYY-MM-DD'))
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                };
                TasksList.push(element);
              } else {
                const element = {
                  Id: this.defaults.Id,
                  title: this.inspectionplanForm.value.title,
                  description: this.inspectionplanForm.value.description,
                  checklist: this.inspectionplanForm.value.checklist,
                  department: this.inspectionplanForm.value.subDepartment
                    ? this.inspectionplanForm.value.subDepartment
                    : this.inspectionplanForm.value.department,
                  machine: this.inspectionplanForm.value.machine,
                  user: this.inspectionplanForm.value.user,
                  start: moment(
                    new Date(this.inspectionplanForm.value.start)
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  DurationDays: durationDays,
                  RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                  Interval: this.inspectionplanForm.value.Interval,
                  AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                  RecurrenceSubType:
                    this.inspectionplanForm.value.RecurrenceSubType,
                  DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                  RecurrenceMonth:
                    this.inspectionplanForm.value.RecurrenceMonth,
                  RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                  RepeatedEndDate: moment(
                    new Date(this.inspectionplanForm.value.RepeatedEndDate)
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  StartTime: this.inspectionplanForm.value.StartTime,
                  EndTime: this.inspectionplanForm.value.EndTime,
                  durationType: this.inspectionplanForm.value.durationType,
                  createdBy: user.userId,
                  instanceKey: instanceKey,
                  TaskDate: moment(
                    addDays(
                      new Date(tmp.format('YYYY-MM-DD')),
                      durationDays - 1
                    )
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  startTaskDate: moment(
                    new Date(tmp.format('YYYY-MM-DD'))
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                };
                TasksList.push(element);
              }
              if (countAbsoliteMonthly > 0) {
                let newStart = tmp.add(interval, 'M').startOf('month');
                tmp = newStart.clone().day(this.GetDayNo(el1));
              } else {
                let newStart = tmp.add(1, 'M').startOf('month');
                tmp = newStart.clone().day(this.GetDayNo(el1));
              }
              continue;
            }
            tmp.add(7, 'days');
          }
        });
      }
    } else if (recurrenceType == 'Quaterly') {
      if (recurrenceSubType == 'Relative') {
        //Days between Start Date and End Date

        let startMonthly = new Date(stardDate);
        let end = new Date(endDate);

        let countRelativeQuaterly = 0;
        let loop = new Date(startMonthly);
        while (loop <= end) {
          let Date1 = moment(loop);
          let startOfMonth = moment(new Date(loop)).startOf('month');
          let dayDate = startOfMonth.add(Number(dayOfMonth) - 1, 'days');
          let DayDateFormated = dayDate.format('YYYY-MM-DD');
          const instanceKey = generateGuid();
          this.instanceKeys.push(instanceKey);
          if (
            new Date(DayDateFormated) >= new Date(startMonthly) &&
            new Date(DayDateFormated) <= new Date(end)
          ) {
            countRelativeQuaterly++;
            if (durationType == 'Days') {
              const element = {
                Id: this.defaults.Id,
                title: this.inspectionplanForm.value.title,
                description: this.inspectionplanForm.value.description,
                checklist: this.inspectionplanForm.value.checklist,
                department: this.inspectionplanForm.value.subDepartment
                  ? this.inspectionplanForm.value.subDepartment
                  : this.inspectionplanForm.value.department,
                machine: this.inspectionplanForm.value.machine,
                user: this.inspectionplanForm.value.user,
                start: moment(
                  new Date(this.inspectionplanForm.value.start)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                DurationDays: durationDays,
                RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                Interval: this.inspectionplanForm.value.Interval,
                AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                RecurrenceSubType:
                  this.inspectionplanForm.value.RecurrenceSubType,
                DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
                RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                RepeatedEndDate: moment(
                  new Date(this.inspectionplanForm.value.RepeatedEndDate)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                StartTime: this.inspectionplanForm.value.StartTime,
                EndTime: this.inspectionplanForm.value.EndTime,
                durationType: this.inspectionplanForm.value.durationType,
                createdBy: user.userId,
                instanceKey: instanceKey,
                TaskDate: moment(
                  addDays(new Date(DayDateFormated), durationDays - 1)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                startTaskDate: moment(new Date(DayDateFormated)).format(
                  'YYYY-MM-DDThh:mm:ssZ'
                ),
              };
              TasksList.push(element);
            } else {
              const element = {
                Id: this.defaults.Id,
                title: this.inspectionplanForm.value.title,
                description: this.inspectionplanForm.value.description,
                checklist: this.inspectionplanForm.value.checklist,
                department: this.inspectionplanForm.value.subDepartment
                  ? this.inspectionplanForm.value.subDepartment
                  : this.inspectionplanForm.value.department,
                machine: this.inspectionplanForm.value.machine,
                user: this.inspectionplanForm.value.user,
                start: moment(
                  new Date(this.inspectionplanForm.value.start)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                DurationDays: null,
                RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                Interval: this.inspectionplanForm.value.Interval,
                AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                RecurrenceSubType:
                  this.inspectionplanForm.value.RecurrenceSubType,
                DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
                RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                RepeatedEndDate: moment(
                  new Date(this.inspectionplanForm.value.RepeatedEndDate)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                StartTime: this.inspectionplanForm.value.StartTime,
                EndTime: this.inspectionplanForm.value.EndTime,
                durationType: this.inspectionplanForm.value.durationType,
                createdBy: user.userId,
                instanceKey: instanceKey,
                TaskDate: moment(new Date(DayDateFormated)).format(
                  'YYYY-MM-DDThh:mm:ssZ'
                ),
                startTaskDate: moment(new Date(DayDateFormated)).format(
                  'YYYY-MM-DDThh:mm:ssZ'
                ),
              };
              TasksList.push(element);
            }
          }
          if (countRelativeQuaterly > 0) {
            var dateString = Date1.add(Number(Interval) * 4, 'M')
              .startOf('month')
              .toString();
          } else {
            var dateString = Date1.add(1, 'M').startOf('month').toString();
          }
          loop = new Date(dateString);
        }
      } else {
        let interval = Number(Interval) * 4;

        let start1 = moment(new Date(stardDate));
        let end1 = moment(new Date(endDate));
        let arrayOfDays = absoluteDays;
        //Loop Of Days string Start
        arrayOfDays.forEach((el1) => {
          let tmp = start1.clone().day(this.GetDayNo(el1));

          while (tmp.isBefore(end1)) {
            const instanceKey = generateGuid();
            this.instanceKeys.push(instanceKey);
            // If condition for week no
            let week = this.weekOfMonth(tmp);
            if (week == Number(recurrenceWeek)) {
              if (durationType == 'Days') {
                const element = {
                  Id: this.defaults.Id,
                  title: this.inspectionplanForm.value.title,
                  description: this.inspectionplanForm.value.description,
                  checklist: this.inspectionplanForm.value.checklist,
                  department: this.inspectionplanForm.value.subDepartment
                    ? this.inspectionplanForm.value.subDepartment
                    : this.inspectionplanForm.value.department,
                  machine: this.inspectionplanForm.value.machine,
                  user: this.inspectionplanForm.value.user,
                  start: moment(
                    new Date(this.inspectionplanForm.value.start)
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  DurationDays: durationDays,
                  RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                  Interval: this.inspectionplanForm.value.Interval,
                  AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                  RecurrenceSubType:
                    this.inspectionplanForm.value.RecurrenceSubType,
                  DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                  RecurrenceMonth:
                    this.inspectionplanForm.value.RecurrenceMonth,
                  RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                  RepeatedEndDate: moment(
                    new Date(this.inspectionplanForm.value.RepeatedEndDate)
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  StartTime: this.inspectionplanForm.value.StartTime,
                  EndTime: this.inspectionplanForm.value.EndTime,
                  durationType: this.inspectionplanForm.value.durationType,
                  createdBy: user.userId,
                  instanceKey: instanceKey,
                  TaskDate: moment(
                    addDays(
                      new Date(tmp.format('YYYY-MM-DD')),
                      durationDays - 1
                    )
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  startTaskDate: moment(
                    new Date(tmp.format('YYYY-MM-DD'))
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                };
                TasksList.push(element);
              } else {
                const element = {
                  Id: this.defaults.Id,
                  title: this.inspectionplanForm.value.title,
                  description: this.inspectionplanForm.value.description,
                  checklist: this.inspectionplanForm.value.checklist,
                  department: this.inspectionplanForm.value.subDepartment
                    ? this.inspectionplanForm.value.subDepartment
                    : this.inspectionplanForm.value.department,
                  machine: this.inspectionplanForm.value.machine,
                  user: this.inspectionplanForm.value.user,
                  start: moment(
                    new Date(this.inspectionplanForm.value.start)
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  DurationDays: null,
                  RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                  Interval: this.inspectionplanForm.value.Interval,
                  AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                  RecurrenceSubType:
                    this.inspectionplanForm.value.RecurrenceSubType,
                  DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                  RecurrenceMonth:
                    this.inspectionplanForm.value.RecurrenceMonth,
                  RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                  RepeatedEndDate: moment(
                    new Date(this.inspectionplanForm.value.RepeatedEndDate)
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                  StartTime: this.inspectionplanForm.value.StartTime,
                  EndTime: this.inspectionplanForm.value.EndTime,
                  durationType: this.inspectionplanForm.value.durationType,
                  createdBy: user.userId,
                  instanceKey: instanceKey,
                  TaskDate: moment(new Date(tmp.format('YYYY-MM-DD'))).format(
                    'YYYY-MM-DDThh:mm:ssZ'
                  ),
                  startTaskDate: moment(
                    new Date(tmp.format('YYYY-MM-DD'))
                  ).format('YYYY-MM-DDThh:mm:ssZ'),
                };
                TasksList.push(element);
              }
              let newStart = tmp.add(interval, 'M').startOf('month');
              tmp = newStart.clone().day(this.GetDayNo(el1));
              continue;
            }

            tmp.add(7, 'days');
          }
        });
      }
    } else if (recurrenceType == 'Yearly') {
      if (recurrenceSubType == 'Relative') {
        //Days between Start Date and End Date

        let start1 = moment(new Date(stardDate));
        let end1 = moment(new Date(endDate));

        // Get "next" monday
        let startOfYear = start1.startOf('year');
        let MonthOfYear = startOfYear.add(recurrenceMonth - 1, 'M');
        let DayOfMonth = MonthOfYear.startOf('month').add(
          dayOfMonth - 1,
          'days'
        );

        while (DayOfMonth.isBefore(end1)) {
          if (DayOfMonth.isBefore(end1) && DayOfMonth.isAfter(start1)) {
            const instanceKey = generateGuid();
            this.instanceKeys.push(instanceKey);
            if (durationType == 'Days') {
              const element = {
                Id: this.defaults.Id,
                title: this.inspectionplanForm.value.title,
                description: this.inspectionplanForm.value.description,
                checklist: this.inspectionplanForm.value.checklist,
                department: this.inspectionplanForm.value.subDepartment
                  ? this.inspectionplanForm.value.subDepartment
                  : this.inspectionplanForm.value.department,
                machine: this.inspectionplanForm.value.machine,
                user: this.inspectionplanForm.value.user,
                start: moment(
                  new Date(this.inspectionplanForm.value.start)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                DurationDays: durationDays,
                RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                Interval: this.inspectionplanForm.value.Interval,
                AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                RecurrenceSubType:
                  this.inspectionplanForm.value.RecurrenceSubType,
                DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
                RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                RepeatedEndDate: moment(
                  new Date(this.inspectionplanForm.value.RepeatedEndDate)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                StartTime: this.inspectionplanForm.value.StartTime,
                EndTime: this.inspectionplanForm.value.EndTime,
                durationType: this.inspectionplanForm.value.durationType,
                createdBy: user.userId,
                instanceKey: instanceKey,
                TaskDate: moment(
                  addDays(
                    new Date(DayOfMonth.format('YYYY-MM-DD')),
                    durationDays - 1
                  )
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                startTaskDate: moment(
                  new Date(DayOfMonth.format('YYYY-MM-DD'))
                ).format('YYYY-MM-DDThh:mm:ssZ'),
              };
              TasksList.push(element);
            } else {
              const element = {
                Id: this.defaults.Id,
                title: this.inspectionplanForm.value.title,
                description: this.inspectionplanForm.value.description,
                checklist: this.inspectionplanForm.value.checklist,
                department: this.inspectionplanForm.value.subDepartment
                  ? this.inspectionplanForm.value.subDepartment
                  : this.inspectionplanForm.value.department,
                machine: this.inspectionplanForm.value.machine,
                user: this.inspectionplanForm.value.user,
                start: moment(
                  new Date(this.inspectionplanForm.value.start)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                DurationDays: null,
                RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
                Interval: this.inspectionplanForm.value.Interval,
                AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                RecurrenceSubType:
                  this.inspectionplanForm.value.RecurrenceSubType,
                DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
                RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
                RepeatedEndDate: moment(
                  new Date(this.inspectionplanForm.value.RepeatedEndDate)
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                StartTime: this.inspectionplanForm.value.StartTime,
                EndTime: this.inspectionplanForm.value.EndTime,
                durationType: this.inspectionplanForm.value.durationType,
                createdBy: user.userId,
                instanceKey: instanceKey,
                TaskDate: moment(
                  new Date(DayOfMonth.format('YYYY-MM-DD'))
                ).format('YYYY-MM-DDThh:mm:ssZ'),
                startTaskDate: moment(
                  new Date(DayOfMonth.format('YYYY-MM-DD'))
                ).format('YYYY-MM-DDThh:mm:ssZ'),
              };
              TasksList.push(element);
            }
          }
          let dateString = DayOfMonth.toString();
          DayOfMonth = moment(new Date(dateString)).add(Interval, 'y');
        }
      } else {
        let start1 = moment(new Date(stardDate));
        let end1 = moment(new Date(endDate));
        let arrayOfDays = absoluteDays;
        //Loop Of Days string Start
        arrayOfDays.forEach((el6) => {
          let arr = [];
          let countweek = 0;
          let tmp1 = start1.clone().day(this.GetDayNo(el6));

          while (tmp1.isBefore(end1)) {
            let tmpFormatMonth = tmp1.format('M');
            if (tmpFormatMonth == recurrenceMonth) {
              // If condition for week no
              if (countweek == 0) {
                let tempDateForMonthStart = tmp1.format('YYYY-MM-DD');
                let tempDateForMonthStart1 = moment(
                  tempDateForMonthStart
                ).startOf('month');
                var week = this.weekOfMonth(tempDateForMonthStart1);
                countweek++;
              }
              var week = this.weekOfMonth(tmp1);
              if (week == recurrenceWeek) {
                arr.push(tmp1.format('YYYY-MM-DD'));
                const instanceKey = generateGuid();
                this.instanceKeys.push(instanceKey);
                if (durationType == 'Days') {
                  const element = {
                    Id: this.defaults.Id,
                    title: this.inspectionplanForm.value.title,
                    description: this.inspectionplanForm.value.description,
                    checklist: this.inspectionplanForm.value.checklist,
                    department: this.inspectionplanForm.value.subDepartment
                      ? this.inspectionplanForm.value.subDepartment
                      : this.inspectionplanForm.value.department,
                    machine: this.inspectionplanForm.value.machine,
                    user: this.inspectionplanForm.value.user,
                    start: moment(
                      new Date(this.inspectionplanForm.value.start)
                    ).format('YYYY-MM-DDThh:mm:ssZ'),
                    DurationDays: durationDays,
                    RecurrenceType:
                      this.inspectionplanForm.value.RecurrenceType,
                    Interval: this.inspectionplanForm.value.Interval,
                    AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                    RecurrenceSubType:
                      this.inspectionplanForm.value.RecurrenceSubType,
                    DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                    RecurrenceMonth:
                      this.inspectionplanForm.value.RecurrenceMonth,
                    RecurrenceWeek:
                      this.inspectionplanForm.value.RecurrenceWeek,
                    RepeatedEndDate: moment(
                      new Date(this.inspectionplanForm.value.RepeatedEndDate)
                    ).format('YYYY-MM-DDThh:mm:ssZ'),
                    StartTime: this.inspectionplanForm.value.StartTime,
                    EndTime: this.inspectionplanForm.value.EndTime,
                    durationType: this.inspectionplanForm.value.durationType,
                    createdBy: user.userId,
                    instanceKey: instanceKey,
                    TaskDate: moment(
                      new Date(tmp1.format('YYYY-MM-DD'))
                    ).format('YYYY-MM-DDThh:mm:ssZ'),
                    starttTaskDate: moment(
                      new Date(tmp1.format('YYYY-MM-DD'))
                    ).format('YYYY-MM-DDThh:mm:ssZ'),
                  };
                  TasksList.push(element);
                  let newStart = tmp1.add(Interval, 'y').startOf('year');
                  tmp1 = newStart.clone().day(this.GetDayNo(el6));
                  countweek = 0;
                  continue;
                } else {
                  const element = {
                    Id: this.defaults.Id,
                    title: this.inspectionplanForm.value.title,
                    description: this.inspectionplanForm.value.description,
                    checklist: this.inspectionplanForm.value.checklist,
                    department: this.inspectionplanForm.value.subDepartment
                      ? this.inspectionplanForm.value.subDepartment
                      : this.inspectionplanForm.value.department,
                    machine: this.inspectionplanForm.value.machine,
                    user: this.inspectionplanForm.value.user,
                    start: moment(
                      new Date(this.inspectionplanForm.value.start)
                    ).format('YYYY-MM-DDThh:mm:ssZ'),
                    DurationDays: null,
                    RecurrenceType:
                      this.inspectionplanForm.value.RecurrenceType,
                    Interval: this.inspectionplanForm.value.Interval,
                    AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
                    RecurrenceSubType:
                      this.inspectionplanForm.value.RecurrenceSubType,
                    DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
                    RecurrenceMonth:
                      this.inspectionplanForm.value.RecurrenceMonth,
                    RecurrenceWeek:
                      this.inspectionplanForm.value.RecurrenceWeek,
                    RepeatedEndDate: moment(
                      new Date(this.inspectionplanForm.value.RepeatedEndDate)
                    ).format('YYYY-MM-DDThh:mm:ssZ'),
                    StartTime: this.inspectionplanForm.value.StartTime,
                    EndTime: this.inspectionplanForm.value.EndTime,
                    durationType: this.inspectionplanForm.value.durationType,
                    createdBy: user.userId,
                    instanceKey: instanceKey,
                    TaskDate: moment(
                      new Date(tmp1.format('YYYY-MM-DD'))
                    ).format('YYYY-MM-DDThh:mm:ssZ'),
                    startTaskDate: moment(
                      new Date(tmp1.format('YYYY-MM-DD'))
                    ).format('YYYY-MM-DDThh:mm:ssZ'),
                  };
                  TasksList.push(element);
                  let newStart = tmp1.add(Interval, 'y').startOf('year');
                  tmp1 = newStart.clone().day(this.GetDayNo(el6));
                  countweek = 0;
                  continue;
                }
              }
              tmp1.add(7, 'days');
            } else {
              let newDate = tmp1.add(1, 'M').toString();
              let newDateStart = moment(new Date(newDate))
                .startOf('month')
                .toString();
              let valueOfDate = new Date(newDateStart);

              let value = valueOfDate.setDate(
                valueOfDate.getDate() +
                  ((((7 - valueOfDate.getDay()) % 7) + this.GetDayNo(el6)) % 7)
              );
              tmp1 = moment(value);
            }
          }
        });
      }
    } else {
      const instanceKey = generateGuid();
      this.instanceKeys.push(instanceKey);
      if (durationType == 'Days') {
        const element = {
          Id: this.defaults.Id,
          title: this.inspectionplanForm.value.title,
          description: this.inspectionplanForm.value.description,
          checklist: this.inspectionplanForm.value.checklist,
          department: this.inspectionplanForm.value.subDepartment
            ? this.inspectionplanForm.value.subDepartment
            : this.inspectionplanForm.value.department,
          machine: this.inspectionplanForm.value.machine,
          user: this.inspectionplanForm.value.user,
          start: moment(new Date(this.inspectionplanForm.value.start)).format(
            'YYYY-MM-DDThh:mm:ssZ'
          ),
          DurationDays: durationDays,
          RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
          Interval: this.inspectionplanForm.value.Interval,
          AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
          RecurrenceSubType: this.inspectionplanForm.value.RecurrenceSubType,
          DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
          RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
          RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
          RepeatedEndDate: moment(
            new Date(this.inspectionplanForm.value.RepeatedEndDate)
          ).format('YYYY-MM-DDThh:mm:ssZ'),
          StartTime: this.inspectionplanForm.value.StartTime,
          EndTime: this.inspectionplanForm.value.EndTime,
          durationType: this.inspectionplanForm.value.durationType,
          createdBy: user.userId,
          instanceKey: instanceKey,
          TaskDate: moment(
            addDays(new Date(stardDate), durationDays - 1)
          ).format('YYYY-MM-DDThh:mm:ssZ'),
          startTaskDate: moment(new Date(stardDate)).format(
            'YYYY-MM-DDThh:mm:ssZ'
          ),
        };
        TasksList.push(element);
      } else {
        const element = {
          Id: this.defaults.Id,
          title: this.inspectionplanForm.value.title,
          description: this.inspectionplanForm.value.description,
          checklist: this.inspectionplanForm.value.checklist,
          department: this.inspectionplanForm.value.subDepartment
            ? this.inspectionplanForm.value.subDepartment
            : this.inspectionplanForm.value.department,
          machine: this.inspectionplanForm.value.machine,
          user: this.inspectionplanForm.value.user,
          start: moment(new Date(this.inspectionplanForm.value.start)).format(
            'YYYY-MM-DDThh:mm:ssZ'
          ),
          DurationDays: null,
          RecurrenceType: this.inspectionplanForm.value.RecurrenceType,
          Interval: this.inspectionplanForm.value.Interval,
          AbsoluteDays: this.inspectionplanForm.value.AbsoluteDays,
          RecurrenceSubType: this.inspectionplanForm.value.RecurrenceSubType,
          DayOfMonth: this.inspectionplanForm.value.DayOfMonth,
          RecurrenceMonth: this.inspectionplanForm.value.RecurrenceMonth,
          RecurrenceWeek: this.inspectionplanForm.value.RecurrenceWeek,
          RepeatedEndDate: moment(
            new Date(this.inspectionplanForm.value.RepeatedEndDate)
          ).format('YYYY-MM-DDThh:mm:ssZ'),
          StartTime: this.inspectionplanForm.value.StartTime,
          EndTime: this.inspectionplanForm.value.EndTime,
          durationType: this.inspectionplanForm.value.durationType,
          createdBy: user.userId,
          instanceKey: instanceKey,
          TaskDate: moment(new Date(stardDate)).format('YYYY-MM-DDThh:mm:ssZ'),
          startTaskDate: moment(new Date(stardDate)).format(
            'YYYY-MM-DDThh:mm:ssZ'
          ),
        };
        TasksList.push(element);
      }
    }
    this.tasks = TasksList;
    const instanceKeysTask = this.tasks.map((x) => x.instanceKey);
    const workflowInstanceKeys = instanceKeysTask.map((x) => {
      return this.instanceKeys.find((y) => y == x);
    });
    this.instanceKeys = workflowInstanceKeys;

    this.createWorkFlowInstance();
  }

  createTask(): void {
    this.TaskTrackerService.PostNewTask(this.tasks).subscribe({
      next: (response: any) => {
        this.dialogRef.close();
        this.updateBulkWorkFlowInstance(response?.data);
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['SuccessMessage']
          )
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
        this.deleteWorkFlowInstance();
      },
    });
  }

  updateSeriesTask() {
    this.LoadingLabel = 'Updating Task...';
    var user = JSON.parse(localStorage.getItem('logged_in_user'));
    this.sectionState = SectionStateStatus.LoadingTransparent;
    const key = this.tasks;
    this.TaskTrackerService.updateSeriesTask(key).subscribe({
      next: () => {
        this.dialogRef.close();
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['SuccessMessage']
          )
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  isCreateMode() {
    return this.formMode === 'create';
  }
  isUpdateMode() {
    return this.formMode === 'update';
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  resetRecurrenceFields(): void {
    this.inspectionplanForm.get('Interval').setValue(null);
    this.inspectionplanForm.get('AbsoluteDays').setValue(null);
    this.inspectionplanForm.get('RecurrenceSubType').setValue(null);
    this.inspectionplanForm.get('DayOfMonth').setValue(null);
    this.inspectionplanForm.get('RecurrenceMonth').setValue(null);
    this.inspectionplanForm.get('RecurrenceWeek').setValue(null);
    this.inspectionplanForm.get('RepeatedEndDate').setValue(null);
    this.SetValidations();
    this.OldRepeatedDate = null;
    this.ValidateDateRange();
  }
  getChecklistList() {
    this.checklistService
      .getAllCheckListForDropdown(ChecklistType.Audit)
      .subscribe((res) => {
        this.checklists = res;
      });
  }
  getDepartmentParentChild(): any {
    this.departmentService.getDepartmentsParentChild().subscribe({
      next: (res) => {
        this.departmentsData = res;
        if (
          this.departmentsData.length == 1 &&
          this.departmentsData[0].parentName == '---Parent Department---' &&
          this.departmentsData[0].detail == null
        ) {
          this.departmentsData = [];
        }
      },
    });
  }
  getUsers(): void {
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.reportUsers = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getMachines(): void {
    this.machineService.getMachinesByCompany().subscribe({
      next: (response) => {
        this.machines = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  EditTask(): void {
    const key = {
      Id: this.defaults.Id,
    };
    this.LoadingLabel = 'Loading...';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.TaskTrackerService.GetTaskRecurrenceById(key).subscribe({
      next: (response) => {
        this.inspectionplanForm.get('title').setValue(response.title),
          this.inspectionplanForm
            .get('description')
            .setValue(response.description);
        this.inspectionplanForm.get('checklist').setValue(response.checkListId);
        this.inspectionplanForm.get('department').setValue(response.department);
        this.inspectionplanForm.get('machine').setValue(response.machine);
        this.inspectionplanForm.get('user').setValue(response.userId);
        this.inspectionplanForm.get('start').setValue(response.startDate);
        this.inspectionplanForm
          .get('DurationDays')
          .setValue(response.durationDays);
        this.inspectionplanForm
          .get('RecurrenceType')
          .setValue(response.recurrenceType);
        this.inspectionplanForm.get('Interval').setValue(response.interval);

        this.inspectionplanForm
          .get('AbsoluteDays')
          .setValue(
            response.absoluteDays != null
              ? response.absoluteDays.split(',')
              : null
          );
        this.inspectionplanForm
          .get('RecurrenceSubType')
          .setValue(response.recurrenceSubType);
        this.inspectionplanForm.get('DayOfMonth').setValue(response.dayOfMonth);
        this.inspectionplanForm
          .get('RecurrenceMonth')
          .setValue(response.recurrenceMonth);
        this.inspectionplanForm
          .get('RecurrenceWeek')
          .setValue(response.recurrenceWeek);
        this.inspectionplanForm
          .get('RepeatedEndDate')
          .setValue(
            response.repeatedEndDate != null ? response.repeatedEndDate : null
          );
        this.minDate = new Date(response.startDate);
        var n = response.startTime;
        this.OldRepeatedDate = response.repeatedEndDate;
        this.ValidateDateRange();
        this.mintime = n;
        this.inspectionplanForm.get('StartTime').setValue(response.startTime);
        this.inspectionplanForm.get('EndTime').setValue(response.endTime);
        this.inspectionplanForm
          .get('durationType')
          .setValue(response.durationType);

        if (response.departmentParentId) {
          this.inspectionplanForm
            .get('department')
            .setValue(response.departmentParentId);
          this.onChangeDepartment({ value: response.departmentParentId });
          this.inspectionplanForm
            .get('subDepartment')
            .setValue(response.department);
        } else {
          this.onChangeDepartment({ value: response.department });
          this.inspectionplanForm
            .get('department')
            .setValue(response.department);
        }

        this.SetValidations();
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  SetValidations(): any {
    // Adding Validations for Duration Types
    if (this.inspectionplanForm.value.durationType == 'Time') {
      this.inspectionplanForm.controls['StartTime'].setValidators([
        Validators.required,
      ]);
      this.inspectionplanForm.controls['StartTime'].updateValueAndValidity();
      this.inspectionplanForm.controls['EndTime'].setValidators([
        Validators.required,
      ]);
      this.inspectionplanForm.controls['EndTime'].updateValueAndValidity();
      this.inspectionplanForm.controls['DurationDays'].clearValidators();
      this.inspectionplanForm.controls['DurationDays'].updateValueAndValidity();
    } else {
      this.inspectionplanForm.controls['StartTime'].clearValidators();
      this.inspectionplanForm.controls['StartTime'].updateValueAndValidity();
      this.inspectionplanForm.controls['EndTime'].clearValidators();
      this.inspectionplanForm.controls['EndTime'].updateValueAndValidity();
      this.inspectionplanForm.controls['DurationDays'].setValidators([
        Validators.required,
      ]);
      this.inspectionplanForm.controls['DurationDays'].updateValueAndValidity();
    }
    //Adding Validations For different Reccurrence Types

    if (this.inspectionplanForm.value.RecurrenceType == 'NotRepeated') {
      this.inspectionplanForm.controls['Interval'].clearValidators();
      this.inspectionplanForm.controls['Interval'].updateValueAndValidity();
      this.inspectionplanForm.controls['AbsoluteDays'].clearValidators();
      this.inspectionplanForm.controls['AbsoluteDays'].updateValueAndValidity();
      this.inspectionplanForm.controls['RecurrenceSubType'].clearValidators();
      this.inspectionplanForm.controls[
        'RecurrenceSubType'
      ].updateValueAndValidity();
      this.inspectionplanForm.controls['DayOfMonth'].clearValidators();
      this.inspectionplanForm.controls['DayOfMonth'].updateValueAndValidity();
      this.inspectionplanForm.controls['RecurrenceMonth'].clearValidators();
      this.inspectionplanForm.controls[
        'RecurrenceMonth'
      ].updateValueAndValidity();
      this.inspectionplanForm.controls['RecurrenceWeek'].clearValidators();
      this.inspectionplanForm.controls[
        'RecurrenceWeek'
      ].updateValueAndValidity();
      this.inspectionplanForm.controls['RepeatedEndDate'].clearValidators();
      this.inspectionplanForm.controls[
        'RepeatedEndDate'
      ].updateValueAndValidity();
    } else {
      // Interval & Repeated End Date used in all scenerios
      this.inspectionplanForm.controls['Interval'].setValidators([
        Validators.required,
      ]);
      this.inspectionplanForm.controls['Interval'].updateValueAndValidity();
      this.inspectionplanForm.controls['RepeatedEndDate'].setValidators([
        Validators.required,
      ]);
      this.inspectionplanForm.controls[
        'RepeatedEndDate'
      ].updateValueAndValidity();

      if (this.inspectionplanForm.value.RecurrenceType == 'Daily') {
        this.inspectionplanForm.controls['AbsoluteDays'].clearValidators();
        this.inspectionplanForm.controls[
          'AbsoluteDays'
        ].updateValueAndValidity();
        this.inspectionplanForm.controls['RecurrenceSubType'].clearValidators();
        this.inspectionplanForm.controls[
          'RecurrenceSubType'
        ].updateValueAndValidity();
        this.inspectionplanForm.controls['DayOfMonth'].clearValidators();
        this.inspectionplanForm.controls['DayOfMonth'].updateValueAndValidity();
        this.inspectionplanForm.controls['RecurrenceMonth'].clearValidators();
        this.inspectionplanForm.controls[
          'RecurrenceMonth'
        ].updateValueAndValidity();
        this.inspectionplanForm.controls['RecurrenceWeek'].clearValidators();
        this.inspectionplanForm.controls[
          'RecurrenceWeek'
        ].updateValueAndValidity();
      } else if (this.inspectionplanForm.value.RecurrenceType == 'Weekly') {
        this.inspectionplanForm.controls['AbsoluteDays'].setValidators([
          Validators.required,
        ]);
        this.inspectionplanForm.controls[
          'AbsoluteDays'
        ].updateValueAndValidity();
        this.inspectionplanForm.controls['RecurrenceSubType'].clearValidators();
        this.inspectionplanForm.controls[
          'RecurrenceSubType'
        ].updateValueAndValidity();
        this.inspectionplanForm.controls['DayOfMonth'].clearValidators();
        this.inspectionplanForm.controls['DayOfMonth'].updateValueAndValidity();
        this.inspectionplanForm.controls['RecurrenceMonth'].clearValidators();
        this.inspectionplanForm.controls[
          'RecurrenceMonth'
        ].updateValueAndValidity();
        this.inspectionplanForm.controls['RecurrenceWeek'].clearValidators();
        this.inspectionplanForm.controls[
          'RecurrenceWeek'
        ].updateValueAndValidity();
      } else if (
        this.inspectionplanForm.value.RecurrenceType == 'Monthly' ||
        this.inspectionplanForm.value.RecurrenceType == 'Quaterly'
      ) {
        this.inspectionplanForm.controls['RecurrenceSubType'].setValidators([
          Validators.required,
        ]);
        this.inspectionplanForm.controls[
          'RecurrenceSubType'
        ].updateValueAndValidity();

        if (this.inspectionplanForm.value.RecurrenceSubType == 'Relative') {
          this.inspectionplanForm.controls['DayOfMonth'].setValidators([
            Validators.required,
          ]);
          this.inspectionplanForm.controls[
            'DayOfMonth'
          ].updateValueAndValidity();
          this.inspectionplanForm.controls['RecurrenceMonth'].clearValidators();
          this.inspectionplanForm.controls[
            'RecurrenceMonth'
          ].updateValueAndValidity();
          this.inspectionplanForm.controls['RecurrenceWeek'].clearValidators();
          this.inspectionplanForm.controls[
            'RecurrenceWeek'
          ].updateValueAndValidity();

          this.inspectionplanForm.controls['AbsoluteDays'].clearValidators();
          this.inspectionplanForm.controls[
            'AbsoluteDays'
          ].updateValueAndValidity();
        } else {
          this.inspectionplanForm.controls['AbsoluteDays'].setValidators([
            Validators.required,
          ]);
          this.inspectionplanForm.controls[
            'AbsoluteDays'
          ].updateValueAndValidity();
          this.inspectionplanForm.controls['RecurrenceWeek'].setValidators([
            Validators.required,
          ]);
          this.inspectionplanForm.controls[
            'RecurrenceWeek'
          ].updateValueAndValidity();

          this.inspectionplanForm.controls['DayOfMonth'].clearValidators();
          this.inspectionplanForm.controls[
            'DayOfMonth'
          ].updateValueAndValidity();
          this.inspectionplanForm.controls['RecurrenceMonth'].clearValidators();
          this.inspectionplanForm.controls[
            'RecurrenceMonth'
          ].updateValueAndValidity();
        }
      } else if (this.inspectionplanForm.value.RecurrenceType == 'Yearly') {
        this.inspectionplanForm.controls['RecurrenceSubType'].setValidators([
          Validators.required,
        ]);
        this.inspectionplanForm.controls[
          'RecurrenceSubType'
        ].updateValueAndValidity();

        if (this.inspectionplanForm.value.RecurrenceSubType == 'Relative') {
          this.inspectionplanForm.controls['DayOfMonth'].setValidators([
            Validators.required,
          ]);
          this.inspectionplanForm.controls[
            'DayOfMonth'
          ].updateValueAndValidity();
          this.inspectionplanForm.controls['RecurrenceMonth'].setValidators([
            Validators.required,
          ]);
          this.inspectionplanForm.controls[
            'RecurrenceMonth'
          ].updateValueAndValidity();

          this.inspectionplanForm.controls['AbsoluteDays'].clearValidators();
          this.inspectionplanForm.controls[
            'AbsoluteDays'
          ].updateValueAndValidity();

          this.inspectionplanForm.controls['RecurrenceWeek'].clearValidators();
          this.inspectionplanForm.controls[
            'RecurrenceWeek'
          ].updateValueAndValidity();
        } else {
          this.inspectionplanForm.controls['RecurrenceMonth'].setValidators([
            Validators.required,
          ]);
          this.inspectionplanForm.controls[
            'RecurrenceMonth'
          ].updateValueAndValidity();

          this.inspectionplanForm.controls['AbsoluteDays'].setValidators([
            Validators.required,
          ]);
          this.inspectionplanForm.controls[
            'AbsoluteDays'
          ].updateValueAndValidity();

          this.inspectionplanForm.controls['RecurrenceWeek'].setValidators([
            Validators.required,
          ]);
          this.inspectionplanForm.controls[
            'RecurrenceWeek'
          ].updateValueAndValidity();

          this.inspectionplanForm.controls['DayOfMonth'].clearValidators();
          this.inspectionplanForm.controls[
            'DayOfMonth'
          ].updateValueAndValidity();
        }
      }
    }
  }
  findInvalidControls() {
    const invalid = [];
    const controls = this.inspectionplanForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  ValidateDateRange() {
    // this.inspectionplanForm.get('StartTime').setValue('');
    // this.inspectionplanForm.get('EndTime').setValue('');
    var StartingDate = new Date(this.inspectionplanForm.value.start);
    this.inspectionplanForm
      .get('RepeatedEndDate')
      .setValue(this.OldRepeatedDate);
    var today = new Date();
    if (StartingDate <= today) {
      this.getCurrenttime();
    }
    if (StartingDate > today) {
      this.mintime = null;
    }
    if (this.inspectionplanForm.value.RecurrenceType == 'Daily') {
      this.minRepeatedDate = new Date(
        moment(StartingDate).add(1, 'days').toString()
      );
    } else if (this.inspectionplanForm.value.RecurrenceType == 'Weekly') {
      this.minRepeatedDate = new Date(
        moment(StartingDate).add(7, 'days').toString()
      );
    } else if (this.inspectionplanForm.value.RecurrenceType == 'Monthly') {
      this.minRepeatedDate = new Date(
        moment(StartingDate).add(1, 'M').toString()
      );
    } else if (this.inspectionplanForm.value.RecurrenceType == 'Quaterly') {
      this.minRepeatedDate = new Date(
        moment(StartingDate).add(5, 'M').toString()
      );
    } else if (this.inspectionplanForm.value.RecurrenceType == 'Yearly') {
      this.minRepeatedDate = new Date(
        moment(StartingDate).add(1, 'y').toString()
      );
    }
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_AUDIT_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  createWorkFlowInstance(): void {
    const data: CreateInstanceParam[] = this.instanceKeys.map((ele) => {
      return {
        workFlowKey: this.workFlow?.workFlowKey,
        instanceKey: ele,
        userId: this.loggedUser.userId,
        userName: this.loggedUser.userName,
        tenantId: this.companyKey,
      };
    });
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.workFlowService.createBulkWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.setWorkFlowInstance();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  setWorkFlowInstance(): void {
    const data: SetInstanceParam[] = this.instanceKeys.map((ele) => {
      return {
        instanceKey: ele,
        userId: this.loggedUser.userId,
        userName: this.loggedUser.fullName,
        actionName: Constants.APP_CREATE_ACTION,
        rules: [],
        roleId: this.loggedUser.roleIds,
        payload: null,
      };
    });
    this.workFlowService.setBulkWorkFlowInstance(data).subscribe({
      next: (response) => {
        if (this.formMode === 'create') {
          this.createTask();
        } else {
          this.updateSeriesTask();
        }
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
        this.deleteWorkFlowInstance();
      },
    });
  }

  updateBulkWorkFlowInstance(result: any): void {
    const departmentsArr = this.departmentsData.map((x) => x.detail).flat(1);
    // this.instanceKeys = result.map((x) => {
    //   return this.instanceKeys.find((y) => y == x.instanceKey);
    // });
    const data = this.instanceKeys.map((ele) => {
      return {
        tenantId: this.companyKey,
        tableId: result.find((x) => x.instanceKey === ele)?.id,
        instanceKey: ele,
        payload: {
          ...this.tasks.find((x) => x.instanceKey === ele),
          checklistName: this.checklists.find(
            (x) => x.id === this.inspectionplanForm.value.checklist
          ).name,
          departmentName: departmentsArr.find(
            (x) => x.departmentId === this.inspectionplanForm.value.department
          ).departmentName,
          machineName: this.machines.find(
            (x) => x.id === this.inspectionplanForm.value.machine
          )?.machineName,
          assigneeName: this.reportUsers.find(
            (x) => x.userId === this.inspectionplanForm.value.user
          ).fullName,
          taskId: result.find((x) => x.instanceKey === ele)?.id,
          createdByName: this.loggedUser.fullName,
          createdOn: new Date(),
          isActive: true,
          isDeleted: false,
        },
      };
    });
    this.workFlowService.updateBulkWorkFlowInstance(data).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  deleteWorkFlowInstance(): void {
    this.workFlowService
      .deleteBulkWorkFlowInstance(this.instanceKeys)
      .subscribe({
        next: (response) => {},
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }

  GetDayNo(day: string) {
    switch (day) {
      case 'Monday':
        return 1;
      case 'Tuesday':
        return 2;
      case 'Wednesday':
        return 3;

      case 'Thursday':
        return 4;
      case 'Friday':
        return 5;
      case 'Saturday':
        return 6;
      case 'Sunday':
        return 7;
      default:
        return 0;
    }
  }

  weekOfMonth(m) {
    return m.week() - moment(m).startOf('month').week() + 1;
  }

  onChangeDepartment(event) {
    this.inspectionplanForm.get('subDepartment').setValue('');
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.departmentService.getSubDepartmentsById(event.value).subscribe({
      next: (response) => {
        this.subDepartments = response;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
}
