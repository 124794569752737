import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-dashboard-two',
  templateUrl: './dashboard-two.component.html',
  styleUrls: ['./dashboard-two.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class DashboardTwoComponent implements OnInit {
  public loadIot = false;
  public loadDigital = false;
  public loadKPIs = false;

  public userName = '';
  constructor() {}
  hasIotAccess = ApplicationPermission.IOT_DASHBOARD_ACCESS;
  hasDigitalEyeAccess = ApplicationPermission.VIDEO_DASHBOARD_VIEW;
  hasDashboardsAccess = ApplicationPermission.DASHBOARD_VIEW;
  hasSafetyKpisAccess = ApplicationPermission.SAFETY_KPIS_DASHBOARD_ACCESS;
  ActiveKPIs: boolean = true;
  ngOnInit(): void {
    this.userName = JSON.parse(localStorage.getItem('logged_in_user')).fullName;
  }

  tabChanged(event: MatTabChangeEvent) {
    const selectedTabIndex = event.index;
    if (selectedTabIndex == 1 && !this.loadIot) {
      this.loadIot = true;
    } else if (selectedTabIndex == 2 && !this.loadDigital) {
      this.loadDigital = true;
    } else if (selectedTabIndex == 3 && !this.loadKPIs) {
      this.loadKPIs = true;
    }
  }
}
