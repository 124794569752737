<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="
    (formMode === 'create'
      ? 'Department.CreatingDepartment'
      : 'Department.UpdatingDepartment'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="depForm">
    <div class="flex items-center" mat-dialog-title>
      <h2
        *ngIf="depForm.get('DepartmentName').value"
        class="headline m-0 flex-auto"
      >
        {{ depForm.get("DepartmentName").value }}
      </h2>
      <h2
        *ngIf="!depForm.get('DepartmentName').value"
        class="headline m-0 flex-auto"
      >
        {{ "General.AddNew" | translate }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex flex-col flex-wrap justify-between">
      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "Department.DepartmentName" | translate }}
          <span
            [ngClass]="
              depForm.invalid &&
              depForm.get('DepartmentName').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            formControlName="DepartmentName"
          />
          <mat-error
            *ngIf="
              depForm.invalid &&
              depForm.get('DepartmentName').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              depForm.invalid &&
              depForm.get('DepartmentName').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <!-- department representor Users -->
      <div class="field-full flex column">
        <mat-label class="field-label">{{
          "Department.DepartmentRepresentor" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            ngxMatSelect
            #msf1="ngxMatSelect"
            [displayMember]="'fullName'"
            [valueMember]="'userId'"
            [source]="users"
            [useInfiniteScroll]="true"
            formControlName="DepartmentRepresentor"
            [hasPermission]="hasCreateUserPermission | permissionChecker"
            [multiple]="false"
            [manageRoute]="'/user-management/users'"
            [placeholder]="'Placeholder.Select' | translate"
          >
            <mat-option
              [value]="item.userId"
              *ngFor="let item of msf1.filteredSource"
            >
              {{ item.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isCreateMode()"
          [btnType]="'Add'"
          [btnText]="'Add'"
          [isDisabled]="!depForm.valid"
          (onClick)="save()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isUpdateMode()"
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="!depForm.valid"
          (onClick)="save()"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
