<div class="w-full p-gutter text-center">
  <svg xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
     width="504.59" height="480.9" viewBox="0 0 504.59 480.9" 
     class="w-full max-w-lg mx-auto mb-6">
  <defs>
    <linearGradient id="linear-gradient" x1="254.01" y1="304.13" x2="226.78" y2="268.19" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-opacity="0"/>
      <stop offset="0.99"/>
    </linearGradient>
    <linearGradient id="linear-gradient-2" x1="243.75" y1="329.23" x2="305.38" y2="335.59"
                    xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-3" x1="121.74" y1="108.66" x2="461.85" y2="108.66"
                    xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-4" x1="288.59" y1="-46.13" x2="314.02" y2="415.99"
                    xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-5" x1="133.9" y1="403.66" x2="133.9" y2="440.51" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-opacity="0"/>
      <stop offset="0.41" stop-opacity="0.41"/>
      <stop offset="0.99"/>
    </linearGradient>
    <linearGradient id="linear-gradient-6" x1="310.64" y1="392.64" x2="311.75" y2="413.1"
                    xlink:href="#linear-gradient-5"/>
    <linearGradient id="linear-gradient-7" x1="209.45" y1="234.78" x2="208.09" y2="196" xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-8" x1="241.07" y1="323.5" x2="270.67" y2="323.5" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ecc4d7"/>
      <stop offset="0.42" stop-color="#efd4d1"/>
      <stop offset="1" stop-color="#f2eac9"/>
    </linearGradient>
    <linearGradient id="linear-gradient-9" x1="268.52" y1="330.49" x2="253.32" y2="213.3"
                    xlink:href="#linear-gradient"/>
    <linearGradient id="linear-gradient-10" x1="236.72" y1="274.73" x2="248.97" y2="274.73"
                    xlink:href="#linear-gradient-8"/>
    <linearGradient id="linear-gradient-11" x1="255.75" y1="294.68" x2="262.36" y2="294.68"
                    xlink:href="#linear-gradient-8"/>
    <linearGradient id="linear-gradient-12" x1="254.41" y1="283.76" x2="266.78" y2="283.76"
                    xlink:href="#linear-gradient-8"/>
  </defs>
  <g>
    <path
      d="M56.51,142.24c-49.12,122.23-6.28,194-2.17,212.61,6.22,28.25,45.75,154.83,193.74,121.32,30.59-6.93,76.47-29.23,107.76-31.41,26.43-1.85,52.42,6.33,78.75,9.27s56.17-.89,73.3-21.09c15.05-17.76,16.24-43.44,12.86-66.48s-10.56-45.77-9.51-69c1-21.16,8.69-41.33,14.36-61.74,8.46-30.45,12.41-62.88,5.28-93.66s-26.57-59.75-55.27-73C420,43.4,351,82.11,294.41,58.55,263.41,45.65,102.67,27.35,56.51,142.24Z"
      transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 0.6)" opacity="0.18" style="isolation: isolate"/>
    <path
      d="M267.22,271.7a3.85,3.85,0,0,0-.66-2.3,1.75,1.75,0,0,0-2.16-.54c-.39.23-.65.66-1.06.87s-1.09-.08-.95-.52c-1.87.29-3.18,1.92-4.78,2.92-1.27.78-2.75,1.18-4,1.94s-2.42,2.11-2.26,3.59a5.34,5.34,0,0,1,.16,1.42,4.83,4.83,0,0,1-.38.95,4.27,4.27,0,0,0,.37,3.32c.5,1,1.19,2,1.6,3a23.68,23.68,0,0,0,1.1,3.2c.56,1,1.79,1.74,2.81,1.25-.85-1.3-.32-3,.29-4.47,1-2.3,2.15-4.68,4.28-6a8.15,8.15,0,0,0,2.32-1.62,19.57,19.57,0,0,0,1.16-1.92C266.17,275.11,267.26,273.94,267.22,271.7Z"
      transform="translate(-33.12 -0.87)" fill="#3f3d56"/>
    <path
      d="M248.05,287.13a3.63,3.63,0,0,0-1-1c-1.23-.71-2.74.22-3.82,1.14a52.16,52.16,0,0,0-4.22,4.06A3.59,3.59,0,0,0,238,293a3.4,3.4,0,0,0,.29,1.8,25.47,25.47,0,0,0,7.06,10.24,3.28,3.28,0,0,0,1.75.94,3.4,3.4,0,0,0,1.84-.54l7.83-4c1.42-.73,3.11-1.83,2.49-3.7-.41-1.21-1.7-1.62-2.67-2.31A41.64,41.64,0,0,1,248.05,287.13Z"
      transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)"/>
    <path
      d="M248.05,287.22a3.6,3.6,0,0,0-1-1c-1.23-.71-2.74.21-3.82,1.13A52.16,52.16,0,0,0,239,291.4a3.63,3.63,0,0,0-1.05,1.7,3.45,3.45,0,0,0,.29,1.81,25.51,25.51,0,0,0,7.06,10.23,3.3,3.3,0,0,0,1.75.95,3.5,3.5,0,0,0,1.84-.55l7.83-4c1.42-.73,3.11-1.83,2.49-3.7-.41-1.2-1.7-1.61-2.67-2.31A41.39,41.39,0,0,1,248.05,287.22Z"
      transform="translate(-33.12 -0.87)" fill="url(#linear-gradient)"/>
    <path
      d="M266.61,306.11c.95,3.95.66,8.07.36,12.12-.89,12.3-1.81,24.74-5.66,36.45-1,3.11-2.38,6.32-5,8.22-4.33,3.09-10.84,1.39-14.27-2.67s-4.27-9.76-3.82-15.06,2-10.43,2.93-15.67c1.49-8.82,0-22.68,6.07-30C253.7,291.62,264.61,297.71,266.61,306.11Z"
      transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)"/>
    <path
      d="M266.61,306.11c.95,3.95.66,8.07.36,12.12-.89,12.3-1.81,24.74-5.66,36.45-1,3.11-2.38,6.32-5,8.22-4.33,3.09-10.84,1.39-14.27-2.67s-4.27-9.76-3.82-15.06,2-10.43,2.93-15.67c1.49-8.82,0-22.68,6.07-30C253.7,291.62,264.61,297.71,266.61,306.11Z"
      transform="translate(-33.12 -0.87)" fill="url(#linear-gradient-2)"/>
    <path
      d="M260.55,356.72a35.06,35.06,0,0,0-.48,3.5,99.15,99.15,0,0,1-7.49,21.7c-1.55,3.24-3.3,6.43-4.24,9.9-1.31,4.79-1,9.88-.37,14.81.87,6.79,2.77,11.39,4.22,18.08-1.18.82-11.41,7.15-12.85,7.33-2.9-1.94,5-7.84,4.91-8.85-.42-5.18-1-6.47-2.55-10.93-1.3-3.85-2.62-7.7-3.7-11.61a38.38,38.38,0,0,1-1.77-11.91,46,46,0,0,1,1.39-8.22l3.21-13.92-13,10a5.18,5.18,0,0,0-1.51,1.55,5,5,0,0,0-.3,2.91,82.91,82.91,0,0,0,6.27,24.14L225,412.52a11.46,11.46,0,0,1-4.47,3.17c-1.2-1.16-.63-3.21.32-4.58s3.88-3.39,4-5.05c.06-.73-2.72-4.83-2.9-5.53-2.1-8.44-3.35-12.77-4.26-21.42a22.66,22.66,0,0,1,0-6.21c.85-4.71,4.23-8.51,7.48-12,4.9-5.33,8.63-9.6,12.8-14.43,2,2.45,4.58,6.88,14.1,9.33C255.85,356.73,258.28,356.2,260.55,356.72Z"
      transform="translate(-33.12 -0.87)" fill="#3f3d56"/>
    <path
      d="M461.85,162.27a44.25,44.25,0,0,1-44.23,44.23H326.14a40.53,40.53,0,0,1-6-.43,59.6,59.6,0,0,1-7.23.43H184.07a62.33,62.33,0,1,1,0-124.65h25.38A81.3,81.3,0,0,1,369.78,76h7.88a40.85,40.85,0,0,1,40.86,40.84c0,.41,0,.82,0,1.21A44.21,44.21,0,0,1,461.85,162.27Z"
      transform="translate(-33.12 -0.87)" fill="url(#linear-gradient-3)"/>
    <path
      d="M451.9,152.32a44.25,44.25,0,0,1-44.23,44.23H316.2a40.67,40.67,0,0,1-6-.43,59.56,59.56,0,0,1-7.22.43H174.12a62.33,62.33,0,1,1,0-124.65H199.5a81.29,81.29,0,0,1,160.33-5.85h7.88a40.85,40.85,0,0,1,40.86,40.83c0,.42,0,.83,0,1.22A44.22,44.22,0,0,1,451.9,152.32Z"
      transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)"/>
    <path
      d="M451.9,152.32a44.25,44.25,0,0,1-44.23,44.23H316.2a40.67,40.67,0,0,1-6-.43,59.56,59.56,0,0,1-7.22.43H177.85A62.33,62.33,0,0,1,208.94,80.19h25.39A81.31,81.31,0,0,1,315,9.16h1a81.39,81.39,0,0,1,43.86,56.88h7.88a40.85,40.85,0,0,1,40.86,40.83c0,.42,0,.83,0,1.22A44.22,44.22,0,0,1,451.9,152.32Z"
      transform="translate(-33.12 -0.87)" fill="url(#linear-gradient-4)"/>
    <path d="M98.21,365.7H93.3V175.5a17.91,17.91,0,0,1,17.89-17.88h31v4.91h-31a13,13,0,0,0-13,13Z"
          transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)"/>
    <path d="M500.63,365.7h-4.9V175.5a13,13,0,0,0-13-13h-31v-4.91h31a17.9,17.9,0,0,1,17.88,17.88Z"
          transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)"/>
    <rect x="419.46" y="274.18" width="85.13" height="137.09" rx="19.41" fill="rgba(var(--color-primary), 1)"/>
    <path
      d="M531.88,294.24v86.62a12.46,12.46,0,0,1-12.46,12.46H470.57a12.46,12.46,0,0,1-12.47-12.46V294.24a12.48,12.48,0,0,1,12.47-12.48h48.85A12.47,12.47,0,0,1,531.88,294.24Z"
      transform="translate(-33.12 -0.87)" fill="#fff"/>
    <rect x="449.03" y="398.19" width="28.01" height="8.29" rx="4.15" fill="#fff"/>
    <rect x="17.04" y="311.22" width="167.49" height="96.18" rx="13.45" fill="rgba(var(--color-primary), 1)"/>
    <rect x="22.69" y="315.93" width="156.58" height="86.22" rx="12.31" fill="#fff"/>
    <path d="M217.64,414.59a6.3,6.3,0,0,1-6.32,6.32H56.48a6.32,6.32,0,1,1,0-12.64H211.32a6.34,6.34,0,0,1,6.32,6.32Z"
          transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)"/>
    <path
      d="M216.45,410.91a5.38,5.38,0,0,1-.65.78,6.31,6.31,0,0,1-4.48,1.86H56.48a6.31,6.31,0,0,1-5.14-2.64,6.22,6.22,0,0,1,5.14-2.64H211.32A6.28,6.28,0,0,1,216.45,410.91Z"
      transform="translate(-33.12 -0.87)" fill="url(#linear-gradient-5)"/>
    <path
      d="M400.71,344.94H320.55c-1.35,0-3.4-1.1-3.4-2.45s2.05-2.46,3.4-2.46h80.16a17.64,17.64,0,0,0,17.63-17.62V249.73a12.59,12.59,0,0,0-12.58-12.58h-56.5a15.52,15.52,0,0,1-15.5-15.5V203.19a2.46,2.46,0,1,1,4.91,0v18.46a10.6,10.6,0,0,0,10.59,10.59h56.5a17.51,17.51,0,0,1,17.49,17.49v72.68A22.56,22.56,0,0,1,400.71,344.94Z"
      transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)"/>
    <rect x="435.77" y="310.3" width="54.17" height="6.63" rx="3.32" fill="rgba(var(--color-primary), 1)" opacity="0.18"
          style="isolation: isolate"/>
    <rect x="435.77" y="323.38" width="54.17" height="6.63" rx="3.32" fill="rgba(var(--color-primary), 1)" opacity="0.18"
          style="isolation: isolate"/>
    <rect x="435.77" y="336.46" width="54.17" height="6.63" rx="3.32" fill="rgba(var(--color-primary), 1)" opacity="0.18"
          style="isolation: isolate"/>
    <rect x="435.77" y="349.54" width="54.17" height="6.63" rx="3.32" fill="rgba(var(--color-primary), 1)" opacity="0.18"
          style="isolation: isolate"/>
    <rect x="41.91" y="336" width="54.17" height="6.63" rx="3.32" fill="rgba(var(--color-primary), 1)" opacity="0.18"
          style="isolation: isolate"/>
    <rect x="41.91" y="349.08" width="54.17" height="6.63" rx="3.32" fill="rgba(var(--color-primary), 1)" opacity="0.18"
          style="isolation: isolate"/>
    <rect x="41.91" y="362.17" width="54.17" height="6.63" rx="3.32" fill="rgba(var(--color-primary), 1)" opacity="0.18"
          style="isolation: isolate"/>
    <rect x="41.91" y="375.25" width="54.17" height="6.63" rx="3.32" fill="rgba(var(--color-primary), 1)" opacity="0.18"
          style="isolation: isolate"/>
    <path d="M207.58,319.36,55.81,373.45V329.12A12.32,12.32,0,0,1,68.13,316.8H200.07A12.21,12.21,0,0,1,207.58,319.36Z"
          transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)" opacity="0.18" style="isolation: isolate"/>
    <path d="M212.39,366.06V390.7A12.31,12.31,0,0,1,200.07,403H120.19Z" transform="translate(-33.12 -0.87)"
          fill="rgba(var(--color-primary), 1)" opacity="0.18" style="isolation: isolate"/>
    <g>
      <rect x="256.3" y="275.29" width="107.24" height="127.14" rx="10.91" fill="rgba(var(--color-primary), 1)"/>
      <path
        d="M391.63,290.73V386a9.85,9.85,0,0,1-9.86,9.86H304.53a9.86,9.86,0,0,1-9.87-9.86V290.73a9.87,9.87,0,0,1,9.87-9.86h77.24A9.86,9.86,0,0,1,391.63,290.73Z"
        transform="translate(-33.12 -0.87)" fill="#fff"/>
      <polygon
        points="274.72 325.04 288.73 318.77 305.68 333.14 323 310.3 340.32 334.62 340.32 351.43 275.4 351.43 274.72 325.04"
        fill="rgba(var(--color-primary), 1)" opacity="0.18" style="isolation: isolate"/>
      <rect x="305.36" y="396.81" width="11.2" height="3.31" rx="1.66" fill="url(#linear-gradient-6)"/>
      <polygon points="345.66 352.06 274.42 352.06 274.42 304.77 275.4 304.77 275.4 351.08 345.66 351.08 345.66 352.06"
               fill="rgba(var(--color-primary), 1)"/>
      <rect x="285.46" y="316.47" width="5.67" height="34.96" fill="rgba(var(--color-primary), 1)"/>
      <rect x="320.83" y="307.89" width="5.67" height="43.55" fill="rgba(var(--color-primary), 1)"/>
      <rect x="302.87" y="330.2" width="5.67" height="21.23" fill="rgba(var(--color-primary), 1)"/>
      <rect x="336.77" y="330.2" width="5.67" height="21.23" fill="rgba(var(--color-primary), 1)"/>
      <path d="M391.63,290.73v8.67l-97,52.89V290.73a9.87,9.87,0,0,1,9.87-9.86h77.24A9.86,9.86,0,0,1,391.63,290.73Z"
            transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)" opacity="0.18" style="isolation: isolate"/>
    </g>
    <path d="M531.88,329.64v51.22a12.46,12.46,0,0,1-12.46,12.46H470.57a12.46,12.46,0,0,1-12.47-12.46V370l73.75-45.25Z"
          transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)" opacity="0.18" style="isolation: isolate"/>
    <polygon points="214.96 475.3 211.03 476.11 211.03 195.48 214.96 195.48 214.96 475.3" fill="rgba(var(--color-primary), 1)"/>
    <polygon points="214.96 475.3 211.03 476.11 211.03 195.48 214.96 195.48 214.96 475.3"
             fill="url(#linear-gradient-7)"/>
    <path
      d="M245,308.25a2.75,2.75,0,0,0-1.93-1c-1.14.08-1.71,1.42-1.87,2.56a12.48,12.48,0,0,0,0,3.81c.59,3.61,2.69,6.77,4.75,9.81A111.46,111.46,0,0,0,254.56,335a15.64,15.64,0,0,0,4.91,4,6.49,6.49,0,0,0,6.13-.12c2.24-1.44,2.92-4.35,3.42-7l1.5-8.05a3.55,3.55,0,0,0,0-2.14,3.36,3.36,0,0,0-1.24-1.27,4.88,4.88,0,0,0-2.14-1c-2.15-.23-3.71,2-4.26,4.11s-.73,4.44-2.24,6L252,319.52a5.78,5.78,0,0,1-1.76-3.36,16.67,16.67,0,0,1,.52-2.77c.13-.93-.21-2.08-1.12-2.31a3.07,3.07,0,0,1-.87,1.24C247.42,311.19,246.33,309.51,245,308.25Z"
      transform="translate(-33.12 -0.87)" fill="url(#linear-gradient-8)"/>
    <path
      d="M262,309.76l-.95,12.7a1.91,1.91,0,0,0,1.24,2.32l8.43,4.78c2.17-3.51,2.35-7.86,2.3-12a42.75,42.75,0,0,0-.84-9,13.24,13.24,0,0,0-1.54-4,9.26,9.26,0,0,0-3.1-3.06c-.87-.52-6-4-6.88-3.26-1,.9,2.19,5.38,1.92,6.66A37.24,37.24,0,0,0,262,309.76Z"
      transform="translate(-33.12 -0.87)" fill="rgba(var(--color-primary), 1)"/>
    <path
      d="M262,309.76l-.95,12.7a1.91,1.91,0,0,0,1.24,2.32l8.43,4.78c2.17-3.51,2.35-7.86,2.3-12a42.75,42.75,0,0,0-.84-9,13.24,13.24,0,0,0-1.54-4,9.26,9.26,0,0,0-3.1-3.06c-.87-.52-6-4-6.88-3.26-1,.9,2.19,5.38,1.92,6.66A37.24,37.24,0,0,0,262,309.76Z"
      transform="translate(-33.12 -0.87)" fill="url(#linear-gradient-9)"/>
    <path
      d="M249,265.63a8.81,8.81,0,0,1-2.18,5.65,21.45,21.45,0,0,0-2.32,2.25c-1.2,1.63-1.07,3.84-.9,5.86.22,2.61.45,5.22.67,7.84a27,27,0,0,0-4,2.74,41.24,41.24,0,0,1-2.27-5.12,13.32,13.32,0,0,1-1.22-3.92,11,11,0,0,1,.13-2.56,28.75,28.75,0,0,1,6.41-14.15.87.87,0,0,1-.19-.46,10.53,10.53,0,0,1,.17-3.67.69.69,0,0,1,.37-.57c.26-.09.52.15.66.39a5.82,5.82,0,0,1,.74,2.39,28,28,0,0,1,3.27-2.79A26.91,26.91,0,0,1,249,265.63Z"
      transform="translate(-33.12 -0.87)" fill="url(#linear-gradient-10)"/>
    <path
      d="M256.17,299.57a2.22,2.22,0,0,0,2.64.61,9.51,9.51,0,0,0,2.7-1.69A1.73,1.73,0,0,0,262,298a1.68,1.68,0,0,0,.15-.67c.1-1.3.17-2.61.2-3.92s-.05-3.12-1.16-4.07a1.05,1.05,0,0,0-.49-.28,1.6,1.6,0,0,0-.65.06,6.53,6.53,0,0,0-2.41,1c-1.36,1-1.68,3-1.74,4.57C255.85,296,255.47,298.39,256.17,299.57Z"
      transform="translate(-33.12 -0.87)" fill="url(#linear-gradient-11)"/>
    <path d="M266.75,281s.28,7.74-.92,10.22-6.27,2.49-8.2.46-4-7.73-2.95-12.62S265,270.63,266.75,281Z"
          transform="translate(-33.12 -0.87)" fill="url(#linear-gradient-12)"/>
    <path
      d="M260.34,275.64a5.71,5.71,0,0,0-2.61,3.52,16.75,16.75,0,0,0-.49,4.47l-.05,2.28a4.77,4.77,0,0,0-1.95-1.58,4.38,4.38,0,0,0,.28,3.33,3.74,3.74,0,0,1,.47,1,.87.87,0,0,1-.37,1c-.55.26-1.08-.39-1.35-.94-1.07-2.19-2.18-4.57-1.74-7a10.08,10.08,0,0,1,.91-2.53,12.64,12.64,0,0,1,4.21-5.4,6,6,0,0,1,5.2-.7C265.82,274.22,261.29,275.13,260.34,275.64Z"
      transform="translate(-33.12 -0.87)" fill="#3f3d56"/>
  </g>
  </svg>


  <h1 class="display-3 font-medium m-0 text-primary">{{SharedConstants.NOT_FOUND_ERROR}}</h1>
  <h2 class="headline m-0 font-bold display-2">{{'StaticPages.NotFound.Title' | translate}}</h2>
  <h2 class="headline m-0 font-light">{{'StaticPages.NotFound.Message' | translate}}</h2>

  <button color="primary" mat-flat-button
   class="mt-4" type="button" (click)="goBack()">
          Go Back
  </button>
</div>