import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BulkUserUploadService } from 'src/backend/services/bulk-user-upload.service';
import * as XLSX from 'xlsx-js-style';
@Component({
  selector: 'app-bulk-upload-users',
  templateUrl: './bulk-upload-users.component.html',
  styleUrls: ['./bulk-upload-users.component.scss'],
})
export class BulkUploadUsersComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @Output() onInsertBulkUser: EventEmitter<boolean> = new EventEmitter();
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  excelData: any;
  excelFile: any;
  userForm: FormGroup;
  loadingLabel: string = '';
  private translationsList: any = {};

  constructor(
    private fb: FormBuilder,
    private feedBackService: AsyncFeedbackService,
    private bulkService: BulkUserUploadService,
    private translate: TranslateService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit() {
    this.userForm = this.fb.group({
      attachment: [[], Validators.required],
    });
  }

  cancel(): void {
    this.userForm.get('attachment').setValue([]);
  }

  checkProfileExtension(e: any) {
    let files = this.userForm.get('attachment').value;
    const images = files.filter(
      (x) =>
        x.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        x.type === 'application/vnd.ms-excel' ||
        x.type === 'text/csv'
    );
    const otherFiles = files.filter(
      (x) =>
        x.type !==
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
        x.type !== 'application/vnd.ms-excel' &&
        x.type !== 'text/csv'
    );
    if (otherFiles.length > 0) {
      this.userForm.get('attachment').setValue(images);
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Warning,
          'Only XLSX , XLS and CSV files supported'
        )
      );
    }
  }

  saveExcel(): void {
    this.excelFile = this.userForm.get('attachment').value[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.excelFile);
    let array = [];
    fileReader.onload = (e) => {
      let arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });

      workbook.SheetNames.forEach((sheetName) => {
        var worksheet = workbook.Sheets[sheetName];
        var json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        // Skip the first row (headers) and any other non-data rows
        var header: any = json[1];
        var data = json.slice(1).map((row) => {
          let obj = {};
          header.forEach((key, i) => {
            var replacedKey = key.replace(/ /g, '').toLowerCase();
            obj[replacedKey] = row[i];
          });
          return obj;
        });
        array.push(data);
      });

      const usersList = array[0].map((item) => {
        return {
          firstName: item.firstname,
          lastName: item.lastname,
          phoneNumber: item.phonenumber,
          email: item.email,
          idNumber: item.idnumber,
          departmentDto: {
            name: item.userdepartment || '',
          },
          roleWithPermessionsDto: {
            roleName: item.userrole || '',
          },
        };
      });

      // this.loadingLabel = 'General.Refreshing';
      // this.sectionState = SectionStateStatus.Loading;
      // this.bulkService.insertUserInBulk(usersList).subscribe({
      //   next: () => {
      //     this.sectionState = SectionStateStatus.Ready;
      //     this.feedBackService.showFeedback(
      //       new FeedbackModel(
      //         FeedbackType.Success,
      //         this.translationsList['Success']['SuccessMessage']
      //       )
      //     );
      //     this.onInsertBulkUser.emit(true);
      //     this.cancel();
      //   },
      //   error: ({ error }) => {
      //     this.feedBackService.showFeedback(
      //       new FeedbackModel(FeedbackType.Failure, error.message)
      //     );
      //     this.sectionState = SectionStateStatus.Ready;
      //     this.cancel();
      //   },
      // });
    };
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
}
