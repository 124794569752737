<div
  class="card overflow-hidden rounded-xl w-full relative custom-shadow border-gray-300 border"
>
  <div class="p-3 text-start trans-ease-out" matRipple>
    <div class="flex items-start" style="height: 80px">
      <ngx-avatars
        size="48"
        [src]="contact?.profilePicture"
        [name]="contact?.contactName"
        [textSizeRatio]="2"
        [initialsSize]="1"
        [ngClass]="contact.isActive ? 'opacity-100 ' : 'opacity-30'"
      ></ngx-avatars>
      <div class="flex justify-between w-full pt-1">
        <div class="ps-4">
          <h4 class="text-sm leading-none font-medium">
            {{ contact?.contactName }}
          </h4>
          <p class="text-gray-400 text-xs">
            {{ contact?.userRole?.[0]?.roleName}}
          </p>
        </div>
      </div>
      <div
        class="flex items-center justify-end"
        style="width: -webkit-fill-available"
      >
        <span
          *ngIf="contact.isActive"
          class="text-green rounded-full border px-2 text-xs border-green-500"
          >{{ "General.Active" | translate }}</span
        >
        <span
          *ngIf="!contact.isActive"
          class="text-gray-600 rounded-full border px-2 text-xs border-gray-600"
          >{{ "General.Inactive" | translate }}</span
        >
        <button
          *ngIf="
            (hasDeletePermissions | permissionChecker) ||
            (hasActivatePermissions | permissionChecker)
          "
          [matMenuTriggerFor]="menu"
        >
          <mat-icon svgIcon="mat:more_vert"></mat-icon>
        </button>
      </div>
    </div>
    <div class="flex items-end justify-between">
      <div class="w-90">
        <p class="text-gray-400 leading-tight title">
          {{ "EmergencyContact.Mob" | translate }}:
          {{ contact?.phoneNumber ? contact?.phoneNumber : "No Mobile Num" }}
        </p>
        <p
          class="text-gray-600 leading-tight title truncate"
          #div
          [matTooltip]="div?.offsetWidth < div?.scrollWidth ? depts : null"
        >
          {{ "EmergencyContact.Dep" | translate }}:
          {{ depts }}
        </p>
        <p class="text-gray-600 leading-tight title">
          {{ "EmergencyContact.userId" | translate }}:
          {{ contact?.userCard ? contact?.userCard : "No User ID" }}
        </p>
      </div>
      <div *ngIf="contact.isActive && contact.phoneNumber">
        <a href="{{ 'tel:' + contact?.phoneNumber }}">
          <svg
            width="35"
            height="31"
            viewBox="0 0 31 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="31" height="28" rx="8" fill="#73C75E" />
            <path
              d="M19.8848 22C19.2648 21.991 18.6743 21.8421 18.0988 21.6407C17.4124 21.4004 16.7301 21.1469 16.0553 20.8759C15.0383 20.4674 14.1286 19.8737 13.2768 19.192C11.808 18.0166 10.498 16.6861 9.3813 15.1695C8.68373 14.2222 8.15397 13.1883 7.77299 12.0768C7.57724 11.5057 7.35681 10.942 7.18594 10.3637C6.8197 9.1241 7.00772 7.94345 7.63341 6.82508C7.76019 6.59847 7.90465 6.3814 8.04706 6.16392C8.38181 5.65273 8.8377 5.31189 9.4454 5.19213C9.76935 5.1283 10.094 5.06786 10.4188 5.0087C10.4737 4.99871 10.5312 5.00324 10.5874 5.00071C10.7828 4.99192 10.9388 5.06458 11.0441 5.23075C11.1587 5.41165 11.2743 5.59239 11.379 5.77901C11.7747 6.48372 12.1634 7.19235 12.5611 7.89588C12.6594 8.06974 12.784 8.22857 12.8926 8.39686C12.963 8.50601 13.0311 8.61722 13.0917 8.73202C13.2257 8.98601 13.2119 9.22963 13.0338 9.46223C12.8051 9.76082 12.524 10.0047 12.2376 10.2444C11.9195 10.5106 11.594 10.7697 11.2932 11.0545C10.9514 11.3779 10.9193 11.547 11.1252 11.9723C11.2886 12.3097 11.4903 12.6291 11.681 12.9529C12.4919 14.3291 13.5036 15.5319 14.8001 16.476C15.2909 16.8333 15.8133 17.1481 16.3285 17.471C16.5809 17.6291 16.8477 17.765 17.1117 17.9041C17.396 18.054 17.6561 17.9966 17.8683 17.7689C18.1437 17.4734 18.4014 17.1616 18.6725 16.8621C18.9294 16.5784 19.1789 16.2855 19.4589 16.0259C19.7573 15.7492 19.9905 15.7357 20.3428 15.9411C20.628 16.1073 20.8919 16.3104 21.1787 16.4733C21.891 16.8777 22.6124 17.266 23.3268 17.6667C23.504 17.7661 23.671 17.8844 23.8381 18.0005C23.9533 18.0806 23.9962 18.2006 23.9994 18.3392C24.0093 18.7664 23.8947 19.1729 23.8034 19.5845C23.675 20.1631 23.3437 20.5894 22.8653 20.9228C22.1888 21.3944 21.4689 21.7664 20.6488 21.9111C20.3968 21.9555 20.1396 21.9712 19.8848 22Z"
              fill="white"
            />
          </svg>
        </a>
      </div>
      <div *ngIf="!contact.isActive">
        <svg
          width="35"
          height="31"
          viewBox="0 0 31 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="31" height="28" rx="8" fill="#787C84" />
          <path
            d="M19.8848 22C19.2648 21.991 18.6743 21.8421 18.0988 21.6407C17.4124 21.4004 16.7301 21.1469 16.0553 20.8759C15.0383 20.4674 14.1286 19.8737 13.2768 19.192C11.808 18.0166 10.498 16.6861 9.3813 15.1695C8.68373 14.2222 8.15397 13.1883 7.77299 12.0768C7.57724 11.5057 7.35681 10.942 7.18594 10.3637C6.8197 9.1241 7.00772 7.94345 7.63341 6.82508C7.76019 6.59847 7.90465 6.3814 8.04706 6.16392C8.38181 5.65273 8.8377 5.31189 9.4454 5.19213C9.76935 5.1283 10.094 5.06786 10.4188 5.0087C10.4737 4.99871 10.5312 5.00324 10.5874 5.00071C10.7828 4.99192 10.9388 5.06458 11.0441 5.23075C11.1587 5.41165 11.2743 5.59239 11.379 5.77901C11.7747 6.48372 12.1634 7.19235 12.5611 7.89588C12.6594 8.06974 12.784 8.22857 12.8926 8.39686C12.963 8.50601 13.0311 8.61722 13.0917 8.73202C13.2257 8.98601 13.2119 9.22963 13.0338 9.46223C12.8051 9.76082 12.524 10.0047 12.2376 10.2444C11.9195 10.5106 11.594 10.7697 11.2932 11.0545C10.9514 11.3779 10.9193 11.547 11.1252 11.9723C11.2886 12.3097 11.4903 12.6291 11.681 12.9529C12.4919 14.3291 13.5036 15.5319 14.8001 16.476C15.2909 16.8333 15.8133 17.1481 16.3285 17.471C16.5809 17.6291 16.8477 17.765 17.1117 17.9041C17.396 18.054 17.6561 17.9966 17.8683 17.7689C18.1437 17.4734 18.4014 17.1616 18.6725 16.8621C18.9294 16.5784 19.1789 16.2855 19.4589 16.0259C19.7573 15.7492 19.9905 15.7357 20.3428 15.9411C20.628 16.1073 20.8919 16.3104 21.1787 16.4733C21.891 16.8777 22.6124 17.266 23.3268 17.6667C23.504 17.7661 23.671 17.8844 23.8381 18.0005C23.9533 18.0806 23.9962 18.2006 23.9994 18.3392C24.0093 18.7664 23.8947 19.1729 23.8034 19.5845C23.675 20.1631 23.3437 20.5894 22.8653 20.9228C22.1888 21.3944 21.4689 21.7664 20.6488 21.9111C20.3968 21.9555 20.1396 21.9712 19.8848 22Z"
            fill="white"
          />
        </svg>
      </div>
    </div>

    <mat-menu #menu="matMenu">
      <button
        *ngIf="
          !contact.isActive && (hasActivatePermissions | permissionChecker)
        "
        mat-menu-item
        (click)="onClickActivate('Activate', contact?.emergencyId)"
      >
        <span>{{ "General.Buttons.Activate" | translate }}</span>
      </button>

      <button
        *ngIf="contact.isActive && (hasActivatePermissions | permissionChecker)"
        mat-menu-item
        (click)="onClickDeActivate('Deactivate', contact?.emergencyId)"
      >
        <span>{{ "General.Buttons.Deactivate" | translate }}</span>
      </button>

      <button
        *ngIf="hasDeletePermissions | permissionChecker"
        mat-menu-item
        (click)="onClickDelete('Delete', contact?.emergencyId)"
      >
        <span>{{ "General.Buttons.Delete" | translate }}</span>
      </button>
    </mat-menu>
  </div>
</div>
