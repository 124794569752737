<div class="flex justify-end mr-1 mb-4">
  <app-basic-button
    [btnType]="'Cancel'"
    [btnText]="'Back'"
    (onClick)="goBack()"
  >
  </app-basic-button>
  <app-basic-button
    *ngIf="
      (hasEditPermissions | permissionChecker) &&
      data?.status !== ReportHazardStatus.Closed
    "
    [btnType]="'Add'"
    [btnText]="'Edit'"
    (onClick)="editReportHazard()"
  >
  </app-basic-button>
  <app-basic-button
    *ngIf="
      data?.status !== ReportHazardStatus.Closed &&
      (hasSpecialPermissions | permissionChecker)
    "
    [btnType]="'Delete'"
    [btnText]="'Close'"
    [isDisabled]="false"
    (onClick)="closeHazard()"
  ></app-basic-button>
  <app-basic-button
    [btnType]="'Print text-sm'"
    [btnText]="'Print'"
    [isDisabled]="false"
    (onClick)="printHazardReport()"
  ></app-basic-button>
</div>
<div class="flex gap-2">
  <div class="wrapper_1">
    <app-card [title]="data?.title">
      <div
        body
        class="flex justify-between flex-wrap-reverse"
        *ngIf="data"
        #pdf
      >
        <div class="flex-1">
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.description }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.Description" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">
                {{
                  data?.parentDepartment
                    ? data?.parentDepartment
                    : data?.department
                }}
              </p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.Department" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center" *ngIf="data?.parentDepartment">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">
                {{ data?.department }}
              </p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.SubDepartment" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ convertUtcToLocal(data?.updatedOn) }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.UpdateAt" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.shift }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.Shift" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.reportedBy }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.ReportedBy" | translate }}
              </p>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div @fadeInUp class="flex items-center">
            <p class="text-left font-bold text-primary mr-4 rtl:ml-3">
              {{ "General.Status" | translate }}
            </p>
            <div class="w-28 text-center" [attr.id]="data?.status">
              <span>{{
                "Enum.ReportHazardStatus." + data?.status | translate
              }}</span>
            </div>
          </div>
          <div class="py-3 flex items-center" *ngIf="data?.lossMode">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.lossMode }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.LossMode" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center" *ngIf="data?.['rootCause']">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.['rootCause'] }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.rootCause" | translate }}
              </p>
            </div>
          </div>
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ data?.cause }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.6MClassification" | translate }}
              </p>
            </div>
          </div>

          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>

            <div @fadeInRight>
              <p class="m-0 body-1">{{ reportTypes }}</p>
              <p class="m-0 caption text-hint">
                {{ "ReportHazardList.Table.TypeOfHazard" | translate }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div body class="flex flex-wrap gap-2 mt-3" *ngIf="data">
        <ngx-avatars
          *ngFor="let img of data?.images"
          class="cursor-pointer"
          size="60"
          [src]="getAttachmentImage(img.imageUrl)"
          [name]="getAttachementTypeName(img.imageUrl)"
          [textSizeRatio]="2"
          [round]="false"
          [initialsSize]="2"
          [matTooltip]="img['imageName']"
          [cornerRadius]="10"
          [borderColor]="'#131C4E'"
          (clickOnAvatar)="openBigView(img.id)"
        >
        </ngx-avatars>
      </div>
    </app-card>
  </div>
  <div class="wrapper_2">
    <div class="card p-1" *ngIf="data?.status === 'CLOSED' && closerDetail">
      <h4 class="title">
        {{ "Voilations.View.CloserOfReport" | translate }}
      </h4>
      <hr class="mt-3" />
      <div class="py-3 flex items-center">
        <ngx-avatars
          class="logo box cursor-pointer mr-5"
          size="50"
          [src]="closerDetail?.userImage ? closerDetail?.userImage : ''"
          [name]="closerDetail?.fullName"
          [borderColor]="'#131C4E'"
          [textSizeRatio]="2"
          [initialsSize]="2"
          [round]="true"
        >
        </ngx-avatars>
        <div @fadeInRight>
          <p class="font-bold text-sm text-primary mb-5 rtl:ml-3">
            {{ closerDetail?.fullName }}
          </p>
          <app-boolean-view
            *ngIf="closerDetail?.departmentName"
            [value]="!closerDetail?.departmentName"
            [falseColor]="'#131C4E'"
            [width]="'165px'"
            [height]="'30px'"
            [matTooltip]="closerDetail?.departmentName"
            [text]="trimedDepartments"
          >
          </app-boolean-view>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-4">
  <app-action
    *ngIf="data"
    [sourceId]="data?.id"
    [sourceType]="ReportingType.Hazard"
  ></app-action>
</div>
<!-- History -->
<div class="mt-4">
  <app-expandable-panel
    [expanded]="true"
    #expandablePanel
    [title]="'Hazards.HazardHistory' | translate"
  >
    <app-history-log
      body
      *ngIf="data"
      [sourceId]="data?.id"
      [sourceType]="HistoryType"
    ></app-history-log>
  </app-expandable-panel>
</div>

<ng-template #imageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="selectedImg?.imageUrl" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">{{ data?.title }}</h2>
    <button
      class="text-secondary"
      (click)="downloadDocument(selectedImg?.imageUrl)"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:download"></mat-icon>
    </button>

    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex">
    <div class="">
      <img [src]="selectedImg?.imageUrl" [alt]="data?.title" />
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
  </div>
</ng-template>
