<div (keyup.escape)="close()" [class.show]="show$ | async" class="search">
  <button (click)="close()"
          class="ltr:right-12 rtl:left-12 top-12 absolute"
          color="primary"
          mat-icon-button
          type="button">
    <mat-icon svgIcon="mat:cancel_presentation"></mat-icon>
  </button>
  <div class="relative">
    <input #searchInput (keyup.enter)="search()" [formControl]="searchCtrl" class="search-input" [placeholder]="'General.SearchForAnything' | translate"/>
    <mat-icon *ngIf="searchCtrl.value" 
    class="cursor-pointer absolute right-1/2 top-0" 
    svgIcon="mat:close" 
    [matTooltip]="'Clear Search'"
    (click)="clearSearch()"></mat-icon>
  </div>
  <div class="search-hint">{{'General.HitEnterToSearch' | translate}}</div>
</div>

<div (click)="close()" *ngIf="show$ | async" class="search-overlay"></div>

