import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { CompanyUserStatus, SortDirection } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { GetAllDepartments } from 'src/backend/models/departments/departments.model';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { AddDepartmentComponent } from '../add-department/add-department.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from 'src/backend/services/permission.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
@UntilDestroy()
@Component({
  selector: 'app-view-department',
  templateUrl: './view-department.component.html',
  styleUrls: ['./view-department.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms],
})
export class ViewDepartmentComponent implements OnInit {
  @Input() departmentInfo: GetAllDepartments;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('viewSubDepartmentModal') viewSubDepartmentModal: TemplateRef<any>;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public info: GetAllDepartments;
  departments: GetAllDepartments[];
  columns: TableColumn<GetAllDepartments>[] = [
    {
      label: 'Name',
      property: 'name',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'DepartmentCode',
      property: 'code',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Users',
      property: 'users',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Representor',
      property: 'representor',
      type: 'text',
      visible: true,
    },
    {
      label: 'UpdatedAt',
      property: 'updatedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  companyUserStatus = CompanyUserStatus;
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<GetAllDepartments>([]);
  selection = new SelectionModel<GetAllDepartments>(true, []);
  searchCtrl = new UntypedFormControl();
  public selectedDepartmentStatus: string[] = [];
  searchValue: string = '';
  loadingLabel: string = '';
  timer = null;
  public departmentId: number = 0;
  public selectedSubDepartment: any;
  private destroy$ = new Subject();

  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };

  private translationsList: any = {};

  hasWritePermissions = ApplicationPermission.DEPARTMENTS_CREATE;
  hasEditPermissions = ApplicationPermission.DEPARTMENTS_UPDATE;
  hasActivatePermissions = ApplicationPermission.DEPARTMENTS_ACTIVATE;
  constructor(
    private departmentService: DepartmentsService,
    private feedBackService: AsyncFeedbackService,
    private dialog: MatDialog,
    private permissionService: PermissionService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute
  ) {
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'ConfirmDeleteRecord',
        'StaticPages',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;

    this.pagination.pageIndex = 0;
    this.pagination.pageSize = 10;

    this.selectedDepartmentStatus = [];

    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.pagination.pageIndex = 0;
      this.getSubDepartmentList();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));

    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id = params.get('id') as string;
        this.departmentId = parseInt(id, 10);
        this.getDepartmentDetails();
        this.getSubDepartmentList();
      }
    });
  }

  getDepartmentDetails(): void {
    this.sectionState = SectionStateStatus.Loading;
    this.departmentService.getDepartmentById(this.departmentId).subscribe({
      next: (response) => {
        this.info = response;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }

  getSubDepartmentList(): void {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: sortDirection,
      sortField,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
      searchValue: this.searchValue,
      status:
        this.selectedDepartmentStatus.length > 0
          ? this.selectedDepartmentStatus.toString()
          : null,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.departmentService
      .getSubDepartmentList(filteredParams, this.departmentId)
      .subscribe({
        next: (response) => {
          this.departments = response?.data;
          this.dataSource = new MatTableDataSource(response?.data);
          this.pagination.length = response?.totalCount;
          this.dataSource.sort = this.sort;
          this.sectionState = SectionStateStatus.Ready;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  createSubDepartment() {
    this.dialog
      .open(AddDepartmentComponent, {
        height: 'auto',
        width: '40%',
        data: {
          departmentId: this.departmentId,
        },
      })
      .afterClosed()
      .subscribe((department: GetAllDepartments) => {
        this.getSubDepartmentList();
      });
  }

  viewSubDepartment(department: GetAllDepartments) {
    this.selectedSubDepartment = department;
    this.dialog.open(this.viewSubDepartmentModal, {
      height: 'auto',
      width: '40%',
    });
  }

  updateDepartment(event: any, department: GetAllDepartments) {
    this.dialog
      .open(AddDepartmentComponent, {
        height: 'auto',
        width: '40%',
        data: {
          defaults: department,
          departmentId: this.departmentId,
        },
      })
      .afterClosed()
      .subscribe((updatedUser) => {
        this.getSubDepartmentList();
      });
  }

  deactivateDepartment(event: any, id: number): any {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.departmentService.deActivate(id).subscribe({
            next: (res) => {
              this.getSubDepartmentList();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Failure,
                  this.translationsList['Errors']['ErrorHappened']
                )
              );
            },
          });
        }
      });
  }
  activateDepartment(event: any, id: number): any {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.departmentService.activate(id).subscribe({
            next: (res) => {
              this.getSubDepartmentList();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
            },
            error: (error) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Failure,
                  this.translationsList['Errors']['ErrorHappened']
                )
              );
            },
          });
        }
      });
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  onSelectStatus(event: MatSelectChange) {
    this.selectedDepartmentStatus = event.value;
    this.getSubDepartmentList();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;

    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getSubDepartmentList();
    }, 700);
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;

    this.getSubDepartmentList();
  }
}
