import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class SafetyKpisDashboardsService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getSafetyTriangleDashboard(data): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_SAFETY_TRIANGLE_DASHBOARD),
      data
    );
  }
}
