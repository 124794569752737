import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { WorkPermit } from 'src/app/work-permit/models/work-permit';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class WorkPermitService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  createWorkPermit(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMIT_CREATE),
      data
    );
  }

  getWorkPermitsByCompany(data: any): Observable<DataMapper<WorkPermit>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.workConditionIds) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'workConditionIds=' + data?.workConditionIds;
    }
    return this.http.get<DataMapper<WorkPermit>>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMITS + '?' + params)
    );
  }

  getWorkPermitById(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMIT_BY_ID + '/' + id)
    );
  }

  deleteWorkPermitImage(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMIT_REMOVE_IMG + '/' + id)
    );
  }

  editWorkPermit(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMIT_EDIT),
      data
    );
  }
  approveOrRejectWorkPermit(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMIT_APPROVE_REJECT),
      data
    );
  }

  saveExtendDate(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMIT_SAVE_EXTEND_DATE),
      data
    );
  }

  approveExtendDate(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMIT_APPROVE_EXTEND_DATE),
      data
    );
  }
  rejectExtendDate(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMIT_REJECT_EXTEND_DATE),
      data
    );
  }
  closeWorkPermit(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMIT_CLOSE),
      data
    );
  }
  closeWorkPermitRequest(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_WORK_PERMIT_CLOSE_REQUEST + '/' + id
      ),
      {}
    );
  }
  ExtendTimeRequired(id: number): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(
        Constants.API_WORK_PERMIT_EXTENDTIME_REQUIRED + '/' + id
      ),
      {}
    );
  }

  removeWorkPermit(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(Constants.API_WORK_PERMIT_DELETE + '/' + id)
    );
  }
  getApproverByCompany() {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(Constants.API_APPROVER_USER_LIST)
    );
  }
}
