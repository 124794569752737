import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';

@Component({
  selector: 'app-car-access-information',
  templateUrl: './car-access-information.component.html',
  styleUrls: ['./car-access-information.component.scss']
})
export class CarAccessInformationComponent implements OnInit {

  userForm: FormGroup;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  constructor() { }

  ngOnInit() {
  }

}
