<div class="hodhod-user-menu">
  <!-- <a (click)="close()" class="hodhod-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="hodhod-user-menu-item__icon icon-sm" svgIcon="mat:account_circle"></mat-icon>
    <div class="hodhod-user-menu-item__label">Your Profile</div>
  </a> -->

  <a
    (click)="close()"
    class="hodhod-user-menu-item"
    matRipple
    matRippleColor="rgb(var(--color-primary), 0.1)"
  >
    <mat-icon
      class="hodhod-user-menu-item__icon icon-sm"
      svgIcon="mat:insights"
    ></mat-icon>
    <div class="hodhod-user-menu-item__label">Analytics</div>
    <div class="hodhod-user-menu-item__badge">Enterprise</div>
  </a>

  <!-- <a (click)="close()" class="hodhod-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="hodhod-user-menu-item__icon icon-sm" svgIcon="mat:settings"></mat-icon>
    <div class="hodhod-user-menu-item__label">Account Settings</div>
  </a> -->

  <div class="border-b border-divider mx-4"></div>

  <!-- <div class="hodhod-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)"
    (click)="onClickSwitchAcc()">
    <mat-icon class="hodhod-user-menu-item__icon icon-sm" svgIcon="mat:switch_account"></mat-icon>
    <div class="hodhod-user-menu-item__label">Switch Account</div>
    <mat-icon class="hodhod-user-menu-item__icon icon-sm" svgIcon="mat:chevron_right"></mat-icon>
  </div> -->

  <!-- <a (click)="close()" class="hodhod-user-menu-item" matRipple matRippleColor="rgb(var(--color-primary), 0.1)">
    <mat-icon class="hodhod-user-menu-item__icon icon-sm" svgIcon="mat:logout"></mat-icon>
    <div class="hodhod-user-menu-item__label">Sign Out</div>
  </a> -->
</div>
