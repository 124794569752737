<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'BreadCrumb.View' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div>
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'Role.RoleInfo' | translate"
      >
        <div body class="flex column">
          <app-view-role
            [roleName]="roleName"
            [isExternal]="isExternal"
            [roleId]="roleId"
            (onChangeExternal)="onChangeExternal($event)"
            (onChangeName)="onChangeName($event)"
          ></app-view-role>
        </div>
      </app-expandable-panel>
    </div>
    <div class="mt-5">
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'Role.ManageRolePermissions' | translate"
      >
        <div body class="flex column">
          <app-role-permission
            [permissions]="permissionBlocks"
            (savePermissionsEvent)="saveChangesPermissions($event)"
          >
          </app-role-permission>
        </div>
      </app-expandable-panel>
    </div>
    <div class="mt-5">
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'User.ListOfUsers' | translate"
      >
        <div body class="flex column">
          <app-users-in-role [users]="roleUsers"></app-users-in-role>
        </div>
      </app-expandable-panel>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
