import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { SortDirection } from 'src/@hodhod/common/enum';
import { BaseApi } from 'src/backend/api/base-api';
import {
  GetAllVoilations,
  DataListDtoVM,
  ViolationDashboardListDto,
  GetFilterdDashboardData,
} from 'src/backend/models/voilations/voilation.model';

@Injectable({
  providedIn: 'root',
})
export class VoilationsService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getAllVoilations(data: any): Observable<DataMapper<GetAllVoilations>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    // if (data?.statusClose) {
    //   if (params.length > 0) {
    //     params += '&';
    //   }
    //   params += `statusClose=` + data?.statusClose;
    // }
    if (data?.status) {
      data?.status.forEach((statusItem, index) => {
        if (params.length > 0) {
          params += '&';
        }
        params += `status${statusItem}=` + statusItem;
      });
    }
    // if (data?.statusNew) {
    //   if (params.length > 0) {
    //     params += '&';
    //   }
    //   params += `statusNew=` + data?.statusNew;
    // }
    if (data?.voilationNames) {
      if (params.length > 0) {
        params += '&';
      }
      params += `violationNames=` + data?.voilationNames;
    }
    if (data?.departmentNames) {
      if (params.length > 0) {
        params += '&';
      }
      params += `departmentNames=` + data?.departmentNames;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.companyCode) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'companyId=' + data?.companyCode;
    }
    return this.http.get<DataMapper<GetAllVoilations>>(
      this.baseApi.encodeURL(
        Constants.API_VOILATIONS_GET_ALL + '/' + data?.cameraId + '?' + params
      )
    );
  }

  getVoilationTypes(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_VOILATIONS_GET_VOILATION_NAMES)
    );
  }

  deleteVoilation(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_VOILATIONS_DELETE_VOILATION),
      data
    );
  }

  updateVoilation(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_VOILATIONS_UPDATE_VOILATION),
      data
    );
  }

  getVoilationById(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_VOILATIONS_GET_VOILATION_BY_ID + '/' + id
      )
    );
  }

  getVoilationDashboardData(data: any): Observable<DataListDtoVM> {
    return this.http.post<DataListDtoVM>(
      this.baseApi.encodeURL(Constants.API_VIOLATION_DASHBOARD_DATA),
      data
    );
  }

  getFilterdDashboardData(data: any): Observable<GetFilterdDashboardData> {
    return this.http.post<GetFilterdDashboardData>(
      this.baseApi.encodeURL(Constants.API_VIOLATION_DASHBOARD_FILTERED_DATA),
      data
    );
  }

  getDashboardChartsByChartName(data: any): Observable<DataListDtoVM> {
    return this.http.post<DataListDtoVM>(
      this.baseApi.encodeURL(
        Constants.API_VIOLATION_DASHBOARD_CHART_FILTERED_DATA
      ),
      data
    );
  }
}
