import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { DashboardTwoComponent } from './pages/dashboard-two/dashboard-two.component';
import { EditDashboardTargetComponent } from '../dashboard/edit-dashboard-target/edit-dashboard-target.component';
import { AddDashboardTargetComponent } from '../dashboard/add-dashboard-target/add-dashboard-target.component';
import { DashboardTargetComponent } from '../dashboard/dashboard-target/dashboard-target.component';
import { MultiPermissionGuard } from '../shared/guards/MultiPermission.guard';
import { DashboardSettingsComponent } from '../dashboard/pages/dashboard-settings/dashboard-settings.component';
import { DefineSafetyTriangleLevelsComponent } from '../dashboard/components/define-safety-triangle-levels/define-safety-triangle-levels.component';
import { SafemanHoursComponent } from './components/safeman-hours-settings/safeman-hours.component';
import { SafetyKpiSetupComponent } from '../dashboard/pages/safety-kpi-setup/safety-kpi-setup.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardTwoComponent,
    canActivate: [MultiPermissionGuard],
    data: {
      //  permissions: PredefinedPermission.VOILATIONS_INFO_PERMISSION,
      permissions: [
        ApplicationPermission.DASHBOARD_VIEW,
        ApplicationPermission.VIDEO_DASHBOARD_VIEW,
        ApplicationPermission.IOT_DASHBOARD_ACCESS,
        ApplicationPermission.SAFETY_KPIS_DASHBOARD_ACCESS,
      ],
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'Dashboard',
      },
    },
  },
  {
    canActivate: [MultiPermissionGuard],
    path: SharedConstants.DASHBOARD_TARGET,
    component: DashboardSettingsComponent,
    data: {
      permissions: [
        ApplicationPermission.DASHBOARD_TARGET_VIEW,
        ApplicationPermission.SAFETY_KPIS_MANAGEMENT_ACCESS,
      ],
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'dashboardTarget',
      },
    },
  },
  {
    path:
      SharedConstants.DASHBOARD_TARGET +
      '/' +
      SharedConstants.ADD_DASHBOARD_TARGET,
    component: AddDashboardTargetComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'Add',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.DASHBOARD_TARGET + '/' + SharedConstants.SAFEMAN_HOURS+'/:id',
    component: SafemanHoursComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.DASHBOARD_TARGET +
      '/' +
      SharedConstants.SAFETY_KPI_SETUP +
      '/:id' +
      '/:type',
    component: SafetyKpiSetupComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
  {
    path:
      SharedConstants.DASHBOARD_TARGET +
      '/' +
      SharedConstants.DEFINE_SAFETY_TRIANGLE+
      '/:id',
    component: DefineSafetyTriangleLevelsComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },

  {
    path:
      SharedConstants.DASHBOARD_TARGET +
      '/' +
      SharedConstants.VIEW_DASHBOARD_TARGET +
      '/:id' +
      '/:mode',
    component: EditDashboardTargetComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.DASHBOARD_TARGET_VIEW,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardTwoRoutingModule {}
