import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { NearMissReportService } from 'src/backend/services/reportings/near-miss-report.service';

@Component({
  selector: 'app-near-miss-report-edit',
  templateUrl: './near-miss-report-edit.component.html',
  styleUrls: ['./near-miss-report-edit.component.scss'],
})
export class NearMissReportEditComponent implements OnInit {
  public nearMissReport: any;
  public nearMissId: any;

  constructor(
    private nearMissService: NearMissReportService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.nearMissId = params['id'];
      this.getNearMissReport();
    });
  }

  updateNearMissReport(payload) {
    const formData = new FormData();
    const {
      title,
      rootCause,
      shift,
      severity,
      department,
      cause,
      description,
      typeOfReportIds,
      witness,
      attachments,
      subDepartment,
    } = payload;
    const data = {
      id: this.nearMissId,
      title: title,
      shift: shift,
      saverity: severity,
      description: description,
      departmentId: subDepartment ? subDepartment : department,
      rootCauseId: rootCause,
      causeId: cause,
      witness: witness ? witness.toString() : '',
      typeOfReportIds: typeOfReportIds.toString(),
    };
    formData.append('content', JSON.stringify(data));
    attachments.forEach((element) => {
      formData.append('files', element);
    });
    this.loadingService.startLoading(true);
    this.nearMissService.editNearMissReport(formData).subscribe({
      next: (response) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, response?.message)
        );
        this.loadingService.stopLoading();
        this.router.navigate([
          '/' +
            SharedConstants.REPORTING_MANAGEMENT +
            '/' +
            SharedConstants.REPORT_NEAR_MISS,
        ]);
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  getNearMissReport(): void {
    this.loadingService.startLoading(true);
    this.nearMissService.getNearMissReportById(this.nearMissId).subscribe({
      next: (response) => {
        this.nearMissReport = response;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
}
