import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import {
  ActionStatus,
  HistoryTypes,
  ReportHazardStatus,
  ReportingType,
} from 'src/@hodhod/common/enum';
import { ReportHazard } from '../../models/report-hazard';
import { ReportHazardImage } from '../../models/report-hazard-image';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import { ActionTrackerService } from 'src/backend/services/action-tracker/action-tracker.service';
import { Action } from 'src/app/action-tracker/models/action';
import { UserService } from 'src/backend/services/user.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { HazardReportService } from 'src/backend/services/reportings/hazard-report.service';
import {
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { tap } from 'rxjs/operators';
import { LanguageService } from 'src/@hodhod/services/language.service';
@Component({
  selector: 'app-hazard-report-view',
  templateUrl: './hazard-report-view.component.html',
  styleUrls: ['./hazard-report-view.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms],
})
export class HazardReportViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() hazardReport!: ReportHazard;
  @Output() onCloseReport = new EventEmitter<any>();
  public data: ReportHazard;
  public selectedImg: ReportHazardImage;
  actions: Action[];
  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  @ViewChild('pdf') pdf!: ElementRef;
  ReportingType = ReportingType;
  HistoryType = HistoryTypes.REPORT_HAZARD;
  closerDetail: any;
  ReportHazardStatus = ReportHazardStatus;
  hasEditPermissions = ApplicationPermission.REPORTING_HAZARDS_UPDATE;
  hasSpecialPermissions = ApplicationPermission.REPORTING_HAZARDS_SPECIAL;

  company: any;
  workFlow: WorkFlow;
  companyKey: string = '';
  instanceKey: string = '';
  private translationsList: any = {};
  private destroy$ = new Subject();
  public loggedUser: LoggedUser;
  public trimedDepartments: any;

  reportTypes: string = '';
  public rtl: string;

  constructor(
    private dialog: MatDialog,
    private trackerService: ActionTrackerService,
    private userService: UserService,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private router: Router,
    private route: ActivatedRoute,
    private workFlowService: WorkFlowService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private hazardReportService: HazardReportService,
    private baseApi: BaseApi,
    private langService: LanguageService
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.company = this.baseApi.getCompanyFromStorage();
    this.langService.currentLanguage$.subscribe((language) => {
      this.rtl = language.isoCode;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['hazardReport']) {
      this.companyKey = this.baseApi.getCompanyIdFromStorage();
      this.loggedUser = this.baseApi.getUserSession();
      this.data = this.hazardReport;
      if (this.data) {
        this.reportTypes = this.data.typeOfReport
          .map((item) => item.typeName)
          .toString();
        this.getWorkFlow();
      }
      this.getActionById();
      this.getCloserDetail();
    }
    this.getActionById();
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_HAZARD_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
        this.getWorkflowInstanceKeyById(this.workFlow.workFlowKey);
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getWorkflowInstanceKeyById(workflowKey: string) {
    this.workFlowService
      .getInstanceKeyByTableId(workflowKey, this.data?.id)
      .subscribe({
        next: (res) => {
          this.data.instanceKey = res?.instanceKey
            ? res?.instanceKey
            : this.data?.instanceKey;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  openBigView(id: number): void {
    const img = this.data?.images.find((x) => x.id === id);
    if (img) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
      const extension = img.imageUrl.split('.').pop();
      if (imageExtensions.includes(extension)) {
        this.selectedImg = img;
        this.dialog.open(this.imageBigView, {
          maxHeight: '95vh',
          width: 'auto',
        });
      } else {
        const downloadTag = document.createElement('a');
        downloadTag.href = img.imageUrl;
        downloadTag.addEventListener('click', () => {
          downloadTag.download;
        });
        downloadTag.click();
      }
    }
  }

  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }
  downloadDocument(url: any): void {
    const downloadTag = document.createElement('a');
    downloadTag.href = url;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }
  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }
  getCloserDetail(): void {
    if (this.data?.closerId) {
      this.userService.getUserDetails(this.data?.closerId).subscribe({
        next: (response) => {
          this.closerDetail = response;
          this.trimedDepartments =
            this.closerDetail?.departmentName?.substring(0, 10) + ' ...';
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          // this.loadingService.stopLoading();
        },
      });
    }
  }

  getActionById() {
    const data = {
      sourceId: this.data?.id,
      SourceType: ReportingType.Hazard,
    };
    this.trackerService.getActionById(data).subscribe({
      next: (res) => {
        this.actions = res?.data;
      },
    });
  }
  observActionById() {
    const data = {
      sourceId: this.data?.id,
      SourceType: ReportingType.Hazard,
    };
    return this.trackerService.getActionById(data);
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }

  printHazardReport() {
    if (this.data) {
      this.observActionById()
        .pipe(
          tap((res) => {
            this.actions = res?.data;
          })
        )
        .subscribe(() => {
          this.takePrint(this.data);
        });
    }
  }

  async takePrint(data: any): Promise<void> {
    const doc = new jsPDF({
      orientation: 'portrait',
    }).setProperties({ title: 'String Splitting' });
    doc.addFont('assets/fonts/Amiri-Regular.ttf', 'Amiri', 'normal');
    doc.setFont('Amiri', 'italic', 'bold');
    const date = moment(data?.updatedOn)
      .local(true)
      .format('MMM DD YYYY hh mm A');
    let y = 5;

    // Logo and Company Data
    const companyLogo =
      JSON.parse(localStorage.getItem('company')).companyImage || '';
    if (companyLogo) {
      doc.addImage(companyLogo, 'PNG', 10, y, 20, 20); // Adjust positioning and size as needed
      doc.text(
        JSON.parse(localStorage.getItem('company')).companyName,
        35,
        y + 10
      );
      y += 45;
    } else {
      doc.text(JSON.parse(localStorage.getItem('company')).companyName, 10, y, {
        align: 'center',
      });
      y += 20;
    }
    // Title Line
    doc.setLineWidth(1);
    doc.line(10, 30, 200, 30);
    doc.addImage(
      '../../../../assets/img/resources/saned-white-logo.png',
      'PNG',
      140,
      24,
      40,
      10
    );
    y += 10;
    // Report Title
    doc.setFontSize(18);
    doc.setFont('Amiri', 'normal');
    doc.text(this.rtl === 'ar' ? 'تقرير الخطر' : 'Report Hazard', 105, 40, {
      align: 'center',
    });
    doc.setFont('Amiri', 'normal');
    //Details
    function addTextWithOverflow(
      doc,
      text,
      x,
      y,
      maxWidth,
      rtl,
      pageHeight,
      bottomMargin,
      desc?
    ) {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line) => {
        if (y + 10 > pageHeight - bottomMargin) {
          doc.addPage();
          y = 10;
        }
        if (desc) {
          if (checkIfArabic(line)) {
            doc.text(line, 190, y, { align: 'right' });
          }else{
            doc.text(line, x, y); 
          }
        } else {
          doc.text(line, x, y);
        }
        y += 5;
      });
      return y;
    }
    function checkIfArabic(text: string) {   
      if(text.includes('ا' || 'أ' || 'ل')){
        return true
      }else{
        return false
      }
    }
    const details = [
      {
        label: 'Title',
        arLabel: 'العنوان',
        value: data?.title,
        x: 12,
        yOffset: 0,
      },
      {
        label: 'Department',
        arLabel: 'القسم',
        value: data?.parentDepartment || data?.department,
        x: 12,
        yOffset: 10,
      },
      {
        label: 'Sub Department',
        arLabel: 'القسم الفرعي',
        value: data?.parentDepartment ? data?.department : '--',
        x: 12,
        yOffset: 20,
      },
      {
        label: 'Reported At',
        arLabel: 'تحول في',
        value: date,
        x: 12,
        yOffset: 30,
      },
      {
        label: 'Shift',
        arLabel: 'الوردية',
        value: data?.shift,
        x: 12,
        yOffset: 40,
      },
    ];
    const secDetails = [
      {
        label: 'Status',
        arLabel: 'الحالة',
        value: data?.status,
        x: 110,
        yOffset: 0,
        rect: true,
        rectColor: '#C1E6DA',
        textColor: '#046240',
      },
      {
        label: 'Root Cause',
        arLabel: 'السبب الجذري',
        value: data?.rootCause,
        x: 110,
        yOffset: 10,
      },
      {
        label: '6M Classification',
        arLabel: 'تصنيف العصف الذهني',
        value: data?.cause,
        x: 110,
        yOffset: 20,
      },
      {
        label: 'Type of Hazard',
        arLabel: 'نوع الخطر',

        value: this.reportTypes,
        x: 110,
        yOffset: 30,
      },
      {
        label: 'Reported By',
        arLabel: 'تم بواسطة',
        value: data?.reportedBy,
        x: 110,
        yOffset: 40,
      },
    ];
    doc.setFontSize(12);
    let Y = y;
    let pageHeight = doc.internal.pageSize.height;
    let bottomMargin = 10;
    const lineHeight = 5;
    const maxLength = Math.max(details.length, secDetails.length);

    for (let i = 0; i < maxLength; i++) {
      if (i < details.length) {
        doc.setTextColor('#131C4E');
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          8,
          y - 3,
          5,
          5
        );
        if (this.rtl === 'ar') {
          doc.text(details[i].arLabel, 15, y);
        } else {
          doc.text(details[i].label, 15, y);
        }
        y += 5;
        doc.setTextColor('black');
        y = addTextWithOverflow(
          doc,
          details[i].value || '',
          15,
          y,
          90,
          this.rtl,
          pageHeight,
          bottomMargin
        );
        y += 5;
      }

      if (i < secDetails.length) {
        doc.setTextColor('#131C4E');
        doc.addImage(
          'assets/img/resources/pdfArrow.png',
          'PNG',
          103,
          Y - 3,
          5,
          5
        );
        if (this.rtl === 'ar') {
          doc.text(secDetails[i].arLabel, 110, Y);
        } else {
          doc.text(secDetails[i].label, 110, Y);
        }
        Y += 5;
        doc.setTextColor('black');
        Y = addTextWithOverflow(
          doc,
          secDetails[i].value || '',
          110,
          Y,
          90,
          this.rtl,
          pageHeight,
          bottomMargin
        );
        Y += 5;
      }
    }

    // Description
    doc.setTextColor('#131C4E');
    doc.addImage('assets/img/resources/pdfArrow.png', 'PNG', 8, y - 3, 5, 5);
    if (this.rtl === 'ar') {
      doc.text('الوصف', 15, y);
    } else {
      doc.text('Description', 15, y);
    }
    doc.setTextColor('black');
    doc.setFontSize(12);
    y += 5;
    y = addTextWithOverflow(
      doc,
      data?.description || '',
      15,
      y,
      180,
      this.rtl,
      pageHeight,
      bottomMargin,
      true
    );
    y += 10;
    // Images Section

    if (data?.images.length > 0) {
      doc.setFontSize(18);
      if (this.rtl === 'ar') {
        doc.text('الملفات', 103, y);
      } else {
        doc.text('Documents', 103, y);
      }
      y += 5;
      let x = 10;
      let count = 0;
      const pageWidth = doc.internal.pageSize.width - 20; // Adjust for margins
      const imageWidth = 25;
      const imageHeight = 25;
      const spacing = 10;

      for (let i = 0; i < data?.images.length; i++) {
        const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
        const extension = data?.images[i].imageUrl.split('.').pop();
        let imgData = null;
        if (imageExtensions.includes(extension)) {
          imgData = data?.images[i].imageUrl;
        } else {
          imgData = await this.getBase64ImageFromURL(
            this.getAttachmentImage(data?.images[i].imageUrl)
          );
        }
        if (imgData) {
          if (x + imageWidth > pageWidth) {
            x = 10;
            y += imageHeight + spacing;
          }

          // Add the image to the PDF
          doc.addImage(imgData, 'JPEG', x, y, imageWidth, imageHeight);

          // Add clickable annotation
          doc.link(x, y, imageWidth, imageHeight, {
            url: data?.images[i].imageUrl,
          });

          x += imageWidth + spacing;
          count++;
        }
      }
      y += imageHeight + spacing;
      if (y + 10 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }
    }

    // Actions Section
    y += 20;
    if (this.actions.length > 0) {
      if (y + 30 > pageHeight - bottomMargin) {
        doc.addPage();
        y = 10;
      }

      doc.setFontSize(18);
      if (this.rtl === 'ar') {
        doc.text('الأجرائات', 105, y);
      } else {
        doc.text('Actions', 105, y);
      }
      y += 5;

      doc.setLineWidth(0.5);
      doc.line(10, y, 200, y);
      y += 5;

      doc.setFontSize(12);
      doc.setTextColor('black');

      const generateData = (actions) => {
        return actions.map((action, index) => ({
          description: action.title,
          responsibility: action.assignToName,
          priority: action.priority,
          targetDate: moment
            .utc(action.targetDate)
            .local(true)
            .format('MMM DD yyyy'),
          closingDate:
            action.actionStatus === ActionStatus.CLOSE
              ? moment.utc(action.closedDate).local(true).format('MMM DD yyyy')
              : '',
          status: action.actionStatus,
        }));
      };

      // Create Headers
      const createHeaders = (keys) => {
        return keys.map((key) => ({
          id: key,
          name: key,
          prompt: key.replace(/([A-Z])/g, ' $1').trim(),
          width: 40,
          align: 'center',
          padding: 0,
        }));
      };

      // Define Headers
      let headers = createHeaders([
        'Description',
        'Responsibility',
        'Priority',
        'Target Date',
        'Closing Date',
        'Status',
      ]);
      if (this.rtl === 'ar') {
        headers = createHeaders([
          'الوصف',
          'المسؤل',
          'الأفضلية',
          'تاريخ التسليم',
          'تاريخ الانتهاء',
          'الحاله',
        ]);
      }

      // Generate table data
      const tableData = generateData(this.actions);
      autoTable(doc, {
        startY: y,
        head: [headers.map((header) => header.prompt)],
        body: tableData.map((row) => Object.values(row)),
        styles: { fontSize: 12, font: 'Amiri' },
      });
    }
    doc.save(`report-hazard-${data?.id}.pdf`);
  }

  goBack(): void {
    this.route.queryParams.subscribe((params) => {
      this.router.navigate(
        [
          SharedConstants.REPORTING_MANAGEMENT +
            '/' +
            SharedConstants.REPORT_HAZARD,
        ],
        {
          queryParams: params,
        }
      );
    });
  }

  editReportHazard(): void {
    this.router.navigate([
      SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_HAZARD +
        '/' +
        SharedConstants.REPORT_HAZARD_EDIT +
        '/' +
        this.data?.id,
    ]);
  }

  closeHazard(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.setWorkFlowInstance(
            this.data?.instanceKey ? this.data?.instanceKey : '',
            this.data?.id
          );
        }
      });
  }

  closeReport(id: number): void {
    this.hazardReportService.closeHazardReport(id).subscribe({
      next: (response) => {
        this.onCloseReport.emit(id);
        this.data = null;
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  setWorkFlowInstance(instanceKey: string, id: number): void {
    const data: SetInstanceParam = {
      instanceKey: instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_CLOSE_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    this.loadingService.startLoading(true, '');
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.closeReport(id);
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  convertUtcToLocal(date) {
    return moment.utc(date).local().format('MMM DD YYYY hh mm A');
  }
}
