<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.Reportings' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div class="card p-7 rounded-xl">
      <h2 class="title">
        {{ "ReportedIssue.reportingCardTitle" | translate }}
      </h2>
      <div class="cardSection pb-5">
        <app-card-type
          class="card-type"
          *ngIf="hasHazardViewPermission | permissionChecker"
          [title]="'Enum.ReportingType.HAZARD'|translate"
          [imgSrc]="'assets/img/resources/report-hazard.svg'"
          (onClick)="onHazardClick($event)"
        >
        </app-card-type>
        <app-card-type
          class="card-type"
          *ngIf="hasNearmissViewPermission | permissionChecker"
          [title]="'Enum.ReportingType.NEAR_MISS'|translate"
          [imgSrc]="'assets/img/resources/report-near-miss.svg'"
          (onClick)="onNearMissClick($event)"
        >
        </app-card-type>
        <app-card-type
          class="card-type"
          *ngIf="hasIncidentViewPermission | permissionChecker"
          [title]="'Enum.ReportingType.INCIDENT'|translate"
          [imgSrc]="'assets/img/resources/report-incident.svg'"
          (onClick)="onIncidentClick($event)"
        >
        </app-card-type>
      </div>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
