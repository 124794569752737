<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.AddReportIncident' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-incident-report-form
      [selectedIncident]="incidentReport"
      (formSubmit)="updateIncidentReport($event)"
    ></app-incident-report-form>
  </hodhod-page-layout-content>
</hodhod-page-layout>
