<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.AddReportNearMiss' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div>
      <app-near-miss-report-form
        (formSubmit)="createNearMissReport($event)"
      ></app-near-miss-report-form>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
