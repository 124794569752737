<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.AddReportHazard' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div>
      <app-expandable-panel
        [expanded]="true"
        #expandablePanel
        [title]="'AddReportHazard.ReportHazard' | translate"
      >
        <div body class="flex column">
          <app-hazard-report-form
            [selectedHazard]="hazardReport"
            (formSubmit)="updateHazardReport($event)"
          ></app-hazard-report-form>
        </div>
      </app-expandable-panel>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
