<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.dashboardTarget' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <mat-tab-group
      class="dashboard-tabs"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab
        *ngIf="hasTargetAccessPermission | permissionChecker"
        class="tab-btn"
        [label]="'Dashboards.SmartSafety' | translate"
      >
        <app-dashboard-target></app-dashboard-target>
      </mat-tab>
      <mat-tab
        *ngIf="hassafetyKpisAccessPermission | permissionChecker"
        class="tab-btn"
        [label]="'Dashboards.SafetyKPIs' | translate"
      >
        <app-safety-kpis></app-safety-kpis>
      </mat-tab>
    </mat-tab-group>
  </hodhod-page-layout-content>
</hodhod-page-layout>
