import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { ReportingTypes } from 'src/@hodhod/common/enum';
import { Router } from '@angular/router';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { ReasonsService } from 'src/backend/services/reasons/reasons.service';
import { Shift } from 'src/app/company-setup/models/shift';
import { ShiftsService } from 'src/backend/services/shifts/shifts.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { RootCauseService } from 'src/backend/services/root-cause/root-cause.service';
import { IncidentCategoryService } from 'src/backend/services/incident-category/incident-category.service';
import { ReportHazard } from '../../models/report-hazard';
import { ReportHazardImage } from '../../models/report-hazard-image';
import { HazardReportService } from 'src/backend/services/reportings/hazard-report.service';
@Component({
  selector: 'app-hazard-report-form',
  templateUrl: './hazard-report-form.component.html',
  styleUrls: ['./hazard-report-form.component.scss'],
})
export class HazardReportFormComponent implements OnInit, OnChanges {
  @Input() selectedHazard: ReportHazard | null = null;
  @Output() formSubmit = new EventEmitter<any>();
  shifts: Shift[];
  hazardForm: FormGroup;
  public images: ReportHazardImage[] = [];
  public selectedImg: ReportHazardImage;
  private destroy$ = new Subject();
  public translationsList: any = {};

  departments: any[] = [];
  subDepartments: any[] = [];
  rootCauses: any[] = [];
  lossModes: any[] = [];
  reasons: any[] = [];
  hazardTypes: any[] = [];

  reportType = ReportingTypes.Hazard;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  hasCreateReasonsPermission =
    ApplicationPermission.CONTENT_REPORTING_REASONS_CREATE;
  hasCreateShiftsPermission = ApplicationPermission.SHIFT_CREATE;
  hasDepartmentPermission = ApplicationPermission.DEPARTMENTS_CREATE;
  hasRootCauseCreatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_CREATE;
  hasRootCauseUpdatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_UPDATE;

  hasCreateTypeOfIncidentsPermission =
    ApplicationPermission.CONTENT_REPORTING_INCIDENTCATEGORY_CREATE;

  constructor(
    private fb: FormBuilder,
    private feedBackService: AsyncFeedbackService,
    private translate: TranslateService,
    private router: Router,
    private departmentService: DepartmentsService,
    private reasonService: ReasonsService,
    private confirmationService: ConfirmationService,
    private shiftService: ShiftsService,
    private rootService: RootCauseService,
    private hazardReportService: HazardReportService,
    private incidentCategoryService: IncidentCategoryService
  ) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedHazard'].currentValue) {
      this.populateForm();
    }
  }
  ngOnInit() {
    this.initializeForm();
    this.loadDDLsData();
  }

  initializeForm() {
    this.hazardForm = this.fb.group({
      title: ['', [Validators.required, noWhitespaceValidator]],
      lossMode: [''],
      rootCause: [''],
      shift: ['', [Validators.required]],
      department: ['', [Validators.required]],
      subDepartment: [''],
      cause: ['', [Validators.required]],
      description: ['', [Validators.required, noWhitespaceValidator]],
      typeOfReportIds: ['', [Validators.required]],
      attachments: [[]],
    });
  }

  populateForm() {
    this.hazardForm.patchValue({
      title: this.selectedHazard?.title,
      lossMode: this.selectedHazard?.lossMode,
      rootCause: this.selectedHazard?.rootCauseId,
      shift: this.selectedHazard?.shift,

      cause: this.selectedHazard?.causeId,
      description: this.selectedHazard?.description,
    });

    if (this.selectedHazard?.departmentParentId) {
      this.hazardForm
        .get('department')
        ?.setValue(this.selectedHazard?.departmentParentId);
      this.onChangeDepartment({
        value: this.selectedHazard?.departmentParentId,
      });
      this.hazardForm
        .get('subDepartment')
        ?.setValue(this.selectedHazard?.departmentId);
    } else {
      this.onChangeDepartment({ value: this.selectedHazard?.departmentId });
      this.hazardForm
        .get('department')
        ?.setValue(this.selectedHazard?.departmentId);
    }

    this.hazardForm
      .get('typeOfReportIds')
      ?.setValue(this.selectedHazard?.typeOfReportIds?.split(',').map(Number));

    this.images = this.selectedHazard?.images;
  }

  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }

  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  openBigView(id: number): void {
    const img = this.selectedHazard?.images.find((x) => x.id === id);
    const downloadTag = document.createElement('a');
    downloadTag.href = img.imageUrl;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }

  deleteImage(img?: any): void {
    this.selectedImg = img;
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.hazardReportService
            .deleteHazardReportImage(this.selectedImg.id)
            .subscribe({
              next: (response) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, response?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
                this.images = this.images.filter(
                  (x) => x.id !== this.selectedImg.id
                );
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
        }
      });
  }

  loadDDLsData() {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    forkJoin({
      departments: this.departmentService.getDepartmentsParentChild(),
      shifts: this.shiftService.getActiveShifts(),
      hazardTypes: this.incidentCategoryService.getReportCategoriesByCompany(
        this.reportType
      ),
      rootCauses: this.rootService.getRootCauseByCompany(this.reportType),
      reasons: this.reasonService.getReasonsByCompany(this.reportType),
    }).subscribe((results) => {
      this.departments = results.departments;
      if (
        this.departments.length == 1 &&
        this.departments[0].parentName == '---Parent Department---' &&
        this.departments[0].detail == null
      ) {
        this.departments = [];
      }
      this.shifts = results.shifts;
      this.hazardTypes = results.hazardTypes;
      this.rootCauses = results.rootCauses;
      this.reasons = results.reasons;
      this.sectionState = SectionStateStatus.Ready;
    });
  }

  onSubmit() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.formSubmit.emit(this.hazardForm.value);
        }
      });
  }

  cancelForm(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['CancelForm']['Title'],
          this.translationsList['User']['CancelForm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.router.navigate([
            SharedConstants.REPORTING_MANAGEMENT +
              '/' +
              SharedConstants.REPORT_HAZARD,
          ]);
        }
      });
  }

  onChangeDepartment(event) {
    this.hazardForm.get('subDepartment').setValue('');
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.departmentService.getSubDepartmentsById(event.value).subscribe({
      next: (response) => {
        this.subDepartments = response;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
}
