<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.Dashboard' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container space-y-4">
    <h1 class="welcome-name">{{ "General.Hi" | translate }}, {{ userName }}</h1>
    <h6 class="welcome-text">{{ "General.what'sGoingOn" | translate }}</h6>
    <mat-tab-group
      class="dashboard-tabs"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab
        class="tab-btn"
        [label]="'Dashboards.SmartSafety' | translate"
        *ngIf="hasDashboardsAccess | permissionChecker"
      >
        <app-dashboard-smart-safety></app-dashboard-smart-safety>
      </mat-tab>
      <mat-tab
        class="tab-btn"
        [label]="'Dashboards.FireFightingMonitoring' | translate"
        *ngIf="hasIotAccess | permissionChecker"
      >
        <app-dashboard-iot [loadData]="loadIot"></app-dashboard-iot>
      </mat-tab>
      <mat-tab
        class="tab-btn"
        [label]="'NavigationBar.VideoAnalyticsDashboard' | translate"
        *ngIf="hasDigitalEyeAccess | permissionChecker"
      >
        <app-video-analytics-dashboard-page
          [loadDigital]="loadDigital"
        ></app-video-analytics-dashboard-page>
      </mat-tab>
      <mat-tab
        class="tab-btn"
        *ngIf="hasSafetyKpisAccess | permissionChecker"
        [label]="'Dashboards.SafetyKPIs' | translate"
      >
        <app-safety-kpi-dashboards
          [loadKPIs]="loadKPIs"
        ></app-safety-kpi-dashboards>
      </mat-tab>
    </mat-tab-group>
  </hodhod-page-layout-content>
</hodhod-page-layout>
