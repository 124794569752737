import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { InspectionManagementService } from 'src/backend/services/inspection-management/inspection-management.service';

@Component({
  selector: 'app-inspection-management-reviewers',
  templateUrl: './inspection-management-reviewers.component.html',
  styleUrls: ['./inspection-management-reviewers.component.scss'],
})
export class InspectionManagementReviewersComponent implements OnInit {
  public inspections = [];
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  constructor(
    private router: Router,
    private inspectionManagementService: InspectionManagementService,
    private feedBackService: AsyncFeedbackService
  ) {}

  ngOnInit(): void {
    this.getReviewersInspections();
  }

  getReviewersInspections() {
    this.sectionState = SectionStateStatus.Loading;
    this.inspectionManagementService.getReviewersInspections().subscribe({
      next: (res) => {
        this.inspections = res;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  viewInspection(inspectionId) {
    this.router.navigate([
      SharedConstants.INSPECTION_MANAGEMENT +
        '/' +
        SharedConstants.REVIEW_INSPECTIONS +
        '/' +
        inspectionId,
    ]);
  }
}
