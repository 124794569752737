import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hazard-report',
  templateUrl: './hazard-report.component.html',
  styleUrls: ['./hazard-report.component.scss'],
})
export class HazardReportComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
