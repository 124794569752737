import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { stagger20ms } from 'src/@hodhod/animations/stagger.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, of } from 'rxjs';
import { emit } from 'process';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@UntilDestroy()
@Component({
  selector: 'permissions-data-table',
  templateUrl: './permissions-data-table.component.html',
  styleUrls: ['./permissions-data-table.component.scss'],
  animations: [stagger20ms, fadeInUp400ms, scaleFadeIn400ms],
})
export class PermissionsDataTableComponent<T>
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() data: any;
  @Input() RoleFlag: any;
  @Input() columns: TableColumn<T>[];
  @Input() pageSize = 20;
  @Input() pageSizeOptions = [10, 20, 50];
  @Input() sectionId: number = 0;
  @Input() sectionSelected: boolean = false;
  matSelectValue: any;

  searchCtrl = new UntypedFormControl();

  @Output() onSelectAllEvent = new EventEmitter<any>();
  @Output() onSelectReviwer = new EventEmitter<any>();
  @Output() onPropSelection = new EventEmitter<any>();
  @Output() sharePermissions = new EventEmitter<any>();

  visibleColumns: Array<keyof T | string>;
  dataSource = new MatTableDataSource<T>();
  listOfPermissions: any;
  selection = new SelectionModel<any>(true, []);
  permissionsList: string[] = [];
  selectedPermissions: any[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  updateRolePermission = ApplicationPermission.ROLE_UPDATE;
  updateUserPermission = ApplicationPermission.USER_UPDATE;
  workPermitContent: string = '<div>Manage Hazards <br/> Manage Hazards</div>';

  constructor() {}

  ngOnInit() {
    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columns']) {
      this.visibleColumns = this.columns.map((column) => column.property);
    }

    if (changes['data'].currentValue) {
      changes['data'].currentValue?.permissions.forEach((row) => {
        row.options = this.getPermissionList(row);
        let permissionsObj = row.menusPermissions[0].permissions;
        let lastTrueKey = '';
        for (const key in row.menusPermissions[0].permissions) {
          if (
            permissionsObj.hasOwnProperty(key) &&
            permissionsObj[key] === false
          ) {
            break;
          }
          lastTrueKey = key;
        }
        row.selectedOption = lastTrueKey;
      });
      this.dataSource = new MatTableDataSource(
        changes['data'].currentValue.permissions
      );
    }
  }
  getTooltipContent(row: any): string {
    if (row['rowName'] === 'WorkpermitManageContentAccess') {
      return '- Manage Hazards\n- Manage Work Conditions';
    } else if (row['rowName'] === 'ReportingManageContentAccess') {
      return '- Type of Reports\n- Nature Injury\n- 6M Classification\n- Root Cause\n- Incident Classification';
    } else {
      return '';
    }
  }
  getPermissionList(row) {
    return Object.keys(row.menusPermissions[0].permissions);
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();
    this.paginator.pageIndex = 0;
    this.dataSource.filter = value;
  }

  onSelectReviwerAccess(event: any): void {
    // this.reviewerAccess = event?.checked;
    this.data.isViewAll = event?.checked;
    this.sharePermissions.emit(this.data);
  }

  changeSelection(event, row) {
    let newPermission = event.value;
    let indexOfFoundOption = row.options.indexOf(
      row.options.find((option) => option === newPermission)
    );
    let arr = row?.options?.slice(0, indexOfFoundOption + 1);
    row.menusPermissions.forEach((obj) => {
      for (const key in obj.permissions) {
        if (obj.permissions.hasOwnProperty(key)) {
          obj.permissions[key] = false;
        }
      }
      arr.forEach((key) => {
        obj.permissions[key] = true;
      });
    });
    this.sharePermissions.emit(this.data);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
