import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { formatDistance } from 'date-fns';
import { DateTime } from 'luxon';
import {
  NotificationSeverity,
  NotificationType,
} from 'src/@hodhod/common/enum';
import { ViewSafetyAnnouncementComponent } from 'src/app/safety-announcement/components/view-safety-announcement/view-safety-announcement.component';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { NotificationItem } from 'src/backend/models/notifications/notification-item';
import { MessagingService } from 'src/backend/services/messaging.service';
import { NotificationService } from 'src/backend/services/notification.service';
import { LayoutService } from '../../services/layout.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';

@Component({
  selector: 'hodhod-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss'],
})
export class QuickpanelComponent extends BaseComponent implements OnInit {
  date = DateTime.local().toFormat('DD');
  dayName = DateTime.local().toFormat('EEEE');
  messages: any[] = [];
  public NotificationType = NotificationType;
  public NotificationSeverity = NotificationSeverity;

  currentNotificationFilter: string[] = [];
  private limit = 10;
  private offset = 0;
  private eof = false;
  private translationsList: any = {};

  constructor(
    private messagingService: MessagingService,
    private notificationService: NotificationService,
    private layoutService: LayoutService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private confirmationService: ConfirmationService,
    private feedBackService: AsyncFeedbackService,
    private translate: TranslateService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit() {
    this.getNotifications();
    // this.getMessageFromFirebase();
    this.messagingService.currentMessage$.subscribe((response) => {
      if (response) {
        const formatedRes = {
          ...response,
          createdAt: formatDistance(new Date(response.createdAt), new Date(), {
            addSuffix: true,
          }),
        };
        this.notificationService.notificationCount$.next(
          this.messages
            ? this.messages.filter((x) => x.isRead === false).length + 1
            : 1
        );
        this.messages.unshift(formatedRes);

        if (
          formatedRes.notificationType ===
            NotificationType.SafetyAnnouncement ||
          formatedRes.notificationType ===
            NotificationType.SafetyAnnouncementEdit
        ) {
          this.dialog.open(ViewSafetyAnnouncementComponent, {
            height: 'auto',
            width: '90%',
            data: {
              formMode: 'view',
              announcementId: formatedRes.elementId,
              notificationId: formatedRes.notificationId,
            },
          });
        }

        this.notifiySound();
      }
    });
    this.notificationService.eof$.subscribe((res) => {
      this.eof = res;
    });
  }

  getNotifications(): void {
    this.notificationService.notifications.subscribe((response) => {
      this.messages = [
        ...this.messages,
        ...response?.map((ele) => {
          return {
            ...ele,
            createdAt: formatDistance(new Date(ele.createdAt), new Date(), {
              addSuffix: true,
            }),
          };
        }),
      ];
    });
  }

  getMessageFromFirebase(): void {
    this.messagingService.currentMessageFromFirebase$.subscribe((response) => {
      if (response) {
        const formatedRes = {
          ...response,
          createdAt: formatDistance(new Date(response.createdAt), new Date(), {
            addSuffix: true,
          }),
        };
        this.notificationService.notificationCount$.next(
          this.messages
            ? this.messages.filter((x) => x.isRead === false).length + 1
            : 1
        );
        this.messages.unshift(formatedRes);
        this.notifiySound();
      }
    });
  }

  notifiySound(): void {
    let notifyAlert = new Audio();
    notifyAlert.src = 'assets/sounds/notification_sound.mp3';
    notifyAlert.load();
    notifyAlert.play();
  }

  goToView(notification: any): void {
    const notify = new NotificationItem();
    notify.id = notification['id'];
    notify.title = notification['title'];
    notify.body = notification['description'];
    notify.type = notification['notificationType'];
    notify.elementId = notification['elementId'];
    this.notificationService.navigateToNotificationTarget(notify);
    this.layoutService.closeQuickpanel();
    const markAsReadData = {
      userId: notification?.userId,
      notificationId: notification?.id,
    };
    this.notificationService.markAsReadNotification(markAsReadData).subscribe({
      next: (res) => {
        const found = this.messages.find((x) => x.id === notification?.id);
        if (found) {
          found.isRead = true;
        }
      },
      error: ({ error }) => {},
    });
  }

  markAllAsRead(): void {
    const data = {
      userId: JSON.parse(localStorage.getItem('logged_in_user')).userId,
    };
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          this.notificationService.markAllAsReadNotification(data).subscribe({
            next: (res) => {
              this.messages = [];
              this.getAllNotification();
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Success,
                  'All notifications marked as read successfully'
                )
              );
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  deleteAllNotifications() {
    const data = {
      userId: JSON.parse(localStorage.getItem('logged_in_user')).userId,
    };
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          this.notificationService.deleteAllAsReadNotification(data).subscribe({
            next: (res) => {
              this.messages = [];
              this.getAllNotification();
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Success,
                  'All notifications cleared successfully'
                )
              );
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  onChangeType(event: any, notificationType: string): void {
    if (event.checked) {
      this.currentNotificationFilter.push(notificationType);
    } else {
      this.currentNotificationFilter = this.currentNotificationFilter.filter(
        (x) => x !== notificationType
      );
    }
    this.messages = [];
    this.offset = 0;
    this.getAllNotification();
  }

  onClearFilterClicked(event: any): void {
    event.stopPropagation();
    this.currentNotificationFilter = [];
    this.messages = [];
    this.offset = 0;
    this.getAllNotification();
  }

  getAllNotification(): void {
    const filteredParams = {
      notificationType: this.currentNotificationFilter.toString(),
      pageIndex: this.offset,
      pageSize: this.limit,
    };
    this.notificationService.getUserNotifications(filteredParams);
  }

  refreshNotification(): void {
    this.messages = [];
    this.offset = 0;
    const filteredParams = {
      notificationType: this.currentNotificationFilter.toString(),
      pageIndex: this.offset,
      pageSize: this.limit,
    };
    this.notificationService.getUserNotifications(filteredParams);
  }

  onScroll(e) {
    // If the user has scrolled within 200px of the bottom, request more data
    const buffer = 50;
    const divViewHeight = e.target.offsetHeight; // viewport: ~500px
    const divScrollHeight = e.target.scrollHeight; // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    const limit = divScrollHeight - divViewHeight - buffer;

    if (!this.eof && scrollLocation > limit) {
      this.loadMoreData();
    }
  }

  private loadMoreData() {
    this.offset += 1;
    const filteredParams = {
      notificationType: this.currentNotificationFilter.toString(),
      pageIndex: this.offset,
      pageSize: this.limit,
    };
    this.notificationService.getUserNotifications(filteredParams);
  }
}
