import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChecklistType, HistoryTypes } from 'src/@hodhod/common/enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { GetHazardsList } from 'src/backend/models/hazards/hazards.model';
import { GetAllWorkConditions } from 'src/backend/models/work-conditions/work-conditions.model';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { HazardsService } from 'src/backend/services/hazards/hazards.service';
import { WorkConditionsService } from 'src/backend/services/work-conditions/work-conditions.service';

@Component({
  selector: 'app-add-work-conditions',
  templateUrl: './add-work-conditions.component.html',
  styleUrls: ['./add-work-conditions.component.scss'],
})
export class AddWorkConditionsComponent implements OnInit {
  HistoryType = HistoryTypes.WORK_CONDITION;
  workConditionForm: FormGroup;
  formMode: any;
  private destroy$ = new Subject();
  public translationsList: any = {};
  hasCreatePermission = ApplicationPermission.WORK_CONDITIONS_CREATE;
  hasUpdatePermission = ApplicationPermission.WORK_CONDITIONS_UPDATE;
  hasActivatePermission = ApplicationPermission.WORK_CONDITIONS_ACTIVATE;
  hazards: GetHazardsList[];
  hazardIdArr: number[];
  checklistIdArr: number[];
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  hazardList: any[] = [];
  checklists: any[] = [];
  workConditionList: GetAllWorkConditions;
  loadingLabel: string;

  hasCreateWorkConditionCheckListPermission =
    ApplicationPermission.WORK_CONDITIONS_CREATE;

  hasCreateHazardsPermission = ApplicationPermission.WORK_PERMIT_HAZARDS_CREATE;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AddWorkConditionsComponent>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private hazardService: HazardsService,
    private conditionService: WorkConditionsService,
    private feedBackService: AsyncFeedbackService,
    private checklistService: ChecklistSectionService
  ) {
    this.translate
      .get(['Errors', 'Success', 'Role', 'User', 'General'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.getHazardsList();
    this.getChecklistList();
    if (this.defaults.action === 'update') {
      this.formMode = 'update';
      this.hazardIdArr = this.defaults.workCondition.hazardId?.map((x) =>
        parseInt(x, 10)
      );
      this.checklistIdArr = this.defaults.workCondition.checkListId?.map((x) =>
        parseInt(x, 10)
      );
    } else if (this.defaults.action === 'view') {
      this.formMode = 'view';
      this.hazardIdArr = this.defaults.workCondition.hazardId?.map((x) =>
        parseInt(x, 10)
      );
      this.checklistIdArr = this.defaults.workCondition.checkListId?.map((x) =>
        parseInt(x, 10)
      );
    } else if (this.defaults.action === 'create') {
      this.formMode = 'create';
      this.defaults.reason = {} as GetAllWorkConditions;
    }
    this.workConditionForm = this.fb.group({
      workConditionName: [
        this.defaults.workCondition.workConditionName || '',
        [Validators.required, noWhitespaceValidator],
      ],
      hazard: [this.hazardIdArr, [Validators.required]],
      checklist: [this.checklistIdArr, [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createWorkCondition();
          }
        });
    } else if (this.formMode === 'update') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['Role']['UpdateConfirm']['Title'],
            this.translationsList['Role']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.updateWorkCondition();
          }
        });
    }
  }

  createWorkCondition() {
    const { workConditionName, hazard, checklist } =
      this.workConditionForm.value;
    let selectedhazard = [];
    const all = hazard.find((x) => x == 0);
    if (all == 0) {
      selectedhazard = this.hazards.map((a) => a.id);
    } else {
      selectedhazard = hazard;
    }
    let selectedchecklist = [];
    const allchecklist = checklist.find((x) => x == 0);
    if (allchecklist == 0) {
      selectedchecklist = this.checklists.map((a) => a.id);
    } else {
      selectedchecklist = checklist;
    }
    const data = {
      workConditionName,
      HazardId: selectedhazard,
      ChecklistId: selectedchecklist,
      companyId: '2',
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.conditionService.postWorkCondition(data).subscribe({
      next: () => {
        this.dialogRef.close(data);
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['SuccessMessage']
          )
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  updateWorkCondition() {
    const { workConditionName, hazard, checklist } =
      this.workConditionForm.value;
    let selectedhazard = [];
    const all = hazard.find((x) => x == 0);
    if (all == 0) {
      selectedhazard = this.hazards.map((a) => a.id);
    } else {
      selectedhazard = hazard;
    }
    let selectedchecklist = [];
    const allchecklist = checklist.find((x) => x == 0);
    if (allchecklist == 0) {
      selectedchecklist = this.checklists.map((a) => a.id);
    } else {
      selectedchecklist = checklist;
    }
    const data = {
      id: this.defaults.workCondition.id,
      workConditionName,
      HazardId: selectedhazard,
      ChecklistId: selectedchecklist,
      companyId: '2',
      workConditionJunctionId: this.defaults.workConditionJunctionId,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.conditionService.UpdateWorkCondition(data).subscribe({
      next: () => {
        this.dialogRef.close(data);
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['General']['Updated']
          )
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getHazardsList() {
    this.hazardService.getAllHazardsList().subscribe((res) => {
      this.hazards = res;
      if (this.defaults.id) {
        this.hazardList = this.defaults.id.split(',');
        this.hazardList = this.hazardList.map((x) => parseInt(x, 10));
        const index = this.hazardList.indexOf(0);
        if (index > -1) {
          this.hazardList.splice(index, 1);
        }
        if (this.hazardList.length == this.hazards.length) {
          this.hazardList.push(0);
        }
        this.workConditionForm.get('hazard').setValue(this.hazardList);
      }
    });
  }
  selectedhazard(e: any) {
    const typeId = this.workConditionForm.get('hazard').value;
    let ids = [];
    const all = typeId.find((x) => x == 0);
    if (all == 0) {
      ids = this.hazards.map((a) => a.id);
      ids.push(0);
      this.workConditionForm.get('hazard').setValue(ids);
    } else {
      this.workConditionForm.get('hazard').setValue('');
    }
  }

  selectAllhazard(e: any) {
    const typeId = this.workConditionForm.get('hazard').value;
    const index = typeId.indexOf(0);
    if (index > -1) {
      typeId.splice(index, 1);
    }
    if (typeId.length == this.hazards.length) {
      typeId.push(0);
      this.workConditionForm.get('hazard').setValue(typeId);
    } else {
      this.workConditionForm.get('hazard').setValue(typeId);
    }
  }

  getChecklistList() {
    this.checklistService
      .getAllCheckListForDropdown(ChecklistType.WorkCondition)
      .subscribe((res) => {
        this.checklists = res;
        if (this.defaults.id) {
          this.checklists = this.defaults.id.split(',');
          this.checklists = this.checklists.map((x) => parseInt(x, 10));
          const index = this.checklists.indexOf(0);
          if (index > -1) {
            this.checklists.splice(index, 1);
          }
          // if (this.checklists.length == this.checklists.length) {
          //   this.checklists.push(0);
          // }
          this.workConditionForm
            .get('checklist')
            .setValue(this.checklists.length > 0 ? this.checklists : []);
        }
      });
  }

  selectedChecklist(e: any) {
    const listId = this.workConditionForm.get('checklist').value;
    let ids = [];
    const all = listId.find((x) => x == 0);
    if (all == 0) {
      ids = this.checklists.map((a) => a.id);
      ids.push(0);
      this.workConditionForm.get('checklist').setValue(ids);
    } else {
      this.workConditionForm.get('checklist').setValue('');
    }
  }

  selectAllChecklist(e: any) {
    const listId = this.workConditionForm.get('checklist').value;
    const index = listId.indexOf(0);
    if (index > -1) {
      listId.splice(index, 1);
    }
    if (listId.length == this.checklists.length) {
      listId.push(0);
      this.workConditionForm.get('checklist').setValue(listId);
    } else {
      this.workConditionForm.get('checklist').setValue(listId);
    }
  }

  isCreateMode() {
    return this.formMode === 'create';
  }

  isUpdateMode() {
    return this.formMode === 'update';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
