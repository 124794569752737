import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base-component',
  template: ``
})
export class BaseComponent implements OnInit {
  protected destroy$ = new Subject();

  ngOnInit(): void {
  }

}
