<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span>{{ "NavigationBar.WorkPermits" | translate }}</span>
    </h2>

    <div
      class="bg-foreground rounded-full px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'General.Search' | translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>
    <button
      [matMenuTriggerFor]="exportExcelMenu"
      [matTooltip]="'General.Buttons.ExportToExcel' | translate"
      class="flex-none"
      mat-icon-button
      type="button"
    >
      <img
        class="cursor-pointer"
        src="assets/img/resources/Export-excel-icon.svg"
        width="15px"
        height="15px"
      />
    </button>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>

    <button
      (click)="addWorkPermit()"
      *ngIf="hasWritePermissions | permissionChecker"
      class="ml-2 flex-none"
      color="primary"
      mat-mini-fab
      [matTooltip]="'General.Buttons.AddNew' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:add"></mat-icon>
    </button>
  </div>
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
      <!-- Text Columns -->
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="
            column.type === 'text' && column.property !== 'theNewWorkConditions'
          "
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "WorkPermit." + column.label | translate }}&nbsp;
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>
        <ng-container
          *ngIf="
            column.type === 'text' && column.property === 'theNewWorkConditions'
          "
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div class="flex items-center">
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "General.Buttons." + column.label | translate }}
              <mat-select
                [value]="selectedWorkConditions"
                (selectionChange)="onSelectWorkConditions($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option *ngFor="let wc of workConditions" [value]="wc?.id">
                  {{ wc?.workConditionName }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td
            *matCellDef="let row"
            [ngClass]="column.cssClasses"
            #div
            class="truncate max-w-0 p-3"
            mat-cell
          >
            <span
              [matTooltip]="
                div?.offsetWidth < div?.scrollWidth
                  ? row[column.property]
                  : null
              "
            >
              {{ row[column.property] }}
            </span>
          </td>
        </ng-container>

        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "WorkPermit." + column.label | translate }}&nbsp;
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "mediumDate" }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            <div
              style="width: 100%; position: relative"
              class="flex items-center"
            >
              <mat-icon
                app-click-stop-propagation
                style="width: 35px; height: 35px; cursor: pointer"
                (click)="select.open()"
                svgIcon="mat:keyboard_arrow_down"
                color="primary"
              ></mat-icon>
              {{ "User.Table." + column.label | translate }}
              <mat-select
                [value]="selectedStatus"
                (selectionChange)="onSelectStatus($event)"
                #select
                class="select-options"
                multiple
              >
                <mat-option
                  *ngFor="let statue of WorkPermitStatues | enumToArray"
                  [value]="statue.value"
                >
                  {{ "Enum.WorkPermitStatus." + statue.value | translate }}
                </mat-option>
              </mat-select>
            </div>
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <span>{{
                "Enum.WorkPermitStatus." + row[column.property] | translate
              }}</span>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <!-- ACTIONS Column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let row" mat-cell>
          <div (click)="$event.stopPropagation()">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon svgIcon="mat:more_vert"></mat-icon>
            </button>
            <mat-menu
              #menu="matMenu"
              yPosition="below"
              xPosition="before"
              class="shadow-lg shadow-gray-400 rounded-2xl min-h-0"
            >
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                *ngIf="hasAccessPermissions | permissionChecker"
                (click)="viewWorkPermit($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.View" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                *ngIf="
                  (hasEditPermissions | permissionChecker) &&
                  row?.status === 'NEW'
                "
                (click)="editWorkPermit($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.Edit" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
              <div
                mat-menu-item
                class="flex items-center justify-between p-1 mat-div"
                *ngIf="hasDeletePermissions | permissionChecker"
                (click)="removeWorkPermit($event, row?.id)"
              >
                <button>
                  {{ "General.Buttons.Delete" | translate }}
                </button>
                <mat-icon svgIcon="mat:keyboard_arrow_right"></mat-icon>
              </div>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
        (click)="viewWorkPermit($event, row?.id)"
      ></tr>
    </table>
    <div
      *ngIf="dataSource.filteredData.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [pageSizeOptions]="pageSizeOptions"
      [length]="pagination.length"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.pageIndex"
      (page)="handlePageEvent($event)"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-state-section>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    mat-menu-item
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-icon
      class="text-[#757575] me-1"
      *ngIf="!column.visible"
      svgIcon="mat:check_box_outline_blank"
    ></mat-icon>
    <mat-icon
      *ngIf="column.visible"
      class="text-primary me-1"
      svgIcon="mat:check_box"
    ></mat-icon>
    {{ "WorkPermit." + column.label | translate }}&nbsp;
  </button>
</mat-menu>

<mat-menu #exportExcelMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item class="mat-menu-item" (click)="exportToExcel()">
    Export All
  </button>

  <button mat-menu-item class="mat-menu-item" (click)="exportToExcel('custom')">
    Custom Range
  </button>
</mat-menu>
