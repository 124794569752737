import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChecklistType } from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { PermissionService } from 'src/backend/services/permission.service';

@Component({
  selector: 'app-view-checklist-section-page',
  templateUrl: './view-checklist-section-page.component.html',
  styleUrls: ['./view-checklist-section-page.component.scss'],
})
export class ViewChecklistSectionPageComponent implements OnInit, OnDestroy {
  public mode: 'view' | 'edit' = 'view';
  public checkListId: number = 0;
  private destroy$ = new Subject();
  public checkList: any;
  public formMode: any;

  hasAuditEditPermission = ApplicationPermission.AUDIT_CHECKLIST_UPDATE;
  hasWorkConditionsEditPermission =
    ApplicationPermission.WORK_CONDITIONS_UPDATE;
  hasInspectionChecklistEditPermission =
    ApplicationPermission.INSPECTION_CHECKLIST_UPDATE;

  hasAuditViewPermission = ApplicationPermission.AUDIT_CHECKLIST_READ;
  hasWorkConditionsViewPermission =
    ApplicationPermission.WORK_CONDITIONS_ACCESS;
  hasInspectionChecklistViewPermission =
    ApplicationPermission.INSPECTION_CHECKLIST_READ;
  constructor(
    private route: ActivatedRoute,
    private loadingService: LoadingService,
    private checklistService: ChecklistSectionService,
    private feedBackService: AsyncFeedbackService,
    private permissionService: PermissionService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;
        this.checkListId = parseInt(id, 10);
      }
    });
    this.route.queryParamMap.subscribe((queryParam) => {
      this.formMode = queryParam.get('editMode') as any;
      this.getCheckListById();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  getCheckListById() {
    this.loadingService.startLoading(true, '');
    this.checklistService.getCheckListById(this.checkListId).subscribe({
      next: (response) => {
        this.checkList = response;

        this.loadingService.stopLoading();
        const hasPermissionEdit = this.permissionService.isUserHasPermission(
          this.checkList.type == ChecklistType.Audit
            ? this.hasAuditEditPermission
            : this.checkList.type == ChecklistType.WorkCondition
            ? this.hasWorkConditionsEditPermission
            : this.hasInspectionChecklistEditPermission
        );
        const hasPermissionView = this.permissionService.isUserHasPermission(
          this.checkList.type == ChecklistType.Audit
            ? this.hasAuditViewPermission
            : this.checkList.type == ChecklistType.WorkCondition
            ? this.hasWorkConditionsViewPermission
            : this.hasInspectionChecklistViewPermission
        );

        if (hasPermissionEdit && this.formMode === 'true') {
          this.mode = 'edit';
        } else if (hasPermissionView && this.formMode === 'false') {
          this.mode = 'view';
        } else {
          this.router.navigate([
            '/' +
              SharedConstants.PAGE_ERROR +
              '/' +
              SharedConstants.ACCESS_DENIED,
          ]);
        }
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
}
