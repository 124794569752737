import {
  Component,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { CompanyService } from 'src/backend/services/company.service';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { ManageIdCardService } from 'src/backend/services/manage-id-card/manage-id-card.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { StringifyOptions } from 'querystring';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { UserService } from 'src/backend/services/user.service';
import { GetAllUpdateCardViewhistory } from '../../models/renew-id-card-model';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { MatPaginator } from '@angular/material/paginator';
import { Constants } from 'src/@hodhod/common/constants';
import { MatTableDataSource } from '@angular/material/table';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
@Component({
  selector: 'app-add-id-card-page',
  templateUrl: './add-id-card-page.component.html',
  styleUrls: ['./add-id-card-page.component.scss'],
})
export class AddIdCardPageComponent implements OnInit {
  @ViewChild('viewImageDialog') private profileModel!: TemplateRef<any>;
  @ViewChild('approveRejDialouge') private approveModel!: TemplateRef<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<GetAllUpdateCardViewhistory>([]);
  idCardForm: FormGroup;
  approveForm: FormGroup;
  formName: string;
  siteAccessFormArray!: FormArray;
  formArrayLength: number;
  showRemoveBtn: boolean = false;
  departments: any[] = [];
  sessionOrg: any;
  departmentsByCompany: any[] = [];
  companies: any[];
  profilePath: any;
  loadingLabel: string = '';
  idCardId: number;
  formMode: string;
  basicDoc: any[];
  carDoc: any[];
  viewImagePath: string = '';
  responsibles: any[] = [];
  docId: number;
  docType: string;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  private destroy$ = new Subject();
  public translationsList: any = {};

  hasApprovePermission = ApplicationPermission.SECURITY_MANAGE_IDCARDS_APPEOVE;

  hasCreateUserPermissions = ApplicationPermission.USER_CREATE;
  hasDepartmentPermission = ApplicationPermission.DEPARTMENTS_CREATE;

  constructor(
    private fb: FormBuilder,
    private feedBackService: AsyncFeedbackService,
    private departmentService: DepartmentsService,
    private baseApi: BaseApi,
    private translate: TranslateService,
    private companyService: CompanyService,
    private idCardService: ManageIdCardService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService
  ) {
    this.translate
      .get(['Errors', 'Success', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  columns: TableColumn<GetAllUpdateCardViewhistory>[] = [
    {
      label: 'CreatedBy',
      property: 'createdBy',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    { label: 'CreatedAt', property: 'createdOn', type: 'date', visible: true },
    { label: 'Status', property: 'action', type: 'boolean', visible: true },
    {
      label: 'Attachment',
      property: 'attachment',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Comments', property: 'comment', type: 'text', visible: true },
  ];

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        this.idCardId = parseInt(params.get('id'), 10);
      }
      if (params.has('mode')) {
        this.formMode = params.get('mode') as string;
      }
      if (this.formMode != 'create') {
        this.getIdCardById(this.idCardId);
      }
    });
    this.idCardForm = this.fb.group({
      name: ['', Validators.required],
      jobTitle: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      department: ['', Validators.required],
      idCardProfileImage: [[]],
      basicDocument: [[]],
      siteAccess: this.fb.array([]),
      siteAccessComment: [''],
      carModel: [''],
      plateNumber: [''],
      carColor: [''],
      carComment: [''],
      carDocument: [[]],
    });
    this.approveForm = this.fb.group({
      approveComment: ['', Validators.required],
    });

    this.siteAccessFormArray = this.idCardForm.get('siteAccess') as FormArray;
    if (this.formMode === 'create') {
      this.addFormGroup();
      this.idCardForm.controls['idCardProfileImage'].setValidators(
        Validators.required
      );
    }
    this.getDepartments();
    this.getSessionOrganization();
    this.getUsers();
    if (this.formMode === 'view') {
      this.getHistoryData();
    }
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  checkProfileExtension(e: any) {
    let files = this.idCardForm.get('idCardProfileImage').value;
    const images = files.filter(
      (x) => x.type === 'image/png' || x.type === 'image/jpeg'
    );
    const otherFiles = files.filter(
      (x) => x.type != 'image/png' && x.type != 'image/jpeg'
    );
    if (otherFiles.length > 0) {
      this.idCardForm.get('idCardProfileImage').setValue(images);
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Warning,
          'Only png and jpeg files supported'
        )
      );
    }
  }

  checkCarDocExtension(e: any) {
    let files = this.idCardForm.get('carDocument').value;
    const images = files.filter(
      (x) => x.type === 'image/png' || x.type === 'image/jpeg'
    );
    const otherFiles = files.filter(
      (x) => x.type != 'image/png' && x.type != 'image/jpeg'
    );
    if (otherFiles.length > 0) {
      this.idCardForm.get('carDocument').setValue(images);
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Warning,
          'Only png and jpeg files supported'
        )
      );
    }
  }

  checkBasicDocExtension(e: any) {
    let files = this.idCardForm.get('basicDocument').value;
    const images = files.filter(
      (x) => x.type === 'image/png' || x.type === 'image/jpeg'
    );
    const otherFiles = files.filter(
      (x) => x.type != 'image/png' && x.type != 'image/jpeg'
    );
    if (otherFiles.length > 0) {
      this.idCardForm.get('basicDocument').setValue(images);
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Warning,
          'Only png and jpeg files supported'
        )
      );
    }
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  getHistoryData() {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.idCardService.getIdCardHistory(this.idCardId).subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource(response?.data);
        this.paginator.length = response?.totalCount ? response?.totalCount : 0;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getUsers(): void {
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.responsibles = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getIdCardById(id: number) {
    if (id) {
      this.loadingLabel = 'General.Refreshing';
      this.sectionState = SectionStateStatus.LoadingTransparent;
      this.idCardService.getIdCardById(id).subscribe({
        next: (res) => {
          if (res) {
            this.profilePath = res.profilePath;
            this.basicDoc = res.documentList.filter((x) => x.type === 'BSIC');
            this.carDoc = res.documentList.filter((x) => x.type === 'CAR');
            // this.idCardForm
            //   .get('idCardProfileImage')
            //   .setValue([res.profilePath]);
            // this.idCardForm
            //   .get('idCardProfileImage')
            //   .removeValidators(Validators.required);
            this.idCardForm.get('name').setValue(res.name);
            this.idCardForm.get('jobTitle').setValue(res.jobTitle);
            this.idCardForm.get('phoneNumber').setValue(res.phoneNumber);
            this.idCardForm
              .get('department')
              .setValue(parseInt(res.departmentId, 10));
            this.idCardForm
              .get('siteAccessComment')
              .setValue(res.siteAccessComment);
            this.idCardForm.get('carModel').setValue(res.carModel);
            this.idCardForm.get('plateNumber').setValue(res.plateNumber);
            this.idCardForm.get('carColor').setValue(res.carColor);
            this.idCardForm.get('carComment').setValue(res.carComment);
            if (res.siteAcessInfoList) {
              res.siteAcessInfoList.forEach((item, i) => {
                this.addFormGroup(item);
                const obj = {
                  value: item.companyCode,
                };
                this.getDepartmentsByCompanyCode(obj, i, item);
              });
            }
            this.sectionState = SectionStateStatus.Ready;
          }
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
    }
  }

  getSessionOrganization(): void {
    this.sessionOrg = this.baseApi.getOrganizationFromStorage();
    this.getCompanies();
  }

  createSiteAccessForm(data?: any): FormGroup {
    return this.fb.group({
      siteAccessCompany: [data ? data.companyCode : ''],
      siteAccessDepartment: [data ? parseInt(data.departmentCode, 10) : ''],
    });
  }

  viewImgDialog(path: any, id: any, type: any) {
    debugger;
    this.viewImagePath = path;
    this.docId = id;
    this.docType = type;
    this.dialog.open(this.profileModel, {
      height: 'auto',
      width: 'auto',
    });
  }

  addFormGroup(data?: any): void {
    this.siteAccessFormArray = this.idCardForm.get('siteAccess') as FormArray;
    this.siteAccessFormArray.push(this.createSiteAccessForm(data));
    this.formArrayLength = this.siteAccessFormArray.length;
    if (this.siteAccessFormArray.length == 1) {
      this.showRemoveBtn = false;
    } else {
      this.showRemoveBtn = true;
    }
  }
  get siteAccessControls(): any {
    return this.idCardForm.get('siteAccess') as FormArray;
  }

  reemoveSiteAccessForm(index: any) {
    this.siteAccessFormArray.removeAt(index);
    const data = this.departmentsByCompany[index];
    if (data) {
      this.departmentsByCompany.splice(index, 1);
    }
    this.formArrayLength = this.siteAccessFormArray.length;
    if (this.siteAccessFormArray.length == 1) {
      this.showRemoveBtn = false;
    } else {
      this.showRemoveBtn = true;
    }
  }

  getDepartments(): void {
    this.departmentService.getDepartmentsParentChild().subscribe({
      next: (response) => {
        this.departments = response;
        if (
          this.departments.length == 1 &&
          this.departments[0].parentName == '---Parent Department---' &&
          this.departments[0].detail == null
        ) {
          this.departments = [];
        }
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getCompanies() {
    const organizationId = this.sessionOrg?.orgId ? this.sessionOrg?.orgId : 0;
    this.companyService
      .getCompaniesByOrgIdForDropdown(organizationId)
      .subscribe({
        next: (res) => {
          this.companies = res.data;
        },
      });
  }

  getDepartmentsByCompanyCode(company: any, index: number, data?: any): void {
    // this.loadingLabel = "General.Refreshing";
    // this.sectionState = SectionStateStatus.LoadingTransparent;
    this.departmentService
      .getDepartmentsParentChildByCompanyCode(company.value)
      .subscribe({
        next: (response) => {
          const data: any[] = response;
          if (this.departmentsByCompany[index]) {
            this.departmentsByCompany[index] = response;
          } else {
            this.departmentsByCompany.push(data);
          }
          this.sectionState = SectionStateStatus.Ready;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  createIdCard() {
    const {
      name,
      jobTitle,
      phoneNumber,
      department,
      siteAccess,
      siteAccessComment,
      carModel,
      plateNumber,
      carColor,
      carComment,
      carDocument,
      idCardProfileImage,
      basicDocument,
    } = this.idCardForm.value;

    const data = {
      name: name,
      jobTitle: jobTitle,
      phoneNumber: phoneNumber,
      departmentCode: department,
      siteAccessComment: siteAccessComment,
      carModel: carModel,
      carColor: carColor,
      plateNo: plateNumber,
      carComment: carComment,
      siteAccessInformation: siteAccess.map((item, i) => {
        return {
          companyCode: item.siteAccessCompany,
          departmentCode: item.siteAccessDepartment,
        };
      }),
    };

    const form = new FormData();
    form.append('data', JSON.stringify(data));
    form.append('profile', idCardProfileImage[0]);
    for (let index = 0; index < basicDocument.length; index++) {
      form.append('basicFiles', basicDocument[index]);
    }
    for (let index = 0; index < carDocument.length; index++) {
      form.append('carFiles', carDocument[index]);
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.idCardService.postIdCard(form).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.resetIdCardForm();
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Success,
                  this.translationsList['Success']['SuccessMessage']
                )
              );
            },
            error: ({ error }) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Failure,
                  this.translationsList['Errors']['ErrorHappened']
                )
              );
            },
          });
        }
      });
  }

  updateIdCard() {
    const {
      name,
      jobTitle,
      phoneNumber,
      department,
      siteAccess,
      siteAccessComment,
      carModel,
      plateNumber,
      carColor,
      carComment,
      carDocument,
      idCardProfileImage,
      basicDocument,
    } = this.idCardForm.value;

    const data = {
      id: this.idCardId,
      name: name,
      jobTitle: jobTitle,
      phoneNumber: phoneNumber,
      departmentCode: department,
      siteAccessComment: siteAccessComment,
      carModel: carModel,
      carColor: carColor,
      plateNo: plateNumber,
      carComment: carComment,
      siteAccessInformation: siteAccess.map((item, i) => {
        return {
          companyCode: item.siteAccessCompany,
          departmentCode: item.siteAccessDepartment,
        };
      }),
    };

    const form = new FormData();
    form.append('data', JSON.stringify(data));
    form.append('profile', idCardProfileImage[0]);
    for (let index = 0; index < basicDocument.length; index++) {
      form.append('basicFiles', basicDocument[index]);
    }
    for (let index = 0; index < carDocument.length; index++) {
      form.append('carFiles', carDocument[index]);
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.idCardService.updateIdCard(form).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.router.navigate([
                '/' +
                  SharedConstants.SECURITY +
                  '/' +
                  SharedConstants.NEW_ID_CARD,
              ]);
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
            },
            error: ({ error }) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(
                  FeedbackType.Failure,
                  this.translationsList['Errors']['ErrorHappened']
                )
              );
            },
          });
        }
      });
  }

  deleteDocument(id: number, type: string) {
    if (id) {
      this.closeDialog();
      this.loadingLabel = 'General.Refreshing';
      this.sectionState = SectionStateStatus.LoadingTransparent;
      this.idCardService.deleteDocument(id).subscribe({
        next: (res) => {
          if (type === 'BASIC') {
            this.basicDoc = this.basicDoc.filter((x) => x.documentId != id);
          }
          if (type === 'CAR') {
            this.carDoc = this.carDoc.filter((x) => x.documentId != id);
          }
          this.sectionState = SectionStateStatus.Ready;
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Success, res?.message)
          );
        },
        error: ({ error }) => {
          this.sectionState = SectionStateStatus.Ready;
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
    }
  }

  approveIdCard() {
    if (this.idCardId) {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            const obj = {
              id: this.idCardId,
              comment: this.approveForm.value.approveComment,
            };
            this.loadingLabel = 'General.Refreshing';
            this.sectionState = SectionStateStatus.LoadingTransparent;
            this.idCardService.approveIdCard(obj).subscribe({
              next: (res) => {
                this.closeDialog();
                this.getHistoryData();
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
              },
              error: ({ error }) => {
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Failure,
                    this.translationsList['Errors']['ErrorHappened']
                  )
                );
              },
            });
          }
        });
    }
  }

  openApproveModel() {
    this.formName = 'Approve';
    this.dialog.open(this.approveModel, {
      height: '50%',
      width: '50%',
    });
  }

  openRejectModel() {
    this.formName = 'Reject';
    this.dialog.open(this.approveModel, {
      height: '50%',
      width: '50%',
    });
  }

  rejectIdCard() {
    if (this.idCardId) {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.loadingLabel = 'General.Refreshing';
            this.sectionState = SectionStateStatus.LoadingTransparent;
            const obj = {
              id: this.idCardId,
              comment: this.approveForm.value.approveComment,
            };
            this.idCardService.rejectIdCard(obj).subscribe({
              next: (res) => {
                this.closeDialog();
                this.getHistoryData();
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
              },
              error: ({ error }) => {
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Failure,
                    this.translationsList['Errors']['ErrorHappened']
                  )
                );
              },
            });
          }
        });
    }
  }

  onReadProfile(e: any) {
    let reader = new FileReader();
    reader.onload = (el) => {
      this.profilePath = reader.result;
    };
    const img = this.idCardForm.get('idCardProfileImage').value;
    reader.readAsDataURL(img[0]);
  }

  resetIdCardForm() {
    this.idCardForm.reset();
    this.idCardForm.get('carDocument').setValue([]);
    this.idCardForm.get('idCardProfileImage').setValue([]);
    this.idCardForm.get('basicDocument').setValue([]);
    this.siteAccessFormArray.clear();
    this.addFormGroup();
  }
}
