import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/backend/services/auth.service';
import { SharedConstants } from '../models/shared-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router) {}

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isLoggedIn()) {
      if (state.url.indexOf(SharedConstants.SIGN_IN) > 0) {
        this.authService.navigateToInitialMenu();
      }
      return true;
    }
    this.authService.redirectionURL = state.url;
    this.router.navigate(['/' + SharedConstants.AUTH + '/' + SharedConstants.SIGN_IN]);
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    this.authService.redirectionURL = state.url;
    this.router.navigate(['/' + SharedConstants.AUTH + '/' + SharedConstants.SIGN_IN]);
    return false;
  }
}
