<app-card [title]="'Company.CreatNewCompany' | translate">
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="'Company.' + loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <form [formGroup]="createCompForm">
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div>
          <div class="flex column">
            <mat-label class="field-label"
              >{{ "Company.BasicForm.Name" | translate }}
              <span
                [ngClass]="
                  createCompForm.invalid &&
                  createCompForm.get('companyName').hasError('required')
                    ? 'is-invalid'
                    : 'is-valid'
                "
                >*</span
              >
            </mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <input
                [placeholder]="'Placeholder.AddText' | translate"
                matInput
                type="text"
                formControlName="companyName"
              />
              <mat-error
                *ngIf="
                  createCompForm.invalid &&
                  createCompForm.get('companyName').hasError('required')
                "
                >{{ "Errors.Required" | translate }}</mat-error
              >
              <mat-error
                *ngIf="
                  createCompForm?.get('companyName').hasError('whitespace')
                "
              >
                <div class="flex justify-flex-start align-flex-center">
                  <img
                    class="error-img"
                    src="assets/img/resources/error-icon.svg"
                  />
                  <span>{{ "Errors.InValidData" | translate }}</span>
                </div>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="flex column">
            <mat-label class="field-label">{{
              "Company.BasicForm.Description" | translate
            }}</mat-label>
            <mat-form-field class="input-field" appearance="outline">
              <textarea
                [placeholder]="'Placeholder.AddText' | translate"
                rows="2"
                matInput
                type="text"
                formControlName="description"
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <div>
          <div class="flex column">
            <mat-label class="field-label">{{
              "Company.BasicForm.Image" | translate
            }}</mat-label>
            <file-upload
              formControlName="companyImage"
              [multiple]="false"
              [animation]="true"
              [accept]="'file_extension|image/*'"
            ></file-upload>
          </div>

          <div class="my-2" *ngIf="isSuperAdmin">
            <mat-label class="field-label">{{
              "organization.BasicForm.Theme" | translate
            }}</mat-label>
            <div class="flex items-center">
              <div class="border border-gray-200 p-2 m-1 w-4/12 rounded-lg">
                <input type="color" formControlName="primary" />
                <label for="head" class="p-2">Primary</label>
              </div>
              <img
                src="/assets/img/resources/info-icon.png"
                class="mx-2"
                [matTooltip]="
                  'Hint: Please, avoid bright colors for better contrast.'
                "
                [matTooltipPosition]="'right'"
              />
            </div>
            <div class="flex items-center">
              <div class="border border-gray-200 p-2 m-1 w-4/12 rounded-lg">
                <input type="color" id="body" formControlName="secondary" />
                <label for="body" class="p-2">Secondary</label>
              </div>
              <img
                src="/assets/img/resources/info-icon.png"
                class="mx-2"
                [matTooltip]="
                  'Hint: For better visibility, better to be black or white.'
                "
                [matTooltipPosition]="'right'"
              />
            </div>

            <div class="flex">
              <img
                *ngIf="croppedImageUrl"
                class="border border-gray-200 m-1 w-4/12 rounded-lg"
                [src]="croppedImageUrl"
              />
              <label for="fileInput">
                <img
                  [src]="'assets/img/resources/add-new-img.svg'"
                  style="width: 40px; height: 40px"
                  matTooltip="Please Make Sure that Image Dimensions not Less Than 250*100"
                  alt="Upload Image"
                  class=""
                />
                <input
                  formControlName="companyLogo"
                  type="file"
                  id="fileInput"
                  accept="'file_extension|image/*'"
                  (change)="fileChangeEvent($event)"
                  style="display: none"
                />
              </label>
            </div>

            <ng-template #uploadLogo>
              <div class="flex column">
                <div>
                  <label>Select Particular Part:</label>

                  <image-cropper
                    [imageChangedEvent]="imageChangedEvent"
                    [aspectRatio]="3 / 1"
                    [cropperStaticHeight]="100"
                    [cropperStaticWidth]="250"
                    [containWithinAspectRatio]="true"
                    [resizeToWidth]="250"
                    [resizeToHeight]="100"
                    (imageCropped)="imageCropped($event)"
                  ></image-cropper>
                  <label>preview Image:</label>
                  <img
                    [src]="
                      croppedImageUrl || 'https://placehold.co/250x100/png'
                    "
                  />
                </div>
                <mat-dialog-actions align="end">
                  <app-basic-button
                    [btnType]="'Add'"
                    [btnText]="'Add'"
                    (onClick)="closeDialog()"
                  ></app-basic-button>
                  <app-basic-button
                    [btnType]="'Cancel'"
                    [btnText]="'Cancel'"
                    (onClick)="cancelDialog()"
                  ></app-basic-button>
                </mat-dialog-actions>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <h3 class="text-base font-bold mb-2">
        {{ "Company.CompanyAdmin" | translate }}
      </h3>
      <div class="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div class="flex column">
          <mat-label class="field-label"
            >{{ "Company.BasicForm.FirstName" | translate }}
            <span
              [ngClass]="
                createCompForm.invalid &&
                createCompForm.get('companyAdminFirstName').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="companyAdminFirstName"
            />
            <mat-error
              *ngIf="
                createCompForm.invalid &&
                createCompForm.get('companyAdminFirstName').hasError('required')
              "
              >{{ "Errors.Required" | translate }}</mat-error
            >
            <mat-error
              *ngIf="
                createCompForm
                  ?.get('companyAdminFirstName')
                  .hasError('whitespace')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex column">
          <mat-label class="field-label">{{
            "Company.BasicForm.LastName" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="companyAdminLastName"
            />
            <mat-error
              *ngIf="
                createCompForm.invalid &&
                createCompForm.get('companyAdminLastName').hasError('required')
              "
              >{{ "Errors.Required" | translate }}</mat-error
            >
            <mat-error
              *ngIf="
                createCompForm
                  ?.get('companyAdminLastName')
                  .hasError('whitespace')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex column">
          <mat-label class="field-label"
            >{{ "Company.BasicForm.Email" | translate }}
            <span
              [ngClass]="
                createCompForm.invalid &&
                createCompForm.get('companyAdminEmail').hasError('required')
                  ? 'is-invalid'
                  : 'is-valid'
              "
              >*</span
            >
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="text"
              formControlName="companyAdminEmail"
            />
            <mat-error
              *ngIf="
                createCompForm.invalid &&
                createCompForm.get('companyAdminEmail').hasError('required')
              "
              >{{ "Errors.Required" | translate }}</mat-error
            >
            <mat-error
              *ngIf="
                createCompForm.invalid &&
                createCompForm.get('companyAdminEmail').hasError('invalidEmail')
              "
              >{{ "Errors.InvalidEmail" | translate }}</mat-error
            >
          </mat-form-field>
        </div>
        <div class="flex column">
          <mat-label class="field-label">{{
            "Company.BasicForm.Phone" | translate
          }}</mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              oninput="this.value = this.value.replace(/[^0-9.+()]/g, '').replace(/(\..*?)\..*/g, '$1');"
              type="tel"
              formControlName="companyPhoneNumber"
            />
            <mat-error
              *ngIf="
                createCompForm.invalid &&
                createCompForm.get('companyPhoneNumber').hasError('required')
              "
            >
              {{ "Errors.Required" | translate }}</mat-error
            >
            <mat-error
              *ngIf="
                createCompForm.invalid &&
                createCompForm
                  .get('companyPhoneNumber')
                  .hasError('invalidPhoneNumber')
              "
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <h3 class="text-base font-bold mb-2">
        {{ "Company.CompanyFeatures" | translate }}
      </h3>
      <div class="flex column">
        <div *ngIf="sefetyFeatures.length > 0">
          <mat-label class="field-label"
            >{{ "Company.BasicForm.SafetyFeatures" | translate }}
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              [placeholder]="
                'Company.BasicForm.SafetyFeatures' | translate
              "
              formControlName="safetyFeatures"
              multiple
              (selectionChange)="setSelectedFeature($event)"
            >
              <mat-option [value]="'ALL'"> All </mat-option>
              <mat-option
                *ngFor="let feature of sefetyFeatures"
                [value]="feature.featureCode"
              >
                {{ "Feature." + feature.featureName | translate }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                createCompForm.invalid &&
                createCompForm.get('safetyFeatures').hasError('required')
              "
              >{{ "Errors.Required" | translate }}</mat-error
            >
          </mat-form-field>
        </div>
        <div *ngIf="secFeatures.length > 0">
          <mat-label class="field-label"
            >{{ "Company.BasicForm.SecurityFeatures" | translate }}
          </mat-label>
          <mat-form-field class="input-field" appearance="outline">
            <mat-select
              [placeholder]="
                'Company.BasicForm.SecurityFeatures' | translate
              "
              formControlName="securityFeatures"
              multiple
              (selectionChange)="setSelectedFeature($event)"
            >
              <mat-option [value]="'ALL'"> All </mat-option>
              <mat-option
                *ngFor="let feature of secFeatures"
                [value]="feature.featureCode"
              >
                {{ "Feature." + feature.featureName | translate }}</mat-option
              >
            </mat-select>
            <mat-error
              *ngIf="
                createCompForm.invalid &&
                createCompForm.get('securityFeatures').hasError('required')
              "
              >{{ "Errors.Required" | translate }}</mat-error
            >
          </mat-form-field>
        </div>
        <div *ngIf="setLicenseQuota" class="flex column">
          <mat-label class="field-label"
            >{{ "Company.BasicForm.LicenseQuota" | translate }}
          </mat-label>
          <mat-form-field
            class="input-field"
            appearance="outline"
            class="w-5/12"
          >
            <input
              [placeholder]="'Placeholder.AddText' | translate"
              matInput
              type="number"
              formControlName="CompQuota"
            />
            <mat-error
              *ngIf="
                createCompForm.invalid &&
                createCompForm.get('CompQuota').hasError('required')
              "
              >{{ "Errors.Required" | translate }}</mat-error
            >
            <mat-error
              *ngIf="createCompForm?.get('CompQuota').hasError('whitespace')"
            >
              <div class="flex justify-flex-start align-flex-center">
                <img
                  class="error-img"
                  src="assets/img/resources/error-icon.svg"
                />
                <span>{{ "Errors.InValidData" | translate }}</span>
              </div>
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <!-- <div class="flex column">
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            [placeholder]="'Company.BasicForm.Features' | translate"
            formControlName="features"
            multiple
          >
            <mat-option
              *ngIf="features.length !== 0"
              (click)="selectedvalue($event)"
              [value]="'ALL'"
            >
              All
            </mat-option>
            <mat-option
              *ngFor="let feature of features"
              (click)="selectAll($event)"
              [value]="feature.featureCode"
            >
              {{ "Feature." + feature.featureName | translate }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              createCompForm.invalid &&
              createCompForm.get('features').hasError('required')
            "
            >{{ "Errors.Required" | translate }}</mat-error
          >
        </mat-form-field>
      </div> -->
      <div
        class="grid gap-2 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1"
        *ngIf="selectedFeatures.length > 0"
      >
        <div
          class="rounded overflow-hidden shadow-lg border-b-4 border-primary"
          *ngFor="let feature of selectedFeatures; let i = index"
        >
          <div class="group w-full h-52 overflow-hidden relative">
            <img
              class="transition-all absolute top-0 left-0 h-full w-full hover:scale-110"
              [src]="'assets/img/resources/' + feature.image"
              [alt]="feature.featureName"
            />
            <div
              class="transition-transform duration-500 flex flex-col justify-center items-center font-bold text-primary feature_card_bg absolute bg-clip-content p-6 border-4 border-primary/50 border-dashed top-0 left-0 h-full w-full z-50 transform translate-y-60 group-hover:translate-y-0"
            >
              <img
                [src]="'assets/img/resources/' + feature.icon"
                [alt]="feature.featureName"
                class="w-16 mb-2"
              />
              {{ "Feature." + feature.featureName | translate }}
            </div>
          </div>
          <div class="px-4 py-4">
            <div
              class="font-bold text-lg mb-2 bg-clip-text text-transparent bg-gradient-to-r from-primary to-black text-center"
            >
              {{ "Feature." + feature.featureName | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-end my-3">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="cancelForm()"
        ></app-basic-button>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'Add'"
          [isDisabled]="
          !createCompForm.valid || selectedFeatureCodes.length <= 0
        "
        [matTooltip]="
          selectedFeatureCodes.length <= 0
            ? 'Set at least one feature.'
            : null
        "
          (onClick)="createCompany()"
        ></app-basic-button>
      </div>
    </form>
  </app-state-section>
</app-card>
