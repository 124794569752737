<div class="container">
   <h3 class="font-bold mt-4 pt-5">Search Results</h3>
   <ul class="mt-4">
      <li *ngFor="let element of searchResults | paginate: {id: 'server', itemsPerPage: pageSize, currentPage: pageNumber, totalItems: total }">
          <app-search-card
          [searchData]="element"
          (onCardClick)="viewPage($event)"
          ></app-search-card>
      </li>
   </ul>
   <div class="text-center" *ngIf="searchResults?.length > 0">
      <pagination-controls class="my-pagination" (pageChange)="getData($event) "id="server"></pagination-controls>
   </div>
   <div *ngIf="searchResults?.length === 0"
   class="flex-auto flex flex-col items-center justify-center mt-5">
   <img src="assets/img/resources/empty-state.svg" alt="not found">
   <h2 class="headline m-0 text-center text-primary">{{'General.EmptyTableList' | translate}}...</h2>
 </div>
</div>


