<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.EmergencyContacts' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <app-state-section
      body
      [flexibleHeight]="true"
      [state]="sectionState"
      [loadingLabel]="loadingLabel | translate"
      [errorLabel]="'Errors.ErrorHappened' | translate"
    >
      <div class="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 min-[2000px]:grid-cols-4" >
        <app-contacts-card
          *ngFor="let contact of contacts"
          (onClick)="actionsBtn($event)"
          [contact]="contact"
        >
        
        </app-contacts-card>
        <app-add-contact-card
          *ngIf="hasCreatePermissions | permissionChecker"
          (onClick)="btnClick($event)"
        ></app-add-contact-card>
      </div>
    </app-state-section>
  </hodhod-page-layout-content>
</hodhod-page-layout>
