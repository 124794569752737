import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import {
  EmailValidator,
  noWhitespaceValidator,
} from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { AuthService } from 'src/backend/services/auth.service';
import { CompanyDetail } from '../../models/company-detail';

@Component({
  selector: 'app-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss'],
  animations: [fadeInUp400ms, fadeInRight400ms, scaleIn400ms],
})
export class CompanyDetailComponent implements OnInit {
  @Input() compInfo: CompanyDetail;
  @Input() compLicenseInfo: any;
  public companyInfo: CompanyDetail;
  public ACTIVE_COLOR = '#67DD2D';
  public INACTIVE_COLOR = '#F76F45';

  @ViewChild('compImageBigView') compImageBigView!: TemplateRef<any>;
  @ViewChild('emailChangeModal') changeEmailModal!: TemplateRef<any>;
  emailChangeForm: FormGroup;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public translationsList: any = {};
  public destroy$ = new Subject();
  public isSuperAdmin: boolean;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private translate: TranslateService,
    private authService: AuthService,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService
  ) {
    this.translate
      .get(['Errors', 'Success', 'User', 'confirmVerificationCode'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.emailChangeForm = this.fb.group({
      email: ['', [Validators.required, noWhitespaceValidator, EmailValidator]],
    });
    const roleIds: (number | string)[] = JSON.parse(
      localStorage.getItem('logged_in_user')
    )['roleIds'];
    this.isSuperAdmin = roleIds.includes(
      '9af4aa46-9587-42d3-81a7-86f54ac9ed30'
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['compInfo']) {
      this.companyInfo = this.compInfo;
    }
  }

  fullImageView(): void {
    this.dialog.open(this.compImageBigView, { height: '97%', width: '80%' });
  }

  openChangeEmailModal(): void {
    this.emailChangeForm.get('email').setValue('');
    this.dialog.open(this.changeEmailModal, { height: 'auto', width: '30%' });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  changeEmail() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const data = {
            userId: this.companyInfo?.companyAdminId,
            email: this.emailChangeForm.value.email,
          };
          this.authService.changeUserEmail(data).subscribe({
            next: (response) => {
              this.emailChangeForm.get('email').setValue('');
              this.loadingService.stopLoading();
              this.closeDialog();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
            },
            error: ({ error }) => {
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
  }

  resendVerificationCode(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['confirmVerificationCode']['Title'],
          this.translationsList['confirmVerificationCode']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const companyCode = this.companyInfo.companyCode;
          this.loadingService.startLoading(true, '');
          this.authService
            .resendVerificationCodeFormCompanyAdmin(companyCode)
            .subscribe({
              next: (response) => {
                this.loadingService.stopLoading();
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, response?.message)
                );
              },
              error: ({ error }) => {
                this.loadingService.stopLoading();
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
              },
            });
        }
      });
  }
}
