export interface Feature {
    id: number;
    featureCode: string;
    featureName: string;
    image: string;
    icon: string;
}
export interface Features {
  module:string,
  feature:Feature[]
}
export interface SelectedFeature {
    safety:Feature[];
    security:Feature[]
}

