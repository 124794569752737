import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import {
  ChecklistSectionType,
  ReportingType,
  TaskStatus,
} from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { RescheduleTaskPopupComponent } from 'src/app/task-tracker/components/reschedule-task-popup/reschedule-task-popup.component';
import { GetTaskTrackerViewhistory } from 'src/app/task-tracker/models/view-task-tracker-model';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import {
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { TaskTrackerService } from 'src/backend/services/task-tracker/task-tracker.service';
import { WorkFlowService } from 'src/backend/services/work-flow.service';

@Component({
  selector: 'app-view-task-tracker-page',
  templateUrl: './view-task-tracker-page.component.html',
  styleUrls: ['./view-task-tracker-page.component.scss'],
  animations: [
    fadeInUp400ms,
    fadeInRight400ms,
    scaleIn400ms,
    stagger40ms,
    scaleFadeIn400ms,
  ],
})
export class ViewTaskTrackerPageComponent implements OnInit {
  @ViewChild('closeOrRejectModal') rejectCloseModal!: TemplateRef<any>;
  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  ReportingType = ReportingType;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  private destroy$ = new Subject();
  loadingLabel: string = '';
  dataSource = new MatTableDataSource<GetTaskTrackerViewhistory>([]);
  taskId: number;
  gaugeValue = 0;
  data: any;
  rejectOrCloseForm: FormGroup;
  companyKey: string = '';
  instanceKey: string = '';
  modalName: string;
  loggedUser: LoggedUser;
  rulesApplied: Array<{
    condition: string;
    leftOperand: string;
    rightOperand: string;
    operator: string;
    order: number;
  }> = [];
  private translationsList: any = {};
  public checklist: any[] = [];
  workFlow: WorkFlow;
  selectedImg: any;
  constructor(
    private dialogRef: MatDialog,
    private route: ActivatedRoute,
    private feedBackService: AsyncFeedbackService,
    private TaskTrackerService: TaskTrackerService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private router: Router,
    private workFlowService: WorkFlowService,
    private baseApi: BaseApi,
    private loadingService: LoadingService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'User',
        'CheckAlerts',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.rejectOrCloseForm = this.fb.group({
      comment: [''],
      file: [[]],
    });
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        this.taskId = parseInt(params.get('id'), 10);
      }
    });
    this.getHistoryData();
  }

  rescheduleTask() {
    this.dialogRef.open(RescheduleTaskPopupComponent, {
      height: 'auto',
      width: '50%',
      data: { taskId: this.taskId },
    });
  }

  closeDialog(): void {
    this.dialogRef.closeAll();
  }

  openCloseRejectModal(name: string): void {
    this.rejectOrCloseForm.get('comment').setValue('');
    this.rejectOrCloseForm.get('file').setValue([]);
    this.modalName = name;
    this.dialogRef.open(this.rejectCloseModal, {
      height: 'auto',
      width: '50%',
    });
  }

  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }
  openBigView(imageUrl: string): void {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = imageUrl.split('.').pop();
    if (imageExtensions.includes(extension)) {
      this.selectedImg = imageUrl;
      this.dialog.open(this.imageBigView, {
        maxHeight: '95vh',
        width: 'auto',
      });
    } else {
      const downloadTag = document.createElement('a');
      downloadTag.href = imageUrl;
      downloadTag.addEventListener('click', () => {
        downloadTag.download;
      });
      downloadTag.click();
    }
  }
  downloadDocument(url: any): void {
    const downloadTag = document.createElement('a');
    downloadTag.href = url;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }

  checkExtension(e: any) {
    let files = this.rejectOrCloseForm.get('file').value;
    const images = files.filter(
      (x) => x.type === 'image/png' || x.type === 'image/jpeg'
    );
    const otherFiles = files.filter(
      (x) => x.type != 'image/png' && x.type != 'image/jpeg'
    );
    if (otherFiles.length > 0) {
      this.rejectOrCloseForm.get('file').setValue(images);
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Warning,
          'Only png and jpeg files supported'
        )
      );
    }
  }

  getHistoryData() {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.TaskTrackerService.getTaskHistory(this.taskId).subscribe({
      next: (response) => {
        this.data = response;
        this.getWorkFlow();
        //this.instanceKey = this.data?.instanceKey;
        this.sectionState = SectionStateStatus.Ready;
        this.checklist = this.formatCheckListData(this.data?.checkList.section);
        this.GetScorePercentage();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_AUDIT_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
        this.getWorkflowInstanceKeyById(this.workFlow.workFlowKey);
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getWorkflowInstanceKeyById(workflowKey: string) {
    this.workFlowService
      .getInstanceKeyByTableId(workflowKey, this.taskId)
      .subscribe({
        next: (res) => {
          this.instanceKey = res?.instanceKey
            ? res?.instanceKey
            : this.data?.instanceKey;
          this.getTransitionRules();
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
        },
      });
  }

  formatCheckListData(data: any[]): any[] {
    const formatedData = data.map((section, i) => {
      return {
        sectionId: section?.sectionId,
        title: section?.sectionTitle,
        questionType: section?.sectionType,
        questions: section?.questions?.map((question, j) => {
          return {
            questionId: question?.questionId,
            questionText:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.questionTitle, 10)
                : question?.questionTitle,
            answer:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.Range
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[0]
                : question?.answer,
            target:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[1]
                : '',
            parameters: [],
            userAnswer:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.userAnswer
                  ? question?.userAnswer.split(' ')[1]
                  : ''
                : question?.userAnswer,
            comment: question?.comment,
            isNotApplicable: question?.isNotApplicable,
            media: question?.media,
            mediaName: question?.mediaName,
          };
        }),
      };
    });
    return formatedData;
  }

  GetScorePercentage(): any {
    let correctanswers = 0;
    let NoOfQuestions = 0;
    let Percentage = 0;
    let sectionsFormArray = this.formatCheckListData(
      this.data?.checkList.section
    );
    sectionsFormArray.forEach((element) => {
      if (element.questionType === 'Value Vs Target') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (
              el1.answer + ' ' + ' ' + el1.target ==
              el1.answer + ' ' + ' ' + el1.userAnswer
            ) {
              correctanswers++;
            }
            NoOfQuestions++;
          }
        });
      } else if (element.questionType === 'Select from range') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            switch (el1.userAnswer) {
              case '0':
                correctanswers = correctanswers + 0;
                break;
              case '1':
                correctanswers = correctanswers + 0.25;
                break;
              case '2':
                correctanswers = correctanswers + 0.5;
                break;
              case '3':
                correctanswers = correctanswers + 0.75;
                break;
              case '4':
                correctanswers++;
                break;
              default:
                break;
            }
            NoOfQuestions++;
          }
        });
      } else {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (el1.answer == el1.userAnswer) {
              correctanswers++;
            }
            NoOfQuestions++;
          }
        });
      }
    });
    Percentage = (correctanswers / NoOfQuestions) * 100;
    this.gaugeValue = Number(Percentage.toFixed(1));
  }

  approveAssigneeActions(): void {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;

    this.TaskTrackerService.submitTaskStatus(this.taskId).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
        this.getHistoryData();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onClickActions(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['NeedActions']['Title'],
          this.translationsList['CheckAlerts']['NeedActions']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.rulesApplied = this.rulesApplied?.map((ele) => {
            return {
              ...ele,
              rightOperand: 'Required',
            };
          });
          this.setWorkFlowInstance(true);
        } else {
          this.rulesApplied = this.rulesApplied?.map((ele) => {
            return {
              ...ele,
              rightOperand: 'NotRequired',
            };
          });
          this.setWorkFlowInstance(false);
        }
      });
  }

  needActions(): void {
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.TaskTrackerService.actionTaskStatus(this.taskId).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
        this.goBack();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  close(): void {
    const formData = new FormData();
    const data = {
      taskId: this.taskId,
      comment: this.rejectOrCloseForm.value.comment,
    };
    formData.append('data', JSON.stringify(data));
    formData.append('file', this.rejectOrCloseForm.value.file[0]);
    this.TaskTrackerService.closeTask(formData).subscribe({
      next: (res) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, res?.message)
        );
        this.loadingService.stopLoading();
        this.closeDialog();
        this.goBack();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  closeTask(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          if (this.data?.status === TaskStatus.MISSED) {
            this.setPerformWorkFlowInstance();
          } else {
            this.setCloseWorkFlowInstance();
          }
        }
      });
  }

  setCloseWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_CLOSE_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    this.loadingService.startLoading(true, '');
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.close();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  setPerformWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_PERFORM_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.setCloseWorkFlowInstance();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  actionsAssigned(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['CheckAlerts']['AssignedActions']['Title'],
          this.translationsList['CheckAlerts']['AssignedActions']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const data: SetInstanceParam = {
            instanceKey: this.instanceKey,
            userId: this.loggedUser.userId,
            userName: this.loggedUser.fullName,
            actionName: Constants.APP_ACTION,
            rules: [],
            roleId: this.loggedUser.roleIds,
            payload: this.data,
          };
          this.loadingService.startLoading(true, '');
          this.workFlowService.setWorkFlowInstance(data).subscribe({
            next: (response) => {
              this.loadingService.stopLoading();
              this.approveAssigneeActions();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  onClickReject(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const data: SetInstanceParam = {
            instanceKey: this.instanceKey,
            userId: this.loggedUser.userId,
            userName: this.loggedUser.fullName,
            actionName: Constants.APP_CLOSE_ACTION,
            rules: [],
            roleId: this.loggedUser.roleIds,
            payload: this.data,
          };
          this.loadingService.startLoading(true, '');
          this.workFlowService.setWorkFlowInstance(data).subscribe({
            next: (response) => {
              const formData = new FormData();
              const content = {
                taskId: this.taskId,
                comment: this.rejectOrCloseForm.value.comment,
              };
              formData.append('data', JSON.stringify(content));
              formData.append('file', this.rejectOrCloseForm.value.file[0]);
              this.TaskTrackerService.rejectTaskStatus(formData).subscribe({
                next: (res) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Success, res?.message)
                  );
                  this.loadingService.stopLoading();
                  this.closeDialog();
                  this.goBack();
                },
                error: ({ error }) => {
                  this.feedBackService.showFeedback(
                    new FeedbackModel(FeedbackType.Failure, error?.message)
                  );
                  this.loadingService.stopLoading();
                },
              });
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }

  getGuageColors(value: number): string {
    if (value < 40) {
      return '#fa2020';
    }
    if (value >= 40 && value < 70) {
      return '#2041fa';
    }
    if (value >= 70) {
      return '#42f548';
    }
    return '';
  }

  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.TASK_TRACKER +
        '/' +
        SharedConstants.TASK_TRACKER_List,
    ]);
  }

  setWorkFlowInstance(isRequired: boolean): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_NEEDACTION_ACTION,
      rules: this.rulesApplied,
      roleId: this.loggedUser.roleIds,
      payload: this.data,
    };
    this.loadingService.startLoading(true, '');
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {
        if (isRequired) {
          this.needActions();
        } else {
          this.approveAssigneeActions();
        }
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
  getTransitionRules(): void {
    const data = {
      instanceKey: this.instanceKey,
      actionName: Constants.APP_NEEDACTION_ACTION,
    };
    this.workFlowService.getTransitionRules(data).subscribe({
      next: (response) => {
        this.rulesApplied = response;
      },
      error: ({ error }) => {
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  openImageInBigView(): void {
    if (this.data?.proofOfLocation) {
      this.selectedImg = this.data?.proofOfLocation;
      this.dialog.open(this.imageBigView, { height: '97%', width: '80%' });
    }
  }
  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }
}
