import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from 'src/backend/api/base-api';

@Injectable({
  providedIn: 'root',
})
export class IotDashboardService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getAllCustomizedIotDashboards(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_CUSTOMIZED_IOT_DASHBOARDS)
    );
  }

  getAnalogDashboard(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_ANALOG_DASHBOARD_DATA),
      data
    );
  }

  updateFilterForIot(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_FILTER_FOR_IOT),
      data
    );
  }

  getDigitalDashboard(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_GET_DIGITAL_DASHBOARD_DATA),
      data
    );
  }

  updateDigitalParameters(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_UPDATE_DIGITAL_PARAMETERS),
      data
    );
  }

  getAnalogMachines() {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_ANALOG_MACHINES)
    );
  }
  getAnalogParameters(machineID: number) {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_ANALOG_PARAMETERS + '/' + machineID
      )
    );
  }
  createAnalaogDashboard(data) {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CREATE_ANALOG_DASHBOARD),
      data
    );
  }
  removeDashboard(id:any){
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_DELETE_ANALOG_DASHBOARD + '/' + id
      )
    );
  }

  
}
