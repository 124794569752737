<div class="container">
  <button class="mx-1 print-btn">
    <img src="assets/img/print_icon.png" alt="print" (click)="print()" />
  </button>
  <div
    class="flex items-center filter-date"
    app-click-stop-propagation
    (click)="select.open(); openSelect = true"
  >
    <span>Date: </span>
    <span style="color: #d73651">
      {{ "Enum.DashboardFilters." + dashboard.filterLabel | translate }}
    </span>
    <mat-icon
      style="width: 20px; height: 20px; cursor: pointer"
      svgIcon="mat:keyboard_arrow_down"
      color="primary"
    ></mat-icon>
  </div>
  <mat-select
    #select
    class="select-options"
    [value]="dashboard.filterLabel"
    (selectionChange)="onChangeCalenderDD($event.value)"
    (openedChange)="checkSelectIfOpen($event)"
  >
    <mat-option
      *ngFor="let item of filterDashboards | enumToArray"
      [value]="item.value"
    >
      {{ "Enum.DashboardFilters." + item.value | translate }}
    </mat-option>
  </mat-select>
</div>
<div>
  <div class="flex column justify-center">
    <app-dashboard-card
      *ngIf="showLevelOne"
      class="card"
      [hideActions]="true"
      [dashboard]="dashboard"
      [chartLevel]="'one'"
      [clickedPoint]="clickedPoint"
      (chartClickedEvent)="changeChartLevel($event)"
      (fullscreenDashboard)="updateDashboard($event)"
      [fullScreenOpen]="true"
      [fullScreenFilter]="filterData"
    ></app-dashboard-card>

    <div id="levelTwo" [class]="showLevelTwo ? 'card' : 'hide-card'">
      <app-dashboard-card
        *ngIf="showLevelTwo && dashboard.chartData.part2?.length > 0"
        [hideActions]="true"
        [dashboard]="dashboard"
        [chartLevel]="'two'"
        [clickedPoint]="clickedPoint"
        (chartClickedEvent)="changeChartLevel($event)"
        [fullScreenOpen]="true"
      ></app-dashboard-card>
    </div>

    <div
      id="levelThree"
      [class]="
        showLevelThree &&
        dashboard.chartData.part2?.length > 0 &&
        dashboard.chartData.part3?.length > 0
          ? 'card'
          : 'hide-card'
      "
    >
      <app-dashboard-card
        id="levelThree"
        *ngIf="
          showLevelThree &&
          dashboard.chartData.part2?.length > 0 &&
          dashboard.chartData.part3?.length > 0
        "
        [hideActions]="true"
        [dashboard]="dashboard"
        [chartLevel]="'three'"
        [clickedPoint]="clickedPoint"
        (chartClickedEvent)="changeChartLevel($event)"
        [fullScreenOpen]="true"
      ></app-dashboard-card>
    </div>

    <div
      id="levelFour"
      [class]="
        showLevelFour && dashboard.chartData.part4?.length > 0
          ? 'card'
          : 'hide-card'
      "
    >
      <app-dashboard-card
        *ngIf="showLevelFour"
        [hideActions]="true"
        [dashboard]="dashboard"
        [chartLevel]="'four'"
        [clickedPoint]="clickedPoint"
        [fullScreenOpen]="true"
      ></app-dashboard-card>
    </div>
  </div>
</div>

<div id="pdfContent" [class]="printClicked ? '' : 'hide-card'">
  <div
    class="flex align-center mx-2 mb-1"
    style="justify-content: end"
    *ngIf="showLevelOne"
  >
    <!-- target -->
    <div class="total">
      <span
        *ngIf="dashboard.dashboardType == 'Reporting'"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.TotalReported" | translate }}</span
      >
      <p
        *ngIf="dashboard.totalReported != null"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.totalReported }}
      </p>

      <!-- total permit and plan -->
      <span
        *ngIf="dashboard.total"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.TotalReport" | translate }}</span
      >
      <p
        *ngIf="dashboard.total"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.total }}
      </p>
      <!-- inspection score -->
      <span
        *ngIf="dashboard.avgScore"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.AvgScore" | translate }}</span
      >
      <p
        *ngIf="dashboard.avgScore"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.avgScore }} %
      </p>

      <span
        *ngIf="dashboard.completed"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.Completed" | translate }}</span
      >
      <p
        *ngIf="dashboard.completed"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.completed }} %
      </p>
    </div>

    <div class="max">
      <!-- max target -->
      <span
        *ngIf="
          dashboard?.targetData?.targetValue ||
          dashboard?.targetData?.targetValue != 0
        "
        class="title"
        >{{
          dashboard?.targetData?.targetUptoAtLeast == "Upto"
            ? ("Dashboards.MaxTarget" | translate)
            : ("Dashboards.MinTarget" | translate)
        }}
      </span>
      <p
        *ngIf="
          dashboard.targetData.targetValue ||
          dashboard.targetData.targetValue != 0
        "
        class="value"
      >
        {{ dashboard.targetData.targetValue }}
      </p>

      <!-- target -->
      <span
        *ngIf="
          (dashboard.title != 'WorkPermit' && dashboard.target) ||
          dashboard.target == 0
        "
        class="title"
        >{{ "Dashboards.Target" | translate }}
      </span>
      <p
        *ngIf="
          (dashboard.title != 'WorkPermit' && dashboard.target) ||
          dashboard.target == 0
        "
        class="value"
      >
        {{ dashboard.target }}
      </p>
    </div>
  </div>
  <div id="container1"></div>
  <hr class="divider" />
  <!-- level 2 -->
  <div
    class="flex align-center mx-2 mb-1"
    style="justify-content: end"
    *ngIf="showLevelTwo"
  >
    <div class="total">
      <span
        *ngIf="dashboard.dashboardType == 'Reporting'"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.TotalReported" | translate }}</span
      >
      <p
        *ngIf="dashboard.totalReportedLevelTwo != null"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.totalReportedLevelTwo }}
      </p>

      <!-- total permit and plan -->
      <span
        *ngIf="dashboard.total"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.TotalReport" | translate }}</span
      >
      <p
        *ngIf="dashboard.total"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.total }}
      </p>
      <!-- inspection score -->
      <span
        *ngIf="dashboard.avgScore"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.AvgScore" | translate }}</span
      >
      <p
        *ngIf="dashboard.avgScore"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.avgScore }} %
      </p>

      <span
        *ngIf="dashboard.completed"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.Completed" | translate }}</span
      >
      <p
        *ngIf="dashboard.completed"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.completed }} %
      </p>
    </div>
  </div>
  <div id="container2"></div>
  <hr class="divider" />

  <!-- level3 -->
  <div
    class="flex align-center mx-2 mb-1"
    style="justify-content: end"
    *ngIf="showLevelThree"
  >
    <div class="total">
      <span
        *ngIf="dashboard.dashboardType == 'Reporting'"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.TotalReported" | translate }}</span
      >
      <p
        *ngIf="dashboard.totalReportedLevelThree != null"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.totalReportedLevelThree }}
      </p>

      <!-- total permit and plan -->
      <span
        *ngIf="dashboard.total"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.TotalReport" | translate }}</span
      >
      <p
        *ngIf="dashboard.total"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.total }}
      </p>
      <!-- inspection score -->
      <span
        *ngIf="dashboard.avgScore"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.AvgScore" | translate }}</span
      >
      <p
        *ngIf="dashboard.avgScore"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.avgScore }} %
      </p>

      <span
        *ngIf="dashboard.completed"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.Completed" | translate }}</span
      >
      <p
        *ngIf="dashboard.completed"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.completed }} %
      </p>
    </div>
  </div>
  <div id="container3" *ngIf="showLevelThree"></div>
  <hr class="divider" />

  <!-- level4 -->
  <div
    class="flex align-center mx-2 mb-1"
    style="justify-content: end"
    *ngIf="showLevelFour"
  >
    <div class="total">
      <span
        *ngIf="dashboard.dashboardType == 'Reporting'"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.TotalReported" | translate }}</span
      >
      <p
        *ngIf="dashboard.totalReportedLevelFour != null"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.totalReportedLevelFour }}
      </p>

      <!-- total permit and plan -->
      <span
        *ngIf="dashboard.total"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.TotalReport" | translate }}</span
      >
      <p
        *ngIf="dashboard.total"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.total }}
      </p>
      <!-- inspection score -->
      <span
        *ngIf="dashboard.avgScore"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.AvgScore" | translate }}</span
      >
      <p
        *ngIf="dashboard.avgScore"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.avgScore }} %
      </p>

      <span
        *ngIf="dashboard.completed"
        class="title"
        [ngStyle]="{ color: dashboard.textColor }"
        >{{ "Dashboards.Completed" | translate }}</span
      >
      <p
        *ngIf="dashboard.completed"
        class="value"
        [ngStyle]="{ color: dashboard.textColor }"
      >
        {{ dashboard.completed }} %
      </p>
    </div>
  </div>
  <div id="container4" *ngIf="showLevelFour"></div>
</div>
