import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';
import {
  GetAllWorkConditions,
  PostWorkCondition,
  UpdateWorkCondition,
} from 'src/backend/models/work-conditions/work-conditions.model';

@Injectable({
  providedIn: 'root',
})
export class WorkConditionsService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  postWorkCondition(data: PostWorkCondition): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORK_CONDITION_CREATE),
      data
    );
  }

  UpdateWorkCondition(data: UpdateWorkCondition): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_WORK_CONDITION_UPDATE),
      data
    );
  }

  getWorkConditionList(
    data: any
  ): Observable<DataMapper<GetAllWorkConditions>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<GetAllWorkConditions>>(
      this.baseApi.encodeURL(
        Constants.API_WORK_CONDITION_GET_ALL + '?' + params
      )
    );
  }

  deActivate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_WORK_CONDITION_DEACTIVATE + '/' + id)
    );
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_WORK_CONDITION_ACTIVATE + '/' + id)
    );
  }
  getWorkConditionsWithCheckListExecution(): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(Constants.API_WORK_CONDITIONS_CHECKLIST)
    );
  }
}
