import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { Dashboard } from '../../models/dashboard';
import Drilldown from 'highcharts/modules/drilldown';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { Router } from '@angular/router';

Drilldown(Highcharts);

@Component({
  selector: 'app-dashboard-chart-two',
  templateUrl: './dashboard-chart.component.html',
  styleUrls: ['./dashboard-chart.component.scss'],
})
export class DashboardChartComponent implements OnInit, OnChanges {
  @Input() dashboard: Dashboard;
  @Input() chartLevel: string;
  @Input() chartTitle: string;
  @Input() chartOptimized: any;
  @Input() color: any;
  @Input() fullScreenOpen: boolean = false;
  @Input() isIot: boolean = false;

  @Output() onChartClicked: EventEmitter<any> = new EventEmitter();

  public levelData: any;
  public chart: any;

  public clickedPoint: {
    point: string;
    level: string;
    color: string;
    value: Number;
  } = {
    point: '',
    level: '',
    color: '',
    value: 0,
  };

  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    let colors = [];

    if (changes['color']?.currentValue) {
      for (let index = 0; index < this.chartOptimized?.length; index++) {
        const brightnessFactor =
          ((index - this.chartOptimized?.length / 2) /
            this.chartOptimized?.length) *
          0.4;
        const el = Highcharts.color(this.color)
          .brighten(brightnessFactor)
          .get();

        colors.push(el);
      }
    }

    this.chart = new Chart({
      chart: {
        type: this.isIot ? 'line' : 'pie',
      },
      title: {
        text:
          this.dashboard?.dashboardName == 'AuditScore' &&
          this.chartLevel == 'one'
            ? Number(
                this.chartOptimized[0].data.find((item) => item.name === 'PASS')
                  .y
              ).toFixed(2) + ' %'
            : '',
        verticalAlign: 'middle',
        align: 'center',
        y: -4,
        style: {
          display:
            this.dashboard?.dashboardName == 'AuditScore' &&
            this.chartLevel == 'one'
              ? ''
              : 'none',
        },
      },
      legend: {
        enabled: true,
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        squareSymbol: false,
        borderRadius: 0,
        symbolRadius: 3,
        symbolHeight: 10,
        symbolWidth: 12,
        symbolPadding: 12,
        itemStyle: {
          width: 100,
          height: 100,
          position: 'absolute',
        },
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y}',
          },
          point: {
            events: {
              click: (event) => {
                this.clickedPoint.point = event?.point?.options?.drilldown;

                this.clickedPoint.level =
                  this.chartLevel != null ? this.chartLevel : null;

                this.clickedPoint.color = event?.point?.color?.toString();

                this.clickedPoint.value = event?.point?.options?.y;


                this.onChartClicked.emit(this.clickedPoint);
              },
            },
          },
        },
        column: {
          borderRadius: 5,
          dataLabels: {
            padding: 10,
            borderRadius: 5,
            backgroundColor: '#ffffff',
            color: '#000000',
            borderWidth: 0,
            borderColor: 'none',
            x: 0,
            y: 15,
            format:
              this.dashboard?.dashboardName === 'AuditAdherence' ||
              this.dashboard?.dashboardName === 'AuditScore'
                ? '{point.y:.2f} %'
                : '{point.y}',
          },
        },
        bar: {
          dataLabels: {
            enabled: true,
            padding: 6,
            borderRadius: 5,
            backgroundColor: '#ffffff',
            color: '#000000',
            borderWidth: 0,
            borderColor: 'none',
            y: 0,
            x: -10,
          },
        },
        pie: {
          allowPointSelect: true,
          center: ['50%', '50%'],
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.2f} %',
            distance: -45,
            backgroundColor: '#ffffff',
            color: '#000000',
            style: {
              display:
                this.dashboard?.dashboardName == 'AuditScore' ? 'none' : '',
            },
          },
          showInLegend: true,
        },
        line: {
          dataLabels: {
            enabled: true,
          },
          marker: {
            symbol: 'circle',
            color: '#26326E',
          },
          showInLegend: true,
          enableMouseTracking: true,
        },
      },
      xAxis: {
        type: this.isIot ? 'datetime' : 'linear',
        gridLineColor: 'transparent',
        categories: this.isIot ? this.chartOptimized.xValues : null,
        tickInterval: this.isIot ? 1 : 5,
        title: {
          text: this.chartTitle ? this.chartTitle : null,
          style: {
            fontSize: '14px',
            color: '#6F6F6F',
          },
        },
      },
      yAxis: {
        min: 0,
        gridLineColor: 'transparent',
        title: this.isIot
          ? {
              align: 'middle',
              text: this.isIot ? this.chartOptimized?.unit : '',
            }
          : {
              text: '',
            },
        tickInterval: 5,
        plotBands: [
          {
            // correct range
            from:
              this.isIot && this.chartOptimized?.lowerRange
                ? this.chartOptimized?.lowerRange
                : 0,
            to:
              this.isIot && this.chartOptimized?.upperRange
                ? this.chartOptimized?.upperRange
                : 0,
            color: 'rgba(151, 232, 132, 0.3)',
          },
        ],
      },
      credits: {
        enabled: false,
      },
      exporting: {
        enabled: false,
      },
      drilldown: {
        breadcrumbs: {
          style: {
            display: 'none',
          },
        },
        allowPointDrilldown: false,
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
        },
      },
      colors: colors,
      series: this.isIot ? this.chartOptimized.chartData : this.chartOptimized,
    });
  }

  ngOnInit(): void {}
}
