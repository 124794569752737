<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.ReportHazard' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container-fluid">
    <div body>
      <div>
        <app-hazard-report-view
          [hazardReport]="hazardReport"
          (onCloseReport)="getHazardReport($event)"
        ></app-hazard-report-view>
      </div>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
