import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/@hodhod/common/constants';
import {
  ActionPriority,
  ActionStatus,
  ReportingType,
  SortDirection,
} from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ActionTrackerService } from 'src/backend/services/action-tracker/action-tracker.service';
import { ActionTracker } from '../../models/action-tracker';
import { merge } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { MatSelectChange } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { MediaService } from 'src/backend/services/media.service';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { PermissionService } from 'src/backend/services/permission.service';
import { ViewActionComponent } from '../view-action/view-action.component';

@UntilDestroy()
@Component({
  selector: 'app-action-tracker',
  templateUrl: './action-tracker.component.html',
  styleUrls: ['./action-tracker.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class ActionTrackerComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('closeModal') closeModal!: TemplateRef<any>;

  columns: TableColumn<ActionTracker>[] = [
    { label: 'Code', property: 'id', type: 'text', visible: true },
    {
      label: 'Title',
      property: 'title',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Priority',
      property: 'priority',
      type: 'boolean',
      visible: true,
      cssClasses: ['font-medium'],
    },
    { label: 'Source', property: 'reportType', type: 'boolean', visible: true },
    {
      label: 'AssignedTo',
      property: 'assignToName',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-small'],
    },
    {
      label: 'DateAssigned',
      property: 'createdOn',
      type: 'date',
      visible: true,
    },
    { label: 'DueDate', property: 'targetDate', type: 'date', visible: true },
    {
      label: 'CompleteDate',
      property: 'updatedOn',
      type: 'date',
      visible: false,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'Status',
      property: 'actionStatus',
      type: 'boolean',
      visible: true,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<ActionTracker>([]);
  selection = new SelectionModel<ActionTracker>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';
  closeForm: FormGroup;
  actionId: number = 0;

  public sectionState: SectionStateStatus = SectionStateStatus.Loading;
  public actionsTrackers: ActionTracker[] = [];
  public selectedStatus: ActionStatus[] = [];
  public selectedReportingType: ReportingType[] = [];
  public selectedPriority: ActionPriority[] = [];

  SharedConstants = SharedConstants;
  ActionStatus = ActionStatus;
  ReportingType = ReportingType;
  ActionPriority = ActionPriority;
  private translationsList: any = {};
  loggedUser: LoggedUser;

  hasApprovePermissions = ApplicationPermission.ACTION_TRACKER_APPROVE;
  hasReadPermissions = ApplicationPermission.ACTION_TRACKER_VIEW;

  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  currentQueryParams = null;
  filteredParams = null;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private trackerService: ActionTrackerService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private loadingService: LoadingService,
    private mediaService: MediaService,
    private baseApi: BaseApi,
    private permissionService: PermissionService,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  override ngOnInit(): void {
    this.loggedUser = this.baseApi.getUserSession();
    this.closeForm = this.fb.group({
      remarks: [''],
      media: [[]],
    });
    this.dataSource.sort = this.sort;

    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.selectedPriority = this.currentQueryParams?.orgPriority
      ? this.currentQueryParams?.orgPriority.split(',')
      : [];

    this.selectedReportingType = this.currentQueryParams?.oegReportType
      ? this.currentQueryParams?.orgReportType.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }

    this.filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      status: this.currentQueryParams?.orgStatus
        ? this.currentQueryParams?.orgStatus.split(',')
        : this.selectedStatus,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      priority: this.currentQueryParams?.orgPriority
        ? this.currentQueryParams?.orgPriority.split(',')
        : this.selectedPriority,
      reportType: this.currentQueryParams?.orgReportType
        ? this.currentQueryParams?.orgReportType.split(',')
        : this.selectedReportingType,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.trackerService.getActionTrackers(this.filteredParams).subscribe({
      next: (response) => {
        this.actionsTrackers = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  onSelectStatus(event: MatSelectChange) {
    this.selectedStatus = event.value;
    this.pagination.pageIndex = 0;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
      pageIndex: 0,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  onSelectReportingType(event: MatSelectChange) {
    this.selectedReportingType = event.value;
    this.pagination.pageIndex = 0;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgReportType: event.value.toString(),
      pageIndex: 0,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  onSelectPriority(event: MatSelectChange) {
    this.selectedPriority = event.value;
    this.pagination.pageIndex = 0;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgPriority: event.value.toString(),
      pageIndex: 0,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  openCloseModal(event: any, actionId: number): void {
    this.actionId = actionId;
    this.closeForm.get('remarks')?.setValue('');
    this.closeForm.get('media')?.setValue([]);
    if (actionId) {
      this.dialog.open(this.closeModal, { height: 'auto', width: '50%' });
    }
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  closeAction(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['confirmDeactiveRecord']['Title'],
          this.translationsList['confirmDeactiveRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          if (this.closeForm.value.media[0]) {
            const formData = new FormData();
            formData.append('file', this.closeForm.value.media[0]);
            this.mediaService.uploadSingleFile(formData).subscribe({
              next: (response) => {
                const data = {
                  actionId: this.actionId,
                  actionMedia: response?.mediaPath,
                  actionMediaName: this.closeForm.value.media[0].name,
                  remarks: this.closeForm.value.remarks,
                  updatedBy: this.loggedUser.userId,
                };
                this.trackerService.closeAction(data).subscribe({
                  next: (res) => {
                    this.feedBackService.showFeedback(
                      new FeedbackModel(FeedbackType.Success, res?.message)
                    );
                    this.closeDialog();
                    this.getData();
                    this.loadingService.stopLoading();
                  },
                  error: ({ error }) => {
                    this.feedBackService.showFeedback(
                      new FeedbackModel(FeedbackType.Failure, error?.message)
                    );
                    this.loadingService.stopLoading();
                  },
                });
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.loadingService.stopLoading();
              },
            });
          } else {
            const data = {
              actionId: this.actionId,
              actionMedia: '',
              remarks: this.closeForm.value.remarks,
              updatedBy: this.loggedUser.userId,
            };
            this.trackerService.closeAction(data).subscribe({
              next: (res) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.closeDialog();
                this.getData();
                this.loadingService.stopLoading();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.loadingService.stopLoading();
              },
            });
          }
        }
      });
  }

  viewAction(action) {
    const data = {
      title: action?.title,
      assignToName: action?.assignToName,
      closedMediaName: action?.closedMediaName,
      closedMedia: action?.actionMedia,
      remarks: action?.remarks,
      actionStatus: action?.actionStatus,
      priority: action?.priority,
      closedDate: action?.updatedOn,
      targetDate: action?.targetDate,
    };
    this.dialog.open(ViewActionComponent, {
      height: 'auto',
      width: '40%',
      data: {
        actionData: data,
      },
    });
  }

  navigateToSource(sourceId: number, sourceName: string): void {
    if (sourceName === ReportingType.Hazard) {
      this.router.navigate(
        [
          SharedConstants.REPORTING_MANAGEMENT +
            '/' +
            SharedConstants.REPORT_HAZARD +
            '/' +
            SharedConstants.REPORT_HAZARD_VIEW +
            '/' +
            sourceId,
        ],
        {
          queryParams: { ...this.currentQueryParams, editMode: false },
        }
      );
    }
    if (sourceName === ReportingType.NearMiss) {
      this.router.navigate(
        [
          SharedConstants.REPORTING_MANAGEMENT +
            '/' +
            SharedConstants.REPORT_NEAR_MISS +
            '/' +
            SharedConstants.REPORT_NEAR_MISS_VIEW +
            '/' +
            sourceId,
        ],
        {
          queryParams: { ...this.currentQueryParams, editMode: false },
        }
      );
    }
    if (sourceName === ReportingType.Incident) {
      this.router.navigate(
        [
          SharedConstants.REPORTING_MANAGEMENT +
            '/' +
            SharedConstants.REPORT_INCIDENT +
            '/' +
            SharedConstants.REPORT_INCIDENT_VIEW +
            '/' +
            sourceId,
        ],
        {
          queryParams: { ...this.currentQueryParams, editMode: false },
        }
      );
    }
    if (sourceName === ReportingType.workPermit) {
      this.router.navigate(
        [
          SharedConstants.WORK_PERMIT +
            '/' +
            SharedConstants.WORK_PERMIT_LIST +
            '/' +
            SharedConstants.VIEW_EDIT_WORK_PERMIT +
            '/' +
            sourceId,
        ],
        {
          queryParams: { ...this.currentQueryParams, editMode: false },
        }
      );
    }
    if (sourceName === ReportingType.Task) {
      this.router.navigate(
        [
          '/' +
            SharedConstants.TASK_TRACKER +
            '/' +
            SharedConstants.TASK_TRACKER_List +
            '/' +
            SharedConstants.View_TASK_TRACKER +
            '/' +
            sourceId,
        ],
        {
          queryParams: this.currentQueryParams,
        }
      );
    }
    if (sourceName === ReportingType.Voilation) {
      this.router.navigate(
        [
          '/' +
            SharedConstants.SMART_MONITORING +
            '/' +
            SharedConstants.VOILATIONS +
            '/' +
            SharedConstants.VOILATIONS_VIEW +
            '/' +
            sourceId,
        ],
        {
          queryParams: this.currentQueryParams,
        }
      );
    }
    if (sourceName === 'INVESTIGATION-NEAR_MISS') {
      this.router.navigate(
        [
          SharedConstants.REPORTING_MANAGEMENT +
            '/' +
            SharedConstants.REPORT_NEAR_MISS +
            '/' +
            SharedConstants.REPORT_NEAR_MISS_VIEW +
            '/' +
            sourceId,
        ],
        {
          queryParams: { ...this.currentQueryParams, editMode: false },
        }
      );
    }
    if (sourceName === 'INVESTIGATION-INCIDENT') {
      this.router.navigate(
        [
          SharedConstants.REPORTING_MANAGEMENT +
            '/' +
            SharedConstants.REPORT_INCIDENT +
            '/' +
            SharedConstants.REPORT_INCIDENT_VIEW +
            '/' +
            sourceId,
        ],
        {
          queryParams: { ...this.currentQueryParams, editMode: false },
        }
      );
    }
    if (sourceName === 'PENALTY_MANAGEMENT') {
      this.router.navigate(
        [
          SharedConstants.PENALTY_MANAGEMENT +
            '/' +
            SharedConstants.PENALTY_MANAGEMENT_VIEW +
            '/' +
            sourceId,
        ],
        {
          queryParams: { ...this.currentQueryParams, editMode: false },
        }
      );
    }
    if (sourceName === ReportingType.InspectionManagement) {
      this.router.navigate(
        [
          SharedConstants.INSPECTION_MANAGEMENT +
            '/' +
            SharedConstants.INSPECTION_MANAGEMENT_REVIEW +
            '/' +
            sourceId,
        ],
        {
          queryParams: { ...this.currentQueryParams },
        }
      );
    }
  }
}
