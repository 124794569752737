import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { SortDirection } from 'src/@hodhod/common/enum';
import { BaseApi } from '../api/base-api';
import { DataMapper } from '../models/voilations/voilation.model';

@Injectable({
  providedIn: 'root'
})
export class BulkUserUploadService {

  constructor(private http: HttpClient, private baseApi: BaseApi) { }

  getbulkUsersByCompany(data: any): Observable<DataMapper<any>> {

    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }

    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<any>>(this.baseApi.encodeURL(Constants.API_BULK_USERS_GET_ALL + '?' + params));
  }

  insertUserInBulk(data: any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_INSERT_USER_IN_BULK), data);
  }

  deleteBulkUser(bulkId: number): Observable<any> {
    return this.http.get<any>(this.baseApi.encodeURL(Constants.API_DELETE_BULK_USER + '/' + bulkId));
  }

  registerBulkUser(data: any): Observable<any> {
    return this.http.post<any>(this.baseApi.encodeURL(Constants.API_REGISTER_BULK_USER), data);
  }
}
