import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { SafetyKpisService } from 'src/backend/services/dashboards/safety-kpis.service';

@Component({
  selector: 'app-safety-kpi-setup',
  templateUrl: './safety-kpi-setup.component.html',
  styleUrls: ['./safety-kpi-setup.component.scss'],
})
export class SafetyKpiSetupComponent extends BaseComponent implements OnInit {
  public safetyKpiForm: FormGroup;
  public isAuto: boolean;
  public id: number;
  public type: string;
  public translationsList: any;
  public safetyData: any;
  public manualValue: any;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private safetyKpisService: SafetyKpisService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private feedBackService: AsyncFeedbackService,
    private router: Router
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.safetyKpiForm = this.fb.group({
      id: [''],
      mode: [''],
      manualValue: [0],
      rateType: [''],
    });
    this.activeRoute.paramMap.subscribe((params) => {
      this.id = Number(params.get('id'));
      this.type = params.get('type');
      this.getData();
    });
  }

  getData() {
    const data = {
      id: this.id,
      rateType: this.type,
    };
    this.sectionState = SectionStateStatus.Loading;

    this.safetyKpisService.getSafetyKpiSetupById(data).subscribe({
      next: (res) => {
        this.safetyData = res;
        this.initForm();
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  initForm() {
    this.manualValue = this.safetyData.manualValue;
    this.isAuto = this.safetyData.mode === 'AUTO' ? true : false;
    if (this.isAuto) {
      this.safetyKpiForm.get('manualValue').disable();
    } else {
      this.safetyKpiForm.get('manualValue').setValidators(Validators.required);
    }
    this.safetyKpiForm.get('id').setValue(this.safetyData.id);
    this.safetyKpiForm
      .get('mode')
      .setValue(this.safetyData.mode === 'AUTO' ? true : false);
    this.safetyKpiForm.get('manualValue').setValue(this.safetyData.manualValue);
    this.safetyKpiForm.get('rateType').setValue(this.type);
  }

  autoOrManual(value) {
    if (value.checked) {
      this.safetyKpiForm.get('manualValue').disable();
      this.safetyKpiForm
        .get('manualValue')
        .removeValidators(Validators.required);
    } else {
      this.safetyKpiForm.get('manualValue').enable();
      this.safetyKpiForm.get('manualValue').setValidators(Validators.required);
    }
    this.safetyKpiForm.get('mode').setValue(value.checked ? 'AUTO' : 'MANUAL');
    this.isAuto = value.checked;
  }

  submitForm() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        this.sectionState = SectionStateStatus.Loading;
        if (value === true) {
          const data = {
            id: this.id,
            mode: this.isAuto ? 'AUTO' : 'MANUAL',
            manualValue: this.safetyKpiForm.get('manualValue').value,
            rateType: this.type,
          };
          this.safetyKpisService.updateSafetyKpiSetup(data).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.navigateBack();
              this.feedBackService?.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
            },
            error: (error) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService?.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
  }

  navigateBack() {
    this.router.navigate([
      '/' +
        SharedConstants.DASHBOARD_PATH +
        '/' +
        SharedConstants.DASHBOARD_TARGET,
    ]);
  }
}
