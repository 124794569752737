import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';


@Component({
  selector: 'app-site-access-Information',
  templateUrl: './site-access-Information.component.html',
  styleUrls: ['./site-access-Information.component.scss']
})
export class SiteAccessInformationComponent implements OnInit {

  userForm: FormGroup;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  selectedFiles: any;
  constructor() { }

  ngOnInit() {
  }
 

}
