import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HistoryTypes } from 'src/@hodhod/common/enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { Confirmation, ConfirmationType } from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { GetAllHazards } from 'src/backend/models/hazards/hazards.model';
import { HazardsService } from 'src/backend/services/hazards/hazards.service';

@Component({
  selector: 'app-add-hazards',
  templateUrl: './add-hazards.component.html',
  styleUrls: ['./add-hazards.component.scss']
})
export class AddHazardsComponent implements OnInit {
  hazardForm: FormGroup;
  formMode: any;
  private destroy$ = new Subject();
  public translationsList: any = {};
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  status: boolean;
  HistoryType = HistoryTypes.HAZARD;
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AddHazardsComponent>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private hazardService: HazardsService,
    private feedBackService: AsyncFeedbackService
  ) {
    this.translate.get(['Errors', 'Success', 'Role', 'User', 'General']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
      this.translationsList = translations;
    });
  }

  ngOnInit() {
    if (this.defaults.action === 'update') {
      this.formMode = 'update';
    } else if (this.defaults.action === 'view') {
      this.formMode = 'view';
    } else if (this.defaults.action === 'create') {
      this.formMode = 'create'
      this.defaults.hazard = {} as GetAllHazards;
    }
    let status: any;
    if (this.defaults) {
      if (this.defaults.hazard.status === 'ACTIVE') {
        status = 'active';
      } else {
        status = 'inactive';
      }
    }

    this.hazardForm = this.fb.group({
      hazard: [this.defaults.hazard.name || '',
      [Validators.required, noWhitespaceValidator]],
      status: [status || '', [Validators.required]]
    });
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createHazard();
          }
        });
    } else if (this.formMode === 'update') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['Role']['UpdateConfirm']['Title'],
            this.translationsList['Role']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.updateHazard();
          }
        });
    }
  }

  createHazard() {
    const { hazard, status } = this.hazardForm.value;

    const data = {
      name: hazard,
      status: status === 'active' ? true : false
    };
    this.hazardService.postHazards(data).subscribe({
      next: () => {
        this.sectionState = SectionStateStatus.Loading;
        this.dialogRef.close(data);
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, this.translationsList['Success']['SuccessMessage'])
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
      }
    });
  }

  updateHazard() {
    const { hazard, status } = this.hazardForm.value;
    const data = {
      id: this.defaults.hazard.id,
      name: hazard,
      status: status === 'active' ? true : false
    };
    this.hazardService.UpdateHazards(data).subscribe({
      next: () => {
        this.sectionState = SectionStateStatus.Loading;
        this.dialogRef.close(data);
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, this.translationsList['General']['Updated'])
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
      }
    });

  }
  statusChange(event: any): void {
    if (event.value === 'active') {
      this.status = true
    } else {
      this.status = false
    }
  }
  isCreateMode() {
    return this.formMode === 'create';
  }

  isUpdateMode() {
    return this.formMode === 'update';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }


}
