import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { RoleService } from 'src/backend/services/role.service';

@Component({
  selector: 'app-view-role',
  templateUrl: './view-role.component.html',
  styleUrls: ['./view-role.component.scss'],
  animations: [fadeInUp400ms],
})
export class ViewRoleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() roleName: string = '';
  @Input() isExternal: boolean = false;
  @Input() roleId: string = '';
  @Output() onChangeName: EventEmitter<string> = new EventEmitter();
  @Output() onChangeExternal: EventEmitter<string> = new EventEmitter();
  roleForm: FormGroup;
  editMode: boolean = false;
  private destroy$ = new Subject();
  private translationsList: any = {};

  hasUpdatePermission = ApplicationPermission.ROLE_UPDATE;
  constructor(private fb: FormBuilder, private translate: TranslateService) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.roleForm = this.fb.group({
      roleName: ['', Validators.required],
      isExternal: [false],
    });
    if (!this.editMode) {
      this.roleForm.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['roleName']) {
      if (this.roleForm) {
        this.roleForm.get('roleName')?.setValue(this.roleName);
        this.roleForm.get('isExternal')?.setValue(this.isExternal);
      }
    }
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  onChangeRoleName(event) {
    this.onChangeName.emit(event.target.value);
  }

  onChangeIsExternal(event) {
    this.onChangeExternal.emit(event.checked);
  }

  changeMode(): void {
    this.editMode = !this.editMode;
    if (!this.editMode) {
      this.roleForm.disable();
    } else {
      this.roleForm.enable();
    }
  }
}
