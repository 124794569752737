<div class="flex column justify-center align-center" #captureElement>
  <div class="flex justify-between align-center w-full">
    <img
      [src]="companyLogo"
      style="height: 90px; width: 90px; object-fit: contain"
    />
    <img
      src="assets/img/resources/Sanedlogo.png"
      style="height: 90px; width: 90px; object-fit: contain"
    />
  </div>
  <div
    class="flex column justify-flex-start w-full"
    *ngIf="data?.type === 'msds'"
  >
    <span class="msds-title">
      {{ "MSDS.Form.MaterialCode" | translate }} : {{ data.Code }}
    </span>
    <span class="msds-title">
      {{ "MSDS.Form.MaterialName" | translate }} : {{ data.Title }}
    </span>
  </div>
  <div
    class="flex column justify-flex-start w-full"
    *ngIf="data?.type === 'equipment'"
  >
    <span class="msds-title">
      {{ "Placeholder.EquipmentCode" | translate }} : {{ data.Code }}
    </span>
    <span class="msds-title">
      {{ "Placeholder.EquipmentName" | translate }} : {{ data.Title }}
    </span>
  </div>
  <div>
    <qrcode
      [qrdata]="data?.Url"
      [width]="290"
      [errorCorrectionLevel]="'M'"
    ></qrcode>
  </div>
  <button
    mat-button
    *ngIf="!print"
    class="bg-primary text-[#fff] relative top-0"
    (click)="captureAndDownload()"
  >
    download
  </button>
</div>
