import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportingTypes, Severity } from 'src/@hodhod/common/enum';
import { Shift } from 'src/app/company-setup/models/shift';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { IncidentCategoryService } from 'src/backend/services/incident-category/incident-category.service';
import { ReasonsService } from 'src/backend/services/reasons/reasons.service';
import { NearMissReportService } from 'src/backend/services/reportings/near-miss-report.service';
import { RootCauseService } from 'src/backend/services/root-cause/root-cause.service';
import { ShiftsService } from 'src/backend/services/shifts/shifts.service';
import { UserService } from 'src/backend/services/user.service';
import { NearMissReport } from '../../models/near-miss-report';
import { NearMissImage } from '../../models/near-miss-image';
@Component({
  selector: 'app-near-miss-report-form',
  templateUrl: './near-miss-report-form.component.html',
  styleUrls: ['./near-miss-report-form.component.scss'],
})
export class NearMissReportFormComponent implements OnInit, OnChanges {
  @Input() selectedNearMiss: NearMissReport | null = null;
  @Output() formSubmit = new EventEmitter<any>();

  shifts: Shift[];
  Severity = Severity;
  nearMissForm: FormGroup;
  private destroy$ = new Subject();
  public translationsList: any = {};

  departments: any[] = [];
  subDepartments: any[] = [];
  rootCauses: any[] = [];
  reasons: any[] = [];
  nearMissTypes: any[] = [];
  reportType = ReportingTypes.NearMiss;
  users: any;
  attachments: NearMissImage[];
  selectedImg: any;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;

  hasCreateReasonsPermission =
    ApplicationPermission.CONTENT_REPORTING_REASONS_CREATE;
  hasCreateShiftsPermission = ApplicationPermission.SHIFT_CREATE;
  hasCreateDepartmentPermission = ApplicationPermission.DEPARTMENTS_CREATE;
  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  hasRootCauseCreatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_CREATE;
  hasRootCauseUpdatePermission =
    ApplicationPermission.CONTENT_REPORTING_ROOTCAUSE_UPDATE;

  hasCreateTypeOfIncidentsPermission =
    ApplicationPermission.CONTENT_REPORTING_INCIDENTCATEGORY_CREATE;

  constructor(
    private fb: FormBuilder,
    private feedBackService: AsyncFeedbackService,
    private translate: TranslateService,
    private router: Router,
    private departmentService: DepartmentsService,
    private reasonService: ReasonsService,
    private confirmationService: ConfirmationService,
    private nearMissReportService: NearMissReportService,
    private shiftService: ShiftsService,
    private userService: UserService,
    private rootService: RootCauseService,
    private incidentCategoryService: IncidentCategoryService
  ) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedNearMiss'].currentValue) {
      this.populateForm();
    }
  }

  ngOnInit() {
    this.initializeForm();
    this.loadDDLsData();
  }

  initializeForm() {
    this.nearMissForm = this.fb.group({
      title: ['', [Validators.required, noWhitespaceValidator]],
      rootCause: [''],
      shift: ['', [Validators.required]],
      severity: ['', [Validators.required]],
      department: ['', [Validators.required]],
      subDepartment: [''],
      cause: ['', [Validators.required]],
      description: ['', [Validators.required, noWhitespaceValidator]],
      typeOfReportIds: ['', [Validators.required]],
      witness: [],
      attachments: [[]],
    });
  }

  loadDDLsData() {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    forkJoin({
      departments: this.departmentService.getDepartmentsParentChild(),
      shifts: this.shiftService.getActiveShifts(),
      nearMissTypes: this.incidentCategoryService.getReportCategoriesByCompany(
        this.reportType
      ),
      rootCauses: this.rootService.getRootCauseByCompany(this.reportType),
      reasons: this.reasonService.getReasonsByCompany(this.reportType),
      users: this.userService.getReportToUsers(),
    }).subscribe((results) => {
      this.departments = results.departments;
      if (
        this.departments.length == 1 &&
        this.departments[0].parentName == '---Parent Department---' &&
        this.departments[0].detail == null
      ) {
        this.departments = [];
      }
      this.shifts = results.shifts;
      this.nearMissTypes = results.nearMissTypes;
      this.rootCauses = results.rootCauses;
      this.reasons = results.reasons;
      this.users = results.users;
      this.sectionState = SectionStateStatus.Ready;
    });
  }

  populateForm() {
    this.nearMissForm.get('title')?.setValue(this.selectedNearMiss?.title);
    this.nearMissForm
      .get('rootCause')
      ?.setValue(this.selectedNearMiss?.['rootCauseId']);
    this.nearMissForm.get('shift')?.setValue(this.selectedNearMiss?.shift);
    this.nearMissForm
      .get('severity')
      ?.setValue(this.selectedNearMiss?.saverity);
    this.nearMissForm.get('cause')?.setValue(this.selectedNearMiss?.causeId);
    this.nearMissForm
      .get('description')
      ?.setValue(this.selectedNearMiss?.description);
    this.nearMissForm
      .get('witness')
      ?.setValue(this.selectedNearMiss?.witnesses.map((item) => item.id));
    this.nearMissForm
      .get('typeOfReportIds')
      .setValue(
        this.selectedNearMiss?.typesOfReport.map((item) => item.typeId)
      );

    this.attachments = this.selectedNearMiss?.images;

    if (this.selectedNearMiss?.departmentParentId) {
      this.nearMissForm
        .get('department')
        ?.setValue(this.selectedNearMiss?.departmentParentId);
      this.onChangeDepartment({
        value: this.selectedNearMiss?.departmentParentId,
      });
      this.nearMissForm
        .get('subDepartment')
        ?.setValue(this.selectedNearMiss?.departmentId);
    } else {
      this.onChangeDepartment({ value: this.selectedNearMiss?.departmentId });
      this.nearMissForm
        .get('department')
        ?.setValue(this.selectedNearMiss?.departmentId);
    }
  }

  onSubmit() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.formSubmit.emit(this.nearMissForm.value);
        }
      });
  }

  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase();
    if (extension.includes('xlsx') || extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png';
    } else if (extension.includes('docx') || extension.includes('doc')) {
      return '../../../../assets/img/resources/doc.png';
    } else if (extension.includes('pdf')) {
      return '../../../../assets/img/resources/pdf.png';
    } else {
      return url;
    }
  }

  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  openBigView(id: number): void {
    const img = this.selectedNearMiss?.images.find((x) => x.id === id);
    const downloadTag = document.createElement('a');
    downloadTag.href = img.imageUrl;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }

  deleteImage(img?: any): void {
    this.selectedImg = img;
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.nearMissReportService
            .deleteNearMissReportImage(this.selectedImg.id)
            .subscribe({
              next: (response) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, response?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
                this.attachments = this.attachments.filter(
                  (x) => x.id !== this.selectedImg.id
                );
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
        }
      });
  }

  cancelForm(): void {
    this.router.navigate([
      SharedConstants.REPORTING_MANAGEMENT +
        '/' +
        SharedConstants.REPORT_NEAR_MISS,
    ]);
  }

  onChangeDepartment(event) {
    this.nearMissForm.get('subDepartment').setValue('');
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.departmentService.getSubDepartmentsById(event.value).subscribe({
      next: (response) => {
        this.subDepartments = response;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
}
