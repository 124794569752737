import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { map, Observable } from 'rxjs';
import { ColorSchemeName } from 'src/@hodhod/config/colorSchemeName';
import { ConfigService } from 'src/@hodhod/config/config.service';
import { HodhodConfig } from 'src/@hodhod/config/hodhod-config.interface';

@Component({
  selector: 'app-expandable-panel',
  templateUrl: './expandable-panel.component.html',
  styleUrls: ['./expandable-panel.component.scss']
})
export class ExpandablePanelComponent implements OnInit {

  @Input() title = '';
  @Input() expanded = false;
  @Input() expandable = true;
  @Input() showTrailing = false;
  @Input() disabled = false;
  @Input() isSmallStyle = false;

  @Output() panelExpanded = new EventEmitter();

  @ViewChild('expansionPanel') expansionPanel: MatExpansionPanel;

  config$: Observable<HodhodConfig> = this.configService.config$;

  isDark$: Observable<boolean> = this.config$.pipe(
    map(config => config.style.colorScheme === ColorSchemeName.dark)
  );

  constructor(private configService: ConfigService,) { }

  ngOnInit(): void {
  }

  close() {
    this.expansionPanel.close();
  }

}
