import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SafetyKpisService } from 'src/backend/services/dashboards/safety-kpis.service';
import { IncidentClassificationService } from 'src/backend/services/incident-classification/incident-classification.service';

@Component({
  selector: 'app-auto-levels-form',
  templateUrl: './auto-levels-form.component.html',
  styleUrls: ['./auto-levels-form.component.scss'],
})
export class AutoLevelsFormComponent implements OnInit {
  @ViewChild('historicalConfirmation')
  historicalConfirmation!: TemplateRef<any>;
  @ViewChild('historicalValues') historicalValues!: TemplateRef<any>;
  levelsFormArray: FormArray;
  defaultLevels: any[] = null;
  levelsForm: FormGroup;
  safetyTriangleData: any;
  numOfLevels = 0;
  public incidentClassifications: any[] = [];
  arr: any[] = [];
  public translationsList: any = {};
  private destroy$ = new Subject();
  isFocused = false;
  data;
  selectedValues: any[] = [];
  default = [
    {
      number: 1,
      incidentClassificationId: null,
      incidentClassificationName: 'Total Hazards',
    },
    {
      number: 2,
      incidentClassificationId: null,
      incidentClassificationName: 'Total Nearmisses',
    },
    {
      number: 3,
      incidentClassificationId: null,
      incidentClassificationName: 'Total Incidents',
    },
  ];
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private incidentClassService: IncidentClassificationService,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private safetyKpisService: SafetyKpisService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  ngOnInit(): void {
    this.levelsForm = this.fb.group({
      levels: this.fb.array([]),
    });
    this.levelsFormArray = this.levelsForm.get('levels') as FormArray;
    this.numOfLevels = this.levelsFormArray.value.length;
    this.getIncidentClassifications();
    this.getAutoTriangle();
  }

  getAutoTriangle() {
    const ID = this.route.snapshot.params['id'];
    this.safetyKpisService.getSefetyTriangleById(ID).subscribe({
      next: (res) => {
        this.safetyTriangleData = res;
        if (this.safetyTriangleData?.mode === 'AUTO') {
          this.defaultLevels = this.safetyTriangleData['levels'];
          this.defaultLevels
            .map((levelData) => ({
              number: levelData?.number,
              incidentClassificationId: levelData?.incidentClassificationId,
              incidentClassificationName: levelData?.incidentClassificationName,
            }))
            .sort((a, b) => a.number - b.number)
            .forEach((levelData, i) => {
              this.levelsFormArray.push(this.createlevelForm(levelData));
              this.levelsFormArray
                .at(i)
                .get('incidentClassificationId')
                .setValue(levelData.incidentClassificationId);
            });
          this.getFormValues();
        } else {
          this.default.forEach((item, i) => {
            const levelFormGroup = this.createlevelForm(item);
            this.levelsFormArray?.push(levelFormGroup);
          });
          this.getFormValues();
        }
      },
    });
  }

  removeLevel(i: number) {
    if (i >= 0) {
      this.levelsFormArray.removeAt(i);
      this.numOfLevels = this.levelsFormArray.value.length;
      this.getFormValues();
    }
  }
  getIncidentClassifications(): void {
    this.incidentClassService.getIncidentClassificationByCompany().subscribe({
      next: (response) => {
        this.incidentClassifications = response;
      },
    });
  }
  checkClassification(id) {
    return this.levelsForm.value.levels.some(
      (item) => item['incidentClassificationId'] == id
    );
  }
  onAddLevel(): void {
    if (this.numOfLevels < 7) {
      this.levelsFormArray.push(this.createlevelForm());
      this.numOfLevels = this.levelsFormArray.value.length;
      this.getFormValues();
    }
  }
  openConfirmationPopup() {
    this.dialog.open(this.historicalConfirmation, {
      maxHeight: 'auto',
      width: 'auto',
      disableClose: false,
    });
  }
  openhistoricalPopup() {
    this.dialog.closeAll();
    this.dialog.open(this.historicalValues, {
      maxHeight: '95vh',
      width: 'auto',
      disableClose: false,
    });
  }
  createlevelForm(levelData?): FormGroup {
    return this.fb.group({
      number: [
        levelData ? levelData.number : this.levelsFormArray.value.length + 1,
      ],
      incidentClassificationId: [
        levelData && levelData.number >= 3
          ? levelData.incidentClassificationId
          : null,
        this.levelsFormArray
          ? this.levelsFormArray.length >= 3
            ? [Validators.required]
            : null
          : null,
      ],
      incidentClassificationName: [
        levelData ? levelData?.incidentClassificationName : null,
      ],
      historicalValue: [null],
    });
  }

  getFormValues() {
    this.arr = [];
    let rows = this.levelsFormArray.value;
    rows.forEach((row, i) => {
      this.arr.push({
        color: i + 1 == rows.length ? '#FF0000' : null,
        name: row['incidentClassificationName']
          ? row['incidentClassificationName']
          : '',
        y: 1,
      });
    });
    this.data = this.arr;
  }

  get repeatlevelsFormGroup(): any {
    return this.levelsForm.get('levels') as FormArray;
  }
  onSelectClassification(event, i): void {
    let val = event.value;
    this.selectedValues.push(val);
    let icName = this.incidentClassifications.find((ic) => ic.id === val);
    this.levelsFormArray.controls[i]
      .get('incidentClassificationName')
      .setValue(icName?.name);
    this.getFormValues();
  }
  updateSafetyTriangle(id) {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const levels = this.levelsForm.value['levels'].map((item, index) => {
            return {
              historicalValue: item.historicalValue,
              incidentClassificationId: item.incidentClassificationId,
              incidentClassificationName: item.incidentClassificationName,
              number: index + 1,
            };
          });
          let data = {
            Id: id,
            Mode: 'AUTO',
            Levels: levels,
          };

          this.loadingService.startLoading(true, '');
          this.safetyKpisService.updateSafetyTriabgle(data).subscribe({
            next: (res) => {
              this.loadingService.stopLoading();
              this.dialog.closeAll();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.navigateBack();
            },
            error: (res) => {
              this.dialog.closeAll();
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, res?.message)
              );
            },
          });
        }
      });
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }
  navigateBack() {
    this.router.navigate([
      '/' +
        SharedConstants.DASHBOARD_PATH +
        '/' +
        SharedConstants.DASHBOARD_TARGET,
    ]);
  }
}
