import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { fadeInRight400ms } from 'src/@hodhod/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@hodhod/animations/scale-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { OffenderNature, ReportingType } from 'src/@hodhod/common/enum';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { UserService } from 'src/backend/services/user.service';
import { VoilationsService } from 'src/backend/services/voilations/voilations.service';
import { VoilationInfo } from '../../models/voilation';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-view-voilation',
  templateUrl: './view-voilation.component.html',
  styleUrls: ['./view-voilation.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms,
    scaleFadeIn400ms,
    fadeInRight400ms,
    scaleIn400ms,
  ],
})
export class ViewVoilationComponent extends BaseComponent implements OnInit {
  @Input() voilation!: VoilationInfo;
  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  public data: VoilationInfo;
  offenderNatures = OffenderNature;
  selectedImg: any;
  sessionUser: any;
  voilationForm: FormGroup;
  ReportingType = ReportingType;
  users: any[] = [];
  closerDetail: any;
  x: number;
  y: number;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  private translationsList: any = {};
  enable: boolean = false;
  trimedDepartments: any;
  top = 0;
  left = 0;
  scale = 1;
  hasEditPermissions = ApplicationPermission.VIOLATIONS_UPDATE;
  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  hasSpecialPermission = ApplicationPermission.VIOLATIONS_APPROVE;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private feedBackService: AsyncFeedbackService,
    private voilationService: VoilationsService,
    private userService: UserService,
    private baseApi: BaseApi,
    private loadingService: LoadingService,
    private translate: TranslateService
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'User',
        'deleteVoilation',
        'StaticPages',
        'General',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.voilationForm = this.fb.group({
      offenderNature: ['', Validators.required],
      offenderName: ['', Validators.required],
      isGuestOffender: [false],
      guestOffenderName: ['', Validators.required],
    });
    this.voilationForm.disable()
    if(this.hasSpecialPermission){
      this.voilationForm.enable()
    }
    this.getSessionOrganization();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['voilation']) {
      this.data = this.voilation;
      this.setFormValues(this.data);
      this.getCloserDetail();
    }
  }
  moveImage(e: MouseEvent) {
    const element = e.target as HTMLElement;
    let zoomedX = 0;
    let zoomedY = 0;
    if (this.scale === 1) {
      this.scale = 3;
      zoomedX = e.clientX - 180;
      zoomedY = e.clientY - 100;
      element.style.transformOrigin = `${zoomedX}px ${zoomedY}px`;
      element.style.transform = `scale(${this.scale})`;
    } else {
      this.scale = 1;
      zoomedX = 0;
      zoomedY = 0;
      element.style.transform = `scale(${this.scale})`;
    }
  }

  openBigView(): void {
    if (this.data?.picture) {
      this.selectedImg = this.data?.picture;
      this.dialog.open(this.imageBigView, { height: '90vh', width: '80%' });
    }
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  getSessionOrganization(): void {
    this.sessionUser = this.baseApi.getUserSession();
    this.getUsers();
  }
  downloadDocument(url: any): void {
    const downloadTag = document.createElement('a');
    downloadTag.href = url;
    downloadTag.addEventListener('click', () => {
      downloadTag.download;
    });
    downloadTag.click();
  }
  getUsers(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.users = response;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  getCloserDetail(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    if (this.data?.closerId) {
      this.userService.getUserDetails(this.data?.closerId).subscribe({
        next: (response) => {
          this.closerDetail = response;
          this.trimedDepartments =
            this.closerDetail?.departmentName?.substring(0, 10) + ' ...';
          this.sectionState = SectionStateStatus.Ready;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
    }
  }

  setFormValues(info: VoilationInfo): void {
    if (info) {
      const { offenderName, offenderNature, isGuestOffender } = info;
      this.voilationForm.get('offenderNature')?.setValue(offenderNature);
      this.voilationForm.get('isGuestOffender')?.setValue(isGuestOffender);
      if (isGuestOffender) {
        this.voilationForm.get('guestOffenderName')?.setValue(offenderName);
        this.voilationForm.get('offenderName')?.setValue('');
      } else {
        this.voilationForm.get('offenderName')?.setValue(offenderName);
        this.voilationForm.get('guestOffenderName')?.setValue('');
      }
    }
  }

  submitVoilationForm(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const { offenderNature, offenderName, guestOffenderName } =
            this.voilationForm.value;
          if (offenderNature || offenderName || guestOffenderName) {
            const obj = {
              userId: this.sessionUser?.userId,
              voilationId: this.voilation?.id,
              ...this.voilationForm.value,
            };
            this.loadingService.startLoading(true, '');
            this.voilationService.updateVoilation(obj).subscribe({
              next: () => {
                this.loadingService.stopLoading();
                this.voilations(this.data?.cameraId);
                this.feedBackService.showFeedback(
                  new FeedbackModel(
                    FeedbackType.Success,
                    this.translationsList['Success']['UpdateMessage']
                  )
                );
              },
              error: (error) => {
                this.loadingService.stopLoading();
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.error.message)
                );
              },
            });
          }
        }
      });
  }

  resetForm(): void {
    this.voilationForm.get('offenderNature')?.setValue('');
    this.voilationForm.get('isGuestOffender')?.setValue(false);
    this.voilationForm.get('guestOffenderName')?.setValue('');
    this.voilationForm.get('offenderName')?.setValue('');
  }

  voilations(id: number): void {
    this.router.navigate([
      '/' +
        SharedConstants.SMART_MONITORING +
        '/' +
        SharedConstants.VOILATIONS +
        '/' +
        id,
    ]);
  }
  addValidators(): void {
    const { offenderNature, offenderName, isGuestOffender, guestOffenderName } =
      this.voilationForm.value;
    if (offenderNature !== '') {
      this.voilationForm
        .get('offenderNature')
        .setValidators(Validators.required);
      this.voilationForm.get('offenderName').clearValidators();
      this.voilationForm.get('guestOffenderName').clearValidators();
    }
    if (offenderName !== '') {
      this.voilationForm.get('offenderName').setValidators(Validators.required);
      this.voilationForm.get('offenderNature').clearValidators();
      this.voilationForm.get('guestOffenderName').clearValidators();
    }
    if (isGuestOffender === true) {
      this.voilationForm
        .get('guestOffenderName')
        .setValidators(Validators.required);
      this.voilationForm.get('offenderNature').clearValidators();
      this.voilationForm.get('offenderName').clearValidators();
    }
    if (isGuestOffender === false) {
      this.voilationForm.get('guestOffenderName')?.setValue('');
    }
    this.voilationForm.get('offenderNature').updateValueAndValidity();
    this.voilationForm.get('offenderName').updateValueAndValidity();
    this.voilationForm.get('guestOffenderName').updateValueAndValidity();
  }
}
