import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { ChangeDetectorRef } from '@angular/core';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-dashboard-calender',
  templateUrl: './dashboard-calender.component.html',
  styleUrls: ['./dashboard-calender.component.scss'],
})
export class DashboardCalenderComponent implements OnInit {
  public showRangeDialog: boolean = false;
  public isValidRange: boolean = true;
  sDate: any;
  eDate: any;
  dashboardName: any;

  searchForm = new FormGroup({
    start: new FormControl(
      new Date(new Date().setDate(new Date().getDate() - 7))
    ),
    end: new FormControl(new Date()),
    menu: new FormControl(),
  });

  constructor(
    private dialog: MatDialogRef<DashboardCalenderComponent>,
    public dialogRef: MatDialogRef<DashboardCalenderComponent>,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: { fromDate: Date; toDate: Date; dashboardName: string }
  ) {
    this.sDate = this.data.fromDate;
    this.eDate = this.data.toDate;
    this.dashboardName = this.data.dashboardName;
  }

  ngOnInit() {
  }

  onChangeStartDate() {
    this.CheckingValidation();
  }

  CheckingValidation() {
    this.sDate = this.data.fromDate;
    this.eDate = this.data.toDate;
    if (this.data.fromDate > this.data.toDate) {
      this.isValidRange = false;
      this.snackBar.open('Start date must be before the End date', 'Close', {
        duration: 3000,
        panelClass: ['bg-orange-500', 'text-white'],
      });
      return;
    } else {
      this.isValidRange = true;
    }
  }

  onChangeEndDate() {
    this.CheckingValidation();
  }

  closeDialog() {
    this.dialog.close({ event: 'cancel' });
  }

  readonly date = new FormControl(moment());

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value ?? moment();
    const newMonthAndYear = moment(normalizedMonthAndYear); // Convert to Moment object
    ctrlValue.month(newMonthAndYear.month());
    ctrlValue.year(newMonthAndYear.year());
    ctrlValue.startOf('month'); // Set to the first day of the month
    const newDate = ctrlValue.toDate(); // Convert Moment to Date
    this.searchForm.get('start')?.setValue(newDate); // Update the FormControl value
    this.data.fromDate = newDate; // Update the data object
    this.cdr.detectChanges(); // Trigger Angular change detection
    datepicker.close();
  }
  setMonthAndYearEnd(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value ?? moment();
    const newMonthAndYear = moment(normalizedMonthAndYear);
    ctrlValue.month(newMonthAndYear.month());
    ctrlValue.year(newMonthAndYear.year());
    ctrlValue.endOf('month'); 
    const newDate = ctrlValue.toDate(); 
    this.searchForm.get('end')?.setValue(newDate); 
    this.data.toDate = newDate;
    this.cdr.detectChanges(); 
    datepicker.close();
  }
}
