<app-state-section [flexibleHeight]="true" [state]="sectionState" [loadingLabel]="'User.LoadingUserInfo' | translate"
  [errorLabel]="'Errors.ErrorHappened' | translate">
  <div class="flex justify-between flex-wrap-reverse">
    <form [formGroup]="userForm" class="flex-1 pr-5">
      <div class="flex columns">
        <button mat-flat-button color="primary" type="button" class="w-22 bg-black">
          <a class="sample" href="../../../../assets//sheets/Users.xlsx" download>{{'BulkUpload.DownlodTemplate' |
            translate}}</a>
        </button>
      </div>
      <div class="flex column mt-2">
        <mat-label class="field-label">{{'BulkUpload.UploadUserFile' | translate}}</mat-label>
        <file-upload (change)="checkProfileExtension($event)" formControlName="attachment" [multiple]="false"
          [animation]="true" (drop)="checkProfileExtension($event)"></file-upload>
      </div>
      <div class="flex items-center justify-end">
        <app-basic-button [btnType]="'Cancel'" [btnText]="'Cancel'" (onClick)="cancel()"></app-basic-button>
        <app-basic-button [btnType]="'Add'" [isDisabled]="!userForm.valid" [btnText]="'Add'"
          (onClick)="saveExcel()"></app-basic-button>
      </div>
    </form>
  </div>
</app-state-section>