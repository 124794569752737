import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { ApplicationPermission } from '../../models/application-permission';

@Component({
  selector: 'app-contacts-card',
  templateUrl: './contacts-card.component.html',
  styleUrls: ['./contacts-card.component.scss'],
  animations: [fadeInUp400ms],
})
export class ContactsCardComponent implements OnInit ,OnChanges{
  @Input() contact: any;
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  hasEditPermissions = ApplicationPermission.SECURITY_MANAGE_CONTACTS_EDIT;
  hasDeletePermissions = ApplicationPermission.SECURITY_MANAGE_CONTACTS_DELETE;
  hasActivatePermissions =
    ApplicationPermission.SECURITY_MANAGE_CONTACTS_ACTIVATE;
  depts:string;
  constructor() {

  }
  ngOnChanges(changes: SimpleChanges): void {
    this.depts=changes['contact'].currentValue.userDepartment.map((item)=>item.departmentName).toString()
  }

  ngOnInit() {
    
  }
  onClickActivate(event: any, id: string): void {
    const key = {
      event: event,
      id: id,
    };
    this.onClick.emit(key);
  }
  onClickDeActivate(event: any, id: string): void {
    const key = {
      event: event,
      id: id,
    };
    this.onClick.emit(key);
  }

  onClickDelete(event: any, id: string): void {
    const key = {
      event: event,
      id: id,
    };
    this.onClick.emit(key);
  }
  onClickEdit(event: any, id: string, phoneNumber: string, name: string): void {
    const key = {
      event: event,
      id: id,
      phoneNumber: phoneNumber,
      name: name,
    };
    this.onClick.emit(key);
  }
  
}
