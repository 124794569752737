import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-penalty-management',
  templateUrl: './penalty-management.component.html',
  styleUrls: ['./penalty-management.component.scss']
})
export class PenaltyManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
