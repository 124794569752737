<mat-accordion class="expension_wrapper" hideToggle>
  <mat-expansion-panel #expansionPanel [disabled]="disabled" [expanded]="expanded" (opened)="expanded = true; panelExpanded.emit();" (closed)="expanded = false"
  [style]="isSmallStyle ? ((isDark$ | async) ? 'background-color: #242C3F !important; margin-bottom: 10px;' : 'background-color: var(--color-border-dark) !important; margin-bottom: 10px;') : ''">
    <mat-expansion-panel-header class="header disable_ripple flex justify-between" 
    [style]="isSmallStyle ? 'height: 60px !important;font-weight: 400;font-size: 18px !important;background-color: rgba(var(--color-primary), 1);' : ''">
      <mat-panel-title class="flex align-center"
      [style]="isSmallStyle ? 'color: #ffffff;' : ''" >{{ title }}</mat-panel-title>
      <div class="tag flex align-center">
        <ng-content select="[tag]"></ng-content>
      </div>
      <div *ngIf="showTrailing && !expanded" class="trailing flex">
        <ng-content select="[trailing]"></ng-content>
      </div>
      <div *ngIf="expanded" class="actions flex items-center" [class.hide]="!expanded">
        <ng-content select="[actions]"></ng-content>
      </div>
      <button mat-button [class]="isSmallStyle ? 'small_button' : 'button'">
        <mat-icon [svgIcon]="isSmallStyle ? (expanded ? 'mat:remove_circle_outline' : 'mat:add_circle_outline') : (!expanded ? 'mat:arrow_drop_down' : 'mat:arrow_drop_up')"></mat-icon>
      </button>
    </mat-expansion-panel-header>
    <div class="content" *ngIf="expanded">
      <ng-content select="[body]"></ng-content>
      <div class="actions-mobile" >
        <ng-content select="[actions-mobile]"></ng-content>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
