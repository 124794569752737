<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.Dashboard' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container space-y-4">
    <h1 class="welcome-name">{{ "General.Hi" | translate }}, {{ userName }}</h1>
    <h6 class="welcome-text">{{ "General.what'sGoingOn" | translate }}</h6>
    <mat-tab-group
      class="dashboard-tabs"
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab class="tab-btn" [label]="'Dashboards.SmartSafety' | translate">
        <app-smart-safety-dashboards></app-smart-safety-dashboards>
      </mat-tab>
      <mat-tab
        class="tab-btn"
        [label]="'Dashboards.FireFightingMonitoring' | translate"
      >
        <app-iot-dashboards
          class="overflow-hidden"
          [loadData]="loadIot"
        ></app-iot-dashboards>
      </mat-tab>
    </mat-tab-group>
  </hodhod-page-layout-content>
</hodhod-page-layout>
