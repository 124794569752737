<div class="card overflow-auto">
  <div class="bg-app-bar px-6 h-16 border-b sticky left-0 flex items-center">
    <h2
      class="title my-0 ltr:pr-4 rtl:pl-4 ltr:mr-4 rtl:ml-4 ltr:border-r rtl:border-l hidden sm:block flex-none"
    >
      <span></span>
    </h2>
    <div
      class="bg-foreground rounded-full border px-4 max-w-[300px] flex-auto flex items-center border border-gray-300"
    >
      <mat-icon class="icon-sm text-secondary" svgIcon="mat:search"></mat-icon>
      <input
        [formControl]="searchCtrl"
        class="px-4 py-2 border-0 outline-none w-full bg-transparent"
        [placeholder]="'General.Search'|translate"
        type="search"
      />
    </div>

    <span class="flex-1"></span>

    <button
      [matMenuTriggerFor]="columnFilterMenu"
      class="ml-4 flex-none"
      mat-icon-button
      [matTooltip]="'General.Buttons.FilterColumns' | translate"
      type="button"
    >
      <mat-icon svgIcon="mat:filter_list"></mat-icon>
    </button>

    <!-- <button (click)="addUserInRole()"
              class="ml-4 flex-none"
              color="primary"
              mat-mini-fab
              [matTooltip]="'General.Buttons.AddNew' | translate"
              type="button">
        <mat-icon svgIcon="mat:add"></mat-icon>
      </button> -->
  </div>

  <table @stagger [dataSource]="dataSource" class="w-full" mat-table matSort>
    <!-- Text Columns -->
    <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
      <ng-container
        *ngIf="column.type === 'text'"
        [matColumnDef]="column.property"
      >
        <th *matHeaderCellDef class="uppercase" mat-header-cell mat-sort-header>
          {{ column.label }}
        </th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
          {{ row[column.property] }}
          <mat-icon
            *ngIf="
              column.property === 'email' &&
              row?.emailVerification === 'Verified'
            "
            svgIcon="mat:verified"
            class="text-blue-700"
            style="height: 15px; width: 15px"
          ></mat-icon>
        </td>
      </ng-container>
    </ng-container>

    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th *matHeaderCellDef mat-header-cell></th>
      <td *matCellDef="let row" mat-cell>
        <div class="flex">
          <app-action-button
            [btnBackgroundClass]="'bg-red-100'"
            [btnTextClass]="'text-red'"
            [buttonToolTip]="'Remove'"
            [buttonIcon]="'delete'"
            (onClick)="removeUser($event, row?.userId)"
          ></app-action-button>
        </div>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
    <tr
      *matRowDef="let row; columns: visibleColumns"
      @fadeInUp
      class="hover:bg-hover trans-ease-out cursor-pointer hover:shadow-lg border-l-8 border-primary my-2 bg-primary/10"
      mat-row
      (click)="viewUser(row?.userId)"
    ></tr>
  </table>

  <div
    *ngIf="dataSource.filteredData.length === 0"
    @scaleFadeIn
    class="flex-auto flex flex-col items-center justify-center"
  >
    <img src="assets/img/resources/empty-state.svg" alt="not found" />
    <h2 class="headline m-0 text-center text-primary">
      {{ "General.EmptyTableList" | translate }}...
    </h2>
  </div>

  <mat-paginator
    [class.hidden]="dataSource.filteredData.length === 0"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    showFirstLastButtons
    class="sticky left-0"
  ></mat-paginator>
</div>

<mat-menu #columnFilterMenu="matMenu" xPosition="before" yPosition="below">
  <button
    (click)="toggleColumnVisibility(column, $event)"
    *ngFor="let column of columns"
    class="checkbox-item mat-menu-item"
  >
    <mat-checkbox
      (click)="$event.stopPropagation()"
      [(ngModel)]="column.visible"
      color="primary"
    >
      {{ column.label }}
    </mat-checkbox>
  </button>
</mat-menu>
