import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import funnel from 'highcharts/modules/funnel';
funnel(Highcharts);
Drilldown(Highcharts);

@Component({
  selector: 'app-safety-triangle',
  templateUrl: './safety-triangle.component.html',
  styleUrls: ['./safety-triangle.component.scss'],
})
export class SafetyTriangleComponent implements OnInit, OnChanges {
  @Input() chartData: any;
  public data;
  constructor() {}
  public chart: Chart;
  defultData: any[] = [
    { name: '', y: 1 },
    { name: '', y: 1 },
    { name: '', y: 1 },
  ];
  ngOnChanges(changes: SimpleChanges): void {
    this.data = changes['chartData']?.currentValue;
    if (this.data?.length > 0) {
      this.data[0].name = 'Total Hazards';
      this.data[1].name = 'Total Nearmisses';
      this.data[2].name = this.data[2].name
        ? this.data[2].name
        : 'Total incidents';
    }
    this.chart = new Chart({
      chart: {
        type: 'pyramid',
        inverted: true,
      },
      title: {
        text: '',
        x: -50,
      },
      credits: {
        enabled: false,
      },
      tooltip: this.data
        ? {
            headerFormat: '',
            pointFormat: '{point.name}',
            shared: true,
          }
        : null,
      colors: [
        '#5B924D',
        '#75D572',
        '#FFDD29',
        '#F2B230',
        '#FC8F32',
        '#E88595',
        '#D7354F',
      ],
      plotOptions: {
        series: {
          dataLabels: {
            inside: true,
            enabled: true,
            allowOverlap: true, // Allow labels to overlap
            crop: false, // Do not crop labels
            formatter: function () {
              const shortTitle = this.point.name; // Display first 10 characters
              const fullTitle = this.point.name;
              return `<span title="${fullTitle}"><b>${shortTitle}</b></span>`;
            },
            style: { fontSize: '12px', color: '#fff'},
  
          },
          center: ['50%', '50%'],
          width: '70%',
          borderRadius: {
            radius: '6',
            scope:'point'
          },
          borderWidth: 3,
          borderColor: '#fff',

        } as any,
      },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: false,
        align: 'center',
        layout: 'horizontal',
        verticalAlign: 'bottom',
        squareSymbol: false,
        borderRadius: 10,
        symbolRadius: 3,
        symbolHeight: 10,
        symbolWidth: 12,
        symbolPadding: 12,
        itemStyle: {
          width: 100,
          height: 100,
          position: 'absolute',
        },
      },
      series: [
        {
          type: 'pyramid',
          name: '',
          data: this.data || this.defultData,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    inside: false,
                  },
                  center: ['50%', '50%'],
                  width: '100%',
                },
              },
            } as any,
          },
        ],
      },
    });
  }
  ngOnInit(): void {
    this.chart?.ref$.subscribe((chartInstance) => {
      chartInstance.reflow();
    });
  }
}
