import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import {
  Analog,
  TargetCalculation,
  TargetDashboardType,
  UptoAtLeast,
} from 'src/@hodhod/common/enum';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { DashboardTargetService } from 'src/backend/services/dashboard-target/dashboard-target.service';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-edit-dashboard-target',
  templateUrl: './edit-dashboard-target.component.html',
  styleUrls: ['./edit-dashboard-target.component.scss'],
})
export class EditDashboardTargetComponent implements OnInit {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  targetForm: FormGroup;
  machines: any[] = [];
  machineId: number;
  parameters: any[] = [];
  showPerformance: boolean = false;
  showAnalog: boolean = false;
  showDigital: boolean = false;
  showSameTarget: boolean = false;
  showDifferentTarget: boolean = false;
  private destroy$ = new Subject();
  public translationsList: any = {};
  viewMode: boolean = false;
  dashboargTargetId: number;
  targets: any[] = [];

  // Enum
  TargetDashboardType = TargetDashboardType;
  uptoAtLeast = UptoAtLeast;
  ContentAnalog = Analog;
  TargetCalculation = TargetCalculation;

  hasUpdateDashboardTargetPermission =
    ApplicationPermission.DASHBOARD_TARGET_UPDATE;

  currentQueryParams: any = null;
  constructor(
    private fb: FormBuilder,
    private checklistService: ChecklistSectionService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private feedBackService: AsyncFeedbackService,
    private dashboardTargetService: DashboardTargetService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.translate
      .get(['Errors', 'Success', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    this.targetForm = this.fb.group({
      type: ['', [Validators.required]],
      target: ['', [Validators.required]],
      uptoAtleast: ['', [Validators.required]],
    });
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const id: string = params.get('id') as string;
        this.dashboargTargetId = parseInt(id, 10);
      }
      if (params.has('mode')) {
        const mode: string = params.get('mode') as string;
        if (mode === 'View') {
          this.viewMode = true;
        } else {
          this.viewMode = false;
        }
      }
    });
    this.currentQueryParams = this.route.snapshot.queryParams;
    this.getData();
  }
  getData(): void {
    this.sectionState = SectionStateStatus.Loading;
    this.dashboardTargetService
      .getDashboardTargetById(this.dashboargTargetId)
      .subscribe({
        next: (response) => {
          const data = response;
          this.targetForm.get('type').setValue(data.trendingType);
          this.targetForm.get('target').setValue(data.monthlyTarget);
          this.targetForm.get('uptoAtleast').setValue(data.uptoAtLeast);
          this.sectionState = SectionStateStatus.Ready;
        },
        error: ({ error }) => {
          this.feedBackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }

  updateDashboardTarget() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const data = {
            id: this.dashboargTargetId,
            trendingType: this.targetForm.value.type,
            target: this.targetForm.value.target,
            uptoAtLeast: this.targetForm.value.uptoAtleast,
          };
          this.loadingService.startLoading(true, '');
          this.dashboardTargetService.putDashboardTarget(data).subscribe({
            next: (response) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
              this.loadingService.stopLoading();
              this.router.navigate([
                '/' +
                  SharedConstants.DASHBOARD_PATH +
                  '/' +
                  SharedConstants.DASHBOARD_TARGET,
              ]);
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }
  cancelForm() {
    this.router.navigate(
      [
        '/' +
          SharedConstants.DASHBOARD_PATH +
          '/' +
          SharedConstants.DASHBOARD_TARGET,
      ],
      { queryParams: { ...this.currentQueryParams } }
    );
  }
}
