<hodhod-page-layout>
  <hodhod-secondary-toolbar
    [current]="'NavigationBar.ReportIncident' | translate"
  >
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content>
    <div body>
      <app-incident-report-view
        [incidentReport]="incidentReport"
        (onInvestigate)="onInvestigate($event)"
        (onAddLossCosts)="onAddLossCosts($event)"
      ></app-incident-report-view>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
