import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { merge, takeUntil, tap } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { DbTargetStatus, SortDirection } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { GetAllDasboardTarget } from 'src/backend/models/dashboard-target/dashboard-target.model';
import { DashboardTargetService } from 'src/backend/services/dashboard-target/dashboard-target.service';
import { SafetyKpisService } from 'src/backend/services/dashboards/safety-kpis.service';
import { PermissionService } from 'src/backend/services/permission.service';

@UntilDestroy()
@Component({
  selector: 'app-safety-kpis',
  templateUrl: './safety-kpis.component.html',
  styleUrls: ['./safety-kpis.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class SafetyKpisComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  constructor(
    private router: Router,
    private translate: TranslateService,
    private safetyKpisService: SafetyKpisService,
    private confirmationService: ConfirmationService,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private feedBackService: AsyncFeedbackService
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'User',
        'confirmDeactiveRecord',
        'StaticPages',
        'General',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  searchCtrl = new UntypedFormControl();
  loadingLabel: string = '';
  dataSource = new MatTableDataSource<GetAllDasboardTarget>([]);
  searchValue: string = '';
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  private translationsList: any = {};
  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  hasActivateSafetyKPIsPermission =
    ApplicationPermission.SAFETY_KPIS_MANAGEMENT_ACTIVATE;
  columns: TableColumn<any>[] = [
    {
      label: 'Type',
      property: 'type',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Mode',
      property: 'mode',
      type: 'text',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'UpdatedAt',
      property: 'updatedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    {
      label: 'CreatedAt',
      property: 'createdOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'isActive', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  override ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
    });
    this.getData();
  }

  getData() {
    let sortDirection = SortDirection.None;

    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: sortDirection,
      sortField,
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      searchValue: this.searchValue,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.safetyKpisService.getSefetyKpis(filteredParams).subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource(response);
        this.paginator.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  view(type: string, id?: string): void {
    if (type === 'safetyTriangle') {
      this.router.navigate([
        '/' +
          SharedConstants.DASHBOARD_PATH +
          '/' +
          SharedConstants.DASHBOARD_TARGET +
          '/' +
          SharedConstants.DEFINE_SAFETY_TRIANGLE+ '/' +
          id
      ]);
    } else if (type === 'safeManHours') {
      this.router.navigate([
        '/' +
          SharedConstants.DASHBOARD_PATH +
          '/' +
          SharedConstants.DASHBOARD_TARGET +
          '/' +
          SharedConstants.SAFEMAN_HOURS+ '/' +
          id ,
      ]);

      
    } else if (
      type === 'lostTimeInjuryFrequencyRate' ||
      type === 'totalRecordableInjuryFrequencyRate' ||
      type === 'totalRecordableIncidentRate'
    ) {

      this.router.navigate([
        '/' +
          SharedConstants.DASHBOARD_PATH +
          '/' +
          SharedConstants.DASHBOARD_TARGET +
          '/' +
          SharedConstants.SAFETY_KPI_SETUP +
          '/' +
          id +
          '/' +
          type,
      ]);
    }
  }
  deactivate(type: string, id: number): void {
    if (
      !this.permissionService.isUserHasPermission(
        this.hasActivateSafetyKPIsPermission
      )
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          if (type === 'safetyTriangle') {
            this.safetyKpisService.deActivateSafetyTriangle(id).subscribe({
              next: (res) => {
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.getData();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
          } else if (type === 'safeManHours') {
            this.safetyKpisService.deActivateSafemanHours(id).subscribe({
              next: (res) => {
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.getData();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
          } else if (
            type === 'lostTimeInjuryFrequencyRate' ||
            type === 'totalRecordableInjuryFrequencyRate' ||
            type === 'totalRecordableIncidentRate'
          ) {
            const data = {
              id: id,
              rateType: type,
            };
            this.safetyKpisService.deactivateSafetyKpiSetup(data).subscribe({
              next: (res) => {
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.getData();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
          }
        }
      });
  }

  activate(type: string, id: number): void {
    if (
      !this.permissionService.isUserHasPermission(
        this.hasActivateSafetyKPIsPermission
      )
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          if (type === 'safetyTriangle') {
            this.safetyKpisService.activateSafetyTriangle(id).subscribe({
              next: (res) => {
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.getData();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
          } else if (type === 'safeManHours') {
            this.safetyKpisService.activateSafemanHours(id).subscribe({
              next: (res) => {
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.getData();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
          } else if (
            type === 'lostTimeInjuryFrequencyRate' ||
            type === 'totalRecordableInjuryFrequencyRate' ||
            type === 'totalRecordableIncidentRate'
          ) {
            const data = {
              id: id,
              rateType: type,
            };
            this.safetyKpisService.activateSafetyKpiSetup(data).subscribe({
              next: (res) => {
                this.sectionState = SectionStateStatus.Ready;
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, res?.message)
                );
                this.getData();
              },
              error: ({ error }) => {
                this.feedBackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.sectionState = SectionStateStatus.Ready;
              },
            });
          }
        }
      });
  }
  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  ngOnDestroy(): void {}
}
