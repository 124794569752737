import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ChecklistSectionType,
  OperatorType,
  WorkPermitApproverTypes,
} from 'src/@hodhod/common/enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { MediaService } from 'src/backend/services/media.service';
import { UserService } from 'src/backend/services/user.service';
import { WorkConditionsService } from 'src/backend/services/work-conditions/work-conditions.service';
import { WorkPermitService } from 'src/backend/services/work-permits/work-permit.service';
import * as moment from 'moment';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { PpeService } from 'src/backend/services/ppe-services/ppe.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
@Component({
  selector: 'app-edit-work-permit',
  templateUrl: './edit-work-permit.component.html',
  styleUrls: ['./edit-work-permit.component.scss'],
})
export class EditWorkPermitComponent implements OnInit, OnChanges {
  @Input() workPermitId: number = 0;
  @Input() workPermit: any;
  public data: any;
  @ViewChild('mediaModal') mediaModal!: TemplateRef<any>;
  mediaForm: FormGroup;
  workPermitForm: FormGroup;
  workderInfoFormArray: FormArray;
  jobSafetyAnalyissFormArray: FormArray;
  private destroy$ = new Subject();
  public translationsList: any = {};
  public workConditions: any[] = [];
  public selectedWorkConditions: any[] = [];
  public departments: any[] = [];
  public ppes: any[];
  public hazards: string[] = [];
  public wCCheckListMode: boolean = false;
  public wCHazardsMode: boolean = false;
  public checklists: any[] = [];
  gaugeType = 'semi';
  gaugeValue = 0;
  sectionListFormArray: FormArray;
  questionTypes = ChecklistSectionType;
  OperatorType = OperatorType;
  riskStatus = [
    {
      text: 'HIGH',
      color: '#D52A46',
    },
    {
      text: 'MEDIUM',
      color: '#E49114',
    },
    {
      text: 'LOW',
      color: '#FFEA30',
    },
  ];

  machines: any[] = [];
  machineId: number;
  ranges = [
    { id: 0, value: '0' },
    { id: 1, value: '1' },
    { id: 2, value: '2' },
    { id: 3, value: '3' },
    { id: 4, value: '4' },
  ];

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public selectedImg: any;
  @ViewChild('imageBigView') imageBigView!: TemplateRef<any>;
  public images: any[] = [];
  minDate: any;
  minStartDate = new Date();
  minEndDate = new Date();
  mintime: any;
  reportUsers: any;
  sectionId: number = 0;
  questionId: number = 0;
  public selectedPpes: any[] = [];

  jobSafetyColumns: TableColumn<any>[] = [
    { label: 'Number', property: 'number', type: 'text', visible: true },
    { label: 'JobSteps', property: 'jobStep', type: 'text', visible: true },
    { label: 'Hazards', property: 'theHazards', type: 'text', visible: true },
    {
      label: 'RiskLevel',
      property: 'riskLevel',
      type: 'select',
      visible: true,
    },
    {
      label: 'MitigatingSteps',
      property: 'mitigatingSteps',
      type: 'text',
      visible: true,
    },
    {
      label: 'ResidualRisk',
      property: 'residualRisk',
      type: 'select',
      visible: true,
    },
    {
      label: 'ToolsAndEquipments',
      property: 'toolsAndEquipments',
      type: 'text',
      visible: true,
    },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];

  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  hasCreatePpePermission = ApplicationPermission.PPE_CREATE;
  hasCreateWorkConditionPermission =
    ApplicationPermission.WORK_CONDITIONS_CREATE;

  constructor(
    private fb: FormBuilder,
    private asyncFeedbackService: AsyncFeedbackService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private workConditionService: WorkConditionsService,
    private loadingService: LoadingService,
    private departmentService: DepartmentsService,
    private checklistService: ChecklistSectionService,
    private router: Router,
    private workPermitService: WorkPermitService,
    private dialog: MatDialog,
    private userService: UserService,
    private mediaService: MediaService,
    private ppeService: PpeService
  ) {
    this.translate
      .get(['Errors', 'Success', 'ConfirmDeleteRecord', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  getAttachmentImage(url: string) {
    const extension = url.split('.').pop()?.toLocaleLowerCase()
    if (extension.includes('xlsx')||extension.includes('xls')) {
      return '../../../../assets/img/resources/xls.png'
    }else if(extension.includes('docx')||extension.includes('doc')){
      return '../../../../assets/img/resources/doc.png'
    }else if(extension.includes('pdf')){
      return '../../../../assets/img/resources/pdf.png'
    }else{
      return url
    }
  }
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        var dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  }
  getSelectedPpes(event) {
    const ids = event.value;
    this.selectedPpes=[...this.ppes.filter((x)=>ids.includes(x.id))]
  }
  ngOnInit() {
    this.workPermitForm = this.fb.group({
      title: ['', Validators.required],
      plannedActivityDescription: ['', Validators.required],
      equipmentDescription: ['', Validators.required],
      pPE: [[]],
      departmentId: ['', Validators.required],
      workConditions: [[], Validators.required],
      startDate: [new Date(), Validators.required],
      endDate: [new Date(), Validators.required],
      startTime: ['12:00 AM', Validators.required],
      endTime: ['', Validators.required],
      attachments: [[]],
      approver: [[], Validators.required],
      additionalApprovers: [[]],
      workerInfos: this.fb.array([this.createWorkerInfoFormGroup()]),
      jobSafetyAnalyiss: this.fb.array([]),
      sections: this.fb.array([this.createSectionForm()]),
    });
    this.mediaForm = this.fb.group({
      file: [[], Validators.required],
    });
   
    this.workderInfoFormArray = this.workPermitForm.get(
      'workerInfos'
    ) as FormArray;
    this.jobSafetyAnalyissFormArray = this.workPermitForm.get(
      'jobSafetyAnalyiss'
    ) as FormArray;
    this.sectionListFormArray = this.workPermitForm.get(
      'sections'
    ) as FormArray;
    this.getMachines();

    this.workPermitForm
      .get('startTime')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((startTime: Date) => {
        // Update the min time of the end time control to be the selected start time
        this.mintime = startTime;
        const endTimeControl = this.workPermitForm.get('endTime');
        if (endTimeControl.value && startTime >= endTimeControl.value) {
          endTimeControl.setErrors({ endTimeMustBeAfterStartTime: true });
        } else {
          endTimeControl.setErrors(null);
        }
        endTimeControl.updateValueAndValidity();
      });
  }

  endTimeMustBeAfterStartTimeValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const startTimeControl = control.parent?.get('startTime');
    if (!startTimeControl) {
      return null;
    }
    const startTime = startTimeControl.value;
    const endTime = control.value;
    if (startTime && endTime && startTime >= endTime) {
      return { endTimeMustBeAfterStartTime: true };
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['workPermit']) {
      this.data = this.workPermit;
      if (this.data) {
        this.images = this.data?.images;
        this.buildLandingForm();
        this.getUsers();
      }
    }
  }

  newJobSafetyStep(): FormGroup {
    return this.fb.group({
      jobStep: ['', [Validators.required, noWhitespaceValidator]],
      toolsAndEquipments: ['', [Validators.required, noWhitespaceValidator]],
      theHazards: ['', [Validators.required, noWhitespaceValidator]],
      riskLevel: ['', [Validators.required, noWhitespaceValidator]],
      mitigatingSteps: ['', [Validators.required, noWhitespaceValidator]],
      residualRisk: ['', [Validators.required, noWhitespaceValidator]],
    });
  }

  addJobSafety() {
    this.jobSafetyAnalyissFormArray.push(this.newJobSafetyStep());
    this.workPermitForm.get('jobSafetyAnalyiss')['controls'] = [
      ...this.jobSafetyAnalyissFormArray.controls,
    ];
    this.jobSafetyAnalyissFormArray.controls = [
      ...this.jobSafetyAnalyissFormArray.controls,
    ];
  }
  get jobSafetyAnalyiss(): FormArray {
    return this.workPermitForm.get('jobSafetyAnalyiss') as FormArray;
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }
  get visibleJobSafetyColumns() {
    return this.jobSafetyColumns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  removeJobSafety(index) {
    this.jobSafetyAnalyissFormArray.removeAt(index);
    this.workPermitForm.get('jobSafetyAnalyiss')['controls'] = [
      ...this.jobSafetyAnalyissFormArray.controls,
    ];
    this.jobSafetyAnalyissFormArray.controls = [
      ...this.jobSafetyAnalyissFormArray.controls,
    ];
  }

  validateDateRange(): void {
    const { startDate } = this.workPermitForm.value;
    this.minEndDate = new Date(startDate);
    this.workPermitForm.get('endDate')?.setValue(this.minEndDate);
  }
  validateEndDateRange(): void {
    this.minStartDate = new Date();
    this.workPermitForm.get('startDate')?.setValue(this.minStartDate);
  }
  onChangeApprovers(event: any): void {
    const approvers = event.value;
    const additionalApprovers = this.workPermitForm.value.additionalApprovers;
    if (additionalApprovers.length > 0) {
      const unique = additionalApprovers.filter((x) => !approvers.includes(x));
      this.workPermitForm.get('additionalApprovers').setValue(unique);
    }
  }

  onChangeAdditionalApprovers(event: any): void {
    const approvers = this.workPermitForm.value.approver;
    if (approvers.length > 0) {
      const additionApprovers = event.value;
      const duplicate = approvers.filter((x) => additionApprovers.includes(x));
      const unique = additionApprovers.filter((x) => !duplicate.includes(x));
      if (duplicate.length > 0) {
        this.workPermitForm.get('additionalApprovers').setValue(unique);
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Warning,
            'Already selected in approvers'
          )
        );
      }
    }
  }
  get repeatSectionFormGroup(): any {
    return this.workPermitForm.get('sections') as FormArray;
  }
  repeatQuestionsFormGroup(i: number): any {
    return this.workPermitForm
      .get('sections')
      ['controls'][i].get('questions') as FormArray;
  }
  createSectionForm(): FormGroup {
    return this.fb.group({
      sectionId: [0],
      title: [''],
      questionType: [''],
      questions: this.fb.array([this.createQuestionForm()]),
    });
  }
  createQuestionForm(): FormGroup {
    return this.fb.group({
      questionId: [0],
      questionText: [''],
      answer: [''],
      target: [0],
      parameters: [[]],
      userAnswer: [''],
      comment: [''],
      isNotApplicable: [false],
      media: [''],
    });
  }

  getUsers(): void {
    this.workPermitService.getApproverByCompany().subscribe({
      next: (response) => {
        this.reportUsers = response;
        this.getCompanyDepartments();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getPpes() {
    this.loadingService.startLoading(true, '');
    this.ppeService.getActivePpes().subscribe({
      next: (response) => {
        this.ppes = response;
        this.selectedPpes=this.data?.ppeDtos
        this.workPermitForm
          .get('pPE')
          ?.setValue(
            this.ppes.length > 0
              ? this.data?.ppe?.split(',').map((item) => Number(item))
              : []
          );
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  getMachines() {
    this.checklistService.getMachines().subscribe((res) => {
      this.machines = res;
    });
  }
  getParameters(id: number, i: number, j: number) {
    this.checklistService.getParameters(id).subscribe((res) => {
      this.workPermitForm
        .get('sections')
        ['controls'][i].get('questions')
        ['controls'][j].get('parameters')
        ?.setValue(res);
    });
  }

  getWorkConditions(): void {
    this.loadingService.startLoading(true, '');
    this.workConditionService
      .getWorkConditionsWithCheckListExecution()
      .subscribe({
        next: (response) => {
          this.workConditions = response;
          this.workPermitForm
            .get('workConditions')
            ?.setValue(
              this.workConditions.length > 0
                ? this.data?.workConditions?.map((ele) => ele.id)
                : []
            );
          this.loadingService.stopLoading();
          this.GetScorePercentage();
        },
        error: ({ error }) => {
          this.asyncFeedbackService.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.loadingService.stopLoading();
        },
      });
  }

  getCompanyDepartments(): void {
    this.departmentService.getDepartmentsParentChild().subscribe({
      next: (response) => {
        this.departments = response;
        this.getWorkConditions();
        this.getPpes();
      },
      error: ({ error }) => {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  get repeatWorkderInfoFormGroup(): FormArray {
    return this.workPermitForm.get('workerInfos') as FormArray;
  }
  createWorkerInfoFormGroup(): FormGroup {
    return this.fb.group({
      id: [0],
      workerName: ['', [Validators.required, noWhitespaceValidator]],
      workerRole: ['', [Validators.required, noWhitespaceValidator]],
    });
  }

  addWorkerInfoRow(): void {
    this.workderInfoFormArray.push(this.createWorkerInfoFormGroup());
  }
  removeWorkerInfoRow(index: number): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['ConfirmDeleteRecord']['Title'],
          this.translationsList['ConfirmDeleteRecord']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.workderInfoFormArray.removeAt(index);
        }
      });
  }

  onChangeConditions(event: any): void {
    const conditions: number[] = this.workPermitForm.value.workConditions;
    const unsortedCheckList: any[] = [];
    const unsortedHazards: any[] = [];
    this.selectedWorkConditions = this.workConditions.filter((x) =>
      conditions.includes(x.id)
    );
    this.workConditions
      .filter((x) => conditions.includes(x.id))
      .forEach((elem) => {
        unsortedCheckList.push(...elem.checkLists);
        unsortedHazards.push(...elem.hazardNames);
      });
    const mergeSections: any[] = [];
    [
      ...new Map(
        unsortedCheckList.map((item) => [item['checkListId'], item])
      ).values(),
    ].forEach((ele) => {
      mergeSections.push(...ele?.section);
    });
    this.setCheckListForm(mergeSections, unsortedHazards, true);
    this.GetScorePercentage();
  }
  firtTimeLoadConditions(): void {
    const conditions: number[] = this.workPermitForm.value.workConditions;
    const unsortedHazards: any[] = [];
    this.selectedWorkConditions = this.workConditions.filter((x) =>
      conditions.includes(x.id)
    );
    this.workConditions
      .filter((x) => conditions.includes(x.id))
      .forEach((elem) => {
        unsortedHazards.push(...elem.hazardNames);
      });
    const mergeSections: any[] = [];
    this.data?.sections?.forEach((ele) => {
      mergeSections.push(...ele?.section);
    });
    this.setCheckListForm(mergeSections, unsortedHazards, false);
  }

  setCheckListForm(
    checklist: any[],
    hazards: any[],
    changeType: boolean
  ): void {
    this.checklists = checklist;
    if (checklist.length > 0) {
      this.wCCheckListMode = true;
    } else {
      this.wCCheckListMode = false;
    }
    if (hazards.length > 0) {
      this.wCHazardsMode = true;
    } else {
      this.wCHazardsMode = false;
    }
    this.buildForm(changeType);
  }

  getParamterName(id: number, i: number, j: number): string {
    return this.workPermitForm
      .get('sections')
      .value[i].questions[j].parameters.find((x) => x.id === id)?.name;
  }

  buildForm(changeType: boolean): void {
    this.loadingService.startLoading(true, '');
    const sections = this.checklists;
    this.addSectionsFormGroup(sections);
    this.workPermitForm
      .get('sections')
      ?.setValue(this.formatCheckListData(this.checklists, changeType));
    this.loadingService.stopLoading();
  }
  buildLandingForm(): void {
    const infos = this.data?.workerInfos;
    this.checklists = this.data?.sections;
    this.addWorkerInfoFormGroup(infos);
    const jobSafetySteps = this.data?.jobSafetyAnalyisses;
    jobSafetySteps.forEach(function (v) {
      delete v.jobSafetyId;
    });
    this.addJobSafetyFormGroup(jobSafetySteps);
    const initialApprovers = this.data?.additionalApprovers.filter(
      (x) => x.approverType === WorkPermitApproverTypes.InitialApprover
    );
    const additionalApprovers = this.data?.additionalApprovers.filter(
      (x) => x.approverType === WorkPermitApproverTypes.AdditionalApprover
    );
    this.workPermitForm.get('title')?.setValue(this.data?.title);
    this.workPermitForm
      .get('plannedActivityDescription')
      ?.setValue(this.data?.plannedActivityDescription);
    this.workPermitForm
      .get('equipmentDescription')
      ?.setValue(this.data?.equipmentDescription);

    this.workPermitForm.get('workerInfos')?.setValue(infos);
    this.workPermitForm.get('jobSafetyAnalyiss')?.setValue(jobSafetySteps);

    this.workPermitForm
      .get('departmentId')
      ?.setValue(this.data?.departmentCode);
    this.workPermitForm.get('startDate')?.setValue(this.data?.startDate);
    this.workPermitForm.get('endDate')?.setValue(this.data?.endDate);
    this.minDate = this.data?.endDate;
    this.workPermitForm.get('startTime')?.setValue(this.data?.startTime);
    this.workPermitForm.get('endTime')?.setValue(this.data?.endTime);
    this.mintime = this.data?.endTime;
    this.workPermitForm
      .get('approver')
      ?.setValue(
        initialApprovers ? initialApprovers?.map((ele) => ele.approverId) : []
      );
    this.workPermitForm
      .get('additionalApprovers')
      ?.setValue(
        additionalApprovers
          ? additionalApprovers?.map((ele) => ele.approverId)
          : []
      );

    this.firtTimeLoadConditions();
  }
  addSectionsFormGroup(sections: any[]): void {
    this.sectionListFormArray.clear();
    for (let index = 0; index < sections.length; index++) {
      this.sectionListFormArray.push(this.createSectionForm());
      this.addQuestionsFormGroup(sections[index]?.questions?.length, index);
    }
  }
  addWorkerInfoFormGroup(workerInfos: any[]): void {
    this.workderInfoFormArray.clear();
    for (let index = 0; index < workerInfos.length; index++) {
      this.workderInfoFormArray.push(this.createWorkerInfoFormGroup());
    }
  }
  addJobSafetyFormGroup(jobSafetySteps: any[]): void {
    this.jobSafetyAnalyissFormArray.clear();
    for (let index = 0; index < jobSafetySteps.length; index++) {
      this.jobSafetyAnalyissFormArray.push(this.newJobSafetyStep());
    }
    this.workPermitForm.get('jobSafetyAnalyiss')['controls'] = [
      ...this.jobSafetyAnalyissFormArray.controls,
    ];
    this.jobSafetyAnalyissFormArray.controls = [
      ...this.jobSafetyAnalyissFormArray.controls,
    ];
  }

  addQuestionsFormGroup(numOfGroups: number, i: number): void {
    this.repeatQuestionsFormGroup(i).clear();
    for (let index = 0; index < numOfGroups; index++) {
      this.repeatQuestionsFormGroup(i).push(this.createQuestionForm());
    }
  }

  formatCheckListData(data: any[], changeType: boolean): any[] {
    const formatedData = data.map((section, i) => {
      return {
        sectionId: section?.sectionId,
        title: section?.sectionTitle,
        questionType: section?.sectionType,
        questions: section?.questions?.map((question, j) => {
          if (section?.sectionType === ChecklistSectionType.MachineParameter) {
            this.checklistService
              .getParameters(parseInt(question?.answer, 10))
              .subscribe((res) => {
                this.workPermitForm
                  .get('sections')
                  ['controls'][i].get('questions')
                  ['controls'][j].get('parameters')
                  ?.setValue(res);
              });
          }
          return {
            questionId: question?.questionId,
            questionText:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.questionTitle, 10)
                : question?.questionTitle,
            answer:
              section?.sectionType === ChecklistSectionType.MachineParameter
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.Range
                ? parseInt(question?.answer, 10)
                : section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[0]
                : question?.answer,
            target:
              section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.answer.split(' ')[1]
                : '',
            parameters: [],
            userAnswer: !changeType
              ? section?.sectionType === ChecklistSectionType.ValueVsTarget
                ? question?.userAnswer
                  ? question?.userAnswer.split(' ')[1]
                  : ''
                : question?.userAnswer
              : question?.userAnswer,
            comment: question?.comment,
            isNotApplicable: question?.isNotApplicable,
            media: question?.media,
          };
        }),
      };
    });
    return formatedData;
  }

  editWorkPermit(): void {
    const attachment = this.workPermitForm.value.attachments;
    const fileSize = attachment.reduce((accumulator, object) => {
      return accumulator + object.size;
    }, 0);
    const sizeInMB = fileSize / 1024 / 1024;
    if (sizeInMB <= SharedConstants.WORK_PERMIT_FILE_SIZE) {
      if (this.gaugeValue === 100) {
        this.confirmationService
          .confirm(
            new Confirmation(
              ConfirmationType.NonDestructiveAction,
              this.translationsList['User']['UpdateConfirm']['Title'],
              this.translationsList['User']['UpdateConfirm']['Message']
            )
          )
          .then((value) => {
            if (value === true) {
              this.loadingService.startLoading(true, '');
              const {
                title,
                plannedActivityDescription,
                equipmentDescription,
                pPE,
                departmentId,
                workConditions,
                startDate,
                endDate,
                startTime,
                endTime,
                description,
                attachments,
                workerInfos,
                jobSafetyAnalyiss,
                sections,
                approver,
                additionalApprovers,
              } = this.workPermitForm.value;
              const approvers = approver?.map((ele) => {
                return {
                  approverId: 0,
                  approver: ele,
                  approverType: WorkPermitApproverTypes.InitialApprover,
                };
              });
              const formData = new FormData();
              const data = {
                title: title,
                plannedActivityDescription: plannedActivityDescription,
                equipmentDescription: equipmentDescription,
                pPE: pPE.toString(),
                workPermitId: this.workPermitId,
                departmentCode: departmentId,
                description: description,
                startDate: moment(new Date(startDate)).format(
                  'YYYY-MM-DDThh:mm:ssZ'
                ),
                endDate: moment(new Date(endDate)).format(
                  'YYYY-MM-DDThh:mm:ssZ'
                ),
                startTime: startTime,
                endTime: endTime,
                workConditions: workConditions,
                workerInfos: workerInfos,
                jobSafetyAnalyisses: jobSafetyAnalyiss,
                additionalApprovers: [
                  ...approvers,
                  ...additionalApprovers?.map((ele) => {
                    return {
                      approverId: 0,
                      approver: ele,
                      approverType: WorkPermitApproverTypes.AdditionalApprover,
                    };
                  }),
                ],
                sections: sections?.map((ele) => {
                  if (ele.questionType === 'Value Vs Target') {
                    return {
                      sectionId: ele.sectionId,
                      sectionTitle: ele.title,
                      questions: ele.questions?.map((ques) => {
                        return {
                          questionId: ques.questionId,
                          userAnswer: ques.isNotApplicable
                            ? ''
                            : ques.answer + ' ' + ques.userAnswer.toString(),
                          comment: ques.comment,
                          isNotApplicable: ques.isNotApplicable,
                          media: ques.media,
                        };
                      }),
                    };
                  } else {
                    return {
                      sectionId: ele.sectionId,
                      sectionTitle: ele.title,
                      questions: ele.questions?.map((ques) => {
                        return {
                          questionId: ques.questionId,
                          userAnswer: ques.isNotApplicable
                            ? ''
                            : ques.userAnswer.toString(),
                          comment: ques.comment,
                          isNotApplicable: ques.isNotApplicable,
                          media: ques.media,
                        };
                      }),
                    };
                  }
                }),
              };
              attachments.forEach((element) => {
                formData.append('files', element);
              });
              formData.append('content', JSON.stringify(data));
              this.workPermitService.editWorkPermit(formData).subscribe({
                next: (response) => {
                  this.asyncFeedbackService.showFeedback(
                    new FeedbackModel(FeedbackType.Success, response?.message)
                  );
                  this.loadingService.stopLoading();
                  this.goBack();
                },
                error: ({ error }) => {
                  this.asyncFeedbackService.showFeedback(
                    new FeedbackModel(FeedbackType.Failure, error?.message)
                  );
                  this.loadingService.stopLoading();
                },
              });
            }
          });
      } else {
        this.asyncFeedbackService.showFeedback(
          new FeedbackModel(FeedbackType.Warning, 'need 100% score to submit')
        );
      }
    } else {
      this.asyncFeedbackService.showFeedback(
        new FeedbackModel(FeedbackType.Warning, 'maximum file size allowed 5Mb')
      );
    }
  }

  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.WORK_PERMIT +
        '/' +
        SharedConstants.WORK_PERMIT_LIST,
    ]);
  }

  openBigView(id: number): void {
    const img = this.data?.images.find((x) => x.id === id);
    if (img) {
      const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
      const extension = img.imageUrl.split('.').pop();
      this.selectedImg = img;
      if (imageExtensions.includes(extension)) {
        this.dialog.open(this.imageBigView, {
          maxHeight: '95vh',
          width: 'auto',
        });
      } else {
        const downloadTag = document.createElement('a');
        downloadTag.href = img.imageUrl;
        downloadTag.addEventListener('click', () => {
          downloadTag.download;
        });
        downloadTag.click();
      }
    }
  }

  getAttachementTypeName(url: string): any {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'PNG'];
    const extension = url.split('.').pop();
    if (imageExtensions.includes(extension)) {
      return 'Image';
    }
    return 'Document';
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }

  deleteDirectlyFile(id: number): void {
    const img = this.data?.images.find((x) => x.id === id);
    if (img) {
      this.selectedImg = img;
      this.deleteImage();
    }
  }

  deleteImage(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['UpdateConfirm']['Title'],
          this.translationsList['User']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.closeDialog();
          this.loadingService.startLoading(true, '');
          this.workPermitService
            .deleteWorkPermitImage(this.selectedImg ? this.selectedImg.id : 0)
            .subscribe({
              next: (response) => {
                this.asyncFeedbackService.showFeedback(
                  new FeedbackModel(FeedbackType.Success, response?.message)
                );
                this.loadingService.stopLoading();
                this.images = this.images.filter(
                  (x) => x.id !== this.selectedImg.id
                );
              },
              error: ({ error }) => {
                this.asyncFeedbackService.showFeedback(
                  new FeedbackModel(FeedbackType.Failure, error?.message)
                );
                this.loadingService.stopLoading();
              },
            });
        }
      });
  }

  GetScorePercentage(): any {
    let correctanswers = 0;
    let NoOfQuestions = 0;
    let Percentage = 0;
    let sectionsFormArray = this.workPermitForm.get('sections').value;
    sectionsFormArray.forEach((element) => {
      if (element.questionType === 'Value Vs Target') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (
              el1.answer + ' ' + ' ' + el1.target ==
              el1.answer + ' ' + ' ' + el1.userAnswer
            ) {
              correctanswers++;
            }
            NoOfQuestions++;
          }
        });
      } else if (element.questionType === 'Select from range') {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            switch (el1.userAnswer) {
              case '0':
                correctanswers = correctanswers + 0;
                break;
              case '1':
                correctanswers = correctanswers + 0.25;
                break;
              case '2':
                correctanswers = correctanswers + 0.5;
                break;
              case '3':
                correctanswers = correctanswers + 0.75;
                break;
              case '4':
                correctanswers++;
                break;
              default:
                break;
            }
            NoOfQuestions++;
          }
        });
      } else {
        element.questions.forEach((el1) => {
          if (!el1.isNotApplicable) {
            if (el1.answer == el1.userAnswer) {
              correctanswers++;
            }
            NoOfQuestions++;
          }
        });
      }
    });
    Percentage = (correctanswers / NoOfQuestions) * 100;
    this.gaugeValue = Number(Percentage.toFixed(1));
  }

  getGuageColors(value: number): string {
    if (value < 40) {
      return '#fa2020';
    }
    if (value >= 40 && value < 70) {
      return '#2041fa';
    }
    if (value >= 70) {
      return '#42f548';
    }
    return '';
  }

  updateMediaUrl(mediaUrl: string): void {
    const sections = this.workPermitForm.get('sections').value;
    const singleSection = sections.find((x) => x.sectionId === this.sectionId);
    if (singleSection) {
      const question = singleSection.questions.find(
        (x) => x.questionId === this.questionId
      );
      if (question) {
        question.media = mediaUrl;
        this.workPermitForm.get('sections')?.setValue(sections);
      }
    }
  }

  uploadAnswerMedia(): void {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          const formData = new FormData();
          formData.append('file', this.mediaForm.value.file[0]);
          this.loadingService.startLoading(true, '');
          this.mediaService.uploadSingleFile(formData).subscribe({
            next: (response) => {
              this.updateMediaUrl(response?.mediaPath);
              this.closeDialog();
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, response?.message)
              );
              this.loadingService.stopLoading();
            },
            error: ({ error }) => {
              this.asyncFeedbackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }
  openMediaModal(sectionId: number, questionId: number): void {
    this.sectionId = sectionId;
    this.questionId = questionId;
    this.mediaForm.get('file')?.setValue([]);
    this.dialog.open(this.mediaModal, { height: 'auto', width: '40%' });
  }
  notApplicableChange(): void {
    this.GetScorePercentage();
  }
}
