import { Component, Input, OnInit, Output } from '@angular/core';
import { ParentRole } from '../../models/parent-role';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { RoleService } from 'src/backend/services/role.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { filterPermissions } from 'src/@hodhod/common/custom_methods';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { log } from 'console';

@Component({
  selector: 'app-new-role',
  templateUrl: './new-role.component.html',
  styleUrls: ['./new-role.component.scss'],
})
export class NewRoleComponent implements OnInit {
  public rolePermissions: ParentRole[] = [];
  translationsList: any;
  private destroy$ = new Subject();
  roleForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private roleService: RoleService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private loadingService: LoadingService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.translate
      .get(['Errors', 'Success', 'confirmDeactiveRecord', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.roleForm = this.fb.group({
      roleName: ['', Validators.required],
      isExternal: [false],
    });
    this.getRoleDetails();
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        alert(params.get('id'));
      }
    });
  }

  saveChangesPermissions(event: any): void {
    event
      ? event.map((block) => {
          block.permissions.map((row) => {
            delete row.options;
            delete row.selectedOption;
          });
        })
      : null;

    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingService.startLoading(true, '');
          const newRole = {
            roleName: this.roleForm.get('roleName').value,
            isExternal: this.roleForm.get('isExternal').value,
            permissionBlocks: event,
          };
          this.roleService.createPermissions(newRole).subscribe({
            next: (res) => {
              this.loadingService.stopLoading();
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.router.navigate([
                SharedConstants.USER_MANAGEMENT + '/' + SharedConstants.ROLES,
              ]);
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.loadingService.stopLoading();
            },
          });
        }
      });
  }
  getRoleDetails(): void {
    this.loadingService.startLoading(true, '');
    this.roleService.getRoleDetails(0).subscribe({
      next: (response) => {
        this.rolePermissions = response['permissonBlocks'];
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }
}
