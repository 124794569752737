import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationLink } from '../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { Observable, of } from 'rxjs';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../components/search-modal/search-modal.component';
import { HodhodConfig } from 'src/@hodhod/config/hodhod-config.interface';
import { ColorSchemeName } from 'src/@hodhod/config/colorSchemeName';
import { BaseApi } from 'src/backend/api/base-api';
import { Router } from '@angular/router';
import { LanguageService } from 'src/@hodhod/services/language.service';
import { Language } from 'src/app/shared/models/language';

@Component({
  selector: 'hodhod-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() collapsed!: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(
    map((config) => config.sidenav.title)
  );
  imageUrl$ = this.configService.config$.pipe(
    map((config) => config.sidenav.imageUrl)
  );
  showCollapsePin$ = this.configService.config$.pipe(
    map((config) => config.sidenav.showCollapsePin)
  );
  userVisible$ = this.configService.config$.pipe(
    map((config) => config.sidenav.user.visible)
  );
  searchVisible$ = this.configService.config$.pipe(
    map((config) => config.sidenav.search.visible)
  );

  userMenuOpen$: Observable<boolean> = of(false);
  currentLanguage: Language;
  items = this.navigationService.items.filter(
    (x) => x.label !== 'ManageAccount' && x.label !== 'ManageContent'
  );

  isDark$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.style.colorScheme === ColorSchemeName.dark)
  );

  public organization!: any;
  public company!: any;

  constructor(
    private navigationService: NavigationService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private readonly popoverService: PopoverService,
    private readonly dialog: MatDialog,
    private baseApi: BaseApi,
    private langService: LanguageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.organization = this.baseApi.getOrganizationFromStorage();
    this.company = this.baseApi.getCompanyFromStorage();
    this.ChangeRtl();

    if (this.items.some((item) => item.label == 'Dashboard')) {
      const fromIndex = this.items.findIndex(
        (item) => item.label == 'Dashboard'
      );
      const toIndex = 0;
      const element = this.items.splice(fromIndex, 1)[0];
      this.items.splice(toIndex, 0, element);
    }
  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed
      ? this.layoutService.expandSidenav()
      : this.layoutService.collapseSidenav();
  }

  ChangeRtl(): void {
    this.langService.currentLanguage$.subscribe((language) => {
      this.currentLanguage = language;
    });
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
          },
        ],
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: 'hodhod-dialog-glossy',
      width: '100%',
      maxWidth: '600px',
    });
  }
}
