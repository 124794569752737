import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ReportingDashboardService } from 'src/backend/services/dashboards/reporting-dashboard.service';
import { SafetyKpisService } from 'src/backend/services/dashboards/safety-kpis.service';
@Component({
  selector: 'app-define-safety-triangle-levels',
  templateUrl: './define-safety-triangle-levels.component.html',
  styleUrls: ['./define-safety-triangle-levels.component.scss'],
})
export class DefineSafetyTriangleLevelsComponent implements OnInit {
  safetyTriangleData = null;
  public loadAuto: boolean = false;
  public loadManual: boolean = false;
  constructor(
    private reportingDashboardService: ReportingDashboardService,
    private safetyKpisService: SafetyKpisService,
    private router: Router,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {}
  ID:number;
  foundReportingFeature = null;
  ngOnInit(): void {
   this.ID= this.route.snapshot.params['id']
    this.loadingService.startLoading(true, '');
    this.getTriangleData();
    this.reportingDashboardService.getCompanyFeatures().subscribe({
      next: (res) => {
        this.foundReportingFeature = res.find(
          (feature) => feature.featureId == 'AMF-001'
        );
      },
    });
  }

  getTriangleData() {
    this.safetyKpisService.getSefetyTriangleById(this.ID).subscribe({
      next: (res) => {
        this.safetyTriangleData = res;
        this.loadingService.stopLoading();
      },
      error: (error) => {
        this.loadingService.stopLoading();
      },
    });
  }

  tabChanged(event) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {},
    });
    const selectedTabIndex = event.index;
    if (selectedTabIndex == 0) {
      this.loadManual = !this.loadAuto;
    } else if (selectedTabIndex == 1) {
      this.loadAuto = true;
    }
  }
}
