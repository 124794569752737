import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-invitations-log-card',
  templateUrl: './user-invitations-log-card.component.html',
  styleUrls: ['./user-invitations-log-card.component.scss']
})
export class UserInvitationsLogCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
