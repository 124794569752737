<div class="overflow-hidden">
  <div class="flex justify-end">
    <div class="wrapper">
      <div class="flex justify-end">
        <button
        *ngIf="hasReadPermission|permissionChecker"
          (click)="openCreateModal()"
          class="ml-1 flex-none"
          color="primary"
          mat-mini-fab
          [matTooltip]="'General.Buttons.AddNew' | translate"
          type="button"
          style="border-radius: 10px"
        >
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <app-state-section
    body
    [flexibleHeight]="true"
    [state]="sectionState"
    [loadingLabel]="loadingLabel | translate"
    [errorLabel]="'Errors.ErrorHappened' | translate"
  >
    <div class="wrapper ">
      <!-- dashboards card -->
      <mat-grid-list
        *ngIf="allDashboards.length > 0; else noDataTemblate"
        cols="{{ columns }}"
        rowHeight="450px"
        gutterSize="10px"
      >

      <div class="relative">

        <mat-grid-tile
          class="card-style ltr:mr-10 rtl:ml-10 "
          *ngFor="let dashboard of allDashboards"
        >
          <app-dashboard-card-two
            class="card "
            [iotdashboardSetting]="dashboard"
            [isIot]="true"
          >

        </app-dashboard-card-two>
        <button
        *ngIf="hasReadPermission|permissionChecker"
        (click)="removeDashboard(dashboard?.dashboardID)"
        color="primary"
        class="absolute" style="top: -4px; right:-10px;"
        mat-icon-button
        [matTooltip]="'General.Buttons.Remove' | translate"
        type="button"
      >
        <mat-icon svgIcon="mat:cancel"></mat-icon>
      </button>

      
        </mat-grid-tile>
      </div>

      </mat-grid-list>

      <ng-template #noDataTemblate>
        <div class="flex no-data">
          <div class="flex column">
            <img
              style="width: fit-content; margin: auto"
              src="assets/img/no-dashboards.png"
            />
            <h1 style="color: #00000099; font-size: large">
              This is the where you'll find your dashboards, start using feature
              to see data reflected here.
            </h1>
          </div>
        </div>
      </ng-template>
    </div>
  </app-state-section>
</div>
