<app-state-section [flexibleHeight]="true" [state]="sectionState"
  [loadingLabel]="(formMode === 'create' ? 'Hazards.AddingLoadingHazard' : 'Hazards.UpdatingLoadingHazard')| translate"
  [errorLabel]="'Errors.ErrorHappened' | translate">
  <form [formGroup]="hazardForm">
    <div class="flex items-center" mat-dialog-title>
      <h2 *ngIf="hazardForm.get('hazard').value" class="headline m-0 flex-auto">{{ hazardForm.get('hazard').value}}</h2>
      <h2 *ngIf="!hazardForm.get('hazard').value" class="headline m-0 flex-auto">{{'Hazards.AddHazards' | translate}}
      </h2>
      <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <div class="flex flex-col flex-wrap justify-between">
      <div class="field-full flex column">
        <mat-label class="field-label">{{'Hazards.Name' | translate}}
          <span
            [ngClass]="hazardForm.invalid && hazardForm.get('hazard').hasError('required') ? 'is-invalid' : 'is-valid'">*</span>
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input [placeholder]="'Placeholder.AddText'  | translate" matInput type="text" formControlName="hazard"
            [readonly]="this.formMode === 'view'" />
          <mat-error *ngIf="hazardForm.invalid && hazardForm.get('hazard').hasError('required')">
            <div class="flex justify-flex-start align-flex-center">
              <img class="error-img" src="assets/img/resources/error-icon.svg" />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error *ngIf="hazardForm.invalid && hazardForm.get('hazard').hasError('whitespace')">
            <div class="flex justify-flex-start align-flex-center">
              <img class="error-img" src="assets/img/resources/error-icon.svg" />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <mat-radio-group formControlName="status" [disabled]="this.formMode === 'view'" *ngIf="this.formMode === 'create'" (change)="statusChange($event)"
        class="flex flex-row gap-3">
        <mat-radio-button value="active">{{'General.Active' | translate}}</mat-radio-button>
        <mat-radio-button value="inactive">{{'General.Inactive' | translate}}</mat-radio-button>
      </mat-radio-group>

    </div>
    <!-- History -->
    <div class="mt-4" *ngIf="this.formMode === 'view'">
      <app-expandable-panel [expanded]="true"  #expandablePanel [title]="'Hazards.HazardHistory'|translate">
        <app-history-log body *ngIf="this.defaults.hazard"
          [sourceId]="this.defaults.hazard.id"
          [sourceType]="HistoryType"
        ></app-history-log>
      </app-expandable-panel>
    </div>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button [btnType]="'Cancel'" [btnText]="'Cancel'" (onClick)="closeDialog()"></app-basic-button>
        <app-basic-button *ngIf="isCreateMode()" [btnType]="'Add'" [btnText]="'Add'" [isDisabled]="!hazardForm.valid"
          (onClick)="save()"></app-basic-button>
        <app-basic-button *ngIf="isUpdateMode()" [btnType]="'Add'" [btnText]="'Save'" [isDisabled]="!hazardForm.valid"
          (onClick)="save()"></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>