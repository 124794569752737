import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  SmartSafetyFilterDashboards,
} from 'src/@hodhod/common/enum';
import { DashboardCalenderComponent } from 'src/app/dashboard/components/dashboard-calender/dashboard-calender.component';
import { Dashboard, DashboardFilterPayload } from '../../models/dashboard';
import moment from 'moment';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import funnel from 'highcharts/modules/funnel';
import { SafetyKpisDashboardsService } from 'src/backend/services/dashboards/safety-kpis-dashboards.service';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DashboardFullscreenComponent } from '../dashboard-fullscreen/dashboard-fullscreen.component';
import { ReportingDashboardService } from 'src/backend/services/dashboards/reporting-dashboard.service';

funnel(Highcharts);
Drilldown(Highcharts);

@Component({
  selector: 'app-safety-triangle-dashboard',
  templateUrl: './safety-triangle-dashboard.component.html',
  styleUrls: ['./safety-triangle-dashboard.component.scss'],
})
export class SafetyTriangleDashboardComponent implements OnInit {
  public filterDashboards: any = null;
  public filterName: string = 'lastThirtyDay';
  public chart: Chart;
  public dashboardData: any;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public colors = [
    '#5B924D',
    '#75D572',
    '#FFDD29',
    '#F2B230',
    '#FC8F32',
    '#E88595',
    '#D7354F',
  ];
  public selectedColors = [];
  public dashboard: Dashboard;

  public dashboardFilter: DashboardFilterPayload = {
    fromDate: new Date().toISOString(),
    toDate: new Date().toISOString(),
  };

  levels: any[] = [];

  constructor(
    private dialog: MatDialog,
    private safetyKpisService: SafetyKpisDashboardsService,
    private feedBackService: AsyncFeedbackService,
    public _bottomSheet: MatBottomSheet,
    public reportingDashboardService: ReportingDashboardService
  ) {}

  ngOnInit(): void {
    this.filterDashboards = SmartSafetyFilterDashboards;
    const today = new Date();
    today.setHours(23, 59, 59, 0);
    this.dashboardFilter.toDate = new Date(today).toISOString();
    const Past30Day = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
    Past30Day.setHours(0, 0, 0, 0);
    this.dashboardFilter.fromDate = Past30Day.toISOString();
    this.getSafetyTriangleData();
  }

  getSafetyTriangleData() {
    const data = {
      fromDate: this.dashboardFilter.fromDate,
      toDate: this.dashboardFilter.toDate,
    };
    this.sectionState = SectionStateStatus.Loading;
    this.safetyKpisService.getSafetyTriangleDashboard(data).subscribe({
      next: (res) => {
        this.dashboardData = res;
        this.levels = this.dashboardData?.levels.map((item) => {
          return {
            name: item.historicalValue
              ? `${item.name} ( ${item.value + item.historicalValue} )`
              : `${item.name} ( ${item.value} )`,
            y: 1,
          };
        });
        this.selectedColors = this.colors.slice(0, this.levels.length - 1);
        this.selectedColors.push('#ff0000b9');
        this.buildChart();
        this.sectionState = SectionStateStatus.Ready;
      },
      error: (error) => {
        this.feedBackService?.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  onFilterChange(filterName: string) {
    const date = new Date();
    this.filterName = filterName;
    if (filterName !== 'customRange') {
        if (filterName === 'thisDay') {
        const today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);

        let lastHourInDay = new Date();
        lastHourInDay.setHours(23);
        lastHourInDay.setMinutes(59);
        lastHourInDay.setSeconds(59);
        lastHourInDay.setMilliseconds(999);

        this.dashboardFilter.fromDate = new Date(today).toISOString();
        this.dashboardFilter.toDate = new Date(lastHourInDay).toISOString();
      } else if (filterName === 'yesterday') {
        const today = new Date();
        const day = today.getDate() - 1;
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setDate(day);

        this.dashboardFilter.fromDate = new Date(today).toISOString();
        today.setHours(23);
        today.setMinutes(59);
        today.setSeconds(59);
        today.setMilliseconds(999);
        this.dashboardFilter.toDate = new Date(today).toISOString();
      } else if (filterName === 'currentMonth') {
        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        firstDay.setHours(0, 0, 0, 0);
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.fromDate = firstDay.toISOString();
        this.dashboardFilter.toDate = today.toISOString();
      } else if (filterName === 'lastMonth') {
        const today = new Date();
        const firstDayPrevMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        firstDayPrevMonth.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = firstDayPrevMonth.toISOString();
        const lastDayPrevMonth = new Date(
          today.getFullYear(),
          today.getMonth(),
          0
        );
        lastDayPrevMonth.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = lastDayPrevMonth.toISOString();
      } else if (filterName === 'lastThirtyDay') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);
        this.dashboardFilter.toDate = new Date(today).toISOString();
        const Past30Day = new Date(today.getTime() - 29 * 24 * 60 * 60 * 1000);
        Past30Day.setHours(0, 0, 0, 0);
        this.dashboardFilter.fromDate = Past30Day.toISOString();
      } else if (filterName === 'yearToDate') {
        const today = new Date();
        today.setHours(23, 59, 59, 0);

        this.dashboardFilter.toDate = new Date(today).toISOString();

        const startYearDate = new Date(date.getFullYear(), 0, 1);
        startYearDate.setHours(0, 0, 0, 0);

        this.dashboardFilter.fromDate = startYearDate.toISOString();
      }
      this.getSafetyTriangleData();
    }
  }

  buildChart() {
    this.chart = new Chart({
      chart: {
        type: 'pyramid',
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      tooltip: this.levels
        ? {
            headerFormat: '',
            pointFormat: `{point.name}`,
            shared: true,
          }
        : null,
      colors: [...this.selectedColors],
      plotOptions: {
        series: {
          dataLabels: {
            color: 'black',
            position: 'left',
            style: { fontSize: '14px', textOverflow: 'clip' },
          },
          borderRadius: {
            radius: '60',
            scope: 'point',
          },
          borderWidth: 5,
          borderColor: '#fff',
          point: {
            events: {
              click: (event) => {
                if (this.dashboardData?.mode === 'AUTO') {
                  if (event.point.index === 0) {
                    //hazards
                    this.getDashboardData('ReportedHazard');
                  } else if (event.point.index === 1) {
                    //nearmiss
                    this.getDashboardData('ReportedNearMiss');
                  } else if (event.point.index >= 2) {
                    //incident
                    this.getDashboardData('ReportedIncident');
                  }
                }
              },
            },
          },
          center: ['60%', '48%'],
          width: '85%',
        } as any,
      },

      exporting: {
        enabled: true,
      },
      legend: {
        align: 'left', // Align legend to the left
        verticalAlign: 'middle',
        layout: 'vertical',
        x: 200,
        y: 400,
      },
      series: [
        {
          type: 'pyramid',
          name: '',
          data: this.levels,
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 400,
            },
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    inside: true,
                  },
                  center: ['50%', '50%'],
                  width: '100%',
                },
              },
            } as any,
          },
        ],
      },
    });
    this.chart.ref$.subscribe((chartInstance) => {
      chartInstance.reflow();
    });
  }

  onSelectCustomRange() {
    const dialogRef = this.dialog.open(DashboardCalenderComponent, {
      width: window.innerWidth < 850 ? '90%' : '60%',
      height: 'auto',
      data: {
        fromDate: this.dashboardFilter.fromDate,
        toDate: this.dashboardFilter.toDate,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event !== 'cancel') {
        this.dashboardFilter.fromDate = new Date(
          new Date(result?.data?.fromDate).setHours(0, 0, 0, 0)
        ).toISOString();
        this.dashboardFilter.toDate = new Date(
          new Date(result?.data?.toDate).setHours(23, 59, 59, 0)
        ).toISOString();
        this.filterName = 'customRange';
        this.getSafetyTriangleData();
      }
      this.dashboardFilter.fromDate = result?.data?.fromDate;
      this.dashboardFilter.toDate = result?.data?.toDate;
      this.filterName = 'customRange';
    });
  }

  formatDate(date) {
    return moment(new Date(date)).format('dddd, MMMM Do YYYY');
  }
  openBottomSheet(): void {
    const bottomSheet = this._bottomSheet.open(DashboardFullscreenComponent, {
      panelClass: 'dashboard-full-screen',
      data: {
        dashboard: this.dashboard,
        dashboardSetting: {
          dashboardName: this.dashboard?.dashboardName,
          filterName: this.filterName,
          fromDate: this.dashboardFilter.fromDate,
          toDate: this.dashboardFilter.toDate,
          isVisible: true,
        },
      },
      disableClose: true,
    });

    bottomSheet.backdropClick().subscribe((event) => {
      bottomSheet.instance.handleBackdropClick(event);
    });
  }
  getDashboardData(dashboardName) {
    this.sectionState = SectionStateStatus.Loading;
    this.reportingDashboardService
      .getDataByDashboard(dashboardName, this.dashboardFilter)
      .subscribe({
        next: (res) => {
          this.dashboard = res;
          this.openBottomSheet();
          this.sectionState = SectionStateStatus.Ready;
        },
        error: (error) => {
          this.feedBackService?.showFeedback(
            new FeedbackModel(FeedbackType.Failure, error?.message)
          );
          this.sectionState = SectionStateStatus.Ready;
        },
      });
  }
}
