import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-widget',
  templateUrl: './progress-widget.component.html',
  styleUrls: ['./progress-widget.component.scss']
})
export class ProgressWidgetComponent implements OnInit {

  @Input() icon: string;
  @Input() value: string;
  @Input() label: string;
  @Input() change: number;
  @Input() iconClass: string;

  showButton: boolean;

  constructor() { }

  ngOnInit() {
  }


}
