import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { BaseApi } from '../api/base-api';
import {
  CreateInstanceParam,
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from '../models/work-flows/work-flow';

@Injectable({
  providedIn: 'root',
})
export class WorkFlowService {
  constructor(private baseApi: BaseApi, private http: HttpClient) {}

  getWorkFlowByScreen(
    workflowparams: WorkFlowByScreenParam
  ): Observable<WorkFlow> {
    return this.http.post<WorkFlow>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_GET_WORKFLOW),
      workflowparams
    );
  }
  createWorkFlowInstance(data: CreateInstanceParam): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_CREATE_INSTANCE),
      data
    );
  }
  setWorkFlowInstance(data: SetInstanceParam): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_SET_INSTANCE),
      data
    );
  }
  updateWorkFlowInstance(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_UPDATE_INSTANCE),
      data
    );
  }
  updateBulkWorkFlowInstance(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_UPDATE_BULK_INSTANCE),
      data
    );
  }
  setCustomUserForReminderOrEscalation(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_SET_CUSTOM_USERS),
      data
    );
  }
  getTransitionRules(data: any): Observable<any[]> {
    return this.http.post<any[]>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_TRANISTION_RULES),
      data
    );
  }
  deleteWorkFlowInstance(instanceKey: string): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_WORKFLOW_DELETE_INSTANCE + '/' + instanceKey
      )
    );
  }

  setReminderEscalationMessage(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_CUSTOM_REMINDER_MESSAGE),
      data
    );
  }

  createBulkWorkFlowInstance(data: CreateInstanceParam[]): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_CREATE_BULK_INSTANCE),
      data
    );
  }
  setBulkWorkFlowInstance(data: SetInstanceParam[]): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_SET_BULK_INSTANCE),
      data
    );
  }
  deleteBulkWorkFlowInstance(data: string[]): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_DELETE_BULK_INSTANCE),
      data
    );
  }
  getNextTransitionAssignees(instanceKey: string): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_WORKFLOW_GET_NEXT_TRANSITION_ASSIGNEES + '/' + instanceKey
      )
    );
  }
  getInstanceKeyByTableId(workflowKey: string, id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_WORKFLOW_GET_INSTANCE_KEY_BY_TABLE_ID +
          '/' +
          id +
          '/' +
          workflowKey
      )
    );
  }

  getUserPermission(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_WORKFLOW_GET_USER_PERMISSION),
      data
    );
  }
}
