import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { TaskTrackerService } from 'src/backend/services/task-tracker/task-tracker.service';
import { UserService } from 'src/backend/services/user.service';

@Component({
  selector: 'app-reschedule-task-popup',
  templateUrl: './reschedule-task-popup.component.html',
  styleUrls: ['./reschedule-task-popup.component.scss'],
})
export class RescheduleTaskPopupComponent implements OnInit {
  taskForm: FormGroup;

  reportUsers: any;
  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private userService: UserService,
    private feedBackService: AsyncFeedbackService,
    private taskService: TaskTrackerService,
    private router: Router,
    private loadingService: LoadingService,
    @Inject(MAT_DIALOG_DATA)
    public data: { taskId: any }
  ) {}

  ngOnInit(): void {
    this.taskForm = this.fb.group({
      taskId: [this.data.taskId, Validators.required],
      assigneeId: ['', Validators.required],
      rescheduleDate: ['', Validators.required],
    });
    this.getUsers();
  }

  getUsers(): void {
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.reportUsers = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  rescheduleTask() {
    this.loadingService.startLoading(true, '');
    const data = this.taskForm.value;
    data.rescheduleDate = this.adjustDateForTimeOffset(data.rescheduleDate);

    this.taskService.rescheduleTask(data).subscribe({
      next: (response) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, response?.message)
        );
        this.goBack();
        this.closeDialog();
        this.loadingService.stopLoading();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.loadingService.stopLoading();
      },
    });
  }

  adjustDateForTimeOffset(dateToAdjust) {
    let selectedDate = new Date(dateToAdjust);
    const localTimezoneOffset = selectedDate.getTimezoneOffset() * 60000;
    return new Date(selectedDate.getTime() - localTimezoneOffset);
  }

  goBack(): void {
    this.router.navigate([
      '/' +
        SharedConstants.TASK_TRACKER +
        '/' +
        SharedConstants.TASK_TRACKER_List,
    ]);
  }

  closeDialog() {
    this.dialog.closeAll();
  }
}
