import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SecurityRoutingModule } from './security-routing.module';
import { IdCardsPageComponent } from './pages/id-cards-page/id-cards-page.component';
import { SharedModule } from '../shared/shared.module';
import { EmergencyContactsPageComponent } from './pages/emergency-contacts-page/emergency-contacts-page.component';
import { NewIdCardComponent } from './components/new-id-card/new-id-card.component';
import { NewIdCardPageComponent } from './pages/new-id-card-page/new-id-card-page.component';
import { UpdateCardPageComponent } from './pages/update-card-page/update-card-page.component';
import { UpdateCardComponent } from './components/update-card/update-card.component';
import { LostIdCardPageComponent } from './pages/lost-id-card-page/lost-id-card-page.component';
import { LostIdCardComponent } from './components/lost-id-card/lost-id-card.component';
import { AddIdCardComponent } from './components/add-id-card/add-id-card.component';
import { AddIdCardPageComponent } from './pages/add-id-card-page/add-id-card-page.component';
import { SiteAccessInformationComponent } from './components/site-access-Information/site-access-Information.component';
import { CarAccessInformationComponent } from './components/car-access-information/car-access-information.component';
import { RenewIdCardComponent } from './components/renew-id-card/renew-id-card.component';
import { AddNewContactComponent } from './components/add-new-contact/add-new-contact.component';
import { ViewUpdateCardComponent } from './components/view-update-card/view-update-card.component';
import { ViewUpdateCardPageComponent } from './pages/view-update-card-page/view-update-card-page.component';

@NgModule({
  declarations: [
    IdCardsPageComponent,
    EmergencyContactsPageComponent,
    NewIdCardComponent,
    NewIdCardPageComponent,
    UpdateCardPageComponent,
    UpdateCardComponent,
    LostIdCardPageComponent,
    LostIdCardComponent,
    AddIdCardComponent,
    AddIdCardPageComponent,
    SiteAccessInformationComponent,
    CarAccessInformationComponent,
    RenewIdCardComponent,
    AddNewContactComponent,
    ViewUpdateCardComponent,
    ViewUpdateCardPageComponent,
  ],
  imports: [CommonModule, SecurityRoutingModule, SharedModule],
})
export class SecurityModule {}
