<div class="dropdown-header">
  <div class="p-6 bg-primary flex items-center justify-between">
    <div class="body-2 text-primary-contrast">
      <p class="display-1">Notifications</p>
      <div class="text-base">{{ date }}</div>
    </div>
    <div class="flex justify-center align-center">
      <button [matMenuTriggerFor]="filterMenu" mat-icon-button type="button">
        <mat-icon
          class="notifications-header-icon"
          style="color: #ffffff"
          svgIcon="mat:filter_list"
        ></mat-icon>
      </button>
      <button mat-icon-button type="button" (click)="refreshNotification()">
        <mat-icon
          class="notifications-header-icon"
          style="color: #ffffff"
          svgIcon="mat:refresh"
        ></mat-icon>
      </button>
      <button [matMenuTriggerFor]="settingsMenu" mat-icon-button>
        <mat-icon
          class="notifications-header-icon"
          style="color: #ffffff"
          svgIcon="mat:more_vert"
        ></mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-divider></mat-divider>

<div
  *ngIf="messages?.length > 0"
  class="flex flex-col space-y-4 min-w-screen h-screen animated fadeIn faster justify-start items-center inset-0 z-50 outline-none focus:outline-none bg-transparent overflow-auto mx-2 w-96 my-5"
  (scroll)="onScroll($event)"
>
  <div
    [class]="
      message?.isRead
        ? 'flex flex-col p-4 w-11/12 bg-white notification_card shadow-md hover:shodow-lg rounded-2xl'
        : 'flex flex-col p-4 w-11/12 bg-primary/10 notification_card shadow-md hover:shodow-lg rounded-2xl'
    "
    *ngFor="let message of messages"
  >
    <div
      class="flex items-center justify-between cursor-pointer"
      (click)="goToView(message)"
    >
      <div class="flex flex-col">
        <div class="flex items-center">
          <img
            [src]="'assets/img/resources/' + message?.icon"
            [alt]="message?.title"
            class="w-8 h-8 rounded-full text-red-400 bg-red-50"
          />
          <div class="font-black leading-none ml-1 capitalize">
            {{ message?.title }}
          </div>
        </div>
        <div class="flex flex-col ml-3">
          <p
            class="notification_card_description text-sm text-gray-600 leading-none mt-1 ellipsis w-60"
          >
            {{ message?.description }}
          </p>
          <p class="text-xs text-green-600 leading-none mt-1">
            {{ message?.createdAt }}
          </p>
        </div>
      </div>
      <button
        type="button"
        (click)="goToView(message)"
        class="flex-no-shrink px-2 ml-4 py-1 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider rounded-full cursor-pointer"
      >
        <mat-icon svgIcon="mat:double_arrow"></mat-icon>
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="messages?.length === 0"
  class="flex flex-col space-y-4 min-w-screen h-screen animated fadeIn faster justify-start items-center inset-0 z-50 outline-none focus:outline-none bg-transparent overflow-auto mx-2 w-96 mt-5"
>
  <div
    class="flex flex-col p-4 w-11/12 bg-white notification_card shadow-md hover:shodow-lg rounded-2xl"
  >
    <div class="flex items-center justify-between cursor-pointer">
      <div class="flex flex-col">
        <div class="flex items-center">
          <div class="font-black leading-none ml-1 capitalize">
            No any notification
          </div>
        </div>
        <div class="flex flex-col mt-2">
          <p
            class="notification_card_description text-sm text-gray-600 leading-none mt-1"
          >
            Your notifications will be appear here
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <button mat-menu-item type="button" (click)="markAllAsRead()">
    <mat-icon svgIcon="mat:check"></mat-icon>
    <span>Mark all as read</span>
  </button>

  <!-- <button mat-menu-item type="button">
    <mat-icon svgIcon="mat:notifications_off"></mat-icon>
    <span>Disable Notifications</span>
  </button> -->
  <button mat-menu-item type="button" (click)="deleteAllNotifications()">
    <mat-icon svgIcon="mat:delete"></mat-icon>
    <span>Clear notifications</span>
  </button>
</mat-menu>
<mat-menu #filterMenu="matMenu" xPosition="before" yPosition="below">
  <div class="flex justify-between">
    <span class="option-title box center left">
      {{ "Notification.Type" | translate }}
    </span>
    <a
      *ngIf="currentNotificationFilter.length > 0"
      class="clear-filter"
      mat-button
      (click)="onClearFilterClicked($event)"
    >
      {{ "General.Buttons.ClearFilter" | translate }}
    </a>
  </div>
  <mat-checkbox
    class="option"
    color="primary"
    (click)="$event.stopPropagation()"
    *ngFor="let type of NotificationType | enumToArray"
    [value]="type.key"
    [checked]="currentNotificationFilter.includes(type.key)"
    (change)="onChangeType($event, type.key)"
  >
    {{ "Enum.NotificationType." + type.value | translate }}
  </mat-checkbox>
</mat-menu>
