import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { Confirmation, ConfirmationType } from 'src/app/shared/models/confirmation';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { RootCauseService } from 'src/backend/services/root-cause/root-cause.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import { HistoryTypes, ReportingTypes } from 'src/@hodhod/common/enum';
import { enumToArray } from 'src/@hodhod/common/custom_methods';


@Component({
  selector: 'app-add-update-rootCause',
  templateUrl: './add-update-rootCause.component.html',
  styleUrls: ['./add-update-rootCause.component.scss']
})
export class AddUpdateRootCauseComponent implements OnInit {


  rootCauseForm: FormGroup;
  formMode: any;
  private destroy$ = new Subject();
  public translationsList: any = {};
  HistoryType = HistoryTypes.ROOT_CAUSE;
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string;
  reporting: any[] = enumToArray(ReportingTypes).filter(x => x != 'ALL'); 
  toppingList: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AddUpdateRootCauseComponent>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private rootCauseService: RootCauseService,
    private feedBackService: AsyncFeedbackService
  ) {
    this.translate.get(['Errors', 'Success', 'Role', 'User']).pipe(takeUntil(this.destroy$)).subscribe((translations: any) => {
      this.translationsList = translations;
    });
  }

  ngOnInit() {
    if (this.defaults.action === 'update') {
      this.formMode = 'update';
    } else if (this.defaults.action === 'view') {
      this.formMode = 'view';
    } else if (this.defaults.action === 'create') {
      this.formMode = 'create'
      this.defaults.root = {} as any;
    }

    this.rootCauseForm = this.fb.group({
      rootCauseName: [this.defaults.root.rootCauseName || '',
      [Validators.required, noWhitespaceValidator]],
      reportingTypes: ['', Validators.required]
    });
    
    if (this.defaults.root.reportingTypes) {
      this.toppingList = this.defaults.root.reportingTypes.split(',');
      const index = this.toppingList.indexOf('ALL');
      if (index > -1) {
        this.toppingList.splice(index, 1);
      }
      if (this.toppingList.length == this.reporting.length) {
        this.toppingList.push('ALL');
      }
      this.rootCauseForm.get('reportingTypes').setValue(this.toppingList);
    }

  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  selectedvalue(e: any) {
    const typeId = this.rootCauseForm.get('reportingTypes').value;
    
    const types = this.reporting.slice();
    const all = typeId.find(x => x == 'ALL');
    if (all == 'ALL') {
      types.push('ALL');
      this.rootCauseForm.get('reportingTypes').setValue(types);
    } else {
      this.rootCauseForm.get('reportingTypes').setValue('');
    }
  }
  selectAll(e: any) {
    const typeId = this.rootCauseForm.get('reportingTypes').value;
    
    const index = typeId.indexOf('ALL');
    if (index > -1) {
      typeId.splice(index, 1);
    }
    if (typeId.length == this.reporting.length) {
      typeId.push('ALL');
      this.rootCauseForm.get('reportingTypes').setValue(typeId);
    } else {
      this.rootCauseForm.get('reportingTypes').setValue(typeId);
    }
  }

  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createRoot();
          }
        });
    } else if (this.formMode === 'update') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['Role']['UpdateConfirm']['Title'],
            this.translationsList['Role']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.updateRoot();
          }
        });
    }
  }

  createRoot() {
    
    const { rootCauseName ,reportingTypes} = this.rootCauseForm.value;
    let typeId = [];
    const all = reportingTypes.find((x) => x == 'ALL');
    if (all == 'ALL') {
      typeId = this.reporting.filter((a) => a != 'ALL');
    } else {
      typeId = reportingTypes;
    }
    const obj = {
      rootCauseName,
      reportingTypes:typeId.toString(),
      companyId:'2'
    }
    this.loadingLabel = "General.Refreshing";
    this.sectionState = SectionStateStatus.Loading;
    this.rootCauseService.postRootCause(obj).subscribe({
      next: () => {
        const data = {
          rootCauseName: rootCauseName,
          reportingTypes:reportingTypes,
          createdOn: new Date(),
          updatedOn: new Date(),
          isDeleted: false,
          isActive: true,
        };
        this.dialogRef.close(data);
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, this.translationsList['Success']['SuccessMessage'])
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
      }
    });

  }

  updateRoot() {
    const { rootCauseName ,reportingTypes} = this.rootCauseForm.value;
    let typeId = [];
    const all = reportingTypes.find((x) => x == 'ALL');
    if (all == 'ALL') {
      typeId = this.reporting.filter((a) => a != 'ALL');
    } else {
      typeId = reportingTypes;
    }
    const obj = {
      id: this.defaults.root.id,
      rootCauseName: rootCauseName,
      reportingTypes: typeId.toString(),
      companyId:'2'
    }
    this.loadingLabel = "General.Refreshing";
    this.sectionState = SectionStateStatus.Loading;
    this.rootCauseService.updateRootCause(obj).subscribe({
      next: () => {
        this.dialogRef.close();
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Success, this.translationsList['Success']['UpdateMessage'])
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
      }
    });
  }

  isCreateMode() {
    return this.formMode === 'create';
  }

  isUpdateMode() {
    return this.formMode === 'update';
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
