import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { Feature } from 'src/backend/models/features/feature';
import { CompanyService } from 'src/backend/services/company.service';
import { FeatureService } from 'src/backend/services/feature.service';

@Component({
  selector: 'app-update-company',
  templateUrl: './update-company.component.html',
  styleUrls: ['./update-company.component.scss'],
  animations: [fadeInUp400ms],
})
export class UpdateCompanyComponent implements OnInit {
  @ViewChild('uploadLogo') uploadLogo!: TemplateRef<any>;

  public ACTIVE_COLOR = '#67DD2D';
  public INACTIVE_COLOR = '#F76F45';
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public updateCompForm!: FormGroup;
  public compLicenseInfo: any;
  public features: Feature[] = [];
  secFeatures: Feature[] = [];
  sefetyFeatures: Feature[] = [];
  setLicenseQuota: boolean = false;
  public selectedFeatures: Feature[] = [];
  public selectedFeatureCodes: string[] = [];

  private destroy$ = new Subject();
  public translationsList: any = {};
  public loadingLabel: string = 'BuildingCompanyForm';
  public compId: number = 0;
  public compInfo: any;
  public sessionOrgInfo: any;
  public orgParam: string;
  public orgParamid: any;
  public currentQueryParams: any = null;
  croppedImage: any = '';
  croppedImageUrl: any = '';
  imageChangedEvent: any = '';
  isSuperAdmin;

  constructor(
    private formBuilder: FormBuilder,
    private featureService: FeatureService,
    private feedBackService: AsyncFeedbackService,
    private compService: CompanyService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private route: ActivatedRoute,
    private baseApi: BaseApi,
    private router: Router,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog
  ) {
    this.translate
      .get(['Errors', 'Success', 'Company'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.currentQueryParams = this.route.snapshot.queryParams;
    this.updateCompForm = this.formBuilder.group({
      companyCode: ['', [Validators.required, noWhitespaceValidator]],
      companyName: ['', [Validators.required, noWhitespaceValidator]],
      companyImage: [[]],
      description: [''],
      safetyFeatures: [[]],
      securityFeatures: [[]],
      CompQuota: [0],
      primary: ['#131C4E'],
      secondary: ['#ffffff'],
      companyLogo: [[]],
    });
    this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      if (params.has('id')) {
        const Id = params.get('id') as string;
        this.compId = parseInt(Id, 10);
        this.getSessionOrganization();
      }
    });
    const roleIds: (number | string)[] = JSON.parse(
      localStorage.getItem('logged_in_user')
    )['roleIds'];
    this.isSuperAdmin = roleIds.includes(
      '9af4aa46-9587-42d3-81a7-86f54ac9ed30'
    );
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  fileChangeEvent(event: any): void {
    this.openLogoDialog();
    const selectedFile: File = event.target.files[0];
    if (this.validateImageFile(selectedFile)) {
      this.imageChangedEvent = event;
    } else {
      this.updateCompForm.get('companyLogo').invalid;
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          'Invalid file type. Please select an image.'
        )
      );
    }
  }

  openLogoDialog() {
    this.dialog.open(this.uploadLogo, {
      maxHeight: 'auto',
      width: '40%',
      disableClose: false,
    });
  }
  validateImageFile(file: File): boolean {
    return file && file.type.includes('image');
  }
  closeDialog(): void {
    this.dialog.closeAll();
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageUrl = this.sanitizer.bypassSecurityTrustUrl(
      event.objectUrl
    );
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
    this.croppedImage =
      this.croppedImage['changingThisBreaksApplicationSecurity'];
    this.convertBlobToFile(this.croppedImage).then((file) => {
      this.croppedImage = file;
    });
  }
  convertBlobToFile(croppedImage): Promise<File> {
    return new Promise((resolve, reject) => {
      fetch(croppedImage)
        .then((response) => response.blob())
        .then((blob) => {
          const file = new File([blob], 'filename.png', { type: blob.type });
          resolve(file);
        })
        .catch((error) => {
          console.error('Error fetching or creating file:', error);

          // Reject the Promise with the error
          reject(error);
        });
    });
  }

  getSessionOrganization(): void {
    this.route.queryParams.subscribe((params) => {
      this.orgParam = params['org'];
      this.orgParamid = params['id'];
      if (this.orgParam) {
        this.getAllFeatures(this.orgParam);
      } else {
        this.sessionOrgInfo = this.baseApi.getOrganizationFromStorage();
        const orgCode = this.sessionOrgInfo?.orgCode
          ? this.sessionOrgInfo?.orgCode
          : '';
        this.getAllFeatures(orgCode);
      }
    });
  }

  getAllFeatures(orgCode: string): void {
    this.featureService.getFeaturesByOrganization(orgCode).subscribe({
      next: (response) => {
        let security = response?.find(
          (element) => element['module'] === 'Security'
        );
        let safety = response?.find(
          (element) => element['module'] === 'Safety'
        );
        security ? (this.secFeatures = security['features']) : null;
        safety ? (this.sefetyFeatures = safety['features']) : null;
        this.getCompanyById();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  setSelectedFeature(event: any): void {
    const selectedSafetyCodes: string[] =
      this.updateCompForm.value.safetyFeatures;
    const selectedSecCodes: string[] =
      this.updateCompForm.value.securityFeatures;

    const allSafety = selectedSafetyCodes.includes('ALL');
    const allSecurity = selectedSecCodes.includes('ALL');

    this.selectedFeatures = [];

    if (allSafety) {
      this.selectedFeatures = [...this.sefetyFeatures];
    } else {
      this.selectedFeatures = this.sefetyFeatures.filter((x) =>
        selectedSafetyCodes.includes(x.featureCode)
      );
    }
    if (allSecurity) {
      this.selectedFeatures = [...this.selectedFeatures, ...this.secFeatures];
    } else {
      const selectedSecurityFeatures = this.secFeatures.filter((x) =>
        selectedSecCodes.includes(x.featureCode)
      );
      this.selectedFeatures = [
        ...this.selectedFeatures,
        ...selectedSecurityFeatures,
      ];
    }
    this.selectedFeatureCodes = this.selectedFeatures.map(
      (feature) => feature.featureCode
    );
    if (this.selectedFeatureCodes.includes('AMF-012')) {
      this.setLicenseQuota = true;
      this.updateCompForm.get('CompQuota').addValidators([Validators.required]);
    } else {
      this.setLicenseQuota = false;
    }
  }
  getCompanyById(): void {
    this.sectionState = SectionStateStatus.Loading;
    this.compService.getCompanyDetails(this.compId).subscribe({
      next: (response) => {
        this.compInfo = response;
        this.builForm(response);
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  cancelDialog() {
    this.croppedImageUrl = '';
    this.dialog.closeAll();
  }
  updateCompany(): void {
    const {
      companyName,
      description,
      features,
      companyImage,
      CompQuota,
      primary,
      secondary,
      companyLogo,
    } = this.updateCompForm.value;

    const formData = new FormData();
    formData.append(
      'file',
      companyImage[0] === undefined ? null : companyImage[0]
    );
    if (this.croppedImage) {
      formData.append('CompanyLogo', this.croppedImage);
    }

    const companyData = {
      companyId: this.compId,
      companyName,
      description,
      features: this.selectedFeatureCodes,
      oldImage: this.compInfo?.companyImage,
      themeColor: primary,
      CompQuota,
      secondaryColor: secondary,
    };

    formData.append('content', JSON.stringify(companyData));

    this.loadingLabel = 'UpdatingCompany';
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Company']['UpdateConfirm']['Title'],
          this.translationsList['Company']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.compService.editCompany(formData).subscribe({
            next: (respose) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, respose?.message)
              );
              if (this.orgParam) {
                this.router.navigate([
                  '/' +
                    SharedConstants.ORGANIZATION_SETUP +
                    '/' +
                    SharedConstants.ORGANIZATION_VIEW +
                    '/' +
                    this.orgParamid,
                ]);
              } else {
                this.router.navigate([
                  '/' +
                    SharedConstants.COMPANY_SETUP +
                    '/' +
                    SharedConstants.COMPANIES,
                ]);
              }
            },
            error: ({ error }) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
  }
  builForm(response: any): void {
    this.updateCompForm.get('companyCode')?.setValue(response?.companyCode);
    this.updateCompForm.get('companyName')?.setValue(response?.companyName);
    this.updateCompForm.get('companyImage')?.setValue([]);
    this.updateCompForm.get('description')?.setValue(response?.description);
    response?.themeColor
      ? this.updateCompForm.get('primary')?.setValue(response?.themeColor)
      : null;
    response?.secondaryColor
      ? this.updateCompForm.get('secondary')?.setValue(response?.secondaryColor)
      : null;
    this.updateCompForm.get('companyLogo').setValue([]);

    let safetyCodes = response?.features?.find(
      (element) => element['module'] === '0'
    );
    let secCodes = response?.features?.find(
      (element) => element['module'] === '1'
    );
    if (safetyCodes) {
      const selectedSafetyFeatures = safetyCodes['features'];
      safetyCodes = safetyCodes['features'].map(
        (feature) => feature.featureCode
      );
      this.updateCompForm.get('safetyFeatures')?.setValue(safetyCodes);
      this.selectedFeatures = [...selectedSafetyFeatures];
    }
    if (secCodes) {
      const selectedSecurityFeatures = secCodes['features'];
      secCodes = secCodes['features'].map((feature) => feature.featureCode);
      this.updateCompForm.get('securityFeatures')?.setValue(secCodes);
      this.selectedFeatures = [
        ...(this.selectedFeatures || []),
        ...selectedSecurityFeatures,
      ];
    }
    this.selectedFeatureCodes = [...(secCodes || []), ...(safetyCodes || [])];

    if (this.selectedFeatureCodes.includes('AMF-012')) {
      this.getCompCardInfo();
      this.setLicenseQuota = true;
      this.updateCompForm.get('CompQuota').addValidators([Validators.required]);
    } else {
      this.setLicenseQuota = false;
    }
  }
  getCompCardInfo(): void {
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.compService.getCompCardDetails(this.compId).subscribe({
      next: (response) => {
        this.compLicenseInfo = response['message'];
        if (response['message']?.accessControlLicenseQuota !== null) {
          this.updateCompForm
            .get('CompQuota')
            ?.setValue(response['message']?.accessControlLicenseQuota);
          this.setLicenseQuota = true;
        }
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Error;
      },
    });
  }
  cancel(id): void {
    let { org } = this.currentQueryParams;

    this.router.navigate(
      [
        '/' +
          SharedConstants.ORGANIZATION_SETUP +
          '/' +
          SharedConstants.ORGANIZATION_VIEW +
          '/' +
          org,
      ],
      { queryParams: { ...this.currentQueryParams } }
    );
  }
}
