import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { map, Observable } from 'rxjs';
import { ConfigService } from 'src/@hodhod/config/config.service';
import { HodhodConfig } from 'src/@hodhod/config/hodhod-config.interface';
import { LayoutService } from 'src/@hodhod/services/layout.service';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})

export class SnackBarComponent {

  config$: Observable<HodhodConfig> = this.configService.config$;

  isRTL$: Observable<boolean> = this.config$.pipe(
    map(config => config.direction === 'rtl')
  );
  constructor(
    private configService: ConfigService,
    public snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any) { }
}
