import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionGuard } from '../shared/guards/permission.guard';
import { ApplicationPermission } from '../shared/models/application-permission';
import { SharedConstants } from '../shared/models/shared-constants';
import { AddWorkPermitPageComponent } from './pages/add-work-permit-page/add-work-permit-page.component';
import { EditViewWorkPermitPageComponent } from './pages/edit-view-work-permit-page/edit-view-work-permit-page.component';
import { HazardsPageComponent } from './pages/hazards-page/hazards-page.component';
import { WorkConditionPageComponent } from './pages/work-condition-page/work-condition-page.component';
import { WorkPermitMasterPageComponent } from './pages/work-permit-master-page/work-permit-master-page.component';
import { WorkPermitPageComponent } from './pages/work-permit-page/work-permit-page.component';
import { MultiPermissionGuard } from '../shared/guards/MultiPermission.guard';

const routes: Routes = [
  {
    path: SharedConstants.WORK_PERMIT_LIST,
    component: WorkPermitPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.WORK_PERMIT_LIST_ACCESS,
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'WorkPermit',
      },
    }
    
  },
  {
    path: SharedConstants.WORK_PERMIT_MASTER,
    component: WorkPermitMasterPageComponent,
    canActivate: [MultiPermissionGuard],
    data: {
      permissions: [ApplicationPermission.WORK_CONDITIONS_ACCESS,ApplicationPermission.WORK__PERMIT_HAZARDS_VIEW],
      breadcrumb: {
        isFirstLevel: true,
        labelKey: 'WorkPermitMaster',
      },
    }
    
  },
  {
    path: SharedConstants.WORK_PERMIT_HAZARDS,
    component: HazardsPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.WORK_PERMIT_HAZARDS_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'WorkPermitHazards',
        isActive: true
      },
    }    
  },
  {
    path: SharedConstants.WORK_PERMIT_WORK_CONDITIONS,
    component: WorkConditionPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.WORK_PERMIT_WORK_CONDITIONS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'WorkPermitWorkConditions',
        isActive: true
      },
    }
    
  },
  {
    path: SharedConstants.WORK_PERMIT_LIST + '/' + SharedConstants.ADD_WORK_PERMIT,
    component: AddWorkPermitPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.WORK_PERMIT_LIST_CREATE,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'AddWorkPermit',
        isActive: true
      },
    }    
  },
  {
    path: SharedConstants.WORK_PERMIT_LIST + '/' + SharedConstants.VIEW_EDIT_WORK_PERMIT + '/:id',
    component: EditViewWorkPermitPageComponent,
    canActivate: [PermissionGuard],
    data: {
      permissions: ApplicationPermission.WORK_PERMIT_LIST_ACCESS,
      breadcrumb: {
        isFirstLevel: false,
        labelKey: 'View',
        isActive: true
      },
    }    
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkPermitRoutingModule { }
