import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { fadeInUp400ms } from 'src/@hodhod/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@hodhod/animations/scale-fade-in.animation';
import { stagger40ms } from 'src/@hodhod/animations/stagger.animation';
import { Constants } from 'src/@hodhod/common/constants';
import { GeneralStatus, SortDirection } from 'src/@hodhod/common/enum';
import { TableColumn } from 'src/@hodhod/interfaces/table-column.interface';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { PermissionService } from 'src/backend/services/permission.service';
import { PpeService } from 'src/backend/services/ppe-services/ppe.service';
import { PPE } from '../../models/ppe';
import { ActivatedRoute, Router } from '@angular/router';
@UntilDestroy()
@Component({
  selector: 'app-ppe-list',
  templateUrl: './ppe-list.component.html',
  styleUrls: ['./ppe-list.component.scss'],
  animations: [fadeInUp400ms, stagger40ms, scaleFadeIn400ms],
})
export class PpeListComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @ViewChild('ppeCreateUpdateModal') ppeCreateUpdateModal!: TemplateRef<any>;
  ppeForm: FormGroup;
  formMode: 'create' | 'update' | 'view' = 'create';

  ppes: PPE[];
  columns: TableColumn<PPE>[] = [
    {
      label: ' ',
      property: 'imageUrl',
      type: 'image',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'ppe',
      property: 'ppeName',
      type: 'text',
      visible: true,
      cssClasses: ['font-medium'],
    },
    {
      label: 'Created At',
      property: 'createdOn',
      type: 'date',
      visible: false,
    },
    {
      label: 'Updated At',
      property: 'updatedOn',
      type: 'date',
      visible: true,
      cssClasses: ['text-secondary', 'font-medium'],
    },
    { label: 'Status', property: 'status', type: 'boolean', visible: true },
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
  ];
  ppeDetails: any;
  pageSize = Constants.PAGE_SIZE;
  pageSizeOptions: number[] = Constants.PAGE_OPTIONS;
  dataSource = new MatTableDataSource<PPE>([]);
  selection = new SelectionModel<PPE>(true, []);
  searchCtrl = new UntypedFormControl();
  searchValue: string = '';
  timer = null;
  loadingLabel: string = '';
  ppeId: number = 0;
  selectedImage: any;
  PpeStatuses = GeneralStatus;
  SharedConstants = SharedConstants;

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  public sectionStateModal: SectionStateStatus = SectionStateStatus.Ready;
  public selectedPpesStatus: string[] = [];

  private translationsList: any = {};

  hasViewPermissions = ApplicationPermission.PPE_VIEW;
  hasWritePermissions = ApplicationPermission.PPE_CREATE;
  hasEditPermissions = ApplicationPermission.PPE_UPDATE;
  hasActivatePermissions = ApplicationPermission.PPE_ACTIVATE;
  hasDeletePermission = ApplicationPermission.PPE_DELETE;
  currentQueryParams = null;
  pagination = {
    pageIndex: 0,
    pageSize: 10,
    length: 0,
  };
  constructor(
    private translate: TranslateService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private ppeService: PpeService,
    private feedBackService: AsyncFeedbackService,
    private confirmationService: ConfirmationService,
    private permissionService: PermissionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
    this.translate
      .get([
        'Errors',
        'Success',
        'confirmDeactiveRecord',
        'Role',
        'User',
        'StaticPages',
      ])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  override ngOnInit(): void {
    this.ppeForm = this.fb.group({
      ppeName: ['', [Validators.required]],
      imageUrl: [''],
      file: [[]],
    });
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;
    this.currentQueryParams = this.route.snapshot.queryParams;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.searchCtrl.setValue(this.currentQueryParams?.searchValue);

    this.pagination.pageIndex = this.currentQueryParams?.pageIndex
      ? Number(this.currentQueryParams?.pageIndex)
      : 0;
    this.pagination.pageSize = this.currentQueryParams?.pageSize
      ? Number(this.currentQueryParams?.pageSize)
      : 10;

    this.selectedPpesStatus = this.currentQueryParams?.orgStatus
      ? this.currentQueryParams?.orgStatus.split(',')
      : [];

    this.columns.forEach((item) => {
      if (this.currentQueryParams[item.property] === 'true') {
        item.visible = true;
      } else if (this.currentQueryParams[item.property] === 'false') {
        item.visible = false;
      }
    });

    this.sort.active = this.currentQueryParams?.sortName;
    this.sort.direction = this.currentQueryParams?.sortDirection;
    this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      this.currentQueryParams = {
        ...this.currentQueryParams,
        sortName: res.active,
        sortDirection: res.direction,
        pageIndex: 0,
      };
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.currentQueryParams,
      });
      this.pagination.pageIndex = 0;
      this.getData();
    });

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: any) => this.onFilterChange(value));
    this.getData();
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }
  enableEdit() {
    this.formMode = 'update';
    this.ppeForm.enable();
  }
  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      [column.property]: column.visible,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
  }
  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  getData() {
    let sortDirection = SortDirection.None;
    if (this.sort.direction) {
      if (this.sort.direction === 'asc') {
        sortDirection = SortDirection.Ascending;
      } else {
        sortDirection = SortDirection.Descending;
      }
    }
    let sortField = null;
    if (this.sort.active) {
      sortField = this.sort.active;
    }
    const filteredParams = {
      sortDirection: this.currentQueryParams?.sortDirection || '',
      sortField,
      pageIndex: this.currentQueryParams?.pageIndex
        ? this.currentQueryParams?.pageIndex
        : this.pagination.pageIndex,
      pageSize: this.currentQueryParams?.pageSize
        ? this.currentQueryParams?.pageSize
        : this.pagination.pageSize,
      searchValue: this.currentQueryParams?.searchValue
        ? this.currentQueryParams?.searchValue
        : this.searchValue,
      status: this.currentQueryParams?.orgStatus,
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.ppeService.getAllPpes(filteredParams).subscribe({
      next: (response) => {
        this.ppes = response?.data;
        this.dataSource = new MatTableDataSource(response?.data);
        this.pagination.length = response?.totalCount;
        this.dataSource.sort = this.sort;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  onFileSelected(event: any): void {
    const file = this.ppeForm.get('file').value[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
  openCreatePpeModal() {
    this.formMode = 'create';
    this.resetForm();
    this.dialog.open(this.ppeCreateUpdateModal, {
      height: 'auto',
      width: '60%',
      disableClose: true,
    });
  }

  createPpe() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.Loading;
          const ppe = {
            ppeName: this.ppeForm.value.ppeName,
            imageUrl: '',
          };
          const formData = new FormData();
          formData.append('content', JSON.stringify(ppe));
          formData.append(
            'file',
            this.ppeForm.get('file').value
              ? this.ppeForm.get('file').value[0]
              : ''
          );

          this.ppeService.createPpe(formData).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  removePic(event) {
    event.stopPropagation();
    this.selectedImage = '';
  }
  updatePpe() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Role']['UpdateConfirm']['Title'],
          this.translationsList['Role']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionStateModal = SectionStateStatus.Loading;
          const ppe = {
            id: this.ppeId,
            ppeName: this.ppeForm.value.ppeName,
            imageUrl: '',
          };
          const formData = new FormData();
          formData.append('content', JSON.stringify(ppe));
          formData.append(
            'file',
            this.ppeForm.get('file')?.value
              ? this.ppeForm.get('file').value[0]
              : ''
          );
          this.ppeService.updatePpe(formData).subscribe({
            next: (res) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
              this.closeDialog();
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionStateModal = SectionStateStatus.Ready;
            },
          });
        }
      });
  }
  viewPpe(id) {
    this.formMode = 'view';
    this.resetForm();
    this.ppeForm.disable();
    this.ppeId = id;
    this.ppeForm
      .get('ppeName')
      ?.setValue(this.ppes.find((x) => x.id === id).ppeName);
    this.dialog.open(this.ppeCreateUpdateModal, {
      height: 'auto',
      width: '60%',
      disableClose: true,
    });
    this.ppeDetails = this.ppes.find((x) => x.id === id);
    const ppeCopy = { ...this.ppeDetails };
    this.selectedImage = ppeCopy.imageUrl;
  }

  activatePpe(id) {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.ppeService.activatePpe(id).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  deactivatePpe(id) {
    // checking permissions
    if (
      !this.permissionService.isUserHasPermission(this.hasActivatePermissions)
    ) {
      this.feedBackService.showFeedback(
        new FeedbackModel(
          FeedbackType.Failure,
          this.translationsList['StaticPages']['AccessDenied']['Title']
        )
      );
      return;
    }
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.DestructiveAction,
          this.translationsList['User']['AddConfirm']['Title'],
          this.translationsList['User']['AddConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.loadingLabel = 'General.Refreshing';
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.ppeService.deActivatePpe(id).subscribe({
            next: (res) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, res?.message)
              );
              this.getData();
            },
            error: ({ error }) => {
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
              this.sectionState = SectionStateStatus.Ready;
            },
          });
        }
      });
  }

  onSelectStatus(event) {
    this.selectedPpesStatus = event.value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      orgStatus: event.value.toString(),
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    this.searchValue = value;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      searchValue: this.searchValue,
      pageIndex: 0,
    };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.pagination.pageIndex = 0;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getData();
    }, 700);
  }

  resetForm(): void {
    this.ppeForm.reset();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  handlePageEvent(page) {
    this.pagination.pageIndex = page.pageIndex;
    this.pagination.pageSize = page.pageSize;
    this.pagination.length = page.length;
    this.currentQueryParams = {
      ...this.currentQueryParams,
      pageIndex: this.pagination.pageIndex,
      pageSize: this.pagination.pageSize,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: this.currentQueryParams,
    });
    this.getData();
  }
}
