<app-state-section
  [flexibleHeight]="true"
  [state]="sectionState"
  [loadingLabel]="
    (formMode === 'create'
      ? 'CheckList.AddingLoadingChecklist'
      : 'CheckList.UpdatingLoadingChecklist'
    ) | translate
  "
  [errorLabel]="'Errors.ErrorHappened' | translate"
>
  <form [formGroup]="checklistForm">
    <div class="flex items-center" mat-dialog-title>
      <h2
        *ngIf="checklistForm.get('checklistName').value"
        class="headline m-0 flex-auto"
      >
        {{ checklistForm.get("checklistName").value }}
      </h2>
      <h2
        *ngIf="!checklistForm.get('checklistName').value"
        class="headline m-0 flex-auto"
      >
        {{ "CheckList.AddChecklist" | translate }}
      </h2>
      <button
        class="text-secondary"
        mat-dialog-close
        mat-icon-button
        type="button"
      >
        <mat-icon svgIcon="mat:close"></mat-icon>
      </button>
    </div>

    <mat-divider class="-mx-6 text-border mb-2"></mat-divider>

    <mat-dialog-content class="flex column justify-between">
      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "CheckList.ChecklistName" | translate }}
          <span
            [ngClass]="
              checklistForm.invalid &&
              checklistForm.get('checklistName').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [placeholder]="'Placeholder.AddText' | translate"
            matInput
            type="text"
            formControlName="checklistName"
          />
          <mat-error
            *ngIf="
              checklistForm.invalid &&
              checklistForm.get('checklistName').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error
            *ngIf="
              checklistForm.invalid &&
              checklistForm.get('checklistName').hasError('whitespace')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.InValidData" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="field-full flex column">
        <mat-label class="field-label"
          >{{ "CheckList.ChecklistType" | translate }}
          <span
            [ngClass]="
              checklistForm.invalid &&
              checklistForm.get('checklisttype').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <mat-select
            formControlName="checklisttype"
            [placeholder]="'CheckList.ChecklistType' | translate"
          >
            <!-- <mat-option
              *ngFor="let type of ChecklistType | enumToArray"
              [value]="type.value"
            >
              {{ "Enum.ChecklistType." + type.value | translate }}
            </mat-option> -->
            <mat-option
              [value]="'Audit'"
              *ngIf="hasAuditCreatePermission | permissionChecker"
              >{{ "Enum.ChecklistType.Audit" | translate }}</mat-option
            >
            <mat-option
              [value]="'WorkCondition'"
              *ngIf="hasWorkConditionsCreatePermission | permissionChecker"
              >{{ "Enum.ChecklistType.WorkCondition" | translate }}</mat-option
            >
            <mat-option
              [value]="'Inspection'"
              *ngIf="hasInspectionCreatePermission | permissionChecker"
              >{{ "Enum.ChecklistType.Inspection" | translate }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              checklistForm.invalid &&
              checklistForm.get('checklisttype').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div
        class="field-full flex column pb-2"
        *ngIf="checklistForm.get('checklisttype').value === 'Audit'"
      >
        <mat-label class="field-label"
          >{{ "General.MinimumScore" | translate }}
          <span
            [ngClass]="
              checklistForm.invalid &&
              checklistForm.get('minimumScore').hasError('required')
                ? 'is-invalid'
                : 'is-valid'
            "
            >*</span
          >
        </mat-label>
        <mat-form-field class="input-field border-t-0" appearance="outline">
          <input
            [placeholder]="'Placeholder.EnterValue' | translate"
            matInput
            type="number"
            step="0.1"
            oninput="validity.valid||(value='');"
            formControlName="minimumScore"
            [min]="0"
            [max]="100"
          />
          <mat-error
            *ngIf="
              checklistForm.invalid &&
              checklistForm.get('minimumScore').hasError('required')
            "
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
          <mat-error *ngIf="checklistForm.controls['minimumScore'].errors">
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.valueLimit" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <!-- <div class=" flex column">
        <app-add-checklist-section></app-add-checklist-section>
      </div> -->
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'Cancel'"
          (onClick)="closeDialog()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isCreateMode()"
          [btnType]="'Add'"
          [btnText]="'Add'"
          [isDisabled]="!checklistForm.valid"
          (onClick)="save()"
        ></app-basic-button>
        <app-basic-button
          *ngIf="isUpdateMode()"
          [btnType]="'Add'"
          [btnText]="'Save'"
          [isDisabled]="!checklistForm.valid"
          (onClick)="save()"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </form>
</app-state-section>
