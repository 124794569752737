<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.Categories' | translate">
    <hodhod-breadcrumbs
      class="flex-auto"
    ></hodhod-breadcrumbs> </hodhod-secondary-toolbar
  >
  <hodhod-page-layout-content class="container-fluid">
    <app-view-category-details [page]="'review'"></app-view-category-details>
  </hodhod-page-layout-content>
</hodhod-page-layout>
