<!-- auto-levels-form.component.html -->
<div>
  <div class="flex mt-2 mb-2">
    <!-- <h1>{{ "Dashboards.SafetyTriangle" | translate }}</h1> -->
    <div
      class="w-50 text-center"
      class="status"
      *ngIf="safetyTriangleData?.mode == 'AUTO'"
    >
      <span>Active Mode</span>
    </div>
  </div>

  <div class="flex justify-between">
    <div class="w-4/12" *ngIf="repeatlevelsFormGroup?.controls?.length > 0">
      <form [formGroup]="levelsForm">
        <div
          formArrayName="levels"
          *ngIf="repeatlevelsFormGroup?.controls.length"
        >
          <ol>
            <li
              *ngFor="
                let levelCtrl of repeatlevelsFormGroup?.controls;
                let i = index
              "
            >
              <div [formGroupName]="i">
                <div class="flex items-center">
                  <div class="field-full flex column m-1">
                    <mat-label *ngIf="i < 3">
                      {{ i + 1 }}{{ i === 0 ? "st" : i === 1 ? "nd" : "rd" }}
                      level
                    </mat-label>
                    <mat-label class="field-label" *ngIf="i >= 3">
                      {{ i + 1 + "th level" }}
                    </mat-label>
                    <div class="flex items-center">
                      <mat-form-field class="input-field" appearance="outline">
                        <mat-select
                          [disabled]="i == 0 || i == 1"
                          #select
                          (selectionChange)="onSelectClassification($event, i)"
                          [placeholder]="
                            i == 0
                              ? 'Total Reported Hazards'
                              : i == 1
                              ? 'Total Reported Nearmisses'
                              : i == 2
                              ? 'Total Reported Incidents'
                              : ('Placeholder.Select' | translate)
                          "
                          formControlName="incidentClassificationId"
                        >
                          <mat-option
                            *ngFor="let ic of incidentClassifications"
                            [value]="ic?.id"
                            [disabled]="checkClassification(ic?.id)"
                            >{{ ic?.name }}</mat-option
                          >
                        </mat-select>
                        <mat-error
                          *ngIf="
                            levelsForm.invalid &&
                            levelCtrl
                              .get('incidentClassificationId')
                              .hasError('required')
                          "
                        >
                          <div
                            class="flex justify-flex-start align-flex-center"
                          >
                            <img
                              class="error-img"
                              src="assets/img/resources/error-icon.svg"
                            />
                            <span>{{ "Errors.Required" | translate }}</span>
                          </div>
                        </mat-error>
                      </mat-form-field>
                      <button
                        *ngIf="i !== 0 && i !== 1 && i !== 2"
                        color="primary"
                        (click)="removeLevel(i)"
                        mat-icon-button
                        [matTooltip]="'General.Buttons.Remove' | translate"
                        type="button"
                      >
                        <mat-icon svgIcon="mat:cancel"></mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ol>
        </div>
      </form>
      <div class="flex justify-end">
        <button
          *ngIf="numOfLevels <= 6"
          (click)="onAddLevel()"
          class="ml-4 flex-none"
          color="primary"
          mat-mini-fab
          [matTooltip]="'Placeholder.AddLevel' | translate"
          type="button"
        >
          <mat-icon svgIcon="mat:add"></mat-icon>
        </button>
      </div>
      <div>
        <div class="flex items-center mb-2">
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="navigateBack()"
          ></app-basic-button>
          <app-basic-button
            [btnType]="'Add'"
            [btnText]="safetyTriangleData?.mode == 'AUTO' ? 'Save' : 'Enable'"
            [isDisabled]="levelsForm.invalid"
            (onClick)="openConfirmationPopup()"
          ></app-basic-button>
        </div>
      </div>
    </div>
    <div *ngIf="data?.length > 0">
      <app-safety-triangle [chartData]="data"></app-safety-triangle>
    </div>
  </div>
</div>
<ng-template #historicalConfirmation class="text-center">
  <div>
    <h1>Do you have any Historical values?</h1>
    <mat-dialog-actions align="end">
      <div class="flex items-center">
        <app-basic-button
          [btnType]="'Cancel'"
          [btnText]="'No'"
          (onClick)="updateSafetyTriangle(safetyTriangleData?.id)"
        ></app-basic-button>
        <app-basic-button
          [btnType]="'Add'"
          [btnText]="'Yes'"
          (onClick)="openhistoricalPopup()"
        ></app-basic-button>
      </div>
    </mat-dialog-actions>
  </div>
</ng-template>
<ng-template #historicalValues>
  <form [formGroup]="levelsForm" class="align-middle text-left p-1">
    <div formArrayName="levels">
      <ol>
        <li
          *ngFor="
            let levelCtrl of repeatlevelsFormGroup.controls;
            let i = index
          "
        >
          <div [formGroupName]="i">
            <div class="flex items-center">
              <div class="field-full flex column m-1">
                <mat-label *ngIf="i < 3"
                  >{{ i + 1
                  }}{{
                    i === 0 ? "st" : i === 1 ? "nd" : "rd"
                  }}
                  level</mat-label
                >
                <mat-label class="field-label" *ngIf="i >= 3">{{
                  i + 1 + "th level"
                }}</mat-label>
                <div class="flex items-center">
                  <mat-form-field
                    class="input-field w-full m-1"
                    appearance="outline"
                  >
                    <input
                      [max]="10000000"
                      oninput="validity.valid||(value='');"
                      formControlName="historicalValue"
                      [placeholder]="
                        i === 0
                          ? 'Total Hazards'
                          : i === 1
                          ? 'Total Nearmiss'
                          : i === 2
                          ? 'Total Incidents'
                          : levelCtrl.value['incidentClassificationName']
                      "
                      matInput
                      type="number"
                    />
                    <mat-error
                      *ngIf="
                        levelsForm.invalid &&
                        levelCtrl.get('historicalValue').hasError('required')
                      "
                    >
                      <div class="flex justify-flex-start align-flex-center">
                        <img
                          class="error-img"
                          src="assets/img/resources/error-icon.svg"
                        />
                        <span>{{ "Errors.Required" | translate }}</span>
                      </div>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ol>
      <div>
        <div class="flex items-center justify-center">
          <app-basic-button
            class="ml-0"
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="closeDialog()"
          ></app-basic-button>
          <app-basic-button
            [btnType]="'Add'"
            [btnText]="'Save'"
            (onClick)="updateSafetyTriangle(safetyTriangleData?.id)"
          ></app-basic-button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
