<div class="flex justify-end mr-1 mb-4" *ngIf="data">
  <app-basic-button
    [btnType]="'Print text-sm'"
    [btnText]="'Print'"
    [isDisabled]="false"
    (onClick)="printWorkPermitReport()"
  >
  </app-basic-button>
  <app-basic-button
    *ngIf="
      data?.extendDateDetail?.status === 'PENDING' &&
      (data?.status === WorkPermitStatuses.APPROVED ||
        data?.status === WorkPermitStatuses.INPROGRESS) &&
      isUserCan &&
      data?.isExtendTimeRequired
    "
    [btnType]="'Add text-sm'"
    [btnText]="'ExtendedRequest'"
    [isDisabled]="false"
    (onClick)="viewExtendDateModel()"
  >
  </app-basic-button>
  <app-basic-button
    *ngIf="
      data?.extendDateDetail?.status !== 'PENDING' &&
      (data?.status === WorkPermitStatuses.APPROVED ||
        data?.status === WorkPermitStatuses.INPROGRESS) &&
      data?.requestorId === loggedUser?.userId &&
      data?.isExtendTimeRequired
    "
    [btnType]="'Add text-sm'"
    [btnText]="'ExtendDate'"
    [isDisabled]="false"
    (onClick)="openExtendDateModel()"
  >
  </app-basic-button>
  <app-basic-button
    *ngIf="
      (hasApprovePermissions | permissionChecker) &&
      (data?.status === WorkPermitStatuses.NEW ||
        data?.status === WorkPermitStatuses.INPROGRESS) &&
      isUserCan &&
      !isUserAlreadyApproved
    "
    [btnType]="'Approve text-sm'"
    [btnText]="'Approve'"
    [isDisabled]="false"
    (onClick)="openApproveModel()"
  >
  </app-basic-button>
  <app-basic-button
    *ngIf="
      (hasApprovePermissions | permissionChecker) &&
      (data?.status === WorkPermitStatuses.NEW ||
        data?.status === WorkPermitStatuses.INPROGRESS) &&
      isUserCan &&
      !isUserAlreadyApproved
    "
    [btnType]="'Delete text-sm'"
    [btnText]="'Reject'"
    [isDisabled]="false"
    (onClick)="openRejectModel()"
  >
  </app-basic-button>
  <app-basic-button
    *ngIf="
      (data?.status === WorkPermitStatuses.APPROVED ||
        data?.status === WorkPermitStatuses.INPROGRESS) &&
      checkUserCanCloseRequest() &&
      data?.isClosingRequestSent === true
    "
    [btnType]="'Delete text-sm'"
    [btnText]="'Close'"
    [isDisabled]="false"
    (onClick)="openCloseModal()"
  >
  </app-basic-button>
  <app-basic-button
    *ngIf="
      (data?.status === WorkPermitStatuses.APPROVED ||
        data?.status === WorkPermitStatuses.INPROGRESS) &&
      data?.requestorId === loggedUser?.userId &&
      data?.isClosingRequestSent === false &&
      (hasApprovePermissions | permissionChecker)
    "
    [btnType]="'Delete text-sm'"
    [btnText]="'CloseExtend'"
    [isDisabled]="false"
    (onClick)="openCloseWorkPermitRequest()"
  >
  </app-basic-button>
</div>
<app-expandable-panel
  [expanded]="true"
  #expandablePanel
  [title]="'WorkPermit.WorkPermitInfo' | translate"
>
  <div body class="flex justify-between flex-wrap-reverse" *ngIf="data">
    <div class="flex-1">
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.code }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.Code" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>
        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.title }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.Form.Title" | translate }}
          </p>
        </div>
      </div>

      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">
            {{
              data?.departmentParentName
                ? data?.departmentParentName
                : data?.department
            }}
          </p>
          <p class="m-0 caption text-hint">
            {{ "ReportHazardList.Table.Department" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center" *ngIf="data?.departmentParentName">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.department }}</p>
          <p class="m-0 caption text-hint">
            {{ "ReportHazardList.Table.SubDepartment" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.noOfWorkers }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.NoOfWorkers" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>
        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.startDate | date : "mediumDate" }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.StartDate" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.endDate | date : "mediumDate" }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.EndDate" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>
        <div @fadeInRight>
          <p class="m-0 body-1">{{ getInitialApproversString() }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.WorkPermitApprover" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.plannedActivityDescription }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.PlannedActivityDescription" | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div @fadeInUp class="flex items-center">
        <p class="text-left font-bold text-primary mr-4 rtl:ml-3">
          {{ "General.Status" | translate }}
        </p>
        <div class="w-28 text-center" [attr.id]="data?.status">
          <span>{{ "Enum.WorkPermitStatus." + data?.status | translate }}</span>
        </div>
      </div>

      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.startTime }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.StartTime" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.endTime }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.EndTime" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.requestor }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.View.Table.RequestedBy" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.updatedOn | date : "mediumDate" }}</p>
          <p class="m-0 caption text-hint">
            {{ "ReportHazardList.Table.UpdateAt" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>
        <div @fadeInRight>
          <p class="m-0 body-1">{{ getAdditionalApproversString() }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.AdditionalApprovers" | translate }}
          </p>
        </div>
      </div>
      <div class="py-3 flex items-center">
        <div
          @scaleIn
          class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
        >
          <mat-icon
            class="icon-sm"
            svgIcon="mat:keyboard_double_arrow_right"
          ></mat-icon>
        </div>

        <div @fadeInRight>
          <p class="m-0 body-1">{{ data?.equipmentDescription }}</p>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.Form.EquipmentDescription" | translate }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div body class="flex flex-wrap gap-2 mt-3" *ngIf="data">
    <ngx-avatars
      *ngFor="let img of data?.images"
      [src]="getAttachmentImage(img.imageUrl)"
      [name]="getAttachementTypeName(img.imageUrl)"
      [textSizeRatio]="2"
      [round]="false"
      [initialsSize]="2"
      [cornerRadius]="10"
      [matTooltip]="img.fileName"
      [borderColor]="'#131C4E'"
      (clickOnAvatar)="openBigView(img.id)"
    >
    </ngx-avatars>
  </div>
</app-expandable-panel>

<div class="mt-5" *ngIf="data">
  <app-expandable-panel
    [expanded]="true"
    #expandablePanel
    [title]="'WorkPermit.Form.Ppe' | translate"
  >
    <div body>
      <div class="py-3">
        <div @fadeInRight class="flex flex-wrap">
          <div
            class="flex border border-gray-200 p-1 ms-0 w-1/6 rounded-full items-center m-2 "
            *ngFor="let item of ppes"
          >
            <ngx-avatars
              class="logo box"
              size="38"
              [src]="item?.imageUrl"
              [name]="item?.ppeName"
              [textSizeRatio]="2"
              [round]="true"
              [initialsSize]="2"
            ></ngx-avatars>
            <p class="text-left ps-2 overflow-hidden">{{ item?.ppeName }}</p>
          </div>
        </div>
      </div>
    </div>
  </app-expandable-panel>
</div>
<div class="mt-5" *ngIf="data">
  <app-expandable-panel
    [expanded]="true"
    #expandablePanel
    [title]="'WorkPermit.WorkerInfo' | translate"
  >
    <div body class="flex column">
      <div class="flex flex-wrap-reverse items-center elements">
        <div
          class="info_dark element flex center"
          *ngFor="let info of data?.workerInfos"
        >
          <p>
            <strong class="text-primary">{{
              "WorkPermit.WorkerName" | translate
            }}</strong>
            <br />
            {{ info?.workerName }}
          </p>
          <p>
            <strong class="text-primary">{{
              "WorkPermit.WorkerRole" | translate
            }}</strong>
            <br />
            {{ info?.workerRole }}
          </p>
        </div>
      </div>
    </div>
  </app-expandable-panel>
</div>
<!-- Actions -->
<div
  class="mt-4"
  *ngIf="
    data &&
    data?.status !== 'NEW' &&
    data?.status !== 'REJECTED' &&
    hasViewPermissions
  "
>
  <app-action
    [sourceId]="data?.id"
    [sourceType]="ReportingType?.workPermit"
    (onCreateAction)="onCreateAction($event)"
  ></app-action>
</div>
<div class="mt-5" *ngIf="data">
  <app-expandable-panel
    class="panal-overflow-auto"
    [expanded]="true"
    #expandablePanel
    [title]="'WorkPermit.JobSafety' | translate"
  >
    <div body>
      <table
        class="w-full"
        [dataSource]="jobSafetyDataSource"
        mat-table
        matSort
      >
        <ng-container
          *ngFor="let column of jobSafetyColumns; trackBy: trackByProperty"
        >
          <ng-container
            *ngIf="column.type === 'text'"
            [matColumnDef]="column.property"
          >
            <th *matHeaderCellDef class="uppercase" mat-header-cell>
              {{ "WorkPermit.View.JobSafetyTable." + column.label | translate }}
            </th>
            <td
              *matCellDef="let row; let i = index"
              [ngClass]="column.cssClasses"
              mat-cell
            >
              <ng-container *ngIf="column.property == 'number'; else jobStep">
                {{ i + 1 }}
              </ng-container>
              <ng-template #jobStep>
                <div
                  [style.color]="
                    column.property === 'riskLevel' ||
                    column.property === 'residualRisk'
                      ? getColor(row[column.property])
                      : ''
                  "
                >
                  {{ row[column.property] }}
                </div>
              </ng-template>
            </td>
          </ng-container>
        </ng-container>
        <tr *matHeaderRowDef="visibleJobSafetyColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: visibleJobSafetyColumns" mat-row></tr>
      </table>
      <div
        *ngIf="jobSafetyDataSource?.filteredData?.length === 0"
        @scaleFadeIn
        class="flex-auto flex flex-col items-center justify-center"
      >
        <img src="assets/img/resources/empty-state.svg" alt="not found" />
        <h2 class="headline m-0 text-center text-primary">
          {{ "General.EmptyTableList" | translate }}...
        </h2>
      </div>
    </div>
  </app-expandable-panel>
</div>

<div class="mt-5" *ngIf="data">
  <app-expandable-panel
    [expanded]="true"
    #expandablePanel
    [title]="'WorkPermit.HazardInfo' | translate"
  >
    <div body class="flex column">
      <div
        class="item flex column"
        *ngFor="let workCondition of workConditions"
      >
        <h3 class="font-bold text-lg">
          {{ workCondition?.workConditionName }}
        </h3>
        <div class="flex flex-wrap-reverse items-center elements">
          <div
            class="info_dark element flex center"
            *ngFor="let hazard of workCondition?.hazardNames"
          >
            <p>{{ hazard }}</p>
          </div>
        </div>
      </div>
    </div>
  </app-expandable-panel>
</div>
<div class="mt-5" *ngIf="data">
  <app-expandable-panel
    [expanded]="true"
    #expandablePanel
    [title]="'NavigationBar.WorkConditions' | translate"
  >
    <div body class="flex column">
      <div class="grid grid-cols-2 items-start gap-3">
        <div class="item flex column flex-1" *ngFor="let cl of checklist">
          <div class="py-3 flex items-center">
            <div
              @scaleIn
              class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
            >
              <mat-icon
                class="icon-sm"
                svgIcon="mat:keyboard_double_arrow_right"
              ></mat-icon>
            </div>
            <div @fadeInRight>
              <h4 class="m-0 font-bold">{{ cl?.title }}</h4>
            </div>
          </div>
          <div *ngFor="let q of cl?.questions">
            <h6 class="font-bold text-gray-400">
              {{ "WorkPermit.Question" | translate }}: {{ q?.questionText }}
            </h6>
            <p class="mt-2">
              {{ "WorkPermit.Answer" | translate }}:
              {{ q?.isNotApplicable ? "Not Applicable" : q?.userAnswer }}
            </p>
            <a
              mat-button
              *ngIf="q?.media"
              color="primary"
              href="{{ q.media }}"
              download
            >
              <mat-icon class="mr-2" svgIcon="mat:download"></mat-icon>
              {{ "WorkPermit.ViewAttachment" | translate }}
            </a>
            <hr class="my-2" />
          </div>
        </div>
      </div>
      <div style="margin: 0 auto">
        <ngx-gauge
          class="guage"
          [foregroundColor]="getGuageColors(gaugeValue)"
          [type]="'full'"
          [value]="gaugeValue"
          [label]=""
          [append]="'%'"
          [thick]="20"
        ></ngx-gauge>
        <h4 class="font-bold text-center">
          {{ "WorkPermit.ResponseScore" | translate }}
        </h4>
      </div>
    </div>
  </app-expandable-panel>
</div>

<div class="mt-5">
  <app-expandable-panel
    [expanded]="true"
    #expandablePanel
    [title]="'NavigationBar.ApproversStatus' | translate"
  >
    <table
      body
      @stagger
      [dataSource]="approverDataSource"
      class="w-full"
      mat-table
    >
      <ng-container
        *ngFor="let column of approverColumns; trackBy: trackByProperty"
      >
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            {{ "WorkPermit.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean'"
          [matColumnDef]="column.property"
        >
          <th *matHeaderCellDef class="uppercase" mat-header-cell>
            {{ "WorkPermit.View.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center w-52"
              [attr.id]="row[column.property] ? 'APPROVED' : 'APPROVER_PENDING'"
            >
              <span>{{
                "Enum.WorkPermitStatus." +
                  (row[column.property] ? "APPROVED" : "APPROVER_PENDING")
                  | translate
              }}</span>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <tr *matHeaderRowDef="visibleApproverColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleApproverColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
      ></tr>
    </table>
    <div
      *ngIf="approverDataSource?.filteredData?.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>
  </app-expandable-panel>
</div>

<div class="mt-5">
  <app-expandable-panel
    [expanded]="true"
    #expandablePanel
    [title]="'IdCardDetails.History' | translate"
  >
    <table
      body
      @stagger
      [dataSource]="dataSource"
      class="w-full"
      mat-table
      matSort
    >
      <ng-container *ngFor="let column of columns; trackBy: trackByProperty">
        <ng-container
          *ngIf="column.type === 'text'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "WorkPermit.View.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'boolean' && column.property === 'status'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            {{ "WorkPermit.View.Table." + column.label | translate }}
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            <div
              class="flex justify-center align-center"
              [attr.id]="row[column.property]"
            >
              <span>{{
                "Enum.WorkPermitStatus." + row[column.property] | translate
              }}</span>
            </div>
          </td>
        </ng-container>
        <ng-container
          *ngIf="column.type === 'date'"
          [matColumnDef]="column.property"
        >
          <th
            *matHeaderCellDef
            class="uppercase"
            mat-header-cell
            mat-sort-header
          >
            Created
          </th>
          <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>
            {{ row[column.property] | date : "mediumDate" }}
          </td>
        </ng-container>
      </ng-container>

      <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: visibleColumns"
        @fadeInUp
        class="hover:bg-hover trans-ease-out cursor-pointer"
        mat-row
      ></tr>
    </table>
    <div
      *ngIf="dataSource?.filteredData?.length === 0"
      @scaleFadeIn
      class="flex-auto flex flex-col items-center justify-center"
    >
      <img src="assets/img/resources/empty-state.svg" alt="not found" />
      <h2 class="headline m-0 text-center text-primary">
        {{ "General.EmptyTableList" | translate }}...
      </h2>
    </div>

    <mat-paginator
      [class.hidden]="dataSource.filteredData.length === 0"
      [pageSizeOptions]="pageSizeOptions"
      [pageSize]="pageSize"
      showFirstLastButtons
      class="sticky left-0"
    ></mat-paginator>
  </app-expandable-panel>
</div>

<ng-template #imageBigView>
  <div class="flex items-center" mat-dialog-title>
    <img [src]="selectedImg?.imageUrl" class="avatar mr-5" />
    <h2 class="headline m-0 flex-auto">{{ data?.title }}</h2>
    <button
      class="text-secondary"
      (click)="downloadDocument(selectedImg?.imageUrl)"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:download"></mat-icon>
    </button>
    <button
      class="text-secondary"
      (click)="closeDialog()"
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div class="flex">
    <div class="">
      <img [src]="selectedImg?.imageUrl" [alt]="data?.title" />
    </div>
  </div>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
  </div>
</ng-template>

<ng-template #approveRejDialouge>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{
        formMode === "Approve" ? "Approve Work Permit" : "Reject Work Permit"
      }}
    </h2>
    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <form [formGroup]="statusForm">
    <mat-label class="field-label">{{
      "ManageIdCard.Comment" | translate
    }}</mat-label>
    <mat-form-field class="input-field" appearance="outline">
      <textarea
        matInput
        rows="5"
        formControlName="comment"
        [placeholder]="'Placeholder.Comment' | translate"
      >
      </textarea>
    </mat-form-field>
  </form>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="formMode === 'Approve' ? 'Approve' : 'Delete'"
      [isDisabled]="!statusForm.valid"
      [btnText]="formMode === 'Approve' ? 'Approve' : 'Reject'"
      (onClick)="
        formMode === 'Approve' ? approveWorkPermit() : rejectWorkPermit()
      "
    ></app-basic-button>
  </div>
</ng-template>
<ng-template #closeWorkPermitModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "WorkPermit.CloseWorkPermit" | translate }}
    </h2>
    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <form [formGroup]="closeForm">
    <mat-label class="field-label">{{
      "ManageIdCard.Comment" | translate
    }}</mat-label>
    <mat-form-field class="input-field" appearance="outline">
      <textarea
        matInput
        rows="5"
        formControlName="comment"
        [placeholder]="'Placeholder.Comment' | translate"
      >
      </textarea>
    </mat-form-field>
  </form>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [isDisabled]="!closeForm.valid"
      [btnText]="'Submit'"
      (onClick)="closeWorkPermit()"
    ></app-basic-button>
  </div>
</ng-template>

<ng-template #extendDateModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "WorkPermit.ExtendDate" | translate }}
    </h2>
    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <form [formGroup]="extendDateForm">
    <div class="flex items-center gap-3">
      <div class="flex column flex-grow">
        <mat-label class="field-label">{{
          "Task.StartDate" | translate
        }}</mat-label>
        <mat-form-field appearance="outline">
          <input
            [placeholder]="'Placeholder.SelectDate' | translate"
            matInput
            [matDatepicker]="startDate"
            (click)="startDate.open()"
            formControlName="startDate"
            [min]="this.data?.startDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>

          <mat-error
            *ngIf="extendDateForm.get('startDate').hasError('required')"
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex column flex-grow">
        <mat-label class="field-label">{{
          "Task.EndDate" | translate
        }}</mat-label>
        <mat-form-field appearance="outline">
          <input
            [placeholder]="'Placeholder.SelectDate' | translate"
            matInput
            [matDatepicker]="endDate"
            (click)="endDate.open()"
            formControlName="endDate"
            [min]="this.data?.startDate"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="endDate"
          ></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>

          <mat-error *ngIf="extendDateForm.get('endDate').hasError('required')">
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="flex items-center gap-3 mt-1">
      <div class="flex column flex-grow">
        <mat-label class="field-label">{{
          "WorkPermit.StartTimewithinworkday" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [format]="24"
            matInput
            [ngxMatTimepicker]="starttimepicker"
            [placeholder]="'Placeholder.Select' | translate"
            formControlName="startTime"
            readonly
          />
          <mat-icon
            matSuffix
            svgIcon="mat:watch_later"
            (click)="starttimepicker.open()"
          >
          </mat-icon>
          <ngx-mat-timepicker #starttimepicker></ngx-mat-timepicker>

          <mat-error
            *ngIf="extendDateForm.get('startTime').hasError('required')"
          >
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="flex column flex-grow">
        <mat-label class="field-label">{{
          "WorkPermit.EndTimewithinworkday" | translate
        }}</mat-label>
        <mat-form-field class="input-field" appearance="outline">
          <input
            [format]="24"
            matInput
            [ngxMatTimepicker]="endtimepicker"
            formControlName="endTime"
            readonly
          />
          <mat-icon
            matSuffix
            svgIcon="mat:watch_later"
            (click)="endtimepicker.open()"
          >
          </mat-icon>
          <ngx-mat-timepicker #endtimepicker></ngx-mat-timepicker>

          <mat-error *ngIf="extendDateForm.get('endTime').hasError('required')">
            <div class="flex justify-flex-start align-flex-center">
              <img
                class="error-img"
                src="assets/img/resources/error-icon.svg"
              />
              <span>{{ "Errors.Required" | translate }}</span>
            </div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-row">
      <mat-label class="field-label">{{
        "ManageIdCard.Comment" | translate
      }}</mat-label>
      <mat-form-field class="input-field" appearance="outline">
        <textarea
          matInput
          rows="3"
          [placeholder]="'Placeholder.Comment' | translate"
          formControlName="comment"
        >
        </textarea>
      </mat-form-field>
    </div>
    <div class="flex column">
      <file-upload
        formControlName="attachments"
        [multiple]="true"
        [animation]="true"
      ></file-upload>
    </div>
  </form>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [isDisabled]="!extendDateForm.valid"
      [btnText]="'Submit'"
      (onClick)="saveExtendDate()"
    ></app-basic-button>
  </div>
</ng-template>

<ng-template #viewExtendModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "WorkPermit.RequestExtendedTime" | translate }}
    </h2>
    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <div body class="flex justify-between flex-wrap-reverse" *ngIf="data">
    <div class="flex-1">
      <div class="flex items-center">
        <div @fadeInRight>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.StartDate" | translate }}
          </p>
          <p class="m-0 body-1">
            {{ data?.extendDateDetail?.startDate | date : "dd-MM-yyyy" }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div class="flex items-center">
        <div @fadeInRight>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.EndDate" | translate }}
          </p>
          <p class="m-0 body-1">
            {{ data?.extendDateDetail?.endDate | date : "dd-MM-yyyy" }}
          </p>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div class="flex items-center">
        <div @fadeInRight>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.StartTime" | translate }}
          </p>
          <p class="m-0 body-1">{{ data?.extendDateDetail?.startTime }}</p>
        </div>
      </div>
    </div>
    <div class="flex-1">
      <div class="flex items-center">
        <div @fadeInRight>
          <p class="m-0 caption text-hint">
            {{ "WorkPermit.EndDate" | translate }}
          </p>
          <p class="m-0 body-1">{{ data?.extendDateDetail?.endTime }}</p>
        </div>
      </div>
    </div>
  </div>
  <div @fadeInRight class="mt-4">
    <p class="m-0 caption text-hint">
      {{ "WorkPermit.Description" | translate }}
    </p>
    <p class="m-0 body-1">{{ data?.extendDateDetail?.comment }}</p>
  </div>
  <div body class="flex flex-wrap gap-2 mt-3" *ngIf="data">
    <ngx-avatars
      *ngFor="let img of data?.extendDateDetail?.attachments"
      [src]="getAttachmentImage(img.imageUrl)"
      [name]="getAttachementTypeName(img.imageUrl)"
      [textSizeRatio]="2"
      [round]="false"
      [initialsSize]="2"
      [cornerRadius]="10"
      [matTooltip]="img.fileName"
      [borderColor]="'#131C4E'"
      (clickOnAvatar)="openBigView(img.id)"
    >
    </ngx-avatars>
  </div>

  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Approve'"
      [isDisabled]="false"
      [btnText]="'Approve'"
      (onClick)="approveExtendDate()"
    >
    </app-basic-button>
    <app-basic-button
      [btnType]="'Delete'"
      [isDisabled]="false"
      [btnText]="'Reject'"
      (onClick)="rejectExtendDate()"
    >
    </app-basic-button>
  </div>
</ng-template>

<ng-template #closePermitRequestModal>
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">
      {{ "WorkPermit.CloseWorkPermitRequest" | translate }}
    </h2>
    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>

  <form [formGroup]="closeRequestForm">
    <mat-checkbox formControlName="isExtendTime" color="primary" class="mt-5">
      {{ "WorkPermit.Doyouwanttoextendtime" | translate }}
    </mat-checkbox>
  </form>
  <div class="flex justify-end items-center mt-4">
    <app-basic-button
      [btnType]="'Cancel'"
      [btnText]="'Cancel'"
      (onClick)="closeDialog()"
    ></app-basic-button>
    <app-basic-button
      [btnType]="'Add'"
      [isDisabled]="!closeRequestForm.valid"
      [btnText]="'Submit'"
      (onClick)="closeSubmitPermitRequest()"
    ></app-basic-button>
  </div>
</ng-template>
