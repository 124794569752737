import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import { Observable, of } from 'rxjs';
import { NotificationService } from 'src/backend/services/notification.service';
import { ColorSchemeName } from 'src/@hodhod/config/colorSchemeName';
import { SearchService } from 'src/@hodhod/services/search.service';
import { GlobalSearchService } from 'src/backend/services/global-search.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'hodhod-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  @Input() mobileQuery!: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow!: boolean;
  searchCtrl = new UntypedFormControl();

  navigationItems = this.navigationService.items.filter(
    (x) => x.label !== 'ManageAccount' && x.label !== 'ManageContent'
  );

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'horizontal')
  );
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.layout === 'vertical')
  );
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'in-toolbar')
  );
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.navbar.position === 'below-toolbar')
  );
  userVisible$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.toolbar.user.visible)
  );
  imageUrl$ = this.configService.config$.pipe(
    map((config) => config.sidenav.imageUrl)
  );

  isDark$: Observable<boolean> = this.configService.config$.pipe(
    map((config) => config.style.colorScheme === ColorSchemeName.dark)
  );
  isOrganizationAdmin;

  megaMenuOpen$: Observable<boolean> = of(false);
  notifyCount: number = 0;
  timer = null;

  sidenavCollapsed$: Observable<boolean> = this.layoutService.sidenavCollapsed$;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private popoverService: PopoverService,
    public notificationService: NotificationService,
    private searchService: SearchService,
    private globalSearchService: GlobalSearchService
  ) {}

  ngOnInit(): void {
    let rolesArray = [];
    rolesArray = JSON.parse(localStorage.getItem('logged_in_user'))['roles'];
    this.isOrganizationAdmin = rolesArray.includes('Organization Admin');

    this.notificationService.notificationCount$.subscribe({
      next: (resp) => {
        this.notifyCount = resp;
      },
      error: ({ error }) => {},
    });
  }

  openQuickpanel(): void {
    // this.getAllNotification();
    this.layoutService.openQuickpanel();
  }

  openSidenav(): void {
    this.layoutService.openSidenav();
  }

  openMegaMenu(origin: ElementRef | HTMLElement): void {
    this.megaMenuOpen$ = of(
      this.popoverService.open({
        content: MegaMenuComponent,
        origin,
        offsetY: 12,
        position: [
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
          },
        ],
      })
    ).pipe(
      switchMap((popoverRef) => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true)
    );
  }

  openSearch(): void {
    this.layoutService.openSearch();
  }

  formateManageContentItems(): any[] {
    let linkItems = [];
    const menuItems: any[] = this.navigationService.items.filter(
      (x) => x.label === 'ManageContent'
    );
    for (const subheading of menuItems) {
      for (const item of subheading.children) {
        if (item.type === 'dropdown') {
          linkItems = [...linkItems, ...item?.children];
        } else {
          linkItems.push(item);
        }
      }
    }
    return linkItems;
  }
  formateManageAccountItems(): any[] {
    let linkItems = [];
    const menuItems: any[] = this.navigationService.items.filter(
      (x) => x.label === 'ManageAccount'
    );
    for (const subheading of menuItems) {
      for (const item of subheading.children) {
        if (item.type === 'dropdown') {
          linkItems = [...linkItems, ...item?.children];
        } else {
          linkItems.push(item);
        }
      }
    }
    return linkItems;
  }

  search() {
    this.searchService.submitSubject.next(this.searchCtrl.value);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.globalSearchService.onSearch(this.searchCtrl.value);
    }, 700);
  }

  clearSearch(): void {
    this.searchCtrl.setValue('');
    this.search();
  }
}
