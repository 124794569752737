import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseApi } from 'src/backend/api/base-api';
import { Observable } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';

@Injectable({
  providedIn: 'root',
})
export class SafetyAnnouncementService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  GetReceiversList(): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(Constants.API_GET_RECEIVER_LIST)
    );
  }

  GetSafetyAnnouncements(data?: any): Observable<any> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.sortDirection ) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_SAFETY_ANNOUNCEMENTS + '?' + params
      )
    );
  }

  GetSafetyAnnouncementById(id: number) {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_GET_SAFETY_ANNOUNCEMENTS_BY_ID + '/' + id
      )
    );
  }

  CreateSafetyAnnouncement(data: any): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_CREATE_SAFETY_ANNOUNCEMENT),
      data
    );
  }

  EditSafetyAnnouncement(data: any): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_EDIT_SAFETY_ANNOUNCEMENT),
      data
    );
  }

  DeleteAnnouncement(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_DELETE_SAFETY_ANNOUNCEMENT + '/' + id
      )
    );
  }

  DeleteAnnouncementAttachments(id: number): Observable<any> {
    return this.http.delete<any>(
      this.baseApi.encodeURL(
        Constants.API_DELETE_SAFETY_ANNOUNCEMENT_ATTACHMENTS + '/' + id
      )
    );
  }
}
