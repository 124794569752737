<hodhod-page-layout>
  <hodhod-secondary-toolbar [current]="'NavigationBar.Dashboard' | translate">
    <hodhod-breadcrumbs class="flex-auto"></hodhod-breadcrumbs>
  </hodhod-secondary-toolbar>
  <hodhod-page-layout-content class="container space-y-4">
    <div class="card overflow-auto">
      <div class="p-4">
        <div class="flex justify-between">
          <h2 class="text-xl font-semibold p-1 pb-2">Safeman Hours</h2>
          <div>
            <mat-slide-toggle
              (change)="AutoOrManual($event)"
              [checked]="isAuto"
            >
              <span>Auto</span>
            </mat-slide-toggle>
          </div>
        </div>

        <form [formGroup]="safeManHoursForm">
          <div
            class="flex flex-wrap"
            [ngClass]="isAuto ? 'justify-between' : 'justify-start'"
          >
            <div class="lg:w-3/12 md:w-4/12 sm:w-full p-1" *ngIf="isAuto">
              <mat-label class="field-label"
                >{{ "SafemanHours.NumberOfEmployees" | translate }}
                <span
                  [ngClass]="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('employees').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  [placeholder]="'Placeholder.Insert' | translate"
                  formControlName="employees"
                  matInput
                  #myinput
                />
                <mat-error
                  *ngIf="safeManHoursForm.get('employees').hasError('required')"
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
                <mat-error
                  *ngIf="safeManHoursForm.get('employees').hasError('min')"
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.NotAPositiveNumber" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="lg:w-3/12 md:w-4/12 sm:w-full p-1" *ngIf="isAuto">
              <mat-label class="field-label"
                >{{ "SafemanHours.WorkingHours/day/employee" | translate }}
                <span
                  [ngClass]="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingHours').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  [placeholder]="'Placeholder.Insert' | translate"
                  formControlName="workingHours"
                  matInput
                  #myinput
                />
                <mat-error
                  *ngIf="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingHours').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
                <mat-error
                  *ngIf="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingHours').hasError('max')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.workingHoursMax" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="lg:w-3/12 md:w-4/12 sm:w-full p-1" *ngIf="isAuto">
              <mat-label class="field-label"
                >{{ "SafemanHours.WorkingDays/week/employee" | translate }}
                <span
                  [ngClass]="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingDays').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  [placeholder]="'Placeholder.Insert' | translate"
                  formControlName="workingDays"
                  matInput
                  #myinput
                />
                <mat-error
                  *ngIf="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingDays').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
                <mat-error
                  *ngIf="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingDays').hasError('min')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.NotAPositiveNumber" | translate }}</span>
                  </div>
                </mat-error>
                <mat-error
                  *ngIf="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingDays').hasError('max')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.workingDaysMax" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="lg:w-3/12 md:w-4/12 sm:w-full p-1" *ngIf="isAuto">
              <mat-label class="field-label"
                >{{ "SafemanHours.WorkingWeeks/year/employee" | translate }}
                <span
                  [ngClass]="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingWeeks').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  [placeholder]="'Placeholder.Insert' | translate"
                  formControlName="workingWeeks"
                  matInput
                  #myinput
                />
                <mat-error
                  *ngIf="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingWeeks').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
                <mat-error
                  *ngIf="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingWeeks').hasError('min')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.NotAPositiveNumber" | translate }}</span>
                  </div>
                </mat-error>
                <mat-error
                  *ngIf="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('workingWeeks').hasError('max')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.workingWeekssMax" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="lg:w-3/12 md:w-4/12 sm:w-full p-1" *ngIf="!isAuto">
              <mat-label class="field-label"
                >{{ "SafemanHours.ManualValue" | translate }}
                <span
                  [ngClass]="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('manualValue').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  [placeholder]="'Placeholder.Insert' | translate"
                  formControlName="manualValue"
                  matInput
                  #myinput
                />
                <mat-error
                  *ngIf="
                    safeManHoursForm.invalid &&
                    !isAuto &&
                    safeManHoursForm.get('manualValue').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="lg:w-3/12 md:w-4/12 sm:w-full p-1">
              <mat-label class="field-label"
                >{{ "SafemanHours.Target" | translate }}
                <span
                  [ngClass]="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('target').hasError('required')
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  >*</span
                >
              </mat-label>
              <mat-form-field class="input-field" appearance="outline">
                <input
                  type="number"
                  min="0"
                  oninput="validity.valid||(value='');"
                  [placeholder]="'Placeholder.Insert' | translate"
                  formControlName="target"
                  matInput
                  #myinput
                />
                <mat-error
                  *ngIf="
                    safeManHoursForm.invalid &&
                    safeManHoursForm.get('target').hasError('required')
                  "
                >
                  <div class="flex justify-flex-start align-flex-center p-0">
                    <img
                      class="error-img"
                      src="assets/img/resources/error-icon.svg"
                    />
                    <span>{{ "Errors.Required" | translate }}</span>
                  </div>
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div class="flex justify-end items-center mt-4">
          <app-basic-button
            [btnType]="'Cancel'"
            [btnText]="'Cancel'"
            (onClick)="navigateBack()"
          ></app-basic-button>
          <app-basic-button
            [btnType]="'Add'"
            [btnText]="'Save'"
            [isDisabled]="safeManHoursForm.invalid"
            (onClick)="submitForm()"
          ></app-basic-button>
        </div>
      </div>
    </div>
  </hodhod-page-layout-content>
</hodhod-page-layout>
