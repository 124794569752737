import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  GetIncidentClassification,
  PostIncidentClassification,
  UpdateIncidentClassification,
} from 'src/backend/models/incident-classification/incident-classification.model';
import { DataMapper } from 'src/app/shared/models/data-mapper';
import { BaseApi } from 'src/backend/api/base-api';
import { SortDirection } from 'src/@hodhod/common/enum';
import { Constants } from 'src/@hodhod/common/constants';

@Injectable({
  providedIn: 'root',
})
export class IncidentClassificationService {
  constructor(private http: HttpClient, private baseApi: BaseApi) {}

  getIncidentClassification(
    data: any
  ): Observable<DataMapper<GetIncidentClassification>> {
    let params = '';
    if (data?.pageIndex) {
      params += 'offset=' + data?.pageIndex.toString();
    }
    if (data?.pageSize) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'limit=' + data?.pageSize.toString();
    }
    if (data?.status) {
      if (params.length > 0) {
        params += '&';
      }
      params += `status=` + data?.status;
    }
    if (data?.sortDirection !== SortDirection.None) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortDirection=' + data?.sortDirection;
    }
    if (data?.sortField) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'sortField=' + data?.sortField;
    }
    if (data?.searchValue) {
      if (params.length > 0) {
        params += '&';
      }
      params += 'phrase=' + data?.searchValue;
    }
    return this.http.get<DataMapper<GetIncidentClassification>>(
      this.baseApi.encodeURL(
        Constants.API_INCIDENT_CLASSIFICATION_GET_ALL + '?' + params
      )
    );
  }

  postIncidentClassification(
    data: PostIncidentClassification
  ): Observable<any> {
    return this.http.post<any>(
      this.baseApi.encodeURL(Constants.API_INCIDENT_CLASSIFICATION_CREATE),
      data
    );
  }

  updateIncidentClassification(
    data: UpdateIncidentClassification
  ): Observable<any> {
    return this.http.put<any>(
      this.baseApi.encodeURL(Constants.API_INCIDENT_CLASSIFICATION_EDIT),
      data
    );
  }

  deActivate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_INCIDENT_CLASSIFICATION_DEACTIVATE + '/' + id
      )
    );
  }
  activate(id: number): Observable<any> {
    return this.http.get<any>(
      this.baseApi.encodeURL(
        Constants.API_INCIDENT_CLASSIFICATION_ACTIVATE + '/' + id
      )
    );
  }
  getIncidentClassificationByCompany(): Observable<any[]> {
    return this.http.get<any[]>(
      this.baseApi.encodeURL(Constants.API_INCIDENT_CLASSIFICATION_LIST)
    );
  }
}
