<div>
  <div class="flex items-center justify-between" mat-dialog-title>
    <h2
      class="headline m-0 flex-auto max-w-[300px] truncate"
      [matTooltip]="action?.title"
    >
      {{ action?.title }}
    </h2>
    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      type="button"
    >
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <mat-divider class="-mx-6 text-border mb-2"></mat-divider>
  <mat-dialog-content>
    <div class="flex justify-between">
      <div class="flex-1">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p
              class="m-0 body-1 max-w-[150px] truncate"
              [matTooltip]="action?.title"
            >
              {{ action?.title }}
            </p>
            <p class="m-0 caption text-hint">Title</p>
          </div>
        </div>
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">{{ action?.assignToName }}</p>
            <p class="m-0 caption text-hint">Responsibility</p>
          </div>
        </div>

        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{ action?.targetDate | date : "mediumDate" }}
            </p>
            <p class="m-0 caption text-hint">Target Date</p>
          </div>
        </div>

        <div
          class="py-3 flex items-center"
          *ngIf="action?.actionStatus === 'CLOSE'"
        >
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <p class="m-0 body-1">
              {{ action?.closedDate | date : "mediumDate" }}
            </p>
            <p class="m-0 caption text-hint">Close Date</p>
          </div>
        </div>
      </div>

      <div class="flex-1">
        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <div class="w-28 text-center" [attr.id]="action?.actionStatus">
              <span>{{ action?.actionStatus }}</span>
            </div>
            <p class="m-0 caption text-hint">Status</p>
          </div>
        </div>

        <div class="py-3 flex items-center">
          <div
            @scaleIn
            class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
          >
            <mat-icon
              class="icon-sm"
              svgIcon="mat:keyboard_double_arrow_right"
            ></mat-icon>
          </div>

          <div @fadeInRight>
            <div class="w-28 text-center priority" [attr.id]="action?.priority">
              <span>{{ action?.priority }}</span>
            </div>
            <p class="m-0 caption text-hint">Priority</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="py-3 flex items-center"
      *ngIf="action?.actionStatus === 'CLOSE'"
    >
      <div
        @scaleIn
        class="w-8 h-8 rounded-full bg-primary/10 text-primary ltr:mr-3 rtl:ml-3 flex items-center justify-center"
      >
        <mat-icon
          class="icon-sm"
          svgIcon="mat:keyboard_double_arrow_right"
        ></mat-icon>
      </div>

      <div @fadeInRight>
        <p class="m-0 body-1">
          {{ action?.remarks }}
        </p>
        <p class="m-0 caption text-hint">remarks</p>
      </div>
    </div>
    <div
      class="flex"
      *ngIf="action?.actionStatus === 'CLOSE' && action?.closedMediaName"
    >
      <ngx-avatars
        class="cursor-pointer"
        size="60"
        [src]="getAttachmentImage(action?.closedMedia)"
        [name]="getAttachementTypeName(action?.closedMedia)"
        [textSizeRatio]="2"
        [round]="false"
        [initialsSize]="2"
        [matTooltip]="action?.closedMediaName"
        [cornerRadius]="10"
        [borderColor]="'#131C4E'"
        (clickOnAvatar)="downloadDocument(action?.closedMedia)"
      >
      </ngx-avatars>
    </div>
  </mat-dialog-content>
</div>
