import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  OnDestroy,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { CarouselConfig, CarouselWidthMode } from 'ng-carousel-cdk';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { LoadingService } from 'src/app/shared/helpers/loading.service';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { VoilationsService } from 'src/backend/services/voilations/voilations.service';
import { ChartDataColumn } from '../../models/voilation';
import { LanguageService } from 'src/@hodhod/services/language.service';
import { Language } from 'src/app/shared/models/language';
import { LayoutService } from 'src/@hodhod/services/layout.service';
import { SharedConstants } from 'src/app/shared/models/shared-constants';

interface CarouselItem {
  color: string;
  voilationName: string;
  y: any;
  path: string;
}
@Component({
  selector: 'app-video-analytics-dashboard-page',
  templateUrl: './video-analytics-dashboard-page.component.html',
  styleUrls: ['./video-analytics-dashboard-page.component.scss'],
})
export class VideoAnalyticsDashboardPageComponent
  extends BaseComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() loadDigital: boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  @ViewChild('RangePicker') private RangePicker!: TemplateRef<any>;
  @ViewChild('dbLeft') dbLeftElement!: ElementRef;
  @ViewChild('dbRight') dbRightElement!: ElementRef;
  public sectionState: SectionStateStatus = SectionStateStatus.Loading;
  public expanded = false;
  public searchedValue = '';
  loadingLabel: string = '';
  searchForm = new FormGroup({
    start: new FormControl(
      new Date(new Date().setDate(new Date().getDate() - 7))
    ),
    end: new FormControl(new Date()),
    menu: new FormControl(),
  });

  config: CarouselConfig<CarouselItem> = {
    items: [],
    autoplayEnabled: true,
    autoplayDelay: 4000,
    widthMode: CarouselWidthMode.PX,
    slideWidth: 240,
    dragEnabled: true,
    shouldLoop: true,
    shouldRecalculateOnResize: true,
    allowKeyboardNavigation: true,
  };
  carouselItems: any[] = [];
  calenderMenu = [
    {
      id: 2,
      name: 'ThisDay',
    },
    {
      id: 3,
      name: 'Yesterday',
    },
    {
      id: 4,
      name: 'CurrentMonth',
    },
    {
      id: 5,
      name: 'LastMonth',
    },
    {
      id: 6,
      name: 'Past30Days',
    },
    {
      id: 7,
      name: 'CustomRange',
    },
  ];
  public dashboardData: any;
  public violationlist: any[] = [];
  public FilterChartData: any[] = [];
  public filteredData: any[] = [];
  public violationCardList: any[] = [];
  public chartData: ChartDataColumn[] = [];
  private translationsList: any = {};
  chartOneArr: any[] = [];
  AvgChartOneArr: any[] = [];
  deptchartOneArr: any[] = [];
  ReportchartOneArr: any[] = [];
  highChartsColor = [
    '#D52A46',
    '#00008B',
    '#34A56F',
    '#F80000',
    '#2D0A77',
    '#8C001A',
    '#FF69B4',
    '#FA4A08',
    '#5453A6',
    '#8B008B',
    '#3090C7',
    '#F1C40F',
    '#CB4335',
    '#36454F',
    '#A569BD',
    '#FFA500',
    '#179fcc',
    '#093323',
    '#08fa03',
    '#048b8e',
    '#9637A4',
    '#F39C12',
    '#0b774e',
    '#F5B7B1',
    '#0d98da',
    '#512E5F',
    '#C471A5',
    '#0034bb',
    '#06573d',
    '#0d6e54',
    '#0692aa',
    '#611745',
    '#F80000',
    '#FFDF00',
    '#6D81A8',
    '#08FAC7',
    '#D208FA',
    '#FAEB08',
  ];

  secondcharttotal: any;
  violationchart: any;
  chartArra2: any[] = [];
  chartTwoArr: any[] = [];
  AvgChartTwoArr: any[] = [];
  deptchartTwoArr: any[] = [];
  ReportchartTwoArr: any[] = [];
  offenderchartOneArr: any[] = [];
  offenderchartTwoArr: any[] = [];
  actionchartOneArr: any[] = [];
  actionchartTwoArr: any[] = [];
  department: any;
  week: any;
  day: any;
  month: any;
  useCase: any;
  offender: any;
  date: any;
  status: any;
  year: any;
  chartname: any;
  Reportchart: any;
  actionchart: any;
  deptchart: any;
  offenderchart: any;
  chartName: string;
  chartEndDate: any;
  chartStartDate: any;
  ViolationIconfilter: any;
  maskIcon: any[];
  violation: any;
  color: any;
  sessionCompany: any;
  currentLanguage: Language;
  public columnChartData: any = [
    {
      customTitle: 'MonthlyViolationsReport',
      // customTitle: "",
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
      },
      exporting: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        title: {
          text: '',
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      plotOptions: {
        series: {
          borderWidth: 0,
        },
      },

      series: [],
      drilldown: {
        allowPointDrilldown: false,
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: [],
      },
    },
    {
      customTitle: 'Actionstatus',
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
      },
      exporting: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
      },

      accessibility: {
        announceNewData: {
          enabled: true,
        },
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y:.1f}%',
          },
        },
      },
      series: [],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: [],
      },
    },
    {
      customTitle: 'ViolationsByType',
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      exporting: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0.1,
        },
      },
      series: [],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: [],
      },
    },

    {
      customTitle: 'ViolationsByDepartments',
      chart: {
        type: 'column',
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0.1,
        },
      },
      exporting: {
        enabled: true,
      },
      series: [],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: [],
      },
    },

    {
      customTitle: 'ViolationsbyTeamMembers',
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      exporting: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0.1,
        },
      },
      series: [],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: [],
      },
    },

    {
      customTitle: 'MonthlyViolationsAvg',
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      exporting: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0.1,
        },
      },
      series: [],
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: [],
      },
    },
  ];
  constructor(
    private voilationService: VoilationsService,
    private asyncFeedbackService: AsyncFeedbackService,
    private translate: TranslateService,
    private router: Router,
    private baseApi: BaseApi,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private langService: LanguageService,
    private layoutService: LayoutService
  ) {
    super();
    this.translate
      .get(['Errors', 'Success', 'VideoAnalyticsDashboard'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loadDigital'].currentValue) {
      this.ChangeRtl();
      this.getSessionOrganization();
      this.searchForm.get('menu').setValue('Past30Days');
      this.onChangeCalenderDD();
      this.collapseDashboardnav();
    }
  }

  override ngOnInit(): void {}

  ngOnDestroy(): void {
    this.collapseDashboardnav();
  }

  collapseDashboardnav(): void {
    const Currenturl = this.router.url;
    if (
      Currenturl ===
      '/' +
        SharedConstants.SMART_MONITORING +
        '/' +
        SharedConstants.VIDEO_ANALYTICS_DASHBOARD
    ) {
      this.layoutService.collapseSidenav();
    } else {
      this.layoutService.expandSidenav();
    }
  }

  onChangeCalenderDD(): void {
    const menuVal = this.searchForm.value.menu;

    if (menuVal === 'ThisDay') {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(1);
      this.searchForm.get('start')?.setValue(new Date(today));
      this.searchForm.get('end')?.setValue(new Date());
      // this.onChangeStartDate();
      this.setEventToGetData();
      this.searchForm.get('menu').setValue('');
    } else if (menuVal === 'Yesterday') {
      const today = new Date();
      const day = today.getDate() - 1;
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setDate(day);
      this.searchForm.get('start')?.setValue(new Date(today));
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setDate(day + 1);
      this.searchForm.get('end')?.setValue(new Date(today));
      this.setEventToGetData();
      this.searchForm.get('menu').setValue('');
    } else if (menuVal === 'CurrentMonth') {
      const today = new Date();
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
      this.searchForm.get('start')?.setValue(new Date(firstDay));
      this.setEventToGetData();
      this.searchForm.get('menu').setValue('');
    } else if (menuVal === 'LastMonth') {
      const today = new Date();
      const firstDayPrevMonth = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      this.searchForm.get('start')?.setValue(new Date(firstDayPrevMonth));
      const lastDayPrevMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      this.searchForm.get('end')?.setValue(new Date(lastDayPrevMonth));
      this.setEventToGetData();
      this.searchForm.get('menu').setValue('');
    } else if (menuVal === 'Past30Days') {
      const today = new Date();
      this.searchForm.get('end')?.setValue(new Date(today));
      const Past30Day = new Date(today.setDate(today.getDate() - 30));
      this.searchForm.get('start')?.setValue(new Date(Past30Day));
      this.setEventToGetData();
      this.searchForm.get('menu').setValue('');
    } else if (menuVal === 'CustomRange') {
      this.searchForm.get('start')?.setValue(new Date());
      this.searchForm.get('end')?.setValue(new Date());
      this.dialog.open(this.RangePicker, {
        width: 'auto',
        height: 'auto',
      });
      this.searchForm.get('menu').setValue('');
    }
  }

  getSessionOrganization(): void {
    this.sessionCompany = this.baseApi.getCompanyFromStorage();
  }

  ChangeRtl(): void {
    this.langService.currentLanguage$.subscribe((language) => {
      this.currentLanguage = language;
    });
  }

  setEventToGetData(): void {
    this.violationlist = [];
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    const { start, end } = this.searchForm.value;
    const data = {
      companyId: this.sessionCompany?.companyCode,
      fromDate: start,
      toDate: end,
    };
    this.voilationService.getVoilationDashboardData(data).subscribe({
      next: (res) => {
        this.sectionState = SectionStateStatus.Ready;
        this.dialog.closeAll();
        this.dashboardCharts(res);
      },
    });
  }

  validateArray(): void {
    if (this.carouselItems.length > 0) {
      this.carouselItems?.forEach(async (elem: any, index) => {
        let file =
          window.location.origin +
          '/assets/img/' +
          elem.voilationName +
          '_' +
          elem.color +
          '.png';

        const res = await fetch(file, { method: 'HEAD' });
        if (res.ok) {
          this.carouselItems[index].path = file;
        } else {
          this.carouselItems[index].path = null;
        }
      });
    }
  }

  setEventFilterToGetData(): void {
    this.violationlist = [];
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    const key = {
      fromDate: this.searchForm.value.start,
      toDate: this.searchForm.value.end,
      week: this.week ? this.week : '',
      department: this.department ? this.department : '',
      year: this.year ? this.year : '',
      month: this.month ? this.month : '',
      voilation: this.useCase ? this.useCase : '',
      offender: this.offender ? this.offender : '',
      status: this.status ? this.status : '',
      day: this.day ? this.day : '',
      companyId: this.sessionCompany.companyCode,
    };
    this.voilationService.getFilterdDashboardData(key).subscribe({
      next: (filterData) => {
        this.sectionState = SectionStateStatus.Ready;
        this.violationlist = filterData?.data;
      },
    });
  }

  dashboardCharts(res: any): void {
    if (res?.dataListDto?.dataListDto.totalCount > 0) {
      this.violationlist = res?.dataListDto?.dataListDto.data;
    }
    if (this.violationlist.length === 0) {
      this.dbLeftElement.nativeElement.style.width = '100%';
      this.dbRightElement.nativeElement.style.display = 'none';
      this.dbRightElement.nativeElement.style.width = '0%';
    } else if (this.violationlist.length > 0) {
      this.dbLeftElement.nativeElement.style.width = '78%';
      this.dbRightElement.nativeElement.style.width = '23%';
      this.dbRightElement.nativeElement.style.display = 'block';
    }
    //for filterd data
    this.filteredData = res?.data;
    //----
    this.violationCardList = null;
    this.violationCardList = res?.violationCardList;
    this.carouselItems = this.violationCardList ? this.violationCardList : [];
    if (this.carouselItems.length > 0) {
      this.carouselItems?.forEach((itemObj) => {
        itemObj.path = '';
      });
    }
    this.config.items = this.carouselItems ? this.carouselItems : [];
    this.validateArray();
    this.Reportchart = res?.offenceChart;
    const ReportchartArrayaOne = this.Reportchart?.part1;
    const ReportseriesArrChartOne: any[] = [
      ...new Set(ReportchartArrayaOne?.map((c: any) => c.drilldown)),
    ];

    this.ReportchartOneArr = [];
    const newoffence = {
      name: 'New Offences',
      color: '#D52A46',
      data: [],
    };
    const closeoffence = {
      name: 'Close Offences',
      color: '#1f456b',
      data: [],
    };
    this.ReportchartOneArr.push(closeoffence);
    this.ReportchartOneArr.push(newoffence);
    ReportseriesArrChartOne.forEach((element: any) => {
      const data = ReportchartArrayaOne?.find(
        (x: any) => x.drilldown === element
      );
      this.ReportchartOneArr.forEach((elem: any, index) => {
        if (elem.name === data.status) {
          this.ReportchartOneArr[index].data.push(data);
        }
      });
    });

    ReportseriesArrChartOne.forEach((element: any) => {
      const data = ReportchartArrayaOne?.find(
        (x: any) => x.drilldown === element
      );
      this.ReportchartTwoArr.push({ name: data.status, id: element, data: [] });
    });
    this.Reportchart?.part2?.forEach((a1: any) => {
      this.ReportchartTwoArr.forEach((a2: any) => {
        if (a1.id === a2.id) {
          a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
        }
      });
    });

    const ReportseriesArrChartTwo = [
      ...new Set(this.Reportchart?.part2?.map((c: any) => c.drilldown)),
    ];
    ReportseriesArrChartTwo.forEach((element: any) => {
      const data = this.Reportchart?.part2?.find(
        (x: any) => x.drilldown === element
      );
      this.ReportchartTwoArr.push({ name: data.status, id: element, data: [] });
    });

    this.Reportchart?.part3?.forEach((a1: any) => {
      this.ReportchartTwoArr.forEach((a2: any) => {
        if (a1.id === a2.id) {
          a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
        }
      });
    });
    const vars1 = {
      // customTitle: 'Monthly Violations Report',
      customTitle: 'MonthlyViolationsReport',
      chart: {
        type: 'bar',
      },
      navigation: {
        breadcrumbs: {
          style: {
            'font-size': '8px',
          },
        },
      },
      exporting: {
        enabled: true,
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        itemStyle: {
          fontSize: '9px',
        },
      },
      xAxis: {
        type: 'category',
        title: {
          text: '',
        },
        labels: {
          style: {
            fontSize: '9px',
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          point: {
            events: {
              click: (event) => {
                this.department = '';
                this.week = '';
                this.day = '';
                this.month = '';
                this.useCase = '';
                this.offender = '';
                this.date = '';
                this.status = '';
                this.year = '';
                if (event.point.name) {
                  this.day = event.point.name;
                  const monthName =
                    event.point.series.chart.breadcrumbs.list[1].levelOptions
                      .name;
                  const legend = event.point.series.legendItem.textStr;
                  const weekname =
                    event.point.series.chart.breadcrumbs.list[2].levelOptions
                      .name;
                  this.week = weekname.split('Week')[1];
                  this.status = legend.split('Offences')[0].trim();
                  const monthsLong = {
                    January: '01',
                    February: '02',
                    March: '03',
                    April: '04',
                    May: '05',
                    June: '06',
                    July: '07',
                    August: '08',
                    September: '09',
                    October: '10',
                    November: '11',
                    December: '12',
                  };
                  this.month = monthsLong[monthName];
                  this.setEventFilterToGetData();
                }
              },
            },
          },
        },
      },

      series: this.ReportchartOneArr,
      drilldown: {
        allowPointDrilldown: false,
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },

        series: this.ReportchartTwoArr,
      },
    };
    this.columnChartData[0] = vars1;

    // Drilldown First Chart End

    // Drilldown Fifth Chart Start
    this.actionchart = res?.actionListChart;
    const actionchartArrayAreaOne = this.actionchart?.part1;

    this.actionchartOneArr = [];
    const data1 = [];
    actionchartArrayAreaOne?.forEach((element, i) => {
      let questionItem = {
        name: element.name,
        y: element.y,
        drilldown: element.drilldown,
        color: this.highChartsColor[i],
      };

      data1.push(questionItem);
    });
    this.actionchartOneArr.push({ name: 'Status', data: data1 });
    const data = actionchartArrayAreaOne;
    const actionseriesArrChartOne = [
      ...new Set(actionchartArrayAreaOne?.map((c: any) => c.name)),
    ];

    actionseriesArrChartOne?.forEach((element: any) => {
      this.actionchartTwoArr.push({ name: '', id: element, data: [] });
    });
    this.actionchart?.part2?.forEach((a1: any) => {
      this.actionchartTwoArr.forEach((a2: any) => {
        if (a1.id === a2.id) {
          a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
        }
      });
    });

    const actionseriesArrChartTwo = [
      ...new Set(this.actionchart?.part2?.map((c: any) => c.drilldown)),
    ];
    actionseriesArrChartTwo?.forEach((element: any) => {
      this.actionchartTwoArr.push({ name: '', id: element, data: [] });
    });

    this.actionchart?.part3?.forEach((a1: any) => {
      this.actionchartTwoArr.forEach((a2: any, i) => {
        if (a1.id === a2.id) {
          a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
        }
      });
    });

    const actionseriesArrChartThree = [
      ...new Set(this.actionchart?.part3?.map((c: any) => c.drilldown)),
    ];
    actionseriesArrChartThree?.forEach((element: any) => {
      this.actionchartTwoArr.push({ name: '', id: element, data: [] });
    });

    this.actionchart?.part4?.forEach((a1: any) => {
      this.actionchartTwoArr.forEach((a2: any, i) => {
        if (a1.id === a2.id) {
          a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
        }
      });
    });

    const var4 = {
      // customTitle: 'Action status',
      customTitle: 'Actionstatus',
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
      },
      exporting: {
        enabled: true,
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">Actions</span>: <b>{point.y:2f}</b><br/>',
      },
      accessibility: {
        announceNewData: {
          enabled: true,
        },
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y:1f}',
          },
        },
      },
      series: [this.actionchartOneArr[0]],

      drilldown: {
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: this.actionchartTwoArr,
      },
    };

    this.columnChartData[1] = var4;
    // Drilldown Fifth Chart End

    // Drilldown Second Chart Start
    this.violationchart = res?.violationChartDto;
    const chartArrayAreaOne: any[] = this.violationchart?.part1;

    const seriesArrChartOne = [
      ...new Set(chartArrayAreaOne?.map((c: any) => c.name)),
    ];
    this.chartOneArr = [];
    seriesArrChartOne?.forEach((element: any, i: number) => {
      const data = chartArrayAreaOne?.find((x: any) => x.name === element);
      this.chartOneArr.push({
        name: element,
        color: this.highChartsColor[i],
        data: [data],
      });
    });

    seriesArrChartOne?.forEach((element: any) => {
      this.chartTwoArr.push({ name: 'Months', id: element, data: [] });
    });
    const coilationChartPart2: [] = this.violationchart?.part2;
    if (coilationChartPart2?.length > 0) {
      coilationChartPart2?.forEach((a1: any) => {
        this.chartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });
    }
    const seriesArrChartTwo = [
      ...new Set(coilationChartPart2?.map((c: any) => c.drilldown)),
    ];
    seriesArrChartTwo?.forEach((element: any) => {
      this.chartTwoArr.push({ name: 'Weeks', id: element, data: [] });
    });

    const voilationChartPart3: [] = this.violationchart?.part3;
    if (voilationChartPart3?.length > 0) {
      voilationChartPart3?.forEach((a1: any) => {
        this.chartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });
    }

    const seriesArrChartThree = [
      ...new Set(voilationChartPart3?.map((c: any) => c.drilldown)),
    ];
    seriesArrChartThree?.forEach((element: any) => {
      this.chartTwoArr.push({ name: 'Days', id: element, data: [] });
    });

    const voilationChartPart4: [] = this.violationchart?.part4;
    if (voilationChartPart4?.length > 0) {
      voilationChartPart4.forEach((a1: any) => {
        this.chartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });
    }

    const seriesArrChartFour = [
      ...new Set(voilationChartPart4?.map((c: any) => c.drilldown)),
    ];
    seriesArrChartFour?.forEach((element: any) => {
      this.chartTwoArr.push({ name: 'Voilations', id: element, data: [] });
    });

    const voilationChartPart5: [] = this.violationchart?.part5;
    if (voilationChartPart5?.length > 0) {
      voilationChartPart5?.forEach((a1: any) => {
        this.chartTwoArr?.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2?.data?.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });
    }

    const vars = {
      customTitle: 'ViolationsByType',
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '9px',
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      exporting: {
        enabled: true,
      },
      legend: { enabled: false },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">Violations: </td>' +
          '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },

      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0.1,
        },
        series: {
          point: {
            events: {
              click: (event) => {
                this.department = '';
                this.week = '';
                this.day = '';
                this.month = '';
                this.useCase = '';
                this.offender = '';
                this.date = '';
                this.status = '';
                this.year = '';
                this.useCase =
                  event.point.series.chart?.breadcrumbs?.list[0]?.levelOptions?.name;
                this.day = event.point.name;
                // this.month = event.point.series.chart.breadcrumbs.list[2].levelOptions.name;
                const monthName =
                  event.point.series.chart?.breadcrumbs?.list[2]?.levelOptions
                    ?.name;
                const monthsLong = {
                  January: '01',
                  February: '02',
                  March: '03',
                  April: '04',
                  May: '05',
                  June: '06',
                  July: '07',
                  August: '08',
                  September: '09',
                  October: '10',
                  November: '11',
                  December: '12',
                };
                this.month = monthsLong[monthName];
                this.setEventFilterToGetData();
              },
            },
          },
        },
      },
      series: this.chartOneArr,
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: this.chartTwoArr,
      },
    };
    this.columnChartData[2] = vars;
    this.chartTwoArr = [];

    // Drilldown Second Chart End

    // Drilldown Third Chart Start
    this.deptchart = res?.departmentChartDto;
    const deptchartArrayAreaOne: [] = this.deptchart?.part1;

    const deptseriesArrChartOne = [
      ...new Set(deptchartArrayAreaOne?.map((c: any) => c.name)),
    ];
    this.deptchartOneArr = [];
    deptseriesArrChartOne?.forEach((element: any, i: number) => {
      const data = deptchartArrayAreaOne?.find((x: any) => x.name === element);
      this.deptchartOneArr.push({
        name: element,
        color: this.highChartsColor[i],
        data: [data],
      });
    });

    deptseriesArrChartOne?.forEach((element: any) => {
      this.deptchartTwoArr.push({
        name: 'Types of voilations',
        id: element,
        data: [],
      });
    });

    const departmentPart2: [] = this.deptchart?.part2;
    if (departmentPart2?.length > 0) {
      departmentPart2?.forEach((a1: any) => {
        this.deptchartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });
    }

    const vars2 = {
      customTitle: 'ViolationsByDepartments',
      chart: {
        type: 'column',
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
      },
      legend: {
        itemStyle: {
          fontSize: '9px',
        },
      },
      exporting: {
        enabled: true,
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '9px',
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">Violations: </td>' +
          '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0.1,
        },
        series: {
          point: {
            events: {
              click: (event) => {
                this.offender = '';
                this.department = '';
                this.week = '';
                this.day = '';
                this.month = '';
                this.useCase = '';
                // this.offender = '';
                this.date = '';
                this.status = '';
                this.year = '';
                this.useCase = event.point.name;
                this.department =
                  event.point.series.chart?.breadcrumbs.list[0].levelOptions.name;
                this.setEventFilterToGetData();
              },
            },
          },
        },
      },
      series: this.deptchartOneArr,
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: this.deptchartTwoArr,
      },
    };
    this.columnChartData[3] = vars2;
    this.chartTwoArr = [];

    // Drilldown Third Chart End

    // Drilldown Fourth Chart Start

    this.offenderchart = res?.voilationByTeamMembers;
    const offenderchartArrayAreaOne = this.offenderchart?.part1;

    const offenderseriesArrChartOne = [
      ...new Set(offenderchartArrayAreaOne?.map((c: any) => c.name)),
    ];
    this.offenderchartOneArr = [];
    offenderseriesArrChartOne?.forEach((element: any, i: number) => {
      const data = offenderchartArrayAreaOne?.find(
        (x: any) => x.name === element
      );
      this.offenderchartOneArr.push({
        name: element,
        color: this.highChartsColor[i],
        data: [data],
      });
    });

    offenderseriesArrChartOne?.forEach((element: any) => {
      this.offenderchartTwoArr.push({
        name: 'Types of voilations',
        id: element,
        data: [],
      });
    });

    const offenderChartPart2: [] = this.offenderchart?.part2;
    if (offenderChartPart2?.length > 0) {
      offenderChartPart2?.forEach((a1: any) => {
        this.offenderchartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });
    }

    const offenderseriesArrChartTwo = [
      ...new Set(this.offenderchart?.part2?.map((c: any) => c.drilldown)),
    ];
    offenderseriesArrChartTwo?.forEach((element: any) => {
      this.offenderchartTwoArr.push({
        name: 'Offenders',
        id: element,
        data: [],
      });
    });

    const offenderChartPart3: [] = this.offenderchart?.part3;
    if (offenderChartPart3?.length > 0) {
      offenderChartPart3?.forEach((a1: any) => {
        this.offenderchartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });
    }

    const vars3 = {
      customTitle: 'ViolationsbyTeamMembers',
      chart: {
        type: 'bar',
      },
      title: {
        text: '',
        style: {
          display: 'none',
        },
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '9px',
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      legend: { enabled: false },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">Violations: </td>' +
          '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0.1,
        },
        series: {
          point: {
            events: {
              click: (event) => {
                this.department = '';
                this.week = '';
                this.day = '';
                this.month = '';
                this.useCase = '';
                this.offender = '';
                this.date = '';
                this.status = '';
                this.year = '';
                const date = event.point.name;
                this.offender =
                  event.point.series.chart?.breadcrumbs.list[0].levelOptions.name;
                this.day = new Date(date).getDate().toString();
                this.month = (new Date(date).getMonth() + 1).toString();
                this.useCase =
                  event.point.series.chart.breadcrumbs.list[2].levelOptions.name;
                this.setEventFilterToGetData();
              },
            },
          },
        },
      },
      series: this.offenderchartOneArr,
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: this.offenderchartTwoArr,
      },
    };
    this.columnChartData[4] = vars3;

    // Drilldown Fourth Chart End

    // Drilldown Fifth Chart Start

    this.violationchart = res?.avgViolations;
    const chartArrayAvgOne = this.violationchart;

    const seriesAvgArrChartOne = [
      ...new Set(chartArrayAvgOne?.map((c: any) => c.name)),
    ];
    this.AvgChartOneArr = [];
    seriesAvgArrChartOne?.forEach((element: any, i: number) => {
      const data = chartArrayAvgOne?.find((x: any) => x.name === element);
      this.AvgChartOneArr.push({
        name: element,
        color: this.highChartsColor[i],
        data: [data],
      });
    });
    const vars4 = {
      customTitle: 'MonthlyViolationsAvg',
      chart: {
        type: 'column',
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'category',
        labels: {
          style: {
            fontSize: '9px',
          },
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
        },
      },
      exporting: {
        enabled: true,
      },
      legend: { enabled: false },
      credits: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">Violations: </td>' +
          '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
        footerFormat: '</table>',
        shared: true,
        useHTML: true,
      },

      plotOptions: {
        column: {
          pointPadding: 0.1,
          borderWidth: 0.1,
        },
        series: {
          point: {
            events: {},
          },
        },
      },
      series: this.AvgChartOneArr,
      drilldown: {
        activeAxisLabelStyle: {
          color: '#000',
          textDecoration: 'none',
          fontWeight: 700,
        },
        series: this.AvgChartTwoArr,
      },
    };
    this.columnChartData[5] = vars4;
    this.AvgChartTwoArr = [];

    // Drilldown Fifth Chart End
    if (res?.dataListDto) {
      const { newReports, acknowledgedReports } = res?.dataListDto;
      const acknowledperc = parseFloat(
        ((acknowledgedReports / newReports) * 100).toFixed(2)
      );
      const newPerc = parseFloat(
        (100 - (Number.isNaN(acknowledperc) ? 0 : acknowledperc)).toFixed(2)
      );
      this.chartData = [
        new ChartDataColumn(
          '1',
          newReports + ' New Reports',
          [newPerc],
          '#19456B',
          '#19456B',
          'transparent',
          '#19456B',
          true
        ),
        new ChartDataColumn(
          '2',
          acknowledgedReports + ' Acknowledged Reports',
          [acknowledperc],
          '#D52A46',
          '#D52A46',
          'transparent',
          '#D52A46',
          false
        ),
      ];
      this.dashboardData = res;
      this.loadingService.stopLoading();
      this.sectionState = SectionStateStatus.Ready;
    }
  }
  onChangeCalenderIndividualChart(event: any, title: string): void {
    this.chartStartDate = event.startDate;
    this.chartEndDate = event.endDate;
    debugger;
    this.chartName = title;
    if (event.type === 'CURRENT_HOUR') {
      switch (title) {
        case 'MonthlyViolationsReport':
          this.setEventChartFilterToGetData();
          break;
        case 'Actionstatus':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByType':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByDepartments':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsbyTeamMembers':
          this.setEventChartFilterToGetData();
          break;
      }
    } else if (event.type === 'LAST_HOUR') {
      switch (title) {
        case 'MonthlyViolationsReport':
          this.setEventChartFilterToGetData();
          break;
        case 'Actionstatus':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByType':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByDepartments':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsbyTeamMembers':
          this.setEventChartFilterToGetData();
          break;
      }
    } else if (event.type === 'THIS_DAY') {
      switch (title) {
        case 'MonthlyViolationsReport':
          this.setEventChartFilterToGetData();
          break;
        case 'Actionstatus':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByType':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByDepartments':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsbyTeamMembers':
          this.setEventChartFilterToGetData();
          break;
      }
    } else if (event.type === 'YESTERDAY') {
      switch (title) {
        case 'MonthlyViolationsReport':
          this.setEventChartFilterToGetData();
          break;
        case 'Actionstatus':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByType':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByDepartments':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsbyTeamMembers':
          this.setEventChartFilterToGetData();
          break;
      }
    } else if (event.type === 'CURRENT_MONTH') {
      switch (title) {
        case 'MonthlyViolationsReport':
          this.setEventChartFilterToGetData();
          break;
        case 'Actionstatus':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByType':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByDepartments':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsbyTeamMembers':
          this.setEventChartFilterToGetData();
          break;
      }
    } else if (event.type === 'LAST_MONTH') {
      switch (title) {
        case 'MonthlyViolationsReport':
          this.setEventChartFilterToGetData();
          break;
        case 'Actionstatus':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByType':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByDepartments':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsbyTeamMembers':
          this.setEventChartFilterToGetData();
          break;
      }
    } else if (event.type === 'LAST_30_DAYS') {
      switch (title) {
        case 'MonthlyViolationsReport':
          this.setEventChartFilterToGetData();
          break;
        case 'Actionstatus':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByType':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByDepartments':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsbyTeamMembers':
          this.setEventChartFilterToGetData();
          break;
      }
    } else if (event.type === 'CUSTOM_RANGE') {
      switch (title) {
        case 'MonthlyViolationsReport':
          this.setEventChartFilterToGetData();
          break;
        case 'Actionstatus':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByType':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsByDepartments':
          this.setEventChartFilterToGetData();
          break;
        case 'ViolationsbyTeamMembers':
          this.setEventChartFilterToGetData();
          break;
      }
    }
  }

  setEventChartFilterToGetData(): void {
    debugger;
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    if (this.chartName === 'MonthlyViolationsReport') {
      this.chartName = 'Monthly Violations Report';
    } else if (this.chartName === 'Actionstatus') {
      this.chartName = 'Action status';
    } else if (this.chartName === 'ViolationsByType') {
      this.chartName = 'Violations By Type';
    } else if (this.chartName === 'ViolationsByDepartments') {
      this.chartName = 'Violations By Departments';
    } else if (this.chartName === 'ViolationsbyTeamMembers') {
      this.chartName = 'Violations by Team Members';
    }
    const key = {
      fromDate: this.chartStartDate,
      toDate: this.chartEndDate,
      chartName: this.chartName,
      companyId: this.sessionCompany?.companyCode,
    };
    this.voilationService.getDashboardChartsByChartName(key).subscribe({
      next: (res) => {
        this.filterIndividualChart(res);
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  filterIndividualChart(res: any) {
    this.FilterChartData = res;

    if (this.chartName === 'Monthly Violations Report') {
      this.Reportchart = [];
      this.Reportchart = res?.offenceChart;
      const ReportchartArrayaOne = this.Reportchart?.part1;
      const ReportseriesArrChartOne = [
        ...new Set(ReportchartArrayaOne?.map((c: any) => c.drilldown)),
      ];

      this.ReportchartOneArr = [];

      this.ReportchartOneArr = [];
      const newoffence = {
        name: 'New Offences',
        color: '#D52A46',
        data: [],
      };
      const closeoffence = {
        name: 'Close Offences',
        color: '#1f456b',
        data: [],
      };
      this.ReportchartOneArr.push(closeoffence);
      this.ReportchartOneArr.push(newoffence);
      ReportseriesArrChartOne.forEach((element: any) => {
        const data = ReportchartArrayaOne?.find(
          (x: any) => x.drilldown === element
        );
        this.ReportchartOneArr.forEach((elem: any, index) => {
          if (elem.name === data.status) {
            this.ReportchartOneArr[index].data.push(data);
          }
        });
      });

      ReportseriesArrChartOne.forEach((element: any) => {
        const data = ReportchartArrayaOne?.find(
          (x: any) => x.drilldown === element
        );
        this.ReportchartTwoArr.push({
          name: data.status,
          id: element,
          data: [],
        });
      });
      this.Reportchart?.part2?.forEach((a1: any) => {
        this.ReportchartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });

      const ReportseriesArrChartTwo = [
        ...new Set(this.Reportchart?.part2?.map((c: any) => c.drilldown)),
      ];
      ReportseriesArrChartTwo.forEach((element: any) => {
        const data = this.Reportchart?.part2?.find(
          (x: any) => x.drilldown === element
        );
        this.ReportchartTwoArr.push({
          name: data.status,
          id: element,
          data: [],
        });
      });

      this.Reportchart?.part3?.forEach((a1: any) => {
        this.ReportchartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });
      const vars1 = {
        customTitle: 'MonthlyViolationsReport',
        chart: {
          type: 'bar',
        },
        navigation: {
          breadcrumbs: {
            style: {
              'font-size': '8px',
            },
          },
        },
        title: {
          text: '',
          style: {
            display: 'none',
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          itemStyle: {
            fontSize: '9px',
          },
        },
        xAxis: {
          type: 'category',
          title: {
            text: '',
          },
          labels: {
            style: {
              fontSize: '9px',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            point: {
              events: {
                click: (event) => {
                  this.department = '';
                  this.week = '';
                  this.day = '';
                  this.month = '';
                  this.useCase = '';
                  this.offender = '';
                  this.date = '';
                  this.status = '';
                  this.year = '';
                  if (event.point.name) {
                    this.day = event.point.name;
                    const monthName =
                      event.point.series.chart.breadcrumbs.list[1].levelOptions
                        .name;
                    const legend = event.point.series.legendItem.textStr;
                    const weekname =
                      event.point.series.chart.breadcrumbs.list[2].levelOptions
                        .name;
                    this.week = weekname.split('Week')[1];
                    this.status = legend.split('Offences')[0].trim();
                    const monthsLong = {
                      January: '01',
                      February: '02',
                      March: '03',
                      April: '04',
                      May: '05',
                      June: '06',
                      July: '07',
                      August: '08',
                      September: '09',
                      October: '10',
                      November: '11',
                      December: '12',
                    };
                    this.month = monthsLong[monthName];
                    this.setEventFilterToGetData();
                  }
                },
              },
            },
          },
        },

        series: this.ReportchartOneArr,
        drilldown: {
          allowPointDrilldown: false,
          activeAxisLabelStyle: {
            color: '#000',
            textDecoration: 'none',
            fontWeight: 700,
          },

          series: this.ReportchartTwoArr,
        },
      };
      this.columnChartData[0] = vars1;
    } else if (this.chartName === 'Action status') {
      this.actionchart = [];
      this.actionchart = res?.actionListChart;
      const actionchartArrayAreaOne = this.actionchart?.part1;

      this.actionchartOneArr = [];
      const data1 = [];
      actionchartArrayAreaOne?.forEach((element, i) => {
        let questionItem = {
          name: element.name,
          y: element.y,
          drilldown: element.drilldown,
          color: this.highChartsColor[i],
        };

        data1.push(questionItem);
      });
      this.actionchartOneArr.push({ name: 'Status', data: data1 });
      const data = actionchartArrayAreaOne;
      const actionseriesArrChartOne = [
        ...new Set(actionchartArrayAreaOne?.map((c: any) => c.name)),
      ];

      actionseriesArrChartOne?.forEach((element: any) => {
        this.actionchartTwoArr.push({ name: '', id: element, data: [] });
      });
      this.actionchart?.part2?.forEach((a1: any) => {
        this.actionchartTwoArr.forEach((a2: any, i) => {
          if (a1.id === a2.id) {
            a2.data.push({
              name: a1.name,
              y: a1.y,
              color: this.highChartsColor[i],
              drilldown: a1.drilldown,
            });
          }
        });
      });

      const actionseriesArrChartTwo = [
        ...new Set(this.actionchart?.part2?.map((c: any) => c.drilldown)),
      ];
      actionseriesArrChartTwo?.forEach((element: any) => {
        this.actionchartTwoArr.push({ name: '', id: element, data: [] });
      });

      this.actionchart?.part3?.forEach((a1: any) => {
        this.actionchartTwoArr.forEach((a2: any, i) => {
          if (a1.id === a2.id) {
            a2.data.push({
              name: a1.name,
              y: a1.y,
              color: this.highChartsColor[i],
              drilldown: a1.drilldown,
            });
          }
        });
      });

      const actionseriesArrChartThree = [
        ...new Set(this.actionchart?.part3?.map((c: any) => c.drilldown)),
      ];
      actionseriesArrChartThree?.forEach((element: any) => {
        this.actionchartTwoArr.push({ name: '', id: element, data: [] });
      });

      this.actionchart?.part4?.forEach((a1: any) => {
        this.actionchartTwoArr.forEach((a2: any, i) => {
          if (a1.id === a2.id) {
            a2.data.push({
              name: a1.name,
              y: a1.y,
              color: this.highChartsColor[i],
              drilldown: a1.drilldown,
            });
          }
        });
      });

      const var4 = {
        // customTitle: 'Action status',
        customTitle: 'Actionstatus',
        chart: {
          type: 'pie',
        },
        title: {
          text: '',
          style: {
            display: 'none',
          },
        },

        credits: {
          enabled: false,
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat:
            '<span style="color:{point.color}">Actions</span>: <b>{point.y:2f}</b><br/>',
        },
        accessibility: {
          announceNewData: {
            enabled: true,
          },
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.name}: {point.y:1f}',
            },
          },
        },
        series: [this.actionchartOneArr[0]],

        drilldown: {
          activeAxisLabelStyle: {
            color: '#000',
            textDecoration: 'none',
            fontWeight: 700,
          },
          series: this.actionchartTwoArr,
        },
      };

      this.columnChartData[1] = var4;
    } else if (this.chartName === 'Violations By Departments') {
      this.deptchart = [];
      this.deptchart = res?.departmentChartDto;
      const deptchartArrayAreaOne = this.deptchart?.part1;

      const deptseriesArrChartOne = [
        ...new Set(deptchartArrayAreaOne?.map((c: any) => c.name)),
      ];
      this.deptchartOneArr = [];
      deptseriesArrChartOne?.forEach((element: any, i: number) => {
        const data = deptchartArrayAreaOne?.find(
          (x: any) => x.name === element
        );
        this.deptchartOneArr.push({
          name: element,
          color: this.highChartsColor[i],
          data: [data],
        });
      });

      deptseriesArrChartOne?.forEach((element: any) => {
        this.deptchartTwoArr.push({
          name: 'Types of voilations',
          id: element,
          data: [],
        });
      });
      this.deptchart?.part2?.forEach((a1: any) => {
        this.deptchartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });
      const vars2 = {
        customTitle: 'ViolationsByDepartments',
        chart: {
          type: 'column',
        },
        title: {
          text: '',
          style: {
            display: 'none',
          },
        },
        legend: {
          itemStyle: {
            fontSize: '9px',
          },
        },
        xAxis: {
          type: 'category',
          labels: {
            style: {
              fontSize: '9px',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">Violations: </td>' +
            '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0.1,
          },
          series: {
            point: {
              events: {
                click: (event) => {
                  this.offender = '';
                  this.department = '';
                  this.week = '';
                  this.day = '';
                  this.month = '';
                  this.useCase = '';
                  // this.offender = '';
                  this.date = '';
                  this.status = '';
                  this.year = '';
                  this.useCase = event.point.name;
                  this.department =
                    event.point.series.chart.breadcrumbs.list[0].levelOptions.name;
                  this.setEventFilterToGetData();
                },
              },
            },
          },
        },
        series: this.deptchartOneArr,
        drilldown: {
          activeAxisLabelStyle: {
            color: '#000',
            textDecoration: 'none',
            fontWeight: 700,
          },
          series: this.deptchartTwoArr,
        },
      };
      this.columnChartData[3] = vars2;
      this.chartTwoArr = [];
    } else if (this.chartName === 'Violations By Type') {
      this.violationchart = [];
      this.violationchart = res?.violationChartDto;
      const chartArrayAreaOne = this.violationchart?.part1;

      const seriesArrChartOne = [
        ...new Set(chartArrayAreaOne?.map((c: any) => c.name)),
      ];
      this.chartOneArr = [];
      seriesArrChartOne?.forEach((element: any, i: number) => {
        const data = chartArrayAreaOne?.find((x: any) => x.name === element);
        this.chartOneArr.push({
          name: element,
          color: this.highChartsColor[i],
          data: [data],
        });
      });

      seriesArrChartOne?.forEach((element: any) => {
        this.chartTwoArr.push({ name: 'Months', id: element, data: [] });
      });
      this.violationchart?.part2?.forEach((a1: any) => {
        this.chartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });
      const seriesArrChartTwo = [
        ...new Set(this.violationchart?.part2?.map((c: any) => c.drilldown)),
      ];
      seriesArrChartTwo?.forEach((element: any) => {
        this.chartTwoArr.push({ name: 'Weeks', id: element, data: [] });
      });

      this.violationchart?.part3?.forEach((a1: any) => {
        this.chartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });

      const seriesArrChartThree = [
        ...new Set(this.violationchart?.part3?.map((c: any) => c.drilldown)),
      ];
      seriesArrChartThree?.forEach((element: any) => {
        this.chartTwoArr.push({ name: 'Days', id: element, data: [] });
      });

      this.violationchart?.part4?.forEach((a1: any) => {
        this.chartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });

      const seriesArrChartFour = [
        ...new Set(this.violationchart?.part4?.map((c: any) => c.drilldown)),
      ];
      seriesArrChartFour?.forEach((element: any) => {
        this.chartTwoArr.push({ name: 'Voilations', id: element, data: [] });
      });

      this.violationchart?.part5?.forEach((a1: any) => {
        this.chartTwoArr?.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2?.data?.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });

      const vars = {
        customTitle: 'ViolationsByType',
        chart: {
          type: 'column',
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
          labels: {
            style: {
              fontSize: '9px',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
        },
        legend: { enabled: false },
        credits: {
          enabled: false,
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">Violations: </td>' +
            '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },

        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0.1,
          },
          series: {
            point: {
              events: {
                click: (event) => {
                  this.department = '';
                  this.week = '';
                  this.day = '';
                  this.month = '';
                  this.useCase = '';
                  this.offender = '';
                  this.date = '';
                  this.status = '';
                  this.year = '';
                  this.useCase =
                    event.point.series.chart?.breadcrumbs?.list[0]?.levelOptions?.name;
                  this.day = event.point.name;
                  // this.month = event.point.series.chart.breadcrumbs.list[2].levelOptions.name;
                  const monthName =
                    event.point.series.chart?.breadcrumbs?.list[2]?.levelOptions
                      ?.name;
                  const monthsLong = {
                    January: '01',
                    February: '02',
                    March: '03',
                    April: '04',
                    May: '05',
                    June: '06',
                    July: '07',
                    August: '08',
                    September: '09',
                    October: '10',
                    November: '11',
                    December: '12',
                  };
                  this.month = monthsLong[monthName];
                  this.setEventFilterToGetData();
                },
              },
            },
          },
        },
        series: this.chartOneArr,
        drilldown: {
          activeAxisLabelStyle: {
            color: '#000',
            textDecoration: 'none',
            fontWeight: 700,
          },
          series: this.chartTwoArr,
        },
      };
      this.columnChartData[2] = vars;
      this.chartTwoArr = [];
    } else if (this.chartName === 'Violations by Team Members') {
      this.offenderchart = [];
      this.offenderchart = res?.voilationByTeamMembers;
      const offenderchartArrayAreaOne = this.offenderchart?.part1;

      const offenderseriesArrChartOne = [
        ...new Set(offenderchartArrayAreaOne?.map((c: any) => c.name)),
      ];
      this.offenderchartOneArr = [];
      offenderseriesArrChartOne?.forEach((element: any, i: number) => {
        const data = offenderchartArrayAreaOne?.find(
          (x: any) => x.name === element
        );
        this.offenderchartOneArr.push({
          name: element,
          color: this.highChartsColor[i],
          data: [data],
        });
      });

      offenderseriesArrChartOne?.forEach((element: any) => {
        this.offenderchartTwoArr.push({
          name: 'Types of voilations',
          id: element,
          data: [],
        });
      });
      this.offenderchart?.part2?.forEach((a1: any) => {
        this.offenderchartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });

      const offenderseriesArrChartTwo = [
        ...new Set(this.offenderchart?.part2?.map((c: any) => c.drilldown)),
      ];
      offenderseriesArrChartTwo?.forEach((element: any) => {
        this.offenderchartTwoArr.push({
          name: 'Offenders',
          id: element,
          data: [],
        });
      });

      this.offenderchart?.part3?.forEach((a1: any) => {
        this.offenderchartTwoArr.forEach((a2: any) => {
          if (a1.id === a2.id) {
            a2.data.push({ name: a1.name, y: a1.y, drilldown: a1.drilldown });
          }
        });
      });

      const vars3 = {
        customTitle: 'ViolationsbyTeamMembers',
        chart: {
          type: 'bar',
        },
        title: {
          text: '',
          style: {
            display: 'none',
          },
        },
        xAxis: {
          type: 'category',
          labels: {
            style: {
              fontSize: '9px',
            },
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: '',
          },
        },
        legend: { enabled: false },
        credits: {
          enabled: false,
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">Violations: </td>' +
            '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
        },
        plotOptions: {
          column: {
            pointPadding: 0.1,
            borderWidth: 0.1,
          },
          series: {
            point: {
              events: {
                click: (event) => {
                  this.department = '';
                  this.week = '';
                  this.day = '';
                  this.month = '';
                  this.useCase = '';
                  this.offender = '';
                  this.date = '';
                  this.status = '';
                  this.year = '';
                  const date = event.point.name;
                  this.offender =
                    event.point.series.chart.breadcrumbs.list[0].levelOptions.name;
                  this.day = new Date(date).getDate().toString();
                  this.month = (new Date(date).getMonth() + 1).toString();
                  this.useCase =
                    event.point.series.chart.breadcrumbs.list[2].levelOptions.name;
                  this.setEventFilterToGetData();
                },
              },
            },
          },
        },
        series: this.offenderchartOneArr,
        drilldown: {
          activeAxisLabelStyle: {
            color: '#000',
            textDecoration: 'none',
            fontWeight: 700,
          },
          series: this.offenderchartTwoArr,
        },
      };
      this.columnChartData[4] = vars3;
    }

    this.loadingService.stopLoading();
    this.sectionState = SectionStateStatus.Ready;
  }
  onSelectCustomDate(): any {
    const { start, end } = this.searchForm.value;
    if (new Date(start) > new Date(end)) {
      this.asyncFeedbackService.showWarningMessage(
        this.translationsList['VideoAnalyticsDashboard']['StartDateShouldBe']
      );
      this.searchForm.get('start')?.setValue(new Date(end));
      return;
    }
    if (new Date(end) < new Date(start)) {
      this.asyncFeedbackService.showWarningMessage(
        this.translationsList['VideoAnalyticsDashboard']['EndDateShouldBe']
      );
      this.searchForm.get('end')?.setValue(new Date(start));
      return;
    }
    this.setEventToGetData();
  }

  onClickNew(item: any): void {
    this.department = '';
    this.week = '';
    this.day = '';
    this.month = '';
    this.useCase = '';
    this.offender = '';
    this.date = '';
    this.status = '';
    this.year = '';
    if (item) {
      this.useCase = item;
      this.status = 'New';
      this.setEventFilterToGetData();
    }
  }
}
