import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { Constants } from 'src/@hodhod/common/constants';
import { generateGuid } from 'src/@hodhod/common/custom_methods';
import { ChecklistType } from 'src/@hodhod/common/enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { BaseApi } from 'src/backend/api/base-api';
import { LoggedUser } from 'src/backend/models/session-user/logged-user';
import {
  CreateInstanceParam,
  SetInstanceParam,
  WorkFlow,
  WorkFlowByScreenParam,
} from 'src/backend/models/work-flows/work-flow';
import { ChecklistSectionService } from 'src/backend/services/checklist-section/checklist-section.service';
import { DepartmentsService } from 'src/backend/services/departments/departments.service';
import { MachineEquipmentService } from 'src/backend/services/machine-and-equipment/machine-equipment.service';
import { TaskTrackerService } from 'src/backend/services/task-tracker/task-tracker.service';
import { UserService } from 'src/backend/services/user.service';
import { WorkFlowService } from 'src/backend/services/work-flow.service';
import { AddInspectionPlanComponent } from '../add-inspection-plan/add-inspection-plan.component';
import * as moment from 'moment';
import { ApplicationPermission } from 'src/app/shared/models/application-permission';

@Component({
  selector: 'app-update-single-task',
  templateUrl: './update-single-task.component.html',
  styleUrls: ['./update-single-task.component.scss'],
})
export class UpdateSingleTaskComponent implements OnInit {
  UpdateSingleTaskForm: FormGroup;
  minDate = new Date();
  OldRepeatedDate = null;
  formMode: 'create' | 'update' = 'create';
  private destroy$ = new Subject();
  public translationsList: any = {};

  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  TaskRecurrence: any;
  checklists: any;

  departmentsData: any;
  subDepartments: any[] = [];
  reportUsers: any;
  machines: any;
  LoadingLabel: any;
  public Type = '';
  mintime: string;
  minRepeatedDate: any;
  workFlow: WorkFlow;
  companyKey: string = '';
  instanceKey: string = '';
  loggedUser: LoggedUser;
  hasCreateUserPermission = ApplicationPermission.USER_CREATE;
  hasCreateDepartmentPermission = ApplicationPermission.DEPARTMENTS_CREATE;
  hasCreateMachinesPermission = ApplicationPermission.MACHINE_CREATE;
  hasCreateAuditCheckListPermission =
    ApplicationPermission.AUDIT_CHECKLIST_WRITE;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddInspectionPlanComponent>,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private TaskTrackerService: TaskTrackerService,
    private checklistService: ChecklistSectionService,
    private feedBackService: AsyncFeedbackService,
    private departmentService: DepartmentsService,
    private machineService: MachineEquipmentService,
    private userService: UserService,
    private workFlowService: WorkFlowService,
    private baseApi: BaseApi
  ) {
    this.translate
      .get(['Errors', 'Success', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.companyKey = this.baseApi.getCompanyIdFromStorage();
    this.loggedUser = this.baseApi.getUserSession();
    this.UpdateSingleTaskForm = this.fb.group({
      title: ['', [Validators.required, noWhitespaceValidator]],
      description: ['', [Validators.required, noWhitespaceValidator]],
      checklist: ['', [Validators.required]],
      department: ['', [Validators.required]],
      subDepartment: [''],
      machine: ['', [Validators.required]],
      user: ['', [Validators.required]],
      TaskDate: ['', [Validators.required]],
    });
    this.getChecklistList();
    this.getDepartmentParentChild();
    this.getUsers();
    this.getMachines();

    this.Type = this.defaults.type;
    if (this.defaults.action == 'update') {
      this.formMode = 'update';
      this.EditTask();
    }

    // else {
    //   this.EditTask();
    // }
    this.OldRepeatedDate = null;
    this.getWorkFlow();
  }
  getCurrenttime(): void {
    const date = new Date();
    var n = date.toLocaleString([], { hour: '2-digit', minute: '2-digit' });
    this.mintime = n;
  }
  Update() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['Role']['UpdateConfirm']['Title'],
          this.translationsList['Role']['UpdateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.updateSingleTask();
        }
      });
  }

  updateSingleTask() {
    this.LoadingLabel = 'Updating Task...';
    var user = JSON.parse(localStorage.getItem('logged_in_user'));
    this.sectionState = SectionStateStatus.LoadingTransparent;
    const key = {
      title: this.UpdateSingleTaskForm.value.title,
      description: this.UpdateSingleTaskForm.value.description,
      checklist: this.UpdateSingleTaskForm.value.checklist,
      department: this.UpdateSingleTaskForm.value.subDepartment
        ? this.UpdateSingleTaskForm.value.subDepartment
        : this.UpdateSingleTaskForm.value.department,
      machine: this.UpdateSingleTaskForm.value.machine,
      user: this.UpdateSingleTaskForm.value.user,
      Id: this.defaults.Id,
      createdBy: user.userId,
      TaskDate: moment(
        new Date(this.UpdateSingleTaskForm.value.TaskDate)
      ).format('YYYY-MM-DDThh:mm:ssZ'),
    };
    this.TaskTrackerService.updateSingleTask(key).subscribe({
      next: () => {
        this.dialogRef.close();
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['SuccessMessage']
          )
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getChecklistList() {
    this.checklistService
      .getAllCheckListForDropdown(ChecklistType.Audit)
      .subscribe((res) => {
        this.checklists = res;
      });
  }
  getDepartmentParentChild(): any {
    this.departmentService.getDepartmentsParentChild().subscribe({
      next: (res) => {
        this.departmentsData = res;
        if (
          this.departmentsData.length == 1 &&
          this.departmentsData[0].parentName == '---Parent Department---' &&
          this.departmentsData[0].detail == null
        ) {
          this.departmentsData = [];
        }
      },
    });
  }
  getUsers(): void {
    this.userService.getReportToUsers().subscribe({
      next: (response) => {
        this.reportUsers = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  getMachines(): void {
    this.machineService.getMachinesByCompany().subscribe({
      next: (response) => {
        this.machines = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  EditTask(): void {
    const key = {
      Id: this.defaults.Id,
    };
    this.LoadingLabel = 'Loading...';
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.TaskTrackerService.GetTaskRecurrenceById(key).subscribe({
      next: (response) => {
        this.UpdateSingleTaskForm.get('title').setValue(response.title),
          this.UpdateSingleTaskForm.get('description').setValue(
            response.description
          );
        this.UpdateSingleTaskForm.get('checklist').setValue(
          response.checkListId
        );

        this.UpdateSingleTaskForm.get('machine').setValue(response.machine);
        this.UpdateSingleTaskForm.get('user').setValue(response.userId);
        this.UpdateSingleTaskForm.get('TaskDate').setValue(response.taskDate);

        if (response.departmentParentId) {
          this.UpdateSingleTaskForm.get('department').setValue(
            response.departmentParentId
          );
          this.onChangeDepartment({ value: response.departmentParentId });
          this.UpdateSingleTaskForm.get('subDepartment').setValue(
            response.department
          );
        } else {
          this.onChangeDepartment({ value: response.department });
          this.UpdateSingleTaskForm.get('department').setValue(
            response.department
          );
        }

        this.minDate = new Date(response.TaskDate);
        const date = new Date(response.startTime);

        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.UpdateSingleTaskForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  getWorkFlow(): void {
    const data: WorkFlowByScreenParam = {
      screenName: Constants.WORKFLOW_AUDIT_SCREEN,
      tenantId: this.companyKey,
      companyKey: Constants.WORK_FLOW_COMPANY_KEY,
    };
    this.workFlowService.getWorkFlowByScreen(data).subscribe({
      next: (response) => {
        this.workFlow = response;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  createWorkFlowInstance(): void {
    this.instanceKey = generateGuid();
    const data: CreateInstanceParam = {
      workFlowKey: this.workFlow?.workFlowKey,
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.userName,
      tenantId: this.companyKey,
    };
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.workFlowService.createWorkFlowInstance(data).subscribe({
      next: (response) => {
        this.setWorkFlowInstance();
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
  setWorkFlowInstance(): void {
    const data: SetInstanceParam = {
      instanceKey: this.instanceKey,
      userId: this.loggedUser.userId,
      userName: this.loggedUser.fullName,
      actionName: Constants.APP_CREATE_ACTION,
      rules: [],
      roleId: this.loggedUser.roleIds,
      payload: {},
    };
    this.workFlowService.setWorkFlowInstance(data).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
        this.deleteWorkFlowInstance();
      },
    });
  }
  deleteWorkFlowInstance(): void {
    this.workFlowService.deleteWorkFlowInstance(this.instanceKey).subscribe({
      next: (response) => {},
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  onChangeDepartment(event) {
    this.UpdateSingleTaskForm.get('subDepartment').setValue('');
    this.sectionState = SectionStateStatus.LoadingTransparent;
    this.departmentService.getSubDepartmentsById(event.value).subscribe({
      next: (response) => {
        this.subDepartments = response;
        this.sectionState = SectionStateStatus.Ready;
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
        this.sectionState = SectionStateStatus.Ready;
      },
    });
  }
}
