import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  EmailValidator,
  noWhitespaceValidator,
  PhoneNumberValidator,
} from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SharedConstants } from 'src/app/shared/models/shared-constants';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { Feature } from 'src/backend/models/features/feature';
import { FeatureService } from 'src/backend/services/feature.service';
import { OrganizationService } from 'src/backend/services/organization.service';

@Component({
  selector: 'app-create-organization',
  templateUrl: './create-organization.component.html',
  styleUrls: ['./create-organization.component.scss'],
})
export class CreateOrganizationComponent implements OnInit, OnDestroy {
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  createOrgForm!: FormGroup;
  public features: Feature[] = [];
  public selectedFeatures: Feature[] = [];
  public selectedFeatureCodes: string[] = [];
  secFeatures: Feature[] = [];
  sefetyFeatures: Feature[] = [];
  setLicenseQuota: boolean = false;
  private destroy$ = new Subject();
  public translationsList: any = {};
  public loadingLabel: string = 'BuildingOrganizationForm';

  constructor(
    private formBuilder: FormBuilder,
    private featureService: FeatureService,
    private feedBackService: AsyncFeedbackService,
    private orgService: OrganizationService,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
    this.translate
      .get(['Errors', 'Success', 'organization'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit(): void {
    this.sectionState = SectionStateStatus.Loading;
    setTimeout(() => {
      this.sectionState = SectionStateStatus.Ready;
    }, 2000);
    this.createOrgForm = this.formBuilder.group({
      orgName: ['', [Validators.required, noWhitespaceValidator]],
      orgImage: [[]],
      noOfCompanies: ['', Validators.required],
      noOfUsers: ['', Validators.required],
      description: [''],
      orgAdminFirstName: ['', [Validators.required, noWhitespaceValidator]],
      orgAdminLastName: [''],
      orgAdminEmail: ['', [Validators.required, EmailValidator]],
      orgPhoneNumber: ['', Validators.pattern('^[0-9]*$')],
      safetyFeatures: [[]],
      securityFeatures: [[]],
      OrgQuota: [0],
      primary: ['#131C4E'],
      secondary: ['#ffffff'],
    });
    this.getAllFeatures();
  }
  ngOnDestroy(): void {
    this.destroy$.complete();
  }
  getAllFeatures(): void {
    this.featureService.getAllFeatures().subscribe({
      next: (response) => {
        this.features = response;
        let security = response?.find(
          (element) => element['module'] === 'Security'
        );
        let safety = response?.find(
          (element) => element['module'] === 'Safety'
        );
        this.secFeatures = security['features'];
        this.sefetyFeatures = safety['features'];
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }
  setSelectedFeature(event: any): void {
    const selectedSafetyCodes: string[] =
      this.createOrgForm.value.safetyFeatures;
    const selectedSecCodes: string[] =
      this.createOrgForm.value.securityFeatures;

    const allSafety = selectedSafetyCodes.includes('ALL');
    const allSecurity = selectedSecCodes.includes('ALL');

    this.selectedFeatures = [];

    if (allSafety) {
      this.selectedFeatures = [...this.sefetyFeatures];
    } else {
      this.selectedFeatures = this.sefetyFeatures.filter((x) =>
        selectedSafetyCodes.includes(x.featureCode)
      );
    }
    if (allSecurity) {
      this.selectedFeatures = [...this.selectedFeatures, ...this.secFeatures];
    } else {
      const selectedSecurityFeatures = this.secFeatures.filter((x) =>
        selectedSecCodes.includes(x.featureCode)
      );
      this.selectedFeatures = [
        ...this.selectedFeatures,
        ...selectedSecurityFeatures,
      ];
    }
    this.selectedFeatureCodes = this.selectedFeatures.map(
      (feature) => feature.featureCode
    );
    if (this.selectedFeatureCodes.includes('AMF-012')) {
      this.setLicenseQuota = true;
      this.createOrgForm.get('OrgQuota').addValidators([Validators.required]);
    } else {
      this.setLicenseQuota = false;
    }
  }
  createOrganization(): void {
    const {
      orgName,
      description,
      orgAdminFirstName,
      orgAdminLastName,
      orgAdminEmail,
      orgPhoneNumber,
      safetyFeatures,
      orgImage,
      noOfCompanies,
      securityFeatures,
      OrgQuota,
      noOfUsers,
      primary,
      secondary,
    } = this.createOrgForm.value;
    const formData = new FormData();
    formData.append('file', orgImage[0] ? orgImage[0] : null);
    const organizationData = {
      orgName,
      description,
      orgAdminFirstName,
      orgAdminLastName,
      orgAdminEmail,
      orgPhoneNumber,
      OrgQuota,
      features: this.selectedFeatureCodes,
      noOfCompanies,
      noOfUsers,
      themeColor: primary,
      secondaryColor: secondary,
    };
    formData.append('content', JSON.stringify(organizationData));
    this.loadingLabel = 'CreatingOrganization';
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['organization']['CreateConfirm']['Title'],
          this.translationsList['organization']['CreateConfirm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.sectionState = SectionStateStatus.LoadingTransparent;
          this.orgService.createOrganization(formData).subscribe({
            next: (respose) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Success, respose?.message)
              );
              this.router.navigate([
                '/' +
                  SharedConstants.ORGANIZATION_SETUP +
                  '/' +
                  SharedConstants.ORGANIZATIONS,
              ]);
              this.resetForm();
            },
            error: ({ error }) => {
              this.sectionState = SectionStateStatus.Ready;
              this.feedBackService.showFeedback(
                new FeedbackModel(FeedbackType.Failure, error?.message)
              );
            },
          });
        }
      });
  }
  resetForm(): void {
    this.createOrgForm.get('orgName')?.setValue('');
    this.createOrgForm.get('orgImage')?.setValue([]);
    this.createOrgForm.get('description')?.setValue('');
    this.createOrgForm.get('noOfCompanies')?.setValue('');
    this.createOrgForm.get('noOfUsers')?.setValue('');
    this.createOrgForm.get('orgAdminFirstName')?.setValue('');
    this.createOrgForm.get('orgAdminLastName')?.setValue('');
    this.createOrgForm.get('orgAdminEmail')?.setValue('');
    this.createOrgForm.get('orgPhoneNumber')?.setValue('');
    this.createOrgForm.get('safetyFeatures')?.setValue([]);
    this.selectedFeatures = [];
  }
  cancelOrganizationForm() {
    this.confirmationService
      .confirm(
        new Confirmation(
          ConfirmationType.NonDestructiveAction,
          this.translationsList['organization']['CancelForm']['Title'],
          this.translationsList['organization']['CancelForm']['Message']
        )
      )
      .then((value) => {
        if (value === true) {
          this.resetForm();
        }
      });
  }
}
