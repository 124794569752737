import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { enumToArray } from 'src/@hodhod/common/custom_methods';
import { HistoryTypes, ReportingTypes } from 'src/@hodhod/common/enum';
import { noWhitespaceValidator } from 'src/app/shared/custom-validators/form-validators';
import { AsyncFeedbackService } from 'src/app/shared/helpers/async-feedback.service';
import { ConfirmationService } from 'src/app/shared/helpers/confirmation.service';
import {
  Confirmation,
  ConfirmationType,
} from 'src/app/shared/models/confirmation';
import { FeedbackModel, FeedbackType } from 'src/app/shared/models/feedback';
import { SectionStateStatus } from 'src/app/shared/models/shared.enum';
import { IncidentCategoryService } from 'src/backend/services/incident-category/incident-category.service';

@Component({
  selector: 'app-add-update-incident',
  templateUrl: './add-update-incident.component.html',
  styleUrls: ['./add-update-incident.component.scss'],
})
export class AddUpdateIncidentComponent implements OnInit {
  roleForm: FormGroup;
  formMode: any;
  private destroy$ = new Subject();
  public translationsList: any = {};
  public sectionState: SectionStateStatus = SectionStateStatus.Ready;
  loadingLabel: string = '';
  reporting: any[] = enumToArray(ReportingTypes).filter((x) => x != 'ALL');

  selectedReporting = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AddUpdateIncidentComponent>,
    private fb: FormBuilder,
    private translate: TranslateService,
    private confirmationService: ConfirmationService,
    private incidentCategoryService: IncidentCategoryService,
    private feedBackService: AsyncFeedbackService
  ) {
    this.translate
      .get(['Errors', 'Success', 'Role', 'User'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((translations: any) => {
        this.translationsList = translations;
      });
  }

  ngOnInit() {
    if (this.defaults.action === 'update') {
      this.formMode = 'update';
    } else if (this.defaults.action === 'view') {
      this.formMode = 'view';
    } else if (this.defaults.action === 'create') {
      this.formMode = 'create';
      this.defaults.role = {} as any;
    }
    this.roleForm = this.fb.group({
      typeName: [
        this.defaults.role.incidentName || '',
        [Validators.required, noWhitespaceValidator],
      ],
      typeOfReport: ['', [Validators.required]],
    });

    if (this.defaults.role.typeOfReport != '') {
      this.roleForm
        .get('typeOfReport')
        .setValue(this.defaults.role.typeOfReport?.split(','));
    }

    if (this.defaults.action === 'view') {
      this.roleForm.disable();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.complete();
  }

  selectedvalue(event) {
    const typeId = this.roleForm.get('typeOfReport').value;
    const types = this.reporting.slice();
    const all = typeId.find((x) => x == 'ALL');
    if (all == 'ALL') {
      types.push('ALL');
      this.roleForm.get('typeOfReport').setValue(types);
    } else {
      this.roleForm.get('typeOfReport').setValue('');
    }
  }

  selectAll(e: any) {
    const typeId = this.roleForm.get('typeOfReport').value;
    const index = typeId.indexOf('ALL');
    if (index > -1) {
      typeId.splice(index, 1);
    }
    if (typeId.length == this.reporting.length) {
      typeId.push('ALL');
      this.roleForm.get('typeOfReport').setValue(typeId);
    } else {
      this.roleForm.get('typeOfReport').setValue(typeId);
    }
  }

  save() {
    if (this.formMode === 'create') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['User']['AddConfirm']['Title'],
            this.translationsList['User']['AddConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.createType();
          }
        });
    } else if (this.formMode === 'update') {
      this.confirmationService
        .confirm(
          new Confirmation(
            ConfirmationType.NonDestructiveAction,
            this.translationsList['Role']['UpdateConfirm']['Title'],
            this.translationsList['Role']['UpdateConfirm']['Message']
          )
        )
        .then((value) => {
          if (value === true) {
            this.updateType();
          }
        });
    }
  }

  createType() {
    const { typeName, typeOfReport } = this.roleForm.value;
    let typeId = [];
    const all = typeOfReport.find((x) => x == 'ALL');
    if (all == 'ALL') {
      typeId = this.reporting.filter((x) => x != 'ALL');
    } else {
      typeId = typeOfReport;
    }
    const obj = {
      incidentName: typeName,
      typeOfReport: typeId.toString(),
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.incidentCategoryService.postTypeOfIncident(obj).subscribe({
      next: () => {
        this.dialogRef.close({ action: 'created' });
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['SuccessMessage']
          )
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error?.message)
        );
      },
    });
  }

  updateType() {
    const { typeName, typeOfReport } = this.roleForm.value;
    let typeId = [];
    const all = typeOfReport.find((x) => x == 'ALL');
    if (all == 'ALL') {
      typeId = this.reporting.filter((a) => a != 'ALL');
    } else {
      typeId = typeOfReport;
    }
    const data = {
      id: this.defaults.role.id,
      incidentName: typeName,
      typeOfReport: typeId.toString(),
    };
    this.loadingLabel = 'General.Refreshing';
    this.sectionState = SectionStateStatus.Loading;
    this.incidentCategoryService.UpdateTypeOfIncident(data).subscribe({
      next: () => {
        this.dialogRef.close({ action: 'updated' });
        this.sectionState = SectionStateStatus.Ready;
        this.feedBackService.showFeedback(
          new FeedbackModel(
            FeedbackType.Success,
            this.translationsList['Success']['UpdateMessage']
          )
        );
      },
      error: ({ error }) => {
        this.feedBackService.showFeedback(
          new FeedbackModel(FeedbackType.Failure, error)
        );
      },
    });
  }

  isCreateMode() {
    return this.formMode === 'create';
  }

  isUpdateMode() {
    return this.formMode === 'update';
  }

  isViewMode() {
    return this.formMode === 'view';
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
}
